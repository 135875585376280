// @flow

import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  SeparatedListContainer,
  SeparatedListTitle,
  SeparatedList,
  SeparatedListItem,
} from '.';

type Props = {
  title?: string,
  items: string[],
  styles: Object,
};

const ListItemData = styled.div`
  ${({ itemFont }) => itemFont.fontSize && `font-size: ${itemFont.fontSize}px`};
`;

const SeparatedListTitleExtended = styled(SeparatedListTitle)`
  ${({ hideEquipmentListTitle }) => hideEquipmentListTitle && 'display: none'};
`;

const ListItemContent = ({ font, item }) => (
  <Fragment>
    <div style={{ paddingRight: '5px' }}>—</div>
    <ListItemData itemFont={font}>{item}</ListItemData>
  </Fragment>
);

function BulletedList({ title, items, styles }: Props) {
  const { itemFont = '', hideEquipmentListTitle = '' } = styles || {};
  return (
    <SeparatedListContainer>
      <SeparatedList>
        {items.map((item, idx) =>
          idx === 0 ? (
            <SeparatedListItem key={item}>
              <SeparatedListTitleExtended
                hideEquipmentListTitle={hideEquipmentListTitle}
              >
                {title}
              </SeparatedListTitleExtended>
              <div style={{ display: 'flex' }}>
                <ListItemContent font={itemFont} item={item} />
              </div>
            </SeparatedListItem>
          ) : (
            <SeparatedListItem key={item} flex>
              <ListItemContent font={itemFont} item={item} />
            </SeparatedListItem>
          ),
        )}
      </SeparatedList>
    </SeparatedListContainer>
  );
}

BulletedList.defaultProps = {
  title: null,
};

export default BulletedList;

// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollLock from 'react-scrolllock';

import media from '../../theme';

const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  overflow: hidden;
  transition: visibility 0.35s, opacity 0.35s;
  visibility: visible;
  opacity: 1;
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  * {
    box-sizing: border-box;
  }
  * img {
    display: block;
  }
  ${media.min.medium`
    height: auto;
    width: ${({ modalWidth }) => (modalWidth ? `${modalWidth};` : 'auto')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `};
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  ${({ direction }) => (direction === 'rtl' ? 'left: 0;' : 'right: 0;')};
  box-sizing: border-box;
  margin: 10px 10px;
  cursor: pointer;
  z-index: 999;
  font-size: 1.5em;
  color: white;
`;

const CloseImg = styled.img`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 40px;
  padding: 5px;
`;

type Props = {
  close: func,
  modalWidth: string,
  children: any,
  direction: string,
};

export default ({ close, modalWidth, children, direction }): Props => (
  <Overlay>
    <Close onClick={close} direction={direction}>
      <CloseImg
        src="https://res.cloudinary.com/motortrak/image/upload/v1564484782/cms/global/icons/x.svg"
        alt="close"
      />
    </Close>
    <ModalContentWrapper modalWidth={modalWidth}>
      <ModalContent>{children}</ModalContent>
    </ModalContentWrapper>
    <ScrollLock />
  </Overlay>
);

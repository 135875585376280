import React from 'react';
import styled from 'styled-components';

const TextInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #0065db;
`;

export const TextInputEditor = ({ field, fields, updatePodField }) => {
  return (
    <>
      <TextInputWrapper>
        <div>Label</div>
        <input
          style={{ width: '300px' }}
          value={field.label}
          onChange={e =>
            updatePodField(
              'fields',
              fields.map(m =>
                m === field
                  ? {
                      ...m,
                      label: e.target.value,
                    }
                  : m,
              ),
            )
          }
        />
      </TextInputWrapper>
    </>
  );
};

/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { constants } from './reducer';
import { actions as shortlistActions } from '../../shared/shortlist';

function* getVehicles() {
  try {
    yield put(shortlistActions.getVehicles());
  } catch (error) {
    // no-op
  }
}

function* toggleVehicle(action) {
  const { payload } = action;
  try {
    yield put(shortlistActions.toggleVehicle({ ...payload }));
  } catch (error) {
    // no-op
  }
}

export default function* ShortlistSaga() {
  yield takeEvery(constants.INIT, getVehicles);
  yield takeEvery(constants.TOGGLE_VEHICLE, toggleVehicle);
}

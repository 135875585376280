/* eslint-disable quote-props, quotes */
const config = {};

const previewCompareVehicles = [
  {
    id: '58046',
    name: '2016 XE',
    images: [
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_1_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_2_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_3_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_4_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_5_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_6_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_7_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_8_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_9_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_10_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_11_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_12_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_13_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_14_5bc620fccd71a2eb.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/AN4/GA9/175/59/1024/SAJAB4AN4GA917559_15_5bc620fccd71a2eb.jpg',
    ],
    odometer: {
      reading: 33120,
      units: 'Km',
    },
    engine: '2.0 i4 Diesel (180PS',
    transmission: 'Automatic',
    fuel: 'Diesel',
    views: 0,
    price: {
      currency: 'EUR',
      value: 59990,
      monthly: 0,
      apr: 0,
    },
    co2EmissionsCombined: null,
    distanceFromRetailer: 0,
    retailerName: 'Auto Hartmann GmbH',
    retailerId: '1417',
    retailerPhone: '0831-9604600',
    description: 'XE 2.0 I4 DIESEL (180PS) PRESTIGE LIMOUSINE',
    interior: 'Latte',
    exterior: 'Glacier White',
    bodystyle: 'Saloon',
    registration: '2016',
    location: 'Kempten',
  },
  {
    id: '57894',
    name: '2015 XE',
    images: [
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_1_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_2_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_3_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_4_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_5_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_6_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_7_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_8_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_9_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_10_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_11_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_12_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_13_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_14_323b920d714b8b50.jpg',
      'http://images-ims.motortrak.com/S/SAJ/AB4/ANX/GA9/151/98/1024/SAJAB4ANXGA915198_15_323b920d714b8b50.jpg',
    ],
    odometer: {
      reading: 33120,
      units: 'Km',
    },
    engine: '2.0 i4 Diesel (180PS',
    transmission: 'Automatic',
    fuel: 'Diesel',
    views: 0,
    price: {
      currency: 'EUR',
      value: 64880,
      monthly: 0,
      apr: 0,
    },
    co2EmissionsCombined: null,
    distanceFromRetailer: 0,
    retailerName: 'Auto Center Milinski GmbH',
    retailerId: '1231',
    retailerPhone: '06181 / 578900',
    description: 'XE 2.0 I4 DIESEL (180PS) R-SPORT LIMOUSINE',
    interior: 'Jet',
    exterior: 'Ultimate Black',
    bodystyle: 'Saloon',
    registration: '2015',
    location: 'Bruchköbel',
  },
];

const previewShortListedVehicles = [
  {
    id: '53482',
    name: '2015 XE',
    images: [
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_1_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_2_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_3_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_4_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_6_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_5_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_7_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_8_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_9_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_0_4A74F48F66F6DBF3.jpg',
    ],
    odometer: {
      reading: 33120,
      units: 'Km',
    },
    engine: '2.0 i4 Diesel (180PS',
    transmission: 'Automatic',
    fuel: 'Diesel',
    views: null,
    price: {
      currency: 'EUR',
      value: 36600,
      monthly: 0,
      apr: 0,
    },
    co2EmissionsCombined: null,
    distanceFromRetailer: 0,
    retailerName: 'Walter Coenen GmbH',
    retailerId: '1333',
    description: 'XE 2.0 I4 DIESEL (180PS) PRESTIGE SALOON',
  },
  {
    id: '55180',
    name: '2015 XF (X260)',
    images: [
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_1_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_2_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_3_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_4_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_5_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_6_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_7_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_8_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_9_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_10_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_11_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_12_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_13_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_14_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_15_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_16_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_17_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_18_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_19_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_20_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_21_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_22_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_23_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_24_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_25_31b9364182c383d9.jpg',
    ],
    odometer: {
      reading: 33120,
      units: 'Km',
    },
    engine: '2.0 i4 Diesel (180PS',
    transmission: 'Automatic',
    fuel: 'Diesel',
    views: null,
    price: {
      currency: 'EUR',
      value: 38990,
      monthly: 0,
      apr: 0,
    },
    co2EmissionsCombined: null,
    distanceFromRetailer: 0,
    retailerName: 'Walter Coenen GmbH',
    retailerId: '1333',
    description: 'XF (X260) 2.0 I4 DIESEL (180PS) PRESTIGE SALOON',
  },
];

export { config, previewShortListedVehicles, previewCompareVehicles };

// @flow

import React from 'react';
import styled from 'styled-components';

import { InputField } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

export default function Text({
  values: { id: value },
  ids: { id },
  name,
  onChange,
  ...props
}: FieldProps) {
  const input = {
    value,
    onChange: event => onChange(id, event.target.value.trimStart()),
  };

  return (
    <>
      {props.displayLabel && <TextLabel>{name}</TextLabel>}
      <InputField
        input={input}
        type="text"
        name={name}
        initialValue={value}
        inputFlexMode="1"
      />
    </>
  );
}

/* eslint-disable react/sort-comp */
// @flow
import React, { Component } from 'react';
import { Swipeable } from 'react-swipeable';
import styled from 'styled-components';
import CarouselSlide from './CarouselSlide';
import renderNavigation from './renderNavigation';
import RenderMedia from '../VehicleGallery/RenderMedia';

const Container = styled.div`
  width: 100%;
`;

const SwipeContainer = styled(Swipeable)`
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 10px;
`;

const SlidesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type CarouselProps = {
  slides: any[],
  renderFooter?: Function,
  renderNavigation?: Function,
  delay?: number,
  isLooping?: boolean,
  transitionTime?: number,
  imageWidth?: number,
  imageHeight?: number,
  rotate?: boolean,
  slideIndex: number,
  arrowRight: string,
  arrowLeft: string,
  enable360View: boolean,
  onClickPrev: () => void,
  onClickNext: () => void,
  vehicleInfo: Object,
  globalStyling: Object,
  close360Icon: string,
  translations: Object,
  imageCarousel: Object,
  fullscreen: boolean,
};

type DefaultProps = {
  renderNavigation: Function,
  isLooping: boolean,
  delay: number,
  imageWidth: number,
  imageHeight: number,
  transitionTime: number,
  slides: [],
  rotate: boolean,
};

type CarouselState = {
  mediaType?: string,
  slideTotal: number,
  activeSlides: any[],
  isMediaVisible: boolean,
  isNavigationVisible: boolean,
};

export default class Carousel extends Component<CarouselProps, CarouselState> {
  constructor(props: CarouselProps) {
    super(props);
    this.state = {
      isMediaVisible: false,
    };
  }

  static defaultProps: DefaultProps = {
    renderNavigation,
    isLooping: true,
    delay: 3000,
    imageWidth: 786,
    imageHeight: 650,
    transitionTime: 500,
    slides: [],
    rotate: false,
  };

  showMedia = (mediaType: string) =>
    this.setState({ isMediaVisible: true, mediaType });

  hideMedia = () => this.setState({ isMediaVisible: false });

  goToPrev = () => {
    const { rotate, onClickPrev, slideIndex } = this.props;
    const canGoPrev = rotate || slideIndex > 0;
    if (canGoPrev) {
      onClickPrev();
    }
  };

  goToNext = () => {
    const { slideTotal } = this.state;
    const { isLooping, rotate, slideIndex, onClickNext } = this.props;
    const canGoNext = rotate || isLooping || slideIndex + 1 < slideTotal;
    if (canGoNext) {
      onClickNext();
    }
  };

  render() {
    const { isMediaVisible, mediaType } = this.state;
    const { slides } = this.props;
    const {
      imageHeight,
      imageWidth,
      renderFooter,
      renderNavigation: renderNavigationProp = renderNavigation,
      transitionTime,
      slideIndex,
      arrowRight,
      arrowLeft,
      enable360View,
      vehicleInfo,
      globalStyling,
      close360Icon,
      translations,
      imageCarousel,
      fullscreen,
      parsedYouTubeId,
    } = this.props;

    const goLeft =
      globalStyling.direction === 'rtl' ? this.goToNext : this.goToPrev;
    const goRight =
      globalStyling.direction === 'rtl' ? this.goToPrev : this.goToNext;

    return (
      <Container>
        {isMediaVisible ? (
          <RenderMedia
            hideMedia={this.hideMedia}
            mediaType={mediaType}
            vehicleInfo={vehicleInfo}
            enable360View={enable360View}
            close360Icon={close360Icon}
            globalStyling={globalStyling}
            translations={translations}
            imageCarousel={imageCarousel}
            parsedYouTubeId={parsedYouTubeId}
          />
        ) : (
          <SwipeContainer
            trackMouse
            style={{ touchAction: 'pan-y' }}
            preventDefaultTouchmoveEvent
            onSwipedLeft={goLeft}
            onSwipedRight={goRight}
          >
            <SlidesContainer imageHeight={imageHeight}>
              <CarouselSlide
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                transitionTime={transitionTime}
                slide={slides[slideIndex]}
                fullscreen={fullscreen}
              />
            </SlidesContainer>
            {renderNavigationProp({
              goToNext: goRight,
              goToPrev: goLeft,
              arrowRight,
              arrowLeft,
            })}
            {renderFooter &&
              !isMediaVisible &&
              renderFooter({
                showMedia: this.showMedia,
              })}
          </SwipeContainer>
        )}
      </Container>
    );
  }
}

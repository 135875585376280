// @flow
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { InputField, Fieldset, Label, FormGroup } from 'cms-ui';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colours.highlight02};
  border: 1px solid ${({ theme }) => theme.colours.lowlight};
  width: 100%;
  margin-top: 20px;
  padding: 40px;
`;

export default function GeneralDetails() {
  return (
    <Box>
      <Fieldset>
        <FormGroup>
          <Label htmlFor="name">AVL Name</Label>
          <Field
            large
            id="name"
            name="name"
            component={InputField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="builderUrl">Internal URL</Label>
          <Field
            large
            id="builderUrl"
            name="builderUrl"
            disabled
            component={InputField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="externalUrl">External URL</Label>
          <Field
            large
            id="externalUrl"
            name="configuration.externalUrl"
            component={InputField}
            type="text"
          />
        </FormGroup>
      </Fieldset>
    </Box>
  );
}

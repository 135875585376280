// @flow
import React from 'react';
import styled from 'styled-components';

import SearchResultTile from './SearchResultTile';
import SearchResultBlock from './SearchResultBlock';

import CloseIcon from './JaguarIcons/Global/Close';
import CompareIcon from './JaguarIcons/Global/Compare';
import HeartIcon from './JaguarIcons/Global/Heart';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ length }) =>
    length && length % 3 > 0 ? 'flex-start' : 'space-between'};}
`;

const IconWrapper = styled.button.withConfig({
  displayName: 'IconButton',
})`
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const CloseIconButton = onCloseClick => () => (
  <IconWrapper onClick={onCloseClick}>
    <CloseIcon width="1.5em" height="1.3125em" colour="#ACACAC" />
  </IconWrapper>
);

const CompareIconButton = (
  onCompareClick: Function,
  compareIconColour: string,
) => () => (
  <IconWrapper onClick={onCompareClick}>
    <CompareIcon width="2em" height="1.75em" colour={compareIconColour} />
  </IconWrapper>
);

const HeartIconButton = (
  onHeartClick: Function,
  heartIconColour: string,
) => () => (
  <IconWrapper onClick={onHeartClick}>
    <HeartIcon width="2em" height="1.75em" colour={heartIconColour} />
  </IconWrapper>
);

type Props = {
  translations: Object,
  vehicles: Vehicle[],
  onHeartClick: Function,
  onCloseClick: Function,
  onCompareClick: Function,
  compareVehicle: Function,
  shortlistVehicle: Function,
  onMoreInfoClick: Function,
  getCompareIconColour: Function,
  getShortListedIconColour: Function,
  selectedView: 'GRID' | 'LIST',
  sortValue: string,
  sortValueDictionary: string,
  placeholdingImage: string,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  showFinancePopup: boolean,
  vehicleSpecContainer: Object,
  financeWidthOverride: string,
  icon360: string,
};

export default function VehicleShortlist({
  translations,
  vehicles,
  placeholdingImage,
  onHeartClick,
  onCloseClick,
  onCompareClick,
  compareVehicle,
  shortlistVehicle,
  onMoreInfoClick,
  getCompareIconColour,
  getShortListedIconColour,
  selectedView,
  sortValue,
  sortValueDictionary,
  globalStyling,
  visibility,
  locale,
  financeHeadingFont,
  showFinancePopup,
  vehicleSpecContainer,
  financeWidthOverride,
  icon360,
}: Props) {
  return (
    <div>
      {vehicles && vehicles.length ? (
        <Container length={vehicles.length}>
          {selectedView === 'GRID'
            ? Array.from(new Set(vehicles.map(v => v.id)))
                .map(id => ({
                  ...vehicles.find(v => v.id === id),
                }))
                .sort(sortValueDictionary[sortValue])
                .map(vehicle => (
                  <SearchResultTile
                    checkLength={vehicles.length % 3 > 0}
                    shortList
                    placeholdingImage={placeholdingImage}
                    key={vehicle.id}
                    translations={translations}
                    align="left"
                    carResult={vehicle}
                    onMoreInfoClicked={() => onMoreInfoClick('VDP', vehicle.id)}
                    renderTopRightIcon={CloseIconButton(() =>
                      onCloseClick(vehicle),
                    )}
                    renderBottomIcon1={CompareIconButton(
                      () => onCompareClick(vehicle),
                      getCompareIconColour(vehicle.id),
                    )}
                    renderBottomIcon2={HeartIconButton(
                      () => onHeartClick(vehicle),
                      getShortListedIconColour(vehicle.id),
                    )}
                    globalStyling={globalStyling}
                    visibility={visibility.tiles}
                    locale={locale}
                    financeHeadingFont={financeHeadingFont}
                    showFinancePopup={showFinancePopup}
                    icon360={icon360}
                  />
                ))
            : null}
          {selectedView === 'LIST'
            ? Array.from(new Set(vehicles.map(v => v.id)))
                .map(id => ({
                  ...vehicles.find(v => v.id === id),
                }))
                .sort(sortValueDictionary[sortValue])
                .map(vehicle => (
                  <SearchResultBlock
                    key={vehicle.id}
                    translations={translations}
                    vehicle={vehicle}
                    placeholdingImage={placeholdingImage}
                    onMoreInfoClick={onMoreInfoClick}
                    compareVehicle={compareVehicle}
                    shortlistVehicle={shortlistVehicle}
                    compareIconColour={getCompareIconColour(vehicle.id)}
                    shortListedIconColour={getShortListedIconColour(vehicle.id)}
                    renderTopRightIcon={CloseIconButton(() =>
                      onCloseClick(vehicle),
                    )}
                    globalStyling={globalStyling}
                    visibility={visibility.tiles}
                    locale={locale}
                    financeHeadingFont={financeHeadingFont}
                    displayImage
                    vehiclePriceSectionWidth={25}
                    showFinancePopup={showFinancePopup}
                    vehicleSpecContainer={vehicleSpecContainer}
                    financeWidthOverride={financeWidthOverride}
                    icon360={icon360}
                  />
                ))
            : null}
        </Container>
      ) : null}
    </div>
  );
}

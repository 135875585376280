// @flow
const Navigate: 'ROUTE NAVIGATE' = 'ROUTE NAVIGATE';
type NavigateAction = { type: typeof Navigate, payload: string };
const navigate = (route: string): NavigateAction => ({
  type: Navigate,
  payload: route,
});

const NavigateSuccess: 'ROUTE NAVIGATE SUCCESS' = 'ROUTE NAVIGATE SUCCESS';
type NavigateSuccessAction = {
  type: typeof NavigateSuccess,
  payload: Location,
};
const navigateSuccess = (location: Location): NavigateSuccessAction => ({
  type: NavigateSuccess,
  payload: location,
});

export const actions = { navigate, navigateSuccess };

export const constants = { Navigate, NavigateSuccess };

export type Action = NavigateAction | NavigateSuccessAction;

import { useSelector, useDispatch } from 'react-redux';
import uuid from 'uuid/v1';
import { actions as contextActions } from '../../state/actions/context';
import { actions as pageActions } from '../../state/actions/page';
import pods from '../../pods';

const getPodConfig = podId => {
  const pod = pods.find(p => p.id === podId);
  return {
    config: {
      ...pod.defaultConfig,
    },
    id: pod.id,
    instanceId: uuid(),
  };
};

const markPodProperties = (
  bandSizes,
  activeItem,
  action,
  setDraggingContext,
  setClickedEditorPodContext,
  setClickedPreviewPodContext,
  setAddPod,
  replacePodOnBand,
) => {
  return pods
    .filter(p => !p.requirements.global)
    .filter(p => !p.requirements.pageType)
    .map(p =>
      p.requirements.columns.some(c => bandSizes.includes(c))
        ? {
            ...p,
            available: true,
            selected: action === 'pod-type-selected' && activeItem === p.id,
            onClick:
              action !== 'pod-selected'
                ? () =>
                    setClickedEditorPodContext(
                      action === 'pod-type-selected' && activeItem === p.id
                        ? null
                        : p.id,
                    )
                : () => {
                    replacePodOnBand(
                      () => setAddPod(null),
                      activeItem,
                      getPodConfig(p.id),
                    );
                    setClickedPreviewPodContext(null);
                  },
            onDragStart: event => {
              event.dataTransfer.setData('text/plain', p.id);
              setDraggingContext(p.id);
            },
            onDragEnd: () => setDraggingContext(null),
          }
        : p,
    );
};

const usePodList = () => {
  const {
    page: { pageName, id: pageId },
    activeItem,
    action,
  } = useSelector(state => state.context);

  const page = useSelector(state =>
    Object.values(state.config.pages).find(p => p.id === pageId),
  );

  const bands = useSelector(state => state.config.bands);

  const bandsOnPage =
    action !== 'pod-selected'
      ? page.modules.map(m => bands[m])
      : page.modules
          .filter(m => bands[m].config.pods.includes(activeItem))
          .map(m => bands[m]);

  const bandSizes = bandsOnPage.map(b => b.config.columns);

  const dispatch = useDispatch();
  const setDraggingContext = dragging =>
    dispatch(contextActions.setDraggingContext(dragging));
  const setClickedEditorPodContext = clicked =>
    dispatch(contextActions.setClickedEditorPodContext(clicked));
  const setClickedPreviewPodContext = clicked =>
    dispatch(contextActions.setClickedPreviewPodContext(clicked));
  const setAddPod = bandPosition =>
    dispatch(contextActions.setAddPod(bandPosition));
  const replacePodOnBand = (act, currentPodId, pod) =>
    dispatch(pageActions.replacePodOnBand(act, currentPodId, pod));

  const onLeave = () => dispatch(contextActions.clearContext());

  const display = markPodProperties(
    bandSizes,
    activeItem,
    action,
    setDraggingContext,
    setClickedEditorPodContext,
    setClickedPreviewPodContext,
    setAddPod,
    replacePodOnBand,
  );

  return { onLeave, pods: display, pageName };
};

export default usePodList;

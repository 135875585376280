// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_MATRIX_SEARCH';

export const constants = moduleConstants(modulePrefix, [
  'GET_INVENTORY_GROUP',
  'GET_INVENTORY_GROUP_SUCCESS',
  'GET_MODEL_GROUPS',
  'GET_MODEL_GROUPS_SUCCESS',
  'UPDATE_MODEL_COUNTS',
  'GET_FINANCE',
  'GET_FINANCE_SUCCESS',
  'CONVERT_PRICES',
  'CONVERT_PRICES_SUCCESS',
  'INIT',
]);

export const id = 'MatrixSearch';

// @TODO: The models have accents and trailing white spaces in some cases, the modelsGroups do not
// so need to normalize the string to perform lookups
// we need to get the ModelGroups end point to return proper model name data :-(
const normalize = str =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();

export const actions = {
  getInventoryGroup: moduleActionWithArgs(
    constants.GET_INVENTORY_GROUP,
    id,
    payload => payload,
  ),
  getInventoryGroupSuccess: moduleActionWithArgs(
    constants.GET_INVENTORY_GROUP_SUCCESS,
    id,
    config => config,
  ),
  getModelGroups: moduleAction(constants.GET_MODEL_GROUPS, id),
  getModelGroupsSuccess: moduleActionWithArgs(
    constants.GET_MODEL_GROUPS_SUCCESS,
    id,
    payload => payload,
  ),
  updateModelCounts: moduleActionWithArgs(
    constants.UPDATE_MODEL_COUNTS,
    id,
    payload => payload,
  ),
  getFinance: moduleActionWithArgs(
    constants.GET_FINANCE,
    id,
    payload => payload,
  ),
  getFinanceSuccess: moduleActionWithArgs(
    constants.GET_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  convertPrices: moduleAction(constants.CONVERT_PRICES, id),
  convertPricesSuccess: moduleActionWithArgs(
    constants.CONVERT_PRICES_SUCCESS,
    id,
    payload => payload,
  ),
  init: moduleAction(constants.INIT, id),
};

export const initialState = {
  modelGroups: [],
  error: null,
};

type State = {
  modelGroups: [],
  error: any,
};

type Action = {
  type: string,
  payload: any,
};

const sortGroups = (a, b) => {
  // order can be undefined so put these last
  const aOrder = a.position || 10000;
  const bOrder = b.position || 10000;

  return aOrder - bOrder;
};

const mergeCountsAndModelGroups = (counts, stateGroups) =>
  counts
    .map(c => {
      const fromState =
        stateGroups.find(
          g =>
            (g.model && g.model === c.display) ||
            (g.modelGroupName &&
              normalize(g.modelGroupName.toLowerCase()) ===
                normalize(c.display.toLowerCase())),
        ) || {};
      return {
        ...fromState,
        model: c.display,
        count: c.count,
        ...(c.count === 0 && {
          finance: undefined,
        }),
      };
    })
    .sort(sortGroups);

export const matchGroup = (g, i) =>
  g.model === i.model ||
  (g.modelGroupName &&
    i.model &&
    normalize(g.modelGroupName.toLowerCase()) ===
      normalize(i.model.toLowerCase()));

const mergeModelGroupsAndModelGroups = (groups, stateGroups) => {
  const mergedGroups = groups.map(g => {
    const fromState = stateGroups.find(sg => matchGroup(g, sg)) || {};
    return {
      ...fromState,
      ...g,
    };
  });

  const stateOnlyGroups = stateGroups.filter(
    sg => !mergedGroups.some(mg => matchGroup(mg, sg)),
  );

  return [...mergedGroups, ...stateOnlyGroups].sort(sortGroups);
};

const mergeInventoryAndModelGroups = (inventory, stateGroups) => {
  const vehiclesfromInventory = inventory.map(i => {
    const fromState = stateGroups.find(g => matchGroup(g, i)) || {};
    return {
      ...fromState,
      model: i.model,
      minimumPrice: i.minimumPrice,
      currency: i.currency,
      financeVehicleId: i.financeVehicleId,
      originalPrice: i.originalPrice,
      modelDisplay: i.modelDisplay,
    };
  });

  const vehiclesNotInInventory = stateGroups.filter(
    g => !inventory.find(i => matchGroup(g, i)),
  );

  return [...vehiclesfromInventory, ...vehiclesNotInInventory].sort(sortGroups);
};

const mergeFinanceAndModelGroups = (finance, modelGroups) => {
  const modelForFinance = modelGroups.find(g => matchGroup(g, finance));

  return [
    ...modelGroups.filter(g => g !== modelForFinance),
    {
      ...modelForFinance,
      finance: finance.finance,
    },
  ].sort(sortGroups);
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.GET_MODEL_GROUPS_SUCCESS: {
      const { modelGroups } = action.payload;
      const merged = mergeModelGroupsAndModelGroups(
        modelGroups,
        state.modelGroups,
      );
      return {
        ...state,
        modelGroups: merged,
      };
    }
    case constants.GET_INVENTORY_GROUP_SUCCESS: {
      const merged = mergeInventoryAndModelGroups(
        action.payload,
        state.modelGroups,
      );
      return {
        ...state,
        modelGroups: merged,
      };
    }
    case constants.GET_FINANCE_SUCCESS: {
      const merged = mergeFinanceAndModelGroups(
        action.payload,
        state.modelGroups,
      );
      return {
        ...state,
        modelGroups: merged,
      };
    }
    case constants.UPDATE_MODEL_COUNTS: {
      const merged = mergeCountsAndModelGroups(
        action.payload,
        state.modelGroups,
      );
      return {
        ...state,
        modelGroups: merged,
      };
    }
    case constants.CONVERT_PRICES_SUCCESS: {
      return {
        ...state,
        modelGroups: mergeInventoryAndModelGroups(
          action.payload,
          state.modelGroups,
        ),
      };
    }
    case constants.GET_INVENTORY_GROUP_FAILURE:
    case constants.GET_MODEL_GROUPS_FAILURE:
    default:
      return state;
  }
}

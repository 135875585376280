// @flow
import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';
import type { Theme } from '../theme';

type ThemeProps = {
  theme: Theme,
};

type Props = {
  children?: ?Node,
};

const StyledInlineError = styled.div.withConfig({
  displayName: 'InlineError',
})`
  color: ${(props: ThemeProps) => props.theme.colours.danger01};
  padding: 10px 2px 2px 2px;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
`;

export default function InlineError({ children }: Props) {
  return <StyledInlineError>{children}</StyledInlineError>;
}

InlineError.defaultProps = {
  children: null,
};

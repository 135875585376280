// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_BUDGET_SEARCH';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'GET_BUDGET_SEARCH_GROUPS',
  'GET_BUDGET_SEARCH_GROUPS_SUCCESS',
  'GET_BUDGET_SEARCH_RANGE',
  'GET_BUDGET_SEARCH_RANGE_SUCCESS',
  'GET_AFFORDABLE_MODEL_FINANCE_SUCCESS',
  'GET_SUGGESTED_MODEL_FINANCE_SUCCESS',
]);

export const id = 'BudgetSearch';

export const actions = {
  init: moduleAction(constants.INIT, id),
  getBudgetSearchGroups: moduleActionWithArgs(
    constants.GET_BUDGET_SEARCH_GROUPS,
    id,
    payload => payload,
  ),
  getBudgetSearchGroupsSuccess: moduleActionWithArgs(
    constants.GET_BUDGET_SEARCH_GROUPS_SUCCESS,
    id,
    config => config,
  ),
  getBudgetSearchRange: moduleActionWithArgs(
    constants.GET_BUDGET_SEARCH_RANGE,
    id,
    payload => payload,
  ),
  getBudgetSearchRangeSuccess: moduleActionWithArgs(
    constants.GET_BUDGET_SEARCH_RANGE_SUCCESS,
    id,
    config => config,
  ),
  getAffordableModelFinanceSuccess: moduleActionWithArgs(
    constants.GET_AFFORDABLE_MODEL_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  getSuggestedModelFinanceSuccess: moduleActionWithArgs(
    constants.GET_SUGGESTED_MODEL_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
};

const replaceIfExists = (groups, group) => [
  ...groups.map(g => (g.model === group.model ? group : g)),
];

type Range = {
  min: number,
  max: number,
};

type State = {
  error: any,
  affordableModels: ?(Object[]),
  suggestedModels: ?(Object[]),
  suggestedBudgetIncrease: number,
  priceRangeLabel: ?Range,
  fullRangeOptions: ?Range,
  placeholdingImage: string,
};

export const initialState: State = {
  error: null,
  affordableModels: null,
  suggestedModels: null,
  suggestedBudgetIncrease: 10,
  priceRangeLabel: null,
  fullRangeOptions: null,
  searching: true,
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.GET_BUDGET_SEARCH_GROUPS:
      return {
        ...state,
        searching: true,
      };
    case constants.GET_BUDGET_SEARCH_RANGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case constants.GET_BUDGET_SEARCH_GROUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        searching: false,
      };
    case constants.GET_AFFORDABLE_MODEL_FINANCE_SUCCESS: {
      const { group } = action.payload;
      return {
        ...state,
        affordableModels: replaceIfExists(state.affordableModels, group),
      };
    }
    case constants.GET_SUGGESTED_MODEL_FINANCE_SUCCESS: {
      const { group } = action.payload;
      return {
        ...state,
        suggestedModels: replaceIfExists(state.suggestedModels, group),
      };
    }
    default:
      return state;
  }
}

// @flow
import { put, call } from 'redux-saga/effects';
import { actions, constants } from '../actions/pageTemplates';
import { API_BASE_URL_PAGE_TEMPLATES } from '../../constants/apis';
import { get } from '../../helpers/http';
import watch from '../../helpers/watch';

const transformPageTemplates = pageTemplates =>
  pageTemplates.map(pt => ({ value: pt.pageTemplateId, label: pt.name }));

export function* loadPageTemplates(token: string): Generator<*, *, *> {
  try {
    const url = API_BASE_URL_PAGE_TEMPLATES;
    const response = yield call(get, { url, token });
    const pageTemplates = response.data.contents;
    yield put(
      actions.loadPageTemplatesSuccess(transformPageTemplates(pageTemplates)),
    );
  } catch (error) {
    yield put(
      actions.loadPageTemplatesFailure({ message: error.response.data }),
    );
  }
}

export default [watch(constants.LoadPageTemplates, loadPageTemplates, true)];

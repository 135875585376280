// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function IconX({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 16 16" width="1em" height="1em">
        <g fill="#444" fillRule="nonzero">
          <path d="M4.707 12.707l-1.414-1.414 8-8 1.414 1.414z" />
          <path d="M3.293 4.707l1.414-1.414 8 8-1.414 1.414z" />
        </g>
      </svg>
    </IconWrapper>
  );
}

IconX.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import { put, call } from 'redux-saga/effects';
import { actions, constants } from '../actions/externalLink';
import { actions as contextActions } from '../actions/context';
import type {
  SaveLinkAction,
  DeleteLinkAction,
  LoadLinksAction,
} from '../actions/externalLink';
import { post, get, remove } from '../../helpers/http';
import { API_BASE_URL_SITE_LINKS } from '../../constants/apis';
import watch from '../../helpers/watch';
import httpError from '../../helpers/error';

export function* loadLinks(
  token: string,
  action: LoadLinksAction,
): Generator<*, *, *> {
  const { siteId } = action.payload;
  const url = API_BASE_URL_SITE_LINKS(siteId);
  try {
    const response = yield call(get, { url, token });
    const links = response.data.contents;
    yield put(actions.loadLinksSuccess(links));
  } catch (error) {
    yield put(
      actions.loadLinksFailure(httpError(error, 'Failed to load links')),
    );
  }
}

export function* saveLink(
  token: string,
  action: SaveLinkAction,
): Generator<*, *, *> {
  const { payload } = action;
  const url = API_BASE_URL_SITE_LINKS(payload.siteId);
  try {
    const response = yield call(post, { url, token, data: payload.link });
    yield put(actions.saveLinkSuccess(response.data));
    yield put(contextActions.setBreakoutContext(''));
    if (payload.onChange) {
      yield payload.onChange({ ...payload.event, value: response.data.id });
    }
  } catch (error) {
    yield put(actions.saveLinkFailure(httpError(error, 'Failed to save link')));
  }
}

export function* deleteLink(
  token: string,
  action: DeleteLinkAction,
): Generator<*, *, *> {
  const {
    payload: { siteId, link },
  } = action;
  const url = API_BASE_URL_SITE_LINKS(siteId);
  try {
    yield call(remove, { url: `${url}/${link.id}`, token });
    yield put(actions.deleteLinkSuccess(link.id));
    yield put(contextActions.setBreakoutContext(''));
  } catch (error) {
    yield put(
      actions.deleteLinkFailure(httpError(error, 'Failed to delete link')),
    );
  }
}

export default [
  watch(constants.LoadLinks, loadLinks, true),
  watch(constants.SaveLink, saveLink, true),
  watch(constants.DeleteLink, deleteLink, true),
];

// @flow
import { useSelector, useDispatch } from 'react-redux';

import { actions } from '../../state/actions/blogs';
import { actions as siteBuilderActions } from '../../state/actions/siteBuilder';

export default () => {
  const {
    breakout: {
      data: { id },
    },
  } = useSelector(state => state.context);
  const blog = useSelector(state => state.blogs.blogs.find(b => b.id === id));
  const dispatch = useDispatch();

  const {
    config: { title, keywords, description, image },
    visible,
  } = blog;

  const setTitle = newTitle =>
    dispatch(
      actions.updateBlogInContext({
        ...blog,
        config: {
          ...blog.config,
          title: newTitle,
        },
      }),
    );
  const setImage = newImage =>
    dispatch(
      actions.updateBlogInContext({
        ...blog,
        config: {
          ...blog.config,
          image: newImage,
        },
      }),
    );
  const setKeywords = newKeywords =>
    dispatch(
      actions.updateBlogInContext({
        ...blog,
        config: {
          ...blog.config,
          keywords: newKeywords,
        },
      }),
    );
  const setDescription = newDescription =>
    dispatch(
      actions.updateBlogInContext({
        ...blog,
        config: {
          ...blog.config,
          description: newDescription,
        },
      }),
    );
  const setVisibility = newVisibility =>
    dispatch(
      actions.updateBlogInContext({
        ...blog,
        visible: newVisibility,
      }),
    );
  const mediaActions = {
    onUploadMediaSuccess: (resources, type) =>
      dispatch(siteBuilderActions.uploadMediaContentSuccess(resources, type)),
    onUploadMediaFailure: error =>
      dispatch(siteBuilderActions.uploadMediaContentFailure(error)),
    onDeleteMediaItem: (mediaPublicId: string, type: MediaType) =>
      dispatch(siteBuilderActions.deleteMediaContent(mediaPublicId, type)),
  };

  const { media } = useSelector(state => ({
    media: state.siteBuilder.media,
  }));

  return {
    title,
    keywords,
    description,
    blog,
    setVisibility,
    setDescription,
    setKeywords,
    setTitle,
    setImage,
    visible,
    image,
    mediaActions,
    media,
  };
};

// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import ThinRight from '../JaguarIcons/Arrows/ThinRight';
import media from '../../theme';
import { Button, HeadingOne } from '../Global';
import { localiseCurrency } from '../../shared/localisation/numbers';
import FinancePopup from '../FinancePopup';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

export type BudgetRangeResult = {
  id: string,
  model: string,
  count: number,
  minimumPrice: number,
  financePrice: string,
  apr: string,
  imageUrl: string,
  description: string,
};

type Props = {
  result: BudgetRangeResult,
  translations: Object,
  onViewNowClick: (model: string) => void,
  onMoreInfoClick: (model: string) => void,
  preview: boolean,
  placeholdingImage: string,
  globalStyling: Object,
  buttonStyle: string,
  locale: string,
};

const namedDiv = displayName => styled.div.withConfig({ displayName });

const BudgetResultTileItemContainer = namedDiv('BudgetResultTileItemContainer')`
  background-color:white;
  margin-top: 1%;
  ${media.min.large`
    display: flex;
    flex-direction: column;
    width:32%;
    margin-right: 1%;
  `}
`;

const BudgetResultSummaryInformation = namedDiv(
  'BudgetResultSummaryInformation',
)`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding:0 5%;
`;

const ImageContainer = namedDiv('ImageContainer')`
${({ url }) => `${url && `background-image: url(${url})`}`};
height: 236px;
background-repeat:no-repeat;
background-size:cover;
background-position:center;
`;

const FinancialInformationText = namedDiv('FinancialInformationText')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444444;
  font-size: 16px;
  margin: 2% 0;
  font-weight:600;
`;

const Link = styled.div.withConfig({
  displayName: 'Link',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444444;
  height: 43px;
  background-color: transparent;
  font-weight: 600;
  text-decoration: none;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  width: 100%;
  font-size: 13px;
  cursor: pointer;
  font-family: inherit;
  letter-spacing: 0.1em;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 3%;
`;

const SubTitle = styled.div.withConfig({
  displayName: 'SubTitle',
})`
  color: #444444;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 2% 0;
`;

const Body = styled.div.withConfig({
  displayName: 'Body',
})`
  color: #444444;
  font-size: 15px;
  line-height: 21px;
  margin-top: 2%;
  margin-bottom: 5%;
  min-height: 60px;
`;

const AdditionalInfo = styled.div.withConfig({
  displayName: 'AdditionalInfo',
})`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const BottomText = styled.div.withConfig({
  displayName: 'BottomText',
})`
  color: #444444;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-top: 10%;
  margin-bottom: 5%;
`;

const HorizontalLine = styled.hr.withConfig({
  displayName: 'HorizontalLine',
})`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #dedede;
  margin: 0;
  padding: 0;
  justify-content: center;
`;

export default function BudgetResultTile({
  translations,
  result,
  onViewNowClick,
  onMoreInfoClick,
  preview,
  placeholdingImage,
  globalStyling,
  buttonStyle,
  locale,
}: Props) {
  return (
    <BudgetResultTileItemContainer>
      {result.imageUrl ? (
        <ImageContainer url={result.imageUrl} />
      ) : (
        <ImageContainer url={placeholdingImage} />
      )}
      <BudgetResultSummaryInformation>
        <HeadingOne
          styleOverride={() => `
            font-size: 22px;
            margin-top: 5%;
          `}
        >
          {result.model}
        </HeadingOne>
        <SubTitle>
          {translateFromTemplate(
            'priceFrom',
            {
              PRICE: localiseCurrency(result.minimumPrice, locale, 'EUR', 0),
            },
            translations,
          )}
        </SubTitle>
        {result.finance && (
          <div>
            <HorizontalLine />
            <FinancialInformationText>
              {translateFromTemplate(
                'financeFrom',
                {
                  FINANCEPRICE: localiseCurrency(
                    parseFloat(
                      result.financePrice.replace('.', '').replace(',', '.'),
                    ),
                    locale,
                    'EUR',
                  ),
                  APR: result.apr,
                },
                translations,
              )}
              <FinancePopup
                translations={translations}
                data={result.finance}
                locale={locale}
              />
            </FinancialInformationText>
          </div>
        )}
        <HorizontalLine />
        <Body>{result.description}</Body>
        <AdditionalInfo>
          <Link
            onClick={e => {
              if (preview) {
                e.preventDefault();
              } else {
                onMoreInfoClick(result.model);
              }
            }}
          >
            {translations.learnMoreButtonText}
            <ThinRight
              colour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
            />
          </Link>
          <BottomText>
            {translateFromTemplate(
              result.count >= 2 ? 'numberAvailable' : 'numberAvailableSingle',
              {
                COUNT: result.count,
              },
              translations,
            )}
          </BottomText>
          <Button
            onClick={() => onViewNowClick(result.model)}
            text={translations.viewButtonText}
            applyStyle="primary"
            buttonStyle={buttonStyle}
            styleOverride={() => `
              margin-bottom: 20px;
            `}
            mobileStyleOverride={() => `
              margin-bottom: 20px;
              `}
          />
        </AdditionalInfo>
      </BudgetResultSummaryInformation>
    </BudgetResultTileItemContainer>
  );
}

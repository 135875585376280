// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../theme';

type Props = {
  vehicle: Object,
};

const TestDriveVehicleTileContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  ${media.min.large`
    flex-wrap: wrap;
  `};
`;

const Image = styled.img.withConfig({
  displayName: 'Image',
})`
  display: block;
  max-width: 376px;
  max-height: 283px;
  width: 100%;
  ${media.max.large`
    width: 100%;
  `};
`;

const Header = styled.div`
  font-size: 20px;
  margin-top: 32px;
  color: #000;
  font-weight: 600;
`;

const Details = styled.div`
  font-size: 16px;
  margin: 5px 0;
  color: #444444;
  display: flex;
  flex-wrap: wrap;
`;

const Detail = styled.div`
  margin-right: 5px;
`;

const ImageContainer = styled.div.withConfig({
  displayName: 'ImageContainer',
})`
  padding: 5%;
  ${media.max.large`
    padding: 0;
    width: 40%;
  `};
`;

const TextContainer = styled.div`
  padding: 0 5%;
  width: 100%;
  ${media.max.large`
    width: 60%;
    padding-left: 15px;
  `};
`;

export default function TestDriveVehicleTile({ vehicle }: Props) {
  return (
    <TestDriveVehicleTileContainer>
      <ImageContainer>
        {vehicle.images.length > 0 ? (
          <Image src={vehicle.images[0]} />
        ) : (
          <Image src={vehicle.placeholdingImage} />
        )}
      </ImageContainer>
      <TextContainer>
        <Header>{vehicle.name}</Header>
        <Details>
          <Detail>
            {vehicle.specification.odometer &&
              `${vehicle.specification.odometer.display} | `}
          </Detail>
          <Detail>
            {vehicle.specification.engine &&
              `${vehicle.specification.engine} | `}
          </Detail>
          <Detail>
            {vehicle.specification.transmission &&
              `${vehicle.specification.transmission} | `}
          </Detail>
          <Detail>{vehicle.specification.fuelType}</Detail>
        </Details>
      </TextContainer>
    </TestDriveVehicleTileContainer>
  );
}

const mergeHostname = (baseUrl, servicePath) => {
  const url = new URL(baseUrl);
  const path = servicePath.replace(/\//g, '');
  url.hostname = `${path}.${url.host}`;

  return url;
};

const extractNewUrl = (baseUrl, servicePath) => {
  const url = mergeHostname(baseUrl, servicePath);
  return `${url.href}api/`;
};

export const getBaseUrl = state => state.config.settings.baseUrl;

export const getModelGroupsBaseUrl = state => {
  const { useImsLeadsAndGroups } = state.config.settings;
  const hostName = useImsLeadsAndGroups
    ? state.config.settings.imsModelGroupsBaseUrl
    : mergeHostname(getBaseUrl(state), 'model-explore');

  const matrixPath = state.config.settings.modelGroupsPath;

  return `${hostName}${matrixPath}/`;
};

const imsLeadsHostname = state =>
  state.config.settings.apac
    ? state.config.settings.imsApacLeadsHostName
    : state.config.settings.imsLeadsHostName;

const leadsUrl = (state, baseUrl) => {
  const { leadsPath } = state.config.settings;
  return `${baseUrl}${leadsPath}`;
};

export const getLeadsUrl = state => {
  const { useImsLeadsAndGroups } = state.config.settings;
  return leadsUrl(
    state,
    useImsLeadsAndGroups
      ? imsLeadsHostname(state)
      : extractNewUrl(getBaseUrl(state), 'leads'),
  );
};

export const getPlaceholderBrandImageUrl = state =>
  state.config.config.global.globalStyling.placeholderBrandImageUrl;
export const getLocale = state => state.config.settings.locale;
export const getMarket = state => state.config.config.global.inventory.market;
export const getRegion = state => state.config.config.global.inventory.region;
export const getSearchMarket = state => state.shared.sessionPreferences.market;
export const getCountry = state => state.config.config.global.inventory.country;
export const getMake = state => state.config.config.global.inventory.make;
export const getTerritory = state =>
  state.config.config.global.inventory.territory;
export const getSiteId = state => state.config.settings.siteId;
export const getInventoryStatus = state =>
  state.config.config.global.inventory.inventoryStatus;
export const getAccessoryStatus = state =>
  state.config.config.global.inventory.displayAccessories;
export const getImageSize = state =>
  state.config.config.global.inventory.imageSize;
export const getFinanceStatus = state =>
  state.config.config.global.featureFlags &&
  state.config.config.global.featureFlags.finance;
export const getGlobalFinance = state => state.config.config.global.finance;
export const getMarketLevelDealer = state =>
  state.config.config.global.inventory.marketLevelDealerId;
export const getCurrencyCode = state =>
  state.config.config.global.inventory.currencyCode;
export const getSearchCountryCode = state =>
  state.config.config.global.inventory.searchCountryCode;
export const getInventorySimilarVehicles = state =>
  state.config.config.global.inventory.similarVehicles;

export const usesGoogleAnalytics = state =>
  !!(
    state.config.config.global.analytics &&
    state.config.config.global.analytics.googleAnalyticsTrackingId
  );

export const vehicleValuationServicePath = () => 'vehicle-valuations/';
export const vehicleValuationServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), vehicleValuationServicePath(state));

export const vehiclesShortlistServicePath = state =>
  state.config.settings.vehiclesShortlistServicePath;
export const vehiclesShortListServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), vehiclesShortlistServicePath(state));

export const testDrivesServicePath = state =>
  state.config.settings.testDrivesServicePath;
export const testDrivesServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), testDrivesServicePath(state));

export const inventorySearchServicePath = state =>
  state.config.settings.inventorySearchServicePath;

export const inventorySearchServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), inventorySearchServicePath(state));

export const myDealsServicePath = state =>
  state.config.settings.myDealsServicePath;
export const myDealsServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), myDealsServicePath(state));

export const avlCustomersServicePath = state =>
  state.config.settings.avlCustomersServicePath;
export const avlCustomersServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), avlCustomersServicePath(state));

export const authenticationCoreServicePath = state =>
  state.config.settings.authenticationCoreServicePath;
export const authenticationCoreServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), authenticationCoreServicePath(state));

export const appendLocaleQueryString = state =>
  state.config.settings.appendLocaleQueryString;
export const pdfGeneratorServicePath = state =>
  state.config.settings.pdfGeneratorServicePath;
export const pdfGeneratorServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), pdfGeneratorServicePath(state));

export const currencyConversionServicePath = state =>
  state.config.settings.currencyConversionServicePath;
export const currencyConversionServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), currencyConversionServicePath(state));

export const postsServicePath = state => state.config.settings.postsServicePath;
export const postsServiceUrl = state =>
  extractNewUrl(getBaseUrl(state), postsServicePath(state));

export const getAVLConfig = state => ({
  locale: getLocale(state),
  market: getMarket(state),
  country: getCountry(state),
  make: getMake(state),
  siteId: getSiteId(state),
  currencyCode: getCurrencyCode(state),
  region: getRegion(state),
  searchCountryCode: getSearchCountryCode(state),
  territory: getTerritory(state),
});

// @flow
import React from 'react';

import type { Node } from 'react';

type Props = {
  children: ({ open: boolean, openClose: () => void }) => Node,
  onOpenClosed?: (open: boolean) => void,
};

export default class OpenClose extends React.Component<Props, *> {
  static defaultProps = {
    onOpenClosed: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  setOpen = () =>
    this.setState(
      () => ({ open: true }),
      () => this.props.onOpenClosed && this.props.onOpenClosed(true),
    );

  setClosed = () =>
    this.setState(
      () => ({ open: false }),
      () => this.props.onOpenClosed && this.props.onOpenClosed(false),
    );

  openClose = () =>
    this.setState(
      ({ open }) => ({ open: !open }),
      () => this.props.onOpenClosed && this.props.onOpenClosed(this.state.open),
    );

  render() {
    return this.props.children({
      open: this.state.open,
      openClose: this.openClose,
      setOpen: this.setOpen,
      setClosed: this.setClosed,
    });
  }
}

// @flow
const engineSizeValueUp = engineSize => `${engineSize.replace(/\D/g, '')}00`;
export const engineSizeValueDown = engineSize =>
  `${engineSize.toString()[0]}.${engineSize.toString()[1]}L`;

export const generateCheckboxFilterUrl = (selectedCheckboxFilters: {}) => {
  const keys = Object.keys(selectedCheckboxFilters);
  return keys
    .map(key =>
      selectedCheckboxFilters[key]
        .map(
          filter =>
            `${key}=${
              key === 'engineSizes' ? engineSizeValueUp(filter) : filter
            }&`,
        )
        .join(''),
    )
    .join('');
};

export const rangePayloads = {
  mileageRange: ['MinMileage', 'MaxMileage'],
  yearRange: ['MinYear', 'MaxYear'],
  priceRange: ['MinPrice', 'MaxPrice'],
  monthlyPaymentRange: ['MinMonthlyPayment', 'MaxMonthlyPayment'],
};

export const generateRangeUrl = (rangeFilters: {}) => {
  const keys = Object.keys(rangeFilters);
  return keys
    .filter(key => rangeFilters[key].active)
    .map(key =>
      rangePayloads[key]
        .map(
          (filterName, index) =>
            `${filterName}=${
              !index ? rangeFilters[key].min : rangeFilters[key].max
            }&`,
        )
        .join(''),
    )
    .join('');
};

export const generateLocationUrl = (location: {
  lat?: number,
  long?: number,
}) => {
  const { lat, long } = location;
  return lat && long ? `Latitude=${lat}&Longitude=${long}&` : '';
};

export const generateMaxDistanceUrl = (maxDistance: string) =>
  maxDistance && maxDistance !== 'all' ? `MaxDistance=${maxDistance}&` : '';

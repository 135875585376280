// @flow
import React from 'react';
import styled from 'styled-components';
import IoChevronLeft from 'react-icons/lib/io/chevron-left';

import TestDriveDateAndTime from './TestDriveDateAndTime';
import TestDriveLogin from './TestDriveLogin';
import TestDriveUserDetails from './TestDriveUserDetails';
import TestDriveSummary from './TestDriveSummary';

import type {
  SelectDateMode,
  SelectTimeMode,
} from '../modules/TestDrive/reducer';

const Container = styled.div.withConfig({
  displayName: 'TestDriveContainer',
})`
  width: 100%;
  height: auto;
`;

const HeaderBar = styled.div`
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #d8d8d8;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const HeaderWrapper = styled.div.withConfig({
  displayName: 'HeaderWrapper',
})`
  width: 100%;
  margin-left: ${props => (props.hidden ? 'none' : '-40px')};
`;

const TestDriveHeader = styled.div.withConfig({
  displayName: 'TestDriveHeader',
})`
  font-family: ${props => props.font && props.font.value};
  font-size: 20px;
  margin: 10px 0;
  color: #000;
`;

const TestDriveSubHeader = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: #474747;
`;

const BackButton = styled.div.withConfig({
  displayName: 'BackButton',
})`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`;

const SubHeaderDictionary = {
  DateAndTime: ({ translations }) => translations.testDriveSubHeaderDateAndTime,
  Login: ({ translations }) => translations.testDriveSubHeaderLogin,
  UserDetails: ({ translations }) => translations.testDriveSubHeaderUserDetails,
  Summary: ({ translations }) => translations.testDriveSubHeaderSummary,
};

type Props = {
  translations: Object,
  context: 'DateAndTime' | 'Login' | 'UserDetails' | 'Summary',
  setBookingFormValue: (key: string, value: string) => void,
  confirmDateAndTime: Function,
  sendTestDriveLead: Function,
  open: boolean,
  bookingForm: Object,
  testDriveConfig: Object,
  selectDateModeOptions: SelectDateMode[],
  selectDateMode: SelectDateMode,
  selectTimeModeOptions: SelectTimeMode[],
  selectTimeMode: SelectTimeMode,
  setSelectRadioGroupMode: (key: string, value: string) => void,
  disableCalendar: boolean,
  selectedDateMode: string,
  selectedTimeMode: string,
  interestedClicked: () => void,
  loginAndConfirm: (loginDetails: Object) => void,
  loginFailed: 'VALIDATION REQUIRED' | 'UNAUTHORISED',
  userIsLoggedIn: boolean,
  somethingWentWrong: boolean,
  userCreated: boolean,
  newUserEmail: string,
  onVisitUs: (retailerInformation: Object) => void,
  navigateToVdp: () => void,
  globalStyling: Object,
};

const getOnClick = props => {
  if (props.context === 'DateAndTime') return () => props.navigateToVdp();
  if (props.context === 'Login') return () => props.goBack('DateAndTime');
  if (props.context === 'UserDetails') return () => props.goBack('Login');
  return () => {};
};

const contextRenderer = {
  DateAndTime: (props: Props) => (
    <TestDriveDateAndTime
      translations={props.translations}
      open={props.open}
      bookingForm={props.bookingForm}
      bookingFormError={props.bookingFormError}
      selected={props.selected}
      onSelectDate={props.setBookingFormValue}
      toggleCalendar={props.toggleCalendar}
      testDriveConfig={props.testDriveConfig}
      selectDateModeOptions={props.selectDateModeOptions}
      setSelectRadioGroupMode={props.setSelectRadioGroupMode}
      selectTimeModeOptions={props.selectTimeModeOptions}
      disableCalendar={props.disableCalendar}
      confirmDateAndTime={props.confirmDateAndTime}
      sendTestDriveLead={props.sendTestDriveLead}
      selectedDateMode={props.selectedDateMode}
      selectedTimeMode={props.selectedTimeMode}
      interestedClicked={props.interestedClicked}
      userIsLoggedIn={props.userIsLoggedIn}
      somethingWentWrong={props.somethingWentWrong}
      globalStyling={props.globalStyling}
    />
  ),
  Login: props => (
    <TestDriveLogin
      translations={props.translations}
      onChange={props.setBookingFormValue}
      loginAndConfirm={props.loginAndConfirm}
      continueAsGuest={props.continueAsGuest}
      bookingForm={props.bookingForm}
      bookingFormError={props.bookingFormError}
      loginFailed={props.loginFailed}
      globalStyling={props.globalStyling}
    />
  ),
  UserDetails: props => (
    <TestDriveUserDetails
      translations={props.translations}
      bookingForm={props.bookingForm}
      bookingFormError={props.bookingFormError}
      onChange={props.setBookingFormValue}
      onTitleChange={props.onTitleChange}
      confirmRequest={props.confirmRequest}
      testDriveConfig={props.testDriveConfig}
      goBack={props.goBack}
      globalStyling={props.globalStyling}
    />
  ),
  Summary: props => (
    <TestDriveSummary
      translations={props.translations}
      bookingForm={props.bookingForm}
      bookingFormError={props.bookingFormError}
      testDriveConfig={props.testDriveConfig}
      contactUs={props.contactUs}
      userIsLoggedIn={props.userIsLoggedIn}
      userCreated={props.userCreated}
      newUserEmail={props.newUserEmail}
      goToMyAccount={props.goToMyAccount}
      onVisitUs={props.onVisitUs}
      globalStyling={props.globalStyling}
      navigateToVdp={props.navigateToVdp}
    />
  ),
};

export default function TestDrive(props: Props) {
  return (
    <Container>
      <HeaderBar>
        <div style={{ width: '40px', zIndex: '10' }}>
          <BackButton
            onClick={getOnClick(props)}
            hidden={['Summary'].includes(props.context)}
          >
            <IoChevronLeft />
          </BackButton>
        </div>
        <HeaderWrapper>
          <TestDriveHeader font={props.globalStyling.fonts.primaryFont}>
            {props.translations.testDriveHeader}
          </TestDriveHeader>
          <TestDriveSubHeader>
            {SubHeaderDictionary[props.context](props)}
          </TestDriveSubHeader>
        </HeaderWrapper>
      </HeaderBar>
      {contextRenderer[props.context](props)}
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import UtilityBar from '../../../../components/UtilityBar';
import ContentWrapper from '../../../../components/ContentWrapper';
import MenuBar from '../../../../components/MenuBar';
import CurrencyPopup from '../../../../components/CurrencyPopup';

import media from '../../../../theme';

const DesktopContainer = styled.div`
  position: ${({ stickyHeader }) => (stickyHeader ? 'fixed' : 'static')};
  width: 100%;
  z-index: 80;
`;

const DesktopMenu = styled.div`
  display: flex;
  width: 100%;
  ${media.max.large`
    display: none;
  `};
`;

const MobileMenu = styled.div`
  display: none;
  ${media.max.large`
  display: flex;
  align-self: flex-end;
  `};
`;

const UtilityBarContainer = styled.div`
  display: flex;
  ${({ height }) => `${height && `height: ${height}px`}`};
  ${({ backgroundColour }) =>
    `${backgroundColour && `background-color: ${backgroundColour.value}`}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${media.max.large`
    display: none;
  `};
`;

const SectionWithMidLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ backgroundColour }) =>
    `${backgroundColour && `background-color: ${backgroundColour.value}`}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
`;

const BrandLogo = styled.img`
  ${({ source }) => source && 'cursor: pointer;'};
  height: 62px;
  width: 35px;
`;

const CenterLogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  ${({ height }) => `${height && `height: ${height}px`}`};
`;

const SliceMenuItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SliceMenuItems = styled.div`
  padding: 0 8px;
  width: auto;
`;

const Container = styled.div`
  @media print {
    display: none;
  }
`;

type Props = {
  config: Object,
  globalStyling: Object,
  links: Object[],
  socialShare: Object,
  navigateOnClick: Function,
  navigateIfInternal: (link: string) => boolean,
  filteredOptions: Object,
  utilityBarActions: Function,
  utilityBarInitialValues: Function,
  currentLanguage: string,
  currentCurrency: string,
  shared: Object,
  disableCurrencyPopup: () => void,
};

const MenuItems = ({ config, links, navigateIfInternal }: Props) => (
  <SliceMenuItems>
    <MenuBar
      height={config.menuBarHeight}
      maxWidth={config.itemContainerMaxWidth}
      theme={{
        font: config.menuBarLinkFont,
        hoverColour: config.menuBarLinkHoverColour,
        backgroundHoverColour: config.menuBarLinkBgHoverColour,
      }}
      items={links}
      backgroundColour={config.menuBarBGColour && config.menuBarBGColour.value}
      navigateIfInternal={navigateIfInternal}
      mobileMenuIconsColour={
        config.mobileMenuIconsColour && config.mobileMenuIconsColour.value
      }
    />
  </SliceMenuItems>
);

export default function TemplateThree({
  config,
  globalStyling,
  links,
  navigateOnClick,
  navigateIfInternal,
  filteredOptions,
  utilityBarActions,
  utilityBarInitialValues,
  socialShare,
  currentLanguage,
  currentCurrency,
  disableCurrencyPopup,
  shared,
}: Props) {
  return (
    <Container>
      <CurrencyPopup
        translations={config.translations}
        globalStyling={globalStyling}
        disableCurrencyPopup={disableCurrencyPopup}
        shared={shared}
      />
      <DesktopContainer stickyHeader={config.stickyHeader}>
        <UtilityBarContainer
          height={config.utilityBarHeight}
          backgroundColour={config.utilityBarBGColour}
          borderBottom={config.utilityBarBorderBottom}
        >
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <UtilityBar
              config={config}
              theme={{
                link: {
                  font: config.utilityBarLinkFont,
                },
                dropdown: {
                  font: config.utilityBarDropdownFont,
                  backgroundColour: config.utilityBarDropdownColour,
                  dropdownIconSize: config.languageDropdownIconSize,
                },
              }}
              utilityBarOptions={filteredOptions}
              dropDownActions={utilityBarActions}
              initialValues={utilityBarInitialValues}
              socialShare={socialShare}
            />
          </ContentWrapper>
        </UtilityBarContainer>
        <SectionWithMidLogoContainer
          backgroundColour={config.menuBarBGColour}
          borderBottom={config.menuBarBorderBottom}
        >
          <CenterLogoContainer height={config.menuBarHeight}>
            <BrandLogo
              source={config.brandLogoLink}
              onClick={navigateOnClick}
              src={config.brandLogoUrl}
              alt="rolls-royce"
            />
          </CenterLogoContainer>
          <DesktopMenu>
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              <SliceMenuItemsContainer>
                <MenuItems
                  config={config}
                  links={links.slice(0, links.length / 2)}
                  navigateIfInternal={navigateIfInternal}
                />
                <MenuItems
                  config={config}
                  links={links.slice(links.length / 2, links.length)}
                  navigateIfInternal={navigateIfInternal}
                />
              </SliceMenuItemsContainer>
            </ContentWrapper>
          </DesktopMenu>
          <MobileMenu>
            <MenuBar
              theme={{
                font: config.menuBarLinkFont,
                hoverColour: config.menuBarLinkHoverColour,
                backgroundHoverColour: config.menuBarLinkBgHoverColour,
                underLineColor: config.menuBarUnderlineColor,
              }}
              items={links}
              height={config.menuBarHeight}
              backgroundColour={config.menuBarBGColour.value}
              navigateIfInternal={navigateIfInternal}
              withSearch={config.showSearchBar}
              searchBarAlign={config.searchBarAlign}
              searchIconAlign={config.searchIconAlign}
              mobileMenuIconsColour={config.mobileMenuIconsColour.value}
              burgerMenuItemBorder={config.burgerMenuItemBorder}
              socialShareEnabled={config.socialShareEnabled}
              utilityBarLanguageEnabled={config.utilityBarLanguageEnabled}
              utilityBarCurrencyEnabled={config.utilityBarCurrencyEnabled}
              socialIcon={config.socialIcon}
              languageIcon={config.languageIcon}
              currencyIcon={config.currencyIcon}
              translations={config.translations}
              socialShareProps={socialShare}
              utilityBarOptions={filteredOptions}
              languageActions={utilityBarActions.language}
              currencyActions={utilityBarActions.currency}
              burgerMenuLanguageFont={config.burgerMenuLanguageFont}
              languageChevron={config.burgerMenuLanguageChevron}
              hideChevronOnMobile={config.hideChevronOnMobile}
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              config={config}
            />
          </MobileMenu>
        </SectionWithMidLogoContainer>
      </DesktopContainer>
    </Container>
  );
}

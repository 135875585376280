// @flow
/* eslint-env browser */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';

import { InputField, SelectField } from './FormFields';
import Button from './Global/Button';
import CheckBox from './CheckBox';
import media from '../theme';
import { titleOptions } from '../helpers/formTitleOptions';
import PersonalDataConsent from './PersonalDataConsent';

const Container = styled.div.withConfig({
  displayName: 'TestDriveLoginContainer',
})`
  height: auto;
  text-align: center;
  margin: 20px auto 80px auto;
  max-width: 430px;
  ${media.max.large`
    width: 90%;
    padding: 0 5%;
  `};
`;

const LineBreak = styled.div`
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
`;

const Block = styled.div`
  margin-bottom: 20px;
`;

const Heading = styled.span`
  font-size: 20px;
`;

const DoubleRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.max.large`
    display: none;
  `};
`;
const MobileSingleRow = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

const PasswordAccountCreation = styled.div`
  background-color: #f3f3f3;
  border: 1px solid #d8d8d8;
  padding: 20px;
`;
const Link = styled.a`
  padding-left: 10px;
  text-decoration: underline;
  color: ${({ colour }) => colour};
`;

type Props = {
  translations: Object,
  confirmRequest: Function,
  onChange: (
    keyValue: string,
    value: string,
    translations: Object,
    validate?: boolean,
  ) => void,
  bookingForm: Object,
  bookingFormError: Object,
  testDriveConfig: Object,
  onTitleChange: Function,
  goBack: (str: string) => void,
  globalStyling: Object,
};

export default function UserDetails(props: Props) {
  const {
    globalStyling: { colours },
  } = props;
  const primaryBrandColour =
    colours.primaryBrandColour && colours.primaryBrandColour.value;
  return (
    <Container>
      <Block>
        <Heading>{props.translations.testDriveUserDetailsHeader}</Heading>
      </Block>
      <DoubleRow>
        <SelectField
          keyValue="title"
          value={props.bookingForm.title || ''}
          onChange={props.onTitleChange}
          label={props.translations.testDriveUserDetailsTitle}
          options={titleOptions(props.translations)}
          error={
            props.bookingFormError.title && props.bookingFormError.title[0]
          }
          doubleRow
          required
        />
      </DoubleRow>
      <MobileSingleRow>
        <SelectField
          keyValue="title"
          value={props.bookingForm.title || ''}
          onChange={props.onTitleChange}
          label={props.translations.testDriveUserDetailsTitle}
          options={titleOptions(props.translations)}
          error={
            props.bookingFormError.title && props.bookingFormError.title[0]
          }
          required
        />
      </MobileSingleRow>
      <DoubleRow>
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsFirstName}
          value={props.bookingForm.firstname || ''}
          onChange={props.onChange}
          keyValue="firstname"
          error={
            props.bookingFormError.firstname &&
            props.bookingFormError.firstname[0]
          }
          translations={props.translations}
          doubleRow
          required
          validate
        />
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsLastName}
          value={props.bookingForm.lastname || ''}
          onChange={props.onChange}
          keyValue="lastname"
          error={
            props.bookingFormError.lastname &&
            props.bookingFormError.lastname[0]
          }
          translations={props.translations}
          doubleRow
          required
          validate
        />
      </DoubleRow>
      <MobileSingleRow>
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsFirstName}
          value={props.bookingForm.firstname || ''}
          onChange={props.onChange}
          error={
            props.bookingFormError.firstname &&
            props.bookingFormError.firstname[0]
          }
          translations={props.translations}
          keyValue="firstname"
          required
          validate
        />
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsLastName}
          value={props.bookingForm.lastname || ''}
          onChange={props.onChange}
          error={
            props.bookingFormError.lastname &&
            props.bookingFormError.lastname[0]
          }
          translations={props.translations}
          keyValue="lastname"
          required
          validate
        />
      </MobileSingleRow>
      <InputField
        type="text"
        label={props.translations.testDriveUserDetailsEmail}
        value={props.bookingForm.email || ''}
        onChange={props.onChange}
        keyValue="email"
        error={props.bookingFormError.email && props.bookingFormError.email[0]}
        translations={props.translations}
        required
        validate
      />
      <InputField
        type="text"
        label={props.translations.testDriveUserDetailsEmailConfirm}
        value={props.bookingForm.emailConfirm || ''}
        onChange={props.onChange}
        keyValue="emailConfirm"
        error={
          props.bookingFormError.emailConfirm &&
          props.bookingFormError.emailConfirm[0]
        }
        translations={props.translations}
        required
        validate
      />
      <DoubleRow>
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsPhone}
          value={props.bookingForm.phone || ''}
          onChange={props.onChange}
          keyValue="phone"
          error={
            props.bookingFormError.phone && props.bookingFormError.phone[0]
          }
          translations={props.translations}
          doubleRow
          required
          validate
        />
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsPostcode}
          value={props.bookingForm.postcode || ''}
          onChange={props.onChange}
          keyValue="postcode"
          error={
            props.bookingFormError.postcode &&
            props.bookingFormError.postcode[0]
          }
          translations={props.translations}
          doubleRow
          required
          validate
        />
      </DoubleRow>
      <MobileSingleRow>
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsPhone}
          value={props.bookingForm.phone || ''}
          onChange={props.onChange}
          error={
            props.bookingFormError.phone && props.bookingFormError.phone[0]
          }
          translations={props.translations}
          keyValue="phone"
          required
          validate
        />
        <InputField
          type="text"
          label={props.translations.testDriveUserDetailsPostcode}
          value={props.bookingForm.postcode || ''}
          onChange={props.onChange}
          error={
            props.bookingFormError.postcode &&
            props.bookingFormError.postcode[0]
          }
          translations={props.translations}
          keyValue="postcode"
          required
          validate
        />
      </MobileSingleRow>
      <LineBreak />
      <InputField
        type="text"
        label={props.translations.testDriveUserDetailsVehicleInterest}
        value={props.testDriveConfig.name || ''}
        translations={props.translations}
        keyValue="interestedIn"
        disabled
      />
      <InputField
        type="text"
        label={props.translations.testDriveUserDetailsCurrentVehicle}
        value={props.bookingForm.currentVehicle || ''}
        onChange={props.onChange}
        keyValue="currentVehicle"
        placeholder={
          props.translations.testDriveUserDetailsCurrentVehiclePlaceholder
        }
        translations={props.translations}
      />
      <Block>
        <PasswordAccountCreation>
          <Heading>
            {props.translations.testDriveUserDetailsCreateAccount}
          </Heading>
          <div style={{ fontSize: '14px', margin: '10px 0', color: '#444' }}>
            {props.translations.testDriveUserDetailsCreatePassword}
          </div>
          <InputField
            type="password"
            value={props.bookingForm.accountPassword || ''}
            onChange={props.onChange}
            keyValue="accountPassword"
            placeholder={props.translations.testDriveLoginPasswordLabel}
            translations={props.translations}
            error={
              props.bookingFormError.accountPassword &&
              props.bookingFormError.accountPassword[0]
            }
            validate
          />
          <InputField
            type="password"
            value={props.bookingForm.accountPasswordConfirm || ''}
            onChange={props.onChange}
            keyValue="accountPasswordConfirm"
            placeholder={
              props.translations.testDriveUserDetailsConfirmPwdPlaceholder
            }
            translations={props.translations}
            error={
              props.bookingFormError.accountPasswordConfirm &&
              props.bookingFormError.accountPasswordConfirm[0]
            }
            validate
          />
        </PasswordAccountCreation>
      </Block>
      <div style={{ textAlign: 'left' }}>
        <PersonalDataConsent
          translations={props.translations}
          onCheckBoxClick={(formKey: string, value: any) =>
            props.onChange(formKey, value, props.translations)
          }
          phoneChecked={props.bookingForm.phoneConsent}
          phoneKey="phoneConsent"
          emailChecked={props.bookingForm.emailConsent}
          emailKey="emailConsent"
          smsChecked={props.bookingForm.smsConsent}
          smsKey="smsConsent"
          globalStyling={props.globalStyling}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckBox
            checked={props.bookingForm.termsAndConditionsAccept}
            onClick={() =>
              props.onChange(
                'termsAndConditionsAccept',
                !props.bookingForm.termsAndConditionsAccept,
                props.translations,
              )
            }
          />
          <Link colour={primaryBrandColour}>
            {props.translations.testDriveUserDetailsTermsAndConditionsAccept}
          </Link>
        </div>
        <div
          style={{
            color: primaryBrandColour,
            textAlign: 'left',
          }}
        >
          {props.bookingFormError.termsAndConditionsNotChecked &&
            props.bookingFormError.termsAndConditionsNotChecked[0]}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Button
          applyStyle="secondary"
          buttonStyle={
            props.globalStyling.uiElements.secondaryButton &&
            props.globalStyling.uiElements.secondaryButton.buttonStyle
          }
          styleOverride={() => 'width: 30%; margin-right: 10%; '}
          text={props.translations.testDriveGoBack}
          onClick={() => props.goBack('Login')}
        />
        <Button
          applyStyle="primary"
          buttonStyle={
            props.globalStyling.uiElements.primaryButton &&
            props.globalStyling.uiElements.primaryButton.buttonStyle
          }
          text={props.translations.testdriveUserDetailsConfirmRequest}
          onClick={() => props.confirmRequest(props)}
          styleOverride={() => 'width: 60%; '}
        />
      </div>
    </Container>
  );
}

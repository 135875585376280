import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions as sessionActions } from '../../shared/sessionPreferences';

export default function useCookiePolicy() {
  const [domEl, setDomEl] = useState(null);
  const [isVisible, setVisible] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const el = document.getElementById('header');

    if (el) {
      setDomEl(el);
    }
  }, []);

  useEffect(() => {
    if (isVisible === false) {
      dispatch(
        sessionActions.updateSessionPreferences('cookiesAccepted', true),
      );
    }
  }, [isVisible]);

  return {
    domEl,
    isVisible,
    setVisible,
  };
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import FinanceContainer from '../../containers/FinanceContainer';
import ModelSummary from '../../components/ModelSummary/ModelSummary';
import RepresentativeExample from '../../components/RepresentativeExample';
import PathnameSubNavBar from '../../components/PathnameSubNavBar';
import { actions as routerActions } from '../../actions/router';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { actions as filterActions } from '../../shared/filters';
import { localiseCurrency } from '../../shared/localisation/numbers';
import {
  HeadingOne,
  HeadingTwo,
  fontStyleOverride,
} from '../../components/Global';
import FinancePopup from '../../components/FinancePopup';
import { formatUrl } from '../../shared/routing';
import ContentWrapper from '../../components/ContentWrapper';
import { translateFinanceFromTemplate } from '../../helpers/finance';

import media from '../../theme';

const TopImageText = styled.div`
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
`;

const RepresentativeExampleContainer = styled.div.withConfig({
  displayName: 'RepresentativeExampleContainer',
})`
  ${media.min.large`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  `};
`;

const TopImageContainer = styled.div.withConfig({
  displayName: 'TopImageContainer',
})`
  ${({ url }) => `${url && `background-image: url(${url})`}`};
  height: 400px;
  max-height: 700px;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  ${media.max.large`
    display: none;
  `};
`;

type Props = {
  config: Object,
  shared: Object,
  history: Object,
  actions: {
    init: Function,
    getModelGroups: ({}) => void,
    getModelGroupsImages: ({}) => void,
  },
  dispatch: Function,
  preview: boolean,
  state: Object,
  pathByModule: Function,
  globalStyling: Object,
  marketInfo: Object,
  featureFlags: Object,
};

const renderModelFinance = (
  from: string,
  summary: string,
  finance: Object,
  translations: Object,
  locale: string,
) => () => (
  <div>
    <HeadingTwo
      styleOverride={() => `
        font-size: 20px;
      `}
    >
      {from}
    </HeadingTwo>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <HeadingTwo
        styleOverride={() => `
        font-size: 20px;
      `}
      >
        {summary}
      </HeadingTwo>
      {finance && (
        <FinancePopup
          data={finance}
          translations={translations}
          locale={locale}
        />
      )}
    </div>
  </div>
);

export default class ExploreRange extends Component<Props, *> {
  componentDidMount() {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const { init, getModelGroups } = this.props.actions;
    init();
    getModelGroups();
  }

  render() {
    const {
      preview,
      dispatch,
      shared,
      state,
      pathByModule,
      globalStyling,
      config,
      marketInfo,
      featureFlags,
    } = this.props;
    const { translations, previewBannerUrl } = this.props.config;
    const representativeExample = preview
      ? {}
      : shared.financeCalculator.representativeExample;
    const vehicles = state.modelGroups;
    const lowerCaseLinks = config.modelLinks.map(ml => ml.toLowerCase());
    const modelDictionary = config.modelLinks.map(ml => ({
      key: ml.toLowerCase(),
      value: ml,
    }));
    const links = (vehicles || [])
      .filter(model => lowerCaseLinks.includes(model.model.toLowerCase()))
      .map(({ model, modelDisplay }) => ({
        text: modelDisplay || model,
        onClick: () =>
          dispatch(routerActions.navigate(formatUrl(`/range/${model}`))),
        availableOnMobile: true,
        path: formatUrl(`range/${model}`),
      }));

    const searchLink = {
      text: translations.searchRangeLabel,
      onClick: () => dispatch(routerActions.navigate('search')),
      availableOnMobile: true,
    };

    const goToModule = name =>
      dispatch(routerActions.navigate(pathByModule(name)));

    const {
      config: { market, make },
    } = this.props;

    const searchClick = (modelRange: string) => {
      if (config.showLamboCTAs) {
        dispatch(filterActions.resetFilters());

        dispatch(
          routerActions.navigateWithQuery('searchresults', {
            model: modelRange,
          }),
        );
      } else {
        dispatch(searchFilterActions.resetFilters());
        dispatch(
          searchFilterActions.filterByModelRange({
            section: 'models',
            filter: modelRange,
          }),
        );
        dispatch(
          searchFilterActions.getSearchFilters({
            market,
            make,
            filters: `models=${modelRange}`,
          }),
        );
        goToModule('SearchResults');
      }
    };

    const findOutMoreUrl = vehicle =>
      vehicle.externalUrl || formatUrl(`/range/${vehicle.model}`);
    return vehicles ? (
      <div>
        {config.hideSubNav ? null : (
          <PathnameSubNavBar
            links={[...links, searchLink]}
            title="Explore Range"
            exploreRangeBackgroundColor={
              globalStyling.colours.primaryBrandColour &&
              globalStyling.colours.primaryBrandColour.value
            }
            exploreRangeForegroundColour={config.exploreRangeForegroundColour}
            subNavBGColour={config.subNavBGColour}
            subNavBorderColour={config.subNavBorderColour}
            subNavFontTheme={config.subNavFont}
            isExploreRange
            subNavBarItemSelectedBackgroundColour={
              config.subNavBarItemSelectedBackgroundColour
            }
            subNavBarItemSelectedFontColour={
              config.subNavBarItemSelectedFontColour
            }
            contentWidth={globalStyling.contentWidth}
          />
        )}
        {featureFlags.finance && config.showRepresentativeExample && (
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <RepresentativeExampleContainer>
              <FinanceContainer
                component={props => (
                  <RepresentativeExample
                    {...props}
                    representativeExample={representativeExample}
                    preview={preview}
                  />
                )}
              />
            </RepresentativeExampleContainer>
          </ContentWrapper>
        )}
        <TopImageContainer url={previewBannerUrl}>
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <TopImageText>
              <HeadingOne
                styleOverride={() => `
                  margin: 0;
                  ${fontStyleOverride(config.heroHeadingFont)}
                `}
              >
                {translations.title}
              </HeadingOne>
              {translations.subTitle && (
                <HeadingTwo
                  styleOverride={() => `
                    box-sizing: border-box;
                    border-top: 1px solid #FFFFFF;
                    max-width: 500px;
                    ${fontStyleOverride(config.heroSubheadingFont)}
                  `}
                >
                  {translations.subTitle}
                </HeadingTwo>
              )}
            </TopImageText>
          </ContentWrapper>
        </TopImageContainer>
        <ContentWrapper contentWidth={globalStyling.contentWidth}>
          {vehicles
            .filter(model =>
              config.modelLinks
                .map(ml => ml.toLowerCase())
                .includes(model.model.toLowerCase()),
            )
            .map((vehicle, index) => (
              <ModelSummary
                title={
                  vehicle.modelDisplay ||
                  modelDictionary.find(
                    ml => ml.key === vehicle.model.toLowerCase(),
                  ).value
                }
                key={vehicle.model}
                {...vehicle}
                findOutMore={() =>
                  dispatch(routerActions.navigate(findOutMoreUrl(vehicle)))
                }
                modelFinanceComponent={
                  vehicle.isModelAvailable && featureFlags.finance
                    ? renderModelFinance(
                        `${translations.fromText} ${localiseCurrency(
                          vehicle.priceFrom,
                          'es-ES',
                          'EUR',
                          0,
                        )}`,
                        vehicle.financePrice &&
                          translateFinanceFromTemplate(
                            translations.financeSummary,
                            vehicle.finance,
                            marketInfo.locale,
                          ),
                      )
                    : () => null
                }
                financeButtonText={translations.financeButtonText}
                searchButtonText={translations.searchButtonText}
                availableText={`${
                  vehicle.isModelAvailable
                    ? `${vehicle.availability} ${
                        vehicle.availability >= 2
                          ? translations.availableText
                          : translations.availableTextSingle
                      }`
                    : `${translations.noAvailableText}`
                }`}
                findOutMoreText={translations.findOutMoreText}
                disclaimerText={translations.disclaimerText}
                alignment={index % 2 === 0 ? 'row-reverse' : 'row'}
                searchButtonOnClick={() =>
                  searchClick(vehicle.modelDisplay || vehicle.model)
                }
                showSearchButton={vehicle.isModelAvailable}
                financeButtonOnClick={() =>
                  dispatch(routerActions.navigate('financeoptions'))
                }
                globalStyling={globalStyling}
                modelSummaryHeaderFont={config.modelSummaryHeaderFont}
                lamboDescriptionStyling={config.lamboDescriptionStyling}
                showLamboCTAs={config.showLamboCTAs}
              />
            ))}
        </ContentWrapper>
      </div>
    ) : null;
  }
}

// @flow
/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';

import InputField from './FormFields/InputField';
import Button from './Global/Button';
import media from '../theme';

const Container = styled.div.withConfig({
  displayName: 'TestDriveLoginContainer',
})`
  height: auto;
  text-align: center;
  margin: 20px auto 80px auto;
  max-width: 430px;
  ${media.max.large`
    width: 90%;
    padding: 0 5%;
  `};
`;

const LineBreak = styled.div`
  border-bottom: 1px solid #dedede;
  margin: 40px 0;
`;

const Block = styled.div`
  margin-bottom: 20px;
`;

const Heading = styled.span`
  font-size: 20px;
  color: #444444;
`;
const LoginFailedHeading = styled.span`
  font-size: 16px;
  color: #9e1b32;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = {
  translations: Object,
  loginAndConfirm: Function,
  continueAsGuest: Function,
  onChange: Function,
  bookingForm: Object,
  loginFailed: 'VALIDATION REQUIRED' | 'UNAUTHORISED',
  globalStyling: Object,
};

export default function TestDriveLogin(props: Props) {
  window.scroll(0, 0);
  return (
    <Container>
      <Block>
        <Heading>{props.translations.testDriveLoginHeader}</Heading>
      </Block>
      <Block>
        {props.loginFailed === 'UNAUTHORISED' && (
          <LoginFailedHeading>
            {props.translations.testDriveLoginInvalidUsernamePassword}
          </LoginFailedHeading>
        )}
        {props.loginFailed === 'VALIDATION REQUIRED' && (
          <LoginFailedHeading>
            {props.translations.accountValidationRequired}
          </LoginFailedHeading>
        )}
      </Block>
      <InputField
        type="text"
        label={props.translations.testDriveLoginUsernameLabel}
        value={props.bookingForm.username || ''}
        onChange={props.onChange}
        keyValue="username"
      />
      <InputField
        type="password"
        label={props.translations.testDriveLoginPasswordLabel}
        value={props.bookingForm.password || ''}
        onChange={props.onChange}
        keyValue="password"
      />
      <Block>
        <ButtonWrapper>
          <Button
            applyStyle="primary"
            buttonStyle={
              props.globalStyling.uiElements.primaryButton &&
              props.globalStyling.uiElements.primaryButton.buttonStyle
            }
            text={props.translations.testDriveLoginAndConfirmButtonLabel}
            styleOverride={() => 'min-width: 242px;'}
            onClick={() =>
              props.loginAndConfirm({
                credentials: {
                  user: props.bookingForm.username,
                  pass: props.bookingForm.password,
                },
              })
            }
          />
        </ButtonWrapper>
      </Block>
      <LineBreak />
      <Block>
        <Block>
          <Heading>{props.translations.testDriveLoginNewUserHeader}</Heading>
        </Block>
        <div>{props.translations.testDriveLoginNewUserInfo}</div>
      </Block>
      <Block>
        <ButtonWrapper>
          <Button
            applyStyle="primary"
            buttonStyle={
              props.globalStyling.uiElements.primaryButton &&
              props.globalStyling.uiElements.primaryButton.buttonStyle
            }
            styleOverride={() => 'min-width: 242px;'}
            text={props.translations.testDriveLoginContinueAsGuestLabel}
            onClick={props.continueAsGuest}
          />
        </ButtonWrapper>
      </Block>
    </Container>
  );
}

import editor from './editor';

export default {
  id: 'IconGrid',
  name: 'Icon Grid Pod',
  description: 'Add icon and text to the page',
  defaultSlice: 'Content',
  defaultConfig: {
    image: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
      label: 'dev/cms/playground/content/default-icon_u1la8w',
    },
    translations: {
      headlineText: 'Lorem Ipsum',
      bodyText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.',
    },
    headlineFont: { fontSize: '20', kerning: 4 },
    bodyFont: { fontSize: '14', kerning: 4 },
    alignment: 'center',
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078980/editor/Side-Icon-Grid.svg',
  editor,
  requirements: {
    columns: [2, 3, 4],
  },
};

export const constants = {
  LOAD_REPRESENTATIVE_EXAMPLE: 'LOAD_REPRESENTATIVE_EXAMPLE',
  LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS: 'LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS',
  LOAD_FINANCE_OPTIONS: 'LOAD_FINANCE_OPTIONS',
  LOAD_FINANCE_OPTIONS_SUCCESS: 'LOAD_FINANCE_OPTIONS_SUCCESS',
  LOAD_FINANCE_PRODUCT: 'LOAD_FINANCE_PRODUCT',
  LOAD_FINANCE_PRODUCT_SUCCESS: 'LOAD_FINANCE_PRODUCT_SUCCESS',
  RESET_FINANCE_PRODUCT: 'RESET_FINANCE_PRODUCT',
};

export const actions = {
  loadRepresentativeExample: () => ({
    type: constants.LOAD_REPRESENTATIVE_EXAMPLE,
  }),
  loadRepresentativeExampleSuccess: representativeExample => ({
    type: constants.LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS,
    payload: representativeExample,
  }),
  loadFinanceOptions: payload => ({
    type: constants.LOAD_FINANCE_OPTIONS,
    payload,
  }),
  loadFinanceOptionsSuccess: payload => ({
    type: constants.LOAD_FINANCE_OPTIONS_SUCCESS,
    payload,
  }),
  loadFinanceProduct: payload => ({
    type: constants.LOAD_FINANCE_PRODUCT,
    payload,
  }),
  loadFinanceProductSuccess: payload => ({
    type: constants.LOAD_FINANCE_PRODUCT_SUCCESS,
    payload,
  }),
  resetFinanceProduct: payload => ({
    type: constants.RESET_FINANCE_PRODUCT,
    payload,
  }),
};

const initialState = {
  representativeExample: null,
  financeOptions: {},
  financeProductExample: {},
};

const transformMarketFinanceOptions = options =>
  options
    .filter(o => !!o.financeType)
    .reduce((acc, option) => {
      const { financeType, durationOptions, downpaymentRange } = option;
      return {
        ...acc,
        [financeType]: {
          durationOptions,
          downpaymentRange,
        },
      };
    }, {});

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS:
      return {
        ...state,
        representativeExample: payload,
      };
    case constants.LOAD_FINANCE_OPTIONS_SUCCESS:
      return {
        ...state,
        financeOptions: {
          ...state.financeOptions,
          ...transformMarketFinanceOptions(payload.data),
        },
      };
    case constants.LOAD_FINANCE_PRODUCT_SUCCESS:
      return {
        ...state,
        financeProductExample: {
          ...state.financeProductExample,
          [payload.type]: payload.data,
        },
      };
    case constants.RESET_FINANCE_PRODUCT:
      return {
        ...state,
        financeProductExample: {
          ...state.financeProductExample,
          [payload.type]: null,
        },
      };
    default:
      return state;
  }
}

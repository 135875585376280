// @flow
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import type { Theme } from '../theme';

import { ColourField } from '.';

export type Option = { value: string, label: string };

type Props = {
  input: {
    value: Option,
    onChange: Option => void,
  },
  options: Option[],
};

const StyledSelect = styled(Select).withConfig({ displayName: 'StyledSelect' })`
  border-radius: 0;
  font-family: ${(props: { theme: Theme }) => props.theme.fontFamily};
  font-size: ${(props: { theme: Theme }) => props.theme.fontSizeContent};

  & .Select-value {
    background: none;
    padding: 0 !important;
  }

  & .Select-placeholder {
    &:hover {
      color: #474747;
    }
  }

  & .Select-control {
    background: none;
    border-radius: 0;
    border: none;
    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }
  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    background-color: transparent;
  }

  & .Select-clear-zone,
  .Select-arrow-zone {
    margin: 0;
    padding: 0;
    width: 0;
  }
`;

const hideIcons = () => <span />;
const colourFieldRenderer = ({
  label,
  value,
}: {
  label: string,
  value: string,
}) => <ColourField label={label} value={value} />;

export default function ColourSelection({ input, options }: Props) {
  return (
    <StyledSelect
      value={input.value}
      options={options}
      optionRenderer={colourFieldRenderer}
      valueRenderer={colourFieldRenderer}
      onChange={input.onChange}
      arrowRenderer={null}
      placeholder="Template Colour"
      clearRenderer={hideIcons}
    />
  );
}

import editor from './editor';

export default {
  id: 'FeatureInventoryRange',
  name: 'Featured Range Pod',
  description: 'Add a featured model range to the page',
  defaultSlice: 'Content',
  defaultConfig: {
    template: 'FeatureInventoryTemplate',
    image: {
      label: 'default image',
      src:
        'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
    },
    button: {},
    alignment: 'center',
    model: 'Huracan',
    translations: {
      headlineText: 'Lorem ipsum dolor sit amet.',
      buttonText: 'Lorem ipsum',
      availableText: '{COUNT} currently available',
      singleAvailableText: '{COUNT} currently available',
      unAvailableText: 'No cars currently available',
    },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078980/editor/Side-Icon-Grid.svg',
  editor,
  requirements: {
    columns: [1, 2, 3, 4],
  },
};

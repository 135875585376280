// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import { InputField, SelectField } from './FormFields';
import EmailVerification from './EmailVerification';
import CheckBox from './CheckBox';
import PersonalDataConsent from './PersonalDataConsent';
import JLRMarketingAndDistributionConsent from './DataConsent/JLRMarketingAndDistributionConsent';
import { Button, HeadingOne, HeadingTwo } from './Global';
import media from '../theme';
import {
  required,
  validPhone,
  validEmail,
  valueMatchesRequired,
  passwordMatchesRequired,
  meetsPasswordPolicy,
} from '../helpers/validation';
import { titleOptions } from '../helpers/formTitleOptions';

const RegisterContent = styled.div`
  padding: 20px 30% 60px;
  ${media.max.large`
    padding: 20px 5%;
  `};
`;

const RegisterFailure = styled.div`
  font-size: 16px;
  color: #9e1b32;
  font-weight: 600;
  margin-bottom: 10px;
`;
const HorizontalLine = styled.div`
  border-bottom: 1px solid #dedede;
  margin: 20px 0;
`;
const Link = styled.a`
  padding-left: 10px;
  text-decoration: underline;
  ${({ colour }) => colour && `color: ${colour};`};
`;
const DoubleRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.max.large`
    display: none;
  `};
`;
const MobileSingleRow = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

type Props = {
  translations: Object,
  register: Function,
  registerFailed: boolean,
  context: 'FORM' | 'VERIFY',
  goToPage: Function,
  globalStyling: Object,
  registerTextTransform: string,
  termsLink: string,
  marketingLink: string,
  showUserCommunication: boolean,
};
type State = {
  title: { label: string, value: string },
  firstName: string,
  lastName: string,
  email: string,
  emailConfirm: string,
  phoneNumber: string,
  postCode: string,
  interestedIn: string,
  currentVehicle: string,
  password: string,
  repeatPassword: string,
  phoneConsent: boolean,
  emailConsent: boolean,
  smsConsent: boolean,
  termsAndConditionsAccept: boolean,
  distributionListConsent: boolean,
  errors: Object,
};

export default class Register extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: {
        label: 'Please select',
        value: '',
      },
      firstName: '',
      lastName: '',
      email: '',
      emailConfirm: '',
      phoneNumber: '',
      postCode: '',
      interestedIn: '',
      currentVehicle: '',
      password: '',
      repeatPassword: '',
      phoneConsent: false,
      emailConsent: false,
      smsConsent: false,
      termsAndConditionsAccept: false,
      errors: {},
      marketingConsent: false,
      distributionListConsent: false,
      customisedServicesConsent: false,
    };
  }

  onSelectChange = ({
    keyValue,
    value,
    label,
  }: {
    keyValue: string,
    value: any,
    label: string,
  }) => {
    const error = this.validateSingle(keyValue, value);
    this.setState(prevState => ({
      [keyValue]: { label, value },
      errors: { ...prevState.errors, ...error },
    }));
  };

  onInputChange = (formKey: string, value: any) => {
    const error = this.validateSingle(formKey, value);
    this.setState(prevState => ({
      [formKey]: value,
      errors: { ...prevState.errors, ...error },
    }));
  };

  onRegisterClick = () => {
    const validations = this.validateForm();
    const valid =
      Object.values(validations).filter(val => val !== undefined).length === 0;
    const customer = {
      customer: {
        title: this.state.title.value,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        postCode: this.state.postCode,
        vehiclePreferences: {
          interestedIn: this.state.interestedIn,
          currentVehicle: this.state.currentVehicle,
        },
        contactPreferences: {
          marketing: this.state.marketingConsent,
          customisedServices: this.state.customisedServicesConsent,
          email: this.state.emailConsent,
          phone: this.state.phoneConsent,
          sms: this.state.smsConsent,
          distributionList: this.state.distributionListConsent,
        },
      },
      credentials: {
        password: this.state.repeatPassword,
      },
    };
    if (valid) {
      this.props.register(customer)();
      return;
    }
    window.scroll(0, 0);
    this.setState(() => ({ errors: validations }));
  };

  onRadioSelect = event => {
    const {
      target: { name, value },
    } = event;
    this.setState(() => ({
      [name]: value,
    }));
  };

  validateSingle = (formKey: string, value: string) => {
    const nonValidatedKeys = [
      'postCode',
      'interestedIn',
      'currentVehicle',
      'smsConsent',
      'phoneConsent',
      'emailConsent',
      'termsAndConditionsAccept',
      'distributionListConsent',
      'marketingConsent',
    ];

    if (nonValidatedKeys.includes(formKey)) return undefined;
    const validations = {
      title: required,
      firstName: required,
      lastName: required,
      email: validEmail,
      emailConfirm: validEmail,
      phoneNumber: validPhone,
      password: meetsPasswordPolicy,
      repeatPassword: required,
    };

    return {
      [formKey]: validations[formKey](value, this.props.translations, true),
    };
  };

  validateForm = () => {
    const {
      title,
      firstName,
      lastName,
      email,
      emailConfirm,
      phoneNumber,
      password,
      repeatPassword,
      termsAndConditionsAccept,
      marketingConsent,
      customisedServicesConsent,
    } = this.state;
    const { translations, showUserCommunication } = this.props;

    const validations = {
      title: required(title.value, translations),
      firstName: required(firstName, translations),
      lastName: required(lastName, translations),
      email: validEmail(email, translations),
      emailConfirm: valueMatchesRequired(email, emailConfirm, translations),
      phone: validPhone(phoneNumber, translations),
      password: meetsPasswordPolicy(password, translations, true),
      repeatPassword: passwordMatchesRequired(
        password,
        repeatPassword,
        translations,
      ),
    };
    const termsAndConditionsValidation = {
      termsAndConditionsAccept: !termsAndConditionsAccept
        ? [translations.formValidationTermsConditionsAccept]
        : undefined,
    };
    const newValidations = {
      marketingConsent: required(marketingConsent, translations),
      customisedServicesConsent: required(
        customisedServicesConsent,
        translations,
      ),
    };
    if (translations.registerTermsCondsAccept && !showUserCommunication)
      return {
        ...validations,
        ...newValidations,
        ...termsAndConditionsValidation,
      };
    if (!showUserCommunication) return { ...validations, ...newValidations };
    return validations;
  };

  render = () => {
    const {
      translations,
      registerFailed,
      context,
      goToPage,
      globalStyling,
      termsLink,
      registerTextTransform,
      showUserCommunication,
      marketingLink,
      fnIfNotPreview,
      showJLRMarketingAndDistributionConsent,
      dataConsentFont,
      checkboxLabelStyles,
    } = this.props;
    const {
      title,
      firstName,
      lastName,
      email,
      emailConfirm,
      phoneNumber,
      postCode,
      interestedIn,
      currentVehicle,
      password,
      repeatPassword,
      phoneConsent,
      emailConsent,
      smsConsent,
      termsAndConditionsAccept,
      errors,
      marketingConsent,
      customisedServicesConsent,
      distributionListConsent,
    } = this.state;
    const hasError = field => errors[field] && errors[field][0];
    return context === 'FORM' ? (
      <div>
        <HeadingOne
          styleOverride={() => `
            display: flex;
            align-items: Center;
            justify-content: center;
            border-bottom: 1px solid #d8d8d8;
            font-size: 20px;
            text-transform: uppercase;
            height: 75px;
          `}
        >
          {translations.registerHeader}
        </HeadingOne>
        <RegisterContent>
          <HeadingTwo
            styleOverride={() => `
              text-align: left;
              font-size: 20px;
              margin-bottom: 25px;
              text-transform: ${registerTextTransform || 'none'}
            `}
          >
            {translations.registerContentHeader}
          </HeadingTwo>
          {registerFailed && (
            <RegisterFailure>{translations.signInFailure}</RegisterFailure>
          )}
          <DoubleRow>
            <SelectField
              keyValue="title"
              value={title.value}
              onChange={this.onSelectChange}
              label={translations.registerTitleLabel}
              options={titleOptions(translations)}
              error={hasError('title')}
              doubleRow
              required
            />
          </DoubleRow>
          <MobileSingleRow>
            <SelectField
              keyValue="title"
              value={title}
              onChange={this.onSelectChange}
              label={translations.registerTitleLabel}
              options={titleOptions(translations)}
              error={hasError('title')}
              required
            />
          </MobileSingleRow>
          <DoubleRow>
            <InputField
              type="text"
              label={translations.registerFirstNameLabel}
              value={firstName}
              onChange={this.onInputChange}
              keyValue="firstName"
              error={hasError('firstName')}
              doubleRow
              required
            />
            <InputField
              type="text"
              label={translations.registerLastNameLabel}
              value={lastName}
              onChange={this.onInputChange}
              keyValue="lastName"
              error={hasError('lastName')}
              doubleRow
              required
            />
          </DoubleRow>
          <MobileSingleRow>
            <InputField
              type="text"
              label={translations.registerFirstNameLabel}
              value={firstName}
              onChange={this.onInputChange}
              keyValue="firstName"
              error={hasError('firstName')}
              required
            />
            <InputField
              type="text"
              label={translations.registerLastNameLabel}
              value={lastName}
              onChange={this.onInputChange}
              keyValue="lastName"
              error={hasError('lastName')}
              required
            />
          </MobileSingleRow>
          <InputField
            type="text"
            label={translations.registerEmailLabel}
            value={email}
            onChange={this.onInputChange}
            keyValue="email"
            error={hasError('email')}
            required
          />
          <InputField
            type="text"
            label={translations.registerEmailConfirmLabel}
            value={emailConfirm}
            onChange={this.onInputChange}
            keyValue="emailConfirm"
            error={hasError('emailConfirm')}
            required
          />
          <DoubleRow>
            <InputField
              type="text"
              label={translations.registerPhoneLabel}
              value={phoneNumber}
              onChange={this.onInputChange}
              keyValue="phoneNumber"
              error={hasError('phoneNumber')}
              doubleRow
            />
            <InputField
              type="text"
              label={translations.registerPostcodeLabel}
              value={postCode}
              onChange={this.onInputChange}
              keyValue="postCode"
              error={hasError('postCode')}
              doubleRow
            />
          </DoubleRow>
          <MobileSingleRow>
            <InputField
              type="text"
              label={translations.registerPhoneLabel}
              value={phoneNumber}
              onChange={this.onInputChange}
              keyValue="phoneNumber"
              error={hasError('phoneNumber')}
            />
            <InputField
              type="text"
              label={translations.registerPostcodeLabel}
              value={postCode}
              onChange={this.onInputChange}
              keyValue="postCode"
              error={hasError('postCode')}
            />
          </MobileSingleRow>
          <HorizontalLine />
          <DoubleRow>
            <InputField
              type="text"
              label={translations.registerVehicleInterestLabel}
              value={interestedIn}
              onChange={this.onInputChange}
              keyValue="interestedIn"
              error={hasError('interestedIn')}
              doubleRow
            />
            <InputField
              type="text"
              label={translations.registerCurrentVehicleLabel}
              value={currentVehicle}
              onChange={this.onInputChange}
              keyValue="currentVehicle"
              placeholder={translations.registerCurrentVehiclePlaceholder}
              error={hasError('currentVehicle')}
              doubleRow
            />
          </DoubleRow>
          <MobileSingleRow>
            <InputField
              type="text"
              label={translations.registerVehicleInterestLabel}
              value={interestedIn}
              onChange={this.onInputChange}
              keyValue="interestedIn"
              error={hasError('interestedIn')}
            />
            <InputField
              type="text"
              label={translations.registerCurrentVehicleLabel}
              value={currentVehicle}
              onChange={this.onInputChange}
              keyValue="currentVehicle"
              placeholder={translations.registerCurrentVehiclePlaceholder}
              error={hasError('currentVehicle')}
            />
          </MobileSingleRow>
          <HorizontalLine />
          <InputField
            type="password"
            label={translations.registerPasswordLabel}
            value={password}
            onChange={this.onInputChange}
            keyValue="password"
            error={hasError('password')}
            required
          />
          <InputField
            type="password"
            label={translations.registerRepeatPasswordLabel}
            value={repeatPassword}
            onChange={this.onInputChange}
            keyValue="repeatPassword"
            error={hasError('repeatPassword')}
            required
          />
          {showJLRMarketingAndDistributionConsent && (
            <JLRMarketingAndDistributionConsent
              translations={translations}
              onCheckBoxClick={fnIfNotPreview(this.onInputChange)}
              onRadioSelect={this.onRadioSelect}
              marketingChecked={marketingConsent}
              marketingKey="marketingConsent"
              distributionListConsentChecked={distributionListConsent}
              distributionListConsentKey="distributionListConsent"
              onColour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              linkColour={globalStyling.colours.primaryBrandColour}
              marketingLink={marketingLink}
              showUserCommunication={showUserCommunication}
              globalStyling={globalStyling}
              dataConsentFont={dataConsentFont}
              checkBoxError={hasError('checkBoxError')}
              labelFontSize={
                checkboxLabelStyles && checkboxLabelStyles.fontSize
              }
            />
          )}
          {!showJLRMarketingAndDistributionConsent && (
            <PersonalDataConsent
              onColour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              translations={translations}
              onCheckBoxClick={this.onInputChange}
              phoneChecked={phoneConsent}
              phoneKey="phoneConsent"
              emailChecked={emailConsent}
              emailKey="emailConsent"
              smsChecked={smsConsent}
              smsKey="smsConsent"
              labelFontSize="13"
              checkboxDimension="1.25"
              linkColour={globalStyling.colours.primaryBrandColour}
              onRadioSelect={this.onRadioSelect}
              customisedServices={customisedServicesConsent}
              customisedServicesKey="customisedServicesConsent"
              customisedServicesError={hasError('customisedServicesConsent')}
              marketing={marketingConsent}
              marketingKey="marketingConsent"
              marketingError={hasError('marketingConsent')}
              marketingLink={marketingLink}
              showUserCommunication={showUserCommunication}
              globalStyling={globalStyling}
            />
          )}
          {translations.registerTermsCondsAccept && (
            <Fragment>
              <HorizontalLine />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckBox
                  checked={termsAndConditionsAccept}
                  onClick={() =>
                    this.onInputChange(
                      'termsAndConditionsAccept',
                      !termsAndConditionsAccept,
                    )
                  }
                  onColour={
                    globalStyling.colours.primaryBrandColour &&
                    globalStyling.colours.primaryBrandColour.value
                  }
                  checkboxDimension="1.25"
                />
                <Link
                  href={termsLink}
                  target="_blank"
                  colour={
                    globalStyling.colours.primaryBrandColour &&
                    globalStyling.colours.primaryBrandColour.value
                  }
                >
                  {translations.registerTermsCondsAccept}
                </Link>
              </div>
            </Fragment>
          )}
          {errors.termsAndConditionsAccept && (
            <RegisterFailure>
              {translations.formValidationTermsConditionsAccept}
            </RegisterFailure>
          )}
          <div style={{ marginTop: '20px' }}>
            <Button
              styleOverride={() => `
                width: 100%;
              `}
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              onClick={this.onRegisterClick}
              text={translations.registerButton}
            />
            {registerFailed && (
              <RegisterFailure>{translations.registerFailure}</RegisterFailure>
            )}
          </div>
        </RegisterContent>
      </div>
    ) : (
      <EmailVerification
        globalStyling={globalStyling}
        translations={translations}
        email={email}
        goToPage={goToPage}
      />
    );
  };
}
Register.defaultProps = {
  showUserCommunication: false,
};

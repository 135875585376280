// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
  width: string,
};

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${(props: Props) => props.theme.layout.doublePadding};
  width: ${({ width }) => width || 'auto'};
`;

export default function FormContainer(props: Props) {
  return <StyledFormContainer {...props} />;
}

// @flow
import React from 'react';
import styled from 'styled-components';

import SearchResultTile from './SearchResultTile';
import VdpHeader from './VdpHeader';
import Chunky from './JaguarIcons/Arrows/Chunky';
import type { CarResult } from './SearchResultTile';
import media from '../theme';
import CompareIcon from './JaguarIcons/Global/Compare';
import HeartIcon from './JaguarIcons/Global/Heart';

import type { ButtonLinkTheme } from '../types';

type Props = {
  similarVehicles: CarResult[],
  vehiclesInView: CarResult[],
  next: () => void,
  previous: () => void,
  nextMobile: () => void,
  previousMobile: () => void,
  totalPages: number,
  currentPage: number,
  mobileSelected: number,
  mobileCurrentPage: number,
  mobileTotalPages: number,
  translations: Object,
  compareVehicle: Function,
  shortlistVehicle: Function,
  moreInfoClick: Function,
  isListed: Function,
  comparedVehicles: Object,
  shortlistedVehicles: Object,
  buttonTheme: ButtonLinkTheme,
  placeholdingImage: string,
  globalStyling: Object,
  gridTile: Object,
  visibility: Object,
  showOdometerInformation: boolean,
  locale: string,
  selectedHeartColour: string,
  selectedCompareColour: string,
};

const Container = styled.div.withConfig({
  displayName: 'SimilarVehiclesContainer',
})`
  margin-bottom: 20px;
  padding-top: 20px;
`;
const HeaderContainer = styled.div`
  width: 90%;
  margin: 0 5%;
  ${media.max.large`
    text-align: center;
  `};
`;
const Section = styled.div`
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.alignment};
  align-items: center;
`;

const MobileSection = styled.div`
  ${media.min.large`
    display: ${props => (props.hideOnDesktop ? 'none' : 'flex')};
  `};
`;

const VehicleContainer = styled.div.withConfig({
  displayName: 'VehicleContainer',
})`
  width: 90%;
  display: flex;
  justify-content: space-between;
  ${media.max.large`
    width: 100%;
    `};
`;

const Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: ${props => (props.hideOnDesktop ? 'none' : 'flex')};
  &:focus {
    outline: none;
  }
  ${media.max.large`
    display:  ${props => (props.hideOnDesktop ? 'flex' : 'none')};
    `};
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

const CompareIconButton = (onCompareClick, colour) => (
  <IconWrapper data-qa-hook="compare-vehicle-button" onClick={onCompareClick}>
    <CompareIcon width="2em" height="1.75em" colour={colour} />
  </IconWrapper>
);

const HeartIconButton = (onHeartClick, colour) => (
  <IconWrapper data-qa-hook="shortlist-vehicle-button" onClick={onHeartClick}>
    <HeartIcon width="2em" height="1.75em" colour={colour} />
  </IconWrapper>
);

export default function SimilarVehicles({
  similarVehicles,
  vehiclesInView,
  next,
  previous,
  nextMobile,
  previousMobile,
  totalPages,
  mobileSelected,
  mobileCurrentPage,
  mobileTotalPages,
  translations,
  compareVehicle,
  shortlistVehicle,
  moreInfoClick,
  isListed,
  comparedVehicles,
  shortlistedVehicles,
  buttonTheme,
  placeholdingImage,
  globalStyling,
  gridTile,
  locale,
  selectedHeartColour,
  selectedCompareColour,
  icon360,
}: Props) {
  const hasMultiplePages = totalPages > 1;
  const hasMultiplePagesMobile = mobileTotalPages > 1;

  return vehiclesInView &&
    similarVehicles &&
    similarVehicles.contents.length > 2 ? (
    <Container>
      <HeaderContainer>
        <VdpHeader qaHook="similar-vehicles-header">
          {translations.vdpSimilarVehiclesHeader}
        </VdpHeader>
      </HeaderContainer>
      <Section alignment={hasMultiplePages ? 'space-between' : 'center'}>
        {hasMultiplePages && (
          <Button style={{ transform: 'rotateY(180deg)' }} onClick={previous}>
            <Chunky width="2em" height="2em" />
          </Button>
        )}
        <VehicleContainer>
          {vehiclesInView.map((v, index) => {
            const heartColour = isListed(v.id, shortlistedVehicles)
              ? selectedHeartColour
              : '#ACACAC';
            const compareColour = isListed(v.id, comparedVehicles)
              ? selectedCompareColour
              : '#ACACAC';
            return (
              <SearchResultTile
                align="left"
                carResult={v}
                onMoreInfoClicked={() => moreInfoClick('VDP', v.id)}
                translations={translations}
                renderBottomIcon1={() =>
                  CompareIconButton(() => compareVehicle(v), compareColour)
                }
                renderBottomIcon2={() =>
                  HeartIconButton(() => shortlistVehicle(v), heartColour)
                }
                mobileSelectedForSimVehicle={mobileSelected === index}
                inSimilarVehicles
                buttonTheme={buttonTheme}
                placeholdingImage={placeholdingImage}
                globalStyling={globalStyling}
                visibility={gridTile.visibility}
                locale={locale}
                icon360={icon360}
              />
            );
          })}
        </VehicleContainer>
        {hasMultiplePages && (
          <Button onClick={next}>
            <Chunky width="2em" height="2em" />
          </Button>
        )}
      </Section>
      <MobileSection hideOnDesktop>
        <Section alignment="center">
          {hasMultiplePagesMobile && (
            <Button
              style={{ transform: 'rotateY(180deg)' }}
              onClick={previousMobile}
              hideOnDesktop
            >
              <Chunky width="2em" height="2em" />
            </Button>
          )}
          {`${mobileCurrentPage}/${mobileTotalPages}`}
          {hasMultiplePagesMobile && (
            <Button onClick={nextMobile} hideOnDesktop>
              <Chunky width="2em" height="2em" />
            </Button>
          )}
        </Section>
      </MobileSection>
    </Container>
  ) : null;
}

// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import useCookiePolicy from './useCookiePolicy';
import { Wrapper, TextWrapper } from '../components';
import {
  Button,
  HeadingOne,
  HeadingTwo,
  Paragraph,
  fontStyleOverride,
  buttonStyleOverride,
} from '../components/Global';
import type { Option, Font, ButtonType, Padding } from '../../types';

export const buttonAlignMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const CookiePolicyFixedTopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
`;

export const CookiePolicyWrapper = styled.div`
  width: ${props => (props.type === 'compact' ? '50vw' : '100%')};
  display: flex;
  position: fixed;
  z-index: 999;
  ${props => (props.vPlacement === 'bottom' ? `bottom: 0px` : `top: 0px`)};
  ${props => (props.hPlacement === 'right' ? `right: 0px` : `left: 0px`)};
  ${props =>
    props.hPlacement === 'center' && `left: 50%; transform: translateX(-50%)`};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.alignment || 'center'};
  padding-bottom: 10px;
`;

export type MarkdownProps = {
  level?: number,
  children?: Node,
};

export interface ICookiePolicyConfig {
  translations: {
    bodyText: string,
  };
  type: 'full-width' | 'compact';
  placement:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';
  backgroundColour?: Option;
  bodyFont?: Font;
  button?: ButtonType;
  padding?: Padding;
}

export type Props = {
  config: ICookiePolicyConfig,
};

export const disallowedTypes = ['strong'];

export const MarkdownRenderers = bodyFont => ({
  paragraph: (props: MarkdownProps) => (
    <Paragraph styleOverride={() => fontStyleOverride(bodyFont)}>
      {props.children}
    </Paragraph>
  ),
  heading: (props: MarkdownProps) => {
    switch (props.level) {
      case 2:
        return <HeadingTwo>{props.children}</HeadingTwo>;
      default:
        return <HeadingOne>{props.children}</HeadingOne>;
    }
  },
});

export type CookiePolicyContentProps = {
  translations: object,
  uiElements: object,
  backgroundColour: object,
  border: object,
  borderColour: object,
  padding: object,
  bodyFont: object,
  alignment: string,
  setVisible: Function,
};

export const CookiePolicyContent = (props: CookiePolicyContentProps) => {
  const {
    backgroundColour,
    border,
    borderColour,
    padding,
    bodyFont,
    alignment,
    uiElements,
    button,
    translations,
    setVisible,
  } = props;

  return (
    <Wrapper
      backgroundColour={backgroundColour}
      border={border}
      borderColour={borderColour}
    >
      {translations.bodyText && (
        <TextWrapper padding={padding}>
          <ReactMarkdown
            renderers={MarkdownRenderers(bodyFont)}
            disallowedTypes={disallowedTypes}
            unwrapDisallowed
          >
            {translations.bodyText}
          </ReactMarkdown>
        </TextWrapper>
      )}
      <ButtonWrapper {...{ alignment: buttonAlignMapping[alignment] }}>
        <Button
          buttonStyle={
            uiElements.primaryButton && uiElements.primaryButton.buttonStyle
          }
          applyStyle="primary"
          styleOverride={() => buttonStyleOverride(button)}
          text={translations.buttonText}
          onClick={() => setVisible(false)}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ({
  config: {
    translations,
    backgroundColour,
    border,
    borderColour,
    bodyFont,
    button,
    padding,
    alignment,
    placement,
    type,
  },
  globalStyling: { uiElements = {} } = {},
}: Props) => {
  const { domEl, isVisible, setVisible } = useCookiePolicy();
  const [vPlacement, hPlacement] = placement.split('-');
  const cookiePolicyProps = {
    backgroundColour,
    border,
    borderColour,
    padding,
    bodyFont,
    alignment,
    uiElements,
    button,
    translations,
    setVisible,
  };

  if (!isVisible) {
    return null;
  }

  if (
    type === 'full-width' &&
    vPlacement === 'top' &&
    hPlacement === 'center'
  ) {
    return (
      domEl &&
      ReactDOM.createPortal(
        <CookiePolicyFixedTopWrapper>
          <CookiePolicyContent {...cookiePolicyProps} />
        </CookiePolicyFixedTopWrapper>,
        domEl,
      )
    );
  }

  return ReactDOM.createPortal(
    <CookiePolicyWrapper
      hPlacement={hPlacement}
      vPlacement={vPlacement}
      type={type}
    >
      <CookiePolicyContent {...cookiePolicyProps} />
    </CookiePolicyWrapper>,
    document.body,
  );
};

// @flow
import React from 'react';
import styled from 'styled-components';

import GridItem from '../../components/GridItem';
import type { Link, Font, Option } from '../../types';
import media from '../../theme';
import ContentWrapper from '../../components/ContentWrapper';
import { actions as routerActions } from '../../actions/router';
import { HeadingTwo } from '../../components/Global';

const MAX_COLUMNS = 3;
const namedDiv = displayName => styled.div.withConfig({ displayName });

const Row = namedDiv('Row')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 2px;
  ${media.max.medium`
    display: block;
  `}
  ${({ height }) => height && `height:${height}px`};
`;

const Column = namedDiv('Column')`
  display: flex;
  flex-grow: 1;
  border-top: ${({ borderTop }) => `${borderTop || 'none'}`};
  border-left: ${({ borderLeft }) => `${borderLeft || 'none'}`};
  margin-right: 0.5%;
  ${({ colour }) => colour && `background-color: ${colour.value}`};
  ${media.max.large`
    width: 100% !important;
  `}
  ${media.min.medium`
    max-width: 33%;

    &:last-child {
      margin-right: 0;
    }
  `}
`;

const HeaderStyleOverride = () => `
  margin: 32px 0;
  text-align: center;
  font-weight: 600;
`;

const Gap = namedDiv('Gap')`
  height: ${({ height }) => height}px;
`;

type Item = {
  image: { label: string, value: string },
  link: { url: string, label: string },
  translations: {
    headline: string,
    body: string,
    link: string,
  },
};

export interface Config {
  items: Item[];
  translations: Object;
  gridItemsAlign: string;
  gridBackgroundColour: Option;
  gridItemHeaderFont: Font;
  gridItemBodyFont: Font;
  gridItemLinkFont: Font;
  buttonBackgroundColour: Option;
  buttonHover: Option;
  buttonFont: Font;
  buttonSize: Object;
  buttonType: string;
  column: Object;
  gapHeight: number;
}

type Props = {
  config: Config,
  globalStyling: Object,
  dispatch: Function,
};

const onSearchClick = (
  modelRange: string,
  make: string,
  market: string,
  dispatch: Object,
) => {
  dispatch(
    routerActions.navigateWithQuery('searchresults', { model: modelRange }),
  );
};

const imageTransformationItemsPerRow = {
  1: 'h_600,w_1300',
  2: 'h_300,w_680',
  3: 'h_176,w_400',
};

const imageTransform = (url, item) =>
  url &&
  url.replace(
    'image/upload',
    `image/upload/c_fill,${imageTransformationItemsPerRow[item]}`,
  );

export default function ModelGrid({
  config,
  globalStyling,
  dispatch,
  marketInfo: { make, market },
}: Props) {
  const numberOfRows = config.items
    ? Math.ceil(config.items.length / MAX_COLUMNS)
    : 1;
  const numberOfLastRowItems = config.items
    ? config.items.length % MAX_COLUMNS
    : 0;
  const isLastRow = rowValue => rowValue === numberOfRows - 1;
  const getNumberOfColumns = rowValue =>
    isLastRow(rowValue)
      ? !numberOfLastRowItems
        ? MAX_COLUMNS
        : numberOfLastRowItems
      : MAX_COLUMNS;
  const onSearchClickHandler = model =>
    onSearchClick(model, make, market, dispatch);
  const buttonProps = {
    backgroundColour: config.buttonBackgroundColour,
    hover: config.buttonHover,
    font: config.buttonFont,
    buttonSize: config.buttonSize,
    buttonType: config.buttonType,
  };
  const alignItems = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };
  return (
    <ContentWrapper
      contentWidth={globalStyling.contentWidth}
      colour={config.gridBackgroundColour}
    >
      <HeadingTwo styleOverride={HeaderStyleOverride}>
        {config.translations.header}
      </HeadingTwo>
      {[...Array(numberOfRows).keys()].map((rowValue, x) => (
        <Row key={rowValue}>
          {[...Array(getNumberOfColumns(rowValue)).keys()].map(
            (columnValue, y) => {
              const item = config.items && config.items[x * MAX_COLUMNS + y];
              const itemImage: Option = (item && item.image) || {
                value: null,
                label: null,
              };
              const itemLink: Link = (item &&
                item.link && {
                  ...item.link,
                  text: item.translations.link,
                }) || {
                url: '',
                label: '',
                text: '',
              };
              const headline: string = item && item.translations.headline;
              const bodyText: string = item && item.translations.body;
              const bottomText: string = item && item.translations.bottomText;
              return (
                <Column
                  width="33%"
                  key={columnValue}
                  colour={config.gridItemBackgroundColour}
                  borderTop={config.column && config.column.borderTop}
                  borderLeft={config.column && config.column.borderLeft}
                >
                  <GridItem
                    linkText={itemLink.text}
                    imageUrl={imageTransform(
                      itemImage.value,
                      getNumberOfColumns(rowValue),
                    )}
                    headline={headline}
                    bodyText={bodyText}
                    bottomText={bottomText}
                    bodyTextTheme={config.gridItemBodyFont || {}}
                    headlineTheme={config.gridItemHeaderFont || {}}
                    bottomTextTheme={config.gridItemBottomTextFont || {}}
                    align={alignItems[config.gridItemsAlign]}
                    buttonProps={buttonProps}
                    globalStyling={globalStyling}
                    bottomContentGrow
                    onClickLink={() =>
                      onSearchClickHandler(item.models.join('&models='))
                    }
                    imageAspectRatioPercentage={
                      config.gridItemImageAspectRatioPercentage
                    }
                  />
                </Column>
              );
            },
          )}
        </Row>
      ))}
      <Gap height={config.gapHeight} />
    </ContentWrapper>
  );
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Location from './JaguarIcons/Global/Location';
import { google } from '../utilities/googleObject';

const LocationContainer = styled.div`
  background: #ffffff;
  display: flex;
  height: 40px;
  max-height: 40px;
  width: 100%;
  ${({ isStickySearchBar }) =>
    isStickySearchBar ? 'width: initial' : 'width: 100%;'};
`;

const IconWrapper = styled.div`
  align-items: center;
  border: 1px solid #d8d8d8;
  ${({ direction }) =>
    direction === 'rtl' ? 'border-left: none;' : 'border-right: none;'};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 20%;
`;

const StyledInput = styled.input`
  width: 80%;
  padding: 0 5%;
  margin: 0;
  border: 1px solid #d8d8d8;
  ${({ direction }) => direction === 'rtl' && 'font-size: 16px'};
  &:focus {
    border: 1px solid #999999;
    outline: none;
  }
  ${({ isStickySearchBar }) =>
    isStickySearchBar && 'height: 46px; width: 100%; border: 0;'};
`;

type Props = {
  onGetCurrentPosition: (position: Position) => void,
  placeholder: string,
  locationValue: string,
  locationIconColour: string,
  handleChange: (value: string) => void,
  handleBlur: (value: string) => void,
  searchCountryCode: string,
  isStickySearchBar: boolean,
  globalStyling: Object,
};

export default class LocationInput extends Component<Props, *> {
  input = null;

  autocomplete = null;

  static defaultProps = {
    handleBlur: () => {},
  };

  componentDidMount = () => {
    const { input } = this;
    const { searchCountryCode } = this.props;
    this.autocomplete = new google.maps.places.Autocomplete(
      input,
      searchCountryCode && {
        types: ['geocode'],
        componentRestrictions: {
          country: searchCountryCode,
        },
      },
    );
    this.autocomplete.addListener('place_changed', this.getSearchLocation);
    window.addEventListener('scroll', this.hideAutocomplete);
  };

  autocompleteResultsPosition = () => {
    const googleSuggest = document.querySelectorAll('.pac-container');
    return googleSuggest.forEach(el =>
      this.props.isStickySearchBar
        ? el.setAttribute('style', 'min-height: 176px; margin-top: -226px;')
        : el.removeAttribute('style'),
    );
  };

  hideAutocomplete = () => {
    const googleSuggest = document.querySelectorAll('.pac-container');
    return googleSuggest.forEach(el =>
      el.setAttribute('style', 'display: none;'),
    );
  };

  getSearchLocation = () => {
    this.props.handleBlur(this.input.value);
  };

  getCurrentPosition = () => {
    if (!('geolocation' in navigator)) {
      return;
    }
    navigator.geolocation.getCurrentPosition(res =>
      this.props.onGetCurrentPosition(res),
    );
  };

  render() {
    const {
      placeholder,
      locationValue,
      locationIconColour,
      handleChange,
      isStickySearchBar,
      globalStyling,
    } = this.props;
    return (
      <LocationContainer isStickySearchBar={isStickySearchBar}>
        {this.props.onGetCurrentPosition && (
          <IconWrapper
            data-qa-hook="get-location-button"
            onClick={this.getCurrentPosition}
            direction={globalStyling && globalStyling.direction}
          >
            <Location colour={locationIconColour} width="2em" height="2em" />
          </IconWrapper>
        )}
        <StyledInput
          data-qa-hook="location-input"
          type="text"
          placeholder={placeholder}
          onClick={this.autocompleteResultsPosition}
          onChange={event => {
            handleChange(event.target.value);
          }}
          value={locationValue}
          isStickySearchBar={isStickySearchBar}
          direction={globalStyling && globalStyling.direction}
          ref={input => {
            this.input = input;
          }}
        />
      </LocationContainer>
    );
  }
}

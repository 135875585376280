import { useDispatch, useSelector } from 'react-redux';
import { actions as routerActions } from '../actions/router';
import { getAVLConfig } from '../shared/selectors/settings';
import { modules } from '..';

const { navigate } = routerActions;

const bindActionToInstance = ({ page, instance }, dispatch, action) => (
  ...args
) => dispatch(action(page, instance, ...args));

const bindActionsToInstance = (moduleConfig, actions, dispatch) =>
  Object.keys(actions).reduce(
    (newActions, name) => ({
      ...newActions,
      [name]: bindActionToInstance(moduleConfig, dispatch, actions[name]),
    }),
    {},
  );

const bindPathByModule = (pages, bands) => moduleId => {
  const band = bands.find(m => m.id === moduleId || m.name === moduleId);
  const { relativePath } = pages.find(page =>
    page.modules.includes(band.instanceId),
  ) || {
    relativePath: '',
  };

  return relativePath;
};

const bindNavigateIfInternal = dispatch => href => {
  if (!href.match(/^http/)) {
    dispatch(navigate(href));
    return true;
  }
  return false;
};

const useCompiledModule = (id, index, path) => {
  const { component: ReactComponent, actions = {} } = Object.values(
    modules,
  ).find(m => m.id === id);
  const dispatch = useDispatch();
  const [_, page] = path.split('/');
  const pagePath = `/${page}`;
  const moduleConfig = {
    id,
    instance: index,
    page: pagePath,
  };

  const moduleState =
    useSelector(
      state =>
        state.pages && state.pages[pagePath] && state.pages[pagePath][index],
    ) || {};

  const bands = useSelector(state => state.config.bands);
  const pages = useSelector(state => state.config.pages);

  const boundActions = bindActionsToInstance(moduleConfig, actions, dispatch);
  const shared = useSelector(state => state.shared);
  const router = useSelector(state => state.router);
  const vehicleInContext = useSelector(state => state.vehicleInContext);
  const marketInfo = useSelector(state => getAVLConfig(state));
  const history = useSelector(state => state.router.history);
  const linkedModules = useSelector(state => state.config.linked);
  const { featureFlags, finance, socialShare } = useSelector(
    state => state.config.config.global,
  );

  const pathByModule = bindPathByModule(
    Object.values(pages),
    Object.values(bands),
  );
  const navigateIfInternal = bindNavigateIfInternal(dispatch);

  return {
    ReactComponent,
    moduleConfig,
    shared,
    moduleState,
    boundActions,
    router,
    marketInfo,
    vehicleInContext,
    history,
    linkedModules,
    featureFlags,
    finance,
    socialShare,
    pathByModule,
    navigateIfInternal,
    dispatch,
  };
};

export default useCompiledModule;

// @flow
/* eslint-env browser */
import { put, call, delay } from 'redux-saga/effects';
import uuid from 'uuid/v1';
import watch from '../../helpers/watch';
import {
  get,
  post,
  multipartFormConfig,
  blobContentConfig,
  fileDownload,
} from '../../helpers/http';
import httpError from '../../helpers/error';
import { actions, constants } from '../actions/translation';
import type {
  LoadTranslationsAction,
  ImportTranslationFileAction,
  ExportTranslationFileAction,
} from '../actions/translation';
import {
  API_BASE_URL_SITE_TRANSLATIONS,
  TRANSLATION_UPLOAD_DOWNLOAD_URL,
} from '../../constants/apis';
import type { Translation } from '../types/translation';

export const down = (translation: any): Translation => ({
  id: uuid(),
  uploading: false,
  language: translation.language.name,
  isoCode: translation.language.isoCode,
  lastUpdated: new Date(translation.lastUpdated),
});

const valuesFromResponse = response => response.data.contents;

export const formDataFile = (file: File) => {
  const fd = new FormData();
  fd.append('csvFile', file);
  return fd;
};

export function* loadTranslations(
  token: string,
  action: LoadTranslationsAction,
): Generator<*, *, *> {
  try {
    const url = API_BASE_URL_SITE_TRANSLATIONS(action.payload.siteId);
    const response = yield call(get, { url, token });

    const translations: any[] = yield valuesFromResponse(response).map(down);

    yield put(actions.loadTranslationsSuccess(translations));
  } catch (error) {
    yield put(
      actions.loadTranslationsFailure(
        httpError(error, 'Failure to load translations'),
      ),
    );
  }
}

export function* importTranslationFile(
  token: string,
  action: ImportTranslationFileAction,
): Generator<*, *, *> {
  const {
    siteId,
    translationId,
    file,
    defaultIso,
    translationIso,
    date,
  } = action.payload;

  yield put(actions.markTranslationAsUploading(translationId));

  const url = yield call(
    TRANSLATION_UPLOAD_DOWNLOAD_URL,
    siteId,
    defaultIso,
    translationIso,
    'upload',
  );

  yield call(delay, 1000);

  try {
    const formData = yield formDataFile(file);

    yield call(post, {
      url,
      data: formData,
      config: multipartFormConfig(token),
    });

    yield put(actions.translationUploadedSuccess(translationId, date));
  } catch (e) {
    yield put(actions.translationUploadFailure(translationId));
  }
}

export function* exportTranslationFile(
  token: string,
  action: ExportTranslationFileAction,
): Generator<*, *, *> {
  const { siteId, defaultIso, translationIso } = action.payload;

  const url = yield call(
    TRANSLATION_UPLOAD_DOWNLOAD_URL,
    siteId,
    defaultIso,
    translationIso,
    'download',
  );

  try {
    const response = yield call(get, {
      url,
      config: blobContentConfig(token),
    });

    yield call(fileDownload, response);
  } catch (error) {
    yield put(
      actions.exportTranslationFileFailure(
        httpError(error, 'Failure to export translations file'),
      ),
    );
  }
}

export default [
  watch(constants.LoadTranslations, loadTranslations, true),
  watch(constants.ImportTranslationFile, importTranslationFile, true),
  watch(constants.ExportTranslationFile, exportTranslationFile, true),
];

// @flow
import React, { Component } from 'react';

import SubNav from '../../containers/SubNav';
import { actions as routerActions } from '../../actions/router';
import CompareVehicles from '../../components/CompareVehicles';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortListActions } from '../../shared/shortlist';
import type { Font } from '../types';
import { config as previewConfig } from './preview.config';
import ContentWrapper from '../../components/ContentWrapper';

export interface Config {
  translations: Object;
  subNavBGColour?: string;
  subNavFont?: Font;
  subNavBorderColour?: Object;
  subNavChevronColour?: Object;
  closeIconColour: Object;
  phoneIconColour: Object;
  gridConfiguration: Object;
  shortlistIconListedColour: Object;
}

type Props = {
  dispatch: Function,
  marketInfo: Object,
  config: Config,
  actions: {
    toggleOptionalExtras: Function,
    init: Function,
  },
  pathByModule: string => any,
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  globalStyling: Object,
};

const themeFromProps = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
}: Font): Theme => ({
  fontFamily: typeface ? typeface.label : '',
  fontSize: fontSize ? `${fontSize}px` : '',
  characterSpacing: kerning ? `${kerning}px` : '',
  textTransform: transform || '',
  colour: colour ? colour.value : '',
});

export default class Compare extends Component<Props, *> {
  componentDidMount() {
    // We do this to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      actions: { init },
      dispatch,
      shared: { sessionPreferences, currencyConversion },
    } = this.props;

    init();
    dispatch(compareActions.checkVehicleStatus());
    dispatch(shortListActions.getVehicles());
    dispatch(compareActions.getEquipmentList());

    if (
      sessionPreferences.currency &&
      currencyConversion.exchangeRates[sessionPreferences.currency]
    ) {
      dispatch(
        compareActions.updateVehiclePrices({
          currency: sessionPreferences.currency,
          exchangeRates: currencyConversion.exchangeRates,
        }),
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      shared,
      dispatch,
      shared: { sessionPreferences, currencyConversion },
    } = this.props;
    if (
      sessionPreferences.currency &&
      currencyConversion.exchangeRates[sessionPreferences.currency] &&
      prevProps.shared.currencyConversion.exchangeRates !==
        currencyConversion.exchangeRates
    ) {
      dispatch(
        compareActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      dispatch(compareActions.checkVehicleStatus());
      dispatch(compareActions.getEquipmentList());
    }
  }

  render = () => {
    const {
      dispatch,
      state: { optionalExtras },
      actions: { toggleOptionalExtras },
      history,
      pathByModule,
      preview,
      config,
      shared,
      globalStyling,
      marketInfo: { locale },
    } = this.props;
    const comparedVehicles = preview ? previewConfig : shared.compare.vehicles;

    const sharedShortListedVehicles = preview
      ? previewConfig
      : shared.shortlist.vehicles;

    const getShortListedIconColour = (id: string) => {
      const isShortListed = sharedShortListedVehicles.find(
        shortlistedVehicle => shortlistedVehicle.id === (id || {}),
      );
      return isShortListed
        ? config.shortlistIconListedColour &&
            config.shortlistIconListedColour.value
        : '#ACACAC';
    };

    const equipmentMetaData = shared.compare.equipmentList || [];
    return (
      <div>
        <SubNav
          pathByModule={pathByModule}
          title={config.translations.mobileVehicleDetails}
          preview={preview}
          onBackButtonClick={history.goBack}
          translations={config.translations}
          subNavBGColour={config.subNavBGColour}
          subNavBorderColour={config.subNavBorderColour}
          subNavFontTheme={
            config.subNavFont && themeFromProps(config.subNavFont)
          }
          subNavChevronColour={config.subNavChevronColour}
          subNavBarItemSelectedBackgroundColour={
            config.subNavBarItemSelectedBackgroundColour
          }
          subNavBarItemSelectedFontColour={
            config.subNavBarItemSelectedFontColour
          }
          contentWidth={globalStyling.contentWidth}
          globalStyling={globalStyling}
        />
        <ContentWrapper contentWidth={globalStyling.contentWidth}>
          <CompareVehicles
            requestInformation={vehicle =>
              dispatch(routerActions.navigate(`/enquiry/${vehicle.id}`))
            }
            translations={config.translations}
            removeVehicle={vehicle =>
              dispatch(compareActions.toggleVehicle(vehicle))
            }
            shortlistVehicle={vehicle =>
              dispatch(shortListActions.toggleVehicle(vehicle))
            }
            comparedVehicles={comparedVehicles}
            optionalExtras={optionalExtras}
            equipmentMetaData={equipmentMetaData}
            toggleOptionalExtras={toggleOptionalExtras}
            getShortListedIconColour={getShortListedIconColour}
            phoneIconColour={
              config.phoneIconColour && config.phoneIconColour.value
            }
            closeIconColour={
              config.closeIconColour && config.closeIconColour.value
            }
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
            gridConfiguration={config.gridConfiguration}
            globalStyling={globalStyling}
            headerFont={config.headerFont}
            infoFont={config.infoFont}
            borderBottomOnly={config.borderBottomOnly}
            headerOverSubheader={config.headerOverSubheader}
            locale={locale}
          />
        </ContentWrapper>
      </div>
    );
  };
}

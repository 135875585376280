/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';
import {
  HeadingTwo,
  Paragraph,
  fontStyleOverride,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';

const Container = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  ${({ backgroundImage }) =>
    backgroundImage && `background-image: url("${backgroundImage.value}")`};
  ${({ backgroundImage }) => backgroundImage && 'background-size: cover'};
  ${({ backgroundImage }) => backgroundImage && 'background-position: center'};
  ${({ colour }) => colour && `background-color: ${colour.value}`};
  @media print {
    display: none;
  }
`;

const ImageOverlay = styled.div`
  ${({ showOverlay }) =>
    `${showOverlay && 'background-color: rgba(0,0,0,0.6);'}`};
  width: 100%;
`;

const IconGridContainer = styled.div`
  ${({ padding }) => `padding:
    ${(padding.horizontal !== undefined && `${padding.horizontal}px`) || '30px'}
    ${(padding.vertical !== undefined && `${padding.vertical}px`) || '25px'}`};
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  ${media.max.medium`
    ${({ padding }) =>
      `${padding.horizontal !== undefined &&
        `padding: ${padding.horizontal}px 5%`}`};
  `};
`;

const HeadlineWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ align }) => `${align && `align-items: ${align}`}`};
  ${({ marginTop }) => `${marginTop && `margin-top: ${marginTop}px`}`};
  ${({ marginBottom }) =>
    `${marginBottom && `margin-bottom: ${marginBottom}px`}`};
`;

const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
`;

const IconGridItem = styled.div`
  ${({ width }) => `${width && `width:${width}%`}`};
  min-width: 33%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ paddingBottom }) =>
    `padding-bottom: ${paddingBottom ? `${paddingBottom}px` : '30px'}`};
  ${media.max.medium`
    width: 100%;
  `};
`;

const Image = styled.img`
  margin: 4px 0;
`;

const alignItems = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

type Item = {
  image: { label: string, value: string },
  translations: {
    headlineText: string,
    descriptionText: string,
  },
};

export interface Config {
  items: Item[];
}

type Props = {
  config: Config,
  globalStyling: Object,
};

export default function IconGrid({ config, globalStyling }: Props) {
  const showOverlay = config.backgroundImageActive && config.backgroundImage;
  return (
    <Container
      id={config.moduleId}
      colour={config.backgroundColour}
      backgroundImage={config.backgroundImageActive && config.backgroundImage}
    >
      <ImageOverlay showOverlay={showOverlay}>
        <ContentWrapper contentWidth={globalStyling.contentWidth}>
          <IconGridContainer
            padding={{
              horizontal: config.horizontalPadding,
              vertical: config.verticalPadding,
            }}
          >
            <HeadlineWrapper
              marginTop={config.headlineFont.marginTop}
              marginBottom={
                config.marginBottom || config.headlineFont.marginBottom
              }
              align={alignItems[config.headLineAlignment]}
            >
              {config.translations.headLineText && (
                <HeadingTwo
                  styleOverride={() => `
                    ${fontStyleOverride(config.headlineFont)}
                  `}
                >
                  {config.translations.headLineText}
                </HeadingTwo>
              )}
              {config.translations.subHeadlineText && (
                <Paragraph
                  styleOverride={() => `
                    ${config.marginBetweenSubheader &&
                      `margin: ${config.marginBetweenSubheader};`}
                      ${fontStyleOverride(config.descriptionFont)}
                      max-width: 65%;
                    `}
                >
                  {config.translations.subHeadlineText}
                </Paragraph>
              )}
            </HeadlineWrapper>
            <ItemsContainer>
              {config.items.map((icongrid, index) => [
                <IconGridItem
                  key={index.toString()}
                  paddingBottom={config.itemBottomPadding}
                  width={
                    config.items.length !== 4 ? 100 / config.items.length : 50
                  }
                >
                  {icongrid.image.value && (
                    <Image
                      src={icongrid.image && icongrid.image.value}
                      alt={icongrid.image && icongrid.image.label}
                    />
                  )}
                  {icongrid.translations.titleItemText && (
                    <HeadingTwo
                      styleOverride={() => `
                        ${fontStyleOverride(config.itemHeaderFont)}
                        width: ${
                          config.itemHeaderFont && config.itemHeaderFont.width
                            ? `${config.itemHeaderFont.width}%`
                            : '90%'
                        };
                        margin: 4px 0;
                      `}
                    >
                      {icongrid.translations.titleItemText}
                    </HeadingTwo>
                  )}
                  {icongrid.translations.descriptionItemText && (
                    <Paragraph
                      styleOverride={() => `
                        ${fontStyleOverride(
                          config.itemDescriptionFont || config.descriptionFont,
                        )}
                        width: 90%;
                        margin: 4px 0px;
                      `}
                    >
                      {icongrid.translations.descriptionItemText}
                    </Paragraph>
                  )}
                </IconGridItem>,
              ])}
            </ItemsContainer>
          </IconGridContainer>
        </ContentWrapper>
      </ImageOverlay>
    </Container>
  );
}

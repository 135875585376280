// @flow
import React from 'react';
import styled from 'styled-components';

import FileUpload from './FileUpload';

const Container = styled.div`
  padding: 25px;
  width: 790px;
`;
const Heading = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #474747;
`;
const SubHeading = styled.div`
  font-size: 13px;
  color: #757575;
`;
const ColouredActionLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #d0021b;
`;
const ImageBackground = styled.div`
  width: 270px;
  height: 115px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px 0;
`;
const NoImageText = styled.span`
  color: #757575;
  font-size: 13px;
`;
const Image = styled.img`
  max-width: 170px;
  max-height: 100px;
  height: auto;
`;
const ImagePreview = ({ url, forKey }: { url: string, forKey: string }) => (
  <ImageBackground data-qa-hook={`${forKey}-brand-logo-container`}>
    {url ? (
      <Image data-qa-hook={`${forKey}-brand-logo`} src={url} />
    ) : (
      <NoImageText>No image uploaded</NoImageText>
    )}
  </ImageBackground>
);
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 270px;
  margin-bottom: 45px;
`;

type Props = {
  brandHeaderLogo: string,
  brandFooterLogo: string,
  addLogo: (image: File, forKey: string) => void,
  removeLogo: (forKey: string) => void,
};
export default function TemplateBrandLogo(props: Props) {
  return (
    <Container>
      <Heading>Header logo</Heading>
      <SubHeading>Logo should be transparent in .png format.</SubHeading>
      <ImagePreview forKey="header" url={props.brandHeaderLogo} />
      <Actions imageExists={props.brandHeaderLogo}>
        <FileUpload
          input={{
            name: 'brandHeaderLogo',
            onClick: e => {
              e.target.value = null;
            },
            onChange: e => props.addLogo(e.target.files[0], 'brandHeaderLogo'),
          }}
          label="select image"
          accept=".png"
        />
        {props.brandHeaderLogo && (
          <ColouredActionLink
            data-qa-hook="delete-brand-header"
            onClick={() => props.removeLogo('brandHeaderLogo')}
          >
            delete
          </ColouredActionLink>
        )}
      </Actions>
      <Heading>Footer logo</Heading>
      <SubHeading>Logo should be transparent in .png format.</SubHeading>
      <ImagePreview forKey="footer" url={props.brandFooterLogo} />
      <Actions imageExists={props.brandFooterLogo}>
        <FileUpload
          input={{
            name: 'brandFooterLogo',
            onClick: e => {
              e.target.value = null;
            },
            onChange: e => props.addLogo(e.target.files[0], 'brandFooterLogo'),
          }}
          label="select image"
          accept=".png"
        />
        {props.brandFooterLogo && (
          <ColouredActionLink
            data-qa-hook="delete-brand-footer"
            onClick={() => props.removeLogo('brandFooterLogo')}
          >
            delete
          </ColouredActionLink>
        )}
      </Actions>
    </Container>
  );
}

// @flow
import React from 'react';
import { connect } from 'react-redux';
import FinanceOptions from '../components/FinanceOptions/FinanceOptions';

import { actions } from '../state/actions/finance';

const mapStateToProps = ({ finance, site }) => ({
  finance,
  siteId: site.currentSiteId,
});

const mapDispatchToProps = dispatch => ({
  updateFinanceOptions: finance =>
    dispatch(actions.updateFinanceOptions(finance)),
  saveFinanceOptions: siteId => dispatch(actions.saveFinanceOptions(siteId)),
});

function FinanceOptionsContainer(props) {
  return <FinanceOptions {...props} />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinanceOptionsContainer);

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Twitter({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 24 24" width="1em" height="1em">
        <path d="M23 4.251a9.43 9.43 0 0 1-2.71.757 4.795 4.795 0 0 0 2.076-2.652c-.911.55-1.92.95-2.997 1.163A4.68 4.68 0 0 0 15.923 2c-2.607 0-4.718 2.147-4.718 4.795 0 .376.04.742.122 1.093C7.403 7.69 3.924 5.78 1.599 2.876a4.851 4.851 0 0 0-.639 2.41c0 1.662.838 3.131 2.106 3.992A4.556 4.556 0 0 1 .92 8.68v.06c0 2.325 1.628 4.26 3.787 4.7a4.683 4.683 0 0 1-1.243.168c-.302 0-.6-.03-.887-.09.6 1.906 2.345 3.291 4.411 3.33A9.372 9.372 0 0 1 0 18.834 13.048 13.048 0 0 0 7.223 21c8.69 0 13.438-7.308 13.438-13.646 0-.208-.005-.416-.015-.619A9.688 9.688 0 0 0 23 4.251z" />
      </svg>
    </IconWrapper>
  );
}

Twitter.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

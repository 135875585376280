// @flow

import pathOr from 'ramda/src/pathOr';

import { type Error } from '../state/types/error';

const httpError = (error: any, defaultMessage: string): Error => ({
  message: pathOr(defaultMessage, ['response', 'data'], error),
});

export default httpError;

/* eslint-disable quote-props, quotes, import/prefer-default-export */

const previewShortListedVehicles = [
  {
    id: '53482',
    name: '2015 XE',
    images: [
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_1_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_2_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_3_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_4_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_6_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_5_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_7_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_8_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_9_b79776471bc84e12.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/AB4/BN0/GA9/106/24/1024/SAJAB4BN0GA910624_0_4A74F48F66F6DBF3.jpg',
    ],
    odometer: '4.500 Km',
    engine: '2.0 i4 Diesel (180PS',
    transmission: 'Automatic',
    fuel: 'Diesel',
    views: null,
    price: {
      currency: 'EUR',
      value: 36600,
      monthly: 0,
      apr: 0,
    },
    co2EmissionsCombined: null,
    distanceFromRetailer: 0,
    retailerName: 'Walter Coenen GmbH',
    retailerId: '1333',
    description: 'XE 2.0 I4 DIESEL (180PS) PRESTIGE SALOON',
  },
  {
    id: '55180',
    name: '2015 XF (X260)',
    images: [
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_1_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_2_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_3_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_4_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_5_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_6_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_7_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_8_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_9_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_10_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_11_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_12_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_13_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_14_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_15_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_16_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_17_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_18_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_19_9b944a86840c9fc8.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_20_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_21_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_22_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_23_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_24_31b9364182c383d9.jpg',
      'http://images-imsuat.motortrak.com/S/SAJ/BB4/BNX/GCY/028/44/1024/SAJBB4BNXGCY02844_25_31b9364182c383d9.jpg',
    ],
    odometer: {
      reading: 33120,
      units: 'Km',
    },
    engine: '2.0 i4 Diesel (180PS',
    transmission: 'Automatic',
    fuel: 'Diesel',
    views: null,
    price: {
      currency: 'EUR',
      value: 38990,
      monthly: 0,
      apr: 0,
    },
    co2EmissionsCombined: null,
    distanceFromRetailer: 0,
    retailerName: 'Walter Coenen GmbH',
    retailerId: '1333',
    description: 'XF (X260) 2.0 I4 DIESEL (180PS) PRESTIGE SALOON',
  },
];

export { previewShortListedVehicles };

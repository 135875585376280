// @flow
import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MarkerClusterer from '@google/markerclustererplus';

import InfoWindow from './InfoWindow';
import { actions } from './reducer';
import { google } from '../../utilities/googleObject';
import { actions as routerActions } from '../../actions/router';
import { IDealerLocatorConfig } from './DealerLocator';

type DealerLocator = {
  countryCode: string,
  location: {
    latitude: Number,
    longitude: Number,
  },
  ref: HTMLElement,
  locationCallback: (lat: Number, long: Number) => void,
  dealers: Object[],
  status: String,
};

export function useDealerLocator(
  dispatch: (action: string) => void,
  config: IDealerLocatorConfig,
  globalStyling: Object,
): DealerLocator {
  const {
    flipCoordinates,
    defaultCenterPoint,
    defaultZoomLevel,
    translations,
    pinIcon,
    selectedPinIcon,
    clusterIcon,
    clusterFontColour,
  } = config;
  const ref = useRef();
  const map = useRef();
  const markers = useRef([]);
  const activeMarker = useRef();
  const activeWindow = useRef();
  const zoomThreshold = 8;

  const [currentZoomLevel, setZoomLevel] = useState(defaultZoomLevel);
  const [locationSubmitted, setLocationSubmitted] = useState(false);

  const countryCode = useSelector(
    state => state.config.config.global.inventory.searchCountryCode,
  );
  const location = useSelector(
    state => state.shared.sessionPreferences.location,
  );
  const { dealers, status } = useSelector(state => state.dealerLocator);
  const { buttonStyle } = globalStyling.uiElements.secondaryButton;

  const applyCoordinateFlip = (coordinates: Object) =>
    flipCoordinates
      ? { lat: coordinates[1], lng: coordinates[0] }
      : { lat: coordinates[0], lng: coordinates[1] };

  // initialise map
  useEffect(() => {
    map.current = new google.maps.Map(ref.current, {
      zoom: defaultZoomLevel,
      center: defaultCenterPoint,
      gestureHandling: 'none',
    });
    const zoomListener = map.current.addListener('zoom_changed', () => {
      const newZoomLevel = map.current.getZoom();
      setZoomLevel(newZoomLevel);
    });
    return () => {
      google.maps.event.removeListener(zoomListener);
    };
  }, [config]);

  // compare zoom level to threshold before fetching all dealers
  useEffect(() => {
    if (currentZoomLevel <= zoomThreshold && locationSubmitted) {
      dispatch(actions.getAllDealers());
      setLocationSubmitted(false);
    }
  }, [currentZoomLevel]);

  useEffect(() => {
    dispatch(actions.getAllDealers());
  }, []);

  /*
  when dealers changes, if there are results then map over them.
  - if markers already exist on map then remove them (this was causing rendering issues).
  - reset markers array.
  - generate a new bounds object
  - for each dealer, generate an infowindow and a marker, adding onclick and close
  listeners to each instance of them.
  - extend the bounds of the map after each dealer has been created.
  - generate the markercluster.
  - if we have submitted our location, apply bounds to the map.
  */
  useEffect(() => {
    if (dealers.length > 0) {
      markers.current.forEach(m => {
        m.setOptions({ map: null, visible: false });
      });
      markers.current = [];
      const bounds = new google.maps.LatLngBounds();

      dealers.forEach((d, index) => {
        const infoWindow = new google.maps.InfoWindow({
          content: InfoWindow({
            dealer: d,
            translations,
            buttonStyle,
            onButtonClick: () =>
              dispatch(
                routerActions.navigate(`/searchresults?dealerId=${d.id}`),
              ),
          }),
        });
        const marker = new google.maps.Marker({
          position: applyCoordinateFlip(d.location.coordinates),
          map: map.current,
          icon:
            index === activeMarker &&
            activeMarker.current &&
            activeMarker.current.id
              ? selectedPinIcon.src
              : pinIcon.src,
          id: index,
        });
        infoWindow.addListener('closeclick', () => {
          marker.setIcon(pinIcon.src);
          activeMarker.current = null;
        });
        marker.addListener('click', () => {
          if (activeMarker && activeMarker.current) {
            activeMarker.current.setIcon(pinIcon.src);
          }
          if (activeWindow && activeWindow.current) {
            activeWindow.current.close();
          }
          marker.setIcon(selectedPinIcon.src);
          activeMarker.current = marker;
          activeWindow.current = infoWindow;
          infoWindow.open(map.current, marker);
        });
        markers.current = [...markers.current, marker];
        bounds.extend(applyCoordinateFlip(d.location.coordinates));
      });
      // eslint-disable-next-line
      const markerCluster = new MarkerClusterer(map.current, markers.current, {
        styles: [
          {
            url: clusterIcon.src,
            height: 26,
            width: 26,
            textColor: clusterFontColour
              ? clusterFontColour.value
              : globalStyling.colours.primaryColour.value,
          },
        ],
      });
      if (locationSubmitted) {
        map.current.fitBounds(bounds);
        map.current.panToBounds(bounds);
      }
    }
  }, [dealers, config]);

  const locationCallback = (lat, long) => {
    dispatch(actions.getClosestDealers({ latitude: lat, longitude: long }));
    setLocationSubmitted(true);
  };

  return {
    countryCode,
    location,
    ref,
    locationCallback,
    dealers,
    status,
  };
}

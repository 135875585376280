// @flow
import { useState, useEffect, useRef } from 'react';

import { google } from '../../utilities/googleObject';

type LocationHook = {
  formattedLocation: string,
  getBrowserLocation: () => void,
  getInputLocation: (suggestion: Object) => void,
  onInputChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  suggestions: Object[],
  visible: boolean,
  currentlySelected: number,
  setCurrentlySelected: (index: number) => void,
  inputRef: HTMLInputElement | null,
  onInputKeyDown: (event: SyntheticEvent<HTMLInputElement>) => void,
  onInputBlur: (event: SyntheticEvent<HTMLInputElement>) => void,
  onInputFocus: (event: SyntheticEvent<HTMLInputElement>) => void,
};

export default (
  browserLocationCallback: (lat: string, long: string) => void,
  inputLocationCallback: (lat: string, long: string) => void,
  options: {
    countryCode?: string,
    latitude?: string,
    longitude?: string,
  },
): LocationHook => {
  const geocoder = new google.maps.Geocoder();
  const autocomplete = new google.maps.places.AutocompleteService();
  const [formattedLocation, setFormattedLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState(0);
  const [status, setStatus] = useState('unsubmitted');
  const [attempts, setAttempts] = useState(0);
  const inputRef = useRef(null);

  const { countryCode, latitude, longitude } = options;

  const applyRequestParams = (params, country) => {
    const restrictions = country ? { componentRestrictions: { country } } : {};
    return {
      ...params,
      ...restrictions,
    };
  };
  /*
  these strings are response types from the geocode api that
  are the types we want to display. This is essentially so we dont show things
  like building names in our results.
  */
  const findResults = results =>
    results.find(res => res.types.find(t => t === 'locality')) ||
    results.find(res => res.types.find(t => t === 'postal_code')) ||
    results.find(res => res.types.find(t => t === 'route'));

  useEffect(() => {
    if (latitude && longitude) {
      setAttempts(0);
      setStatus('submitting');
    } else {
      setFormattedLocation('');
    }
  }, [latitude, longitude]);
  useEffect(() => {
    if (attempts < 3 && status === 'submitting') {
      geocoder.geocode(
        applyRequestParams({
          location: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        }),
        (results, stat) => {
          let timeout;
          if (stat === 'OVER_QUERY_LIMIT') {
            timeout = setTimeout(() => setAttempts(attempts + 1), 2000);
          }
          if (stat === google.maps.GeocoderStatus.OK && results.length) {
            const address = findResults(results);
            if (address) {
              clearTimeout(timeout);
              setFormattedLocation(address.formatted_address);
              setAttempts(4);
              setStatus('successful');
            }
          }
        },
      );
    }
  }, [status, attempts]);

  const getInputLocation = (suggestion = {}) => {
    setSuggestions([]);
    setFormattedLocation(suggestion.description);
    geocoder.geocode(
      applyRequestParams({ placeId: suggestion.place_id }),
      (results, stat) => {
        if (stat === google.maps.GeocoderStatus.OK && results.length) {
          const address = findResults(results);
          if (address) {
            inputLocationCallback(
              address.geometry.location.lat().toString(),
              address.geometry.location.lng().toString(),
            );
          }
        }
      },
    );
  };

  const getBrowserLocation = () => {
    if (!('geolocation' in navigator)) {
      return;
    }
    navigator.geolocation.getCurrentPosition(geo => {
      geocoder.geocode(
        applyRequestParams({
          location: {
            lat: parseFloat(geo.coords.latitude),
            lng: parseFloat(geo.coords.longitude),
          },
        }),
        (results, stat) => {
          if (stat === google.maps.GeocoderStatus.OK && results.length) {
            const address = findResults(results);
            if (address) {
              setFormattedLocation(address.formatted_address);
              browserLocationCallback(
                address.geometry.location.lat().toString(),
                address.geometry.location.lng().toString(),
              );
            }
          }
        },
      );
    });
  };

  const onInputChange = ({ currentTarget: { value } }) => {
    setFormattedLocation(value);
    if (value.length) {
      setVisible(true);
    }
    autocomplete.getPlacePredictions(
      applyRequestParams(
        {
          input: value,
        },
        countryCode,
      ),
      (results, stat) => {
        if (stat === google.maps.GeocoderStatus.OK && results.length) {
          const filteredResults = results.filter(
            r =>
              r.types.includes('locality') ||
              r.types.includes('postal_code') ||
              r.types.includes('route'),
          );
          setSuggestions(filteredResults);
        }
      },
    );
  };

  const keyboardCodes = {
    space: ' ',
    enter: 'Enter',
    down: 'ArrowDown',
    up: 'ArrowUp',
  };

  const onInputKeyDown = ev => {
    if (ev.key === keyboardCodes.up) {
      ev.preventDefault();
      const newSelected =
        currentlySelected === 0
          ? suggestions.length - 1
          : currentlySelected - 1;
      setCurrentlySelected(newSelected);
    }
    if (ev.key === keyboardCodes.down) {
      ev.preventDefault();
      const newSelected =
        currentlySelected === suggestions.length - 1
          ? 0
          : currentlySelected + 1;
      setCurrentlySelected(newSelected);
    }
    if (ev.key === keyboardCodes.enter) {
      ev.preventDefault();
      getInputLocation(suggestions[currentlySelected]);
      inputRef.current.blur();
    }
  };

  const onInputBlur = () => setVisible(false);
  const onInputFocus = () => setCurrentlySelected(0);

  return {
    formattedLocation,
    getBrowserLocation,
    getInputLocation,
    onInputChange,
    suggestions,
    visible,
    currentlySelected,
    setCurrentlySelected,
    inputRef,
    onInputKeyDown,
    onInputBlur,
    onInputFocus,
  };
};

import { put, takeEvery } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import {
  actions as customerActions,
  constants as customerConstants,
} from '../../shared/CustomerLogin';
import { actions as routerActions } from '../../actions/router';

function* signIn(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  yield put(customerActions.postLogin(page, instance, payload));
}

function* signInSuccess(action) {
  const {
    module: { page },
    payload: { redirect },
  } = action;
  if (page.toLowerCase() !== '/signin') return;

  if (redirect) {
    yield put(
      routerActions.navigateWithPayload(`/${redirect.path}`, redirect.payload),
    );
  } else {
    yield put(routerActions.navigate('/'));
  }
}

function* signInFailure(action) {
  const {
    module: { page, instance },
    payload,
  } = action;
  yield put(actions.signInFailure(page, instance, payload));
}

export default function* SignInSaga() {
  yield takeEvery(constants.SIGN_IN, signIn);
  yield takeEvery(customerConstants.GET_CUSTOMER_SUCCESS, signInSuccess);
  yield takeEvery(customerConstants.LOGIN_FAILURE, signInFailure);
}

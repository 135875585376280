// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import VehicleRetailerInfoDetails from './VehicleRetailerInfoDetails';
import type { IVehicleRetailerInfoConfig } from './types';

type Props = {
  config: IVehicleRetailerInfoConfig,
};

export default (props: Props) => {
  const vehicleInfo = useSelector(state => {
    if (!state.dataProvider.vehicleInfo) {
      return null;
    }

    const {
      id: vehicleId,
      retailerInformation,
    } = state.dataProvider.vehicleInfo;

    if (props.config.flipRetailerCoordinates) {
      const clonedLatlong = [...retailerInformation.location.coordinates];

      return {
        vehicleId,
        retailerInformation: {
          ...retailerInformation,
          location: { coordinates: clonedLatlong.reverse() },
        },
      };
    }

    return { vehicleId, retailerInformation };
  });

  return (
    vehicleInfo && (
      <VehicleRetailerInfoDetails
        {...{
          ...props,
          vehicleInfo,
        }}
      />
    )
  );
};

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CreatePage } from 'cms-ui';

import { actions as pageActions } from '../state/actions/page';
import { actions as contextActions } from '../state/actions/context';
import { actions as siteBuilderActions } from '../state/actions/siteBuilder';
import { actions as pageTemplatesActions } from '../state/actions/pageTemplates';
import { stripNonUrlSafeCharsFromPageUrl } from '../state/reducers/page';

import type { Page } from '../state/types/page';

type PageTemplateOption = { value: string, label: string };

type Props = {
  renderButton?: (() => void) => Node,
  loadPageTemplates: Function,
  showAccordion: Function,
  pageTemplates: PageTemplateOption[],
  ui: { createPage: boolean },
  page: Page,
};

const mapStateToProps = ({ pageTemplates, siteBuilder, site }, { page }) => ({
  pageTemplates: pageTemplates.pageTemplates,
  ui: siteBuilder.ui,
  page,
  siteId: site.currentSiteId,
});

const mapDispatchToProps = (dispatch, { renderButton }) => ({
  loadPageTemplates: () => dispatch(pageTemplatesActions.loadPageTemplates()),
  showAccordion: (id, show) =>
    dispatch(siteBuilderActions.showAccordion(id, show)),
  renderButton: siteId => value =>
    renderButton &&
    renderButton(() => {
      dispatch(pageActions.deletePage(siteId, value.id));
      dispatch(contextActions.clearContext());
    }),
  onPageChange: page =>
    dispatch(pageActions.updatePage(stripNonUrlSafeCharsFromPageUrl(page))),
});

const combineProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  renderButton: dispatchProps.renderButton(stateProps.siteId),
});

class EditPageWithLifecycle extends Component<Props> {
  componentDidMount() {
    this.props.loadPageTemplates();
  }

  render() {
    const {
      renderButton,
      pageTemplates,
      showAccordion,
      ui,
      page,
      onPageChange,
    } = this.props;
    return (
      <CreatePage
        title="Page settings"
        pageTemplates={pageTemplates}
        value={page}
        onChange={onPageChange}
        showAccordion={showAccordion}
        ui={ui}
        renderButton={renderButton}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  combineProps,
)(EditPageWithLifecycle);

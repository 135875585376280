import Icon from '../../modules/SearchResults/Icon';

export { id as reducerId } from '../../modules/SearchResults/reducer';

export { default as component } from './SearchResults';
export { default as effects } from '../../modules/SearchResults/effects';
export { default as config } from '../../modules/SearchResults/config';
export {
  default as defaultConfig,
} from '../../modules/SearchResults/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/SearchResults/reducer';

export const name = 'SearchResults';
export const id = 'SearchResults';
export const availableToAdministration = false;
export const description = 'Search Results Page';
export const icon = Icon;

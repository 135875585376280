// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import media from '../theme';

export const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  ${({ open }) => open && 'z-index: 1000'};
  overflow: hidden;
  transition: visibility 0.35s, opacity 0.35s;
  ${({ open }) => open && 'visibility: visible; opacity: 1;'};
`;

export const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  ${media.min.medium`
    height: auto;
    width: ${({ modalWidth }) => (modalWidth ? `${modalWidth}px;` : 'auto')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `};
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
`;

const Close = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  margin: 10px 10px;
  cursor: pointer;
`;

const ClickComponent = styled.div`
  display: flex;
  * {
    cursor: pointer;
  }
`;

export default class ModalOverlay extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onToggleModal = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render = () => {
    const { open } = this.state;
    const {
      closeIconUrl,
      component,
      modalWidth,
      renderComponentWithProps,
      children,
    } = this.props;
    return (
      <Fragment>
        <Overlay open={open}>
          <ModalContentWrapper modalWidth={modalWidth}>
            <Close
              src={closeIconUrl}
              alt="close"
              onClick={() => this.onToggleModal()}
            />
            <ModalContent>
              {renderComponentWithProps
                ? renderComponentWithProps(this.onToggleModal)
                : children}
            </ModalContent>
          </ModalContentWrapper>
        </Overlay>
        <ClickComponent onClick={this.onToggleModal}>
          {component}
        </ClickComponent>
      </Fragment>
    );
  };
}

import { takeLatest, put, select, call } from 'redux-saga/effects';
import { constants, actions } from './reducer';
import {
  getModelGroupsBaseUrl,
  getAVLConfig,
} from '../../shared/selectors/settings';
import { get } from '../../helpers/http';

function* getModelGroups() {
  const modelGroupsBaseUrl = yield select(getModelGroupsBaseUrl);
  const { locale, make, country } = yield select(getAVLConfig);

  const url = `${modelGroupsBaseUrl}${make.replace(
    ' ',
    '',
  )}/${country}/${locale}/model_search`;
  try {
    const response = yield call(get, { url });
    yield put(actions.getModelGroupsSuccess(response.data));
  } catch (error) {
    console.log(error);
  }
}

export default function* modelSearchSaga() {
  yield takeLatest(constants.getModelGroups, getModelGroups);
}

// @flow
/* eslint-disable import/extensions */
import { templatePos2 } from './templatePos2';
import {
  titleize,
  convertImgToBase64,
  getVehicleImgsBase64,
  setLine,
  setDetailSection,
  setImageTable,
  setColumns,
  getSpacer,
  setPageBreak,
} from './pdf-utils';
import PageSizes from './pdfPageSizes';

const IMG_THRESHOLD = 8;

const [pageWidth, pageHeight] = PageSizes.A4;

export async function openPDF(document: { document: Object }, docTitle) {
  //* * pdfMake.createPdf returns 3 types of handles: open, print and download */

  try {
    const { default: pdfMake } = await import('pdfmake/build/pdfmake');
    const { default: pdfFonts } = await import('pdfmake/build/vfs_fonts');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const handle = pdfMake.createPdf(document);
    handle.download(
      `${docTitle.toLowerCase().replace(/ /g, '_')}_${Date.now()}.pdf`,
    );
  } catch (error) {
    /* eslint-disable no-console */
    console.warn('Failed to open pdf document', error);
    /* eslint-enable no-console */
  }
}

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const getValidDate = (date, locale) => {
  const validityDate = date ? new Date(date) : new Date();
  return validityDate
    .toLocaleDateString(locale.replace('_', '-'), dateOptions)
    .replace(/[^ -~]/g, '');
};

export async function myDeal({
  vehicle,
  finance,
  pdfConfig,
  vehicleImgUrl,
  financeHeaderDisplayList,
  financeDisplayList,
  financeTermsAndConditions,
}: {
  vehicle: Object,
  finance: Object,
  translations: Object,
  pdfConfig: Object,
  marketInfo: Object,
}) {
  const { brandLogoUrl } = pdfConfig;

  const brandLogo = await convertImgToBase64(brandLogoUrl);

  const {
    co2Emission,
    description,
    otherEquipment,
    locale,
    powerOutput,
    registrationDate,
    retailerInformation: {
      name,
      address,
      email = '',
      website = '',
      phone = '',
    },
    specification: {
      bodystyle,
      fuelType,
      odometer: { display: odometerDisplay },
      efficiency,
      engine,
      exterior,
      interior,
      transmission,
    },
    transmissionType,
  } = vehicle;

  const {
    pdfLineColor: { value: lineColor },
    translations: {
      exterior: exteriorLabel,
      interior: interiorLabel,
      pdfEfficiencyLabel,
      bodystyle: bodystyleLabel,
      pdfEmmisionsLabel,
      engine: engineLabel,
      fuelType: fuelTypeLabel,
      display: odometerLabel,
      vehiclePDFTransmission,
      vehiclePDFFuelType,
      vdpMyDealHeader,
      vdpOtherEquipmentHeader,
      vdpSpecificationHeader,
      vehiclePDFImages,
      vehiclePDFMiles,
      vehiclePDFPower,
      vehiclePDFWebsite,
      vehiclePDFRegistration,
      vdpRetailerEmailLabel,
      vdpRetailerPhoneLabel,
      financeValidFom,
      financeValidUntil,
    },
  } = pdfConfig;

  const { validUntil, hideValidUntil } = finance;
  const formattedRegDate = getValidDate(registrationDate, locale);

  let carImgs = await getVehicleImgsBase64(vehicleImgUrl);

  const emailText = email ? `${vdpRetailerEmailLabel}: ${email}` : '';
  const websiteText = website ? `${vehiclePDFWebsite}: ${website}` : '';

  const logoAddressSection = [
    {
      columns: [
        {
          image: `${brandLogo}`,
          alignment: 'center',
          width: 120,
          margin: [0, 10, 0, 20],
        },
        [
          {
            text: name,
            alignment: 'right',
            fontSize: 9,
          },
          {
            text: `${address[0]}`,
            alignment: 'right',
            fontSize: 9,
          },
          {
            text: `${address[1]}, ${address[2]}`,
            alignment: 'right',
            fontSize: 9,
            margin: [0, 2],
          },
          emailText && {
            canvas: [
              {
                type: 'line',
                lineWidth: 2,
                color: '#D8D8D8',
                x1: 125,
                y1: 5,
                x2: 400,
                y2: 5,
              },
            ],
          },
          emailText && {
            text: emailText,
            alignment: 'right',
            fontSize: 9,
            margin: [4, 4],
          },
          websiteText && {
            canvas: [
              {
                type: 'line',
                lineWidth: 2,
                color: '#D8D8D8',
                x1: 125,
                y1: 5,
                x2: 400,
                y2: 5,
              },
            ],
          },
          websiteText && {
            text: websiteText,
            alignment: 'right',
            fontSize: 9,
            margin: [4, 2],
          },
        ],
      ],
    },
  ];

  const vehicleSpecHeaderRow = [
    odometerDisplay && {
      text: `${vehiclePDFMiles}`,
      bold: true,
      alignment: 'left',
      color: '#7E7E7E',
      fontSize: 10,
      margin: [5, 2],
    },
    transmissionType && {
      text: `${vehiclePDFTransmission}`,
      bold: true,
      alignment: 'left',
      color: '#7E7E7E',
      fontSize: 10,
      margin: [5, 2],
    },
    fuelType && {
      text: `${vehiclePDFFuelType}`,
      bold: true,
      alignment: 'left',
      color: '#7E7E7E',
      fontSize: 10,
      margin: [5, 2],
    },
    formattedRegDate && {
      text: `${vehiclePDFRegistration}`,
      bold: true,
      alignment: 'left',
      color: '#7E7E7E',
      fontSize: 10,
      margin: [5, 2],
    },
    powerOutput && {
      text: `${vehiclePDFPower}`,
      bold: true,
      alignment: 'left',
      color: '#7E7E7E',
      fontSize: 10,
      margin: [5, 2],
    },
  ].filter(Boolean);

  const vehicleSpecInfoRow = [
    odometerDisplay && {
      text: odometerDisplay,
      bold: true,
      alignment: 'left',
      fontSize: 10,
      margin: [5, 2],
    },
    transmissionType && {
      text: transmissionType,
      bold: true,
      alignment: 'left',
      fontSize: 10,
      margin: [5, 2],
    },
    fuelType && {
      text: fuelType,
      bold: true,
      alignment: 'left',
      fontSize: 10,
      margin: [5, 2],
    },
    formattedRegDate && {
      text: formattedRegDate,
      bold: true,
      alignment: 'left',
      fontSize: 10,
      margin: [5, 2],
    },
    powerOutput && {
      text: powerOutput,
      bold: true,
      alignment: 'left',
      fontSize: 10,
      margin: [5, 2],
    },
  ].filter(Boolean);

  const widths = [false, false, false, false, false]
    .fill(90, 0, vehicleSpecInfoRow.length)
    .filter(Boolean);

  const vehicleSpecSection = [
    {
      table: {
        headerRows: 0,
        widths,
        body: [vehicleSpecHeaderRow, vehicleSpecInfoRow],
      },
      layout: {
        vLineColor: '#D8D8D8',
        hLineColor: 'white',
      },
    },
  ];

  const mainCarDisplay = [
    {
      table: {
        headerRows: 0,
        widths: [280, '*'],
        body: [
          [
            {
              rowSpan: 4,
              stack: [
                {
                  image: `${carImgs[0]}`,
                  alignment: 'center',
                  width: 250,
                  height: 160,
                  margin: [0, 0, 0, 20],
                },
              ],
            },
            [
              {
                text:
                  financeHeaderDisplayList[0] &&
                  financeHeaderDisplayList[0].value,
                alignment: 'left',
                bold: true,
                fontSize: 12,
                margin: [0, 5, 0, 0],
              },
              {
                text:
                  financeHeaderDisplayList[0] &&
                  financeHeaderDisplayList[0].label,
                alignment: 'left',
                color: '#7E7E7E',
                fontSize: 10,
                margin: [0, 0, 0, 3],
              },
            ],
          ],
          [
            {},
            [
              {
                text:
                  financeHeaderDisplayList[1] &&
                  financeHeaderDisplayList[1].value,
                alignment: 'left',
                bold: true,
                fontSize: 12,
                margin: [0, 3, 0, 0],
              },
              {
                text:
                  financeHeaderDisplayList[1] &&
                  financeHeaderDisplayList[1].label,
                alignment: 'left',
                color: '#7E7E7E',
                fontSize: 10,
                margin: [0, 0, 0, 3],
              },
            ],
          ],
          [
            {},
            [
              {
                text:
                  financeHeaderDisplayList[2] &&
                  financeHeaderDisplayList[2].value,
                alignment: 'left',
                bold: true,
                fontSize: 12,
                margin: [0, 3, 0, 0],
              },
              {
                text:
                  financeHeaderDisplayList[2] &&
                  financeHeaderDisplayList[2].label,
                alignment: 'left',
                color: '#7E7E7E',
                fontSize: 10,
                margin: [0, 0, 0, 3],
              },
            ],
          ],
          [
            {},
            [
              {
                text:
                  financeHeaderDisplayList[3] &&
                  financeHeaderDisplayList[3].value,
                alignment: 'left',
                bold: true,
                fontSize: 12,
                margin: [0, 3, 0, 0],
              },
              {
                text:
                  financeHeaderDisplayList[3] &&
                  financeHeaderDisplayList[3].label,
                alignment: 'left',
                color: '#7E7E7E',
                fontSize: 10,
                margin: [0, 0, 0, 3],
              },
            ],
          ],
        ],
      },
      layout: {
        hLineColor: 'white',
        vLineColor: 'white',
      },
    },
  ];

  const colouredLine = setLine({
    lineWidth: 4,
    color: lineColor,
    x1: 0,
    y1: 0,
    x2: 525,
    y2: 0,
  });

  const spacer25 = getSpacer({ height: 25 });
  const spacer15 = getSpacer({ height: 15 });
  const spacer5 = getSpacer({ height: 5 });

  const carDescriptionTelephone = setColumns(
    [
      {
        text: `${description}`,
        alignment: 'left',
        bold: true,
        fontSize: 16,
        width: 350,
        margin: [0, 20],
      },
      {
        text: `${vdpRetailerPhoneLabel}: ${phone}`,
        alignment: 'right',
        fontSize: 10,
        width: '*',
        margin: [10, 20],
      },
    ],
    2,
  );

  const untilDate = getValidDate(validUntil, locale);
  const fromDate = getValidDate(new Date(), locale);

  const validDateSection = !hideValidUntil
    ? [
        {
          table: {
            headerRows: 0,
            widths: [250, 250],
            body: [
              [
                {
                  text: `${financeValidFom}`,
                  alignment: 'left',
                  fontSize: 10,
                  color: '#7E7E7E',
                  fillColor: '#F0F0F0',
                },
                {
                  text: `${financeValidUntil}`,
                  alignment: 'left',
                  fontSize: 10,
                  color: '#7E7E7E',
                  fillColor: '#F0F0F0',
                },
              ],
              [
                {
                  text: `${fromDate}`,
                  alignment: 'left',
                  fontSize: 14,
                  fillColor: '#F0F0F0',
                },
                {
                  text: `${untilDate}`,
                  alignment: 'left',
                  fontSize: 14,
                  fillColor: '#F0F0F0',
                },
              ],
            ],
          },
          layout: {
            hLineColor: '#F0F0F0',
            vLineColor: '#F0F0F0',
            defaultBorder: false,
            paddingLeft() {
              return 10;
            },
            paddingRight() {
              return -5;
            },
            paddingTop() {
              return 10;
            },
            paddingBottom() {
              return 10;
            },
          },
        },
      ]
    : [];

  const specificationRows = [
    { label: `${exteriorLabel}`, value: exterior && `${exterior}` },
    {
      label: `${vehiclePDFTransmission}`,
      value: transmission && `${transmission}`,
    },
    { label: `${pdfEfficiencyLabel}`, value: efficiency && `${efficiency}` },
    { label: `${interiorLabel}`, value: interior && `${interior}` },
    { label: `${engineLabel}`, value: engine && `${engine}` },
    { label: `${pdfEmmisionsLabel}`, value: co2Emission && `${co2Emission}` },
    { label: `${bodystyleLabel}`, value: bodystyle && `${bodystyle}` },
    { label: `${fuelTypeLabel}`, value: fuelType && `${fuelType}` },
    {
      label: `${odometerLabel}`,
      value: odometerDisplay && `${odometerDisplay}`,
    },
  ].filter(({ value }) => Boolean(value));

  const equipments = otherEquipment.map(({ equipment }) => equipment);
  const equipmentRows = []
    .concat(...equipments)
    .map(equipment => ({ value: equipment }));

  const myDealRows = financeDisplayList;

  const equipmentTable = {
    table: {
      headerRows: 0,
      widths: [167, 167, 165],
    },
    layout: {
      vLineColor: 'white',
      hLineColor: 'white',
    },
  };

  const specificationsSection = setDetailSection({
    title: `${titleize(vdpSpecificationHeader)}`,
    tableData: specificationRows,
    lineColor,
  });
  const equipmentSection = setDetailSection({
    title: `${titleize(vdpOtherEquipmentHeader)}`,
    tableData: equipmentRows,
    rowCount: 3,
    table: equipmentTable,
    lineColor,
  });
  const myDealSection = setDetailSection({
    title: `${titleize(vdpMyDealHeader)}`,
    tableData: myDealRows,
    rowCount: 2,
    lineColor,
  });

  carImgs =
    carImgs.length > IMG_THRESHOLD ? carImgs.slice(0, IMG_THRESHOLD) : carImgs;

  const imgTable = setImageTable({
    title: `${titleize(vehiclePDFImages)}`,
    tableData: carImgs,
    rowCount: 4,
    lineColor,
  });

  const pageBreak = setPageBreak();

  const content = [].concat(
    logoAddressSection,
    spacer5,
    colouredLine,
    carDescriptionTelephone,
    vehicleSpecSection,
    spacer15,
    mainCarDisplay,
    specificationsSection,
    pageBreak,
    equipmentSection,
    pageBreak,
    imgTable,
    spacer25,
    myDealSection,
    spacer25,
    validDateSection,
  );

  return {
    pageSize: {
      width: pageWidth,
      height: pageHeight,
    },
    pageMargins: [40, 60, 40, 120],
    content,
    footer: {
      text: `${financeTermsAndConditions}`,
      fontSize: 8,
      margin: [20, 60, 20, 20],
    },
  };
}

export async function printPos({
  vehicle,
  pdfConfig,
  vehicleImgUrl,
}: {
  vehicle: Object,
  pdfConfig: Object,
  marketInfo: Object,
}) {
  const { brandLogoUrl } = pdfConfig;
  let carImgs = await getVehicleImgsBase64(vehicleImgUrl);

  const brandLogo = await convertImgToBase64(brandLogoUrl);

  const formattedRegDate = getValidDate(
    vehicle.registrationDate,
    vehicle.locale,
  );

  carImgs =
    carImgs.length > IMG_THRESHOLD ? carImgs.slice(0, IMG_THRESHOLD) : carImgs;

  const content = await templatePos2(
    vehicle,
    pdfConfig,
    carImgs,
    formattedRegDate,
    brandLogo,
  );

  return {
    pageSize: {
      width: pageWidth,
      height: pageHeight,
    },
    pageMargins: [40, 60, 40, 120],
    content,
  };
}

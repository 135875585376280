// @flow

import styled from 'styled-components';

import media from '../theme';

export const SeparatedListContainer = styled.div.withConfig({
  displayName: 'ListContainer',
})`
  font-size: 16px;
  line-height: 22px;
  color: #343434;
  break-inside: avoid;
  display: inline;
`;

export const SeparatedListTitle = styled.div.withConfig({
  displayName: 'ListTitle',
})`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #444444;
  margin-bottom: 27px;
`;

export const SeparatedList = styled.ul.withConfig({
  displayName: 'List',
})`
  list-style: none;
  padding: 0;
  margin: 0 0 21px 0;
  ${media.max.large`
    margin: 0;
  `};
`;

export const SeparatedListItem = styled.li.withConfig({
  displayName: 'ListItem',
})`
  padding: 0 0 20px 10px;
  font-size: 16px;
  ${({ flex }) => flex && 'display: flex'};
  page-break-inside: avoid;
  break-inside: avoid;
`;

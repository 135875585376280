import editor from './editor';

export default {
  id: 'DealerLocator',
  name: 'Dealer Locator',
  description: 'Edit Dealer Locator',
  defaultSlice: 'Content',
  defaultConfig: {
    flipCoordinates: false,
    pinIcon: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/v1580222253/cms/global/icons/default-icon-pin-grey.svg',
    },
    selectedPinIcon: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/v1580222253/cms/global/icons/default-icon-pin.svg',
    },
    clusterIcon: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/v1552906292/cms/lamborghini/content/Map_Square.png',
    },
    locationIcon: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/v1566817275/cms/global/icons/location.svg',
    },
    defaultZoomLevel: 13,
    defaultCenterPoint: {
      lat: 55.951168,
      lng: -3.2226967,
    },
    translations: {
      buttonText: 'View',
      description: 'Check out some Edinburgh dealers',
      inputPlaceholder: 'Placeholder',
      phoneLabel: 'Phone no.',
      noDealers: 'No Dealers',
    },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  requirements: {
    columns: [1],
  },
};

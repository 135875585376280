// @flow
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import templates from './Templates';
import { useBlogPost } from './useBlogPost';

import type { IBlogPostConfig } from './types';

type Props = {
  config: IBlogPostConfig,
};

export default ({ config, config: { postId, template } }: Props) => {
  const postData = useBlogPost(postId);
  const Template = templates[template];

  return postData ? (
    <Fragment>
      <Helmet>
        <title>{postData.title}</title>
        <meta name="description" content={postData.description} />
        <meta name="keywords" content={postData.keywords} />
      </Helmet>
      <Template config={config} postData={postData} />
    </Fragment>
  ) : null;
};

// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import type { Theme } from '../theme';
import type { InputLabelPosition } from './InputLabel';

type ThemeProps = {
  theme: Theme,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Prefix = styled.span`
  padding-right: ${({ theme }) => theme.layout.halfPadding};
`;

const StyledInput = styled.input.withConfig({
  displayName: 'Input',
})`
  ${({ theme, large }: { theme: Theme, large?: boolean }) => {
    let fs; // Font size
    let fc; // Font colour
    let bc; // Border color
    let pv; // Padding vertically
    let ph; // Padding horizontally

    if (large) {
      fs = theme.fontSizeContent;
      fc = theme.colours.secondary05;
      bc = theme.colours.secondary02;
      ph = 10;
      pv = 10;
    } else {
      fs = theme.fontSizeFormControls;
      fc = theme.colours.secondary03;
      bc = theme.colours.secondary03;
      ph = 4;
      pv = 2;
    }

    return css`
      color: ${fc};
      font-size: ${fs};
      border-color: ${bc};
      padding: ${pv}px ${ph}px ${pv}px ${ph}px;
    `;
  }};
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;

  box-sizing: border-box;

  ${({ prefix, inputWidth }) => {
    if (inputWidth) {
      return css`
        width: ${inputWidth}px;
      `;
    }

    if (prefix) {
      return css`
        overflow: hidden;
        width: 100%;
      `;
    }

    return css`
      width: auto;
    `;
  }} max-width: ${({ maxInputWidth }) =>
  maxInputWidth ? `${maxInputWidth}px` : 'unset'};
  border-width: 1px;
  border-style: solid;

  ${props =>
    props.inputLabelPosition === 'left' &&
    props.hasInputLabel &&
    css`
      border-left-width: 0;
      order: 2;
    `};
  ${props =>
    props.inputLabelPosition === 'right' &&
    props.hasInputLabel &&
    css`
      border-right-width: 0;
      text-align: right;
      order: 1;
    `};
  ${props =>
    props.error &&
    css`
      border-color: ${({ theme }: ThemeProps) => theme.colours.danger01};
    `};
  &::placeholder {
    color: ${({ theme }: ThemeProps) => theme.colours.highlight01};
  }
  &:focus {
    border-color: ${({ theme }: ThemeProps) => theme.colours.primary01};
    outline: none;
    appearance: none;
    ~ span {
      border-color: ${({ theme }: ThemeProps) => theme.colours.primary01};
    }
  }
  &:disabled {
    background-color: ${(props: ThemeProps) => props.theme.colours.disabled02};
  }
  `;

type Props = {
  name: string,
  value?: string | number,
  type: 'text' | 'number' | 'url',
  large?: boolean,
  error?: boolean,
  placeholder?: string,
  inputLabelPosition?: InputLabelPosition,
  hasInputLabel?: boolean,
  onBlur?: Function,
  onChange?: Function,
  prefix?: ?string,
  inputWidth?: ?number,
  maxInputWidth?: number,
  disabled?: boolean,
  max?: string,
  min?: string,
  step?: string,
};

export default function Input({
  name,
  value,
  large,
  error,
  inputLabelPosition,
  hasInputLabel,
  placeholder,
  type,
  onBlur,
  onChange,
  prefix,
  inputWidth,
  maxInputWidth,
  disabled,
  max,
  min,
  step,
}: Props) {
  const input = (
    <StyledInput
      inputWidth={inputWidth}
      maxInputWidth={maxInputWidth}
      onBlur={onBlur}
      onChange={onChange}
      inputLabelPosition={inputLabelPosition}
      hasInputLabel={hasInputLabel}
      prefix={prefix}
      placeholder={placeholder}
      type={type === 'text' || type === 'number' ? type : 'text'}
      name={name}
      value={value}
      large={large}
      error={error}
      disabled={disabled}
      max={max}
      min={min}
      step={step}
    />
  );

  if (!prefix) return input;

  return (
    <Container>
      <Prefix>{prefix}</Prefix>
      {input}
    </Container>
  );
}

Input.defaultProps = {
  value: '',
  large: false,
  error: false,
  placeholder: '',
  inputLabelPosition: 'left',
  hasInputLabel: false,
  onBlur: () => {},
  onChange: () => {},
  prefix: false,
  inputWidth: null,
  maxInputWidth: 300,
  disabled: false,
  max: null,
  min: null,
  step: null,
};

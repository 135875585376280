import { put, call, select, takeLatest } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getInventoryStatus,
} from '../selectors/settings';
import { actions as errorActions } from '../errors';

function* getVehicleInfo(action) {
  const { vehicleId } = action.payload;

  const { locale, make, market } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
  const inventoryStatus = yield select(getInventoryStatus);
  const inventoryUrl = `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${vehicleId}`;

  try {
    const vehicleResponse = yield call(get, {
      url: inventoryUrl,
      config: localeConfigSerializer(withLocaleQueryString && locale, {
        status: inventoryStatus,
      }),
    });

    yield put(actions.getVehicleInfoSuccess(vehicleResponse.data));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* VehicleInformationSaga() {
  yield takeLatest(constants.GET_VEHICLE_INFO, getVehicleInfo);
}

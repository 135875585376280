// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';
import CarouselUnselected from './CarouselUnselected';
import CarouselSelected from './CarouselSelected';
import { HeadingTwo, Paragraph } from '../Global';
import ContentWrapper from '../ContentWrapper';

const Background = styled.div`
  background-color: #f0f0f0;
`;

const TextImageContainer = styled.div.withConfig({
  displayName: 'TextImageContainer',
})`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  ${media.min.large`
    flex-direction: ${props => props.alignment};
    overflow: unset;
  `};
`;
const TextImageContentWrapper = styled.div`
  width: 100%;
  ${media.min.large`
    width: ${props => props.contentWidth}%;
    height: 500px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `};
`;
const TextImageContent = styled.div`
  padding: 20px 5%;
  ${media.min.large`
    padding: 0 60px;
    margin: auto 0;
  `};
`;
const TextImageImage = styled.img`
  width: 100%;
  ${media.min.large`
    height: 500px;
    object-fit: cover;
  `};
`;

const CarouselIndicatorsWapper = styled.div.withConfig({
  displayName: 'CarouselIndicatorsWapper',
})`
  z-index: 100;
  position: relative;
  width: 100%;
  bottom: 60px;
  display: flex;
  justify-content: ${props => props.flexJustify};
`;

const CarouselIndicators = styled.div.withConfig({
  displayName: 'CarouselIndicators',
})`
  display: flex;
  justify-content: space-between;
  width: ${props => props.numberOfImages * 25}px;
  margin: 0px auto;
`;

export default class TextImage extends React.Component<*, *> {
  state = { counter: 0 };

  componentDidMount() {
    this.startInterval();
  }

  componentWillUnmount() {
    this.resetInterval();
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.nextImage();
    }, 6000);
  }

  resetInterval() {
    clearInterval(this.interval);
  }

  interval = 0;

  nextImage() {
    const { counter } = this.state;
    const numImages = this.props.images.length;

    if (numImages === 1) return;

    this.setState({
      counter: (counter + 1) % numImages,
    });
  }

  handleClick = (index: number) => {
    this.setState({ counter: index });
    this.resetInterval();
    this.startInterval();
  };

  render() {
    return (
      <Background>
        <ContentWrapper contentWidth={this.props.contentWidth}>
          <TextImageContainer alignment={this.props.alignment}>
            <TextImageContentWrapper
              contentWidth={100 - this.props.imageWidthPercent}
            >
              <TextImageContent>
                <HeadingTwo
                  styleOverride={() => `
                    display: block;
                    font-size: 20px;
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    padding-top: 50px;
                    padding-bottom: 6px;
                  `}
                  mobileStyleOverride={() => [
                    {
                      queryPath: 'max.large',
                      template: 'display: none;',
                    },
                  ]}
                >
                  {this.props.title}
                </HeadingTwo>
                <Paragraph
                  styleOverride={() => `
                    font-size: 15px;
                    padding-bottom: 10px;
                    line-height: 20px;
                  `}
                >
                  {this.props.content}
                </Paragraph>
              </TextImageContent>
            </TextImageContentWrapper>
            <TextImageContentWrapper
              contentWidth={this.props.imageWidthPercent}
            >
              <TextImageImage src={this.props.images[this.state.counter]} />
              {this.props.images.length > 1 && (
                <CarouselIndicatorsWapper
                  flexJustify={
                    this.props.alignment === 'row-reverse'
                      ? 'flex-start'
                      : 'flex-end'
                  }
                >
                  <CarouselIndicators numberOfImages={this.props.images.length}>
                    {this.props.images.map((image, index) => (
                      <div
                        role="none"
                        onClick={() => this.handleClick(index)}
                        key={image}
                      >
                        {index === this.state.counter ? (
                          <CarouselSelected
                            width="12px"
                            height="12px"
                            colour={this.props.iconColour}
                          />
                        ) : (
                          <CarouselUnselected
                            width="12px"
                            height="12px"
                            colour={this.props.iconColour}
                          />
                        )}
                      </div>
                    ))}
                  </CarouselIndicators>
                </CarouselIndicatorsWapper>
              )}
            </TextImageContentWrapper>
          </TextImageContainer>
        </ContentWrapper>
      </Background>
    );
  }
}

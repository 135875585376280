import { moduleConstants } from '../../utilities/reducerMacros';
import { localiseCurrency } from '../localisation/numbers';
import { convertVehiclesPriceCurrency } from '../currencyConversion/helpers';

const modulePrefix = 'MOD_COMPARE_SHARED';

export const constants = moduleConstants(modulePrefix, [
  'TOGGLE_VEHICLE',
  'TOGGLE_VEHICLE_SUCCESS',
  'CHECK_VEHICLE_STATUS',
  'CHECK_VEHICLE_STATUS_SUCCESS',
  'UPDATE_VEHICLE_PRICES',
  'GET_EQUIPMENT_LIST',
  'GET_EQUIPMENT_LIST_SUCCESS',
]);

export const actions = {
  toggleVehicle: payload => ({ type: constants.TOGGLE_VEHICLE, payload }),
  toggleVehicleSuccess: payload => ({
    type: constants.TOGGLE_VEHICLE_SUCCESS,
    payload,
  }),
  checkVehicleStatus: () => ({ type: constants.CHECK_VEHICLE_STATUS }),
  checkVehicleStatusSuccess: payload => ({
    type: constants.CHECK_VEHICLE_STATUS_SUCCESS,
    payload,
  }),
  updateVehiclePrices: payload => ({
    type: constants.UPDATE_VEHICLE_PRICES,
    payload,
  }),
  getEquipmentList: () => ({ type: constants.GET_EQUIPMENT_LIST }),
  getEquipmentListSuccess: payload => ({
    type: constants.GET_EQUIPMENT_LIST_SUCCESS,
    payload,
  }),
};

const updateVehicleCurrencies = (state, action) => {
  const { payload } = action;
  const { exchangeRates } = payload;
  const { currency } = payload;

  if (!exchangeRates[currency]) {
    return state;
  }

  const vehicles = convertVehiclesPriceCurrency(
    state.vehicles,
    exchangeRates[currency].rates,
    currency,
  );

  return {
    ...state,
    vehicles,
  };
};

export const initialState = {
  vehicles: [],
  equipmentList: [],
};
const maxVehiclesToCompare = 3;
const take = n => collection => collection.slice(0, n);
const takeOnly = take(maxVehiclesToCompare);
const patch = (vehicles, vehicle) => {
  const formattedVehicle = {
    ...vehicle,
    price: {
      value: localiseCurrency(
        vehicle.price.value,
        vehicle.locale,
        vehicle.price.currency,
      ),
    },
  };
  return takeOnly([
    ...(vehicles.find(v => v.id === vehicle.id)
      ? [...vehicles.filter(v => v.id !== vehicle.id)]
      : [...vehicles, formattedVehicle]),
  ]);
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.TOGGLE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: patch(state.vehicles, payload.vehicle),
      };
    case constants.CHECK_VEHICLE_STATUS_SUCCESS:
      return {
        ...state,
        vehicles: [...payload.vehicles],
      };
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    case constants.GET_EQUIPMENT_LIST_SUCCESS:
      return {
        ...state,
        equipmentList: payload,
      };
    default:
      return state;
  }
}

// selectors
export const getVehicles = state => state.compare.vehicles || [];

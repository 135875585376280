// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import media from '../../theme';
import {
  Button,
  HeadingOne,
  HeadingThree,
  Paragraph,
  fontStyleOverride,
} from '../Global';
import ThinRight from '../JaguarIcons/Arrows/ThinRight';

type Props = {
  title: string,
  modelFinanceComponent: () => Node,
  imageUrl: string,
  description: string,
  alignment: string,
  financeButtonText: string,
  searchButtonText: string,
  availableText: string,
  findOutMoreText: string,
  disclaimerText: string,
  findOutMore: () => void,
  searchButtonOnClick: () => void,
  financeButtonOnClick: () => void,
  globalStyling: Object,
  showSearchButton: boolean,
  modelSummaryHeaderFont: Object,
  lamboDescriptionStyling: Object,
  showLamboCTAs: boolean,
};

const ButtonContainer = styled.div.withConfig({
  displayName: 'ButtonContainer',
})`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 15px;
`;

const ModelSummaryContainer = styled.div.withConfig({
  displayName: 'ModelSummaryContainer',
})`
  ${media.min.large`
  display: flex;
  ${({ alignContent }) =>
    `${alignContent && `flex-direction:${alignContent}`}`};
  padding: 0;
  `};
`;

const ModelSummaryInformation = styled.div.withConfig({
  displayName: 'ModelSummaryInformation',
})`
  padding-right: 5%;
  padding-left: 5%;
  ${media.min.large`
    width: 30%;
    padding: 3% 5% 2% 5%;
  `};
`;

const ModelSummaryImageContainer = styled.div.withConfig({
  displayName: 'ModelSummaryImageContainer',
})`
  ${({ url }) => `${url && `background-image: url(${url})`}`};
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  min-height: 250px;
  ${media.min.small`
  min-height: 350px;
`};
  ${media.min.medium`
  min-height: 440px;
`};
  ${media.min.large`
  width: 60%;
  background-position: center;
  height: auto;
`};
`;

const Link = styled.div.withConfig({
  displayName: 'Link',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444444;
  height: 43px;
  background-color: transparent;
  font-weight: 600;
  text-decoration: none;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  width: 100%;
  font-size: 13px;
  cursor: pointer;
  font-family: inherit;
  letter-spacing: 0.1em;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 3%;
`;

const DisclaimerText = styled.div.withConfig({
  displayName: 'DisclaimerText',
})`
  color: #444444;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5%;
  margin-bottom: 5%;
`;

export default function ModelSummary({
  title,
  modelFinanceComponent: ModelFinance,
  findOutMore,
  description,
  imageUrl,
  alignment,
  financeButtonText,
  searchButtonText,
  availableText,
  findOutMoreText,
  disclaimerText,
  searchButtonOnClick,
  financeButtonOnClick,
  globalStyling,
  showSearchButton,
  modelSummaryHeaderFont,
  lamboDescriptionStyling,
  showLamboCTAs,
}: Props) {
  return (
    <ModelSummaryContainer alignContent={alignment}>
      <ModelSummaryImageContainer url={imageUrl} />
      <ModelSummaryInformation>
        <HeadingOne
          styleOverride={() => `
            margin-top: 5%;
            ${modelSummaryHeaderFont &&
              fontStyleOverride(modelSummaryHeaderFont)}
          `}
        >
          {title}
        </HeadingOne>
        <ModelFinance />
        <Paragraph
          styleOverride={() => `
            font-size: 15px;
            line-height: 21px;
            margin-top: 2%;
            margin-bottom: 5%;
            ${lamboDescriptionStyling &&
              `
              border-top: ${lamboDescriptionStyling.horizontalBorder};
              border-bottom: ${lamboDescriptionStyling.horizontalBorder};
              `};
            ${lamboDescriptionStyling &&
              `padding: ${lamboDescriptionStyling.horizontalPadding}px 0;`};
          `}
        >
          {description}
        </Paragraph>
        {!showLamboCTAs && (
          <Link onClick={findOutMore}>
            {findOutMoreText}
            <ThinRight colour="#444444" />
          </Link>
        )}
        <ButtonContainer>
          {!showLamboCTAs && (
            <Button
              styleOverride={() => `
                width: 48%;
              `}
              onClick={financeButtonOnClick}
              text={financeButtonText}
              applyStyle="secondary"
              buttonStyle={
                globalStyling.uiElements.secondaryButton &&
                globalStyling.uiElements.secondaryButton.buttonStyle
              }
            />
          )}
          {showLamboCTAs && (
            <Button
              styleOverride={() => `
                width: 48%;
              `}
              onClick={findOutMore}
              text={findOutMoreText}
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
            />
          )}
          {showSearchButton && (
            <Button
              styleOverride={() => `
                width: 48%;
              `}
              onClick={searchButtonOnClick}
              text={searchButtonText}
              applyStyle="secondary"
              buttonStyle={
                globalStyling.uiElements.secondaryButton &&
                globalStyling.uiElements.secondaryButton.buttonStyle
              }
            />
          )}
        </ButtonContainer>
        <HeadingThree
          styleOverride={() => `
            font-size: 16px;
            line-height: 19px;
            margin-top: 10%;
            margin-bottom: 5%;
          `}
        >
          {availableText}
        </HeadingThree>
        <DisclaimerText>{disclaimerText}</DisclaimerText>
      </ModelSummaryInformation>
    </ModelSummaryContainer>
  );
}

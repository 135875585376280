/* eslint-disable import/prefer-default-export */
import * as formFields from './pods/components/formFields';
import * as genericModules from './modules';
import * as JaguarModules from './modules.jaguar';
import * as linkedModules from './linked-modules';
import * as bands from './bands';
import * as pods from './pods';
import renderer from './renderer';
import {
  CookiePolicyWrapper,
  CookiePolicyContent,
} from './pods/CookiePolicy/CookiePolicy';
import GlobalStyling from './components/GlobalStyling';
import { Spinner } from './components';

import config from './helpers/config';
import normalisers from './normalisation';

export * as actions from './actions';
export * as shared from './shared';
export * as components from './components';

export { mountComponent } from './utilities/reducerMacros';

const modules = {
  ...genericModules,
  ...JaguarModules,
  ...bands,
};

export {
  modules,
  linkedModules,
  bands,
  pods,
  Spinner,
  config,
  formFields,
  CookiePolicyWrapper,
  CookiePolicyContent,
  GlobalStyling,
  renderer,
  normalisers,
};

import {
  pods as podsFromModules,
  bands as bandsFromModules,
} from 'cms-modules';
import WrappedPod from './WrappedPod';
import WrappedBand from './WrappedBand';
import PreviewBody from './PreviewBody';

const podsToRemove = ['CookiePolicy'];

const pods = Object.entries(podsFromModules.default).reduce(
  (acc, curr) => ({
    ...acc,
    [curr[0]]: podsToRemove.includes(curr[0]) ? () => null : WrappedPod,
  }),
  {},
);

const bands = Object.entries(bandsFromModules).reduce(
  (acc, curr) => ({
    ...acc,
    [curr[0]]:
      curr[1].id === 'Band'
        ? {
            ...curr[1],
            component: WrappedBand,
          }
        : curr[1],
  }),
  {},
);

export { pods, bands, PreviewBody };

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import SlideOut from '../SlideOut';
import media from '../../theme';
import CheckboxGroupList from './CheckboxGroupList';
import type { Filters } from './search-filters-helper';
import {
  transformCheckboxProps,
  transformRangeProps,
  calculateAge,
  calculateYearRegistered,
  distanceOptions,
  getInitialRangeValues,
  getAgeInitialRangeValues,
} from './search-filters-helper';
import RangeSlider from '../RangeSlider';
import PriceRangeFilter from './PriceRangeFilter';
import LocationSearchFilter from '../LocationSearchFilter';
import RangeHeading from './RangeHeading';
import SectionTitle from './SectionTitle';
import Section from './Section';
import SubSection from './SubSection';
import DropdownFilter from './DropdownFilter';
import renderFilterTitle from './renderFilterTitle';
import renderFilterClose from './renderFilterClose';

type Location = {
  lat: number,
  long: number,
};

type Props = {
  allFilters: Filters,
  availableFilters: Filters,
  resetRangeCount: number,
  selectedCheckboxFilters: Filters,
  selectedRangeFilters: Filters,
  maxDistance: number,
  clearCheckboxesBySection: (id: string) => void,
  toggleCheckboxFilter: (id: string, value: string) => void,
  handleRangeFilters: (id: string, values: number[]) => void,
  handleLocationChange: (location: Location) => void,
  handleMaxDistanceChange: (distance: string) => void,
  removeRangeFilter: (id: string) => void,
  config: {
    make: string,
    market: string,
    locale: string,
    translations: {
      [key: string]: string,
    },
    filterVisibility: Object,
    locationIconUrl: string,
  },
  stylingConfig: Object,
  featureFlags: Object,
  location: Object,
  searchCountryCode: string,
};

const FilterContainer = styled.div.withConfig({
  displayName: 'FilterContainer',
})`
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;

  ${media.min.medium`
    min-width: 350px;
    padding: 0;
    overflow: hidden;
  `};
`;

const RangeContainer = styled.div.withConfig({
  displayName: 'RangeContainer',
})`
  padding: 20px;
  border-top: 1px solid #d8d8d8;
`;

const SearchHeader = styled.div.withConfig({
  displayName: 'SearchHeader',
})`
  padding: 0 0 20px 0;
  color: #7e7e7e;

  ${media.min.medium`
    display: none;
  `};
`;
type Range = {
  mi: number,
  max: number,
};

type State = {
  monthlyPaymentRange: Range,
  mileageRangeLabel: Range,
  yearRangeLabel: Range,
  priceRangeLabel: Range,
  filtersCollapsed: Object,
  resetRangeCount: number,
};

const initialFilterState = {
  locationDistance: true,
  priceRange: true,
  modelVariant: true,
  engineTransmission: true,
  bodyStyles: true,
  ageMileage: true,
  features: true,
  colour: true,
};

export default class SearchFilters extends Component<Props, State> {
  static getDerivedStateFromProps(props, state) {
    const { priceRange, monthlyPaymentRange } = transformRangeProps(
      props.allFilters,
    );

    const priceRangeLabel = {
      min:
        !state.resetRangeCount && props.selectedRangeFilters[priceRange.id]
          ? props.selectedRangeFilters[priceRange.id].min
          : priceRange.defaultValues[0],
      max:
        !state.resetRangeCount && props.selectedRangeFilters[priceRange.id]
          ? props.selectedRangeFilters[priceRange.id].max
          : priceRange.defaultValues[1],
    };
    const monthlyPaymentRangeLabel = {
      min:
        !state.resetRangeCount &&
        props.selectedRangeFilters[monthlyPaymentRange.id]
          ? props.selectedRangeFilters[monthlyPaymentRange.id].min
          : monthlyPaymentRange.defaultValues[0],
      max:
        !state.resetRangeCount &&
        props.selectedRangeFilters[monthlyPaymentRange.id]
          ? props.selectedRangeFilters[monthlyPaymentRange.id].max
          : monthlyPaymentRange.defaultValues[1],
    };
    const valuesReset = props.resetRangeCount !== state.resetRangeCount;

    return valuesReset
      ? {
          ...state,
          resetRangeCount: props.resetRangeCount,
          priceRangeLabel,
          monthlyPaymentRangeLabel,
        }
      : null;
  }

  state = {
    filtersCollapsed: {
      ...initialFilterState,
    },
  };

  onChangeRangeLabel = (rangeValues: [number, number], label: string) => {
    this.setState(() => ({
      [label]: { min: rangeValues[0], max: rangeValues[1] },
    }));
  };

  handleAgeRangeChange = (rangeValues: [number, number]) => {
    const values = [
      calculateYearRegistered(rangeValues[1]),
      calculateYearRegistered(rangeValues[0]),
    ];
    this.props.handleRangeFilters('yearRange', values);
  };

  toggle = (filter: string) => {
    this.setState(prevState => ({
      filtersCollapsed: {
        ...initialFilterState,
        [filter]: !prevState.filtersCollapsed[filter],
      },
    }));
  };

  render() {
    const {
      allFilters,
      availableFilters,
      selectedRangeFilters,
      maxDistance,
      resetRangeCount,
      selectedCheckboxFilters,
      clearCheckboxesBySection,
      toggleCheckboxFilter,
      handleRangeFilters,
      handleLocationChange,
      removeRangeFilter,
      config: {
        translations = {},
        stylingConfig,
        filterVisibility = {},
        distanceFilterInitialSelectedIndex,
        dropdown,
        searchCountryCode,
        locationIconUrl,
        locale,
      },
      featureFlags,
      location,
    } = this.props;

    const checkboxes = transformCheckboxProps(
      allFilters,
      availableFilters,
      selectedCheckboxFilters,
    );

    const {
      mileageRange,
      yearRange,
      priceRange,
      monthlyPaymentRange,
    } = transformRangeProps(allFilters);

    const rangeAge =
      availableFilters.yearRange.min && availableFilters.yearRange.max
        ? {
            defaultRangeAgeHeader: {
              min: calculateAge(availableFilters.yearRange.max),
              max: calculateAge(availableFilters.yearRange.min),
            },
            defaultRangeAgeValues: getAgeInitialRangeValues(
              1,
              availableFilters.yearRange,
            ),
          }
        : {
            defaultRangeAgeHeader: { min: 0, max: 0 },
            defaultRangeAgeValues: [0, 0],
          };

    const selectedLocationDistanceOption = () => {
      const options = distanceOptions(translations);
      return (
        options.find(o => o.value === maxDistance) ||
        options[distanceFilterInitialSelectedIndex || 0]
      );
    };

    return (
      <FilterContainer>
        <SearchHeader>{translations.searchHeadingFilters}</SearchHeader>
        <SlideOut
          qaHook="location-distance-filter"
          toggle={() => this.toggle('locationDistance')}
          collapsed={this.state.filtersCollapsed.locationDistance}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderLocationDistance,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderLocationDistance,
          })}
          visible={filterVisibility.locationDistance}
        >
          <Section>
            <SubSection>
              <SectionTitle>
                {translations.searchSubHeaderLocation}
              </SectionTitle>
              <LocationSearchFilter
                placeholder={translations.searchLocationInputPlaceholder}
                handleLocationChange={handleLocationChange}
                translations={translations}
                locationIconColour={
                  stylingConfig.locationIconColour &&
                  stylingConfig.locationIconColour.value
                }
                rawLocation={location && location.rawLocation}
                location={location}
                key={resetRangeCount}
                searchCountryCode={searchCountryCode}
                locationIconUrl={locationIconUrl}
              />
            </SubSection>
            <SubSection>
              <SectionTitle>
                {translations.searchSubHeaderDistance}
              </SectionTitle>
              <DropdownFilter
                initialSelectedValue={selectedLocationDistanceOption()}
                onOptionClick={({ value }: { value: string }) =>
                  this.props.handleMaxDistanceChange(value)
                }
                options={distanceOptions(translations)}
                key={resetRangeCount}
                selectedValue={maxDistance}
                theme={dropdown}
                border
              />
            </SubSection>
          </Section>
        </SlideOut>
        <SlideOut
          qaHook="price-range-filter"
          toggle={() => this.toggle('priceRange')}
          collapsed={this.state.filtersCollapsed.priceRange}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderPriceRange,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderPriceRange,
          })}
          visible={filterVisibility.priceRange}
        >
          <PriceRangeFilter
            featureFlags={featureFlags}
            stylingConfig={stylingConfig}
            translations={translations}
            priceRange={priceRange}
            monthlyPaymentRange={monthlyPaymentRange}
            availableFilters={availableFilters}
            onChangeRangeLabel={this.onChangeRangeLabel}
            handleRangeFilters={handleRangeFilters}
            resetRangeCount={resetRangeCount}
            removeRangeFilter={removeRangeFilter}
            locale={locale}
            headingValues={{
              [priceRange.id]:
                this.state.priceRangeLabel || priceRange.fullRangeValues,
              [monthlyPaymentRange.id]:
                this.state.monthlyPaymentRangeLabel ||
                monthlyPaymentRange.fullRangeValues,
            }}
            rangeValues={{
              [priceRange.id]:
                selectedRangeFilters[priceRange.id] ||
                priceRange.fullRangeValues,
              [monthlyPaymentRange.id]:
                selectedRangeFilters[monthlyPaymentRange.id] ||
                monthlyPaymentRange.fullRangeValues,
            }}
          />
        </SlideOut>
        <SlideOut
          qaHook="model-variant-filter"
          toggle={() => this.toggle('modelVariant')}
          collapsed={this.state.filtersCollapsed.modelVariant}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderModelVariant,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderModelVariant,
          })}
          visible={filterVisibility.modelVariant}
        >
          <CheckboxGroupList
            clearCheckboxSection={clearCheckboxesBySection}
            handleCheckboxClick={toggleCheckboxFilter}
            checkboxGroups={[checkboxes.models, checkboxes.variants]}
            translations={translations}
            stylingConfig={stylingConfig}
          />
        </SlideOut>
        <SlideOut
          qaHook="engine-transmission-filter"
          toggle={() => this.toggle('engineTransmission')}
          collapsed={this.state.filtersCollapsed.engineTransmission}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderEngineTransmission,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderEngineTransmission,
          })}
          visible={filterVisibility.engineTransmission}
        >
          <CheckboxGroupList
            clearCheckboxSection={clearCheckboxesBySection}
            handleCheckboxClick={toggleCheckboxFilter}
            checkboxGroups={[
              checkboxes.engineSizes,
              checkboxes.engine,
              checkboxes.transmissions,
            ]}
            translations={translations}
            stylingConfig={stylingConfig}
          />
        </SlideOut>
        <SlideOut
          qaHook="body-style-filter"
          toggle={() => this.toggle('bodyStyles')}
          collapsed={this.state.filtersCollapsed.bodyStyles}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderBodyStyle,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderBodyStyle,
          })}
          visible={filterVisibility.bodyStyles}
        >
          <CheckboxGroupList
            clearCheckboxSection={clearCheckboxesBySection}
            handleCheckboxClick={toggleCheckboxFilter}
            checkboxGroups={[checkboxes.bodyStyles]}
            translations={translations}
            stylingConfig={stylingConfig}
          />
        </SlideOut>
        <SlideOut
          qaHook="age-mileage-filter"
          toggle={() => this.toggle('ageMileage')}
          collapsed={this.state.filtersCollapsed.ageMileage}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderAgeMileage,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderAgeMileage,
          })}
          visible={filterVisibility.ageMileage}
        >
          <RangeContainer>
            <RangeHeading
              range={
                this.state.yearRangeLabel || rangeAge.defaultRangeAgeHeader
              }
              heading={translations.searchSubHeaderAge}
              unit={translations.unitAge}
              locale={locale}
            />
            <RangeSlider
              stylingConfig={stylingConfig}
              step={yearRange.step}
              rangeValue={yearRange.fullRangeValues}
              name={yearRange.name}
              defaultValue={rangeAge.defaultRangeAgeValues}
              onAfterChange={this.handleAgeRangeChange}
              onChange={values =>
                this.onChangeRangeLabel(values, 'yearRangeLabel')
              }
              key={resetRangeCount}
            />
          </RangeContainer>
          <RangeContainer>
            <RangeHeading
              range={
                this.state.mileageRangeLabel || availableFilters.mileageRange
              }
              heading={translations.searchSubHeaderMileage}
              unit={allFilters.odometerUnits}
              locale={locale}
            />
            <RangeSlider
              stylingConfig={stylingConfig}
              step={mileageRange.step}
              rangeValue={mileageRange.fullRangeValues}
              name={mileageRange.name}
              locale={locale}
              defaultValue={getInitialRangeValues(
                500,
                availableFilters.mileageRange,
              )}
              onAfterChange={values =>
                handleRangeFilters(mileageRange.id, values)
              }
              onChange={values =>
                this.onChangeRangeLabel(values, 'mileageRangeLabel')
              }
              key={resetRangeCount}
            />
          </RangeContainer>
        </SlideOut>
        <SlideOut
          qaHook="colour-filter"
          toggle={() => this.toggle('colour')}
          collapsed={this.state.filtersCollapsed.colour}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderColour,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderColour,
          })}
          visible={filterVisibility.colour}
        >
          <CheckboxGroupList
            clearCheckboxSection={clearCheckboxesBySection}
            handleCheckboxClick={toggleCheckboxFilter}
            checkboxGroups={[
              checkboxes.exteriorColours,
              checkboxes.interiorShades,
            ]}
            translations={translations}
            stylingConfig={stylingConfig}
          />
        </SlideOut>
        <SlideOut
          qaHook="features-filter"
          toggle={() => this.toggle('features')}
          collapsed={this.state.filtersCollapsed.features}
          renderTitle={renderFilterTitle({
            title: translations.searchHeaderFeatures,
          })}
          renderClose={renderFilterClose({
            title: translations.searchHeaderFeatures,
          })}
          visible={filterVisibility.features}
        >
          <CheckboxGroupList
            clearCheckboxSection={clearCheckboxesBySection}
            handleCheckboxClick={toggleCheckboxFilter}
            checkboxGroups={[checkboxes.features]}
            translations={translations}
            stylingConfig={stylingConfig}
          />
        </SlideOut>
      </FilterContainer>
    );
  }
}

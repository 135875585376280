// @flow

import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  CheckboxSwitch,
  FieldLabel,
  ButtonWithConfirm,
  Button,
  TextArea,
} from 'cms-ui';
import { type FieldProps } from '../models/fields';
import { MediaLibraryInModal } from '../components/MediaLibrary';
import Toggle from '../components/Toggle';

import theme from '../theme';

const defaultImage = {
  src:
    'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
  label: 'Default Image',
};

const mediacollectionKey = {
  image: 'images',
  video: 'videos',
};

const OpenContainer = styled.div`
  display: flex;
  cursor: pointer;
  height: 54px;
  border: 1px solid #474747;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
`;
const ThumbnailAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`;

const Thumbnail = styled.div`
  margin: 7px;
  height: 38px;
  width: 50px;
  background-color: #f0f0f0;
  ${props =>
    props.backgroundImage &&
    `background-image: url(${
      props.backgroundImage
    });`} background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const EllipsisLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  font-size: 13px;
`;

const Hint = styled.span`
  color: #757575;
  line-height: 16px;
  font-size: 13px;
`;

const toThumbnail = resource =>
  resource &&
  (resource.endsWith('.mp4') ? `${resource.slice(0, -4)}.jpg` : resource);

const MediaSelectorContainer = styled.div`
  padding-top: ${theme.layout.halfPadding};
`;

const StyledButton = styled(Button)`
  margin-top: 2px;
  width: 48%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const AltTextContainer = styled.div`
  padding-top: ${theme.layout.halfPadding};
`;

const renderModalContents = (onClick, type) => closeSelfFn => (
  <>
    <p>{`Are you sure you want to delete this ${type}?`}</p>
    <ButtonContainer>
      <StyledButton
        small
        fullWidth
        danger
        textTransform="uppercase"
        onClick={onClick}
      >
        Confirm Delete
      </StyledButton>
      <StyledButton
        small
        fullWidth
        textTransform="uppercase"
        onClick={closeSelfFn}
      >
        Cancel
      </StyledButton>
    </ButtonContainer>
  </>
);

const MediaSelector = ({
  items,
  type,
  actions,
  onChange,
  id,
  hintText,
  media,
  customDefault,
}) => (
  <MediaLibraryInModal
    items={items}
    type={type}
    onUploadMediaSuccess={actions.onUploadMediaSuccess}
    onUploadMediaFailure={actions.onUploadMediaFailure}
    onDeleteMediaItem={actions.onDeleteMediaItem}
    onSelectItem={item =>
      onChange(id, {
        ...media,
        ...(({ value: src, label }) => ({ src, label }))(
          items.find(({ publicId }) => item.publicId === publicId),
        ),
      })
    }
    openComponent={({ onClick, item }) => (
      <div>
        <OpenContainer onClick={onClick}>
          <ThumbnailAndLabelContainer>
            <Thumbnail backgroundImage={toThumbnail(media && media.src)} />
            <EllipsisLabel>
              {(media && media.label) || `Select ${type}`}
            </EllipsisLabel>
          </ThumbnailAndLabelContainer>
          {media &&
            media.src &&
            media.src !== (customDefault || defaultImage).src && (
              <ButtonWithConfirm
                icon="https://res.cloudinary.com/motortrak/image/upload/v1570186934/editor/delete-icon.svg"
                renderModalContents={closeSelfFn =>
                  renderModalContents(e => {
                    e.stopPropagation();
                    onChange(id, {
                      ...media,
                      ...(customDefault || defaultImage),
                    });
                  }, type)(closeSelfFn)
                }
              />
            )}
        </OpenContainer>
        {hintText && (
          <Hint>
            {hintText({
              label: item && item.label,
              value: item && item.publicId,
            })}
          </Hint>
        )}
      </div>
    )}
  />
);

export default function Media({
  name,
  ids,
  values,
  onChange,
  data,
  actions,
  customDefault,
  ...props
}: FieldProps) {
  const { type, hintText, optional, hideLabel } = props;
  const { media, accessibilityText } = values;
  const { media: id, accessibilityText: accessibilityTextId } = ids;
  const items = data.media[mediacollectionKey[type]];
  const clearValue = on =>
    !on ? onChange(id) : onChange(id, { ...media, on });

  return (
    <>
      {optional ? (
        <Toggle defaultOn={media} onToggle={clearValue}>
          {({ on, onClick }) => (
            <Fragment>
              <FieldLabel label={name} inputFlexMode="0">
                <CheckboxSwitch
                  name={`${name}-toggle`}
                  onChange={onClick}
                  value={on}
                />
              </FieldLabel>
              {on && (
                <MediaSelectorContainer>
                  <MediaSelector
                    id={id}
                    hintText={hintText}
                    media={media}
                    items={items}
                    type={type}
                    actions={actions}
                    onChange={onChange}
                    customDefault={customDefault}
                  />
                </MediaSelectorContainer>
              )}
            </Fragment>
          )}
        </Toggle>
      ) : (
        <>
          {!hideLabel && <FieldLabel label={name} inputFlexMode="0" />}
          <MediaSelector
            id={id}
            hintText={hintText}
            media={media}
            items={items}
            type={type}
            actions={actions}
            onChange={onChange}
            customDefault={customDefault}
          />
        </>
      )}
      {hintText && <Hint>{hintText(media)}</Hint>}

      {(type === 'image' || type === 'video') && accessibilityTextId && (
        <AltTextContainer>
          <FieldLabel
            label={
              type === 'video'
                ? 'Video Transcripts'
                : name
                ? `${name}'s Alt Text`
                : 'Alt Text'
            }
          />
          <TextArea
            type="text"
            rows={3}
            onChange={value => onChange(accessibilityTextId, value)}
            value={accessibilityTextId && accessibilityText}
          />
        </AltTextContainer>
      )}
    </>
  );
}

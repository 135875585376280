// @flow

import React from 'react';

import { CheckboxSwitch, FieldLabel } from 'cms-ui';

import { type FieldProps } from '../models/fields';

export default function Toggle({
  name,
  values: { id: value },
  ids: { id },
  onChange,
}: FieldProps) {
  return (
    <FieldLabel label={name} inputFlexMode="0">
      <CheckboxSwitch value={value} onChange={() => onChange(id, !value)} />
    </FieldLabel>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import TestDriveVehicleTile from './TestDriveVehicleTile';
import TestDriveSelectDateAndTime from './TestDriveSelectDateAndTime';
import Button from './Global/Button';

import media from '../theme';
import type {
  SelectDateMode,
  SelectTimeMode,
} from '../modules/TestDrive/reducer';

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  height: auto;
  padding: 0 15%;
  margin: 20px auto 80px auto;
  max-width: 930px;
  ${media.max.large`
    width: 90%;
    padding: 0 5%;
  `};
`;

const Block = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const Heading = styled.span`
  font-size: 20px;
`;

const Row = styled.div.withConfig({
  displayName: 'Row',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
`;

const Cell = styled.div.withConfig({
  displayName: 'Cell',
})`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  ${media.min.large`
    width: 50%;
  `};
`;

const ActionButtonRow = styled.div`
  padding-right: 2.5%;
  display: flex;
  justify-content: flex-end;
  ${media.max.large`
  padding: 23px 0;
`};
`;

const ButtonWrapper = styled.div.withConfig({
  displayName: 'buttonWrapper',
})`
  width: 60%;
  margin: 20px 0 0 20px;
  ${media.max.large`
    width: 100%;
    margin: 20px 0;
  `};
`;

type Props = {
  translations: Object,
  onSelectDate: () => void,
  toggleCalendar: () => void,
  selectDateModeOptions: SelectDateMode[],
  selectDateMode: SelectDateMode,
  selectTimeModeOptions: SelectTimeMode[],
  selectTimeMode: SelectTimeMode,
  open: boolean,
  selected: Date,
  bookingForm: Object,
  testDriveConfig: Object,
  disableCalendar: boolean,
  confirmDateAndTime: Function,
  sendTestDriveLead: Function,
  selectedDateMode: string,
  selectedTimeMode: string,
  setSelectRadioGroupMode: (key: string, value: string) => void,
  interestedClicked: () => void,
  userIsLoggedIn: boolean,
  somethingWentWrong: boolean,
  useSimplifiedFlow: boolean,
  globalStyling: Object,
};

export default function TestDriveDateAndTime(props: Props) {
  const {
    globalStyling: { colours },
  } = props;
  const primaryBrandColour =
    colours.primaryBrandColour && colours.primaryBrandColour.value;
  return (
    <Container>
      <Block>
        <Heading>{props.translations.testDriveDateAndTimeHeader}</Heading>
      </Block>
      <Row>
        <Cell>
          <TestDriveVehicleTile vehicle={props.testDriveConfig} />
        </Cell>
        <Cell>
          {props.useSimplifiedFlow && (
            <div style={{ backgroundColor: '#f0f0f0', marginTop: '5%' }}>
              <div style={{ padding: '25px' }}>
                <div
                  style={{
                    color: '#444444',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  {props.translations.spanishGetStartedText}
                </div>
                <ButtonWrapper>
                  {props.userIsLoggedIn ? (
                    <Button
                      applyStyle="primary"
                      buttonStyle={
                        props.globalStyling.uiElements.primaryButton &&
                        props.globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      text={
                        props.translations.testdriveUserDetailsConfirmRequest
                      }
                      onClick={props.sendTestDriveLead}
                    />
                  ) : (
                    <Button
                      applyStyle="primary"
                      buttonStyle={
                        props.globalStyling.uiElements.primaryButton &&
                        props.globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      text={props.translations.testDriveDateAndTimeNextButton}
                      onClick={props.confirmDateAndTime}
                    />
                  )}
                </ButtonWrapper>
              </div>
            </div>
          )}
          {!props.useSimplifiedFlow && (
            <div>
              <TestDriveSelectDateAndTime
                globalStyling={props.globalStyling}
                disabled={!props.disableCalendar}
                {...props}
              />
              <ButtonWrapper>
                {props.userIsLoggedIn ? (
                  <Button
                    applyStyle="primary"
                    buttonStyle={
                      props.globalStyling.uiElements.primaryButton &&
                      props.globalStyling.uiElements.primaryButton.buttonStyle
                    }
                    text={props.translations.testdriveUserDetailsConfirmRequest}
                    onClick={props.sendTestDriveLead}
                  />
                ) : (
                  <Button
                    applyStyle="primary"
                    buttonStyle={
                      props.globalStyling.uiElements.primaryButton &&
                      props.globalStyling.uiElements.primaryButton.buttonStyle
                    }
                    text={props.translations.testDriveDateAndTimeNextButton}
                    onClick={props.confirmDateAndTime}
                  />
                )}
              </ButtonWrapper>
            </div>
          )}
        </Cell>
      </Row>
      <ActionButtonRow>
        <ButtonWrapper>
          <div
            style={{
              color: primaryBrandColour,
              textAlign: 'left',
            }}
          >
            {props.somethingWentWrong &&
              props.translations.testDriveRequestSomethingWentWrong}
          </div>
        </ButtonWrapper>
      </ActionButtonRow>
    </Container>
  );
}

TestDriveDateAndTime.defaultProps = {
  useSimplifiedFlow: false,
};

// @flow
import React from 'react';
import IoMonitor from 'react-icons/lib/io/monitor';
import IoIpad from 'react-icons/lib/io/ipad';
import IoIphone from 'react-icons/lib/io/iphone';
import { LargeIconRadioGroup } from 'cms-ui';

type Props = {
  onChange: string => void,
  selected: string,
};

const options = [
  { element: <IoMonitor />, value: 'desktop' },
  { element: <IoIpad />, value: 'tablet', rotate: 90 },
  { element: <IoIphone />, value: 'mobile' },
];

export default function ScreenSizePicker({ onChange, selected }: Props) {
  return (
    <LargeIconRadioGroup
      name="ScreenSizePicker"
      options={options}
      selected={selected}
      onChange={onChange}
    />
  );
}

ScreenSizePicker.defaultProps = {
  selected: 'desktop',
};

// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import { fontStyleOverride } from './Global';

const StyledHeader = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 35px;
  letter-spacing: 3px;
  ${({ headerFont }) => headerFont && fontStyleOverride(headerFont)};
`;

type Props = {
  children: Node,
  qaHook?: string,
  styles?: Object,
};

export default function VdpHeader({ children, qaHook, styles }: Props) {
  const { headerFont } = styles;
  return (
    <StyledHeader data-qa-hook={qaHook} headerFont={headerFont}>
      {children}
    </StyledHeader>
  );
}
VdpHeader.defaultProps = {
  qaHook: undefined,
  styles: {},
};

// @flow
import { put, call } from 'redux-saga/effects';
import watch from '../../helpers/watch';
import { get, post, remove } from '../../helpers/http';
import { actions, constants } from '../actions/users';
import type {
  AttemptAddUserAction,
  AddUserAction,
  RemoveUserAction,
} from '../actions/users';
import {
  API_BASE_URL_EDITOR_USERS,
  API_BASE_URL_EDITOR_USERS_DELETE,
  API_BASE_URL_ROLES,
  API_BASE_URL_USERS_VALIDATE,
} from '../../constants/apis';

export function* loadUsers(token: string): Generator<*, *, *> {
  try {
    const url = API_BASE_URL_EDITOR_USERS;
    const response: any = yield call(get, { url, token });
    yield put(actions.loadUsersSuccess(response.data.contents));
  } catch (error) {
    yield put(actions.loadUsersFailed(error.response.data.message));
  }
}

export function* attemptAddUser(
  token: string,
  action: AttemptAddUserAction,
): Generator<*, *, *> {
  try {
    const normaliseEmailAddress = email =>
      email
        .trim()
        .toLowerCase()
        // this regex removes non a-z0-9 chars from the start and end of the email
        // it is also in the administration effects/users.js
        .replace(/^[^a-z0-9]*|[^a-z0-9]*$/g, '');
    const normalisedEmailAddress = normaliseEmailAddress(action.payload.email);
    const url = API_BASE_URL_USERS_VALIDATE(normalisedEmailAddress);
    yield call(get, { url, token });
    yield put(
      actions.addUser({ ...action.payload, email: normalisedEmailAddress }),
    );
  } catch (error) {
    yield put(actions.attemptAddUserFailed('User not found'));
  }
}

export function* addUser(
  token: string,
  action: AddUserAction,
): Generator<*, *, *> {
  try {
    const url = API_BASE_URL_EDITOR_USERS;
    const response: any = yield call(post, {
      url,
      token,
      data: action.payload,
    });
    yield put(actions.addUserSuccess(response.data));
  } catch (error) {
    yield put(actions.addUserFailed(error.response.data.message));
  }
}

export function* loadRoles(token: string): Generator<*, *, *> {
  try {
    const url = API_BASE_URL_ROLES;
    const response: any = yield call(get, { url, token });
    yield put(actions.loadRolesSuccess(response.data.contents));
  } catch (error) {
    yield put(actions.loadRolesFailed(error.response.data.message));
  }
}

export function* removeUser(
  token: string,
  action: RemoveUserAction,
): Generator<*, *, *> {
  try {
    const url = API_BASE_URL_EDITOR_USERS_DELETE(action.payload.id);
    yield call(remove, { url, token });
    yield put(actions.removeUserSuccess(action.payload));
  } catch (error) {
    yield put(
      actions.removeUserFailed(action.payload, error.response.data.message),
    );
  }
}

export default [
  watch(constants.LoadUsers, loadUsers, true),
  watch(constants.RemoveUser, removeUser, true),
  watch(constants.LoadRoles, loadRoles, true),
  watch(constants.AttemptAddUser, attemptAddUser, true),
  watch(constants.AddUser, addUser, true),
];

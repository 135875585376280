// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Waypoint from 'react-waypoint';
import { Helmet } from 'react-helmet';

import media from '../theme';
import VdpGalleryRr, { formattedVehicleDescription } from './VdpGalleryRr';
import VdpHighlights from './VdpHighlights';
import VehicleInfoBanner from './VehicleInfoBanner';
import FooterActionBanner from './FooterActionBanner';
import { Button } from './Global';
import { localiseCurrency } from '../shared/localisation/numbers';

type Slide = {
  id: any,
  image: any,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 16px;
  ${media.max.large`
    display: flex;
    flex-direction: column;
    padding: 32px 0;
  `};
`;

const MyDealContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 0 0 16px;
  width: 40%;
  ${media.max.large`
    padding: 16px 5% 0 5%;
    width: 100%;
  `};
`;

type RangeConfig = {
  id: string,
  name: string,
  step: number,
  marks: any,
  defaultValue: number,
  fullRangeValues: { min: number, max: number },
  title: string,
  unit: string,
};

type Props = {
  config: Object,
  vehicleImages: { slides: Slide[] },
  rotateGallery: boolean,
  placeholdingImage: string,
  shortListedIconColour: string,
  compareIconColour: string,
  vehicleInfo: Object,
  translations: Object,
  bookTestDrive: Function,
  reserveThisVehicle: Function,
  makeAnEnquiry: Function,
  makeATestDriveRequest: Function,
  makeAnOnDemandEnquiry: Function,
  makeAReservation: Function,
  compareVehicle: (vehicle: Object) => void,
  shortlistVehicle: (vehicle: Object) => void,
  finance: Object,
  showStickyBanner: boolean,
  toggleStickyBanner: (bool: boolean) => void,
  downpaymentRange: RangeConfig,
  termsRange: RangeConfig,
  financeProductExample: Object,
  requestFinanceProductUpdate: (
    duration: number,
    downpayment: number,
    additionalServices: string,
  ) => void,
  updateMyDeal: (finance: Object) => void,
  updateMyDealWithPartExchange: (finance: Object) => void,
  saveMyDeal: () => void,
  buttonTheme: ButtonLinkTheme,
  buttonSecondaryTheme: ButtonLinkTheme,
  iconColour: string,
  featureFlags: Object,
  getValuationMakes: () => {},
  valuationMakes: Object[],
  getValuationModels: string => void,
  valuationModels: Object[],
  globalStyling: Object,
  getValuationVersions: (makeId: string, modelId: string) => void,
  valuationVersions: Object[],
  getValuationYears: (
    makeId: string,
    modelId: string,
    versionId: string,
  ) => void,
  valuationYears: Object[],
  getValuationMonths: (makeId: string, modelId: string, year: string) => void,
  getVehicleValuation: (
    make: Object,
    model: Object,
    version: string,
    year: string,
    month: string,
    odometer: string,
  ) => void,
  valuationMonths: Object[],
  vehicleValuation: Object,
  editVehicleValuation: string => void,
  vehicleValuationRequestLimit: boolean,
  valuationError: boolean,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  vehicleHeadingColour: Option,
  locale: string,
  financeProducts: Object[],
  stickyBannerStyling: Object,
  resetFinanceProduct: () => void,
  goToAnchor: (element: string) => void,
  showStickyBannerDealerLocation: boolean,
  showStickyBannerContactUsButton: boolean,
  hideVertSeparators: boolean,
  imageCarousel: Object,
  socialShareConfig: Object,
  socialShare: Object,
  badgeStyling: Object,
  handleZeroPriceAsText: boolean,
  data360: Object,
  get360Info: Function,
  printVehiclePdf: () => void,
  printingState: Object,
};

export default function VdpGalleryAndHighlights(props: Props) {
  const rightButton = () => (
    <Button
      onClick={props.reserveThisVehicle}
      text={props.translations.vdpReserveButtonLabel}
      applyStyle="primary"
      buttonStyle={
        props.globalStyling.uiElements.primaryButton &&
        props.globalStyling.uiElements.primaryButton.buttonStyle
      }
    />
  );
  const leftButton = () => (
    <Button
      onClick={props.makeATestDriveRequest}
      text={props.translations.vdpTestDriveButtonLabel}
      applyStyle="primary"
      buttonStyle={
        props.globalStyling.uiElements.primaryButton &&
        props.globalStyling.uiElements.primaryButton.buttonStyle
      }
    />
  );

  const vehicleTitle = vehicleInfo =>
    vehicleInfo.description ? `${vehicleInfo.registration}` : vehicleInfo.name;
  const hasFinance = !!props.vehicleInfo.finance;
  const price = localiseCurrency(
    props.vehicleInfo.price.value,
    props.vehicleInfo.locale,
    props.vehicleInfo.price.currency,
    0,
  );

  const badgeTitle = `${props.vehicleInfo.modelGroupName || ''} ${props
    .vehicleInfo.modelVariant || ''}`;
  const defaultTitle = `${props.vehicleInfo.modelGroupName || ''}`;
  const title =
    props.badgeStyling &&
    props.badgeStyling.displayModelAndVariant &&
    props.vehicleInfo.modelVariant !== null
      ? badgeTitle
      : defaultTitle;
  const handleZeroPrice =
    props.handleZeroPriceAsText &&
    props.vehicleInfo &&
    props.vehicleInfo.price.value === 0
      ? props.config.translations.zeroPriceText
      : price;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {props.showStickyBanner && (
        <VehicleInfoBanner
          title={title}
          iconColour={props.iconColour}
          pinIcon={props.config.pinIcon}
          featureFlags={props.featureFlags}
          translations={props.translations}
          borderColour={props.borderColour}
          imageSrc={
            props.vehicleImages.slides.length > 0
              ? props.vehicleImages.slides[0].image
              : props.placeholdingImage
          }
          vehicleModel={vehicleTitle(props.vehicleInfo)}
          vehicleDetail={formattedVehicleDescription(
            props.subHeaderType,
            props.vehicleInfo,
          )}
          dealerTelephone={props.vehicleInfo.retailerInformation.phone}
          dealerName={props.vehicleInfo.retailerInformation.name}
          stickyBannerStyling={props.stickyBannerStyling}
          showStickyBannerDealerLocation={props.showStickyBannerDealerLocation}
          contentWidth={props.globalStyling.contentWidth}
          info={[
            [handleZeroPrice, props.translations.searchResultsPriceLabel],
            (hasFinance && [
              localiseCurrency(
                parseFloat(
                  props.vehicleInfo.finance.monthlyPrice
                    .replace('.', '')
                    .replace(',', '.'),
                ),
                props.vehicleInfo.locale,
                props.vehicleInfo.price.currency,
                2,
              ),
              props.translations.searchResultsMonthlyLabel,
            ]) ||
              [],
            (hasFinance && [
              `${props.vehicleInfo.finance.apr}%`,
              props.translations.searchResultsAprLabel,
            ]) ||
              [],
            (hasFinance && [
              `${props.vehicleInfo.finance.interestRate}%`,
              props.translations.searchResultsInterestRateLabel,
            ]) ||
              [],
          ]}
          onReserveButtonClick={props.reserveThisVehicle}
          onTestDriveButtonClick={props.bookTestDrive}
          onContactUsButtonClick={props.makeAnEnquiry}
          buttonTheme={props.buttonTheme}
          hideVertSeparators={props.config.hideVertSeparators}
          globalStyling={props.globalStyling}
          showStickyBannerContactUsButton={
            props.showStickyBannerContactUsButton
          }
        />
      )}
      {(props.featureFlags.testDrive || props.featureFlags.reserve) && (
        <FooterActionBanner
          leftButton={props.featureFlags.testDrive && leftButton}
          rightButton={props.featureFlags.reserve && rightButton}
        />
      )}
      <Container>
        <VdpGalleryRr
          title={title}
          shortListedIconColour={props.shortListedIconColour}
          compareIconColour={props.compareIconColour}
          translations={props.translations}
          vehicleInfo={props.vehicleInfo}
          vehicleImages={props.vehicleImages}
          compareVehicle={props.compareVehicle}
          shortlistVehicle={props.shortlistVehicle}
          placeholdingImage={props.placeholdingImage}
          subHeaderType={props.subHeaderType}
          vehicleHeadingColour={props.vehicleHeadingColour}
          rotateGallery={props.rotateGallery}
          goToAnchor={props.goToAnchor}
          iconColour={props.iconColour}
          imageCarousel={props.imageCarousel}
          pinIcon={props.config.pinIcon}
          data360={props.data360}
          get360Info={props.get360Info}
          globalStyling={props.globalStyling}
          noBorder
        />
        <MyDealContainer>
          <VdpHighlights
            config={props.config}
            translations={props.translations}
            globalStyling={props.globalStyling}
            vehicleInfo={props.vehicleInfo}
            price={price}
            makeAnEnquiry={props.makeAnEnquiry}
            makeATestDriveRequest={props.makeATestDriveRequest}
            makeAnOnDemandEnquiry={props.makeAnOnDemandEnquiry}
            makeAReservation={props.makeAReservation}
            goToAnchor={props.goToAnchor}
            iconColour={props.iconColour}
            socialShareConfig={props.socialShareConfig}
            socialShare={props.socialShare}
            handleZeroPriceAsText={props.handleZeroPriceAsText}
            featureFlags={props.featureFlags}
            printVehiclePdf={props.printVehiclePdf}
            printingState={props.printingState}
          />
          <Waypoint
            onPositionChange={({ currentPosition }) =>
              props.toggleStickyBanner(currentPosition === 'above')
            }
          />
        </MyDealContainer>
      </Container>
    </Fragment>
  );
}

VdpGalleryAndHighlights.defaultProps = {
  iconColour: '#9E1B32',
  showDeal: true,
  subHeaderType: 'VehicleDescription',
};

// @flow
import React from 'react';
import styled from 'styled-components';
import Close from './JaguarIcons/Global/Close';
import Globe from './JaguarIcons/Global/Globe';
import Modal from './Modal';
import FormLocation from './FormLocation';
import OpenClose from './OpenClose';

const LocationContainer = styled.div.withConfig({
  displayName: 'LocationContainer',
})`
  display: flex;
  background: #f0f0f0;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  width: 100%;
`;

const IConWrapper = styled.div`
  margin: 0 10px;
  cursor: pointer;
`;

const renderClose = () => <Close width="1em" height="1em" colour="#ACACAC" />;

type Option = {
  label: string,
  value: number,
};

type Props = {
  iconColour: string,
  title: string,
  confirm: string,
  locationOptions: {
    placeholder?: ?string,
    initialSelectedValue?: Option,
    key: string,
    label: string,
    options: Option[],
  }[],
  actions: Object,
  initialValues: Object,
  globalStyling: Object,
};

export default function LocationSettings({
  iconColour,
  title,
  confirm,
  locationOptions,
  actions,
  initialValues,
  globalStyling,
}: Props) {
  return (
    <OpenClose>
      {({ open, openClose }) => (
        <div>
          {open ? (
            <Modal overlay onClickOutside={openClose}>
              <LocationContainer>
                <FormLocation
                  title={title}
                  confirm={confirm}
                  formOptions={locationOptions}
                  onSubmit={selected => {
                    if (
                      selected.Language &&
                      selected.Language !== initialValues.language
                    ) {
                      actions.language({ value: selected.Language });
                    }

                    if (
                      selected.Currency &&
                      selected.Currency !== initialValues.currency
                    ) {
                      actions.currency({ value: selected.Currency });
                    }

                    if (selected.Countries !== initialValues.countries) {
                      actions.countries({ value: selected.Countries });
                    }

                    openClose();
                  }}
                  renderClose={renderClose}
                  onClose={openClose}
                  initialValues={initialValues}
                  globalStyling={globalStyling}
                />
              </LocationContainer>
            </Modal>
          ) : (
            <IConWrapper onClick={openClose}>
              <Globe width="2em" height="2em" colour={iconColour} />
            </IConWrapper>
          )}
        </div>
      )}
    </OpenClose>
  );
}

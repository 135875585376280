/* eslint-disable import/extensions */
import validate from 'validate.js';

validate.validators.isValidPhone = (value, options) => {
  const regex = /^\+?[0-9]+$/;
  if (value !== '' && !regex.exec(value)) return options.message;
  return undefined;
};

export const required = (value, translations) =>
  validate.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired,
    },
  });

export const validEmail = (value, translations) =>
  validate.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired,
    },
    email: {
      message: translations.formValidationEmail,
    },
  });

export const valueIsTrue = (value, translations) =>
  value !== true ? [translations.formValidationRequired] : undefined;

export const valueMatchesRequired = (value, comparedValue, translations) => {
  if (!comparedValue) return [translations.formValidationRequired];
  if (value !== comparedValue) return [translations.formValidationEmailMatch];
  return undefined;
};
export const meetsPasswordPolicy = (value, translations, isRequired) => {
  const policy = [/[0-9]+/, /[A-Z]+/, /[a-z]+/, /.{10,}/, /[^\da-zA-Z]/];
  if (isRequired && !value) return [translations.formValidationRequired];
  if (value && !policy.every(rule => value.match(rule))) {
    return [translations.formValidationPasswordPolicy];
  }
  return undefined;
};
export const passwordMatchesRequired = (value, comparedValue, translations) => {
  if (!comparedValue) return [translations.formValidationRequired];
  if (value !== comparedValue) {
    return [translations.formValidationPasswordMatch];
  }

  return undefined;
};
export const valueMatches = (value, comparedValue, error) => {
  if (value !== comparedValue) return [error];
  return undefined;
};

export const validPhone = (value, translations) =>
  validate.single(value, {
    presence: {
      allowEmpty: true,
      message: translations.formValidationRequired,
    },
    isValidPhone: {
      message: translations.formValidationPhone,
    },
  });

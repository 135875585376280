// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FiltersContainer from '../../linked-modules/Filters/FiltersContainer';
import { actions as filterActions } from '../../shared/filters';

type Props = {
  config: Object,
  globalStyling: Object,
};
export default ({ config, globalStyling }: Props) => {
  const dispatch = useDispatch();
  const shared = useSelector(state => state.shared);
  const queryParams = useSelector(state => state.router.queryParams);
  useEffect(() => {
    dispatch(filterActions.initialiseFilters(queryParams));
  }, []);
  return (
    <div>
      <FiltersContainer
        config={config}
        globalStyling={globalStyling}
        shared={shared}
        hideSearch={config.hideSearch}
      />
    </div>
  );
};

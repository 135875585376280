// @flow
import React from 'react';
import { connect } from 'react-redux';
import { actions as brandActions } from '../state/actions/brand';
import { actions as siteActions } from '../state/actions/site';
import type { Site as SiteType } from '../state/reducers/site';
import type { Language, Currency } from '../state/reducers/brand';

import Site from '../components/Site';
import BrandedHeader from '../components/BrandedHeader';

const mapStateToProps = state => ({
  sites: state.site.sites,
  languages: state.brand.languages,
  currencies: state.brand.currencies,
  logoUrl: state.editor.branding.logoUrl,
});

const mapDispatchToProps = dispatch => ({
  loadBrand: () => dispatch(brandActions.loadBrand()),
  loadSite: (siteId: string) => dispatch(siteActions.loadSite(siteId)),
  saveSite: (site: SiteType) => dispatch(siteActions.saveSite(site)),
});

export type SiteId = $PropertyType<SiteType, 'id'>;

type Props = {
  loadBrand: () => void,
  loadSite: SiteId => void,
  saveSite: SiteType => void,
  sites: { [id: SiteId]: SiteType },
  languages: Language[],
  currencies: Currency[],
  siteId: string,
  logoUrl: string,
};

class SiteConfigurationLifecycle extends React.Component<Props, *> {
  componentDidMount() {
    this.props.loadBrand();
    this.props.loadSite(this.props.siteId);
  }

  render() {
    const {
      sites,
      siteId,
      loadBrand,
      loadSite,
      logoUrl,
      ...props
    } = this.props;
    const site = sites[siteId] || null;

    return (
      <div>
        <BrandedHeader
          logo={logoUrl}
          title={`Step 1 of 2 - Configure AVL ${site ? site.name : ''}`}
        />
        <Site site={site} {...props} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteConfigurationLifecycle);

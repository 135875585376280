import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from './actions';
import { actions as languageActions } from '../../actions/language';

export function useMarketSelector(translations) {
  const dispatch = useDispatch();
  const marketSelected = useSelector(
    state => state.shared.sessionPreferences.marketSelected,
  );
  const languages = useSelector(
    state => state.config.config.global.inventory.languages,
  );
  const currencies = useSelector(
    state => state.config.config.global.inventory.currencies,
  );
  const markets = useSelector(
    state => state.config.config.global.inventory.markets,
  );

  const locale = useSelector(state => state.config.settings.locale);

  const [languageOpen, toggleLanguageOpen] = useState(false);
  const [currencyOpen, toggleCurrencyOpen] = useState(false);
  const [marketOpen, toggleMarketOpen] = useState(false);

  const [currency, setCurrency] = useState(currencies[0]);
  const [language, setLanguage] = useState(
    languages.find(l => l.value === locale),
  );
  const [market, setMarket] = useState();

  const closeAll = () => {
    toggleMarketOpen(false);
    toggleCurrencyOpen(false);
    toggleLanguageOpen(false);
  };

  const submit = () =>
    dispatch(
      actions.setMarketSelection({
        currency: currency.value,
        language: language.value,
        market: market ? market.value : null,
      }),
    );

  const dropDowns = [
    {
      open: marketOpen,
      heading: translations.selectCountry,
      title: (market && market.label) || translations.allCountries,
      setter: opt => e => {
        e.stopPropagation();
        toggleMarketOpen(false);
        setMarket(opt);
      },
      toggle: e => {
        e.stopPropagation();
        toggleMarketOpen(!marketOpen);
      },
      showResetOption: !!market,
      resetLabel: translations.allCountries,
      options: markets,
    },
    {
      open: currencyOpen,
      heading: translations.selectCurrency,
      title: currency.label,
      setter: opt => e => {
        e.stopPropagation();
        toggleCurrencyOpen(false);
        setCurrency(opt);
      },
      toggle: e => {
        e.stopPropagation();
        toggleCurrencyOpen(!currencyOpen);
      },
      options: currencies,
    },
    {
      open: languageOpen,
      heading: translations.selectLanguage,
      title: language.label,
      setter: opt => e => {
        e.stopPropagation();
        toggleLanguageOpen(false);
        if (language !== opt) {
          setLanguage(opt);
        }
      },
      toggle: e => {
        e.stopPropagation();
        toggleLanguageOpen(!languageOpen);
      },
      options: languages,
    },
  ];

  useEffect(() => {
    if (language.value !== locale) {
      dispatch(languageActions.changeLanguage(language.value));
    }
  }, [language.value]);

  return {
    marketSelected,
    currency,
    language,
    dropDowns,
    closeAll,
    submit,
  };
}

const SectionIcons = {
  BottomTier: 'BottomTier',
  CallToAction: 'CallToAction',
  Carousel: 'Carousel',
  CarouselText: 'CarouselText',
  Gallery: 'Gallery',
  GridItem: 'GridItem',
  LetterB: 'LetterB',
  LetterD: 'LetterD',
  LetterL: 'LetterL',
  LetterP: 'LetterP',
  LetterT: 'LetterT',
  LetterS: 'LetterS',
  MidTier: 'MidTier',
  QuickLinks: 'QuickLinks',
  SideVideo: 'SideVideo',
  SlideBottom: 'SlideBottom',
  SlideTop: 'SlideTop',
  SocialMedia: 'SocialMedia',
  TopTier: 'TopTier',
  HeroCarousel: 'HeroCarousel',
  SideCarousel: 'SideCarousel',
};
export default SectionIcons;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { get } from '../../helpers/http';
import { postsServiceUrl } from '../../shared/selectors/settings';

export function useSummaryPost(postId) {
  const [postData, setPostData] = useState(null);
  const postsUrl = useSelector(postsServiceUrl);

  useEffect(() => {
    const getPostData = async () => {
      const url = `${postsUrl}posts/${postId}/summary`;
      try {
        const result = await get({ url });
        setPostData({
          ...(result.data.data.image && { image: result.data.data.image }),
          translations: {
            ...(result.data.data.title && {
              headlineText: result.data.data.title,
            }),
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    if (postId) {
      getPostData();
    }
  }, [postId]);

  return postData;
}

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { actions as searchFilterActions } from '../shared/searchFilter';
import { actions as sessionPreferencesActions } from '../shared/sessionPreferences';
import { actions as routerActions } from '../actions/router';
import StickySearchBar from '../components/StickySearchBar';
import { distanceOptions } from '../components/SearchFilters/search-filters-helper';

const mapStateToProps = ({ shared }) => ({
  filterSearchUrl: shared.searchFilter.filterSearchUrl,
  maxDistance: shared.searchFilter.maxDistance,
  location: shared.sessionPreferences.location || {},
  distanceOptions,
});

const mapDispatchToProps = dispatch => ({
  resetFilters: () => dispatch(searchFilterActions.resetFilters()),
  handleLocationChange: ({ latitude, longitude }: Object) => {
    dispatch(
      sessionPreferencesActions.updateSessionPreferences('location', {
        latitude,
        longitude,
      }),
    );
  },
  handleMaxDistanceChange: (maxDistance: string) =>
    dispatch(searchFilterActions.changeMaxDistance({ maxDistance })),
  buttonPress: () => dispatch(routerActions.navigate('searchresults')),
});

type Config = {
  locationIconUrl: string,
};

type Props = {
  config: Config,
};

function SearchFilterLocationSearchBarContainer({ locationIconUrl }: Props) {
  return <StickySearchBar locationIconUrl={locationIconUrl} />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StickySearchBar, SearchFilterLocationSearchBarContainer);

// @flow
import { put, call } from 'redux-saga/effects';

import { actions, constants, LoadCloneableSitesAction } from '../actions/site';
import type {
  SaveSiteAction,
  LoadSiteAction,
  SkipCloningAction,
  CloneSiteAction,
} from '../actions/site';
import { actions as routerActions } from '../actions/router';
import { TO } from '../../constants/routes';
import type { Site } from '../reducers/site';
import { get, post, put as update } from '../../helpers/http';
import httpError from '../../helpers/error';
import {
  API_BASE_URL_SITES,
  API_BASE_URL_SITE_CONFIGURATION,
  API_BASE_URL_SKIP_CLONING,
  API_BASE_URL_CLONE_SITE,
} from '../../constants/apis';
import watch from '../../helpers/watch';

function up(site: Site) {
  return {
    ...site,
    configuration: {
      ...site.configuration,
      defaultLanguageId:
        site.configuration && site.configuration.defaultLanguage.id,
      defaultCurrencyId:
        site.configuration && site.configuration.defaultCurrency.id,
    },
  };
}

export function down(site: any): Site {
  return {
    ...site,
    configuration: site.configuration && {
      ...site.configuration,
      defaultLanguage: site.configuration.languages.find(
        l => l.id === site.configuration.defaultLanguageId,
      ),
      defaultCurrency: site.configuration.currencies.find(
        c => c.id === site.configuration.defaultCurrencyId,
      ),
    },
  };
}

export function* loadSites(token: string): Generator<*, *, *> {
  const url = API_BASE_URL_SITES;
  try {
    const response = yield call(get, { url, token });
    const sites = response.data.contents.map(down);
    yield put(actions.loadSitesSuccess(sites));
  } catch (error) {
    const action = actions.loadSitesFailure(
      httpError(error, 'Failed to load sites'),
    );
    yield put(action);
  }
}

export function* loadSite(
  token: string,
  action: LoadSiteAction,
): Generator<*, *, *> {
  const siteId = action.payload;
  const url = API_BASE_URL_SITE_CONFIGURATION(siteId);

  try {
    const response = yield call(get, { url, token });
    const site = down(response.data);
    yield put(actions.loadSiteSuccess(site));
  } catch (error) {
    yield put(actions.loadSiteFailure(httpError(error, 'Failed to load site')));
  }
}

export function* loadCloneableSites(
  token: string,
  action: LoadCloneableSitesAction,
): Generator<*, *, *> {
  const url = API_BASE_URL_CLONE_SITE(action.payload);
  try {
    const response = yield call(get, { url, token });
    const sites = response.data.contents.map(down);
    yield put(actions.loadCloneableSitesSuccess(sites));
  } catch (error) {
    yield put(
      actions.loadCloneableSitesFailure(
        httpError(error, 'Failed to load sites'),
      ),
    );
  }
}

export function* createSite(
  token: string,
  { payload }: SaveSiteAction,
): Generator<*, *, *> {
  const site = up(payload);
  const url = API_BASE_URL_SITE_CONFIGURATION(site.id);
  try {
    const response = yield call(update, { url, token, data: site });
    yield put(actions.saveSiteSuccess(response.data));
    yield put(routerActions.navigate(TO.CLONE(site.id)));
  } catch (error) {
    yield put(
      actions.saveSiteFailure(httpError(error, 'Failed to create site')),
    );
  }
}

// TODO - integration with backend services when cloning/skipping cloning
export function* skipCloningSite(
  token: string,
  { payload }: SkipCloningAction,
): Generator<*, *, *> {
  const url = API_BASE_URL_SKIP_CLONING(payload);
  try {
    yield call(post, { url, token });
    yield put(routerActions.navigate(TO.SITE_BUILDER(payload)));
  } catch (error) {
    yield put(
      actions.skipCloningFailure(httpError(error, 'Failed to skip cloning')),
    );
  }
}

export function* cloneSite(
  token: string,
  { payload }: CloneSiteAction,
): Generator<*, *, *> {
  const url = API_BASE_URL_CLONE_SITE(payload.site);
  try {
    yield call(post, {
      url,
      token,
      data: { id: payload.siteToCloneFrom.value, mode: payload.mode.value },
    });
    yield put(routerActions.navigate(TO.SITE_BUILDER(payload.site)));
  } catch (error) {
    yield put(
      actions.cloneSiteFailure(httpError(error, 'Failed to clone site')),
    );
  }
}

export default [
  watch(constants.SaveSite, createSite, true),
  watch(constants.LoadSite, loadSite, true),
  watch(constants.LoadSites, loadSites, true),
  watch(constants.LoadCloneableSites, loadCloneableSites, true),
  watch(constants.SkipCloning, skipCloningSite, true),
  watch(constants.CloneSite, cloneSite, true),
];

// @flow
/* eslint-disable no-return-assign, no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import media from '../theme';

const Anchor = styled.a.withConfig({
  displayName: 'Anchor',
})`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Logo = styled.img.withConfig({
  displayName: 'Logo',
})`
  max-height: ${({ height }) => (height ? `${height}px` : '50px')};
  max-width: ${({ width }) => (width ? `${width}px` : '100px')};
  ${media.max.large`
    margin: 10px 15px;
    max-width: 80px;
`};
`;

type Props = {
  pageLink: string,
  logoUrl: string,
  fallbackUrl: string,
  brandLogoVisibleDesktop: boolean,
  preview: boolean,
  navigateOnClick: Function,
  brandLogoSize: Object,
};

export default function BrandLogo({
  logoUrl,
  brandLogoVisibleDesktop,
  preview,
  navigateOnClick,
  brandLogoSize,
}: Props) {
  return (
    brandLogoVisibleDesktop && (
      <Anchor
        onClick={e => {
          if (preview) {
            e.preventDefault();
          } else {
            navigateOnClick();
          }
        }}
      >
        <Logo
          width={brandLogoSize && brandLogoSize.width}
          height={brandLogoSize && brandLogoSize.height}
          src={logoUrl}
          alt="Brand Logo"
        />
      </Anchor>
    )
  );
}

BrandLogo.defaultProps = {
  preview: false,
};

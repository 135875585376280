// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Pin({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 25">
        <path d="M12.893 1C8.055 1 4 4.87 4 9.488c0 2.037.711 4.074 2.134 5.432 1.637 1.969 6.83 8.08 6.83 8.08s5.265-6.383 6.902-8.216C21.146 13.358 22 11.389 22 9.352 21.787 4.735 17.66 1 12.893 1zm-.213 13.648c-2.846 0-5.478-2.376-5.478-5.228 0-2.716 2.49-5.229 5.478-5.229 2.988 0 5.478 2.377 5.478 5.229 0 2.852-2.419 5.228-5.478 5.228z" />
      </svg>
    </IconWrapper>
  );
}

Pin.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

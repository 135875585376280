// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';
import type { Option, Font } from '../../types';
import {
  HeadingOne,
  fontStyleOverride,
  HeadingTwo,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';

export interface Config {
  imageAlign: string;
  selectedImage: Option;
  backgroundColour: Option;
  headlineFont: Font;
  bodyFont: Font;
  translations: {
    headlineText: string,
    subHeading: string,
  };
  marginTop: number;
  marginLeft: number;
  contentWidth: number;
  contentHeight: number;
  opacity: number;
  imageHeight: number;
}

type Props = {
  config: Config,
  globalStyling: Object,
};

const Container = styled.div`
  height: ${({ height }) => (height ? `${height}px` : '100px')};
  max-height: ${({ height }) => (height ? `${height}px` : '100px')};
  flex-direction: column;
  display: flex;
`;

const Image = styled.div`
  height: inherit;
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  position: absolute;
  background-position: center;
  ${media.max.medium`
    position: relative;
  `};
`;

const HeadingBackgroundColor = styled.div`
  ${({ colour }) => colour && `background-color: ${colour}`};
  ${({ width }) => width && `width: ${width}px`};
  ${({ height }) => height && `height: ${height}px`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  ${({ padding }) => padding && `padding-left: ${padding}px`};
  ${({ opacity }) => opacity && `opacity: ${opacity}`};
  display: flex;
  position: absolute;
  box-sizing: border-box;
  ${({ padding, direction }) =>
    padding && direction === 'rtl' && `padding-right: ${padding}px`};
  ${media.max.medium`
    position: relative;
    height: auto
    width: 100%
    margin: 0;
    opacity: 1;
    top: 0;
    left: 0;
    padding: 0px 20px;
  `};
`;

export default function ImageTitle({ config, globalStyling }: Props) {
  return (
    <Container height={config.imageHeight}>
      <Image src={config.selectedImage && config.selectedImage.value} />
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <HeadingBackgroundColor
          colour={config.backgroundColour && config.backgroundColour.value}
          width={config.contentWidth}
          height={config.contentHeight}
          marginTop={config.marginTop}
          marginLeft={config.marginLeft}
          padding={config.paddingLeft}
          opacity={config.opacity}
          direction={globalStyling.direction}
        >
          {config.translations.headlineText && (
            <HeadingOne
              styleOverride={() => `
              ${fontStyleOverride(config.headlineFont)}
                margin: 10px 0;
                display: flex;
                align-items: center;
              `}
            >
              {config.translations.headlineText}
            </HeadingOne>
          )}

          {config.translations.subHeading && (
            <HeadingTwo
              styleOverride={() => fontStyleOverride(config.bodyFont)}
            >
              {config.translations.subHeading}
            </HeadingTwo>
          )}
        </HeadingBackgroundColor>
      </ContentWrapper>
    </Container>
  );
}

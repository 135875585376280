// @flow
import { put, call } from 'redux-saga/effects';
import watch from '../../helpers/watch';
import { saveSiteSettings } from './settings';
import { actions, constants } from '../actions/finance';
import type { SaveFinanceOptionsAction } from '../actions/finance';

export function* saveFinanceOptions(
  token: string,
  action: SaveFinanceOptionsAction,
): Generator<*, *, *> {
  try {
    yield call(saveSiteSettings, token, action.payload);
    yield put(actions.saveFinanceOptionsSuccess());
  } catch (error) {
    yield put(actions.saveFinanceOptionsFailure());
  }
}

export default [watch(constants.SaveFinanceOptions, saveFinanceOptions, true)];

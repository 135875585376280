// @flow

import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

const StyledTextArea = styled.textarea.withConfig({
  displayName: 'StyledTextArea',
})`
  box-sizing: border-box;
  width: 100%;
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormControls};
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary06};
  border-color: ${({ theme }: { theme: Theme }) => theme.colours.secondary03};
  resize: vertical; /* IE and Edge users will just have to be careful! */
  &:focus {
    border: 1px solid
      ${({ theme }: { theme: Theme }) => theme.colours.primary01};
  }
`;

type Props = {
  value: string,
  onChange: string => void,
  placeholder: string,
  rows: number,
};

export default function TextArea({
  value,
  onChange,
  placeholder,
  rows,
}: Props) {
  return (
    <StyledTextArea
      onChange={event => onChange(event.target.value)}
      placeholder={placeholder}
      rows={rows}
      value={value}
    />
  );
}

// @flow
import React from 'react';
import Dayzed from 'dayzed';
import styled from 'styled-components';
import { subDays } from 'date-fns';
import Chunky from './JaguarIcons/Arrows/Chunky';

const Container = styled.div.withConfig({
  displayName: 'Container',
})``;

const NavigationContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 18px;
`;

const NavigationButton = styled.button`
  color: ${({ colour }) => colour};
  font-weight: 600;
  cursor: pointer;
  border: none;
  ${({ navigationButton }) =>
    navigationButton.background &&
    `background: ${navigationButton.background}`};
`;

const WeekDaysContainer = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid #d8d8d8;
`;

const WeekDaysHeaderCell = styled.div`
  display: inline-block;
  width: ${100 / 7}%;
  padding: 6px 6px;
  border: none;
  text-align: center;
  color: #b9c1cb;
  font-size: 10px;
  box-shadow: inset 0 0 0 0 #d2d8df, inset 0 -1px 0 0 #d2d8df,
    inset -1px 0 0 0 #d2d8df;
  background: #ffffff;
  box-sizing: border-box;
`;

const EmptyDayCell = styled.div`
  display: inline-block;
  width: ${100 / 7}%;
  border: none;
  box-shadow: inset 1px -1px 0 0 #d2d8df, inset -1px 0 1px 0 #d2d8df;
  background: #ffffff;
  box-sizing: border-box;
`;

const DayCell = styled.button`
  display: inline-block;
  width: ${100 / 7}%;
  border: none;
  box-shadow: inset 0 -1px 0 0 #d2d8df, inset -1px 0 1px 0 #d2d8df;
  padding: 6px 6px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  box-sizing: border-box;
`;

const DayCellExpired = styled.div`
  color: #d8d8d8;
`;

const MonthYearHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
`;

type DayzedProps = {
  onDateSelected: any => void,
  selected: Date,
  date: Date,
  globalStyling: Object,
  calendarStyles: Object,
  translations: Object,
  locale: string,
};

export default function DatePicker({
  onDateSelected,
  selected,
  date,
  calendarStyles,
  globalStyling: { colours },
  translations,
}: DayzedProps) {
  let emptyDayCellId = 'emptyDayCellId';
  let dayCellId = 'dayCellId';
  let weekDaysCellId = 'weekDaysCellId';
  const excludeYesterday = subDays(new Date(), 1);
  const { navigationButton = '' } = calendarStyles || '';

  const monthNames = [
    translations.january,
    translations.february,
    translations.march,
    translations.april,
    translations.may,
    translations.june,
    translations.july,
    translations.august,
    translations.september,
    translations.october,
    translations.november,
    translations.december,
  ];

  const weekdayNamesShort = [
    translations.sunday.substring(0, 1),
    translations.monday.substring(0, 1),
    translations.tuesday.substring(0, 1),
    translations.wednesday.substring(0, 1),
    translations.thursday.substring(0, 1),
    translations.friday.substring(0, 1),
    translations.saturday.substring(0, 1),
  ];

  const primaryBrandColour =
    colours.primaryBrandColour && colours.primaryBrandColour.value;
  return (
    <Dayzed
      onDateSelected={onDateSelected}
      minDate={excludeYesterday}
      selected={selected}
      render={({ calendars, getBackProps, getForwardProps, getDateProps }) => {
        if (calendars.length) {
          return (
            <Container>
              {calendars.map(calendar => (
                <div key={`${calendar.month}${calendar.year}`}>
                  <NavigationContainer>
                    <NavigationButton
                      colour={primaryBrandColour}
                      style={{ float: 'left' }}
                      {...getBackProps({ calendars })}
                      navigationButton={navigationButton}
                    >
                      <div style={{ transform: 'rotateY(180deg)' }}>
                        <Chunky colour={primaryBrandColour} />
                      </div>
                    </NavigationButton>
                    <NavigationButton
                      style={{ float: 'right' }}
                      {...getForwardProps({ calendars })}
                      navigationButton={navigationButton}
                    >
                      <div>
                        <Chunky colour={primaryBrandColour} />
                      </div>
                    </NavigationButton>
                    <MonthYearHeader>
                      {monthNames[calendar.month]} {calendar.year}
                    </MonthYearHeader>
                  </NavigationContainer>

                  <WeekDaysContainer>
                    {weekdayNamesShort.map(weekday => {
                      weekDaysCellId += 1;
                      return (
                        <WeekDaysHeaderCell
                          key={`${calendar.month}${
                            calendar.year
                          }${weekday}${weekDaysCellId}`}
                        >
                          {weekday}
                        </WeekDaysHeaderCell>
                      );
                    })}
                  </WeekDaysContainer>

                  {calendar.weeks.map(week =>
                    week.map(dateObj => {
                      emptyDayCellId += 1;
                      if (!dateObj) {
                        return (
                          <EmptyDayCell
                            key={`${calendar.year}${
                              calendar.month
                            }${emptyDayCellId}`}
                          >
                            {' '}
                          </EmptyDayCell>
                        );
                      }
                      const { date: initialDate, selectable, today } = dateObj;

                      const isSelected =
                        date.getDate() === initialDate.getDate();
                      const todayTextColour = today
                        ? primaryBrandColour
                        : '#67727D';
                      const selectedTextColour = isSelected
                        ? '#FFFFFF'
                        : '#67727D';
                      const textColour = isSelected
                        ? selectedTextColour
                        : todayTextColour;
                      const selectedDayBackgroundColour = isSelected
                        ? primaryBrandColour
                        : '#FFFFFF';

                      dayCellId += 1;
                      return (
                        <DayCell
                          style={{
                            backgroundColor: selectedDayBackgroundColour,
                            color: textColour,
                          }}
                          key={`${calendar.year}${calendar.month}${dayCellId}`}
                          {...getDateProps({
                            dateObj,
                          })}
                        >
                          {selectable ? (
                            initialDate.getDate()
                          ) : (
                            <DayCellExpired>
                              {' '}
                              {initialDate.getDate()}{' '}
                            </DayCellExpired>
                          )}
                        </DayCell>
                      );
                    }),
                  )}
                </div>
              ))}
            </Container>
          );
        }
        return null;
      }}
    />
  );
}

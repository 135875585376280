// @flow
import { connect } from 'react-redux';
import SiteBuilderView from '../components/SiteBuilderView';
import type { ScreenSizeOption } from '../screenSizeOptions';

const mapStateToProps = ({ siteBuilder, context }) => ({
  globalContext: context.global,
  selectedScreenSize: siteBuilder.selectedScreenSize,
});

export type Props = {
  globalContext: string,
  selectedScreenSize: ScreenSizeOption,
};

export default connect(mapStateToProps)(SiteBuilderView);

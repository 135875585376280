// @flow
import styled from 'styled-components';
import path from 'ramda/src/path';
import media from '../../theme';

const screenSizeOptions = {
  max: {
    small: 'maxSmallMobileStyleOverride',
    medium: 'maxMediumMobileStyleOverride',
    large: 'maxLargeMobileStyleOverride',
  },
  min: {
    small: 'minSmallMobileStyleOverride',
    medium: 'minMediumMobileStyleOverride',
    large: 'minLargeMobileStyleOverride',
  },
};

export const createElement = (element: string) => styled[element].attrs(() => ({
  className: 'global',
}))`
  &&& {
    ${({ styleOverride }) => styleOverride && styleOverride()};
    ${media.max.medium`${({ maxMediumMobileStyleOverride }) =>
      maxMediumMobileStyleOverride};`};
    ${media.max.small`${({ maxSmallMobileStyleOverride }) =>
      maxSmallMobileStyleOverride};`};
    ${media.max.large`${({ maxLargeMobileStyleOverride }) =>
      maxLargeMobileStyleOverride};`};
    ${media.min.medium`${({ minMediumMobileStyleOverride }) =>
      minMediumMobileStyleOverride};`};
    ${media.min.small`${({ minSmallMobileStyleOverride }) =>
      minSmallMobileStyleOverride};`};
    ${media.min.large`${({ minLargeMobileStyleOverride }) =>
      minLargeMobileStyleOverride};`};
  }
`;

export const mediaProps = (mobileStyleOverride = []) =>
  mobileStyleOverride.reduce(
    (ac, { queryPath, template }) => ({
      ...ac,
      [path(queryPath.split('.'), screenSizeOptions)]: template,
    }),
    {},
  );

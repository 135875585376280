// @flow

import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';

import type { Theme } from '../theme';

const FieldRow = styled.div.withConfig({
  displayName: 'FieldRow',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const LabelCell = styled.div.withConfig({
  displayName: 'LabelCell',
})`
  font-weight: bold;
  margin-right: 8px;
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormLabel};
`;

const InputCell = styled.div.withConfig({
  display: 'InputCell',
})`
  flex-grow: ${({ inputFlexMode }) => `${inputFlexMode}`};
`;

type Props = {
  children: Node,
  label: string,
  inputFlexMode?: string,
};

export default function FieldLabel({ children, label, inputFlexMode }: Props) {
  return (
    <FieldRow>
      <LabelCell>{label}</LabelCell>
      <InputCell inputFlexMode={inputFlexMode}>{children}</InputCell>
    </FieldRow>
  );
}

FieldLabel.defaultProps = {
  inputFlexMode: '1',
};

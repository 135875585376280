// @flow

import React from 'react';
import styled from 'styled-components';
import IoChevronRight from 'react-icons/lib/io/chevron-right';

import type { Node } from 'react';
import type { Theme } from '../theme';

import FlexWrapper from './FlexWrapper';
import IconBox from './IconBox';

type ThemeProps = { theme: Theme };

type Props = {
  subtitle?: string,
  withBorder?: boolean,
  withoutChevron?: boolean,
  onPlusClick?: Function,
  onClick: Function,
  children: Node,
};

const itemBorder = shadowColour => `1px solid ${shadowColour}`;

const Body = styled.div.withConfig({
  displayName: 'Title',
})`
  width: 100%;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  color: ${(props: ThemeProps) => props.theme.colours.secondary03};
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  padding: ${(props: ThemeProps) => props.theme.layout.standardPadding};
  background-color: ${(props: ThemeProps) => props.theme.colours.primary03};
  border-bottom: ${(props: Props & ThemeProps) =>
    props.withBorder ? itemBorder(props.theme.colours.secondary02) : 'none'};
  cursor: pointer;
  user-select: none;
`;

const Title = styled.div.withConfig({
  displayName: 'Title',
})`
  color: ${(props: ThemeProps) => props.theme.colours.secondary03};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
`;

const Subtitle = styled.div.withConfig({
  displayName: 'Subtitle',
})`
  color: ${(props: ThemeProps) => props.theme.colours.secondary02};
  font-size: 12px;
  line-height: 15px;
`;

const PlusIcon = styled.img`
  height: 1em;
  color: ${({ theme }) => `${theme.colours.secondary03}`};
`;

export default function SectionMenuItem({
  children,
  onClick,
  subtitle,
  withBorder,
  withoutChevron,
  onPlusClick,
}: Props) {
  return (
    <Body onClick={onClick} withBorder={withBorder}>
      <FlexWrapper>
        <Title>{children}</Title>
        {subtitle && <Subtitle> {subtitle} </Subtitle>}
      </FlexWrapper>
      {onPlusClick && (
        <IconBox>
          <PlusIcon
            src="https://res.cloudinary.com/motortrak/image/upload/v1566479519/editor/plus-newpage.svg"
            onClick={e => {
              e.stopPropagation();
              onPlusClick();
            }}
          />
        </IconBox>
      )}
      {!withoutChevron && (
        <IconBox>
          <IoChevronRight />
        </IconBox>
      )}
    </Body>
  );
}

SectionMenuItem.defaultProps = {
  subtitle: '',
  withBorder: false,
  withoutChevron: false,
};

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import GlobalInfo from './JaguarIcons/Global/GlobalInfo';
import Close from './JaguarIcons/Global/Close';
import OpenClose from './OpenClose';
import Modal from './Modal';
import { HeadingTwo, Paragraph } from './Global';
import FinanceContainer from '../containers/FinanceContainer';
import FinanceExample from './FinanceExample';
import media from '../theme';

const ActionButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const ModalTitleWrapper = styled.div.withConfig({
  displayName: 'ModalTitleWrapper',
})`
  display: flex;
  background-color: #f0f0f0;
  width: 100%;
  align-items: center;
`;

const CloseButtonWrapper = styled.div.withConfig({
  displayName: 'CloseButtonWrapper',
})`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 10%;
`;

const FinanceModalContent = styled.div.withConfig({
  displayName: 'FinanceModalContent',
})`
  padding: 20px;
  margin: 40px;
  border: 1px solid #acacac;
  ${media.max.small`
    margin: 0;
    border: 0;
    padding: 0 30px 0 10px;
  `};
  ${media.max.medium`
    margin: 10px;
  `};
`;

type Props = {
  translations: Translations,
  data: Object,
  locale: string,
};

export default function FinancePopup({ data }: Props) {
  return (
    <OpenClose>
      {({ open, openClose }) => (
        <div>
          {open ? (
            <Modal overlay onClickOutside={openClose}>
              <FinanceContainer
                component={props => (
                  <Fragment>
                    <ModalTitleWrapper>
                      <HeadingTwo
                        data-qa-hook="Popup-Title"
                        styleOverride={() => `
                        display: flex;
                        justify-content: center;
                        width: 80%;
                        margin-left: 10%;
                        padding: 20px 0;
                        text-transform: uppercase;
                      `}
                      >
                        {props.finance.translations.financeModalTitle}
                      </HeadingTwo>
                      <CloseButtonWrapper
                        onClick={openClose}
                        data-qa-hook="Close-Button"
                      >
                        <Close width="1em" height="1em" colour="#ACACAC" />
                      </CloseButtonWrapper>
                    </ModalTitleWrapper>
                    <FinanceModalContent>
                      <Paragraph
                        styleOverride={() => `
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        color: #444;
                        font-weight: 600;
                        white-space: pre-line;
                      `}
                      >
                        {props.translateFinanceTemplate('financeMyDealTitle', {
                          PRODUCT:
                            props.finance.translations[
                              `finance${props.finance.defaultProduct}Label`
                            ],
                        })}
                      </Paragraph>
                      <FinanceExample {...props} data={data} />
                    </FinanceModalContent>
                  </Fragment>
                )}
              />
            </Modal>
          ) : (
            <ActionButton onClick={openClose}>
              <GlobalInfo width="1.5em" height="1.5em" colour="#000000" />
            </ActionButton>
          )}
        </div>
      )}
    </OpenClose>
  );
}

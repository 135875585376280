// @flow
import React from 'react';
import styled from 'styled-components';

const StyledFieldListRow = styled.li`
  display: flex;
`;

export default function FieldListRow(props: Object) {
  return <StyledFieldListRow {...props} />;
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import ColourPreview from '../ColourPreview';

const ColourPickerWrapper = styled.div`
  height: 40px;
  width: 160px;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? '#D0021B' : '#d6d6d6')};
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledInput = styled.input`
  font-family: inherit;
  width: 100px;
  height: 24px;
  border: none;
  padding: 0;
  margin: 0 0 0 10px;
  font-size: 14px;
  color: #474747;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #ffffff;
  }
`;

type Props = {
  availableColours: { label: string, value: string }[],
  saveBrandColour: (hex: string) => void,
  hex: string | null,
  disabled?: boolean,
};
type State = {
  hexValue: string,
  valid: boolean,
  error: string,
};

const hexPattern = /[#][A-F0-9a-f]{6}/g;
export default class ColourPicker extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hexValue: props.hex || '',
      valid: true,
      error: '',
    };
  }

  onInputChange = (hex: string) => {
    let hexValue = hex;
    if (hexValue === '') hexValue = '#';
    const error = this.isValidInputValue(hexValue);
    if (!error) this.props.saveBrandColour(hexValue);
    this.setState(() => ({ hexValue, valid: error === '', error }));
  };

  onInputFocus = () => {
    if (this.state.hexValue.length === 0) this.onInputChange('#');
  };

  onInputBlur = () => {
    if (this.state.hexValue === '#') {
      this.setState(() => ({ hexValue: '', valid: true, error: '' }));
    }
  };

  isValidInputValue = (hex: string) => {
    if (this.props.availableColours.find(c => c.value === hex) !== undefined) {
      return 'This colour is already in your collection.';
    }
    if (hex.match(hexPattern) === null) return 'This is not a valid hex value.';
    return '';
  };

  props: Props;

  render = () => {
    const { hexValue, valid, error } = this.state;
    const { disabled } = this.props;
    return (
      <div>
        <ColourPickerWrapper
          data-qa-hook="colour-picker-box"
          isInvalid={!disabled && !valid}
        >
          <StyledInput
            data-qa-hook="colour-picker-input"
            onFocus={this.onInputFocus}
            onChange={e => this.onInputChange(e.target.value)}
            onBlur={this.onInputBlur}
            value={hexValue}
            placeholder="Type hex value"
            disabled={disabled}
            maxLength="7"
          />
          <ColourPreview hexValue={hexValue} />
        </ColourPickerWrapper>
        {error && <div>{error}</div>}
      </div>
    );
  };
}

// @flow
import React, { Fragment, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import ComponentContext from '../../renderer/ComponentContext';
import media from '../../theme';

import WindowDimensionsProvider from '../../shared/WindowDimensionsProvider';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  max-width: ${({ contain, contentWidth }) =>
    contain ? `${contentWidth}px;` : '100%;'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  * {
    box-sizing: border-box;
  }
  ${media.min.medium`
    flex-direction: row;
  `};
`;
// TODO fix types/interfaces
interface IBandConfig {
  pods: Object[];
  contain: boolean;
}

type Props = {
  config: IBandConfig,
  dispatch: () => void,
  globalStyling: Object,
  instanceId: string,
};

export default ({ band, globalStyling }: Props) => {
  const {
    config: { pods, contain },
  } = band;

  const { pods: availablePods } = useContext(ComponentContext);
  const pod1 = useSelector(s => s.config.pods[pods[0]]);
  const pod2 = useSelector(s => s.config.pods[pods[1]]);

  const pod3 = useSelector(s => s.config.pods[pods[2]]);
  const pod4 = useSelector(s => s.config.pods[pods[3]]);

  const dispatch = useDispatch();
  const podsToRender = [pod1, pod2, pod3, pod4].filter(Boolean);

  return (
    <Wrapper>
      <WindowDimensionsProvider>
        <ContentWrapper
          contain={contain}
          contentWidth={globalStyling.contentWidth || 1366}
        >
          {podsToRender.map(p => {
            const Pod = availablePods[p.id];
            return (
              <Fragment key={p.instanceId}>
                <Pod {...{ ...p, dispatch, globalStyling }} />
              </Fragment>
            );
          })}
        </ContentWrapper>
      </WindowDimensionsProvider>
    </Wrapper>
  );
};

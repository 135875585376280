// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../theme';

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  visibility: visible;
  z-index: 100;
  overflow: hidden;
  transition: visibility 0.35s, opacity 0.35s;
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  ${media.min.medium`
    width: ${({ modalWidth }) => (modalWidth ? `${modalWidth}px;` : 'auto')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `};
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
`;

type Props = {
  children: Node,
  modalWidth: number,
  shared: Object,
};

export default function ModalCurrency({ children, modalWidth, shared }: Props) {
  const { disableCurrencyPopup, currency } = shared.sessionPreferences;
  return (
    <Fragment>
      {currency !== null && !disableCurrencyPopup && (
        <Overlay>
          <ModalContentWrapper modalWidth={modalWidth}>
            <ModalContent>{children}</ModalContent>
          </ModalContentWrapper>
        </Overlay>
      )}
    </Fragment>
  );
}

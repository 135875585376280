// @flow
import React from 'react';
import styled from 'styled-components';

import Search from './JaguarIcons/Global/Search';
import Chunky from './JaguarIcons/Arrows/Chunky';

import media from '../theme';

type SearchInputProps = {
  onSearch: (term: ?string) => void,
  placeholder: string,
  value: ?string,
  onChange: (value: string) => void,
  globalStyling: Object,
};

const SearchContainer = styled.div.withConfig({
  displayName: 'SearchContainer',
})`
  display: flex;
  height: 40px;
  width: 50%;
  padding: 0 5%;
  ${media.max.large`
    width: 100%;
  `};
`;

const SearchIconWrapper = styled.div.withConfig({
  displayName: 'SearchIconWrapper',
})`
  width: 50px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const GoIconWrapper = styled.div.withConfig({
  displayName: 'GoIconWrapper',
})`
  background-color: ${props => (props.colour ? `${props.colour}` : '#000000')};
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledInput = styled.input.withConfig({
  displayName: 'StyledInput',
})`
  width: 80%;
  padding-left: 5%;
  margin: 0;
  border: 1px solid #d8d8d8;
  font-size: 16px;
  &:focus {
    border: 1px solid #999999;
    outline: none;
  }
`;

export default function SearchInput({
  onSearch,
  placeholder = '',
  value,
  onChange,
  globalStyling,
}: SearchInputProps) {
  return (
    <SearchContainer>
      <SearchIconWrapper>
        <Search
          colour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
          width="1.5em"
          height="1.5em"
        />
      </SearchIconWrapper>
      <StyledInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && onSearch(value)}
      />
      <GoIconWrapper
        colour={
          globalStyling.colours.primaryBrandColour &&
          globalStyling.colours.primaryBrandColour.value
        }
        onClick={() => onSearch(value)}
      >
        <Chunky colour="#FFFFFF" />
      </GoIconWrapper>
    </SearchContainer>
  );
}

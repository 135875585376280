import Icon from './Icon';

export { default as component } from './IconGrid';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Icon Grid';
export const id = 'IconGrid';
export const availableToAdministration = true;
export const description = 'A grid view of items with image, text and button';
export const icon = Icon;

export function template() {
  return {
    items: {
      image: {
        label: 'default_icongrid_icon',
        value:
          'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg',
      },
      translations: {
        titleItemText: 'Title Placeholder',
        descriptionItemText: 'Description Placeholder',
      },
    },
  };
}

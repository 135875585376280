/* eslint-disable no-console */
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import { localiseNumber } from '../../shared/localisation/numbers';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getInventoryStatus,
  getInventorySimilarVehicles,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

function* getSimilarVehicles(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const inventoryStatus = yield select(getInventoryStatus);
    const inventorySimilarVehicles = yield select(getInventorySimilarVehicles);
    const url = `${baseUrl}vehicles/make/${make}/market/${market}/${
      payload.vehicleId
    }/similarvehicles`;
    const status = inventoryStatus;
    const {
      pageSize,
      sameMake,
      sameDealer,
      sameModel,
      sameRegion,
      higherMilagePercentage,
      lowerMileagePercentage,
      higherPricePercentage,
      lowerPricePercentage,
      longitude,
      latitude,
      sortDirection,
      sortField,
      languageCode,
    } = inventorySimilarVehicles;

    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale, {
          pageSize,
          sameMake,
          sameDealer,
          sameModel,
          sameRegion,
          higherMilagePercentage,
          lowerMileagePercentage,
          higherPricePercentage,
          lowerPricePercentage,
          longitude,
          latitude,
          sortDirection,
          sortField,
          languageCode,
          status,
        }),
      }),
    });

    const vehicles = [
      ...response.data.contents.map(v => ({
        ...v,
        odometer: {
          ...v.odometer,
          display: `${localiseNumber(v.odometer.reading, locale)} ${
            v.odometer.units
          }`,
        },
      })),
    ];

    yield put(
      actions.getSimilarVehiclesSuccess(page, instance, {
        similarVehicles: vehicles,
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* VdpSaga() {
  yield takeEvery(constants.GET_SIMILAR_VEHICLES, getSimilarVehicles);
}

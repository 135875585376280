// @flow
import React from 'react';
import styled from 'styled-components';

import SearchResultTile from '../SearchResultTile';
import SearchResultGridTile from '../SearchResultGridTile';
import CompareIcon from '../JaguarIcons/Global/Compare';
import HeartIcon from '../JaguarIcons/Global/Heart';
import media from '../../theme';

const Results = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${media.max.small`
    justify-content: safe;
  `};
  justify-content: ${({ length }) =>
    length && length < 3 ? 'flex-start' : 'space-between'};
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

const CompareIconButton = (onCompareClick, iconColour) => (
  <IconWrapper onClick={onCompareClick}>
    <CompareIcon width="2em" height="1.75em" colour={iconColour} />
  </IconWrapper>
);

const HeartIconButton = (onHeartClick, iconColour) => (
  <IconWrapper onClick={onHeartClick}>
    <HeartIcon width="2em" height="1.75em" colour={iconColour} />
  </IconWrapper>
);

// this has the same flex basis as the TileItemContainer
// which allows the flex display to look like a grid.
const EmptyTile = styled.div.withConfig({
  displayName: 'EmptyTile',
})`
  display: flex;
  flex-direction: column;
  flex-basis: 32%;
  width: 100%;
`;

type Props = {
  results: Object,
  translations: Object,
  onMoreInfoClick: Function,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  getCompareIconColour: Function,
  globalStyling: Object,
  filtersExpanded: boolean,
  visibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  headerItemFont: Object,
  showGridTiles: boolean,
  headerItemFont: Object,
  badgeStyling: Object,
  financeHeadingFont: Object,
  showFinancePopup: boolean,
  countDivMargin: boolean,
  compareShortlistIconWidth: number,
  compareShortlistIconColour: Object,
  icon360: string,
};
export default function GridView({
  results,
  translations,
  onMoreInfoClick,
  compareVehicle,
  shortlistVehicle,
  getShortListedIconColour,
  getCompareIconColour,
  filtersExpanded,
  globalStyling,
  visibility,
  locale,
  vehicleModelTitleFontWeight,
  headerItemFont,
  showGridTiles,
  badgeStyling,
  financeHeadingFont,
  showFinancePopup,
  countDivMargin,
  compareShortlistIconWidth,
  compareShortlistIconColour,
  icon360,
}: Props) {
  return (
    <Results length={results.length}>
      {results.map(vehicle =>
        showGridTiles ? (
          <SearchResultGridTile
            key={vehicle.id}
            align="left"
            onHeartClick={() => {}}
            vehicle={vehicle}
            placeholdingImage={results.placeholdingImage}
            checkLength={results.length < 3}
            onMoreInfoClicked={() => onMoreInfoClick('VDP', vehicle)}
            translations={translations}
            filtersExpanded={filtersExpanded}
            renderTopRightIcon={() => {}}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
            shortListedIconColour={getShortListedIconColour(vehicle.id)}
            compareIconColour={getCompareIconColour(vehicle.id)}
            globalStyling={globalStyling}
            visibility={visibility}
            locale={locale}
            vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
            headerItemFont={headerItemFont}
            badgeStyling={badgeStyling}
            countDivMargin={countDivMargin}
            compareShortlistIconWidth={compareShortlistIconWidth}
            compareShortlistIconColour={compareShortlistIconColour}
            icon360={icon360}
          />
        ) : (
          <SearchResultTile
            key={vehicle.id}
            align="left"
            onHeartClick={() => {}}
            carResult={vehicle}
            placeholdingImage={results.placeholdingImage}
            checkLength={results.length < 3}
            onMoreInfoClicked={() => onMoreInfoClick('VDP', vehicle)}
            translations={translations}
            filtersExpanded={filtersExpanded}
            renderTopRightIcon={() => {}}
            renderBottomIcon1={() =>
              CompareIconButton(
                () => compareVehicle(vehicle),
                getCompareIconColour(vehicle.id),
              )
            }
            renderBottomIcon2={() =>
              HeartIconButton(
                () => shortlistVehicle(vehicle),
                getShortListedIconColour(vehicle.id),
              )
            }
            globalStyling={globalStyling}
            visibility={visibility}
            locale={locale}
            vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
            headerItemFont={headerItemFont}
            badgeStyling={badgeStyling}
            financeHeadingFont={financeHeadingFont}
            showFinancePopup={showFinancePopup}
            countDivMargin={countDivMargin}
            compareShortlistIconWidth={compareShortlistIconWidth}
            compareShortlistIconColour={compareShortlistIconColour}
            icon360={icon360}
          />
        ),
      )}
      {results.length % 3 !== 0 && <EmptyTile />}
    </Results>
  );
}

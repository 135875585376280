// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SectionMenuItem, SectionMenuTitle } from 'cms-ui';
import { actions as contextActions } from '../state/actions/context';
import { breakoutType } from './BreakoutWrapper';
import useCreateBlog from './hooks/useCreateBlog';
import useCreateForm from './hooks/useCreateForm';
import useSearchResults from './hooks/useSearchResults';
import useEditCookiePolicy from './hooks/useEditCookiePolicy';

const SectionMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 36px;
  border-top: ${({ theme }) => `1px solid ${theme.colours.secondary02}`};
`;

const MenuContent = styled.span`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: ${({ theme }) => `${theme.layout.halfPadding}`};
  color: ${({ theme }) => `${theme.colours.secondary03}`};
`;

const addIcon =
  'https://res.cloudinary.com/motortrak/image/upload/v1566479515/editor/pages.svg';

export default function RootMenu() {
  const dispatch = useDispatch();
  const onEnterBreakout = breakout =>
    dispatch(contextActions.setBreakoutContext(breakout));
  const { createBlog } = useCreateBlog();
  const { createForm } = useCreateForm();
  const { editSearchResults } = useSearchResults();
  const { editCookiePolicy } = useEditCookiePolicy();
  return (
    <>
      <SectionMenuTitle>Main Menu</SectionMenuTitle>
      <SectionMenu>
        <SectionMenuItem
          onClick={() => onEnterBreakout({ name: breakoutType.listPages })}
          onPlusClick={() => onEnterBreakout({ name: breakoutType.createPage })}
          withBorder
        >
          <MenuContent>
            <Icon src={addIcon} />
            Pages
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem
          onClick={() => onEnterBreakout({ name: breakoutType.listPosts })}
          onPlusClick={() => createBlog()}
          withBorder
        >
          <MenuContent>
            <Icon src={addIcon} />
            Posts
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem
          onClick={() => onEnterBreakout({ name: breakoutType.listForms })}
          onPlusClick={() => createForm()}
          withBorder
        >
          <MenuContent>
            <Icon src={addIcon} />
            Forms
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem onClick={() => editCookiePolicy()} withBorder>
          <MenuContent>
            <Icon src={addIcon} />
            Cookie Policy
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem onClick={() => {}} withBorder>
          <MenuContent>
            <Icon src={addIcon} />
            Header/Footer
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem
          onClick={() =>
            dispatch(contextActions.setGlobalContext('Media Library'))
          }
          withBorder
        >
          <MenuContent>
            <Icon src={addIcon} />
            Gallery
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem onClick={() => editSearchResults()} withBorder>
          <MenuContent>
            <Icon src={addIcon} />
            Search Results
          </MenuContent>
        </SectionMenuItem>
        <SectionMenuItem withBorder>
          <MenuContent>
            <Icon src="https://res.cloudinary.com/motortrak/image/upload/v1564992732/editor/settings-icon.svg" />
            Settings
          </MenuContent>
        </SectionMenuItem>
      </SectionMenu>
    </>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import IoChevronLeft from 'react-icons/lib/io/chevron-left';
import IoChevronRight from 'react-icons/lib/io/chevron-right';
import IoChevronUp from 'react-icons/lib/io/chevron-up';
import IoChevronDown from 'react-icons/lib/io/chevron-down';

import media from '../theme';
import { HeadingTwo, fontStyleOverride } from './Global';
import ContentWrapper from './ContentWrapper';

const SubNavBarContainer = styled.div`
  display: flex;
  ${({ height }) => `${height && `height: ${height}px`}`};
  ${({ backgroundColour }) =>
    `${backgroundColour && `background-color: ${backgroundColour.value}`}`};
  ${({ bottomBorder }) =>
    `${bottomBorder &&
      `border-bottom: ${bottomBorder.size}px solid ${bottomBorder.colour}`}`};

  ${media.max.large`
    display: flex;
    height: 100%;
  `};
  @media print {
    display: none;
  }
`;

const SubNavLink = styled.div`
  display: flex;
  padding: 0 25px;
  align-items: center;
  min-width: 100px;
  &:first-child {
    ${({ itemBorder }) =>
      `${itemBorder &&
        `border-left: ${itemBorder.size}px solid ${itemBorder.colour}`}`};
  }
  ${({ itemBorder }) =>
    `${itemBorder &&
      `border-right: ${itemBorder.size}px solid ${itemBorder.colour}`}`};
  ${({ selected, selectedItemBackgroundColour }) =>
    `${selected &&
      selectedItemBackgroundColour &&
      `background-color: ${selectedItemBackgroundColour.value}`}`};

  ${media.max.large`
    /* Safary bug: when the flex parent doesn't have enough height the children's height shrinks and move inside the parent, min-height avoids the height shrinking */
    min-height: 40px;
    height: 40px;
  `};

  ${({ itemBorder, direction }) =>
    `${itemBorder &&
      direction === 'rtl' &&
      `border-left: ${itemBorder.size}px solid ${itemBorder.colour}`}`};
`;

const LinksContainer = styled.div`
  display: flex;
  flex: 1 0 0%;
  ${({ align }) => `${align && `justify-content: ${align}`}`};

  ${media.max.large`
    flex-direction: column;
  `};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
`;

const AccordionButton = styled(Button)`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${media.min.large`
    display: none;
  `};
`;

const DesktopLinks = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  ${media.max.medium`
    display: none;
  `};
`;
const MobileLinks = styled.div`
  display: none;
  overflow: hidden;
  ${media.max.medium`
    display: flex;
  `};
`;

const Content = styled.div`
  display: flex;
  width: 100%;

  ${media.max.large`
    flex-direction: column;
    overflow: hidden;
    ${({ height, open }) => `${height && !open && `height: ${height}px`}`};
  `};
`;

const HeaderContainer = styled.div`
  display: flex;
  /* Safary bug: when the flex parent doesn't have enough height the children's height shrinks and move inside the parent, min-height avoids the height shrinking */
  flex: 1 0 auto;
`;

const MobileHeader = styled.div`
  ${({ height }) => height && `height: ${height}px`};
  ${({ font }) => font && fontStyleOverride(font)};
  display: flex;
  flex: 1 0 0%;
  align-items: center;
  justify-content: center;

  ${media.min.large`
    display: none;
  `};
`;

const IconWrapper = styled.img`
  width: 16px;
  height: 16px;
`;

type Link = {
  text: string,
  onClick: any => void,
  availableOnMobile: boolean,
  active?: boolean,
  path: string,
};

type Props = {
  links: Link[],
  onBackButtonClick: () => void,
  config: Object,
  selected: Link,
  globalStyling: Object,
  direction: String,
};

type State = {
  open: boolean,
};

export const alignDetails = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const AccordionController = ({
  onButtonClick,
  chevronSize,
  arrowDownIcon,
  subNavChevronColour,
  open,
  visible,
}) => {
  const Chevron = open ? IoChevronUp : IoChevronDown;

  return (
    <AccordionButton
      size={chevronSize}
      onClick={onButtonClick}
      visible={visible}
    >
      {arrowDownIcon ? (
        <IconWrapper src={arrowDownIcon} alt="" />
      ) : (
        <Chevron color={subNavChevronColour} />
      )}
    </AccordionButton>
  );
};

const AvailableLinks = ({ links, config, selected, direction }: Props) => (
  <LinksContainer align={alignDetails[config.alignMenuItems]}>
    {links &&
      links.map((link, index) => (
        <SubNavLink
          key={index.toString()}
          onClick={link.onClick}
          itemBorder={config.showNavBarItemBorder}
          selectedItemBackgroundColour={config.selectedItemBackgroundColour}
          selected={selected && selected.path === link.path}
          direction={direction}
        >
          <HeadingTwo
            styleOverride={() => `
            cursor: pointer;
            width: 100%;
            ${fontStyleOverride(config.subNavFont)}
            ${selected === link &&
              config.selectedItemFontColour &&
              `color: ${config.selectedItemFontColour.value}`}
          `}
            mobileStyleOverride={() => [
              {
                queryPath: 'max.large',
                template: 'text-align: center;',
              },
            ]}
          >
            &#x200E;{link.text}&#x200E;
          </HeadingTwo>
        </SubNavLink>
      ))}
  </LinksContainer>
);

export default class DisplaySubNavBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleAccordion = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const {
      globalStyling,
      onBackButtonClick,
      links,
      config,
      selected,
    } = this.props;
    const mobileLinks = links && links.filter(f => f.path !== 'compare');
    const {
      chevronSize,
      showChevron,
      showNavBarBottomBorder,
      subNavBGColour,
      subNavHeight,
      translations,
      subNavChevronColour,
    } = config;

    const chevronColour =
      (subNavChevronColour && subNavChevronColour.value) ||
      (globalStyling.colours.primaryBrandColour &&
        globalStyling.colours.primaryBrandColour.value);

    return (
      <SubNavBarContainer
        backgroundColour={subNavBGColour}
        bottomBorder={showNavBarBottomBorder}
        height={subNavHeight}
      >
        <ContentWrapper contentWidth={globalStyling.contentWidth} flex>
          <Content open={this.state.open} height={subNavHeight}>
            <HeaderContainer>
              {showChevron && onBackButtonClick && (
                <Button size={chevronSize} onClick={onBackButtonClick}>
                  {globalStyling.direction === 'rtl' ? (
                    <IoChevronRight
                      size={`${chevronSize}px`}
                      color={chevronColour}
                    />
                  ) : (
                    <IoChevronLeft
                      size={`${chevronSize}px`}
                      color={chevronColour}
                    />
                  )}
                </Button>
              )}
              <MobileHeader height={subNavHeight} font={config.subNavFont}>
                {translations.pageTitle}
              </MobileHeader>
              <AccordionController
                onButtonClick={this.toggleAccordion}
                subNavChevronColour={chevronColour}
                chevronSize={chevronSize}
                arrowDownIcon={config.arrowDownIcon}
                open={this.state.open}
                visible={mobileLinks && mobileLinks.length > 0}
                globalStyling={globalStyling}
              />
            </HeaderContainer>
            <DesktopLinks>
              <AvailableLinks
                links={links}
                config={config}
                selected={selected}
                direction={globalStyling.direction}
              />
            </DesktopLinks>
            <MobileLinks>
              <AvailableLinks
                links={mobileLinks}
                config={config}
                selected={selected}
                direction={globalStyling.direction}
              />
            </MobileLinks>
          </Content>
        </ContentWrapper>
      </SubNavBarContainer>
    );
  }
}

import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v1';

const Icon = styled.img`
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
`;

const generateOption = (
  field,
  fields,
  translations,
  updatePodField,
  translationKey,
) => {
  return (
    <OptionWrapper>
      <InputWrapper>
        <input
          style={{ width: '300px' }}
          value={
            translations[`${translationKey}Value`] &&
            translations[`${translationKey}Value`]
          }
          onChange={e =>
            updatePodField('translations', {
              ...translations,
              [`${translationKey}Value`]: e.target.value,
              [`${translationKey}Label`]: e.target.value,
            })
          }
        />
      </InputWrapper>
      <Icon
        title="delete"
        src="https://res.cloudinary.com/motortrak/image/upload/v1570186934/editor/delete-icon.svg"
        onClick={() => {
          updatePodField(
            'fields',
            fields.map(m =>
              m === field
                ? {
                    ...m,
                    options: field.options.filter(o => o !== translationKey),
                  }
                : m,
            ),
          );
        }}
      />
    </OptionWrapper>
  );
};

const generateOptions = (
  field,
  fields,
  translations,
  updatePodField,
  radioInput,
  setRadioInput,
) => {
  return (
    <>
      <div>Options</div>
      {field &&
        field.options &&
        field.options.map(o =>
          generateOption(field, fields, translations, updatePodField, o),
        )}
      <OptionWrapper>
        <InputWrapper>
          <input
            style={{ width: '300px' }}
            value={radioInput}
            onChange={e => setRadioInput(e.target.value)}
          />
        </InputWrapper>
        <Icon
          title="okay"
          src="https://res.cloudinary.com/motortrak/image/upload/v1578671548/editor/okay-icon.svg"
          onClick={() => {
            const translationKey = uuid();
            updatePodField(
              'fields',
              fields.map(m =>
                m === field
                  ? {
                      ...m,
                      options: [...field.options, translationKey],
                    }
                  : m,
              ),
            );
            updatePodField('translations', {
              ...translations,
              [`${translationKey}Value`]: radioInput,
            });
            setRadioInput('');
          }}
        />
      </OptionWrapper>
    </>
  );
};

export const RadioEditor = ({
  field,
  fields,
  updatePodField,
  translations,
  radioInput,
  setRadioInput,
}) => (
  <>
    <InputWrapper>
      <div>Label</div>
      <input
        style={{ width: '300px' }}
        value={field.label}
        onChange={e =>
          updatePodField(
            'fields',
            fields.map(m =>
              m === field
                ? {
                    ...m,
                    label: e.target.value,
                  }
                : m,
            ),
          )
        }
      />
    </InputWrapper>
    {generateOptions(
      field,
      fields,
      translations,
      updatePodField,
      radioInput,
      setRadioInput,
    )}
  </>
);

/* eslint-disable quotes */
export default {
  translations: {
    searchResultsNextLabel: 'Next',
    searchResultsPreviousLabel: 'Previous',
    searchResultsShowAllLabel: 'Show All',
    compareLink: 'Compare',
    shortlistLink: 'shortlist',
    searchResultsAddToCompare: 'Add to compare',
    searchResultsSaveVehicle: 'Save Vehicle',
    searchResultsHeader: 'search results',
    searchHeaderLocationDistance: 'LOCATION & DISTANCE',
    searchHeaderPriceRange: 'PRICE RANGE',
    searchHeaderModelVariant: 'MODEL & VARIANT',
    searchHeaderEngineTransmission: 'ENGINE & TRANSMISSION',
    searchHeaderBodyStyle: 'BODY STYLE',
    searchHeaderAgeMileage: 'AGE & ODOMETER',
    searchHeaderColour: 'COLOUR',
    searchHeaderFeatures: 'FEATURES',
    searchSubHeaderLocation: 'Location',
    searchLocationInputPlaceholder: 'Postcode/Town/Place',
    searchDistanceNationwide: 'Nationwide',
    checkboxAnySelection: 'Any',
    searchCarFilterFromValue: 'From {VALUE}',
    searchCarFilterNumberAvailable: '{COUNT} available',
    searchSubHeaderDistance: 'Distance',
    searchSubHeaderPrice: 'Price',
    searchSubHeaderModel: 'Model',
    searchSubHeaderVariant: 'Variant',
    searchSubHeaderEngine: 'Engine',
    searchSubHeaderTransmission: 'Transmission',
    searchSubHeaderAge: 'Age',
    searchSubHeaderMileage: 'Odometer',
    searchSubHeaderExteriorColours: 'Exterior Colours',
    searchSubHeaderInteriorShades: 'Interior Colours',
    unitPrice: '€',
    unitAge: 'years',
    unitDistant: 'kilometres',
    searchResultsFilterLabel: 'Filter Results',
    searchResultsSortPriceAscLabel: 'Price Low to High',
    searchResultsSortPriceDescLabel: 'Price High to Low',
    searchResultsSortDistanceLabel: 'Distance - closest',
    searchResultTileMoreInfo: 'More Info',
    searchResultsAvailableLabel: 'AVAILABLE FOR SALE',
    searchResultsAvailableAtLabel: 'AVAILABLE FOR SALE IN',
    searchResultsPriceLabel: 'Price',
    searchResultsAprLabel: 'Effektiver Jahreszin',
    searchResultsMonthlyLabel: 'Monthly',
    searchResultsOdometerLabel: 'Odometer',
    searchResultsTransmissionLabel: 'Transmission',
    searchResultsFuelLabel: 'Fuel Type',
    searchResultsPowerLabel: 'Power',
    searchResultsEmissionsLabel: 'CO2',
    monthlyPayment: 'Monthly payment',
    finalPayment: 'Final payment',
    numberOfPayments: 'Number of payments',
    totalCost: 'Total Cost',
    deposit: 'Deposit',
    apr: 'APR',
    vehiclePrice: 'Vehicle Price',
    interestRate: 'Interest Rate',
    respresentativeExampleHeading:
      'Representative Hire Purchase Finance Example',
    representativeExampleBody:
      'Representative example for XE subject to 36 month, 60,000 mile agreement',
    representativeExampleTermsAndConditions:
      'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21',
  },
};

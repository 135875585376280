// @flow

import React from 'react';
import styled from 'styled-components';

import { type FieldProps } from '../models/fields';
import Number from './Number';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export default function Box({
  name,
  values: { id: value },
  ids: { id },
  onChange,
  ...props
}: FieldProps) {
  const onChangeHandler = change => onChange(id, { ...value, ...change });
  return (
    <Container>
      <div>
        <strong>{name}</strong>
      </div>
      <div>
        <Number
          name="Top"
          value={value.top}
          onChange={top => onChangeHandler(id, { top })}
          {...props}
        />
        <Number
          name="Right"
          value={value.right}
          onChange={right => onChangeHandler(id, { right })}
          {...props}
        />
        <Number
          name="Bottom"
          value={value.bottom}
          onChange={bottom => onChangeHandler(id, { bottom })}
          {...props}
        />
        <Number
          name="Left"
          value={value.left}
          onChange={left => onChangeHandler(id, { left })}
          {...props}
        />
      </div>
    </Container>
  );
}

/* eslint-disable import/prefer-default-export */
import { chunk } from './pdf-utils';
import { localiseCurrency } from '../localisation/numbers';

const styles = {
  tableTitle: {
    fontSize: 10,
    color: '#444444',
  },

  tableRegular: {
    fontSize: 10,
    color: '#141414',
  },

  tableBold: {
    fontSize: 10,
    bold: true,
    color: '#141414',
  },

  model: {
    fontSize: 18,
    bold: true,
  },
  modelDetails: {
    fontSize: 11,
    color: '#141414',
  },

  modelDetailsTitle: {
    fontSize: 9,
    color: '#999',
  },

  price: {
    fontSize: 15,
    bold: true,
  },

  subheader: {
    fontSize: 11,
    bold: true,
    color: '#141414',
  },
  footer: {
    fontSize: 8,
  },
};

export async function templatePos2(
  vehicle,
  pdfConfig,
  carImgs,
  formattedRegDate,
  brandLogo,
) {
  const {
    translations: {
      exterior: exteriorLabel,
      interior: interiorLabel,
      veneer: veneerLabel,
      vehiclePDFTransmission,
      vehiclePDFFuelType,
      vdpOtherEquipmentHeader,
      vehiclePDFImages,
      vehiclePDFMiles,
      vehiclePDFPower,
      vehiclePDFRegistration,
      vehiclePDFDrive,
      vehiclePDFDesign,
      vdpPdfColour,
      vdpRetailerEmailLabel,
      vdpRetailerPhoneLabel,
      zeroPriceText,
      price: vehiclePDFPrice,
    },
  } = pdfConfig;

  const {
    modelGroupName,
    modelVariant,
    bespokeFeatures,
    otherEquipment,
    locale,
    retailerInformation: {
      address,
      email = '',
      phone = '',
      name: retailerName,
    },
    price,
    registration,
    specification: {
      fuelType,
      odometer: { display: odometerDisplay },
      exterior,
      interior,
      capacity,
      veneer,
    },
    handDrive,
    transmissionType,
  } = vehicle;

  const modelAndVariant = `${modelGroupName} ${modelVariant}`;

  const modelNameToDisplay = modelVariant
    ? modelAndVariant.toUpperCase()
    : modelGroupName.toUpperCase();
  const carImages = carImgs.map(carImg => ({
    image: carImg,
    fit: [120, 120],
    border: [false],
  }));

  const emailText = email ? `${email}` : '';
  const emailLabel = email ? `${vdpRetailerEmailLabel}: ` : '';

  const features = chunk(
    bespokeFeatures.map(feature => ({
      text: feature.name,
      margin: [5, 0],
    })),
    3,
  );

  const equipment = chunk(
    otherEquipment[0].equipment.map(equipmentTitle => ({
      text: equipmentTitle,
      margin: [5, 0],
    })),
    3,
  );

  const vehicleSpecHeaderRow = [
    formattedRegDate && {
      text: vehiclePDFRegistration,
      border: [false, false, true, false],
      margin: [0, 0, 5, 5],
      style: styles.modelDetailsTitle,
    },
    odometerDisplay && {
      text: vehiclePDFMiles,
      border: [false, false, true, false],
      margin: [5, 0, 5, 5],
      style: styles.modelDetailsTitle,
    },
    transmissionType && {
      text: vehiclePDFTransmission,
      border: [false, false, true, false],
      margin: [5, 0, 5, 5],
      style: styles.modelDetailsTitle,
    },
    fuelType && {
      text: vehiclePDFFuelType,
      border: [false, false, true, false],
      margin: [5, 0, 5, 5],
      style: styles.modelDetailsTitle,
    },
    capacity && {
      text: vehiclePDFPower,
      border: [false, false, true, false],
      margin: [5, 0, 5, 5],
      style: styles.modelDetailsTitle,
    },
    handDrive && {
      text: vehiclePDFDrive,
      border: [false],
      margin: [5, 0, 5, 5],
      style: styles.modelDetailsTitle,
    },
  ].filter(Boolean);

  const vehicleSpecInfoRow = [
    registration && {
      text: registration,
      border: [false, false, true, false],
      margin: [0, -6, 5, 3],
      style: styles.modelDetails,
    },
    odometerDisplay && {
      text: odometerDisplay,
      border: [false, false, true, false],
      margin: [5, -6, 5, 3],
      style: styles.modelDetails,
    },
    transmissionType && {
      text: transmissionType,
      border: [false, false, true, false],
      margin: [5, -6, 5, 3],
      style: styles.modelDetails,
    },
    fuelType && {
      text: fuelType,
      border: [false, false, true, false],
      margin: [5, -6, 5, 3],
      style: styles.modelDetails,
    },
    capacity && {
      text: capacity,
      border: [false, false, true, false],
      margin: [5, -6, 5, 3],
      style: styles.modelDetails,
    },
    handDrive && {
      text: handDrive,
      border: [false],
      margin: [5, -6, 5, 3],
      style: styles.modelDetails,
    },
  ];

  const preventMalformedData = data => {
    const lastRow = data[data.length - 1];
    if (lastRow.length !== 3) {
      while (lastRow.length !== 3) {
        lastRow.push({
          text: '',
          margin: [5, 0],
        });
      }
    }
    return data;
  };
  const renderRows = data => [
    // Equipment Title Row Start
    {
      colSpan: 2,
      style: styles.tableRegular,
      table: {
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: data,
      },
      layout: {
        // code from lightHorizontalLines:
        hLineWidth(i, node) {
          if (i === 0) {
            return 0;
          }
          return i === node.table.headerRows && node.table.body.length > 0
            ? 2
            : 1;
        },
        vLineWidth() {
          return 0;
        },
        hLineColor() {
          return '#ddd';
        },
        paddingLeft(i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight(i, node) {
          return i === node.table.widths.length - 1 ? 0 : 8;
        },
      },
    },
  ]; // Equipment Title Row End

  const chunkedCarImages = chunk(carImages, 2);

  const renderImageRows = data => [
    // Thumbnails Row Start
    {
      colSpan: 2,
      style: 'price',
      margin: [0, 20, 0, 40],
      table: {
        widths: ['25%', '25%', '25%', '25%'],
        body: [data],
      },
      layout: {
        vLineWidth() {
          return 0;
        },
        hLineWidth() {
          return 0;
        },
      },
    },
  ]; // Thumbnails Row End
  return [
    // Content Start
    {
      // Content Body Start

      layout: 'noBorders',
      table: {
        // Main Table Start

        body: [
          // Main Table Body Start
          [
            // Top Row Start
            {
              // Logo Section Start
              image: brandLogo,
              fit: ['*', 70],
              margin: [0, 0, 0, 20],
            }, // Logo Section Start

            {
              // Address Section Start
              style: styles.tableRegular,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: retailerName,
                      border: [false],
                      alignment: 'right',
                    },
                  ],
                  [
                    {
                      text: address[0],
                      border: [false],
                      alignment: 'right',
                    },
                  ],
                  [
                    {
                      text: `${address[1]}, ${address[2]}`,
                      border: [false],
                      alignment: 'right',
                      margin: [0, 0, 0, 2],
                    },
                  ],
                  [
                    {
                      text: [
                        {
                          text: emailLabel,
                          bold: true,
                        },
                        emailText,
                      ],
                      border: [false],
                      alignment: 'right',
                      margin: [0, 0, 0, 2],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return '#ddd';
                },
              },
            }, // Address Section End
          ], // Top Row End
          [
            // Model Row Start
            {
              colSpan: 2,
              style: styles.model,

              table: {
                widths: ['*', 150],
                body: [
                  [
                    {
                      text: modelNameToDisplay,
                      border: [false, true, false, false],
                      margin: [0, 12, 0, 0],
                    },
                    {
                      text: `${vdpRetailerPhoneLabel}: ${phone}`,
                      border: [false, true, false, false],
                      margin: [0, 12, 0, 8],
                      alignment: 'right',
                      style: styles.subheader,
                    },
                  ],
                  [
                    {
                      colSpan: 2,
                      border: [false, false, false, false],
                      table: {
                        body: [vehicleSpecHeaderRow, vehicleSpecInfoRow],
                      },
                      layout: {
                        vLineWidth() {
                          return 1;
                        },
                        vLineColor() {
                          return '#ddd';
                        },
                      },
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return '#00AAEE';
                },
              },
            },
          ], // Model Row End

          [
            // Image Row Start
            {
              colSpan: 2,
              style: styles.price,
              margin: [0, 10, 0, 0],
              table: {
                widths: ['*', 200],
                body: [
                  [
                    {
                      margin: [10, 0, 0, 0],
                      image: `${carImgs[0]}`,
                      fit: ['*', 170],
                      border: [false],
                      alignment: 'left',
                    },
                    {
                      style: styles.price,
                      margin: [10, 0, 0, 0],
                      table: {
                        widths: ['*'],
                        body: [
                          [
                            {
                              text:
                                price.value !== 0
                                  ? localiseCurrency(
                                      price.value,
                                      locale,
                                      price.currency,
                                    )
                                  : zeroPriceText,
                              border: [false],
                            },
                          ],
                          [
                            {
                              text: vehiclePDFPrice,
                              border: [false],
                              style: styles.subheader,
                              margin: [0, 0, 0, 5],
                              color: '#999',
                            },
                          ],
                        ],
                      },
                      layout: {
                        hLineWidth() {
                          return 1;
                        },
                        hLineColor() {
                          return '#ddd';
                        },
                      },
                    },
                  ],
                ],
              },
              layout: 'noBorders',
            },
          ], // Image Row End

          [
            // Specs Title Row Start
            {
              colSpan: 2,
              style: styles.subheader,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: vdpPdfColour,
                      border: [false, false, false, true],
                      margin: [0, 20, 0, 6],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return '#00AAEE';
                },
              },
            },
          ], // Specs Title Row End

          [
            // Design Row Start
            {
              colSpan: 2,
              style: styles.tableRegular,

              columns: [
                {
                  margin: [0, 0, 5, 0],
                  table: {
                    widths: ['*', '*'],
                    body: [
                      [
                        {
                          text: exteriorLabel,
                          margin: [0, 3],
                          border: [false, false, false, true],
                        },
                        {
                          text: exterior || '',
                          style: styles.tableBold,
                          margin: [0, 3],
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: interiorLabel,
                          margin: [0, 3],
                          border: [false, false, false, true],
                        },
                        {
                          text: interior || '',
                          style: styles.tableBold,
                          margin: [0, 3],
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: veneerLabel,
                          margin: [0, 3],
                          border: [false, false, false, true],
                        },
                        {
                          text: veneer || '',
                          style: styles.tableBold,
                          margin: [0, 3],
                          border: [false, false, false, true],
                        },
                      ],
                    ],
                  },
                  layout: {
                    hLineWidth() {
                      return 1;
                    },
                    hLineColor() {
                      return '#ddd';
                    },
                  },
                },
              ],
            },
          ], // Specs Table Row End

          [
            // Equipment Title Row Start
            {
              colSpan: 2,
              style: styles.subheader,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: vehiclePDFDesign,
                      border: [false, false, false, true],
                      margin: [0, 20, 0, 6],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return '#00AAEE';
                },
              },
            },
          ],

          renderRows(preventMalformedData(features)),
          [
            // Equipment Title Row Start
            {
              colSpan: 2,
              style: styles.subheader,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: vdpOtherEquipmentHeader,
                      border: [false, false, false, true],
                      margin: [0, 20, 0, 6],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return '#00AAEE';
                },
              },
            },
          ], // Equipment Title Row End
          renderRows(preventMalformedData(equipment)), // Equipment Table Row End
          [
            // Thumbnails Title Row Start
            {
              colSpan: 2,
              style: styles.subheader,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: vehiclePDFImages,
                      border: [false, false, false, true],
                      margin: [0, 20, 0, 6],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return '#00AAEE';
                },
              },
            },
          ], // Thumbnails Title Row Begin
          renderImageRows(chunkedCarImages),
          // Thumbnails Row End
          [
            // Spacer Start
            {
              colSpan: 2,
              margin: [0, 15, 0, 0],
              text: '',
            },
          ], // Spacer End
        ], // Main Table Body End
      }, // Main Table End
    }, // Content Body End
  ]; // Content End
}

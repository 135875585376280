// @flow
import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Handle from './Handle';

type Props = {
  onAfterChange?: (value: number) => void,
  onChange?: (value: number) => void,
  rangeValue?: {
    min: number,
    max: number,
  },
  step?: number,
  defaultValue?: number,
  marks?: any,
  sliderColor: string,
};

export default function StyledSlider({
  step,
  defaultValue,
  onAfterChange,
  onChange,
  rangeValue,
  marks,
  sliderColor,
}: Props) {
  const marksOrStep = Object.keys(marks).length ? null : step;
  return (
    <Slider
      handle={Handle}
      trackStyle={[{ backgroundColor: sliderColor }]}
      railStyle={{ backgroundColor: '#F0F0F0' }}
      marks={marks}
      min={rangeValue.min}
      max={rangeValue.max}
      step={marksOrStep}
      defaultValue={defaultValue}
      allowCross={false}
      dotStyle={{ borderColor: '#F0F0F0' }}
      activeDotStyle={{ borderColor: sliderColor }}
      onAfterChange={onAfterChange}
      onChange={onChange}
    />
  );
}

StyledSlider.defaultProps = {
  step: 1,
  marks: {},
  defaultValue: 0,
  rangeValue: { min: 0, max: 100 },
  onAfterChange: () => {},
  onChange: () => {},
};

// @flow
import React from 'react';
import styled from 'styled-components';
import IoIosMore from 'react-icons/lib/io/ios-more';
import { Button, Dropdown } from 'cms-ui';
import { useSelector, useDispatch } from 'react-redux';
import ScreenSizePicker from './ScreenSizePicker';
import { actions } from '../state/actions/blogs';

type Props = {
  brandName: string,
  moduleName: string,
  onScreenSizeChange: string => void,
  selectedScreenSize: string,
  saveStatus: 'unsaved' | 'saving' | 'saved',
  blogStatus: 'saving' | 'unsaved',
  onSaveChanges: Function,
  onPublishClick: () => void,
  onMenuClick: Function, // TODO,
  showScreenSizeSelector: boolean,
  pages: Page[],
  setPageContext: string => void,
  currentPage: string,
  onHomeMenuClick: () => void,
};

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  background: ${({ theme }) => theme.colours.primary03};
  padding: 6px;
  display: flex;
  justify-content: space-between;
  height: 52px;
`;

const Left = styled.div`
  display: flex;
  flex: 1 0 0%;
`;

const Middle = styled.div`
  width: 150px;
  display: flex;

  * {
    flex: 1 0 0%;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1 0 0%;
  justify-content: flex-end;
`;

const Heading = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colours.disabled01};
  margin-left: ${({ theme }) => theme.layout.halfPadding};
  margin-right: ${({ theme }) => theme.layout.halfPadding};
  font-size: ${({ theme }) => theme.fontSizeSubHeader};
  font-weight: 600;
`;

const Brand = styled.span`
  font-size: ${({ theme }) => theme.fontSizeTiny};
  text-transform: uppercase;
`;

const Icon = styled.img`
  margin-right: ${({ theme }) => `${theme.layout.doublePadding}`};
  cursor: pointer;
`;

const DropDownWrapper = styled.span`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 175px;
`;

const ReducedHeightButton = styled(Button)`
  height: 40px;
  margin-left: 10px;
  white-space: nowrap;
`;

const UnsavedChanges = styled(props => (
  <div {...props}>You have unsaved changes</div>
))`
  height: 40px;
  width: 125px;
  color: ${({ theme }) => theme.colours.disabled01};
`;

const UnsavedBlog = styled(props => <div {...props}>Unsaved changes</div>)`
  height: 40px;
  width: 125px;
  color: ${({ theme }) => theme.colours.disabled01};
`;

function SaveChangesButton({
  saveStatus,
  onSaveChanges,
  savingText,
  saveText,
}: {
  saveStatus: 'saving' | 'saved' | 'unsaved',
  onSaveChanges: () => void,
  saveText: string,
  savingText: string,
}) {
  const saving = saveStatus === 'saving';

  return (
    <>
      {saveStatus === 'unsaved' && <UnsavedChanges />}
      <ReducedHeightButton disabled={saving} onClick={onSaveChanges}>
        {saving ? savingText : saveText}
      </ReducedHeightButton>
    </>
  );
}

function SaveBlogButton({
  blogStatus,
  onSaveChanges,
}: {
  blogStatus: 'saving' | 'unsaved',
  onSaveChanges: () => void,
  saveText: string,
  savingText: string,
}) {
  const saving = blogStatus === 'saving';

  return (
    <>
      {blogStatus === 'unsaved' && <UnsavedBlog />}
      <ReducedHeightButton disabled={saving} onClick={onSaveChanges}>
        {saving ? 'Saving Post' : 'Save Post'}
      </ReducedHeightButton>
    </>
  );
}

const blockTabClose = e => {
  e.preventDefault();
  e.returnValue =
    'You may have unsaved changes, Are you sure you want to leave?';
  return '';
};

const MenuButton = styled(ReducedHeightButton).withConfig({
  type: 'MenuButton',
})`
  color: ${({ theme }) => theme.colours.disabled01};
  margin-left: ${({ theme }) => theme.layout.halfPadding};
  text-decoration: none;
  width: auto;
`;

const Ellipsis = styled(IoIosMore)`
  font-size: 40px;
  margin: -10px 0;
`;

export default function EditorHeader({
  brandName,
  onScreenSizeChange,
  onSaveChanges,
  onPublishClick,
  onMenuClick,
  saveStatus,
  blogStatus,
  selectedScreenSize,
  showScreenSizeSelector,
  pages,
  setPageContext,
  currentPage,
  onHomeMenuClick,
}: Props) {
  const breakout = useSelector(state => state.context.breakout);
  const isLoggingOut = useSelector(state => state.editor.isLoggingOut);

  const editedBlogId =
    breakout && breakout.name === 'EditPost' ? breakout.data.id : null;
  const editingBlog = !!editedBlogId;
  const editedBlog = useSelector(state =>
    state.blogs.blogs.find(b => b.id === editedBlogId),
  );
  const dispatch = useDispatch();
  const showPageDropdown = !editingBlog && pages && !!pages.length;
  if (saveStatus === 'unsaved' && !isLoggingOut) {
    window.addEventListener('beforeunload', blockTabClose);
  } else {
    window.removeEventListener('beforeunload', blockTabClose);
  }

  return (
    <Container>
      <Left>
        <Heading>
          <span>CMS</span>
          <Brand>{brandName}</Brand>
        </Heading>
        <Icon
          src="https://res.cloudinary.com/motortrak/image/upload/v1564992732/editor/links-icon.svg"
          onClick={onHomeMenuClick}
        />
        {showPageDropdown && (
          <DropDownWrapper>
            <Dropdown
              input={{
                value: currentPage && currentPage.id,
                onChange: option => setPageContext(option.value, option.label),
              }}
              options={pages.map(p => ({
                label: p.pageName,
                value: p.id,
              }))}
              size={25}
            />
          </DropDownWrapper>
        )}
      </Left>
      <Middle>
        {showScreenSizeSelector && (
          <ScreenSizePicker
            onChange={onScreenSizeChange}
            selected={selectedScreenSize}
          />
        )}
      </Middle>
      {editingBlog ? (
        <Right>
          <SaveBlogButton
            onSaveChanges={() => dispatch(actions.saveBlog(editedBlog))}
            blogStatus={blogStatus}
          />
        </Right>
      ) : (
        <Right>
          <SaveChangesButton
            onSaveChanges={onSaveChanges}
            saveStatus={saveStatus}
            saveText="Save Changes"
            savingText="Saving Changes"
          />
          <ReducedHeightButton primary onClick={onPublishClick}>
            Publish
          </ReducedHeightButton>
          <MenuButton link onClick={onMenuClick}>
            <Ellipsis />
          </MenuButton>
        </Right>
      )}
    </Container>
  );
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Chunky({
  background,
  colour,
  width,
  height,
  direction,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
      direction={direction}
    >
      <svg width="1em" height="1em" viewBox="0 0 25 23">
        <path d="M9.8 20.4L7 17.7l6.5-6.5L7 4.7 9.8 2l9.2 9.2z" />
      </svg>
    </IconWrapper>
  );
}

Chunky.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import { connect } from 'react-redux';

import TemplateOptions from '../components/TemplateOptions';

import { actions as editorActions } from '../state/actions/editor';

const mapStateToProps = state => ({
  branding: state.editor.branding,
  logos: state.editor.logos,
});

const mapDispatchToProps = dispatch => ({
  loadBranding: () => dispatch(editorActions.loadBranding()),
  addBrandColour: () => dispatch(editorActions.addBrandColour()),
  removeBrandColour: hex => dispatch(editorActions.removeBrandColour(hex)),
  saveBrandColour: hex => dispatch(editorActions.saveBrandColour(hex)),
  selectBrandColour: (key, value) =>
    dispatch(editorActions.selectBrandColour(key, value)),
  addBrandFont: font => dispatch(editorActions.addBrandFont(font)),
  selectBrandFont: (key, font) =>
    dispatch(editorActions.selectBrandFont(key, font)),
  removeBrandFont: fontName =>
    dispatch(editorActions.removeBrandFont(fontName)),
  addBrandLogo: (image, forKey) =>
    dispatch(editorActions.parseBrandLogo(image, forKey)),
  removeBrandLogo: forKey => dispatch(editorActions.removeBrandLogo(forKey)),
  saveChanges: (branding, logos) =>
    dispatch(editorActions.saveBranding(branding, logos)),
  cancelChanges: () => dispatch(editorActions.loadBranding()),
  updateUiElement: (element, key, value) =>
    dispatch(editorActions.updateUiElement(element, key, value)),
  updateButtonStyle: (element, value) =>
    dispatch(editorActions.updateButtonStyle(element, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateOptions);

// @flow
import React, { type Node, Fragment } from 'react';
import styled from 'styled-components';

import media from '../../../theme';
import JaguarArrow from '../../../assets/JaguarArrow';
import KiaArrow from '../../../assets/KiaArrow';

const ButtonStyles = {
  ButtonStyle1: () => '',
  ButtonStyle2: () => '',
  ButtonStyle3: () => `
    border-radius: 15px;
    justify-content: space-between;
    height: 30px;
    padding: 0;

    span {
      margin: 0 5px 1px 15px;
      width: 100%;
    }
  `,
  ButtonStyle4: () => '',
  ButtonStyleBTT: () => '',
  ButtonStyle5: () => `
    border-radius: 2px;
    opacity: 0.8;

    &:hover {
    opacity: 1;
  }
  `,
  ButtonStyle6: () => `
    transition: background-color 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    border: none;
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 8px 16px;
    span {
      transition: all 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
      position: relative;
      left: 0;
      line-height: 16px;
    }
    &:hover span {
      left: -10px;
    }
    &:hover:after {
      right: 10px;
    }
    &:after {
      content: '';
      right: -30px;
      transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      position: absolute;
      line-height: 16px;
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      background-image: url('https://res.cloudinary.com/motortrak/image/upload/v1553264076/cms/lamborghini/content/dropdown_copy_3.png');
    }
  `,
};

const StyledButton = styled.button.attrs(props => ({
  className: `global ${props.applyStyle} ${props.disabled ? 'disabled' : ''}`,
}))`
  &&& {
    ${media.max.medium`
      margin: 0;
      height: ${({ buttonStyle }) =>
        buttonStyle === 'ButtonStyle3' ? 'unset' : '41px'};
      width: 100%;
      ${({ mobileStyleOverride }) =>
        mobileStyleOverride && mobileStyleOverride()};
    `};
    ${({ buttonStyle }) => buttonStyle && ButtonStyles[buttonStyle]()};
    ${({ styleOverride }) => styleOverride && styleOverride()};
  }
`;

const ButtonText = styled.span`
  margin: 0 10px;
`;

const ButtonTextStyleFour = styled.span`
  margin: 0 5px;
`;

const ButtonStyleContent = {
  ButtonStyle1: (text: string) => (
    <Fragment>
      <ButtonText>{text}</ButtonText>
      <div />
    </Fragment>
  ),
  ButtonStyle2: (text: string) => (
    <Fragment>
      <ButtonText>{text}</ButtonText>
      <JaguarArrow width="1.2em" height="1.2em" />
    </Fragment>
  ),
  ButtonStyle3: (text: string) => (
    <Fragment>
      <ButtonText>{text}</ButtonText>
      <KiaArrow width="1.7em" height="1.7em" />
    </Fragment>
  ),
  ButtonStyle4: (text: string) => (
    <Fragment>
      <JaguarArrow width="10px" height="10px" />
      <ButtonTextStyleFour>{text}</ButtonTextStyleFour>
    </Fragment>
  ),
  ButtonStyleBTT: (text: Node) => text,
  ButtonStyle5: (text: string) => (
    <Fragment>
      <ButtonText>{text}</ButtonText>
    </Fragment>
  ),
  ButtonStyle6: (text: string) => (
    <Fragment>
      <ButtonText>{text}</ButtonText>
    </Fragment>
  ),
};

type Props = {
  buttonStyle: string,
  text: string | Node,
  styleOverride?: Function,
  mobileStyleOverride?: Function,
  applyStyle: string,
  onClick: Function,
  children: Node,
};

export default function Button({
  buttonStyle,
  text,
  styleOverride,
  mobileStyleOverride,
  applyStyle,
  onClick,
  ...props
}: Props) {
  return (
    <StyledButton
      {...props}
      buttonStyle={buttonStyle}
      styleOverride={styleOverride}
      mobileStyleOverride={mobileStyleOverride}
      applyStyle={applyStyle}
      onClick={onClick}
    >
      {ButtonStyleContent[buttonStyle](text)}
    </StyledButton>
  );
}

Button.defaultProps = {
  styleOverride: null,
  mobileStyleOverride: null,
  buttonStyle: 'ButtonStyle2',
  text: '',
  applyStyle: 'primary',
};

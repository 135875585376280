// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

type Props = {
  children: Node,
};

const StyledSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
  border-top: 1px solid #d8d8d8;
`;

export default function Section(props: Props) {
  return <StyledSection>{props.children}</StyledSection>;
}

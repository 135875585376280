// @flow

import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div.withConfig({
  displayName: 'Backdrop',
})`
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f0f0;
  ${({ order }) => order && `order: ${order}`};
`;

const Inset = styled.div.withConfig({
  displayName: 'Inset',
})`
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
`;

type Props = {
  children: any,
  order: Number,
};

function Well({ children, order }: Props) {
  return (
    <Backdrop order={order}>
      <Inset data-qa-hook="vehicle-information">{children}</Inset>
    </Backdrop>
  );
}

export default Well;

import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getInventoryStatus,
  getGlobalFinance,
  getFinanceStatus,
} from '../selectors/settings';
import {
  getFinanceHeaderDisplayList,
  getFinanceDisplayList,
  reorderFinanceDisplayList,
} from './data';
import { openPDF, myDeal } from '../pdf';
import { translateFinanceTemplate } from '../../containers/FinanceContainer';
import { actions as errorActions } from '../errors';

export function* printMyDeal(payload) {
  const {
    vehicle: { id, vin },
    finance,
  } = payload;
  const { make, market, locale } = yield select(getAVLConfig);
  const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
  const vehicleImgUrl = `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${id ||
    vin}/images`;
  const globalFinance = yield select(
    state => state.config.config.global.finance,
  );
  let financeDisplayList = reorderFinanceDisplayList(
    getFinanceDisplayList(finance, { ...globalFinance, locale }),
  );
  const disclaimer = globalFinance.showExampleDisclaimer && {
    label: globalFinance.translations.exampleDisclaimer,
    value: '',
  };

  financeDisplayList = [...financeDisplayList, disclaimer].filter(Boolean);

  const financeHeaderDisplayList = getFinanceHeaderDisplayList(finance, {
    ...globalFinance,
    locale,
  });

  const document = yield call(myDeal, {
    ...payload,
    finance: {
      ...payload.finance,
      hideValidUntil: globalFinance.hideValidUntil,
    },
    vehicleImgUrl,
    financeDisplayList,
    financeHeaderDisplayList,
    financeTermsAndConditions:
      globalFinance.translations.financeTermsAndConditions,
  });
  yield call(
    openPDF,
    document,
    translateFinanceTemplate(globalFinance.translations)('financeMyDealTitle', {
      PRODUCT:
        globalFinance.translations[`finance${finance.financingType}Label`],
    }),
  );
}

function* loadRepresentativeExample() {
  const financeStatus = yield select(getFinanceStatus);

  if (financeStatus) {
    try {
      const { make, market, locale } = yield select(getAVLConfig);
      const withLocaleQueryString = yield select(appendLocaleQueryString);
      const inventoryStatus = yield select(getInventoryStatus);
      const globalFinance = yield select(getGlobalFinance);

      const url = `${yield select(
        inventorySearchServiceUrl,
      )}make/${make}/market/${market}/finance/example/?status=${inventoryStatus}&FinanceProduct=${
        globalFinance.defaultProduct
      }`;

      const response = yield call(get, {
        url,
        ...(withLocaleQueryString && {
          config: localeConfigSerializer(locale),
        }),
      });

      yield put(
        actions.loadRepresentativeExampleSuccess({
          ...response.data.deal,
          model: response.data.model,
        }),
      );
    } catch (error) {
      // no-op
    }
  }
}
// This function sends a request to the financeCalculator to return
// the range of values for downpayments and duration available within
// a market for each finance product that is in use in that market.
// eg response is
// {
//   contents: [
//     {
//       financeType: 'RS', --> this will be one of RS, PC or LE
//       downpaymentRange: {
//         min: 0,
//         max: 60000,
//       },
//       durationOptions: [12, 24, 36, 48],
//     }
//   ]
// }

function* loadMarketFinanceOptions({ payload: id }) {
  try {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const globalFinance = yield select(getGlobalFinance);
    const queryParams = globalFinance.productTypes
      .map(pt => `FinanceProducts=${pt}`)
      .join('&');
    const url = `${yield select(
      inventorySearchServiceUrl,
    )}make/${make}/market/${market}/${id}/finance/setup?${queryParams}`;
    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });
    yield put(
      actions.loadFinanceOptionsSuccess({ data: response.data.contents }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export function* getFinanceProduct(
  id,
  duration,
  downpayment,
  additionalServices,
  type,
) {
  const { make, market, locale } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const url = `${yield select(
    inventorySearchServiceUrl,
  )}make/${make}/market/${market}/${id}/finance/deal`;

  const { paramsSerializer, params } = localeConfigSerializer(locale);

  const response = yield call(get, {
    url,
    config: {
      paramsSerializer,
      params: {
        financeProduct: type.toUpperCase(),
        duration,
        downpayment,
        ...(additionalServices &&
          additionalServices.length && {
            additionalServices,
          }),
        ...(withLocaleQueryString && params),
      },
    },
  });

  return response.data;
}

function* loadFinanceProduct({
  payload: { id, duration, downpayment, additionalServices, type },
}) {
  try {
    const finance = yield getFinanceProduct(
      id,
      duration,
      downpayment,
      additionalServices,
      type,
    );
    yield put(actions.loadFinanceProductSuccess({ data: finance, type }));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export function* mapVehicleAndFinance(vehicle, financeProduct) {
  let vehicleWithFinance = {
    ...vehicle,
    financeLoaded: true,
  };

  const { make, market, locale } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const url = `${yield select(
    inventorySearchServiceUrl,
  )}make/${make}/market/${market}/${vehicle.id}/finance/example`;
  const { paramsSerializer, params } = localeConfigSerializer(locale);

  const response = yield call(get, {
    url,
    config: {
      paramsSerializer,
      params: {
        financeProduct: financeProduct.toUpperCase(),
        ...(withLocaleQueryString && params),
      },
    },
  });

  if (response.data && response.data.deal) {
    const finance = {
      ...response.data.deal,
      mustRefreshAfter: Date.now() + 15000, // expire in 15 mins
    };
    vehicleWithFinance = {
      ...vehicle,
      finance,
      financeLoaded: true,
    };
  }

  return vehicleWithFinance;
}

export function* mapGroupAndFinance(group) {
  let groupWithFinance = {
    ...group,
    financeLoaded: true,
  };

  if (group.financeVehicleId) {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const globalFinance = yield select(getGlobalFinance);
    const url = `${yield select(
      inventorySearchServiceUrl,
    )}make/${make}/market/${market}/${group.financeVehicleId}/finance/example`;
    const { paramsSerializer, params } = localeConfigSerializer(locale);

    const response = yield call(get, {
      url,
      config: {
        paramsSerializer,
        params: {
          financeProduct: globalFinance.defaultProduct.toUpperCase(),
          ...(withLocaleQueryString && params),
        },
      },
    });

    if (response.data && response.data.deal) {
      const finance = response.data.deal;
      groupWithFinance = {
        ...group,
        financePrice: finance.monthlyPrice,
        apr: finance.apr,
        finance,
        financeLoaded: true,
      };
    }
  }

  return groupWithFinance;
}

export default function* effects() {
  yield takeEvery(
    constants.LOAD_REPRESENTATIVE_EXAMPLE,
    loadRepresentativeExample,
  );
  yield takeEvery(constants.LOAD_FINANCE_OPTIONS, loadMarketFinanceOptions);
  yield takeLatest(constants.LOAD_FINANCE_PRODUCT, loadFinanceProduct);
}

import {
  Config,
  Slice,
  Section,
  Align,
  AlignLeftRight,
  Colour,
  Page,
  Font,
  FontWithAlignment,
  Pixels,
  Items,
  Toggle,
  EditTextWithToggle,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'Header',
  { id: 'header' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      'Utility Bar',
      { icon: SectionIcons.TopTier },
      Pixels('Height', 'utilityBarHeight'),
      Align('Align', 'utilityBarAlign'),
    ),
    Section(
      'Actions Bar',
      { icon: SectionIcons.MidTier },
      Toggle('Show', 'showActionsBar'),
      Pixels('Height', 'actionsBarHeight'),
    ),
    Section(
      'Menu Bar',
      { icon: SectionIcons.BottomTier },
      Pixels('Height', 'menuBarHeight'),
    ),
    Section(
      'Search Bar',
      { icon: SectionIcons.LetterS },
      AlignLeftRight('Align', 'searchBarAlign'),
      AlignLeftRight('Search icon align', 'searchIconAlign'),
    ),
    Section(
      'Brand Logo',
      { icon: SectionIcons.LetterB },
      AlignLeftRight('Align', 'logoAlign'),
    ),
    Section(
      'Global',
      {},
      Toggle('Sticky Header?', 'stickyHeader'),
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Utility Bar',
      { icon: SectionIcons.TopTier },
      Toggle('Language', 'utilityBarLanguageEnabled'),
      Toggle('Currency', 'utilityBarCurrencyEnabled'),
      Items('PagesAndLinks', 'utilityBarItems'),
    ),
    Section(
      'Actions Bar',
      { icon: SectionIcons.MidTier },
      EditTextWithToggle('Page Title', 'actionsBarPageTitle'),
      EditTextWithToggle('Account', 'actionsBarsAccountTitle'),
      EditTextWithToggle('Social Share', 'socialShareTitle'),
    ),
    Section(
      'Menu Bar',
      { icon: SectionIcons.BottomTier },
      Items('PagesAndLinks', 'menuBarItems'),
    ),
    Section(
      'Search bar',
      { icon: SectionIcons.LetterS },
      Toggle('Show search bar', 'showSearchBar'),
    ),
    Section(
      'Brand Logo',
      { icon: SectionIcons.LetterB },
      Page('Page link', 'brandLogoLink'),
      Toggle('Visible on desktop', 'brandLogoVisibleDesktop'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Utility Bar',
      { icon: SectionIcons.TopTier },
      Colour('Background', 'utilityBarBGColour'),
      Font('Link', 'utilityBarLinkFont'),
      Colour('Link Hover', 'utilityBarLinkHoverColour'),
      Colour('Dropdown Hover Colour', 'utilityBarDropdownHoverColour'),
      Colour('Dropdown Menu', 'utilityBarDropdownColour'),
      Font('Dropdown Link', 'utilityBarDropdownFont'),
      Pixels('Bottom Border Height', 'utilityBarBorderBottomHeight', {
        max: 10,
      }),
      Colour('Bottom Border Colour', 'utilityBarBorderBottomColour'),
    ),
    Section(
      'Actions Bar',
      { icon: SectionIcons.MidTier },
      Colour('Background', 'actionsBarBGColour'),
      Font('Page title', 'actionsBarPageFont'),
      Font('Account', 'actionsBarAccountFont'),
      Pixels('Bottom Border Height', 'actionsBarBorderBottomHeight', {
        max: 10,
      }),
      Colour('Bottom Border Colour', 'actionsBarBorderBottomColour'),
    ),
    Section(
      'Menu Bar',
      { icon: SectionIcons.BottomTier },
      Colour('Background', 'menuBarBGColour'),
      Colour('Border Colour', 'menuBarBorderColour'),
      FontWithAlignment('Link', 'menuBarLinkFont'),
      Colour('Link Hover', 'menuBarLinkHoverColour'),
      Colour('Link Background Hover', 'menuBarLinkBgHoverColour'),
      Pixels('Bottom Border Height', 'menuBarBorderBottomHeight', {
        max: 10,
      }),
      Colour('Bottom Border Colour', 'menuBarBorderBottomColour'),
    ),
    Section(
      'Search Bar',
      { icon: SectionIcons.LetterS },
      Colour('Background', 'searchBGColour'),
      Colour('Icon', 'searchIconColour'),
      Font('Search text', 'searchTextFont'),
      Toggle('Show search input border', 'showSearchInputBorder'),
    ),
    Section(
      'Brand Logo',
      { icon: SectionIcons.LetterB },
      Colour('Background', 'brandLogoBGColour'),
      Colour('Border', 'brandLogoBorderColour'),
    ),
  ),
);

export default config;

// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Button,
  HeadingTwo,
  fontStyleOverride,
  buttonStyleOverride,
  Paragraph,
} from '../Global';
import { TextWrapper } from '..';
import { actions as routerActions } from '../../../actions/router';

const positions = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  CENTER: 'center',
};

const mapAlignToPosition = ({
  horizontalAlignment,
  verticalAlignment,
  padding = 0,
}) => {
  let top;
  let right;
  let bottom;
  let left;
  let translateX = 0;
  let translateY = 0;

  const paddingValue = `${padding}px`;
  const fiftyPercent = '50%';
  const minusFiftyPercent = '-50%';

  if (horizontalAlignment === positions.LEFT) {
    left = paddingValue;
  }

  if (horizontalAlignment === positions.CENTER) {
    left = fiftyPercent;
    translateX = minusFiftyPercent;
  }

  if (horizontalAlignment === positions.RIGHT) {
    right = paddingValue;
  }

  if (verticalAlignment === positions.TOP) {
    top = paddingValue;
  }

  if (verticalAlignment === positions.CENTER) {
    top = fiftyPercent;
    translateY = minusFiftyPercent;
  }

  if (verticalAlignment === positions.BOTTOM) {
    bottom = paddingValue;
  }

  return {
    top,
    right,
    bottom,
    left,
    transform: `translate(${translateX}, ${translateY})`,
  };
};

const OverlayWrapper = styled.div`
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  position: absolute;

  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.transform && `transform: ${props.transform}`};
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${(props: Object) =>
      (props.backgroundColour && props.backgroundColour.value) || '#000000'};
    opacity: ${(props: Object) => props.opacity || 0.8};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

const alignMap = {
  left: 'flex-start',
  right: 'flex-end',
};

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: ${({ horizontalAlignment }) =>
    (horizontalAlignment && alignMap[horizontalAlignment]) || 'center'};
`;

export default (props: Props) => {
  const {
    backgroundColour,
    opacity,
    padding,
    headlineFont,
    bodyFont,
    button,
    headlineText,
    bodyText,
    buttonText,
    horizontalAlignment,
    verticalAlignment,
    uiElements,
    dispatch,
  } = props;
  const position = mapAlignToPosition({
    horizontalAlignment,
    verticalAlignment,
    padding,
  });
  return (
    <OverlayWrapper {...position}>
      <Overlay {...{ backgroundColour, opacity }}>
        <TextWrapper padding={padding}>
          <HeadingTwo
            styleOverride={() => `
              ${fontStyleOverride({
                ...headlineFont,
                alignment: horizontalAlignment,
              })};
              z-index: 2;
            `}
          >
            {headlineText}
          </HeadingTwo>
          <Paragraph
            styleOverride={() => `
              ${fontStyleOverride({
                ...bodyFont,
                alignment: horizontalAlignment,
              })};
              z-index: 2;
            `}
          >
            {bodyText}
          </Paragraph>
        </TextWrapper>
      </Overlay>
      {button && (
        <ButtonWrapper {...{ horizontalAlignment }}>
          <Button
            buttonStyle={
              uiElements.primaryButton && uiElements.primaryButton.buttonStyle
            }
            applyStyle="primary"
            styleOverride={() => buttonStyleOverride(button)}
            text={buttonText}
            onClick={() => dispatch(routerActions.navigate(button.url))}
          />
        </ButtonWrapper>
      )}
    </OverlayWrapper>
  );
};

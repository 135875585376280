// @flow
import React from 'react';
import styled from 'styled-components';
import Chunky from '../JaguarIcons/Arrows/Chunky';
import Criteria from '../JaguarIcons/Global/Criteria';
import SearchFiltersContainer from '../../containers/SearchFiltersContainer';
import Button from '../Global/Button';

type Config = {
  make: string,
  market: string,
  translations: {
    [string]: string,
  },
  filterVisibility: Object,
};

type Props = {
  goBack: Function,
  config: Config,
  resetFilters: Function,
  buttonStyle: string,
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const SearchBarContainer = styled.div.withConfig({
  displayName: 'SearchBarContainer',
})`
  display: flex;
  width: 100%;
  flex-flow: column;
  background: #333333;
  justify-content: space-between;
`;

const SearchBarHeaderContainer = styled.div.withConfig({
  displayName: 'SearchBarHeaderContainer',
})`
  display: flex;
  height: 50px;
  width: 100%;
  background: #333333;
  justify-content: space-between;
`;

const SearchHeaderContainer = styled.div.withConfig({
  displayName: 'SearchHeaderContainer',
})`
  display: flex;
  height: 50px;
  width: 100%;
  background: #333333;
  justify-content: space-between;
  border-top: 1px #acacac solid;
`;

const Chevron = styled.div`
  display: flex;
  position: absolute;
  width: 10px;
  height: 10px;
`;

const BackButton = styled(Chevron)`
  transform: rotate(180deg);
  top: 23px;
  left: 16px;
  width: 10px;
  height: 10px;
`;

const ToggleButton = styled(Chevron)`
  transform: ${({ collapsed }) =>
    collapsed ? 'rotate(0deg)' : 'rotate(90deg)'};
  top: ${({ collapsed }) => (collapsed ? '18px' : '18px')};
  right: ${({ collapsed }) => (collapsed ? '20px' : '16px')};
`;

const SearchBarHeader = styled.div`
  display: flex;
  width: 100%;
  line-height: 55px;
  justify-content: space-around;
  color: #fff;
`;

const SearchHeader = styled.div`
  display: flex;
  line-height: 55px;
  color: #fff;
  flex: 1 0 0%;
  padding-left: 8px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  position: relative;
`;

const ToggleContainer = styled.div`
  width: 10%;
  display: flex;
  position: relative;
`;

const IconContainer = styled.div.withConfig({
  displayName: 'IconContainer',
})`
  display: flex;
  align-items: center;
  padding: 4px 0 0 12px;
`;

const SearchOverlay = styled.div.withConfig({
  displayName: 'SearchOverlay',
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  z-index: 999;
`;

const ButtonContainer = styled.div.withConfig({
  displayName: 'ButtonContainer',
})`
  background-color: #f0f0f0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
`;

const OverlayWrapper = styled.div.withConfig({
  displayName: 'OverlayWrapper',
})`
  overflow: scroll;
  float: left;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
  box-sizing: border-box;
`;

export default class MobileSearchPanel extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: {
        advancedSearch: true,
        searchLocation: false,
      },
      resetValue: 0,
    };
  }

  toggle = (el: string) =>
    this.setState(({ collapsed }) => ({
      collapsed: { ...collapsed, [el]: !collapsed[el] },
    }));

  resetFiltersAndClose = () => {
    this.props.resetFilters();
    this.toggle('advancedSearch');
    this.setState(({ resetValue }) => ({ resetValue: resetValue + 1 }));
  };

  render() {
    const { goBack, config, buttonStyle, featureFlags } = this.props;
    return (
      <Container>
        <SearchBarContainer>
          <SearchBarHeaderContainer>
            <BackButtonContainer onClick={goBack}>
              <BackButton collapsed={this.state.collapsed}>
                <Chunky colour="#7E7E7E" />
              </BackButton>
            </BackButtonContainer>
            <SearchBarHeader>
              {config.translations.searchHeaderMobile}
            </SearchBarHeader>
          </SearchBarHeaderContainer>
          <SearchHeaderContainer>
            <IconContainer>
              <Criteria colour="#7E7E7E" />
            </IconContainer>
            <SearchHeader>
              {config.translations.searchHeaderAdvancedSearch}
            </SearchHeader>
            <ToggleContainer onClick={() => this.toggle('advancedSearch')}>
              <ToggleButton collapsed={this.state.collapsed.advancedSearch}>
                <Chunky colour="#7E7E7E" />
              </ToggleButton>
            </ToggleContainer>
          </SearchHeaderContainer>
        </SearchBarContainer>
        {!this.state.collapsed.advancedSearch && (
          <SearchOverlay>
            <OverlayWrapper>
              <SearchFiltersContainer
                config={config}
                featureFlags={featureFlags}
              />
              <ButtonContainer>
                <Button
                  buttonStyle={buttonStyle}
                  applyStyle="secondary"
                  onClick={this.resetFiltersAndClose}
                  text={config.translations.searchCTAReset}
                  mobileStyleOverride={() => 'margin: 0 10px;'}
                />
                <Button
                  buttonStyle={buttonStyle}
                  applyStyle="primary"
                  onClick={() => this.toggle('advancedSearch')}
                  text={config.translations.searchCTADone}
                  mobileStyleOverride={() => 'margin: 0 10px;'}
                />
              </ButtonContainer>
            </OverlayWrapper>
          </SearchOverlay>
        )}
      </Container>
    );
  }
}

// @flow
import React from 'react';
import styled from 'styled-components';

import IoChevronDown from 'react-icons/lib/io/chevron-down';
import IoChevronUp from 'react-icons/lib/io/chevron-up';

import type { Node } from 'react';
import type { Theme } from '../theme';

import Checkbox from './CheckboxSwitch';
import IconBox from './IconBox';
import FlexWrapper from './FlexWrapper';

type Props = {
  open: boolean,
  disabled?: boolean,
  hasDisableToggle?: boolean,
  name: string,
  titleContents?: Node,
  bodyContents?: Node,
  onDisableToggle?: () => void,
  onTitleClick?: () => void,
};
type ThemeProps = Props & { theme: Theme };

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: ${({ open }: Props) => (open ? '' : 'hidden')};
`;

const Title = styled.div.withConfig({
  displayName: 'Title',
})`
  color: ${(props: ThemeProps) =>
    props.disabled
      ? props.theme.colours.secondary02
      : props.theme.colours.secondary03};
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  height: 14px;
  font-weight: 600;
  padding: ${(props: ThemeProps) => props.theme.layout.standardPadding};
  background-color: ${(props: ThemeProps) => props.theme.colours.primary03};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  cursor: ${(props: { disabled_ie11: boolean }) =>
    props.disabled_ie11 ? 'not-allowed' : 'pointer'};
`;

const IsOpenIcon = styled(IoChevronUp).withConfig({
  displayName: 'IsOpenIcon',
})``;

const IsClosedIcon = styled(IoChevronDown).withConfig({
  displayName: 'IsClosedIcon',
})``;

const ContentContainer = styled.div.withConfig({
  displayName: 'ContentContainer',
})`
  max-height: ${({ visible }: { visible: boolean }) =>
    visible ? 'auto' : '0'};
`;

const ContentMargin = styled.div`
  border-left: 1px solid
    ${(props: ThemeProps) => props.theme.colours.secondary02};
  padding: ${(props: ThemeProps) => `${props.theme.layout.halfPadding} 10px`};
  margin: ${(props: ThemeProps) => props.theme.layout.standardPadding} 0;
`;

export default function Accordion({
  titleContents,
  bodyContents,
  open,
  disabled,
  onTitleClick,
  hasDisableToggle,
  name,
  onDisableToggle,
}: Props) {
  return (
    <Container open={open}>
      <Title onClick={disabled ? null : onTitleClick} disabled_ie11={disabled}>
        <FlexWrapper>{titleContents}</FlexWrapper>
        {hasDisableToggle ? (
          <Checkbox
            onChange={onDisableToggle}
            name={`${name}-toggle`}
            value={!disabled}
          />
        ) : null}
        <IconBox>{open ? <IsOpenIcon /> : <IsClosedIcon />}</IconBox>
      </Title>
      <ContentContainer visible={open && !disabled}>
        <ContentMargin>{bodyContents}</ContentMargin>
      </ContentContainer>
    </Container>
  );
}

Accordion.defaultProps = {
  open: false,
  disabled: false,
  hasDisableToggle: false,
  name: '',
  titleContents: null,
  bodyContents: null,
  onDisableToggle: () => {},
  onTitleClick: () => {},
};

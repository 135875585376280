const appSettings = window.AppSettings || {};

const {
  formPageInstanceId,
  previewBase,
  cssBaseUrl,
  appEnvironment,
  fontUploadIdentity,
  fontUploadBucket,
  awsRegion,
} = appSettings;

const authOn = true;

const redirectUri = `${window.location.protocol}//${window.location.host}/`;

const domain = 'avl-builder.eu.auth0.com';
const clientId = '861FsCH66JL0AQNv5OHjo875ZCZ2Hztx';
const apiAudience = 'https://dev.admin.avl-builder.com';
const authorisationScopes = 'openid edit:sites';
const brandFromDomain = () => window.location.hostname.split('.')[0];
const brand = process.env.REACT_APP_BRAND || brandFromDomain();

const fontsCssUrl = `${cssBaseUrl}/${brand}/fonts.css`;
const environment = appEnvironment || 'dev';
const mediaAccountName = 'motortrak';
const mediaBase = 'https://res.cloudinary.com';
const mediaBaseUrl = `${mediaBase}/${mediaAccountName}`;
const mediaUploadPreset = 'cmspreset';

const product = 'cms';
const globalStylingFolder = 'global';
const getBrandLogoResourceId = logoName =>
  `${brand}/${globalStylingFolder}/${logoName}Logo`;
const logos = {
  header: `${mediaBaseUrl}/image/upload/v1/${product}/${getBrandLogoResourceId(
    'header',
  )}.png`,
  footer: `${mediaBaseUrl}/image/upload/v1/${product}/${getBrandLogoResourceId(
    'footer',
  )}.png`,
  fallback: `${mediaBaseUrl}/image/upload/c_crop,h_50,w_100/v1537798827/default-image_btd6xy.png`,
};

const settings = {
  authOn,
  redirectUri,
  domain,
  clientId,
  apiAudience,
  authorisationScopes,
  brand,
  fontsCssUrl,
  environment,
  previewBase,
  mediaBaseUrl,
  mediaAccountName,
  mediaUploadPreset,
  fontUploadIdentity,
  fontUploadBucket,
  awsRegion,
  logos,
  getBrandLogoResourceId,
  product,
  formPageInstanceId,
};

export default settings;

// @flow
import React, { Fragment, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import media from '../../../theme';

const Video = styled.video`
  width: 100%;
  height: 100%;
  ${media.min.large`
    object-fit: cover;
  `};
`;

const PlayButton = styled.button`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 66;
  color: white;
`;

type Props = {
  src: string,
  isCurrent: boolean,
  handleComplete: () => void,
  autoplay: boolean,
  muted: boolean,
};

export default (props: Props) => {
  const { src, isCurrent, handleComplete, autoplay, muted = true } = props;
  const playButtonSrc =
    'https://res.cloudinary.com/motortrak/image/upload/v1579531581/cms/global/icons/play-btn.svg';

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    videoRef.current.addEventListener(
      'ended',
      () => {
        handleComplete();
      },
      false,
    );
  }, []);

  useEffect(() => {
    if (
      (!isCurrent && !videoRef.current.paused) ||
      (isCurrent && !videoRef.current.paused && !autoplay)
    ) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }

    if (isCurrent && videoRef.current.paused && autoplay) {
      videoRef.current.play();
    }
  }, [videoRef, isCurrent, autoplay]);

  useEffect(() => {
    if (videoRef.current.paused || videoRef.current.ended) {
      setIsPlaying(false);
    }

    if (!videoRef.current.paused && !videoRef.current.ended) {
      setIsPlaying(true);
    }
  }, [isCurrent]);

  return (
    <Fragment>
      <Video muted={muted} ref={videoRef} src={src}>
        <source src={src} type="video/mp4" />
      </Video>
      {!isPlaying && !autoplay && (
        <PlayButton
          onClick={() => {
            videoRef.current.play();
            setIsPlaying(true);
          }}
          src={playButtonSrc}
        />
      )}
    </Fragment>
  );
};

/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';

export function useScript({
  playerScriptSrc: src,
  mediaRef,
  showMedia,
  mediaView,
  vehicleId,
}) {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (!showMedia) {
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute(`data-${mediaView}`, true);
    script.setAttribute('data-subject', vehicleId);

    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      });
    };

    const onScriptError = () => {
      script.remove();

      setState({
        loaded: true,
        error: true,
      });
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    while (mediaRef.current.firstChild) {
      mediaRef.current.removeChild(mediaRef.current.firstChild);
    }

    mediaRef.current.appendChild(script);

    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src, showMedia, mediaView]);

  return state.error;
}

// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  name?: ?string,
  checked: boolean,
  label: string,
  onChange: Function,
};

type CheckboxInputProps = {
  theme: Theme,
};

export const CheckboxInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  border: 1px solid
    ${(props: CheckboxInputProps) => props.theme.colours.secondary03};
  background: #fff;
`;

export const CheckboxLabel = styled.label`
  min-height: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
  display: block;
`;

// This is a custom component for Redux Form Field component
// and is used by passing it to the Field's component prop
// input and meta are props passed from the Field component
export default function Checkbox({ name, checked, label, onChange }: Props) {
  return (
    <CheckboxLabel>
      <CheckboxInput
        name={name}
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      {label}
    </CheckboxLabel>
  );
}

Checkbox.defaultProps = {
  name: null,
};

// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { mediaProps } from './styledComponentFactory';
import { actions as routerActions } from '../../../actions/router';
import media from '../../../theme';

const Anchor = styled.a.attrs(props => ({
  className: `global ${props.applyStyle}`,
}))`
  &&& {
    text-decoration: none;
    ${({ styleOverride }) => styleOverride && styleOverride};
    ${media.min.large`
    ${({ mobileStyleOverride }) => mobileStyleOverride && mobileStyleOverride};
  `}
  }
`;

type Props = {
  href: string,
  styleOverride?: () => string,
  mobileStyleOverride?: () => { queryPath: string, template: string }[],
};

export default function Link({
  styleOverride,
  mobileStyleOverride,
  href,
  ...props
}: Props) {
  const dispatch = useDispatch();
  return (
    <Anchor
      styleOverride={styleOverride}
      mobileStyleOverride={mobileStyleOverride}
      onClick={() => dispatch(routerActions.navigate(href))}
      {...{ ...props, ...mediaProps(mobileStyleOverride()) }}
    />
  );
}

Link.defaultProps = {
  styleOverride: () => '',
  mobileStyleOverride: () => [],
};

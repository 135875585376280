// @flow
import React from 'react';
import styled from 'styled-components';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';

import Facebook from '../JaguarIcons/Global/Facebook';
import Twitter from '../JaguarIcons/Global/Twitter';
import Whatsapp from '../JaguarIcons/Global/Whatsapp';
import Email from '../JaguarIcons/Global/Email';

type Props = {
  shareUrl: string,
  socialShare: Object,
  iconColour?: string,
  height?: number,
};

const SocialMediaShareContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height !== 0 ? `${height}px;` : 'auto;')};
`;

const ShareButtonWrapper = styled.div`
  cursor: pointer;
  padding: 0 8px;
  ${({ enabled }) => !enabled && 'display: none'};

  & > div:focus {
    outline: 0;
  }
`;

export default function SocialMediaShare({
  shareUrl,
  socialShare,
  iconColour,
  height,
}: Props) {
  return (
    <SocialMediaShareContainer height={height}>
      <ShareButtonWrapper enabled={socialShare.facebook}>
        <FacebookShareButton url={shareUrl}>
          <Facebook width="32px" height="32px" colour={iconColour} />
        </FacebookShareButton>
      </ShareButtonWrapper>

      <ShareButtonWrapper enabled={socialShare.twitter}>
        <TwitterShareButton url={shareUrl}>
          <Twitter width="32px" height="32px" colour={iconColour} />
        </TwitterShareButton>
      </ShareButtonWrapper>

      <ShareButtonWrapper enabled={socialShare.whatsApp}>
        <WhatsappShareButton url={shareUrl}>
          <Whatsapp width="32px" height="32px" colour={iconColour} />
        </WhatsappShareButton>
      </ShareButtonWrapper>

      <ShareButtonWrapper enabled={socialShare.email}>
        <EmailShareButton url={shareUrl}>
          <Email width="32px" height="32px" colour={iconColour} />
        </EmailShareButton>
      </ShareButtonWrapper>
    </SocialMediaShareContainer>
  );
}

SocialMediaShare.defaultProps = {
  iconColour: '#000000',
  height: 125,
};

// @flow
/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';

import PlayVideo from '../../components/JaguarIcons/VideoPlayer/PlayVideo';
import {
  HeadingOne,
  HeadingTwo,
  fontStyleOverride,
} from '../../components/Global';
import media from '../../theme';
import type { Option } from '../../types';
import ContentWrapper from '../../components/ContentWrapper';

const Wrapper = styled.div.withConfig({
  displayName: 'Wrapper',
})`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const OpacityParent = styled.div`
  display: none;
  ${media.min.large`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    width: 33%;
    position: absolute;
    top: 50px;
    ${({ left }) => left && `left : ${left}%`};
  `};
`;
const OpacityWrapper = styled.div`
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    ${({ theme }) =>
      theme.overlayVisible
        ? theme.backgroundColour &&
          `background-color: ${theme.backgroundColour.value}`
        : ''};
    ${({ theme }) =>
      theme.overlayVisible
        ? theme.backgroundOpacity && `opacity: ${theme.backgroundOpacity}`
        : ''};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
  }
  position: relative;
  margin: 0 0 1px 0;
  padding: 20px 40px;
`;

const PlayButton = styled.div.withConfig({
  displayName: 'PlayButton',
})`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  ${props =>
    props.backgroundImage &&
    `background-image: url(${
      props.backgroundImage
    });`} background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

export interface Config {
  videoFile: Option;
  posterImage: Option;
  translations: {
    heading: string,
    subheading: string,
    body: string,
    footer: string,
  };
  backgroundColour: {
    label: string,
    value: string,
  };
  backgroundOpacity: number;
  overlayVisible: boolean;
  headingFont: Object;
  bodyFont: Object;
  noTransform?: Boolean;
}
type Props = {
  config: Config,
  preview: boolean,
  contentWidth: number,
};

type State = {
  playButtonVisible: boolean,
  videoUrl: string,
};

const ecoSetting = 'q_auto:eco';

// request video at the size of component keeping the aspect ratio
const convertToEcoQuality = (url: string, width: number, height: number) =>
  url.replace(
    'video/upload',
    `video/upload/${ecoSetting},w_${width},h_${height},c_fit`,
  );

const toThumbnail = resource =>
  resource &&
  (resource.endsWith('.mp4') ? `${resource.slice(0, -4)}.jpg` : resource);

export default class VideoPlayer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      playButtonVisible: true,
    };
  }

  componentDidMount() {
    const {
      config: { noTransform, videoFile },
    } = this.props;
    this.sourceRef.src = noTransform
      ? videoFile.value
      : convertToEcoQuality(
          videoFile.value,
          this.videoRef.clientWidth,
          this.videoRef.clientHeight,
        );
  }

  videoRef = null;

  sourceRef = null;

  play = () => {
    if (!this.props.preview) {
      this.videoRef.play();
      this.setState(() => ({
        playButtonVisible: false,
      }));
    }
  };

  showPlayButton = () => {
    this.setState(() => ({
      playButtonVisible: true,
    }));
  };

  render() {
    const { playButtonVisible } = this.state;
    const {
      posterImage = {},
      videoFile = {},
      translations,
      overlayVisible,
      backgroundColour,
      backgroundOpacity,
      headingFont,
      bodyFont,
      contentWidth,
      videoContentLeft,
    } = this.props.config;
    return (
      <Wrapper>
        {translations && (
          <ContentWrapper
            contentWidth={
              (this.props.globalStyling &&
                this.props.globalStyling.contentWidth) ||
              contentWidth
            }
          >
            <OpacityParent
              visible={overlayVisible && playButtonVisible}
              left={videoContentLeft}
            >
              <OpacityWrapper
                theme={{ backgroundColour, backgroundOpacity, overlayVisible }}
              >
                {translations.heading && (
                  <HeadingOne
                    styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    ${fontStyleOverride(headingFont)}
                  `}
                  >
                    {translations.heading}
                  </HeadingOne>
                )}
                {translations.subheading && (
                  <HeadingOne
                    styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    ${fontStyleOverride(headingFont)}
                  `}
                  >
                    {translations.subheading}
                  </HeadingOne>
                )}
                {translations.body && (
                  <HeadingTwo
                    styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    ${fontStyleOverride(bodyFont)}
                  `}
                  >
                    {translations.body}
                  </HeadingTwo>
                )}
                {translations.footer && (
                  <HeadingTwo
                    styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    margin-top: 20px;
                    ${fontStyleOverride(bodyFont)}
                  `}
                  >
                    {translations.footer}
                  </HeadingTwo>
                )}
              </OpacityWrapper>
            </OpacityParent>
          </ContentWrapper>
        )}
        <PlayButton
          onClick={this.play}
          backgroundImage={posterImage.value || toThumbnail(videoFile.value)}
          visible={playButtonVisible}
        >
          <PlayVideo height="5em" width="5em" colour="#A9A9A9" />
        </PlayButton>
        <ContentWrapper
          contentWidth={
            (this.props.globalStyling &&
              this.props.globalStyling.contentWidth) ||
            contentWidth
          }
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={vid => {
              this.videoRef = vid;
            }}
            width="100%"
            controls
            disablePictureInPicture
            playsInline
            controlsList="nodownload"
            onEnded={this.showPlayButton}
          >
            <source
              ref={source => {
                this.sourceRef = source;
              }}
              src={this.state.videoUrl || 'novideo'}
              type="video/mp4"
            />
            Sorry, your browser does not support embedded videos
          </video>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

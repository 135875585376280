import Icon from './Icon';

export { default as component } from './TextCTA';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Text with CTA';
export const id = 'TextCTA';
export const availableToAdministration = true;
export const description = 'Text with CTA';
export const icon = Icon;
export const searchable = true;
export const getIndexContent = ({
  config: { translations = {} } = {},
  relativePath,
  instanceId,
}) =>
  [
    {
      title: translations.headlineText,
      content: translations.bodyText,
    },
    {
      title: translations.headlineText,
      content: translations.smallBodyText,
    },
    {
      title: translations.headlineText,
      content: translations.buttonText,
    },
  ]
    .map(({ title, content }, index) => ({
      key: `${instanceId}-${index}`,
      title,
      content,
      link: relativePath,
    }))
    .filter(({ title, content }) => !!title || !!content);

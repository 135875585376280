// @flow
import React, { Component } from 'react';
import ShortlistedGridVehicles from '../../components/ShortlistedGridVehicles';
import { screenSizeOptions } from '../../theme';
import { actions as financeActions } from '../../shared/financeCalculator';
import { actions as routerActions } from '../../actions/router';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortlistActions } from '../../shared/shortlist';
import { getVehicles as compareVehicles } from '../../shared/compare/reducer';
import { getVehicles as shortlistVehicles } from '../../shared/shortlist/reducer';
import { sortOptions } from '../../shared/sortOptions/settings';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import ContentWrapper from '../../components/ContentWrapper';
import type { Font } from '../../types';
import { vanityUrlFormatter } from '../../helpers/vehicle';

export interface Config {
  translations: Object;
  subNavFont?: Font;
  subNavBorderColour?: string;
  subNavChevronColour?: string;
  visibility: Object;
  headingAlignment: string;
  compareIconListedColour: Object;
  shortlistIconListedColour: Object;
  headingBottomLineDisable: boolean;
  tileBackgroundColor: Object;
  linearBackgroundDisable: boolean;
  headerItemFont: Object;
  showSubNavCounter: boolean;
  contentWidth: number;
  gridViewColumns: number;
  optionalIconListedColour: Object;
  vdpUrlMetaInfo: Object;
}

type Props = {
  dispatch: Function,
  config: Config,
  actions: {
    toggleOptionalExtras: Function,
    init: Function,
    toggleVehicle: Function,
  },
  pathByModule: string => any,
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  marketInfo: Object,
  globalStyling: Object,
};

type State = {
  sortField: {
    field: string,
    order: string,
    value: string,
  },
  selectedView: 'GRID' | 'LIST',
  sortValueDictionary: Object,
};

export default class ShortlistGrid extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedView =
      window.innerWidth < screenSizeOptions.large ? 'GRID' : 'LIST';

    const sortValueDictionary = {
      priceAsc: (a, b) => a.price.value - b.price.value,
      priceDesc: (a, b) => b.price.value - a.price.value,
      mileageAsc: (a, b) => a.odometer.reading - b.odometer.reading,
      mileageDesc: (a, b) => b.odometer.reading - a.odometer.reading,
      ageAsc: (a, b) => b.registration - a.registration,
      ageDesc: (a, b) => a.registration - b.registration,
    };

    this.state = {
      selectedView,
      sortValueDictionary,
    };
  }

  componentDidMount() {
    const {
      actions: { init },
      dispatch,
      preview,
      shared,
    } = this.props;
    init();
    if (!preview && shared.financeCalculator.representativeExample) {
      dispatch(financeActions.loadRepresentativeExample());
    }
    if (!preview && shared.sessionPreferences.currency) {
      dispatch(
        shortlistActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }
    window.addEventListener('resize', this.onViewportChange);
    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps: Props) {
    const { shared, dispatch } = this.props;
    if (
      prevProps.shared.currencyConversion.exchangeRates !==
      shared.currencyConversion.exchangeRates
    ) {
      dispatch(
        shortlistActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      dispatch(shortlistActions.getVehicles());
    }
  }

  onSortChange = (payload, sortField) => {
    // payload only used for filters update on VehicleSearchResults
    this.props.dispatch(
      sessionActions.updateSessionPreferences(
        'shortlistSort',
        sortOptions[sortField],
      ),
    );
  };

  onViewportChange = () => {
    if (window.innerWidth < screenSizeOptions.extraLarge) {
      this.setState(() => ({ selectedView: 'GRID' }));
    }
    if (window.innerWidth > screenSizeOptions.extraLarge) {
      this.setState(() => ({ selectedView: 'LIST' }));
    }
  };

  props: Props;

  render = () => {
    const { selectedView, sortValueDictionary } = this.state;
    const {
      preview,
      config,
      actions,
      shared,
      dispatch,
      globalStyling,
      marketInfo,
    } = this.props;

    const { distanceAsc, ...optionsForSort } = sortOptions;

    const shortlistedVehicles = preview ? [] : shortlistVehicles(shared);
    const sharedCompareVehicles = preview ? [] : compareVehicles(shared);
    const sharedShortListedVehicles = shortlistVehicles(shared);

    const onMoreInfoClick = (name, vehicle) => {
      const vehicleMetaInfo = vanityUrlFormatter(
        config,
        vehicle,
        shared.sessionPreferences.language,
      );
      dispatch(routerActions.navigate(`/vdp/${vehicle.id}-${vehicleMetaInfo}`));
    };

    const representativeExample = preview
      ? {}
      : shared.financeCalculator.representativeExample;

    const isListed = (id: string, vehicles: Vehicle[]) =>
      vehicles.find(vehicle => vehicle.id === (id || {}));

    const getCompareIconColour = (id: string) =>
      isListed(id, sharedCompareVehicles)
        ? config.compareIconListedColour && config.compareIconListedColour.value
        : '#ACACAC';

    const getShortListedIconColour = (id: string) =>
      isListed(id, sharedShortListedVehicles)
        ? config.shortlistIconListedColour &&
          config.shortlistIconListedColour.value
        : '#ACACAC';

    const getSortValue = () => {
      const { searchSort, shortlistSort } = shared.sessionPreferences;
      return shortlistSort ? shortlistSort.value : searchSort.value;
    };

    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <ShortlistedGridVehicles
          config={config}
          vehicles={shortlistedVehicles}
          placeholdingImage={shared.shortlist.placeholdingImage}
          representativeExample={representativeExample}
          sortOptions={optionsForSort}
          sortValue={getSortValue()}
          sortValueDictionary={sortValueDictionary}
          onSortChange={this.onSortChange}
          selectedView={selectedView}
          getCompareIconColour={getCompareIconColour}
          getShortListedIconColour={getShortListedIconColour}
          onMoreInfoClick={onMoreInfoClick}
          onHeartClick={vehicle => actions.toggleVehicle(vehicle)}
          onCloseClick={vehicle => actions.toggleVehicle(vehicle)}
          onCompareClick={vehicle =>
            dispatch(compareActions.toggleVehicle(vehicle))
          }
          compareVehicle={vehicle =>
            dispatch(compareActions.toggleVehicle(vehicle))
          }
          shortlistVehicle={vehicle =>
            dispatch(shortlistActions.toggleVehicle(vehicle))
          }
          globalStyling={globalStyling}
          locale={marketInfo.locale}
          preview={preview}
          optionalSelectedColour={
            config.optionalSelectedColour && config.optionalSelectedColour.value
          }
          optionalSelectedBgColour={
            config.optionalSelectedBgColour &&
            config.optionalSelectedBgColour.value
          }
          translations={config.translations}
        />
      </ContentWrapper>
    );
  };
}

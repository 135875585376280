import { resolve } from 'url';
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import { mapGroupAndFinance as getGroupFinance } from '../../shared/financeCalculator/effects';
import {
  getModelGroupsBaseUrl,
  getAVLConfig,
  inventorySearchServiceUrl,
  getPlaceholderBrandImageUrl,
  appendLocaleQueryString,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

let modelsCache;

function* mapAffordableGroupAndFinance(group, page, instance) {
  try {
    const groupWithFinance = yield getGroupFinance(group);
    yield put(
      actions.getAffordableModelFinanceSuccess(page, instance, {
        group: groupWithFinance,
      }),
    );
  } catch (error) {
    // noop leave group as is
  }
}

function* mapSuggestedGroupAndFinance(group, page, instance) {
  try {
    const groupWithFinance = yield getGroupFinance(group);
    yield put(
      actions.getSuggestedModelFinanceSuccess(page, instance, {
        group: groupWithFinance,
      }),
    );
  } catch (error) {
    // noop leave group as is
  }
}

function* getBudgetSearchGroups(action) {
  const {
    payload: { budgetRange, budgetIncreasePercentage },
    module: { page, instance },
  } = action;
  try {
    const { locale, make, market, country } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const affordableModelsUrl = `${baseUrl}emulator/make/${make}/market/${market}/min-budget/${
      budgetRange.min
    }/max-budget/${
      budgetRange.max
    }/budget-increase-percentage/${budgetIncreasePercentage}`;

    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);

    const modelGroupsUrl = yield call(
      resolve,
      yield select(getModelGroupsBaseUrl),
      `${make.replace(' ', '')}/${country}/${locale}/model_explore`,
    );

    const modelExplore = modelsCache
      ? [yield { data: modelsCache }]
      : [
          call(get, {
            url: modelGroupsUrl,
          }),
        ];

    const [
      { data: exploreModelsData },
      { data: affordableModelsData },
    ] = yield all([
      ...modelExplore,
      ...[
        call(get, {
          url: affordableModelsUrl,
          ...(withLocaleQueryString && {
            config: localeConfigSerializer(locale),
          }),
        }),
      ],
    ]);

    if (!modelsCache) {
      modelsCache = exploreModelsData;
    }

    const mergedAffordableModels = affordableModelsData.affordableModels.map(
      model => ({
        ...model,
        imageUrl: (
          exploreModelsData.find(
            d => d.modelGroupName.toLowerCase() === model.model.toLowerCase(),
          ) || {}
        ).imageDomain,
        description: (
          exploreModelsData.find(
            d => d.modelGroupName.toLowerCase() === model.model.toLowerCase(),
          ) || {}
        ).modelGroupDescription,
      }),
    );
    const mergedSuggestedModels = affordableModelsData.suggestedModels.map(
      model => ({
        ...model,
        imageUrl: (
          exploreModelsData.find(
            d => d.modelGroupName.toLowerCase() === model.model.toLowerCase(),
          ) || {}
        ).imageDomain,
        description: (
          exploreModelsData.find(
            d => d.modelGroupName.toLowerCase() === model.model.toLowerCase(),
          ) || {}
        ).modelGroupDescription,
      }),
    );

    yield put(
      actions.getBudgetSearchGroupsSuccess(page, instance, {
        affordableModels: mergedAffordableModels,
        suggestedModels: mergedSuggestedModels,
        suggestedBudgetIncrease: affordableModelsData.suggestedBudgetIncrease,
        placeholdingImage,
      }),
    );

    yield all([
      ...mergedAffordableModels.map(group =>
        call(mapAffordableGroupAndFinance, group, page, instance),
      ),
      ...mergedSuggestedModels.map(group =>
        call(mapSuggestedGroupAndFinance, group, page, instance),
      ),
    ]);
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* getBudgetSearchRange(action) {
  const {
    payload: { preview, budgetIncreasePercentage },
    module: { page, instance },
  } = action;
  if (preview) {
    yield put(
      actions.getBudgetSearchRangeSuccess(page, instance, {
        fullRangeOptions: {
          min: 800,
          max: 1000,
        },
      }),
    );
    return;
  }
  try {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const url = `${baseUrl}emulator/make/${make}/market/${market}/setup`;
    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });
    yield put(
      actions.getBudgetSearchRangeSuccess(page, instance, {
        fullRangeOptions: {
          min: response.data.min,
          max: response.data.max,
        },
      }),
    );

    yield put(
      actions.getBudgetSearchGroups(page, instance, {
        budgetRange: {
          min: response.data.min,
          max: response.data.max,
        },
        budgetIncreasePercentage,
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* BudgetSearchSaga() {
  yield takeEvery(constants.GET_BUDGET_SEARCH_RANGE, getBudgetSearchRange);
  yield takeEvery(constants.GET_BUDGET_SEARCH_GROUPS, getBudgetSearchGroups);
}

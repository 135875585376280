import { takeLatest, select, put, call } from 'redux-saga/effects';
import querystring from 'querystring';
import omit from 'ramda/src/omit';
import without from 'ramda/src/without';
import { actions, constants } from './reducer';
import { actions as routerActions } from '../../actions/router';
import { google } from '../../utilities/googleObject';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getSearchMarket,
  getInventoryStatus,
} from '../selectors/settings';
import applyModelVariantLogic, {
  adjustPriceRangeFilterValues,
} from '../../helpers/filters';

const getRetailerId = () => {
  const queryStringObject = querystring.parse(
    window.location.search.substring(1),
  );
  return queryStringObject.retailerId;
};

const setRegionAvailability = (regionsToUpdate, regions) => {
  const regionsEnabledAndDisabled = Object.entries(regions).reduce(
    (acc, entry) => {
      const region = entry[0];
      const subregions = entry[1];

      return {
        ...acc,
        [region]: subregions.map(sr => ({
          name: sr,
          enabled: !!(
            regionsToUpdate &&
            regionsToUpdate[region] &&
            regionsToUpdate[region].includes(sr)
          ),
        })),
      };
    },
    {},
  );

  return regionsEnabledAndDisabled;
};

function* loadFilters(action) {
  const queryStringFromUrl = action.payload;
  try {
    let selectedFilters = querystring.parse(queryStringFromUrl);
    selectedFilters = applyModelVariantLogic(selectedFilters);
    selectedFilters = selectedFilters.maxOdometer
      ? { ...selectedFilters, maxOdometer: Number(selectedFilters.maxOdometer) }
      : selectedFilters;

    const currency = yield select(
      state => state.shared.sessionPreferences.currency,
    );
    const exchange = yield select(
      state => state.shared.currencyConversion.exchangeRates[currency],
    );

    selectedFilters = selectedFilters.minPriceRange
      ? {
          ...selectedFilters,
          minPriceRange: Number(selectedFilters.minPriceRange),
        }
      : selectedFilters;

    selectedFilters = selectedFilters.maxPriceRange
      ? {
          ...selectedFilters,
          maxPriceRange: Number(selectedFilters.maxPriceRange),
        }
      : selectedFilters;

    const orignialMinDefaultPrice = selectedFilters.minDefaultPrice;
    const originalMaxDefaultPrice = selectedFilters.maxDefaultPrice;

    selectedFilters = adjustPriceRangeFilterValues(selectedFilters, exchange);

    const location = yield select(
      state => state.shared.sessionPreferences.location || {},
    );

    selectedFilters = location
      ? { ...selectedFilters, ...location }
      : selectedFilters;

    const { make, market, region, locale } = yield select(getAVLConfig);
    const searchMarket = yield select(getSearchMarket);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const inventoryStatus = yield select(getInventoryStatus);

    const url = region
      ? `${baseUrl}filters/make/${make}/region/${region}`
      : `${baseUrl}filters/make/${make}/market/${market}`;

    const result = yield call(get, {
      url,
      config: localeConfigSerializer(withLocaleQueryString && locale, {
        ...selectedFilters,
        ...(searchMarket && { market: searchMarket }),
        status: inventoryStatus,
      }),
    });

    const { defaultPriceRange, priceRange, regions, ...rest } = result.data;

    const retailerId = getRetailerId();
    const linkedFilterId = 'fdda8500-369e-11e9-820b-5bbf0b6f1c0b';
    const regionsList = yield select(
      state => state.config.linked[linkedFilterId].config.regions,
    );
    const availableRegions = regionsList
      ? setRegionAvailability(regions, regionsList)
      : regions;
    const availableFilterValues = {
      maxDistance: [
        {
          label: 'Worldwide',
          value: '',
          translationKey: 'distanceDropdownPlaceholder',
        },
        {
          label: '25km',
          value: 25,
        },
        {
          label: '50km',
          value: 50,
        },
        {
          label: '100km',
          value: 100,
        },
        {
          label: '200km',
          value: 200,
        },
        {
          label: '500km',
          value: 500,
        },
        {
          label: '1000km',
          value: 1000,
        },
      ],
      fuelConsumption: [
        {
          label: 'Up to 10 l/100km',
          value: 10,
        },
        {
          label: 'Up to 8 l/100km',
          value: 8,
        },
        {
          label: 'Up to 6 l/100km',
          value: 6,
        },
        {
          label: 'Up to 4 l/100km',
          value: 4,
        },
      ],
      minPriceRange: priceRange.min,
      maxPriceRange: priceRange.max,
      minDefaultPrice: defaultPriceRange.min,
      maxDefaultPrice: defaultPriceRange.max,
      ...rest,
      regions: availableRegions,
    };

    /*
    All code below wrapped in ### is to fix a filter bug with
    the new disable variant when no model logic
    https://connect-auto.atlassian.net/browse/AVL3-2247
    */
    // is there 1 variant selected and is it in the list of available variants
    if (
      !!selectedFilters.variant &&
      typeof selectedFilters.variant === 'string' &&
      !availableFilterValues.variant.find(
        v => v.value === selectedFilters.variant,
      )
    ) {
      if (retailerId) {
        selectedFilters = {
          ...selectedFilters,
          retailerId,
        };
      }
      const queryParams = querystring.stringify(
        omit(['variant'], selectedFilters),
      );
      yield put(routerActions.navigateWithFilters(`?${queryParams}`, true));
      yield put(actions.initialiseFilters(window.location.search.substring(1)));
    }
    /* are there multiple variants selected and are they in the list
    of available variants */
    if (
      !!selectedFilters.variant &&
      typeof selectedFilters.variant === 'object'
    ) {
      let invalidVariants = [];
      selectedFilters.variant.forEach(v => {
        if (!availableFilterValues.variant.find(va => v === va.value)) {
          invalidVariants = [...invalidVariants, v];
        }
      });
      if (invalidVariants.length > 0) {
        if (retailerId) {
          selectedFilters = {
            ...selectedFilters,
            retailerId,
          };
        }
        const queryParams = querystring.stringify({
          ...selectedFilters,
          variant: without(invalidVariants, selectedFilters.variant),
        });
        yield put(routerActions.navigateWithFilters(`?${queryParams}`, true));
        yield put(
          actions.initialiseFilters(window.location.search.substring(1)),
        );
      }
    }
    // ###########################################################################

    selectedFilters = {
      ...selectedFilters,
      ...(orignialMinDefaultPrice && {
        minDefaultPrice: orignialMinDefaultPrice,
      }),
      ...(originalMaxDefaultPrice && {
        maxDefaultPrice: originalMaxDefaultPrice,
      }),
    };

    yield put(
      actions.loadFiltersSuccess({
        selectedFilters: omit(['latitude', 'longitude'], selectedFilters),
        availableFilterValues,
      }),
    );
  } catch (error) {
    // no-op
  }
}

function* updateQueryParameters() {
  try {
    let params = yield select(state => state.shared.filters.selectedFilters);
    const retailerId = getRetailerId();
    params = applyModelVariantLogic(params);

    if (retailerId) {
      params = {
        ...params,
        retailerId,
      };
    }

    const queryParams = querystring.stringify(params);
    yield put(routerActions.navigateWithFilters(`?${queryParams}`, true));
  } catch (error) {
    // console.log(error);
  }
}

function* updateFilters(action) {
  yield put(actions.updateFiltersStore(action.payload));
  yield updateQueryParameters();
  yield put(actions.initialiseFilters(window.location.search.substring(1)));
}

function decodeLocation(location) {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const coords = results[0].geometry.location;
        const latitude = coords.lat();
        const longitude = coords.lng();
        resolve({ latitude, longitude });
      } else {
        reject(status);
      }
    });
  });
}

function* searchWithFilters() {
  try {
    let params = yield select(state => state.shared.filters.selectedFilters);
    const coords = params.location
      ? yield call(decodeLocation, params.location)
      : {};
    const retailerId = getRetailerId();

    if (retailerId) {
      params = {
        ...params,
        retailerId,
      };
    }
    const queryParams = querystring.stringify({ ...params, ...coords });
    yield put(
      routerActions.navigateWithFilters(`/searchresults?${queryParams}`, false),
    );
  } catch (error) {
    // console.log(error);
  }
}

function* resetPriceSliderValues() {
  const payload = [
    { key: 'minDefaultPrice', value: null },
    { key: 'maxDefaultPrice', value: null },
  ];

  yield put(actions.updateFilters(payload));
}

export default function* effects() {
  yield takeLatest(constants.INITIALISE_FILTERS, loadFilters);
  yield takeLatest(constants.UPDATE_FILTERS, updateFilters);
  yield takeLatest(constants.RESET_FILTERS, updateQueryParameters);
  yield takeLatest(constants.SEARCH_WITH_FILTERS, searchWithFilters);
  yield takeLatest(constants.RESET_PRICE_SLIDER_VALUES, resetPriceSliderValues);
}

// @flow
import React from 'react';
import styled from 'styled-components';

import FeatureInventoryTemplate from './Templates/FeatureInventoryTemplate';
import { useFeatureInventoryRange } from './useFeatureInventoryRange';

import type { FeatureInventoryConfig } from './types';

type Props = {
  config: FeatureInventoryConfig,
};

const templates = {
  FeatureInventoryTemplate,
};

const FeatureInventoryRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

export default ({ config }: Props) => {
  const postData = useFeatureInventoryRange();
  const Template = templates[config.template];
  return (
    <FeatureInventoryRangeWrapper>
      <Template config={config} postData={postData} />
    </FeatureInventoryRangeWrapper>
  );
};

// @flow
import React from 'react';
import ItemsEditor from '../components/ItemsEditor/ItemsEditor';

import { type FieldProps } from '../models/fields';

const Items = ({
  ids: { items: key, translations: tkey },
  onChange,
  data,
  values: { items, translations },
  siteId,
  actions,
  addButtonText,
  saveButtonText,
  name: fieldName,
  max,
}: FieldProps) => {
  const options = [
    ...data.pages.map(({ id, pageName, relativePath }) => ({
      id,
      label: pageName,
      url: relativePath.substring(1),
    })),
    ...data.externalLinks.map(({ id, name, url }) => ({
      id,
      label: name,
      url,
    })),
  ];

  return (
    <ItemsEditor
      value={items || []}
      onChange={newItems => {
        onChange(key, newItems);
        onChange(
          tkey,
          newItems.reduce(
            (acc, curr) => ({ ...acc, [curr.itemId]: curr.label }),
            { ...translations },
          ),
        );
      }}
      options={options}
      name={key}
      siteId={siteId}
      actions={actions}
      addButtonText={addButtonText}
      saveButtonText={saveButtonText}
      fieldName={fieldName}
      max={max}
    />
  );
};
export default Items;

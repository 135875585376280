import { required, validEmail, validPhone } from './validationFunctions';

const validations = {
  required,
  email: validEmail,
  phone: validPhone,
};

export default function validate(field, translations) {
  if (!field.validation) {
    return undefined;
  }
  return {
    ...{
      [field.name]: validations[field.validation](field.value, translations)[0],
    },
  };
}

export function validateAll(fields, translations) {
  const errors = Object.values(fields)
    .map(fieldValue => validate(fieldValue, translations))
    .filter(fieldError => fieldError !== undefined)
    .reduce((acc, currVal) => ({ ...acc, ...currVal }), {});

  return errors;
}

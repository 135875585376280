// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../../theme';
import BudgetResultTile from '../BudgetResultTile';

const BudgetResultsContainer = styled.div.withConfig({
  displayName: 'BudgetResultsContainer',
})`
  ${media.min.large`
    background-color: #F0F0F0;
    display:flex;
    flex-wrap:wrap;
    min-height: 500px;
    padding:1% 2% 2% 2%;
  `};
`;

type Props = {
  results: Object[],
  moreInfoClick: (id: string) => void,
  translations: Object,
  viewNowClick: (model: string) => void,
  placeholdingImage: string,
  globalStyling: Object,
  buttonStyle: string,
  locale: string,
};

export default function BudgetResultPane({
  results = [],
  moreInfoClick,
  translations,
  viewNowClick,
  placeholdingImage,
  globalStyling,
  buttonStyle,
  locale,
}: Props) {
  return (
    <BudgetResultsContainer>
      {results.map(result => (
        <BudgetResultTile
          key={`${result.id}${result.model}`}
          align="left"
          onMoreInfoClick={moreInfoClick}
          onViewNowClick={viewNowClick}
          result={result}
          translations={translations}
          placeholdingImage={placeholdingImage}
          globalStyling={globalStyling}
          buttonStyle={buttonStyle}
          locale={locale}
        />
      ))}
    </BudgetResultsContainer>
  );
}

// @flow
/* eslint-disable-next-line import/no-unresolved, import/extensions */

import type { SiteBuilderState } from '../types/siteBuilder';
import { constants, type Action } from '../actions/siteBuilder';
import screenSizeOptions from '../../screenSizeOptions';
import type { ScreenSizeOption } from '../../screenSizeOptions';

import externalLink, {
  initialState as externalLinkInitialState,
} from './externalLink';
import { constants as editorConstants } from '../actions/editor';

import { mediaResourcesDown } from '../../helpers/media';

const defaultState: SiteBuilderState = {
  success: null,
  ui: {
    createPageDetail: true,
    createPageSeo: true,
    externalLink: true,
    section: 0,
  },
  selectedScreenSize: screenSizeOptions[0],
  message: '',
  externalLink: externalLinkInitialState,
  publish: {
    publishStatus: 'none',
    error: undefined,
  },
  media: {
    images: [],
    videos: [],
    error: undefined,
  },
};

const screenSizeOrDefault = (name: string): ScreenSizeOption =>
  screenSizeOptions.find(o => o.name === name) || screenSizeOptions[0];

const updatePublishStatus = (
  state: SiteBuilderState,
  status: string,
  url: string = null,
) => ({
  ...state,
  publish: {
    ...state.publish,
    publishStatus: status,
    publishedUrl: url,
  },
});

function siteBuilder(state: SiteBuilderState = defaultState, action: Action) {
  switch (action.type) {
    case editorConstants.ResetSuccessMessages:
      return {
        ...state,
        success: null,
      };
    case editorConstants.ResetFailureMessages:
      return {
        ...state,
        error: null,
      };

    case constants.ShowAccordion: {
      const { id, show } = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          [id]: show,
        },
      };
    }
    case constants.SetPreviewScreenSize: {
      return {
        ...state,
        selectedScreenSize: screenSizeOrDefault(action.payload.size),
      };
    }
    case constants.SetMessage: {
      return {
        ...state,
        message: action.payload.message,
      };
    }
    case constants.LoadMediaContentSuccess:
      return {
        ...state,
        media: {
          ...state.media,
          images: [...action.payload.images],
          videos: [...action.payload.videos],
        },
      };
    case constants.PublishSiteRequested:
    case constants.PublishPreviewSiteSuccess:
      return updatePublishStatus(state, 'requested');
    case constants.PublishSite:
      return updatePublishStatus(state, 'publishing');
    case constants.PublishPreviewSite:
      return updatePublishStatus(state, 'publishing preview');
    case constants.PublishSiteSuccess:
      return updatePublishStatus(state, 'published', action.payload);
    case constants.PublishSiteCancelled:
      return updatePublishStatus(state, 'none');
    case constants.PublishSiteFailure:
    case constants.PublishPreviewSiteFailure: {
      const updatedState = updatePublishStatus(state, 'failed');
      return {
        ...updatedState,
        publish: {
          ...updatedState.publish,
          error: action.payload,
        },
      };
    }
    case constants.UploadMediaContentSuccess: {
      const { resources, type } = action.payload;
      const mapType = {
        image: 'image',
        svg: 'image',
        video: 'video',
      };
      const moreResources = mediaResourcesDown(resources, mapType[type]);

      return {
        ...state,
        media: {
          ...state.media,
          images: [
            ...(mapType[type] === 'image' ? moreResources : []),
            ...state.media.images,
          ],
          videos: [
            ...(mapType[type] === 'video' ? moreResources : []),
            ...state.media.videos,
          ],
          error: undefined,
        },
      };
    }
    case constants.UploadMediaContentFailure:
      return {
        ...state,
        media: { ...state.media, error: action.payload },
      };
    case constants.DeleteMediaContentFailure:
      return {
        ...state,
        media: { ...state.media, error: action.payload },
      };
    case constants.DeleteMediaContentSuccess: {
      const { mediaPublicId, type } = action.payload;

      const mediaCollection = type === 'video' ? 'videos' : 'images';

      const index = state.media[mediaCollection].findIndex(
        ({ publicId }) => publicId === mediaPublicId,
      );

      return {
        ...state,
        media: {
          ...state.media,
          [mediaCollection]: [
            ...state.media[mediaCollection].slice(0, index),
            ...state.media[mediaCollection].slice(index + 1),
          ],
          error: undefined,
        },
      };
    }
    case constants.ToggleSection: {
      const { index } = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          section: state.ui.section !== index ? index : null,
        },
      };
    }
    default:
      return state;
  }
}

export default function reducer(state: SiteBuilderState, action: any) {
  const siteState = siteBuilder(state, action);
  return {
    ...siteState,
    externalLink: externalLink(siteState.externalLink, action),
  };
}

// @flow
import React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  flex: 1 0 0%;
`;

export default function Form(props: Object) {
  return <StyledForm {...props} />;
}

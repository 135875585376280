import { moduleConstants } from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_DATA_PROVIDER';

export const constants = moduleConstants(modulePrefix, [
  'GET_VEHICLE_INFO',
  'GET_DATA_SUCCESS',
  'CLEAR_DATA_PROVIDER_STATE',
]);

export const id = 'DATA_PROVIDER';

export const actions = {
  getVehicleInfo: dataKey => ({
    type: constants.GET_VEHICLE_INFO,
    dataKey,
  }),
  getDataSuccess: (payload, dataKey) => ({
    type: constants.GET_DATA_SUCCESS,
    payload,
    dataKey,
  }),
  clearDataProviderState: dataKey => ({
    type: constants.CLEAR_DATA_PROVIDER_STATE,
    dataKey,
  }),
};

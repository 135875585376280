import { moduleConstants } from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_VEHICLE_INFO_SHARED';

export const constants = moduleConstants(modulePrefix, [
  'GET_VEHICLE_INFO',
  'GET_VEHICLE_INFO_SUCCESS',
  'CLEAR_VEHICLE_INFO',
]);

export const actions = {
  getVehicleInfo: payload => ({ type: constants.GET_VEHICLE_INFO, payload }),
  getVehicleInfoSuccess: payload => ({
    type: constants.GET_VEHICLE_INFO_SUCCESS,
    payload,
  }),
  clearVehicleInfo: () => ({ type: constants.CLEAR_VEHICLE_INFO }),
};

export const initialState = {
  vehicle: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_VEHICLE_INFO_SUCCESS: {
      return {
        ...state,
        vehicle: action.payload,
      };
    }
    case constants.CLEAR_VEHICLE_INFO: {
      return {
        ...state,
        vehicle: null,
      };
    }
    default:
      return state;
  }
}

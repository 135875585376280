// @flow
import React from 'react';
import styled from 'styled-components';
import IconX from '../JaguarIcons/Global/IconX';
import { Paragraph, fontStyleOverride } from '../Global';

type Props = {
  pillText: string,
  onClose: () => void,
};

const Pill = styled.div`
  border-radius: 20px;
  border: 1px solid #999999;
  padding: 6px 18px;
  ${({ direction }) =>
    direction === 'rtl' ? 'margin: 0 0 9px 12px' : 'margin: 0 12px 9px 0'};
  color: #444444;
  display: inline-flex;
  align-items: center;
  background: #ffffff;
`;

const CloseButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #444444;
  font-size: 13px;
  background: none;
  border: none;
  cursor: pointer;
`;

export default function FilterPill({
  pillText,
  onClose,
  font,
  direction,
}: Props) {
  return (
    <Pill direction={direction}>
      <Paragraph
        styleOverride={() => `
          ${fontStyleOverride(font)}
          margin: 0 7px;`}
      >
        {pillText}
      </Paragraph>
      <CloseButton onClick={onClose}>
        <IconX height="13px" />
      </CloseButton>
    </Pill>
  );
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Button } from '../Global';

const ControlsWrapper = styled.div`
  height: 100px;
  width: 100%;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 20px;
`;

const ImageViewToggle = styled.button`
  width: 25px;
  height: 25px;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const ImageViewIcon = styled.img`
  width: 100%;
`;

const ToggleViewWrapper = styled.div`
  width: 100%;
  padding-left: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  imagesIcon: string,
  toggleImageView: () => void,
  setPlayerView: (view: string) => void,
  globalStyling: Object,
  currentView: string,
  media: Object,
  translations: Object,
};

export default ({
  imagesIcon,
  toggleImageView,
  setPlayerView,
  globalStyling,
  currentView,
  media,
  translations,
}: Props) => {
  const {
    colours: { primaryForegroundColour, primaryBackgroundColour },
  } = globalStyling;
  const toggleStylingOn = `
    border: 1px solid;
    border-color: ${primaryBackgroundColour && primaryBackgroundColour.value};
    background-color: ${primaryBackgroundColour &&
      primaryBackgroundColour.value};
    color: ${primaryForegroundColour && primaryForegroundColour.value};
    &:hover {
      border-color: ${primaryBackgroundColour && primaryBackgroundColour.value};
      background-color: ${primaryBackgroundColour &&
        primaryBackgroundColour.value};
      color: ${primaryForegroundColour && primaryForegroundColour.value};
    }
  `;
  const toggleStylingOff = `
    border: 1px solid;
    border-color: ${primaryBackgroundColour && primaryBackgroundColour.value};
    background-color: ${primaryForegroundColour &&
      primaryForegroundColour.value};
    color: ${primaryBackgroundColour && primaryBackgroundColour.value};
    &:hover {
      ${toggleStylingOn};
    }
  `;

  return (
    <ControlsWrapper>
      <ImageViewToggle onClick={toggleImageView}>
        <ImageViewIcon src={imagesIcon} />
      </ImageViewToggle>
      <ToggleViewWrapper>
        {media.exterior && media.interior && (
          <Fragment>
            <Button
              text={translations.interior}
              buttonStyle="ButtonStyle1"
              styleOverride={() =>
                currentView === 'interior' ? toggleStylingOn : toggleStylingOff
              }
              onClick={() => {
                setPlayerView('interior');
              }}
            />
            <Button
              text={translations.exterior}
              buttonStyle="ButtonStyle1"
              styleOverride={() =>
                currentView === 'exterior' ? toggleStylingOn : toggleStylingOff
              }
              onClick={() => {
                setPlayerView('exterior');
              }}
            />
          </Fragment>
        )}
      </ToggleViewWrapper>
    </ControlsWrapper>
  );
};

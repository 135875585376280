// @flow
import { useState } from 'react';

type LocationHook = {
  lat: string,
  long: string,
  setLat: (lat: string) => void,
  setLong: (long: string) => void,
};
export default (): LocationHook => {
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');

  return {
    lat,
    long,
    setLat,
    setLong,
  };
};

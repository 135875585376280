import { put, call, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  getSearchMarket,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

let responseAllDealersCache;
let responseCentralLocationCache;

function* getRetailers(action) {
  const {
    module: { page, instance },
    payload: { lat, lng, forceUpdate } = {},
  } = action;
  try {
    const { make, market, region, locale } = yield select(getAVLConfig);
    const searchMarket = yield select(getSearchMarket);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const collectionUrl = region
      ? `${baseUrl}inventory/make/${make}/region/${region}`
      : `${baseUrl}inventory/make/${make}/market/${market}`;
    const url = `${collectionUrl}/dealers?LanguageCode=${locale}${
      searchMarket ? `&market=${searchMarket}` : ''
    }${
      lat && lng
        ? `&Latitude=${lat}&Longitude=${lng}&ClosestNumberOfDealers=3`
        : ''
    }`;

    const response =
      responseAllDealersCache && !forceUpdate && !(lat && lng)
        ? yield {
            data: {
              retailers: responseAllDealersCache,
              centreLocation: responseCentralLocationCache,
            },
          }
        : yield call(get, { url });

    const {
      data: { retailers, centreLocation },
    } = response;
    responseCentralLocationCache = centreLocation;

    if (!responseAllDealersCache || forceUpdate) {
      responseAllDealersCache = retailers;
    }
    yield put(
      actions.getRetailersSuccess(page, instance, {
        retailers,
        centreLocation,
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* RetailersMapSaga() {
  yield takeEvery(constants.GET_RETAILERS, getRetailers);
}

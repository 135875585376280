// @flow
import React from 'react';
import styled from 'styled-components';
import InlineError from './InlineError';
import Input from './Input';
import InputLabel from './InputLabel';
import type { InputLabelPosition } from './InputLabel';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ inputFlexMode }) => `${inputFlexMode}`};
`;

type Props = {
  input: Object,
  meta: Object,
  type: string,
  large?: boolean,
  placeholder: string,
  inputLabel?: string,
  inputWidth?: ?number,
  maxInputWidth?: ?number,
  inputLabelPosition?: InputLabelPosition,
  value?: string | number,
  disabled?: boolean,
  inputFlexMode?: string,
  min?: any,
  max?: any,
  step?: any,
};

export default function InputField({
  input,
  meta: { touched, error },
  type,
  large,
  placeholder,
  inputLabel,
  inputLabelPosition,
  inputWidth,
  maxInputWidth,
  disabled,
  inputFlexMode,
  min,
  max,
  step,
}: Props) {
  return (
    <InputFieldContainer inputFlexMode={inputFlexMode}>
      <InputContainer>
        <Input
          inputWidth={inputWidth}
          maxInputWidth={maxInputWidth}
          large={large}
          hasInputLabel={!!inputLabel}
          inputLabelPosition={inputLabelPosition}
          placeholder={placeholder}
          type={type}
          {...input}
          error={touched && error}
          disabled={disabled}
          prefix=""
          min={min}
          max={max}
          step={step}
        />
        {inputLabel && (
          <InputLabel
            error={touched && error}
            large={large}
            inputLabelPosition={inputLabelPosition}
          >
            {inputLabel}
          </InputLabel>
        )}
      </InputContainer>
      {touched && error && <InlineError>{error}</InlineError>}
    </InputFieldContainer>
  );
}

InputField.defaultProps = {
  input: {},
  meta: { touched: false, error: '' },
  large: false,
  type: 'string',
  placeholder: '',
  inputLabel: '',
  inputWidth: null,
  maxInputWidth: null,
  inputLabelPosition: 'left',
  inputFlexMode: '0',
};

import editor from './editor';

export default {
  id: 'Footer',
  name: 'Footer',
  editor,
  defaultSlice: 'Content',
  requirements: {
    global: true,
  },
};

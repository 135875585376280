// @flow
import {
  moduleActionWithArgs,
  moduleAction,
  moduleConstants,
} from '../../utilities/reducerMacros';
import { convertVehiclesPriceCurrency } from '../../shared/currencyConversion/helpers';

const modulePrefix = 'MOD_VEHICLE_SEARCH_RESULTS';

export const constants = moduleConstants(modulePrefix, [
  'SHOW_ALL',
  'GET_INVENTORY',
  'GET_INVENTORY_SUCCESS',
  'INIT',
  'GET_FINANCE_SUCCESS',
  'GET_DEALER',
  'GET_DEALER_SUCCESS',
  'CLEAR_ALL',
  'CHANGE_PAGE',
  'LOAD_FINANCE',
  'CANCEL_LOADING',
  'CLEAR_LOADED',
  'UPDATE_VEHICLE_PRICES',
  'UPDATE_VEHICLE_PRICES_SUCCESS',
]);

export const id = 'VehicleSearchResults';

export const actions = {
  init: moduleAction(constants.INIT, id),
  showAll: moduleActionWithArgs(constants.SHOW_ALL, id, payload => payload),
  getInventory: moduleActionWithArgs(
    constants.GET_INVENTORY,
    id,
    payload => payload,
  ),
  getInventorySuccess: moduleActionWithArgs(
    constants.GET_INVENTORY_SUCCESS,
    id,
    config => config,
  ),
  getFinanceSuccess: moduleActionWithArgs(
    constants.GET_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  getDealer: moduleActionWithArgs(
    constants.GET_DEALER,
    id,
    dealerId => dealerId,
  ),
  getDealerSuccess: moduleActionWithArgs(
    constants.GET_DEALER_SUCCESS,
    id,
    dealer => dealer,
  ),
  changePage: moduleActionWithArgs(
    constants.CHANGE_PAGE,
    id,
    payload => payload,
  ),
  loadFinance: moduleActionWithArgs(
    constants.LOAD_FINANCE,
    id,
    payload => payload,
  ),
  cancelLoading: moduleActionWithArgs(
    constants.CANCEL_LOADING,
    id,
    payload => payload,
  ),
  clearLoaded: moduleAction(constants.CLEAR_LOADED, id),
  updateVehiclePrices: moduleActionWithArgs(
    constants.UPDATE_VEHICLE_PRICES,
    id,
    payload => payload,
  ),
  updateVehiclePricesSuccess: moduleActionWithArgs(
    constants.UPDATE_VEHICLE_PRICES_SUCCESS,
    id,
    payload => payload,
  ),
};

const replaceIfExists = (vehicles, vehicle) => [
  ...vehicles.map(v => (v.id === vehicle.id ? vehicle : v)),
];

const updateVehicleCurrencies = (state, action) => {
  const { payload } = action;
  const { exchangeRates } = payload;
  const { currency } = payload;
  const baseRates =
    exchangeRates && exchangeRates[currency] && exchangeRates[currency].rates;
  const pageNumbers = Object.keys(state.searchResults);

  const convertedResultsPerPage = pageNumbers.map(pN => {
    const results = state.searchResults[pN];
    const converted = baseRates
      ? convertVehiclesPriceCurrency(results, baseRates, currency)
      : results;
    return { page: pN, results: converted };
  });
  const reduced = convertedResultsPerPage.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.page]: curr.results,
    }),
    {},
  );

  return {
    ...state,
    searchResults: reduced,
  };
};

export const initialState = {
  infiniteScroll: false,
  loading: false,
  searchResults: {},
  pageSize: 9,
  currentPage: 1,
};

type State = {
  searchResults: {},
  pageSize: Number,
  totalResults: ?Number,
  currentPage: Number,
  loading: Boolean,
};

type Action = {
  type: string,
  payload: Object,
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SHOW_ALL: {
      return {
        ...state,
        infiniteScroll: true,
      };
    }
    case constants.CLEAR_LOADED: {
      return {
        ...state,
        searchResults: {},
        totalResults: null,
        infiniteScroll: false,
        currentPage: 1,
        loading: false,
      };
    }
    case constants.CANCEL_LOADING: {
      const resetToFirstPage = action.payload;
      return {
        ...state,
        loading: false,
        infiniteScroll: false,
        ...(resetToFirstPage && {
          currentPage: 1,
        }),
      };
    }
    case constants.GET_INVENTORY: {
      return {
        ...state,
        loading: true,
        filters: action.payload.filters,
      };
    }
    case constants.CHANGE_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
        loading: false,
      };
    }
    case constants.GET_INVENTORY_SUCCESS: {
      const { pageNumber, contents, total } = action.payload;
      return {
        ...state,
        loading: false,
        searchResults: {
          ...state.searchResults,
          [pageNumber]: contents,
        },
        totalResults: total,
        currentPage: pageNumber,
      };
    }
    case constants.GET_FINANCE_SUCCESS: {
      const { vehicle, pageNumber } = action.payload;
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [pageNumber]: replaceIfExists(
            state.searchResults[pageNumber] || [],
            vehicle,
          ),
        },
      };
    }
    case constants.GET_DEALER_SUCCESS: {
      return {
        ...state,
        dealer: action.payload,
      };
    }
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    default:
      return state;
  }
}

// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { actions as routerActions } from '../../actions/router';
import { Button } from '../../components/Global';

import media from '../../theme';

export type Props = {
  vehicle: Object,
  placeholdingImage: string,
  globalStyling: Object,
  onMoreInfoClick: number => void,
  pathByModule: string => any,
  moduleConfig: Object,
};

const ResultTileWrapper = styled.div`
  margin-bottom: 16px;
  border: ${({ border }) => border || '1px solid #d8d8d8'};
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour}`};
  ${({ column }) => column && 'flex-direction: column'};
`;

const ListTile = styled(ResultTileWrapper)`
  width: 100%
  display: none;
  ${media.min.large`
    display: flex;
  `};
`;

const GridTile = styled(ResultTileWrapper)`
  display: none;
  max-width: 300px;
  margin: 0 auto 16px;
  ${media.max.large`
    display: flex;
  `};
`;

const ContentColumn = styled.div`
  display: flex;
  flex: 1 0 ${({ basis }) => `${basis}px`};
`;

const Image = styled.img`
  height: auto;
  max-width: 300px;
  display: flex;

  ${media.max.large`
    max-width: 100%;
  `};
`;

const RightContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ImageFooter = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: -30px;
  opacity: 0.7;
  background: #000000;
  color: #ffffff;
`;

const Views = styled.span`
  padding-top: 2px;
`;

const ViewsContainer = styled.div`
  display: flex;
`;

const ViewsImage = styled.img`
  width: 1.5em;
  height: 1.25em;
  color: #ffffff;
`;

const ImageContent = ({ vehicle, showViews, viewsIconUrl }) => (
  <div>
    <Image src={vehicle.images[0]} />
    <ImageFooter>
      {showViews && (
        <ViewsContainer>
          <ViewsImage src={viewsIconUrl} />
          <Views>{vehicle.views}</Views>
        </ViewsContainer>
      )}
    </ImageFooter>
  </div>
);

const RightContent = ({ globalStyling, onMoreInfoClick }) => (
  <RightContentWrapper>
    <div>Future content</div>
    <Button
      onClick={onMoreInfoClick}
      buttonStyle={
        globalStyling.uiElements.primaryButton &&
        globalStyling.uiElements.primaryButton.buttonStyle
      }
      applyStyle="primary"
      text="Hardcoded more info"
      styleOverride={() => `
        max-width: 100%;
        margin: 10px;
        align-self: auto;`}
    />
  </RightContentWrapper>
);

const ListResultTile = ({
  vehicle,
  placeholdingImage,
  globalStyling,
  onMoreInfoClick,
  moduleConfig,
}: Props) => (
  <ListTile
    border={moduleConfig.border}
    backgroundColour={moduleConfig.backgroundColour}
  >
    <ContentColumn basis={100}>
      <ImageContent
        vehicle={vehicle}
        placeholdingImage={placeholdingImage}
        showViews={moduleConfig.showViews}
        viewsIconUrl={moduleConfig.viewsIconUrl}
      />
    </ContentColumn>
    <ContentColumn basis={145}>Center</ContentColumn>
    <ContentColumn basis={135}>
      <RightContent
        globalStyling={globalStyling}
        onMoreInfoClick={onMoreInfoClick}
      />
    </ContentColumn>
  </ListTile>
);

const GridResultTile = ({
  vehicle,
  placeholdingImage,
  globalStyling,
  onMoreInfoClick,
  moduleConfig,
}: Props) => (
  <GridTile
    border={moduleConfig.border}
    backgroundColour={moduleConfig.backgroundColour}
    column
  >
    <ImageContent
      vehicle={vehicle}
      placeholdingImage={placeholdingImage}
      showViews={moduleConfig.showViews}
    />
    <div>Center</div>
    <RightContent
      globalStyling={globalStyling}
      onMoreInfoClick={onMoreInfoClick}
    />
  </GridTile>
);

function ResultTile(props: Props) {
  return (
    <Fragment>
      <ListResultTile {...props} />
      <GridResultTile {...props} />
    </Fragment>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, { pathByModule, vehicle }: ownProps) => ({
  onMoreInfoClick: () =>
    vehicle.status !== 'sold' &&
    dispatch(routerActions.navigate(`${pathByModule('VDP')}/${vehicle.id}`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResultTile);

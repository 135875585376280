// @flow
import React from 'react';
import styled from 'styled-components';
import type { Theme } from '../theme';

type Option = { value: string, label: string };

type ThemeProps = {
  theme: Theme,
};

type Props = {
  name: string,
  options: Option[],
  onChange: Function,
  selectedValue?: string,
  placeholder?: string,
  thin?: boolean,
};

const StyledSelect = styled.select`
  background-color: ${({ theme }: { theme: Theme }) => theme.colours.primary03};
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary06};
  border: 0;
  outline-offset: 0;
  ${(props: Props) => (props.thin ? 'padding: 2px 4px;' : 'height: 30px;')} ${(
  props: Props,
) => !props.thin && 'line-height: 30px;'} outline: 1px inset ${(
  props: ThemeProps,
) => props.theme.colours.secondary03};
  font-size: ${(props: ThemeProps) => props.theme.fontSizeFormControls};
  &:focus {
    border-color: ${(props: ThemeProps) => props.theme.colours.connectAutoBlue};
    outline: 0;
  }
  &:focus {
    outline: 1px inset ${(props: ThemeProps) =>
      props.theme.colours.secondary03};
  }
`;

export default function Select(props: Props) {
  const options = props.options.map(option => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <StyledSelect
      defaultValue={props.selectedValue}
      name={props.name}
      onChange={props.onChange}
      {...props}
    >
      <option value="">{props.placeholder}</option>
      {options}
    </StyledSelect>
  );
}

Select.defaultProps = {
  placeholder: '',
  selectedValue: '',
  thin: false,
};

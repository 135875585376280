export const constants = {
  navigate: 'ROUTER_NAVIGATE',
  navigateWithPayload: 'ROUTER_NAVIGATE_WITH_PAYLOAD',
  navigateWithPayloadSuccess: 'ROUTER_NAVIGATE_WITH_PAYLOAD_SUCCESS',
  navigateWithQuery: 'ROUTER_NAVIGATE_WITH_QUERY',
  replace: 'ROUTER_REPLACE',
  navigateSuccess: 'ROUTER_NAVIGATE_SUCCESS',
  navigateWithFilters: 'ROUTER_NAVIGATE_WITH_FILTERS',
  historyCreated: 'HISTORY_CREATED',
};

export const actions = {
  navigate: path => ({
    type: constants.navigate,
    payload: path,
  }),
  navigateWithPayload: (path, data) => ({
    type: constants.navigateWithPayload,
    payload: {
      path,
      data,
    },
  }),
  navigateWithQuery: (path, queryParams) => ({
    type: constants.navigateWithQuery,
    payload: {
      path,
      queryParams,
    },
  }),
  navigateWithPayloadSuccess: payload => ({
    type: constants.navigateWithPayloadSuccess,
    payload,
  }),
  replace: path => ({
    type: constants.replace,
    payload: path,
  }),
  navigateSuccess: path => ({
    type: constants.navigateSuccess,
    payload: path,
  }),
  navigateWithFilters: (path, disableScroll) => ({
    type: constants.navigateWithFilters,
    payload: {
      path,
      disableScroll,
    },
  }),
  historyCreated: history => ({
    type: constants.historyCreated,
    payload: history,
  }),
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import ColourPicker from './ColourPicker/ColourPicker';
import ColourSelector from './ColourSelector/ColourSelector';

const Container = styled.div`
  padding: 25px;
  width: 790px;
`;

const Heading = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #474747;
`;

const SubHeading = styled.div`
  font-size: 13px;
  color: #757575;
`;

const ColourPickerRow = styled.div`
  display: flex;
  margin-top: 10px;
  position: relative;
`;

const ColouredActionLink = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: ${({ colour }) => colour};
  margin-top: 10px;
  ${({ add }) => (add ? '' : 'position: absolute; left: 180px;')};
`;

const LineBreak = styled.div`
  margin: 25px 0;
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
`;

const SectionBreak = styled.div`
  margin-bottom: 25px;
`;

const DeletionBox = styled.div`
  height: 40px;
  border: 1px solid #ff4949;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
`;

const Button = styled.div`
  font-size: 10px;
  font-weight: Bold;
  height: 25px;
  min-width: 65px;
  box-sizing: border-box;
  border: 1px solid #474747;
  color: #474747;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;

  ${({ danger }) =>
    danger &&
    'border: 1px solid #FF4949; background-color: #FF4949; color: #ffffff'};
`;

type Props = {
  brandColours: {
    availableColours: Object[],
    primaryBrandColour: Object,
    secondaryBrandColour: Object,
    pageBackgroundColour: Object,
  },
  addBrandColour: () => void,
  removeBrandColour: (hex: string) => void,
  saveBrandColour: (hex: string) => void,
  selectBrandColour: (key: string, value: string) => void,
};

type State = {
  colourForDeletion: string,
};

export default class TemplateBrandColours extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      colourForDeletion: '',
    };
  }

  onDeleteClick = (colourForDeletion: string) => {
    this.setState(() => ({ colourForDeletion }));
  };

  onCancelClick = () => {
    this.setState(() => ({ colourForDeletion: '' }));
  };

  onDeleteConfirmClick = () => {
    const { colourForDeletion } = this.state;
    this.setState(() => ({ colourForDeletion: '' }));
    this.props.removeBrandColour(colourForDeletion);
  };

  props: Props;

  render = () => {
    const {
      brandColours: {
        availableColours,
        primaryBrandColour,
        secondaryBrandColour,
        pageBackgroundColour,
      },
      addBrandColour,
      saveBrandColour,
      selectBrandColour,
    } = this.props;
    const addingIsDisabled =
      availableColours.length === 10 ||
      availableColours[availableColours.length - 1] === '' ||
      availableColours.length === 0;

    return (
      <Container>
        <Heading>Brand colours</Heading>
        <SubHeading>
          Available for selection in the template options and the AVL builder.
        </SubHeading>
        {availableColours.length === 0 && (
          <ColourPickerRow>
            <ColourPicker
              availableColours={availableColours}
              saveBrandColour={saveBrandColour}
              hex={null}
            />
          </ColourPickerRow>
        )}
        {availableColours.map(colour =>
          colour.value === this.state.colourForDeletion ? (
            <ColourPickerRow key={colour.label || 'temp'}>
              <DeletionBox>
                This colour may already be in use.
                <b style={{ marginLeft: '5px' }}>Are you sure?</b>
                <Button
                  data-qa-hook="cancel-delete-button"
                  onClick={this.onCancelClick}
                >
                  CANCEL
                </Button>
                <Button
                  data-qa-hook="confirm-delete-button"
                  onClick={this.onDeleteConfirmClick}
                  danger
                >
                  DELETE
                </Button>
              </DeletionBox>
            </ColourPickerRow>
          ) : (
            <ColourPickerRow key={colour.label || 'temp'}>
              <ColourPicker
                availableColours={availableColours}
                saveBrandColour={saveBrandColour}
                hex={colour.label}
                disabled={colour !== ''}
              />
              <ColouredActionLink
                data-qa-hook="colour-picker-delete-button"
                onClick={() => this.onDeleteClick(colour.value)}
                colour="#D0021B"
              >
                delete
              </ColouredActionLink>
            </ColourPickerRow>
          ),
        )}
        <ColouredActionLink
          data-qa-hook="add-another-button"
          onClick={() => {
            if (addingIsDisabled) {
              return false;
            }
            return addBrandColour();
          }}
          colour={addingIsDisabled ? '#757575' : '#0065DB'}
          disabled={addingIsDisabled}
          add
        >
          add another+
        </ColouredActionLink>
        <LineBreak />
        <Heading>Brand primary colour</Heading>
        <SubHeading>
          Controls the main colour of the template. Applies to primary buttons,
          form elements and icons.
        </SubHeading>
        <ColourPickerRow>
          <ColourSelector
            qaHook="brand-primary"
            brandColours={availableColours}
            selected={primaryBrandColour}
            selectBrandColour={selectBrandColour}
            forKey="primaryBrandColour"
          />
        </ColourPickerRow>
        <SectionBreak />
        <Heading>Brand secondary colour</Heading>
        <SubHeading>
          Applies to secondary elements such as secondary buttons and for
          background colour on header and footer.
        </SubHeading>
        <ColourPickerRow>
          <ColourSelector
            qaHook="brand-secondary"
            brandColours={availableColours}
            selected={secondaryBrandColour}
            selectBrandColour={selectBrandColour}
            forKey="secondaryBrandColour"
          />
        </ColourPickerRow>
        <SectionBreak />
        <Heading>Page background colour</Heading>
        <SubHeading>Background colour on pages across the website.</SubHeading>
        <ColourPickerRow>
          <ColourSelector
            qaHook="page-background"
            brandColours={availableColours}
            selected={pageBackgroundColour}
            selectBrandColour={selectBrandColour}
            forKey="pageBackgroundColour"
          />
        </ColourPickerRow>
      </Container>
    );
  };
}

// @flow

import React from 'react';
import { TextEditor } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const defaultValue = {
  disabled: false,
  editing: false,
};

export default function EditText({
  id,
  value = defaultValue,
  onChange,
  ...props
}: FieldProps) {
  return <TextEditor name={id} text={value} onChange={onChange} {...props} />;
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Budget({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 23">
        <path d="M6.17 15a3.001 3.001 0 0 1 5.66 0H25v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm10-9a3.001 3.001 0 0 1 5.66 0H25v2h-3.17a3.001 3.001 0 0 1-5.66 0H2V6h14.17z" />
      </svg>
    </IconWrapper>
  );
}

Budget.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

export default {
  translations: {
    signInUsernameLabel: 'Username',
    signInPasswordLabel: 'Password',
    signInHeader: 'sign in',
    signInForgotPassword: 'forgot password',
    signInLoginButton: 'login',
    signInFailure: 'That email / password combination is not valid.',
    signInNoAccount: "Don't have an account?",
    signInRegister: 'register',
  },
};

// @flow
import React from 'react';
import styled from 'styled-components';
import Chunky from '../JaguarIcons/Arrows/Chunky';
import media from '../../theme';
import { HeadingTwo } from '../Global';

type Props = {
  title: string,
};

type ParentProps = {
  collapsed: boolean,
};

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: #444;
  line-height: 55px;
  height: 55px;
  border-top: 1px solid #d8d8d8;
`;

const Chevron = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  top: 5px;
  width: 10px;
  height: 10px;
  ${media.min.large`
      transform: ${({ collapsed }) =>
        collapsed ? 'rotate(0deg)' : 'rotate(90deg)'};
      top: ${({ collapsed }) => (collapsed ? '5px' : '20px')};
      right: ${({ collapsed }) => (collapsed ? '20px' : '0px')};
    `};
`;

const renderFilterTitle = (props: Props) => ({ collapsed }: ParentProps) => (
  <TitleContainer collapsed={collapsed}>
    <HeadingTwo
      styleOverride={() => `
        font-size: 16px;
      `}
    >
      {props.title}
    </HeadingTwo>
    <Chevron collapsed={collapsed}>
      <Chunky colour="#7E7E7E" />
    </Chevron>
  </TitleContainer>
);

export default renderFilterTitle;

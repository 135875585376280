import { useState, useEffect } from 'react';
import Hammer from 'hammerjs';

import createModal from './createModal';

export default function useExterior(player, spinner, { url, viewPoints }) {
  const [loaded, setLoaded] = useState(false);
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  canvas.id = 'exterior-canvas';

  const aspectRatio = 1 / (9 / 16);
  let target;
  let videoStep;
  const scale = 1;
  let direction = 1;
  let increment = 0.05;
  let playing;
  let pause;
  let duration;
  let updateVelocity = () => 0.05;

  const slowDown = () => {
    const threshold = 0.01;
    const dVelocity = 0.5;

    if (Math.abs(increment) <= threshold) {
      playing = clearInterval(playing);
      updateVelocity = () => 0;
      return 0;
    }

    return increment * dVelocity;
  };

  const doPause = () => {
    updateVelocity = slowDown;
  };

  const spin = velocity => {
    const spinIncrement = Math.abs(velocity) * videoStep * 50;
    return () => Math.min(spinIncrement, 1);
  };

  const getViewpoints = now => {
    return viewPoints.filter(({ start, end }) => start < now && now < end);
  };

  const getCoordinates = ({ start, end, points }, now) => {
    const offset = now - start;
    const videoDuration = end - start;
    const interval = videoDuration / (points.length - 1);
    const index = Math.floor(offset / interval);
    const { x: x1, y: y1 } = points[index];
    const { x: x2, y: y2 } = points[index + 1] || { x: x1, y: y1 };
    const delta = (offset % interval) / interval;
    return { x: x1 + (x2 - x1) * delta, y: y1 + (y2 - y1) * delta };
  };

  const drawViewpoint = (xp, yp) => {
    const x = xp * canvas.width;
    const y = yp * canvas.height;
    target.beginPath();
    target.strokeStyle = '#ff5d6f';
    target.arc(x, y, 15 * scale, 0, 2 * Math.PI);
    target.stroke();
    target.beginPath();
    target.fillStyle = '#ff5d6f';
    target.arc(x, y, 12 * scale, 0, 2 * Math.PI);
    target.fill();
  };

  const getClicked = (vps, { x: clickX, y: clickY }) => {
    return vps.find(vp => {
      const { x, y } = getCoordinates(vp, video.currentTime);
      const offX = Math.abs(clickX - x * canvas.width);
      const offY = Math.abs(clickY - y * canvas.height);
      return offX <= 15 * scale && offY <= 15 * scale;
    });
  };

  const drawFrame = () => {
    target.drawImage(video, 0, 0, canvas.width, canvas.height);
    const vps = getViewpoints(video.currentTime);
    vps
      .map(vp => getCoordinates(vp, video.currentTime))
      .map(({ x, y }) => drawViewpoint(x * scale, y * scale));
  };

  const maintainSpeed = () => {
    const currentTime = video.currentTime + increment * direction;
    video.currentTime = (currentTime + video.duration) % video.duration;
  };

  const updateFrame = () => {
    drawFrame();
    maintainSpeed();
    increment = updateVelocity();
  };

  const createElements = () => {
    video.src = url;
    video.loop = true;
    video.muted = true;
    video.autoplay = true;
    video.height = player.current.clientWidth / aspectRatio;
    video.width = player.current.clientWidth;
    video.onloadeddata = () => {
      video.pause();
      duration = video.duration;
      video.currentTime = video.duration;
      videoStep = duration / (canvas.width * 0.5);
      updateVelocity = () => videoStep * 0.0001; // Change this value to 1.5 to have the 360view continue autoplaying on load.
      setLoaded(true);
      player.current.classList.add('visible');
      document.getElementById('360-player-spinner').style.display = 'none';
    };

    canvas.width = player.current.clientWidth;
    canvas.height = player.current.clientWidth / aspectRatio;
    canvas.addEventListener('click', event => {
      const vps = getViewpoints(video.currentTime);
      const vp = getClicked(vps, {
        x: event.offsetX,
        y: event.offsetY,
      });

      if (vp) {
        createModal(vp, canvas);
      }
    });

    player.current.appendChild(video);
    player.current.appendChild(canvas);

    target = canvas.getContext('2d');
  };

  const initialiseTouchGestures = () => {
    const hm = new Hammer.Manager(player.current);
    hm.add(new Hammer.Pan({ threshold: 20 }));

    hm.on('panstart', () => {
      if (!playing) {
        playing = setInterval(updateFrame, 100);
      }
    });

    hm.on('panmove', () => {
      pause = pause && clearTimeout(pause);
      pause = setTimeout(doPause, 150);
    });

    hm.on('panend', () => {
      updateVelocity = slowDown;
    });

    hm.on('panleft', event => {
      direction = 1;
      updateVelocity = spin(event.velocity);
    });

    hm.on('panright', event => {
      direction = -1;
      updateVelocity = spin(event.velocity);
    });
  };

  const resizePlayer = () => {
    video.height = player.current.clientWidth / aspectRatio;
    video.width = player.current.clientWidth;
    canvas.width = player.current.clientWidth;
    canvas.height = player.current.clientWidth / aspectRatio;
  };

  const initialisePlayer = () => {
    initialiseTouchGestures();
    createElements();
  };

  useEffect(() => {
    if (!loaded) {
      window.addEventListener('resize', resizePlayer);
      initialisePlayer();
    }
  }, [loaded]);
}

// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';

const FiftyFiftyContainer = styled.div.withConfig({
  displayName: 'FiftyFifty',
})`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  ${media.min.large`
    flex-direction: row;
  `};
`;
const FiftyFiftyContentWrapper = styled.div`
  width: 100%;
  ${media.min.large`
    width: 50%;
    height: 450px;
    background-color: #f0f0f0;
  `};
`;
const FiftyFiftyContent = styled.div`
  padding: 20px 0;
  ${media.min.large`
    padding: 150px 150px 150px 60px;
  `};
`;
const FiftyFiftyContentHeader = styled.div`
  display: none;
  ${media.min.large`
    display: block;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 30px;
  `};
`;
const FiftyFiftyMainContent = styled.div`
  display: block;
  color: #343434;
  ${media.min.large`
    font-size: 15px;
    color: #444444;
    line-height: 20px;
  `};
`;
const FiftyFiftyImage = styled.img`
  width: 100%;
  ${media.min.large`
    width: 50%;
    height: 450px;
    object-fit: cover;
  `};
`;

export default function FiftyFifty(props) {
  return (
    <FiftyFiftyContainer>
      <FiftyFiftyContentWrapper>
        <FiftyFiftyContent>
          <FiftyFiftyContentHeader>{props.title}</FiftyFiftyContentHeader>
          <FiftyFiftyMainContent>{props.content}</FiftyFiftyMainContent>
        </FiftyFiftyContent>
      </FiftyFiftyContentWrapper>
      <FiftyFiftyImage src={props.image} />
    </FiftyFiftyContainer>
  );
}

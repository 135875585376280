// @flow
import { constants, type Action } from '../actions/context';
import { type ContextState } from '../types/context';

const defaultState = {
  action: 'none',
};

/*
 * When updating the active context, each action will:
 * - clear more specific context
 * e.g. setting the page will clear the module, and
 * - preserve more general context
 * e.g. setting the page will preserve the site
 */

export default function reducer(
  state: ContextState = defaultState,
  action: Action,
) {
  switch (action.type) {
    case constants.SetGlobalContext:
      return {
        ...state,
        global: action.payload,
      };
    case constants.SetSiteContext:
      return {
        site: action.payload,
        page: null,
        linkedModuleInstanceId: null,
        slice: null,
        breakout: null,
      };
    case constants.SetPageContext:
      return {
        ...state,
        page: action.payload,
        linkedModuleInstanceId: null,
        slice: null,
        showPageSettings: false,
        pod: null,
        band: null,
      };
    case constants.SetPodContext: {
      return {
        ...state,
        pod: action.payload,
        linkedModuleInstanceId: null,
        slice: null,
        breakout: null,
        global: null,
      };
    }
    case constants.SetBandContext: {
      return {
        ...state,
        module: null,
        band: action.payload,
        linkedModuleInstanceId: null,
        slice: null,
        breakout: null,
      };
    }
    case constants.SetLinkedModuleContext: {
      const { linkedModule, instanceId } = action.payload;
      return {
        ...state,
        linkedModule,
        linkedModuleInstanceId: instanceId,
        slice: null,
        breakout: null,
      };
    }
    case constants.SetSliceContext:
      return {
        ...state,
        slice: action.payload,
      };
    case constants.SetBreakoutContext: {
      return {
        ...state,
        breakout: action.payload,
        previousBreakout: null,
      };
    }
    case constants.SetPreviousBreakoutContext: {
      return {
        ...state,
        previousBreakout: action.payload,
      };
    }
    case constants.SetShowPageSettings:
      return {
        ...state,
        showPageSettings: action.payload,
      };
    case constants.SetAddBand:
      return {
        ...state,
        linkedModuleInstanceId: null,
        slice: null,
        breakout: null,
        addPod: null,
        addBand: action.payload,
      };
    case constants.SetAddPod: {
      return {
        ...state,
        linkedModuleInstanceId: null,
        slice: null,
        breakout: null,
        addPod: action.payload,
        addBand: null,
      };
    }
    case constants.ClearContext: {
      return {
        ...state,
        linkedModuleInstanceId: null,
        slice: null,
        breakout: null,
        global: null,
        addPod: null,
        addBand: null,
        showPageSettings: false,
        pod: null,
        band: null,
        activeItem: null,
        action: 'none',
        previousBreakout: null,
      };
    }
    case constants.ClearAction: {
      return {
        ...state,
        activeItem: null,
        action: 'none',
      };
    }
    case constants.SetDraggingContext:
      return {
        ...state,
        activeItem: action.payload,
        action: action.payload ? 'dragging-pod' : 'none',
      };
    case constants.SetClickedEditorPodContext:
      return {
        ...state,
        dragging: null,
        activeItem: action.payload,
        action: action.payload ? 'pod-type-selected' : 'none',
      };
    case constants.SetClickedPreviewPodContext:
      return {
        ...state,
        activeItem: action.payload,
        action: action.payload ? 'pod-selected' : 'none',
      };
    default:
      return state;
  }
}

export const constants = {
  getAllDealers: 'GET_ALL_DEALERS',
  getAllDealersSuccess: 'GET_ALL_DEALERS_SUCCESS',
  getAllDealersFailure: 'GET_ALL_DEALERS_FAILURE',
  getClosestDealers: 'GET_CLOSEST_DEALERS',
  getClosestDealersSuccess: 'GET_CLOSEST_DEALERS_SUCCESS',
  getClosestDealersFailure: 'GET_CLOSEST_DEALERS_FAILURE',
};

export const actions = {
  getAllDealers: () => ({ type: constants.getAllDealers }),
  getAllDealersSuccess: payload => ({
    type: constants.getAllDealersSuccess,
    payload,
  }),
  getAllDealersFailure: payload => ({
    type: constants.getAllDealersFailure,
    payload,
  }),
  getClosestDealers: payload => ({
    type: constants.getClosestDealers,
    payload,
  }),
  getClosestDealersSuccess: payload => ({
    type: constants.getClosestDealersSuccess,
    payload,
  }),
  getClosestDealersFailure: payload => ({
    type: constants.getClosestDealersFailure,
    payload,
  }),
};

export const dealerPodStatus = {
  default: 'Default',
  fetching: 'Fetching',
  fetchingSuccess: 'FetchingSuccess',
  fetchingFailure: 'FetchingFailure',
};
const initialState = {
  error: null,
  dealers: [],
  centerPoint: null,
  status: dealerPodStatus.default,
};
export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.getAllDealers:
    case constants.getClosestDealers:
      return {
        ...state,
        status: dealerPodStatus.fetching,
      };
    case constants.getAllDealersSuccess:
    case constants.getClosestDealersSuccess:
      return {
        ...state,
        dealers: action.payload.dealers,
        centerPoint: action.payload.centerPoint,
        status: dealerPodStatus.fetchingSuccess,
      };
    case constants.getAllDealersFailure:
    case constants.getClosestDealersFailure:
      return {
        ...state,
        error: action.payload,
        status: dealerPodStatus.fetchingFailure,
      };
    default:
      return state;
  }
}

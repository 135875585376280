// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { HeadingTwo } from './Global';
import SocialMediaShare from './SocialMediaShare';
import ModalOverlay from './ModalOverlay';

const ModalTitleWrapper = styled.div.withConfig({
  displayName: 'ModalTitleWrapper',
})`
  display: flex;
  background-color: #f0f0f0;
  width: 100%;
  align-items: center;
`;

type Props = {
  children: Node,
  socialShare: Object,
  config: Object,
};

export default function SocialSharePopup({
  children,
  socialShare,
  config: { translations, closeIconUrl },
}: Props) {
  return (
    <ModalOverlay
      component={children}
      modalWidth={500}
      closeIconUrl={closeIconUrl}
    >
      <Fragment>
        <ModalTitleWrapper>
          <HeadingTwo
            data-qa-hook="Popup-Title"
            styleOverride={() => `
            display: flex;
            justify-content: center;
            width: 80%;
            margin-left: 10%;
            padding: 20px 0;
            text-transform: uppercase;
          `}
          >
            {translations.shareThisPage}
          </HeadingTwo>
        </ModalTitleWrapper>
        <SocialMediaShare
          shareUrl={window.location.href}
          socialShare={socialShare}
        />
      </Fragment>
    </ModalOverlay>
  );
}

import editor from './editor';

export default {
  id: 'Form',
  name: 'Form',
  description: 'Create a form',
  defaultSlice: 'Content',
  defaultConfig: {},
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  requirements: {
    columns: [1],
    pageType: 'Form',
  },
};

// @flow
import React, { useEffect } from 'react';
import { Dropdown, FieldLabel } from 'cms-ui';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../state/actions/blogs';
import { type FieldProps } from '../models/fields';

export default ({
  name,
  values: { id: value, button },
  ids: { id, button: buttonId },
  onChange,
}: FieldProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.loadBlogs());
  }, [dispatch]);

  const blogs = useSelector(state => state.blogs.blogs.filter(b => b.visible));
  const options = blogs
    ? blogs.map(b => ({ label: b.config.title || b.id, value: b.id }))
    : [];

  const input = {
    value,
    onChange: e => {
      onChange(id, (e || {}).value);
      onChange(buttonId, { ...button, url: `/blogs/${(e || {}).value}` });
    },
  };

  return (
    <FieldLabel label={name}>
      <Dropdown name={name} input={input} options={options} />
    </FieldLabel>
  );
};

// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import styled from 'styled-components';

import { InputField } from './FormFields';
import EmailVerification from './EmailVerification';
import Link from './Link';
import media from '../theme';
import { validEmail } from '../helpers/validation';
import { HeadingOne, Button } from './Global';

const ResetPasswordContent = styled.div`
  padding: 50px 35%;
  ${media.max.large`
    padding: 50px 5%;
  `};
`;

const ResetPasswordInformation = styled.div`
  text-align: center;
  font-size: 16px;
  padding-bottom: 50px;
  width: 100%;
`;

type Props = {
  resetRequestStatus: 'NONE' | 'SUCCESS' | 'FAILURE',
  submitResetRequest: string => void,
  goToLogin: () => void,
  translations: Object,
  globalStyling: Object,
};

type State = {
  email: string,
  emailValidationErrors: string[],
};

export default class RequestResetPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      emailValidationErrors: [],
    };
  }

  onInputChange = (formKey: string, value: any) => {
    this.setState(() => ({ email: value }));
    const validationErrors = this.validateEmail();
    this.setState(() => ({
      emailValidationErrors: [...validationErrors],
    }));
  };

  onFormSubmit = (event: Event) => {
    const validationErrors = this.validateEmail();
    this.setState(() => ({
      emailValidationErrors: [...validationErrors],
    }));

    if (validationErrors.length === 0) {
      const { submitResetRequest } = this.props;
      const { email } = this.state;
      submitResetRequest(email);
    }
    event.preventDefault();
  };

  validateEmail = () =>
    validEmail(this.state.email, this.props.translations, true) || [];

  render = () => {
    const {
      resetRequestStatus,
      goToLogin,
      translations,
      globalStyling,
    } = this.props;
    const { email } = this.state;
    return resetRequestStatus === 'SUCCESS' ? (
      <EmailVerification
        email={email}
        translations={translations}
        globalStyling={globalStyling}
      />
    ) : (
      <div>
        <HeadingOne
          styleOverride={() => `
            height: 75px;
            border-bottom: 1px solid #d8d8d8;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            text-transform: uppercase;
          `}
        >
          {translations.forgotPasswordHeader}
        </HeadingOne>
        <ResetPasswordContent>
          <ResetPasswordInformation>
            {translations.resetPasswordInfoText}
          </ResetPasswordInformation>
          <form onSubmit={this.onFormSubmit}>
            <InputField
              type="email"
              label={translations.emailLabel}
              value={email}
              onChange={this.onInputChange}
              keyValue="email"
              error={
                this.state.emailValidationErrors.length > 0 &&
                this.state.emailValidationErrors[0]
              }
            />
            <Button
              styleOverride={() => `
                width: 100%;
              `}
              type="submit"
              text={translations.resetButtonText}
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
            />
            <Link
              theme={{
                linkColour:
                  globalStyling.colours.primaryBrandColour &&
                  globalStyling.colours.primaryBrandColour.value,
              }}
              onClick={goToLogin}
              fullWidth
            >
              {translations.backToLoginLinkText}
            </Link>
          </form>
        </ResetPasswordContent>
      </div>
    );
  };
}

// @flow
import React from 'react';
import {
  SeparatedListContainer,
  SeparatedList,
  SeparatedListItem,
} from './SeparatedList';

type Props = {
  features: any[],
  columns: number,
};

function VdpHighlightedFeatures({ features, columns }: Props) {
  return (
    <SeparatedListContainer style={{ width: '100%' }}>
      <SeparatedList style={{ columnCount: columns, columnGap: '40px' }}>
        {features.map(f => (
          <SeparatedListItem key={f} style={{ lineHeight: '22px' }} flex>
            <div style={{ paddingRight: '5px' }}>—</div>
            <div>{f}</div>
          </SeparatedListItem>
        ))}
      </SeparatedList>
    </SeparatedListContainer>
  );
}

export default VdpHighlightedFeatures;

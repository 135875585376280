export const translateFromTemplate = (
  translationsKey,
  placeholdersAndValues,
  translations,
) => {
  const template = translations[translationsKey];
  const replaceValue = (t, key) =>
    t.replace(`{${key}}`, placeholdersAndValues[key]);
  return template
    ? Object.keys(placeholdersAndValues).reduce(replaceValue, template)
    : '';
};

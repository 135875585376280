// @flow

export const localiseCurrency = (
  price: number,
  locale: string,
  currencyType: string,
  fractionDigits: number,
) =>
  price !== null &&
  price !== undefined &&
  price.toLocaleString(locale.replace('_', '-'), {
    style: 'currency',
    currency: currencyType || 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const localiseNumber = (
  number: number,
  locale: string,
  fractionDigits?: number,
) =>
  number !== null &&
  number !== undefined &&
  number.toLocaleString(locale.replace('_', '-'), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

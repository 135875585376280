// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function RoadTest({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 40 40">
        <path d="M35.342 23.841c-.178 0-.355.024-.527.07l-2.156.66-2.65-7.04a5.71 5.71 0 0 0 .627-2.629 5.792 5.792 0 0 0-.868-3.063l-4.603-7.455a6.233 6.233 0 0 0-7.193-2.59L4.183 6.264A5.986 5.986 0 0 0 0 11.922c0 .331.03.662.089.987l1.53 8.94a6.025 6.025 0 0 0 4.661 4.804l2.043 5.442-7.252 2.25c-.617.192-1.066 1.124-1.066 1.79C.005 36.96.69 38 1.535 38h33.694c.844 0 1.766-1.41 1.766-2.235V25.331a1.623 1.623 0 0 0-1.653-1.49zm-30.71-2.486l-1.529-8.94a3.325 3.325 0 0 1-.04-.493A2.995 2.995 0 0 1 5.16 9.091l13.78-4.47c.311-.101.638-.153.966-.153a3.089 3.089 0 0 1 2.634 1.45l4.593 7.455c.284.46.433.99.43 1.53a2.984 2.984 0 0 1-2.013 2.801l-16.848 5.96c-.336.117-.69.177-1.045.177a3.043 3.043 0 0 1-3.025-2.486zm7.746 4.178l12.447-4.4 1.958 5.249-12.624 3.907-1.781-4.756z" />
      </svg>
    </IconWrapper>
  );
}

RoadTest.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

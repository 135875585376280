import { put, takeLatest } from 'redux-saga/effects';
import { actions } from './reducer';
import { constants as routerConstants } from '../../actions/router';

function* navigate() {
  yield put(actions.clearError());
}

export default function* errorsSaga() {
  yield takeLatest(routerConstants.navigateSuccess, navigate);
}

// @flow
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import {
  brand,
  context,
  editor,
  auth,
  site,
  siteBuilder,
  router,
  page,
  pageTemplates,
  translation,
  users,
  preview,
  finance,
  socialShare,
  config,
  blogs,
  searchUtilities,
  dealerLocator,
  notification,
} from './reducers';

import type { SiteBuilderState } from './types/siteBuilder';
import { type ContextState } from './types/context';
import type { State as SiteState } from './reducers/site';
import type { State as RouterState } from './reducers/router';
import type { State as EditorState } from './reducers/editor';
import type { State as BrandState } from './reducers/brand';
import type { State as AuthState } from './reducers/auth';
import type { State as TranslationState } from './types/translation';
import type { State as UsersState } from './types/users';
import type { State as FinanceState } from './types/finance';
import type { State as SocialShareState } from './types/socialShare';

export type State = {
  router: RouterState,
  form: formReducer,
  context: ContextState,
  brand: BrandState,
  siteBuilder: SiteBuilderState,
  editor: EditorState,
  site: SiteState,
  auth: AuthState,
  translation: TranslationState,
  users: UsersState,
  finance: FinanceState,
  socialShare: SocialShareState,
  preview: any,
  page: Object,
  success: Object,
};

const defaultSharedState = {
  filters: {
    selectedFilters: {
      minPriceRange: 0,
      maxPriceRange: 10000,
      exteriorColours: [],
      subregions: [],
    },
    availableFilterValues: {
      maxDistance: ['nationwide', '10km', '20km', '30km', '40km'],
      minPriceRange: 0,
      maxPriceRange: 20000,
      bodystyle: ['coupe', 'saloon', 'convertible'],
      model: ['f-pace', 'i-pace', 'e-pace'],
      variant: ['prestuge', 'r-sport', 'portfolio'],
      engine: ['2.0l', '2.2l', '3.0l'],
      transmission: ['automatic', 'manual'],
      exteriorColours: [
        'red',
        'green',
        'blue',
        'yellow',
        'orange',
        'white',
        'black',
        'purple',
        'pink',
      ],
      interiorShades: ['black', 'white'],
      maxOdometer: 50000,
      maxAge: 5,
      features: ['sat nav', 'bluetooth', 'aircon'],
    },
  },
  errors: {},
  sessionPreferences: {
    location: {
      latitude: 0,
      longitude: 0,
    },
  },
};

export default combineReducers({
  router,
  form: formReducer,
  context,
  brand,
  siteBuilder,
  editor,
  site,
  auth,
  page,
  pageTemplates,
  translation,
  users,
  finance,
  socialShare,
  blogs,
  searchUtilities,
  pages: preview,
  config,
  notification,
  shared: () => defaultSharedState,
  dealerLocator,
});

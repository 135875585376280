// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { screenSizeOptions } from '../../../../theme';
import { localiseCurrency } from '../../../../shared/localisation/numbers';
import { getLocale } from '../../../../shared/selectors/settings';
import { vehiclePropFormatter } from '../../../../helpers/vehicle';
import { Button } from '../../../components/Global';
import { useWindowDimensions } from '../../../../shared/WindowDimensionsProvider';

import icons from './images';

const {
  eyeWhiteImg,
  imagesIcon,
  mapPinImg,
  approvedLogoImg,
  compareIconImgOff,
  shortlistIconImgOff,
  compareIconImgOn,
  shortlistIconImgOn,
  registration,
  odometer,
  modelyear,
  exterior,
  interior,
  transmission,
} = icons;

const highlightIcons = {
  registration,
  odometer,
  modelyear,
  exterior,
  interior,
  transmission,
};

const Image = styled.div`
  position: relative;
  width: 100%;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: ${props => props.imageHeight || 200}px;
  cursor: pointer;
`;

const ImageMedium = styled(Image)`
  height: 400px;
`;

const ImageExtraLarge = styled(Image)`
  flex: 1 0 35%;
`;

const ViewsImages = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 25px;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  color: #ffffff;
`;

const ViewsCount = styled.div`
  display: flex;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 20px;
  width: 50%;
  padding-left: 35px;
  align-items: center;
`;

const ImagesCount = styled.div`
  display: flex;
  justify-content: flex-end;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 20px;
  padding-right: 35px;
  width: ${({ showViews }) => (showViews ? '50%' : '100%')};
  height: 25px;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  flex-direction: column;
  padding: 8px;
`;

const DetailsWrapperExtraLarge = styled(DetailsWrapper)`
  flex: 1 1 50%;
`;

const TitleWrapper = styled.div`
  flex-direction: column;
  padding: 8px;
  min-height: 80px;
`;

const TitleWrapperLarge = styled(TitleWrapper)`
  min-height: 100px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(31, 31, 31);
  margin-top: 0;
  padding-top: 0;
`;

const Description = styled.div`
  font-size: 14px;
`;

const HighlightsWrapper = styled.div`
  flex: 2 0;
  flex-wrap: wrap;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
`;

const Highlight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  font-size: 14px;
  padding: 8px 0 8px 40px;
  background-image: url(${props => props.src});
  background-position: 4px center;
  background-repeat: no-repeat;
  background-size: 30px;
`;

const HighlightLabel = styled.div``;
const HighlightValue = styled.div`
  font-weight: bold;
`;

const DistanceWrapper = styled.div`
  height: 40px;
  align-items: center;
  padding: 8px;
`;

const MapPinIcon = styled.div`
  background-image: url(${props => props.src});
  background-position: 4px center;
  background-repeat: no-repeat;
  background-size: 15px;
  width: 25px;
  height: 30px;
`;

const Price = styled.h2`
  flex-direction: column;
  font-size: 24px;
  width: 50%;
  font-family: RobotoBold;
  color: rgb(31, 31, 31);
  margin-top: 0;
  padding-top: 0;
`;

const PriceLabel = styled.p`
  font-size: 14px;
  color: #cccccc;
  margin: 0;
  padding: 0;
`;

const PriceLogo = styled.div`
  padding: 8px;
  justify-content: space-between;
`;

const ApprovedLogo = styled.img`
  height: auto;
  width: 40px;
`;

const CTALabel = styled.div`
  display: none;
`;

const CTA = styled.div`
  flex: 1 1;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
`;

const CompareShortlist = styled.div`
  display: flex;
  flex: 1 1 25%;

  > img {
    margin: 0 3px 0;
  }
`;

const PriceLogoExtraLarge = styled(PriceLogo)`
  flex-direction: column;
  flex: 1 0 250px;
  border-bottom: none;
  padding: 8px;

  button {
    width: 100%;
  }

  ${Price} {
    font-size: 24px;
    height: 45px;
    padding-left: 8px;
  }

  ${CTA} {
    border-top: none;
    flex-direction: column;

    ${CTALabel} {
      display: block;
    }
  }

  ${CompareShortlist} {
    width: 100%;
    flex: 0 1 30px;
    align-items: center;
    margin-bottom: 8px;
  }
`;

const CompareLink = styled.img`
  width: 40px;
  height: 40px;
`;

const FavouriteLink = styled.img`
  width: 40px;
  height: 40px;
`;

const Wrapper = styled.div`
  color: #666;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 10px);
  margin: 5px;

  div {
    display: flex;
  }

  ${CTALabel} {
    display: none;
  }
`;

const WrapperMedium = styled(Wrapper)`
  width: calc(50% - 10px);
`;

const WrapperLarge = styled(Wrapper)`
  width: calc(33.3333% - 10px);
`;

const WrapperExtraLarge = styled(Wrapper)`
  min-height: 250px;
  flex-direction: row;
`;

const PriceLabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default ({
  vehicle,
  vehicle: { name, price, images, views, retailerName, distance, approved },
  globalStyling: { uiElements },
  config: { title, description, highlights, translations, showViews, pricing },
  isCompare,
  isShortlisted,
  handleCompareVehicleClick,
  handleShortlistVehicleClick,
  handleMoreInfoClick,
}) => {
  const { width } = useWindowDimensions();

  const locale = useSelector(getLocale);
  const descriptionOutput = description
    .map(d => vehiclePropFormatter(d, vehicle, locale))
    .join(' | ');
  const titleOutput = title
    .map(t => vehiclePropFormatter(t, vehicle, locale))
    .join(' ');
  const imagesCount = images.length;

  const financeCellTextNoDigits = (priceEntry, currency) =>
    localiseCurrency(priceEntry, locale, currency, 0) ||
    translations.searchResultsNotAvailableLabel;

  const displayPrice = financeCellTextNoDigits(
    price.value && Number(price.value),
    price.currency,
  );

  const formattedPricing = pricing.map(p => {
    if (p.prop === 'totalPrice') return displayPrice;
    return vehiclePropFormatter(p, vehicle, locale);
  });

  const Prices = () => (
    <PriceLabelWrapper>
      {formattedPricing.map(
        p =>
          p && (
            <Price>
              {p}
              <PriceLabel>price</PriceLabel>
            </Price>
          ),
      )}
    </PriceLabelWrapper>
  );

  const Highlights = () => (
    <HighlightsWrapper>
      {highlights &&
        highlights
          .map(h => ({
            label: translations[h.prop],
            value: vehiclePropFormatter(h, vehicle, locale),
            src: highlightIcons[h.prop.toLowerCase()],
          }))
          .filter(Boolean)
          .map(({ label, value, src }) => (
            <Highlight key={`${label}${value}`} src={src}>
              <HighlightLabel>{label}</HighlightLabel>
              <HighlightValue>{value}</HighlightValue>
            </Highlight>
          ))}
    </HighlightsWrapper>
  );

  const CTAWrapper = () => (
    <CTA>
      <CompareShortlist>
        <CompareLink
          src={isCompare ? compareIconImgOn : compareIconImgOff}
          alt={translations.compareLabel}
          onClick={handleCompareVehicleClick}
        />
        <CTALabel>Compare</CTALabel>
        <FavouriteLink
          src={isShortlisted ? shortlistIconImgOn : shortlistIconImgOff}
          alt={translations.favouriteLabel}
          onClick={handleShortlistVehicleClick}
        />
        <CTALabel>Shortlist</CTALabel>
      </CompareShortlist>
      <Button
        buttonStyle={
          uiElements.primaryButton && uiElements.primaryButton.buttonStyle
        }
        applyStyle="primary"
        text={translations.ctaButtonLabel}
        onClick={handleMoreInfoClick}
      />
    </CTA>
  );

  const Approved = () =>
    approved && (
      <ApprovedLogo src={approvedLogoImg} alt={translations.approvedLabel} />
    );

  const PriceLogoWrapper = () => (
    <PriceLogo>
      <Approved />
      <PriceLabelWrapper>
        <Price>
          {displayPrice}
          <PriceLabel>price</PriceLabel>
        </Price>
      </PriceLabelWrapper>
    </PriceLogo>
  );

  const ViewsImagesWrapper = () => (
    <ViewsImages>
      {showViews && (
        <ViewsCount src={eyeWhiteImg}>
          {`${views} ${translations.viewsLabel}`}
        </ViewsCount>
      )}
      <ImagesCount src={imagesIcon} showViews={showViews}>
        {imagesCount}
      </ImagesCount>
    </ViewsImages>
  );

  const TitleContent = () => (
    <React.Fragment>
      <Title>{titleOutput}</Title>
      <Description>{descriptionOutput}</Description>
    </React.Fragment>
  );

  if (width > screenSizeOptions.extraLarge) {
    return (
      <WrapperExtraLarge>
        <ImageExtraLarge
          src={images[0]}
          onClick={handleMoreInfoClick}
          role="img"
          ariaLabel={name}
          title={name}
        >
          <ViewsImagesWrapper />
        </ImageExtraLarge>
        <DetailsWrapperExtraLarge>
          <TitleWrapper>
            <TitleContent />
          </TitleWrapper>
          <Highlights />
          <DistanceWrapper>
            <MapPinIcon src={mapPinImg} />
            {retailerName} {distance && `${distance}km away`}
          </DistanceWrapper>
        </DetailsWrapperExtraLarge>
        <PriceLogoExtraLarge>
          <Prices />
          <Approved />
          <CTAWrapper />
        </PriceLogoExtraLarge>
      </WrapperExtraLarge>
    );
  }

  if (width > screenSizeOptions.large) {
    return (
      <WrapperLarge>
        <TitleWrapperLarge>
          <TitleContent />
        </TitleWrapperLarge>
        <Image
          src={images[0]}
          onClick={handleMoreInfoClick}
          role="img"
          ariaLabel={name}
          title={name}
        >
          <ViewsImagesWrapper />
        </Image>
        <PriceLogoWrapper />
        <Highlights />
        <CTAWrapper />
      </WrapperLarge>
    );
  }

  if (width > screenSizeOptions.medium) {
    return (
      <WrapperMedium>
        <TitleWrapper>
          <TitleContent />
        </TitleWrapper>
        <Image
          src={images[0]}
          onClick={handleMoreInfoClick}
          role="img"
          ariaLabel={name}
          title={name}
        >
          <ViewsImagesWrapper />
        </Image>
        <PriceLogoWrapper />
        <Highlights />
        <CTAWrapper />
      </WrapperMedium>
    );
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleContent />
      </TitleWrapper>
      <ImageMedium
        src={images[0]}
        onClick={handleMoreInfoClick}
        role="img"
        ariaLabel={name}
        title={name}
      >
        <ViewsImagesWrapper />
      </ImageMedium>
      <PriceLogoWrapper />
      <Highlights />
      <CTAWrapper />
    </Wrapper>
  );
};

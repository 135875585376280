// @flow
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';

import { actions, constants } from './reducer';
import {
  actions as enquiryActions,
  constants as enquiryConstants,
} from '../../actions/enquiry';
import { getToken as getLeadsToken } from '../../shared/leadsAuthentication/reducer';
import { get, post, localeConfigSerializer } from '../../helpers/http';
import {
  getLeadsUrl,
  getAVLConfig,
  pdfGeneratorServiceUrl,
  appendLocaleQueryString,
  getInventoryStatus,
  inventorySearchServiceUrl,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

const utcDate = date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

function* getEnquiryLeadPayload(enquiryForm) {
  const make = yield select(state => state.config.config.global.inventory.make);
  const vehicleOfInterest = ['jaguar', 'land rover'].includes(make)
    ? enquiryForm.description
    : {
        make: enquiryForm.avlConfig.make,
        market: enquiryForm.avlConfig.market,
        model: enquiryForm.description,
      };
  const preferenceToBoolean = value =>
    typeof value === 'string' ? value === 'yes' : false;
  const selectedDate =
    enquiryForm.selectedDate && utcDate(enquiryForm.selectedDate);
  const currentVehicle = enquiryForm.vehicleBrand
    ? {
        currentVehicle: {
          make: enquiryForm.vehicleBrand,
          model: enquiryForm.vehicleModel,
          variant: enquiryForm.vehicleVersion,
        },
      }
    : {};

  return {
    leadType: enquiryForm.enquiryType.value
      ? enquiryForm.enquiryType.value
      : enquiryForm.enquiryType,
    preferredTime: enquiryForm.preferredTime,
    requestTime: {
      date: selectedDate,
      time: enquiryForm.preferredTime,
    },
    vehicleId: enquiryForm.id ? enquiryForm.id : null,
    vin: enquiryForm.vin || enquiryForm.id,
    oemId: enquiryForm.retailerInformation.oemId,
    dealerid: enquiryForm.retailerInformation.id,
    refererURL: enquiryForm.id
      ? `${window.location.origin}/VDP/${enquiryForm.id}`
      : window.location.origin,
    additionalInfo: {
      subject: enquiryForm.name,
      comments: enquiryForm.comments,
      requestTradeInEvaluation: enquiryForm.requestTradeInEvaluation,
      date: selectedDate,
      preferredTime: enquiryForm.preferredTime,
      ...currentVehicle,
    },
    phoneConsent: enquiryForm.phoneConsent,
    emailConsent: enquiryForm.emailConsent,
    smsConsent: enquiryForm.smsConsent,
    marketingConsent:
      typeof enquiryForm.marketingConsent === 'boolean'
        ? enquiryForm.marketingConsent
        : preferenceToBoolean(enquiryForm.marketingConsent),
    customisedServicesConsent: preferenceToBoolean(
      enquiryForm.customisedServicesConsent,
    ),
    customer: {
      firstName: enquiryForm.firstName,
      lastName: enquiryForm.lastName,
      email: enquiryForm.email,
      phoneNumber: enquiryForm.phone,
      address1: enquiryForm.address1,
      address2: enquiryForm.address2,
      postCode: enquiryForm.postCode,
      city: enquiryForm.city,
      country: enquiryForm.country,
      county: enquiryForm.county,
      title: enquiryForm.title,
    },
    vehicleOfInterest,
    languageCode: enquiryForm.avlConfig.locale,
    consents: {
      ...enquiryForm.consents,
    },
  };
}

function* submitMessage(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    const avlConfig = yield select(getAVLConfig);
    const enquiryLeadPayload = yield call(getEnquiryLeadPayload, {
      ...payload.enquiryForm,
      avlConfig,
    });

    const leadsToken = yield select(state => getLeadsToken(state.shared));
    const leadsUrl = yield select(getLeadsUrl);

    yield call(post, {
      url: leadsUrl,
      data: enquiryLeadPayload,
      token: leadsToken,
    });

    yield put(actions.submitMessageSuccessful(page, instance));
  } catch (error) {
    yield put(
      actions.submitMessageFailure(page, instance, {
        ...error,
        message: `Failed to submit message: ${error.message}`,
      }),
    );
  }
}

function* getLocale(action) {
  const {
    module: { page, instance },
  } = action;
  try {
    const { locale } = yield select(getAVLConfig);
    yield put(actions.getLocaleSuccess(page, instance, locale));
  } catch (error) {
    // noop
  }
}
/*
this function is used for lamborghini to generate dynamic privacy policy documents per dealer.
*/
function* requestPrivacyPolicy(action) {
  const {
    payload: retailerInfo,
    module: { page, instance },
  } = action;
  const pdfData = {
    dealer_name: retailerInfo.name,
    dealer_address: retailerInfo.address.join(','),
    dealer_phone: retailerInfo.phone,
    dealer_email: retailerInfo.email,
  };
  try {
    const pdfGeneratorUrl = yield select(pdfGeneratorServiceUrl);
    const response = yield call(post, {
      url: `${pdfGeneratorUrl}generate`,
      data: pdfData,
    });
    const responseArrayBuffer = new Int8Array(response.data.body.data);
    const bufferToBlob = new Blob([responseArrayBuffer.buffer], {
      type: 'application/pdf',
    });
    // IE11 / Safari doesnt like createObjectUrl
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(bufferToBlob, 'privacy_policy.pdf');
    } else {
      const url = window.URL.createObjectURL(bufferToBlob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.setAttribute('download', 'privacy_policy.pdf');
      window.document.body.appendChild(tempLink);
      tempLink.click();
      window.document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    actions.submitMessageFailure(page, instance, { ...error });
  }
}

function* getEnquiryVehicleInfo({ vehicleId }) {
  try {
    const { locale, make, market, region } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
    const inventoryStatus = yield select(getInventoryStatus);
    const inventoryUrl = region
      ? `${inventoryBaseUrl}inventory/make/${make}/region/${region}/${vehicleId}`
      : `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${vehicleId}`;

    const vehicleResponse = yield call(get, {
      url: inventoryUrl,
      config: localeConfigSerializer(withLocaleQueryString && locale, {
        status: inventoryStatus,
      }),
    });

    const vdpConfig = {
      ...vehicleResponse.data,
      locale,
    };

    yield put(enquiryActions.getEnquiryVehicleInfoSuccess(vdpConfig));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* EnquirySaga() {
  yield takeLatest(
    enquiryConstants.GET_ENQUIRY_VEHICLE_INFO,
    getEnquiryVehicleInfo,
  );
  yield takeEvery(constants.SUBMIT_MESSAGE, submitMessage);
  yield takeLatest(constants.INIT, getLocale);
  yield takeLatest(constants.REQUEST_PRIVACY_POLICY, requestPrivacyPolicy);
}

// @flow
import { all, put, call } from 'redux-saga/effects';
import { actions, constants } from '../actions/brand';
import { get } from '../../helpers/http';
import watch from '../../helpers/watch';
import {
  API_BASE_URL_LANGUAGES,
  API_BASE_URL_CURRENCIES,
} from '../../constants/apis';

function* loadBrand(token) {
  try {
    const [languages, currencies] = yield all([
      call(get, { url: API_BASE_URL_LANGUAGES, token }),
      call(get, { url: API_BASE_URL_CURRENCIES, token }),
    ]);

    yield put(
      actions.loadBrandSuccessful({
        languages: languages.data.contents,
        currencies: currencies.data.contents,
      }),
    );
  } catch (error) {
    yield put(actions.loadBrandFailure({ message: error.message }));
  }
}

export default [watch(constants.LoadBrand, loadBrand, true)];

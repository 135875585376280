// @flow
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg > rect:not([fill='none']) {
    fill: ${props => props.colour};
  }
  svg > path:not([fill]) {
    fill: ${props => props.colour};
  }
  svg {
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 0 3px;
  }
`;
export default function KiaArrow({ colour, width, height }: IconProps) {
  return (
    <IconWrapper colour={colour} width={width} height={height}>
      <svg viewBox="0 0 24 24" width="1em" height="1em">
        <g fill="none" fillRule="evenodd">
          <circle fill="#FFF" cx={12} cy={12} r={12} />
          <path
            strokeWidth={2}
            d="M10 8l5 5-5 5"
            transform="translate(0, -1)"
          />
        </g>
      </svg>
    </IconWrapper>
  );
}
KiaArrow.defaultProps = {
  colour: null,
  width: null,
  height: null,
};

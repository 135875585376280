// @flow
import React from 'react';
import styled from 'styled-components';

import FinanceContainer from '../containers/FinanceContainer';
import VehicleShortlist from './VehicleShortlist';
import RepresentativeExample from './RepresentativeExample';
import SortMenuBar from './SearchResults/SortMenuBar';
import { HeadingTwo, Paragraph, fontStyleOverride } from './Global';

const Container = styled.div`
  margin: 0 2.5%;
  width: 95%;
`;

const SortMenuBarStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Line = styled.hr`
  display: ${({ visible }) => (visible ? 'none' : 'flex')};
  background-color: #dedede;
  border-bottom: none;
`;

const Footer = styled.p`
  text-align: center;
  color: #7e7e7e;
  font-size: 13px;
  margin: auto 25%;
  margin-top: 27px;
`;

type Props = {
  translations: Object,
  vehicles: Vehicle[],
  onCloseClick: Function,
  onHeartClick: Function,
  onCompareClick: Function,
  representativeExample: Object,
  onMoreInfoClick: Function,
  getCompareIconColour: Function,
  getShortListedIconColour: Function,
  compareVehicle: Function,
  shortlistVehicle: Function,
  sortOptions: Object,
  sortValue: string,
  sortValueDictionary: string,
  onSortChange: Function,
  selectedView: 'GRID' | 'LIST',
  onViewChange: Function,
  placeholdingImage: string,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  headingAlignment: string,
  headingFont: Object,
  subHeadingFont: Obejct,
  preview: boolean,
  headingBottomLineDisable: boolean,
  vehicleSpecContainer: Object,
  financeWidthOverride: string,
  icon360: string,
};

export default function ShortlistedVehicles({
  translations,
  vehicles,
  onCloseClick,
  onHeartClick,
  onCompareClick,
  representativeExample,
  onMoreInfoClick,
  getCompareIconColour,
  getShortListedIconColour,
  compareVehicle,
  shortlistVehicle,
  sortOptions,
  sortValue,
  sortValueDictionary,
  onSortChange,
  selectedView,
  onViewChange,
  placeholdingImage,
  globalStyling,
  visibility,
  locale,
  headingFont,
  subHeadingFont,
  headingAlignment,
  preview,
  headingBottomLineDisable,
  financeHeadingFont,
  vehicleSpecContainer,
  financeWidthOverride,
  icon360,
}: Props) {
  const vehiclesNoDistance = vehicles.map(v => {
    const { distance, ...rest } = v;
    return rest;
  });

  return (
    <Container>
      <HeadingTwo
        styleOverride={() => `
        ${fontStyleOverride(headingFont)}
          margin-top: 34px;
          margin-bottom: 10px;
          text-align: ${headingAlignment || 'center'};
        `}
      >
        {translations.shortlistHeader}
      </HeadingTwo>
      {translations.shortlistSubheader && (
        <Paragraph
          styleOverride={() => `
          ${fontStyleOverride(subHeadingFont)}
            text-align: ${headingAlignment || 'center'};
            margin-bottom: 35px;
          `}
        >
          {vehicles.length} {translations.shortlistSubheader}
        </Paragraph>
      )}
      <Line visible={headingBottomLineDisable} />
      {visibility.showFinanceExample && (
        <FinanceContainer
          component={componentProps => (
            <RepresentativeExample
              {...componentProps}
              representativeExample={representativeExample}
              preview={preview}
            />
          )}
        />
      )}
      {visibility.showFinanceExample && <Line />}
      <SortMenuBarStyled>
        <SortMenuBar
          sortOptions={sortOptions}
          sortValue={sortValue}
          onSortChange={onSortChange}
          selectedView={selectedView}
          onViewChange={onViewChange}
          translations={translations}
          showAge={visibility.sorting.showSortByAge}
          showMileage={visibility.sorting.showSortByMileage}
        />
      </SortMenuBarStyled>
      <VehicleShortlist
        translations={translations}
        vehicles={vehiclesNoDistance}
        onCloseClick={onCloseClick}
        onHeartClick={onHeartClick}
        onCompareClick={onCompareClick}
        onMoreInfoClick={onMoreInfoClick}
        getCompareIconColour={getCompareIconColour}
        getShortListedIconColour={getShortListedIconColour}
        compareVehicle={compareVehicle}
        shortlistVehicle={shortlistVehicle}
        selectedView={selectedView}
        sortValue={sortValue}
        sortValueDictionary={sortValueDictionary}
        placeholdingImage={placeholdingImage}
        globalStyling={globalStyling}
        visibility={visibility}
        locale={locale}
        financeHeadingFont={financeHeadingFont}
        showFinancePopup
        vehicleSpecContainer={vehicleSpecContainer}
        financeWidthOverride={financeWidthOverride}
        icon360={icon360}
      />
      <Footer>{translations.footer}</Footer>
    </Container>
  );
}

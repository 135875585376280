// @flow
import React, { Fragment, Component } from 'react';
import { Helmet } from 'react-helmet';
import { actions as vehicleActions } from '../../shared/vehicleInformation';

import ContentWrapper from '../../components/ContentWrapper';
import { SpinnerWrapper } from '../../components/Spinner';

export interface Config {
  translations: Object;
}

type Props = {
  config: Config,
  state: Object,
  history: Object,
  shared: Object,
  dispatch: Function,
  globalStyling: Object,
};

export default class VehicleOverview extends Component<Props, *> {
  componentDidMount = () => {
    const {
      history: {
        location: { pathname },
      },
      dispatch,
    } = this.props;

    const vehicleId = pathname.split('/').pop();

    dispatch(
      vehicleActions.getVehicleInfo({
        vehicleId,
      }),
    );
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(vehicleActions.clearVehicleInfo());
  };

  render() {
    const {
      globalStyling,
      shared: {
        vehicleInformation: { vehicle },
      },
    } = this.props;
    return (
      <Fragment>
        <Helmet>
          {vehicle && <title>{`${vehicle.name} ${vehicle.description}`}</title>}
        </Helmet>
        <ContentWrapper>
          <div data-qa-hook="vehicle-details">
            <SpinnerWrapper globalStyling={globalStyling} loaded={!!vehicle}>
              {() => <div>{JSON.stringify(vehicle)}</div>}
            </SpinnerWrapper>
          </div>
        </ContentWrapper>
      </Fragment>
    );
  }
}

// @flow
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Exterior from './Exterior';
import Interior from './Interior';
import Controls from './Controls';

const PlayerWrapper = styled.div`
  width: 100%;
  position: relative;
  #exterior-canvas {
    position: absolute;
    left: 0;
    top: 0;
  }
  #viewpoint-modal-wrapper {
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
  }
  #viewpoint-modal-close {
    box-sizing: border-box;
    height: 40px;
    padding: 10px;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
  }
  #viewpoint-modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: -40px;
  }

  #viewpoint-image {
    width: 400px;
    height: 200px;
    padding: 10px;
  }

  #viewpoint-desc {
    color: #ffffff;
    padding: 10px;
  }
`;

type Props = {
  imagesIcon: string,
  toggleImageView: () => void,
  globalStyling: Object,
  media: Object,
  translations: Object,
};
export default ({
  imagesIcon,
  toggleImageView,
  globalStyling,
  media,
  translations,
}: Props) => {
  const loader = useRef();
  const defaultView = media.exterior ? 'exterior' : 'interior';
  const [view, setView] = useState(defaultView);
  const spinnerColour = globalStyling.colours.primaryBrandColour;
  return (
    <PlayerWrapper id="player-wrapper">
      <div>
        {view === 'interior' && (
          <Interior
            loader={loader}
            interior={media.interior}
            spinnerColour={spinnerColour}
          />
        )}
        {view === 'exterior' && (
          <Exterior
            loader={loader}
            exterior={media.exterior}
            spinnerColour={spinnerColour}
          />
        )}
      </div>
      <Controls
        imagesIcon={imagesIcon}
        toggleImageView={toggleImageView}
        setPlayerView={setView}
        globalStyling={globalStyling}
        currentView={view}
        media={media}
        translations={translations}
      />
    </PlayerWrapper>
  );
};

// @flow
import { connect } from 'react-redux';
import MediaLibrary from '../components/MediaLibrary';
import { actions as siteBuilderActions } from '../state/actions/siteBuilder';
import type { MediaType, MediaResource } from '../state/types/media';

const mapStateToProps = ({ siteBuilder }) => ({
  images: siteBuilder.media.images,
  videos: siteBuilder.media.videos,
});

const mapDispatchToProps = dispatch => ({
  onUploadMediaSuccess: (resources: MediaResource[], type: MediaType) =>
    dispatch(siteBuilderActions.uploadMediaContentSuccess(resources, type)),
  onUploadMediaFailure: (error: string) =>
    dispatch(siteBuilderActions.uploadMediaContentFailure(error)),
  onDeleteMediaItem: (mediaPublicId: string, type: MediaType) =>
    dispatch(siteBuilderActions.deleteMediaContent(mediaPublicId, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaLibrary);

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Plus({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 22">
        {'>'}
        <path d="M14 11.5h10.5v1H14V23h-1V12.5H2.5v-1H13V1h1v10.5z" />
      </svg>
    </IconWrapper>
  );
}

Plus.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import React from 'react';
import PathnameSubNav from '../../components/PathnameSubNav';
import { actions as routerActions } from '../../actions/router';
import type { Option, Font } from '../../types';

type Link = {
  text: string,
  path: string,
  availableOnMobile: boolean,
  active: boolean,
  translations: Object,
};

type Config = {
  items: Link[],
  subNavFont: Font,
  subNavBGColour: Option,
  subNavBarItemSelectedBackgroundColour: Option,
  showNavBarItemBorder: Object,
  showNavBarBottomBorder: Object,
  showChevron: boolean,
  subNavChevronColour: Option,
  subNavHeight: number,
  alignMenuItems: string,
};

type Props = {
  config: Config,
  globalStyling: Object,
  dispatch: Function,
  history: Object,
  shared: Object,
};

export default function SubNav({
  config,
  dispatch,
  history,
  globalStyling,
  shared,
}: Props) {
  const goToPath = path => dispatch(routerActions.navigate(path));
  const vehicleCounters = {
    compare:
      shared && shared.compare.vehicles && shared.compare.vehicles.length,
    shortlist:
      shared && shared.shortlist.vehicles && shared.shortlist.vehicles.length,
  };
  return (
    <PathnameSubNav
      globalStyling={globalStyling}
      onBackButtonClick={history.goBack}
      links={config.items.map(link => ({
        text: `${link.translations.linkText} ${
          vehicleCounters[link.path.substr(1)]
            ? `(${vehicleCounters[link.path.substr(1)]})`
            : ''
        }`,
        onClick: () => goToPath(link.path),
        availableOnMobile: link.availableOnMobile,
        active: link.active,
        path: link.path,
      }))}
      config={config}
    />
  );
}

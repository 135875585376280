// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import VehicleCarouselDetails from './VehicleCarouselDetails';

import type { IVDPCarouselConfig } from './types';

const imagesSelector = state => {
  if (!state.dataProvider.vehicleInfo) {
    return null;
  }

  const { images, id: vehicleId, views } = state.dataProvider.vehicleInfo;

  return { images, vehicleId, views };
};

type Props = {
  config: IVDPCarouselConfig,
  vehicleInfo: {
    images: array,
    vehicleId: string,
    views: number,
  },
};

export default (props: Props) => {
  const vehicleInfo = useSelector(imagesSelector);

  return (
    vehicleInfo && (
      <VehicleCarouselDetails
        {...{
          ...props,
          vehicleInfo,
        }}
      />
    )
  );
};

// @flow
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg > rect:not([fill='none']) {
    fill: ${props => props.colour};
  }
  svg > path:not([fill]) {
    fill: ${props => props.colour};
  }
  svg {
    width: ${props => props.width};
    height: ${props => props.height};
  }
`;
export default function HeroCarousel({ colour, width, height }: IconProps) {
  return (
    <IconWrapper colour={colour} width={width} height={height}>
      <svg viewBox="0 0 18 18" width="1em" height="1em">
        <g fill="none" fillRule="evenodd">
          <path fill="#0065DB" d="M2 3h7v7H2z" />
          <path fill="#D6D6D6" d="M10 3h6v12H2v-4.273h8z" />
          <path stroke="#0065DB" d="M.5 1.5h17v15H.5z" />
        </g>
      </svg>
    </IconWrapper>
  );
}
HeroCarousel.defaultProps = {
  colour: null,
  width: null,
  height: null,
};

// @flow
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import FinanceContainer from '../../containers/FinanceContainer';
import PathnameSubNavBar from '../../components/PathnameSubNavBar';
import VehicleModel from '../../components/Range/VehicleModel';
import RepresentativeExample from '../../components/RepresentativeExample';

import { actions as routerActions } from '../../actions/router';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import media from '../../theme';
import type { Font, Option } from '../../types';
import { localiseCurrency } from '../../shared/localisation/numbers';
import { formatUrl } from '../../shared/routing';
import ContentWrapper from '../../components/ContentWrapper';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

const RepresentativeExampleContainer = styled.div.withConfig({
  displayName: 'RepresentativeExampleContainer',
})`
  ${media.min.large`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  `};
`;

export interface Config {
  items: Object[];
  translations: Object;
  buttonFont?: Font;
  buttonBGColour?: Option;
  subNavBGColour?: Option;
  subNavFont?: Font;
  subNavBorderColour?: Option;
  subNavChevronColour?: Option;
  buttonFont?: Font;
  buttonBGColour?: Option;
  headerBannerBgColour?: Option;
  headerBannerYearFont?: Font;
  headerBannerTitleFont?: Font;
  headerBannerSubTitleFont?: Font;
  headerBannerPriceFont?: Font;
  searchLinkBgColour?: Option;
  performanceHeadingFont?: Font;
  modelLinks: string[];
}

type Props = {
  config: Config,
  shared: Object,
  history: Object,
  actions: {
    init: Function,
    getModelGroups: ({}) => void,
  },
  dispatch: Function,
  preview: boolean,
  pathByModule: Function,
  translations: Object,
  state: Object,
  globalStyling: Object,
  featureFlags: Object,
};

export default class Range extends Component<Props, *> {
  componentDidMount() {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const { getModelGroups } = this.props.actions;

    if (
      !this.props.state.modelGroups ||
      this.props.state.modelGroups.length === 0
    ) {
      getModelGroups({ preview: this.props.preview });
    }
  }

  onSearchClick = (model: string) => {
    const {
      dispatch,
      marketInfo: { make, market },
    } = this.props;
    dispatch(searchFilterActions.resetFilters());
    dispatch(
      searchFilterActions.filterByModelRange({
        section: 'models',
        filter: model,
      }),
    );
    dispatch(
      searchFilterActions.getSearchFilters({
        market,
        make,
        filters: `models=${model}`,
      }),
    );
    dispatch(routerActions.navigate('/searchresults'));
  };

  render() {
    const {
      preview,
      config,
      dispatch,
      shared,
      history,
      state,
      marketInfo,
      state: { modelGroups },
    } = this.props;

    const representativeExample = preview
      ? {}
      : shared.financeCalculator.representativeExample;
    const vehicle = history.location.pathname
      .split('/')
      .pop()
      .replace(/\s/g, '');
    const navigateToFinance = () =>
      dispatch(routerActions.navigate('/financeoptions'));
    const patchWithModelGroupData = vehicleModel => {
      const {
        priceFrom,
        description,
        imageUrl,
        model: modelName,
        modelDisplay,
      } =
        (modelGroups || []).find(
          ({ model }) =>
            model
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/_/g, ' ')
              .replace(/\s/g, '') ===
            vehicleModel
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/_/g, ' ')
              .replace(/\s/g, ''),
        ) || {};
      const localisedPriceFrom =
        priceFrom && localiseCurrency(priceFrom, marketInfo.locale, 'EUR', 0);
      return {
        priceFrom: localisedPriceFrom,
        description,
        imageUrl,
        searchModelName: modelDisplay || modelName,
      };
    };

    const lowerCaseLinks = config.modelLinks.map(ml => ml.toLowerCase());
    const links = (state.modelGroups || [])
      .filter(model => lowerCaseLinks.includes(model.model.toLowerCase()))
      .map(({ model, modelDisplay }) => ({
        text: modelDisplay || model,
        onClick: () =>
          dispatch(routerActions.navigate(formatUrl(`range/${model}`))),
        availableOnMobile: true,
        path: formatUrl(`range/${model}`),
      }));

    const {
      config: {
        items,
        translations,
        subNavBGColour,
        subNavBorderColour,
        subNavChevronColour,
        subNavFont,
        subNavBarItemSelectedBackgroundColour,
        subNavBarItemSelectedFontColour,
      },
      globalStyling,
    } = this.props;

    const vehicleFromConfig = items.find(
      model => model.key === vehicle.toLowerCase(),
    );
    const vehicleModel = {
      ...vehicleFromConfig,
      ...patchWithModelGroupData(vehicle),
    };

    const { featureFlags } = this.props;

    return (
      <div>
        <Helmet>
          {vehicleModel.searchModelName && (
            <title>
              {translateFromTemplate(
                'rangeTabTitle',
                {
                  MODEL: vehicleModel.searchModelName,
                },
                translations,
              )}
            </title>
          )}
        </Helmet>
        <PathnameSubNavBar
          title={vehicleModel.searchModelName}
          links={[...links]}
          subNavBGColour={subNavBGColour}
          subNavBorderColour={subNavBorderColour}
          subNavChevronColour={subNavChevronColour}
          subNavFontTheme={subNavFont}
          subNavBarItemSelectedBackgroundColour={
            subNavBarItemSelectedBackgroundColour
          }
          subNavBarItemSelectedFontColour={subNavBarItemSelectedFontColour}
          contentWidth={globalStyling.contentWidth}
        />
        {featureFlags.finance && config.showRepresentativeExample && (
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <RepresentativeExampleContainer>
              <FinanceContainer
                component={props => (
                  <RepresentativeExample
                    {...props}
                    representativeExample={representativeExample}
                    preview={preview}
                  />
                )}
              />
            </RepresentativeExampleContainer>
          </ContentWrapper>
        )}
        {vehicleFromConfig ? (
          <VehicleModel
            vehicle={vehicleModel}
            searchForModel={this.onSearchClick}
            navigateToFinance={navigateToFinance}
            translations={translations}
            globalStyling={globalStyling}
            featureFlags={featureFlags}
            contentWidth={globalStyling.contentWidth}
            videoContentLeft={config.videoContentLeft}
          />
        ) : (
          <div>{translations.modelNotFound}</div>
        )}
      </div>
    );
  }
}

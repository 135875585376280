// @flow
const appSettings = window.AppSettings || {};
const apiHostProtocol = 'https';
const hostUrl = appSettings.hostName || 'dev.mtkip.com';
export const BASE_URL = `${apiHostProtocol}://${hostUrl}`;
export const EDITOR_API_BASE_URL = `${apiHostProtocol}://avl-editor.${hostUrl}/api`;
export const TRANSLATIONS_BASE_URL = `${apiHostProtocol}://translations.${hostUrl}/api`;

export const API_BASE_URL_USERS = `${apiHostProtocol}://avl-users.${hostUrl}/api`;

export const API_BASE_URL_LANGUAGES = `${EDITOR_API_BASE_URL}/languages`;

export const API_BASE_URL_CURRENCIES = `${EDITOR_API_BASE_URL}/currencies`;

export const API_BASE_URL_BRANDING = `${EDITOR_API_BASE_URL}/branding`;

export const API_BASE_URL_SITES = `${EDITOR_API_BASE_URL}/sites`;

export const API_BASE_URL_MODULES = `${EDITOR_API_BASE_URL}/modules`;

export const API_BASE_URL_EDITOR_USERS = `${API_BASE_URL_USERS}/users`;

export const API_BASE_URL_ROLES = `${EDITOR_API_BASE_URL}/roles`;

export const API_BASE_URL_SITE_CONFIGURATION = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}`;

export const API_BASE_URL_SITE_PAGES = (siteId: string, pageId?: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/pages-instances${
    pageId ? `/${pageId}` : ''
  }`;

export const API_BASE_URL_SITE_LINKED_MODULES = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/linked-modules`;

export const API_BASE_URL_SITE_GLOBAL = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/global-modules`;

export const API_URL_SITE_SETTINGS = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/settings`;

export const API_BASE_URL_SITE_LINKS = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/external-links`;

export const API_BASE_URL_SITE_TRANSLATIONS = (siteId: string) =>
  `${TRANSLATIONS_BASE_URL}/translations/${siteId}/summary`;

export const TRANSLATION_UPLOAD_DOWNLOAD_URL = (
  siteId: string,
  defaultLanguageIsoCode: string,
  translatedLanguageIsoCode: string,
  mode: 'upload' | 'download',
) =>
  `${TRANSLATIONS_BASE_URL}/translations/${siteId}/fromlanguage/${defaultLanguageIsoCode}/tolanguage/${translatedLanguageIsoCode}/${mode}/csv`;

export const API_BASE_URL_PAGE_TEMPLATES = `${EDITOR_API_BASE_URL}/pages-templates`;

export const API_BASE_URL_SKIP_CLONING = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/actions/initialise-content`;

export const API_BASE_URL_CLONE_SITE = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/actions/clone-from`;

export const API_BASE_URL_EDITOR_USERS_DELETE = (userId: string) =>
  `${API_BASE_URL_EDITOR_USERS}/${userId}`;

export const API_BASE_URL_USERS_VALIDATE = (email: string) =>
  `${API_BASE_URL_USERS}/${encodeURIComponent(email)}`;

export const API_BASE_URL_SITE_PUBLISH = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/actions/publish`;

export const API_BASE_URL_SITE_PUBLISH_PREVIEW = (siteId: string) =>
  `${EDITOR_API_BASE_URL}/sites/${siteId}/actions/publish-preview`;

export const MEDIA_SERVICE_URL = `${apiHostProtocol}://media-library.${hostUrl}/media`;

export const POSTS_SERVICE_URL = `${apiHostProtocol}://posts.${hostUrl}/api/`;

export const INTERNAL_PUBLISH_DOMAIN = appSettings.internalPublishDomain;

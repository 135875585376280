// @flow
type fileType = 'ArrayBuffer' | 'DataURL' | 'Text';
export default function(
  file: Blob,
  type: fileType = 'ArrayBuffer',
): Promise<*> {
  return new Promise((resolve: Function, reject: Function) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    if (type === 'ArrayBuffer') return reader.readAsArrayBuffer(file);
    if (type === 'DataURL') return reader.readAsDataURL(file);
    if (type === 'Text') return reader.readAsText(file);
    return reject();
  });
}

// @flow
import React from 'react';
import styled from 'styled-components';
import LabelledCheckBox from './LabelledCheckBox';
import DatePickerWrapper from './DatePickerWrapper';
import RadioGroup from './RadioGroup';
import media from '../theme';
import {
  type SelectDateMode,
  type SelectTimeMode,
} from '../modules/TestDrive/reducer';

const RadioGroupLabel = styled.span`
  font-weight: 600;
  margin-bottom: 6px;
`;

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  ${media.min.large`
  padding: 5%;
`};
`;

const BorderedBlock = styled.div`
  border: 1px solid #d8d8d8;
  padding: 20px;
`;

const HorizontalWrapper = styled.div.withConfig({
  displayName: 'HorizontalWrapper',
})`
  flex-direction: column;
`;

const Overlay = styled.div`
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;

const SelectTimeWrapper = styled.div.withConfig({
  displayName: 'SelectTimeWrapper',
})`
  margin: 14px 0;
`;

const TimeRadioButtonsWrapper = styled.div.withConfig({
  displayName: 'SelectTimeRadioButtonsWrapper',
})`
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin: 14px 0;
`;

const InterestedText = styled.div.withConfig({
  displayName: 'InterestedText',
})`
  margin-top: 20px;
  color: #444444;
  line-height: 22px;
`;

const CheckboxWrapper = styled.div.withConfig({
  displayName: 'CheckboxWrapper',
})`
  align-items: center;
  margin-top: 15px;
  display: flex;
`;

type Props = {
  translations: Object,
  disabled: boolean,
  onSelectDate: () => void,
  toggleCalendar: () => void,
  selectDateModeOptions: SelectDateMode[],
  selectDateMode: SelectDateMode,
  selectTimeModeOptions: SelectTimeMode[],
  selectTimeMode: SelectTimeMode,
  open: boolean,
  selected: Date,
  bookingForm: Object,
  testDriveConfig: Object,
  disableCalendar: boolean,
  confirmDateAndTime: Function,
  selectedDateMode: string,
  selectedTimeMode: string,
  setSelectRadioGroupMode: (key: string, value: string) => void,
  interestedClicked: () => void,
  globalStyling: Object,
};

const onDateRadioGroupChange = ({ setSelectRadioGroupMode }: Props) => ({
  value,
}) => setSelectRadioGroupMode('selectedDateMode', value);

const onTimeRadioGroupChange = ({ setSelectRadioGroupMode }: Props) => x =>
  setSelectRadioGroupMode('selectedTimeMode', x.value);

const onToggleCalendarChange = ({ disableCalendar, toggleCalendar }: Props) =>
  disableCalendar ? () => {} : toggleCalendar;

const asOption = (key: string, value: string) => ({ value, label: key });

export default function TestDriveSelectDateAndTime(props: Props) {
  const {
    translations,
    selectDateModeOptions,
    selectedDateMode,
    disableCalendar,
    open,
    bookingForm,
    selected,
    onSelectDate,
    selectTimeModeOptions,
    selectedTimeMode,
    interestedClicked,
    globalStyling,
  } = props;
  const firstSelectable = new Date();

  const translatedDateModeOptions = selectDateModeOptions.map(opt => ({
    value: opt.value,
    label: translations[opt.value],
  }));

  const translatedTimeModeOptions = selectTimeModeOptions.map(opt => ({
    value: opt.value,
    label: translations[opt.value],
  }));

  return (
    <Container>
      <BorderedBlock>
        <RadioGroupLabel>
          {translations.testDriveSelectDateLabel}
        </RadioGroupLabel>
        <HorizontalWrapper>
          <RadioGroup
            horizontal
            options={translatedDateModeOptions}
            value={asOption(selectedDateMode, selectedDateMode)}
            onChange={onDateRadioGroupChange(props)}
            globalStyling={globalStyling}
          />
        </HorizontalWrapper>
        <Overlay disabled={disableCalendar}>
          <DatePickerWrapper
            open={open}
            date={(bookingForm && bookingForm.date) || firstSelectable}
            selected={selected}
            onSelectDate={onSelectDate}
            toggleCalendar={onToggleCalendarChange(props)}
            globalStyling={globalStyling}
            translations={translations}
          />
        </Overlay>
        <SelectTimeWrapper>
          <RadioGroupLabel>
            {translations.testDriveSelectTimeLabel}
          </RadioGroupLabel>
          <TimeRadioButtonsWrapper>
            <RadioGroup
              options={translatedTimeModeOptions}
              value={asOption(selectedTimeMode, selectedTimeMode)}
              onChange={onTimeRadioGroupChange(props)}
              globalStyling={globalStyling}
            />
          </TimeRadioButtonsWrapper>
        </SelectTimeWrapper>
      </BorderedBlock>
      <InterestedText>{translations.testDriveOfferText}</InterestedText>
      <CheckboxWrapper>
        <LabelledCheckBox
          checked={bookingForm.interestedChecked}
          onClick={interestedClicked}
          label={translations.testDriveInterestedCheckboxLabel}
        />
      </CheckboxWrapper>
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  padding-bottom: ${({ fullscreen }) => (fullscreen ? '100vh' : '75%')};
`;

class CarouselSlides extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSlide: {},
    };
  }

  componentDidUpdate({ slide: prevSlide }) {
    const { slide } = this.props;

    if (slide.id !== prevSlide.id) {
      this.setState({ prevSlide });
    }
  }

  render() {
    const {
      slide: { component: Slide, id, image },
      fullscreen,
    } = this.props;

    const {
      prevSlide: { id: prevId, image: prevImage },
    } = this.state;

    return (
      <Container key={image} fullscreen={fullscreen}>
        {prevImage && (
          <Slide image={prevImage} id={prevId} fullscreen={fullscreen} />
        )}
        <Slide image={image} id={id} active fullscreen={fullscreen} />
      </Container>
    );
  }
}

CarouselSlides.defaultProps = {
  imageWidth: 800,
  imageHeight: 650,
  transitionTime: 500,
};

export default CarouselSlides;

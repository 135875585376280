import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ComponentContext from './ComponentContext';
import { actions as routerActions } from '../actions/router';
import { httpClientIntercept } from '../helpers/http';

const httpClientNext = dispatch => response => {
  if (
    response &&
    response.status === 401 &&
    window.location.pathname !== '/signin'
  ) {
    dispatch(routerActions.navigate('/signin'));
  }
};

const useSite = () => {
  const dispatch = useDispatch();
  const { analytics, featureFlags } = useSelector(
    state => state.config.config.global,
  );
  const site = useSelector(s => s.config.site);
  const siteBands = useSelector(s => site.map(gm => s.config.bands[gm]));
  const { Body } = useContext(ComponentContext);

  useEffect(() => {
    httpClientIntercept(httpClientNext(dispatch));
  }, [dispatch]);

  return { analytics, featureFlags, siteBands, Body };
};

export default useSite;

// @flow

import type { CarResult, Theme } from '../components/SearchResultTile';

const emptyTheme: Theme = {
  colour: '',
  textTransform: '',
  characterSpacing: '',
  fontSize: '',
  fontFamily: '',
};

const vehicleToCarResult = (v: Vehicle): CarResult => ({
  id: v.id,
  name: v.name,
  description: v.description,
  odometer: v.specification && v.specification.odometer,
  engine: v.specification && v.specification.engine,
  transmission: v.specification && v.specification.transmission,
  fuel: v.specification && v.specification.fuelType,
  price: v.price,
  priceFull: 0,
  priceText: 'TODO',
  priceMonthly: v.price && v.price.monthly,
  monthlyText: 'TODO',
  priceApr: v.price && v.price.apr,
  aprText: 'TODO',
  locale: v.locale,
  currencyType: v.price && v.price.currency,
  performance: v.performance,
  powerOutput: v.powerOutput,
  distanceFromRetailer: undefined,
  retailerName: v.retailerInformation && v.retailerInformation.name,
  images: v.images,
  numberAvailable: 0,
  selected: false,
  modelTheme: emptyTheme,
  priceTheme: emptyTheme,
  numberAvailableTheme: emptyTheme,
  financeTextTheme: emptyTheme,
  align: 'left',
  views: v.views,
  equipmentMetaData: v.equipmentMetaData,
  exterior: v.specification && v.specification.exterior,
  interior: v.specification && v.specification.interior,
  bodystyle: v.specification && v.specification.bodystyle,
  registration: v.registration,
  location: v.location,
  retailerPhone: v.retailerInformation && v.retailerInformation.phone,
  colourWithTrim: v.colourWithTrim,
  veneer: v.specification && v.specification.veneer,
  warranty: v.specification && v.specification.warranty,
  engineDetails: v.specification && v.specification.engineDetails,
});

export default vehicleToCarResult;

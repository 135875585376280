// @flow
/* eslint-env browser */
import React, { Component } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import VdpHeader from './VdpHeader';
import DirectionSearcher from './DirectionSearcher';
import GoogleMap from './GoogleMap';
import { google } from '../utilities/googleObject';
import media from '../theme';
import { RetailerInfo as Retailer } from '../modules/RetailerInformation/RetailerInformation';
import Pin from './JaguarIcons/Global/Pin';
import { Button, fontStyleOverride } from './Global';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const MapContainer = styled.div.withConfig({
  displayName: 'MapContainer',
})`
  width: 70%;
  ${media.max.large`
    display: none;
  `};
`;

const RetailerContainer = styled.div.withConfig({
  displayName: 'RetailerContainer',
})`
  display: flex;
  height: auto;
`;
const RetailerInfo = styled.div.withConfig({
  displayName: 'RetailerInfo',
})`
  width: 30%;
  padding: 32px 50px;
  ${media.max.large`
    width: 100%;
    padding: 0 5%;
    padding-bottom: 20px;
  `};
`;

const StyledInfoSection = styled.div`
  padding: 8px 0;
  border-bottom: ${props => (props.lineBreak ? '1px solid #dedede' : null)};
  ${media.max.large`
    padding: 8px 0;
  `};
`;

const RetailerAddress = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #444444;
  ${({ font }) => font && fontStyleOverride(font)};
`;
const ContactInfo = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #444444;
  margin: 10px 0;
  ${({ font }) => font && fontStyleOverride(font)};
`;

type InfoSectionProps = {
  children: Node,
  lineBreak?: boolean,
  qaHook?: string,
};

const InfoSection = ({ children, lineBreak, qaHook }: InfoSectionProps) => (
  <StyledInfoSection data-qa-hook={qaHook} lineBreak={lineBreak}>
    {children}
  </StyledInfoSection>
);

InfoSection.defaultProps = {
  lineBreak: false,
  qaHook: undefined,
};

const FlexDisplay = styled.div`
  display: flex;
  align-items: center;
`;

const DistanceFrom = ({
  distanceFromRetailer,
  iconColour,
}: {
  distanceFromRetailer: string,
  iconColour: string,
}) => {
  if (distanceFromRetailer) {
    return (
      <FlexDisplay>
        <Pin colour={iconColour} width="1.8em" height="1.8em" />
        <span>{distanceFromRetailer}</span>
      </FlexDisplay>
    );
  }
  return null;
};

type Props = {
  retailer: Retailer,
  translations: Object,
  viewRetailerStock: Function,
  buttonBGColour: string,
  iconColour: string,
  globalStyling: Object,
  retailerLocationCountry: string,
  retailerHeaderFont: Object,
  retailerInfoFont: Object,
  searchCountryCode: string,
};
export default class VdpRetailer extends Component<Props, *> {
  constructor(props: Props) {
    super(props);

    this.state = {
      distanceFromRetailer: null,
    };
  }

  getDistanceFromRetailer = (position: Position) => {
    const { latitude: userLat, longitude: userLong } = position.coords;
    const { retailer } = this.props;
    const [lat, long] = retailer.location.coordinates;

    const distanceService = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix(
      {
        origins: [new google.maps.LatLng(userLat, userLong)],
        destinations: [new google.maps.LatLng(lat, long)],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          const distanceText =
            response.rows[0].elements[0].distance &&
            response.rows[0].elements[0].distance.text;
          if (distanceText) {
            this.setState({
              distanceFromRetailer: translateFromTemplate(
                'vdpRetailerDistanceSuccess',
                {
                  DISTANCE: distanceText,
                },
                this.props.translations,
              ),
            });
          } else {
            this.setState({
              distanceFromRetailer: this.props.translations
                .vdpRetailerDistanceFailed,
            });
          }
        } else {
          this.setState({
            distanceFromRetailer: null,
          });
        }
      },
    );
  };

  render() {
    const {
      retailer,
      translations,
      viewRetailerStock,
      buttonBGColour,
      iconColour,
      globalStyling,
      retailerLocationCountry,
      retailerHeaderFont,
      retailerInfoFont,
      searchCountryCode,
    } = this.props;
    const { distanceFromRetailer } = this.state;
    return (
      <RetailerContainer data-qa-hook="retailer-module">
        <RetailerInfo>
          <InfoSection lineBreak>
            <VdpHeader
              styles={{ headerFont: retailerHeaderFont }}
              qaHook="retailer-header"
            >
              {retailer.name}
            </VdpHeader>
          </InfoSection>
          <InfoSection qaHook="retailer-address" lineBreak>
            {retailer.address.map(line => (
              <RetailerAddress font={retailerInfoFont} key={line}>
                {line}
              </RetailerAddress>
            ))}
            <div>{retailer.country}</div>
          </InfoSection>
          <InfoSection qaHook="retailer-contact-info" lineBreak>
            <ContactInfo font={retailerInfoFont}>
              {translations.vdpRetailerPhoneLabel}
              {': '}
              &#x200E;{retailer.phone}
            </ContactInfo>
            {translations.vdpRetailerEmailLabel && retailer.email && (
              <ContactInfo>
                {translations.vdpRetailerEmailLabel}
                {': '}
                {retailer.email.split(',').map(e => (
                  <div>{e}</div>
                ))}
              </ContactInfo>
            )}
          </InfoSection>
          {distanceFromRetailer && (
            <InfoSection qaHook="retailer-location">
              <DistanceFrom
                distanceFromRetailer={distanceFromRetailer}
                iconColour={iconColour}
              />
            </InfoSection>
          )}
          <InfoSection>
            <DirectionSearcher
              onGetCurrentPosition={(position: Position) =>
                this.getDistanceFromRetailer(position)
              }
              placeholder={translations.vdpRetailerGetDirections}
              destination={retailer.location}
              buttonBGColour={buttonBGColour}
              iconColour={iconColour}
              retailerLocationCountry={retailerLocationCountry}
              locationFilterLabel={translations.locationFilterLabel}
              globalStyling={globalStyling}
              searchCountryCode={searchCountryCode}
            />
          </InfoSection>
          <InfoSection>
            <Button
              data-qa-hook="view-retailer-stock-button"
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              styleOverride={() => `
                width: 100%;
              `}
              text={translations.vdpRetailerStockButtonText}
              onClick={() => viewRetailerStock(retailer)}
            />
          </InfoSection>
        </RetailerInfo>
        <MapContainer>
          <GoogleMap mapName="vdpMap" retailerLocation={retailer.location} />
        </MapContainer>
      </RetailerContainer>
    );
  }
}

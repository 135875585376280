// @flow
import React from 'react';
import styled from 'styled-components';

import { SectionMenuItem } from 'cms-ui';

import type { Page } from '../state/types/page';

type Props = {
  pages: Page[],
  onEnterBreakout: Function,
  setPage: Function,
};

const SectionMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 36px;
  border-top: ${({ theme }) => `1px solid ${theme.colours.secondary02}`};
`;

const MenuContent = styled.span`
  display: flex;
  align-items: center;
`;

const SelectPageMenu = ({ pages, onEnterBreakout, setPage }: Props) => {
  return (
    <SectionMenu>
      {pages.map(({ id, pageName }) => (
        <SectionMenuItem
          key={id}
          onClick={() => {
            setPage(id, pageName);
            onEnterBreakout({ name: 'EditPage' });
          }}
          withBorder
        >
          <MenuContent>{pageName}</MenuContent>
        </SectionMenuItem>
      ))}
    </SectionMenu>
  );
};

export default SelectPageMenu;

import { config } from 'cms-modules';

const { Config, Slice, Section, TextArea, Text, Posts } = config;

const editor = Config(
  'PostSummary',
  { id: 'PostSummary' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      Posts('Select post', { id: 'postId', button: 'button' }),
      TextArea('Summary Text', 'bodyText'),
      Text('Button text', 'buttonText'),

      TextArea('Image Accessibility Text', 'imageLabel', { rows: 3 }),
    ),
  ),
);

export default editor;

/* eslint-env browser */

// Pollyfill all the things
// TODO try to reduce this to just the pollyfills we actually need?
import 'core-js/fn/object/';
import 'core-js/fn/array/';

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import Root from './root';

import settings from './settings';

const linkTag = document.createElement('link');
linkTag.setAttribute('href', settings.fontsCssUrl);
linkTag.setAttribute('type', 'text/css');
linkTag.setAttribute('rel', 'stylesheet');
linkTag.setAttribute('id', 'fontsLink');
document.head.appendChild(linkTag);
ReactDOM.render(React.createElement(Root), document.getElementById('root'));

// @flow
import React from 'react';
import { IconWrapper } from '../../components';

export default function Icon({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
          <path fill="#0065DB" d="M2 3h14v8H2z" />
          <path stroke="#0065DB" d="M.5 1.5h17v15H.5z" />
          <g transform="translate(5 13)" fill="#0065DB">
            <circle cx={1} cy={1} r={1} />
            <circle cx={4} cy={1} r={1} />
            <circle cx={7} cy={1} r={1} />
          </g>
        </g>
      </svg>
    </IconWrapper>
  );
}

Icon.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

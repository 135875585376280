import { config } from 'cms-modules';

const {
  Config,
  Slice,
  Section,
  Items,
  Font,
  Markdown,
  SocialLinks,
  Image,
} = config;

const editor = Config(
  'Footer',
  { id: 'Footer' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Navigation links',
      {},
      Items('Footer links', 'nav.links', {
        addButtonText: 'Add Footer Link',
        saveButtonText: 'Save Footer Link',
        max: 6,
      }),
      SocialLinks('Social Link', 'social.links'),
    ),
    Section(
      'Logo',
      {},
      Image('Logo', { image: 'logo', accessibilityText: 'imageLabel' }),
      Markdown('Footnote Text', 'footnoteText'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'Styling' },
    Section(
      'Fonts',
      {},
      Font('Link Text', 'footerFont'),
      Font('Footnote Text', 'footnoteFont'),
    ),
  ),
);

export default editor;

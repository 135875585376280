// @flow
import React from 'react';
import styled from 'styled-components';

import SitesContainer from '../containers/SitesContainer';
import TemplateOptionsContainer from '../containers/TemplateOptionsContainer';
import MediaLibraryContainer from '../containers/MediaLibraryContainer';
import BrandedHeader from './BrandedHeader';
import NavigationBar from './NavigationBar';

const Container = styled.div`
  ${({ small }) =>
    small
      ? `
    padding:0 calc((100vw - 960px) / 2);
    `
      : ''};
  height: calc(100vh - 180px);
  overflow-y: scroll;
`;

export const navOptions = {
  SITE_LIST: 'SITE_LIST',
  TEMPLATE_OPTIONS: 'TEMPLATE_OPTIONS',
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
};
export type NavContext = 'SITE_LIST' | 'TEMPLATE_OPTIONS' | 'MEDIA_LIBRARY';
type Props = {
  branding: {
    logos: {
      brandHeaderLogo: string,
      brandFooterLogo: string,
    },
    name: string,
  },
  navContext: NavContext,
  onTabClick: (tab: string) => void,
};

export default function Homepage({
  branding: { logos, name },
  navContext,
  onTabClick,
}: Props) {
  return (
    <div>
      <BrandedHeader logo={logos.brandHeaderLogo} title={`${name} AVL List`} />
      <NavigationBar onTabClick={onTabClick} />
      <Container small={navContext !== navOptions.SITE_LIST}>
        {navContext === navOptions.SITE_LIST && <SitesContainer />}
        {navContext === navOptions.TEMPLATE_OPTIONS && (
          <TemplateOptionsContainer />
        )}
        {navContext === navOptions.MEDIA_LIBRARY && <MediaLibraryContainer />}
      </Container>
    </div>
  );
}

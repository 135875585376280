// @flow
import React from 'react';
import styled from 'styled-components';
import { mixins } from '../../theme';
import type { ButtonLinkTheme } from '../../types';

const BasicButton = styled.button`
  height: 45px;
  width: 100%;
  font-size: 13px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 600;
  letter-spacing: 0.1em;

  &:focus {
    outline: none;
  }

  ${({ withoutChevron }) => withoutChevron || mixins.arrowComtentTemplate()};
`;

const TertiaryButton = styled(BasicButton)`
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;

  &:active {
    color: #ffffff;
    background-color: #000000;
  }

  &:disabled {
    color: #444444;
    background-color: #ffffff;
  }
`;

const SecondaryButton = styled(BasicButton)`
  color: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.colour ? theme.colour : '#9e1b32'};
  letter-spacing: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.characterSpacing ? theme.characterSpacing : '0.1em'};
  font-family: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.fontFamily ? theme.fontFamily : 'inherit'};
  background-color: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.backgroundColour ? theme.backgroundColour : 'transparent'};
  text-transform: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.textTransform ? theme.textTransform : 'uppercase'};
  font-size: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.fontSize ? theme.fontSize : '13px'};
  border: ${({ borderColour }: { borderColour: string }) =>
    `1px solid ${borderColour || '#999999'}`};

  &:active {
    color: #ffffff;
    background-color: #352c2c;
    border: none;
  }

  &:hover {
    color: #ffffff;
    background-color: #352c2c;
    border: none;
  }

  &:disabled {
    color: #9e1b32;
    background-color: #ffffff;
  }
`;

const PrimaryButton = styled(BasicButton)`
  color: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.colour ? theme.colour : '#ffffff'};
  letter-spacing: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.characterSpacing ? theme.characterSpacing : '0.1em'};
  font-family: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.fontFamily ? theme.fontFamily : 'inherit'};
  background-color: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.backgroundColour ? theme.backgroundColour : '#9e1b32'};
  text-transform: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.textTransform ? theme.textTransform : 'uppercase'};
  font-size: ${({ theme }: { theme: ButtonLinkTheme }) =>
    theme.fontSize ? theme.fontSize : '13px'};

  &:active {
    background-color: #352c2c;
  }

  &:hover {
    background-color: #352c2c;
  }

  &:disabled {
    background-color: #9e1b32;
  }
`;

function Button({
  primary,
  secondary,
  tertiary,
  ...rest
}: {
  primary: boolean,
  secondary: boolean,
  tertiary: boolean,
}) {
  if (primary) return <PrimaryButton {...rest} />;
  if (secondary) return <SecondaryButton {...rest} />;
  if (tertiary) return <TertiaryButton {...rest} />;

  return <BasicButton {...rest} />;
}

Button.defaultProps = {
  theme: {
    fontFamily: 'inherit',
  },
};

export default Button;

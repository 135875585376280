// @flow
import React from 'react';
import styled from 'styled-components';

import Electric from './JaguarIcons/VDP/Electric';
import Efficiency from './JaguarIcons/VDP/Efficiency';
import Mileage from './JaguarIcons/VDP/Mileage';
import Petrol from './JaguarIcons/VDP/Fuel';
import Stopwatch from './JaguarIcons/VDP/Stopwatch';

type Performance = {
  acceleration: string,
  emissions: string,
  maxSpeed: string,
  maxSpeedUnit: string,
  fuelConsumption: string,
  fuelConsumptionUnit: string,
  maxPower: string,
};
type Props = {
  performance: Performance,
  isMobile?: boolean,
};

const Container = styled.div`
  width: ${props => (props.isMobile ? '50%' : '100%')};
  height: auto;
  margin: 20px 0;
`;

const InfoBlockRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

const InfoBlockContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.isMobile ? '100%' : '33%')};
  border-right: ${props => (props.isCenter ? '1px solid' : 'none')};
  border-left: ${props => (props.isCenter ? '1px solid' : 'none')};
  border-color: #dedede;
  box-sizing: border-box;
`;
const IconContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
`;
const InfoContainer = styled.div`
  width: 70%;
`;
const InfoStyling = styled.div`
  color: #6b6b6b;
  font-size: 16px;
  font-weight: 600;
  padding: 3px;
`;

type InfoBlockProps = {
  label: string,
  value: string,
  isCenter?: boolean,
  icon: any,
  iconProps: Object,
  isMobile?: boolean,
};

const InfoBlock = ({
  label,
  value,
  isCenter,
  icon: Icon,
  iconProps,
  isMobile,
}: InfoBlockProps) => (
  <InfoBlockContainer isCenter={isCenter} isMobile={isMobile}>
    <IconContainer>
      <Icon colour="#acacac" {...iconProps} />
    </IconContainer>
    <InfoContainer>
      <InfoStyling>{label}</InfoStyling>
      <InfoStyling>{value}</InfoStyling>
    </InfoContainer>
  </InfoBlockContainer>
);

InfoBlock.defaultProps = {
  isCenter: false,
  isMobile: false,
};

export default function VdpPerformance(props: Props) {
  const {
    acceleration,
    emissions,
    maxSpeed,
    maxPower,
    fuelConsumption,
  } = props.performance;
  const { isMobile } = props;

  return isMobile ? (
    <Container isMobile>
      <InfoBlock
        label="Acceleration"
        value={acceleration}
        icon={Stopwatch}
        iconProps={{ width: '4em', height: '4em' }}
        isMobile
      />
      <InfoBlock
        label="Max Speed"
        value={maxSpeed}
        icon={Mileage}
        iconProps={{ width: '3em', height: '3em' }}
        isMobile
      />
      <InfoBlock
        label="Max Power"
        value={maxPower}
        icon={Electric}
        iconProps={{ width: '3em', height: '3em' }}
        isMobile
      />
      <InfoBlock
        label="Emissions(CO2)"
        value={emissions}
        icon={Efficiency}
        iconProps={{ width: '3.5em', height: '3.5em' }}
        isMobile
      />
      <InfoBlock
        label="Fuel Consumption"
        value={fuelConsumption}
        icon={Petrol}
        iconProps={{ width: '3em', height: '3em' }}
        isMobile
      />
    </Container>
  ) : (
    <Container>
      <InfoBlockRow>
        <InfoBlock
          label="Acceleration"
          value={acceleration}
          icon={Stopwatch}
          iconProps={{ width: '4em', height: '4em' }}
        />
        <InfoBlock
          label="Max Speed"
          value={maxSpeed}
          isCenter
          icon={Mileage}
          iconProps={{ width: '3em', height: '3em' }}
        />
        <InfoBlock
          label="Max Power"
          value={maxPower}
          icon={Electric}
          iconProps={{ width: '3em', height: '3em' }}
        />
      </InfoBlockRow>
      <InfoBlockRow>
        <InfoBlock
          label="Emissions(CO2)"
          value={emissions}
          icon={Efficiency}
          iconProps={{ width: '3.5em', height: '3.5em' }}
        />
        <InfoBlock
          label="Fuel Consumption"
          value={fuelConsumption}
          isCenter
          icon={Petrol}
          iconProps={{ width: '3em', height: '3em' }}
        />
      </InfoBlockRow>
    </Container>
  );
}
VdpPerformance.defaultProps = {
  isMobile: false,
};

// @flow

const ClearContext: 'CLEAR CONTEXT' = 'CLEAR CONTEXT';
export type ClearContextAction = {
  type: typeof ClearContext,
};
const clearContext = (): ClearContextAction => ({
  type: ClearContext,
});
const ClearAction: 'CLEAR ACTION' = 'CLEAR ACTION';
export type ClearActionAction = {
  type: typeof ClearAction,
};
const clearAction = (): ClearActionAction => ({
  type: ClearAction,
});

const SetSiteContext: 'SET SITE CONTEXT' = 'SET SITE CONTEXT';
export type SetSiteContextAction = {
  type: typeof SetSiteContext,
  payload: string,
};
const setSiteContext = (site: string): SetSiteContextAction => ({
  type: SetSiteContext,
  payload: site,
});

const SetPageContext: 'SET PAGE CONTEXT' = 'SET PAGE CONTEXT';
type PageDetails = { id: string, pageName: string };
export type SetPageContextAction = {
  type: typeof SetPageContext,
  payload: PageDetails,
};
const setPageContext = (
  id: string,
  pageName: string,
): SetPageContextAction => ({
  type: SetPageContext,
  payload:
    id && pageName
      ? {
          id,
          pageName,
        }
      : null,
});

const SetBandContext: 'SET BAND CONTEXT' = 'SET BAND CONTEXT';
type BandPosition = { band: string, index: number, instanceId: string };
export type SetBandContextAction = {
  type: typeof SetBandContext,
  payload: BandPosition,
};
const setBandContext = (band: BandPosition): SetBandContextAction => ({
  type: SetBandContext,
  payload: band,
});

const SetAddPod: 'SET ADD POD' = 'SET ADD POD';
export type SetAddPodAction = {
  type: typeof SetAddPod,
  payload: BandPosition,
};
const setAddPod = (band: BandPosition): SetAddPodAction => ({
  type: SetAddPod,
  payload: band,
});

const SetDraggingContext: 'SET DRAGGING CONTEXT' = 'SET DRAGGING CONTEXT';
export type SetDraggingContextAction = {
  type: typeof SetDraggingContext,
  payload: string,
};
const setDraggingContext = (podId: ?string): SetDraggingContextAction => ({
  type: SetDraggingContext,
  payload: podId,
});

const SetClickedEditorPodContext: 'SET CLICKED EDITOR POD CONTEXT' =
  'SET CLICKED EDITOR POD CONTEXT';
export type SetClickedEditorPodContextAction = {
  type: typeof SetClickedEditorPodContext,
  payload: string,
};
const setClickedEditorPodContext = (
  podId: ?string,
): SetClickedEditorPodContextAction => ({
  type: SetClickedEditorPodContext,
  payload: podId,
});

const SetClickedPreviewPodContext: 'SET CLICKED PREVIEW POD CONTEXT' =
  'SET CLICKED PREVIEW POD CONTEXT';
export type SetClickedPreviewPodContextAction = {
  type: typeof SetClickedPreviewPodContext,
  payload: string,
};
const setClickedPreviewPodContext = (
  podId: ?string,
): SetClickedPreviewPodContextAction => ({
  type: SetClickedPreviewPodContext,
  payload: podId,
});

const SetLinkedModuleContext: 'SET LINKED MODULE CONTEXT' =
  'SET LINKED MODULE CONTEXT';
type LinkedModulePosition = { linkedModule: string, instanceId: string };
export type SetLinkedModuleContextAction = {
  type: typeof SetLinkedModuleContext,
  payload: LinkedModulePosition,
};
const setLinkedModuleContext = (
  linkedModule: string,
  instanceId: string,
): SetLinkedModuleContextAction => ({
  type: SetLinkedModuleContext,
  payload: { linkedModule, instanceId },
});

const SetAddBand: 'SET ADD BAND' = 'SET ADD BAND';
export type SetAddBandAction = {
  type: typeof SetAddBand,
  payload: string,
};
const setAddBand = (scope: string): SetAddBandAction => ({
  type: SetAddBand,
  payload: scope,
});

const SetPodContext: 'SET POD CONTEXT' = 'SET POD CONTEXT';
export type SetPodContextAction = {
  type: typeof SetPodContext,
  payload: string,
};
const setPodContext = (pod: string): SetPodContextAction => ({
  type: SetPodContext,
  payload: pod,
});

const SetSliceContext: 'SET SLICE CONTEXT' = 'SET SLICE CONTEXT';
export type SetSliceContextAction = {
  type: typeof SetSliceContext,
  payload: string,
};
const setSliceContext = (slice: string): SetSliceContextAction => ({
  type: SetSliceContext,
  payload: slice,
});

const SetBreakoutContext: 'SET BREAKOUT CONTEXT' = 'SET BREAKOUT CONTEXT';
export type SetBreakoutContextAction = {
  type: typeof SetBreakoutContext,
  payload: object,
};
const setBreakoutContext = (breakout: object): SetBreakoutContextAction => ({
  type: SetBreakoutContext,
  payload: breakout,
});

const SetPreviousBreakoutContext: 'SET PREVIOUS BREAKOUT CONTEXT' =
  'SET PREVIOUS BREAKOUT CONTEXT';
export type SetPreviousBreakoutContextAction = {
  type: typeof SetPreviousBreakoutContext,
  payload: object,
};
const setPreviousBreakoutContext = (
  breakout: object,
): SetPreviousBreakoutContextAction => ({
  type: SetPreviousBreakoutContext,
  payload: breakout,
});

const SetGlobalContext: 'SET GLOBAL CONTEXT' = 'SET GLOBAL CONTEXT';
export type SetGlobalContextAction = {
  type: typeof SetGlobalContext,
  payload: string,
};
const setGlobalContext = (globalContext: string): SetGlobalContextAction => ({
  type: SetGlobalContext,
  payload: globalContext,
});

const SetShowPageSettings: 'SET SHOW PAGE SETTINGS' = 'SET SHOW PAGE SETTINGS';
export type SetShowPageSettingsAction = {
  type: typeof SetShowPageSettings,
  payload: boolean,
};
const setShowPageSettings = (show: boolean): SetShowPageSettingsAction => ({
  type: SetShowPageSettings,
  payload: show,
});

export const actions = {
  setSiteContext,
  setPageContext,
  setBandContext,
  setSliceContext,
  setPodContext,
  setBreakoutContext,
  setGlobalContext,
  setShowPageSettings,
  setAddPod,
  setAddBand,
  setLinkedModuleContext,
  clearContext,
  setDraggingContext,
  setClickedEditorPodContext,
  setClickedPreviewPodContext,
  clearAction,
  setPreviousBreakoutContext,
};

export const constants = {
  SetSiteContext,
  SetPageContext,
  SetBandContext,
  SetPodContext,
  SetSliceContext,
  SetBreakoutContext,
  SetGlobalContext,
  SetShowPageSettings,
  SetAddPod,
  SetAddBand,
  SetLinkedModuleContext,
  ClearContext,
  SetDraggingContext,
  SetClickedEditorPodContext,
  SetClickedPreviewPodContext,
  ClearAction,
  SetPreviousBreakoutContext,
};

export type Action =
  | SetSiteContextAction
  | SetPageContextAction
  | SetBandContextAction
  | SetSliceContextAction
  | SetPodContextAction
  | SetBreakoutContextAction
  | SetGlobalContextAction
  | SetShowPageSettingsAction
  | SetAddPodAction
  | SetAddBandAction
  | SetLinkedModuleContextAction
  | ClearContextAction
  | SetDraggingContextAction
  | SetClickedEditorPodContextAction
  | ClearActionAction
  | SetClickedPreviewPodContextAction
  | SetPreviousBreakoutContext;

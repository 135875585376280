// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import media from '../../theme';
import { actions as routerActions } from '../../actions/router';
import { getVehicles as compareVehicles } from '../../shared/compare/reducer';
import { getVehicles as shortlistVehicles } from '../../shared/shortlist/reducer';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortlistActions } from '../../shared/shortlist';
import { HeadingTwo, fontStyleOverride } from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';
import Icon from '../../components/StyledIcon';
import SearchResultGridTile from '../../components/SearchResultGridTile';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  ${({ colour }) => `${colour && `background-color: ${colour}`}`};
  @media print {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px 32px;
`;

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.max.medium`
    display: none;
  `};
`;

const MobileContainer = styled.div`
  display: none;
  ${media.max.medium`
    display: flex;
    flex-direction: column;
    padding: 0 5%;
  `};
`;

const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
  ${media.max.medium`
    padding: 0 5%;
  `};
`;

const GridTilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ItemContainer = styled.div`
  width: 32%;
`;

const PagingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  font-size: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

type Props = {
  config: Object,
  globalStyling: Object,
  dispatch: Function,
  history: Object,
  actions: {
    getSimilarVehicles: Function,
    similarVehiclesNext: Function,
    similarVehiclesPrev: Function,
    similarVehiclesNextMobile: Function,
    similarVehiclesPrevMobile: Function,
    init: Function,
  },
  state: Object,
  pathByModule: string => any,
  shared: Object,
  marketInfo: Object,
};

type State = {
  similarVehicles: Object,
  vehiclesInView: Object,
};

export default class SimilarVehicles extends Component<Props, State> {
  componentDidMount() {
    const {
      actions: { init, getSimilarVehicles },
      history: {
        location: { pathname },
      },
    } = this.props;
    init();
    const vehicleId = pathname.split('/').pop();
    getSimilarVehicles(vehicleId);
  }

  render() {
    const {
      config,
      globalStyling,
      state: {
        similarVehicles,
        vehiclesInView,
        similarVehiclePageTotal,
        similarVehiclePage,
        selectedVehicleMobile,
        similarVehiclePageMobile,
        similarVehiclePageTotalMobile,
      },
      actions: {
        similarVehiclesNext,
        similarVehiclesPrev,
        similarVehiclesNextMobile,
        similarVehiclesPrevMobile,
      },
      dispatch,
      pathByModule,
      shared,
      marketInfo,
    } = this.props;

    const sharedCompareVehicles = compareVehicles(shared);

    const sharedShortListedVehicles = shortlistVehicles(shared);

    const onMoreInfoClick = (name, id) =>
      dispatch(routerActions.navigate(`${pathByModule('VDP')}/${id}`));
    const isListed = (id: string, vehicles: Vehicle[]) =>
      vehicles.find(vehicle => vehicle.id === (id || {}));
    const getCompareIconColour = (id: string) =>
      isListed(id, sharedCompareVehicles)
        ? config.compareIconListedColour
        : '#ACACAC';
    const getShortListedIconColour = (id: string) =>
      isListed(id, sharedShortListedVehicles)
        ? config.shortlistIconListedColour
        : '#ACACAC';
    const compareVehicle = vehicle =>
      dispatch(compareActions.toggleVehicle(vehicle));
    const shortlistVehicle = vehicle =>
      dispatch(shortlistActions.toggleVehicle(vehicle));

    const desktopPaging = `${similarVehiclePage} / ${similarVehiclePageTotal}`;
    const mobilePaging = `${similarVehiclePageMobile} / ${similarVehiclePageTotalMobile}`;
    return (
      <Fragment>
        {similarVehicles && similarVehicles.length > 0 && (
          <Container colour={config.backgroundColour}>
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              {config.translations.similarVehiclesTitle && (
                <HeaderContainer>
                  <HeadingTwo
                    styleOverride={() =>
                      `${fontStyleOverride(config.headlineFont)}`
                    }
                  >
                    {config.translations.similarVehiclesTitle}
                  </HeadingTwo>
                </HeaderContainer>
              )}
              <DesktopContainer>
                {vehiclesInView && (
                  <TilesContainer>
                    {similarVehiclePageTotal > 1 && (
                      <IconWrapper onClick={similarVehiclesPrev}>
                        <Icon
                          link={config.leftArrowIcon}
                          width={40}
                          height={120}
                        />
                      </IconWrapper>
                    )}
                    <GridTilesContainer>
                      {vehiclesInView.map(vehicle => (
                        <ItemContainer key={vehicle.id}>
                          <SearchResultGridTile
                            config={config}
                            key={vehicle.id}
                            align="left"
                            vehicle={vehicle}
                            onMoreInfoClicked={() =>
                              onMoreInfoClick('VDP', vehicle.id)
                            }
                            translations={config.translations}
                            compareVehicle={compareVehicle}
                            shortlistVehicle={shortlistVehicle}
                            shortListedIconColour={getShortListedIconColour(
                              vehicle.id,
                            )}
                            compareIconColour={getCompareIconColour(vehicle.id)}
                            renderTopRightIcon={() => {}}
                            globalStyling={globalStyling}
                            visibility={config.visibility}
                            locale={marketInfo.locale}
                            badgeStyling={config.badgeStyling}
                            handleZeroPriceAsText={config.handleZeroPriceAsText}
                            showNumberOfImagesInsteadOfViews={
                              config.showNumberOfImagesInsteadOfViews
                            }
                            headerItemFont={config.headerItemFont}
                          />
                        </ItemContainer>
                      ))}
                      {vehiclesInView.length % 3 === 2 && <ItemContainer />}
                    </GridTilesContainer>
                    {similarVehiclePageTotal > 1 && (
                      <IconWrapper onClick={similarVehiclesNext}>
                        <Icon
                          link={config.rightArrowIcon}
                          width={40}
                          height={120}
                        />
                      </IconWrapper>
                    )}
                  </TilesContainer>
                )}
                {similarVehiclePageTotal > 1 && (
                  <PagingContainer>{desktopPaging}</PagingContainer>
                )}
              </DesktopContainer>
              <MobileContainer>
                {similarVehicles &&
                  similarVehicles.map((vehicle, index) => (
                    <Fragment key={vehicle.id}>
                      {selectedVehicleMobile === index && (
                        <SearchResultGridTile
                          config={config}
                          key={vehicle.id}
                          align="left"
                          vehicle={vehicle}
                          onMoreInfoClicked={() =>
                            onMoreInfoClick('VDP', vehicle.id)
                          }
                          translations={config.translations}
                          compareVehicle={compareVehicle}
                          shortlistVehicle={shortlistVehicle}
                          shortListedIconColour={getShortListedIconColour(
                            vehicle.id,
                          )}
                          compareIconColour={getCompareIconColour(vehicle.id)}
                          renderTopRightIcon={() => {}}
                          globalStyling={globalStyling}
                          visibility={config.visibility}
                          locale={marketInfo.locale}
                          badgeStyling={config.badgeStyling}
                          handleZeroPriceAsText={config.handleZeroPriceAsText}
                          showNumberOfImagesInsteadOfViews={
                            config.showNumberOfImagesInsteadOfViews
                          }
                          headerItemFont={config.headerItemFont}
                        />
                      )}
                    </Fragment>
                  ))}
                <PagingContainer>
                  {similarVehiclePageTotalMobile > 1 && (
                    <IconWrapper onClick={similarVehiclesNextMobile}>
                      <Icon
                        link={config.leftArrowIcon}
                        width={32}
                        height={32}
                      />
                    </IconWrapper>
                  )}
                  {mobilePaging}
                  {similarVehiclePageTotalMobile > 1 && (
                    <IconWrapper onClick={similarVehiclesPrevMobile}>
                      <Icon
                        link={config.rightArrowIcon}
                        width={32}
                        height={32}
                      />
                    </IconWrapper>
                  )}
                </PagingContainer>
              </MobileContainer>
            </ContentWrapper>
          </Container>
        )}
      </Fragment>
    );
  }
}

import Icon from './Icon';

export { default as component } from './HeroCarousel';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Hero Carousel';
export const id = 'HeroCarousel';
export const availableToAdministration = true;
export const description = 'Horizontal image slider (up to 8 slides)';
export const icon = Icon;

export function template() {
  return {
    items: {
      translations: {
        header: 'Placeholder',
        body: 'placeholder',
        topLink: 'Placeholder',
      },
      slideContentStyle: {
        label: 'slideContentOne',
        value: 'slideContentOne',
      },
      paginationStyle: {
        label: 'paginationOne',
        value: 'paginationOne',
      },
      align: 'left',
      topLink: {
        url: 'search',
        label: 'search',
        on: true,
      },
      midLink: {
        url: 'search',
        label: 'search',
        on: false,
      },
      bottomLink: {
        url: 'search',
        label: 'search',
        on: false,
      },
      mediaContent: {
        label: 'placeholder',
        value:
          'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png',
      },
    },
  };
}

export const searchable = true;
export const getIndexContent = ({
  config: { items = [] } = {},
  relativePath,
  instanceId,
}) =>
  [
    ...items.map(({ translations: { header, body } }) => ({
      title: header,
      content: body,
    })),
    ...items.map(({ translations: { header, topLink } }) => ({
      title: header,
      content: topLink,
    })),
    ...items.map(({ translations: { header, midLink } }) => ({
      title: header,
      content: midLink,
    })),
    ...items.map(({ translations: { header, bottomLink } }) => ({
      title: header,
      content: bottomLink,
    })),
  ]
    .map(({ title, content }, index) => ({
      key: `${instanceId}-${index}`,
      title,
      content,
      link: relativePath,
    }))
    .filter(({ title, content }) => !!title || !!content);

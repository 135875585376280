// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import media from '../theme';

import { SelectField, InputField } from './FormFields';
import { Button, HeadingTwo, Paragraph } from './Global';
import { localiseCurrency } from '../shared/localisation/numbers';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const VehicleDetailsContainer = styled.div.withConfig({
  displayName: 'VehicleDetailsContainer',
})`
  width: auto;
  margin: 0 170px;
  ${media.max.medium`
    margin: 0 5%;
  `};
`;
const HeadingTextWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 35px;
`;
const InputRow = styled.div`
  ${media.min.medium`
    display: flex;
    justify-content: space-between;
  `};
`;
const SelectWrapper = styled.div`
  width: 100%;
  ${media.min.medium`
    width: 48%;
  `};
`;
const SelectWrapperDoubled = styled(SelectWrapper)`
  display: flex;
  justify-content: space-between;
`;
const ErrorText = styled.div`
  color: #9e1b32;
  font-size: 14px;
  margin-right: 10px;
`;
const ActionButtonContainer = styled.div`
  width: auto;
  margin: 0 5% 20px 5%;
  ${media.min.medium`
  margin: 0 30px 30px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`};
`;
const Image = styled.img`
  height: 200px;
`;
type Props = {
  vehicleInfo: Object,
  translations: Object,
  getValuationMakes: () => {},
  valuationMakes: Object[],
  getValuationModels: string => void,
  valuationModels: Object[],
  getValuationVersions: (makeId: string, modelId: string) => void,
  valuationVersions: Object[],
  getValuationYears: (
    makeId: string,
    modelId: string,
    versionId: string,
  ) => void,
  valuationYears: Object[],
  getValuationMonths: (makeId: string, modelId: string, year: string) => void,
  valuationMonths: Object[],
  getVehicleValuation: (
    make: Object,
    model: Object,
    version: string,
    year: string,
    month: string,
    odometer: string,
  ) => void,
  vehicleValuation: Object,
  editVehicleValuation: string => void,
  updateMyDealWithValuation: () => void,
  vehicleValuationRequestLimit: boolean,
  networkError: boolean,
  globalStyling: Object,
};
type State = {
  make: Object,
  model: Object,
  version: Object,
  regMonth: Object,
  regYear: Object,
  odometer: string,
  errors: Object,
};

export default class VehicleValuation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      make: {},
      model: {},
      version: {},
      regMonth: {},
      regYear: {},
      odometer: '',
      errors: {
        make: false,
        model: false,
        version: false,
        regMonth: false,
        regYear: false,
        odometer: false,
      },
    };
  }

  componentDidMount = () => {
    this.props.getValuationMakes();
  };

  onInputChange = (formKey: string, value: string) => {
    this.setState({
      [formKey]: value,
    });
  };

  onlyNumericValues = (event: Object) => {
    if (!event.key.match(/([0-9])/g)) {
      event.preventDefault();
    }
  };

  onSelectChange = ({
    keyValue,
    value,
    label,
  }: {
    keyValue: string,
    value: any,
    label: string,
  }) => {
    let id;
    let resetValues;
    const {
      valuationMakes,
      getValuationModels,
      valuationModels,
      getValuationVersions,
      valuationVersions,
      valuationYears,
      getValuationYears,
      getValuationMonths,
      valuationMonths,
    } = this.props;
    if (keyValue === 'make') {
      ({ id } = valuationMakes.find(make => make.name === value));
      getValuationModels(id);
      resetValues = {
        model: {},
        regYear: {},
        regMonth: {},
        version: {},
      };
    }
    if (keyValue === 'model') {
      ({ id } = valuationModels.find(model => model.name === value));
      getValuationVersions(this.state.make.id, id);
      resetValues = {
        regYear: {},
        regMonth: {},
        version: {},
      };
    }
    if (keyValue === 'version') {
      ({ id } = valuationVersions.find(version => version.name === value));
      getValuationYears(this.state.make.id, this.state.model.id, id);
    }
    if (keyValue === 'regYear') {
      ({ id } = valuationYears.find(year => year.value === value));
      getValuationMonths(this.state.make.id, this.state.model.id, value);
      resetValues = {
        regMonth: {},
      };
    }
    if (keyValue === 'regMonth') {
      ({ id } = valuationMonths.find(month => month.value === value));
    }
    this.setState({
      ...resetValues,
      [keyValue]: { id, label, value },
      errors: {
        make: false,
        model: false,
        version: false,
        regMonth: false,
        regYear: false,
      },
    });
  };

  onSubmit = () => {
    const errors = this.validate();
    if (Object.values(errors).some(v => !!v)) {
      this.setState(() => ({ errors }));
    } else {
      this.props.getVehicleValuation(
        this.state.make,
        this.state.model,
        this.state.version.id,
        this.state.regYear.id,
        this.state.regMonth.id,
        this.state.odometer,
      );
    }
  };

  validate = () => ({
    make:
      Object.keys(this.state.make).length === 0 || this.state.make.value === '',
    model:
      Object.keys(this.state.model).length === 0 ||
      this.state.model.value === '',
    version:
      Object.keys(this.state.version).length === 0 ||
      this.state.version.value === '',
    regMonth:
      Object.keys(this.state.regMonth).length === 0 ||
      this.state.regMonth.value === '',
    regYear:
      Object.keys(this.state.regYear).length === 0 ||
      this.state.regYear.value === '',
    odometer: !this.state.odometer.match(/([0-9])/g),
  });

  render = () => {
    const {
      vehicleInfo,
      translations,
      valuationMakes,
      valuationModels,
      valuationVersions,
      valuationYears,
      valuationMonths,
      vehicleValuation,
      updateMyDealWithValuation,
      vehicleValuationRequestLimit,
      editVehicleValuation,
      globalStyling,
      networkError,
    } = this.props;
    const makeDisabled = networkError;
    const modelDisabled = networkError || !this.state.make.value;
    const versionDisabled =
      networkError || !this.state.make.value || !this.state.model.value;
    const yearDisabled =
      networkError ||
      !this.state.make.value ||
      !this.state.model.value ||
      !this.state.version.value;
    const monthDisabled =
      networkError ||
      !this.state.make.value ||
      !this.state.model.value ||
      !this.state.regYear.value;

    return !vehicleValuation ? (
      <div style={{ backgroundColor: '#ffffff' }}>
        <VehicleDetailsContainer>
          <HeadingTextWrapper>
            <HeadingTwo
              styleOverride={() => `
                color: #141414;
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
              `}
            >
              {translations.vehicleValuationHeading}
            </HeadingTwo>
            <Paragraph
              styleOverride={() => `
                color: #474747;
                font-size: 14px;
                line-height: 20px;
              `}
            >
              {translations.vehicleValuationSubHeading}
            </Paragraph>
          </HeadingTextWrapper>
          <InputRow>
            <SelectWrapper>
              <SelectField
                keyValue="make"
                value={this.state.make.value}
                onChange={this.onSelectChange}
                label={translations.vehicleValuationsMakeLabel}
                options={[
                  {
                    label: translations.vehicleValuationsMakePlaceholder,
                    value: '',
                  },
                  ...valuationMakes.map(make => ({
                    label: make.name,
                    value: make.name,
                  })),
                ]}
                error={this.state.errors.make}
                disabled={makeDisabled}
                required
              />
            </SelectWrapper>
            <SelectWrapper>
              <SelectField
                keyValue="model"
                value={this.state.model.value}
                onChange={this.onSelectChange}
                label={translations.vehicleValuationsModelLabel}
                options={[
                  {
                    label: translations.vehicleValuationsModelPlaceholder,
                    value: '',
                  },
                  ...valuationModels.map(model => ({
                    label: model.name,
                    value: model.name,
                  })),
                ]}
                disabled={modelDisabled}
                error={this.state.errors.model}
                required
              />
            </SelectWrapper>
          </InputRow>
          <InputRow>
            <SelectWrapper>
              <SelectField
                keyValue="version"
                value={this.state.version.value}
                onChange={this.onSelectChange}
                label={translations.vehicleValuationsVersionLabel}
                options={[
                  {
                    label: translations.vehicleValuationsVersionPlaceholder,
                    value: '',
                  },
                  ...valuationVersions.map(version => ({
                    label: version.name,
                    value: version.name,
                  })),
                ]}
                disabled={versionDisabled}
                error={this.state.errors.version}
                required
              />
            </SelectWrapper>
            <SelectWrapperDoubled>
              <SelectWrapper>
                <SelectField
                  keyValue="regYear"
                  value={this.state.regYear.value}
                  onChange={this.onSelectChange}
                  label={translations.vehicleValuationsRegLabel}
                  options={[
                    {
                      label: translations.vehicleValuationsYearPlaceholder,
                      value: '',
                    },
                    ...valuationYears,
                  ]}
                  disabled={yearDisabled}
                  error={this.state.errors.regYear}
                  required
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectField
                  keyValue="regMonth"
                  value={this.state.regMonth.value}
                  onChange={this.onSelectChange}
                  label="&nbsp;"
                  options={[
                    {
                      label: translations.vehicleValuationsMonthPlaceholder,
                      value: '',
                    },
                    ...valuationMonths,
                  ]}
                  disabled={monthDisabled}
                  error={this.state.errors.regMonth}
                />
              </SelectWrapper>
            </SelectWrapperDoubled>
          </InputRow>
          <InputRow>
            <SelectWrapper>
              <InputField
                value={this.state.odometer}
                keyValue="odometer"
                placeholder={translations.vehicleValuationsOdometerPlaceholder}
                onChange={this.onInputChange}
                onKeyPress={this.onlyNumericValues}
                label={translations.vehicleValuationsOdometerLabel}
                error={this.state.errors.odometer}
                required
              />
            </SelectWrapper>
          </InputRow>
          <Paragraph
            styleOverride={() => `
              color: #7e7e7e;
              font-size: 12px;
              margin-top: 30px;
              margin-bottom: 30px;
            `}
          >
            {translations.valuationDisclaimer}
          </Paragraph>
        </VehicleDetailsContainer>
        <ActionButtonContainer>
          {Object.values(this.state.errors).some(v => !!v) && !networkError && (
            <ErrorText>{translations.vehicleValuationsInvalid}</ErrorText>
          )}
          {vehicleValuationRequestLimit && (
            <ErrorText>{translations.vehicleValuationsRequestLimit}</ErrorText>
          )}
          {networkError && (
            <ErrorText>{translations.valuationNetworkError}</ErrorText>
          )}
          <Button
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
            text={translations.vehicleValuationsCTA}
            applyStyle="primary"
            styleOverride={() => {}}
            mobileStyleOverride={() => {}}
            onClick={this.onSubmit}
          />
        </ActionButtonContainer>
      </div>
    ) : (
      <div style={{ backgroundColor: '#ffffff' }}>
        <VehicleDetailsContainer>
          <HeadingTextWrapper>
            <HeadingTwo
              styleOverride={() => `
              color: #141414;
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
            `}
            >
              {translateFromTemplate(
                'valuationSuccessHeading',
                {
                  VALUE: localiseCurrency(
                    vehicleValuation[Object.keys(vehicleValuation)[0]]
                      .valuation,
                    vehicleInfo.locale,
                    vehicleInfo.price.currency,
                    0,
                  ),
                },
                translations,
              )}
            </HeadingTwo>
            <Image
              src={vehicleValuation[Object.keys(vehicleValuation)[0]].image}
            />
            <HeadingTwo
              styleOverride={() => `
              color: #141414;
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
            `}
            >
              {vehicleValuation[Object.keys(vehicleValuation)[0]].vehicle}
            </HeadingTwo>
          </HeadingTextWrapper>
          <Paragraph
            styleOverride={() => `
              color: #7e7e7e;
              font-size: 12px;
              margin-top: 30px;
              margin-bottom: 30px;
            `}
          >
            {translations.valuationDisclaimer}
          </Paragraph>
        </VehicleDetailsContainer>
        <ActionButtonContainer>
          <Button
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
            text={translations.editValuationCTA}
            applyStyle="primary"
            styleOverride={() => 'margin-right: 20px;'}
            mobileStyleOverride={() => {}}
            onClick={() => editVehicleValuation(vehicleInfo.id)}
          />
          <Button
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
            text={translations.valuationSuccessCTA}
            applyStyle="primary"
            styleOverride={() => {}}
            mobileStyleOverride={() => {}}
            onClick={updateMyDealWithValuation}
          />
        </ActionButtonContainer>
      </div>
    );
  };
}

import { useDispatch } from 'react-redux';
import uuid from 'uuid/v1';

import { actions as contextActions } from '../../state/actions/context';
import { breakoutType } from '../BreakoutWrapper';

const useCreateBlog = () => {
  const dispatch = useDispatch();
  const createBlog = () => {
    const newBlog = { id: uuid(), config: {} };
    dispatch({ type: 'ADD_BLOG', payload: newBlog });
    dispatch(contextActions.setGlobalContext('Edit Blog Content'));
    dispatch(
      contextActions.setBreakoutContext({
        name: breakoutType.editPost,
        data: newBlog,
      }),
    );
  };

  return {
    createBlog,
  };
};

export default useCreateBlog;

import React from 'react';
import Fade from './Fade';
import StickyWrapper from './StickyWrapper';
import SearchFilterLocationSearchBarContainer from '../containers/SearchFilterLocationSearchBarContainer';

export default ({
  translations,
  dropdownTheme,
  count,
  display,
  globalStyling,
  pathByModule,
  distanceFilterInitialSelectedIndex,
  locationIconUrl,
  searchCountryCode,
}) => (
  <Fade display={display}>
    <StickyWrapper id="stickywrapper" layout="bottom">
      <SearchFilterLocationSearchBarContainer
        count={count}
        translations={translations}
        dropdownTheme={dropdownTheme}
        globalStyling={globalStyling}
        pathByModule={pathByModule}
        distanceFilterInitialSelectedIndex={distanceFilterInitialSelectedIndex}
        locationIconUrl={locationIconUrl}
        searchCountryCode={searchCountryCode}
      />
    </StickyWrapper>
  </Fade>
);

import React from 'react';

const LeftAlignDisabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#757575" d="M1.5 1.5h15v15h-15z" />
      <path fill="#757575" d="M1 1h2v16H1zM4 6h11v2H4zM4 10h6v2H4z" />
    </g>
  </svg>
);

export default LeftAlignDisabled;

// @flow

import { constants } from '../actions/pageTemplates';
import type { Action } from '../actions/pageTemplates';

export type PageTemplate = {
  pageTemplateId: string,
  name: string,
  template: string,
};

type State = {
  pageTemplates: PageTemplate[],
  error: ?Error,
};

const initialState: State = {
  pageTemplates: [],
  error: null,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.LoadPageTemplatesSuccess: {
      return {
        pageTemplates: action.payload,
        error: null,
      };
    }
    case constants.LoadPageTemplatesFailure:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

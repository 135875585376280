// @flow
/* eslint-disable import/prefer-default-export */
import { currencySymbolMap } from './currencySymbolMap';

export const getSymbolFromCurrency = (currencyCode: string) => {
  if (typeof currencyCode !== 'string') return undefined;
  const code = currencyCode.toUpperCase();
  if (!Object.keys(currencySymbolMap).find(c => c === code)) return undefined;

  return currencySymbolMap[code];
};

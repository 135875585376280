// @flow
import React from 'react';
import Chunky from './Chunky';

export default function ThinRight({ ...props }: IconProps) {
  return <Chunky {...props} />;
}

ThinRight.defaultProps = {
  width: '1em',
  height: '1em',
  colour: '#fff',
  background: null,
};

// @flow

import React from 'react';
import styled from 'styled-components';

import { InputField } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const namedDiv = displayName => styled.div.withConfig({ displayName });

const Container = namedDiv('Container')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Left = namedDiv('Left')`
  display: flex;
  flex: 2 0 0%;
  font-size: 13px;
`;

const Right = namedDiv('Right')`
  display: flex;
  flex: 0 0 0%;
  justify-content: flex-end;
`;

export default function Opacity({
  name,
  values: { id: value },
  ids: { id },
  onChange,
}: FieldProps) {
  const input = {
    value,
    onChange: event => onChange(id, event.target.value),
    step: '0.1',
  };
  return (
    <Container>
      <Left>
        <strong>{name}</strong>
      </Left>
      <Right>
        <InputField
          input={input}
          type="number"
          maxInputWidth={54}
          name={name}
          initialValue={value}
          inputFlexMode="1"
          min="0"
          max="1"
        />
      </Right>
    </Container>
  );
}

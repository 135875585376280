// @flow
import styled from 'styled-components';

export default styled.div`
  &&& {
    width: ${props => props.width};
    height: ${props => props.height};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
    }

    background-color: ${props => props.background};
    svg > rect:not([fill='none']) {
      fill: ${props => props.colour};
    }
    svg > path:not([fill]) {
      fill: ${props => props.colour};
    }
    svg {
      width: ${props => props.width};
      height: ${props => props.height};
    }
    ${({ direction }) =>
      direction === 'rtl'
        ? 'transform:  scaleX(-1);'
        : 'transform:  scaleX(1);'};
  }
`;

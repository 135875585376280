// @flow
import React from 'react';
import styled from 'styled-components';

import { CookiePolicyWrapper, CookiePolicyContent } from 'cms-modules';

const PreviewCookiePolicyWrapper = styled(CookiePolicyWrapper)`
  position: absolute;
`;

export default ({
  config: {
    translations,
    backgroundColour,
    border,
    borderColour,
    bodyFont,
    button,
    padding,
    alignment,
    placement,
    type,
  },
  globalStyling: { uiElements },
}: Props) => {
  const [vPlacement, hPlacement] = placement.split('-');
  const cookiePolicyProps = {
    backgroundColour,
    border,
    borderColour,
    padding,
    bodyFont,
    alignment,
    uiElements,
    button,
    translations,
    setVisible: () => {},
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <PreviewCookiePolicyWrapper
        hPlacement={hPlacement}
        vPlacement={vPlacement}
        type={type}
        position="absolute"
      >
        <CookiePolicyContent {...cookiePolicyProps} />
      </PreviewCookiePolicyWrapper>
    </div>
  );
};

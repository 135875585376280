// @flow

import React from 'react';
import styled from 'styled-components';

import { ConfigureList, ConfigRow } from '.';
import type { MediaType, MediaResource } from '../state/types/media';

type Props = {
  config: any,
  data: any,
  module: string,
  index: number,
  list: boolean,
  many: ?{},
  sections: any[],
  disclosed: {},
  onOpenClose: Function,
  onFieldChange: Function,
  onListFieldChange: Function,
  onAddSection: Function,
  onDeleteSection: Function,
  onToggleSection: Function,
  onUploadMediaSuccess: (resources: MediaResource[], type: MediaType) => void,
  onUploadMediaFailure: (error: string) => void,
  onDeleteMediaItem: (mediaPublicId: string, type: MediaType) => void,
};

const Container = styled.div`
  padding: ${({ theme }) => `${theme.layout.halfPadding} 10px`};
  margin: ${({ theme }) => theme.layout.standardPadding} 0;
`;

const Section = ({ fields }, config, data, onFieldChange, actions, siteId) =>
  fields.map(({ name, ids, datatype, props }) => (
    <ConfigRow
      key={Object.values(ids).join('_')}
      {...{
        name,
        ids,
        datatype,
        data,
        props,
        config,
        onFieldChange,
        actions,
        siteId,
      }}
      show
    />
  ));

export default function ConfigSection({
  config,
  data,
  index,
  list,
  many,
  sections,
  disclosed,
  onFieldChange,
  onListFieldChange,
  onAddSection,
  onDeleteSection,
  onToggleSection,
  onUploadMediaSuccess,
  onUploadMediaFailure,
  onDeleteMediaItem,
  siteId,
  saveAndSetExternalLink,
}: Props) {
  if (list) {
    return (
      <ConfigureList
        data={data}
        index={index}
        config={config}
        fields={sections}
        id={many.id}
        max={many.max}
        show={disclosed.section}
        sectionLabel={many.sectionLabel}
        buttonLabel={many.buttonLabel}
        icon={many.icon}
        onAddSection={onAddSection}
        onToggleSection={(id, i) => {
          if (many.focus && disclosed.section !== i)
            onFieldChange(many.focus, i);
          onToggleSection(id, i);
        }}
        onDeleteSection={onDeleteSection}
        onFieldChange={onListFieldChange}
        onListReOrder={onFieldChange}
        actions={{
          onUploadMediaSuccess,
          onUploadMediaFailure,
          onDeleteMediaItem,
          saveAndSetExternalLink,
        }}
        siteId={siteId}
      />
    );
  }
  return (
    <>
      {sections.map(section => {
        const actions = {
          onUploadMediaSuccess,
          onUploadMediaFailure,
          onDeleteMediaItem,
          saveAndSetExternalLink,
        };
        return (
          <Container
            key={section.fields
              .reduce((prev, curr) => [...prev, ...Object.values(curr.ids)], [])
              .join('_')}
          >
            {Section(section, config, data, onFieldChange, actions, siteId)}
          </Container>
        );
      })}
    </>
  );
}

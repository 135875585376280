// @flow
import React from 'react';
import { pods } from 'cms-modules';

const { Video } = pods.default;

export default (props: Props) => {
  return (
    <div style={{ position: 'relative', width: '100%', pointerEvents: 'none' }}>
      <Video {...props} />
    </div>
  );
};

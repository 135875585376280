// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Co2({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 22 23">
        <path d="M4.876 14.428c-2.242 0-4.024-1.575-4.024-3.88 0-2.307 1.782-3.881 4.024-3.881 1.748 0 2.726.9 3.266 1.833l-1.403.675a2.107 2.107 0 0 0-1.863-1.091c-1.333 0-2.345 1.046-2.345 2.463 0 1.418 1.012 2.464 2.345 2.464.851 0 1.541-.473 1.863-1.091l1.403.663c-.552.934-1.518 1.845-3.266 1.845zm3.875-3.88c0-2.262 1.69-3.881 4.002-3.881 2.323 0 4.013 1.62 4.013 3.88 0 2.261-1.69 3.881-4.013 3.881-2.311 0-4.002-1.62-4.002-3.88zm6.336 0c0-1.407-.92-2.464-2.334-2.464-1.414 0-2.323 1.057-2.323 2.463 0 1.395.909 2.464 2.323 2.464s2.334-1.069 2.334-2.464zm2.599 5.388v-.788c1.989-1.361 2.483-1.8 2.483-2.295 0-.416-.345-.618-.781-.618-.53 0-1.024.28-1.254.562l-.575-.686c.483-.529 1.22-.742 1.852-.742 1.057 0 1.85.495 1.85 1.406 0 .73-.643 1.372-1.977 2.294h2.012v.867h-3.61z" />
      </svg>
    </IconWrapper>
  );
}

Co2.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

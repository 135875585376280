import { takeEvery, select, put, call, all } from 'redux-saga/effects';
import { currencyConversionServiceUrl } from '../selectors/settings';
import { actions, constants } from './reducer';
import { get } from '../../helpers/http';

const exchangeRatesCache = {};

function* getExchangeRates(action) {
  const expired = timeToCheck => timeToCheck <= Date.now() + 60000;
  const baseCurrency = action.payload;
  const baseUrl = yield select(currencyConversionServiceUrl);
  const url = `${baseUrl}rates/${baseCurrency}`;

  const exchangeRatesData =
    exchangeRatesCache[baseCurrency] &&
    !expired(exchangeRatesCache[baseCurrency].retrievedAt)
      ? [yield { ...exchangeRatesCache[baseCurrency] }]
      : [
          call(get, {
            url,
          }),
        ];

  try {
    const [exchangeRates] = yield all([...exchangeRatesData]);
    if (!exchangeRatesCache[baseCurrency]) {
      exchangeRatesCache[baseCurrency] = exchangeRates.data;
      exchangeRatesCache[baseCurrency].retrievedAt = Date.now();
    }
    yield put(
      actions.getExchangeRatesSuccess(exchangeRatesCache[baseCurrency]),
    );
  } catch (error) {
    // no-op
  }
}

export default function* effects() {
  yield takeEvery(constants.GET_EXCHANGE_RATES, getExchangeRates);
}

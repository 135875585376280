// @flow
export const initialState: SearchUtilitiesState = {
  searchUtilities: [
    { url: '/searchresults?status=new', name: 'New Vehicles' },
    { url: '/searchresults?promotion=true', name: 'On Promotion' },
    { url: '/searchresults?status=retail', name: 'Used Vehicles' },
  ],
};
export default function reducer(state: SearchUtilitiesState = initialState) {
  return state;
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';

import { HeadingOne, Paragraph, fontStyleOverride } from './Global';
import LabelledCheckBox from './LabelledCheckBox';
import media from '../theme';

const CheckboxContainer = styled.div`
  display: flex;
  margin: 20px 0;
  ${media.max.large`
    width: 100%;
    flex-direction: column;
    height: 132px;
    justify-content: space-between;
  `};
`;

const Label = styled.span`
  font-size: 16px;
  color: ${({ error }) => (error ? '#9e1b32' : '#444444')};
  margin-left: 4px;
`;

const Url = styled.a`
  color: ${({ theme }) => theme.color};
`;
// this has jag colour but we are using as error colour too
const ErrorLabel = styled.span`
  color: #9e1b32;
  margin-left: 15px;
`;

const RadioButtonRow = styled.div`
  display: flex;
  align-items: center;
  div:last-of-type {
    margin-left: 40px;
  }
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  translations: Object,
  onRadioSelect: Object => void,
  onCheckBoxClick: (formKey: string, value: string | boolean) => void,
  phoneChecked: boolean,
  phoneKey: string,
  emailChecked: boolean,
  emailKey: string,
  smsChecked: boolean,
  smsKey: string,
  onColour: string,
  labelFontSize?: Number,
  checkboxDimension?: Number,
  linkColour?: Object,
  globalStyling: Object,
  marketingLink: string,
  marketingKey: string,
  marketing: string,
  marketingError: string,
  customisedServices: boolean,
  customisedServicesKey: string,
  customisedServicesError: string,
  showUserCommunication: boolean,
};

const UserConsentPreferences = ({
  translations,
  onRadioSelect,
  linkColour,
  marketingLink,
  marketingKey,
  marketing,
  marketingError,
  customisedServices,
  customisedServicesKey,
  customisedServicesError,
}: Props) => (
  <div style={{ marginBottom: '20px' }}>
    <HeadingOne
      styleOverride={() => `
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;`}
    >
      {translations.marketingHeaderText}
    </HeadingOne>
    <Paragraph
      styleOverride={() => `
        font-size: 13px;
        line-height: 16px;
        margin-top: 20px;`}
    >
      <Linkify
        component={({ children, ...props }) => (
          <Url {...props} theme={{ color: linkColour && linkColour.value }}>
            {children}
          </Url>
        )}
        properties={{ target: '_blank' }}
      >
        {translations.marketingConsentContentOne}
      </Linkify>
    </Paragraph>
    <Url
      href={marketingLink}
      target="_blank"
      theme={{ color: linkColour && linkColour.value }}
    >
      {translations.marketingLinkText}
    </Url>
    <Paragraph
      styleOverride={() => `
        font-size: 13px;
        line-height: 16px;
        margin-top: 20px;`}
    >
      <Linkify
        component={({ children, ...props }) => (
          <Url {...props} theme={{ color: linkColour && linkColour.value }}>
            {children}
          </Url>
        )}
        properties={{ target: '_blank' }}
      >
        {translations.marketingConsentContentTwo}
      </Linkify>
    </Paragraph>
    <Paragraph styleOverride={() => 'text-transform: uppercase'}>
      {translations.marketingConsentSubheaderOne}
    </Paragraph>
    <Paragraph
      styleOverride={() => `
        font-size: 13px;
        line-height: 16px;
        margin-top: 20px;`}
    >
      <Linkify
        component={({ children, ...props }) => (
          <Url {...props} theme={{ color: linkColour && linkColour.value }}>
            {children}
          </Url>
        )}
        properties={{ target: '_blank' }}
      >
        {translations.marketingConsentContentThree}
      </Linkify>
    </Paragraph>
    <RadioButtonRow>
      <RadioButtonWrapper>
        <input
          checked={marketing === 'yes'}
          onChange={event => onRadioSelect(event)}
          type="radio"
          id="marketingYes"
          name={marketingKey}
          value="yes"
        />
        <Label error={marketingError} htmlFor="marketingYes">
          {translations.marketingConsentYes}
        </Label>
      </RadioButtonWrapper>
      <RadioButtonWrapper>
        <input
          checked={marketing === 'no'}
          onChange={event => onRadioSelect(event)}
          type="radio"
          id="marketingNo"
          name={marketingKey}
          value="no"
        />
        <Label error={marketingError} htmlFor="marketingNo">
          {translations.marketingConsentNo}
        </Label>
      </RadioButtonWrapper>
      {marketingError && (
        <ErrorLabel>{translations.formValidationRequired}</ErrorLabel>
      )}
    </RadioButtonRow>
    <Paragraph styleOverride={() => 'text-transform: uppercase'}>
      {translations.marketingConsentSubheaderTwo}
    </Paragraph>
    <Paragraph
      styleOverride={() => `
        font-size: 13px;
        line-height: 16px;
        margin-top: 20px;`}
    >
      <Linkify
        component={({ children, ...props }) => (
          <Url {...props} theme={{ color: linkColour && linkColour.value }}>
            {children}
          </Url>
        )}
        properties={{ target: '_blank' }}
      >
        {translations.marketingConsentContentFour}
      </Linkify>
    </Paragraph>
    <RadioButtonRow>
      <RadioButtonWrapper>
        <input
          checked={customisedServices === 'yes'}
          onChange={event => onRadioSelect(event)}
          type="radio"
          id="marketingYes"
          name={customisedServicesKey}
          value="yes"
        />
        <Label error={customisedServicesError} htmlFor="marketingYes">
          {translations.marketingConsentYes}
        </Label>
      </RadioButtonWrapper>
      <RadioButtonWrapper>
        <input
          checked={customisedServices === 'no'}
          onChange={event => onRadioSelect(event)}
          type="radio"
          id="marketingNo"
          name={customisedServicesKey}
          value="no"
        />
        <Label error={customisedServicesError} htmlFor="marketingNo">
          {translations.marketingConsentNo}
        </Label>
      </RadioButtonWrapper>
      {customisedServicesError && (
        <ErrorLabel>{translations.formValidationRequired}</ErrorLabel>
      )}
    </RadioButtonRow>
  </div>
);

const UserCommunicationPreferences = ({
  translations,
  onCheckBoxClick,
  phoneChecked,
  phoneKey,
  emailChecked,
  emailKey,
  smsChecked,
  smsKey,
  onColour,
  labelFontSize,
  checkboxDimension,
  linkColour,
  enquiryFormStyles = {},
  globalStyling,
}: Props) => (
  <Fragment>
    <HeadingOne
      styleOverride={() => `
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 20px;
          ${fontStyleOverride(enquiryFormStyles.headerFont)}
          `}
    >
      {translations.dataConsentHeader}
    </HeadingOne>
    <Paragraph
      styleOverride={() => `
        font-size: 13px;
        line-height: 16px;
        margin: 20px 0 0;
        ${fontStyleOverride(enquiryFormStyles.paragraphFont)}
        `}
    >
      <Linkify
        component={({ children, ...props }) => (
          <Url {...props} theme={{ color: linkColour && linkColour.value }}>
            {children}
          </Url>
        )}
        properties={{ target: '_blank' }}
      >
        {translations.dataConsentContent}
      </Linkify>
    </Paragraph>
    {translations.dataConsentContactHeader && (
      <Paragraph
        styleOverride={() => `
            font-size: 16px;
            line-height: 16px;
            margin: 30px 0 0`}
      >
        {translations.dataConsentContactHeader}
      </Paragraph>
    )}
    <CheckboxContainer>
      <LabelledCheckBox
        onClick={() => onCheckBoxClick(phoneKey, !phoneChecked)}
        checked={phoneChecked}
        label={translations.dataConsentPhoneLabel}
        onColour={onColour}
        labelFontSize={enquiryFormStyles.labelFontSize || labelFontSize}
        checkboxDimension={checkboxDimension}
        globalStyling={globalStyling}
      />
      <LabelledCheckBox
        onClick={() => onCheckBoxClick(emailKey, !emailChecked)}
        checked={emailChecked}
        label={translations.dataConsentEmailLabel}
        onColour={onColour}
        labelFontSize={enquiryFormStyles.labelFontSize || labelFontSize}
        checkboxDimension={checkboxDimension}
        globalStyling={globalStyling}
      />
      <LabelledCheckBox
        onClick={() => onCheckBoxClick(smsKey, !smsChecked)}
        checked={smsChecked}
        label={translations.dataConsentSmsLabel}
        onColour={onColour}
        labelFontSize={enquiryFormStyles.labelFontSize || labelFontSize}
        checkboxDimension={checkboxDimension}
        globalStyling={globalStyling}
      />
    </CheckboxContainer>
  </Fragment>
);

export default function PersonalDataConsent(props: Props) {
  return (
    <div>
      {props.showUserCommunication ? (
        <UserCommunicationPreferences {...props} />
      ) : (
        <UserConsentPreferences {...props} />
      )}
    </div>
  );
}

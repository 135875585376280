// @flow
import React from 'react';
import styled from 'styled-components';
import { ButtonWithConfirm, Button } from 'cms-ui';
import EditPageContainer from '../containers/EditPageContainer';
import type { Theme } from '../theme';

type Props = {
  page: Function,
};

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const P = styled.p`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary05};
  text-align: center;
`;

const Em = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary03};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormLabel};
  font-weight: 600;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};
  display: block;
`;

const Btn = styled(Button)`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};
`;

const renderModalContents = onClick => closeSelfFn => (
  <Center>
    <P>
      <Em>Are you sure you want to permanently delete this page?</Em>
      All the page content will be lost
    </P>
    <Btn small fullWidth danger textTransform="uppercase" onClick={onClick}>
      Yes, delete page
    </Btn>
    <Btn small fullWidth textTransform="uppercase" onClick={closeSelfFn}>
      Cancel
    </Btn>
  </Center>
);

const renderDeletePageButton = onClick => (
  <ButtonWithConfirm
    buttonText="Delete Page"
    renderModalContents={closeSelfFn =>
      renderModalContents(onClick)(closeSelfFn)
    }
  />
);

export default function PageSettings({ page }: Props) {
  return (
    <EditPageContainer
      renderButton={page.required ? null : renderDeletePageButton}
      page={page}
    />
  );
}

export const constants = {
  getFeaturedVehicle: 'GET_FEATURED_VEHICLE',
  getFeaturedVehicleSuccess: 'GET_FEATURED_VEHICLE_SUCCESS',
  getFeaturedVehicleFailure: 'GET_FEATURED_VEHICLE_FAILURE',
};

export const actions = {
  getFeaturedVehicle: vehicleId => ({
    type: constants.getFeaturedVehicle,
    payload: vehicleId,
  }),
  getFeaturedVehicleSuccess: payload => ({
    type: constants.getFeaturedVehicleSuccess,
    payload,
  }),
  getFeaturedVehicleFailure: () => ({
    type: constants.getFeaturedVehicleFailure,
  }),
};

export function reducer(state = [], action) {
  const { type, payload: vehicle } = action;
  switch (type) {
    case constants.getFeaturedVehicleSuccess:
      return {
        ...state,
        [vehicle.id]: vehicle,
      };
    default:
      return state;
  }
}

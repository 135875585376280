// @flow
import React from 'react';
import styled from 'styled-components';
import GridEnabled from '../JaguarIcons/SearchResults/GridEnabled';
import GridDisabled from '../JaguarIcons/SearchResults/GridDisabled';
import ListEnabled from '../JaguarIcons/SearchResults/ListEnabled';
import ListDisabled from '../JaguarIcons/SearchResults/ListDisabled';

import media from '../../theme';

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  width: ${props => (props.filtersExpanded ? '100%' : '50%')};
  justify-content: flex-end;
  ${media.max.large`
    width: 100%;
    height: auto;
  `};
`;
const Sort = styled.div`
  display: flex;
  ${media.max.large`
    width: 100%;
  `};
`;
const Button = styled.button`
  padding: 0;
  margin: 0 5px 0 0;
  border: none;
  background: none;
  min-height: 45px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:last-child {
    margin: 0 0 0 5px;
  }
`;
const Select = styled.select`
  ${({ fontFamily }) => `${fontFamily && `font-family: ${fontFamily}`}`};
  margin-right: 10px;
  width: 250px;
  height: 45px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid #999999;
  padding-left: 15px;
  padding-right: 15px;
  color: #444444;
  background-color: #fff;
  ${media.max.large`
    width: 100%;
    margin-right: 0;
  `};
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.img`
  width: 45px;
  height: 45px;
`;

const ButtonContainer = styled.div`
  ${media.max.large`
    display: none;
  `};
`;

const SortButtonsRenderer = ({
  selectedView,
  onViewChange,
  gridSelected,
  gridDeselected,
  listSelected,
  listDeselected,
  optionalSelectedColour,
  optionalSelectedBgColour,
}) => {
  switch (selectedView) {
    case 'GRID':
      return (
        <ButtonContainer>
          <Button
            data-qa-hook="sort-button-grid"
            onClick={() => onViewChange('GRID')}
          >
            {gridSelected ? (
              <IconWrapper src={gridSelected} alt="" />
            ) : (
              <GridEnabled
                background={optionalSelectedBgColour}
                colour={optionalSelectedColour}
                width="45px"
                height="45px"
              />
            )}
          </Button>
          <Button
            data-qa-hook="sort-button-list"
            onClick={() => onViewChange('LIST')}
          >
            {listDeselected ? (
              <IconWrapper src={listDeselected} alt="" />
            ) : (
              <ListDisabled width="45px" height="45px" />
            )}
          </Button>
        </ButtonContainer>
      );
    case 'LIST':
      return (
        <ButtonContainer>
          <Button
            data-qa-hook="sort-button-grid"
            onClick={() => onViewChange('GRID')}
          >
            {gridDeselected ? (
              <IconWrapper src={gridDeselected} alt="" />
            ) : (
              <GridDisabled width="45px" height="45px" />
            )}
          </Button>
          <Button
            data-qa-hook="sort-button-list"
            onClick={() => onViewChange('LIST')}
          >
            {listSelected ? (
              <IconWrapper src={listSelected} alt="" />
            ) : (
              <ListEnabled
                background={optionalSelectedBgColour}
                colour={optionalSelectedColour}
                width="45px"
                height="45px"
              />
            )}
          </Button>
        </ButtonContainer>
      );
    default:
      return null;
  }
};

type Props = {
  sortOptions: Object,
  sortValue: string,
  filtersExpanded?: boolean,
  onSortChange: Function,
  selectedView: 'GRID' | 'LIST',
  onViewChange: Function,
  translations: Object,
  showDistance: boolean,
  showMileage: boolean,
  showAge: boolean,
  fontFamily: string,
  optionalSelectedColour: string,
  optionalSelectedBgColour: string,
  gridSelected: string,
  gridDeselected: string,
  listSelected: string,
  listDeselected: string,
};

export default function SortMenuBar({
  sortOptions,
  sortValue,
  filtersExpanded,
  onSortChange,
  selectedView,
  onViewChange,
  translations,
  showDistance,
  showMileage = true,
  showAge = true,
  fontFamily,
  optionalSelectedColour,
  optionalSelectedBgColour,
  gridSelected,
  gridDeselected,
  listSelected,
  listDeselected,
}: Props) {
  return (
    <SortContainer filtersExpanded={filtersExpanded}>
      <Sort>
        <Select
          data-qa-hook="sort-dropdown"
          value={sortValue}
          onChange={e => onSortChange(e.target.value)}
          fontFamily={fontFamily}
        >
          <option value={sortOptions.priceAsc.value}>
            {translations.searchResultsSortPriceAscLabel}
          </option>
          <option value={sortOptions.priceDesc.value}>
            {translations.searchResultsSortPriceDescLabel}
          </option>
          {showMileage && (
            <option value={sortOptions.mileageAsc.value}>
              {translations.searchResultsSortMileageAscLabel}
            </option>
          )}
          {showMileage && (
            <option value={sortOptions.mileageDesc.value}>
              {translations.searchResultsSortMileageDescLabel}
            </option>
          )}
          {showAge && (
            <option value={sortOptions.ageAsc.value}>
              {translations.searchResultsSortAgeAscLabel}
            </option>
          )}
          {showAge && (
            <option value={sortOptions.ageDesc.value}>
              {translations.searchResultsSortAgeDescLabel}
            </option>
          )}
          {showDistance && (
            <option value={sortOptions.distanceAsc.value}>
              {translations.searchResultsSortDistanceLabel}
            </option>
          )}
        </Select>
        <SortButtonsRenderer
          selectedView={selectedView}
          onViewChange={onViewChange}
          gridSelected={gridSelected}
          gridDeselected={gridDeselected}
          listSelected={listSelected}
          listDeselected={listDeselected}
          optionalSelectedColour={optionalSelectedColour}
          optionalSelectedBgColour={optionalSelectedBgColour}
        />
      </Sort>
    </SortContainer>
  );
}
SortMenuBar.defaultProps = {
  filtersExpanded: false,
};

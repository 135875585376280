// @flow
import React from 'react';
import type { Node } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styled from 'styled-components';
import media from '../theme';

type RenderProp = any => Node;

type CollapseProps = {
  renderTitle: RenderProp,
  renderClose?: RenderProp,
  backgroundColour?: string,
  children: Node,
  collapsed: boolean,
  toggle: () => void,
  qaHook?: string,
  visible?: boolean,
};

const ToggleButtonWrapper = styled.div.withConfig({
  displayName: 'ToggleButtonWrapper',
})`
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: #444;
`;

const CloseButtonWrapper = styled.div.withConfig({
  displayName: 'CloseButtonWrapper',
})`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`;

const ToggleSection = styled.div.withConfig({
  displayName: 'ToggleSection',
})`
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  position: fixed;
  z-index: 9999;
  background: ${({ backgroundColour }: { backgroundColour: string }) =>
    backgroundColour || '#FFF'};
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  overflow-y: scroll;
  ${media.min.large`
    position: static;
    overflow: visible;
    width: 100%;
    height: 100%;
  `};
`;
const StyledCollapse = styled.div`

  .collapse-leave.collapse-leave-active,
  .collapse-enter {
    width: 100vw;
    height: 100vh;
    background-color: ${({ backgroundColour }: { backgroundColour: string }) =>
      backgroundColour || '#FFF'};
    position: fixed;
    top: 0;
    right: -100vw;
    transition: 400ms;
  }

  ${media.min.medium`
    .collapse-leave.collapse-leave-active,
    .collapse-enter {
      position: static;
      display: none;
      transition: none;
    }
  `};
  }
  .collapse-enter.collapse-enter-active,
  .collapse-leave {
    right: 0;
    transition: 400ms;

    ${media.min.medium`
      .collapse-enter.collapse-enter-active,
      .collapse-leave {
        transition: none;
        opacity: 1;
        display: block;
        height: 100%;
        width: 100%
      }
    `};
  }
`;

const SlideOutWrapper = styled.div.withConfig({
  displayName: 'SlideOutWrapper',
})`
  display: ${({ visible }) => (visible === true ? 'block' : 'none')};
`;

export default function SlideOut(props: CollapseProps) {
  const {
    renderClose,
    renderTitle,
    backgroundColour,
    children,
    collapsed,
    toggle,
    qaHook,
    visible = true,
  } = props;
  return (
    <SlideOutWrapper visible={visible}>
      <ToggleSection>
        {renderTitle && (
          <ToggleButtonWrapper
            data-qa-hook={qaHook}
            collapsed={collapsed}
            onClick={toggle}
          >
            {renderTitle({ collapsed })}
          </ToggleButtonWrapper>
        )}
        <StyledCollapse backgroundColour={backgroundColour}>
          <ReactCSSTransitionGroup
            transitionName="collapse"
            transitionEnterTimeout={400}
            transitionLeaveTimeout={400}
          >
            {collapsed ? null : (
              <Content backgroundColour={backgroundColour}>
                {renderClose && (
                  <CloseButtonWrapper onClick={toggle}>
                    {renderClose()}
                  </CloseButtonWrapper>
                )}
                {children}
              </Content>
            )}
          </ReactCSSTransitionGroup>
        </StyledCollapse>
      </ToggleSection>
    </SlideOutWrapper>
  );
}

/* eslint-disable quotes */
export default {
  translations: {
    header: 'make an enquiry',
    asteriskFormRules: 'Fields marked with an asterisk (*) must be provided',
    cancelActionButton: 'cancel',
    submitMessageActionButton: 'submit message',
    enquiryTypeLabel: 'Enquiry Type',
    generalEnquiry: 'General Enquiry',
    myDeal: 'My Deal',
    testDrive: 'Test Drive',
    reserve: 'Reserve',
    titleLabel: 'Title',
    formTitleMr: 'Mr',
    formTitleDr: 'Dr',
    formTitleMs: 'Ms',
    formTitleMiss: 'Miss',
    formTitleSir: 'Sir',
    formTitleMrs: 'Mrs',
    formTitleDame: 'Dame',
    formTitleLord: 'Lord',
    formTitleLady: 'Lady',
    formTitleReverand: 'Reverend',
    formTitleProfessor: 'Professor',
    formTitleSelect: 'Please Select',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    phoneLabel: 'Phone Number',
    vehicleOfInterestLabel: 'Vehicle of interest',
    commentsLabel: 'Comments',
    dataConsentHeader: 'Preferred Method of Contact',
    dataConsentContent: `IMPORTANT CONSUMER INFORMATION: In providing this information you consent to be contacted using the above methods of communication, unless you indicate otherwise by ticking any of the boxes below. Jaguar Land Rover will use any information collected to inform you about products and services and to improve how we serve you. Your data will be treated in accordance with the Data Protection Act 1998. Your information will only be disclosed to Jaguar Land Rover,
      its Retailers, franchise partners, agencies, affiliated or associated companies providing services for your benefit.`,
    dataConsentPhoneLabel: 'Phone',
    dataConsentEmailLabel: 'Email',
    dataConsentSmsLabel: 'SMS',
    confirmationGoBackLabel: 'go back',
    confirmationHeaderText: 'Your enquiry has been sent',
    confirmationContentText:
      'Thank you for contacting us. A representative will reply to you as soon as possible.',
    somethingWentWrong:
      'Something went wrong and we couldnt send your enquiry, please try again.',
  },
  backgroundImage: '',
};

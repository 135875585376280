// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../../theme';

import { ModalContentWrapper, Overlay } from '../../components/ModalOverlay';
import {
  HeadingOne,
  Button,
  fontStyleOverride,
  buttonStyleOverride,
} from '../../components/Global';
import UserLocation from '../../components/UserLocation';
import useLocation from '../../shared/location';

import { actions as routerActions } from '../../actions/router';
import { actions as sessionPreferencesActions } from '../../shared/sessionPreferences';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

const Wrapper = styled.div`
  padding: 45px;
  * {
    box-sizing: border-box;
  }
`;

const LocationCtaWrapper = styled.div`
  display: block;
  ${media.min.medium`
    display: flex;
  `};
`;

type Props = {
  shared: Object,
  config: Object,
  dispatch: Function => void,
  globalStyling: Object,
};
export default ({
  shared: {
    filters: { availableFilterValues, total },
    sessionPreferences: { location = {} },
  },
  config: {
    translations,
    headingFont,
    subHeadingFont,
    linkFont,
    locationIconUrl,
  },
  dispatch,
  globalStyling,
}: Props) => {
  const { lat, long, setLat, setLong } = useLocation();
  const { latitude, longitude } = location;

  return (
    <Overlay open={availableFilterValues && !latitude && !longitude}>
      <ModalContentWrapper modalWidth={520}>
        <Wrapper>
          <HeadingOne styleOverride={() => fontStyleOverride(headingFont)}>
            {translations.heading}
          </HeadingOne>
          <HeadingOne styleOverride={() => fontStyleOverride(subHeadingFont)}>
            {translateFromTemplate(
              'vehicleCount',
              { COUNT: total },
              translations,
            )}
          </HeadingOne>
          <LocationCtaWrapper>
            <div style={{ flex: '1 1 0%' }}>
              <UserLocation
                direction={globalStyling.direction}
                placeholderText={translations.locationPlaceholder}
                iconUrl={locationIconUrl}
                browserLocationCallback={(latResponse, longResponse) => {
                  setLat(latResponse);
                  setLong(longResponse);
                }}
                inputLocationCallback={(latResponse, longResponse) => {
                  setLat(latResponse);
                  setLong(longResponse);
                }}
                autocompletePosition="below"
              />
            </div>
            <Button
              text={translations.searchText}
              mobileStyleOverride={() => 'margin-top: 10px;'}
              onClick={() => {
                dispatch(
                  sessionPreferencesActions.updateSessionPreferences(
                    'location',
                    {
                      latitude: lat,
                      longitude: long,
                    },
                  ),
                );
              }}
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              disabled={!lat && !long}
              styleOverride={() => `
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex-basis: 20%;
                }
                height: 46px;
              `}
            />
          </LocationCtaWrapper>
          <Button
            buttonStyle="ButtonStyle1"
            styleOverride={() => `
              ${buttonStyleOverride(
                {},
                { value: '#FFFFFF' },
                { value: '#FFFFFF' },
              )};
              ${fontStyleOverride(linkFont)};
              height: auto;
              margin-top: 40px;
              display: flex;
              flex: 1 0 20%;
              width: 100%;
            `}
            onClick={() => dispatch(routerActions.navigate('/'))}
            text={translations.returnToHomeLink}
          />
        </Wrapper>
      </ModalContentWrapper>
    </Overlay>
  );
};

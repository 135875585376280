/* eslint-disable arrow-parens */
// @flow
import React from 'react';
import styled from 'styled-components';

import type { ButtonLinkTheme, Theme, Link as LinkType } from '../../types';
import media from '../../theme';
import { Button, HeadingOne, HeadingTwo } from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';

type Option = {
  value: ?string,
  label: ?string,
};

const opacity = theme => `
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    ${theme.backgroundColour && `background-color: ${theme.backgroundColour}`};
    ${theme.backgroundOpacity && `opacity: ${theme.backgroundOpacity}`};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  position: relative;
  margin: 0 0 1px 0;
`;

const OpacityWrapper = styled.div`
  ${({ theme }) => opacity(theme)};
  padding: 10px
    ${({ rightPadding }) =>
      `${rightPadding !== undefined ? `${rightPadding}px` : '15%'}`}
    10px
    ${({ leftPadding }) =>
      `${leftPadding !== undefined ? `${leftPadding}px` : '15%'}`};

  ${media.max.medium`
    width: auto;
    padding: 0;

    &:after {
      opacity: 1;
    }
  `};
`;

const FullWidthOpacityWrapper = styled.div`
  ${({ theme }) => opacity(theme)} width: 100%;
  padding: 5%;

  ${media.max.medium`
    width: auto;
    padding: 0;

    &:after {
      opacity: 1;
    }
  `};
`;

const SlideContainer = styled.div.withConfig({ displayName: 'SlideContainer' })`
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const BackgroundImage = styled.img.withConfig({
  displayName: 'BackgroundImage',
})`
  display: flex;
  max-width: 100%;
  width: 100%;
`;

const SlideContentContainer = styled.div.withConfig({
  displayName: 'SlideContentContainer',
})`
  position: absolute;
  top: ${({ top }) => (top ? `${top}%` : '50px')};
  ${({ align, alignmentWidth }) =>
    align && alignmentWidth && `${align}: ${alignmentWidth}%;`};
  ${media.max.medium`
      position: static;
      top: auto;
    `};
`;

const TopSlideContentContainer = styled.div.withConfig({
  displayName: 'TopSlideContentContainer',
})`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 0 auto;
  ${({ width }) => width && `width : ${width}px;`}
  ${media.max.medium`
    width: 100%;
  `};
`;

const ButtonContainer = styled.div`
  ${media.max.medium`
    margin: 0 20px;
  `};
`;

const ClickWrapper = styled.div`
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  min-height: 1px;
  max-height: 600px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

type ImageProps = {
  url: Option,
  mediaContent: Object,
  preview: boolean,
  onLinkClick: (url: string) => void,
};

type ContentProps = {
  alignmentWidth: string,
  align: string,
  translations: {
    header: string,
    body: string,
    topLink: string,
    midLink: string,
    bottomLink: string,
  },
  topLink: LinkType,
  midLink: LinkType,
  bottomLink: LinkType,
  headerTheme: Theme,
  bodyTheme: Theme,
  contentWidth: string,
  linkTheme: ButtonLinkTheme,
  buttonBGColour: Option,
  slideContentWidth: number,
  left: number,
  headerLeftPadding: number,
  headerRightPadding: number,
  top: number,
  headingImage: Object,
  headerMobileFontSize: number,
};

type SlideProps = ImageProps & ContentProps;

const buttonStyleOverride = linkTheme => `
  min-width: ${linkTheme.buttonWidth ? `${linkTheme.buttonWidth}px` : '340px'};
  height: ${linkTheme.buttonHeight ? `${linkTheme.buttonHeight}px` : '34px'};
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  ${linkTheme.backgroundColour &&
    `background-color: ${linkTheme.backgroundColour}`};
  ${linkTheme.fontFamily && `font-family: ${linkTheme.fontFamily}`};
  ${linkTheme.textTransform && `text-transform: ${linkTheme.textTransform}`};
  ${linkTheme.fontSize && `font-size: ${linkTheme.fontSize}`};
  ${linkTheme.colour && `color: ${linkTheme.colour}`};
  ${linkTheme.characterSpacing &&
    `letter-spacing: ${linkTheme.characterSpacing}`};
  ${linkTheme.alignment && `justify-content: ${linkTheme.alignment}`}
  svg > rect:not([fill='none']) {
    ${linkTheme.colour && `fill: ${linkTheme.colour}`};
  }
  svg > path:not([fill]) {
    ${linkTheme.colour && `fill: ${linkTheme.colour}`};
  }
`;

const mobileButtonStyleOverride = () => `
  margin: 5px 0;
  min-width: unset;
`;

const headingOneStyleOverride = headerTheme => `
position: relative;
z-index: 2;
height: auto;
overflow-wrap: break-word;
${headerTheme.fontFamily && `font-family: ${headerTheme.fontFamily}`};
${headerTheme.textTransform && `text-transform: ${headerTheme.textTransform}`};
${headerTheme.fontSize && `font-size: ${headerTheme.fontSize}`};
${headerTheme.colour && `color: ${headerTheme.colour}`};
${headerTheme.characterSpacing &&
  `letter-spacing: ${headerTheme.characterSpacing}`};
`;

const headingTwoStyleOverride = bodyTheme => `
position: relative;
max-height: 200px;
z-index: 2;
overflow-wrap: break-word;
${bodyTheme.fontFamily && `font-family: ${bodyTheme.fontFamily}`};
${bodyTheme.textTransform && `text-transform: ${bodyTheme.textTransform}`};
${bodyTheme.fontSize && `font-size: ${bodyTheme.fontSize}`};
${bodyTheme.colour && `color: ${bodyTheme.colour}`};
${bodyTheme.characterSpacing &&
  `letter-spacing: ${bodyTheme.characterSpacing}`};
${bodyTheme.lineHeight && `line-height: ${bodyTheme.lineHeight}`}
`;

const Image = ({ url, preview, mediaContent, onLinkClick }: ImageProps) => (
  <ClickWrapper
    pointer={url && url.value}
    onClick={e => {
      if (preview) {
        e.preventDefault();
      }
      onLinkClick(url && url.value);
    }}
  >
    <BackgroundImage src={mediaContent.value} />
  </ClickWrapper>
);

export const SlideOne = ({
  alignmentWidth,
  align,
  url,
  translations,
  topLink,
  midLink,
  bottomLink,
  headerTheme,
  contentWidth,
  mediaContent = { value: '', label: '' },
  bodyTheme,
  preview = false,
  onLinkClick,
  linkTheme,
  slideContentWidth,
  headerLeftPadding,
  headerRightPadding,
  top,
  headerMobileFontSize,
}: SlideProps) => (
  <SlideContainer>
    <Image {...{ url, preview, mediaContent, onLinkClick }} />
    <ContentWrapper contentWidth={slideContentWidth}>
      <SlideContentContainer
        align={align}
        alignmentWidth={alignmentWidth}
        top={top}
      >
        <TopSlideContentContainer width={contentWidth}>
          {translations.header && (
            <OpacityWrapper
              theme={headerTheme}
              leftPadding={headerLeftPadding}
              rightPadding={headerRightPadding}
            >
              <HeadingOne
                styleOverride={() => `
                  margin: 5px 0;
                  ${headingOneStyleOverride(headerTheme)}
                `}
                mobileStyleOverride={() => [
                  {
                    queryPath: 'max.medium',
                    template: `margin: 20px;
                    ${headerMobileFontSize &&
                      `font-size : ${headerMobileFontSize}px`}`,
                  },
                ]}
              >
                {translations.header}
              </HeadingOne>
            </OpacityWrapper>
          )}
          {translations.body && (
            <OpacityWrapper
              theme={bodyTheme}
              leftPadding={headerLeftPadding}
              rightPadding={headerRightPadding}
            >
              <HeadingTwo
                styleOverride={() => `
                  margin: 0;
                  ${headingTwoStyleOverride(bodyTheme)}
                `}
                mobileStyleOverride={() => [
                  { queryPath: 'max.medium', template: 'margin: 20px;' },
                ]}
              >
                {translations.body}
              </HeadingTwo>
            </OpacityWrapper>
          )}
        </TopSlideContentContainer>
        <ButtonContainer>
          {topLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.topLink}
              applyStyle="primary"
              styleOverride={() => `
                margin: 5px 0 0 0;
                ${buttonStyleOverride(linkTheme)}
              `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(topLink.url)}
            />
          )}
          {midLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.midLink}
              applyStyle="primary"
              styleOverride={() => `
                margin: 5px 0 0 0;
                ${buttonStyleOverride(linkTheme)}
              `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(midLink.url)}
            />
          )}
          {bottomLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.bottomLink}
              applyStyle="primary"
              styleOverride={() => `
                margin: 5px 0 0 0;
                ${buttonStyleOverride(linkTheme)}
              `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(bottomLink.url)}
            />
          )}
        </ButtonContainer>
      </SlideContentContainer>
    </ContentWrapper>
  </SlideContainer>
);

export const SlideTwo = ({
  alignmentWidth,
  align,
  url,
  translations,
  topLink,
  midLink,
  bottomLink,
  headerTheme,
  contentWidth,
  mediaContent = { value: '', label: '' },
  bodyTheme,
  preview = false,
  onLinkClick,
  linkTheme,
  top,
}: SlideProps) => (
  <SlideContainer>
    <Image {...{ url, preview, mediaContent, onLinkClick }} />
    <SlideContentContainer
      align={align}
      alignmentWidth={alignmentWidth}
      top={top}
    >
      <FullWidthOpacityWrapper theme={bodyTheme}>
        <TopSlideContentContainer width={contentWidth}>
          {translations.header && (
            <HeadingOne
              styleOverride={() => `
                ${headingOneStyleOverride(headerTheme)}
              `}
              mobileStyleOverride={() => [
                {
                  queryPath: 'max.medium',
                  template: `margin: 20px;`,
                },
              ]}
            >
              {translations.header}
            </HeadingOne>
          )}
          {translations.body && (
            <HeadingTwo
              styleOverride={() => `
                ${headingTwoStyleOverride(bodyTheme)}
              `}
              mobileStyleOverride={() => [
                {
                  queryPath: 'max.medium',
                  template: `margin: 20px;`,
                },
              ]}
            >
              {translations.body}
            </HeadingTwo>
          )}
        </TopSlideContentContainer>
        <ButtonContainer>
          {topLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.topLink}
              applyStyle="primary"
              styleOverride={() => `
              margin: 10px auto;
              ${buttonStyleOverride(linkTheme)}
            `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(topLink.url)}
            />
          )}
          {midLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.midLink}
              applyStyle="primary"
              styleOverride={() => `
              margin: 10px auto;
              ${buttonStyleOverride(linkTheme)}
            `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(midLink.url)}
            />
          )}
          {bottomLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.bottomLink}
              applyStyle="primary"
              styleOverride={() => `
              margin: 10px auto;
              ${buttonStyleOverride(linkTheme)}
            `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(bottomLink.url)}
            />
          )}
        </ButtonContainer>
      </FullWidthOpacityWrapper>
    </SlideContentContainer>
  </SlideContainer>
);

export const SlideThree = ({
  alignmentWidth,
  align,
  url,
  translations,
  topLink,
  midLink,
  bottomLink,
  headerTheme,
  contentWidth,
  mediaContent = { value: '', label: '' },
  bodyTheme,
  preview = false,
  onLinkClick,
  linkTheme,
  top,
  slideContentWidth,
}: SlideProps) => (
  <SlideContainer>
    <Image {...{ url, preview, mediaContent, onLinkClick }} />
    <ContentWrapper contentWidth={slideContentWidth}>
      <SlideContentContainer
        align={align}
        alignmentWidth={alignmentWidth}
        top={top}
      >
        <FullWidthOpacityWrapper theme={bodyTheme}>
          <TopSlideContentContainer width={contentWidth}>
            {translations.header && (
              <HeadingOne
                styleOverride={() => `
                margin: 0 0 15px;
                ${headingOneStyleOverride(headerTheme)}
              `}
                mobileStyleOverride={() => [
                  {
                    queryPath: 'max.medium',
                    template: 'margin: 0; padding: 5% 5% 15px;',
                  },
                ]}
              >
                {translations.header}
              </HeadingOne>
            )}
            {translations.body && (
              <HeadingTwo
                styleOverride={() => `
                margin: 0;
                ${headingTwoStyleOverride(bodyTheme)}
              `}
                mobileStyleOverride={() => [
                  {
                    queryPath: 'max.medium',
                    template: 'margin: 0; padding: 0 5% 5%;',
                  },
                ]}
              >
                {translations.body}
              </HeadingTwo>
            )}
          </TopSlideContentContainer>
        </FullWidthOpacityWrapper>
        <ButtonContainer>
          {topLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.topLink}
              applyStyle="primary"
              styleOverride={() => `
              margin: 20px 5%;

              & span {
                margin: 0 30px;
              }
              ${buttonStyleOverride(linkTheme)}
            `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(topLink.url)}
            />
          )}
          {midLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.midLink}
              applyStyle="primary"
              styleOverride={() => `
              margin: 20px 5%;

              & span {
                margin: 0 30px;
              }
            `}
              mobileStyleOverride={() => `
              margin: 20px 5%;
              min-width: initial;
              width: initial;
            `}
              onClick={() => onLinkClick(midLink.url)}
            />
          )}
          {bottomLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.bottomLink}
              applyStyle="primary"
              styleOverride={() => `
              margin: 20px 5%;
            `}
              mobileStyleOverride={() => `
              margin: 20px 5%;
              min-width: initial;
              width: initial;
            `}
              onClick={() => onLinkClick(bottomLink.url)}
            />
          )}
        </ButtonContainer>
      </SlideContentContainer>
    </ContentWrapper>
  </SlideContainer>
);

// Lamborghini Slide Type
const SlideContent = styled.div`
  ${media.min.large`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 10%;
    padding: 0 50px;
    box-sizing: border-box;
  `};
`;
const ImageContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  ${media.min.medium`
    padding: 0;
    margin-left: -32px;
  `};
  ${media.min.large`
    justify-content: flex-start;
    margin: 0;
  `};
`;
const ContentImage = styled.img`
  ${media.max.medium`
    height: 60px;
    width: auto;
  `};
  width: 300px;
  z-index: 2; /* higher than opacity wrapper */
`;
const ContentText = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-flow: column wrap;
  margin-left: 32px;
`;
const SlideFourButtonContainer = styled(ButtonContainer)`
  & {
    margin: 0;
  }
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  ${media.min.small`
    margin-top: 10px;
  `};
  ${media.min.medium`
    margin: 20px 0 10px 0;
  `};
  ${media.min.large`
    margin: 0;
    margin-top: -200px;
  `};
`;
const SlideFourOpacityWrapper = styled(OpacityWrapper)`
  &:after {
    opacity: ${({ theme }) => theme.backgroundOpacity};
  }
  ${media.max.small`
  &:after {
        opacity: 1;
      };
  `};
`;
const SlideFourTopSlideContentContainer = styled(TopSlideContentContainer)`
  ${media.max.large`
  width: 60%;
  margin: -90px auto 0 auto;
  `};
  ${media.max.small`
    width: 100%;
    margin: 0;
  `};
  margin: 0;
`;

export const SlideFour = ({
  url,
  translations,
  topLink,
  midLink,
  bottomLink,
  headerTheme,
  contentWidth,
  mediaContent = { value: '', label: '' },
  bodyTheme,
  preview = false,
  onLinkClick,
  linkTheme,
  slideContentWidth,
  headerLeftPadding,
  headerRightPadding,
  headingImage,
}: SlideProps) => (
  <SlideContainer>
    <Image {...{ url, preview, mediaContent, onLinkClick }} />
    <ContentWrapper contentWidth={slideContentWidth}>
      <SlideContent>
        <SlideFourTopSlideContentContainer width={contentWidth}>
          {headingImage && (
            <SlideFourOpacityWrapper
              theme={headerTheme}
              leftPadding={headerLeftPadding}
              rightPadding={headerRightPadding}
            >
              <ImageContentWrapper>
                {headingImage.value && (
                  <ContentImage src={headingImage.value} />
                )}
                {(translations.header || translations.body) && (
                  <ContentText>
                    {translations.header && (
                      <HeadingOne
                        styleOverride={() => `
                    margin: 5px 0;
                    ${headingOneStyleOverride(headerTheme)}
                  `}
                      >
                        {translations.header}
                      </HeadingOne>
                    )}
                    {translations.body && (
                      <HeadingTwo
                        styleOverride={() => `
                    margin: 0;
                    ${headingTwoStyleOverride(bodyTheme)}
                  `}
                      >
                        {translations.body}
                      </HeadingTwo>
                    )}
                  </ContentText>
                )}
              </ImageContentWrapper>
            </SlideFourOpacityWrapper>
          )}
        </SlideFourTopSlideContentContainer>
        <SlideFourButtonContainer>
          {topLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.topLink}
              applyStyle="primary"
              styleOverride={() => `
                height: ${linkTheme.buttonHeight}px;
                font-size: ${linkTheme.fontSize};
                font-family: ${linkTheme.fontFamily};
                && {
                  &:after {
                    width: 24px;
                    height: 24px;
                    background-size: 24px;
                    margin-top: 6px;
                  };
                };
              `}
              mobileStyleOverride={() => `
                width: auto;
                margin: 5px 0;
                height: ${linkTheme.buttonHeight}px;
              `}
              onClick={() => onLinkClick(topLink.url)}
            />
          )}
          {midLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.midLink}
              applyStyle="primary"
              styleOverride={() => `
                margin: 5px 0 0 0;
                ${buttonStyleOverride(linkTheme)}
              `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(midLink.url)}
            />
          )}
          {bottomLink.on && (
            <Button
              buttonStyle={linkTheme.buttonStyle}
              text={translations.bottomLink}
              applyStyle="primary"
              styleOverride={() => `
                margin: 5px 0 0 0;
                ${buttonStyleOverride(linkTheme)}
              `}
              mobileStyleOverride={mobileButtonStyleOverride}
              onClick={() => onLinkClick(bottomLink.url)}
            />
          )}
        </SlideFourButtonContainer>
      </SlideContent>
    </ContentWrapper>
  </SlideContainer>
);

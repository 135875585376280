import Icon from '../../modules/Compare/Icon';

export { id as reducerId } from '../../modules/Compare/reducer';

export { default as component } from './Compare';
export { default as config } from '../../modules/Compare/config';
export { default as defaultConfig } from '../../modules/Compare/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/Compare/reducer';

export const name = 'Compare';
export const id = 'Compare';
export const availableToAdministration = false;
export const description = 'Compare Page';
export const icon = Icon;

// @flow
import React from 'react';
import styled from 'styled-components';
import type { Theme } from '../theme';
import TextAlignment from './TextAlignment';

import DeleteButton from './DeleteButton';

type ThemeProps = {
  theme: Theme,
};

const StyledTable = styled.table.withConfig({
  displayName: 'StyledTable',
})`
  width: 100%;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
`;
const StyledTH = styled.th.withConfig({
  displayName: 'StyledTH',
})`
  color: ${(props: ThemeProps) => props.theme.colours.secondary03};
  text-align: left;
  width: 33%;
  padding-bottom: 10px;
  &:last-child {
    text-align: center;
  }
`;

const StyledTD = styled.td.withConfig({
  displayName: 'StyledTD',
})`
  &:first-child {
    line-height: 40px;
  }
  &:last-child {
    text-align: center;
    width: 33%;
    max-width: 33%;
    font-size: ${(props: ThemeProps) => props.theme.fontSizeHeader};
  }
`;

type SelectedComponent = {
  componentName: string,
  open: boolean,
  value: string,
};
type Props = {
  selectedComponents: Array<SelectedComponent>,
  onChange: (index: number, value: string) => void,
  onOpen: (index: number) => void,
  onClose: (index: number) => void,
  onDelete: (index: number) => void,
};

export default function ComponentList({
  selectedComponents,
  onChange,
  onClose,
  onOpen,
  onDelete,
}: Props) {
  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTH>Added...</StyledTH>
          <StyledTH>Align</StyledTH>
          <StyledTH>Delete</StyledTH>
        </tr>
      </thead>
      <tbody>
        {selectedComponents.map(({ componentName, value, open }, index) => (
          <tr key={`${componentName}${index + 1}`}>
            <StyledTD>{componentName}</StyledTD>
            <StyledTD>
              <TextAlignment
                name={`${componentName}`}
                value={value}
                open={open}
                onChange={newValue => onChange(index, newValue)}
                onClose={() => onClose(index)}
                onOpen={() => onOpen(index)}
              />
            </StyledTD>
            <StyledTD>
              <DeleteButton onClick={() => onDelete(index)} />
            </StyledTD>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

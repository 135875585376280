// @flow
import React from 'react';
import styled from 'styled-components';

import { Wrapper, Overlay } from '../components';
import type { Option, Font } from '../../types';

interface IImageTitleConfig {
  image: Option;
  translations: Object;
  horizontalAlignment: string;
  verticalAlignment: string;
  headlineFont: Font;
  bodyFont: Font;
  backgroundColour: Option;
  opacity: number;
  padding: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  };
}

type Props = {
  config: IImageTitleConfig,
};

const ImageTitle = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${props => props.imageHeight || 300}px;
  background-image: url(${(props: Object) => props.src});
  background-size: cover;
  background-position: center;
`;

export default ({
  config: {
    image: { src },
    translations: { headlineText, bodyText, imageLabel },
    ...overlayConfig
  },
  globalStyling: { uiElements = {} } = {},
  dispatch,
}: Props) => (
  <Wrapper>
    <ImageTitle {...{ src }} role="img" aria-label={imageLabel}>
      <Overlay
        {...{
          ...overlayConfig,
          headlineText,
          bodyText,
          uiElements,
          dispatch,
        }}
      />
    </ImageTitle>
  </Wrapper>
);

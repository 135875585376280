// @flow
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import validate, { validateAll } from './validations';
import { post } from '../../helpers/http';

import { actions as enquiryActions } from '../../actions/enquiry';

const validateOnChange = {
  Select: true,
  Checkbox: true,
};

type FormHook = {
  handleChange: { name: string, value: any },
  handleBlur: { name: string, value: any },
  handleSubmit: (event: SyntheticEvent<HTMLInputElement>) => void,
  formState: Object,
  errors: Object,
  submissionStatus: 'unsubmitted' | 'isSubmitting' | 'submitted' | 'failed',
};

export function useForm(
  fields: Array,
  translations: Object,
  formURL: string,
  requireVehicle: Object,
): FormHook {
  const [formState, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [vehicleDisplay, setVehicleDisplay] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('unsubmitted');

  const dispatch = useDispatch();
  const vehicle = useSelector(state => state.vehicleInContext);
  const path = useSelector(state => state.router.path);

  useEffect(() => {
    const [vehicleId] = path.split('/').reverse();

    if (vehicleId) {
      dispatch(enquiryActions.getEnquiryVehicleInfo(vehicleId));
    }

    const initialState = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: { ...field, value: field.value },
      }),
      { vehicleId: { value: vehicleId } },
    );

    setValues(initialState);
  }, []);

  useEffect(() => {
    if (!formState.vehicle && vehicle) {
      const { vehicleTemplate, vehicleRequiredProps } = requireVehicle;

      const vehicleDetails = vehicleRequiredProps.reduce((acc, prop) => {
        return acc.replace(`{${prop}}`, vehicle[prop]);
      }, vehicleTemplate);

      setVehicleDisplay(vehicleDetails);
    }
  }, [vehicle]);

  useEffect(() => {
    if (submissionStatus === 'isSubmitting') {
      const noErrors = Object.keys(errors).length === 0;

      if (noErrors) {
        const data = Object.keys(formState).reduce((acc, curr) => {
          return { ...acc, [curr]: formState[curr].value };
        }, {});

        try {
          const getPostData = async () => {
            const result = await post({ formURL, data });
            // todo: handle result
            setSubmissionStatus('submitted');
          };
          getPostData();
        } catch (error) {
          // todo: handle error
          setSubmissionStatus('failed');
        }
      } else {
        setSubmissionStatus('unsubmitted');
      }
    }
  }, [submissionStatus]);

  function updateErrors(newErrors) {
    const filteredErrors = Object.keys(newErrors).reduce(
      (acc, k) => (newErrors[k] ? { ...acc, [k]: newErrors[k] } : acc),
      {},
    );

    setErrors(filteredErrors);
  }

  function handleChange({ name, value }) {
    const field = formState[name];

    setValues({
      ...formState,
      [name]: {
        ...field,
        value,
      },
    });

    if (validateOnChange[field.type]) {
      const error = validate({ ...field, value }, translations);
      updateErrors({ ...errors, [field.name]: error });
    }
  }

  function handleBlur({ name, value }) {
    const field = formState[name];
    const error = validate({ ...field, value }, translations);
    updateErrors({ ...errors, [field.name]: error });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const submissionErrors = validateAll(formState, translations);
    updateErrors(submissionErrors);
    setSubmissionStatus('isSubmitting');
  }

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    formState,
    errors,
    submissionStatus,
    vehicle,
    vehicleDisplay,
  };
}

// @flow
import { useEffect } from 'react';

export function useTimeout(callback, delay) {
  let id;

  const clearTimer = () => {
    clearTimeout(id);
  };

  useEffect(() => {
    if (delay !== null) {
      id = setTimeout(callback, delay);
    }

    return () => clearTimer();
  }, [delay]);

  return clearTimer;
}

// @flow

import React from 'react';
import styled from 'styled-components';

import { Dropdown, Column } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

const MultiDropdown = ({
  values: { id: value },
  ids: { id },
  name,
  onChange,
  options,
  limit,
}: FieldProps) => {
  const getValues = items =>
    items.map(item => ({
      prop: item.value,
    }));

  const limitOptions = () =>
    options.map(option =>
      !value.some(v => v.prop === option.value)
        ? { ...option, disabled: true }
        : option,
    );

  return (
    <>
      {name && <TextLabel>{`${name}`}</TextLabel>}
      <Column>
        <Dropdown
          input={{
            value: value.map(v => v.prop),
            onChange: e => onChange(id, getValues(e)),
          }}
          options={limit && value.length >= limit ? limitOptions() : options}
          name={`${name}-dropdown`}
          multi
        />
      </Column>
    </>
  );
};

export default MultiDropdown;

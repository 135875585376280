// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Waypoint from 'react-waypoint';

import media from '../theme';
import VdpGallery, { formattedVehicleDescription } from './VdpGallery';
import VdpMyDeal from './VdpMyDeal';
import VehicleInfoBanner from './VehicleInfoBanner';
import FooterActionBanner from './FooterActionBanner';
import { Button, HeadingOne, fontStyleOverride } from './Global';
import PhoneIcon from './JaguarIcons/Global/Phone';
import { localiseCurrency } from '../shared/localisation/numbers';
import { getFinanceHeaderDisplayList } from '../shared/financeCalculator/data';

type Slide = {
  id: any,
  image: any,
};

const Container = styled.div.withConfig({
  displayName: 'GalleryDealContainer',
})`
  display: flex;
  justify-content: center;
  padding-top: ${props => (props.showDeal ? '0px' : '30px')};
  ${media.max.large`
    display: flex;
    flex-direction: column;
  `};
`;
const BottomText = styled.div`
  padding: 24px 48px;
  color: #7e7e7e;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin: 10px auto;
  box-sizing: border-box;
  ${({ width }) => width && `width: ${width}%;`};
  ${({ showBorders }) =>
    showBorders &&
    'border-top: 1px solid #9B9B9B; border-bottom: 1px solid #9B9B9B;'};
  ${({ bottomTextFont }) =>
    bottomTextFont && fontStyleOverride(bottomTextFont)};
  ${media.max.large`
    display: none;
  `};
`;

const ToggleMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  ${media.max.large`
    display: none;
  `};
`;

const ActionButtonsWrapper = styled.div`
  width: 100%;
  margin: 10px 20;
  display: flex;
  justify-content: space-between;
  ${({ grow }) => grow && 'flex-grow: 1;'};
  ${media.min.large`
    margin: 0;
  `};
`;

const ActionButtonsBox = styled.div`
  width: 100%;
  margin: 20px 0;
  ${media.min.large`
    display: flex;
    justify-content: space-between;
    margin: 0;
  `};
`;

const ActionButtonsContainer = styled.div`
  width: 49%;
  ${({ margin }) =>
    margin && margin.marginTop && `margin-top: ${margin.marginTop}px`};
`;

const CtaButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 0 100%;
  margin-bottom: 15px;
  box-sizing: border-box;
  ${media.min.large`
      flex: 0 0 48%;
  `};

  ${media.max.large`
    ${({ order }) => order && `order: ${order}`};
  `};
`;

const PhoneLink = styled.a.withConfig({
  displayName: 'PhoneLink',
})`
  text-decoration: none;
`;

const ExtendedPhoneLink = styled(PhoneLink)`
  box-sizing: border-box;
  flex: 1 0 100%;
`;

const MyDealContainer = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  padding: ${({ containerPadding, containerPaddingRTL, direction }) =>
    direction === 'rtl'
      ? containerPaddingRTL
      : `${containerPadding || '20px 2% 0 2%'}`}};
  ${media.max.large`
    padding: 0;
  `};
`;

const MyDealButtonContainer = styled.div.withConfig({
  displayName: 'MyDealButtonContainer',
})`
  ${media.min.large`
    width: 100%;
    margin-top: 20px;
    margin-top: ${({ styles }) =>
      styles.marginTop ? `${styles.marginTop}px` : '20px'};
    ${({ styles }) =>
      styles.paddingBottom && `padding-bottom: ${styles.paddingBottom}px`};
  `};

  ${media.max.large`
    margin: 0 5%;
    padding-bottom: 20px;
  `};
`;

const IconWrapper = styled.div.withConfig({
  displayName: 'PhoneIconWrapper',
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomInfoSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  ${media.min.medium`
    width: ${({ galleryWidth }) =>
      galleryWidth ? `${galleryWidth}%;` : '60%;'};
    max-width: 1000px;
    flex-direction: row;
    margin: 10px auto;
  `};
  ${media.min.large`
    padding: 0;
  `};
`;

const BottomInfoActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  ${media.min.medium`
    flex-direction: row;
  `};
`;

const BottomInfoPricingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  ${media.min.medium`
    margin: 0;
  `};
`;

const ApprovedImage = styled.img`
  margin-left: 10px;
  height: 35px;
`;

type RangeConfig = {
  id: string,
  name: string,
  step: number,
  marks: any,
  defaultValue: number,
  fullRangeValues: { min: number, max: number },
  title: string,
  unit: string,
};

const StyledContactLabel = styled.div`
  margin-left: '8px';
  overflow: hidden;
  text-overflow: ellipsis;
  width: 175px;
`;

type Props = {
  vehicleImages: { slides: Slide[] },
  rotateGallery: boolean,
  placeholdingImage: string,
  shortListedIconColour: string,
  compareIconColour: string,
  vehicleInfo: Object,
  translations: Object,
  bookTestDrive: Function,
  reserveThisVehicle: Function,
  makeAnEnquiry: Function,
  makeATestDriveEnquiry: Function,
  makeAReserveEnquiry: Function,
  makeAnOnDemandEnquiry: Function,
  compareVehicle: (vehicle: Object) => void,
  shortlistVehicle: (vehicle: Object) => void,
  finance: Object,
  showStickyBanner: boolean,
  toggleStickyBanner: (bool: boolean) => void,
  downpaymentRange: RangeConfig,
  termsRange: RangeConfig,
  financeProductExample: Object,
  requestFinanceProductUpdate: (
    duration: number,
    downpayment: number,
    additionalServices: string,
  ) => void,
  updateMyDeal: (finance: Object) => void,
  updateMyDealWithPartExchange: (finance: Object) => void,
  saveMyDeal: () => void,
  printMyDeal: (finance: Object) => void,
  buttonTheme: ButtonLinkTheme,
  buttonSecondaryTheme: ButtonLinkTheme,
  iconColour: string,
  showContactbuttons: Boolean,
  showOnDemand: Boolean,
  onDemandBtn: Object,
  featureFlags: Object,
  getValuationMakes: () => {},
  valuationMakes: Object[],
  getValuationModels: string => void,
  valuationModels: Object[],
  globalStyling: Object,
  getValuationVersions: (makeId: string, modelId: string) => void,
  valuationVersions: Object[],
  getValuationYears: (
    makeId: string,
    modelId: string,
    versionId: string,
  ) => void,
  valuationYears: Object[],
  getValuationMonths: (makeId: string, modelId: string, year: string) => void,
  getVehicleValuation: (
    make: Object,
    model: Object,
    version: string,
    year: string,
    month: string,
    odometer: string,
  ) => void,
  valuationMonths: Object[],
  vehicleValuation: Object,
  editVehicleValuation: string => void,
  vehicleValuationRequestLimit: boolean,
  valuationError: boolean,
  showDeal: boolean,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  vehicleHeadingColour: Option,
  vehicleHeadingPadding: string,
  vehicleHeadingMargin: string,
  heartMobileNoPadding: boolean,
  locale: string,
  financeProducts: Object[],
  resetFinanceProduct: () => void,
  financeTranslations: Object,
  financeHeadingDisplay: Array,
  printing: boolean,
  showFinanceDescriptions: boolean,
  buttonStyles: Object,
  financePricingTable: Object,
  hideActionButtons: boolean,
  showShareButton: boolean,
  approvedImage: string,
  galleryWidth: number,
  stickyBannerStyling: Object,
  featuredSpec: Object,
  config: Object,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
  hideYear: boolean,
  myDealButtonContainerStyles: Object,
  imageCarousel: Object,
};

function labelWithIcon(label, iconColour, Icon) {
  return (
    <IconWrapper>
      <Icon width="2em" height="2em" colour={iconColour} />
      <StyledContactLabel>{label}</StyledContactLabel>
    </IconWrapper>
  );
}

const ContactButtons = ({
  makeAnEnquiry,
  makeATestDriveEnquiry,
  makeAReserveEnquiry,
  makeAnOnDemandEnquiry,
  iconColour,
  translations,
  vehicleInfo,
  grow,
  showContactbuttons,
  showOnDemand,
  buttonStyles,
  direction,
  onDemandBtn,
}: Props) => (
  <Fragment>
    {showContactbuttons ? (
      <ActionButtonsBox grow={grow}>
        <CtaButtonsContainer>
          <ButtonContainer order={1}>
            <Button
              data-qa-hook="contact-us-button"
              text={translations.vdpTestDriveButtonLabel}
              onClick={makeATestDriveEnquiry}
              buttonStyle={buttonStyles}
              styleOverride={() => `
                width: 100%;
              `}
            />
          </ButtonContainer>
          <ButtonContainer order={2}>
            <Button
              data-qa-hook="contact-us-button"
              text={translations.vdpReserveButtonLabel}
              onClick={makeAReserveEnquiry}
              buttonStyle={buttonStyles}
              styleOverride={() => `
                width: 100%;
              `}
            />
          </ButtonContainer>
          <ButtonContainer order={4}>
            <ExtendedPhoneLink
              href={`tel:${vehicleInfo.retailerInformation.phone}`}
            >
              <Button
                data-qa-hook="phone-number-button"
                text={labelWithIcon(
                  vehicleInfo.retailerInformation.phone,
                  iconColour,
                  PhoneIcon,
                )}
                buttonStyle="ButtonStyleBTT"
                styleOverride={() => `
                  width: 100%;
                  background: none;
                  ${iconColour && `color: ${iconColour}`};
                `}
                mobileStyleOverride={() => `
                  justify-content: flex-start;
                `}
              />
            </ExtendedPhoneLink>
          </ButtonContainer>
          <ButtonContainer order={3}>
            <Button
              data-qa-hook="contact-us-button"
              text={translations.vdpContactUs}
              onClick={makeAnEnquiry}
              buttonStyle={buttonStyles}
              styleOverride={() => `
                width: 100%;
              `}
            />
          </ButtonContainer>
        </CtaButtonsContainer>
      </ActionButtonsBox>
    ) : (
      <Fragment>
        <ActionButtonsWrapper>
          <ActionButtonsContainer>
            <Button
              data-qa-hook="contact-us-button"
              applyStyle="primary"
              styleOverride={() => `
                  width: 100%;
                `}
              text={translations.vdpContactUs}
              onClick={makeAnEnquiry}
              buttonStyle={buttonStyles}
            />
          </ActionButtonsContainer>
          <ActionButtonsContainer>
            <PhoneLink href={`tel:${vehicleInfo.retailerInformation.phone}`}>
              <Button
                data-qa-hook="phone-number-button"
                applyStyle="secondary"
                styleOverride={() => `
                    width: 100%;
                    ${direction === 'rtl' && 'direction: ltr'}
                  `}
                text={labelWithIcon(
                  vehicleInfo.retailerInformation.phone,
                  iconColour,
                  PhoneIcon,
                )}
                buttonStyle="ButtonStyleBTT"
              />
            </PhoneLink>
          </ActionButtonsContainer>
        </ActionButtonsWrapper>
        {showOnDemand && (
          <ActionButtonsWrapper>
            <ActionButtonsContainer margin={onDemandBtn}>
              <Button
                data-qa-hook="contact-us-button"
                text={translations.vdpOnDemand}
                onClick={makeAnOnDemandEnquiry}
                buttonStyle={buttonStyles}
                styleOverride={() => `
                width: 100%;
              `}
              />
            </ActionButtonsContainer>
          </ActionButtonsWrapper>
        )}
      </Fragment>
    )}
  </Fragment>
);

const BottomInfoSection = ({
  price,
  makeAnEnquiry,
  iconColour,
  translations,
  vehicleInfo,
  approvedImage,
  galleryWidth,
  buttonStyle,
}: Props) => (
  <BottomInfoSectionWrapper galleryWidth={galleryWidth}>
    <BottomInfoPricingSection>
      <HeadingOne
        styleOverride={() => `
          margin: 0;
          font-size: 20px;
        `}
      >
        {price}
      </HeadingOne>
      {vehicleInfo.approved && <ApprovedImage src={approvedImage} alt="" />}
    </BottomInfoPricingSection>
    <BottomInfoActionButtons>
      <Button
        applyStyle="primary"
        buttonStyle={buttonStyle}
        text={translations.vdpContactUs}
        onClick={makeAnEnquiry}
        styleOverride={() => 'min-width: 150px;'}
        mobileStyleOverride={() => 'margin-bottom: 10px;'}
      />
      <PhoneLink href={`tel:${vehicleInfo.retailerInformation.phone}`}>
        <Button
          data-qa-hook="phone-number-button"
          applyStyle="secondary"
          text={labelWithIcon(
            vehicleInfo.retailerInformation.phone,
            iconColour,
            PhoneIcon,
          )}
          buttonStyle="ButtonStyle1"
          styleOverride={() => 'margin-left: 10px; min-width: 150px;'}
        />
      </PhoneLink>
    </BottomInfoActionButtons>
  </BottomInfoSectionWrapper>
);

const financeDataToDisplay = (props, financeLoaded) => {
  const globalFinance = {
    translations: {
      ...props.financeTranslations,
      searchResultsNotAvailableLabel:
        props.translations.searchResultsNotAvailableLabel,
    },
    locale: props.locale,
    financeHeadingDisplay: props.financeHeadingDisplay,
  };

  return getFinanceHeaderDisplayList(
    props.finance || {
      vehiclePrice: props.vehicleInfo.price.value,
      currency: props.vehicleInfo.price.currency,
    },
    globalFinance,
    financeLoaded,
  );
};

export default function GalleryAndMyDeal(props: Props) {
  const rightButton = () => (
    <Button
      onClick={props.reserveThisVehicle}
      text={props.translations.vdpReserveButtonLabel}
      applyStyle="primary"
      buttonStyle={
        props.globalStyling.uiElements.primaryButton &&
        props.globalStyling.uiElements.primaryButton.buttonStyle
      }
    />
  );
  const leftButton = () => (
    <Button
      onClick={props.reserveThisVehicle}
      text={props.translations.vdpTestDriveButtonLabel}
      applyStyle="primary"
      buttonStyle={
        props.globalStyling.uiElements.primaryButton &&
        props.globalStyling.uiElements.primaryButton.buttonStyle
      }
    />
  );
  const hasFinance = !!props.vehicleInfo.finance;
  const { financeLoaded } = props.vehicleInfo;
  const price = localiseCurrency(
    props.vehicleInfo.price.value,
    props.locale,
    props.vehicleInfo.price.currency,
    0,
  );

  return (
    <div>
      {props.showStickyBanner && !props.featureFlags.hideVdpStickyBanner && (
        <VehicleInfoBanner
          iconColour={props.iconColour}
          borderColour={props.borderColour}
          featureFlags={props.featureFlags}
          translations={props.translations}
          imageSrc={
            props.vehicleImages.slides.length > 0
              ? props.vehicleImages.slides[0].image
              : props.placeholdingImage
          }
          title={
            props.hideYear
              ? props.vehicleInfo.description
              : `${props.vehicleInfo.registration} ${
                  props.vehicleInfo.description
                }`
          }
          vehicleDetail={formattedVehicleDescription(
            props.subHeaderType,
            props.vehicleInfo,
            null,
            props.config.subtitleData,
          )}
          dealerTelephone={props.vehicleInfo.retailerInformation.phone}
          info={
            hasFinance && financeLoaded
              ? financeDataToDisplay(props, financeLoaded).map(fd => [
                  fd.value,
                  fd.label,
                ])
              : [
                  [
                    price,
                    (props.financeTranslations &&
                      props.financeTranslations.financeVehiclePrice) ||
                      props.translations.searchResultsPriceLabel,
                  ],
                ]
          }
          onReserveButtonClick={props.reserveThisVehicle}
          onTestDriveButtonClick={props.bookTestDrive}
          buttonTheme={props.buttonTheme}
          contentWidth={props.globalStyling.contentWidth}
          stickyBannerStyling={props.stickyBannerStyling}
          globalStyling={props.globalStyling}
        />
      )}
      {(props.featureFlags.testDrive || props.featureFlags.reserve) && (
        <FooterActionBanner
          leftButton={props.featureFlags.testDrive && leftButton}
          rightButton={props.featureFlags.reserve && rightButton}
        />
      )}
      <Container showDeal={props.showDeal}>
        <VdpGallery
          shortListedIconColour={props.shortListedIconColour}
          compareIconColour={props.compareIconColour}
          translations={props.translations}
          vehicleInfo={props.vehicleInfo}
          vehicleImages={props.vehicleImages}
          compareVehicle={props.compareVehicle}
          shortlistVehicle={props.shortlistVehicle}
          placeholdingImage={props.placeholdingImage}
          subHeaderType={props.subHeaderType}
          vehicleHeadingColour={props.vehicleHeadingColour}
          vehicleHeadingPadding={props.vehicleHeadingPadding}
          vehicleHeadingMargin={props.vehicleHeadingMargin}
          heartMobileNoPadding={props.heartMobileNoPadding}
          rotateGallery={props.rotateGallery}
          showShareButton={props.showShareButton}
          galleryWidth={props.galleryWidth}
          socialShare={props.socialShare}
          config={props.config}
          imageCarousel={props.imageCarousel}
          globalStyling={props.globalStyling}
        />
        {props.showDeal && (
          <MyDealContainer
            containerPadding={
              props.financePricingTable &&
              props.financePricingTable.containerPadding
            }
            containerPaddingRTL={
              props.financePricingTable &&
              props.financePricingTable.containerPaddingRTL
            }
            direction={props.globalStyling.direction}
          >
            <VdpMyDeal
              vehicleInfo={props.vehicleInfo}
              translations={props.translations}
              downpaymentRange={props.downpaymentRange}
              termsRange={props.termsRange}
              requestFinanceProductUpdate={props.requestFinanceProductUpdate}
              finance={props.finance}
              updateMyDeal={props.updateMyDeal}
              updateMyDealWithPartExchange={props.updateMyDealWithPartExchange}
              saveMyDeal={props.saveMyDeal}
              printMyDeal={props.printMyDeal}
              financeProductExample={props.financeProductExample}
              buttonTheme={props.buttonSecondaryTheme}
              iconColour={props.iconColour}
              getValuationMakes={props.getValuationMakes}
              valuationMakes={props.valuationMakes}
              getValuationModels={props.getValuationModels}
              valuationModels={props.valuationModels}
              getValuationVersions={props.getValuationVersions}
              valuationVersions={props.valuationVersions}
              getValuationYears={props.getValuationYears}
              valuationYears={props.valuationYears}
              getValuationMonths={props.getValuationMonths}
              valuationMonths={props.valuationMonths}
              getVehicleValuation={props.getVehicleValuation}
              vehicleValuation={props.vehicleValuation}
              vehicleValuationRequestLimit={props.vehicleValuationRequestLimit}
              editVehicleValuation={props.editVehicleValuation}
              cancelEditVehicleValuation={props.cancelEditVehicleValuation}
              valuationError={props.valuationError}
              featureFlags={props.featureFlags}
              globalStyling={props.globalStyling}
              hasFinance={hasFinance}
              financeLoaded={financeLoaded}
              locale={props.locale}
              financeProducts={props.financeProducts}
              resetFinanceProduct={props.resetFinanceProduct}
              context={props.context}
              financeCellData={financeDataToDisplay(props, financeLoaded)}
              printing={props.printing}
              financeTranslations={props.financeTranslations}
              showFinanceDescriptions={props.showFinanceDescriptions}
              financePricingTable={props.financePricingTable}
              featuredSpec={props.featuredSpec}
              hideActionButtons={props.hideActionButtons}
              badgeWithStripUrl={props.badgeWithStripUrl}
              badgeWithoutStripUrl={props.badgeWithoutStripUrl}
              warrantyBadgeMonths={props.warrantyBadgeMonths}
              warrantyBadgeMaxMileage={props.warrantyBadgeMaxMileage}
              featureSpecificationData={props.featureSpecificationData}
            />
            <Waypoint
              onPositionChange={({ currentPosition }) =>
                props.toggleStickyBanner(currentPosition === 'above')
              }
            />
            <MyDealButtonContainer styles={props.myDealButtonContainerStyles}>
              <ToggleMobile>
                {props.featureFlags.testDrive && (
                  <div style={{ width: '49%' }}>
                    <Button
                      onClick={props.bookTestDrive}
                      text={props.translations.vdpBookTestDrive}
                      applyStyle="primary"
                      styleOverride={() => 'width: 100%;'}
                      buttonStyle={
                        props.globalStyling.uiElements.primaryButton &&
                        props.globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      optionalCTAButtons={props.optionalCTAButtons}
                      data-qa-hook="book-test-drive-button"
                    />
                  </div>
                )}
                {props.featureFlags.reserve && (
                  <div style={{ width: '49%' }}>
                    <Button
                      data-qa-hook="reserve-vehicle-button"
                      onClick={props.reserveThisVehicle}
                      styleOverride={() => 'width: 100%;'}
                      text={props.translations.vdpReserveThisVehicle}
                      applyStyle="primary"
                      buttonStyle={
                        props.globalStyling.uiElements.primaryButton &&
                        props.globalStyling.uiElements.primaryButton.buttonStyle
                      }
                    />
                  </div>
                )}
              </ToggleMobile>
              <ContactButtons
                buttonSecondaryTheme={props.buttonSecondaryTheme}
                makeAnEnquiry={props.makeAnEnquiry}
                iconColour={props.iconColour}
                showContactbuttons={props.showContactbuttons}
                showOnDemand={props.showOnDemand}
                onDemandBtn={props.onDemandBtn}
                makeATestDriveEnquiry={props.makeATestDriveEnquiry}
                makeAnOnDemandEnquiry={props.makeAnOnDemandEnquiry}
                makeAReserveEnquiry={props.makeAReserveEnquiry}
                translations={props.translations}
                vehicleInfo={props.vehicleInfo}
                buttonStyles={
                  props.globalStyling.uiElements.primaryButton &&
                  props.globalStyling.uiElements.primaryButton.buttonStyle
                }
                direction={props.globalStyling.direction}
              />
            </MyDealButtonContainer>
          </MyDealContainer>
        )}
      </Container>
      {!props.showDeal && (
        <Fragment>
          <BottomInfoSection
            price={price}
            buttonSecondaryTheme={props.buttonSecondaryTheme}
            makeAnEnquiry={props.makeAnEnquiry}
            makeAnOnDemandEnquiry={props.makeAnOnDemandEnquiry}
            iconColour={props.iconColour}
            translations={props.translations}
            vehicleInfo={props.vehicleInfo}
            approvedImage={props.approvedImage}
            galleryWidth={props.galleryWidth}
            buttonStyle={
              props.globalStyling.uiElements.primaryButton &&
              props.globalStyling.uiElements.primaryButton.buttonStyle
            }
          />
          {props.translations.vdpGalleryAndMyDealBottomText && (
            <BottomText
              bottomTextFont={props.bottomTextFont}
              width={props.galleryWidth}
              showBorders
            >
              {props.translations.vdpGalleryAndMyDealBottomText}
            </BottomText>
          )}
        </Fragment>
      )}
    </div>
  );
}

GalleryAndMyDeal.defaultProps = {
  iconColour: '#9E1B32',
  showDeal: true,
  subHeaderType: 'VehicleDescription',
};

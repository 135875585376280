// @flow
import React from 'react';
import styled from 'styled-components';

type CustomTextTheme = {
  colour: string,
  textTransform: string,
  characterSpacing: string,
  fontSize: string,
  fontFamily: string,
};

export const StyledCustomText = styled.div.withConfig({
  displayName: 'StyledCustomText',
})`
  color: ${({ theme }: { theme: CustomTextTheme }) => `${theme.colour}`};
  margin: 0 6px;
  text-transform: ${({ theme }: { theme: CustomTextTheme }) =>
    `${theme.textTransform}`};
  letter-spacing: ${({ theme }: { theme: CustomTextTheme }) =>
    `${theme.characterSpacing}`};
  font-size: ${({ theme }: { theme: CustomTextTheme }) => `${theme.fontSize}`};
  font-family: ${({ theme }: { theme: CustomTextTheme }) =>
    `${theme.fontFamily}`};
`;

type Props = {
  content: string,
  theme: CustomTextTheme,
};

export default function CustomText({ theme, content }: Props) {
  return <StyledCustomText theme={theme}>{content}</StyledCustomText>;
}

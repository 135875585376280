import { useSelector, useDispatch } from 'react-redux';

import partial from 'ramda/src/partial';
import compose from 'ramda/src/compose';

import pods from '../../pods';

import { actions as contextActions } from '../../state/actions/context';
import { actions as siteBuilderActions } from '../../state/actions/siteBuilder';
import { actions as pageActions } from '../../state/actions/page';
import { actions as externalLinkActions } from '../../state/actions/externalLink';

const getPodFromContext = context => pods.find(p => p.id === context.pod.id);

const usePodMenu = onLeaveModuleOverride => {
  const dispatch = useDispatch();
  const context = useSelector(state => state.context);
  const siteId = useSelector(state => state.site.currentSiteId);
  const disclosed = useSelector(state => state.siteBuilder.ui);
  const podConfig = useSelector(
    state => state.config.pods[context.pod.instanceId],
  );

  const data = useSelector(state => ({
    fonts: state.editor.branding.fonts.availableFonts || [],
    colours: state.editor.branding.colours.availableColours || [],
    pages: Object.values(state.config.pages) || [],
    media: {
      images: state.siteBuilder.media.images || [],
      videos: state.siteBuilder.media.videos || [],
    },
    externalLinks:
      (state.siteBuilder.externalLink &&
        state.siteBuilder.externalLink.links) ||
      [],
  }));

  const onPodFieldChange = compose(
    dispatch,
    partial(pageActions.setPodField, [context.pod.instanceId]),
  );
  const onPodListFieldChange = compose(
    dispatch,
    partial(pageActions.setPodListField, [context.pod.instanceId]),
  );
  const onDeletePodSection = compose(
    dispatch,
    partial(pageActions.deletePodSection, [
      context.pod && context.pod.instanceId,
    ]),
  );

  const showAccordion = (id, show) =>
    dispatch(siteBuilderActions.showAccordian(id, show));

  const toggleSection = (sectionListManyId, index) =>
    dispatch(siteBuilderActions.toggleSection(sectionListManyId, index));
  const saveAndSetExternalLink = (link, siteId, onChange, event) =>
    dispatch(externalLinkActions.saveLink(link, siteId, onChange, event));
  const onAddPodSection = compose(
    dispatch,
    partial(pageActions.addPodSection, [context.pod && context.pod.instanceId]),
  );
  const setSliceContext = slice =>
    dispatch(contextActions.setSliceContext(slice));

  const setPodContext = slice => dispatch(contextActions.setPodContext(slice));
  const setBreakoutContext = brk =>
    dispatch(contextActions.setBreakoutContext(brk));

  const onUploadMediaSuccess = (resources, type) =>
    dispatch(siteBuilderActions.uploadMediaContentSuccess(resources, type));
  const onUploadMediaFailure = error =>
    dispatch(siteBuilderActions.uploadMediaContentFailure(error));
  const onDeleteMediaItem = (mediaPublicId, type) =>
    dispatch(siteBuilderActions.deleteMediaContent(mediaPublicId, type));

  const pod = getPodFromContext(context);
  let sliceProps;
  if (context.slice) {
    const slice = pod.editor.slices.find(({ name }) => name === context.slice);
    const many = slice.list && {
      id: slice.id,
      max: slice.max,
      sectionLabel: slice.sectionLabel,
      buttonLabel: slice.buttonLabel,
      icon: slice.icon,
      focus: slice.focus,
    };
    sliceProps = {
      config: podConfig.config,
      data,
      disclosed,
      onFieldChange: onPodFieldChange,
      onListFieldChange: onPodListFieldChange,
      onAddSection: () => onAddPodSection(slice.id),
      onDeleteSection: listIndex => onDeletePodSection(slice.id, listIndex),
      onUploadMediaSuccess,
      onUploadMediaFailure,
      onDeleteMediaItem,
      saveAndSetExternalLink,
      siteId,
      sections: slice.list ? slice.fields || [] : slice.sections,
      pod: context.pod,
      list: slice.list,
      onClickSection: showAccordion,
      onToggleSection: toggleSection,
      current: context.slice,
      many,
    };
  }

  return {
    name: pod.name,
    slices: pod.editor.slices,
    onEnterSlice: setSliceContext,
    onLeaveModule:
      onLeaveModuleOverride ||
      (() => {
        setPodContext(null);
        if (context.previousBreakout) {
          setBreakoutContext(context.previousBreakout);
        }
      }),
    sliceProps,
  };
};

export default usePodMenu;

// @flow
import React from 'react';

import VdpRetailer from '../../components/VdpRetailer';

export interface Location {
  coordinates: [number, number];
}
export interface RetailerInfo {
  location: Location;
  name: string;
  address: string[];
  country: string;
  phone: string;
  fax: string;
  email: string;
  openClosingTimes: string[];
}

export interface Config {
  retailerInformation: RetailerInfo;
}

type Props = {
  config: Config,
};
export default function RetailerInformation({ config }: Props) {
  return <VdpRetailer retailer={config.retailerInformation} />;
}

// @flow
import React from 'react';
import Preview from './Preview';
import TranslationsContainer from '../containers/TranslationsContainer';
import UsersContainer from '../containers/UsersContainer';
import MediaLibraryContainer from '../containers/MediaLibraryContainer';
import FinanceOptionsContainer from '../containers/FinanceOptionsContainer';
import SocialShareOptionsContainer from '../containers/SocialShareOptionsContainer';
import EditBlogContent from './EditBlogContent';
import FormEditor from './FormEditor';
import EditCookiePolicyContent from './EditCookiePolicyContent';
import type { Props } from '../containers/SiteBuilderViewContainer';

export default function SiteBuilderView({
  selectedScreenSize,
  globalContext,
}: Props) {
  switch (globalContext) {
    case 'Translations':
      return <TranslationsContainer />;
    case 'Manage Users':
      return <UsersContainer />;
    case 'Media Library':
      return <MediaLibraryContainer />;
    case 'Finance Options':
      return <FinanceOptionsContainer />;
    case 'Social Share Options':
      return <SocialShareOptionsContainer />;
    case 'Edit Blog Content':
      return <EditBlogContent />;
    case 'Edit Form Content':
      return <FormEditor />;
    case 'Edit Cookie Policy Content':
      return <EditCookiePolicyContent />;
    default:
      return <Preview maxWidth={selectedScreenSize.maxWidth} />;
  }
}

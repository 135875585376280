// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import type { IFeaturedVehicleConfig } from './types';
import { useFeaturedVehicle } from './useFeaturedVehicle';

const Wrapper = styled.div`
  margin: 10px 5px;
  display: flex;
  box-sizing: border-box;
`;

type Props = {
  config: IFeaturedVehicleConfig,
  translations: Object,
};

export default ({
  config: { template, vehicleId, translations, rates, ...config },
}: Props) => {
  const {
    Template,
    vehicle,
    locale,
    shared,
    globalStyling,
    priceNoDigits,
    mileageNoDigits,
    generateSubTitle,
    onMoreInfoClick,
    compareVehicle,
    shortlistVehicle,
  } = useFeaturedVehicle(template, vehicleId, translations);

  return (
    <Fragment>
      {vehicle && (
        <Wrapper>
          <Template
            config={config}
            translations={translations}
            vehicle={vehicle}
            locale={locale}
            shared={shared}
            globalStyling={globalStyling}
            priceNoDigits={priceNoDigits}
            mileageNoDigits={mileageNoDigits}
            generateSubTitle={generateSubTitle}
            onMoreInfoClick={onMoreInfoClick}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
          />
        </Wrapper>
      )}
    </Fragment>
  );
};

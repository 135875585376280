// @flow
import React from 'react';
import styled from 'styled-components';
import Youtube from '@u-wave/react-youtube';

import Controls from './Controls';

const YouTubeWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  > iframe {
    width: 100%;
    min-height: 550px;
  }
`;

export default (props: Props) => {
  const { src, autoplay, muted = false, imagesIcon, toggleImageView } = props;

  return (
    <YouTubeWrapper>
      <Youtube
        video={src}
        width="100%"
        height="100%"
        showRelatedVideos={false}
        modestBranding
        controls
        muted={muted}
        autoplay={autoplay}
      />
      <Controls imagesIcon={imagesIcon} toggleImageView={toggleImageView} />
    </YouTubeWrapper>
  );
};

// @flow
import React from 'react';
import styled from 'styled-components';

const NavBarContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colours.secondary01};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colours.secondary03};
  border-bottom: 2px solid ${({ theme }) => theme.colours.secondary02};
  font-weight: bold;
`;
const NavBar = styled.div`
  max-width: 1366px;
  height: auto;
  margin: auto;
  display: flex;
`;
const NavBarItem = styled.div`
  cursor: pointer;
  margin-right: 50px;
  padding: 10px 0;
  &:last-child {
    margin-right: 0;
  }
`;
export default ({ onTabClick }: { onTabClick: (tab: string) => void }) => (
  <NavBarContainer>
    <NavBar>
      <NavBarItem
        data-qa-hook="nav-bar-site-list"
        onClick={() => onTabClick('SITE_LIST')}
      >
        AVL List
      </NavBarItem>
      <NavBarItem
        data-qa-hook="nav-bar-template-options"
        onClick={() => onTabClick('TEMPLATE_OPTIONS')}
      >
        Template Options
      </NavBarItem>
      <NavBarItem
        data-qa-hook="nav-bar-media-library"
        onClick={() => onTabClick('MEDIA_LIBRARY')}
      >
        Media Library
      </NavBarItem>
    </NavBar>
  </NavBarContainer>
);

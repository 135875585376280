import Icon from '../Search/Icon';
import * as Filters from '../../linked-modules/Filters';

export { id as reducerId } from './reducer';

export { default as component } from './ModelMatrixSearch';
export { default as effects } from './effects';
export { default as config } from '../Search/config';
export { default as defaultConfig } from './defaultConfig';
export { actions, constants, reducer, initialState } from './reducer';

export const name = 'ModelMatrixSearch';
export const id = 'ModelMatrixSearch';
export const availableToAdministration = true;
export const description = 'Search Vehicles Page';
export const icon = Icon;
export const submodules = [Filters];

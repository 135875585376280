import uuid from 'uuid/v1';
import { useDispatch, useSelector } from 'react-redux';
import curry from 'ramda/src/curry';

import { actions as pageActions } from '../../state/actions/page';
import { actions as contextActions } from '../../state/actions/context';
import pods from '../../pods';
import PAGE_TYPES from '../../constants/pageTypes';

const clearAddPodContext = () => contextActions.setAddPod(null);
const replacePodAction = curry(pageActions.replacePodOnBand);
const replacePodThenClearContext = replacePodAction(clearAddPodContext);

const getPodConfig = podId => {
  const pod = pods.find(p => p.id === podId);
  return {
    config: {
      ...pod.defaultConfig,
    },
    id: pod.id,
    instanceId: uuid(),
  };
};

const useActions = (podId, podInstanceId) => {
  const dispatch = useDispatch();
  const site = useSelector(state => state.config.site);
  const bands = useSelector(state => state.config.bands);
  const activeItem = useSelector(state => state.context.activeItem);
  const currentAction = useSelector(state => state.context.action);
  const band = Object.values(bands).find(b =>
    b.config.pods.includes(podInstanceId),
  );
  const pages = useSelector(state => state.config.pages);
  const page = Object.values(pages).find(p =>
    p.modules.includes(band.instanceId),
  );
  const isSpacer = podId === 'Spacer';

  const edit = () => {
    dispatch(
      contextActions.setBandContext({
        id: band.id,
        instanceId: band.instanceId,
      }),
    );
    dispatch(
      contextActions.setPodContext({ id: podId, instanceId: podInstanceId }),
    );
    dispatch(contextActions.setSliceContext('Content'));
  };

  const remove = () => {
    dispatch(contextActions.setPodContext(null));
    dispatch(
      replacePodThenClearContext(podInstanceId, {
        id: 'Spacer',
        instanceId: uuid(),
      }),
    );
  };
  const addPod = newPodId =>
    dispatch(replacePodThenClearContext(podInstanceId, getPodConfig(newPodId)));
  const drop = event => {
    event.preventDefault();
    const newPodId = event.dataTransfer.getData('text/plain');
    addPod(newPodId);
  };
  const place = () => {
    addPod(activeItem);
    dispatch(contextActions.setClickedEditorPodContext(null));
  };
  const select = () => {
    dispatch(contextActions.setPodContext(null));
    dispatch(
      contextActions.setBandContext({
        pageId: page.id,
        bandInstanceId: band.instanceId,
      }),
    );
    dispatch(
      contextActions.setAddPod({
        pageId: page.id,
        bandInstanceId: band.instanceId,
      }),
    );
    dispatch(contextActions.setClickedPreviewPodContext(podInstanceId));
  };

  const canPlace = () =>
    currentAction !== 'pod-selected' && activeItem
      ? pods
          .find(p => p.id === activeItem)
          .requirements.columns.some(c => band.config.columns === c)
      : currentAction !== 'pod-selected';

  const spacerPodStatesForCurrentActions = {
    'pod-type-selected': 'placeable',
    'dragging-pod': 'droppable',
    'pod-selected': 'selected',
    none: 'selectable',
    nodrop: 'nodrop',
    noclick: 'noclick',
  };

  const spacerPodActionsForCurrentActions = {
    'pod-type-selected': {
      click: place,
    },
    'dragging-pod': {
      drop,
    },
    'pod-selected': {
      click: select,
    },
    none: {
      click: select,
    },
    nodrop: {
      drop: e => e.preventDefault(),
    },
    noclick: {},
  };

  const isSelected = podInstanceId === activeItem;
  const podState = isSpacer
    ? canPlace() || isSelected
      ? spacerPodStatesForCurrentActions[currentAction]
      : spacerPodStatesForCurrentActions[
          currentAction === 'pod-selected' ? 'noclick' : 'nodrop'
        ]
    : 'content';

  const spacerActions = () =>
    canPlace() || isSelected
      ? spacerPodActionsForCurrentActions[currentAction]
      : spacerPodActionsForCurrentActions[
          currentAction === 'pod-selected' ? 'noclick' : 'nodrop'
        ];
  const canEdit =
    !isSpacer && (!page || page.pageType !== PAGE_TYPES.SEARCH_RESULTS);
  const canRemove = !isSpacer && !site.includes(band.instanceId) && canEdit;
  const actions = {
    ...(canEdit && { edit }),
    ...(canRemove && { remove }),
    ...(isSpacer && spacerActions()),
  };

  const contentState = isSpacer ? 'empty' : podId;

  return {
    actions,
    contentState,
    podState,
  };
};

export default useActions;

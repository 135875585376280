// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  label?: ?Node,
  width?: ?string,
  theme: Theme,
};

const Container = styled.div`
  color: ${(props: Props) => props.theme.colours.lowlight};
  display: flex;
  width: ${({ width }) => width || '100%'};
  font-size: 26px;
  font-weight: 600;
`;

const Rule = styled.hr`
  flex: 1 0 0%;
  color: ${(props: Props) => props.theme.colours.lowlight};
  height: 0;
`;

const LabelWrapper = styled.span`
  margin: 0 ${(props: Props) => props.theme.layout.standardPadding};
`;

export default function Separator({ label, width }: Props) {
  return (
    <Container width={width}>
      <Rule />
      {label ? <LabelWrapper>{label}</LabelWrapper> : <Rule />}
      <Rule />
    </Container>
  );
}

Separator.defaultProps = {
  label: null,
  width: null,
};

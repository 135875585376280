// @flow

import React from 'react';
import styled from 'styled-components';

import { HeadingTwo, Paragraph, fontStyleOverride } from './Global';
import ContentWrapper from './ContentWrapper';

const Container = styled.div`
  text-align: ${({ align }) => `${align || 'unset'}`};
  padding: 20px;
  ${({ maxWidth }) =>
    maxWidth &&
    `max-width: ${maxWidth}px;
    margin: 0 auto;`};
`;

const TextContainer = styled.div`
  margin: 10px;
`;

const OnDemandLink = styled.div`
  ${({ colour }) => colour && `color: ${colour};`};
  ${({ icon }) =>
    icon &&
    `&:before {
    content: '';
    height: 19px;
    width: 19px;
    position: absolute;
    margin: 0 0 0 -24px;
    background: url('${icon}') no-repeat center center;
  }
  `};
  cursor: pointer;
`;

type Props = {
  config: Config,
  globalStyling: Object,
  navigate: string => void,
};

function VdpOnDemandSection({ config, globalStyling, navigate }: Props) {
  return (
    <ContentWrapper
      colour={
        config.onDemandSection.backgroundColour &&
        config.onDemandSection.backgroundColour
      }
      config={config}
      contentWidth={globalStyling.contentWidth}
    >
      <Container
        align={config.onDemandSection.alignment}
        maxWidth={config.onDemandSection.contentMaxWidth}
      >
        <TextContainer>
          <HeadingTwo
            styleOverride={() => `
                ${fontStyleOverride(config.onDemandSection.HeadingFont)}
              `}
          >
            {config.translations.onDemandSectionTitle}
          </HeadingTwo>
        </TextContainer>

        <TextContainer>
          <Paragraph>{config.translations.onDemandSectionPara1}</Paragraph>
          <Paragraph>{config.translations.onDemandSectionPara2}</Paragraph>
        </TextContainer>

        <OnDemandLink
          onClick={() => navigate(config.onDemandSection.onDemandURL)}
          colour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
          icon={config.onDemandSection.icon}
        >
          {config.translations.onDemandSectionButtonText}
        </OnDemandLink>
      </Container>
    </ContentWrapper>
  );
}

export default VdpOnDemandSection;

// @flow
import { constants } from '../actions/brand';
import type { Action } from '../actions/brand';
import type { Error } from '../types/error';

export type Language = { id: string, name: string, iso: string };

export type Currency = { id: string, name: string, iso: string };

export type Brand = {
  languages: Language[],
  currencies: Currency[],
};

export type State = {
  languages: Language[],
  currencies: Currency[],
  error: ?Error,
};

const initialState: State = {
  languages: [],
  currencies: [],
  error: null,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.LoadBrandSuccessful:
      return {
        ...state,
        languages: action.payload.languages,
        currencies: action.payload.currencies,
        error: null,
      };
    case constants.LoadBrandFailure:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

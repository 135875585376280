// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../../theme';
import { HeadingTwo, Paragraph } from '../Global';

const Container = styled.div`
  width: auto;
  height: 200px;
  padding: 0 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.max.large`
    text-align: center;
    padding: 0 5%;
    height: 150px;
  `};
`;

type Props = {
  heading: string,
  subHeading?: string,
};

export default function MyAccountHeading({ heading, subHeading }: Props) {
  return (
    <Container>
      <HeadingTwo
        styleOverride={() => `
          font-size: 26px;
          text-transform: uppercase;
        `}
      >
        {heading}
      </HeadingTwo>
      {subHeading && (
        <Paragraph
          styleOverride={() => `
            font-size: 18px;
            margin-top: 20px;
          `}
        >
          {subHeading}
        </Paragraph>
      )}
    </Container>
  );
}
MyAccountHeading.defaultProps = {
  subHeading: '',
};

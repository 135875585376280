// @flow
import {
  moduleActionWithArgs,
  moduleAction,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_SEARCH_RESULTS';

export const constants = moduleConstants(modulePrefix, [
  'SHOW_ALL',
  'GET_INVENTORY',
  'GET_INVENTORY_SUCCESS',
  'INIT',
  'GET_FINANCE_SUCCESS',
  'GET_DEALER',
  'GET_DEALER_SUCCESS',
  'CLEAR_ALL',
  'CHANGE_PAGE',
  'LOAD_FINANCE',
  'CANCEL_LOADING',
  'CLEAR_LOADED',
]);

export const id = 'SearchResults';

export const actions = {
  init: moduleAction(constants.INIT, id),
  showAll: moduleActionWithArgs(constants.SHOW_ALL, id, payload => payload),
  getInventory: moduleActionWithArgs(
    constants.GET_INVENTORY,
    id,
    payload => payload,
  ),
  getInventorySuccess: moduleActionWithArgs(
    constants.GET_INVENTORY_SUCCESS,
    id,
    config => config,
  ),
  getFinanceSuccess: moduleActionWithArgs(
    constants.GET_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  getDealer: moduleActionWithArgs(
    constants.GET_DEALER,
    id,
    dealerId => dealerId,
  ),
  getDealerSuccess: moduleActionWithArgs(
    constants.GET_DEALER_SUCCESS,
    id,
    dealer => dealer,
  ),
  changePage: moduleActionWithArgs(
    constants.CHANGE_PAGE,
    id,
    payload => payload,
  ),
  loadFinance: moduleActionWithArgs(
    constants.LOAD_FINANCE,
    id,
    payload => payload,
  ),
  cancelLoading: moduleActionWithArgs(
    constants.CANCEL_LOADING,
    id,
    payload => payload,
  ),
  clearLoaded: moduleAction(constants.CLEAR_LOADED, id),
};

const replaceIfExists = (vehicles, vehicle) => [
  ...vehicles.map(v => (v.id === vehicle.id ? vehicle : v)),
];

export const initialState = {
  infiniteScroll: false,
  loading: false,
  searchResults: {},
  pageSize: 9,
  currentPage: 1,
  dealer: {},
};

type State = {
  searchResults: {},
  pageSize: Number,
  totalResults: ?Number,
  currentPage: Number,
  loading: Boolean,
};

type Action = {
  type: string,
  payload: Object,
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SHOW_ALL: {
      return {
        ...state,
        infiniteScroll: true,
      };
    }
    case constants.CLEAR_LOADED: {
      return {
        ...state,
        searchResults: {},
        totalResults: null,
        infiniteScroll: false,
        currentPage: 1,
        loading: false,
      };
    }
    case constants.CANCEL_LOADING: {
      const resetToFirstPage = action.payload;
      return {
        ...state,
        loading: false,
        infiniteScroll: false,
        ...(resetToFirstPage && {
          currentPage: 1,
        }),
      };
    }
    case constants.GET_INVENTORY: {
      return {
        ...state,
        loading: true,
        filters: action.payload.filters,
      };
    }
    case constants.CHANGE_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
        loading: false,
      };
    }
    case constants.GET_INVENTORY_SUCCESS: {
      const { pageNumber, contents, total } = action.payload;
      return {
        ...state,
        loading: false,
        searchResults: {
          ...state.searchResults,
          [pageNumber]: contents,
        },
        totalResults: total,
        currentPage: pageNumber,
      };
    }
    case constants.GET_FINANCE_SUCCESS: {
      const { vehicle, pageNumber } = action.payload;
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [pageNumber]: replaceIfExists(
            state.searchResults[pageNumber] || [],
            vehicle,
          ),
        },
      };
    }
    case constants.GET_DEALER_SUCCESS: {
      return {
        ...state,
        dealer: action.payload,
      };
    }
    default:
      return state;
  }
}

// @flow
export const constants = {
  printVehicleInfo: 'PRINT_VEHICLE_INFO',
  printingSuccessful: 'PRINTING_SUCCESSFUL',
  printingFailure: 'PRINTING_FAILURE',
};

export const actions = {
  printVehicleInfo: (vehicle: Object) => ({
    type: constants.printVehicleInfo,
    payload: vehicle,
  }),
  printingSuccessful: () => ({ type: constants.printingSuccessful }),
  printingFailure: () => ({ type: constants.printingFailure }),
};

const initialState = {
  isPrinting: false,
  error: false,
};

export function reducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case constants.printVehicleInfo:
      return {
        ...state,
        isPrinting: true,
        error: false,
      };
    case constants.printingSuccessful:
      return {
        ...state,
        isPrinting: false,
        error: false,
      };
    case constants.printingFailure:
      return {
        isPrinting: false,
        error: true,
      };
    default:
      return state;
  }
}

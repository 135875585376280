import { put, takeLatest } from 'redux-saga/effects';
import { constants } from './actions';
import { actions as sessionActions } from '../../shared/sessionPreferences/reducer';
import { actions as currencyActions } from '../../shared/currencyConversion';
import { language as languageActions } from '../../actions';

function* handleMarketSelection(action) {
  const {
    payload: { currency, language, market },
  } = action;

  yield put(
    sessionActions.updateMultipleSessionPreferences([
      { key: 'currency', value: currency },
      { key: 'language', value: language },
      { key: 'market', value: market },
      { key: 'marketSelected', value: true },
      { key: 'disableCurrencyPopup', value: true },
    ]),
  );

  yield put(currencyActions.getExchangeRates(currency));
}

export default function* MarketSelectorSaga() {
  yield takeLatest(constants.SET_MARKET_SELECTION, handleMarketSelection);
}

// @flow
import React from 'react';
import styled from 'styled-components';

import { type Props } from './FinanceOptions';

const Container = styled.div`
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  padding: 35px 2.5%;
  background-color: #ffffff;
`;

const Header = styled.div`
  color: #757575;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SelectProduct = styled.select`
  width: 300px;
  height: 40px;
  border: 1px solid #d6d6d6;
  font-family: Lato;
  font-size: 14px;
  color: #474747;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
`;

const ProductOption = styled.option`
  color: #474747;
  font-size: 14px;
  margin: 10px 0;
`;

export default function DefaultProduct({
  finance,
  updateFinanceOptions,
}: Props) {
  return (
    <Container>
      <Header>Default Finance Product</Header>
      <SelectProduct
        value={finance.defaultProduct}
        onChange={e =>
          updateFinanceOptions({ ...finance, defaultProduct: e.target.value })
        }
      >
        {finance.productTypes.map(p => (
          <ProductOption key={p} value={p}>
            {p}
          </ProductOption>
        ))}
      </SelectProduct>
    </Container>
  );
}

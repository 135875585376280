export const constants = {
  changeLanguage: 'CHANGE_LANGUAGE',
};

export const actions = {
  changeLanguage: language => ({
    type: constants.changeLanguage,
    payload: language,
  }),
};

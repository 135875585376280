import { put, call, select, takeLatest } from 'redux-saga/effects';
import { actions, constants } from '.';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getInventoryStatus,
} from '../../shared/selectors/settings';

import { actions as errorActions } from '../../shared/errors';

function* getVehicleInfo({ dataKey }) {
  const path = yield select(state => state.router.path);
  const [vehicleId] = path.split('/').reverse();

  const { locale, make, market } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
  const inventoryStatus = yield select(getInventoryStatus);
  const inventoryUrl = `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${vehicleId}`;

  try {
    const vehicleResponse = yield call(get, {
      url: inventoryUrl,
      config: localeConfigSerializer(withLocaleQueryString && locale, {
        status: inventoryStatus,
      }),
    });

    yield put(actions.getDataSuccess(vehicleResponse.data, dataKey));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* VehicleInformationSaga() {
  yield takeLatest(constants.GET_VEHICLE_INFO, getVehicleInfo);
}

import editor from './editor';

export default {
  id: 'TextCTA',
  name: 'Text with CTA',
  description: 'Add text with call to action to the page',
  defaultSlice: 'Content',
  defaultConfig: {
    translations: {
      headlineText: 'Lorem ipsum',
      bodyText:
        'Lorem ipsum dolor sit amet, pro qualisque rationibus ea, nam consul eirmod ut. Vis tantas primis putent in, no mei putant voluptaria philosophia. Ex viris electram pri, dicit pertinacia in cum. Te mea tamquam minimum iudicabit, mundi expetendis per te, ut mel fastidii expetenda adipiscing.',
      buttonText: 'Lorem ipsum',
    },
    button: { label: 'Homepage', url: '' },
    alignment: 'center',
    headlineFont: { fontSize: '20' },
    bodyFont: { fontSize: '14' },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078977/editor/Side-Icon-Carousel.svg',
  editor,
  requirements: {
    columns: [1, 2, 3],
  },
};

export const constants = {
  changeTerritory: 'CHANGE_TERRITORY',
};

export const actions = {
  changeTerritory: territory => ({
    type: constants.changeTerritory,
    payload: territory,
  }),
};

// @flow
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import splitEvery from 'ramda/src/splitEvery';

import FinanceContainer from '../containers/FinanceContainer';
import FinanceExample from './FinanceExample';
import VdpPopupAccordion from './VdpPopupAccordion';
import VdpAccordion from './VdpAccordion';
import Modal from './Modal';
import OpenClose from './OpenClose';
import TradeIn from './JaguarIcons/VDP/TradeIn2';
import Calculator from './JaguarIcons/VDP/Calculator2';
import Print from './JaguarIcons/VDP/Print';
import Close from './JaguarIcons/Global/Close';
import VehicleValuation from './VehicleValuation';
import VdpFeaturedSpecification from './VdpFeaturedSpecification';
import media from '../theme';
import type { ButtonLinkTheme } from '../types';
import { Button, HeadingTwo, Paragraph } from './Global';
import FinanceProducts from './FinanceProducts/FinanceProducts';
import { localiseCurrency } from '../shared/localisation/numbers';
import Spinner, { SpinnerWrapper } from './Spinner';
import WarrantyBadge from './WarrantyBadge';
import { convertSecondaryPriceCurrency } from '../shared/currencyConversion/helpers';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const Container = styled.div.withConfig({
  displayName: 'MyDealContainer',
})`
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${media.min.large`
    ${({ borderWidth, borderColour }) =>
      borderWidth && borderColour
        ? `border: ${borderWidth}px solid ${borderColour.value}`
        : 'border: 2px solid #ACACAC'};
  `};
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ font }) => font && `font-family: ${font};`};
  box-sizing: border-box;
  ${({ financeTitle }) =>
    financeTitle && financeTitle.transform
      ? `text-transform: ${financeTitle.transform}`
      : 'text-transform: uppercase'};
  ${({ financeTitle }) =>
    financeTitle && financeTitle.fontSize
      ? `font-size: ${financeTitle.fontSize}px;}`
      : 'font-size: 16px;'};
  ${media.min.large`
    justify-content: flex-start;
  `};
`;

const FinanceCellContainer = styled.div`
  margin: 0 5%;
  display: flex;
  padding: 10px 0;
`;

const FinanceSection = styled.div`
  width: 50%;
  &:first-child {
    ${({ showBorder }) => showBorder && 'border-right: 1px solid #dedede;'};
    box-sizing: border-box;
    ${({ direction }) =>
      direction === 'rtl' ? 'padding-left: 10px' : 'padding-right: 10px'};
  }
  &:last-child {
    padding-left: 10px;
    ${({ hideHeadings }) =>
      hideHeadings ? 'padding-left: 0' : 'padding-left: 10px'};
  }
`;

const FinanceCell = styled.div`
  padding: 5px 0;
  &:first-child {
    ${({ showBorder }) => showBorder && 'border-bottom: 1px solid #dedede;'};
    box-sizing: border-box;
  }
`;

const FinanceCellHeader = styled.div`
  font-size: 26px;
  font-weight: 600;
  ${({ financeTitle }) =>
    financeTitle && financeTitle.colour
      ? `color: ${financeTitle.colour.value};}`
      : 'color: #444'};
`;

const FinanceCellLabel = styled.div`
  font-size: 14px;
  color: #8b8b8b;
`;

const ContentContainer = styled.div`
  display: none;
  ${media.min.large`
    display: block;
    margin: 0 5%;
  `};
`;

const ContentContainerMobile = styled.div`
  margin: 0 5%;
  ${media.min.large`
    display: none;
  `};
`;

const ActionsContainer = styled.div`
  margin: 0 5%;
  padding-bottom: 20px;
  ${media.min.large`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

const LeftActionButtons = styled.div`
  ${media.min.large`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 3%;
    box-sizing: border-box;
    max-width: 50%;
  `};
`;

const RightActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${media.min.large`
    box-sizing: border-box;
    max-width: 50%;
    padding-left: 3%;
  `};
`;

const ActionButton = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:first-child {
    border-bottom: 1px solid #dedede;
  }
`;

const ActionButtonText = styled.div`
  padding-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #343434;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ModalTitleWrapper = styled.div.withConfig({
  displayName: 'ModalTitleWrapper',
})`
  display: flex;
  background-color: #f0f0f0;
  width: 100%;
  align-items: center;
`;

const UpdateDealActionContainer = styled.div.withConfig({
  displayName: 'UpdateDealActionContainer',
})`
  display: flex;
  width: 93%;
  justify-content: flex-end;
  margin: 0 auto 50px;
`;

const CloseButtonWrapper = styled.div.withConfig({
  displayName: 'CloseButtonWrapper',
})`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 10%;
`;

const FinanceTabContainer = styled.div.withConfig({
  displayName: 'FinanceTabContainer',
})`
  height: 40px;
  align-items: center;
  display: flex;
`;

const FinanceTab = styled.div.withConfig({
  displayName: 'FinanceTab',
})`
  text-align: center;
  flex: 1 1 0%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  background: ${({ selected }) => (selected ? '#ffffff' : '#f0f0f0')};
  color: ${({ fontColour }) => `${fontColour}`};
  cursor: pointer;
`;

const ItemHeader = styled.div`
  color: #444444;
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  white-space: pre-line;
`;

const WarrantyBadgeContainer = styled.div`
  padding: 10px 0;
  text-align: right;
  width: 100%;
`;

const FeaturesSpecContainer = styled.div`
  margin: 0 5% 30px;
`;

const FinanceSpinnerWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FinanceLoadingWrapper = styled.div`
  margin: 10px;
`;

type Info = { label: string, value: string };

type RangeConfig = {
  id: string,
  name: string,
  step: number,
  defaultValue: number,
  fullRangeValues: { min: number, max: number },
  title: string,
  unit: string,
};

type Props = {
  vehicleInfo: Object,
  translations: Object,
  finance: Object,
  price: Info,
  monthlyPrice: Info,
  apr: Info,
  interestRate: Info,
  downpaymentRange: RangeConfig,
  termsRange: RangeConfig,
  financeProductExample: Object,
  requestFinanceProductUpdate: (
    duration: number,
    downpayment: number,
    additionalServices: string,
  ) => void,
  updateMyDeal: (finance: Object) => void,
  updateMyDealWithPartExchange: (finance: Object) => void,
  saveMyDeal: () => void,
  printMyDeal: (finance: Object) => void,
  iconColour?: string,
  buttonTheme: ButtonLinkTheme,
  getValuationMakes: () => {},
  valuationMakes: Object[],
  getValuationModels: string => void,
  valuationModels: Object[],
  getValuationVersions: (makeId: string, modelId: string) => void,
  valuationVersions: Object[],
  getValuationYears: (
    makeId: string,
    modelId: string,
    versionId: string,
  ) => void,
  valuationYears: Object[],
  getValuationMonths: (makeId: string, modelId: string, year: string) => void,
  valuationMonths: Object[],
  getVehicleValuation: (
    make: Object,
    model: Object,
    version: string,
    year: string,
    month: string,
    odometer: string,
  ) => void,
  vehicleValuation: Object,
  editVehicleValuation: string => void,
  vehicleValuationRequestLimit: boolean,
  valuationError: boolean,
  featureFlags: Object,
  globalStyling: Object,
  hasFinance: boolean,
  financeLoaded: boolean,
  locale: string,
  context: 'PCP' | 'HP' | 'Leasing',
  financeProducts: Object[],
  resetFinanceProduct: () => void,
  cancelEditVehicleValuation: () => void,
  financeCellData: Array,
  printing: boolean,
  financeTranslations: Object,
  showFinanceDescriptions: boolean,
  financePricingTable: Object,
  borderColour: Option,
  hideActionButtons: boolean,
  featuredSpec: Object,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyOdometer: Number,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
};
export default function VdpMyDeal({
  vehicleInfo,
  translations,
  finance,
  downpaymentRange,
  termsRange,
  financeProductExample,
  requestFinanceProductUpdate,
  updateMyDeal,
  updateMyDealWithPartExchange,
  saveMyDeal,
  printMyDeal,
  iconColour,
  getValuationMakes,
  valuationMakes,
  getValuationModels,
  valuationModels,
  getValuationVersions,
  valuationVersions,
  getValuationYears,
  valuationYears,
  getValuationMonths,
  valuationMonths,
  getVehicleValuation,
  vehicleValuation,
  vehicleValuationRequestLimit,
  editVehicleValuation,
  cancelEditVehicleValuation,
  valuationError,
  featureFlags,
  globalStyling,
  hasFinance,
  financeLoaded,
  locale,
  resetFinanceProduct,
  financeProducts,
  context,
  financeCellData,
  printing,
  financeTranslations,
  showFinanceDescriptions,
  financePricingTable,
  hideActionButtons,
  featuredSpec,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
  featureSpecificationData,
}: Props) {
  const { currency } = vehicleInfo.price;

  const { rates, secondaryCurrency } = useSelector(state => ({
    rates: state.shared.currencyConversion.exchangeRates[currency]
      ? state.shared.currencyConversion.exchangeRates[currency].rates
      : null,
    secondaryCurrency:
      state.config.config.global.inventory.secondaryCurrency || null,
  }));

  const financeColumns = splitEvery(2, financeCellData);

  const financeCellTextNoDigits = (priceEntry, curCurrency) =>
    localiseCurrency(priceEntry, locale, curCurrency, 0) ||
    translations.searchResultsNotAvailableLabel;

  const FinanceLoadingText = () => (
    <FinanceSpinnerWrapper>
      <Spinner colour={globalStyling.colours.primaryBrandColour} />
      <FinanceLoadingWrapper>
        {translations.financeLoadingText}
      </FinanceLoadingWrapper>
    </FinanceSpinnerWrapper>
  );

  return (
    <Container
      data-qa-hook="my-deal"
      borderWidth={financePricingTable && financePricingTable.borderWidth}
      borderColour={financePricingTable && financePricingTable.borderColour}
    >
      <SpinnerWrapper
        loaded={financeLoaded || true}
        globalStyling={globalStyling}
        label={translations.financeLoadingText}
      >
        {() => (
          <Fragment>
            {financePricingTable && financePricingTable.hideFinanceHeadings ? (
              <Fragment>
                <FinanceCellContainer data-qa-hook="pricing-and-apr">
                  <FinanceSection
                    hideHeadings={
                      financePricingTable &&
                      financePricingTable.hideFinanceHeadings
                    }
                    direction={globalStyling.direction}
                  >
                    <FinanceCell>
                      <FinanceCellHeader
                        financeTitle={
                          financePricingTable &&
                          financePricingTable.financeTitle
                        }
                      >
                        {financeCellTextNoDigits(
                          vehicleInfo.price.value &&
                            Number(vehicleInfo.price.value),
                          vehicleInfo.price.currency,
                        )}
                      </FinanceCellHeader>
                      {secondaryCurrency && rates && (
                        <Fragment>
                          <FinanceCellHeader>
                            {financeCellTextNoDigits(
                              convertSecondaryPriceCurrency(
                                vehicleInfo.price.value,
                                rates,
                                vehicleInfo.price.currency,
                                secondaryCurrency,
                              ),
                              secondaryCurrency,
                            )}
                          </FinanceCellHeader>
                          <FinanceCellLabel>
                            {translateFromTemplate(
                              'exchangeRate',
                              {
                                EXCHANGERATE: rates[secondaryCurrency].toFixed(
                                  2,
                                ),
                              },
                              translations,
                            )}
                          </FinanceCellLabel>
                        </Fragment>
                      )}

                      {!secondaryCurrency && (
                        <FinanceCellLabel>
                          {(financeTranslations &&
                            financeTranslations.financeVehiclePrice) ||
                            translations.searchResultsPriceLabel}
                        </FinanceCellLabel>
                      )}
                    </FinanceCell>
                  </FinanceSection>
                  <WarrantyBadgeContainer>
                    <WarrantyBadge
                      vehicle={vehicleInfo}
                      badgeWithStripUrl={badgeWithStripUrl}
                      badgeWithoutStripUrl={badgeWithoutStripUrl}
                      warrantyOdometer={
                        vehicleInfo.specification.odometer.reading
                      }
                      warrantyBadgeMonths={warrantyBadgeMonths}
                      warrantyBadgeMaxMileage={warrantyBadgeMaxMileage}
                    />
                  </WarrantyBadgeContainer>
                </FinanceCellContainer>
                {translations.vatDisclaimer && (
                  <FinanceCellContainer>
                    <Paragraph styleOverride={() => 'font-size: 12px;'}>
                      {translations.vatDisclaimer}
                    </Paragraph>
                  </FinanceCellContainer>
                )}
              </Fragment>
            ) : (
              <FinanceCellContainer data-qa-hook="pricing-and-apr">
                {financeColumns.map(col => (
                  <FinanceSection showBorder={financeLoaded}>
                    {col.map(item => (
                      <FinanceCell showBorder={financeLoaded}>
                        <FinanceCellHeader
                          financeTitle={
                            financePricingTable &&
                            financePricingTable.financeTitle
                          }
                        >
                          {item.value}
                        </FinanceCellHeader>
                        <FinanceCellLabel>{item.label}</FinanceCellLabel>
                      </FinanceCell>
                    ))}
                  </FinanceSection>
                ))}
              </FinanceCellContainer>
            )}
            <ContentContainer>
              {hasFinance && !featuredSpec && (
                <Header
                  font={
                    globalStyling.fonts.primaryFont &&
                    globalStyling.fonts.primaryFont.value
                  }
                  financeTitle={
                    financePricingTable && financePricingTable.financeTitle
                  }
                >
                  {translations.myDealHeader}
                </Header>
              )}
              <FinanceContainer
                component={props =>
                  hasFinance &&
                  financeLoaded &&
                  !featuredSpec && (
                    <Fragment>
                      <ItemHeader>
                        {props.translateFinanceTemplate('financeMyDealTitle', {
                          PRODUCT:
                            props.finance.translations[
                              `finance${context}Label`
                            ],
                        })}
                      </ItemHeader>
                      <FinanceExample
                        {...props}
                        data={finance}
                        singleColumn
                        labelFontOverride={
                          financePricingTable &&
                          financePricingTable.infoLabelFont
                        }
                        valueFontOverride={
                          financePricingTable &&
                          financePricingTable.infoValueFont
                        }
                        financePricingTable={financePricingTable}
                      />
                    </Fragment>
                  )
                }
              />
            </ContentContainer>
            {hasFinance && (
              <ContentContainerMobile>
                <VdpAccordion title={translations.showFinanceInformation}>
                  <FinanceContainer
                    component={props => (
                      <Fragment>
                        <ItemHeader>
                          {props.translateFinanceTemplate(
                            'financeMyDealTitle',
                            {
                              PRODUCT:
                                props.finance.translations[
                                  `finance${context}Label`
                                ],
                            },
                          )}
                        </ItemHeader>
                        <FinanceExample
                          {...props}
                          data={finance}
                          singleColumn
                        />
                      </Fragment>
                    )}
                  />
                </VdpAccordion>
              </ContentContainerMobile>
            )}

            {featuredSpec && (
              <FeaturesSpecContainer>
                <VdpFeaturedSpecification
                  translations={translations}
                  featuredSpec={featuredSpec}
                  vehicleInfo={vehicleInfo}
                  locale={locale}
                  dataConfig={featureSpecificationData}
                />
              </FeaturesSpecContainer>
            )}
            {!hideActionButtons && hasFinance && (
              <ActionsContainer>
                <LeftActionButtons>
                  <OpenClose>
                    {({ open, openClose }) => (
                      <div>
                        {open ? (
                          <Modal
                            overlay
                            onClickOutside={() => {
                              cancelEditVehicleValuation();
                              openClose();
                            }}
                          >
                            <ModalTitleWrapper>
                              <HeadingTwo
                                styleOverride={() => `
                                  display: flex;
                                  justify-content: center;
                                  width: 80%;
                                  margin-left: 10%;
                                  padding: 20px 0;
                                  text-transform: uppercase;
                                  font-weight: 600;
                                  font-size: 18px;
                                `}
                              >
                                {translations.addPartExchangeTitle}
                              </HeadingTwo>
                              <CloseButtonWrapper
                                onClick={() => {
                                  cancelEditVehicleValuation();
                                  openClose();
                                }}
                              >
                                <Close
                                  width="1em"
                                  height="1em"
                                  colour="#ACACAC"
                                />
                              </CloseButtonWrapper>
                            </ModalTitleWrapper>
                            <VehicleValuation
                              vehicleInfo={vehicleInfo}
                              translations={translations}
                              getValuationMakes={getValuationMakes}
                              valuationMakes={valuationMakes}
                              getValuationModels={getValuationModels}
                              valuationModels={valuationModels}
                              getValuationVersions={getValuationVersions}
                              valuationVersions={valuationVersions}
                              getValuationYears={getValuationYears}
                              valuationYears={valuationYears}
                              getValuationMonths={getValuationMonths}
                              valuationMonths={valuationMonths}
                              getVehicleValuation={getVehicleValuation}
                              vehicleValuation={vehicleValuation}
                              updateMyDealWithValuation={() => {
                                updateMyDealWithPartExchange({
                                  finance: {
                                    ...{
                                      ...finance,
                                      downpayment: Math.min(
                                        vehicleValuation[
                                          Object.keys(vehicleValuation)[0]
                                        ].valuation,
                                        downpaymentRange.fullRangeValues.max,
                                      ),
                                    },
                                    partExchange: {
                                      ...vehicleValuation[
                                        Object.keys(vehicleValuation)[0]
                                      ],
                                    },
                                  },
                                  vehicleDetails: {
                                    ...vehicleInfo,
                                  },
                                });
                                openClose();
                              }}
                              vehicleValuationRequestLimit={
                                vehicleValuationRequestLimit
                              }
                              editVehicleValuation={editVehicleValuation}
                              cancelEditVehicleValuation={
                                cancelEditVehicleValuation
                              }
                              globalStyling={globalStyling}
                              networkError={valuationError}
                            />
                          </Modal>
                        ) : (
                          <ActionButton
                            data-qa-hook="part-exchange-button"
                            onClick={() => {
                              if (featureFlags.partExchange) {
                                openClose();
                              }
                            }}
                          >
                            <TradeIn
                              width="2em"
                              height="2em"
                              colour={iconColour}
                            />
                            <ActionButtonText>
                              {finance.partExchange
                                ? finance.partExchange.vehicle
                                : translations.addPartExchangeLabel}
                            </ActionButtonText>
                          </ActionButton>
                        )}
                      </div>
                    )}
                  </OpenClose>
                  <OpenClose>
                    {({ open, openClose }) => (
                      <div>
                        {open ? (
                          <Modal
                            overlay
                            onClickOutside={() => {
                              openClose();
                              resetFinanceProduct();
                            }}
                          >
                            <ModalTitleWrapper>
                              <HeadingTwo
                                styleOverride={() => `
                            display: flex;
                            justify-content: center;
                            width: 80%;
                            margin-left: 10%;
                            padding: 20px 0;
                            text-transform: uppercase;
                          `}
                              >
                                {financeTranslations.financeConfigureModalTitle}
                              </HeadingTwo>
                              <CloseButtonWrapper
                                onClick={() => {
                                  openClose();
                                  resetFinanceProduct();
                                }}
                              >
                                <Close
                                  width="1em"
                                  height="1em"
                                  colour="#ACACAC"
                                />
                              </CloseButtonWrapper>
                            </ModalTitleWrapper>
                            <FinanceTabContainer>
                              {financeProducts &&
                                financeProducts.map(financeProduct => (
                                  <FinanceTab
                                    onClick={financeProduct.onClick}
                                    selected={context === financeProduct.path}
                                    fontColour={
                                      context === financeProduct.path &&
                                      globalStyling.colours.primaryBrandColour
                                        .value
                                    }
                                  >
                                    {financeProduct.text}
                                  </FinanceTab>
                                ))}
                            </FinanceTabContainer>
                            <FinanceProducts
                              downpaymentRange={downpaymentRange}
                              termsRange={termsRange}
                              financeExample={financeProductExample || finance}
                              financeExampleRenderProp={() => (
                                <FinanceContainer
                                  component={props => (
                                    <VdpPopupAccordion
                                      title={props.translateFinanceTemplate(
                                        'financeMyDealTitle',
                                        {
                                          PRODUCT:
                                            props.finance.translations[
                                              `finance${context}Label`
                                            ],
                                        },
                                      )}
                                    >
                                      <FinanceExample
                                        {...props}
                                        data={financeProductExample || finance}
                                      />
                                    </VdpPopupAccordion>
                                  )}
                                />
                              )}
                              requestFinanceUpdate={requestFinanceProductUpdate}
                              translations={translations}
                              globalStyling={globalStyling}
                              locale={locale}
                              context={context}
                              showDescription={!!showFinanceDescriptions}
                            />
                            <UpdateDealActionContainer>
                              <Button
                                applyStyle="primary"
                                buttonStyle={
                                  globalStyling.uiElements.primaryButton &&
                                  globalStyling.uiElements.primaryButton
                                    .buttonStyle
                                }
                                text={translations.updateMyDealLabel}
                                onClick={() => {
                                  updateMyDeal(
                                    financeProductExample || finance,
                                  );
                                  openClose();
                                }}
                              />
                            </UpdateDealActionContainer>
                          </Modal>
                        ) : (
                          <ActionButton
                            data-qa-hook="edit-finance-button"
                            onClick={() => {
                              openClose();
                            }}
                          >
                            <Calculator
                              width="2em"
                              height="2em"
                              colour={iconColour}
                            />
                            <ActionButtonText>
                              {translations.addFinanceLabel}
                            </ActionButtonText>
                          </ActionButton>
                        )}
                      </div>
                    )}
                  </OpenClose>
                </LeftActionButtons>
                <RightActionButtons>
                  {printing ? (
                    <ActionButton data-qa-hook="printing-my-deal-button">
                      <Spinner colour={{ value: iconColour }} />
                      <ActionButtonText>
                        {translations.printingDeal}
                      </ActionButtonText>
                    </ActionButton>
                  ) : (
                    <ActionButton
                      data-qa-hook="print-my-deal-button"
                      onClick={() => printMyDeal(finance)}
                    >
                      <Print width="2em" height="2em" colour={iconColour} />
                      <ActionButtonText>
                        {translations.printDealLabel}
                      </ActionButtonText>
                    </ActionButton>
                  )}
                  <Button
                    data-qa-hook="save-my-deal-button"
                    applyStyle="secondary"
                    text={translations.saveDealLabel}
                    onClick={saveMyDeal}
                    buttonStyle={
                      globalStyling.uiElements.primaryButton &&
                      globalStyling.uiElements.primaryButton.buttonStyle
                    }
                  />
                </RightActionButtons>
              </ActionsContainer>
            )}
          </Fragment>
        )}
      </SpinnerWrapper>
      {featureFlags.finance && !financeLoaded ? (
        <FinanceLoadingText />
      ) : (
        '\u00A0'
      )}
    </Container>
  );
}

VdpMyDeal.defaultProps = {
  iconColour: '#9E1B32',
};

// @flow
import type { PagePayload } from '../effects/page';
import type { Page, Module, Band, Pod } from '../types/page';
import type { Error } from '../types/error';

const CreateForm: 'CREATE FORM' = 'CREATE FORM';
export type CreateFormAction = {
  type: typeof CreateForm,
  payload: object,
};

const createForm = (payload: object): CreateFormAction => ({
  type: CreateForm,
  payload,
});

const CreatePage: 'CREATE PAGE' = 'CREATE PAGE';
export type CreatePageAction = {
  type: typeof CreatePage,
  payload: PagePayload,
  breakout?: string,
};
const createPage = (
  pagePayload: PagePayload,
  breakout?: string,
): CreatePageAction => ({
  type: CreatePage,
  payload: pagePayload,
  breakout,
});

const CreatePageFailure: 'CREATE PAGE FAILURE' = 'CREATE PAGE FAILURE';
export type CreatePageFailureAction = {
  type: typeof CreatePageFailure,
  payload: Error,
};
const createPageFailure = (payload: Error): CreatePageFailureAction => ({
  type: CreatePageFailure,
  payload,
});

const CreatePageSuccess: 'CREATE PAGE SUCCESS' = 'CREATE PAGE SUCCESS';
export type CreatePageSuccessAction = {
  type: typeof CreatePageSuccess,
  payload: Page,
};
const createPageSuccess = (page: Page): CreatePageSuccessAction => ({
  type: CreatePageSuccess,
  payload: page,
});

const LoadPages: 'LOAD PAGE LIST' = 'LOAD PAGE LIST';
export type LoadPagesAction = {
  type: typeof LoadPages,
  payload: { siteId: string },
};
const loadPages = (siteId: string): LoadPagesAction => ({
  type: LoadPages,
  payload: { siteId },
});

const LoadPagesFailure: 'LOAD PAGE LIST FAILURE' = 'LOAD PAGE LIST FAILURE';
export type LoadPagesFailureAction = {
  type: typeof LoadPagesFailure,
  payload: Error,
};
const loadPagesFailure = (error: Error): LoadPagesFailureAction => ({
  type: LoadPagesFailure,
  payload: error,
});

const LoadPagesSuccess: 'LOAD PAGE LIST SUCCESS' = 'LOAD PAGE LIST SUCCESS';
export type LoadPagesSuccessAction = {
  type: typeof LoadPagesSuccess,
  payload: Page[],
};
const loadPagesSuccess = (pages: Page[]): LoadPagesSuccessAction => ({
  type: LoadPagesSuccess,
  payload: pages,
});

const UpdatePage: 'UPDATE PAGE' = 'UPDATE PAGE';
export type UpdatePageAction = { type: typeof UpdatePage, payload: Page };
const updatePage = (page: Page): UpdatePageAction => ({
  type: UpdatePage,
  payload: page,
});

const DeletePage: 'DELETE PAGE' = 'DELETE PAGE';
export type DeletePageAction = {
  type: typeof DeletePage,
  payload: { siteId: string, pageId: string },
};
const deletePage = (siteId: string, pageId: string): DeletePageAction => ({
  type: DeletePage,
  payload: { siteId, pageId },
});

const DeletePageSuccess: 'DELETE PAGE SUCCESS' = 'DELETE PAGE SUCCESS';
export type DeletePageSuccessAction = {
  type: typeof DeletePageSuccess,
  payload: string,
};
const deletePageSuccess = (pageId: string): DeletePageSuccessAction => ({
  type: DeletePageSuccess,
  payload: pageId,
});

const AddBandToPage: 'ADD BAND TO PAGE' = 'ADD BAND TO PAGE';
export type AddBandToPageAction = {
  type: typeof AddBandToPage,
  payload: { band: Band, pageId: string, action: Function },
};
const addBandToPage = (
  action: Function,
  pageId: string,
  band: Band,
): AddBandToPageAction => ({
  type: AddBandToPage,
  payload: { band, pageId, action },
});

const ReplacePodOnBand: 'REPLACE POD ON BAND' = 'REPLACE POD ON BAND';
export type ReplacePodOnBandAction = {
  type: typeof ReplacePodOnBand,
  payload: { pod: Pod, currentPodId: string, action: Function },
};
const replacePodOnBand = (
  action: Function,
  currentPodId: string,
  pod: Pod,
): ReplacePodOnBandAction => ({
  type: ReplacePodOnBand,
  payload: { pod, currentPodId, action },
});

const RemoveBandFromPage: 'REMOVE BAND FROM PAGE' = 'REMOVE BAND FROM PAGE';
export type RemoveBandFromPageAction = {
  type: typeof RemoveBandFromPage,
  payload: { bandInstanceId: string, pageId: string },
};
const removeBandFromPage = (
  bandInstanceId: string,
  pageId: string,
): RemoveBandFromPageAction => ({
  type: RemoveBandFromPage,
  payload: { bandInstanceId, pageId },
});

const SetPodField: 'SET POD FIELD' = 'SET POD FIELD';
type PodFieldPayload = {
  pageId: string,
  bandId: string,
  podId: string,
  key: string,
  value: any,
};

export type SetPodFieldAction = {
  type: typeof SetPodField,
  payload: PodFieldPayload,
};
const setPodField = (
  podId: string,
  key: string,
  value: any,
): SetFieldAction => ({
  type: SetPodField,
  payload: {
    podId,
    key,
    value,
  },
});

const SetPodListField: 'SET POD LIST FIELD' = 'SET POD LIST FIELD';
type PodListFieldPayload = {
  podId: string,
  listKey: string,
  listIndex: number,
  fieldKey: string,
  value: any,
};

export type SetPodListFieldAction = {
  type: typeof SetPodListField,
  payload: PodListFieldPayload,
};
const setPodListField = (
  podId: string,
  listKey: string,
  listIndex: number,
  fieldKey: string,
  value: any,
): SetPodListFieldAction => ({
  type: SetPodListField,
  payload: {
    podId,
    listKey,
    listIndex,
    fieldKey,
    value,
  },
});

const AddPodSection: 'ADD POD SECTION' = 'ADD POD SECTION';
type AddPodSectionPayload = {
  podId: string,
  section: string,
};
export type AddPodSectionAction = {
  type: typeof AddPodSection,
  payload: AddPodSectionPayload,
};
const addPodSection = (
  podId: string,
  section: string,
): AddPodSectionAction => ({
  type: AddPodSection,
  payload: {
    podId,
    section,
  },
});

const DeletePodSection: 'DELETE POD SECTION' = 'DELETE POD SECTION';
type DeletePodSectionPayload = {
  podId: string,
  section: string,
  listIndex: number,
};
export type DeletePodSectionAction = {
  type: typeof DeletePodSection,
  payload: DeletePodSectionPayload,
};
const deletePodSection = (
  podId: string,
  section: string,
  sectionId: number,
): DeletePodSectionAction => ({
  type: DeletePodSection,
  payload: {
    podId,
    section,
    sectionId,
  },
});

const UpdateBandOrder: 'UPDATE BAND ORDER' = 'UPDATE BAND ORDER';
type UpdateBandOrderPayload = {
  pageId: string,
  bands: Module[],
};

export type UpdateBandOrderAction = {
  type: typeof UpdateBandOrder,
  payload: UpdateBandOrderPayload,
};
const updateBandOrder = (
  pagePath: string,
  bandId: string,
  direction: 'UP' | 'DOWN',
): UpdateBandOrderAction => ({
  type: UpdateBandOrder,
  payload: {
    pagePath,
    bandId,
    direction,
  },
});

const SaveChanges: 'SAVE CHANGES' = 'SAVE CHANGES';
export type SaveChangesAction = {
  type: typeof SaveChanges,
  payload: string,
};
const saveChanges = (siteId: string): SaveChangesAction => ({
  type: SaveChanges,
  payload: siteId,
});

const SaveChangesFailure: 'SAVE CHANGES FAILURE' = 'SAVE CHANGES FAILURE';
export type SaveChangesFailureAction = {
  type: typeof SaveChangesFailure,
  payload: Error,
};
const saveChangesFailure = (error: Error): SaveChangesFailureAction => ({
  type: SaveChangesFailure,
  payload: error,
});

const SaveChangesSuccess: 'SAVE CHANGES SUCCESS' = 'SAVE CHANGES SUCCESS';
export type SaveChangesSuccessAction = {
  type: typeof SaveChangesSuccess,
};
const saveChangesSuccess = (): SaveChangesSuccessAction => ({
  type: SaveChangesSuccess,
});

export const actions = {
  createForm,
  createPage,
  createPageFailure,
  createPageSuccess,
  loadPages,
  loadPagesFailure,
  loadPagesSuccess,
  updatePage,
  deletePage,
  deletePageSuccess,
  addBandToPage,
  removeBandFromPage,
  replacePodOnBand,
  setPodListField,
  addPodSection,
  deletePodSection,
  updateBandOrder,
  setPodField,
  saveChanges,
  saveChangesSuccess,
  saveChangesFailure,
};

export const constants = {
  CreateForm,
  CreatePage,
  CreatePageSuccess,
  CreatePageFailure,
  LoadPages,
  LoadPagesFailure,
  LoadPagesSuccess,
  UpdatePage,
  DeletePage,
  DeletePageSuccess,
  AddBandToPage,
  RemoveBandFromPage,
  ReplacePodOnBand,
  SetPodField,
  SetPodListField,
  AddPodSection,
  DeletePodSection,
  UpdateBandOrder,
  SaveChanges,
  SaveChangesSuccess,
  SaveChangesFailure,
};

export type Action =
  | CreatePageAction
  | CreatePageFailureAction
  | CreatePageSuccessAction
  | LoadPagesAction
  | LoadPagesFailureAction
  | LoadPagesSuccessAction
  | UpdatePageAction
  | DeletePageAction
  | DeletePageSuccessAction
  | ReplacePodOnBandAction
  | SetPodFieldAction
  | SetPodListFieldAction
  | SaveLinkedModulesAction
  | SaveLinkedModulesFailureAction
  | SaveLinkedModulesSuccessAction
  | LoadLinkedModulesAction
  | LoadLinkedModulesFailureAction
  | LoadLinkedModulesSuccessAction
  | AddPodSectionAction
  | DeletePodSectionAction
  | UpdateBandOrderAction
  | SaveChangesAction
  | SaveChangesSuccessAction
  | SaveChangesFailureAction;

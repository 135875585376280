// @flow
import styled from 'styled-components';
import IoTrashB from 'react-icons/lib/io/trash-b';

import type { Theme } from '../theme';

type Props = {
  small?: boolean,
};

const DeleteButton = styled(IoTrashB).withConfig({
  displayName: 'DeleteButton',
})`
  color: ${(props: { theme: Theme }) => props.theme.colours.primary01};
  ${({ small }: Props) =>
    small ? 'margin-left: 6px;' : 'padding: 6px;'} border-radius: 50px;
  &:hover {
    cursor: pointer;
    background: ${(props: { theme: Theme }) => props.theme.colours.disabled02};
  }
`;

export default DeleteButton;

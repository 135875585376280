// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import MyAccountHeading from './MyAccountHeading';
import MyAccountContent from './MyAccountContent';
import { InputField, SelectField } from '../FormFields';
import { Button } from '../Global';
import {
  required,
  validPhone,
  meetsPasswordPolicy,
  passwordMatchesRequired,
} from '../../helpers/validation';
import { titleOptions } from '../../helpers/formTitleOptions';

import media from '../../theme';

const Content = styled.div`
  display: flex;
  min-height: 450px;
  padding: 60px 0 150px 0;
  ${media.max.large`
    display: block;
  `};
`;

const UserDetails = styled.div`
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  ${media.max.large`
    width: 100%;
  `};
`;

const UserPasswordDetails = styled.div`
  width: 50%;
  height: 100%;
  ${media.max.large`
    width: 100%;
    margin-top: 60px;
  `};
`;

const DoubleRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.max.large`
    display: none;
  `};
`;

const MobileSingleRow = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

const VerticalLine = styled.div`
  height: inherit;
  border-left: 1px solid #d8d8d8;
  margin: 0 35px;
  ${media.max.large`
    display: none;
  `};
`;

const HorizontalLine = styled.div`
  width: 100%;
  border-top: 1px solid #d8d8d8;
  box-sizing: border-box;
  margin: ${props => props.marginSize}px 0;
`;

const DeleteMessage = styled.div`
  font-weight: 600;
  color: #444;
  margin-bottom: 20px;
  font-size: 16px;
`;

const FeedbackMessage = styled.div`
  color: ${props => (props.success ? '#68B414' : '#9E1B32')};
  font-weight: 600;
`;

function validateDetails(formKey: string, value: any, translations: Object) {
  const nonValidatedKeys = [
    'email',
    'postCode',
    'vehicleOfInterest',
    'currentVehicle',
    'phoneConsent',
    'smsConsent',
    'emailConsent',
    'errors',
    'currentPassword',
    'newPassword',
    'newPasswordConfirm',
    'deleteConfirm',
    'passwordOnDelete',
  ];
  if (nonValidatedKeys.includes(formKey)) return undefined;

  const validations = {
    title: required,
    firstName: required,
    lastName: required,
    phoneNumber: validPhone,
  };

  return { [formKey]: validations[formKey](value, translations) };
}
function validatePassword(formKey: string, value: any, translations: Object) {
  const nonValidatedKeys = [
    'title',
    'email',
    'postCode',
    'vehicleOfInterest',
    'currentVehicle',
    'phoneConsent',
    'smsConsent',
    'emailConsent',
    'errors',
    'firstName',
    'lastName',
    'phoneNumber',
    'deleteConfirm',
    'passwordOnDelete',
  ];
  if (nonValidatedKeys.includes(formKey)) return undefined;

  const validations = {
    currentPassword: required,
    newPassword: meetsPasswordPolicy,
    newPasswordConfirm: required,
  };
  return { [formKey]: validations[formKey](value, translations, true) };
}
type Props = {
  translations: Object,
  userDetails: Object,
  saveUserDetails: Function,
  changePassword: Function,
  deleteAccount: Function,
  feedback: Object,
  globalStyling: Object,
  config: Object,
};

type State = {
  title: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  postCode: string,
  vehicleOfInterest: string,
  currentVehicle: string,
  smsConsent: boolean,
  emailConsent: boolean,
  phoneConsent: boolean,
  errors: Object,
  currentPassword: string,
  newPassword: string,
  newPasswordConfirm: string,
  deleteConfirm: boolean,
  passwordOnDelete: string,
};

export default class MyAccountDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      userDetails: {
        title = props.translations.formTitleSelect,
        firstName = '',
        lastName = '',
        email = '',
        phoneNumber = '',
        postCode = '',
        vehiclePreferences = {
          interestedIn: '',
          currentVehicle: '',
        },
        contactPreferences = {
          sms: false,
          email: false,
          phone: false,
        },
      },
    } = this.props;

    this.state = {
      title,
      firstName,
      lastName,
      email,
      phoneNumber,
      postCode,
      vehicleOfInterest: vehiclePreferences.interestedIn,
      currentVehicle: vehiclePreferences.currentVehicle,
      smsConsent: contactPreferences && contactPreferences.sms,
      emailConsent: contactPreferences && contactPreferences.email,
      phoneConsent: contactPreferences && contactPreferences.phone,
      errors: {},
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      deleteConfirm: false,
      passwordOnDelete: '',
    };
  }

  onInputChange = (formKey: string, value: string | boolean) => {
    const passwordValidation = [
      'currentPassword',
      'newPassword',
      'newPasswordConfirm',
    ];
    let error;
    if (passwordValidation.includes(formKey)) {
      error = validatePassword(formKey, value, this.props.translations);
    } else {
      error = validateDetails(formKey, value, this.props.translations);
    }
    this.setState(prevState => ({
      [formKey]: value,
      errors: {
        ...prevState.errors,
        ...error,
      },
    }));
  };

  onSelectChange = ({
    keyValue,
    value,
  }: {
    keyValue: string,
    value: any,
    label: string,
  }) => {
    const error = validateDetails(keyValue, value, this.props.translations);
    this.setState(prevState => ({
      [keyValue]: value,
      errors: { ...prevState.errors, ...error },
    }));
  };

  onSaveDetails = () => {
    const errors = Object.entries(this.state)
      .map(entry =>
        validateDetails(entry[0], entry[1], this.props.translations),
      )
      .filter(entry => entry !== undefined)
      .reduce((acc, currVal) => ({ ...acc, ...currVal }), {});

    if (Object.values(errors).filter(error => error !== undefined).length > 0) {
      window.scroll(0, 0);
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...errors },
      }));
      return;
    }
    this.props.saveUserDetails(this.state)();
  };

  onChangePassword = () => {
    const errors = Object.entries(this.state)
      .map(entry =>
        validatePassword(entry[0], entry[1], this.props.translations),
      )
      .filter(entry => entry !== undefined)
      .reduce((acc, currVal) => ({ ...acc, ...currVal }), {});

    if (Object.values(errors).filter(error => error !== undefined).length > 0) {
      window.scroll(0, 0);
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...errors },
      }));
      return;
    }
    const { currentPassword, newPassword, newPasswordConfirm } = this.state;
    const error = passwordMatchesRequired(
      newPassword,
      newPasswordConfirm,
      this.props.translations,
    );
    if (error) {
      window.scroll(0, 0);
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          newPasswordConfirm: error,
        },
      }));
      return;
    }
    this.props.changePassword({ currentPassword, newPassword })();
  };

  onDeleteAccount = () => {
    const { passwordOnDelete: password } = this.state;
    this.props.deleteAccount({ password })();
  };

  render = () => {
    const { translations, feedback, globalStyling } = this.props;
    const {
      title,
      firstName,
      lastName,
      email,
      phoneNumber,
      postCode,
      vehicleOfInterest,
      currentVehicle,
      currentPassword,
      newPassword,
      newPasswordConfirm,
      deleteConfirm,
      passwordOnDelete,
    } = this.state;
    const hasError = field =>
      this.state.errors[field] && this.state.errors[field][0];
    const formTitleOptions = titleOptions(translations);
    const titleOption = (
      formTitleOptions.find(opt => opt.value === title) || {}
    ).value;
    return (
      <div>
        <MyAccountHeading
          heading={translations.myAccountDetailsHeader}
          subHeading={translations.myAccountDetailsSubHeader}
        />
        <MyAccountContent>
          <Content>
            <UserDetails>
              <DoubleRow>
                <SelectField
                  type="text"
                  keyValue="title"
                  value={titleOption}
                  onChange={this.onSelectChange}
                  label={translations.formTitleLabel}
                  options={formTitleOptions}
                  error={hasError('title')}
                  doubleRow
                  required
                />
              </DoubleRow>
              <MobileSingleRow>
                <SelectField
                  keyValue="title"
                  initialValue={titleOption}
                  onChange={this.onSelectChange}
                  label={translations.formTitleLabel}
                  options={formTitleOptions}
                  error={hasError('title')}
                  doubleRow
                  required
                />
              </MobileSingleRow>
              <DoubleRow>
                <InputField
                  type="text"
                  label={translations.firstNameLabel}
                  value={firstName}
                  onChange={this.onInputChange}
                  keyValue="firstName"
                  error={hasError('firstName')}
                  doubleRow
                  required
                />
                <InputField
                  type="text"
                  label={translations.lastNameLabel}
                  value={lastName}
                  onChange={this.onInputChange}
                  keyValue="lastName"
                  error={hasError('lastName')}
                  doubleRow
                  required
                />
              </DoubleRow>
              <MobileSingleRow>
                <InputField
                  type="text"
                  label={translations.firstNameLabel}
                  value={firstName}
                  onChange={this.onInputChange}
                  keyValue="firstName"
                  error={hasError('firstName')}
                  required
                />
                <InputField
                  type="text"
                  label={translations.lastNameLabel}
                  value={lastName}
                  onChange={this.onInputChange}
                  keyValue="lastName"
                  error={hasError('lastName')}
                  required
                />
              </MobileSingleRow>
              <InputField
                type="text"
                label={translations.emailLabel}
                value={email}
                onChange={this.onInputChange}
                keyValue="email"
                disabled
                required
              />
              <DoubleRow>
                <InputField
                  type="text"
                  label={translations.phoneLabel}
                  value={phoneNumber}
                  onChange={this.onInputChange}
                  keyValue="phoneNumber"
                  error={hasError('phoneNumber')}
                  doubleRow
                />
                <InputField
                  type="text"
                  label={translations.postcodeLabel}
                  value={postCode}
                  onChange={this.onInputChange}
                  keyValue="postCode"
                  doubleRow
                />
              </DoubleRow>
              <MobileSingleRow>
                <InputField
                  type="text"
                  label={translations.phoneLabel}
                  value={phoneNumber}
                  onChange={this.onInputChange}
                  keyValue="phoneNumber"
                  error={hasError('phoneNumber')}
                />
                <InputField
                  type="text"
                  label={translations.postcodeLabel}
                  value={postCode}
                  onChange={this.onInputChange}
                  keyValue="postCode"
                />
              </MobileSingleRow>
              <HorizontalLine marginSize={45} />
              <InputField
                type="text"
                label={translations.vehicleOfInterestLabel}
                value={vehicleOfInterest}
                onChange={this.onInputChange}
                keyValue="vehicleOfInterest"
              />
              <InputField
                type="text"
                label={translations.currentVehicleLabel}
                placeholder={translations.currentVehiclePlaceholder}
                value={currentVehicle}
                onChange={this.onInputChange}
                keyValue="currentVehicle"
              />
              <Button
                styleOverride={() => `
                  float: right;
                `}
                onClick={this.onSaveDetails}
                text={translations.myAccountSaveActionButton}
                applyStyle="primary"
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
              />
              {feedback.saveDetails && (
                <FeedbackMessage success={feedback.saveDetails === 'SUCCESS'}>
                  {feedback.saveDetails === 'SUCCESS'
                    ? translations.myAccountSaveDetailsSuccess
                    : translations.myAccountSaveDetailsFailure}
                </FeedbackMessage>
              )}
            </UserDetails>
            <VerticalLine />
            <UserPasswordDetails>
              <InputField
                type="password"
                label={translations.myAccountDetailsCurrentPassword}
                value={currentPassword}
                onChange={this.onInputChange}
                keyValue="currentPassword"
                error={hasError('currentPassword')}
                required
              />
              <InputField
                type="password"
                label={translations.myAccountDetailsNewPassword}
                value={newPassword}
                onChange={this.onInputChange}
                keyValue="newPassword"
                error={hasError('newPassword')}
                required
              />
              <InputField
                type="password"
                label={translations.myAccountDetailsConfirmNewPassword}
                value={newPasswordConfirm}
                onChange={this.onInputChange}
                keyValue="newPasswordConfirm"
                error={hasError('newPasswordConfirm')}
                required
              />
              <Button
                styleOverride={() => `
                  float: right;
                `}
                onClick={this.onChangePassword}
                text={this.props.translations.myAccountChangePassLabel}
                applyStyle="secondary"
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
              />
              {feedback.changePassword && (
                <FeedbackMessage
                  success={feedback.changePassword === 'SUCCESS'}
                >
                  {feedback.changePassword === 'SUCCESS'
                    ? translations.myAccountChangePasswordSuccess
                    : translations.myAccountChangePasswordFailure}
                </FeedbackMessage>
              )}
              <HorizontalLine marginSize={60} />
              {deleteConfirm ? (
                <div>
                  <DeleteMessage>
                    {translations.myAccountConfirmDeletionMessage}
                  </DeleteMessage>
                  <InputField
                    type="password"
                    label={translations.myAccountYourPasswordLabel}
                    value={passwordOnDelete}
                    onChange={this.onInputChange}
                    keyValue="passwordOnDelete"
                    error={hasError('passwordOnDelete')}
                    required
                  />
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%', marginRight: '10px' }}>
                      <Button
                        styleOverride={() => `
                          width: 100%;
                        `}
                        onClick={() =>
                          this.setState(prevState => ({
                            deleteConfirm: !prevState.deleteConfirm,
                          }))
                        }
                        text={this.props.translations.myAccountCancelLabel}
                        applyStyle="secondary"
                        buttonStyle={
                          globalStyling.uiElements.secondaryButton &&
                          globalStyling.uiElements.secondaryButton.buttonStyle
                        }
                      />
                    </div>
                    <div style={{ width: '100%', marginLeft: '10px' }}>
                      <Button
                        styleOverride={() => `
                          width: 100%;
                        `}
                        onClick={this.onDeleteAccount}
                        text={this.props.translations.deleteMyAccountLabel}
                        applyStyle="primary"
                        buttonStyle={
                          globalStyling.uiElements.primaryButton &&
                          globalStyling.uiElements.primaryButton.buttonStyle
                        }
                      />
                    </div>
                  </div>
                  {feedback.deleteAccount && (
                    <FeedbackMessage>
                      {translations.myAccountSaveDetailsFailure}
                    </FeedbackMessage>
                  )}
                </div>
              ) : (
                <Button
                  styleOverride={() => `
                    float: right;
                  `}
                  onClick={() =>
                    this.setState(prevState => ({
                      deleteConfirm: !prevState.deleteConfirm,
                    }))
                  }
                  text={this.props.translations.deleteMyAccountLabel}
                  applyStyle="secondary"
                  buttonStyle={
                    globalStyling.uiElements.secondaryButton &&
                    globalStyling.uiElements.secondaryButton.buttonStyle
                  }
                />
              )}
            </UserPasswordDetails>
          </Content>
        </MyAccountContent>
      </div>
    );
  };
}

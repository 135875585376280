export const getSlices = config => config.slices;
export const getSections = slice => slice.sections;
export const getFields = section => section.fields;

export const getSlice = (config, sliceName) =>
  config.slices.find(slice => slice.name === sliceName);

export const getSection = (config, sliceName, sectionName) =>
  getSlice(config, sliceName).sections.find(
    section => section.name === sectionName,
  );

export const Config = (name, config, ...slices) => ({
  ...config,
  name,
  slices,
});

export const Slice = (name, config, ...sections) => ({
  ...config,
  name,
  sections,
});
export const ListSlice = (name, config, ...fields) => ({
  ...config,
  name,
  fields,
  list: true,
});
export const ListMany = (name, config) => ({ ...config, name });
export const Section = (name, { icon, help }, ...fields) => ({
  name,
  icon,
  help,
  fields,
});
export const OptionalSection = (name, ...fields) => ({
  name,
  fields,
  optional: true,
});

const translatable = id => `translations.${id}`;

export const Field = (datatype, props) => (name, ids, extraProps) => ({
  name,
  ids,
  datatype,
  props: { ...props, ...extraProps },
});

export const DraggableListWithToggle = (name, id) =>
  Field('DraggableListWithToggle')(name, { id });
export const Opacity = (name, id) => Field('Opacity')(name, { id });
export const Pixels = (name, id) => Field('Pixels')(name, { id });
export const Number = (name, id, ...rest) =>
  Field('Number')(name, { id }, ...rest);
export const CheckField = (name, id) => Field('CheckField')(name, { id });

export const Page = (name, id) => Field('Page')(name, { id });
export const Align = (name, id) => Field('Align')(name, { id });
export const VerticalAlign = (name, id) => Field('VerticalAlign')(name, { id });
export const HorizontalVerticalAlign = (name, ids) =>
  Field('HorizontalVerticalAlign')(name, { ...ids });
export const Box = (name, id) => Field('Box')(name, { id });
export const AlignLeftRight = (name, id) =>
  Field('Align', { leftRightOnly: true })(name, { id });
export const Font = (name, id) => Field('Font')(name, { id });
export const FontWithAlignment = (name, id) =>
  Field('Font', {
    withParagraphAlignment: true,
  })(name, { id });
export const Colour = (name, id, ...rest) =>
  Field('Colour')(name, { id }, ...rest);
export const Checkbox = (name, id) => Field('Checkbox')(name, { id });
export const Items = (name, id, ...rest) =>
  Field('Items')(name, { items: id, translations: 'translations' }, ...rest);
export const URL = (name, id) =>
  Field('URL', { displayLabel: true })(name, { id });
export const URLWithoutLabel = (name, id) =>
  Field('URL', { displayLabel: false })(name, { id });
export const TextArea = (name, id, ...rest) =>
  Field('TextArea')(name, { id: translatable(id) }, ...rest);
export const Text = (name, id) =>
  Field('Text', { displayLabel: true })(name, { id: translatable(id) });
export const EditorText = (name, id) =>
  Field('Text', { displayLabel: true })(name, { id });
export const EditorLabel = (name, id) =>
  Field('Text', { displayLabel: true })(name, { id });
export const EditorTextArea = (name, id, ...rest) =>
  Field('TextArea')(name, { id }, ...rest);
export const EmailTextArea = (name, id, ...rest) =>
  Field('TextArea')(name, { id }, ...rest);
export const Toggle = (name, id) => Field('Toggle')(name, { id });
export const Image = (name, ids, ...rest) =>
  Field('Media', { type: 'image' })(
    name,
    {
      media: ids.image,
      ...(ids.accessibilityText && {
        accessibilityText: translatable(ids.accessibilityText),
      }),
    },
    ...rest,
  );
export const Link = (name, ids) =>
  Field('Link')(name, { ...ids, text: translatable(ids.text) });
export const Markdown = (name, textId) =>
  Field('Markdown')(name, { text: translatable(textId) });
export const Video = (name, video, ...rest) =>
  Field('Media', { type: 'video' })(name, { media: video }, ...rest);
export const MediaPicker = (name, ids, ...rest) =>
  Field('MediaPicker')(
    name,
    {
      ...ids,
      bodyText: translatable(ids.bodyText),
      headingText: translatable(ids.headingText),
      ...(ids.accesibilityText && {
        accessibilityText: translatable(ids.accesibilityText),
      }),
    },
    ...rest,
  );
export const EditTextWithToggle = (name, id) =>
  Field('EditText', {
    small: true,
    hasToggle: true,
  })(name, { id });
export const EditText = (name, id) =>
  Field('EditText', {
    small: false,
    hasToggle: false,
  })(name, { id });
export const PageAndExternalLink = (name, id) =>
  Field('Page', {
    withExternalLink: true,
  })(name, { id });
export const Styles = (name, id, ...rest) =>
  Field('Styles')(name, { id }, ...rest);

export const Posts = (name, ids, ...rest) => Field('Posts')(name, ids, ...rest);
export const SocialLinks = (name, id, ...rest) =>
  Field('SocialLinks')(
    name,
    { links: id, translations: 'translations' },
    ...rest,
  );
export const Email = () => ({
  name: 'Email',
  ids: { email: 'email' },
  datatype: 'Email',
});
export const MultiDropdown = (name, id, ...rest) =>
  Field('MultiDropdown')(name, { id, translations: 'translations' }, ...rest);

export default {
  Align,
  AlignLeftRight,
  Box,
  Colour,
  Config,
  EditText,
  EditorLabel,
  EditorTextArea,
  EditorText,
  Font,
  Link,
  Markdown,
  Number,
  Opacity,
  Section,
  Slice,
  Image,
  Styles,
  Text,
  TextArea,
  Video,
  MediaPicker,
  ListSlice,
  VerticalAlign,
  HorizontalVerticalAlign,
  Toggle,
  Items,
  Posts,
  SocialLinks,
  Email,
  MultiDropdown,
};

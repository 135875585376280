export const constants = {
  GET_ENQUIRY_VEHICLE_INFO: 'GET_ENQUIRY_VEHICLE_INFO',
  GET_ENQUIRY_VEHICLE_INFO_SUCCESS: 'GET_ENQUIRY_VEHICLE_INFO_SUCCESS',
  CLEAR_ENQUIRY_VEHICLE_INFO: 'CLEAR_ENQUIRY_VEHICLE_INFO',
};

export const actions = {
  getEnquiryVehicleInfo: vehicleId => ({
    type: constants.GET_ENQUIRY_VEHICLE_INFO,
    vehicleId,
  }),
  getEnquiryVehicleInfoSuccess: vehicleResponse => ({
    type: constants.GET_ENQUIRY_VEHICLE_INFO_SUCCESS,
    payload: vehicleResponse,
  }),
  clearEnquiryVehicleInfo: () => ({
    type: constants.CLEAR_ENQUIRY_VEHICLE_INFO,
  }),
};

// @flow
import React from 'react';
import IconWrapper from './IconWrapper';

export default function ToggleIcon({ icon }: IconProps) {
  return (
    <IconWrapper icon={icon}>
      <img src={icon} alt="" />
    </IconWrapper>
  );
}

ToggleIcon.defaultProps = {
  icon: null,
};

// @flow
/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';

import Phone from './JaguarIcons/Global/Phone';
import Pin from './JaguarIcons/Global/Pin';
import media from '../theme';
import Summary from './Summary';
import Button from './Global/Button';

const Container = styled.div.withConfig({
  displayName: 'TestDriveSummaryContainer',
})`
  height: auto;
  text-align: center;
  margin: 20px auto 80px auto;
  max-width: 930px;
  ${media.max.large`
    width: 90%;
    padding: 0 5%;
  `};
`;

const Block = styled.div`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  margin-bottom: 20px;
  line-height: 21px;
`;

const Heading = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #000000;
`;

const DesktopOnly = styled.div`
  display: block;
  ${media.max.large`
    display: none;
  `};
`;
const MobileOnly = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;
const SummaryFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  padding: 0 25%;
  ${media.max.large`
     width: 100%;
     padding: 20px 0;
   `};
`;

const ThanksDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const ThanksText = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  color: #444444;
`;

const ReserveCarBox = styled.div`
  border: 1px solid #d8d8d8;
`;

const ReserveCarBoxHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  height: 50px;
  font-weight: 600;
  color: #000000;
`;

const ReserveCarBoxFoot = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
  padding: 0 22px;
`;

const DepositText = styled.div`
  max-width: 68%;
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  color: #444444;
`;

const ReserveButtonContainer = styled.div`
  width: 32%;
`;

const SearchResultsActionButton = styled.div`
  padding: 22px 5%;
  margin-top: 32px;
`;

const VisitUs = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
`;

type Props = {
  translations: Object,
  contactUs: (vehicle: Vehicle) => void,
  confirmRequest: Function,
  onChange: Function,
  bookingForm: Object,
  testDriveConfig: Object,
  userIsLoggedIn: boolean,
  userCreated: boolean,
  newUserEmail: string,
  goToMyAccount: () => void,
  onVisitUs: (retailerInformation: Object) => void,
  globalStyling: Object,
  navigateToVdp: () => void,
};

export default function TestDriveSummary(props: Props) {
  window.scroll(0, 0);
  const { retailerInformation } = props.testDriveConfig;
  const {
    globalStyling: { colours },
  } = props;
  const primaryBrandColour =
    colours.primaryBrandColour && colours.primaryBrandColour.value;
  return (
    <div>
      <Container>
        <Block center>
          <Heading>{props.translations.testDriveSummaryHeader}</Heading>
        </Block>
        <DesktopOnly>
          <Block>
            <Summary
              bookingForm={props.bookingForm}
              testDriveConfig={props.testDriveConfig}
              globalStyling={props.globalStyling}
              translations={props.translations}
              navigateToVdp={props.navigateToVdp}
            />
          </Block>
          <Block>
            <ThanksDiv>
              {props.userCreated && (
                <div style={{ maxWidth: '80%', marginRight: '51px' }}>
                  <Heading>
                    {props.translations.testDriveSummaryVerifyAccountHeader}
                  </Heading>
                  <ThanksText>
                    {props.translations.testDriveSummaryVerifyAccountStart}
                    <b>{props.newUserEmail}</b>
                  </ThanksText>
                  <ThanksText>
                    {props.translations.testDriveSummaryVerifyAccountEnd}
                  </ThanksText>
                </div>
              )}
            </ThanksDiv>
            <ThanksDiv>
              <div style={{ maxWidth: '80%', marginRight: '51px' }}>
                <Heading>
                  {props.translations.testDriveSummaryContentHeader}
                </Heading>
                <ThanksText>
                  {props.translations.testDriveSummaryContent}
                </ThanksText>
              </div>
              <div style={{ minWidth: '308px' }}>
                <Block>
                  <Button
                    applyStyle="secondary"
                    buttonStyle={
                      props.globalStyling.uiElements.secondaryButton &&
                      props.globalStyling.uiElements.secondaryButton.buttonStyle
                    }
                    text={props.translations.testDriveSummaryEmailButton}
                    onClick={() => props.contactUs(props.testDriveConfig)}
                  />
                </Block>
                {props.userIsLoggedIn && (
                  <Button
                    applyStyle="secondary"
                    buttonStyle={
                      props.globalStyling.uiElements.secondaryButton &&
                      props.globalStyling.uiElements.secondaryButton.buttonStyle
                    }
                    text={props.translations.testDriveSummaryAccountButton}
                    onClick={props.goToMyAccount}
                  />
                )}
              </div>
            </ThanksDiv>
          </Block>
          <Block>
            <ReserveCarBox>
              <ReserveCarBoxHead>
                <Heading>
                  {props.translations.testDriveSummaryReserveHeader}
                </Heading>
              </ReserveCarBoxHead>
              <ReserveCarBoxFoot>
                <DepositText>
                  {props.translations.testDriveSummaryReserveContent}
                </DepositText>
                <ReserveButtonContainer>
                  <Button
                    applyStyle="primary"
                    buttonStyle={
                      props.globalStyling.uiElements.primaryButton &&
                      props.globalStyling.uiElements.primaryButton.buttonStyle
                    }
                    text={props.translations.testDriveSummaryReserveButton}
                    onClick={() => props.contactUs(props.testDriveConfig)}
                  />
                </ReserveButtonContainer>
              </ReserveCarBoxFoot>
            </ReserveCarBox>
          </Block>
        </DesktopOnly>
        <MobileOnly>
          <Summary
            bookingForm={props.bookingForm}
            testDriveConfig={props.testDriveConfig}
            globalStyling={props.globalStyling}
            translations={props.translations}
          />
          <ThanksDiv>
            {props.userCreated && (
              <div style={{ maxWidth: '80%', marginRight: '51px' }}>
                <Heading>
                  {props.translations.testDriveSummaryVerifyAccountHeader}
                </Heading>
                <ThanksText>
                  {props.translations.testDriveSummaryVerifyAccountStart}
                  <b>{props.newUserEmail}</b>
                </ThanksText>
                <ThanksText>
                  {props.translations.testDriveSummaryVerifyAccountEnd}
                </ThanksText>
              </div>
            )}
          </ThanksDiv>
          <Block>
            {props.translations.testDriveSummaryReserveHeader}
            {props.translations.testDriveSummaryReserveContent}
          </Block>
          <Block>
            {props.userIsLoggedIn && (
              <Button
                applyStyle="secondary"
                buttonStyle={
                  props.globalStyling.uiElements.secondaryButton &&
                  props.globalStyling.uiElements.secondaryButton.buttonStyle
                }
                text={props.translations.testDriveSummaryAccountButton}
                onClick={props.goToMyAccount}
              />
            )}
          </Block>
          <Block>
            <Heading>{props.translations.testDriveSummaryNextSteps}</Heading>
          </Block>
          <Block>
            {props.translations.testDriveSummaryContentHeader}
            {props.translations.testDriveSummaryContent}
          </Block>
          <Block>
            <Button
              applyStyle="secondary"
              buttonStyle={
                props.globalStyling.uiElements.secondaryButton &&
                props.globalStyling.uiElements.secondaryButton.buttonStyle
              }
              text={props.translations.testDriveSummaryEmailButton}
              onClick={() => props.contactUs(props.testDriveConfig)}
            />
          </Block>
          <Block>
            <Button
              applyStyle="secondary"
              buttonStyle={
                props.globalStyling.uiElements.secondaryButton &&
                props.globalStyling.uiElements.secondaryButton.buttonStyle
              }
              text={props.translations.testDriveSummaryReserveButton}
              onClick={() => props.contactUs(props.testDriveConfig)}
            />
          </Block>
        </MobileOnly>
        <SummaryFooter>
          <VisitUs onClick={() => props.onVisitUs(retailerInformation)}>
            <Pin width="2em" height="2em" colour={primaryBrandColour} />
            {props.translations.testDriveSummaryVisitUs}
          </VisitUs>
          <ContactLink href={`tel:${retailerInformation.phone}`}>
            <Phone width="2em" height="2em" colour={primaryBrandColour} />
            {retailerInformation.phone}
          </ContactLink>
        </SummaryFooter>
      </Container>
      <MobileOnly>
        <SearchResultsActionButton>
          <Button
            applyStyle="secondary"
            buttonStyle={
              props.globalStyling.uiElements.secondaryButton &&
              props.globalStyling.uiElements.secondaryButton.buttonStyle
            }
            text={props.translations.testDriveSummaryReturnToSearchResults}
            onClick={() => {}}
          />
        </SearchResultsActionButton>
      </MobileOnly>
    </div>
  );
}

// @flow
import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { actions } from '../state/actions/auth';
import { LogoutWrapper } from '../components';

type Props = {
  login: Function,
  logout: Function,
  isLoggedIn: Boolean,
  children?: Node,
};

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(actions.login()),
  logout: () => dispatch(actions.logout()),
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

class LogoutWrapperWithLifecycle extends React.Component<Props> {
  constructor(props) {
    super(props);
    if (!props.isLoggedIn) {
      props.login();
    }
  }

  render() {
    const { children, isLoggedIn, logout } = this.props;
    return isLoggedIn ? (
      <LogoutWrapper onLogout={logout}>{children}</LogoutWrapper>
    ) : null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutWrapperWithLifecycle);

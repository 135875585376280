// @flow
import React from 'react';
import styled from 'styled-components';

import { Wrapper, TextWrapper } from '../components';
import { Button } from '../components/Global';
import { InputField, TextAreaField } from './FormFields';
import media from '../../theme';
import type { Option, Border, Padding, FormField, Font } from '../../types';

import { useQuickContact } from './useQuickContact';

const StyledTextWrapper = styled(TextWrapper)`
  width: 100%;
  box-sizing: border-box;
  ${media.min.medium`
    width: auto;
    margin: 0 auto;
  `};
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  ${media.min.medium`
    flex-direction: row;
    justify-content: center;
  `};
`;

const SubmissionFormRow = styled(FormRow)`
  ${media.min.medium`
    justify-content: flex-end;
    padding: 0 5px;
  `};
`;

interface IQuickContactConfig {
  translations: {
    firstNameLabel: string,
    lastNameLabel: string,
    emailLabel: string,
    phoneNumberLabel: string,
    commentsLabel: string,
    submitButtonLabel: string,
  };
  fields: FormField[];
  labelFont: Font;
  padding: Padding;
  border: Border;
  backgroundColour: Option;
}

type Props = {
  config: IQuickContactConfig,
  globalStyling: Object,
};

export default ({
  config: {
    translations,
    fields,
    labelFont,
    padding,
    border,
    backgroundColour,
  },
  globalStyling: {
    uiElements: { primaryButton },
  },
}: Props) => {
  const reducedFieldState = fields.reduce(
    (acc, currVal) => ({
      ...acc,
      [currVal.name]: { ...currVal, value: '' },
    }),
    {},
  );
  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    submissionStatus,
    errors,
  } = useQuickContact(reducedFieldState, translations);
  return (
    <Wrapper border={border} backgroundColour={backgroundColour}>
      <StyledTextWrapper padding={padding}>
        <FormRow>
          <InputField
            type="text"
            name="firstName"
            font={labelFont}
            label={translations.firstNameLabel}
            value={values.firstName.value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.firstName}
            required={
              fields.find(f => f.name === 'firstName').status === 'required'
            }
          />
          <InputField
            type="text"
            name="lastName"
            font={labelFont}
            label={translations.lastNameLabel}
            value={values.lastName.value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName}
            required={
              fields.find(f => f.name === 'lastName').status === 'required'
            }
          />
        </FormRow>
        <FormRow>
          <InputField
            type="text"
            name="email"
            font={labelFont}
            label={translations.emailLabel}
            value={values.email.value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            required={
              fields.find(f => f.name === 'email').status === 'required'
            }
          />
          <InputField
            type="text"
            name="phoneNumber"
            font={labelFont}
            label={translations.phoneNumberLabel}
            value={values.phoneNumber.value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber}
            required={
              fields.find(f => f.name === 'phoneNumber').status === 'required'
            }
          />
        </FormRow>
        <FormRow>
          <TextAreaField
            name="comments"
            font={labelFont}
            value={values.comments.value}
            label={translations.commentsLabel}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.comments}
            required={
              fields.find(f => f.name === 'comments').status === 'required'
            }
          />
        </FormRow>
        <SubmissionFormRow>
          <Button
            buttonStyle={primaryButton && primaryButton.buttonStyle}
            text={translations.submitButtonLabel}
            onClick={handleSubmit}
            disabled={submissionStatus === 'submitted'}
          />
        </SubmissionFormRow>
      </StyledTextWrapper>
    </Wrapper>
  );
};

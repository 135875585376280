import React from 'react';

const LeftAlignEnabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#0065DB" d="M1.5 1.5h15v15h-15z" />
      <path
        strokeOpacity={0.296}
        stroke="#0065DB"
        strokeWidth={4}
        d="M-1-1h20v20H-1z"
      />
      <path stroke="#FFF" d="M.5.5h17v17H.5z" />
      <path fill="#0065DB" d="M1 1h2v16H1zM4 6h11v2H4zM4 10h6v2H4z" />
    </g>
  </svg>
);

export default LeftAlignEnabled;

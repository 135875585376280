// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Range, Handle } from 'rc-slider';
import media from '../../../theme';
import { Paragraph, fontStyleOverride } from '../../../components/Global';

import useSliderValues from './useSliderValues';

const ContentWrapper = styled.div`
  ${media.max.medium`
     padding: 8px 30px 16px 30px;
  `};
  padding: 8px 16px 16px 16px;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SliderWrapper = styled.div`
  padding: 0 8px;
`;
type Props = {
  filterKeyMin: string,
  filterKeyMax: string,
  filterLabel: string,
  step?: number,
  availableValues: {
    min: number,
    max: number,
  },
  selectedValues: {
    min: number,
    max: number,
  },
  globalStyling: Object,
  updateFilters: filterObj => void,
  filtersStyling: Object,
  formatter?: any => string,
  hideValues: boolean,
};

const HandleContainer = styled(Handle)`
  margin-top: -8px;
  position: absolute;
  touch-action: pan-x;
  cursor: grab;
  outline: none;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  ${({ src }) => `background-image: url(${src})`};
  background-size: cover;
`;

export default ({
  filterLabel,
  step = 1,
  availableValues,
  selectedValues,
  globalStyling: {
    direction,
    colours: { primaryBrandColour },
  },
  filtersStyling,
  formatter = val => val,
  hideValues,
  filterKeyMin,
  filterKeyMax,
  updateFilters,
}: Props) => {
  const { updateSliderValues, selectedSliderValues } = useSliderValues(
    selectedValues,
  );

  const updateFilterValues = values => {
    const [minValue, maxValue] = values;

    updateFilters([
      { key: filterKeyMin, value: minValue },
      { key: filterKeyMax, value: maxValue },
    ]);
  };

  const { minValue, maxValue } = selectedSliderValues;

  const CustomHandle = props => (
    <HandleContainer
      key={props.index}
      value={props.value}
      offset={props.offset}
      reverse={direction === 'rtl'}
    >
      <IconWrapper src={filtersStyling.filterSliderIcon} alt="" />
    </HandleContainer>
  );

  const minRangeValue = Number(availableValues.min);
  const maxRangeValue = Number(availableValues.max);

  return (
    <ContentWrapper>
      <Labels>
        <Paragraph
          styleOverride={() => `
            ${fontStyleOverride(filtersStyling.filterMenuFont)}
          `}
        >
          {filterLabel}
        </Paragraph>
        {hideValues ? null : (
          <Paragraph
            styleOverride={() => `
              ${fontStyleOverride(filtersStyling.filterMenuFont)}
              text-align: right;
            `}
          >
            {`${formatter(minValue)} - ${formatter(maxValue)}`}
          </Paragraph>
        )}
      </Labels>
      <SliderWrapper>
        <Range
          trackStyle={[
            {
              backgroundColor: primaryBrandColour.value,
            },
          ]}
          handle={CustomHandle}
          step={step}
          value={[Number(minValue), Number(maxValue)]}
          min={minRangeValue}
          max={maxRangeValue}
          allowCross={false}
          onChange={updateSliderValues}
          onAfterChange={updateFilterValues}
          reverse={direction === 'rtl'}
        />
      </SliderWrapper>
    </ContentWrapper>
  );
};

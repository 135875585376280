// @flow
import React from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { Button, FormContainer } from 'cms-ui';
import type { Language, Currency } from '../state/reducers/brand';
import type { Site as SiteType } from '../state/reducers/site';
import { GeneralDetails, CheckboxGroupWithDropdown } from '.';
import { siteValidator } from '../helpers/validation';

const Container = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily};
  overflow: auto;
  height: calc(100vh - 100px - 37px); /* 100px banner, 37px nav bar */
`;

const BoxHeader = styled.h2`
  margin-bottom: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizeHeader};
`;

const StyledButton = styled(Button)`
  margin-top: 56px;
`;

type FormProps = {
  site: SiteType,
  languages: Language[],
  currencies: Currency[],
  submitConfiguration: Function,
  handleSubmit: Function,
  pristine: boolean,
  submitting: boolean,
};

const sortByName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }

  if (a.name < b.name) {
    return -1;
  }

  return 0;
};

const Form = ({
  languages,
  currencies,
  handleSubmit,
  pristine,
  submitting,
}: FormProps) => (
  <Container>
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <BoxHeader>General details</BoxHeader>
        <GeneralDetails />
        <BoxHeader>Language</BoxHeader>
        <CheckboxGroupWithDropdown
          items={languages.sort(sortByName)}
          checkboxGroupName="configuration.languages"
          dropdownName="configuration.defaultLanguage"
          dropdownLabel="Default language"
          valueKey="id"
          getLabelFromOption={i => i.name}
          getValueFromOption={i => i.id}
          optionRenderer={i => i.name}
          valueRenderer={i => i.name}
        />
        <BoxHeader>Currency</BoxHeader>
        <CheckboxGroupWithDropdown
          items={currencies.sort(sortByName)}
          checkboxGroupName="configuration.currencies"
          dropdownName="configuration.defaultCurrency"
          dropdownLabel="Default currency"
          valueKey="id"
          getLabelFromOption={i => i.name}
          getValueFromOption={i => i.id}
          optionRenderer={i => i.name}
          valueRenderer={i => i.name}
        />
        <StyledButton primary type="submit" disabled={pristine || submitting}>
          CONTINUE
        </StyledButton>
      </form>
    </FormContainer>
  </Container>
);

const ConfigureSiteForm = reduxForm({
  form: 'siteForm',
  validate: siteValidator,
  enableReinitialize: true,
})(Form);

type Props = {
  site: SiteType,
  languages: Language[],
  currencies: Currency[],
  saveSite: SiteType => void,
};

export default function Site({ site, languages, currencies, saveSite }: Props) {
  if (!site) return null;

  return (
    <ConfigureSiteForm
      initialValues={site}
      languages={languages}
      currencies={currencies}
      onSubmit={saveSite}
    />
  );
}

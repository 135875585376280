// @flow
import React from 'react';

import type { Node } from 'react';

type Props = {
  children: ({ on: boolean, onClick: () => void }) => Node,
  onToggle?: (on: boolean) => void,
  defaultOn?: boolean,
};

export default class Toggle extends React.Component<Props, *> {
  static defaultProps = {
    onToggle: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      on: props.defaultOn || false,
    };
  }

  onToggle = () =>
    this.setState(
      ({ on }) => ({ on: !on }),
      () => this.props.onToggle && this.props.onToggle(this.state.on),
    );

  render() {
    return this.props.children({
      on: this.state.on,
      onClick: this.onToggle,
    });
  }
}

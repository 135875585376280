// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image } from '../../components';
import {
  Button,
  HeadingTwo,
  HeadingThree,
  fontStyleOverride,
} from '../../components/Global';
import { actions as currencyActions } from '../../../shared/currencyConversion';
import { getVehicles as compareVehicles } from '../../../shared/compare/reducer';
import { getVehicles as shortlistVehicles } from '../../../shared/shortlist/reducer';

const Wrapper = styled.div`
  border: 1px solid #d5d5d5;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const TopSection = styled.div`
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #d5d5d5;
`;

const ImageSection = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
`;

const ApprovedImage = styled.img`
  position: absolute;
  width: 150px;
  height: 35px;
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid #d5d5d5;
  justify-content: space-between;
`;

const PriceWrapper = styled.div`
  ${({ direction }) => `
    text-align: ${direction === 'rtl' ? `right` : `left`}
    padding: ${
      direction === 'rtl' ? '15px 10px 15px 0px' : '15px 0px 15px 10px'
    }
  `}
`;

const MileageWrapper = styled.div`
  ${({ direction }) => `
    text-align: ${direction === 'rtl' ? `left` : `right`}
    padding: ${
      direction === 'rtl' ? '15px 0px 15px 10px' : '15px 10px 15px 0px'
    }
  `}
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 10px;
`;

const SingleButton = styled.div`
  min-width: 26px;
  min-height: 26px;
  margin: 0 5px;
  cursor: pointer;
`;

type Props = {
  vehicle: {
    name: string,
    specification: {
      transmission: string,
      fuelType: string,
      odometer: {
        units: string,
        reading: number,
      },
    },
    images: Object[],
    retailerInformation: Object,
    price: Object,
    powerOutput: string,
  },
  headingFont: Font,
  bodyFont: Font,
  locale: string,
  translations: Object,
  priceNoDigits: () => void,
  mileageNoDigits: () => void,
};

export default ({
  translations,
  config: {
    approvedImage,
    compareIconOn,
    compareIconOff,
    shortlistIconOn,
    shortlistIconOff,
    headingFont,
    bodyFont,
  },
  subtitleData,
  shared,
  globalStyling,
  globalStyling: { uiElements },
  vehicle,
  vehicle: {
    id,
    approved,
    name,
    specification: {
      odometer: { units, reading },
    },
    images,
    price,
  },
  priceNoDigits,
  mileageNoDigits,
  generateSubTitle,
  compareVehicle,
  shortlistVehicle,
  onMoreInfoClick,
}: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(currencyActions.getExchangeRates(price.currency));
  }, []);
  const { rates } = useSelector(state => ({
    rates:
      state.shared.currencyConversion.exchangeRates &&
      state.shared.currencyConversion.exchangeRates[vehicle.price.currency] &&
      state.shared.currencyConversion.exchangeRates[vehicle.price.currency]
        .rates,
  }));

  const shortListedIconOption = () =>
    shortlistVehicles(shared).find(v => v.id === (id || {}))
      ? shortlistIconOn && shortlistIconOn
      : shortlistIconOff && shortlistIconOff;

  const compareIconOption = () =>
    compareVehicles(shared).find(v => v.id === (id || {}))
      ? compareIconOn && compareIconOn
      : compareIconOff && compareIconOff;

  return (
    <Wrapper>
      <TopSection>
        <HeadingTwo
          styleOverride={() => `
            ${fontStyleOverride(headingFont)}
          `}
        >
          {name}
        </HeadingTwo>
        <HeadingThree
          styleOverride={() => `
            ${fontStyleOverride(bodyFont)}
            padding-top: 4px;
          `}
        >
          {generateSubTitle(subtitleData, vehicle)}
        </HeadingThree>
      </TopSection>
      <ImageSection>
        {approved && (
          <ApprovedImage src={approvedImage} alt={translations.imageLabel} />
        )}
        <Image src={images[0]} imageHeight="150" alt="" />
      </ImageSection>
      <PriceSection>
        <PriceWrapper direction={globalStyling.direction}>
          <HeadingTwo
            styleOverride={() => `
              ${fontStyleOverride(headingFont)}
            `}
          >
            {priceNoDigits(price.value, rates, price.currency)}
          </HeadingTwo>
          <HeadingThree
            styleOverride={() => `
              ${fontStyleOverride(bodyFont)}
            `}
          >
            {translations.priceText}
          </HeadingThree>
        </PriceWrapper>
        <MileageWrapper direction={globalStyling.direction}>
          <HeadingTwo
            styleOverride={() => `
              ${fontStyleOverride(headingFont)}
            `}
          >
            {mileageNoDigits(reading)} {units}
          </HeadingTwo>
          <HeadingThree
            styleOverride={() => `
              ${fontStyleOverride(bodyFont)}
            `}
          >
            {translations.mileage}
          </HeadingThree>
        </MileageWrapper>
      </PriceSection>
      <ButtonSection>
        <ButtonWrapper alignment="left">
          <SingleButton onClick={() => compareVehicle(vehicle)}>
            <Image src={compareIconOption} imageHeight="26" alt="" />
          </SingleButton>
          <SingleButton onClick={() => shortlistVehicle(vehicle)}>
            <Image src={shortListedIconOption} imageHeight="26" alt="" />
          </SingleButton>
        </ButtonWrapper>
        <ButtonWrapper aligment="right">
          <Button
            buttonStyle={uiElements.primaryButton.buttonStyle}
            applyStyle="primary"
            text={translations.view}
            onClick={() => onMoreInfoClick(id)}
            styleOverride={() => `
              height: auto;
            `}
          />
        </ButtonWrapper>
      </ButtonSection>
    </Wrapper>
  );
};

// @flow
import React from 'react';

import type { Font, Option, ButtonType, Padding } from '../../types';
import { HeadingTextButton, Icon } from '../components';

interface IIconConfig {
  image: Option;
  imageBehaviour: string;
  imagePosition: string;
  imageHeight: number;
  alignment: string;
  headlineFont: Font;
  translations: {
    headlineText: string,
    bodyText: string,
    buttonText: string,
    imageLabel: string,
  };
  padding: Padding;
  bodyFont: Font;
  alignment: string;
  button: ButtonType;
  backgroundColour: Option;
}

type Props = {
  config: IIconConfig,
  globalStyling: Object,
  dispatch: any => void,
};

export default (props: Props) => (
  <HeadingTextButton {...props}>
    {({
      config: {
        translations: { imageLabel },
        image: { src },
        imageBehaviour,
        imagePosition,
        imageHeight,
      },
    }) => (
      <Icon
        {...{ src, imageBehaviour, imagePosition, imageHeight }}
        role="img"
        aria-label={imageLabel}
      />
    )}
  </HeadingTextButton>
);

import { useDispatch, useSelector } from 'react-redux';
import { actions as pageActions } from '../../state/actions/page';
import { actions as contextActions } from '../../state/actions/context';
import PAGE_TYPES from '../../constants/pageTypes';

const useActions = band => {
  const dispatch = useDispatch();
  const pages = useSelector(state => state.config.pages);
  const page = Object.values(pages).find(p =>
    p.modules.includes(band.instanceId),
  );

  const bandIndex = page.modules.findIndex(
    instanceId => instanceId === band.instanceId,
  );

  const isFirst = bandIndex === 0;

  const isLast = bandIndex === page.modules.length - 1;

  const remove = () => {
    dispatch(contextActions.setPodContext(null));
    dispatch(contextActions.setBandContext(null));
    dispatch(contextActions.clearAction());
    dispatch(pageActions.removeBandFromPage(band.instanceId, page.id));
  };

  const edit = () => {
    dispatch(contextActions.setPodContext(null));
    dispatch(contextActions.setBandContext({ ...band }));
    dispatch(contextActions.setAddPod({ ...band }));
  };

  const moveUp = () =>
    dispatch(
      pageActions.updateBandOrder(page.relativePath, band.instanceId, 'UP'),
    );

  const moveDown = () =>
    dispatch(
      pageActions.updateBandOrder(page.relativePath, band.instanceId, 'DOWN'),
    );

  return {
    actions: {
      ...(page.pageType !== PAGE_TYPES.SEARCH_RESULTS && {
        remove,
        edit,
        moveUp,
        moveDown,
      }),
    },
    isFirst,
    isLast,
  };
};

export default useActions;

// @flow
import React, { type Node, Fragment } from 'react';
import styled from 'styled-components';
import path from 'ramda/src/path';
import uuid from 'uuid/v1';

import Plus from '../../components/JaguarIcons/Global/Plus';
import Minus from '../../components/JaguarIcons/Global/Minus';
import Heart from '../../components/JaguarIcons/Global/Heart';
import Phone from '../../components/JaguarIcons/Global/Phone';
import Remove from '../../components/JaguarIcons/Global/Remove';
import Tick from '../../components/JaguarIcons/Global/Tick';
import CloseIcon from '../../components/JaguarIcons/Global/Close';
import {
  Button,
  HeadingTwo,
  Paragraph,
  HeadingThree,
  fontStyleOverride,
} from '../../components/Global';
import { localiseCurrency } from '../../shared/localisation/numbers';
import Icon from '../../components/StyledIcon';

const Container = styled.table.withConfig({
  displayName: 'CompareVehiclesContainer',
})`
  width: 95%;
  height: auto;
  margin: 0 2.5% 5% 2.5%;
  border-collapse: separate;
  border-spacing: 10px 0;
`;
const TableRow = styled.tr`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '20px')};
  &:last-child {
    td {
      border-bottom: ${({ hideBottomBorder }) =>
        hideBottomBorder ? 'none' : '1px solid #d8d8d8'};
    }
  }
`;
const TableData = styled.td`
  border-bottom: ${({ hideBottomBorder }) =>
    hideBottomBorder ? 'none' : '1px solid #d8d8d8'};
  padding-left: 10px;
  color: #444444;
`;

const ImageContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const IconButton = styled.button.withConfig({
  displayName: 'IconButton',
})`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #444444;
  font-size: 15px;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.button.withConfig({
  displayName: 'IconWrapper',
})`
  background: none;
  border: none;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
`;

const ImageOverlay = styled.div.withConfig({
  displayName: 'ImageOverlay',
})`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const CloseIconBlock = iconColour => (
  <IconWrapper>
    <CloseIcon
      width="1.5em"
      height="1.3125em"
      colour={iconColour || '#000000'}
    />
  </IconWrapper>
);

const TickIconBlock = iconColour => (
  <IconWrapper>
    <Tick
      width="1.5em"
      height="1.3125em"
      colour={iconColour || '#FFFFFF'}
      fill={iconColour || '#FFFFFF'}
    />
  </IconWrapper>
);

const PhoneLink = styled.a.withConfig({
  displayName: 'PhoneLink',
})`
  text-decoration: none;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 0 280px 0;
  justify-content: center;
`;

type RowProps = {
  content: string | Node,
  font: Font,
  unit: ?string,
};

const RenderRow = ({ content, font, unit }: RowProps) => (
  <Paragraph
    styleOverride={() => `
      line-height: 40px;
      text-align: ${font ? `${font.align}` : 'left'};
      margin: 0;
    `}
  >
    {content} {unit}
  </Paragraph>
);

const RetailerPhoneRow = ({
  comparedVehicles,
  globalStyling,
  phoneIconColour,
  font,
  optionalIconFont,
  optionalIconColourFont,
  optionalHeadingThreeFont,
  hideBottomBorder,
}: {
  comparedVehicles: Vehicle[],
  iconColour: string,
  globalStyling: Object,
  font: Font,
  phoneIconColour: Object,
  optionalIconFont: boolean,
  optionalIconColourFont: Font,
  optionalHeadingThreeFont: Font,
  hideBottomBorder: boolean,
}) => (
  <TableRow fontSize={font && font.fontSize}>
    <td style={{ border: 'none' }} />
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`} hideBottomBorder={hideBottomBorder}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
          }}
        >
          <Phone
            width={
              optionalIconColourFont
                ? `${optionalIconColourFont.fontSize}em`
                : '2em'
            }
            height={
              optionalIconColourFont
                ? `${optionalIconColourFont.fontSize}em`
                : '2em'
            }
            colour={phoneIconColour.value}
          />
          <PhoneLink href={`tel:${vehicle.retailerPhone}`}>
            <HeadingThree
              styleOverride={() => `
              ${fontStyleOverride(optionalHeadingThreeFont)}
                font-size: ${
                  optionalHeadingThreeFont
                    ? `${optionalHeadingThreeFont.fontSize}px;`
                    : '16px'
                }
                padding: 0 2px;
                ${optionalIconFont &&
                  `color: ${globalStyling &&
                    globalStyling.colours.primaryBrandColour.value};`}
                `}
            >
              {vehicle.retailerPhone || '-'}
            </HeadingThree>
          </PhoneLink>
        </div>
      </TableData>
    ))}
  </TableRow>
);
const MoreInfoRow = ({
  requestInformation,
  comparedVehicles,
  buttonLabel,
  buttonStyle,
  font,
  buttonSize,
  onMoreInfoClick,
  ctaToVdp,
  hideBottomBorder,
}: {
  requestInformation: (vehicle: Vehicle) => void,
  comparedVehicles: Vehicle[],
  buttonLabel: string,
  buttonStyle: string,
  font: Font,
  buttonSize: Object,
  onMoreInfoClick: Function,
  ctaToVdp: boolean,
  hideBottomBorder: boolean,
}) => (
  <TableRow fontSize={font && font.fontSize}>
    <td style={{ border: 'none' }} />
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`} hideBottomBorder={hideBottomBorder}>
        <div
          style={{
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {ctaToVdp ? (
            <Button
              onClick={() =>
                vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle)
              }
              text={buttonLabel}
              applyStyle="primary"
              buttonStyle={buttonStyle}
              styleOverride={() => `
              width: ${
                buttonSize ? `${buttonSize && buttonSize.width};` : '100%'
              }
          `}
            />
          ) : (
            <Button
              onClick={() => requestInformation(vehicle)}
              text={buttonLabel}
              applyStyle="primary"
              buttonStyle={buttonStyle}
              styleOverride={() => `
                width: ${
                  buttonSize ? `${buttonSize && buttonSize.width};` : '100%'
                }
            `}
            />
          )}
        </div>
      </TableData>
    ))}
  </TableRow>
);

const ShortlistRow = ({
  comparedVehicles,
  shortlistVehicle,
  getShortListedIconColour,
  translations,
  globalStyling,
  font,
  optionalIconFont,
  optionalIconColourFont,
  optionalHeadingThreeFont,
  hideBottomBorder,
}: {
  comparedVehicles: Vehicle[],
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  translations: Object,
  globalStyling: Object,
  font: Font,
  optionalIconFont: boolean,
  optionalIconColourFont: Font,
  optionalHeadingThreeFont: Font,
  hideBottomBorder: boolean,
}) => (
  <TableRow
    hideBottomBorder={hideBottomBorder}
    fontSize={font && font.fontSize}
  >
    <td style={{ border: 'none' }} />
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`} hideBottomBorder={hideBottomBorder}>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '40px' }}
        >
          <IconButton onClick={() => shortlistVehicle(vehicle)}>
            <Heart
              width={
                `${optionalIconColourFont &&
                  optionalIconColourFont.fontSize}em` || '2em'
              }
              height={
                `${optionalIconColourFont &&
                  optionalIconColourFont.fontSize}em` || '2em'
              }
              colour={getShortListedIconColour(vehicle.id)}
            />
            <HeadingThree
              styleOverride={() => `
              ${fontStyleOverride(optionalHeadingThreeFont)}
              padding-left: ${optionalIconColourFont ? '5px' : '10px'};
              font-size: ${
                optionalHeadingThreeFont
                  ? `${optionalHeadingThreeFont.fontSize}px;`
                  : '16px'
              };
              ${optionalIconFont &&
                `color: ${globalStyling &&
                  globalStyling.colours.primaryBrandColour.value};`}
            `}
            >
              {translations.compareVehiclesSaveText}
            </HeadingThree>
          </IconButton>
        </div>
      </TableData>
    ))}
  </TableRow>
);

type DataRowProps = {
  label: string,
  comparedVehicles: Vehicle[],
  keyValues: string[],
  borderBottom?: boolean,
  visible?: boolean,
  font: Font,
  displayFunc: Function,
  unit: string,
};

type EquipmentMetaDataRowProps = {
  label: string,
  comparedVehicles: Vehicle[],
  borderBottom: boolean,
  font: Font,
  globalStyling: globalStyling,
  iconColour: string,
  optionalIconColourFont: Font,
};

const hasDataToDisplayInRow = (comparedVehicles, keyValues, displayFunc) =>
  [0, 1, 2].some(n =>
    keyValues
      ? path(keyValues, comparedVehicles[n])
      : displayFunc(comparedVehicles[n]),
  );

const DataRow = ({
  label,
  comparedVehicles,
  keyValues,
  font,
  visible = true,
  displayFunc,
  unit,
}: DataRowProps) => {
  if (!hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc)) {
    return <tr style={{ border: 'none' }} />;
  }

  return visible ? (
    <TableRow fontSize={font && font.fontSize}>
      <TableData>
        <RenderRow content={label} font={font} />
      </TableData>
      {[0, 1, 2].map(n =>
        comparedVehicles[n] ? (
          <TableData key={`${comparedVehicles[n].id}`}>
            <RenderRow
              content={
                keyValues
                  ? keyValues && path(keyValues, comparedVehicles[n])
                  : displayFunc(comparedVehicles[n]) || '-'
              }
              font={font}
              unit={unit}
            />
          </TableData>
        ) : (
          <td key={uuid()} />
        ),
      )}
    </TableRow>
  ) : null;
};

DataRow.defaultProps = {
  borderBottom: false,
};

const getEquipmentMetaDataIcon = (
  vehicle: Vehicle,
  label: string,
  iconColour: string,
) => {
  const exists =
    vehicle.otherEquipment &&
    vehicle.otherEquipment[0] &&
    vehicle.otherEquipment[0].equipment.filter(equipment => equipment === label)
      .length > 0;
  return exists ? TickIconBlock(iconColour) : CloseIconBlock(iconColour);
};

const EquipmentMetaDataRow = ({
  label,
  comparedVehicles,
  font,
  optionalIconColourFont,
}: EquipmentMetaDataRowProps) => (
  <TableRow fontSize={font && font.fontSize}>
    <TableData>
      <RenderRow content={label} alignment="left" font={font} />
    </TableData>
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`}>
        <RenderRow
          content={getEquipmentMetaDataIcon(
            vehicle,
            label,
            (optionalIconColourFont &&
              optionalIconColourFont.colour &&
              optionalIconColourFont.colour.value) ||
              '#000000',
          )}
          font={font}
        />
      </TableData>
    ))}
  </TableRow>
);

EquipmentMetaDataRow.defaultProps = {
  borderBottom: false,
};

type OptionalExtrasProps = {
  label: string,
  comparedVehicles: Vehicle[],
  optionalExtras: boolean,
  toggleOptionalExtras: () => void,
  font: Font,
  optionalExtrasBackgroundColour: Font,
  optionalIconColourFont: Font,
  equipmentMetaData: Object[],
};

const OptionalExtrasRow = ({
  label,
  comparedVehicles,
  optionalExtras,
  toggleOptionalExtras,
  font,
  optionalExtrasBackgroundColour,
  optionalIconColourFont,
}: OptionalExtrasProps) => (
  <TableRow fontSize={font && font.fontSize}>
    <TableData
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: optionalExtrasBackgroundColour
          ? `${optionalExtrasBackgroundColour.value}`
          : '#f0f0f0',
        borderWidth: '1px',
        color: font ? `${font.colour.value}` : '#000000',
        textTransform: optionalIconColourFont ? 'capitalize' : 'uppercase',
        lineHeight: '40px',
        fontSize: font ? font.fontSize : 20,
      }}
      font={font}
    >
      {label}
      <IconButton onClick={toggleOptionalExtras}>
        {optionalExtras ? (
          <Minus
            width="2em"
            height="2em"
            colour={
              optionalIconColourFont
                ? optionalIconColourFont.colour.value
                : '#7e7e7e'
            }
          />
        ) : (
          <Plus
            width="2em"
            height="2em"
            colour={
              optionalIconColourFont
                ? optionalIconColourFont.colour.value
                : '#7e7e7e'
            }
          />
        )}
      </IconButton>
    </TableData>
    {comparedVehicles.map(vehicle => (
      <TableData
        font={font}
        key={`${vehicle.id}`}
        style={{
          backgroundColor: optionalExtrasBackgroundColour
            ? `${optionalExtrasBackgroundColour.value}`
            : '#f0f0f0',
        }}
      />
    ))}
  </TableRow>
);

type Props = {
  requestInformation: (vehicle: Vehicle) => void,
  removeVehicle: (vehicle: Vehicle) => void,
  comparedVehicles: Vehicle[],
  optionalExtras: boolean,
  toggleOptionalExtras: () => void,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  equipmentMetaData: string[],
  gridConfiguration: Object,
  globalStyling: Object,
  config: Object,
  compareTableDataBackgroundColour: Object,
  currentLanguage: string,
  onMoreInfoClick: Function,
  ctaToVdp: boolean,
};

export default function CompareVehicles(props: Props) {
  const {
    requestInformation,
    optionalExtras,
    toggleOptionalExtras,
    comparedVehicles = [],
    removeVehicle,
    shortlistVehicle,
    getShortListedIconColour,
    equipmentMetaData,
    globalStyling,
    gridConfiguration,
    currentLanguage,
    onMoreInfoClick,
    ctaToVdp,
    config: {
      headerFont,
      paragraphFont,
      subHeaderFont,
      buttonSize,
      closeIconColour,
      infoFont,
      phoneIconColour,
      translations,
      optionalIconFont,
      optionalIconColourFont,
      optionalHeadingThreeFont,
      optionalExtrasBackgroundColour,
      compareTableDataBackgroundColour,
      optionalCloseIcon,
      displayModelAndVariant,
      handleZeroPriceAsText,
      hideBottomBorder,
      showOptionalExtras,
      hideYear,
    },
  } = props;

  const buttonStyle =
    globalStyling.uiElements.primaryButton &&
    globalStyling.uiElements.primaryButton.buttonStyle;

  const localisePrice = vehicle =>
    localiseCurrency(
      vehicle.price.value,
      currentLanguage,
      vehicle.price.currency,
      0,
    );

  const handleZeroPrice = vehicle =>
    vehicle.price.value === 0
      ? props.config.translations.zeroPriceText
      : localiseCurrency(
          vehicle.price.value,
          currentLanguage.replace('_', '-'),
          vehicle.price.currency,
          0,
        );
  const handlePrice = vehicle =>
    handleZeroPriceAsText ? handleZeroPrice(vehicle) : localisePrice(vehicle);

  const getVehicleName = vehicle => {
    if (displayModelAndVariant && vehicle.modelVariant)
      return `${vehicle.name}${' '}${vehicle.modelVariant}`;
    if (hideYear) return vehicle.description;
    return vehicle.name;
  };

  return comparedVehicles.length > 0 ? (
    <Container>
      <tbody>
        <TableRow fontSize={subHeaderFont && subHeaderFont.fontSize}>
          <td />
          {comparedVehicles.map(vehicle => (
            <td key={`${vehicle.id}`} style={{ width: '25%' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={() => removeVehicle(vehicle)}>
                  {optionalCloseIcon ? (
                    <img src={optionalCloseIcon} alt={translations.close} />
                  ) : (
                    <Remove
                      width="2em"
                      height="2em"
                      colour={closeIconColour && closeIconColour.value}
                    />
                  )}
                </IconButton>
              </div>
            </td>
          ))}
        </TableRow>
        <TableRow fontSize={subHeaderFont && subHeaderFont.fontSize}>
          <td
            style={{
              width: '25%',
              background: `${
                compareTableDataBackgroundColour
                  ? `${compareTableDataBackgroundColour.value}`
                  : 'transparent'
              }`,
            }}
          >
            <Paragraph
              styleOverride={() => `
              ${fontStyleOverride(subHeaderFont)}`}
            >
              {translations.compareVehiclesSubHeader}
            </Paragraph>
          </td>
          {comparedVehicles.map(vehicle => (
            <td key={`${vehicle.id}`} style={{ width: '25%' }}>
              <ImageContainer
                onClick={() =>
                  vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle)
                }
              >
                {vehicle.images.length > 0 ? (
                  <StyledImage
                    src={vehicle.images[0]}
                    width="100%"
                    alt={vehicle.name}
                  />
                ) : (
                  <StyledImage
                    src="https://res.cloudinary.com/motortrak/image/upload/v1536583771/Placeholder%20Images/PlaceholderJAG.png"
                    alt="placeholderImage"
                  />
                )}
                {vehicle.status === 'sold' && (
                  <ImageOverlay>
                    <SoldHeading>{translations.sold}</SoldHeading>
                    <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
                  </ImageOverlay>
                )}
              </ImageContainer>
            </td>
          ))}
        </TableRow>
        <DataRow
          label={translations.comparePrice}
          comparedVehicles={comparedVehicles}
          font={infoFont}
          displayFunc={vehicle => `${handlePrice(vehicle)}`}
        />
        <DataRow
          label={translations.compareVehiclesModelYear}
          comparedVehicles={comparedVehicles}
          font={infoFont}
          displayFunc={vehicle => getVehicleName(vehicle)}
        />
        <DataRow
          label={translations.compareVehiclesExterior}
          comparedVehicles={comparedVehicles}
          keyValues={['exterior']}
          font={infoFont}
        />
        <DataRow
          label={translations.compareVehiclesInterior}
          comparedVehicles={comparedVehicles}
          keyValues={['interior']}
          font={infoFont}
        />
        {translations.compareVehiclesVeneer && (
          <DataRow
            label={translations.compareVehiclesVeneer}
            comparedVehicles={comparedVehicles}
            keyValues={['veneer']}
            font={infoFont}
          />
        )}
        <DataRow
          label={translations.compareVehiclesOdometer}
          comparedVehicles={comparedVehicles}
          keyValues={['odometer', 'display']}
          visible={gridConfiguration && gridConfiguration.showOdometer}
          font={infoFont}
        />
        {translations.compareVehiclesTransmission && (
          <DataRow
            label={translations.compareVehiclesTransmission}
            comparedVehicles={comparedVehicles}
            keyValues={['transmission']}
            font={infoFont}
          />
        )}
        {translations.compareVehiclesEmissions && (
          <DataRow
            label={translations.compareVehiclesEmissions}
            comparedVehicles={comparedVehicles}
            keyValues={['performance', 'emissions']}
            font={infoFont}
          />
        )}
        {translations.compareVehiclesFuelConsumption && (
          <DataRow
            label={translations.compareVehiclesFuelConsumption}
            comparedVehicles={comparedVehicles}
            keyValues={['performance', 'fuelConsumption']}
            visible={gridConfiguration && gridConfiguration.showFuelConsumption}
            font={infoFont}
          />
        )}
        <DataRow
          label={translations.compareVehiclesBodystyle}
          comparedVehicles={comparedVehicles}
          keyValues={['bodystyle']}
          font={infoFont}
        />
        <DataRow
          label={translations.compareVehiclesEngine}
          comparedVehicles={comparedVehicles}
          font={infoFont}
          displayFunc={vehicle =>
            `${vehicle.engineCapacity ? vehicle.engineCapacity : '-'}${' '}${
              vehicle.engineCapacity && translations.ccUnit
                ? translations.ccUnit
                : ''
            }`
          }
        />
        <DataRow
          label={translations.compareVehiclesFuelType}
          comparedVehicles={comparedVehicles}
          keyValues={['fuel']}
          font={infoFont}
        />
        {translations.co2EmissionsCombined && (
          <DataRow
            label={translations.co2EmissionsCombined}
            comparedVehicles={comparedVehicles}
            font={infoFont}
            displayFunc={vehicle =>
              `${
                vehicle.engineDetails.co2EmissionsCombined
                  ? vehicle.engineDetails.co2EmissionsCombined
                  : '-'
              }${' '}${
                vehicle.engineDetails.co2EmissionsCombined &&
                translations.co2Unit
                  ? translations.co2Unit
                  : ''
              }`
            }
          />
        )}
        {translations.combinedConsumption && (
          <DataRow
            label={translations.combinedConsumption}
            comparedVehicles={comparedVehicles}
            font={infoFont}
            displayFunc={vehicle =>
              `${
                vehicle.engineDetails.combinedConsumption
                  ? vehicle.engineDetails.combinedConsumption
                  : '-'
              }${' '}${
                vehicle.engineDetails.combinedConsumption &&
                translations.combinedConsumptionUnit
                  ? translations.combinedConsumptionUnit
                  : ''
              }`
            }
          />
        )}
        {translations.consumptionUrban && (
          <DataRow
            label={translations.consumptionUrban}
            comparedVehicles={comparedVehicles}
            font={infoFont}
            displayFunc={vehicle =>
              `${
                vehicle.engineDetails.consumptionUrban
                  ? vehicle.engineDetails.consumptionUrban
                  : '-'
              }${' '}${
                vehicle.engineDetails.consumptionUrban &&
                translations.consumptionUrbanUnit
                  ? translations.consumptionUrbanUnit
                  : ''
              }`
            }
          />
        )}
        {translations.consumptionExtraUrban && (
          <DataRow
            label={translations.consumptionExtraUrban}
            comparedVehicles={comparedVehicles}
            font={infoFont}
            displayFunc={vehicle =>
              `${
                vehicle.engineDetails.consumptionExtraurban
                  ? vehicle.engineDetails.consumptionExtraurban
                  : '-'
              }${' '}${
                vehicle.engineDetails.consumptionExtraurban &&
                translations.consumptionExtraUrbanUnit
                  ? translations.consumptionExtraUrbanUnit
                  : ''
              }`
            }
          />
        )}
        {translations.powerKw && (
          <DataRow
            label={translations.powerKw}
            comparedVehicles={comparedVehicles}
            font={infoFont}
            displayFunc={vehicle =>
              `${
                vehicle.engineDetails.powerKw
                  ? vehicle.engineDetails.powerKw
                  : '-'
              }${' '}${
                vehicle.engineDetails.powerKw && translations.powerUnit
                  ? translations.powerUnit
                  : ''
              }`
            }
          />
        )}
        <DataRow
          label={translations.compareVehiclesRegistration}
          comparedVehicles={comparedVehicles}
          keyValues={['registration']}
          visible={gridConfiguration && gridConfiguration.showRegistrationYear}
          font={infoFont}
        />
        <DataRow
          label={translations.compareVehiclesLocation}
          comparedVehicles={comparedVehicles}
          keyValues={['location']}
          font={infoFont}
        />
        {showOptionalExtras && (
          <Fragment>
            {equipmentMetaData && equipmentMetaData.length && (
              <OptionalExtrasRow
                label={translations.compareVehiclesOptionalExtras}
                comparedVehicles={comparedVehicles}
                optionalExtras={optionalExtras}
                toggleOptionalExtras={toggleOptionalExtras}
                font={infoFont}
                optionalIconColourFont={optionalIconColourFont}
                optionalExtrasBackgroundColour={optionalExtrasBackgroundColour}
                equipmentMetaData={equipmentMetaData}
              />
            )}
            {optionalExtras &&
              equipmentMetaData.map((equipment, idx) => (
                <EquipmentMetaDataRow
                  key={`${equipment}`}
                  label={equipment}
                  comparedVehicles={comparedVehicles}
                  keyValues={['performance', 'fuelConsumption']}
                  visible={
                    gridConfiguration && gridConfiguration.showFuelConsumption
                  }
                  borderBottom={idx === equipmentMetaData.length - 1}
                  font={infoFont}
                  globalStyling={globalStyling}
                  iconColour={phoneIconColour}
                  optionalIconColourFont={optionalIconColourFont}
                />
              ))}
          </Fragment>
        )}
        <RetailerPhoneRow
          comparedVehicles={comparedVehicles}
          globalStyling={globalStyling}
          phoneIconColour={phoneIconColour}
          font={infoFont}
          optionalIconFont={optionalIconFont}
          optionalIconColourFont={optionalIconColourFont}
          optionalHeadingThreeFont={optionalHeadingThreeFont}
          hideBottomBorder={hideBottomBorder}
        />
        <MoreInfoRow
          requestInformation={requestInformation}
          comparedVehicles={comparedVehicles}
          buttonLabel={translations.compareVehiclesRequestInfo}
          buttonStyle={buttonStyle}
          buttonSize={buttonSize}
          font={infoFont}
          onMoreInfoClick={onMoreInfoClick}
          ctaToVdp={ctaToVdp}
          hideBottomBorder={hideBottomBorder}
        />
        <ShortlistRow
          comparedVehicles={comparedVehicles}
          shortlistVehicle={shortlistVehicle}
          getShortListedIconColour={getShortListedIconColour}
          translations={translations}
          globalStyling={globalStyling}
          font={infoFont}
          optionalIconFont={optionalIconFont}
          optionalIconColourFont={optionalIconColourFont}
          optionalHeadingThreeFont={optionalHeadingThreeFont}
          hideBottomBorder={hideBottomBorder}
        />
      </tbody>
    </Container>
  ) : (
    <div style={{ margin: '16px 16px 0' }}>
      {translations.compareVehiclesHeader && (
        <HeadingTwo
          styleOverride={() => `
        font-size: ${headerFont ? `${headerFont.fontSize}px` : '26px'};
        text-transform: ${
          headerFont ? `${headerFont.textTransform}` : 'uppercase;'
        }
        text-align: ${headerFont ? `${headerFont.align}` : 'center;'};
        flex: 1 0 0%;
        letter-spacing: ${headerFont ? `${headerFont.kerning}px` : '3px;'};
      `}
        >
          {translations.compareVehiclesHeader}
        </HeadingTwo>
      )}
      {props.config.emptyIcon && (
        <Fragment>
          <EmptyContainer>
            <Icon link={props.config.emptyIcon} width={100} height={100} />
            <Paragraph
              styleOverride={() => `
                margin-top: 40px;
                font-size: 18px;
                text-align: center;
                ${fontStyleOverride(paragraphFont)}
              `}
            >
              {translations.compareVehiclesSubHeader}
            </Paragraph>
          </EmptyContainer>
        </Fragment>
      )}
    </div>
  );
}

// @flow
const LoadSettings: 'LOAD SETTINGS' = 'LOAD SETTINGS';
type LoadSettingsAction = { type: typeof LoadSettings, payload: any };
const loadSettings = (settings: any): LoadSettingsAction => ({
  type: LoadSettings,
  payload: settings,
});

const LoadSettingsSuccess: 'LOAD SETTINGS SUCCESS' = 'LOAD SETTINGS SUCCESS';
type LoadSettingsSuccessAction = {
  type: typeof LoadSettingsSuccess,
  payload: any,
};
const loadSettingsSuccess = (settings: any): LoadSettingsSuccessAction => ({
  type: LoadSettingsSuccess,
  payload: settings,
});

const LoadSettingsFailure: 'LOAD SETTINGS FAILURE' = 'LOAD SETTINGS FAILURE';
type LoadSettingsFailureAction = { type: typeof LoadSettingsFailure };
const loadSettingsFailure = (): LoadSettingsFailureAction => ({
  type: LoadSettingsFailure,
});

const SaveSettingsSuccess: 'SAVE SETTINGS SUCCESS' = 'SAVE SETTINGS SUCCESS';
type SaveSettingsSuccessAction = {
  type: typeof SaveSettingsSuccess,
  payload: any,
};
const saveSettingsSuccess = (settings: any): SaveSettingsSuccessAction => ({
  type: SaveSettingsSuccess,
  payload: settings,
});

export const constants = {
  LoadSettings,
  LoadSettingsSuccess,
  LoadSettingsFailure,
  SaveSettingsSuccess,
};

export const actions = {
  loadSettings,
  loadSettingsSuccess,
  loadSettingsFailure,
  saveSettingsSuccess,
};

export type Action =
  | LoadSettingsAction
  | LoadSettingsSuccessAction
  | LoadSettingsFailure
  | SaveSettingsSuccessAction;

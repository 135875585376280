// @flow
/* eslint-env browser */
import axios from 'axios';
import compose from 'ramda/src/compose';

export const defaultConfig = ({ token }: { token: string }) => ({
  responseType: 'json',
  headers: token ? { Authorization: `Bearer ${token}` } : {},
});

export const blobContentConfig = (token: string) => ({
  ...defaultConfig({ token }),
  responseType: 'blob',
});

export const multipartFormConfig = (token: string) => {
  const postConfig = defaultConfig({ token });

  return {
    ...postConfig,
    headers: {
      ...postConfig.headers,
      ...{
        'content-type': 'multipart/form-data',
      },
    },
  };
};

type Options = {
  url: string,
  config: Object,
  data?: Object,
  token: string,
};

export function get(options: Options): Promise<{ data: * }> {
  const { url, config = {}, token } = options;
  return axios.get(url, { ...defaultConfig({ token }), ...config });
}

export function post(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.post(url, data, { ...defaultConfig({ token }), ...config });
}

export function put(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.put(url, data, { ...defaultConfig({ token }), ...config });
}

export function remove(options: Options) {
  const { url, config = {}, token } = options;
  return axios.delete(url, { ...defaultConfig({ token }), ...config });
}

const extractLongFileName = headers =>
  headers['content-disposition'].split(' ')[1];
const pretifyLongFileName = longFileName =>
  longFileName
    ? longFileName.replace('filename=', '').replace(';', '')
    : 'download.csv';

const fileNameFromHeaders = compose(
  pretifyLongFileName,
  extractLongFileName,
);

const blobFactory = (headers, data) =>
  new Blob([data], {
    type: headers['content-type'] || 'application/octet-stream',
  });

const downloadWithHiddenAnchorTag = (blob, fileName) => {
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  window.document.body.appendChild(tempLink);
  tempLink.click();
  window.document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

export const fileDownload = ({
  headers,
  data,
}: {
  headers: { 'content-disposition': string, 'content-type': string },
  data: any,
}) => {
  const fileName = fileNameFromHeaders(headers);
  const blob = blobFactory(headers, data);

  return navigator.msSaveBlob
    ? navigator.msSaveBlob(blob, fileName)
    : downloadWithHiddenAnchorTag(blob, fileName);
};

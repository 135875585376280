export default function defaultConfig() {
  return {
    translations: {
      distanceDisplayText: 'Distance up to {VALUE} km',
      ageDisplayText: 'Age up to {VALUE} years',
      odometerDisplayText: 'Mileage up to {VALUE}',
      fuelConsumptionText: 'Up to {VALUE} l/100km',
    },
  };
}

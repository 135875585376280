import React from 'react';
import Fade from './Fade';
import StickyWrapper from './StickyWrapper';
import FiltersLocationSearchBarContainer from '../containers/FiltersLocationSearchBarContainer';

export default ({
  translations,
  dropdownTheme,
  count,
  display,
  globalStyling,
  pathByModule,
  shared,
  onReset,
  config,
}) => (
  <Fade display={display}>
    <StickyWrapper id="stickywrapper" layout="bottom">
      <FiltersLocationSearchBarContainer
        count={count}
        translations={translations}
        dropdownTheme={dropdownTheme}
        globalStyling={globalStyling}
        pathByModule={pathByModule}
        shared={shared}
        onReset={onReset}
        config={config}
        newFilters
      />
    </StickyWrapper>
  </Fade>
);

// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../../theme';

type Props = {
  config: Object,
  height: number,
  background: string,
  mobileHeight: number,
};

const Push = styled.div`
  position: relative;
  height: ${props => props.height}px;
  display: block;
  width: 100%;
  background: ${props => props.background};
  ${media.max.large`
    ${props => props.mobileHeight && `height: ${props.mobileHeight}px;`};
  `};
  @media print {
    display: none;
  }
`;

export default function({ config }: Props) {
  return (
    <Push
      height={config.height}
      mobileHeight={config.mobileHeight}
      background={config.background}
    />
  );
}

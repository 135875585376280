import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { get } from '../../helpers/http';
import {
  inventorySearchServiceUrl,
  getAVLConfig,
} from '../../shared/selectors/settings';

export function useFeatureInventoryRange() {
  const [postData, setFeatureInventoryRangeData] = useState();
  const inventoryUrl = useSelector(inventorySearchServiceUrl);
  const { make, market } = useSelector(getAVLConfig);

  useEffect(() => {
    try {
      const getFeatureInventoryRangeData = async () => {
        const url = `${inventoryUrl}inventory/make/${make}/market/${market}/groups`;
        const result = await get({ url });
        setFeatureInventoryRangeData(result.data.contents);
      };
      getFeatureInventoryRangeData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return postData;
}

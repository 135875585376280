// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import { Button, HeadingTwo, HeadingThree, fontStyleOverride } from './Global';
import Heart from './JaguarIcons/Global/Heart';
import Compare from './JaguarIcons/Global/Compare';
import {
  localiseCurrency,
  localiseNumber,
} from '../shared/localisation/numbers';
import media from '../theme';
import Icon from './StyledIcon';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const Container = styled.div`
  display: flex;
  ${({ backgroundColor }) =>
    `${backgroundColor && `background-color: ${backgroundColor}`}`};
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

const ImageSection = styled.div`
  ${({ width }) => `${width && `width: ${width}%`}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const VehicleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ width }) => `${width && `width: ${width}%`}`};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`;

const VehicleSpecs = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  box-sizing: border-box;
  justify-content: space-around;
  width: 70%;
  border-top: 1px solid #dedede;
  padding-bottom: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0 8px 16px;
  flex: 2;
`;

const ImageOverlay = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const ImageFooter = styled.div`
  position: absolute;
  bottom: 0;
`;

const ViewsContainer = styled.div`
  padding: 0 5px;
  display: flex;
  color: #ffffff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #000000;
  opacity: 0.5;
  font-size: 10px;
`;
const PanoramicIcon = styled(ViewsContainer)`
  display: none;
  padding: 4px;
  img {
    height: 32px;
    width: 32px;
  }
  ${media.min.medium`
    display: block;
  `};
`;

const Views = styled.div`
  padding: 0px 2px;
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const MoreInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 16px 8px 0;
`;

const ActionsRow = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 200px;
  }
`;

const RetailerSectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DistanceSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const NameAndDistanceSection = styled.div`
  display: flex;
`;

const RetailerSectionLabel = styled.div`
  font-size: 14px;
  padding-right: 8px;
`;

const LocationLabel = styled.div`
  font-size: 11px;
  color: #979797;
  text-transform: uppercase;
`;

const Badge = styled.div`
  ${({ badgeBackgroundColour }) =>
    badgeBackgroundColour &&
    `background-color: ${badgeBackgroundColour};
     color:#FFFFFF;
     `};
`;

const SectionWrapper = styled.div`
  padding-bottom: 8px;
`;

const useCustomTitle = visible =>
  visible &&
  (visible.showModelName ||
    visible.showYear ||
    visible.showEngine ||
    visible.showBodystyle ||
    visible.showTransmission ||
    visible.showFuel ||
    visible.showHandDrive ||
    visible.showOdometer ||
    visible.showExteriorColor);

const generateTitle = (vehicle, visible) =>
  `
  ${visible.showYear && vehicle.registration ? `${vehicle.registration}` : ''}
  ${
    visible.showModelName && vehicle.modelGroupName
      ? `${vehicle.modelGroupName}`
      : ''
  }
  ${visible.showEngine && vehicle.engine ? `${vehicle.engine}` : ''}
  ${visible.showBodystyle && vehicle.bodystyle ? `${vehicle.bodystyle}` : ''}
  ${
    visible.showOdometer && vehicle.odometer && vehicle.odometer.display
      ? `${vehicle.odometer.display}`
      : ''
  }
  ${visible.showExteriorColor && vehicle.exterior ? `${vehicle.exterior}` : ''}
  ${
    visible.showTransmission && vehicle.transmission
      ? `${vehicle.transmission}`
      : ''
  }
  ${visible.showFuel && vehicle.fuel ? `${vehicle.fuel}` : ''}
  ${visible.showHandDrive && vehicle.handDrive ? `${vehicle.handDrive}` : ''}
`;

const generateSubTitle = (
  { registration, bodystyle, fuel, odometer: { reading, units } },
  locale,
) =>
  [registration, bodystyle, fuel, `${localiseNumber(reading, locale)}${units}`]
    .filter(Boolean)
    .join(' | ');

const CustomTitle = (vehicle, visible) => generateTitle(vehicle, visible);

const DefaultTitle = vehicle =>
  `${vehicle.registration} ${vehicle.description}`;

const DefaultSubtitle = vehicle => vehicle.colourWithTrim;

const defaultOrCustomText = (vehicle, visible) =>
  useCustomTitle(visible && visible.showTitle)
    ? CustomTitle(vehicle, visible && visible.showTitle)
    : DefaultTitle(vehicle);

const titleHeaderText = (vehicle, visible, titleFont, displayModelAndVariant) =>
  displayModelAndVariant
    ? `${vehicle.modelGroupName} ${vehicle.modelVariant}`
    : defaultOrCustomText(vehicle, visible, titleFont);

const subTitleHeaderText = (vehicle, visible, locale) =>
  useCustomTitle(visible && visible.showSubtitle)
    ? generateSubTitle(vehicle, locale)
    : DefaultSubtitle(vehicle);

type VehicleTitleProps = {
  vehicle: Object,
  titleFont: string,
  subtitleFont: string,
  visible: boolean,
  badgeStyling?: Object,
  badge: boolean,
  displayModelAndVariant: boolean,
};

export const VehicleTitle = ({
  vehicle,
  titleFont,
  visible,
  badgeStyling,
  badge,
  displayModelAndVariant,
}: VehicleTitleProps) => (
  <HeadingTwo
    styleOverride={() =>
      badge
        ? fontStyleOverride(badgeStyling.titleFont)
        : fontStyleOverride(titleFont)
    }
  >
    {titleHeaderText(vehicle, visible, titleFont, displayModelAndVariant)}
  </HeadingTwo>
);

type SectionProps = {
  vehicle: Object,
  translations: Object,
  icon: string,
};

export const VehicleSubTitle = ({
  vehicle,
  subtitleFont,
  visible,
  badgeStyling,
  badge,
  locale,
}: VehicleTitleProps) => (
  <HeadingThree
    styleOverride={() =>
      badge
        ? fontStyleOverride(badgeStyling.subtitleFont)
        : fontStyleOverride(subtitleFont)
    }
  >
    {subTitleHeaderText(vehicle, visible, locale)}
  </HeadingThree>
);

export const ExteriorSection = ({
  vehicle,
  translations,
  icon,
}: SectionProps) => (
  <DesignHighlightsDataContainer>
    <DesignHighlightsDataColumn>
      <IconWrapper>
        <Icon link={icon} />
      </IconWrapper>
    </DesignHighlightsDataColumn>
    <DesignHighlightsDataColumn padding>
      {vehicle.exterior && (
        <DesignHighlightsDataRow>
          <DesignHighlightsLabel>{translations.exterior}</DesignHighlightsLabel>
          <DesignHighlightsValue>{vehicle.exterior}</DesignHighlightsValue>
        </DesignHighlightsDataRow>
      )}
      {vehicle.interior && (
        <DesignHighlightsDataRow>
          <DesignHighlightsLabel>{translations.interior}</DesignHighlightsLabel>
          <DesignHighlightsValue>{vehicle.interior}</DesignHighlightsValue>
        </DesignHighlightsDataRow>
      )}
      {vehicle.veneer && (
        <DesignHighlightsDataRow>
          <DesignHighlightsLabel>{translations.veneer}</DesignHighlightsLabel>
          <DesignHighlightsValue>{vehicle.veneer}</DesignHighlightsValue>
        </DesignHighlightsDataRow>
      )}
    </DesignHighlightsDataColumn>
  </DesignHighlightsDataContainer>
);

export const BespokeFeatureSection = ({ vehicle, icon }: SectionProps) => (
  <DesignHighlightsDataContainer>
    <DesignHighlightsDataColumn>
      <IconWrapper>
        <Icon link={icon} />
      </IconWrapper>
    </DesignHighlightsDataColumn>
    <DesignHighlightsDataColumn padding>
      {vehicle.bespokeFeatures &&
        vehicle.bespokeFeatures.slice(0, 3).map((feature, index) => (
          <DesignHighlightsDataRow
            key={`feature-${feature.name}-${index.toString()}`}
          >
            <DesignHighlightsLabel>{feature.name}</DesignHighlightsLabel>
          </DesignHighlightsDataRow>
        ))}
    </DesignHighlightsDataColumn>
  </DesignHighlightsDataContainer>
);

export const location = (retailerName, distanceLabel) =>
  [retailerName && `${retailerName}`, distanceLabel && `${distanceLabel}`]
    .filter(Boolean)
    .join(' - ');

export const distanceLabel = (distance, translations) =>
  distance &&
  translateFromTemplate(
    'distanceLabel',
    {
      DISTANCE: distance,
    },
    translations,
  );

export const RetailerSection = ({
  vehicle,
  icon,
  translations,
}: SectionProps) => (
  <RetailerSectionContainer>
    <Icon link={icon} />
    <DistanceSection>
      <NameAndDistanceSection>
        <RetailerSectionLabel>
          {location(
            vehicle.retailerName,
            distanceLabel(vehicle.distance, translations),
          )}
        </RetailerSectionLabel>
      </NameAndDistanceSection>
      <LocationLabel>{vehicle.location}</LocationLabel>
    </DistanceSection>
  </RetailerSectionContainer>
);

export const IconWithLabel = ({
  text,
  children,
  onClickFn,
  qaHook,
  styleOverride,
  anchorTextWidth,
  hideOnMobile,
}: {
  text: string,
  children: Node,
  onClickFn: Function,
  qaHook: string,
  styleOverride: string,
  anchorTextWidth: string,
  hideOnMobile: boolean,
}) => (
  <IconWrapper
    styleOverride={styleOverride}
    data-qa-hook={qaHook}
    onClick={onClickFn}
    hideOnMobile={hideOnMobile}
  >
    {children}
    {text && <AnchorText width={anchorTextWidth}>{text}</AnchorText>}
  </IconWrapper>
);

const DesignHighlightsDataContainer = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: #8b8b8b;
  display: flex;
  flex-direction: row;
`;
const DesignHighlightsDataColumn = styled.div`
  ${({ padding }) => padding && 'padding-left: 10px;'};
  display: flex;
  flex-direction: column;
`;
const DesignHighlightsDataRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
`;
const DesignHighlightsLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
`;
const DesignHighlightsValue = styled.div`
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  padding-left: 5px;
  line-height: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  color: #00aaee;
  font-size: 16px;
  letter-spacing: 1px;
  ${({ styleOverride }) => styleOverride && styleOverride()};
  ${media.max.medium`
    ${({ hideOnMobile }) => `${hideOnMobile && 'display: none'}`};
  `};
`;

const VehiclePriceSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 8px;
  padding-right: 16px;
  flex: 1;
`;

const AnchorText = styled.div`
  ${({ width }) => `${width && `width: ${width}`}`};
  display: flex;
  align-items: center;
`;
const PriceWrapper = styled.div`
  text-align: right;
`;
const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const VdpButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  config: Object,
  vehicle: Object,
  placeholdingImage: string,
  filtersExpanded: boolean,
  onMoreInfoClick: Function,
  translations: Object,
  compareVehicle: Function,
  shortlistVehicle: Function,
  shortListedIconColour: string,
  compareIconColour: string,
  renderTopRightIcon: () => Node,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  tileBackgroundColor: Object,
  headerItemFont: Object,
  vehicleModelTitleFontWeight: number,
  badgeStyling: Object,
  listViewImageWidth: number,
  handleZeroPriceAsText: boolean,
  showNumberOfImagesInsteadOfViews: boolean,
  imageWidth: number,
};

type ImageTileSectionProps = {
  vehicle: Object,
  translations: Object,
  onMoreInfoClick: Function,
  imageSrc: string,
  width: string,
  listViewImageWidth: string,
  showNumberOfImages: boolean,
  icon: string,
  icon360: string,
};

export const ImageTileSection = ({
  vehicle,
  translations,
  onMoreInfoClick,
  imageSrc,
  width,
  showNumberOfImages,
  icon,
  icon360,
}: ImageTileSectionProps) => (
  <ImageSection
    width={width}
    onClick={() =>
      vehicle && vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle)
    }
  >
    <Image src={imageSrc} />
    {vehicle && vehicle.status === 'sold' && (
      <ImageOverlay>
        <SoldHeading>{translations.sold}</SoldHeading>
        <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
      </ImageOverlay>
    )}
    <ImageFooter>
      {((vehicle.panoramicViews && vehicle.panoramicViews.exterior) ||
        (vehicle.panoramicViews && vehicle.panoramicViews.interior)) && (
        <PanoramicIcon>
          <img src={icon360} alt="360" />
        </PanoramicIcon>
      )}
      <ViewsContainer>
        <Icon link={icon} width={16} height={16} />
        {showNumberOfImages ? (
          <Views>{vehicle && vehicle.images && vehicle.images.length}</Views>
        ) : (
          <Views>{vehicle && vehicle.views}</Views>
        )}
      </ViewsContainer>
    </ImageFooter>
  </ImageSection>
);

export default function SearchResultGridBlock({
  config,
  vehicle,
  placeholdingImage,
  filtersExpanded,
  onMoreInfoClick,
  translations,
  compareVehicle,
  shortlistVehicle,
  shortListedIconColour,
  compareIconColour,
  globalStyling,
  visibility,
  vehicleModelTitleFontWeight,
  headerItemFont,
  locale,
  badgeStyling,
  listViewImageWidth,
  handleZeroPriceAsText,
  showNumberOfImagesInsteadOfViews,
  imageWidth,
}: Props) {
  const imageSrc =
    vehicle && vehicle.images && vehicle.images.length > 0
      ? vehicle.images[0]
      : placeholdingImage;
  const badge =
    badgeStyling &&
    badgeStyling.badgeVariant &&
    vehicle.modelVariant &&
    vehicle.modelVariant.toLowerCase() ===
      badgeStyling.badgeVariant.toLowerCase();

  const showModelAndVariant =
    badgeStyling &&
    badgeStyling.displayModelAndVariant &&
    vehicle.modelVariant !== null;

  const bespokeFeaturesColour = badge ? '#FFFFFF' : '#00AAEE';
  const starIcon = badge
    ? badgeStyling && badgeStyling.whiteStarIcon
    : config.starIcon;
  const localisePrice = localiseCurrency(
    vehicle.price.value,
    locale,
    vehicle.price.currency,
    0,
  );
  const handleZeroPrice =
    vehicle.price.value === 0 ? translations.zeroPriceText : localisePrice;
  const handlePrice = handleZeroPriceAsText ? handleZeroPrice : localisePrice;
  const displayExteriorSection =
    vehicle.exterior || vehicle.interior || vehicle.veneer;
  return (
    <Container
      data-qa-hook="vehicle-search-result-block"
      key={vehicle.id}
      filtersExpanded={filtersExpanded}
      backgroundColor="white"
    >
      <ImageTileSection
        vehicle={vehicle}
        imageSrc={imageSrc}
        translations={translations}
        onMoreInfoClick={onMoreInfoClick}
        listViewImageWidth={listViewImageWidth}
        width={imageWidth}
        showNumberOfImages={showNumberOfImagesInsteadOfViews}
        icon={config.imagesIcon}
      />
      <VehicleInfoContainer width={100 - imageWidth}>
        <Badge
          badgeBackgroundColour={
            badge && badgeStyling && badgeStyling.badgeBackgroundColour
          }
        >
          <InfoSection>
            <TitleContainer>
              <VehicleTitle
                vehicle={vehicle}
                fontWeight={vehicleModelTitleFontWeight}
                titleFont={headerItemFont && headerItemFont.titleFont}
                visible={visibility}
                globalStyling={globalStyling}
                badge={badge}
                badgeStyling={badgeStyling}
                displayModelAndVariant={showModelAndVariant}
              />
              <VehicleSubTitle
                vehicle={vehicle}
                locale={locale}
                fontWeight={vehicleModelTitleFontWeight}
                subtitleFont={headerItemFont && headerItemFont.subtitleFont}
                visible={visibility}
                globalStyling={globalStyling}
                badge={badge}
                badgeStyling={badgeStyling}
              />
            </TitleContainer>
            <VehiclePriceSectionWrapper>
              <BadgeWrapper>
                {vehicle.modelCollectionName && (
                  <IconWithLabel
                    styleOverride={() =>
                      `font-size:14px; color:${bespokeFeaturesColour}`
                    }
                    text={vehicle.modelCollectionName}
                  >
                    <Icon link={starIcon} width={20} height={20} />
                  </IconWithLabel>
                )}
              </BadgeWrapper>
              <PriceWrapper>
                <HeadingThree
                  styleOverride={() =>
                    badge &&
                    badgeStyling &&
                    `color:${badgeStyling.subtitleFont.colour.value}`
                  }
                >
                  {handlePrice}
                </HeadingThree>
              </PriceWrapper>
            </VehiclePriceSectionWrapper>
          </InfoSection>
        </Badge>
        <ActionsRow>
          <VehicleSpecs>
            {displayExteriorSection && (
              <SectionWrapper>
                <ExteriorSection
                  vehicle={vehicle}
                  translations={translations}
                  icon={config.coloursIcon}
                />
              </SectionWrapper>
            )}
            {vehicle.bespokeFeatures && (
              <SectionWrapper>
                <BespokeFeatureSection
                  vehicle={vehicle}
                  icon={config.designIcon}
                />
              </SectionWrapper>
            )}
            {visibility.showDistance && (
              <RetailerSection
                vehicle={vehicle}
                icon={config.pinIcon}
                translations={translations}
              />
            )}
          </VehicleSpecs>
          <MoreInfoRow>
            <ActionsContainer>
              <IconWithLabel
                styleOverride={() => 'cursor: pointer;'}
                qaHook="compare-vehicle-button"
                text={translations.searchResultsAddToCompare}
                onClickFn={() => compareVehicle(vehicle)}
                hideOnMobile
              >
                <Compare
                  width="24px"
                  height="24px"
                  colour={compareIconColour}
                />
              </IconWithLabel>
              <IconWithLabel
                styleOverride={() => 'cursor: pointer; margin-left: 8px;'}
                qaHook="shortlist-vehicle-button"
                text={translations.searchResultsSaveVehicle}
                onClickFn={() => shortlistVehicle(vehicle)}
              >
                <Heart
                  width="24px"
                  height="24px"
                  colour={shortListedIconColour}
                />
              </IconWithLabel>
            </ActionsContainer>
            {vehicle.status !== 'sold' && (
              <VdpButtonWrapper>
                <Button
                  data-qa-hook="more-info-button"
                  onClick={() =>
                    vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle)
                  }
                  buttonStyle={
                    globalStyling.uiElements.primaryButton &&
                    globalStyling.uiElements.primaryButton.buttonStyle
                  }
                  applyStyle="primary"
                  text={translations.searchResultTileMoreInfo}
                />
              </VdpButtonWrapper>
            )}
          </MoreInfoRow>
        </ActionsRow>
      </VehicleInfoContainer>
    </Container>
  );
}

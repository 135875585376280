// @flow
import React from 'react';
import styled from 'styled-components';
import DatePicker from './DatePicker';
import type { Theme } from '../theme';
import Calendar from './JaguarIcons/Global/Calendar';
import media from '../theme';

type ItemProps = {
  open: boolean,
  date: Date,
  selected: Date,
  toggleCalendar: () => void,
  onSelectDate: (string, string) => void,
  globalStyling: Object,
  calendarStyles: Object,
  translations: Object,
  mobileWidth: number,
};

type ThemeProps = ItemProps & { theme: Theme };

const DatePickerContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 300px;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  display: flex;
  padding-top: 15px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.18);
  display: ${({ open }: ItemProps) => (open ? 'block' : 'none')};
  box-sizing: border-box;
  ${media.max.small`
  ${({ mobileWidth }) => `${mobileWidth && `width: ${mobileWidth}%`}`};
  `};
`;

const CalendarIconContainer = styled.div`
  padding: ${({ calendarIconContainer }) =>
    calendarIconContainer && calendarIconContainer.padding
      ? `${calendarIconContainer.padding}px`
      : '10px'};
  width: auto;
  border: 1px solid
    ${({ calendarIconContainer }) =>
      calendarIconContainer && calendarIconContainer.borderColour
        ? calendarIconContainer.borderColour
        : '#999999'};
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
`;

const DatePickerWrapperContainer = styled.div`
  height: auto;
  width: 100%;
  margin-top: ${({ datePickerWrapperContainer }) =>
    datePickerWrapperContainer && datePickerWrapperContainer.marginTop
      ? datePickerWrapperContainer.marginTop
      : '15px'};
`;

const FormattedDateContainer = styled.div`
  float: left;
  margin: 5px;
  color: #7e7e7e;
  font-size: ${({ formattedDateContainer }) =>
    formattedDateContainer && formattedDateContainer.fontSize
      ? `${formattedDateContainer.fontSize}px`
      : '14px'};
  line-height: 17px;
  text-transform: capitalize;
`;

const IconContainer = styled.div`
  float: right;
  cursor: pointer;
  margin-top: ${({ iconContainer }) =>
    iconContainer && iconContainer.marginTop
      ? `${iconContainer.marginTop}px`
      : '0'};
`;

const DatePickerItem = ({
  open,
  date,
  selected,
  toggleCalendar,
  onSelectDate,
  globalStyling,
  calendarStyles,
  translations,
  locale,
  mobileWidth,
}: ItemProps) => {
  const handleOnDateSelected = ({ date: selectedDate }) => {
    onSelectDate('date', selectedDate);
    toggleCalendar();
  };

  let localeFormatted = 'en-GB';

  if (locale) {
    localeFormatted = locale.replace('_', '-');
  }

  const formattedDate = selected
    ? new Date(selected).toLocaleDateString(localeFormatted, {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
    : null;

  const {
    calendarIconContainer = '',
    datePickerWrapperContainer = '',
    iconContainer = '',
    formattedDateContainer = '',
  } = calendarStyles || '';
  const { primaryBrandColour } = globalStyling.colours;
  return (
    <DatePickerWrapperContainer
      datePickerWrapperContainer={datePickerWrapperContainer}
    >
      <CalendarIconContainer
        onClick={toggleCalendar}
        calendarIconContainer={calendarIconContainer}
      >
        <FormattedDateContainer formattedDateContainer={formattedDateContainer}>
          {formattedDate}
        </FormattedDateContainer>
        <IconContainer iconContainer={iconContainer}>
          <Calendar
            colour={primaryBrandColour.value || '#7E7E7E'}
            width="27px"
            height="24px"
          />
        </IconContainer>
      </CalendarIconContainer>
      <DatePickerContainer
        open={open}
        date={new Date(date)}
        mobileWidth={mobileWidth}
      >
        <DatePicker
          selected={selected}
          date={new Date(date)}
          onDateSelected={handleOnDateSelected}
          globalStyling={globalStyling}
          calendarStyles={calendarStyles}
          translations={translations}
        />
      </DatePickerContainer>
    </DatePickerWrapperContainer>
  );
};

export default function DatePickerWrapper({
  open,
  date,
  selected,
  toggleCalendar,
  onSelectDate,
  globalStyling,
  calendarStyles,
  translations,
  locale,
  mobileWidth,
}: ItemProps) {
  return (
    <DatePickerItem
      open={open}
      date={date}
      selected={selected}
      toggleCalendar={toggleCalendar}
      onSelectDate={onSelectDate}
      globalStyling={globalStyling}
      calendarStyles={calendarStyles}
      translations={translations}
      locale={locale}
      mobileWidth={mobileWidth}
    />
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import MyAccountLanding from './MyAccountLanding';
import MyAccountDeals from './MyAccountDeals';
import type { MyDeal, PrintDeal } from './MyAccountDeals';
import MyAccountTestDrives from './MyAccountTestDrives';
import type { TestDrive } from './MyAccountTestDrives';
import MyAccountDetails from './MyAccountDetails';

const Container = styled.div.withConfig({
  displayName: 'MyAccountContainer',
})`
  width: 100%;
  height: auto;
`;

type Props = {
  globalStyling: Object,
  translations: Object,
  iconColour: string,
  context: 'Account' | 'Deals' | 'TestDrives' | 'Details',
  changeAccountView: (name: string) => void,
  userDetails: Object,
  saveUserDetails: (userDetails: Object) => void,
  changePassword: (passwords: Object) => void,
  deleteAccount: () => void,
  testDrives: TestDrive[],
  myDeals: MyDeal[],
  onContactClick: Function,
  goToVdp: (id: string) => void,
  deleteMyDeal: (id: string) => void,
  printMyDeal: (deal: PrintDeal) => void,
  onShortListClick: Function,
  printing: Array,
};

const contextRenderer = {
  Account: (props: Props) => (
    <MyAccountLanding
      featureFlags={props.featureFlags}
      translations={props.translations}
      changeAccountView={props.changeAccountView}
      userDetails={props.userDetails}
      signOut={props.signOut}
      onShortListClick={props.onShortListClick}
    />
  ),
  Deals: props => (
    <MyAccountDeals
      finance={props.finance}
      translations={props.translations}
      myDeals={props.myDeals}
      goToVdp={props.goToVdp}
      deleteMyDeal={props.deleteMyDeal}
      printMyDeal={props.printMyDeal}
      globalStyling={props.globalStyling}
      iconColour={props.iconColour}
      printing={props.printing}
      brandLogoUrl={props.brandLogoUrl}
    />
  ),
  TestDrives: props => (
    <MyAccountTestDrives
      translations={props.translations}
      testDrives={props.testDrives}
      onContactClick={props.onContactClick}
      onDeleteTestDriveClick={props.onDeleteTestDriveClick}
      goToVdp={props.goToVdp}
      globalStyling={props.globalStyling}
    />
  ),
  Details: props => (
    <MyAccountDetails
      globalStyling={props.globalStyling}
      translations={props.translations}
      userDetails={props.userDetails}
      saveUserDetails={props.saveUserDetails}
      changePassword={props.changePassword}
      deleteAccount={props.deleteAccount}
      feedback={props.detailsFeedback}
      config={props.config}
    />
  ),
};

export default function MyAccount(props: Props) {
  return <Container>{contextRenderer[props.context](props)}</Container>;
}

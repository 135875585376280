import { config } from 'cms-modules';

const { Config, Slice, Section, Items, Font } = config;

const editor = Config(
  'Header',
  { id: 'Header' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Navigation Links',
      {},
      Items('Header Links', 'menu.links', {
        addButtonText: 'Add Header Link',
        saveButtonText: 'Save Header Link',
        max: 6,
      }),
    ),
  ),
  Slice(
    'Styling',
    { id: 'Styling' },
    Section('Fonts', {}, Font('Link Text', 'headerFont')),
  ),
);

export default editor;

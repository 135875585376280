// @flow
import React from 'react';
import styled from 'styled-components';
import { Loader, Dropdown, Separator, Button, RadioGroup } from 'cms-ui';
import { BrandedHeader } from '.';

import type { Site, CloneMode, CloneModeValue } from '../state/reducers/site';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colours.highlight02};
  border: 1px solid ${({ theme }) => theme.colours.lowlight};
  width: 40%;
  margin: 20px;
  padding: 40px;
`;

const Heading = styled.h1`
  margin-top: 0;
  font-size: ${({ theme }) => theme.fontSizeHeader};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DropdownLabel = styled.span`
  color: ${({ theme }) => theme.colours.highlight01};
  font-size: ${({ theme }) => theme.fontSizeFormLabel};
  font-weight: 600;
  margin-bottom: 6px;
`;

const WhileLoading = styled.div`
  display: flex;
  justify-content: center;
`;

const PaddedText = styled.span`
  margin: 0 ${({ theme }) => theme.layout.doublePadding};
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.layout.doublePadding} 0;
`;

const If = ({ condition, children }) => (condition ? children : null);

const asOption = (site: Site) =>
  site ? { value: site.id, label: site.name } : null;

const haveSites = sites => Object.keys(sites).length > 0;

type Props = {
  loading: boolean,
  cloneableSites: { [id: $PropertyType<Site, 'id'>]: Site },
  selectedForCloning: Site,
  selectSite: Site => void,
  cloneSite: (Site, CloneMode) => void,
  skipClone: () => void,
  logoUrl: string,
  cloneModeOptions: CloneMode[],
  cloneMode: CloneMode,
  setCloneMode: CloneModeValue => void,
};

export default function Clone({
  loading,
  cloneableSites,
  selectedForCloning,
  selectSite,
  cloneSite,
  skipClone,
  logoUrl,
  cloneModeOptions,
  cloneMode,
  setCloneMode,
}: Props) {
  return (
    <div>
      <BrandedHeader logo={logoUrl} title="Step 2 of 2 - Create AVL" />
      <Container>
        <Box>
          <Heading>Create empty Avl</Heading>
          <Button primary onClick={skipClone} textTransform="uppercase">
            Create Empty AVL
          </Button>
        </Box>
        <Separator label="OR" width="45%" />
        <Box>
          <Heading>Clone existing Avl</Heading>
          <If condition={loading}>
            <WhileLoading>
              <Loader visible />
              <PaddedText>AVL list loading</PaddedText>
            </WhileLoading>
          </If>
          <If condition={!loading && haveSites(cloneableSites)}>
            <SettingsContainer>
              <RadioGroup
                options={cloneModeOptions}
                value={cloneMode}
                onChange={x => setCloneMode(x.value)}
              />
              <Separator />
            </SettingsContainer>
            <ListContainer>
              <DropdownLabel>Clone AVL from</DropdownLabel>
              <Dropdown
                className="avlDropdown"
                options={Object.values(cloneableSites).map((site: any) =>
                  asOption(site),
                )}
                input={{
                  value: selectedForCloning,
                  onChange: selectSite,
                }}
              />
            </ListContainer>
          </If>
          <Button
            primary
            disabled={!selectedForCloning}
            textTransform="uppercase"
            onClick={() => cloneSite(selectedForCloning, cloneMode)}
          >
            Clone Avl
          </Button>
        </Box>
      </Container>
    </div>
  );
}

// @flow
import React, { Component } from 'react';

import { actions as routerActions } from '../../actions/router';

import SignInContainer from '../../components/SignIn';
import { isTokenValid } from '../../shared/CustomerLogin/reducer';

export interface Config {
  translations: Object;
}

type Props = {
  config: Config,
  actions: {
    init: () => void,
    signIn: (credentials: Object) => void,
  },
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  dispatch: Function,
  globalStyling: Object,
};

export default class SignIn extends Component<Props, *> {
  componentDidMount = () => {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      dispatch,
      shared,
      actions: { init },
    } = this.props;

    if (isTokenValid(shared)) {
      dispatch(routerActions.navigate('/'));
    } else {
      init();
    }
  };

  render = () => {
    const {
      state,
      config,
      actions,
      preview,
      dispatch,
      history: {
        location: { state: redirect },
      },
      globalStyling,
    } = this.props;
    const fnIfNotPreview = fn => (preview ? () => {} : fn);
    return (
      <SignInContainer
        translations={config.translations}
        login={credentials =>
          fnIfNotPreview(() => actions.signIn({ credentials, redirect }))
        }
        loginFailed={state.loginFailed}
        register={() =>
          fnIfNotPreview(() => dispatch(routerActions.navigate('/register')))
        }
        goToForgotPassword={() =>
          fnIfNotPreview(() =>
            dispatch(routerActions.navigate('/resetpassword')),
          )
        }
        globalStyling={globalStyling}
      />
    );
  };
}

/* eslint-disable import/prefer-default-export */
export const titleOptions = translations =>
  [
    {
      label: translations.formTitleSelect,
      value: '',
    },
    {
      label: translations.formTitleMr,
      value: translations.formTitleMr,
    },
    {
      label: translations.formTitleMrs,
      value: translations.formTitleMrs,
    },
    {
      label: translations.formTitleMiss,
      value: translations.formTitleMiss,
    },
    {
      label: translations.formTitleMs,
      value: translations.formTitleMs,
    },
    {
      label: translations.formTitleLady,
      value: translations.formTitleLady,
    },
    {
      label: translations.formTitleLord,
      value: translations.formTitleLord,
    },
    {
      label: translations.formTitleDr,
      value: translations.formTitleDr,
    },
    {
      label: translations.formTitleProfessor,
      value: translations.formTitleProfessor,
    },
    {
      label: translations.formTitleReverand,
      value: translations.formTitleReverand,
    },
    {
      label: translations.formTitleSir,
      value: translations.formTitleSir,
    },
    {
      label: translations.formTitleDame,
      value: translations.formTitleDame,
    },
  ].filter(({ label }) => label && label.trim());

// @flow
import React from 'react';
import styled from 'styled-components';
import { RadioGroup } from 'cms-ui';

import { type Props } from './FinanceOptions';

const Container = styled.div`
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  padding: 35px 2.5%;
  background-color: #ffffff;
`;

const Header = styled.div`
  color: #757575;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export default function AccordionSettings({
  finance,
  updateFinanceOptions,
}: Props) {
  const options = [
    { label: 'Opened by default', value: 'open' },
    { label: 'Closed by default', value: 'closed' },
  ];
  const getFullValue = () =>
    options.find(o => o.value === finance.repexDefaultState);
  return (
    <Container>
      <Header>Finance Accordion</Header>
      <RadioGroup
        options={options}
        onChange={option =>
          updateFinanceOptions({ ...finance, repexDefaultState: option.value })
        }
        value={getFullValue()}
      />
    </Container>
  );
}

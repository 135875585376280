// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Power({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 27 26" width="1em" height="1em">
        <path
          d="M13.365 10.534h6.16L9.372 24.5l2.808-9.617H6.525L16.249.5z"
          fill="#7E8083"
          fillRule="nonzero"
        />
      </svg>
    </IconWrapper>
  );
}

Power.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

export { default as component } from './Repex';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Repex';
export const id = 'Repex';
export const availableToAdministration = false;

export const description = 'Repex Module';
// export const icon = Icon;

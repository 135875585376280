import { takeEvery, put, select, call } from 'redux-saga/effects';
import { constants, actions } from './reducer';
import {
  inventorySearchServiceUrl,
  getAVLConfig,
} from '../../shared/selectors/settings';
import { get } from '../../helpers/http';

function* getFeaturedVehicle(featuredVehicleId) {
  const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
  const { make, market } = yield select(getAVLConfig);
  const url = `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${
    featuredVehicleId.payload
  }`;

  try {
    const response = yield call(get, { url });
    yield put(actions.getFeaturedVehicleSuccess(response.data));
  } catch (error) {
    console.log(error);
  }
}

export default function* featuredVehicleSaga() {
  yield takeEvery(constants.getFeaturedVehicle, getFeaturedVehicle);
}

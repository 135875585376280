// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import querystring from 'querystring';
import SearchResultsComponent from '../../components/SearchResults/SearchResults';
import MobileSearchPanel from '../../components/MobileSearchPanel';
import { actions as routerActions } from '../../actions/router';
import media, { screenSizeOptions } from '../../theme';
import SubNav from '../../containers/SubNav';
import { actions as compareActions } from '../../shared/compare';
import { getVehicles as compareVehicles } from '../../shared/compare/reducer';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { actions as shortlistActions } from '../../shared/shortlist';
import { getVehicles as shortlistVehicles } from '../../shared/shortlist/reducer';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import {
  previewShortListedVehicles,
  previewCompareVehicles,
} from './preview.config';
import { sortOptions } from '../../shared/sortOptions/settings';
import type { Font, Theme, Option } from '../../types';
import ContentWrapper from '../../components/ContentWrapper';
import { distanceOptions } from '../../components/SearchFilters/search-filters-helper';
import { vanityUrlFormatter } from '../../helpers/vehicle';

const FIRST_PAGE = 1;

export interface Config {
  translations: Object;
  subNavBGColour?: string;
  subNavFont?: Font;
  subNavBorderColour?: string;
  subNavChevronColour?: string;
  headingFont?: Font;
  subHeadingFont?: Font;
  headingAlignment?: string;
  buttonFont?: Font;
  buttonBGColour?: Option;
  stylingConfig: Object;
  visibility: Object;
  compareIconListedColour: Object;
  shortlistIconListedColour: Object;
}

type Props = {
  config: Config,
  actions: {
    init: Function,
    getInventory: Function,
    clearLoaded: () => void,
    cancelLoading: () => void,
  },
  pathByModule: string => any,
  state: Object,
  preview: boolean,
  shared: Object,
  dispatch: Function,
  history: Object,
  marketInfo: Object,
  globalStyling: Object,
  featureFlags: Object,
};
type State = {
  filtersExpanded: boolean,
  resultPageSize: string,
  resultPageNumber: string,
  leasingExampleExpanded: boolean,
  selectedView: 'GRID' | 'LIST',
};

const MobileOnly = styled.div`
  display: flex;
  ${media.min.large`
    display: none;
  `};
`;

const DesktopOnly = styled.div`
  display: none;
  ${media.min.large`
    display: block;
  `};
`;

const themeFromProps = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
}: Font): Theme => ({
  fontFamily: typeface ? typeface.label : '',
  fontSize: fontSize ? `${fontSize}px` : '',
  characterSpacing: kerning ? `${kerning}px` : '',
  textTransform: transform || '',
  colour: colour ? colour.value : '',
});

export default class SearchResults extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedView =
      window.innerWidth < screenSizeOptions.large ? 'GRID' : 'LIST';
    this.state = {
      filtersExpanded: false,
      leasingExampleExpanded: false,
      selectedView,
      searchUrl: '',
    };
  }

  componentDidMount() {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const { actions, dispatch, history, shared, router } = this.props;

    const queryParams = querystring.parse(history.location.search.substring(1));

    this.retailerId = queryParams.retailerId;

    if (queryParams.models) {
      const models =
        typeof queryParams.models === 'string'
          ? [queryParams.models]
          : [...queryParams.models];

      dispatch(
        searchFilterActions.selectMultipleCheckboxes({
          section: 'models',
          values: models,
        }),
      );
    }

    dispatch(searchFilterActions.updateQuery());
    if (
      Object.keys(shared.sessionPreferences.location).length &&
      this.retailerId
    ) {
      dispatch(searchFilterActions.changeMaxDistance('all'));
    }
    actions.init();
    dispatch(shortlistActions.getVehicles());

    if (this.retailerId) {
      actions.getDealer(this.retailerId);
    }

    window.addEventListener('resize', this.onViewportChange);

    const vdpMatcher = /.*\/vdp\/.*/i;
    const previousRoute = router.navigationHistory[1];
    const comeFromVdp = vdpMatcher.test(previousRoute);

    this.forceUpdate = !comeFromVdp || (comeFromVdp && this.retailerId);

    if (shared.sessionPreferences.infiniteScroll) {
      this.onShowAllResultsClick();
    } else {
      actions.cancelLoading(this.forceUpdate);
      window.scroll(0, 0);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.forceUpdate) {
      this.props.actions.clearLoaded();
      this.updateInventory(
        FIRST_PAGE,
        this.props.shared.sessionPreferences.searchSort,
        this.props.preview,
        true,
      );
      this.forceUpdate = false;
    } else if (
      prevProps.shared.searchFilter.searchUrl !==
        this.props.shared.searchFilter.searchUrl ||
      this.state.searchUrl !== this.props.shared.searchFilter.searchUrl ||
      (!this.props.state.loading && !this.props.state.searchResults[FIRST_PAGE])
    ) {
      this.props.actions.clearLoaded();

      this.setState({
        searchUrl: this.props.shared.searchFilter.searchUrl,
      });

      this.updateInventory(
        FIRST_PAGE,
        this.props.shared.sessionPreferences.searchSort,
        this.props.preview,
      );
    }
    const { modelSearchUrl } = this.props.shared.searchFilter;
    if (
      prevProps.shared.searchFilter.modelSearchUrl !== modelSearchUrl ||
      this.props.shared.sessionPreferences.location !==
        prevProps.shared.sessionPreferences.location
    ) {
      this.updateInventory(
        FIRST_PAGE,
        this.props.shared.sessionPreferences.searchSort,
        this.props.preview,
        true,
      );
    }
  }

  componentWillUnmount() {
    const { actions, dispatch, history } = this.props;
    const vdpMatcher = /.*\/vdp\/.*/i;
    const goingToVdp = vdpMatcher.test(history.location.pathname);

    if (!goingToVdp) {
      dispatch(
        sessionActions.updateSessionPreferences('infiniteScroll', false),
      );
    }

    actions.cancelLoading(!goingToVdp);
  }

  getMaxDistanceUrl() {
    const { config } = this.props;

    const options = distanceOptions(config.translations);
    const defaultOption =
      (config.distanceFilterInitialSelectedIndex &&
        `MaxDistance=${
          options[config.distanceFilterInitialSelectedIndex].value
        }&`) ||
      '';
    return defaultOption;
  }

  onViewportChange = () => {
    if (window.innerWidth < screenSizeOptions.large) {
      this.setState(() => ({ selectedView: 'GRID' }));
    }
  };

  onFilterMenuClick = () =>
    this.setState(prevState => ({
      filtersExpanded: !prevState.filtersExpanded,
    }));

  onShowAllResultsClick = () => {
    const { actions, shared, marketInfo, state, dispatch } = this.props;
    const { searchUrl } = shared.searchFilter;
    const {
      checkboxUrl = '',
      rangeUrl = '',
      locationUrl = '',
      maxDistanceUrl = this.getMaxDistanceUrl(),
    } = searchUrl;
    const filtersUrl = `${checkboxUrl}${rangeUrl}${locationUrl}${maxDistanceUrl}`;
    const sortField = shared.sessionPreferences.searchSort;
    const sortingParams = `&SortField=${sortField.field}&SortDirection=${
      sortField.order
    }`;
    const retailerParam = !this.retailerId
      ? ''
      : `&RetailerId=${this.retailerId}`;

    dispatch(sessionActions.updateSessionPreferences('infiniteScroll', true));

    actions.showAll({
      ...marketInfo,
      filters: ''.concat(filtersUrl, sortingParams, retailerParam),
      pageNumber: state.currentPage,
      sortField: shared.sessionPreferences.searchSort,
    });
  };

  onPageNumberChange = (value: Number, infiniteScroll: boolean) => {
    const { shared } = this.props;
    this.updateInventory(value, shared.sessionPreferences.searchSort);

    if (!infiniteScroll) {
      window.scroll(0, 0);
    }
  };

  onSortChange = (value: string) => {
    this.props.dispatch(
      sessionActions.updateSessionPreferences('searchSort', sortOptions[value]),
    );
    this.props.actions.clearLoaded();
    this.updateInventory(FIRST_PAGE, sortOptions[value], this.props.preview);
  };

  onViewChange = (selectedView: 'GRID' | 'LIST') =>
    this.setState(() => ({ selectedView }));

  toggleLeasingExample = () => {
    this.setState(prevState => ({
      ...prevState,
      leasingExampleExpanded: !prevState.leasingExampleExpanded,
    }));
  };

  updateInventory = (
    pageNumber: Number,
    sortField: Object,
    preview: boolean,
    forceUpdate?: boolean,
  ) => {
    const { actions, shared, marketInfo } = this.props;
    const searchUrl = preview ? {} : shared.searchFilter.searchUrl;

    const {
      checkboxUrl = '',
      rangeUrl = '',
      locationUrl = '',
      maxDistanceUrl = this.getMaxDistanceUrl(),
    } = searchUrl;
    const filtersUrl = `${checkboxUrl}${rangeUrl}${locationUrl}${maxDistanceUrl}`;

    const sortingParams = `&SortField=${sortField.field}&SortDirection=${
      sortField.order
    }`;
    const retailerParam =
      preview || !this.retailerId ? '' : `&RetailerId=${this.retailerId}`;

    actions.getInventory({
      ...marketInfo,
      filters: ''.concat(filtersUrl, sortingParams, retailerParam),
      forceUpdate,
      preview,
      pageNumber,
    });
  };

  resetFilters = () => this.props.dispatch(searchFilterActions.resetFilters());

  retailerId = null;

  forceUpdate = false;

  render() {
    const {
      state,
      dispatch,
      pathByModule,
      preview,
      config,
      shared,
      history,
      marketInfo,
      globalStyling,
      featureFlags,
    } = this.props;

    const {
      filtersExpanded,
      selectedView,
      leasingExampleExpanded,
    } = this.state;
    const representativeExample = preview
      ? {}
      : shared.financeCalculator.representativeExample;
    const onMoreInfoClick = (name, vehicle) => {
      const vehicleMetaInfo = vanityUrlFormatter(
        config,
        vehicle,
        shared.sessionPreferences.language,
      );
      dispatch(routerActions.navigate(`/vdp/${vehicle.id}-${vehicleMetaInfo}`));
    };
    const goToModule = name =>
      dispatch(routerActions.navigate(pathByModule(name)));

    const sharedShortListedVehicles = preview
      ? previewShortListedVehicles
      : shortlistVehicles(shared);

    const retailer = preview
      ? null
      : this.retailerId && {
          id: this.retailerId,
          ...state.dealer,
        };

    const sharedCompareVehicles = preview
      ? previewCompareVehicles
      : compareVehicles(shared);

    const isListed = (id: string, vehicles: Vehicle[]) =>
      vehicles.find(vehicle => vehicle.id === (id || {}));

    const getShortListedIconColour = (id: string) =>
      isListed(id, sharedShortListedVehicles)
        ? config.shortlistIconListedColour &&
          config.shortlistIconListedColour.value
        : '#ACACAC';

    const getCompareIconColour = (id: string) =>
      isListed(id, sharedCompareVehicles)
        ? config.compareIconListedColour && config.compareIconListedColour.value
        : '#ACACAC';

    return (
      <div>
        {preview ? null : (
          <MobileOnly>
            <MobileSearchPanel
              resetFilters={this.resetFilters}
              goBack={() => goToModule('Search')}
              config={{
                ...marketInfo,
                translations: config.translations,
                stylingConfig: config.stylingConfig,
                filterVisibility: config.visibility.filters,
                distanceFilterInitialSelectedIndex:
                  config.distanceFilterInitialSelectedIndex,
                dropdown: config.dropdown,
                locationIconUrl: config.locationIconUrl,
              }}
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              featureFlags={featureFlags}
            />
          </MobileOnly>
        )}
        <DesktopOnly>
          <SubNav
            pathByModule={pathByModule}
            title="Vehicle Details"
            preview={preview}
            onBackButtonClick={history.goBack}
            subNavBGColour={config.subNavBGColour}
            subNavBorderColour={config.subNavBorderColour}
            subNavFontTheme={
              config.subNavFont && themeFromProps(config.subNavFont)
            }
            subNavChevronColour={config.subNavChevronColour}
            translations={config.translations}
            subNavBarItemSelectedBackgroundColour={
              config.subNavBarItemSelectedBackgroundColour
            }
            subNavBarItemSelectedFontColour={
              config.subNavBarItemSelectedFontColour
            }
            contentWidth={globalStyling.contentWidth}
            showNavBarItemBorder={config.showNavBarItemBorder}
            subNavHeight={config.subNavHeight}
            alignMenuItems={config.alignMenuItems}
            navBarBottomBorder={config.navBarBottomBorder}
            globalStyling={globalStyling}
          />
        </DesktopOnly>
        <ContentWrapper contentWidth={globalStyling.contentWidth}>
          <SearchResultsComponent
            locationIconUrl={config.locationIconUrl}
            filtersStyling={config.stylingConfig}
            marketInfo={marketInfo}
            translations={config.translations}
            filtersExpanded={filtersExpanded}
            onFilterMenuClick={this.onFilterMenuClick}
            results={state.searchResults}
            currentPage={state.currentPage}
            totalResults={state.totalResults}
            onMoreInfoClick={onMoreInfoClick}
            onShowAllResultsClick={this.onShowAllResultsClick}
            onPageNumberChange={value =>
              this.onPageNumberChange(value, state.infiniteScroll)
            }
            sortOptions={sortOptions}
            sortValue={
              shared.sessionPreferences.searchSort &&
              shared.sessionPreferences.searchSort.value
            }
            onSortChange={this.onSortChange}
            showSortByDistance={
              shared.searchFilter.location &&
              shared.searchFilter.location.lat &&
              shared.searchFilter.location.long
            }
            showSortByAge={
              config.visibility.sorting &&
              config.visibility.sorting.showSortByAge
            }
            showSortByMileage={
              config.visibility.sorting &&
              config.visibility.sorting.showSortByMileage
            }
            fontFamily={config.sortMenuBarFontFamily}
            selectedView={selectedView}
            onViewChange={this.onViewChange}
            toggleLeasingExample={this.toggleLeasingExample}
            leasingExampleExpanded={leasingExampleExpanded}
            preview={preview}
            compareVehicle={vehicle =>
              dispatch(compareActions.toggleVehicle(vehicle))
            }
            shortlistVehicle={vehicle =>
              dispatch(shortlistActions.toggleVehicle(vehicle))
            }
            getShortListedIconColour={getShortListedIconColour}
            representativeExample={representativeExample}
            getCompareIconColour={getCompareIconColour}
            resetFilters={this.resetFilters}
            retailer={retailer}
            globalStyling={globalStyling}
            tilesVisibility={config.visibility.tiles}
            filterVisibility={config.visibility.filters}
            headingFont={config.headingFont}
            subHeadingFont={config.subHeadingFont}
            headerItemFont={config.headerItemFont}
            capitalizeSubHeaderCountryName={
              config.capitalizeSubHeaderCountryName
            }
            headingAlignment={config.headingAlignment}
            history={history}
            featureFlags={featureFlags}
            vehicleModelTitleFontWeight={config.vehicleModelTitleFontWeight}
            headingBottomLineDisable={config.headingBottomLineDisable}
            subHeadingAlignment={config.subHeadingAlignment}
            showGridTiles={config.showGridTiles}
            badgeStyling={config.badgeStyling}
            financeHeadingFont={config.financeHeadingFont}
            infiniteScroll={state.infiniteScroll}
            loading={state.loading}
            pageSize={state.pageSize}
            distanceFilterInitialSelectedIndex={
              config.distanceFilterInitialSelectedIndex
            }
            dropdownTheme={config.dropdown}
          />
        </ContentWrapper>
      </div>
    );
  }
}

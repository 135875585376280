// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Minus({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 50 10">
        <path d="M13 1h22v4H13z" />
      </svg>
    </IconWrapper>
  );
}

Minus.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import { HeadingTwo } from '../Global';

const Container = styled.div.withConfig({
  displayName: 'TabContainer',
})`
  width: 100%;
`;
const TabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: Center;
  justify-content: center;
  padding: 33px 0;
`;
const Tab = styled.button`
  border: 0;
  background-color: ${props => (props.active ? '#474747' : '#f0f0f0')};
  color: ${props => (props.active ? '#fff' : '#444')};
  font-weight: 600;
  font-size: 13px;
  outline: none;
  margin-right: 1px;
  min-width: 100px;
  text-transform: uppercase;
  padding: 10px 15px;
  cursor: pointer;
`;
const TabComponent = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
`;

export default class Tabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: props.tabs[0],
    };
  }

  onTabClick = (activeTab: Object) => this.setState(() => ({ activeTab }));

  render = () => {
    const { tabs } = this.props;
    const { activeTab } = this.state;
    return (
      <Container>
        <TabContainer>
          {tabs.map(tab => (
            <Tab
              key={tab.title}
              onClick={() => this.onTabClick(tab)}
              active={tab.title === activeTab.title}
            >
              <HeadingTwo
                styleOverride={() => `
                  margin: 0;
                  font-size: 13px;
                  background-color: ${
                    tab.title === activeTab.title ? '#474747' : '#f0f0f0'
                  };
                  color: ${tab.title === activeTab.title ? '#fff' : '#444'};
                `}
              >
                {tab.title}
              </HeadingTwo>
            </Tab>
          ))}
        </TabContainer>
        <div>
          {tabs.map(tab => (
            <TabComponent
              key={tab.title}
              active={tab.title === activeTab.title}
            >
              {activeTab.component}
            </TabComponent>
          ))}
        </div>
      </Container>
    );
  };
}

import React from 'react';

const RightAlignDisabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#757575" d="M1.5 1.5h15v15h-15z" />
      <path fill="#757575" d="M15 1h2v16h-2zM3 6h11v2H3zM8 10h6v2H8z" />
    </g>
  </svg>
);

export default RightAlignDisabled;

// @flow
import React from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';

import { HeadingOne, Paragraph } from '../Global';

import LabelledCheckBox from '../LabelledCheckBox';

const Url = styled.a`
  color: ${({ theme }) => theme.color};
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

type Props = {
  translations: Object,
  onRadioSelect: Object => void,
  onCheckBoxClick: (formKey: string, value: string | boolean) => void,
  onColour: string,
  labelFontSize?: Number,
  checkboxDimension?: Number,
  linkColour?: Object,
  globalStyling: Object,
  marketingChecked: boolean,
  marketingLink: string,
  marketingKey: string,
  marketing: string,
  marketingError: string,
  dataConsentFont: string,
  checkBoxError: string,
  enquiryFormStyles: object,
  distributionListConsentChecked: boolean,
  distributionListConsentKey: string,
};

const JLRMarketingAndDistributionConsent = ({
  translations,
  onCheckBoxClick,
  marketingChecked,
  marketingKey,
  distributionListConsentChecked,
  distributionListConsentKey,
  onColour,
  labelFontSize,
  checkboxDimension,
  linkColour,
  enquiryFormStyles = {},
  globalStyling,
}: Props) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <HeadingOne
        styleOverride={() => `
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;`}
      >
        {translations.marketingHeaderText}
      </HeadingOne>
      <Paragraph
        styleOverride={() => `
        font-size: 14px;
        line-height: 17px;
        margin-top: 20px;`}
      >
        <Linkify
          component={({ children, ...props }) => (
            <Url {...props} theme={{ color: linkColour && linkColour.value }}>
              {children}
            </Url>
          )}
          properties={{ target: '_blank' }}
        >
          {translations.marketingConsentContent}
        </Linkify>
      </Paragraph>

      <CheckboxContainer>
        <LabelledCheckBox
          onClick={() => onCheckBoxClick(marketingKey, !marketingChecked)}
          checked={marketingChecked}
          label={translations.marketingConsentLabel}
          onColour={onColour}
          colour={
            globalStyling.colours.paragraphFontColour &&
            globalStyling.colours.paragraphFontColour.value
          }
          labelFontSize={enquiryFormStyles.labelFontSize || labelFontSize}
          checkboxDimension={checkboxDimension}
          globalStyling={globalStyling}
        />
        <LabelledCheckBox
          onClick={() =>
            onCheckBoxClick(
              distributionListConsentKey,
              !distributionListConsentChecked,
            )
          }
          checked={distributionListConsentChecked}
          label={translations.distrubutionListConsentLabel}
          onColour={onColour}
          colour={
            globalStyling.colours.paragraphFontColour &&
            globalStyling.colours.paragraphFontColour.value
          }
          labelFontSize={enquiryFormStyles.labelFontSize || labelFontSize}
          checkboxDimension={checkboxDimension}
          globalStyling={globalStyling}
        />
      </CheckboxContainer>
      <Paragraph
        styleOverride={() => `
        font-size: 14px;
        line-height: 17px;
        margin-top: 20px;`}
      >
        <Linkify
          component={({ children, ...props }) => (
            <Url {...props} theme={{ color: linkColour && linkColour.value }}>
              {children}
            </Url>
          )}
          properties={{ target: '_blank' }}
        >
          {translations.dataUsageContent}
        </Linkify>
      </Paragraph>
    </div>
  );
};
export default JLRMarketingAndDistributionConsent;

// @flow
import React from 'react';
import { connect } from 'react-redux';
import SocialShareOptions from '../components/SocialShareOptions/SocialShareOptions';

import { actions } from '../state/actions/socialShare';

const mapStateToProps = ({ socialShare, site }) => ({
  socialShare,
  siteId: site.currentSiteId,
});

const mapDispatchToProps = dispatch => ({
  updateSocialShareOptions: socialShare =>
    dispatch(actions.updateSocialShareOptions(socialShare)),
  saveSocialShareOptions: siteId =>
    dispatch(actions.saveSocialShareOptions(siteId)),
});

function SocialShareOptionsContainer(props) {
  return <SocialShareOptions {...props} />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SocialShareOptionsContainer);

export default {
  eyeWhiteImg:
    'https://res.cloudinary.com/motortrak/image/upload/v1554901932/cms/global/icons/eye-white.svg',
  imagesIcon:
    'https://res.cloudinary.com/motortrak/image/upload/v1574856827/cms/global/icons/Images.svg',
  mapPinImg:
    'https://res.cloudinary.com/motortrak/image/upload/v1566820052/cms/global/icons/pin.svg',
  approvedLogoImg:
    'https://res.cloudinary.com/motortrak/image/upload/v1575019797/cms/global/icons/Model_Year_2x.png',
  compareIconImgOff:
    'https://res.cloudinary.com/motortrak/image/upload/v1560954861/cms/global/icons/compare-empty.svg',
  shortlistIconImgOff:
    'https://res.cloudinary.com/motortrak/image/upload/v1560954861/cms/global/icons/shortlist-empty.svg',
  compareIconImgOn:
    'https://res.cloudinary.com/motortrak/image/upload/v1560954861/cms/global/icons/compare-empty.svg',
  shortlistIconImgOn:
    'https://res.cloudinary.com/motortrak/image/upload/v1560954861/cms/global/icons/shortlist-empty.svg',
  registration:
    'https://res.cloudinary.com/motortrak/image/upload/v1575019806/cms/global/icons/Reg_Date_2x.png',
  odometer:
    'https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/global/content/Mileage_2x.png',
  modelyear:
    'https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/global/content/Model_Year_2x.png',
  exterior:
    'https://res.cloudinary.com/motortrak/image/upload/v1575019744/cms/global/icons/Exterior_2x.png',
  interior:
    'https://res.cloudinary.com/motortrak/image/upload/v1575019775/cms/global/icons/Interior_2x.png',
  transmission:
    'https://res.cloudinary.com/motortrak/image/upload/v1575019815/cms/global/icons/Transmission.svg',
};

import { constants } from '.';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case constants.GET_DATA_SUCCESS:
      return {
        ...state,
        [action.dataKey]: action.payload,
      };
    case constants.CLEAR_DATA_PROVIDER_STATE:
      return {
        ...state,
        [action.dataKey]: null,
      };
    default:
      return state;
  }
}

// @flow
import type { PageTemplate } from '../reducers/pageTemplates';
import type { Error } from '../types/error';

const LoadPageTemplates: 'LOAD PAGE TEMPLATES' = 'LOAD PAGE TEMPLATES';
export type LoadPageTemplatesAction = { type: typeof LoadPageTemplates };
const loadPageTemplates = (): LoadPageTemplatesAction => ({
  type: LoadPageTemplates,
});

const LoadPageTemplatesFailure: 'LOAD PAGE TEMPLATES FAILURE' =
  'LOAD PAGE TEMPLATES FAILURE';
export type LoadPageTemplatesFailureAction = {
  type: typeof LoadPageTemplatesFailure,
  payload: Error,
};
const loadPageTemplatesFailure = (
  error: Error,
): LoadPageTemplatesFailureAction => ({
  type: LoadPageTemplatesFailure,
  payload: error,
});

const LoadPageTemplatesSuccess: 'LOAD PAGE TEMPLATES SUCCESS' =
  'LOAD PAGE TEMPLATES SUCCESS';
export type LoadPageTemplatesSuccessAction = {
  type: typeof LoadPageTemplatesSuccess,
  payload: PageTemplate[],
};
const loadPageTemplatesSuccess = (
  pageTemplates: PageTemplate[],
): LoadPageTemplatesSuccessAction => ({
  type: LoadPageTemplatesSuccess,
  payload: pageTemplates,
});

export const actions = {
  loadPageTemplates,
  loadPageTemplatesFailure,
  loadPageTemplatesSuccess,
};

export const constants = {
  LoadPageTemplates,
  LoadPageTemplatesFailure,
  LoadPageTemplatesSuccess,
};

export type Action =
  | LoadPageTemplatesAction
  | LoadPageTemplatesFailureAction
  | LoadPageTemplatesSuccessAction;

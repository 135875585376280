// @flow
import React from 'react';
import styled from 'styled-components';
import { pods } from 'cms-modules';
import PreviewVideo from '../PreviewVideo';
import PreviewHeroCarousel from '../PreviewHeroCarousel';
import useActions from './useActions';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 25%;
  min-height: 250px;
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
`;

const Button = styled.div`
  cursor: pointer;
  background: #0065db;
  color: #ffffff;
  padding: 2px;
  border: 1px solid #ffffff;
`;

const DropZone = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 800;
  background: #eeeeee;
`;

const unselectableStates = ['nodrop', 'noclick'];

const DropArea = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dashed #0065db;
  background: ${({ state }) =>
    unselectableStates.includes(state) ? '#999999' : '#ffffff'};
  ${({ state }) =>
    unselectableStates.includes(state) ? 'cursor: not-allowed;' : ''}
  ${({ state }) =>
    unselectableStates.includes(state) ? 'color: #FFFFFF;' : ''}
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-style: italic;
`;

type Config = {
  actions: any,
  podState: string,
};

type Props = {
  dispatch: () => void,
  config: Config,
};

const swappablePods = {
  Video: PreviewVideo,
  HeroCarousel: PreviewHeroCarousel,
};

const dropZoneTexts = {
  noclick: 'Unavailable',
  nodrop: 'Cant position here',
  selectable: 'click or drag a pod to place here',
  selected: 'select a pod',
  droppable: 'drag a pod to place here',
  placeable: 'click to place pod here',
};

const dropZoneText = state => dropZoneTexts[state];

const ActionZone = ({ actions, podState }: Config) => (
  <DropZone
    onDrop={actions.drop}
    onClick={actions.click}
    onDragOver={event => event.preventDefault()}
  >
    <DropArea state={podState}>
      <Text>{dropZoneText(podState)}</Text>
    </DropArea>
  </DropZone>
);

export default (props: Props) => {
  const { id, instanceId, dispatch, config, globalStyling } = props;
  const { actions, podState, contentState } = useActions(id, instanceId);
  const Pod = swappablePods[id] || pods.default[id];

  return (
    <Wrapper>
      <ButtonContainer>
        {actions.edit && <Button onClick={actions.edit}>edit</Button>}
        {actions.remove && <Button onClick={actions.remove}>delete</Button>}
      </ButtonContainer>
      {contentState === 'empty' ? (
        <ActionZone actions={actions} podState={podState} />
      ) : (
        <Pod
          config={config}
          dispatch={dispatch}
          globalStyling={globalStyling}
        />
      )}
    </Wrapper>
  );
};

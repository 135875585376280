import { normalisers } from 'cms-modules';
import uuid from 'uuid/v1';
import { BASE_URL } from '../../constants/apis';
import { constants as pageConstants } from '../actions/page';
import { constants as siteBuilderConstants } from '../actions/siteBuilder';
import { updateConfigValue, updateValue, keyLens } from './configLenses';
import editorPodConfig from '../../pods';
import cookieDefault from '../../pods/cookiePolicy';

const { normalisePage, normalisePages, normaliseGlobal } = normalisers;

const savedStatuses = {
  saved: 'saved',
  unsaved: 'unsaved',
  saving: 'saving',
};

const defaultSettings = {
  postsServicePath: 'posts/',
  baseUrl: BASE_URL,
  inventorySearchServicePath: 'inventory-search',
  locale: 'en_gb',
};

const now = () => new Date().getTime();

const initialState = {
  settings: defaultSettings,
  site: [],
  pages: {},
  bands: {},
  pods: {},
  // TODO get rid of the nested config !!!
  config: {
    global: {
      inventory: {
        make: 'lamborghini',
        market: 'Non-North America',
        searchCountryCode: 'en_GB',
      },
      featureFlags: {},
      globalStyling: { uiElements: {} },
    },
  },
  saveStatus: savedStatuses.saved,
  lastSaveTime: now(),
};

const createSite = lastUpdated => [
  {
    id: 'Band',
    columns: 1,
    instanceId: uuid(),
    config: {
      pods: [
        {
          id: 'CookiePolicy',
          instanceId: uuid(),
          config: {
            ...cookieDefault.defaultConfig,
          },
          lastUpdated,
        },
      ],
    },
    lastUpdated,
  },
  {
    id: 'Band',
    columns: 1,
    instanceId: uuid(),
    config: {
      pods: [
        {
          id: 'Header',
          instanceId: uuid(),
          config: {
            template: 'One',
          },
          lastUpdated,
        },
      ],
      lastUpdated,
    },
  },

  {
    id: '$$BODY$$',
    instanceId: uuid(),
    config: {},
    lastUpdated,
  },
  {
    id: 'Band',
    instanceId: uuid(),
    columns: 1,
    config: {
      pods: [
        {
          id: 'Footer',
          instanceId: uuid(),
          config: {
            translations: {},
            template: 'One',
            social: {
              links: [],
            },
          },
          lastUpdated,
        },
      ],
    },
    lastUpdated,
  },
];

export default (state = initialState, action) => {
  switch (action.type) {
    case siteBuilderConstants.LoadGlobalModulesSuccess: {
      const {
        payload: { modules },
      } = action;
      const updated = now();
      const globalModules = (modules.length
        ? action.payload.modules
        : createSite(updated)
      ).map(gm => ({
        ...gm,
        lastUpdated: gm.lastUpdated || updated,
        config: {
          ...gm.config,
          pods: (gm.config.pods || []).map(pod => ({
            ...pod,
            lastUpdated: pod.lastUpdated || updated,
          })),
        },
      }));

      const {
        entities: { bands: siteBands, pods: sitePods },
        result: site,
      } = normaliseGlobal(globalModules);
      return {
        ...state,
        site,
        bands: {
          ...state.bands,
          ...siteBands,
        },
        pods: {
          ...state.pods,
          ...sitePods,
        },
        lastSaveTime: updated,
      };
    }
    case pageConstants.LoadPagesSuccess: {
      const updated = now();
      const pagePayload = action.payload.map(p => ({
        ...p,
        lastUpdated: p.lastUpdated || updated,
        modules: (p.modules || []).map(m => ({
          ...m,
          lastUpdated: updated,
          config: {
            ...m.config,
            pods: (m.config.pods || []).map(pod => ({
              ...pod,
              lastUpdated: pod.lastUpdated || updated,
            })),
          },
        })),
      }));

      const {
        entities: { pages, bands, pods },
      } = normalisePages(pagePayload);

      return {
        ...state,
        pages: {
          ...pages,
        },
        bands: {
          ...state.bands,
          ...bands,
        },
        pods: {
          ...state.pods,
          ...pods,
        },
        lastSaveTime: updated,
      };
    }
    case pageConstants.UpdatePage: {
      const updatedPage = action.payload;

      return {
        ...state,
        pages: Object.entries(state.pages).reduce(
          (acc, curr) => ({
            ...acc,
            [curr[0]]:
              curr[1].id === updatedPage.id
                ? {
                    ...updatedPage,
                    lastUpdated: now(),
                  }
                : curr[1],
          }),
          {},
        ),
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.CreatePageSuccess:
    case pageConstants.SavePageSuccess: {
      const page = normalisePage(action.payload);
      return {
        ...state,
        pages: {
          ...state.pages,
          [page.relativePath]: {
            ...page,
            lastUpdated: now(),
          },
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.DeletePage: {
      const { pageId } = action.payload;

      return {
        ...state,
        pages: Object.entries(state.pages).reduce(
          (acc, curr) => ({
            ...acc,
            [curr[0]]:
              curr[1].id === pageId
                ? {
                    ...curr[1],
                    removed: true,
                    lastUpdated: now(),
                  }
                : curr[1],
          }),
          {},
        ),
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.SetPodField: {
      const {
        payload: { podId, key, value },
      } = action;
      const podToUpdate = state.pods[podId];
      const updatedPod = {
        ...updateConfigValue(podToUpdate, key, value),
        lastUpdated: now(),
      };
      return {
        ...state,
        pods: {
          ...state.pods,
          [podId]: updatedPod,
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.SetPodListField: {
      const {
        payload: { podId, listKey, listIndex, fieldKey, value },
      } = action;
      const podToUpdate = state.pods[podId];
      const items = podToUpdate.config[listKey];
      const updatedPod = {
        ...podToUpdate,
        config: {
          ...podToUpdate.config,
          [listKey]: items.map((item, index) =>
            index === listIndex
              ? updateValue(keyLens(fieldKey), value, item)
              : item,
          ),
        },
        lastUpdated: now(),
      };
      return {
        ...state,
        pods: {
          ...state.pods,
          [podId]: updatedPod,
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.AddPodSection: {
      const {
        payload: { podId, section },
      } = action;
      const podToUpdate = state.pods[podId];
      const items = podToUpdate.config[section];
      const template = editorPodConfig
        .find(p => p.id === podToUpdate.id)
        .template()[section];
      const updatedPod = {
        ...podToUpdate,
        config: {
          ...podToUpdate.config,
          [section]: [...items, template],
        },
        lastUpdated: now(),
      };
      return {
        ...state,
        pods: {
          ...state.pods,
          [podId]: updatedPod,
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.DeletePodSection: {
      const {
        payload: { podId, section, sectionId },
      } = action;
      const podToUpdate = state.pods[podId];
      const items = podToUpdate.config[section];
      const updatedPod = {
        ...podToUpdate,
        config: {
          ...podToUpdate.config,
          [section]: items.filter(item => item.id !== sectionId),
        },
        lastUpdated: now(),
        saveStatus: savedStatuses.unsaved,
      };
      return {
        ...state,
        pods: {
          ...state.pods,
          [podId]: updatedPod,
        },
      };
    }
    case pageConstants.RemoveBandFromPage: {
      const { bandInstanceId } = action.payload;
      const page = Object.values(state.pages).find(p =>
        p.modules.includes(bandInstanceId),
      );
      const updated = now();
      return {
        ...state,
        pages: {
          ...state.pages,
          [page.relativePath]: {
            ...page,
            modules: page.modules.filter(m => m !== bandInstanceId),
            lastUpdated: updated,
          },
        },
        bands: {
          ...state.bands,
          [bandInstanceId]: {
            ...state.bands[bandInstanceId],
            removed: true,
            lastUpdated: updated,
          },
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.ReplacePodOnBand: {
      const { currentPodId, pod } = action.payload;
      const band = Object.values(state.bands).find(b =>
        b.config.pods.includes(currentPodId),
      );
      const updated = now();
      const updatedBand = {
        ...band,
        config: {
          ...band.config,
          pods: band.config.pods.map(instanceId =>
            instanceId === currentPodId ? pod.instanceId : instanceId,
          ),
        },
        lastUpdated: updated,
        saveStatus: savedStatuses.unsaved,
      };

      return {
        ...state,
        bands: {
          ...state.bands,
          [updatedBand.instanceId]: updatedBand,
        },
        pods: {
          ...state.pods,
          [pod.instanceId]: {
            ...pod,
            lastUpdated: updated,
          },
          [currentPodId]: {
            ...state.pods[currentPodId],
            lastUpdated: updated,
            removed: true,
          },
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.UpdateBandOrder: {
      const { pagePath, bandId, direction } = action.payload;

      const page = state.pages[pagePath];
      const currentIndex = page.modules.findIndex(
        instanceId => instanceId === bandId,
      );
      const newIndex =
        direction === 'UP'
          ? Math.max(currentIndex - 1, 0)
          : Math.max(currentIndex + 1, page.modules.length - 1);

      const reordered = [...page.modules];
      [reordered[currentIndex], reordered[newIndex]] = [
        reordered[newIndex],
        reordered[currentIndex],
      ];

      return {
        ...state,
        pages: {
          ...state.pages,
          [pagePath]: {
            ...page,
            modules: reordered,
            lastUpdated: now(),
          },
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.AddBandToPage: {
      const { pageId, band } = action.payload;
      const page = Object.values(state.pages).find(p => p.id === pageId);
      const updated = now();

      return {
        ...state,
        pages: {
          ...state.pages,
          [page.relativePath]: {
            ...page,
            modules: [...page.modules, band.instanceId], // TODO use normaliser
            lastUpdated: updated,
          },
        },
        bands: {
          ...state.bands,
          [band.instanceId]: {
            ...band,
            config: {
              ...band.config,
              pods: band.config.pods.map(p => p.instanceId), // TODO use normaliser
            },
            lastUpdated: updated,
          },
        },
        pods: {
          ...state.pods,
          ...band.config.pods.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.instanceId]: {
                ...curr,
                lastUpdated: updated,
              },
            }),
            {},
          ),
        },
        saveStatus: savedStatuses.unsaved,
      };
    }
    case pageConstants.SaveChangesFailure: {
      return {
        ...state,
      };
    }
    case pageConstants.SaveChangesSuccess: {
      return {
        ...state,
        saveStatus: savedStatuses.saved,
        lastSaveTime: now(),
      };
    }
    default: {
      return state;
    }
  }
};

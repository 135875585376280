// @flow

import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';
import type { Theme } from '../theme';

import FlexWrapper from './FlexWrapper';

type ThemeProps = { theme: Theme };

const Title = styled.div.withConfig({
  displayName: 'Title',
})`
  position: relative;
  z-index: 50;
  width: 100%;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  color: ${(props: ThemeProps) => props.theme.colours.secondary03};
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 42px;
  font-weight: 600;
  padding: ${(props: ThemeProps) => props.theme.layout.standardPadding};
  background-color: ${(props: ThemeProps) => props.theme.colours.primary03};
  ${({ theme }: { theme: Theme }) =>
    theme.mixins.boxShadow('#000', 0.86, 0, 4, 20, 0, 0)};
`;

type Props = {
  children: Node,
};

export default function SectionMenuHeader({ children }: Props) {
  return (
    <Title>
      <FlexWrapper>{children}</FlexWrapper>
    </Title>
  );
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { screenSizeOptions } from '../../theme';
import { actions as shortlistActions } from '../../shared/shortlist';
import { actions as financeActions } from '../../shared/financeCalculator';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import type { Font, Option } from '../../types';
import { actions as filterActions } from '../../shared/filters';
import ContentWrapper from '../../components/ContentWrapper';
import ResultTile from '../../linked-modules/ResultTile/ResultTileContainer';

const FIRST_PAGE = '1';

const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export interface Config {
  translations: Object;
  subNavBGColour?: string;
  subNavFont?: Font;
  subNavBorderColour?: string;
  subNavChevronColour?: string;
  subHeadingFont?: Font;
  headingAlignment?: string;
  buttonFont?: Font;
  buttonBGColour?: Option;
  stylingConfig: Object;
  visibility: Object;
  compareIconListedColour: Object;
  shortlistIconListedColour: Object;
}

type Props = {
  config: Config,
  actions: {
    init: Function,
    getInventory: Function,
  },
  pathByModule: string => any,
  state: Object,
  shared: Object,
  dispatch: Function,
  history: Object,
  marketInfo: Object,
  globalStyling: Object,
  featureFlags: Object,
  linkedModules: Object,
};
type State = {
  filtersExpanded: boolean,
  resultPageSize: string,
  resultPageNumber: string,
  leasingExampleExpanded: boolean,
  selectedView: 'GRID' | 'LIST',
};

export default class LinkedVehicleSearchResults extends Component<
  Props,
  State,
> {
  constructor(props: Props) {
    super(props);

    const selectedView =
      window.innerWidth < screenSizeOptions.large ? 'GRID' : 'LIST';
    this.state = {
      filtersExpanded: false,
      resultPageSize: '6',
      resultPageNumber: FIRST_PAGE,
      leasingExampleExpanded: false,
      selectedView,
    };
  }

  componentDidMount() {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const { actions, dispatch, history, featureFlags } = this.props;

    const query = history.location.search.substring(1);
    this.setState({ query });
    actions.init();

    this.updateInventory(
      this.state.resultPageSize,
      this.state.resultPageNumber,
      this.props.shared.sessionPreferences.searchSort,
    );
    dispatch(filterActions.initialiseFilters(query));
    dispatch(shortlistActions.getVehicles());

    if (this.retailerId) {
      actions.getDealer(this.retailerId);
    }

    if (
      featureFlags.finance &&
      !this.props.shared.financeCalculator.representativeExample
    ) {
      dispatch(financeActions.loadRepresentativeExample());
    }

    window.addEventListener('resize', this.onViewportChange);
    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps: Props) {
    const query = this.props.history.location.search.substring(1);
    if (this.state.query !== query) {
      this.setState({ query });
      this.updateInventory(
        this.state.resultPageSize,
        FIRST_PAGE,
        this.props.shared.sessionPreferences.searchSort,
      );
    }
    const { shared } = this.props;
    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      this.updateInventory(
        this.state.resultPageSize,
        FIRST_PAGE,
        shared.sessionPreferences.searchSort,
      );
    }
  }

  onViewportChange = () => {
    if (window.innerWidth < screenSizeOptions.large) {
      this.setState(() => ({ selectedView: 'GRID' }));
    }
  };

  onFilterMenuClick = () =>
    this.setState(prevState => ({
      filtersExpanded: !prevState.filtersExpanded,
    }));

  onShowAllResultsClick = (totalResultCount: number) => {
    const resultPageSize = totalResultCount.toString();
    this.setState({ resultPageSize });
    this.updateInventory(
      resultPageSize,
      FIRST_PAGE,
      this.props.shared.sessionPreferences.searchSort,
      this.props.preview,
    );
  };

  onPageNumberChange = (value: string) => {
    this.setState({ resultPageNumber: value });
    this.updateInventory(
      this.state.resultPageSize,
      value,
      this.props.shared.sessionPreferences.searchSort,
    );
    window.scroll(0, 0);
  };

  onSortChange = (payload, sortField) => {
    this.props.dispatch(filterActions.updateFilters(payload));
    this.props.dispatch(
      sessionActions.updateSessionPreferences('searchSort', sortField),
    );
  };

  onViewChange = (selectedView: 'GRID' | 'LIST') =>
    this.setState(() => ({ selectedView }));

  toggleLeasingExample = () => {
    this.setState(prevState => ({
      ...prevState,
      leasingExampleExpanded: !prevState.leasingExampleExpanded,
    }));
  };

  updateInventory = (
    pageSize: string,
    pageNumber: string,
    sortField: Object,
  ) => {
    const { actions, marketInfo, history } = this.props;
    const filters = history.location.search.substring(1);

    actions.getInventory({
      ...marketInfo,
      filters,
      pageSize,
      pageNumber,
      sortField,
    });
  };

  render() {
    const { state, pathByModule, globalStyling, linkedModules } = this.props;
    const tileConfig = Object.values(linkedModules).find(
      lm => lm.name === 'ResultTile',
    ).config;
    const results = state.searchResultConfig;

    return (
      <div>
        <ContentWrapper contentWidth={globalStyling.contentWidth}>
          <Results>
            {results &&
              results.contents &&
              results.contents.map(vehicle => (
                <ResultTile
                  key={vehicle.id}
                  vehicle={vehicle}
                  placeholdingImage={results.placeholdingImage}
                  globalStyling={globalStyling}
                  pathByModule={pathByModule}
                  moduleConfig={tileConfig}
                />
              ))}
          </Results>
        </ContentWrapper>
      </div>
    );
  }
}

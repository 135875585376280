import Icon from '../../modules/Range/Icon';

export { id as reducerId } from '../../modules/Range/reducer';

export { default as component } from './Range';
export { default as effects } from '../../modules/Range/effects';
export { default as config } from '../../modules/Range/config';
export { default as defaultConfig } from '../../modules/Range/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/Range/reducer';

export const name = 'Range';
export const id = 'Range';
export const availableToAdministration = false;
export const description = 'View information for a particular vehicle model.';
export const icon = Icon;

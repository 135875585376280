/* eslint-disable import/extensions */
import { takeEvery, put, call, select } from 'redux-saga/effects';
import Fuse from 'fuse.js';
import { actions, constants } from './reducer';
import { actions as errorActions } from '../../shared/errors';

const optionsCriteria = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 10000,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['title', 'content'],
};

const searchFactory = ({ options, list }) => new Fuse(list, options);

const search = ({ term, searchStrategy }) =>
  new Promise(resolve => {
    const result = searchStrategy.search(term);
    return resolve(result);
  });

function* SearchFor(action) {
  const {
    payload: { term },
    module: { page, instance },
  } = action;
  let fuse;
  if (!fuse) {
    const indexedPages = yield select(state => state.indexedPages);
    fuse = searchFactory({
      options: optionsCriteria,
      list: indexedPages.content,
    });
  }
  try {
    const result = yield call(search, { term, searchStrategy: fuse });
    yield put(actions.searchForSuccess(page, instance, result));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* SiteSearchSaga() {
  yield takeEvery(constants.SearchFor, SearchFor);
}

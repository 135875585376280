export const constants = {
  LOAD_VEHICLE_MAKES: 'LOAD_VEHICLE_MAKES',
  LOAD_VEHICLE_MAKES_SUCCESS: 'LOAD_VEHICLE_MAKES_SUCCESS',
  LOAD_VEHICLE_MAKES_FAILURE: 'LOAD_VEHICLE_MAKES_FAILURE',
  LOAD_VEHICLE_MODELS: 'LOAD_VEHICLE_MODELS',
  LOAD_VEHICLE_MODELS_SUCCESS: 'LOAD_VEHICLE_MODELS_SUCCESS',
  LOAD_VEHICLE_MODELS_FAILURE: 'LOAD_VEHICLE_MODELS_FAILURE',
  LOAD_VEHICLE_VERSIONS: 'LOAD_VEHICLE_VERSIONS',
  LOAD_VEHICLE_VERSIONS_SUCCESS: 'LOAD_VEHICLE_VERSIONS_SUCCESS',
  LOAD_VEHICLE_VERSIONS_FAILURE: 'LOAD_VEHICLE_VERSIONS_FAILURE',
  LOAD_VEHICLE_REGISTRATION_MONTHS: 'LOAD_VEHICLE_REGISTRATION_MONTHS',
  LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS:
    'LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS',
  LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE:
    'LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE',
  LOAD_VEHICLE_REGISTRATION_YEARS: 'LOAD_VEHICLE_REGISTRATION_YEARS',
  LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS:
    'LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS',
  LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE:
    'LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE',
  GET_VEHICLE_VALUATION: 'GET_VEHICLE_VALUATION',
  GET_VEHICLE_VALUATION_SUCCESS: 'GET_VEHICLE_VALUATION_SUCCESS',
  GET_VEHICLE_VALUATION_FAILURE: 'GET_VEHICLE_VALUATION_FAILURE',
  VALUATION_LIMIT_REACHED: 'VALUATION_LIMIT_REACHED',
  EDIT_VEHICLE_VALUATION: 'EDIT_VEHICLE_VALUATION',
  CANCEL_EDIT_VEHICLE_VALUATION: 'CANCEL_EDIT_VEHICLE_VALUATION',
};

export const actions = {
  loadVehicleMakes: () => ({
    type: constants.LOAD_VEHICLE_MAKES,
  }),
  loadVehicleMakesSuccess: makes => ({
    type: constants.LOAD_VEHICLE_MAKES_SUCCESS,
    payload: makes,
  }),
  loadVehicleMakesFailure: error => ({
    type: constants.LOAD_VEHICLE_MAKES_FAILURE,
    payload: error,
  }),
  loadVehicleModels: makeId => ({
    type: constants.LOAD_VEHICLE_MODELS,
    payload: makeId,
  }),
  loadVehicleModelsSuccess: models => ({
    type: constants.LOAD_VEHICLE_MODELS_SUCCESS,
    payload: models,
  }),
  loadVehicleModelsFailure: error => ({
    type: constants.LOAD_VEHICLE_MODELS_FAILURE,
    payload: error,
  }),
  loadVehicleVersions: (makeId, modelId) => ({
    type: constants.LOAD_VEHICLE_VERSIONS,
    payload: {
      makeId,
      modelId,
    },
  }),
  loadVehicleVersionsSuccess: versions => ({
    type: constants.LOAD_VEHICLE_VERSIONS_SUCCESS,
    payload: versions,
  }),
  loadVehicleVersionsFailure: error => ({
    type: constants.LOAD_VEHICLE_VERSIONS_FAILURE,
    payload: error,
  }),
  loadVehicleRegistrationMonths: (makeId, modelId, year) => ({
    type: constants.LOAD_VEHICLE_REGISTRATION_MONTHS,
    payload: {
      makeId,
      modelId,
      year,
    },
  }),
  loadVehicleRegistrationMonthsSuccess: months => ({
    type: constants.LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS,
    payload: months,
  }),
  loadVehicleRegistrationMonthsFailure: error => ({
    type: constants.LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE,
    payload: error,
  }),
  loadVehicleRegistrationYears: (makeId, modelId, versionId) => ({
    type: constants.LOAD_VEHICLE_REGISTRATION_YEARS,
    payload: {
      makeId,
      modelId,
      versionId,
    },
  }),
  loadVehicleRegistrationYearsSuccess: years => ({
    type: constants.LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS,
    payload: years,
  }),
  loadVehicleRegistrationYearsFailure: error => ({
    type: constants.LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE,
    payload: error,
  }),
  getVehicleValuation: (
    make,
    model,
    versionId,
    year,
    month,
    odometer,
    vehicleDetails,
  ) => ({
    type: constants.GET_VEHICLE_VALUATION,
    payload: {
      make,
      model,
      versionId,
      year,
      month,
      odometer,
      vehicleDetails,
    },
  }),
  getVehicleValuationSuccess: valuation => ({
    type: constants.GET_VEHICLE_VALUATION_SUCCESS,
    payload: valuation,
  }),
  getVehicleValuationFailure: error => ({
    type: constants.GET_VEHICLE_VALUATION_FAILURE,
    payload: error,
  }),
  valuationRequestLimitReached: () => ({
    type: constants.VALUATION_LIMIT_REACHED,
  }),
  editVehicleValuation: vehicleId => ({
    type: constants.EDIT_VEHICLE_VALUATION,
    payload: vehicleId,
  }),
  cancelEditVehicleValuation: () => ({
    type: constants.CANCEL_EDIT_VEHICLE_VALUATION,
  }),
};

const initialState = {
  makes: [],
  models: [],
  versions: [],
  years: [],
  months: [],
  networkError: null,
  valuation: null,
  tempValuation: null,
  valuationRequestLimit: false,
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.LOAD_VEHICLE_MAKES_FAILURE:
    case constants.LOAD_VEHICLE_MODELS_FAILURE:
    case constants.LOAD_VEHICLE_VERSIONS_FAILURE:
    case constants.LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE:
    case constants.LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE:
    case constants.GET_VEHICLE_VALUATION_FAILURE:
      return {
        ...state,
        networkError: true,
      };
    case constants.LOAD_VEHICLE_MAKES_SUCCESS:
      return {
        ...state,
        makes: payload,
        networkError: null,
      };
    case constants.LOAD_VEHICLE_MODELS_SUCCESS:
      return {
        ...state,
        models: payload,
        networkError: null,
      };
    case constants.LOAD_VEHICLE_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: payload,
        networkError: null,
      };
    case constants.LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS:
      return {
        ...state,
        years: payload,
        networkError: null,
      };
    case constants.LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS:
      return {
        ...state,
        months: payload,
        networkError: null,
      };
    case constants.GET_VEHICLE_VALUATION_SUCCESS:
      return {
        ...state,
        valuation: {
          ...state.valuation,
          [payload.id]: payload,
          created: new Date(),
        },
        networkError: null,
      };
    case constants.VALUATION_LIMIT_REACHED:
      return {
        ...state,
        valuationRequestLimit: true,
      };
    case constants.EDIT_VEHICLE_VALUATION:
      return {
        ...state,
        valuation: null,
        tempValuation: {
          ...state.valuation,
        },
      };
    case constants.CANCEL_EDIT_VEHICLE_VALUATION: {
      const isEditing = !!state.tempValuation;
      if (isEditing) {
        return {
          ...state,
          valuation: {
            ...state.tempValuation,
          },
          tempValuation: null,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

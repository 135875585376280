// @flow
import React from 'react';
import styled from 'styled-components';

import transparentize from 'polished/lib/color/transparentize';

import type { Node } from 'react';
import type { Theme } from '../theme';

type ThemeProps = { theme: Theme };

type Props = {
  onClick: Function,
  children: Node,
  onPlusClick?: Function,
  withLinksIcon?: boolean,
};

const Wrapper = styled.div`
  flex: 1 0 0%;
  display: flex;
  justify-content: center;
`;

const Body = styled.div`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  color: ${(props: ThemeProps) => props.theme.colours.secondary01};
  display: flex;
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  padding-top: ${(props: ThemeProps) => props.theme.layout.standardPadding};
  padding-bottom: ${(props: ThemeProps) => props.theme.layout.standardPadding};
  background-color: #33a8ef;
`;

const Title = styled.div`
  color: ${(props: ThemeProps) =>
    transparentize(0.5, props.theme.colours.primary03)};
  font-size: ${(props: ThemeProps) => props.theme.fontSizeFormControls};
  line-height: 17px;
  font-weight: 600;
  margin-left: ${(props: ThemeProps) => props.theme.layout.halfPadding};
`;

const Icon = styled.img`
  height: 35px;
  width: 35px;
  padding: 12px;
  cursor: pointer;
`;

export default function SectionMenuBack({
  children,
  onClick,
  onPlusClick,
  withLinksIcon,
}: Props) {
  return (
    <Body>
      <Icon
        src="https://res.cloudinary.com/motortrak/image/upload/v1572449966/editor/chevron-left.svg"
        onClick={onClick}
      />
      <Wrapper>
        <Title>{children}</Title>
      </Wrapper>
      {withLinksIcon && (
        <Icon src="https://res.cloudinary.com/motortrak/image/upload/v1564992732/editor/links-icon.svg" />
      )}
      {onPlusClick && (
        <Icon
          src="https://res.cloudinary.com/motortrak/image/upload/v1571824537/editor/white-plus.png"
          onClick={onPlusClick}
        />
      )}
    </Body>
  );
}

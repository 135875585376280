// @flow
import { useState, useEffect } from 'react';
import validate, { validateAll } from './validations';

type QuickContactHook = {
  handleChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  handleBlur: (event: SyntheticEvent<HTMLInputElement>) => void,
  handleSubmit: (event: SyntheticEvent<HTMLInputElement>) => void,
  values: Object,
  errors: Object,
  submissionStatus: 'unsubmitted' | 'isSubmitting' | 'submitted',
};

export function useQuickContact(
  initialState: Object,
  translations: Object,
): QuickContactHook {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState('unsubmitted');

  useEffect(() => {
    if (submissionStatus === 'isSubmitting') {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setSubmissionStatus('submitted');
      } else {
        setSubmissionStatus('unsubmitted');
      }
    }
  }, [submissionStatus]);

  function handleChange(event) {
    setValues({
      ...values,
      [event.target.name]: {
        ...values[event.target.name],
        value: event.target.value,
      },
    });
  }

  function handleBlur(event) {
    const blurError = validate(values[event.target.name], translations);
    setErrors({ ...errors, ...blurError });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const submissionErrors = validateAll(values, translations);
    setErrors(submissionErrors);
    setSubmissionStatus('submitted');
  }

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    submissionStatus,
  };
}

import {
  Config,
  Slice,
  Section,
  AlignLeftRight,
  Image,
  TextArea,
  Colour,
  Font,
  Link,
  Pixels,
} from '../../helpers/config';

const config = Config(
  '50-50 grid',
  { id: 'FiftyFifty' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      '50-50 item',
      {},
      AlignLeftRight('Image Alignment', 'imageAlign'),
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      '50-50 item',
      {},
      Image('Image', 'selectedImage'),
      TextArea('Headline', 'headlineText'),
      TextArea('Body text', 'bodyText'),
      Link('Button', 'buttonText'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      {},
      Colour('Background Colour', 'backgroundColour'),
      Font('Header Text', 'headlineFont'),
      Font('Body Text', 'bodyFont'),
      Font('Button Text', 'buttonFont'),
      Colour('Button Colour', 'buttonBackgroundColour'),
      Colour('Button Hover Colour', 'buttonHover'),
    ),
  ),
);

export default config;

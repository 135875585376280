// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from '../theme';
import VdpAccordion from './VdpAccordion';

import {
  HeadingTwo,
  HeadingThree,
  Paragraph,
  fontStyleOverride,
} from './Global';
import VdpHighlightsImageOverlay from './VdpHighlightsImageOverlay';
import ContentWrapper from './ContentWrapper';

const WellFeatures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ horizontalPadding }) =>
    horizontalPadding && `padding: ${horizontalPadding}px 0`};
  ${({ borderTop }) =>
    borderTop &&
    `border-top: ${borderTop.size}px ${borderTop.type} ${borderTop.colour}`};
`;

const DesktopContainer = styled.div`
  display: block;
  ${media.max.large`
    display:none;
  `};
  ${WellFeatures}:nth-child(odd) {
    ${({ backgroundColour }) =>
      backgroundColour && `background-color: ${backgroundColour}`};
  }
`;

const MobileContainer = styled.div`
  display: none;
  ${media.max.large`
    display:block;
    padding: 0 5%;
  `};
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 16px;
  ${media.max.large`
    padding: 0;
  `};
`;

const Item = styled.div`
  display: flex;
  height: auto;
  width: 30%;
  align-items: center;
  ${({ border }) =>
    border &&
    `border-bottom: ${border.size}px ${border.type} ${border.colour}`};
  ${media.max.large`
    width: 100%;
    &:last-child {
      border-bottom: none;
    }
  `};
`;

type Props = {
  vehicle: Object,
  translations: Object,
  designList: Object,
  equipmentList: Object,
  specificationList: Object,
  globalStyling: Object,
  infoIcon: string,
};

type HeaderProps = {
  title: string,
  fontStyle: Font,
  paddingBottom: number,
};

const Header = ({ title, fontStyle, paddingBottom }: HeaderProps) => (
  <HeadingTwo
    styleOverride={() => `
        width: 100%;
        padding-bottom: ${paddingBottom}px;
        ${fontStyleOverride(fontStyle)}
      `}
  >
    {title}
  </HeadingTwo>
);

export default function VdpWellRr({
  vehicle,
  translations,
  designList,
  equipmentList,
  specificationList,
  globalStyling,
  infoIcon,
}: Props) {
  const HandDrive = {
    L: translations.leftHandDrive,
    R: translations.rightHandDrive,
  };

  const specs = [];
  specs.push(
    {
      name: translations.specificationCapacity,
      value: vehicle.specification.capacity,
    },
    {
      name: translations.specificationTransmission,
      value: vehicle.transmissionType,
    },
    {
      name: translations.specificationHandDrive,
      value: HandDrive[vehicle.handDrive],
    },
    {
      name: translations.specificationFuelType,
      value: vehicle.specification.fuelType,
    },
    {
      name: translations.specificationOdometer,
      value: vehicle.specification.odometer.display,
    },
    {
      name: translations.specificationRegistration,
      value: vehicle.registration,
    },
  );
  const designItems =
    vehicle &&
    vehicle.bespokeFeatures &&
    vehicle.bespokeFeatures.length > 5 &&
    vehicle.bespokeFeatures.slice(5);
  const specifications = specs.reduce((result, spec) => {
    if (spec.value && spec.value !== '') {
      result.push(spec);
    }
    return result;
  }, []);
  const equipmentListDisplay =
    vehicle.otherEquipment &&
    vehicle.otherEquipment.length &&
    vehicle.otherEquipment.find(f => f.category === equipmentList.categoryName);

  const DesignItems = () => (
    <Fragment>
      {designItems &&
        designItems.map((f, index) => (
          <Item
            key={`designItem-${index.toString()}`}
            border={designList.itemBorder}
          >
            <Paragraph
              styleOverride={() => `
              width: 100%;
              margin: 12px 0;
              ${fontStyleOverride(designList.itemFont)}
            `}
            >
              {f.name}
            </Paragraph>
            {f.imageUrl && (
              <VdpHighlightsImageOverlay
                imageUrl={f.imageUrl}
                infoIcon={infoIcon}
              />
            )}
          </Item>
        ))}
    </Fragment>
  );
  const EquipmentItems = () => (
    <Fragment>
      {equipmentListDisplay.equipment.map((f, index) => (
        <Item
          key={`equipmentItem-${index.toString()}`}
          border={equipmentList.itemBorder}
        >
          <Paragraph
            styleOverride={() => `
              width: 100%;
              margin: 12px 0;
              ${fontStyleOverride(equipmentList.itemFont)}
            `}
          >
            {f}
          </Paragraph>
        </Item>
      ))}
    </Fragment>
  );
  const SpecificationItems = () => (
    <Fragment>
      {specifications.map((f, index) => (
        <Item
          key={`specificationItem-${index.toString()}`}
          border={specificationList.itemBorder}
        >
          <HeadingThree
            styleOverride={() => `
              width: 100%;
              margin: 12px 0;
              ${fontStyleOverride(specificationList.labelItemFont)}
            `}
          >
            {f.name}
          </HeadingThree>
          <Paragraph
            styleOverride={() => `
              width: 100%;
              margin: 12px 0;
              ${fontStyleOverride(specificationList.itemFont)}
            `}
          >
            {f.value}
          </Paragraph>
        </Item>
      ))}
    </Fragment>
  );
  const AdditionalCommentItems = () => (
    <Fragment>
      {vehicle.additionalComments.map((f, index) => (
        <Item
          key={`additionalComments-${index.toString()}`}
          border={equipmentList.itemBorder}
        >
          <Paragraph
            styleOverride={() => `
              width: 100%;
              margin: 12px 0;
              ${fontStyleOverride(equipmentList.itemFont)}
            `}
          >
            {f}
          </Paragraph>
        </Item>
      ))}
    </Fragment>
  );

  return (
    <Fragment>
      <div id="design-list" />
      <DesktopContainer backgroundColour={designList.backgroundColour}>
        {designItems && designItems.length > 0 && (
          <WellFeatures
            data-qa-hook="vehicle-information"
            borderTop={designList.borderTop}
            horizontalPadding={designList.horizontalPadding}
          >
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              {translations.vdpDesignTitle && (
                <Header
                  title={translations.vdpDesignTitle}
                  fontStyle={designList.headerFont}
                  paddingBottom={designList.headerPaddingBottom}
                />
              )}
              <ItemsContainer>
                <DesignItems />
                {designItems.length % 3 === 2 && <Item />}
              </ItemsContainer>
            </ContentWrapper>
          </WellFeatures>
        )}
        {equipmentListDisplay && (
          <WellFeatures
            data-qa-hook="vehicle-information"
            borderTop={equipmentList.borderTop}
            horizontalPadding={equipmentList.horizontalPadding}
          >
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              {translations.vdpOtherEquipmentHeader && (
                <Header
                  title={translations.vdpOtherEquipmentHeader}
                  fontStyle={equipmentList.headerFont}
                  paddingBottom={equipmentList.headerPaddingBottom}
                />
              )}
              <ItemsContainer>
                <EquipmentItems />
                {equipmentListDisplay.equipment &&
                  equipmentListDisplay.equipment.length % 3 === 2 && <Item />}
              </ItemsContainer>
            </ContentWrapper>
          </WellFeatures>
        )}
        {vehicle.additionalComments && (
          <WellFeatures
            data-qa-hook="vehicle-information"
            borderTop={specificationList.borderTop}
            horizontalPadding={specificationList.horizontalPadding}
          >
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              {translations.vdpAdditionalCommentsHeader && (
                <Header
                  title={translations.vdpAdditionalCommentsHeader}
                  fontStyle={specificationList.headerFont}
                  paddingBottom={specificationList.headerPaddingBottom}
                />
              )}
              <ItemsContainer>
                <AdditionalCommentItems />
                {vehicle.additionalComments &&
                  vehicle.additionalComments.length % 3 === 2 && <Item />}
              </ItemsContainer>
            </ContentWrapper>
          </WellFeatures>
        )}
        {vehicle.specification && (
          <WellFeatures
            data-qa-hook="vehicle-information"
            borderTop={specificationList.borderTop}
            horizontalPadding={specificationList.horizontalPadding}
          >
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              {translations.vdpSpecificationHeader && (
                <Header
                  title={translations.vdpSpecificationHeader}
                  fontStyle={specificationList.headerFont}
                  paddingBottom={specificationList.headerPaddingBottom}
                />
              )}
              <ItemsContainer>
                <SpecificationItems />
                {specifications.length % 3 === 2 && <Item />}
              </ItemsContainer>
            </ContentWrapper>
          </WellFeatures>
        )}
      </DesktopContainer>
      <MobileContainer>
        {translations.vdpDesignTitle && designItems && (
          <VdpAccordion title={translations.vdpDesignTitle}>
            <ItemsContainer>
              <DesignItems />
            </ItemsContainer>
          </VdpAccordion>
        )}
        {translations.vdpOtherEquipmentHeader && equipmentListDisplay && (
          <VdpAccordion title={translations.vdpOtherEquipmentHeader}>
            <ItemsContainer>
              <EquipmentItems />
            </ItemsContainer>
          </VdpAccordion>
        )}
        {translations.vdpAdditionalCommentsHeader &&
          vehicle.additionalComments && (
            <VdpAccordion title={translations.vdpAdditionalCommentsHeader}>
              <ItemsContainer>
                <AdditionalCommentItems />
              </ItemsContainer>
            </VdpAccordion>
          )}
        {translations.vdpSpecificationHeader && vehicle.specification && (
          <VdpAccordion title={translations.vdpSpecificationHeader}>
            <ItemsContainer>
              <SpecificationItems />
            </ItemsContainer>
          </VdpAccordion>
        )}
      </MobileContainer>
    </Fragment>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import GlobalSettingsIcon from './GlobalSettingsIcon';

const StyledGlobalSettings = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  height: 1.5em;
  margin-right: 10px;
`;

export default function GlobalSettings() {
  return (
    <StyledGlobalSettings>
      <IconWrapper>
        <GlobalSettingsIcon />
      </IconWrapper>
      Global Settings
    </StyledGlobalSettings>
  );
}

// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import FormField from './FormField';
import FieldError from './FieldError';
import WidthWrapper from './WidthWrapper';

import type { Font } from '../../../types';

const Input = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-family: inherit;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid ${({ error }) => (error ? '#BF0C0C' : '#d8d8d8')};
  background-color: ${({ backgroundColour }) => backgroundColour || '#ffffff'};
  padding: 0 8px;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
  ${props =>
    props.truncateChars &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

type Props = {
  label: string,
  required: boolean,
  name: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  error?: string,
  isDisabled: boolean,
  placeholder: string,
  translations: Object,
  truncateChars: boolean,
  font: Font,
};

export default ({
  name,
  label,
  required,
  value,
  onChange,
  onBlur,
  error,
  isDisabled,
  placeholder,
  truncateChars = false,
  font,
}: Props) => {
  return (
    <WidthWrapper>
      <FormField label={label} name={name} required={required} font={font}>
        <Input
          type="text"
          name={name}
          id={name}
          value={value}
          onChange={e => {
            onChange({ name: e.target.name, value: e.target.value });
          }}
          onBlur={e => {
            onBlur({ name: e.target.name, value: e.target.value });
          }}
          placeholder={placeholder}
          error={error}
          disabled={isDisabled}
          truncateChars={truncateChars}
          ariaRequired={required}
        />
        {error && <FieldError>{error}</FieldError>}
      </FormField>
    </WidthWrapper>
  );
};

// @flow

import React from 'react';

import { type FieldProps } from '../models/fields';
import Number from './Number';

export default function Pixels(props: FieldProps) {
  return <Number {...props} inputLabel="px" />;
}

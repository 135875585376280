// @flow
import React, { Component } from 'react';
import TestDriveContainer from '../../components/TestDrive';
import { actions as routerActions } from '../../actions/router';
import { actions as leadsActions } from '../../shared/leadsAuthentication';
import { isTokenValid } from '../../shared/CustomerLogin/reducer';

export interface Config {
  translations: Object;
}

type Props = {
  config: Config,
  actions: {
    getVehicleInfo: (id: string) => void,
    setBookingFormValue: (key: string, value: string) => void,
    toggleCalendar: () => void,
    disableCalendar: boolean,
    confirmDateAndTime: () => void,
    setSelectRadioGroupMode: (key: string, value: string) => void,
    interestedClicked: () => void,
    setFormValueFromDropdown: Function,
    reset: () => void,
    changeContext: (context: string) => void,
  },
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  dispatch: Function,
  pathByModule: Function,
  globalStyling: Object,
  history: Object,
};

export default class TestDrive extends Component<Props, *> {
  componentDidMount = () => {
    const { actions, dispatch } = this.props;
    const location = this.props.history.location.pathname;
    const vehicleId = location.split('/').pop();
    dispatch(leadsActions.getLeadsToken());
    actions.getVehicleInfo(vehicleId);
  };

  componentWillUnmount = () => {
    this.props.actions.reset();
  };

  onTitleChange = (selected: {
    keyValue: string,
    value: any,
    label: string,
  }) => {
    this.props.dispatch(
      this.props.actions.setFormValueFromDropdown(
        selected,
        this.props.config.translations,
      ),
    );
  };

  render = () => {
    const {
      state: {
        testDriveConfig,
        context,
        open,
        bookingForm,
        bookingFormError,
        selectDateMode,
        selectDateModeOptions,
        selectTimeMode,
        selectTimeModeOptions,
        selectedDateMode,
        selectedTimeMode,
        disableCalendar,
        loginFailed,
        somethingWentWrong,
        userCreated,
        newUserEmail,
      },
      config,
      dispatch,
      pathByModule,
      actions,
      shared,
      preview,
      globalStyling,
      history,
    } = this.props;

    const vehicleId = history.location.pathname.split('/').pop();
    const navigateToVdp = () =>
      dispatch(routerActions.navigate(`${pathByModule('VDP')}/${vehicleId}`));
    const userIsLoggedIn = preview ? false : isTokenValid(shared);
    return testDriveConfig ? (
      <TestDriveContainer
        goBack={ctx => actions.changeContext(ctx)}
        translations={config.translations}
        context={context}
        setBookingFormValue={actions.setBookingFormValue}
        onTitleChange={this.onTitleChange}
        continueAsGuest={actions.continueAsGuest}
        userCreated={userCreated}
        newUserEmail={newUserEmail}
        confirmRequest={actions.confirmRequest}
        toggleCalendar={actions.toggleCalendar}
        loginAndConfirm={actions.loginAndConfirm}
        loginFailed={loginFailed}
        userIsLoggedIn={userIsLoggedIn}
        somethingWentWrong={somethingWentWrong}
        open={open}
        bookingForm={bookingForm}
        bookingFormError={bookingFormError}
        testDriveConfig={testDriveConfig}
        selectDateMode={selectDateMode}
        selectDateModeOptions={selectDateModeOptions}
        setSelectRadioGroupMode={actions.setSelectRadioGroupMode}
        selectTimeMode={selectTimeMode}
        selectTimeModeOptions={selectTimeModeOptions}
        disableCalendar={disableCalendar}
        confirmDateAndTime={actions.confirmDateAndTime}
        sendTestDriveLead={actions.userLoggedIn}
        selectedDateMode={selectedDateMode}
        selectedTimeMode={selectedTimeMode}
        interestedClicked={actions.interestedClicked}
        globalStyling={globalStyling}
        contactUs={vehicle =>
          dispatch(routerActions.navigate(`/enquiry/${vehicle.id}`))
        }
        goToMyAccount={() => dispatch(routerActions.navigate('/myaccount'))}
        onVisitUs={retailerInformation =>
          dispatch(
            routerActions.navigateWithPayload(
              pathByModule('RetailerLocationSearch'),
              retailerInformation,
            ),
          )
        }
        navigateToVdp={navigateToVdp}
      />
    ) : null;
  };
}

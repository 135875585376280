// @flow
import React from 'react';
import styled from 'styled-components';

import { SectionMenuBack } from 'cms-ui';

import PodSelector from './PodSelector';
import usePodList from './hooks/usePodList';

const Container = styled.div`
  display: grid;
  grid-template-columns: 84px 84px 84px;
  grid-gap: ${({ theme }) => theme.layout.halfPadding};
  padding: ${({ theme }) =>
    `${theme.layout.halfPadding} 0 0 ${theme.layout.halfPadding}`};
`;

export default () => {
  const { pageName, pods, onLeave } = usePodList();
  return (
    <>
      <SectionMenuBack onClick={onLeave}>{pageName}</SectionMenuBack>
      <Container>
        {pods
          .sort((a, b) =>
            a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }),
          )
          .map(pod => (
            <PodSelector key={pod.id} pod={pod} />
          ))}
      </Container>
    </>
  );
};

import {
  Config,
  Slice,
  ListSlice,
  Section,
  Colour,
  Font,
  Toggle,
  AlignLeftRight,
  Image,
  TextArea,
  Link,
  PageAndExternalLink,
  Opacity,
  Styles,
  Number,
  Pixels,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

import { slideContentStyles, paginationStyles } from './HeroCarousel';

const slideContentStyleOptions = Object.keys(slideContentStyles);
const paginationStyleOptions = Object.keys(paginationStyles);

const hintText = () => `
  Please note that all images for this Module will be transformed to a resolution of 1366 x 600 pixels.
  To optimise the display of images they should be as close to this resolution as possible.
  `;

const config = Config(
  'NewHeroCarousel',
  { id: 'newHeroCarousel' },
  Slice(
    'Slides',
    { id: 'slide-options' },
    Section(
      'Global',
      { icon: SectionIcons.SideCarousel },
      Toggle('Slides loop', 'slidesLoopEnabled'),
      Number('Slide transition speed', 'slideTransitionSpeed', {
        inputLabel: 'sec',
      }),
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
  ListSlice(
    'Content',
    {
      id: 'items',
      max: 8,
      sectionLabel: 'Slide',
      buttonLabel: 'Add new slide',
      icon: SectionIcons.HeroCarousel,
      focus: 'displaySlide',
    },
    AlignLeftRight('Align', 'align'),
    Image('Image', 'mediaContent', { hintText }),
    PageAndExternalLink('Slide URL', 'url'),
    TextArea('Header Text', 'header'),
    TextArea('Body Text', 'body'),
    Link('Button', 'topLink'),
    Link('Button', 'midLink'),
    Link('Button', 'bottomLink'),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.SideCarousel },
      Font('Header text', 'slideHeaderFont'),
      Colour('Header Background Colour', 'headerBGColour'),
      Font('Body text', 'slideBodyFont'),
      Colour('Body Background Colour', 'bodyBGColour'),
      Font('Button text', 'slideButtonFont'),
      Colour('Button Colour', 'buttonBGColour'),
      Styles('Slide Styles', 'slideContentStyle', {
        options: slideContentStyleOptions,
      }),
      Styles('Pagination Styles', 'paginationStyle', {
        options: paginationStyleOptions,
      }),
    ),
  ),
);

export default config;

import React from 'react';
import { useSelector } from 'react-redux';
import { renderer } from 'cms-modules';
import AddNewBand from './AddNewBand';
import PAGE_TYPES from '../../constants/pageTypes';

const { Body } = renderer;

export default props => {
  const { path } = props;
  const page = useSelector(state => state.config.pages[path]);
  return (
    <>
      <Body {...props} />
      {page.pageType !== PAGE_TYPES.SEARCH_RESULTS && <AddNewBand />}
    </>
  );
};

// @flow
import React, { Fragment, Component } from 'react';
import styled from 'styled-components';

import media from '../../theme';

import SortMenuBar from './SortMenuBar';
import VehicleResults from './VehicleResults';
import { SpinnerWrapper } from '../Spinner';
import FiltersContainer from '../../linked-modules/Filters/FiltersContainer';
import MobileFilters from '../MobileFilters';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.min.large`
    padding: 0 16px;
  `};
`;

const Line = styled.hr`
  background-color: #dedede;
  border-bottom: none;
`;

const Grid = styled.div`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 100%;
  }
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  flex-wrap: nowrap;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  ${({ applyPadding }) => applyPadding && 'padding: 0 50px;'};
  ${media.max.large`
  ${({ applyPadding }) => applyPadding && 'padding: 0 20px;'};
  `};
  ${media.max.medium`
    display: none;
    padding: 0;
  `};
`;
const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const GridResultColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const SortMenuBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MobileOnly = styled.div`
  display: none;
  ${media.max.medium`
    display: block;
  `};
`;

const ResultsSectionWrapper = styled.div`
  min-height: 1px;
  ${({ direction }) =>
    direction === 'rtl' ? 'padding: 0 16px 0 0;' : 'padding: 0 0 0 16px;'};
  ${media.max.large`
    padding: 16px 5%;
  `};
`;

const VehicleResultsWrapper = styled.div`
  padding-top: 16px;
`;

const FiltersContainerWrapper = styled.div`
  width: 280px;
`;

type Props = {
  config: Object,
  shared: Object,
  filtersStyling: Object,
  marketInfo: Object,
  translations: Object,
  filtersExpanded: boolean,
  onFilterMenuClick: () => void,
  results: Object,
  onMoreInfoClick: Function,
  onShowAllResultsClick: Function,
  onPageNumberChange: Function,
  sortOptions: Object,
  sortValue: string,
  onSortChange: Function,
  showSortByDistance: boolean,
  showSortByAge: boolean,
  showSortByPrice: boolean,
  showSortByMileage: boolean,
  selectedView: 'GRID' | 'LIST',
  preview: boolean,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  representativeExample: Object,
  getCompareIconColour: Function,
  resetFilters: Function,
  retailer: Object,
  headingAlignment?: string,
  subHeadingAlignment?: string,
  globalStyling: Object,
  tilesVisibility: Object,
  filterVisibility: Object,
  capitalizeSubHeaderCountryName: boolean,
  history: Object,
  vehicleModelTitleFontWeight: number,
  headingBottomLineDisable: boolean,
  showGridTiles: boolean,
  filtersConfig: any,
  badgeStyling: Object,
  gridSelected: string,
  gridDeselected: string,
  listSelected: string,
  listDeselected: string,
  optionalSelectedColour: string,
  optionalSelectedBgColour: string,
  pricingSectionImage: string,
  headerItemFont: Object,
  listViewImageWidth: string,
  gridViewColumns: number,
  resultItemBackgroundColour: Option,
  moreInfoButtonRightAlign: boolean,
  tileImageMaxWidth: number,
  financeCellHorizontalPadding: number,
  showFinancePopup: boolean,
  activeFiltersCount: boolean,
  handleZeroPriceAsText: boolean,
  showNumberOfImagesInsteadOfViews: boolean,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
  optionalVehicleTitle: boolean,
  vehicleTitleFont: Object,
  vehicleSubTitleFont: Object,
  backgroundColour: Option,
  financeWidthOverride: number,
  financePaddingOverride: boolean,
  financeHeadingFont: Object,
  financeSubHeadingFont: Object,
  actionRowStyles: Object,
  vehicleSpecContainer: Object,
  infiniteScroll: boolean,
  loading: boolean,
  currentPage: number,
  pageSize: number,
  totalResults: number,
  filtersLoaded: boolean,
  countDivMargin: boolean,
  compareShortlistIconWidth: number,
  icon360: string,
};

const ResultsSection = ({
  config,
  globalStyling,
  translations,
  sortOptions,
  sortValue,
  onSortChange,
  selectedView,
  showSortByDistance,
  filtersExpanded,
  showSortByMileage,
  showSortByAge,
  showSortByPrice,
  results,
  onMoreInfoClick,
  onShowAllResultsClick,
  onPageNumberChange,
  compareVehicle,
  getShortListedIconColour,
  shortlistVehicle,
  getCompareIconColour,
  tilesVisibility,
  marketInfo,
  vehicleModelTitleFontWeight,
  showGridTiles,
  gridSelected,
  gridDeselected,
  listSelected,
  listDeselected,
  badgeStyling,
  optionalSelectedColour,
  optionalSelectedBgColour,
  pricingSectionImage,
  headerItemFont,
  listViewImageWidth,
  gridViewColumns,
  resultItemBackgroundColour,
  moreInfoButtonRightAlign,
  tileImageMaxWidth,
  financeCellHorizontalPadding,
  showFinancePopup,
  handleZeroPriceAsText,
  showNumberOfImagesInsteadOfViews,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
  optionalVehicleTitle,
  vehicleTitleFont,
  vehicleSubTitleFont,
  backgroundColour,
  financeWidthOverride,
  financePaddingOverride,
  financeHeadingFont,
  financeSubHeadingFont,
  actionRowStyles,
  vehicleSpecContainer,
  infiniteScroll,
  loading,
  fullSubtitle,
  currentPage,
  pageSize,
  totalResults,
  countDivMargin,
  compareShortlistIconWidth,
}: Props) => (
  <ResultsSectionWrapper direction={globalStyling.direction}>
    <SortMenuBarContainer>
      {totalResults > 0 && (
        <SortMenuBar
          sortOptions={sortOptions}
          sortValue={sortValue}
          onSortChange={onSortChange}
          selectedView={selectedView}
          translations={translations}
          showDistance={showSortByDistance}
          filtersExpanded={filtersExpanded}
          showMileage={showSortByMileage}
          showAge={showSortByAge}
          showPrice={showSortByPrice}
          gridSelected={gridSelected}
          gridDeselected={gridDeselected}
          listSelected={listSelected}
          listDeselected={listDeselected}
          optionalSelectedColour={optionalSelectedColour}
          optionalSelectedBgColour={optionalSelectedBgColour}
        />
      )}
    </SortMenuBarContainer>
    <VehicleResultsWrapper>
      <VehicleResults
        config={config}
        results={results}
        onMoreInfoClick={onMoreInfoClick}
        translations={translations}
        onShowAllResultsClick={onShowAllResultsClick}
        onPageNumberChange={onPageNumberChange}
        selectedView={selectedView}
        filtersExpanded={filtersExpanded}
        compareVehicle={compareVehicle}
        shortlistVehicle={shortlistVehicle}
        getShortListedIconColour={getShortListedIconColour}
        getCompareIconColour={getCompareIconColour}
        globalStyling={globalStyling}
        tilesVisibility={tilesVisibility}
        locale={marketInfo.locale}
        vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
        showGridTiles={showGridTiles}
        badgeStyling={badgeStyling}
        pricingSectionImage={pricingSectionImage}
        headerItemFont={headerItemFont}
        listViewImageWidth={listViewImageWidth}
        gridViewColumns={gridViewColumns}
        resultItemBackgroundColour={resultItemBackgroundColour}
        moreInfoButtonRightAlign={moreInfoButtonRightAlign}
        tileImageMaxWidth={tileImageMaxWidth}
        financeCellHorizontalPadding={financeCellHorizontalPadding}
        showFinancePopup={showFinancePopup}
        handleZeroPriceAsText={handleZeroPriceAsText}
        showNumberOfImagesInsteadOfViews={showNumberOfImagesInsteadOfViews}
        badgeWithStripUrl={badgeWithStripUrl}
        badgeWithoutStripUrl={badgeWithoutStripUrl}
        warrantyBadgeMonths={warrantyBadgeMonths}
        warrantyBadgeMaxMileage={warrantyBadgeMaxMileage}
        optionalVehicleTitle={optionalVehicleTitle}
        vehicleTitleFont={vehicleTitleFont}
        vehicleSubTitleFont={vehicleSubTitleFont}
        backgroundColour={backgroundColour}
        financeWidthOverride={financeWidthOverride}
        financePaddingOverride={financePaddingOverride}
        financeHeadingFont={financeHeadingFont}
        financeSubHeadingFont={financeSubHeadingFont}
        actionRowStyles={actionRowStyles}
        vehicleSpecContainer={vehicleSpecContainer}
        infiniteScroll={infiniteScroll}
        loading={loading}
        fullSubtitle={fullSubtitle}
        currentPage={currentPage}
        pageSize={pageSize}
        totalResults={totalResults}
        countDivMargin={countDivMargin}
        compareShortlistIconWidth={compareShortlistIconWidth}
      />
    </VehicleResultsWrapper>
  </ResultsSectionWrapper>
);

const Filters = ({
  filtersConfig,
  globalStyling,
  toggle,
  marketInfo,
  shared,
}) => (
  <FiltersContainer
    {...filtersConfig}
    globalStyling={globalStyling}
    locale={marketInfo.locale}
    toggle={toggle}
    currencyCode={marketInfo.currencyCode}
    shared={shared}
    searchCountryCode={marketInfo.searchCountryCode}
    hideSearch
  />
);

export default class SearchResults extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayMobileFilterOptions: false,
    };
  }

  toggle = () => {
    this.setState(({ displayMobileFilterOptions }) => ({
      displayMobileFilterOptions: !displayMobileFilterOptions,
    }));
  };

  render = () => {
    const {
      config,
      headingBottomLineDisable,
      filtersConfig,
      globalStyling,
      translations,
      activeFiltersCount,
      infiniteScroll,
      marketInfo,
      loading,
      filtersLoaded,
      shared,
    } = this.props;
    const { displayMobileFilterOptions } = this.state;
    const filters = (
      <Filters
        {...{
          filtersConfig,
          globalStyling,
          toggle: () => this.toggle(),
          marketInfo,
          shared,
          displayMobileFilterOptions,
        }}
      />
    );

    return (
      <Container data-qa-hook="search-results">
        <MobileOnly>
          <MobileFilters
            filters={filters}
            translations={translations}
            activeFiltersCount={activeFiltersCount}
            globalStyling={globalStyling}
            backgroundColour={
              filtersConfig.config.stylingConfig &&
              filtersConfig.config.stylingConfig.backgroundColour
            }
            borderColour={
              filtersConfig.config.stylingConfig &&
              filtersConfig.config.stylingConfig.borderColour
            }
            config={config}
            shared={shared}
            market={marketInfo.market}
          />
          {!(headingBottomLineDisable === true) && <Line />}
          <ResultsSection {...this.props} />
        </MobileOnly>
        <Grid applyPadding={!globalStyling.contentWidth}>
          <SpinnerWrapper
            loaded={filtersLoaded}
            globalStyling={globalStyling}
            label={translations.loadingText}
          >
            {() => (
              <Fragment>
                <GridColumn>
                  <FiltersContainerWrapper>{filters}</FiltersContainerWrapper>
                </GridColumn>
                <GridResultColumn>
                  <SpinnerWrapper
                    loaded={infiniteScroll || !loading}
                    globalStyling={globalStyling}
                    label={translations.loadingText}
                  >
                    {() => (
                      <Fragment>
                        <ResultsSection {...this.props} />
                      </Fragment>
                    )}
                  </SpinnerWrapper>
                </GridResultColumn>
              </Fragment>
            )}
          </SpinnerWrapper>
        </Grid>
      </Container>
    );
  };
}

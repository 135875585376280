// @flow
import { put, call } from 'redux-saga/effects';
import watch from '../../helpers/watch';
import { saveSiteSettings } from './settings';
import { actions, constants } from '../actions/socialShare';
import type { SaveSocialShareOptionsAction } from '../actions/socialShare';

export function* saveSocialShareOptions(
  token: string,
  action: SaveSocialShareOptionsAction,
): Generator<*, *, *> {
  try {
    yield call(saveSiteSettings, token, action.payload);
    yield put(actions.saveSocialShareOptionsSuccess());
  } catch (error) {
    yield put(actions.saveSocialShareOptionsFailure());
  }
}

export default [
  watch(constants.SaveSocialShareOptions, saveSocialShareOptions, true),
];

// @flow
import { constants, type Action as SocialAction } from '../actions/socialShare';
import {
  constants as settingsConstants,
  type Action as SettingsAction,
} from '../actions/settings';
import { constants as editorConstants } from '../actions/editor';

import type { State } from '../types/socialShare';

const initialState: State = {
  socialMediaTypes: [],
  availableShareDestinations: ['Facebook', 'Twitter', 'WhatsApp', 'Email'],
  error: null,
  success: null,
};

export default function reducer(
  state: State = initialState,
  action: SocialAction | SettingsAction,
) {
  switch (action.type) {
    case editorConstants.ResetSuccessMessages:
      return {
        ...state,
        success: null,
      };
    case editorConstants.ResetFailureMessages:
      return {
        ...state,
        error: null,
      };
    case constants.UpdateSocialShareOptions:
      return {
        ...action.payload,
      };
    case constants.SaveSocialShareOptionsSuccess:
      return {
        ...state,
        success: true,
        error: null,
      };
    case constants.SaveSocialShareOptionsFailure:
      return {
        ...state,
        success: null,
        error: 'save failed',
      };
    case settingsConstants.LoadSettingsSuccess:
      return {
        ...state,
        ...action.payload.socialShare,
      };
    default:
      return state;
  }
}

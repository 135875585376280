// @flow
import React from 'react';
import styled from 'styled-components';

import DirectionSearcher from '../../components/DirectionSearcher';
import GoogleMap from '../../components/GoogleMap';
import { Button, HeadingTwo, Paragraph } from '../../components/Global';
import { useVehicleRetailer } from './useVehicleRetailer';
import { Wrapper } from '../components';

import type { IVehicleRetailerInfoConfig } from './types';

const pinIconUrl =
  'https://res.cloudinary.com/motortrak/image/upload/v1566820052/cms/global/icons/pin.svg';

const Header = styled.div`
  display: flex;
  flex: 1 0 100%;
`;

const RetailerInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: stretch;
`;

const RetailerInfo = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
`;

const MapContainer = styled.div`
  width: 70%;
`;

const DistanceFromRetailer = styled.div`
  display: flex;
  align-items: center;
`;

const PinIcon = styled.img`
  src: ${({ src }) => src};
`;

type Props = {
  config: IVehicleRetailerInfoConfig,
  retailer: Object,
  globalStyling: Object,
  vehicleInfo: {
    vehicleId: string,
    retailerInformation: {
      location: Object,
    },
  },
};

export default ({
  config,
  config: { translations },
  globalStyling,
  vehicleInfo: {
    vehicleId,
    retailerInformation: retailer,
    retailerInformation: { location: retailerLoc },
  },
}: Props) => {
  const {
    distanceFromRetailer,
    setDistanceFromRetailer,
    setPosition,
    viewRetailerStock,
    makeAnEnquiry,
  } = useVehicleRetailer(retailerLoc, translations);

  return (
    <Wrapper>
      <RetailerInfoWrapper>
        <Header>{translations.retailerMapHeader}</Header>
        <RetailerInfo>
          <HeadingTwo>{retailer.name}</HeadingTwo>
          {retailer.address.map(line => (
            <Paragraph key={line}>{line}</Paragraph>
          ))}
          <Paragraph>
            {`${translations.vdpRetailerPhoneLabel}: ${retailer.phone}`}
          </Paragraph>
          <Paragraph>
            {translations.vdpRetailerEmailLabel}: {retailer.email}
          </Paragraph>
          {distanceFromRetailer && (
            <DistanceFromRetailer>
              <PinIcon alt="map pin" src={pinIconUrl} />
              <span>{distanceFromRetailer}</span>
            </DistanceFromRetailer>
          )}
          <DirectionSearcher
            onGetCurrentPosition={(position: Position) => setPosition(position)}
            placeholder={translations.vdpRetailerGetDirections}
            destination={retailerLoc}
            retailerLocationCountry={retailer.country}
            locationFilterLabel={translations.locationFilterLabel}
            globalStyling={globalStyling}
            resetDistanceFromRetailer={() => setDistanceFromRetailer(null)}
          />
          <Button
            applyStyle="primary"
            text={translations.vdpRetailerStockButtonText}
            onClick={() => viewRetailerStock(retailer)}
          />
          <Button
            applyStyle="secondary"
            text={translations.vdpRetailerEnquiryButtonText}
            onClick={() => makeAnEnquiry(vehicleId)}
          />
        </RetailerInfo>
        <MapContainer>
          <GoogleMap
            mapName="vdpMap"
            retailerLocation={retailerLoc}
            iconPin={config.iconPin}
          />
        </MapContainer>
      </RetailerInfoWrapper>
    </Wrapper>
  );
};

// @flow

import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';
import type { Theme } from '../theme';

import FlexWrapper from './FlexWrapper';

type ThemeProps = { theme: Theme };

type Props = {
  children: Node,
};

const Title = styled.div.withConfig({
  displayName: 'Title',
})`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  color: ${(props: ThemeProps) => props.theme.colours.secondary01};
  display: flex;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  font-weight: 600;
  padding: ${(props: ThemeProps) => props.theme.layout.standardPadding};
  background: linear-gradient(
    180deg,
    #009fef 0%,
    ${(props: ThemeProps) => props.theme.colours.primary01} 100%
  );
`;

export default function SectionMenuTitle({ children }: Props) {
  return (
    <Title>
      <FlexWrapper>{children}</FlexWrapper>
    </Title>
  );
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../../theme';
import FinanceContainer from '../../containers/FinanceContainer';
import RepresentativeExample from '../RepresentativeExample';
import FilterMenuBar from './FilterMenuBar';
import SortMenuBar from './SortMenuBar';
import VehicleResults from './VehicleResults';
import { HeadingTwo, Paragraph, fontStyleOverride } from '../Global';
import { SpinnerWrapper } from '../Spinner';

const Container = styled.div.withConfig({
  displayName: 'SearchResultsContainer',
})`
  width: 95%;
  margin: 0 2.5%;
`;

const Line = styled.hr`
  background-color: #dedede;
  border-bottom: none;
`;

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 50px;
`;

const MobileOnly = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

const DesktopOnly = styled.div`
  display: block;
  ${media.max.large`
    display: none;
  `};
`;

type Props = {
  filtersStyling: Object,
  marketInfo: Object,
  translations: Object,
  filtersExpanded: boolean,
  onFilterMenuClick: () => void,
  results: Object,
  onMoreInfoClick: Function,
  onShowAllResultsClick: Function,
  onPageNumberChange: Function,
  sortOptions: Object,
  sortValue: string,
  onSortChange: Function,
  showSortByDistance: boolean,
  showSortByAge: boolean,
  showSortByMileage: boolean,
  selectedView: 'GRID' | 'LIST',
  onViewChange: Function,
  preview: boolean,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  representativeExample: Object,
  getCompareIconColour: Function,
  resetFilters: Function,
  retailer: Object,
  headingAlignment?: string,
  subHeadingAlignment?: string,
  globalStyling: Object,
  tilesVisibility: Object,
  filterVisibility: Object,
  headingFont: Object,
  subHeadingFont: Object,
  headerItemFont: Object,
  capitalizeSubHeaderCountryName: boolean,
  history: Object,
  featureFlags: Object,
  vehicleModelTitleFontWeight: number,
  headingBottomLineDisable: boolean,
  fontFamily: string,
  headerItemFont: Object,
  showGridTiles: boolean,
  badgeStyling: Object,
  financeHeadingFont: Object,
  infiniteScroll: boolean,
  loading: boolean,
  currentPage: number,
  pageSize: number,
  totalResults: number,
  distanceFilterInitialSelectedIndex: number,
};

const FilterMenuBarSection = ({
  history,
  filtersExpanded,
  onFilterMenuClick,
  translations,
  preview,
  resetFilters,
  marketInfo,
  filtersStyling,
  filterVisibility,
  globalStyling,
  featureFlags,
  distanceFilterInitialSelectedIndex,
  dropdownTheme,
  locationIconUrl,
}: Props) => (
  <FilterMenuBar
    filtersExpanded={filtersExpanded}
    onFilterMenuClick={onFilterMenuClick}
    translations={translations}
    preview={preview}
    resetFilters={resetFilters}
    config={{
      ...marketInfo,
      locationIconUrl,
      translations,
      stylingConfig: filtersStyling,
      filterVisibility,
      distanceFilterInitialSelectedIndex,
      dropdown: dropdownTheme,
    }}
    globalStyling={globalStyling}
    history={history}
    featureFlags={featureFlags}
  />
);

const ResultsSection = ({
  globalStyling,
  translations,
  sortOptions,
  sortValue,
  onSortChange,
  selectedView,
  onViewChange,
  showSortByDistance,
  filtersExpanded,
  showSortByMileage,
  showSortByAge,
  results,
  onMoreInfoClick,
  onShowAllResultsClick,
  onPageNumberChange,
  compareVehicle,
  getShortListedIconColour,
  shortlistVehicle,
  getCompareIconColour,
  tilesVisibility,
  marketInfo,
  vehicleModelTitleFontWeight,
  fontFamily,
  headerItemFont,
  showGridTiles,
  badgeStyling,
  financeHeadingFont,
  infiniteScroll,
  loading,
  currentPage,
  pageSize,
  totalResults,
}: Props) => (
  <SpinnerWrapper
    loaded={infiniteScroll || !loading}
    globalStyling={globalStyling}
    label={translations.loadingText}
  >
    {() => (
      <Fragment>
        <SortMenuBar
          sortOptions={sortOptions}
          sortValue={sortValue}
          onSortChange={onSortChange}
          selectedView={selectedView}
          onViewChange={onViewChange}
          translations={translations}
          showDistance={showSortByDistance}
          filtersExpanded={filtersExpanded}
          showMileage={showSortByMileage}
          showAge={showSortByAge}
          fontFamily={fontFamily}
        />
        <VehicleResults
          results={results}
          onMoreInfoClick={onMoreInfoClick}
          translations={translations}
          onShowAllResultsClick={onShowAllResultsClick}
          onPageNumberChange={onPageNumberChange}
          selectedView={selectedView}
          filtersExpanded={filtersExpanded}
          compareVehicle={compareVehicle}
          shortlistVehicle={shortlistVehicle}
          getShortListedIconColour={getShortListedIconColour}
          getCompareIconColour={getCompareIconColour}
          globalStyling={globalStyling}
          tilesVisibility={tilesVisibility}
          locale={marketInfo.locale}
          vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
          headerItemFont={headerItemFont}
          showGridTiles={showGridTiles}
          badgeStyling={badgeStyling}
          financeHeadingFont={financeHeadingFont}
          infiniteScroll={infiniteScroll}
          loading={loading}
          currentPage={currentPage}
          pageSize={pageSize}
          totalResults={totalResults}
          showFinancePopup
        />
      </Fragment>
    )}
  </SpinnerWrapper>
);

export default function SearchResults(props: Props) {
  const {
    translations,
    filtersExpanded,
    preview,
    representativeExample,
    retailer,
    headingAlignment,
    tilesVisibility,
    headingFont,
    subHeadingFont,
    featureFlags,
    headingBottomLineDisable,
    subHeadingAlignment,
    totalResults,
  } = props;
  const singularOrPluralHeading = () =>
    totalResults !== 1
      ? translations.searchResultsAvailableAtLabel
      : translations.searchResultsAvailableAtSingleLabel;

  return (
    <Container data-qa-hook="search-results">
      <HeadingTwo
        data-qa-hook="header"
        styleOverride={() => `
            ${fontStyleOverride(headingFont)}
            margin-top: 34px;
            margin-bottom: 10px;
            text-align: ${headingAlignment || 'center'};
          `}
      >
        {translations.searchResultsHeader}
      </HeadingTwo>
      {totalResults && (
        <Paragraph
          data-qa-hook="sub-header"
          styleOverride={() => `
              ${fontStyleOverride(subHeadingFont)}
              margin-bottom: 35px;
              text-align: ${subHeadingAlignment ||
                (headingAlignment || 'center')};
            `}
          mobileStyleOverride={() => [
            {
              queryPath: 'max.small',
              template: 'margin-bottom: 0;',
            },
          ]}
        >
          {totalResults}
          {retailer
            ? ` ${singularOrPluralHeading()} (${retailer.name})`
            : ` ${singularOrPluralHeading()}`}
        </Paragraph>
      )}
      {!(headingBottomLineDisable === true) && <Line />}

      {featureFlags.finance && tilesVisibility.showFinanceExample && (
        <Fragment>
          <FinanceContainer
            component={componentProps => (
              <RepresentativeExample
                {...componentProps}
                representativeExample={representativeExample}
                preview={preview}
              />
            )}
          />
          <Line />
        </Fragment>
      )}
      <MobileOnly>
        <ResultsSection {...props} />
      </MobileOnly>
      <DesktopOnly>
        {filtersExpanded ? (
          <Grid>
            <FilterMenuBarSection {...props} />
            <div style={filtersExpanded && { width: 'calc(100% - 400px)' }}>
              <ResultsSection {...props} />
            </div>
          </Grid>
        ) : (
          <Grid>
            <FilterMenuBarSection {...props} />
            <ResultsSection {...props} />
          </Grid>
        )}
      </DesktopOnly>
    </Container>
  );
}

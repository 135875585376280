// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq, without } from 'ramda/src';

import templates from './Templates';
import { actions } from './reducer';
import { actions as filterActions, helpers } from '../../shared/filters';

type ModelSearchHook = {
  TileComponent: () => void,
  modelGroups: Object[],
  selectedModels: string[],
  selectedVariants: string[],
  onModelTileClick: (model: Object) => void,
  onVariantTileClick: (model: Object, variant: string) => void,
  loaded: boolean,
};

export function useModelSearch(template: string): ModelSearchHook {
  const TileComponent = templates[template];
  const dispatch = useDispatch();
  const queryParams = useSelector(state => state.router.queryParams);

  const { modelGroups, loaded } = useSelector(state => state.modelSearch);

  useEffect(() => {
    dispatch(actions.clearState());
    dispatch(filterActions.initialiseFilters(queryParams));
    dispatch(actions.getModelGroups());
    return () => {
      dispatch(actions.clearState());
    };
  }, []);

  const selectedModels =
    useSelector(
      state =>
        state.shared.filters.selectedFilters &&
        state.shared.filters.selectedFilters.model,
    ) || [];

  const selectedVariants =
    useSelector(
      state =>
        state.shared.filters.selectedFilters &&
        state.shared.filters.selectedFilters.modelVariants,
    ) || [];

  function onModelTileClick(model) {
    const modelFilterValue = helpers.newArray(
      selectedModels,
      model.modelGroupName,
    );
    const modelVariants = model.variants
      ? model.variants.map(v => v.value)
      : [];
    const variantFilterValue = modelFilterValue.includes(model.modelGroupName)
      ? uniq([...selectedVariants, ...modelVariants])
      : without(modelVariants, selectedVariants);

    dispatch(
      filterActions.updateFilters([
        { key: 'model', value: modelFilterValue },
        { key: 'modelVariants', value: variantFilterValue },
      ]),
    );
  }

  function onVariantTileClick(model, variant) {
    const variantFilterValue = helpers.newArray(selectedVariants, variant);
    let modelFilterValue = selectedModels;

    if (variantFilterValue.includes(variant)) {
      modelFilterValue = modelFilterValue.includes(model.modelGroupName)
        ? modelFilterValue
        : [...modelFilterValue, model.modelGroupName];
    } else {
      modelFilterValue = model.variants.some(v =>
        variantFilterValue.includes(v.value),
      )
        ? modelFilterValue
        : helpers.newArray(modelFilterValue, model.modelGroupName);
    }

    dispatch(
      filterActions.updateFilters([
        { key: 'modelVariants', value: variantFilterValue },
        { key: 'model', value: modelFilterValue },
      ]),
    );
  }

  return {
    TileComponent,
    modelGroups,
    selectedModels,
    selectedVariants,
    onModelTileClick,
    onVariantTileClick,
    loaded,
  };
}

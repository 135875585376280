// @flow
import auth0 from 'auth0-js';
import { put, call, select } from 'redux-saga/effects';

import { actions, constants } from '../actions/auth';
import { actions as routerActions } from '../actions/router';
import { TO } from '../../constants/routes';
import settings from '../../settings';
import watch from '../../helpers/watch';

const authClient = new auth0.WebAuth({
  domain: settings.domain,
  clientID: settings.clientId,
  redirectUri: settings.redirectUri,
  audience: settings.apiAudience,
  responseType: 'token id_token',
  scope: settings.authorisationScopes,
});

const authClientRedirect = () =>
  authClient.authorize({ brand: settings.brand });

function* authorize() {
  const authClientHandler = () =>
    new Promise((resolve, reject) => {
      authClient.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult.accessToken);
        } else if (err) {
          reject(err);
        }
      });
    });

  try {
    const result = yield call(authClientHandler);
    yield put(actions.loginSuccess(result));
    yield put(routerActions.navigate(TO.INDEX()));
  } catch (error) {
    yield put(actions.loginFailure(error));
  }
}

function* location() {
  const {
    location: { hash },
  } = yield select(state => state.router);
  if (hash.indexOf('#access_token', 0) === -1) {
    yield call(authClientRedirect);
  }
}

function* watchLogin() {
  yield call(location);
  yield call(authorize);
}

function* watchLogout() {
  yield put(routerActions.navigate(TO.INDEX()));
  yield put(actions.login());
}

export default [
  watch(constants.Login, watchLogin),
  watch(constants.Logout, watchLogout),
];

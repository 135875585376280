import Icon from './Icon';

export { id as reducerId } from './reducer';

export { default as component } from './RetailerLocationSearch';
export { default as effects } from './effects';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';
export { actions, constants, reducer, initialState } from './reducer';

export const name = 'RetailerLocationSearch';
export const id = 'RetailerLocationSearch';
export const availableToAdministration = false;
export const icon = Icon;

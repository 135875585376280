// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Waypoint from 'react-waypoint';
import querystring from 'querystring';

import media from '../../theme';
import FinanceContainer from '../../containers/FinanceContainer';
import FilterByCarContainer from '../../components/FilterByCarContainer';
import RepresentativeExample from '../../components/RepresentativeExample';
import { Button, HeadingTwo } from '../../components/Global';
import FooterActionBanner from '../../components/FooterActionBanner';
import MobileSearchPanel from '../../components/MobileSearchPanel';
import SearchFiltersContainer from '../../containers/SearchFiltersContainer';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { actions as routerActions } from '../../actions/router';
import type { Props } from './types';
import ContentWrapper from '../../components/ContentWrapper';
import SearchFilterStickyLocationSearchBar from '../../components/SearchFilterStickyLocationSearchBar';
import { distanceOptions } from '../../components/SearchFilters/search-filters-helper';

const namedDiv = displayName => styled.div.withConfig({ displayName });

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const LeasingContainer = namedDiv('LeasingContainer')`
  padding: 20px;
  ${media.min.medium`
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  `};
`;
const Container = namedDiv('Container')`
  padding: 0;
  display: flex;
  flex-direction: column;
  ${media.min.medium`
    padding: 40px;
    flex-direction: row;
  `};
`;

const RightColumn = namedDiv('RightColumn')`
  display: block;
  ${media.min.medium`
    width: calc(100% - 350px);
  `};
`;

const DesktopOnly = styled.div`
  display: block;
  ${media.max.large`
    display: none;
  `};
`;

const MobileOnly = namedDiv('MobileOnly')`
  display: flex;
  ${media.min.medium`
    display: none;
  `};
`;
const LeftColumn = namedDiv('LeftColumn')`
  display: none;
  ${media.min.medium`
    display: flex;
    flex-direction: column;
    max-width: 350px;
  `};
`;

const Line = styled.hr`
  display: none;
  ${media.min.medium`
    background-color: #dedede;
    border-bottom: none;
    width: 100%;
    display: block;
  `};
`;

type State = {
  showStickySearch: boolean,
};

export default class Search extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showStickySearch: true,
    };
  }

  componentDidMount = () => {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      actions: { init },
      history,
      dispatch,
    } = this.props;
    init();

    const queryParams = querystring.parse(history.location.search.substring(1));

    this.retailerId = queryParams.retailerId;

    if (queryParams.models) {
      const models =
        typeof queryParams.models === 'string'
          ? [queryParams.models]
          : [...queryParams.models];

      dispatch(
        searchFilterActions.selectMultipleCheckboxes({
          section: 'models',
          values: models,
        }),
      );
    }

    this.getModelGroupsAndInventory('');
  };

  componentDidUpdate(prevProps: Props) {
    const { config, shared } = this.props;

    if (!this.props.preview) {
      const { modelSearchUrl } = this.props.shared.searchFilter;
      if (
        prevProps.shared.searchFilter.modelSearchUrl !== modelSearchUrl ||
        shared.sessionPreferences.location !==
          prevProps.shared.sessionPreferences.location
      ) {
        const options = distanceOptions(config.translations);
        const defaultOption =
          (config.distanceFilterInitialSelectedIndex &&
            `MaxDistance=${
              options[config.distanceFilterInitialSelectedIndex].value
            }&`) ||
          '';

        const {
          checkboxUrl = '',
          rangeUrl = '',
          locationUrl = '',
          maxDistanceUrl = defaultOption,
        } = modelSearchUrl;

        this.getInventoryGroup(
          `${[checkboxUrl, rangeUrl, locationUrl, maxDistanceUrl]
            .filter(Boolean)
            .join('')}`,
        );
      }
    }
  }

  getInventoryGroup = (url: string) => {
    const { actions } = this.props;
    actions.getInventoryGroup({
      filters: url,
    });
  };

  getModelGroupsAndInventory = (url: string) => {
    const { actions } = this.props;
    actions.getModelGroupsAndInventory({
      filters: url,
    });
  };

  toggleModelFilter = (models: string) => {
    this.props.dispatch(
      searchFilterActions.toggleCheckboxFilter({
        section: 'models',
        filter: models,
      }),
    );
  };

  toggleStickySearchBar = ({ currentPosition }: string) => {
    if (currentPosition) {
      const showStickySearch = currentPosition === 'below';
      this.setState({ showStickySearch });
    }
  };

  resetFilters = () => this.props.dispatch(searchFilterActions.resetFilters());

  render() {
    const {
      shared,
      state: { searchConfig },
      dispatch,
      pathByModule,
      config,
      preview,
      marketInfo,
      globalStyling,
      featureFlags,
    } = this.props;

    const { showStickySearch } = this.state;

    let carCount = 0;

    const goToModule = (name: string) =>
      dispatch(routerActions.navigate(pathByModule(name)));
    const representativeExample = preview
      ? {}
      : shared.financeCalculator.representativeExample;

    const ResetBtn = () => (
      <Button
        onClick={this.resetFilters}
        text={config.translations.searchCTAReset}
        buttonStyle={
          globalStyling.uiElements.primaryButton &&
          globalStyling.uiElements.primaryButton.buttonStyle
        }
        applyStyle="secondary"
      />
    );
    const SearchBtn = () => (
      <Button
        onClick={() => goToModule('SearchResults')}
        text={config.translations.searchCTASearch}
        buttonStyle={
          globalStyling.uiElements.primaryButton &&
          globalStyling.uiElements.primaryButton.buttonStyle
        }
        applyStyle="primary"
      />
    );

    const selectedFilterModels =
      shared.searchFilter.selectedCheckboxFilters.models;

    if (searchConfig && searchConfig.inventoryGroup.length > 0) {
      carCount = searchConfig.inventoryGroup
        .filter(
          ({ modelDisplay }) =>
            selectedFilterModels.length === 0 ||
            selectedFilterModels.some(
              filterModel => filterModel === modelDisplay,
            ),
        )
        .reduce((acc, { count }) => acc + count, 0);
    }

    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <Wrapper>
          {preview ? null : (
            <MobileOnly>
              <MobileSearchPanel
                resetFilters={this.resetFilters}
                goBack={() => goToModule('Home')}
                config={{
                  ...marketInfo,
                  translations: config.translations,
                  stylingConfig: config.stylingConfig,
                  filterVisibility: config.filterVisibility,
                  distanceFilterInitialSelectedIndex:
                    config.distanceFilterInitialSelectedIndex,
                  dropdown: config.dropdown,
                  locationIconUrl: config.locationIconUrl,
                }}
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
                featureFlags={featureFlags}
              />
            </MobileOnly>
          )}
          <LeasingContainer>
            <HeadingTwo
              styleOverride={() => `
              text-align: center;
              margin-top: 34px;
              margin-bottom: ${config.titleBottomMargin || '10'}px;
              display: block;
              font-family: ${globalStyling &&
                globalStyling.fonts.primaryFont.value}
            `}
              mobileStyleOverride={() => [
                { queryPath: 'max.medium', template: 'display:none;' },
              ]}
            >
              {config.translations.searchHeader}
            </HeadingTwo>
            <Line />
            {featureFlags.finance && config.showFinanceExample && (
              <Fragment>
                <FinanceContainer
                  component={props => (
                    <RepresentativeExample
                      {...props}
                      representativeExample={representativeExample}
                      preview={preview}
                    />
                  )}
                />
                <Line />
              </Fragment>
            )}
          </LeasingContainer>
          <Container>
            {searchConfig && (
              <LeftColumn>
                {preview ? null : (
                  <SearchFiltersContainer
                    config={{
                      ...marketInfo,
                      dropdown: config.dropdown,
                      translations: config.translations,
                      stylingConfig: config.stylingConfig,
                      filterVisibility: config.filterVisibility,
                      locationIconUrl: config.locationIconUrl,
                      distanceFilterInitialSelectedIndex:
                        config.distanceFilterInitialSelectedIndex,
                      searchCountryCode: marketInfo.searchCountryCode,
                    }}
                    featureFlags={featureFlags}
                  />
                )}
                <Button
                  onClick={() => goToModule('SearchResults')}
                  buttonStyle={
                    globalStyling.uiElements.primaryButton &&
                    globalStyling.uiElements.primaryButton.buttonStyle
                  }
                  applyStyle="primary"
                  text={config.translations.searchCTASearch}
                  styleOverride={() => 'margin: 10px 0;'}
                />
                <Button
                  onClick={this.resetFilters}
                  buttonStyle={
                    globalStyling.uiElements.secondaryButton &&
                    globalStyling.uiElements.secondaryButton.buttonStyle
                  }
                  applyStyle="secondary"
                  text={config.translations.searchCTAReset}
                />
              </LeftColumn>
            )}
            {searchConfig && searchConfig.inventoryGroup && (
              <RightColumn>
                <FilterByCarContainer
                  cars={searchConfig.inventoryGroup}
                  itemClicked={this.toggleModelFilter}
                  selectedModels={
                    shared.searchFilter.selectedCheckboxFilters.models
                  }
                  translations={config.translations}
                  selectedIconColour={
                    config.carFilterSelectedIconColour &&
                    config.carFilterSelectedIconColour.value
                  }
                  locale={marketInfo.locale}
                  titleFont={config.modelTitleFont}
                  featureFlags={featureFlags}
                  globalStyling={globalStyling}
                />
              </RightColumn>
            )}
          </Container>
          <DesktopOnly>
            <SearchFilterStickyLocationSearchBar
              count={carCount}
              translations={config.translations}
              dropdownTheme={config.dropdown}
              display={showStickySearch}
              globalStyling={globalStyling}
              pathByModule={pathByModule}
              distanceFilterInitialSelectedIndex={
                config.distanceFilterInitialSelectedIndex
              }
              locationIconUrl={config.locationIconUrl}
              searchCountryCode={marketInfo.searchCountryCode}
            />
          </DesktopOnly>
          <Waypoint onPositionChange={this.toggleStickySearchBar} />
          <FooterActionBanner leftButton={ResetBtn} rightButton={SearchBtn} />
        </Wrapper>
      </ContentWrapper>
    );
  }
}

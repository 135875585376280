// @flow

import React from 'react';
import styled from 'styled-components';

import CheckboxUnchecked from './JaguarIcons/Global/CheckboxUnchecked';
import Tick from './JaguarIcons/Global/Tick';

type Props = {
  checked: boolean,
  onClick: () => void,
  onColour: string,
  checkboxDimension?: Number,
};

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  display: flex;
  cursor: pointer;
`;

const Check = ({
  checked,
  onColour,
  checkboxDimension,
}: {
  checked: boolean,
  onColour: string,
  checkboxDimension: Number,
}) =>
  checked ? (
    <Tick
      background={onColour}
      colour="#fff"
      width={`${checkboxDimension || 1.5}em`}
      height={`${checkboxDimension || 1.5}em`}
      fill="#fff"
    />
  ) : (
    <CheckboxUnchecked
      width={`${checkboxDimension || 1.5}em`}
      height={`${checkboxDimension || 1.5}em`}
    />
  );
Check.defaultProps = {
  onColour: '#000000',
};
export default function CheckBox({
  onClick,
  checked,
  onColour,
  checkboxDimension,
}: Props) {
  return (
    <Container onClick={onClick}>
      <Check
        checked={checked}
        onColour={onColour}
        checkboxDimension={checkboxDimension}
      />
    </Container>
  );
}

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Sites } from '../components';

import { actions as editorActions } from '../state/actions/editor';
import { actions as siteActions } from '../state/actions/site';
import { actions as routerActions } from '../state/actions/router';
import { actions as contextActions } from '../state/actions/context';
import type { Branding } from '../state/reducers/editor';
import type { Site } from '../state/reducers/site';

const mapStateToProps = ({ site, editor }) => ({
  sites: site.sites,
  branding: editor.branding,
});

const mapDispatchToProps = dispatch => ({
  navigate: route => dispatch(routerActions.navigate(route)),
  loadSites: () => dispatch(siteActions.loadSites()),
  loadBranding: () => dispatch(editorActions.loadBranding()),
  clearContext: () => dispatch(contextActions.clearContext()),
});

type Props = {
  sites: { [id: $PropertyType<Site, 'id'>]: Site },
  branding: Branding,
  navigate: Function,
  loadSites: Function,
  loadBranding: Function,
  onTabClick: (tab: string) => void,
};

class SiteListWithLifecycle extends Component<Props, *> {
  componentDidMount() {
    this.props.loadBranding();
    this.props.loadSites();
    this.props.clearContext();
  }

  render() {
    return <Sites {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteListWithLifecycle);

// @flow
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonWithConfirm } from 'cms-ui';
import { actions as pageActions } from '../state/actions/page';
import { actions as contextActions } from '../state/actions/context';
import { breakoutType } from './BreakoutWrapper';
import PodMenu from './PodMenu';

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const P = styled.p`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary05};
  text-align: center;
`;

const Em = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary03};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormLabel};
  font-weight: 600;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};
  display: block;
`;

const Btn = styled(Button)`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};
`;

const ButtonwWrapper = styled.div`
  padding: 0 10px;
`;

const renderModalContents = onClick => closeSelfFn => (
  <Center>
    <P>
      <Em>Are you sure you want to permanently delete this form?</Em>
      All the form content will be lost
    </P>
    <Btn small fullWidth danger textTransform="uppercase" onClick={onClick}>
      Yes, delete form
    </Btn>
    <Btn small fullWidth textTransform="uppercase" onClick={closeSelfFn}>
      Cancel
    </Btn>
  </Center>
);

const RenderDeleteFormButton = ({ onClick }) => (
  <ButtonWithConfirm
    buttonText="Delete Form"
    renderModalContents={closeSelfFn =>
      renderModalContents(onClick)(closeSelfFn)
    }
  />
);

const EditForm = () => {
  const dispatch = useDispatch();
  const { currentSiteId } = useSelector(state => state.site);
  const ids = useSelector(state => ({
    podInContextId: state.context.breakout.data.id,
    pageId: state.context.breakout.data.pageId,
  }));

  const { podInContextId, pageId } = ids;
  const formPod = useSelector(state => state.config.pods[podInContextId]);

  return formPod ? (
    <>
      <PodMenu
        name="Edit Form"
        onLeaveModuleOverride={() => {
          dispatch(contextActions.setGlobalContext(null));
          dispatch(
            contextActions.setBreakoutContext({
              name: breakoutType.listForms,
            }),
          );
        }}
      />
      <ButtonwWrapper>
        <RenderDeleteFormButton
          onClick={() => {
            dispatch(pageActions.deletePage(currentSiteId, pageId));
            dispatch(contextActions.setGlobalContext(''));
            dispatch(
              contextActions.setBreakoutContext({
                name: breakoutType.listForms,
              }),
            );
          }}
        />
      </ButtonwWrapper>
    </>
  ) : null;
};

export default EditForm;

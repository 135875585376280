// @flow
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { actions, constants, getToken } from './reducer';
import { post, get, remove, put as update } from '../../helpers/http';
import {
  avlCustomersServiceUrl,
  getAVLConfig,
  getLeadsUrl,
  getMarketLevelDealer,
} from '../selectors/settings';
import { getToken as getLeadsToken } from '../leadsAuthentication/reducer';
import { actions as shorlistActions } from '../shortlist';

const preferenceToBoolean = value =>
  typeof value === 'boolean' ? value : value === 'yes';
const preferenceToBinary = value => (value ? '1' : '0');

function* sendLead(customer) {
  try {
    const marketLevelDealerId = yield select(getMarketLevelDealer);
    const { make, market } = yield select(getAVLConfig);

    const leadPayload = {
      leadType: 'registration',
      vehicleId: '0',
      dealerId: marketLevelDealerId || '1',
      consents: {
        'distribution list consent': preferenceToBinary(
          customer.contactPreferences.distributionList,
        ),
        'Marketing consent': preferenceToBinary(
          customer.contactPreferences.marketing,
        ),
        ...(market.split(' ')[1] === 'italy' && {
          customisedServicesConsent: preferenceToBoolean(customisedServices),
        }),
      },
      customer: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        postCode: customer.postCode,
        title: customer.title,
      },
      additionalInfo: {
        make,
        currentVehicle:
          (customer.vehiclePreferences &&
            customer.vehiclePreferences.currentVehicle) ||
          '',
        vehicleOfInterest:
          (customer.vehiclePreferences &&
            customer.vehiclePreferences.interestedIn) ||
          '',
      },
    };

    const leadsToken = yield select(state => getLeadsToken(state.shared));
    const leadsUrl = yield select(getLeadsUrl);

    yield call(post, {
      url: leadsUrl,
      data: leadPayload,
      token: leadsToken,
    });
  } catch (error) {
    // noop
  }
}

function* createCustomerAccount(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer`;
    const { siteId } = yield select(getAVLConfig);
    yield call(post, {
      url,
      data: {
        ...payload,
        customer: {
          ...payload.customer,
          contactPreferences: {
            ...payload.customer.contactPreferences,
            marketing: preferenceToBoolean(
              payload.customer.contactPreferences.marketing,
            ),
            customisedServices: preferenceToBoolean(
              payload.customer.contactPreferences.customisedServices,
            ),
          },
        },
        siteId,
      },
    });
    yield put(actions.createCustomerAccountSuccess(page, instance, payload));
  } catch (error) {
    // https://github.com/axios/axios/issues/960 console logging the error is
    // parsed as the string representation
    if (error.status === 409) {
      yield put(actions.createCustomerAccountSuccess(page, instance, payload));
      return;
    }
    yield put(actions.createCustomerAccountFailure(page, instance));
  }
  yield call(sendLead, payload.customer);
}

function* getCustomerLogin(action) {
  const {
    payload: { credentials, redirect },
    module: { page, instance },
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}token`;
    const { siteId } = yield select(getAVLConfig);
    const response = yield call(post, {
      url,
      data: { username: credentials.user, password: credentials.pass, siteId },
    });
    yield put(
      actions.postLoginSuccess(page, instance, {
        auth: response.data,
        redirect,
      }),
    );
    yield put(shorlistActions.saveShorlistedVehicles());
  } catch (error) {
    const reason =
      error.status === 422 ? 'VALIDATION REQUIRED' : 'UNAUTHORISED';
    yield put(actions.postLoginFailure(page, instance, { reason }));
  }
}

function* getCustomerDetails(action) {
  const {
    module: { page, instance },
    payload: { redirect },
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer`;

    const token = yield select(state => getToken(state.shared));
    const response = yield call(get, {
      url,
      token,
    });
    yield put(
      actions.postGetCustomerSuccess(page, instance, {
        customerDetails: response.data,
        redirect,
      }),
    );
  } catch (error) {
    yield put(actions.postGetCustomerFailure(page, instance));
  }
}

function* saveUserDetails(action) {
  const {
    payload: { customerDetails },
    module: { page, instance },
  } = action;
  const transformedCustomer = {
    title: customerDetails.title,
    firstName: customerDetails.firstName,
    lastName: customerDetails.lastName,
    email: customerDetails.email,
    phoneNumber: customerDetails.phoneNumber,
    postCode: customerDetails.postCode,
    vehiclePreferences: {
      interestedIn: customerDetails.vehicleOfInterest,
      currentVehicle: customerDetails.currentVehicle,
    },
    contactPreferences: {
      email: customerDetails.emailConsent,
      phone: customerDetails.phoneConsent,
      sms: customerDetails.smsConsent,
    },
  };
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer`;

    const token = yield select(state => getToken(state.shared));
    const response = yield call(update, {
      url,
      data: transformedCustomer,
      token,
    });
    yield put(
      actions.postGetCustomerSuccess(page, instance, {
        customerDetails: response.data,
      }),
    );
    yield put(
      actions.saveUserDetailsSuccess(page, instance, {
        saveDetails: 'SUCCESS',
      }),
    );
  } catch (error) {
    yield put(
      actions.saveUserDetailsFailure(page, instance, { saveDetails: 'FAIL' }),
    );
  }
}

function* changePassword(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer/change-password`;

    const token = yield select(state => getToken(state.shared));
    yield call(post, {
      url,
      data: payload,
      token,
    });
    yield put(
      actions.changePasswordSuccess(page, instance, {
        changePassword: 'SUCCESS',
      }),
    );
  } catch (error) {
    yield put(
      actions.changePasswordFailure(page, instance, { changePassword: 'FAIL' }),
    );
  }
}

function* deleteAccount(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer`;

    const token = yield select(state => getToken(state.shared));
    yield call(remove, {
      url,
      data: payload,
      token,
    });
    yield put(actions.deleteAccountSuccess(page, instance));
  } catch (error) {
    yield put(
      actions.deleteAccountFailure(page, instance, { deleteAccount: 'FAIL' }),
    );
  }
}

function* refreshApp() {
  function reload() {
    window.location.reload();
  }
  yield call(reload);
}

export default function* customerLoginSaga() {
  yield takeEvery(constants.CREATE_ACCOUNT, createCustomerAccount);
  yield takeEvery(constants.LOGIN, getCustomerLogin);
  yield takeEvery(constants.LOGIN_SUCCESS, getCustomerDetails);
  yield takeEvery(constants.SAVE_USER_DETAILS, saveUserDetails);
  yield takeEvery(constants.CHANGE_PASSWORD, changePassword);
  yield takeEvery(constants.DELETE_ACCOUNT, deleteAccount);
  yield takeEvery(
    [constants.DELETE_ACCOUNT_SUCCESS, constants.SIGN_OUT],
    refreshApp,
  );
}

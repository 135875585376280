/* eslint-disable */
import { delay } from 'redux-saga';
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  inventorySearchServiceUrl,
  getModelGroupsBaseUrl,
  getAVLConfig,
  appendLocaleQueryString,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

let modelGroupsCache;
let groupsInventoryCache;

function* getModelGroups(action) {
  const {
    module: { page, instance },
  } = action;
  try {
    const { locale, make, country, market } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const inventoryUrl = `${yield select(
      inventorySearchServiceUrl,
    )}inventory/make/${make}/market/${market}/groups`;
    const modelGroupsUrl = `${yield select(getModelGroupsBaseUrl)}/${make.replace(
      ' ',
      '',
    )}/${country}/${locale}/model_explore`;

    const modelGroupsData = modelGroupsCache
      ? [yield { data: modelGroupsCache }]
      : [
          call(get, {
            url: modelGroupsUrl,
          }),
        ];

    const inventoryData = groupsInventoryCache
      ? [yield { data: groupsInventoryCache }]
      : [
          call(get, {
            url: inventoryUrl,
            ...(withLocaleQueryString && {
              config: localeConfigSerializer(locale),
            }),
          }),
        ];

    const [{ data: inventory }, { data: modelGroups }] = yield all([
      ...inventoryData,
      ...modelGroupsData,
    ]);

    if (!modelGroupsCache) {
      modelGroupsCache = modelGroups;
    }

    if (!groupsInventoryCache) {
      groupsInventoryCache = inventory;
    }

    const result = modelGroups
      .sort((a, b) => a.position - b.position)
      .map(({ modelGroupName, imageDomain, modelGroupDescription }) => {
        const {
          minimumPrice,
          count,
          apr,
          financePrice,
          modelDisplay,
          isModelAvailable = true,
        } = inventory.contents.find(
          ({ model }) =>
            model.replace(' ', '').toLowerCase() === modelGroupName.replace(' ', '').toLowerCase(),
        ) || { isModelAvailable: false };
        return {
          model: modelGroupName,
          priceFrom: minimumPrice,
          imageUrl: imageDomain,
          availability: count,
          description: modelGroupDescription,
          modelDisplay,
          apr,
          financePrice,
          isModelAvailable,
        };
      });

    yield put(actions.getModelGroupsSuccess(page, instance, { modelGroups: result }));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* RangeSaga() {
  yield takeEvery(constants.GET_MODEL_GROUPS, getModelGroups);
}

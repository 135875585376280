import React from 'react';
import styled from 'styled-components';
import { TextArea } from 'cms-ui';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 0px 10px;
`;
const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

const Email = props => {
  const { onChange, values } = props;
  const { email = {} } = values;
  const update = (key, value) => onChange('email', { ...email, [key]: value });
  return (
    <div style={{ padding: '10px 10px 0px 10px' }}>
      <FieldContainer>
        <TextLabel>Email</TextLabel>
        <TextArea
          type="text"
          value={email.fromAddress}
          onChange={v => update('fromAddress', v)}
        />
      </FieldContainer>
      <FieldContainer>
        <TextLabel>Delivery Url</TextLabel>
        <TextArea
          type="text"
          value={email.deliveryUrl}
          onChange={v => update('deliveryUrl', v)}
        />
      </FieldContainer>
      <FieldContainer>
        <TextLabel>Delivery Email</TextLabel>
        <TextArea
          type="text"
          value={email.deliveryEmail}
          onChange={v => update('deliveryEmail', v)}
        />
      </FieldContainer>
      <FieldContainer>
        <TextLabel>Subject</TextLabel>
        <TextArea
          type="text"
          value={email.subject}
          onChange={v => update('subject', v)}
        />
      </FieldContainer>
      <FieldContainer>
        <TextLabel>Body</TextLabel>
        <TextArea
          type="text"
          value={email.bodyText}
          onChange={v => update('bodyText', v)}
        />
      </FieldContainer>
    </div>
  );
};

export default Email;

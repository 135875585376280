// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import ArrowDown from '../../assets/ArrowDown';

const Container = styled.div`
  margin-right: 20px;
  position: relative;
  &:focus {
    outline: none;
  }
`;
const SelectorWrapper = styled.div`
  cursor: pointer;
  height: 40px;
  width: 180px;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ open }) => open && 'border-bottom: 1px solid #ffffff'};
`;
const Label = styled.div`
  width: 150px;
  height: 24px;
  border: none;
  font-size: 14px;
  color: #474747;
  line-height: 24px;
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
`;
const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  border: 1px solid #d6d6d6;
  width: 180px;
  padding: 0 10px;
  background-color: #ffffff;
`;
const NoOptionsWrapper = styled.div`
  padding: 10px;
`;
const NoOptionsTitle = styled.div`
  color: #474747;
  font-weight: bold;
  font-size: 14px;
`;
const NoOptionsText = styled.div`
  color: #474747;
  font-size: 12px;
`;

type Props = {
  qaHook?: string,
  options: Object[],
  selected: Object,
  forKey: string,
  onOptionClick: (key: string, value: Object) => void,
};
export default class Selector extends Component<Props, *> {
  static defaultProps = {
    qaHook: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      optionsVisible: false,
    };
  }

  onSelectClick = () => {
    this.setState(prevState => ({ optionsVisible: !prevState.optionsVisible }));
  };

  onOptionClick = (option: Object) => {
    this.setState(() => ({ optionsVisible: false }));
    this.props.onOptionClick(this.props.forKey, option);
  };

  render = () => {
    const { optionsVisible } = this.state;
    const { selected = { value: '', label: '' }, options, qaHook } = this.props;
    return (
      <Container
        tabIndex="0"
        onBlur={() => {
          this.setState({ optionsVisible: false });
        }}
      >
        <SelectorWrapper
          data-qa-hook={qaHook && `${qaHook}-selector-box`}
          onClick={this.onSelectClick}
          open={optionsVisible}
        >
          <Label data-qa-hook={qaHook && `${qaHook}-selector-value`}>
            {selected.label === '' ? 'Please select' : selected.label}
          </Label>
          <ArrowDown width="0.8em" height="0.8em" />
        </SelectorWrapper>
        {optionsVisible && (
          <OptionsWrapper>
            {options.length > 0 ? (
              options.map(option => (
                <Option
                  key={option.value}
                  data-qa-hook="selector-option"
                  onClick={() => this.onOptionClick(option)}
                >
                  <Label>{option.label}</Label>
                </Option>
              ))
            ) : (
              <NoOptionsWrapper>
                <NoOptionsTitle>No brand fonts</NoOptionsTitle>
                <NoOptionsText>
                  please add some first via the input above.
                </NoOptionsText>
              </NoOptionsWrapper>
            )}
          </OptionsWrapper>
        )}
      </Container>
    );
  };
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Wrapper, TextWrapper } from '../components';
import { HeadingOne, fontStyleOverride } from '../components/Global';
import * as formFields from '../components/formFields';
import media from '../../theme';
import { useForm } from './useForm';

const StyledTextWrapper = styled(TextWrapper)`
  width: 100%;
  box-sizing: border-box;
  ${media.min.medium`
    width: auto;
    margin: 0 auto;
  `};
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  ${media.min.medium`
    flex-direction: row;
    justify-content: center;
  `};
`;

export default ({
  config: {
    translations,
    fields,
    padding,
    border,
    headingFont,
    button,
    formURL,
    requireVehicle,
  },
  config,
  globalStyling: { uiElements = {} },
}) => {
  const {
    formState,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    submissionStatus,
    vehicle,
    vehicleDisplay,
  } = useForm(fields, translations, formURL, requireVehicle);

  const { FormButton } = formFields;

  return !vehicle ? null : (
    <Wrapper border={border}>
      <StyledTextWrapper padding={padding}>
        {submissionStatus === 'unsubmitted' && (
          <Fragment>
            <HeadingOne styleOverride={() => fontStyleOverride(headingFont)}>
              {vehicleDisplay}
            </HeadingOne>
            {Object.values(formState).map(field => {
              const FieldComponent = formFields[field.type];
              return (
                FieldComponent && (
                  <FormRow key={field.name}>
                    <FieldComponent
                      name={field.name}
                      id={field.id}
                      label={translations[`${field.id}Label`]}
                      placeholder={translations[`${field.id}Placeholder`]}
                      value={formState[field.name].value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors[field.name]}
                      required={!!field.validation}
                      isDisabled={field.isDisabled}
                      options={field.options}
                      uiElements={uiElements}
                      translations={translations}
                    />
                  </FormRow>
                )
              );
            })}
            {button && (
              <FormButton config={config} handleSubmit={handleSubmit} />
            )}
          </Fragment>
        )}
        {submissionStatus === 'isSubmitting' && <div>Working</div>}
        {submissionStatus === 'submitted' && <div>Done</div>}
      </StyledTextWrapper>
    </Wrapper>
  );
};

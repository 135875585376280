// @flow
import React from 'react';
import styled from 'styled-components';

export type Props = {
  font: string,
  foregroundColor: string,
  backgroundColor: string,
};

const Badge = styled.div`
  position: absolute;
  max-width: 100%;
  margin: 10px;
  height: 25px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 16px;
  color: ${({ foregroundColor }) => foregroundColor.value};
  background-color: ${({ backgroundColor }) => backgroundColor.value};
  font-family: ${({ font }) => font.value || 'inherit'};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
`;

const OnDemandBadge = (props: Props) => <Badge {...props}>{props.text}</Badge>;

export default OnDemandBadge;

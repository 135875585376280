import { useContext } from 'react';
import { useSelector } from 'react-redux';

import ComponentContext from './ComponentContext';

const errorPage = pagesState => pagesState['/$$ERROR$$'];
const notFoundPage = pagesState => pagesState['/404'];
const currentPage = (pagesState, path) => pagesState[path];

const useBody = path => {
  const [, split] = path.split('/');
  const pagePath = `/${split}`;
  const errors = useSelector(state => state.shared.errors.error);
  const page = useSelector(state =>
    errors
      ? errorPage(state.config.pages)
      : currentPage(state.config.pages, pagePath) ||
        notFoundPage(state.config.pages),
  );
  const bands = useSelector(state =>
    page.modules.map(m => state.config.bands[m]),
  );
  const { bands: bandComponents } = useContext(ComponentContext);

  return {
    page,
    bands,
    bandComponents,
  };
};

export default useBody;

import editor from './editor';

export default {
  id: 'Video',
  name: 'Video',
  description: 'Add video to the page',
  defaultSlice: 'Content',
  defaultConfig: {
    translations: {},
    media: {
      componentType: 'video',
      muted: false,
      autoplay: false,
    },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  swap: 'PreviewVideo',
  requirements: {
    columns: [1, 2],
  },
};

import { put, takeEvery, call, select } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import {
  actions as customerActions,
  constants as customerConstants,
} from '../../shared/CustomerLogin';
import { getToken } from '../../shared/CustomerLogin/reducer';
import { get, remove } from '../../helpers/http';
import {
  getAVLConfig,
  testDrivesServiceUrl,
  myDealsServiceUrl,
  getPlaceholderBrandImageUrl,
} from '../../shared/selectors/settings';
import { localiseNumber } from '../../shared/localisation/numbers';
import { fetchVehicleStatuses, mapStatus } from '../../shared/vehicleStatus';
import { printMyDeal as printFinanceDeal } from '../../shared/financeCalculator/effects';

function* saveUserDetails(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  yield put(
    customerActions.saveUserDetails(page, instance, {
      customerDetails: payload,
    }),
  );
}

function* changePassword(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  yield put(customerActions.changePassword(page, instance, payload));
}

function* deleteAccount(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  yield put(customerActions.deleteAccount(page, instance, payload));
}

function* setFeedbackState(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  yield put(actions.setFeedback(page, instance, payload));
}

function* signOut(action) {
  const {
    module: { page, instance },
  } = action;
  yield put(customerActions.signOut(page, instance));
}

function* loadTestDrives(action) {
  const {
    module: { page, instance },
  } = action;
  try {
    const token = yield select(state => getToken(state.shared));

    const baseUrl = yield select(testDrivesServiceUrl);
    const { make, market, locale } = yield select(getAVLConfig);
    const url = `${baseUrl}make/${make}/market/${market}/requests`;
    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);

    const response = yield call(get, { url, token });

    let testDrives = [
      ...response.data.contents.map(testDrive => ({
        ...testDrive,
        vehicle: {
          ...testDrive.vehicle,
          odometer: {
            ...testDrive.vehicle.odometer,
            display: `${localiseNumber(
              testDrive.vehicle.odometer.reading,
              locale,
            )} ${testDrive.vehicle.odometer.units}`,
          },
        },
        placeholdingImage,
      })),
    ];

    if (testDrives.length > 0) {
      const vehicleStatuses = yield fetchVehicleStatuses(
        testDrives.map(td => td.vehicle.vin),
      );
      testDrives = testDrives.map(t => ({
        ...t,
        vehicle: {
          ...t.vehicle,
          status: mapStatus(t.vehicle.vin, vehicleStatuses),
        },
      }));
    }

    yield put(actions.loadTestDrivesSuccess(page, instance, { testDrives }));
  } catch (error) {
    // no-op
  }
}

function* deleteTestDrive(action) {
  const {
    module: { page, instance },
    payload: { vin },
  } = action;
  const token = yield select(state => getToken(state.shared));
  try {
    const url = `${yield select(testDrivesServiceUrl)}requests/${vin}`;
    yield call(remove, { url, token });
    yield put(actions.deleteTestDriveSuccess(page, instance, vin));
  } catch (error) {
    yield put(
      actions.deleteTestDriveFailure(page, instance, {
        ...error,
        message: `Failed to delete test drive: ${error.message}`,
      }),
    );
  }
}

function* loadMyDeals(action) {
  const {
    module: { page, instance },
  } = action;
  try {
    const token = yield select(state => getToken(state.shared));
    const url = `${yield select(myDealsServiceUrl)}finance-deals/list`;
    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
    const response = yield call(get, { url, token });

    const { locale } = yield select(getAVLConfig);
    let deals = response.data.contents.map(
      ({ vehicle, finance, createdAt }) => ({
        vehicle: {
          ...vehicle,
          locale,
        },
        finance: { ...finance, createdAt },
        placeholdingImage,
      }),
    );

    if (deals.length > 0) {
      const vehicleStatuses = yield fetchVehicleStatuses(
        deals.map(d => d.vehicle.id),
      );
      deals = deals.map(d => ({
        ...d,
        vehicle: {
          ...d.vehicle,
          status: mapStatus(d.vehicle.id, vehicleStatuses),
        },
      }));
    }

    yield put(actions.loadMyDealsSuccess(page, instance, { myDeals: deals }));
  } catch (error) {
    // yield put(
    //   actions.loadMyDealsFailure(page, instance, {
    //     ...error,
    //     message: `Failed to load my deals : ${error.message}`,
    //   }),
    // );
  }
}

function* deleteMyDeal(action) {
  const {
    module: { page, instance },
    payload: { id },
  } = action;
  const token = yield select(state => getToken(state.shared));
  try {
    const url = `${yield select(myDealsServiceUrl)}finance-deals/${id}`;
    const response = yield call(remove, { url, token });
    yield put(actions.deleteMyDealSuccess(page, instance, id, response));
  } catch (error) {
    yield put(
      actions.deleteMyDealFailure(page, instance, {
        ...error,
        message: `Failed to delete my deal: ${error.message}`,
      }),
    );
  }
}

function* printMyDeal({ payload, module: { page, instance } }) {
  yield call(printFinanceDeal, payload);
  yield put(actions.printMyDealSuccess(page, instance, payload.vehicle));
}

export default function* MyAccountSaga() {
  yield takeEvery(constants.SAVE_USER_DETAILS, saveUserDetails);
  yield takeEvery(constants.LOAD_TEST_DRIVES, loadTestDrives);
  yield takeEvery(constants.DELETE_TEST_DRIVE, deleteTestDrive);
  yield takeEvery(constants.LOAD_MY_DEALS, loadMyDeals);
  yield takeEvery(constants.DELETE_MY_DEAL, deleteMyDeal);
  yield takeEvery(constants.PRINT_MY_DEAL, printMyDeal);
  yield takeEvery(constants.CHANGE_PASSWORD, changePassword);
  yield takeEvery(constants.DELETE_ACCOUNT, deleteAccount);
  yield takeEvery(constants.SIGN_OUT, signOut);
  yield takeEvery(
    [
      customerConstants.CHANGE_PASSWORD_SUCCESS,
      customerConstants.CHANGE_PASSWORD_FAILURE,
      customerConstants.SAVE_USER_DETAILS_SUCCESS,
      customerConstants.SAVE_USER_DETAILS_FAILURE,
      customerConstants.DELETE_ACCOUNT_FAILURE,
    ],
    setFeedbackState,
  );
}

// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import styled from 'styled-components';

import Chunky from './JaguarIcons/Arrows/Chunky';
import LocationInput from './LocationInput';
import Icon from './StyledIcon';
import { google } from '../utilities/googleObject';

const SearcherContainer = styled.div`
  display: flex;
  height: 40px;
  max-height: 40px;
`;

const LocationInputContainer = styled.div`
  width: 90%;
  display: flex;
`;

const Link = styled.a`
  color: #ffffff;
  text-decoration: none;
  background-color: ${({
    backgroundColour,
    disabledBackgroundColour,
    disabled,
  }: {
    backgroundColour: string,
  }) => (disabled ? disabledBackgroundColour : backgroundColour) || '#a01e35'};
  ${({ width }) => (width ? `width: ${width}px` : 'width: 10%')};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px`};
  ${({ opacity }) => opacity && `opacity: ${opacity}`};
  ${({ disabled, disabledFontColour }) =>
    disabled && disabledFontColour && `color: ${disabledFontColour.value}`};
  &:hover {
    opacity: 1;
  }
  ${({ globalStyling }) =>
    globalStyling.direction === 'rtl' && 'transform: scaleX(-1);'};
`;

type Destination = {
  coordinates: [number, number],
};

type Props = {
  onGetCurrentPosition: (position: Position) => void,
  placeholder: string,
  destination: Destination,
  buttonBGColour: string,
  iconColour: string,
  retailerLocationCountry: string,
  locationFilterLabel: string,
  opacityDirectionButton: number,
  locationButtonWidth: string,
  arrowRightIcon: string,
  directionButtonText: string,
  searchCountryCode: string,
  borderRadiusButton: number,
  globalStyling: Object,
  resetDistanceFromRetailer: () => void,
};

type State = {
  directionValue: string,
  userLat?: number,
  userLong?: number,
};

export default class DirectionSearcher extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      directionValue: '',
      userLat: undefined,
      userLong: undefined,
    };
  }

  componentDidMount = () => {
    this.geocoder = new google.maps.Geocoder();
  };

  onGetCurrentPosition = (pos: Position) => {
    const { placeholder } = this.props;
    this.setState({
      directionValue: placeholder,
      userLat: pos.coords.latitude,
      userLong: pos.coords.longitude,
    });
    this.parseCoordinates(pos.coords.latitude, pos.coords.longitude);
  };

  getCoordinates = () => {
    if (!('geolocation' in navigator)) {
      return;
    }
    navigator.geolocation.getCurrentPosition(res => {
      const { latitude, longitude } = res.coords;
      this.parseCoordinates(latitude, longitude);
    });
  };

  parseCoordinates = (latitude, longitude) => {
    if (latitude && longitude) {
      this.geocoder.geocode(
        {
          location: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            const address =
              results.find(res => res.types.find(t => t === 'locality')) ||
              results.find(res => res.types.find(t => t === 'postal_code')) ||
              results.find(res => res.types.find(t => t === 'route'));
            if (address) {
              this.setState(() => ({
                directionValue: address.formatted_address,
              }));
              this.handleLocationChange(address.formatted_address);
            }
          }
        },
      );
    } else {
      this.setState(() => ({ directionValue: '' }));
      this.handleLocationChange('');
    }
  };

  handleLocationChange = (locationAddress: string) => {
    const { onGetCurrentPosition } = this.props;
    this.geocoder.geocode({ address: locationAddress }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const { location } = results[0].geometry;
        const lat = location.lat();
        const long = location.lng();
        onGetCurrentPosition({ coords: { latitude: lat, longitude: long } });
      }
      this.setState(() => ({ directionValue: locationAddress }));
    });
  };

  constructUrl = (destination: Destination) => {
    const [lat, long] = destination.coordinates;
    const { userLat, userLong, directionValue } = this.state;
    const dest = `${lat},${long}`;
    let origin = '';

    const MAPS_DIR_URL = (ori, des) =>
      `https://www.google.com/maps/dir/?api=1&origin=${ori}&destination=${des}`;

    // they click the button without having done anything else
    if (
      userLat === undefined &&
      userLong === undefined &&
      directionValue === ''
    ) {
      return '';
      // they type a thing without location present.
    }
    if (
      directionValue !== '' &&
      userLat === undefined &&
      userLong === undefined
    ) {
      origin = `${directionValue.replace(' ', '+')}+${this.props
        .retailerLocationCountry || ''}`;
      return `${MAPS_DIR_URL(origin, dest)}`;
    }
    // otherwise we have location, go ahead and use coords
    // need the && here as flow moans about coercing undefined to string.
    // the || is to prevent MAP_DIR_URL param "ori" from ever being underfined
    // and therefore cannot be coerced.
    origin = (userLat && userLong && `${userLat},${userLong}`) || '';
    return `${MAPS_DIR_URL(origin, dest)}`;
  };

  handleAddressChange = (locationAddress: string) => {
    this.setState({
      directionValue: locationAddress,
      userLat: undefined,
      userLong: undefined,
    });
  };

  render() {
    const {
      placeholder,
      destination,
      buttonBGColour,
      iconColour,
      opacityDirectionButton,
      locationButtonWidth,
      arrowRightIcon,
      directionButtonText,
      borderRadiusButton,
      globalStyling,
      searchCountryCode,
    } = this.props;

    const { directionValue } = this.state;
    const searchDisabled = !directionValue || !directionValue.trim();

    return (
      <SearcherContainer>
        <LocationInputContainer>
          <LocationInput
            placeholder={placeholder}
            locationValue={directionValue}
            handleChange={value => this.handleAddressChange(value)}
            onGetCurrentPosition={(pos: Position) =>
              this.onGetCurrentPosition(pos)
            }
            locationIconColour={iconColour}
            handleBlur={this.handleLocationChange}
            searchCountryCode={searchCountryCode}
            globalStyling={globalStyling}
            ref={input => {
              this.input = input;
            }}
          />
        </LocationInputContainer>
        <Link
          width={locationButtonWidth}
          data-qa-hook="location-search-button"
          backgroundColour={buttonBGColour}
          disabledBackgroundColour={
            globalStyling.colours.disabledBackgroundColour &&
            globalStyling.colours.disabledBackgroundColour.value
          }
          disabledFontColour={
            globalStyling.colours.disabledForegroundColour &&
            globalStyling.colours.disabledForegroundColour.value
          }
          borderRadius={borderRadiusButton}
          href={!searchDisabled ? this.constructUrl(destination) : undefined}
          target="_blank"
          rel="noopener noreferrer"
          opacity={opacityDirectionButton}
          disabled={searchDisabled}
          globalStyling={globalStyling}
        >
          {arrowRightIcon ? (
            <Icon link={arrowRightIcon} width={20} height={20} />
          ) : (
            directionButtonText || <Chunky colour="#fff" />
          )}
        </Link>
      </SearcherContainer>
    );
  }
}

// @flow
import { eventChannel, END } from 'redux-saga';
import { put, call, take } from 'redux-saga/effects';
import AWS from 'aws-sdk';
import uuid from 'uuid/v4';

import { actions, constants } from '../actions/editor';
import type { SaveBrandingAction } from '../actions/editor';
import { get, post, put as save, remove } from '../../helpers/http';
import watch from '../../helpers/watch';
import httpError from '../../helpers/error';
import { API_BASE_URL_BRANDING, MEDIA_SERVICE_URL } from '../../constants/apis';
import { getFontName } from '../../helpers/fontParser';
import settings from '../../settings';
import imageParser from '../../helpers/imageParser';

// Initialize the Amazon Cognito credentials provider
AWS.config.region = settings.awsRegion;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: settings.fontUploadIdentity,
});

type EntityResponse<T> = { data: T };

type BrandingResponse = {
  brandName: string,
  globalStyling: Object,
};

const logoKeyLookup = {
  brandHeaderLogo: 'header',
  brandFooterLogo: 'footer',
};

const transform = ({
  brandName,
  globalStyling: { logos, fonts, colours, uiElements },
}: BrandingResponse) => ({
  name: brandName,
  logos: {
    ...logos,
  },
  fonts: {
    availableFonts: [...(fonts ? fonts.availableFonts : [])],
    ...fonts,
  },
  colours: {
    availableColours: [...(colours ? colours.availableColours : [])],
    ...colours,
  },
  uiElements: {
    primaryButton: { ...(uiElements ? uiElements.primaryButton : {}) },
    secondaryButton: { ...(uiElements ? uiElements.secondaryButton : {}) },
    tertiaryButton: { ...(uiElements ? uiElements.tertiaryButton : {}) },
  },
});

function* uploadLogo({ resourceId, token, src }) {
  const response = yield call(post, {
    url: MEDIA_SERVICE_URL,
    data: {
      image: src,
      folder: settings.product,
      resourceId,
    },
    token,
  });
  return response.data.result.secure_url;
}

function* deleteLogo({ resourceId, token }) {
  const mediaPublicId = `${settings.product}/${resourceId}`;
  const url = `${MEDIA_SERVICE_URL}/image/${encodeURIComponent(
    mediaPublicId,
  )}?destroy=true`;
  yield call(remove, { url, token });
  return '';
}

function* getLogoUrl({ token, action, src, resourceId }) {
  let response;
  switch (action) {
    case 'Upload': {
      response = yield uploadLogo({ token, src, resourceId });
      return response;
    }
    case 'Delete': {
      response = yield deleteLogo({ token, resourceId });
      return response;
    }
    default: {
      return src;
    }
  }
}

export function* loadBranding(token: string): Generator<*, *, *> {
  try {
    const response: EntityResponse<BrandingResponse> = yield call(get, {
      url: API_BASE_URL_BRANDING,
      token,
    });
    yield put(actions.loadBrandingSuccess(transform(response.data)));
  } catch (error) {
    yield put(
      actions.loadBrandingFailure(
        httpError(error, 'Failed to load branding information'),
      ),
    );
  }
}

export function* saveBranding(
  token: string,
  action: SaveBrandingAction,
): Generator<*, *, *> {
  const {
    logos: { brandHeaderLogo, brandFooterLogo },
    branding,
  } = action.payload;
  try {
    const brandHeaderLogoUrl = yield getLogoUrl({
      action: brandHeaderLogo.action,
      token,
      src: brandHeaderLogo.src,
      resourceId: settings.getBrandLogoResourceId(
        logoKeyLookup.brandHeaderLogo,
      ),
    });

    const brandFooterLogoUrl = yield getLogoUrl({
      action: brandFooterLogo.action,
      token,
      src: brandFooterLogo.src,
      resourceId: settings.getBrandLogoResourceId(
        logoKeyLookup.brandFooterLogo,
      ),
    });

    const response: EntityResponse<BrandingResponse> = yield call(save, {
      url: API_BASE_URL_BRANDING,
      data: {
        globalStyling: {
          fonts: { ...branding.fonts },
          colours: { ...branding.colours },
          logos: {
            brandHeaderLogo: brandHeaderLogoUrl,
            brandFooterLogo: brandFooterLogoUrl,
          },
          uiElements: { ...branding.uiElements },
        },
      },
      token,
    });
    yield put(actions.saveBrandingSuccess(transform(response.data)));
    const linkTag = document.getElementById('fontsLink');
    const newLink = linkTag;
    if (linkTag) {
      document.head.removeChild(linkTag);
      document.head.appendChild(newLink);
    }
  } catch (error) {
    yield put(
      actions.saveBrandingFailure(
        httpError(error, 'Failed to load branding information'),
      ),
    );
  }
}

const uploadFile = (file, name) =>
  eventChannel(emit => {
    const id = uuid();
    const ext = file.name.split('.').pop();
    const s3 = new AWS.S3();
    const uploadParams = {
      Bucket: settings.fontUploadBucket,
      ContentType: file.type,
      Body: file,
      Key: `${id}.${ext}`,
    };
    const upload = s3.upload(uploadParams);
    let completed = false;
    upload.on('httpUploadProgress', progress => {
      const action = actions.fontUploadProgress(progress);
      emit(action);
    });
    upload.send(error => {
      completed = true;
      if (error) {
        emit(actions.fontUploadError(error));
      } else {
        emit(actions.fontUploadComplete(name, id, ext));
      }
      emit(END);
    });
    return () => !completed && upload.abort();
  });

function* uploadFont({ payload }) {
  const file = payload;
  // Extract metadata
  const name = yield call(getFontName, file);
  // Then upload the file itself
  const uploadChannel = yield call(uploadFile, file, name);

  let complete = false;
  while (!complete) {
    const action = yield take(uploadChannel);
    yield put(action);

    complete =
      action.type === constants.FontUploadComplete ||
      action.type === constants.FontUploadError;
  }

  uploadChannel.close();
}

function* parseLogo({ payload }) {
  const { image, forKey } = payload;
  try {
    const imageUrl = yield call(imageParser, image);
    yield put(actions.parseBrandLogoSuccess(imageUrl, forKey));
  } catch (error) {
    yield put(actions.parseBrandLogoFailure(error.message));
  }
}

export default [
  watch(constants.LoadBranding, loadBranding, true),
  watch(constants.AddBrandFont, uploadFont),
  watch(constants.ParseBrandLogo, parseLogo),
  watch(constants.SaveBranding, saveBranding, true),
];

// @flow
import React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  ${({ height }) => height && `height: ${height}px`};
  ${({ width }) => width && `width: ${width}px`};
`;

type Props = {
  link: string,
  alt?: string,
  width?: number,
  height?: number,
};

export default function StyledIcon({ link, width, height, alt }: Props) {
  return <Icon src={link} alt={alt} width={width} height={height} />;
}

StyledIcon.defaultProps = {
  alt: 'icon',
  width: 24,
  height: 24,
};

// @flow
import React, { Component } from 'react';

import { actions as routerActions } from '../../actions/router';
import CompareVehicles from './CompareVehicles';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortListActions } from '../../shared/shortlist';
import type { Font } from '../types';
import { config as previewConfig } from './preview.config';
import ContentWrapper from '../../components/ContentWrapper';
import { vanityUrlFormatter } from '../../helpers/vehicle';

export interface Config {
  translations: Object;
  subNavBGColour?: string;
  subNavFont?: Font;
  subNavBorderColour?: Object;
  subNavChevronColour?: Object;
  closeIconColour: Object;
  phoneIconColour: Object;
  gridConfiguration: Object;
  shortlistIconListedColour: Object;
  optionalShortlistIconListedColour: Object;
  headerFont: Font;
  infoFont: Font;
  primaryBrandFontColourOnIconButtons: boolean;
  contentWidth: number;
  vdpUrlMetaInfo: Object;
}

type Props = {
  dispatch: Function,
  marketInfo: Object,
  config: Config,
  actions: {
    toggleOptionalExtras: Function,
    init: Function,
  },
  pathByModule: string => any,
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  globalStyling: Object,
};

export default class CompareGrid extends Component<Props, *> {
  componentDidMount() {
    // We do this to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      actions: { init },
      dispatch,
      shared,
    } = this.props;
    const currentLanguage = shared.sessionPreferences.language;
    const selectedLanguageToLocale = (language: string) =>
      language ? language.replace('_', '-') : '';

    init();
    // load language from session storage and convert into locale to send to api
    dispatch(compareActions.getEquipmentList());
    dispatch(
      compareActions.checkVehicleStatus(
        selectedLanguageToLocale(currentLanguage),
      ),
    );
    dispatch(shortListActions.getVehicles());
    if (shared.sessionPreferences.currency) {
      dispatch(
        compareActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { shared, dispatch } = this.props;
    if (
      prevProps.shared.currencyConversion.exchangeRates !==
      shared.currencyConversion.exchangeRates
    ) {
      dispatch(
        compareActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      dispatch(compareActions.checkVehicleStatus());
      dispatch(compareActions.getEquipmentList());
    }
  }

  render = () => {
    const {
      dispatch,
      state: { optionalExtras },
      actions: { toggleOptionalExtras },
      preview,
      config,
      shared,
      globalStyling,
      marketInfo,
    } = this.props;
    const comparedVehicles = preview ? previewConfig : shared.compare.vehicles;

    const onMoreInfoClick = (name, vehicle) => {
      const vehicleMetaInfo = vanityUrlFormatter(
        config,
        vehicle,
        shared.sessionPreferences.language,
      );
      dispatch(routerActions.navigate(`/vdp/${vehicle.id}-${vehicleMetaInfo}`));
    };

    const sharedShortListedVehicles = preview
      ? previewConfig
      : shared.shortlist.vehicles;
    const getShortListedIconColour = (id: string) => {
      const isShortListed = sharedShortListedVehicles.find(
        shortlistedVehicle => shortlistedVehicle.id === (id || {}),
      );
      return isShortListed
        ? config.shortlistIconListedColour &&
            config.shortlistIconListedColour.value
        : (config.optionalShortlistIconListedColour &&
            config.optionalShortlistIconListedColour.value) ||
            '#ACACAC';
    };

    /*
    shared.compare always exists and there is always a vehicles array
    because its in persisted state so we need a default value of [].
    */
    const { equipmentList = [] } = shared.compare;
    const equipmentMetaData = equipmentList.sort((a, b) => a.localeCompare(b));

    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <CompareVehicles
          gridConfiguration={config.gridConfiguration}
          currentLanguage={marketInfo.locale}
          requestInformation={vehicle =>
            dispatch(
              routerActions.navigate(
                `${config.ctaPath || '/enquiry'}/${vehicle.id}`,
              ),
            )
          }
          removeVehicle={vehicle =>
            dispatch(compareActions.toggleVehicle(vehicle))
          }
          shortlistVehicle={vehicle =>
            dispatch(shortListActions.toggleVehicle(vehicle))
          }
          comparedVehicles={comparedVehicles}
          optionalExtras={optionalExtras}
          equipmentMetaData={equipmentMetaData}
          toggleOptionalExtras={toggleOptionalExtras}
          getShortListedIconColour={getShortListedIconColour}
          globalStyling={globalStyling}
          config={config}
          buttonSize={config.buttonSize}
          optionalCloseIcon={config.optionalCloseIcon}
          onMoreInfoClick={onMoreInfoClick}
          ctaToVdp={config.ctaToVdp}
        />
      </ContentWrapper>
    );
  };
}

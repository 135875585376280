import { config } from 'cms-modules';

const { Align, Colour, Config, Font, Slice, Section, TextArea } = config;

const editor = Config(
  'Heading',
  { id: 'Heading' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section('Layout', {}, Align('Alignment', 'alignment')),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      TextArea('Heading', 'headingText', { rows: 3 }),
      TextArea('Sub Heading', 'bodyText', { rows: 3 }),
    ),
  ),
  Slice(
    'Styles',
    { id: 'styles' },
    Section(
      'Styles',
      {},
      Colour('Background Colour', 'backgroundColour'),
      Font('Heading font', 'headingFont'),
      Font('Sub heading font', 'bodyFont'),
    ),
  ),
);

export default editor;

// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as enquiryActions } from '../../actions/enquiry';
import { getLocale } from '../../shared/selectors/settings';
import { urlTemplateFormatter } from '../../helpers/vehicle';

import type { IEnquiryRedirectConfig } from './types';

type Props = {
  config: IEnquiryRedirectConfig,
};

export default ({ config: { redirect, properties, template } }: Props) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(state => state.vehicleInContext);
  const locale = useSelector(getLocale);
  const path = useSelector(state => state.router.path);
  const [vehicleId] = path.split('/').reverse();

  useEffect(() => {
    dispatch(enquiryActions.getEnquiryVehicleInfo(vehicleId));
  }, []);

  useEffect(() => {
    if (vehicle && properties) {
      const formattedArgs = urlTemplateFormatter(
        properties,
        vehicle,
        locale,
        template,
      );
      window.open(`${redirect}/${formattedArgs}`, '_blank');
      window.history.go(-1);
      return () => {
        dispatch(enquiryActions.clearEnquiryVehicleInfo());
      };
    }
  }, [vehicle]);

  return null;
};

// @flow
import React from 'react';
import styled from 'styled-components';

const ControlsWrapper = styled.div`
  height: 100px;
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 20px;
  background: #ffffff;
`;

const ImageViewToggle = styled.button`
  width: 25px;
  height: 25px;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const ImageViewIcon = styled.img`
  width: 100%;
`;

type Props = {
  imagesIcon: string,
  toggleImageView: () => void,
};

export default ({ imagesIcon, toggleImageView }: Props) => {
  return (
    <ControlsWrapper>
      <ImageViewToggle onClick={toggleImageView}>
        <ImageViewIcon src={imagesIcon} />
      </ImageViewToggle>
    </ControlsWrapper>
  );
};

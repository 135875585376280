// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import type { Option } from '../../types';
import ThinUp from '../../components/JaguarIcons/Arrows/ThinUp';
import { Button } from '../../components/Global';
import media from '../../theme';

const ButtonWrapper = styled.div`
  display: none;
  ${media.min.medium`
        display: flex;
    `};
`;

export interface Config {
  active: boolean;
  buttonBackgroundColour: Option;
  buttonIconColour: Option;
}

type Props = {
  config: Config,
  preview: boolean,
};

export default class BackToTop extends Component<Props, *> {
  state = {
    offset: 300,
    show: this.props.preview,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.scrollEventListener);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollEventListener);
  };

  backToTop = () => {
    if (!this.props.preview) {
      this.setState(() => ({ show: false }));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  scrollEventListener = () => {
    if (
      (window.scrollY || document.documentElement.scrollTop) > this.state.offset
    ) {
      if (!this.state.show) {
        this.setState(() => ({ show: true }));
      }
    } else if (this.state.show) {
      this.setState(() => ({ show: false }));
    }
  };

  render() {
    const { config, globalStyling } = this.props;
    const styleInjection = () => `
      ${this.state.show ? 'display: flex' : 'display: none'};
      ${config.buttonBackgroundColour &&
        `background-color: ${config.buttonBackgroundColour.value}`};
      font-family: unset;
      position: fixed;
      bottom: 50px;
      ${globalStyling.direction === 'rtl' ? 'left: 5%;' : 'right: 5%;'};
      border: none;
      outline: none;
      cursor: pointer;
      height: 50px;
      width: 50px;
      z-index: 98;
    `;

    return config.active ? (
      <ButtonWrapper>
        <Button
          buttonStyle="ButtonStyleBTT"
          applyStyle="primary"
          styleOverride={styleInjection}
          mobileStyleOverride={styleInjection}
          onClick={this.backToTop}
          text={
            <ThinUp
              colour={config.buttonIconColour && config.buttonIconColour.value}
              direction={globalStyling.direction}
            />
          }
        />
      </ButtonWrapper>
    ) : null;
  }
}

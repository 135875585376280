import { schema } from 'normalizr';

const podEntity = new schema.Entity(
  'pods',
  {},
  {
    idAttribute: 'instanceId',
  },
);
const moduleEntity = new schema.Entity(
  'bands',
  {
    config: {
      pods: [podEntity],
    },
  },
  {
    idAttribute: 'instanceId',
  },
);

const pageEntity = new schema.Entity(
  'pages',
  {
    modules: [moduleEntity],
  },
  {
    idAttribute: 'relativePath',
  },
);
const pageArray = [pageEntity];
const globalArray = [moduleEntity];

export { pageEntity, pageArray, globalArray };

// @flow
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Wrapper, Overlay, Image, YouTube, Video } from '../components';
import type { Font, Padding, ButtonType } from '../../types';
import { useWindowDimensions } from '../../shared/WindowDimensionsProvider';
import { screenSizeOptions } from '../../theme';

import { useTimeout } from './useTimeout';

interface IHeroCarouselConfig {
  items: {
    id: string,
    media: {
      src: string,
      componentType: string,
      autoplay: boolean,
      muted: boolean,
    },
    translations: {
      bodyText: string,
      headlineText: string,
      buttonText: string,
    },
    button: string,
  };
  headlineFont: Font;
  bodyFont: Font;
  button: ButtonType;
  padding: Padding;
  isLoop: boolean;
  transitionTime: number;
  horizontalAlignment: string;
  verticalAlignment: string;
  height: number;
}

type Props = {
  config: IHeroCarouselConfig,
};

const Slider = styled.div`
  position: relative;
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 2.5s;

  ${({ active }) =>
    active &&
    `opacity: 1;
    transition-duration: 2.5s;
  `}
`;

const Controls = styled.div`
  min-width: 200px;
  height: 75px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 699;
`;

const ControlBtn = styled.div`
  height: 20px;
  width: 20px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.4;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `opacity: 0.8;
    transition-duration: 2.5s;
  `}
`;

const SliderWrapper = ({
  config,
  globalStyling: { uiElements },
  dispatch,
}): Props => {
  const { items, transitionTime, height, ...overlayConfig } = config;
  const [currentIndex, setCurrentIndex] = useState(0);

  const { headlineText, bodyText, button: buttonText } = items[
    currentIndex
  ].translations;
  const { button, horizontalAlignment, verticalAlignment } = items[
    currentIndex
  ];
  const { length } = items;

  const currentIndexRef = useRef(currentIndex);

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  const goToNext = () => {
    const nextIndex =
      currentIndexRef.current === length - 1 ? 0 : currentIndexRef.current + 1;

    setCurrentIndex(nextIndex);
  };

  const {
    media: { componentType: type },
  } = items[currentIndex];

  useTimeout(goToNext, type === 'image' ? transitionTime * 1000 : null);

  const { width } = useWindowDimensions();

  return (
    <Slider height={height}>
      {items.map(
        ({ media: { componentType, src, autoplay, muted }, id }, i) => {
          const isCurrent = i === currentIndex;
          const Component = {
            youtube: YouTube,
            image: Image,
            video: Video,
          }[componentType];
          return (
            <Slide active={isCurrent} key={id} aria-hidden={!isCurrent}>
              <Component
                src={src}
                index={i}
                isCurrent={isCurrent}
                imageHeight={height}
                handleComplete={goToNext}
                autoplay={autoplay && width > screenSizeOptions.medium}
                muted={muted}
              />
            </Slide>
          );
        },
      )}

      <Controls>
        {items.map((slide, i) => (
          <ControlBtn
            key={slide.id}
            active={currentIndex === i}
            onClick={() => {
              setCurrentIndex(i);
            }}
          />
        ))}
      </Controls>

      <Overlay
        {...{
          ...overlayConfig,
          headlineText,
          bodyText,
          buttonText,
          button,
          uiElements,
          dispatch,
          horizontalAlignment,
          verticalAlignment,
        }}
      />
    </Slider>
  );
};

export default props => (
  <Wrapper>
    <SliderWrapper {...props} />
  </Wrapper>
);

export { default as component } from './Footer';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Footer';
export const id = 'Footer';
export const availableToAdministration = false;
export const global = true;
export const required = true;
export const position = 4;

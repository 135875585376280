// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Close({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 27 25">
        <path d="M4.244 21.756a.831.831 0 0 0 1.179 0L14 13.178l8.577 8.578a.831.831 0 0 0 1.179 0 .832.832 0 0 0 0-1.179L15.178 12l8.578-8.577a.832.832 0 1 0-1.179-1.179L14 10.822 5.423 2.244a.832.832 0 1 0-1.179 1.179L12.822 12l-8.578 8.577a.832.832 0 0 0 0 1.179" />
      </svg>
    </IconWrapper>
  );
}

Close.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

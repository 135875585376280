// @flow
import React from 'react';

import { Wrapper, Overlay, YouTube, Video } from '../components';
import type { Font, Padding } from '../../types';

interface IVideoConfig {
  media: Object;
  headlineFont: Font;
  bodyFont: Font;
  backgroundColour: string;
  opacity: number;
  padding: Padding;
  translations: {
    bodyText: string,
    headlineText: string,
  };
}

type Props = {
  config: IVideoConfig,
};

export default ({
  config: {
    media,
    translations: { headlineText, bodyText },
    ...overlayConfig
  },
  globalStyling: { uiElements = {} } = {},
  dispatch,
  handleComplete,
}): Props => {
  const Component = {
    youtube: YouTube,
    video: Video,
  }[media.componentType];
  return (
    <Wrapper>
      <Component src={media.src} handleComplete={handleComplete} />
      <Overlay
        {...{
          ...overlayConfig,
          headlineText,
          bodyText,
          uiElements,
          dispatch,
        }}
      />
    </Wrapper>
  );
};

/* eslint-disable import/no-unresolved, import/extensions  */
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import reducers from './reducer';
import sagas from './effects';

const reducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['auth'],
  },
  reducers,
);
const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

sagas.forEach(saga => sagaMiddleware.run(saga));

export const persistor = persistStore(store);
export default store;

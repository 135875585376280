import { config } from 'cms-modules';

const { Config, Slice, Section, Image } = config;

const hintText = image =>
  !image ||
  (image && image.meta && (image.meta.width < 1200 || image.meta.height < 600))
    ? 'Recommended minimum size is 1200x600px'
    : '';

const editor = Config(
  'Image',
  { id: 'image' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Choose image',
      {},
      Image(
        'Image',
        { image: 'image', accessibilityText: 'imageLabel' },
        { hintText },
      ),
    ),
  ),
);

export default editor;

// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_REGISTER';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'REGISTER',
  'REGISTER_FAILURE',
  'REGISTER_SUCCESS',
]);

export const id = 'Register';

export const actions = {
  init: moduleAction(constants.INIT, id),
  register: moduleActionWithArgs(constants.REGISTER, id, payload => ({
    ...payload,
  })),
  registerSuccess: moduleAction(constants.REGISTER_SUCCESS, id),
  registerFailure: moduleAction(constants.REGISTER_FAILURE, id),
};

type State = {
  error: any,
  registerFailed: boolean,
  context: 'FORM' | 'VERIFY',
};
export const initialState: State = {
  error: null,
  registerFailed: false,
  context: 'FORM',
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.INIT:
      return {
        ...initialState,
      };
    case constants.REGISTER_FAILURE:
      return {
        ...state,
        registerFailed: true,
      };
    case constants.REGISTER_SUCCESS:
      return {
        ...state,
        context: 'VERIFY',
      };
    default:
      return state;
  }
}

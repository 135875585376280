// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import { Paragraph, fontStyleOverride } from '../../../components/Global';
import media from '../../../theme';

import type { Font } from '../../../types';

const FormFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  ${media.min.medium`
    padding: 20px 5px;
  `};
`;

export type FormFieldProps = {
  label: string,
  required: boolean,
  font: Font,
  children: Node,
};

export default function FormField({
  label,
  required,
  font,
  children,
}: FormFieldProps) {
  return (
    <FormFieldContainer>
      {label && (
        <Paragraph styleOverride={() => fontStyleOverride(font)}>
          {label}
          {required && '*'}
        </Paragraph>
      )}
      {children}
    </FormFieldContainer>
  );
}

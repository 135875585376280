// @flow
import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../../components/ContentWrapper';
import type { Option } from '../../types';

const StyledSubHeading = styled.div`
  padding: 45px;
  color: #000;
  font-size: 21px;
  font-weight: 600;
  character-spacing: 0.4px;
  text-transform: uppercase;
  letter-spacing: 3px;
`;
type Props = {
  config: {
    headline: string,
    backgroundColour: Option,
  },
};
export default function SubHeading({ config }: Props) {
  return (
    <ContentWrapper colour={config.backgroundColour && config.backgroundColour}>
      <StyledSubHeading>{config.translations.headline}</StyledSubHeading>
    </ContentWrapper>
  );
}

// @flow

import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';

type Props = {
  children: Node,
};

const StyledFlexWrapper = styled.div`
  flex: 1 0 0%;
`;

export default function FlexWrapper(props: Props) {
  return <StyledFlexWrapper {...props} />;
}

// @flow
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import splitEvery from 'ramda/src/splitEvery';

import { Button, HeadingTwo } from '../components/Global';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

type Props = {
  dealer: Object,
  translations: Object,
};

export default ({
  dealer,
  translations,
  onButtonClick,
  buttonStyle,
}: Props) => {
  const infoWindow = document.createElement('div');
  ReactDOM.render(
    <Fragment>
      <HeadingTwo styleOverride={() => 'margin-bottom: 10px;'}>
        {dealer.name}
      </HeadingTwo>
      {splitEvery(2, dealer.address).map(addLine => (
        <div key={addLine}>{addLine.join(', ')}</div>
      ))}
      <div>{dealer.country}</div>
      <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
        {translateFromTemplate(
          'phoneLabel',
          { PHONE: dealer.phone },
          translations,
        )}
      </div>
      <Button
        text={translations.buttonText}
        applyStyle="secondary"
        buttonStyle={buttonStyle}
        styleOverride={() => 'margin-top: 10px;'}
        mobileStyleOverride={() => 'margin-top: 10px;'}
        onClick={onButtonClick}
      />
    </Fragment>,
    infoWindow,
  );
  return infoWindow;
};

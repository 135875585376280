// @flow
import type { User, Role } from '../types/users';

const RemoveUser: 'REMOVE USER' = 'REMOVE USER';
export type RemoveUserAction = {
  type: typeof RemoveUser,
  payload: User,
};
const removeUser = (user: User): RemoveUserAction => ({
  type: RemoveUser,
  payload: user,
});

const RemoveUserSuccess: 'REMOVE USER SUCCESS' = 'REMOVE USER SUCCESS';
export type RemoveUserSuccessAction = {
  type: typeof RemoveUserSuccess,
  payload: User,
};
const removeUserSuccess = (user: User): RemoveUserSuccessAction => ({
  type: RemoveUserSuccess,
  payload: user,
});

const RemoveUserFailed: 'REMOVE USER FAILED' = 'REMOVE USER FAILED';
export type RemoveUserFailedAction = {
  type: typeof RemoveUserFailed,
  payload: { user: User, error: string },
};
const removeUserFailed = (
  user: User,
  error: string,
): RemoveUserFailedAction => ({
  type: RemoveUserFailed,
  payload: {
    error,
    user,
  },
});

const AttemptAddUser: 'ATTEMPT ADD USER' = 'ATTEMPT ADD USER';
export type AttemptAddUserAction = {
  type: typeof AttemptAddUser,
  payload: User,
};
const attemptAddUser = (user: User): AttemptAddUserAction => ({
  type: AttemptAddUser,
  payload: user,
});

const AttemptAddUserFailed: 'ATTEMPT ADD USER FAILED' =
  'ATTEMPT ADD USER FAILED';
export type AttemptAddUserFailedAction = {
  type: typeof AttemptAddUserFailed,
  payload: string,
};
const attemptAddUserFailed = (error: string): AttemptAddUserFailedAction => ({
  type: AttemptAddUserFailed,
  payload: error,
});

const AddUser: 'ADD USER' = 'ADD USER';
export type AddUserAction = {
  type: typeof AddUser,
  payload: User,
};
const addUser = (user: User): AddUserAction => ({
  type: AddUser,
  payload: user,
});

const AddUserSuccess: 'ADD USER SUCCESS' = 'ADD USER SUCCESS';
export type AddUserSuccessAction = {
  type: typeof AddUserSuccess,
  payload: User,
};
const addUserSuccess = (user: User): AddUserSuccessAction => ({
  type: AddUserSuccess,
  payload: user,
});

const AddUserFailed: 'ADD USER FAILED' = 'ADD USER FAILED';
export type AddUserFailedAction = {
  type: typeof AddUserFailed,
  payload: string,
};
const addUserFailed = (error: string): AddUserFailedAction => ({
  type: AddUserFailed,
  payload: error,
});

const LoadUsers: 'LOAD USERS' = 'LOAD USERS';
export type LoadUsersAction = {
  type: typeof LoadUsers,
};
const loadUsers = () => ({
  type: LoadUsers,
});

const LoadUsersSuccess: 'LOAD USERS SUCCESS' = 'LOAD USERS SUCCESS';
export type LoadUsersSuccessAction = {
  type: typeof LoadUsersSuccess,
  payload: User[],
};
const loadUsersSuccess = (users: User[]): LoadUsersSuccessAction => ({
  type: LoadUsersSuccess,
  payload: users,
});

const LoadUsersFailed: 'LOAD USERS FAILED' = 'LOAD USERS FAILED';
export type LoadUsersFailedAction = {
  type: typeof LoadUsersFailed,
  payload: string,
};
const loadUsersFailed = (error: string): LoadUsersFailedAction => ({
  type: LoadUsersFailed,
  payload: error,
});

const LoadRoles: 'LOAD ROLES' = 'LOAD ROLES';
export type LoadRolesAction = {
  type: typeof LoadRoles,
};
const loadRoles = () => ({
  type: LoadRoles,
});

const LoadRolesSuccess: 'LOAD ROLES SUCCESS' = 'LOAD ROLES SUCCESS';
export type LoadRolesSuccessAction = {
  type: typeof LoadRolesSuccess,
  payload: Role[],
};
const loadRolesSuccess = (roles: Role[]): LoadRolesSuccessAction => ({
  type: LoadRolesSuccess,
  payload: roles,
});

const LoadRolesFailed: 'LOAD ROLES FAILED' = 'LOAD ROLES FAILED';
export type LoadRolesFailedAction = {
  type: typeof LoadRolesFailed,
  payload: string,
};
const loadRolesFailed = (error: string): LoadRolesFailedAction => ({
  type: LoadRolesFailed,
  payload: error,
});

const SelectedRoleChanged: 'SELECTED ROLE CHANGED' = 'SELECTED ROLE CHANGED';
export type SelectedRoleChangedAction = {
  type: typeof SelectedRoleChanged,
  payload: Role,
};
const selectedRoleChanged = (role: Role): SelectedRoleChangedAction => ({
  type: SelectedRoleChanged,
  payload: role,
});

const EmailAddressChanged: 'EMAIL ADDRESS CHANGED' = 'EMAIL ADDRESS CHANGED';
export type EmailAddressChangedAction = {
  type: typeof EmailAddressChanged,
  payload: string,
};
const emailAddressChanged = (email: string): EmailAddressChangedAction => ({
  type: EmailAddressChanged,
  payload: email,
});

export const constants = {
  RemoveUser,
  RemoveUserSuccess,
  RemoveUserFailed,
  AttemptAddUser,
  AttemptAddUserFailed,
  AddUser,
  AddUserSuccess,
  AddUserFailed,
  LoadUsers,
  LoadUsersSuccess,
  LoadUsersFailed,
  LoadRoles,
  LoadRolesSuccess,
  LoadRolesFailed,
  SelectedRoleChanged,
  EmailAddressChanged,
};

export const actions = {
  removeUser,
  removeUserSuccess,
  removeUserFailed,
  attemptAddUser,
  attemptAddUserFailed,
  addUser,
  addUserSuccess,
  addUserFailed,
  loadUsers,
  loadUsersSuccess,
  loadUsersFailed,
  loadRoles,
  loadRolesSuccess,
  loadRolesFailed,
  selectedRoleChanged,
  emailAddressChanged,
};

export type Action =
  | RemoveUserAction
  | RemoveUserSuccessAction
  | RemoveUserFailedAction
  | AttemptAddUserAction
  | AttemptAddUserFailedAction
  | AddUserAction
  | AddUserSuccessAction
  | AddUserFailedAction
  | LoadUsersAction
  | LoadUsersSuccessAction
  | LoadUsersFailedAction
  | LoadRolesAction
  | LoadRolesSuccessAction
  | LoadRolesFailedAction
  | SelectedRoleChangedAction
  | EmailAddressChangedAction
  | AddUserSuccessAction;

// @flow

import React, { useState } from 'react';
import uuid from 'uuid/v1';
import styled from 'styled-components';
import { formFields } from 'cms-modules';
import { EditField } from './EditField';
import { defaultConfigs } from './defaultConfigs';
import useFormEditor from './useFormEditor';

const AddNewFieldContainer = styled.div`
  border: 1px dashed #0065db;
  display: flex;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  height: 200px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin: 10px 0px;
`;

const AddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const AddFormSectionIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65px;
  position: absolute;
  left: 0;
  height: 100%;
  z-index: 100;
`;

const EditWrapper = styled.div`
  position: absolute;
  right: 0px;
  height: 100%;
  top: 0;
  display: flex;
`;
const FieldWrapper = styled.div`
  width: 100%;
  padding: 0 50px 0 75px;
  position: relative;
`;

const FieldTypeWrapper = styled.div`
  display: flex;
  border: 1px dashed #0065db;
`;

const FormEditorContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  max-width: 600px;
`;

const Icon = styled.img`
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
`;

const RowContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-content: center;
  margin: 10px 0;
  position: relative;
`;
const Text = styled.div`
  font-family: Lato, Helvetica, open sans;
  font-size: 12px;
  color: ${({ colour }) => colour || '#757575'};
`;

const InputTypes = {
  Email: 'TextInput',
  TextInput: 'TextInput',
  FormButton: 'FormButton',
  Radio: 'Radio',
};

const Arrows = ({ field, fields, updatePodField, updatePosition }) => (
  <ArrowWrapper>
    <Icon
      src={
        fields[0] === field
          ? 'https://res.cloudinary.com/motortrak/image/upload/v1578653945/editor/up_arrow_off.svg'
          : 'https://res.cloudinary.com/motortrak/image/upload/v1578653945/editor/up_arrow_on.svg'
      }
      onClick={() =>
        fields[0] !== field && updatePodField('fields', updatePosition('UP'))
      }
    />
    <Icon
      src={
        fields[fields.length - 1] === field
          ? 'https://res.cloudinary.com/motortrak/image/upload/v1578653805/editor/down-arrow-off.svg'
          : 'https://res.cloudinary.com/motortrak/image/upload/v1578653805/editor/down-arrow-on.svg'
      }
      onClick={() =>
        fields[fields.length - 1] !== field &&
        updatePodField('fields', updatePosition('DOWN'))
      }
    />
  </ArrowWrapper>
);

const EditorRow = ({
  renderArrows,
  fields,
  field,
  updatePodField,
  formFieldTypes,
  uiElements,
  translations,
  radioInput,
  setRadioInput,
}) => {
  return (
    <RowContainer>
      {renderArrows(fields, field, updatePodField)}
      <FieldWrapper>
        <EditField
          {...{
            field,
            updatePodField,
            fields,
            formFieldTypes,
            uiElements,
            translations,
            radioInput,
            setRadioInput,
          }}
        />
      </FieldWrapper>
    </RowContainer>
  );
};

const FieldRow = ({
  renderArrows,
  fields,
  field,
  updatePodField,
  uiElements,
  translations,
}) => (
  <RowContainer>
    {renderArrows(fields, field, updatePodField)}
    <FieldWrapper>
      <FieldTypeWrapper>
        {formFields[InputTypes[field.fieldType]]({
          ...field,
          translations,
          uiElements,
          onChange: () => {
            // no-op
          },
          onBlur: () => {
            // no-op
          },
        })}
      </FieldTypeWrapper>
    </FieldWrapper>
    <EditWrapper>
      <Icon
        title="edit"
        src="https://res.cloudinary.com/motortrak/image/upload/v1578658960/editor/edit.svg"
        onClick={() => {
          updatePodField(
            'fields',
            fields.map(fi =>
              fi.id === field.id ? { ...fi, editing: true } : fi,
            ),
          );
        }}
      />
    </EditWrapper>
  </RowContainer>
);

const renderArrows = (fields, field, updatePodField) => {
  const updatePosition = direction => {
    const currentIndex = fields.findIndex(({ id }) => field.id === id);
    const newIndex =
      direction === 'UP'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, fields.length - 1);
    const reordered = [...fields];
    [reordered[currentIndex], reordered[newIndex]] = [
      reordered[newIndex],
      reordered[currentIndex],
    ];

    return reordered;
  };
  return <Arrows {...{ fields, field, updatePodField, updatePosition }} />;
};

export default function FormEditor() {
  const {
    uiElements,
    updatePodField,
    fields,
    translations,
    formFieldTypes,
  } = useFormEditor();

  const [radioInput, setRadioInput] = useState();
  return (
    <FormEditorContentContainer>
      {fields.map(field => {
        return field.editing ? (
          <EditorRow
            {...{
              renderArrows,
              fields,
              field,
              updatePodField,
              formFieldTypes,
              uiElements,
              translations,
              radioInput,
              setRadioInput,
            }}
          />
        ) : (
          <FieldRow
            {...{
              renderArrows,
              fields,
              field,
              updatePodField,
              formFieldTypes,
              uiElements,
              translations,
              radioInput,
              setRadioInput,
            }}
          />
        );
      })}
      <AddNewFieldContainer>
        <AddWrapper
          onClick={() => {
            const id = uuid();
            return updatePodField('fields', [
              ...fields,
              {
                ...defaultConfigs.TextInput,
                fieldType: 'TextInput',
                editing: true,
                id,
                required: false,
              },
            ]);
          }}
        >
          <AddFormSectionIcon
            title="add"
            src="https://res.cloudinary.com/motortrak/image/upload/v1580999566/editor/plus-w-circle.svg"
          />
          <Text colour="#0065DB">Add new form section</Text>
        </AddWrapper>
      </AddNewFieldContainer>
    </FormEditorContentContainer>
  );
}

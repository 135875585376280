// @flow
import { arrayMove } from 'react-sortable-hoc';
import uuid from 'uuid/v1';

import type { Item } from './ItemsEditor';

type Option = {
  id: string,
  url: string,
};

const getItemById = (items: Item[] | Option[], id: ?string) =>
  items.find(item => item.id === id);

export const updateItem = (
  itemId: string,
  label: string,
  id: string,
  items: Item[],
  options: Option[],
) => {
  const index = items.findIndex(p => p.itemId === itemId);
  const { url } = getItemById(options, id);
  return [
    ...items.slice(0, index),
    { ...items[index], label, id, url },
    ...items.slice(index + 1),
  ];
};

export const handleSort = (
  items: Item[],
  oldIndex: number,
  newIndex: number,
) => [...arrayMove(items, oldIndex, newIndex)];

export const handleDelete = (itemId: string, items: Item[]) =>
  items.filter(item => item.itemId !== itemId);

export const addItem = (
  id: string,
  label: string,
  data: Item[],
  items: Item[],
) => {
  const itemToBeAdded = { ...getItemById(data, id), label, itemId: uuid() };
  return [...items, itemToBeAdded];
};

// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import VehicleGallery from './VehicleGallery';
import Compare from './JaguarIcons/Global/Compare';
import Heart from './JaguarIcons/Global/Heart';
import SocialShare from './JaguarIcons/Global/SocialShare';
import {
  HeadingTwo,
  HeadingThree,
  Paragraph,
  fontStyleOverride,
} from './Global';
import SocialSharePopup from './SocialSharePopup';
import { vehicleDataFormatter } from '../helpers/vehicle';

import media from '../theme';
import { localeFormattedDate } from '../shared/localisation/dates';

const Container = styled.div.withConfig({
  displayName: 'VehicleGalleryContainer',
})`
  width: ${({ galleryWidth }) => (galleryWidth ? `${galleryWidth}%;` : '60%;')};
  max-width: 1000px;
  ${media.max.large`
    width: 100%;
    height: 50%;
  `};
`;

const VehicleHeaderBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ padding, direction }) =>
    direction === 'rtl'
      ? '20px 20px 20px 0'
      : `${padding || '20px 0 20px 20px'}`}};
  ${media.max.large`
      ${({ margin }) => margin && `margin: 0 ${margin}%;`}
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
`;
const SingleButton = styled.div`
  border-left: ${props => (props.noBorder ? 'none' : '1px solid #dedede')};
  padding: ${({ heartMobileNoPadding }) =>
    heartMobileNoPadding ? '0' : '0 8px'};
  ${({ direction }) =>
    direction === 'rtl' &&
    'border-left: none; border-right: 1px solid #dedede'}};
  cursor: pointer;
`;
const ButtonLabel = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #7e7e7e;
`;
const IconWrapper = styled.div`
  display: flex;
  div:first-child {
    margin: auto;
  }
`;
const MobileOnly = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

const DesktopOnly = styled.div`
  display: block;
  ${media.max.large`
  display: none;
`};
`;
const MediaWrapper = styled.div`
  width: 100%;
  ${media.max.large`
    width: 60%;
    padding: 0 20%;
    background-color: #000;
  `}
${media.max.medium`
  width: 70%;
  padding: 0 15%;
`}
${media.max.small`
  width: 100%;
  padding: 0;
  background: none;
`}
`;

const SubHeading = ({ children }: { children: Node }) => (
  <div>{children.filter(Boolean).join(' | ')}</div>
);

// eslint-disable-next-line no-confusing-arrow
export const formattedVehicleDescription = (
  type,
  vehicle,
  shortVehicleTitles,
  subtitleData,
) => {
  const {
    description,
    registrationDate,
    powerOutput,
    transmissionType,
    specification: { fuelType, odometer },
    locale,
  } = vehicle;

  if (subtitleData) {
    return vehicleDataFormatter(subtitleData, vehicle, locale, ' | ');
  }

  return type === 'VehicleDescription' ? (
    description
  ) : (
    <SubHeading>
      {shortVehicleTitles
        ? [transmissionType, fuelType, powerOutput]
        : [
            odometer && odometer.display,
            transmissionType,
            fuelType,
            powerOutput,
            registrationDate &&
              localeFormattedDate(registrationDate, locale, {
                month: '2-digit',
                year: 'numeric',
              }),
          ]}
    </SubHeading>
  );
};
const VehicleSubHeader = ({
  subHeaderType,
  vehicleInfo,
  shortVehicleTitles,
  subtitleData,
}: {
  vehicleInfo: Object,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  shortVehicleTitles: boolean,
  subtitleData: Object,
}) => (
  <HeadingThree styleOverride={() => 'font-size: 14px;'}>
    {formattedVehicleDescription(
      subHeaderType,
      vehicleInfo,
      shortVehicleTitles,
      subtitleData,
    )}
  </HeadingThree>
);

type HeaderBarProps = {
  shortListedIconColour: string,
  compareIconColour: string,
  showShareButton: boolean,
  vehicleInfo: Vehicle,
  translations: Object,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  compareVehicle: (vehicle: Vehicle) => void,
  shortlistVehicle: (vehicle: Vehicle) => void,
  vehicleHeadingColour: Object,
  vehicleHeadingPadding: string,
  vehicleHeadingMargin: number,
  heartMobileNoPadding: boolean,
  socialShare: Object,
  config: Object,
};

const VehicleHeaderBar = ({
  translations,
  vehicleInfo,
  compareVehicle,
  showShareButton,
  shortlistVehicle,
  shortListedIconColour,
  compareIconColour,
  subHeaderType,
  vehicleHeadingColour,
  vehicleHeadingPadding,
  vehicleHeadingMargin,
  heartMobileNoPadding,
  socialShare,
  config,
  direction,
}: HeaderBarProps) => (
  <VehicleHeaderBarContainer
    margin={vehicleHeadingMargin}
    padding={vehicleHeadingPadding}
    direction={direction}
  >
    <div data-qa-hook="vehicle-title-description">
      <HeadingTwo
        styleOverride={() => `
          font-size: 25px;
          font-weight: 600;
          text-transform: uppercase;
          ${vehicleHeadingColour && `color: ${vehicleHeadingColour.value}`};
          letter-spacing: ${vehicleInfo.finance ? 'inherit' : '0.72px'};
          margin-bottom: ${vehicleInfo.finance ? 'auto' : '5px'};
        `}
      >
        {config.hideYear
          ? vehicleInfo.description
          : `${vehicleInfo.registration} ${vehicleInfo.description} `}
      </HeadingTwo>
      <VehicleSubHeader
        subHeaderType={subHeaderType}
        vehicleInfo={vehicleInfo}
        shortVehicleTitles={config.shortVehicleTitles}
        subtitleData={config.subtitleData}
      />
    </div>
    <DesktopOnly>
      <ButtonsContainer>
        <SingleButton
          data-qa-hook="compare-vehicle-button"
          onClick={() => compareVehicle(vehicleInfo)}
          direction={direction}
        >
          <IconWrapper>
            <Compare width="28px" height="25px" colour={compareIconColour} />
          </IconWrapper>
          <ButtonLabel>{translations.compareActionLabel}</ButtonLabel>
        </SingleButton>
        <SingleButton
          data-qa-hook="shortlist-vehicle-button"
          onClick={() => shortlistVehicle(vehicleInfo)}
          direction={direction}
        >
          <IconWrapper>
            <Heart width="28px" height="25px" colour={shortListedIconColour} />
          </IconWrapper>
          <ButtonLabel>{translations.shortlistActionLabel}</ButtonLabel>
        </SingleButton>
        {showShareButton ? (
          <SocialSharePopup socialShare={socialShare} config={config}>
            <SingleButton>
              <IconWrapper>
                <SocialShare width="28px" height="25px" colour="#ACACAC" />
              </IconWrapper>
              <ButtonLabel>{translations.socialshareActionLabel}</ButtonLabel>
            </SingleButton>
          </SocialSharePopup>
        ) : null}
      </ButtonsContainer>
    </DesktopOnly>
    <MobileOnly>
      <ButtonsContainer>
        <SingleButton
          heartMobileNoPadding={heartMobileNoPadding}
          noBorder
          onClick={() => shortlistVehicle(vehicleInfo)}
        >
          <IconWrapper>
            <Heart width="28px" height="25px" colour={shortListedIconColour} />
          </IconWrapper>
        </SingleButton>
        {showShareButton ? (
          <SocialSharePopup socialShare={socialShare} config={config}>
            <SingleButton>
              <IconWrapper>
                <SocialShare width="28px" height="25px" colour="#ACACAC" />
              </IconWrapper>
            </SingleButton>
          </SocialSharePopup>
        ) : null}
      </ButtonsContainer>
    </MobileOnly>
  </VehicleHeaderBarContainer>
);

type Props = {
  shortListedIconColour: string,
  compareIconColour: string,
  vehicleImages: Object[],
  placeholdingImage: string,
  vehicleInfo: Vehicle,
  translations: Object,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  compareVehicle: (vehicle: Vehicle) => void,
  shortlistVehicle: (vehicle: Vehicle) => void,
  vehicleHeadingColour: Object,
  vehicleHeadingPadding: string,
  vehicleHeadingMargin: string,
  heartMobileNoPadding: number,
  rotateGallery: boolean,
  showShareButton: boolean,
  socialShare: Object,
  config: Object,
  imageCarousel: Object,
  globalStyling: Object,
  galleryWidth: number,
};

export default function VdpGallery(props: Props) {
  return (
    <Container galleryWidth={props.galleryWidth}>
      <VehicleHeaderBar
        shortListedIconColour={props.shortListedIconColour}
        compareIconColour={props.compareIconColour}
        translations={props.translations}
        vehicleInfo={props.vehicleInfo}
        compareVehicle={props.compareVehicle}
        shortlistVehicle={props.shortlistVehicle}
        subHeaderType={props.subHeaderType}
        vehicleHeadingColour={props.vehicleHeadingColour}
        vehicleHeadingPadding={props.vehicleHeadingPadding}
        vehicleHeadingMargin={props.vehicleHeadingMargin}
        heartMobileNoPadding={props.heartMobileNoPadding}
        showShareButton={props.showShareButton}
        socialShare={props.socialShare}
        config={props.config}
        hideZoomMobile={props.config.hideZoomMobile}
        direction={props.globalStyling.direction}
      />
      <MediaWrapper data-qa-hook="vehicle-gallery">
        <VehicleGallery
          config={props.vehicleImages}
          placeholdingImage={props.placeholdingImage}
          views={props.vehicleInfo.views}
          vehicleInfo={props.vehicleInfo}
          imageCarousel={props.imageCarousel}
          rotateGallery
          isLooping={false}
          hideZoomMobile={props.config.hideZoomMobile}
          translations={props.translations}
          globalStyling={props.globalStyling}
        />
      </MediaWrapper>
      <Paragraph
        styleOverride={() =>
          `
          ${fontStyleOverride(props.config.galleryFootnoteFont)};
          font-style: italic;
          padding: 0 16px;
          `
        }
      >
        {props.translations.galleryFootnote}
      </Paragraph>
    </Container>
  );
}

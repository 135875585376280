// @flow
import React from 'react';
import styled from 'styled-components';
import IoGrid from 'react-icons/lib/io/grid';

import BottomTier from './BottomTier';
import CallToAction from './CallToAction';
import Carousel from './Carousel';
import CarouselText from './CarouselText';
import Gallery from './Gallery';
import GridItem from './GridItem';
import LetterB from './LetterB';
import LetterD from './LetterD';
import LetterL from './LetterL';
import LetterP from './LetterP';
import LetterT from './LetterT';
import LetterS from './LetterS';
import MidTier from './MidTier';
import QuickLinks from './QuickLinks';
import SideVideo from './SideVideo';
import SlideBottom from './SlideBottom';
import SlideTop from './SlideTop';
import SocialMedia from './SocialMedia';
import TopTier from './TopTier';
import HeroCarousel from './HeroCarousel';
import SideCarousel from './SideCarousel';

const icons = {
  BottomTier,
  CallToAction,
  Carousel,
  CarouselText,
  Gallery,
  GridItem,
  LetterB,
  LetterD,
  LetterL,
  LetterP,
  LetterT,
  LetterS,
  MidTier,
  QuickLinks,
  SideVideo,
  SlideBottom,
  SlideTop,
  SocialMedia,
  TopTier,
  HeroCarousel,
  SideCarousel,
};
type Props = {
  icon: string,
};

export default function SectionIcon({ icon }: Props) {
  const Icon = icons[icon] || IoGrid;
  const StyledIcon = styled(Icon)`
    width: 1.5em;
    height: 1.5em;
  `;
  return <StyledIcon />;
}

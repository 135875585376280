// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import Carousel, { Slide } from '../Carousel';
import View360 from '../JaguarIcons/VDP/View360';
import Play from '../JaguarIcons/VDP/Play';
import Eye from '../JaguarIcons/VDP/Eye';
import VehicleFilmStrip from './VehicleFilmStrip';
import Magnify from '../JaguarIcons/VDP/Magnify';
import media from '../../theme';
import ModalOverlay from './ModalOverlay';

import parseYouTubeId from '../YouTubePlayer/parseYouTubeId';

const Footer = styled.div`
  height: 55px;
  ${({ horizontalPadding }) =>
    horizontalPadding && `padding: ${horizontalPadding}px 0`};
  ${({ backgroundColour }) =>
    backgroundColour
      ? `background: ${backgroundColour.value}`
      : 'background: #000000'};
  ${({ border }) =>
    border && `border: ${border.size}px ${border.type} ${border.colour}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  ${media.max.large`
    margin: 0 5% 0;
  `};
`;

const FooterButtonContainer = styled.div`
  border-left: 1px solid #acacac;
  padding: 0 10px;
  &:hover {
    cursor: pointer;
  }
`;

const Counter = styled.div`
  text-align: center;
  color: ${({ fontColour }) =>
    fontColour ? `${fontColour.value}` : '#FFFFFF'};
  font-size: 15px;
  display: flex;
  justify-content: end;
  white-space: nowrap;
  padding-right: 10px;
  ${media.max.medium`
    ${({ hideZoomMobile }) => hideZoomMobile && ' margin-left: 8px;'}
  `};
`;

const ViewsContainer = styled.div`
  ${({ hide }) => hide && 'display: none'};
  opacity: 1;
  color: #fff;
  margin-right: 8px;
`;
const ViewsWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

const FilmStripContainer = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
`;

const ThumbnailsScroller = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  position: relative;
  ${({ direction }) => (direction === 'rtl' ? 'right: 0;' : 'left: 0;')};
  box-sizing: border-box;
  transition: ${({ direction }) =>
    direction === 'rtl' ? 'right 0.5s ease;' : 'left 0.5s ease;'};
`;

const MagnifyWrapper = styled.div`
  cursor: pointer;
  margin: 0 8px;
  ${media.max.medium`
    ${({ hideZoomMobile }) => hideZoomMobile && 'display: none;'}
  `};
`;

const IconWrapper = styled.img`
  width: 32px;
  height: 32px;
`;

const ArrowIconWrapper = styled.img`
  width: 32px;
  height: 24px;
`;

const FlipperButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  height: 30px;
  width: 30px;
  border: 0;
  z-index: 66;
  cursor: pointer;
  ${({ left }) => (left ? 'left: 8px' : 'right: 8px')};
  ${({ direction }) => direction === 'rtl' && 'transform: scaleX(-1);'};

  &:focus {
    outline: 0;
  }
`;

type CarouselFooter = {
  currentSlide: number,
  slideTotal: number,
  showMedia: (mediaType: 'video' | 'view360') => void,
};

type Props = {
  vehicleInfo: Object,
  placeholdingImage: string,
  views: number,
  isLooping: boolean,
  config: Object,
  rotateGallery: boolean,
  imageCarousel: Object,
  hideZoomMobile: boolean,
  globalStyling: Object,
  translations: Object,
};

type State = {
  isOpen: boolean,
  currentCarouselImage: number,
};

class VehicleGallery extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currentCarouselImage: 0,
    };
  }

  containerRef = React.createRef();

  openLightbox = () => {
    this.setState(() => ({
      isOpen: true,
    }));
  };

  closeLightbox = () => {
    this.setState(() => ({ isOpen: false }));
  };

  goToSlide = (slide: number) => {
    let newSlide = slide;
    const {
      config: { slides },
      rotateGallery,
    } = this.props;

    if (rotateGallery && slide < 0) {
      newSlide = slides.length - 1;
    }

    if (rotateGallery && slide >= slides.length) {
      newSlide = 0;
    }

    this.setState(() => ({
      currentCarouselImage: newSlide,
    }));

    const { clientWidth, scrollWidth } = this.containerRef.current;
    const singleScrollDist = (scrollWidth - clientWidth) / (slides.length - 1);
    const scrollDist = singleScrollDist * newSlide;

    if (this.containerRef.current) {
      if (this.props.globalStyling.direction === 'rtl') {
        this.containerRef.current.style.right = `${scrollDist * -1}px`;
      } else {
        this.containerRef.current.style.left = `${scrollDist * -1}px`;
      }
    }
  };

  handleThumbnailClick = (nextSlide: number) => {
    this.setState(() => ({
      currentCarouselImage: nextSlide,
    }));
  };

  render() {
    const {
      config = {
        translations: {},
      },
      isLooping,
      views,
      placeholdingImage,
      vehicleInfo,
      rotateGallery,
      imageCarousel,
      hideZoomMobile,
      globalStyling,
      globalStyling: {
        colours: {
          primaryBackgroundHoverColour: { value: iconColour = '#ccc' } = {},
        },
      },
      translations,
    } = this.props;

    const { isOpen } = this.state;
    const parsedYouTubeId = vehicleInfo.video
      ? parseYouTubeId(vehicleInfo.video)
      : null;

    const slides =
      config.slides.length > 0
        ? config.slides
        : [{ id: placeholdingImage, image: placeholdingImage }];

    const images = config.slides.map(slide => slide.image);

    const carouselProps = {
      hideZoomMobile,
      slides: slides.map(slide => ({ ...slide, component: Slide })),
      isLooping,
      rotate: rotateGallery,
      onClickNext: () => this.goToSlide(this.state.currentCarouselImage + 1),
      onClickPrev: () => this.goToSlide(this.state.currentCarouselImage - 1),
      slideIndex: this.state.currentCarouselImage,
      arrowRight: imageCarousel && imageCarousel.arrowRight,
      arrowLeft: imageCarousel && imageCarousel.arrowLeft,
      icon360: imageCarousel && imageCarousel.icon360,
      enable360View: imageCarousel && imageCarousel.enable360View,
      imageHeight: imageCarousel && imageCarousel.imageHeight,
      video: config.video,
      close360Icon: imageCarousel && imageCarousel.close360Icon,
      vehicleInfo,
      globalStyling,
      translations,
      imageCarousel,
      parsedYouTubeId,
    };

    const leftArrow = () =>
      imageCarousel && imageCarousel.arrowLeft ? (
        <ArrowIconWrapper src={imageCarousel.arrowLeft} alt="Left arrow" />
      ) : (
        '<'
      );
    const rightArrow = () =>
      imageCarousel && imageCarousel.arrowRight ? (
        <ArrowIconWrapper src={imageCarousel.arrowRight} alt="Right arrow" />
      ) : (
        '>'
      );

    const setLeftCurrentCarouselImage =
      globalStyling.direction === 'rtl'
        ? this.state.currentCarouselImage + 1
        : this.state.currentCarouselImage - 1;

    const setRightCurrentCarouselImage =
      globalStyling.direction === 'rtl'
        ? this.state.currentCarouselImage - 1
        : this.state.currentCarouselImage + 1;

    return (
      <Fragment>
        {isOpen && (
          <ModalOverlay
            close={this.closeLightbox}
            config={config}
            modalWidth="80%"
            direction={globalStyling.direction}
          >
            <Carousel {...carouselProps} fullscreen />
          </ModalOverlay>
        )}
        <Carousel
          {...carouselProps}
          renderFooter={({ showMedia }: CarouselFooter) => (
            <Footer
              backgroundColour={
                imageCarousel && imageCarousel.footerBackgroundColour
              }
              border={imageCarousel && imageCarousel.borderFooter}
              horizontalPadding={
                imageCarousel && imageCarousel.horizontalPadding
              }
            >
              <ViewsContainer hide={imageCarousel && imageCarousel.hideViews}>
                <ViewsWrapper>
                  <Eye width="2em" height="2em" colour="#fff" />
                  <span>{views}</span>
                </ViewsWrapper>
              </ViewsContainer>

              <FilmStripContainer>
                <FlipperButton
                  left
                  onClick={() => {
                    this.goToSlide(setLeftCurrentCarouselImage);
                  }}
                  direction={globalStyling.direction}
                >
                  {leftArrow()}
                </FlipperButton>
                <ThumbnailsScroller
                  ref={this.containerRef}
                  direction={globalStyling.direction}
                >
                  <VehicleFilmStrip
                    height={45}
                    imageUrls={images}
                    onImageClick={this.handleThumbnailClick}
                    selectedImage={this.state.currentCarouselImage}
                  />
                </ThumbnailsScroller>
                <FlipperButton
                  onClick={() => {
                    this.goToSlide(setRightCurrentCarouselImage);
                  }}
                  direction={globalStyling.direction}
                >
                  {rightArrow()}
                </FlipperButton>
              </FilmStripContainer>
              <MagnifyWrapper
                hideZoomMobile={hideZoomMobile}
                onClick={this.openLightbox}
              >
                {imageCarousel && imageCarousel.zoomIcon ? (
                  <IconWrapper src={imageCarousel.zoomIcon} alt="" />
                ) : (
                  <Magnify width="30px" height="30px" />
                )}
              </MagnifyWrapper>
              <Counter
                hideZoomMobile={hideZoomMobile}
                fontColour={imageCarousel && imageCarousel.counterColour}
              >
                {`${this.state.currentCarouselImage + 1} / ${images.length}`}
              </Counter>
              <ButtonsWrapper>
                {parsedYouTubeId && (
                  <FooterButtonContainer onClick={() => showMedia('video')}>
                    <Play width="2em" height="2em" colour={iconColour} />
                  </FooterButtonContainer>
                )}
                {vehicleInfo.media &&
                (vehicleInfo.media.exterior || vehicleInfo.media.interior) ? (
                  <FooterButtonContainer onClick={() => showMedia('view360')}>
                    <View360 width="2em" height="2em" colour={iconColour} />
                  </FooterButtonContainer>
                ) : null}
              </ButtonsWrapper>
            </Footer>
          )}
        />
      </Fragment>
    );
  }
}

export default VehicleGallery;

import {
  Config,
  Slice,
  Section,
  Video,
  Image,
  Font,
  Colour,
  Opacity,
  TextArea,
  Toggle,
  Pixels,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const hintText = item =>
  item && item.label
    ? ''
    : 'The default placeholder for videos is the first frame of the video. If you would like an alternative image to be used, please upload it here.';

const config = Config(
  'VideoPlayer',
  { id: 'video-player' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Video',
      { icon: SectionIcons.TopTier },
      Video('Video File', 'videoFile'),
    ),
    Section(
      'Poster',
      { icon: SectionIcons.TopTier },
      Image('Image', 'posterImage', { hintText }),
    ),
    Section(
      'Text Overlay',
      { icon: SectionIcons.TopTier },
      TextArea('Heading Row 1', 'heading'),
      TextArea('Heading Row 2', 'subheading'),
      TextArea('Paragraph Row 1', 'body'),
      TextArea('Paragraph Row 2', 'footer'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Font('Heading Font', 'headingFont'),
      Font('Body Font', 'bodyFont'),
      Toggle('Overlay', 'overlayVisible'),
      Colour('Background Colour', 'backgroundColour'),
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
);

export default config;

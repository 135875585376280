// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import Slider from '../RangeSlider/Slider';
import media from '../../theme';
import LabelledCheckBox from '../LabelledCheckBox';
import { localiseCurrency } from '../../shared/localisation/numbers';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

const PersonalContractInfo = styled.div.withConfig({
  displayName: 'PersonalContractInfo',
})`
  width: 93%;
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 15px;
  text-align: center;
`;

const Title = styled.div``;

const Body = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 15px;
`;

const SlidersContainer = styled.div`
  background-color: #ffffff;
  padding: 0 20px 20px;
  display: flex;
  justify-content: space-between;
  ${media.max.large`
    display: block;
    padding: 50px;
  `};
`;

const RangeContainer = styled.div`
  width: 45%;
  padding: 20px;
  ${media.max.large`
  width: 100%;
  padding: 20px 0 20px 0;
  `};
`;

const StyledRangeHeading = styled.h4`
  margin: 0 0 20px -8px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  color: #444;
`;

const WithSpaceRight = styled.span`
  padding-right: 5px;
`;

const ASContainer = styled.div`
  padding: 0 35px;
  background-color: #ffffff;
`;

const ASTitle = styled.div`
  text-align: left;
  color: #444444;
  font-size: 18px;
  font-weight: 800;
`;

const ASSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 15px 0 25px;
  ${media.max.small`
    flex-direction: column;
  `};
`;

const Block = styled.div`
  width: 50%;
  ${media.max.small`
    margin-top: 10px;
    width: 100%;
  `};
`;

const OptionTitle = styled.div`
  font-size: 16px;
  color: #444444;
  font-family: ProximaNova-Semibold;
`;

type RangeConfig = {
  id: string,
  name: string,
  step: number,
  marks: any,
  defaultValue: number,
  fullRangeValues: { min: number, max: number },
  title: string,
  unit: string,
};

type Props = {
  productKey: string,
  downpaymentRange: RangeConfig,
  termsRange: RangeConfig,
  financeExample: Object,
  translations: Object,
  financeExampleRenderProp: (financeExample: Object) => Node,
  requestFinanceUpdate: (
    duration: number,
    downpayment: number,
    additionalServices: string[],
    productType: string,
  ) => void,
  globalStyling: Object,
  locale: string,
  showDescription: boolean,
};

export default class PersonalContract extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const { financeExample } = this.props;
    const { additionalServices } = financeExample || { additionalServices: [] };
    const selectedServices = additionalServices.reduce(
      (acc, current) => ({
        ...acc,
        [current.id]: current.selected,
      }),
      {},
    );

    this.state = {
      sliderDeposit: this.props.downpaymentRange.defaultValue,
      serviceOption: {
        ...selectedServices,
      },
    };
    this.calculateMaxDepositStep();
  }

  setSliderDeposit = (value: number) => {
    const { step } = this.props.downpaymentRange;
    const deposit =
      value >= this.maxDepositStep
        ? this.props.downpaymentRange.fullRangeValues.max
        : Math.floor(value / step) * step;

    this.setState({ sliderDeposit: deposit });
  };

  getSelectedServiceOptions = () =>
    Object.keys(this.state.serviceOption).filter(
      soKey => this.state.serviceOption[soKey],
    );

  calculateMaxDepositStep = () => {
    const { step } = this.props.downpaymentRange;
    const maxStep =
      Math.floor(this.props.downpaymentRange.fullRangeValues.max / step) * step;
    const maxStepBuffer =
      (this.props.downpaymentRange.fullRangeValues.max - maxStep) / 2;
    this.maxDepositStep = maxStep + maxStepBuffer;
  };

  checkServiceOption = (key, duration, deposit) => {
    this.setState(
      ({ serviceOption }) => ({
        serviceOption: { ...serviceOption, [key]: !serviceOption[key] },
      }),
      () => {
        this.props.requestFinanceUpdate(
          duration,
          deposit,
          [...this.getSelectedServiceOptions()],
          this.props.productKey,
        );
      },
    );
  };

  maxDepositStep = undefined;

  render = () => {
    const {
      downpaymentRange,
      termsRange,
      financeExample,
      requestFinanceUpdate,
      financeExampleRenderProp,
      productKey,
      translations,
      globalStyling,
      locale,
      showDescription,
    } = this.props;

    const duration = (financeExample || {}).duration || termsRange.defaultValue;
    const monthlyPayment = (financeExample || {}).monthlyPrice;
    const { additionalServices } = financeExample || { additionalServices: [] };

    const currency = (financeExample || {}).currency || 'EUR';

    const convertedMonthyPrice =
      monthlyPayment &&
      localiseCurrency(
        parseFloat(monthlyPayment.replace('.', '').replace(',', '.')),
        locale,
        currency,
      );

    return (
      <div>
        <PersonalContractInfo>
          <Body>
            {showDescription
              ? financeExample.description
              : translateFromTemplate(
                  'vdpMyDealHirePurchaseSummary',
                  {
                    PAYMENT: convertedMonthyPrice,
                    DURATION: `${duration}`,
                  },
                  translations,
                )}
          </Body>
        </PersonalContractInfo>
        <SlidersContainer>
          <RangeContainer>
            <StyledRangeHeading>
              <Title>{downpaymentRange.title}</Title>
              <span>
                <WithSpaceRight>
                  {localiseCurrency(
                    this.state.sliderDeposit,
                    locale,
                    currency,
                    0,
                  )}
                </WithSpaceRight>
              </span>
            </StyledRangeHeading>
            <Slider
              step={1}
              marks={downpaymentRange.marks}
              rangeValue={downpaymentRange.fullRangeValues}
              defaultValue={downpaymentRange.defaultValue}
              sliderColor={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              onChange={value => {
                this.setSliderDeposit(value);
              }}
              onAfterChange={() =>
                requestFinanceUpdate(
                  duration,
                  this.state.sliderDeposit,
                  [...this.getSelectedServiceOptions()],
                  productKey,
                )
              }
            />
          </RangeContainer>
          <RangeContainer>
            <StyledRangeHeading>
              <Title>{termsRange.title}</Title>
              <span>
                <WithSpaceRight>{duration}</WithSpaceRight>
                <WithSpaceRight>{termsRange.unit}</WithSpaceRight>
              </span>
            </StyledRangeHeading>
            <Slider
              step={termsRange.step}
              marks={termsRange.marks}
              rangeValue={termsRange.fullRangeValues}
              defaultValue={termsRange.defaultValue}
              value={this.state.sliderDeposit}
              sliderColor={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              onAfterChange={value =>
                requestFinanceUpdate(
                  value,
                  this.state.sliderDeposit,
                  [...this.getSelectedServiceOptions()],
                  productKey,
                )
              }
            />
          </RangeContainer>
        </SlidersContainer>
        {financeExample && (
          <ASContainer>
            <ASTitle>{translations.additionalServicesLabel}</ASTitle>
            <ASSection>
              {additionalServices
                .sort((a, b) => a.id - b.id)
                .map(({ id, selected, description, name, value }) => [
                  <Block>
                    <LabelledCheckBox
                      key={id}
                      label={
                        <OptionTitle>
                          {name}
                          {value &&
                            ` - ${localiseCurrency(
                              parseFloat(
                                value.replace('.', '').replace(',', '.'),
                              ),
                              locale,
                              currency,
                            )}`}
                        </OptionTitle>
                      }
                      description={description}
                      onColour={
                        globalStyling.colours.primaryBrandColour &&
                        globalStyling.colours.primaryBrandColour.value
                      }
                      checked={this.state.serviceOption[id] || selected}
                      onClick={() =>
                        this.checkServiceOption(
                          id,
                          duration,
                          this.state.sliderDeposit,
                        )
                      }
                      globalStyling={globalStyling}
                    />
                  </Block>,
                ])}
            </ASSection>
          </ASContainer>
        )}
        {financeExampleRenderProp()}
      </div>
    );
  };
}

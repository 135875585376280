// @flow
import styled from 'styled-components';

export default styled.div`
  width: 100%;
  background-image: url(${props => props.src});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: ${props => props.imagePosition || 'center'};
  min-height: ${props => props.imageHeight || 300}px;
`;

// @flow
import React, { type Node, Fragment } from 'react';
import styled from 'styled-components';
import path from 'ramda/src/path';
import { useSelector } from 'react-redux';

import Plus from './JaguarIcons/Global/Plus';
import Minus from './JaguarIcons/Global/Minus';
import Heart from './JaguarIcons/Global/Heart';
import Phone from './JaguarIcons/Global/Phone';
import Remove from './JaguarIcons/Global/Remove';
import Tick from './JaguarIcons/Global/Tick';
import CloseIcon from './JaguarIcons/Global/Close';
import {
  Button,
  HeadingTwo,
  Paragraph,
  HeadingThree,
  fontStyleOverride,
} from './Global';
import { localiseCurrency } from '../shared/localisation/numbers';
import { convertSecondaryPriceCurrency } from '../shared/currencyConversion/helpers';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 3% 0;
`;

const Container = styled.table.withConfig({
  displayName: 'CompareVehiclesContainer',
})`
  width: 95%;
  height: auto;
  margin: 0 2.5% 5% 2.5%;
  border-collapse: separate;
  border-spacing: 10px 0;
`;
const TableRow = styled.tr`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '20px')};
  &:nth-child(2) {
    td {
      ${props => !props.borderBottomOnly && 'border-top: 1px solid #d8d8d8'};
    }
  }
`;
const TableData = styled.td`
  border: solid #d8d8d8;
  border-width: ${props =>
    props.borderBottomOnly ? '0 0 1px 0' : '0px 1px 1px 1px'};
  padding: 10px;
  color: #444444;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const IconButton = styled.button.withConfig({
  displayName: 'IconButton',
})`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #444444;
  font-size: 15px;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.button.withConfig({
  displayName: 'IconWrapper',
})`
  background: none;
  border: none;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ImageOverlay = styled.div.withConfig({
  displayName: 'ImageOverlay',
})`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const CellLabel = styled.div`
  font-size: 14px;
  color: #8b8b8b;
  text-align: center;
`;

const CloseIconBlock = iconColour => (
  <IconWrapper>
    <CloseIcon width="1.5em" height="1.3125em" colour={iconColour || '#fff'} />
  </IconWrapper>
);

const TickIconBlock = iconColour => (
  <IconWrapper>
    <Tick
      width="1.5em"
      height="1.3125em"
      colour={iconColour || '#fff'}
      fill="#000"
    />
  </IconWrapper>
);

type RowProps = {
  content: string | Node,
  font: Font,
  secondaryContent?: string,
  secondaryLabel?: string,
};
const RenderRow = ({
  content,
  font,
  secondaryContent,
  secondaryLabel,
}: RowProps) => (
  <Fragment>
    <Paragraph
      styleOverride={() => `
      ${fontStyleOverride(font)}
      margin: 0;
    `}
    >
      {content}
    </Paragraph>
    {secondaryContent && (
      <Fragment>
        <Paragraph
          styleOverride={() => `
      ${fontStyleOverride(font)}
      margin: 0;
      line-height: 16px;
    `}
        >
          {secondaryContent}
        </Paragraph>
        <CellLabel>{secondaryLabel}</CellLabel>
      </Fragment>
    )}
  </Fragment>
);

const RetailerPhoneRow = ({
  comparedVehicles,
  phoneIconColour,
  font,
  primaryBrandFontColourOnIconButtons,
  borderBottomOnly,
  direction,
}: {
  comparedVehicles: Vehicle[],
  iconColour: string,
  font: Font,
  phoneIconColour: Font,
  primaryBrandFontColourOnIconButtons: boolean,
  borderBottomOnly: boolean,
  direction: string,
}) => (
  <TableRow
    fontSize={font && font.fontSize}
    borderBottomOnly={borderBottomOnly}
  >
    <td style={{ border: 'none' }} />
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`} borderBottomOnly={borderBottomOnly}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Phone
            width={primaryBrandFontColourOnIconButtons ? '1em' : '2em'}
            height="2em"
            colour={phoneIconColour}
          />
          <HeadingThree
            styleOverride={() => `
              font-size: 16px;
              ${direction === 'rtl' && 'direction: ltr;'};
            `}
          >
            {vehicle.retailerPhone || '-'}
          </HeadingThree>
        </div>
      </TableData>
    ))}
  </TableRow>
);
const MoreInfoRow = ({
  requestInformation,
  comparedVehicles,
  buttonLabel,
  buttonStyle,
  font,
  borderBottomOnly,
}: {
  requestInformation: (vehicle: Vehicle) => void,
  comparedVehicles: Vehicle[],
  buttonLabel: string,
  buttonStyle: string,
  font: Font,
  borderBottomOnly: boolean,
}) => (
  <TableRow
    fontSize={font && font.fontSize}
    borderBottomOnly={borderBottomOnly}
  >
    <td style={{ border: 'none' }} />
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`} borderBottomOnly={borderBottomOnly}>
        <div
          style={{
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => requestInformation(vehicle)}
            text={buttonLabel}
            applyStyle="primary"
            buttonStyle={buttonStyle}
            styleOverride={() => `
              width: 100%;
            `}
          />
        </div>
      </TableData>
    ))}
  </TableRow>
);

const ShortlistRow = ({
  comparedVehicles,
  shortlistVehicle,
  getShortListedIconColour,
  translations,
  globalStyling,
  font,
  primaryBrandFontColourOnIconButtons,
  borderBottomOnly,
}: {
  comparedVehicles: Vehicle[],
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  translations: Object,
  globalStyling: Object,
  font: Font,
  primaryBrandFontColourOnIconButtons: boolean,
  borderBottomOnly: boolean,
}) => (
  <TableRow
    fontSize={font && font.fontSize}
    borderBottomOnly={borderBottomOnly}
  >
    <td style={{ border: 'none' }} />
    {comparedVehicles.map(vehicle => (
      <TableData key={`${vehicle.id}`} borderBottomOnly={borderBottomOnly}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={() => shortlistVehicle(vehicle)}>
            <Heart
              width={primaryBrandFontColourOnIconButtons ? '1em' : '2em'}
              height="2em"
              colour={getShortListedIconColour(vehicle.id)}
            />
            <HeadingThree
              styleOverride={() => `
              padding-left: 10px;
              ${primaryBrandFontColourOnIconButtons &&
                `color: ${globalStyling &&
                  globalStyling.colours.primaryBrandColour.value};`}
            `}
            >
              {translations.compareVehiclesSaveText}
            </HeadingThree>
          </IconButton>
        </div>
      </TableData>
    ))}
  </TableRow>
);

type DataRowProps = {
  label: string,
  comparedVehicles: Vehicle[],
  keyValues: string[],
  borderBottom?: boolean,
  visible?: boolean,
  font: Font,
  borderBottomOnly: boolean,
  selector: Object => string,
  secondarySelector?: Function | boolean,
  secondaryLabel?: Function,
};

type EquipmentMetaDataRowProps = {
  label: string,
  comparedVehicles: Vehicle[],
  borderBottom: boolean,
  font: Font,
  globalStyling: globalStyling,
  iconColour: string,
  borderBottomOnly: boolean,
};

const hasDataToDisplayInRow = (comparedVehicles, keyValues, selector) =>
  [0, 1, 2].some(n =>
    keyValues
      ? path(keyValues, comparedVehicles[n])
      : selector(comparedVehicles[n]),
  );

const DataRow = ({
  label,
  comparedVehicles,
  keyValues,
  font,
  visible = true,
  borderBottomOnly,
  selector,
  secondarySelector,
  secondaryLabel,
}: DataRowProps) => {
  if (!hasDataToDisplayInRow(comparedVehicles, keyValues, selector)) {
    return <td style={{ border: 'none' }} />;
  }
  return visible ? (
    <TableRow
      fontSize={font && font.fontSize}
      borderBottomOnly={borderBottomOnly}
    >
      <TableData borderBottomOnly={borderBottomOnly}>
        <RenderRow content={label} />
      </TableData>
      {[0, 1, 2].map(n =>
        comparedVehicles[n] ? (
          <TableData
            key={`${comparedVehicles[n].id}`}
            borderBottomOnly={borderBottomOnly}
          >
            <RenderRow
              content={
                keyValues
                  ? path(keyValues, comparedVehicles[n]) || '-'
                  : selector(comparedVehicles[n])
              }
              secondaryContent={
                secondarySelector && secondarySelector(comparedVehicles[n])
              }
              secondaryLabel={
                secondaryLabel && secondaryLabel(comparedVehicles[n])
              }
              font={font}
            />
          </TableData>
        ) : (
          <td style={{ border: 'none' }} />
        ),
      )}
    </TableRow>
  ) : null;
};

DataRow.defaultProps = {
  borderBottom: false,
};

const getEquipmentMetaDataIcon = (
  vehicle: Vehicle,
  label: string,
  iconColour: string,
) => {
  const exists =
    vehicle.equipmentMetaData.filter(equipment => equipment === label).length >
    0;
  return exists ? TickIconBlock(iconColour) : CloseIconBlock(iconColour);
};

const EquipmentMetaDataRow = ({
  label,
  comparedVehicles,
  font,
  iconColour,
  borderBottomOnly,
}: EquipmentMetaDataRowProps) => (
  <TableRow
    fontSize={font && font.fontSize}
    borderBottomOnly={borderBottomOnly}
  >
    <TableData borderBottomOnly={borderBottomOnly}>
      <RenderRow content={label} alignment="left" />
    </TableData>
    {comparedVehicles.map(vehicle =>
      vehicle && vehicle.equipmentMetaData ? (
        <TableData key={`${vehicle.id}`} borderBottomOnly={borderBottomOnly}>
          <RenderRow
            content={getEquipmentMetaDataIcon(
              vehicle,
              label,
              iconColour || '#000000',
            )}
            font={font}
          />
        </TableData>
      ) : (
        <TableData key={`${vehicle.id}`} borderBottomOnly={borderBottomOnly}>
          <RenderRow
            content={CloseIconBlock(iconColour || '#000000')}
            font={font}
          />
        </TableData>
      ),
    )}
  </TableRow>
);

EquipmentMetaDataRow.defaultProps = {
  borderBottom: false,
};

type OptionalExtrasProps = {
  label: string,
  comparedVehicles: Vehicle[],
  optionalExtras: boolean,
  toggleOptionalExtras: () => void,
  font: Font,
  optionalExtrasBackGroundColour: string,
  borderBottomOnly: boolean,
};
const OptionalExtrasRow = ({
  label,
  comparedVehicles,
  optionalExtras,
  toggleOptionalExtras,
  font,
  optionalExtrasBackGroundColour,
  borderBottomOnly,
}: OptionalExtrasProps) => (
  <TableRow
    fontSize={font && font.fontSize}
    borderBottomOnly={borderBottomOnly}
  >
    <TableData
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: optionalExtrasBackGroundColour
          ? `${optionalExtrasBackGroundColour}`
          : '#f0f0f0',
        color: font && font.colour ? `${font.colour.value}` : '#000000',
        textTransform: 'uppercase',
        lineHeight: '40px',
        fontSize: font ? font.fontSize : 20,
      }}
      font={font}
      borderBottomOnly={borderBottomOnly}
    >
      {label}
      <IconButton onClick={toggleOptionalExtras}>
        {optionalExtras ? (
          <Minus width="2em" height="2em" colour="#7e7e7e" />
        ) : (
          <Plus width="2em" height="2em" colour="#7e7e7e" />
        )}
      </IconButton>
    </TableData>
    {comparedVehicles.map(vehicle => (
      <TableData
        font={font}
        key={`${vehicle.id}`}
        style={{
          backgroundColor: optionalExtrasBackGroundColour
            ? `${optionalExtrasBackGroundColour}`
            : '#f0f0f0',
        }}
        borderBottomOnly={borderBottomOnly}
      />
    ))}
  </TableRow>
);

type Props = {
  requestInformation: (vehicle: Vehicle) => void,
  translations: Object,
  removeVehicle: (vehicle: Vehicle) => void,
  comparedVehicles: Vehicle[],
  optionalExtras: boolean,
  toggleOptionalExtras: () => void,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  equipmentMetaData: string[],
  closeIconColour: string,
  phoneIconColour: string,
  gridConfiguration: Object,
  globalStyling: Object,
  headerFont: Font,
  infoFont: Font,
  subHeaderFont: Font,
  headerFont: Font,
  primaryBrandFontColourOnIconButtons: boolean,
  optionalExtrasBackgroundColour: string,
  borderBottomOnly: boolean,
  headerOverSubheader: boolean,
  locale: string,
};

export default function CompareVehicles(props: Props) {
  const {
    requestInformation,
    translations,
    optionalExtras,
    toggleOptionalExtras,
    comparedVehicles = [],
    removeVehicle,
    shortlistVehicle,
    getShortListedIconColour,
    equipmentMetaData,
    closeIconColour,
    phoneIconColour,
    buttonStyle,
    gridConfiguration,
    globalStyling,
    headerFont,
    infoFont,
    subHeaderFont,
    primaryBrandFontColourOnIconButtons,
    optionalExtrasBackgroundColour,
    borderBottomOnly,
    headerOverSubheader,
    locale,
  } = props;
  const headingStyleOverride = () => `
    ${fontStyleOverride(headerFont)}
    flex: 1 0 0%;`;

  const { exchangeRates, secondaryCurrency } = useSelector(state => ({
    exchangeRates: state.shared.currencyConversion.exchangeRates || null,
    secondaryCurrency:
      state.config.config.global.inventory.secondaryCurrency || null,
  }));

  return comparedVehicles.length > 0 ? (
    <Fragment>
      <HeaderContainer>
        {!headerOverSubheader && (
          <HeadingTwo styleOverride={headingStyleOverride}>
            {translations.compareVehiclesHeader}
          </HeadingTwo>
        )}
      </HeaderContainer>

      <Container>
        <tbody>
          <TableRow
            fontSize={subHeaderFont && subHeaderFont.fontSize}
            borderBottomOnly={borderBottomOnly}
          >
            <td style={{ width: '25%' }}>
              {headerOverSubheader && (
                <HeadingTwo styleOverride={headingStyleOverride}>
                  {translations.compareVehiclesHeader}
                </HeadingTwo>
              )}
              <Paragraph
                styleOverride={() => `
                font-size: ${
                  subHeaderFont ? `${subHeaderFont.fontSize}px` : '18px'
                };
                line-height: ${
                  subHeaderFont ? `${subHeaderFont.lineHeight}px` : '22px'
                };
                text-align: ${
                  subHeaderFont ? `${subHeaderFont.align}` : 'center'
                };
              `}
              >
                {translations.compareVehiclesSubHeader}
              </Paragraph>
            </td>
            {comparedVehicles.map(vehicle => (
              <td key={`${vehicle.id}`} style={{ width: '25%' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => removeVehicle(vehicle)}>
                    <Remove width="2em" height="2em" colour={closeIconColour} />
                  </IconButton>
                </div>
                <ImageContainer>
                  {vehicle.images.length > 0 ? (
                    <StyledImage
                      src={vehicle.images[0]}
                      width="100%"
                      alt={vehicle.name}
                    />
                  ) : (
                    <StyledImage
                      src="https://res.cloudinary.com/motortrak/image/upload/v1536583771/Placeholder%20Images/PlaceholderJAG.png"
                      alt="placeholderImage"
                    />
                  )}
                  {vehicle.status === 'sold' && (
                    <ImageOverlay>
                      <SoldHeading>{translations.sold}</SoldHeading>
                      <SoldBody>
                        {translations.similarVehiclesAvailable}
                      </SoldBody>
                    </ImageOverlay>
                  )}
                </ImageContainer>
              </td>
            ))}
          </TableRow>
          <DataRow
            label={translations.comparePrice}
            comparedVehicles={comparedVehicles}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
            selector={v =>
              localiseCurrency(v.price.value, locale, v.price.currency, 0)
            }
            secondarySelector={v =>
              secondaryCurrency &&
              exchangeRates[v.price.currency] &&
              localiseCurrency(
                convertSecondaryPriceCurrency(
                  v.price.value,
                  exchangeRates[v.price.currency].rates,
                  v.price.currency,
                  secondaryCurrency,
                ),
                locale,
                secondaryCurrency,
                0,
              )
            }
            secondaryLabel={v =>
              secondaryCurrency &&
              exchangeRates[v.price.currency] &&
              translateFromTemplate(
                'exchangeRate',
                {
                  EXCHANGERATE: exchangeRates[v.price.currency].rates[
                    secondaryCurrency
                  ].toFixed(2),
                },
                translations,
              )
            }
          />

          <DataRow
            label={translations.compareVehiclesModelYear}
            comparedVehicles={comparedVehicles}
            keyValues={['name']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesExterior}
            comparedVehicles={comparedVehicles}
            keyValues={['exterior']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesInterior}
            comparedVehicles={comparedVehicles}
            keyValues={['interior']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          {translations.compareVehiclesVeneer && (
            <DataRow
              label={translations.compareVehiclesVeneer}
              comparedVehicles={comparedVehicles}
              keyValues={['veneer']}
              font={infoFont}
              borderBottomOnly={borderBottomOnly}
            />
          )}
          <DataRow
            label={translations.compareVehiclesOdometer}
            comparedVehicles={comparedVehicles}
            keyValues={['odometer', 'display']}
            visible={gridConfiguration.showOdometer}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesTransmission}
            comparedVehicles={comparedVehicles}
            keyValues={['transmission']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          {translations.compareVehiclesEmissions && (
            <DataRow
              label={translations.compareVehiclesEmissions}
              comparedVehicles={comparedVehicles}
              keyValues={['performance', 'emissions']}
              font={infoFont}
              borderBottomOnly={borderBottomOnly}
            />
          )}
          {translations.compareVehiclesFuelConsumption && (
            <DataRow
              label={translations.compareVehiclesFuelConsumption}
              comparedVehicles={comparedVehicles}
              keyValues={['performance', 'fuelConsumption']}
              visible={gridConfiguration.showFuelConsumption}
              font={infoFont}
              borderBottomOnly={borderBottomOnly}
            />
          )}
          <DataRow
            label={translations.compareVehiclesBodystyle}
            comparedVehicles={comparedVehicles}
            keyValues={['bodystyle']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesEngine}
            comparedVehicles={comparedVehicles}
            keyValues={['engine']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesFuelType}
            comparedVehicles={comparedVehicles}
            keyValues={['fuel']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesRegistration}
            comparedVehicles={comparedVehicles}
            keyValues={['registration']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          <DataRow
            label={translations.compareVehiclesLocation}
            comparedVehicles={comparedVehicles}
            keyValues={['location']}
            font={infoFont}
            borderBottomOnly={borderBottomOnly}
          />
          {equipmentMetaData && equipmentMetaData.length && (
            <OptionalExtrasRow
              label={translations.compareVehiclesOptionalExtras}
              comparedVehicles={comparedVehicles}
              optionalExtras={optionalExtras}
              toggleOptionalExtras={toggleOptionalExtras}
              font={infoFont}
              iconColour={phoneIconColour}
              optionalExtrasBackgroundColour={optionalExtrasBackgroundColour}
              borderBottomOnly={borderBottomOnly}
            />
          )}
          {optionalExtras &&
            equipmentMetaData.map(equipment => (
              <EquipmentMetaDataRow
                key={`${equipment}`}
                label={equipment}
                comparedVehicles={comparedVehicles}
                font={infoFont}
                globalStyling={globalStyling}
                borderBottomOnly={borderBottomOnly}
              />
            ))}
          <RetailerPhoneRow
            comparedVehicles={comparedVehicles}
            globalStyling={globalStyling}
            phoneIconColour={phoneIconColour}
            font={infoFont}
            primaryBrandFontColourOnIconButtons={
              primaryBrandFontColourOnIconButtons
            }
            borderBottomOnly={borderBottomOnly}
            direction={globalStyling.direction}
          />
          <MoreInfoRow
            requestInformation={requestInformation}
            comparedVehicles={comparedVehicles}
            buttonLabel={translations.compareVehiclesRequestInfo}
            buttonStyle={buttonStyle}
            font={infoFont}
            primaryBrandFontColourOnIconButtons={
              primaryBrandFontColourOnIconButtons
            }
            borderBottomOnly={borderBottomOnly}
          />
          <ShortlistRow
            comparedVehicles={comparedVehicles}
            shortlistVehicle={shortlistVehicle}
            getShortListedIconColour={getShortListedIconColour}
            translations={translations}
            globalStyling={globalStyling}
            font={infoFont}
            primaryBrandFontColourOnIconButtons={
              primaryBrandFontColourOnIconButtons
            }
            borderBottomOnly={borderBottomOnly}
          />
        </tbody>
      </Container>
    </Fragment>
  ) : (
    <div style={{ width: '95%', margin: '5% 2.5%' }}>
      <HeadingTwo
        styleOverride={() => `
        font-size: ${headerFont ? `${headerFont.fontSize}px` : '26px'};
        text-transform: ${headerFont ? `${headerFont.transform}` : 'uppercase;'}
        text-align: ${headerFont ? `${headerFont.align}` : 'center;'};
        flex: 1 0 0%;
        letter-spacing: ${headerFont ? `${headerFont.kerning}px` : '3px;'};
      `}
      >
        {translations.compareVehiclesHeader}
      </HeadingTwo>
      <Paragraph
        styleOverride={() => `
          font-size: 18px;
          line-height: 22px;
          text-align: center;
        `}
      >
        {translations.compareVehiclesSubHeader}
      </Paragraph>
    </div>
  );
}

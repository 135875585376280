// @flow
import React from 'react';
import { connect } from 'react-redux';
import PathnameSubNavBar from '../components/PathnameSubNavBar';

import { actions as routerActions } from '../actions/router';

import type { Theme } from '../types';

const mapStateToProps = ({ shared }, props) => ({
  compare: shared && shared.compare.vehicles && shared.compare.vehicles.length,
  shortlist:
    shared && shared.shortlist.vehicles && shared.shortlist.vehicles.length,
  ...props,
});

const mapDispatchToProps = dispatch => ({
  goToPath: path => dispatch(routerActions.navigate(path)),
});

type Props = {
  pathByModule: string => string,
  goToPath: string => void,
  preview: boolean,
  title: string,
  compare: number,
  shortlist: number,
  onBackButtonClick: () => void,
  subNavBGColour: string,
  subNavBorderColour: string,
  subNavFontTheme: Theme,
  subNavChevronColour: string,
  translations: Object,
  subNavBarItemSelectedBackgroundColour: Option,
  subNavBarItemSelectedFontColour: Option,
  contentWidth?: number,
  showNavBarItemBorder: boolean,
  subNavHeight: string,
  alignMenuItems: string,
  navBarBottomBorder: string,
  globalStyling: Object,
};

function SubNav({
  goToPath,
  preview,
  title,
  compare,
  shortlist,
  onBackButtonClick,
  translations,
  subNavBGColour,
  subNavBorderColour,
  subNavChevronColour,
  subNavFontTheme,
  subNavBarItemSelectedBackgroundColour,
  subNavBarItemSelectedFontColour,
  contentWidth,
  showNavBarItemBorder,
  subNavHeight,
  alignMenuItems,
  navBarBottomBorder,
  globalStyling,
}: Props) {
  const fnIfNotPreview = fn => (preview ? () => {} : fn);
  return (
    <PathnameSubNavBar
      subNavFontTheme={subNavFontTheme}
      subNavBGColour={subNavBGColour}
      subNavBorderColour={subNavBorderColour}
      subNavChevronColour={subNavChevronColour}
      showNavBarItemBorder={showNavBarItemBorder}
      subNavHeight={subNavHeight}
      alignMenuItems={alignMenuItems}
      navBarBottomBorder={navBarBottomBorder}
      title={title}
      onBackButtonClick={onBackButtonClick}
      links={[
        {
          text: `${translations.compareLink} ${compare ? `(${compare})` : ''}`,
          onClick: fnIfNotPreview(() => goToPath('/compare')),
          availableOnMobile: false,
          path: 'compare',
        },
        {
          text: `${translations.shortlistLink} ${
            shortlist ? `(${shortlist})` : ''
          }`,
          onClick: fnIfNotPreview(() => goToPath('/shortlist')),
          availableOnMobile: true,
          path: 'shortlist',
        },
      ]}
      subNavBarItemSelectedBackgroundColour={
        subNavBarItemSelectedBackgroundColour
      }
      subNavBarItemSelectedFontColour={subNavBarItemSelectedFontColour}
      contentWidth={contentWidth}
      globalStyling={globalStyling}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubNav);

import Icon from '../../modules/Shortlist/Icon';

export { id as reducerId } from '../../modules/ShortlistGrid/reducer';

export { default as component } from './Shortlist';
export { default as effects } from '../../modules/ShortlistGrid/effects';
export { default as config } from '../../modules/Shortlist/config';
export {
  default as defaultConfig,
} from '../../modules/Shortlist/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/ShortlistGrid/reducer';

export const name = 'Shortlist';
export const id = 'Shortlist';
export const availableToAdministration = false;
export const description =
  'A module that displays the shortlisted vehicles of the user';
export const icon = Icon;

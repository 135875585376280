// @flow
import React from 'react';

import templates from './Templates';
import type { PostSummaryConfig } from './types';
import { useSummaryPost } from './useSummaryPost';

type Props = {
  config: PostSummaryConfig,
};

export default ({ config, config: { postId, template } }: Props) => {
  const postData = useSummaryPost(postId);
  const Template = templates[template];

  const podConfig = {
    ...config,
    ...postData,
    translations: {
      ...config.translations,
      ...(postData && { ...postData.translations }),
    },
  };

  return <Template config={podConfig} />;
};

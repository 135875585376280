// @flow
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from 'react-sortable-hoc';

const handle = renderFn => SortableHandle(renderFn);

const SortableList = SortableContainer(
  ({ items, renderItem, renderHandle, keyFn }) => (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={keyFn(value)}
          index={index}
          value={value}
          renderItem={renderItem}
          renderHandle={renderHandle}
          item={value}
          disabled={value.locked}
        />
      ))}
    </div>
  ),
);

const SortableItem = SortableElement(({ renderItem, renderHandle, item }) =>
  renderItem(renderHandle, item),
);

type Props = {
  items: { id: string | number }[],
  renderItem: (handleRenderer: Class, item: any) => Node,
  renderHandle: () => Node,
  onSortEnd: (list: any[]) => void,
  keyFn?: any => any,
};

export default function DragList({
  items,
  onSortEnd,
  renderItem,
  renderHandle,
  keyFn,
}: Props) {
  const handleRenderer = handle(renderHandle);

  return (
    <SortableList
      keyFn={keyFn}
      items={items}
      onSortEnd={({ oldIndex, newIndex }) =>
        onSortEnd(arrayMove(items, oldIndex, newIndex))
      }
      renderItem={renderItem}
      renderHandle={handleRenderer}
      useDragHandle
    />
  );
}

DragList.defaultProps = {
  items: [],
  onChange: () => {},
  onSort: () => {},
  keyFn: ({ id }) => id,
};

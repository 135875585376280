import Icon from '../../modules/Enquiry/Icon';

export { id as reducerId } from '../../modules/Enquiry/reducer';

export { default as component } from './Enquiry';
export { default as effects } from '../../modules/Enquiry/effects';
export { default as config } from '../../modules/Enquiry/config';
export { default as defaultConfig } from '../../modules/Enquiry/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/Enquiry/reducer';

export const name = 'Enquiry';
export const id = 'Enquiry';
export const availableToAdministration = false;
export const description = 'Enquiry Page';
export const icon = Icon;

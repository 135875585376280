// @flow
import React from 'react';

import { Wrapper, Image } from '../components';
import type { Option } from '../../types';

interface IImageConfig {
  image: Option;
  imageHeight: number;
  translations: Object;
}

type Props = {
  config: IImageConfig,
};

export default ({
  config: {
    translations: { imageLabel },
    image: { src },
    imageHeight,
  },
}: Props) => (
  <Wrapper>
    <Image {...{ src, imageHeight }} role="img" aria-label={imageLabel} />
  </Wrapper>
);

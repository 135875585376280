// @flow

import React from 'react';
import styled from 'styled-components';

import CheckboxUnchecked from './JaguarIcons/Global/CheckboxUnchecked';
import Tick from './JaguarIcons/Global/Tick';

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
`;

const Label = styled.div`
  font-size: ${({ labelFontSize }) =>
    labelFontSize ? `${labelFontSize}px` : '16px'};
  color: ${({ colour }) => colour || `#444444`};
  padding: ${({ direction }) =>
    direction === 'rtl' ? '10px 14px 0px 20px' : '10px 20px 0px 14px'};
`;

const Body = styled.div`
  font-size: 14px;
  color: #7e7e7e;
  padding: 0px 20px 0px 14px;
`;

const Checkbox = ({
  checked,
  onColour,
  checkboxDimension,
}: {
  checked: boolean,
  offColour?: string,
  onColour?: string,
  checkboxDimension?: Number,
}) =>
  checked ? (
    <Tick
      background={onColour}
      colour="#fff"
      fill="#FFF"
      width={`${checkboxDimension || 1.5}em`}
      height={`${checkboxDimension || 1.5}em`}
    />
  ) : (
    <CheckboxUnchecked
      width={`${checkboxDimension || 1.5}em`}
      height={`${checkboxDimension || 1.5}em`}
    />
  );
Checkbox.defaultProps = {
  offColour: '#000000',
  onColour: '#000000',
};

type Props = {
  checked: boolean,
  onClick: () => void,
  label: string,
  description?: string,
  offColour?: string,
  onColour?: string,
  labelFontSize?: Number,
  checkboxDimension?: Number,
  globalStyling?: styling,
  colour?: string,
};

export default function LabelledCheckBox({
  onClick,
  checked,
  label,
  description,
  offColour,
  onColour,
  labelFontSize,
  checkboxDimension,
  globalStyling,
  colour,
}: Props) {
  return (
    <Container onClick={onClick}>
      <Checkbox
        checked={checked}
        offColour={offColour}
        onColour={onColour}
        checkboxDimension={checkboxDimension}
      />
      <div>
        <Label
          labelFontSize={labelFontSize}
          colour={colour}
          direction={globalStyling.direction}
        >
          {label}
        </Label>
        {description && <Body>{description}</Body>}
      </div>
    </Container>
  );
}
LabelledCheckBox.defaultProps = {
  offColour: '#000000',
  onColour: '#000000',
  description: '',
};

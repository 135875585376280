import Icon from './Icon';

export { default as component } from './CookiePolicy';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'CookiePolicy';
export const id = 'CookiePolicy';
export const availableToAdministration = false;
export const description =
  'Add Cookies Policy to inform users about the use of cookies by a website or an app.';
export const icon = Icon;
export const global = true;
export const required = false;
export const position = 1;

// @flow
import React from 'react';
import styled from 'styled-components';

import SearchInput from '../../components/SearchInput';
import media from '../../theme';
import {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Paragraph,
} from '../../components/Global';
import type { State } from './reducer';
import { actions as routerActions } from '../../actions/router';

type ResultProps = {
  title: string,
  content: string,
  link: string,
  colour: string,
};

type SiteSearchProps = {
  config: { translations: Object },
  actions: {
    searchFor: (term: ?string) => void,
  },
  state: State,
  history: Object,
  pathByModule: string => any,
  dispatch: Function,
  globalStyling: Object,
};

const InputContainer = styled.div.withConfig({
  displayName: 'InputContainer',
})`
  height: 100px;
  background-color: #f0f0f0;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
`;

const ResultsContainer = styled.div.withConfig({
  displayName: 'ResultsContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
`;

const ResultItem = styled.div.withConfig({
  displayName: 'ResultItem',
})`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 3%;
  margin: 1% 0;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  padding: 16px 24px;
  ${media.max.large`
    width: 90%;
    padding: 3%;
    margin: 3% 0;
  `};
`;

const Link = styled.a.withConfig({
  displayName: 'Link',
})`
  color: ${props => (props.colour ? `${props.colour}` : 'unset')};
  font-size: 16px;
  line-height: 22px;
  padding: 1% 0;
`;

const InfoContainer = styled.span.withConfig({
  displayName: 'InfoContainer',
})`
  display: flex;
  align-self: flex-start;
  padding-left: 12%;
  ${media.max.large`
    padding: 0;
  `};
`;

const NoResultsContainer = styled.span.withConfig({
  displayName: 'NoResultsContainer',
})`
  display: flex;
  flex-direction: column;
`;

const Hints = styled.span.withConfig({
  displayName: 'Hints',
})`
  color: #757575;
  font-size: 16px;
  line-height: 22px;
`;

type InfoProps = {
  resultsCount: number,
  hints: string,
  noResultMessage: string,
  resultsMessage: string,
};

const Info = ({
  resultsCount,
  hints,
  noResultMessage,
  resultsMessage,
}: InfoProps) => (
  <InfoContainer>
    {resultsCount ? (
      <HeadingThree
        styleOverride={() => `
          font-size: 16px;
        `}
      >
        {`${resultsCount} ${resultsMessage}`}
      </HeadingThree>
    ) : (
      <NoResultsContainer>
        <Paragraph>{noResultMessage}</Paragraph>
        <Hints>{hints}</Hints>
      </NoResultsContainer>
    )}
  </InfoContainer>
);

const Result = ({ title, content, link, colour }: ResultProps) => (
  <ResultItem>
    <HeadingTwo>{title}</HeadingTwo>
    <Paragraph>{content}</Paragraph>
    <Link colour={colour} href={link}>
      {link}
    </Link>
  </ResultItem>
);

const getInitialQueryTerm = ({
  history: { location: { search } = {} },
  queryPattern,
}) =>
  search && search.startsWith(queryPattern)
    ? search.replace(queryPattern, '')
    : undefined;

export default class SiteSearch extends React.Component<SiteSearchProps, *> {
  state = {
    term: getInitialQueryTerm({
      history: this.props.history,
      queryPattern: '?query=',
    }),
  };

  componentDidMount = () => {
    const {
      actions: { searchFor },
    } = this.props;
    if (this.state.term !== undefined) {
      searchFor(this.state.term);
    }
  };

  handleChange = (term: string) => this.setState(() => ({ term }));

  render() {
    const {
      state: { searchResults = [] },
      config: { translations } = {},
      actions: { searchFor },
      pathByModule,
      dispatch,
      globalStyling,
    } = this.props;
    return (
      <div>
        <HeadingOne
          styleOverride={() => `
            text-transform: uppercase;
            text-align: center;
            color: #474747;
            font-size: 24;
          `}
        >
          {translations.header}
        </HeadingOne>
        <InputContainer>
          <SearchInput
            placeholder=""
            value={this.state.term}
            onSearch={term => {
              dispatch(
                routerActions.replace(
                  `${pathByModule('SiteSearch')}?query=${term || ''}`,
                ),
              );
              searchFor(term);
            }}
            onChange={this.handleChange}
            globalStyling={globalStyling}
          />
        </InputContainer>
        <ResultsContainer>
          <Info
            resultsCount={searchResults.length}
            hints={translations.hints}
            noResultMessage={translations.noResultMessage}
            resultsMessage={translations.resultsMessage}
          />
          {searchResults.map(({ title, content, link, key }) => (
            <Result
              key={key}
              title={title}
              content={content}
              link={link}
              colour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
            />
          ))}
        </ResultsContainer>
      </div>
    );
  }
}

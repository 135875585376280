// @flow
import React from 'react';
import styled from 'styled-components';

import VehicleGallery from './VehicleGallery';
import Compare from './JaguarIcons/Global/Compare';
import Heart from './JaguarIcons/Global/Heart';
import media from '../theme';
import { HeadingTwo, HeadingThree } from './Global';
import { localeFormattedDate } from '../shared/localisation/dates';
import { localiseNumber } from '../shared/localisation/numbers';
import { labelWithIcon } from './VdpHighlights';
import { location, distanceLabel } from './SearchResultGridBlock';

const Container = styled.div.withConfig({
  displayName: 'VehicleGalleryContainer',
})`
  width: 60%;
  ${media.max.large`
    width: 100%;
    height: 50%;
  `};
`;

const VehicleHeaderBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px 16px;
  ${media.max.large`
    padding: 0 5% 8px 5%;
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
`;
const SingleButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
`;
const ButtonLabel = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #7e7e7e;
`;
const IconWrapper = styled.div`
  display: flex;
  div:first-child {
    margin: auto;
  }
`;
const MobileOnly = styled.div`
  display: none;
  ${media.max.medium`
    display: block;
  `};
`;

const DesktopOnly = styled.div`
  display: flex;
  align-items: flex-end;
  ${media.max.medium`
  display: none;
`};
`;
const MediaWrapper = styled.div`
  width: 100%;
`;

const formattedLocale = locale => locale.replace('_', '-');

// eslint-disable-next-line no-confusing-arrow
export const formattedVehicleDescription = (
  type,
  {
    description,
    registration,
    transmissionType,
    specification: { fuelType, odometer },
    locale,
  },
) =>
  type === 'VehicleDescription'
    ? description
    : ` ${localeFormattedDate(registration, formattedLocale(locale), {
        year: 'numeric',
      })} | ${transmissionType} | ${fuelType} | ${localiseNumber(
        odometer.reading,
        locale,
      )} ${odometer.units}`;

const VehicleSubHeader = ({
  subHeaderType,
  vehicleInfo,
}: {
  vehicleInfo: Object,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
}) => (
  <HeadingThree styleOverride={() => 'font-size: 14px;'}>
    {formattedVehicleDescription(subHeaderType, vehicleInfo)}
  </HeadingThree>
);

type HeaderBarProps = {
  shortListedIconColour: string,
  compareIconColour: string,
  vehicleInfo: Vehicle,
  translations: Object,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  compareVehicle: (vehicle: Vehicle) => void,
  shortlistVehicle: (vehicle: Vehicle) => void,
  vehicleHeadingColour: Object,
  goToAnchor: (element: string) => void,
  iconColour: string,
  title: string,
  pinIcon: string,
  retailerAndDistanceLabel: string,
};

type Props = {
  shortListedIconColour: string,
  compareIconColour: string,
  vehicleImages: Object[],
  placeholdingImage: string,
  vehicleInfo: Vehicle,
  translations: Object,
  subHeaderType: 'VehicleDescription' | 'VehicleInformation',
  compareVehicle: (vehicle: Vehicle) => void,
  shortlistVehicle: (vehicle: Vehicle) => void,
  vehicleHeadingColour: Object,
  rotateGallery: boolean,
  goToAnchor: (element: string) => void,
  iconColour: string,
  imageCarousel: Object,
  title: string,
  pinIcon: string,
  data360: Object,
  get360Info: Function,
  globalStyling: Object,
};

const VehicleHeaderBar = ({
  translations,
  vehicleInfo,
  compareVehicle,
  shortlistVehicle,
  shortListedIconColour,
  compareIconColour,
  subHeaderType,
  vehicleHeadingColour,
  goToAnchor,
  pinIcon,
  title,
  retailerAndDistanceLabel,
}: HeaderBarProps) => (
  <VehicleHeaderBarContainer>
    <div data-qa-hook="vehicle-title-description">
      <HeadingTwo
        styleOverride={() => `
          font-size: 25px;
          text-transform: uppercase;
          ${vehicleHeadingColour && `color: ${vehicleHeadingColour.value}`};
          letter-spacing: ${vehicleInfo.finance ? 'inherit' : '0.72px'};
        `}
      >
        {title}
      </HeadingTwo>
      <VehicleSubHeader
        subHeaderType={subHeaderType}
        vehicleInfo={vehicleInfo}
      />
      {labelWithIcon(retailerAndDistanceLabel, pinIcon, () =>
        goToAnchor('retailerMap'),
      )}
    </div>
    <DesktopOnly>
      <ButtonsContainer>
        <SingleButton
          data-qa-hook="compare-vehicle-button"
          onClick={() => compareVehicle(vehicleInfo)}
        >
          <IconWrapper>
            <Compare width="18px" height="18px" colour={compareIconColour} />
          </IconWrapper>
          <ButtonLabel>{translations.compareActionLabel}</ButtonLabel>
        </SingleButton>
        <SingleButton
          data-qa-hook="shortlist-vehicle-button"
          onClick={() => shortlistVehicle(vehicleInfo)}
        >
          <IconWrapper>
            <Heart width="18px" height="18px" colour={shortListedIconColour} />
          </IconWrapper>
          <ButtonLabel>{translations.shortlistActionLabel}</ButtonLabel>
        </SingleButton>
      </ButtonsContainer>
    </DesktopOnly>
    <MobileOnly>
      <ButtonsContainer>
        <SingleButton noBorder onClick={() => shortlistVehicle(vehicleInfo)}>
          <IconWrapper>
            <Heart width="28px" height="25px" colour={shortListedIconColour} />
          </IconWrapper>
        </SingleButton>
      </ButtonsContainer>
    </MobileOnly>
  </VehicleHeaderBarContainer>
);

export default function VdpGalleryRr(props: Props) {
  return (
    <Container>
      <VehicleHeaderBar
        title={props.title}
        shortListedIconColour={props.shortListedIconColour}
        compareIconColour={props.compareIconColour}
        translations={props.translations}
        vehicleInfo={props.vehicleInfo}
        compareVehicle={props.compareVehicle}
        shortlistVehicle={props.shortlistVehicle}
        subHeaderType={props.subHeaderType}
        vehicleHeadingColour={props.vehicleHeadingColour}
        goToAnchor={props.goToAnchor}
        iconColour={props.iconColour}
        pinIcon={props.pinIcon}
        retailerAndDistanceLabel={location(
          props.vehicleInfo.retailerInformation &&
            props.vehicleInfo.retailerInformation.name,
          distanceLabel(
            props.vehicleInfo.retailerInformation &&
              props.vehicleInfo.retailerInformation.distanceToRetailer,
            props.translations,
          ),
        )}
      />
      <MediaWrapper data-qa-hook="vehicle-gallery">
        <VehicleGallery
          config={props.vehicleImages}
          placeholdingImage={props.placeholdingImage}
          views={props.vehicleInfo.views}
          vehicleInfo={props.vehicleInfo}
          rotateGallery
          isLooping={false}
          imageCarousel={props.imageCarousel}
          data360={props.data360}
          get360Info={props.get360Info}
          globalStyling={props.globalStyling}
          translations={props.translations}
        />
      </MediaWrapper>
    </Container>
  );
}

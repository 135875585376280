// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Remove({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 22 22"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <circle id="a" cx={11} cy={11} r={11} />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <use fill={colour || '#9E1B32'} xlinkHref="#a" />
            <circle stroke="#FFF" cx={11} cy={11} r={10.5} />
          </g>
          <path fill="#FFF" d="M7 13.477L13.478 7l1.298 1.298-6.477 6.477z" />
          <path fill="#FFF" d="M8.298 7l6.477 6.478-1.298 1.298L7 8.299z" />
        </g>
      </svg>
    </IconWrapper>
  );
}

Remove.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

import { normalize } from 'normalizr';
import { pageEntity, pageArray, globalArray } from './configSchema';

const normalisePage = page => {
  const {
    entities: { pages },
  } = normalize(page, pageEntity);
  return Object.values(pages)[0];
};
const normalisePages = pages => normalize(pages, pageArray);
const normaliseGlobal = modules => normalize(modules, globalArray);

export { normalisePage, normalisePages, normaliseGlobal };

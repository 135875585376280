// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CheckboxSwitch, InputField, FieldLabel } from 'cms-ui';
import Toggle from '../components/Toggle';
import LinkDropdown from '../components/LinkDropdown';

import { type FieldProps } from '../models/fields';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(props: ThemeProps) => `${props.theme.fontFamily}`};
`;

const LinkContainerInputs = styled.div`
  padding-top: ${(props: ThemeProps) =>
    `${props.theme.layout.standardPadding}`};
`;

const Margin = styled.span`
  height: 10px;
  display: flex;
`;

export default function Link({
  name,
  values,
  ids,
  onChange,
  data,
  siteId,
  actions,
}: FieldProps) {
  const { button, text } = values;
  const { button: buttonId, text: translationId } = ids;
  const searchUtilities = useSelector(
    state => state.searchUtilities.searchUtilities,
  );

  const options = [
    ...data.pages.map(({ pageName, relativePath }) => ({
      label: pageName,
      url: relativePath.replace('/', ''),
    })),
    ...data.externalLinks.map(({ name: n, url }) => ({ label: n, url })),
    ...searchUtilities.map(item => ({
      label: item.name,
      url: item.url,
    })),
  ];

  const clearValue = on =>
    !on ? onChange(buttonId) : onChange(buttonId, { ...button, on });

  return (
    <Toggle defaultOn={button} onToggle={on => clearValue(on)}>
      {({ on, onClick }) => (
        <LinkContainer>
          <FieldLabel label={name} inputFlexMode="0">
            <CheckboxSwitch
              name={`${name}-toggle`}
              onChange={onClick}
              value={on}
            />
          </FieldLabel>
          {on && (
            <LinkContainerInputs>
              <LinkDropdown
                name={`${name}-dropdown`}
                actions={actions}
                siteId={siteId}
                options={options}
                value={button}
                onChange={event => onChange(buttonId, event)}
              />
              <Margin />
              <InputField
                input={{
                  onChange: e => onChange(translationId, e.target.value),
                  name: `${name}-text-input`,
                  value: text,
                }}
                type="text"
                placeholder="text..."
              />
            </LinkContainerInputs>
          )}
        </LinkContainer>
      )}
    </Toggle>
  );
}

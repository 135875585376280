// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import FormField from './FormField';

const Input = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-family: inherit;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#9e1b32' : '#d8d8d8')};
  padding-left: 15px;
  padding-right: 15px;
  color: #7e7e7e;
  background-color: #fff;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
  ${props =>
    props.truncateChars &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

type Props = {
  doubleRow: boolean,
  label: string,
  required: boolean,
  type: string,
  keyValue: string,
  value: string,
  onChange: (
    keyValue: string,
    value: string,
    translations: Object,
    validate: boolean,
  ) => void,
  onKeyPress: (event: Object) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  globalStyling: Object,
};

export default function InputField({
  doubleRow,
  label,
  required,
  type,
  keyValue,
  value,
  onChange,
  onKeyPress,
  error,
  disabled,
  placeholder,
  translations,
  validate,
  truncateChars = false,
  globalStyling,
}: Props) {
  const { direction = '' } = globalStyling || {};
  return (
    <FormField
      doubleRow={doubleRow}
      label={label}
      required={required}
      globalStyling={globalStyling}
    >
      <Input
        type={type}
        value={value}
        onChange={e =>
          onChange(keyValue, e.target.value, translations, validate)
        }
        onKeyPress={onKeyPress}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        truncateChars={truncateChars}
        globalStyling={globalStyling}
        dir={direction}
      />
      {error && (
        <div
          style={{
            color: '#9e1b32',
            textAlign: `${direction === 'rtl' ? 'right' : 'left'}`,
          }}
        >
          {error}
        </div>
      )}
    </FormField>
  );
}
InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  doubleRow: false,
  onChange: () => {},
  onKeyPress: () => {},
  label: '',
  error: '',
  validate: false,
};

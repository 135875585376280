// @flow
import React from 'react';
import styled from 'styled-components';
import Chunky from './Chunky';

const Wrapper = styled.div`
  transform: rotate(180deg);
`;

export default function ThinLeft(props: IconProps) {
  return (
    <Wrapper>
      <Chunky {...props} />
    </Wrapper>
  );
}

ThinLeft.defaultProps = {
  width: '1em',
  height: '1em',
  colour: '#fff',
  background: null,
};

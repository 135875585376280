import { localiseCurrency } from '../localisation/numbers';

const getCurrency = data => (data && data.currency) || 'EUR';

export const getFinanceDisplayList = (data, globalFinance) => {
  const { translations = {}, locale } = globalFinance;
  let financeExampleDisplay =
    globalFinance.financeExampleDisplay[data.financingType];

  if (!financeExampleDisplay) {
    financeExampleDisplay =
      globalFinance.financeExampleDisplay[globalFinance.defaultProduct];
  }

  const currency = getCurrency(data);
  const transformExpenses = () => {
    let objectExpenses = [];
    if (data && data.expenses.length > 0) {
      objectExpenses = data.expenses.map(({ name, value }) => ({
        key: 'expenses',
        label: name,
        value: localiseCurrency(
          parseFloat(value.replace('.', '').replace(',', '.')),
          locale,
          currency,
        ),
      }));
    }
    if (data && data.additionalServices.length > 0) {
      const additional = data.additionalServices
        .filter(o => o.value !== '')
        .map(({ name, value }) => ({
          key: 'services',
          label: name,
          value: localiseCurrency(
            parseFloat(value.replace('.', '').replace(',', '.')),
            locale,
            currency,
          ),
        }));

      const additionalHeader = globalFinance.showServicesTitle &&
        additional.length > 0 && {
          key: 'services',
          label: translations.optionalServicesTitle,
        };

      objectExpenses = [
        additionalHeader,
        ...additional,
        ...objectExpenses,
      ].filter(Boolean);
    }
    return objectExpenses;
  };

  return data
    ? [
        {
          key: 'monthlyPrice',
          label: translations.financeMonthlyPayment,
          value:
            data.monthlyPrice &&
            localiseCurrency(
              parseFloat(data.monthlyPrice.replace('.', '').replace(',', '.')),
              locale,
              currency,
            ),
        },
        {
          key: 'numberOfPayments',
          label: translations.financeNumberOfPayments,
          value: data.numberOfPayments,
        },
        {
          key: 'totalCost',
          label: translations.financeTotalCost,
          value:
            data.totalPayable &&
            localiseCurrency(
              parseFloat(data.totalPayable.replace('.', '').replace(',', '.')),
              locale,
              currency,
            ),
        },
        {
          key: 'deposit',
          label: translations.financeDeposit,
          value:
            data.downpayment &&
            localiseCurrency(data.downpayment, locale, currency),
        },
        {
          key: 'apr',
          label: translations.financeAPR,
          value: data.apr && `${data.apr} %`,
        },
        {
          key: 'vehiclePrice',
          label: translations.financeVehiclePrice,
          value:
            data.vehiclePrice &&
            localiseCurrency(data.vehiclePrice, locale, currency),
        },
        {
          key: 'interestRate',
          label: translations.financeInterestRate,
          value: data.interestRate && `${data.interestRate} %`,
        },
        {
          key: 'duration',
          label: translations.financeDurationOfAgreement,
          value: data.duration,
        },
        {
          key: 'totalAmount',
          label: translations.financeTotalAmount,
          value:
            data.totalAmount &&
            localiseCurrency(
              parseFloat(data.totalAmount.replace('.', '').replace(',', '.')),
              locale,
              currency,
            ),
        },
        {
          key: 'totalAmountOwed',
          label: translations.financeTotalAmountOwed,
          value:
            data.totalAmountOwed &&
            localiseCurrency(
              parseFloat(
                data.totalAmountOwed.replace('.', '').replace(',', '.'),
              ),
              locale,
              currency,
            ),
        },
        {
          key: 'totalCredit',
          label: translations.financeTotalCredit,
          value:
            data.totalCredit &&
            localiseCurrency(
              parseFloat(data.totalCredit.replace('.', '').replace(',', '.')),
              locale,
              currency,
            ),
        },
        {
          key: 'financedAmount',
          label: translations.financedAmount,
          value:
            data.financedAmount &&
            localiseCurrency(
              parseFloat(
                data.financedAmount.replace('.', '').replace(',', '.'),
              ),
              locale,
              currency,
            ),
        },
        {
          key: 'totalInstallments',
          label: translations.financeTotalInstalments,
          value:
            data.totalInstallmentsAmount &&
            localiseCurrency(
              parseFloat(
                data.totalInstallmentsAmount.replace('.', '').replace(',', '.'),
              ),
              locale,
              currency,
            ),
        },
        {
          key: 'maxKm',
          label: translations.financeMaxKm,
          value: data.maxKilometres && `${data.maxKilometres} km`,
        },
        {
          key: 'paymentCosts',
          label: data.paymentCosts && data.paymentCosts.name,
          value:
            data.paymentCosts &&
            data.paymentCosts.value &&
            localiseCurrency(
              parseFloat(
                data.paymentCosts.value.replace('.', '').replace(',', '.'),
              ),
              locale,
              currency,
            ),
        },
        {
          key: 'gfv',
          label: translations.financeGfv,
          value:
            data.guaranteedResidualValue &&
            localiseCurrency(
              parseFloat(
                data.guaranteedResidualValue.replace('.', '').replace(',', '.'),
              ),
              locale,
              currency,
            ),
        },
        {
          key: 'finalPayment',
          label: translations.financeFinalPayment,
          value:
            data.finalPayment &&
            localiseCurrency(
              parseFloat(data.finalPayment.replace('.', '').replace(',', '.')),
              locale,
              currency,
            ),
        },
        ...transformExpenses(),
      ]
        .filter(({ key }) => financeExampleDisplay.includes(key))
        .sort(
          (a, b) =>
            financeExampleDisplay.findIndex(i => i === a.key) -
            financeExampleDisplay.findIndex(i => i === b.key),
        )
    : [];
};

export const getFinanceHeaderDisplayList = (
  data,
  globalFinance,
  financeLoaded = true,
) => {
  const { translations = {}, locale } = globalFinance;
  const { vehiclePrice, monthlyPrice, apr, interestRate } = data;
  const currency = getCurrency(data);

  return [
    {
      key: 'vehiclePrice',
      label: translations.financeVehiclePrice,
      value: localiseCurrency(vehiclePrice, locale, currency, 0),
    },
    financeLoaded && {
      key: 'monthlyPrice',
      label: translations.financeMonthlyHeadlinePayment,
      value:
        localiseCurrency(
          monthlyPrice &&
            parseFloat(monthlyPrice.replace('.', '').replace(',', '.')),
          locale,
          currency,
          2,
        ) || translations.searchResultsNotAvailableLabel,
    },
    financeLoaded && {
      key: 'apr',
      label: translations.financeAPR,
      value: `${apr || translations.searchResultsNotAvailableLabel}%`,
    },
    financeLoaded && {
      key: 'interestRate',
      label: translations.financeInterestRate,
      value: `${interestRate || translations.searchResultsNotAvailableLabel}%`,
    },
  ]
    .filter(Boolean)
    .sort(
      (a, b) =>
        globalFinance.financeHeadingDisplay.findIndex(i => i === a.key) -
        globalFinance.financeHeadingDisplay.findIndex(i => i === b.key),
    );
};

export const reorderFinanceDisplayList = items => {
  const splitAt = Math.ceil(items.length / 2);
  const front = items.slice(0, splitAt);
  const back = items.slice(splitAt);

  return front.reduce(
    (acc, curr, index) => [
      ...acc,
      curr,
      back[index] || { display: '', value: '' },
    ],
    [],
  );
};

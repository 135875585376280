// @flow
import { constants } from '../actions/translation';
import type {
  Action,
  StagedFilePayload,
  TranslationUploadedSuccessPayload,
} from '../actions/translation';
import type { State, Translation } from '../types/translation';

const initialState: State = {
  translations: [],
  error: undefined,
};

const stageFileOnTranslation = (
  translation: Translation,
  payload: StagedFilePayload,
) => ({
  ...translation,
  filename: payload.file.name,
  file: payload.file,
  success: undefined,
  failure: undefined,
});

const markTranslationAsUploading = (t: Translation) => ({
  ...t,
  uploading: true,
});

const markTranslationUploadedSuccessfully = (
  t: Translation,
  payload: TranslationUploadedSuccessPayload,
) => ({
  ...t,
  uploading: false,
  lastUpdated: payload.lastUpdated,
  filename: undefined,
  file: undefined,
  success: true,
  failure: undefined,
});

const markTranslationUploadFailed = (t: Translation) => ({
  ...t,
  uploading: false,
  success: undefined,
  failure: true,
});

const updateTranslation = (
  translations: Translation[],
  match: Translation => boolean,
  fn: Translation => Translation,
) => translations.map(t => (match(t) ? fn(t) : t));

export default function reducer(state: State = initialState, action: Action) {
  const idMatch = t => t.id === action.payload.id;

  switch (action.type) {
    case constants.StageFileForImport:
      return {
        ...state,
        translations: updateTranslation(state.translations, idMatch, t =>
          stageFileOnTranslation(t, action.payload),
        ),
      };
    case constants.LoadTranslationsSuccess:
      return {
        ...state,
        error: undefined,
        translations: action.payload.translations,
      };
    case constants.LoadTranslationsFailure:
      return {
        ...state,
        error: action.payload,
      };
    case constants.MarkTranslationAsUploading:
      return {
        ...state,
        translations: updateTranslation(state.translations, idMatch, t =>
          markTranslationAsUploading(t),
        ),
      };
    case constants.TranslationUploadedSuccess:
      return {
        ...state,
        translations: updateTranslation(state.translations, idMatch, t =>
          markTranslationUploadedSuccessfully(t, action.payload),
        ),
      };
    case constants.TranslationUploadFailure:
      return {
        ...state,
        translations: updateTranslation(state.translations, idMatch, t =>
          markTranslationUploadFailed(t),
        ),
      };
    default:
      return state;
  }
}

export default function() {
  return {
    translations: {
      similarVehiclesTitle: 'Title Placeholder',
    },
    headlineFont: {
      fontSize: 24,
      transform: 'uppercase',
      kerning: 1,
    },
  };
}

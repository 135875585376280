import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import media from '../theme';
import GlobalStyling from '../components/GlobalStyling';

import CompiledModule from './CompiledModule';
import * as bands from '../bands';
import useSite from './useSite';

const Band = bands.Band.component;

const RenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  ${media.max.large`
    margin: 0;
    width: 100%;
    position: static;
  `};
  @media print {
    display: block;
  }
`;

const GutterRenderer = ({ featureFlags, children }) =>
  featureFlags.useGutters ? (
    <div style={{ backgroundColor: '#181818' }}>
      <div style={{ margin: '0px auto', maxWidth: '1366px' }}>{children}</div>
    </div>
  ) : (
    <div>{children}</div>
  );

const compileGlobal = ({ band, globalStyling, path, index }) => {
  const Component = band.id === 'Band' ? Band : CompiledModule;
  return (
    <Component
      key={band.instanceId}
      band={band}
      path={path}
      globalStyling={globalStyling}
      index={index}
    />
  );
};

const bodyId = '$$BODY$$';

export default ({ globalStyling, path }) => {
  const { analytics, featureFlags, siteBands, Body } = useSite();
  return (
    <GutterRenderer featureFlags={featureFlags}>
      <RenderContainer className="content">
        <GlobalStyling globalStyling={globalStyling} />
        <Helmet>
          {analytics &&
            analytics.googleTagManagerIds &&
            analytics.googleTagManagerIds.map(gtmId => (
              <script
                key={`gtm-${gtmId}`}
                src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}`}
              />
            ))}
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'gtm.start': new Date().getTime(),event:'gtm.js'});`}
          </script>
        </Helmet>
        {siteBands.map((band, index) =>
          band.id === bodyId || band.name === bodyId ? (
            <Body key={bodyId} path={path} globalStyling={globalStyling} />
          ) : (
            compileGlobal({
              band,
              index,
              globalStyling,
              path,
            })
          ),
        )}
      </RenderContainer>
    </GutterRenderer>
  );
};

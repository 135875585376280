// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Warranty({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width={width} height={height} viewBox="0 0 40 40">
        <path d="M35.738 4.009a2.284 2.284 0 0 0-2.39.219 9.136 9.136 0 0 1-12.78-1.83 2.284 2.284 0 0 0-3.651 0 9.136 9.136 0 0 1-12.78 1.83 2.256 2.256 0 0 0-1.37-.458A2.276 2.276 0 0 0 .485 6.05v13.694A13.583 13.583 0 0 0 2.99 27.45a23.396 23.396 0 0 0 6.496 6.173 41.777 41.777 0 0 0 8.452 4.23 2.277 2.277 0 0 0 1.607 0 41.777 41.777 0 0 0 8.452-4.23 23.396 23.396 0 0 0 6.496-6.173A13.583 13.583 0 0 0 37 19.743V6.05a2.28 2.28 0 0 0-1.262-2.04zM9.615 8.332a11.392 11.392 0 0 0 9.128-4.562c3.783 5.04 10.934 6.06 15.975 2.279v6.85H2.768v-6.85a11.338 11.338 0 0 0 6.847 2.283zm17.118 23.393a39.39 39.39 0 0 1-7.99 3.992 39.39 39.39 0 0 1-7.99-3.992C5.74 28.385 2.77 24.237 2.77 19.743v-5.705h31.95v5.705c-.001 4.494-2.972 8.642-7.986 11.982z" />
      </svg>
    </IconWrapper>
  );
}

Warranty.defaultProps = {
  colour: null,
  width: '1em',
  height: '1em',
  background: null,
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import { Button } from 'cms-ui';
import EditableList from '../EditableList/index';
import SelectSubmit from '../SelectSubmit';
import { handleSort, addItem, handleDelete, updateItem } from './items-helper';

const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

export type Item = {
  label: string,
  link: string,
  id: string,
  itemId: string,
};

type Props = {
  name: string,
  value: Item[],
  onChange: (items: Item[]) => void,
  options: Item[],
  siteId: string,
  actions: Object,
  addButtonText: string,
  saveButtonText: string,
  fieldName: string,
  max: Number,
};

export default class ItemsEditor extends Component<Props, *> {
  state = {
    ItemBeingEdited: null,
    showEditItem: false,
  };

  toggleAddItem = () => {
    this.setState(prevState => ({ showEditItem: !prevState.showEditItem }));
  };

  handleEdit = (itemId: string, editing: boolean) => {
    this.setState({ ItemBeingEdited: editing ? itemId : null });
  };

  handleEdit = (itemId: string, editing: boolean) => {
    this.setState({ ItemBeingEdited: editing ? itemId : null });
  };

  handleAdd = (id: string, label: string, data: Item[], items: Item[]) => {
    this.toggleAddItem();
    return addItem(id, label, data, items);
  };

  render() {
    const {
      name,
      value: items,
      onChange,
      options,
      siteId,
      actions,
      addButtonText,
      saveButtonText,
      fieldName,
    } = this.props;

    return (
      <div>
        <TextLabel>{fieldName}</TextLabel>
        <EditableList
          items={items}
          collection={items}
          itemBeingEdited={this.state.ItemBeingEdited}
          onEdit={itemId => editing => this.handleEdit(itemId, editing)}
          onChange={itemId => (label: string, id: string) =>
            onChange(updateItem(itemId, id, label, items, options))}
          onDelete={itemId => () => onChange(handleDelete(itemId, items))}
          onSort={({ oldIndex, newIndex }) =>
            onChange(handleSort(items, oldIndex, newIndex))
          }
          options={options}
          actions={actions}
          siteId={siteId}
          buttonText={saveButtonText}
        />
        {!(this.props.value.length === this.props.max) && (
          <>
            {this.state.showEditItem ? (
              <SelectSubmit
                dropdownPlaceholder="Select a page/link"
                inputPlaceholder="Enter link text"
                name={name}
                options={options.map(({ label, id }) => ({ value: id, label }))}
                onSubmit={(id, label) =>
                  onChange(this.handleAdd(id, label, options, items))
                }
                actions={actions}
                siteId={siteId}
                buttonText={addButtonText}
                handleLinks
              />
            ) : (
              <Button
                small
                fullWidth
                textTransform="uppercase"
                primary
                onClick={this.toggleAddItem}
              >
                {addButtonText}
              </Button>
            )}
          </>
        )}
      </div>
    );
  }
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import RangeHeading from './RangeHeading';
import RangeSlider from '../RangeSlider';
import { getInitialRangeValues } from './search-filters-helper';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

const RangeContainer = styled.div.withConfig({
  displayName: 'RangeContainer',
})`
  padding: 20px;
  border-top: 1px solid #d8d8d8;
`;
const TabContainer = styled.div.withConfig({
  displayName: 'TabContainer',
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;
const Tab = styled.button.withConfig({
  displayName: 'RangeTabButton',
})`
  width: 49%;
  border: 1px solid ${props => (props.active ? '#7e7e7e' : '#d8d8d8')};
  height: 45px;
  font-size: 15px;
  font-weight: 600;
  color: ${({ active, selectedColour }) =>
    active ? selectedColour : '#444444'};
  margin: 0;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  text-transform: capitalize;
  font-family: ${({ fontFamily }) => fontFamily.value};
`;
const MonthlyBudgetText = styled.div.withConfig({
  displayName: 'MonthlyBudgetText',
})`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: #7e7e7e;
  margin: 25px 5px 0 5px;
`;

type Props = {
  stylingConfig: Object,
  translations: Object,
  priceRange: Object,
  monthlyPaymentRange: Object,
  availableFilters: Object,
  onChangeRangeLabel: (values: [number, number], key: string) => void,
  handleRangeFilters: (id: string, values: number[]) => void,
  resetRangeCount: number,
  removeRangeFilter: (id: string) => void,
  rangeValues: { [key: string]: { min: number, max: number } },
  featureFlags: Object,
  headingValues: { [key: string]: { min: number, max: number } },
  locale: string,
};
type State = {
  context: 'MONTHLY' | 'CASH',
  monthlyRange: {
    min: number,
    max: number,
  },
  cashRange: {
    min: number,
    max: number,
  },
};
const priceRangeContext = {
  monthly: 'MONTHLY',
  cash: 'CASH',
};
export default class PriceRangeFilter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const selectedDefaultView = props.availableFilters.monthlyPaymentRange
      ? priceRangeContext.monthly
      : priceRangeContext.cash;

    this.state = {
      context:
        props.featureFlags.finance && props.featureFlags.budgetSearch
          ? selectedDefaultView
          : priceRangeContext.cash,
    };
  }

  onTabClick = (
    context: typeof priceRangeContext.monthly | typeof priceRangeContext.cash,
  ) => {
    const {
      priceRange,
      monthlyPaymentRange,
      removeRangeFilter,
      handleRangeFilters,
      rangeValues,
      availableFilters,
    } = this.props;
    const idToRemoveFromFilter =
      context === priceRangeContext.monthly
        ? priceRange.id
        : monthlyPaymentRange.id;
    const idRange =
      context === priceRangeContext.monthly
        ? monthlyPaymentRange.id
        : priceRange.id;
    // this is a patch until we refactor the filters.
    // monthly payment range will be Null when the emulator fails/does not return anything
    if (
      context === priceRangeContext.monthly &&
      availableFilters.monthlyPaymentRange === null
    ) {
      this.setState(() => ({ context }));
      return;
    }
    this.setState(
      () => ({ context }),
      () => {
        removeRangeFilter(idToRemoveFromFilter);
        handleRangeFilters(idRange, [
          rangeValues[idRange].min,
          rangeValues[idRange].max,
        ]);
      },
    );
  };

  render = () => {
    const {
      stylingConfig,
      translations,
      availableFilters,
      priceRange,
      monthlyPaymentRange,
      onChangeRangeLabel,
      handleRangeFilters,
      resetRangeCount,
      rangeValues,
      featureFlags,
      headingValues,
      locale,
    } = this.props;

    const { duration, deposit } = availableFilters.monthlyPaymentRange || {};
    const priceRangeValues = rangeValues[priceRange.id];
    const monthlyPaymentRangeValues = rangeValues[monthlyPaymentRange.id];
    const priceHeadingValues = headingValues[priceRange.id];
    const monthlyHeadingValues = headingValues[monthlyPaymentRange.id];

    return (
      <RangeContainer>
        {featureFlags.budgetSearch && featureFlags.finance && (
          <TabContainer>
            <Tab
              data-qa-hook="monthly-button"
              onClick={() => this.onTabClick(priceRangeContext.monthly)}
              active={this.state.context === priceRangeContext.monthly}
              selectedColour={
                stylingConfig.rangeButtonTextSelectedColour &&
                stylingConfig.rangeButtonTextSelectedColour.value
              }
              fontFamily={stylingConfig.rangeButtonFont}
            >
              {translations.searchPriceByMonth}
            </Tab>
            <Tab
              data-qa-hook="cash-button"
              onClick={() => this.onTabClick(priceRangeContext.cash)}
              active={this.state.context === priceRangeContext.cash}
              selectedColour={
                stylingConfig.rangeButtonTextSelectedColour &&
                stylingConfig.rangeButtonTextSelectedColour.value
              }
              fontFamily={stylingConfig.rangeButtonFont}
            >
              {translations.searchPriceByCash}
            </Tab>
          </TabContainer>
        )}
        {this.state.context === priceRangeContext.cash && (
          <div>
            <RangeHeading
              range={priceHeadingValues}
              heading={translations.searchSubHeaderPrice}
              unit={translations.unitPrice}
              locale={locale}
              id={priceRange.id}
            />
            <RangeSlider
              stylingConfig={stylingConfig}
              step={priceRange.step}
              rangeValue={priceRange.fullRangeValues}
              name={priceRange.name}
              defaultValue={getInitialRangeValues(100, priceRangeValues)}
              onAfterChange={values =>
                handleRangeFilters(priceRange.id, values)
              }
              onChange={values => onChangeRangeLabel(values, 'priceRangeLabel')}
              key={resetRangeCount}
            />
          </div>
        )}
        {this.state.context === priceRangeContext.monthly &&
          availableFilters.monthlyPaymentRange !== null && (
            <div>
              <RangeHeading
                range={monthlyHeadingValues}
                heading={translations.searchSubHeaderPriceMonthly}
                unit={translations.unitPrice}
                locale={locale}
              />
              <RangeSlider
                stylingConfig={stylingConfig}
                step={monthlyPaymentRange.step}
                rangeValue={monthlyPaymentRange.fullRangeValues}
                name={monthlyPaymentRange.name}
                defaultValue={getInitialRangeValues(
                  100,
                  monthlyPaymentRangeValues,
                )}
                onAfterChange={values =>
                  handleRangeFilters(monthlyPaymentRange.id, values)
                }
                onChange={values =>
                  onChangeRangeLabel(values, 'monthlyPaymentRangeLabel')
                }
                key={resetRangeCount}
              />
              <MonthlyBudgetText data-qa-hook="monthly-text">
                {duration && deposit
                  ? translateFromTemplate(
                      'searchPriceByMonthText',
                      {
                        DURATION: duration,
                        DEPOSIT: deposit,
                      },
                      translations,
                    )
                  : translations.noResultsText}
              </MonthlyBudgetText>
            </div>
          )}
        {this.state.context === priceRangeContext.monthly &&
          availableFilters.monthlyPaymentRange === null && (
            <MonthlyBudgetText>
              {translations.monthlyBudgetFailureText}
            </MonthlyBudgetText>
          )}
      </RangeContainer>
    );
  };
}

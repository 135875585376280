// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import TemplateBrandColours from './TemplateBrandColours';
import TemplateBrandTypography from './TemplateBrandTypography';
import TemplateBrandLogo from './TemplateBrandLogo';
import TemplateUIElements from './TemplateUIElements';

const Container = styled.div`
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  min-height: auto;
  width: 100%;
  display: flex;
`;

const MenuItems = styled.div`
  width: 170px;
  border-right: 1px solid #d6d6d6;
`;

const MenuItem = styled.div`
  height: 45px;
  border-bottom: 1px solid #d6d6d6;
  color: ${({ active }) => (active ? '#0065DB' : '#474747')};
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 25px;
`;

const menuOptions = {
  COLOURS: 'COLOURS',
  TYPOGRAPHY: 'TYPOGRAPHY',
  LOGO: 'LOGO',
  UIELEMENTS: 'UIELEMENTS',
};

type MenuContext = 'COLOURS' | 'TYPOGRAPHY' | 'LOGO' | 'UIELEMENTS';

type Props = {
  brandingColours: Object,
  fonts: Object,
  addBrandColour: () => void,
  removeBrandColour: (hex: string) => void,
  saveBrandColour: (hex: string) => void,
  selectBrandColour: (key: string, value: string) => void,
  addBrandFont: (font: File) => void,
  selectBrandFont: (key: string, font: Object) => void,
  removeBrandFont: (fontName: string) => void,
  brandLogos: Object,
  addLogo: (image: File, forKey: string) => void,
  removeLogo: (forKey: string) => void,
  uiElements: Object,
  updateUiElement: (element: string, key: string, value: string) => void,
  updateButtonStyle: (element: string, value: string) => void,
};

type State = {
  menuContext: MenuContext,
};

export default class TabMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menuContext: menuOptions.COLOURS,
    };
  }

  onMenuItemClick = (menuContext: MenuContext) => {
    this.setState(() => ({ menuContext }));
  };

  props: Props;

  render = () => {
    const { menuContext } = this.state;
    const {
      brandingColours,
      fonts,
      addBrandColour,
      removeBrandColour,
      saveBrandColour,
      selectBrandColour,
      addBrandFont,
      selectBrandFont,
      removeBrandFont,
      brandLogos,
      addLogo,
      removeLogo,
      uiElements,
      updateUiElement,
      updateButtonStyle,
    } = this.props;
    return (
      <Container>
        <MenuItems>
          <MenuItem
            data-qa-hook="tab-menu-brand-colours"
            onClick={() => this.onMenuItemClick(menuOptions.COLOURS)}
            active={menuContext === menuOptions.COLOURS}
          >
            Brand colours
          </MenuItem>
          <MenuItem
            data-qa-hook="tab-menu-typography"
            onClick={() => this.onMenuItemClick(menuOptions.TYPOGRAPHY)}
            active={menuContext === menuOptions.TYPOGRAPHY}
          >
            Typography
          </MenuItem>
          <MenuItem
            data-qa-hook="tab-menu-logo"
            onClick={() => this.onMenuItemClick(menuOptions.LOGO)}
            active={menuContext === menuOptions.LOGO}
          >
            Logo
          </MenuItem>
          <MenuItem
            data-qa-hook="tab-menu-ui-elements"
            onClick={() => this.onMenuItemClick(menuOptions.UIELEMENTS)}
            active={menuContext === menuOptions.UIELEMENTS}
          >
            UI Elements
          </MenuItem>
        </MenuItems>
        {menuContext === menuOptions.COLOURS && (
          <TemplateBrandColours
            brandColours={brandingColours}
            addBrandColour={addBrandColour}
            removeBrandColour={removeBrandColour}
            saveBrandColour={saveBrandColour}
            selectBrandColour={selectBrandColour}
          />
        )}
        {menuContext === menuOptions.TYPOGRAPHY && (
          <TemplateBrandTypography
            brandColours={brandingColours}
            addBrandColour={addBrandColour}
            removeBrandColour={removeBrandColour}
            saveBrandColour={saveBrandColour}
            selectBrandColour={selectBrandColour}
            fonts={fonts}
            addBrandFont={addBrandFont}
            selectBrandFont={selectBrandFont}
            removeBrandFont={removeBrandFont}
          />
        )}
        {menuContext === menuOptions.LOGO && (
          <TemplateBrandLogo
            brandHeaderLogo={
              brandLogos.brandHeaderLogo && brandLogos.brandHeaderLogo.src
            }
            brandFooterLogo={
              brandLogos.brandFooterLogo && brandLogos.brandFooterLogo.src
            }
            addLogo={addLogo}
            removeLogo={removeLogo}
          />
        )}
        {menuContext === menuOptions.UIELEMENTS && (
          <TemplateUIElements
            brandColours={brandingColours}
            selectBrandColour={selectBrandColour}
            fonts={fonts}
            selectBrandFont={selectBrandFont}
            uiElements={uiElements}
            updateUiElement={updateUiElement}
            updateButtonStyle={updateButtonStyle}
          />
        )}
      </Container>
    );
  };
}

export default function() {
  return {
    translations: {
      bodyText: 'Body placeholder',
      headlineText: 'Headline placeholder',
      buttonText: 'Button placeholder',
    },
    bodyFont: {
      kerning: '0.4',
      fontSize: '15',
    },
    headlineFont: {
      kerning: '0.4',
      fontSize: '18',
      transform: 'uppercase',
    },
    selectedImage: {
      label: 'image label placeholder',
      value:
        'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png',
    },
    buttonText: {
      on: true,
    },
    buttonFont: {
      fontSize: '14',
      kerning: '1',
      transform: 'uppercase',
    },
    contentAlign: 'top',
  };
}

// @flow

import React from 'react';

import { Checkbox, FieldLabel } from 'cms-ui';

import { type FieldProps } from '../models/fields';

export default function CheckField({
  name,
  values: { id: value },
  ids: { id },
  onChange,
}: FieldProps) {
  return (
    <FieldLabel label={name}>
      <Checkbox name={name} value={value} onChange={val => onChange(id, val)} />
    </FieldLabel>
  );
}

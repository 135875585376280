// @flow
import React from 'react';
import styled from 'styled-components';
import Dropdown from './DropDown';
import { HeadingTwo, fontStyleOverride } from './Global';
import type { Option, Item } from '../types';
import { getSymbolFromCurrency } from '../shared/localisation/currencySymbol';
import SocialShareButton from '../modules/Header/SocialShareButton';

export type UtilityBarProps = {
  // enabledKey: string, // config key that enables/disables this item
  config: Object,
  theme: {
    link: {},
    dropdown: {},
  },
  utilityBarOptions: {
    placeholder?: ?string,
    initialSelectedValue?: Option,
    options: Option[],
  }[],
  items: Item[],
  preview: boolean,
  dropDownActions: Object,
  initialValues: Object,
  socialShare: Object,
  direction: string,
};

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SocialShareContainer = styled.div`
  display: flex;
  padding: 0 16px;
`;

const SocialShare = ({ config, socialShare }: UtilityBarProps) => (
  <SocialShareContainer>
    {config && config.socialShareTitle && !config.socialShareTitle.disabled && (
      <FlexWrapper>
        <SocialShareButton config={config} socialShare={socialShare} />
      </FlexWrapper>
    )}
  </SocialShareContainer>
);

const UtilityBarLink = styled.a`
  margin: 0 10px;
  padding: 15px 10px;
  text-decoration: none;
  cursor: pointer;
`;

const UtilityBarList = styled.ul`
  display: flex;
  margin: 0;
`;

const StyledUtilityBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function UtilityBar({
  config,
  utilityBarOptions,
  items = [],
  theme,
  preview,
  dropDownActions,
  initialValues,
  socialShare,
  direction,
}: UtilityBarProps) {
  const interceptClick = preview ? event => event.preventDefault() : () => {};
  return (
    <StyledUtilityBar>
      {items.length !== 0 && (
        <UtilityBarList>
          {items.map(({ label, itemId }) => (
            <li key={itemId}>
              <UtilityBarLink onClick={interceptClick}>
                <HeadingTwo
                  styleOverride={() => `
                    ${fontStyleOverride(theme.link.font)}
                    padding: 8px;
                    &:hover {
                      ${theme.link.hoverColour &&
                        `color: ${theme.link.hoverColour.value}`};
                    }`}
                >
                  {label}
                </HeadingTwo>
              </UtilityBarLink>
            </li>
          ))}
        </UtilityBarList>
      )}
      {config && config.socialShareEnabled && (
        <SocialShare config={config} socialShare={socialShare} />
      )}
      {utilityBarOptions.map(
        (
          { initialSelectedValue, options, placeholder, key, prefixLabel },
          index,
        ) => {
          const selectedValue = initialValues[key.toLowerCase()];
          let selectedOption;

          if (selectedValue) {
            selectedOption =
              options.find(opt => opt.value === selectedValue) ||
              initialSelectedValue;
          }

          const dropdownProps = {
            onOptionClick: dropDownActions[key.toLowerCase()],
            initialSelectedValue: selectedOption,
            options,
            theme,
            placeholder,
            selectedValue,
            direction,
            getLabelFromOption: i => i.label,
            getValueFromOption: i => i.value,
            getDisplayFromOption: i =>
              key === 'Currency'
                ? `${prefixLabel}${i.display || getSymbolFromCurrency(i.value)}`
                : `${prefixLabel} ${i.display || i.label}`,
            width: key === 'Currency' ? 290 : 200,
          };

          return <Dropdown key={`Dropdown-${index + 1}`} {...dropdownProps} />;
        },
      )}
    </StyledUtilityBar>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../theme';

import Phone from './JaguarIcons/Global/Phone';
import Icon from './StyledIcon';
import { Button } from './Global';
import type { ButtonLinkTheme } from '../types';
import ContentWrapper from './ContentWrapper';

const Container = styled.div`
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 130px;
  border-bottom: 1px solid
    ${({ borderColour }) => (borderColour ? ` ${borderColour}` : '#979797')};
  width: 100%;
  z-index: 95; /* more than google map */

  ${media.max.large`display:none;`};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 700px;
  ${({ fillSpace }) => fillSpace && 'justify-content: space-between;'};
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${({ padded }) => padded && 'padding: 12px'};
`;

const PhoneRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${({ padded }) => padded && 'padding: 12px'};
`;

const InfoRow = styled(Row)`
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
`;

const Image = styled.img`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;

  ${media.max.medium`display:none;`};
`;

const Title = styled.span`
  font-size: ${({ font }) =>
    font && font.fontSize ? `${font.fontSize}px` : '22px'};
  color: ${({ bold }) => (bold ? '#000000' : '#444444')};
  ${({ font }) => !font && 'font-weight: 600'};
  ${({ font }) => font && font.transform && `text-transform:${font.transform}`};
`;

const Label = styled.span`
  font-size: 14px;
  color: #7e7e7e;
`;

const LabelledValue = ({
  value,
  label,
  bold,
  separator,
  width,
  qaHook,
  titleFont,
  labelFont,
  direction,
}: {
  value: string,
  label: string,
  bold: boolean,
  separator: boolean,
  width?: string,
  qaHook?: string,
  titleFont?: Font,
  labelFont?: Font,
}) => (
  <VertSeparator hideBorder={separator} width={width} direction={direction}>
    <Column data-qa-hook={qaHook}>
      <Title font={titleFont} bold={bold}>
        {value}
      </Title>
      <Label font={labelFont}>{label}</Label>
    </Column>
  </VertSeparator>
);

const Bold = styled.span`
  ${({ font }) => !font && 'font-weight: 600'};
  ${({ font }) => font && font.colour && `color: ${font.colour.value};`};
  ${({ font }) => font && font.fontSize && `font-size: ${font.fontSize}px;`};
  ${({ font }) => font && font.typeface && `font-family: ${font.typeface};`};
`;

const PhoneLink = styled.a.withConfig({
  displayName: 'PhoneLink',
})`
  text-decoration: none;
`;

const PhoneNumber = ({
  number,
  colour,
  size,
  font,
}: {
  number: string,
  colour: string,
  size: number,
  font: Font,
}) => (
  <PhoneRow>
    <Phone
      width={size ? `${size}em` : '2em'}
      height={size ? `${size}em` : '2em'}
      colour={colour}
    />
    <Bold font={font}>&#x200E;{number}</Bold>
  </PhoneRow>
);

function labelIcon(label, iconColour, icon) {
  return (
    <LabelWithIconWrapper>
      <Icon link={icon} width={16} height={16} />
      <div style={{ marginLeft: '8px' }}>
        <AnchorText>{label}</AnchorText>
      </div>
    </LabelWithIconWrapper>
  );
}

const DealerLocation = ({
  dealer,
  iconColour,
  icon,
}: {
  dealer: string,
  iconColour: string,
  icon: string,
}) => labelIcon(dealer, iconColour, icon);

const VertSeparator = styled.div`
  border-right: ${({ hideBorder }) =>
    hideBorder ? 'none' : '1px solid #DEDEDE'};
  ${({ direction }) => direction === 'rtl' && 'border-right: none'};
  padding-right: 6px;
  margin-right: 6px;
  ${({ height }) => height && `height: ${height};`};
  ${({ width }) => width && `width: ${width};`};

  ${media.max.medium`
    margin-right: 9px;
    padding-right: 9px;
  `};
`;

const AnchorText = styled.div`
  color: #00aaee;
  font-size: 12px;
  font-weight: 600;
`;

const LabelWithIconWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  ${({ cursorPointer }) => cursorPointer && 'cursor: pointer;'};
`;

type Props = {
  imageSrc: string,
  vehicleModel: string,
  vehicleDetail: string,
  dealerTelephone: string,
  dealerName: string,
  info: [string, string][],
  onReserveButtonClick: () => void,
  onTestDriveButtonClick: () => void,
  onContactUsButtonClick: () => void,
  buttonTheme: ButtonLinkTheme,
  translations: Object,
  featureFlags: Object,
  iconColour: string,
  contentWidth: number,
  globalStyling: Object,
  stickyBannerStyling: Object,
  showStickyBannerDealerLocation: boolean,
  showStickyBannerContactUsButton: boolean,
  hideVertSeparators: boolean,
  title: string,
  borderColour: string,
  pinIcon: string,
};

export default function VehicleInfoBanner({
  translations,
  imageSrc,
  vehicleDetail,
  dealerTelephone,
  dealerName,
  info,
  onReserveButtonClick,
  onTestDriveButtonClick,
  onContactUsButtonClick,
  buttonTheme,
  featureFlags,
  iconColour,
  contentWidth,
  globalStyling,
  showStickyBannerDealerLocation,
  showStickyBannerContactUsButton,
  hideVertSeparators,
  stickyBannerStyling = {},
  borderColour,
  title,
  pinIcon,
}: Props) {
  const { titleFont, labelFont, anchorFont, iconSize } = stickyBannerStyling;
  return (
    <Container data-qa-hook="vehicle-banner" borderColour={borderColour}>
      <ContentWrapper contentWidth={contentWidth} contentHeight={130} flex>
        <Image src={imageSrc} data-qa-hook="vehicle-banner-image" />
        <Row padded>
          <Column fillSpace>
            <LabelledValue
              qaHook="vehicle-title-description"
              value={title}
              label={vehicleDetail}
              titleFont={titleFont}
              labelFont={labelFont}
              bold
              separator={hideVertSeparators}
              direction={globalStyling.direction}
            />
            {showStickyBannerDealerLocation && (
              <DealerLocation
                colour={iconColour}
                size={iconSize}
                font={anchorFont}
                icon={pinIcon}
                dealer={dealerName}
              />
            )}
            <PhoneLink href={`tel:${dealerTelephone}`}>
              <PhoneNumber
                number={dealerTelephone}
                colour={iconColour}
                size={iconSize}
                font={anchorFont}
                direction={globalStyling.direction}
              />
            </PhoneLink>
          </Column>
          <VertSeparator height="106px" hideBorder={hideVertSeparators} />
          <InfoRow data-qa-hook="vehicle-information">
            {info &&
              info.map(([value, label], index) => (
                <LabelledValue
                  key={`${label}${value}${index + 1}`}
                  value={value}
                  label={label}
                  titleFont={titleFont}
                  labelFont={labelFont}
                  separator={hideVertSeparators}
                  direction={globalStyling.direction}
                />
              ))}
          </InfoRow>
          <VertSeparator height="106px" hideBorder={hideVertSeparators} />
          <Column fillSpace>
            {featureFlags.reserve && (
              <Button
                data-qa-hook="reserve-vehicle-button"
                onClick={onReserveButtonClick}
                theme={buttonTheme}
                primary
              >
                {translations.vdpReserveThisVehicle}
              </Button>
            )}
            {featureFlags.testDrive && (
              <Button
                data-qa-hook="book-test-drive-button"
                onClick={onTestDriveButtonClick}
                theme={buttonTheme}
                primary
              >
                {translations.vdpBookTestDrive}
              </Button>
            )}
            {showStickyBannerContactUsButton && (
              <Button
                text={translations.vdpRetailerEnquiryButtonText}
                onClick={onContactUsButtonClick}
                applyStyle="primary"
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
              />
            )}
          </Column>
        </Row>
      </ContentWrapper>
    </Container>
  );
}

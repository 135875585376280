import * as VDP from './VDP';
import * as Search from './Search';
import * as SearchResults from './SearchResults';
import * as Range from './Range';
import * as ExploreRange from './ExploreRange';
import * as BudgetSearch from './BudgetSearch';
import * as Compare from './Compare';
import * as Shortlist from './Shortlist';
import * as Enquiry from './Enquiry';
import * as MyAccount from './MyAccount';
import * as TestDrive from './TestDrive';
import * as Register from './Register';
import * as ResetPassword from './ResetPassword';
import * as SignIn from './SignIn';

export {
  VDP,
  Search,
  SearchResults,
  Range,
  ExploreRange,
  BudgetSearch,
  Compare,
  Shortlist,
  Enquiry,
  MyAccount,
  TestDrive,
  Register,
  ResetPassword,
  SignIn,
};

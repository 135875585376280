// @flow
import { put, call, select } from 'redux-saga/effects';
import watch from '../../helpers/watch';
import { get, put as update } from '../../helpers/http';

import { API_URL_SITE_SETTINGS } from '../../constants/apis';
import { actions, constants, type Action } from '../actions/settings';

export function* saveSiteSettings(token: string, siteId: string) {
  const url = API_URL_SITE_SETTINGS(siteId);
  const finance = yield select(state => state.finance);
  const socialShare = yield select(state => state.socialShare);
  const data = {
    settings: {
      finance: {
        productTypes: finance.productTypes,
        defaultProduct: finance.productTypes.length && finance.defaultProduct,
        repexDefaultState: finance.repexDefaultState,
      },
      socialShare: {
        socialMediaTypes: socialShare.socialMediaTypes,
      },
    },
  };

  const result = yield call(update, { url, token, data });
  yield put(actions.saveSettingsSuccess(result.data.settings));
}

function* loadSiteSettings(token: string, { payload }: Action) {
  const url = API_URL_SITE_SETTINGS(payload);
  try {
    const result = yield call(get, { url, token });
    yield put(actions.loadSettingsSuccess(result.data.settings));
  } catch (error) {
    yield put(actions.loadSettingsFailure());
  }
}

export default [watch(constants.LoadSettings, loadSiteSettings, true)];

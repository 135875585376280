// @flow
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  HeadingOne,
  fontStyleOverride,
  Button,
} from '../../../../components/Global';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  ${({ font }) => font && fontStyleOverride(font)};
  outline: none;
`;

const headingOverride = `
  margin: 0;
  ${fontStyleOverride({
    fontSize: 22,
    transform: 'uppercase',
    alignment: 'center',
    padding: {
      bottom: 20,
    },
  })}
`;

type Props = {
  selectedLanguage: Object,
  selectedCurrency: Object,
  selectedTerritory: string,
  languages: Object[],
  currencies: Object[],
  territories: Object[],
  changeLanguage: Object => void,
  changeCurrency: Object => void,
  changeTerritory: (territory: string) => void,
  translations: Object,
  selectFont: Object,
  button: Object,
  closeModalFn: () => void,
};

export default ({
  selectedLanguage,
  selectedCurrency,
  selectedTerritory,
  languages,
  currencies,
  territories,
  changeLanguage,
  changeCurrency,
  changeTerritory,
  translations,
  selectFont,
  button,
  closeModalFn,
}: Props) => {
  const [language, setLanguage] = useState(selectedLanguage.value);

  const currencyState = selectedCurrency ? selectedCurrency.value : '';
  const [currency, setCurrency] = useState(currencyState);

  const [territory, setTerritory] = useState(selectedTerritory);

  const onSelectClick = (lang, curr, terr) => {
    const langObj = languages.find(l => l.value === lang) || {};
    const currObj = currencies.find(c => c.value === curr) || {};

    if (lang !== selectedLanguage.value && terr === selectedTerritory) {
      changeLanguage(langObj);
    }
    if (
      (selectedCurrency && curr !== selectedCurrency.value) ||
      (!selectedCurrency && curr !== '')
    ) {
      changeCurrency(currObj);
    }
    if (selectedTerritory && terr !== selectedTerritory) {
      changeTerritory({ territory: terr, language: lang });
    }
    closeModalFn();
  };

  return (
    <Wrapper>
      <Column>
        <HeadingOne styleOverride={() => headingOverride}>
          {translations.territorySelectionTitle}
        </HeadingOne>
        <Select
          value={territory}
          font={selectFont}
          onChange={event => setTerritory(event.target.value)}
        >
          {territories.map(t => (
            <option key={t.value} value={t.value}>
              {t.label}
            </option>
          ))}
        </Select>
      </Column>
      <Column>
        <HeadingOne styleOverride={() => headingOverride}>
          {translations.languageSelectionTitle}
        </HeadingOne>
        <Select
          value={language}
          font={selectFont}
          onChange={event => setLanguage(event.target.value)}
        >
          {languages.map(l => (
            <option key={l.value} value={l.value}>
              {l.label}
            </option>
          ))}
        </Select>
      </Column>
      <Column>
        <HeadingOne styleOverride={() => headingOverride}>
          {translations.currencySelectionTitle}
        </HeadingOne>
        <Select
          value={currency}
          font={selectFont}
          onChange={event => setCurrency(event.target.value)}
        >
          {!currencyState && (
            <option value="">{translations.selectCurrency}</option>
          )}
          {currencies.map(c => (
            <option key={c.value} value={c.value}>
              {c.label}
            </option>
          ))}
        </Select>
      </Column>
      <Button
        styleOverride={() => 'width: 100%; margin-top: 20px;'}
        buttonStyle={button.buttonStyle}
        applyStyle="primary"
        text="select"
        onClick={() => onSelectClick(language, currency, territory)}
      />
    </Wrapper>
  );
};

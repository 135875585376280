/* eslint-disable no-confusing-arrow */
// @flow
import React from 'react';
import styled from 'styled-components';

import range from 'ramda/src/range';
import media from '../../theme';
import ContentWrapper from '../../components/ContentWrapper';

const Pagination = styled.div`
  position: absolute;
  top: 500px;
  ${({ alignment }) =>
    alignment && `${alignment.align}: ${alignment.alignmentWidth}%`};
  ${media.max.extraLarge`
    display: none;
  `};
`;

const PaginationCenter = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  top: 90%;
  ${media.max.large`
    display: none
  `};
`;

const AlignmentWrapper = styled.div`
  display: flex;
  position: absolute;
`;

const PageRectangular = styled.span`
  cursor: pointer;
  text-align: center;
  color: ${({ active, activeColour, inactiveColour }) =>
    active ? activeColour : inactiveColour};
  font-size: 12px;
  line-height: 15px;
  background-color: ${({ active, activeColour, inactiveColour }) =>
    active ? inactiveColour : activeColour};
  border: ${({ active, activeColour }) =>
    active ? `1px solid ${activeColour}` : 'unset'};
  margin: 1px;
  padding: 1px 6px;
`;

const PageRadio = styled.span`
  cursor: pointer;
  height: 13px;
  width: 13px;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  ${({ paginationOpacity }) =>
    paginationOpacity && `opacity: ${paginationOpacity}`};
  background-color: ${({ active, activeColour, inactiveColour }) =>
    active ? activeColour : inactiveColour};
  border: ${({ active, activeColour, inactiveColour }) =>
    `1px solid ${active ? activeColour : inactiveColour}`};
  border-radius: 15px;
  margin: 0 13px;
`;

type Props = {
  items: Object[],
  onPageclick: (index: number) => void,
  activeItem: number,
  activeColour: string,
  inactiveColour: string,
  paginationOpacity: number,
  align: string,
  alignmentWidth: number,
  paginationWidth: number,
};

export function PaginationOne({
  items,
  onPageclick,
  activeItem,
  activeColour,
  inactiveColour,
  align,
  alignmentWidth,
  paginationWidth,
}: Props) {
  const slides = items || [];
  const slideRange = range(0, slides.length);
  return (
    <ContentWrapper contentWidth={paginationWidth}>
      <Pagination alignment={{ align, alignmentWidth }}>
        <AlignmentWrapper>
          {slides.length > 1 &&
            slideRange.map(i => (
              <PageRectangular
                key={`slide-page-${i}`}
                active={i === activeItem}
                onClick={() => onPageclick(i)}
                activeColour={activeColour}
                inactiveColour={inactiveColour}
              >
                {i + 1}
              </PageRectangular>
            ))}
        </AlignmentWrapper>
      </Pagination>
    </ContentWrapper>
  );
}

export function PaginationTwo({
  items,
  onPageclick,
  activeItem,
  activeColour,
  inactiveColour,
  paginationOpacity,
}: Props) {
  const slides = items || [];
  const slideRange = range(0, slides.length);

  return (
    <PaginationCenter>
      {slides.length > 1 &&
        slideRange.map(i => (
          <PageRadio
            key={`slide-page-${i}`}
            active={i === activeItem}
            onClick={() => onPageclick(i)}
            activeColour={activeColour}
            inactiveColour={inactiveColour}
            paginationOpacity={paginationOpacity}
          />
        ))}
    </PaginationCenter>
  );
}

// @flow
export const getClosestDivisableStep = (
  minRangeValue: number,
  maxRangeValue: number,
  step: number,
) => {
  const difference = maxRangeValue - minRangeValue;
  const floorQuotient = Math.floor(difference / step);
  return step + (difference - step * floorQuotient) / floorQuotient;
};

export const roundRangeValues = (
  minRangeValue: number,
  maxRangeValue: number,
  minValue: number,
  maxValue: number,
  step: number,
) => {
  if (!step) {
    return [minValue, maxValue];
  }

  const floorMin = Math.round(minValue / step) * step;

  const min = floorMin;
  const max = Math.min(Math.round(maxValue / step) * step, maxRangeValue);
  return [min, max];
};

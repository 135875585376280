import editor from './editor';

export default {
  id: 'Heading',
  name: 'Heading',
  description: 'Add heading pod',
  defaultSlice: 'Content',
  defaultConfig: {
    translations: {
      headingText: 'Heading Text',
      bodyText:
        'Lorem ipsum dolor sit amet, pro qualisque rationibus ea, nam consul eirmod ut. Vis tantas primis putent in, no mei putant voluptaria philosophia. Ex viris electram pri, dicit pertinacia in cum. Te mea tamquam minimum iudicabit, mundi expetendis per te, ut mel fastidii expetenda adipiscing.',
    },
    alignment: 'center',
    headingFont: { fontSize: '28' },
    bodyFont: { fontSize: '16' },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  requirements: {
    columns: [1, 2, 3, 4],
  },
};

import { put, call, takeEvery, select } from 'redux-saga/effects';
import pickBy from 'ramda/src/pickBy';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  getSearchMarket,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

let responseAllDealersCache;

function* getAllDealers() {
  const { make, market, region, locale } = yield select(getAVLConfig);
  const searchMarket = yield select(getSearchMarket);
  const baseUrl = yield select(inventorySearchServiceUrl);
  const params = {
    market: searchMarket,
  };
  try {
    const url = region
      ? `${baseUrl}inventory/make/${make}/region/${region}/dealers`
      : `${baseUrl}inventory/make/${make}/market/${market}/dealers`;

    const response = responseAllDealersCache
      ? yield {
          data: responseAllDealersCache,
        }
      : yield call(get, {
          url,
          config: localeConfigSerializer(
            locale,
            pickBy(value => !!value, params),
          ),
        });
    const { retailers: dealers, centreLocation: centerPoint } = response.data;
    yield put(actions.getAllDealersSuccess({ dealers, centerPoint }));
    responseAllDealersCache = response.data;
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* getClosestDealers(action) {
  const { latitude, longitude } = action.payload;
  const { make, market, region, locale } = yield select(getAVLConfig);
  const searchMarket = yield select(getSearchMarket);
  const baseUrl = yield select(inventorySearchServiceUrl);

  const params = {
    market: searchMarket,
    latitude,
    longitude,
    closestNumberOfDealers: 3,
  };

  try {
    const url = region
      ? `${baseUrl}inventory/make/${make}/region/${region}/dealers`
      : `${baseUrl}inventory/make/${make}/market/${market}/dealers`;

    const response = yield call(get, {
      url,
      config: localeConfigSerializer(locale, pickBy(value => !!value, params)),
    });
    const { retailers: dealers, centreLocation: centerPoint } = response.data;
    yield put(actions.getClosestDealersSuccess({ dealers, centerPoint }));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* DealerLocatorSaga() {
  yield takeEvery(constants.getAllDealers, getAllDealers);
  yield takeEvery(constants.getClosestDealers, getClosestDealers);
}

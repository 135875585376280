/* eslint-disable import/extensions */
import validate from 'validate.js';

export const required = (value, translations) => {
  return validate.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired,
    },
  });
};

export const validEmail = (value, translations) =>
  validate.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired,
    },
    email: {
      message: translations.formValidationEmail,
    },
  });

validate.validators.isValidCheckboxes = (value, options) =>
  Object.values(value).some(v => v) ? undefined : options.message;

export const validCheckboxes = (value, translations) =>
  validate.single(value, {
    isValidCheckboxes: {
      message: translations.formValidationCheckboxes,
    },
  });

import Icon from '../../modules/BudgetSearch/Icon';

export { id as reducerId } from '../../modules/BudgetSearch/reducer';

export { default as component } from './BudgetSearch';
export { default as config } from '../../modules/BudgetSearch/config';
export {
  default as defaultConfig,
} from '../../modules/BudgetSearch/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/BudgetSearch/reducer';
export { default as effects } from '../../modules/BudgetSearch/effects';

export const name = 'Budget Search';
export const id = 'BudgetSearch';
export const availableToAdministration = false;

export const description = 'Budget Search';
export const icon = Icon;

// @flow
import React from 'react';
import styled from 'styled-components';

import FormField, { type FormFieldProps } from './FormField';
import FieldError from './FieldError';

import type { Font } from '../../../types';

const TextArea = styled.textarea`
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#BF0C0C' : '#d8d8d8')};
  color: #7e7e7e;
  background-color: #fff;
  padding: 0px 15px;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
`;

type Props = FormFieldProps & {
  keyValue: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  disabled: boolean,
  placeholder: string,
  error?: string,
  font: Font,
};

export default function TextAreaField({
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  required,
  disabled,
  font,
}: Props) {
  return (
    <FormField label={label} required={required} font={font}>
      <TextArea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
      />
      {error && <FieldError>{error}</FieldError>}
    </FormField>
  );
}
TextAreaField.defaultProps = {
  error: '',
};

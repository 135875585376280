// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import media from '../../theme';

const FormFieldContainer = styled.div`
  margin-bottom: 20px;
  width: ${props => (props.doubleRow ? '48%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${media.max.large`
    width: 100%;
  `};
`;

const Label = styled.div`
  margin-bottom: 10px;
  ${({ direction }) =>
    direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
`;

export type FormFieldProps = {
  doubleRow: boolean,
  label: string,
  required: boolean,
  children: Node,
  globalStyling: Object,
};

export default function FormField({
  doubleRow,
  label,
  required,
  children,
  globalStyling,
}: FormFieldProps) {
  const { direction = '' } = globalStyling || {};
  return (
    <FormFieldContainer doubleRow={doubleRow}>
      {label && (
        <Label direction={direction}>
          {label}
          {required && '*'}
        </Label>
      )}
      {children}
    </FormFieldContainer>
  );
}

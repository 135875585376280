// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import type { Theme } from '../theme';

export type InputLabelPosition = 'right' | 'left';

type ThemeProps = {
  theme: Theme,
};

type Props = {
  inputLabelPosition: InputLabelPosition,
  large: boolean,
  error: boolean,
  children: string,
};

const StyledInputLabel = styled.span.withConfig({
  displayName: 'InputLabel',
})`
  ${({ theme, large }: { theme: Theme, large?: boolean }) => {
    let fs; // Font size
    let fc; // Font colour
    let bc; // Border color
    let pv; // Padding vertically
    let ph; // Padding horizontally

    if (large) {
      fs = theme.fontSizeContent;
      fc = theme.colours.secondary05;
      bc = theme.colours.secondary02;
      ph = 10;
      pv = 10;
    } else {
      fs = theme.fontSizeFormControls;
      fc = theme.colours.secondary03;
      bc = theme.colours.secondary03;
      ph = 8;
      pv = 4;
    }

    return css`
      color: ${fc};
      font-size: ${fs};
      border-color: ${bc};
      padding: ${pv}px ${ph}px ${pv}px ${ph}px;
    `;
  }};
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  border-width: 1px;
  border-style: solid;
  ${(props: Props) =>
    props.inputLabelPosition === 'left' &&
    css`
      order: 1;
      padding-left: 5px;
      border-right: 0;
    `};
  ${(props: Props) =>
    props.inputLabelPosition === 'right' &&
    css`
      order: 2;
      padding-right: 5px;
      border-left: 0;
    `};
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colours.danger01};
    `};
`;

export default function InputLabel({
  inputLabelPosition,
  large,
  error,
  children,
}: Props) {
  return (
    <StyledInputLabel
      error={error}
      large={large}
      inputLabelPosition={inputLabelPosition}
    >
      {children}
    </StyledInputLabel>
  );
}

InputLabel.defaultProps = {
  inputLabelPosition: 'left',
  large: false,
  error: false,
  children: null,
};

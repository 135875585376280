// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import styled from 'styled-components';

import { InputField } from './FormFields';
import media from '../theme';
import Link from './Link';
import { HeadingOne, Button, HeadingTwo } from './Global';

const SignInContent = styled.div`
  padding: 50px 35%;
  ${media.max.large`
    padding: 50px 5%;
  `};
`;

const LoginFailure = styled.div`
  font-size: 16px;
  color: #9e1b32;
  font-weight: 600;
  margin-bottom: 10px;
`;
const HorizontalLine = styled.div`
  border-bottom: 1px solid #dedede;
  margin: 40px 0;
`;

type Props = {
  translations: Object,
  login: Function,
  loginFailed: 'VALIDATION REQUIRED' | 'UNAUTHORISED',
  register: () => Function,
  goToForgotPassword: () => Function,
  buttonStyle: string,
};
type State = {
  username: string,
  password: string,
};

export default class SignIn extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  onInputChange = (formKey: string, value: any) => {
    this.setState(() => ({ [formKey]: value }));
  };

  onFormSubmit = (event: Event) => {
    const { username, password } = this.state;
    this.props.login({ user: username, pass: password })();
    event.preventDefault();
  };

  render = () => {
    const {
      translations,
      loginFailed,
      register,
      goToForgotPassword,
      globalStyling,
    } = this.props;
    const { username, password } = this.state;
    return (
      <div>
        <HeadingOne
          styleOverride={() => `
            display: flex;
            align-items: Center;
            justify-content: center;
            border-bottom: 1px solid #d8d8d8;
            font-size: 20px;
            text-transform: uppercase;
            height: 75px;
          `}
        >
          {translations.signInHeader}
        </HeadingOne>
        <SignInContent>
          {loginFailed === 'UNAUTHORISED' && (
            <LoginFailure>{translations.signInFailure}</LoginFailure>
          )}
          {loginFailed === 'VALIDATION REQUIRED' && (
            <LoginFailure>
              {translations.accountValidationRequired}
            </LoginFailure>
          )}
          <form onSubmit={this.onFormSubmit}>
            <InputField
              type="text"
              label={translations.signInUsernameLabel}
              value={username}
              onChange={this.onInputChange}
              keyValue="username"
            />
            <InputField
              type="password"
              label={translations.signInPasswordLabel}
              value={password}
              onChange={this.onInputChange}
              keyValue="password"
            />
            <div style={{ marginBottom: '40px' }}>
              <Link
                type="button"
                onClick={goToForgotPassword()}
                theme={{
                  linkColour:
                    globalStyling.colours.primaryBrandColour &&
                    globalStyling.colours.primaryBrandColour.value,
                }}
              >
                {translations.signInForgotPassword}
              </Link>
            </div>
            <Button
              type="submit"
              styleOverride={() => `
                width: 100%;
              `}
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              text={translations.signInLoginButton}
            />
          </form>
          <HorizontalLine />
          <HeadingTwo
            styleOverride={() => `
              text-align: center;
              font-size: 20px;
              margin-bottom: 25px;
            `}
          >
            {translations.signInNoAccount}
          </HeadingTwo>
          <Button
            styleOverride={() => `
              width: 100%;
            `}
            applyStyle="secondary"
            buttonStyle={
              globalStyling.uiElements.secondaryButton &&
              globalStyling.uiElements.secondaryButton.buttonStyle
            }
            onClick={register()}
            text={translations.signInRegister}
          />
        </SignInContent>
      </div>
    );
  };
}

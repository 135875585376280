import React from 'react';
import styled from 'styled-components';

import useCompiledModule from './useCompiledModule';

const ModuleInstanceContainer = styled.div`
  height: 100%;
`;

const CompiledModule = ({ band, globalStyling, index, path }) => {
  const { id, instanceId, config } = band;
  const {
    ReactComponent,
    moduleConfig,
    shared,
    moduleState,
    boundActions,
    router,
    marketInfo,
    vehicleInContext,
    history,
    linkedModules,
    featureFlags,
    finance,
    socialShare,
    pathByModule,
    navigateIfInternal,
    dispatch,
  } = useCompiledModule(id, index, path);

  return (
    ReactComponent && (
      <ModuleInstanceContainer
        key={`${instanceId}_${id}`}
        data-module-instance-id={instanceId}
      >
        <ReactComponent
          key={instanceId || `${id}`}
          config={config}
          modInfo={moduleConfig}
          history={history}
          globalStyling={globalStyling}
          featureFlags={featureFlags}
          finance={finance}
          socialShare={socialShare}
          {...{
            state: moduleState,
            shared,
            router,
            vehicleInContext,
            marketInfo,
            pathByModule,
            dispatch,
            actions: boundActions,
            linkedModules,
            navigateIfInternal,
          }}
        />
      </ModuleInstanceContainer>
    )
  );
};

export default CompiledModule;

// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import FormField from './FormField';
import FieldError from './FieldError';
import media from '../../../theme';

import type { Font } from '../../../types';

const WidthWrapper = styled.div`
  width: 100%;
  ${media.min.medium`
    width: 240px;
  `};
`;
const Input = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-family: inherit;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#BF0C0C' : '#d8d8d8')};
  color: #7e7e7e;
  background-color: #fff;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
  ${props =>
    props.truncateChars &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  name: string,
  keyValue: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  font: Font,
};

export default function InputField({
  label,
  required,
  type,
  name,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  placeholder,
  truncateChars = false,
  font,
}: Props) {
  return (
    <WidthWrapper>
      <FormField label={label} required={required} font={font}>
        <Input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          truncateChars={truncateChars}
        />
        {error && <FieldError>{error}</FieldError>}
      </FormField>
    </WidthWrapper>
  );
}
InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: () => {},
  onKeyPress: () => {},
  label: '',
  error: '',
  validate: false,
};

// @flow
import React from 'react';
import styled from 'styled-components';

import { SpinnerWrapper } from '../../components/Spinner';
import { useModelSearch } from './useModelSearch';
import type { Font } from '../../types';

const ModelSearchWrapper = styled.div`
  padding: 50px 0;
  height: 100%;
  width: 100%;
`;

interface IModelSearchConfig {
  template: string;
  translations: Object;
  titleFont: Font;
  descFont: Font;
  labelFont: Font;
  translations: Object;
  arrowIconUrl: string;
  selectedUrl: string;
  unselectedUrl: string;
}

type Props = {
  config: IModelSearchConfig,
  globalStyling: Object,
};

export default ({ config: { template, ...config }, globalStyling }: Props) => {
  const {
    TileComponent,
    modelGroups,
    selectedModels,
    selectedVariants,
    onModelTileClick,
    onVariantTileClick,
    loaded,
  } = useModelSearch(template);
  return (
    <SpinnerWrapper loaded={loaded} globalStyling={globalStyling}>
      {() => (
        <ModelSearchWrapper>
          {modelGroups.map(mg => (
            <TileComponent
              key={mg.modelGroupName}
              modelGroup={mg}
              config={config}
              onModelTileClick={onModelTileClick}
              onVariantTileClick={onVariantTileClick}
              selectedModels={selectedModels}
              selectedVariants={selectedVariants}
            />
          ))}
        </ModelSearchWrapper>
      )}
    </SpinnerWrapper>
  );
};

// @flow
import { constants, type Action } from '../actions/editor';
import type { Error } from '../types/error';
import settings from '../../settings';

export type Font = { value: string, label: string };
export type Colour = { value: string, label: string };
export type LogoAction = 'None' | 'Upload' | 'Delete';
export type Logos = {
  brandHeaderLogo: {
    src: ?string,
    action: LogoAction,
  },
  brandFooterLogo: {
    src: ?string,
    action: LogoAction,
  },
};

export type Site = {
  id: string,
  name: string,
  builderUrl: string,
  lastUpdated: ?string,
  lastPublished: ?string,
};

export type Branding = {
  logos: {
    brandHeaderLogo: string,
    brandFooterLogo: string,
  },
  name: string,
  colours: {
    availableColours: Colour[],
    primaryBrandColour: Colour,
    secondaryBrandColour: Colour,
    pageBackgroundColour: Colour,
    primaryFontColour: Colour,
    secondaryFontColour: Colour,
    paragraphFontColour: Colour,
    highlightedLabelsColour: Colour,
  },
  fonts: {
    availableFonts: Font[],
    primaryFont: Font,
    secondaryFont: Font,
    paragraphFont: Font,
    primaryButtonFont: Font,
    highlightedLabelsFont: Font,
  },
};

export type State = {
  isLoading: boolean,
  error: ?Error,
  success: boolean,
  branding: Branding,
  logos: {
    brandHeaderLogo: {
      action: LogoAction,
      src: ?string,
      error: ?string,
    },
    brandFooterLogo: {
      action: LogoAction,
      image: string,
      error: ?string,
    },
  },
  sites: Site[],
};

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  logos: {
    brandHeaderLogo: {
      action: 'None',
      src: null,
      error: null,
    },
    brandFooterLogo: {
      action: 'None',
      src: null,
      error: null,
    },
  },
  branding: {
    logos: {
      brandHeaderLogo: '',
      brandFooterLogo: '',
    },
    name: '',
    colours: {
      availableColours: [],
    },
    fonts: {
      availableFonts: [],
    },
    uiElements: {
      primaryButton: {},
      secondaryButton: {},
      tertiaryButton: {},
    },
  },
  sites: [],
};

function removeBrandColour(hex, colours) {
  const hexIndex = colours.availableColours.findIndex(c => c.value === hex);
  const slice1 = colours.availableColours.slice(0, hexIndex);
  const slice2 = colours.availableColours.slice(
    hexIndex + 1,
    colours.availableColours.length,
  );

  const primaryBrandColour =
    hex === (colours.primaryBrandColour && colours.primaryBrandColour.value)
      ? { label: '', value: '' }
      : colours.primaryBrandColour;
  const secondaryBrandColour =
    hex === (colours.secondaryBrandColour && colours.secondaryBrandColour.value)
      ? { label: '', value: '' }
      : colours.secondaryBrandColour;
  const pageBackgroundColour =
    hex === (colours.pageBackgroundColour && colours.pageBackgroundColour.value)
      ? { label: '', value: '' }
      : colours.pageBackgroundColour;
  const primaryFontColour =
    hex === (colours.primaryFontColour && colours.primaryFontColour.value)
      ? { label: '', value: '' }
      : colours.primaryFontColour;
  const secondaryFontColour =
    hex === (colours.secondaryFontColour && colours.secondaryFontColour.value)
      ? { label: '', value: '' }
      : colours.secondaryFontColour;
  const paragraphFontColour =
    hex === (colours.paragraphFontColour && colours.paragraphFontColour.value)
      ? { label: '', value: '' }
      : colours.paragraphFontColour;
  const highlightedLabelsColour =
    hex ===
    (colours.highlightedLabelsColour && colours.highlightedLabelsColour.value)
      ? { label: '', value: '' }
      : colours.highlightedLabelsColour;

  return {
    availableColours: [...slice1, ...slice2],
    primaryBrandColour,
    secondaryBrandColour,
    pageBackgroundColour,
    primaryFontColour,
    secondaryFontColour,
    paragraphFontColour,
    highlightedLabelsColour,
  };
}

function removeBrandFont(fontName, fonts) {
  const resetFontValue = { label: '', value: '' };
  const primaryFont =
    fonts.primaryFont && fonts.primaryFont.label === fontName
      ? resetFontValue
      : fonts.primaryFont;
  const secondaryFont =
    fonts.secondaryFont && fonts.secondaryFont.label === fontName
      ? resetFontValue
      : fonts.secondaryFont;
  const paragraphFont =
    fonts.paragraphFont && fonts.paragraphFont.label === fontName
      ? resetFontValue
      : fonts.paragraphFont;
  const highlightedLabelsFont =
    fonts.highlightedLabelsFont &&
    fonts.highlightedLabelsFont.label === fontName
      ? resetFontValue
      : fonts.highlightedLabelsFont;

  return {
    availableFonts: fonts.availableFonts.filter(f => f.label !== fontName),
    primaryFont,
    secondaryFont,
    paragraphFont,
    highlightedLabelsFont,
  };
}

function hexToRGB(h) {
  const r = `0x${h[1]}${h[2]}`;
  const g = `0x${h[3]}${h[4]}`;
  const b = `0x${h[5]}${h[6]}`;

  return `rgba(${+r},${+g},${+b},1)`;
}

function saveBrandColour(hex, colours) {
  colours.pop();
  return [...colours, { label: hex, value: hexToRGB(hex) }];
}

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.LoadSites:
    case constants.LoadBranding:
      return { ...state, isLoading: true };
    case constants.LoadSitesSuccessful:
      return { ...state, sites: action.payload, isLoading: false, error: null };
    case constants.LoadBrandingSuccess: {
      return {
        ...state,
        branding: action.payload,
        logos: {
          brandHeaderLogo: {
            ...state.logos.brandHeaderLogo,
            src: action.payload.logos.brandHeaderLogo,
          },
          brandFooterLogo: {
            ...state.logos.brandFooterLogo,
            src: action.payload.logos.brandFooterLogo,
          },
        },
        isLoading: false,
        error: null,
      };
    }
    case constants.LoadSitesFailure:
    case constants.LoadBrandingFailure:
      return { ...state, error: action.payload, isLoading: false };
    case constants.SaveBrandingSuccess:
      return { ...state, error: null, success: true };
    case constants.AddBrandColour:
      return {
        ...state,
        branding: {
          ...state.branding,
          colours: {
            ...state.branding.colours,
            availableColours: [...state.branding.colours.availableColours, ''],
          },
        },
      };
    case constants.RemoveBrandColour:
      return {
        ...state,
        branding: {
          ...state.branding,
          colours: removeBrandColour(action.payload, state.branding.colours),
        },
      };
    case constants.SaveBrandColour:
      return {
        ...state,
        branding: {
          ...state.branding,
          colours: {
            ...state.branding.colours,
            availableColours: saveBrandColour(
              action.payload,
              state.branding.colours.availableColours,
            ),
          },
        },
      };
    case constants.SelectBrandColour:
      return {
        ...state,
        branding: {
          ...state.branding,
          colours: {
            ...state.branding.colours,
            [action.payload.key]: {
              label: action.payload.value,
              value: action.payload.value,
            },
          },
        },
      };
    case constants.FontUploadComplete: {
      const { label, value, type, id } = action.payload;
      return {
        ...state,
        branding: {
          ...state.branding,
          fonts: {
            ...state.branding.fonts,
            availableFonts: [
              ...state.branding.fonts.availableFonts,
              { label, value, type, id },
            ],
          },
        },
      };
    }
    case constants.SelectBrandFont:
      return {
        ...state,
        branding: {
          ...state.branding,
          fonts: {
            ...state.branding.fonts,
            [action.payload.key]: action.payload.font,
          },
        },
      };
    case constants.RemoveBrandFont:
      return {
        ...state,
        branding: {
          ...state.branding,
          fonts: removeBrandFont(action.payload, state.branding.fonts),
        },
      };
    case constants.ParseBrandLogoSuccess:
      return {
        ...state,
        logos: {
          ...state.logos,
          [action.payload.forKey]: {
            action: 'Upload',
            src: action.payload.image,
            error: null,
          },
        },
      };
    case constants.ParseBrandLogoFailure:
      return {
        ...state,
        logos: {
          ...state.logos,
          [action.payload.forKey]: {
            action: 'Upload',
            error: action.payload,
          },
        },
      };
    case constants.RemoveBrandLogo:
      return {
        ...state,
        logos: {
          ...state.logos,
          [action.payload]: {
            action: 'Delete',
            src: null,
            error: null,
          },
        },
      };
    case constants.UpdateUiElement: {
      const { element, key, value } = action.payload;
      return {
        ...state,
        branding: {
          ...state.branding,
          uiElements: {
            ...state.branding.uiElements,
            [element]: {
              ...state.branding.uiElements[element],
              [key]: value,
            },
          },
        },
      };
    }
    case constants.UpdateButtonStyle: {
      const { element, value } = action.payload;
      return {
        ...state,
        branding: {
          ...state.branding,
          uiElements: {
            ...state.branding.uiElements,
            [element]: {
              ...state.branding.uiElements[element],
              buttonStyle: value,
            },
          },
        },
      };
    }
    case constants.ResetSuccessMessages:
      return {
        ...state,
        success: null,
      };
    case constants.ResetFailureMessages:
      return {
        ...state,
        error: null,
      };
    case constants.IsLoggingOut: {
      const { loggingOut } = action.payload;
      return {
        ...state,
        isLoggingOut: loggingOut,
      };
    }
    default:
      return state;
  }
}

// selectors
export const getGlobalStylingForModules = ({
  branding,
}: {
  branding: Branding,
}) => ({
  fonts: {
    primaryFont: branding.fonts.primaryFont,
    secondaryFont: branding.fonts.secondaryFont,
    primaryButtonFont: branding.fonts.primaryButtonFont,
    paragraphFont: branding.fonts.paragraphFont,
  },
  colours: {
    pageBackgroundColour: branding.colours.pageBackgroundColour,
    primaryColour: branding.colours.primaryBrandColour,
    primaryFontColour: branding.colours.primaryFontColour,
    primaryForegroundColour: branding.colours.primaryForegroundColour,
    primaryBackgroundColour: branding.colours.primaryBackgroundColour,
    secondaryColour: branding.colours.secondaryBrandColour,
    secondaryFontColour: branding.colours.secondaryFontColour,
    primaryButtonForegroundColour: branding.colours.primaryForegroundColour,
    primaryButtonBackgroundColour: branding.colours.primaryBackgroundColour,
    primaryButtonBorderColour: branding.colours.primaryBorderColour,
    primaryButtonForegroundHoverColour:
      branding.colours.primaryForegroundHoverColour,
    primaryButtonBackgroundHoverColour:
      branding.colours.primaryBackgroundHoverColour,
    primaryButtonBorderHoverColour: branding.colours.primaryBorderHoverColour,
    secondaryButtonForegroundColour: branding.colours.secondaryForegroundColour,
    secondaryButtonBackgroundColour: branding.colours.secondaryBackgroundColour,
    secondaryButtonBorderColour: branding.colours.secondaryBorderColour,
    secondaryButtonForegroundHoverColour:
      branding.colours.secondaryForegroundHoverColour,
    secondaryButtonBackgroundHoverColour:
      branding.colours.secondaryBackgroundHoverColour,
    secondaryButtonBorderHoverColour:
      branding.colours.secondaryBorderHoverColour,
    tertiaryButtonForegroundColour: branding.colours.tertiaryForegroundColour,
    tertiaryButtonBackgroundColour: branding.colours.tertiaryBackgroundColour,
    tertiaryButtonBorderColour: branding.colours.tertiaryBorderColour,
    tertiaryButtonForegroundHoverColour:
      branding.colours.tertiaryForegroundHoverColour,
    tertiaryButtonBackgroundHoverColour:
      branding.colours.tertiaryBackgroundHoverColour,
    tertiaryButtonBorderHoverColour: branding.colours.tertiaryBorderHoverColour,
  },
  uiElements: {
    primaryButton: {
      primaryButtonHeight:
        branding.uiElements.primaryButton &&
        branding.uiElements.primaryButton.primaryButtonHeight,
      primaryButtonCasing:
        branding.uiElements.primaryButton &&
        branding.uiElements.primaryButton.primaryButtonCasing,
      buttonStyle:
        branding.uiElements.primaryButton &&
        branding.uiElements.primaryButton.buttonStyle,
    },
    secondaryButton: {
      secondaryButtonHeight:
        branding.uiElements.secondaryButton &&
        branding.uiElements.secondaryButton.secondaryButtonHeight,
      secondaryButtonCasing:
        branding.uiElements.secondaryButton &&
        branding.uiElements.secondaryButton.secondaryButtonCasing,
      buttonStyle:
        branding.uiElements.secondaryButton &&
        branding.uiElements.secondaryButton.buttonStyle,
    },
    tertiaryButton: {
      tertiaryButtonHeight:
        branding.uiElements.tertiaryButton &&
        branding.uiElements.tertiaryButton.tertiaryButtonHeight,
      tertiaryButtonCasing:
        branding.uiElements.tertiaryButton &&
        branding.uiElements.tertiaryButton.tertiaryButtonCasing,
      buttonStyle:
        branding.uiElements.tertiaryButton &&
        branding.uiElements.tertiaryButton.buttonStyle,
    },
  },
  logos: {
    ...settings.logos,
  },
});

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function RadioButtonActive({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg
        viewBox="0 0 25 25"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="1em"
        height="1em"
      >
        <defs>
          <circle id="a" cx={12.5} cy={12.5} r={12.5} />
        </defs>
        <g fill="none" fillRule="evenodd">
          <use fill="#FFF" xlinkHref="#a" />
          <circle stroke={colour} cx={12.5} cy={12.5} r={12} />
          <circle fill={colour} cx={12.5} cy={12.5} r={6.875} />
        </g>
      </svg>
    </IconWrapper>
  );
}

RadioButtonActive.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

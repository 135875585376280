// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
};

const StyledLink = styled.a`
  font-family: ${(props: Props) => props.theme.fontFamily};
  font-size: ${(props: Props) => props.theme.fontSizeContent};
  color: ${(props: Props) => props.theme.colours.linkAction};
  cursor: pointer;
`;

export default function Link(props: Props) {
  return <StyledLink {...props} />;
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import DropdownFilter from './SearchFilters/DropdownFilter';
import { Button, HeadingOne, Paragraph } from './Global';
import RefreshIcon from './JaguarIcons/Global/Refresh';
import UserLocation from './UserLocation';

type Props = {
  translations: object,
  dropdownTheme: object,
  count: number,
  resetFilters: () => void,
  handleLocationChange: (location: Location) => void,
  handleMaxDistanceChange: (distance: string) => void,
  buttonPress: () => void,
  filterSearchUrl: {
    resetRangeCount: number,
  },
  globalStyling: object,
  pathByModule: func,
  maxDistance: number,
  locationValue: string,
  availableLabelFont: font,
  distanceOptions: (translations: Object) => Object[],
  distanceFilterInitialSelectedIndex: number,
  searchCountryCode: string,
  config: Object,
  newFilters?: boolean,
  locationIconColour: string,
  locationIconUrl: string,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  background-color: #d8d8d8;
  padding: 6px 20px;
  height: 80px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 6px 20px;
  padding: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0 5px 15px;
  background: #ffffff;
  ${({ newFilters }) =>
    !newFilters
      ? `
    box-sizing: border-box;
    height: 46px;
    border-right: 1px solid #d8d8d8;
  `
      : ''}
`;
const VehicleCount = styled.div`
  display: flex;
  flex-direction: column;
  color: #0c121c;
  margin-top: -5px;
  text-align: center;
  & span:first-child {
    height: 37px;
    width: 64px;
    font-size: 24px;
    line-height: 37px;
  }

  & span:nth-child(2) {
    height: 16px;
    width: 65px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }
`;

const DropdownContainer = styled.div`
  height: ${({ newFilters }) => (newFilters ? '40px;' : '46px;')};
  box-sizing: border-box;
  width: 160px;
  ${({ newFilters }) => (newFilters ? 'border: 1px solid #d8d8d8' : '')};
  display: flex;
  align-items: center;
  background: #ffffff;
  margin: 5px 15px 5px 0;

  & h2 {
    height: 21px;
    width: 99px;
    margin-top: 8px;
    font-size: 14px;
  }
  & span {
    color: #1f1f1f;
    font-size: 14px;
    line-height: 17px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function labelWithIcon(label, iconColour, Icon) {
  return (
    <IconWrapper>
      <Icon width="24px" height="24px" colour={iconColour} />
      <div style={{ marginLeft: '8px', marginRight: '8px' }}>{label}</div>
    </IconWrapper>
  );
}

export default class StickySearchBar extends Component<Props, *> {
  static defaultProps = {
    count: 0,
  };

  resetAllFilters = () => this.props.resetFilters();

  handleSelectedDistanceChange = ({ value }) =>
    this.props.handleMaxDistanceChange(value);

  goToSearch = () => {
    this.props.buttonPress();
  };

  handleLocationChange = (latitude, longitude) => {
    this.props.handleLocationChange({
      latitude,
      longitude,
    });
  };

  render() {
    const {
      translations,
      dropdownTheme,
      maxDistance,
      globalStyling,
      filterSearchUrl: { resetRangeCount },
      count,
      distanceOptions,
      distanceFilterInitialSelectedIndex,
      searchCountryCode,
      config,
      newFilters,
      locationIconUrl,
      location: { latitude, longitude },
    } = this.props;

    const { resetAllFilters, handleSelectedDistanceChange, goToSearch } = this;

    const selectedLocationDistanceOption = () => {
      const options = distanceOptions(translations);
      return (
        options.find(o => o.value === maxDistance) ||
        options[distanceFilterInitialSelectedIndex || 0]
      );
    };

    const {
      colours: { brandPrimaryColour },
      uiElements: { secondaryButton },
    } = globalStyling;

    return (
      <Container>
        <ButtonContainer>
          <Button
            data-qa-hook="reset-search-button"
            applyStyle="secondary"
            buttonStyle="ButtonStyleBTT"
            styleOverride={() => 'height: 44px; width: 238px;'}
            text={labelWithIcon(
              translations.searchCTAResetSearch,
              brandPrimaryColour && brandPrimaryColour.value,
              RefreshIcon,
            )}
            onClick={resetAllFilters}
          />
        </ButtonContainer>
        <InputContainer newFilters={newFilters}>
          {newFilters ? (
            <UserLocation
              placeholderText={translations.searchLocationInputPlaceholder}
              newFilters={newFilters}
              iconUrl={config.locationIconUrl}
              browserLocationCallback={this.handleLocationChange}
              inputLocationCallback={this.handleLocationChange}
              options={{
                latitude,
                longitude,
                countryCode: searchCountryCode,
                autocompletePosition: 'above',
              }}
            />
          ) : (
            <UserLocation
              placeholderText={translations.searchLocationInputPlaceholder}
              iconUrl={locationIconUrl}
              newFilters={newFilters}
              browserLocationCallback={this.handleLocationChange}
              inputLocationCallback={this.handleLocationChange}
              options={{
                latitude,
                longitude,
                countryCode: searchCountryCode,
                autocompletePosition: 'above',
              }}
            />
          )}
        </InputContainer>
        <DropdownContainer newFilters={newFilters}>
          <DropdownFilter
            width={120}
            position="relative"
            theme={dropdownTheme}
            layout="above"
            onOptionClick={handleSelectedDistanceChange}
            border={false}
            height={30}
            options={distanceOptions(translations)}
            key={resetRangeCount}
            initialSelectedValue={selectedLocationDistanceOption()}
            selectedValue={maxDistance}
            placeholder={translations.distanceDropdownPlaceholder}
          />
        </DropdownContainer>
        <VehicleCount>
          <HeadingOne
            styleOverride={() => 'font-size: 26px; margin: 0; color: #0C121C;'}
          >
            {count}
          </HeadingOne>
          <Paragraph
            styleOverride={() =>
              'font-size: 13px; margin: 0; color: #0C121C; text-transform: uppercase;'
            }
          >
            {translations.searchCarNumberAvailable}
          </Paragraph>
        </VehicleCount>
        <ButtonContainer>
          <Button
            buttonStyle={secondaryButton && secondaryButton.buttonStyle}
            applyStyle="primary"
            text={translations.searchCTASearch}
            styleOverride={() => 'height: 44px; width: 238px;'}
            onClick={goToSearch}
          />
        </ButtonContainer>
      </Container>
    );
  }
}

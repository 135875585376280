// @flow
import type { Site, MediaContent } from '../types/siteBuilder';
import type { MediaType, MediaResource } from '../types/media';

const SetField: 'SET GLOBAL FIELD' = 'SET GLOBAL FIELD';
type FieldPayload = { index: number, key: string, value: any };

export type SetFieldAction = { type: typeof SetField, payload: FieldPayload };
const setField = (index: number, key: string, value: any): SetFieldAction => ({
  type: SetField,
  payload: {
    index,
    key,
    value,
  },
});

const SetListField: 'SET GLOBAL LIST FIELD' = 'SET GLOBAL LIST FIELD';
type ListFieldPayload = {
  moduleIndex: number,
  listKey: string,
  listIndex: number,
  fieldKey: string,
  value: any,
};

export type SetListFieldAction = {
  type: typeof SetListField,
  payload: ListFieldPayload,
};
const setListField = (
  moduleIndex: number,
  listKey: string,
  listIndex: number,
  fieldKey: string,
  value: any,
): SetListFieldAction => ({
  type: SetListField,
  payload: {
    moduleIndex,
    listKey,
    listIndex,
    fieldKey,
    value,
  },
});

const ShowAccordion: 'TOGGLE ACCORDION' = 'TOGGLE ACCORDION';
type AccordionPayload = { id: string, show: boolean };
export type ShowAccordionAction = {
  type: typeof ShowAccordion,
  payload: AccordionPayload,
};
const showAccordion = (id: string, show: boolean): ShowAccordionAction => ({
  type: ShowAccordion,
  payload: {
    id,
    show,
  },
});

const SetPreviewScreenSize: 'SET PREVIEW SIZE' = 'SET PREVIEW SIZE';
type PreviewScreenSizePayload = { size: string };
export type SetPreviewScreenSizeAction = {
  type: typeof SetPreviewScreenSize,
  payload: PreviewScreenSizePayload,
};
const setPreviewScreenSize = (size: string): SetPreviewScreenSizeAction => ({
  type: SetPreviewScreenSize,
  payload: { size },
});

const SetMessage: 'SET MESSAGE' = 'SET MESSAGE';
type SetMessagePayload = { message?: string };
export type SetMessageAction = {
  type: typeof SetMessage,
  payload: SetMessagePayload,
};
const setMessage = (message?: string): SetMessageAction => ({
  type: SetMessage,
  payload: { message },
});

const AddSection: '[GLOBAL] ADD SECTION' = '[GLOBAL] ADD SECTION';
type AddSectionPayload = {
  moduleName: string,
  moduleIndex: number,
  listKey: string,
  globalStyling: Object,
};
export type AddSectionAction = {
  type: typeof AddSection,
  payload: AddSectionPayload,
};
const addSection = globalStyling => (
  moduleIndex: number,
  moduleName: string,
  listKey: string,
): AddSectionAction => ({
  type: AddSection,
  payload: {
    moduleName,
    moduleIndex,
    listKey,
    globalStyling,
  },
});

const DeleteSection: '[GLOBAL] DELETE SECTION' = '[GLOBAL] DELETE SECTION';
type DeleteSectionPayload = {
  moduleIndex: number,
  listKey: string,
  listIndex: number,
};
export type DeleteSectionAction = {
  type: typeof DeleteSection,
  payload: DeleteSectionPayload,
};
const deleteSection = (
  moduleIndex: number,
  listKey: string,
  listIndex: number,
): DeleteSectionAction => ({
  type: DeleteSection,
  payload: {
    moduleIndex,
    listKey,
    listIndex,
  },
});

const ToggleSection: '[GLOBAL] TOGGLE SECTION' = '[GLOBAL] TOGGLE SECTION';
type ToggleSectionPayload = { sectionListManyId: string, index: number };
export type ToggleSectionAction = {
  type: typeof ToggleSection,
  payload: ToggleSectionPayload,
};
const toggleSection = (
  sectionListManyId: string,
  index: number,
): ToggleSectionAction => ({
  type: ToggleSection,
  payload: {
    sectionListManyId,
    index,
  },
});

const SaveGlobalModules: 'SAVE GLOBAL MODULES' = 'SAVE GLOBAL MODULES';
export type SaveGlobalModulesAction = {
  type: typeof SaveGlobalModules,
  payload: { siteId: string, site: Site },
};
const saveGlobalModules = (
  siteId: string,
  site: Site,
): SaveGlobalModulesAction => ({
  type: SaveGlobalModules,
  payload: { siteId, site },
});

const SaveGlobalModulesSuccess: 'SAVE GLOBAL MODULES SUCCESS' =
  'SAVE GLOBAL MODULES SUCCESS';
export type SaveGlobalModulesSuccessAction = {
  type: typeof SaveGlobalModulesSuccess,
  payload: Site,
};
const saveGlobalModulesSuccess = (
  site: Site,
): SaveGlobalModulesSuccessAction => ({
  type: SaveGlobalModulesSuccess,
  payload: site,
});

const SaveGlobalModulesFailure: 'SAVE GLOBAL MODULES FAILURE' =
  'SAVE GLOBAL MODULES FAILURE';
export type SaveGlobalModulesFailureAction = {
  type: typeof SaveGlobalModulesFailure,
  payload: Error,
};
const saveGlobalModulesFailure = (
  error: Error,
): SaveGlobalModulesFailureAction => ({
  type: SaveGlobalModulesFailure,
  payload: error,
});

const LoadGlobalModules: 'LOAD GLOBAL MODULES' = 'LOAD GLOBAL MODULES';
export type LoadGlobalModulesAction = {
  type: typeof LoadGlobalModules,
  payload: string,
};
const loadGlobalModules = (siteId: string): LoadGlobalModulesAction => ({
  type: LoadGlobalModules,
  payload: siteId,
});

const LoadGlobalModulesSuccess: 'LOAD GLOBAL MODULES SUCCESS' =
  'LOAD GLOBAL MODULES SUCCESS';
export type LoadGlobalModulesSuccessAction = {
  type: typeof LoadGlobalModulesSuccess,
  payload: Site,
};
const loadGlobalModulesSuccess = (
  site: Site,
): LoadGlobalModulesSuccessAction => ({
  type: LoadGlobalModulesSuccess,
  payload: site,
});

const LoadGlobalModulesFailure: 'LOAD GLOBAL MODULES FAILURE' =
  'LOAD GLOBAL MODULES FAILURE';
export type LoadGlobalModulesFailureAction = {
  type: typeof LoadGlobalModulesFailure,
  payload: Error,
};
const loadGlobalModulesFailure = (
  error: Error,
): LoadGlobalModulesFailureAction => ({
  type: LoadGlobalModulesFailure,
  payload: error,
});

const PublishSiteRequested: 'PUBLISH SITE REQUESTED' = 'PUBLISH SITE REQUESTED';
export type PublishSiteRequestedAction = {
  type: typeof PublishSiteRequested,
};
const publishSiteRequested = (): PublishSiteRequestedAction => ({
  type: PublishSiteRequested,
});

const PublishSiteCancelled: 'PUBLISH SITE CANCELLED' = 'PUBLISH SITE CANCELLED';
export type PublishSiteCancelledAction = {
  type: typeof PublishSiteCancelled,
};
const publishSiteCancelled = (): PublishSiteCancelledAction => ({
  type: PublishSiteCancelled,
});

const PublishSite: 'PUBLISH SITE' = 'PUBLISH SITE';
export type PublishSiteAction = {
  type: typeof PublishSite,
  payload: string,
};
const publishSite = (siteId: string): PublishSiteAction => ({
  type: PublishSite,
  payload: siteId,
});

const PublishSiteSuccess: 'PUBLISH SITE SUCCESS' = 'PUBLISH SITE SUCCESS';
export type PublishSiteSuccessAction = {
  type: typeof PublishSiteSuccess,
  payload: string,
};
const publishSiteSuccess = (url: string): PublishSiteSuccessAction => ({
  type: PublishSiteSuccess,
  payload: url,
});

const PublishSiteFailure: 'PUBLISH SITE FAILURE' = 'PUBLISH SITE FAILURE';
export type PublishSiteFailureAction = {
  type: typeof PublishSiteFailure,
  payload: Error,
};
const publishSiteFailure = (error: Error): PublishSiteFailureAction => ({
  type: PublishSiteFailure,
  payload: error,
});

const PublishPreviewSite: 'PUBLISH PREVIEW SITE' = 'PUBLISH PREVIEW SITE';
export type PublishPreviewSiteAction = {
  type: typeof PublishPreviewSite,
  payload: {
    siteId: string,
    defaultLanguageIso: string,
    previewWindow: Window,
  },
};
const publishPreviewSite = (
  siteId: string,
  defaultLanguageIso: string,
  previewWindow: Window,
): PublishPreviewSiteAction => ({
  type: PublishPreviewSite,
  payload: {
    siteId,
    defaultLanguageIso,
    previewWindow,
  },
});

const PublishPreviewSiteSuccess: 'PUBLISH PREVIEW SITE SUCCESS' =
  'PUBLISH PREVIEW SITE SUCCESS';
export type PublishPreviewSiteSuccessAction = {
  type: typeof PublishPreviewSiteSuccess,
  payload: string,
};
const publishPreviewSiteSuccess = (
  url: string,
): PublishPreviewSiteSuccessAction => ({
  type: PublishPreviewSiteSuccess,
  payload: url,
});

const PublishPreviewSiteFailure: 'PUBLISH PREVIEW SITE FAILURE' =
  'PUBLISH PREVIEW SITE FAILURE';
export type PublishPreviewSiteFailureAction = {
  type: typeof PublishPreviewSiteFailure,
  payload: Error,
};
const publishPreviewSiteFailure = (
  error: Error,
): PublishPreviewSiteFailureAction => ({
  type: PublishPreviewSiteFailure,
  payload: error,
});

const LoadMediaContent: 'LOAD MEDIA CONTENT' = 'LOAD MEDIA CONTENT';
export type LoadMediaContentAction = { type: typeof LoadMediaContent };
const loadMediaContent = (): LoadMediaContentAction => ({
  type: LoadMediaContent,
});

const LoadMediaContentSuccess: 'LOAD MEDIA CONTENT SUCCESS' =
  'LOAD MEDIA CONTENT SUCCESS';
export type LoadMediaContentSuccessAction = {
  type: typeof LoadMediaContentSuccess,
  payload: MediaContent,
};
const loadMediaContentSuccess = (
  content: MediaContent,
): LoadMediaContentSuccessAction => ({
  type: LoadMediaContentSuccess,
  payload: content,
});

const LoadMediaContentFailure: 'LOAD MEDIA CONTENT FAILURE' =
  'LOAD MEDIA CONTENT FAILURE';
export type LoadMediaContentFailureAction = {
  type: typeof LoadMediaContentFailure,
  payload: string,
};
const loadMediaContentFailure = (
  error: string,
): LoadMediaContentFailureAction => ({
  type: LoadMediaContentFailure,
  payload: error,
});

const UploadMediaContent: 'UPLOAD MEDIA CONTENT' = 'UPLOAD MEDIA CONTENT';
export type UploadMediaContentAction = {
  type: typeof UploadMediaContent,
  payload: {
    media: string,
    transform: object,
    type: MediaType,
  },
};
const uploadMediaContent = (
  image: string,
  transform: object,
  type: string,
): UploadMediaContentAction => ({
  type: UploadMediaContent,
  payload: { image, transform, type },
});

const UploadMediaContentSuccess: 'UPLOAD MEDIA CONTENT SUCCESS' =
  'UPLOAD MEDIA CONTENT SUCCESS';
export type UploadMediaContentSuccessAction = {
  type: typeof UploadMediaContentSuccess,
  payload: { resources: MediaResource[], type: MediaType },
};
const uploadMediaContentSuccess = (
  resources: MediaResource[],
  type: MediaType,
): UploadMediaContentSuccessAction => ({
  type: UploadMediaContentSuccess,
  payload: { resources, type },
});

const UploadMediaContentFailure: 'UPLOAD MEDIA CONTENT FAILURE' =
  'UPLOAD MEDIA CONTENT FAILURE';
export type UploadMediaContentFailureAction = {
  type: typeof UploadMediaContentFailure,
  payload: string,
};
const uploadMediaContentFailure = (
  error: string,
): UploadMediaContentFailureAction => ({
  type: UploadMediaContentFailure,
  payload: error,
});

const DeleteMediaContent: 'DELETE MEDIA CONTENT' = 'DELETE MEDIA CONTENT';
export type DeleteMediaContentAction = {
  type: typeof DeleteMediaContent,
  payload: { mediaPublicId: string, type: MediaType },
};
const deleteMediaContent = (
  mediaPublicId: string,
  type: MediaType,
): DeleteMediaContentAction => ({
  type: DeleteMediaContent,
  payload: { mediaPublicId, type },
});

const DeleteMediaContentSuccess: 'DELETE MEDIA CONTENT SUCCESS' =
  'DELETE MEDIA CONTENT SUCCESS';
export type DeleteMediaContentSuccessAction = {
  type: typeof DeleteMediaContentSuccess,
  payload: { mediaPublicId: string, type: MediaType },
};
const deleteMediaContentSuccess = (
  mediaPublicId: string,
  type: MediaType,
): DeleteMediaContentSuccessAction => ({
  type: DeleteMediaContentSuccess,
  payload: { mediaPublicId, type },
});

const DeleteMediaContentFailure: 'DELETE MEDIA CONTENT FAILURE' =
  'DELETE MEDIA CONTENT FAILURE';
export type DeleteMediaContentFailureAction = {
  type: typeof DeleteMediaContentFailure,
  payload: string,
};
const deleteMediaContentFailure = (
  error: string,
): DeleteMediaContentFailureAction => ({
  type: DeleteMediaContentFailure,
  payload: error,
});

export const actions = {
  setField,
  setListField,
  showAccordion,
  setPreviewScreenSize,
  setMessage,
  addSection,
  deleteSection,
  toggleSection,
  saveGlobalModules,
  saveGlobalModulesSuccess,
  saveGlobalModulesFailure,
  loadGlobalModules,
  loadGlobalModulesSuccess,
  loadGlobalModulesFailure,
  publishSite,
  publishSiteSuccess,
  publishSiteFailure,
  publishSiteRequested,
  publishSiteCancelled,
  publishPreviewSite,
  publishPreviewSiteSuccess,
  publishPreviewSiteFailure,
  loadMediaContent,
  loadMediaContentSuccess,
  loadMediaContentFailure,
  uploadMediaContent,
  uploadMediaContentSuccess,
  uploadMediaContentFailure,
  deleteMediaContent,
  deleteMediaContentSuccess,
  deleteMediaContentFailure,
};

export const constants = {
  SetField,
  SetListField,
  ShowAccordion,
  SetPreviewScreenSize,
  SetMessage,
  AddSection,
  DeleteSection,
  ToggleSection,
  SaveGlobalModules,
  SaveGlobalModulesSuccess,
  SaveGlobalModulesFailure,
  LoadGlobalModules,
  LoadGlobalModulesSuccess,
  LoadGlobalModulesFailure,
  PublishSite,
  PublishSiteSuccess,
  PublishSiteFailure,
  PublishSiteRequested,
  PublishSiteCancelled,
  PublishPreviewSite,
  PublishPreviewSiteSuccess,
  PublishPreviewSiteFailure,
  LoadMediaContent,
  LoadMediaContentSuccess,
  LoadMediaContentFailure,
  UploadMediaContent,
  UploadMediaContentSuccess,
  UploadMediaContentFailure,
  DeleteMediaContent,
  DeleteMediaContentSuccess,
  DeleteMediaContentFailure,
};

export type Action =
  | SetFieldAction
  | SetListFieldAction
  | ShowAccordionAction
  | SetPreviewScreenSizeAction
  | SetMessageAction
  | AddSectionAction
  | DeleteSectionAction
  | ToggleSectionAction
  | SaveGlobalModulesAction
  | SaveGlobalModulesSuccessAction
  | SaveGlobalModulesFailureAction
  | LoadGlobalModulesAction
  | LoadGlobalModulesSuccessAction
  | LoadGlobalModulesFailureAction
  | PublishSiteAction
  | PublishSiteSuccessAction
  | PublishSiteFailureAction
  | PublishSiteRequestedAction
  | PublishSiteCancelledAction
  | PublishPreviewSiteAction
  | PublishPreviewSiteSuccessAction
  | PublishPreviewSiteFailureAction
  | LoadMediaContentAction
  | LoadMediaContentSuccessAction
  | LoadMediaContentFailureAction
  | UploadMediaContentAction
  | UploadMediaContentSuccessAction
  | UploadMediaContentFailureAction
  | DeleteMediaContentAction
  | DeleteMediaContentSuccessAction
  | DeleteMediaContentSuccessAction;

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Menu({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 29 24">
        <path d="M4 5h20v2H4V5zm0 6h20v2H4v-2zm0 6h20v2H4v-2z" />
      </svg>
    </IconWrapper>
  );
}

Menu.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import RangeSlider from '../../components/RangeSlider/RangeSlider';
import BudgetResultsPane from '../../components/BudgetResultsPane/BudgetResultsPane';
import PathnameSubNavBar from '../../components/PathnameSubNavBar';
import media from '../../theme';
import { actions as routerActions } from '../../actions/router';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { rangePayloads } from '../../shared/searchFilter/helper';
import { localiseCurrency } from '../../shared/localisation/numbers';
import { formatUrl } from '../../shared/routing';
import { Button, HeadingThree } from '../../components/Global';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

const BudgetSearchContainer = styled.div.withConfig({
  displayName: 'BudgetSearchContainer',
})`
  ${media.min.large`
  padding: 0;
  `};
`;

const BudgetSliderContainer = styled.div.withConfig({
  displayName: 'BudgetSliderContainer',
})`
  padding: 0 13%;
`;

const SliderSection = styled.div.withConfig({
  displayName: 'SliderSection',
})`
  margin-top: 40px;
  ${media.min.large`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

const HorizontalLine = styled.div.withConfig({
  displayName: 'HorizontalLine',
})`
  border-top: 1px solid #dedede;
  box-sizing: border-box;

  ${media.min.large`
  display:none;
`};
`;

const Title = styled.div.withConfig({
  displayName: 'Title',
})`
  text-align: center;
  margin: 40px 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  ${media.min.large`
    text-align: left;
  `};
`;

const BodyText = styled.div.withConfig({
  displayName: 'BodyText',
})`
  margin-bottom: 40px;
  width: 100%;
  color: #444;
  font-size: 18px;
  ${media.min.large`
    width: 45%;
  `};
`;

const SliderLabel = styled.div.withConfig({
  displayName: 'SliderLabel',
})`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const SliderContainer = styled.div.withConfig({
  displayName: 'SliderContainer',
})`
  ${media.min.large`
    width: 45%;
    margin-bottom: 40px;
  `};
`;

const SimilarPricesBanner = styled.div.withConfig({
  displayName: 'SimilarPricesBanner',
})`
  height: 150px;
  font-size: 24px;
  background-color: #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 0 5%;
`;

type Translations = {
  title: string,
  bodyText: string,
  budgetMatch: string,
  learnMoreButtonText: string,
  viewButtonText: string,
  noResultsText: string,
  budgetSearchBudgetLink: string,
  budgetSearchFinanceLink: string,
  budgetSearchSliderTitle: string,
  searching: string,
};

export interface Config {
  translations: Translations;
  make: string;
  market: string;
}

type Props = {
  handleRangeFilters: (string, number[]) => void,
  config: Config,
  actions: {
    init: Function,
    getBudgetSearchGroups: ({}) => void,
    getBudgetSearchRange: ({}) => void,
  },
  dispatch: Function,
  preview: boolean,
  state: Object,
  pathByModule: Function,
  globalStyling: Object,
  history: Object,
};

export default class BudgetSearch extends Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      range: {},
      budgetIncreasePercentage: 20,
    };
  }

  componentDidMount() {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const { init, getBudgetSearchRange } = this.props.actions;
    const { preview, dispatch } = this.props;
    init();
    getBudgetSearchRange({
      preview,
      budgetIncreasePercentage: this.state.budgetIncreasePercentage,
    });

    dispatch(searchFilterActions.resetFilters());
  }

  onChangeRangeLabel = (rangeValues: [number, number]) =>
    this.setState(() => ({
      range: { min: rangeValues[0], max: rangeValues[1] },
    }));

  render() {
    const { translations, market, make, stylingConfig } = this.props.config;
    const { searching } = this.props.state;
    const {
      preview,
      dispatch,
      state,
      pathByModule,
      globalStyling,
      config,
      history,
    } = this.props;
    const fnIfNotPreview = fn => (preview ? () => {} : fn);
    const goToModule = name =>
      dispatch(routerActions.navigate(pathByModule(name)));
    const {
      actions: { getBudgetSearchGroups },
    } = this.props;

    const fullRangeOptions = state.fullRangeOptions || {};
    const defaultStep = fullRangeOptions.min
      ? fullRangeOptions.min
      : fullRangeOptions.min;
    const monthlyPaymentRangeId = 'monthlyPaymentRange';

    const getMinValue = this.state.range.min || fullRangeOptions.min;
    const getMaxValue = this.state.range.max || fullRangeOptions.max;

    const budgetSearchRange = {
      id: 'budgetSearchRange',
      name: 'budgetSearchRange',
      title: 'My Budget Search',
      unit: '€',
      step: defaultStep,
      defaultValue: [
        fullRangeOptions.min,
        fullRangeOptions.min + fullRangeOptions.max,
      ],
      fullRangeValues: {
        min: Math.round(fullRangeOptions.min / defaultStep) * defaultStep,
        max: Math.round(fullRangeOptions.max / defaultStep) * defaultStep,
      },
    };

    const learnMore = model =>
      dispatch(routerActions.navigate(formatUrl(`range/${model}`)));
    const rangeFilterUrl = ([minValue, maxValue]) => {
      const [minfilter, maxfilter] = rangePayloads[monthlyPaymentRangeId];
      return `${minfilter}=${minValue}&${maxfilter}=${maxValue}`;
    };

    const searchClick = (
      modelRange: string,
      isBudgetIncreased: boolean = false,
    ) => {
      const min = isBudgetIncreased ? getMaxValue : getMinValue;
      const max = isBudgetIncreased
        ? Math.round(getMaxValue * 1.2)
        : getMaxValue;
      dispatch(searchFilterActions.resetFilters());
      dispatch(
        searchFilterActions.filterByModelRange({
          section: 'models',
          filter: modelRange,
        }),
      );
      dispatch(
        searchFilterActions.changeRangeFilter({
          id: monthlyPaymentRangeId,
          values: [min, max],
        }),
      );
      dispatch(
        searchFilterActions.getSearchFilters({
          market,
          make,
          filters: `models=${modelRange}&${rangeFilterUrl([min, max])}`,
        }),
      );
      goToModule('SearchResults');
    };

    const searchIncreasedClick = (modelRange: string) =>
      searchClick(modelRange, true);

    const links = [
      {
        text: translations.budgetSearchFinanceLink,
        onClick: fnIfNotPreview(() =>
          dispatch(routerActions.navigate('financeoptions')),
        ),
        availableOnMobile: true,
        path: 'financeoptions',
      },
      {
        text: translations.budgetSearchBudgetLink,
        onClick: fnIfNotPreview(() =>
          dispatch(routerActions.navigate('budgetsearch')),
        ),
        availableOnMobile: true,
        path: 'budgetsearch',
      },
    ];

    const localisedMinValue =
      getMinValue &&
      localiseCurrency(getMinValue, this.props.marketInfo.locale, 'EUR', 0);
    const localisedMaxValue =
      getMaxValue &&
      localiseCurrency(getMaxValue, this.props.marketInfo.locale, 'EUR', 0);

    return state.fullRangeOptions ? (
      <BudgetSearchContainer>
        <PathnameSubNavBar
          onBackButtonClick={history.goBack}
          links={links}
          title={translations.budgetSearchTitle}
          subNavFontTheme={config.subNavFont}
          subNavBGColour={config.subNavBGColour}
          subNavBorderColour={config.subNavBorderColour}
          subNavBarItemSelectedBackgroundColour={
            config.subNavBarItemSelectedBackgroundColour
          }
          globalStyling={globalStyling}
          subNavBarItemSelectedFontColour={
            config.subNavBarItemSelectedFontColour
          }
        />
        <BudgetSliderContainer>
          <Title>{translations.budgetSearchTitle}</Title>
          <HorizontalLine />
          <SliderSection>
            <BodyText>
              {translateFromTemplate(
                'bodyText',
                {
                  MIN: localisedMinValue || '0 €',
                  MAX: localisedMaxValue || '0 €',
                  COUNT: state.affordableModels
                    ? state.affordableModels.length
                    : 0,
                },
                translations,
              )}
            </BodyText>
            <SliderContainer>
              <SliderLabel>
                <div>{translations.budgetSearchSliderTitle}</div>
                <div>{`${getMinValue} € - ${getMaxValue} €`}</div>
              </SliderLabel>
              <RangeSlider
                stylingConfig={stylingConfig}
                step={budgetSearchRange.step}
                rangeValue={budgetSearchRange.fullRangeValues}
                defaultValue={budgetSearchRange.defaultValue}
                name={budgetSearchRange.name}
                onChange={values => this.onChangeRangeLabel(values)}
                onAfterChange={values =>
                  fnIfNotPreview(
                    getBudgetSearchGroups({
                      budgetRange: {
                        min: values[0],
                        max: values[1],
                      },
                      budgetIncreasePercentage: this.state
                        .budgetIncreasePercentage,
                      preview,
                    }),
                  )
                }
              />
            </SliderContainer>
          </SliderSection>
          <HeadingThree
            styleOverride={() => `
              font-size: 18px;
              margin: 20px 0;
              text-align: center;
            `}
            mobileStyleOverride={() => [
              { queryPath: 'max.large', template: 'margin: 40px 0' },
            ]}
          >
            {searching
              ? translations.searching
              : state.affordableModels != null &&
                state.affordableModels.length > 0
              ? translateFromTemplate(
                  'budgetMatch',
                  {
                    COUNT: state.affordableModels
                      ? state.affordableModels.length
                      : 0,
                  },
                  translations,
                )
              : translations.noResultsText}
          </HeadingThree>
        </BudgetSliderContainer>
        {!searching &&
          state.affordableModels &&
          state.affordableModels.length && (
            <BudgetResultsPane
              viewNowClick={fnIfNotPreview(searchClick)}
              results={state.affordableModels}
              translations={translations}
              moreInfoClick={learnMore}
              placeholdingImage={state.placeholdingImage}
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              globalStyling={globalStyling}
              locale={this.props.marketInfo.locale}
            />
          )}
        {!searching &&
          state.suggestedModels &&
          state.suggestedModels.length > 0 && (
            <div>
              <SimilarPricesBanner>
                {translateFromTemplate(
                  'similarPricesText',
                  {
                    INCREASE: state.suggestedBudgetIncrease,
                  },
                  translations,
                )}
              </SimilarPricesBanner>
              <BudgetResultsPane
                results={state.suggestedModels}
                translations={translations}
                viewNowClick={fnIfNotPreview(searchIncreasedClick)}
                moreInfoClick={learnMore}
                placeholdingImage={state.placeholdingImage}
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
                globalStyling={globalStyling}
                locale={this.props.marketInfo.locale}
              />
            </div>
          )}
        <SimilarPricesBanner>
          <Button
            onClick={() => {
              goToModule('SearchResults');
            }}
            text={translations.searchButtonText}
            applyStyle="primary"
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
          />
        </SimilarPricesBanner>
      </BudgetSearchContainer>
    ) : null;
  }
}

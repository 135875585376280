// @flow
import React from 'react';
import styled from 'styled-components';

import FieldError from './FieldError';
import WidthWrapper from './WidthWrapper';
import media from '../../../theme';

const FormFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  ${media.min.medium`
    padding: 20px 5px;
  `};
`;

const RadioGroupTitle = styled.div`
  margin-bottom: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  position: relative;
`;

const StyledRadioButton = styled.div`
  position: absolute;
  left: 4px;
  top: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ borderColour }) => borderColour || '#999999'};
  background-color: ${({ backgroundColour }) => backgroundColour || '#ffffff'};
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:checked + ${StyledRadioButton} {
    background-color: ${({ backgroundColourChecked }) =>
      backgroundColourChecked || '#ffffff'};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 5px;
      background: ${({ colourChecked }) => colourChecked || '#999999'};
    }
  }
`;

type Props = {
  required: boolean,
  name: string,
  label: string,
  onChange: Function,
  options: Array,
  value: string,
  error: string,
  uiElements: Object,
};

export default ({
  required,
  name,
  label,
  onChange,
  options,
  value,
  error,
  uiElements: { radio },
  translations,
}: Props) => {
  return (
    <WidthWrapper>
      <FormFieldContainer role="group" aria-labelledby={name}>
        <RadioGroupTitle id={name}>
          {translations[`${name}Label`]} {required && '*'}
        </RadioGroupTitle>
        {options.map(translationKey => {
          const optionLabel = translations[`${translationKey}Value`];
          const optionValue = translations[`${translationKey}Value`];

          return (
            <Item key={translationKey}>
              <label htmlFor={`${name}-${translationKey}`}>
                <RadioButton
                  type="radio"
                  name={optionLabel}
                  id={`${name}-${translationKey}`}
                  value={optionValue}
                  checked={optionValue === value}
                  onChange={() => onChange({ name, value: optionValue })}
                  {...radio}
                />
                <StyledRadioButton />
                {optionLabel}
              </label>
            </Item>
          );
        })}
        {error && <FieldError>{error}</FieldError>}
      </FormFieldContainer>
    </WidthWrapper>
  );
};

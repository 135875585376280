export const defaultConfigs = {
  TextInput: {
    required: false,
    name: 'textInput',
    label: '',
  },
  Email: {
    required: false,
    name: 'email',
    label: '',
  },
  FormButton: {
    required: true,
    name: 'formButton',
    config: {
      alignment: {},
      button: {},
    },
  },
  Radio: {
    required: false,
    name: 'radio',
    label: '',
    options: [],
  },
};

// @flow
import React from 'react';
import styled from 'styled-components';

import type { Font } from '../types';
import {
  HeadingTwo,
  Paragraph,
  fontStyleOverride,
  Button,
  buttonStyleOverride,
} from './Global';

export type ItemTheme = {
  height?: number,
  align: string,
};

const Image = styled.div`
  flex: 1 1 0%;
  max-width: 100%;
  width: 100%;
  background-size: cover;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const GridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  ${({ bgColour }) => bgColour && `background-color: ${bgColour}`};
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ margin }) => `${margin && `margin: ${margin}px`}`};
  align-items: ${(props: ItemTheme) => props.align || 'flex-start'};
  justify-content: space-between;
  ${({ bottomContentGrow }) => bottomContentGrow && 'flex: 1 0 auto'};
`;

const Description = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 90%;
  margin: 0 19px 25px 19px;
`;

const AspectRatioContainer = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 43.25%;
  ${({ aspectRatio }) => aspectRatio && `padding-top: ${aspectRatio}%`};
`;

type Props = {
  linkText: string,
  imageUrl: ?string,
  headline: string,
  bodyText: string,
  bottomText: string,
  headlineTheme: Font,
  bodyTextTheme: Font,
  align: string,
  buttonProps: Object,
  globalStyling: Object,
  bottomContentGrow: ?boolean,
  onClickLink: () => void,
  margin?: number,
  bgColour: string,
  imageAspectRatioPercentage: number,
};

export default function GridItem({
  linkText,
  imageUrl,
  headline,
  bodyText,
  bottomText,
  headlineTheme,
  bodyTextTheme,
  bottomTextTheme,
  align,
  buttonProps,
  globalStyling,
  bottomContentGrow,
  onClickLink,
  margin,
  bgColour,
  imageAspectRatioPercentage,
}: Props) {
  return (
    <GridItemContainer bgColour={bgColour}>
      {imageUrl && (
        <AspectRatioContainer aspectRatio={imageAspectRatioPercentage}>
          <Image src={imageUrl} />
        </AspectRatioContainer>
      )}
      <BottomContainer
        align={align}
        bottomContentGrow={bottomContentGrow}
        margin={margin}
      >
        <Description>
          <HeadingTwo styleOverride={() => fontStyleOverride(headlineTheme)}>
            {headline}
          </HeadingTwo>
          <Paragraph styleOverride={() => fontStyleOverride(bodyTextTheme)}>
            {bodyText}
          </Paragraph>
          <Paragraph styleOverride={() => fontStyleOverride(bottomTextTheme)}>
            {bottomText}
          </Paragraph>
        </Description>
      </BottomContainer>
      {linkText && (
        <ButtonContainer>
          <Button
            buttonStyle={
              buttonProps.buttonStyle ||
              (globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle)
            }
            applyStyle={buttonProps.buttonType}
            styleOverride={() =>
              buttonStyleOverride(
                buttonProps.font,
                buttonProps.backgroundColour,
                buttonProps.hover,
                buttonProps.buttonSize,
                buttonProps.buttonHoverColour,
              )
            }
            text={linkText}
            onClick={onClickLink}
          />
        </ButtonContainer>
      )}
    </GridItemContainer>
  );
}
GridItem.defaultProps = {
  margin: 20,
};

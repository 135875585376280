import { moduleConstants } from '../../utilities/reducerMacros';
import { convertVehiclesPriceCurrency } from '../currencyConversion/helpers';

const modulePrefix = 'MOD_SHORTLIST_SHARED';

export const constants = moduleConstants(modulePrefix, [
  'TOGGLE_VEHICLE',
  'TOGGLE_VEHICLE_SUCCESS',
  'GET_VEHICLES',
  'GET_VEHICLES_SUCCESS',
  'SAVE_SHORTLISTED_VEHICLES',
  'SAVE_SHORTLISTED_VEHICLES_SUCCESS',
  'SAVE_SHORTLISTED_VEHICLES_FAILURE',
  'UPDATE_FINANCE_SUCCESS',
  'UPDATE_VEHICLE_PRICES',
]);

export const actions = {
  toggleVehicle: payload => ({ type: constants.TOGGLE_VEHICLE, payload }),
  toggleVehicleSuccess: payload => ({
    type: constants.TOGGLE_VEHICLE_SUCCESS,
    payload,
  }),
  getVehicles: () => ({ type: constants.GET_VEHICLES }),
  getVehiclesSuccess: payload => ({
    type: constants.GET_VEHICLES_SUCCESS,
    payload,
  }),
  saveShorlistedVehicles: () => ({ type: constants.SAVE_SHORTLISTED_VEHICLES }),
  saveShorlistedVehiclesSuccess: payload => ({
    type: constants.SAVE_SHORTLISTED_VEHICLES_SUCCESS,
    payload,
  }),
  saveShorlistedVehiclesFailure: error => ({
    type: constants.SAVE_SHORTLISTED_VEHICLES_FAILURE,
    error,
  }),
  updateFinanceSuccess: payload => ({
    type: constants.UPDATE_FINANCE_SUCCESS,
    payload,
  }),
  updateVehiclePrices: payload => ({
    type: constants.UPDATE_VEHICLE_PRICES,
    payload,
  }),
};

export const initialState = {
  vehicles: [],
};

const patch = (vehicles, vehicle) => [
  ...(vehicles.find(v => v.id === vehicle.id)
    ? [...vehicles.filter(v => v.id !== vehicle.id)]
    : [...vehicles, vehicle]),
];

const replaceIfExists = (vehicles, vehicle) => [
  ...vehicles.map(v => (v.id === vehicle.id ? vehicle : v)),
];

const updateVehicleCurrencies = (state, action) => {
  const { payload } = action;
  const { exchangeRates } = payload;
  const { currency } = payload;

  const vehicles = convertVehiclesPriceCurrency(
    state.vehicles,
    exchangeRates[currency].rates,
    currency,
  );

  return {
    ...state,
    vehicles,
  };
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: payload.vehicles,
        placeholdingImage: payload.placeholdingImage,
      };
    case constants.TOGGLE_VEHICLE_SUCCESS: {
      return {
        ...state,
        vehicles: patch(
          state.vehicles,
          payload.vehicle,
          payload.placeholdingImage,
        ),
        placeholdingImage: payload.placeholdingImage,
      };
    }
    case constants.SAVE_SHORTLISTED_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: payload.vehicles,
      };
    case constants.SAVE_SHORTLISTED_VEHICLES_FAILURE:
      return {
        ...state,
        error: payload,
      };
    case constants.UPDATE_FINANCE_SUCCESS:
      return {
        ...state,
        vehicles: replaceIfExists(state.vehicles, payload),
      };
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    default:
      return state;
  }
}

// selectors
export const getVehicles = state => state.shortlist.vehicles || [];

// @flow
import React from 'react';

import styled from 'styled-components';
import SearchResultTile from './VehicleDetailsTile';

import WindowDimensionsProvider from '../../shared/WindowDimensionsProvider';

const searchResults = {
  // dummy data so QA can test the pod
  contents: [
    {
      id: '119217',
      name: '2015 Discovery Sport',
      images: [
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/1_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/2_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/3_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/4_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/5_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/6_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/7_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/8_b9a1c6ab978c3cae.jpg',
        'http://images-ims.motortrak.com/S/SAL/CA2/BN3/GH5/609/68/1024/9_b9a1c6ab978c3cae.jpg',
      ],
      odometer: {
        units: 'Km',
        reading: 102000,
      },
      engine: '2.0 TD4',
      engineCapacity: 2000,
      handDrive: 'L',
      transmission: 'Automatico',
      fuel: 'Diesel',
      views: 3,
      price: {
        currency: 'EUR',
        value: 18500,
        monthly: '263,07',
        apr: '5,71',
        interestRate: '4,75',
      },
      co2EmissionsCombined: '0',
      distance: null,
      retailerName: 'Iperauto Bergamo Spa',
      retailerId: '15977',
      retailerPhone: '035-2924211',
      description: 'DISCOVERY SPORT 2.0 TD4 PURE',
      interior: 'Ebony',
      exterior: 'Kaikoura Stone',
      bodystyle: '5 Porte',
      registration: '2015',
      location: 'BERGAMO',
      equipmentMetaData: [
        'Vetri oscurati',
        'Sistema di controllo pressione pneumatici',
        'Cruise Control - Controllo della velocità di crociera',
        'Attrezzature per il traino',
        'Bluetooth',
        'Sedili posteriori frazionabili',
        'Climatizzatore',
        'Sedili e dotazioni degli interni',
        'Sedili e dotazioni degli interni',
        'Sedili e dotazioni degli interni',
        'Sedili e dotazioni degli interni',
        'Sedili e dotazioni degli interni',
        'Esterni',
        'Esterni',
      ],
      modelGroupName: 'Discovery Sport',
      registrationDate: '2015-10-22T00:00:00',
      colourWithTrim: 'Kaikoura Stone, Ebony',
      powerOutput: '150PS',
      secondaryInterior: null,
      secondaryExterior: null,
      veneer: null,
      modelCollectionName: null,
      modelVariant: 'pure',
      bespokeFeatures: null,
      approved: true,
      ageMonths: null,
      engineDetails: {
        co2EmissionsCombined: 0,
        co2EmissionsMax: null,
        co2EmissionsMin: null,
        combinedConsumption: null,
        powerKw: null,
        consumptionUrban: null,
        consumptionExtraurban: null,
        emissionsClass: null,
        starRating: null,
        fuelEconomy: null,
        maxRange: null,
        minPowerKw: null,
        maxPowerKw: null,
      },
      warranty: {
        remainingWarrantyWholeYears: null,
        expiryDate: null,
      },
      otherEquipment: [
        {
          category: 'Cerchi in lega e opzioni ruote',
          equipment: [
            'Dadi antifurto',
            'Sistema di monitoraggio pressione pneumatici',
            'Sistema di riparazione ruote',
          ],
        },
        {
          category: 'Informazione, comunicazione e intrattenimento',
          equipment: [
            'Display TFT',
            'SOS/Chiamata di soccorso',
            'Bluetooth Audio Stream',
            'Sistema Audio Base',
            'InControl™ Protect',
          ],
        },
        {
          category: 'Esterni',
          equipment: [
            'Specchietti laterali con regolazione elettrica e finitura Black',
            'Luci di posizione',
            'Vetri oscurati',
            'Fendinebbia anteriori',
            'Tetto in tinta con la carrozzeria',
            'Parabrezza posteriore riscaldabile',
            'Regolazione automatica dei fari in base alla luce',
            'Maniglie delle portiere in tinta con la carrozzeria',
            'Specchietti laterali regolabili elettricamente, ripiegabili e riscaldabili',
            'Fari alogeni',
            'Bocchette di aerazione anteriori Brunel',
            'Doppio Downpipe - tubi piani',
            'Paraurti posteriore in tinta con la carrozzeria bordato',
            'Soglie di ingresso standard',
            'Paraurti anteriore in tinta con la carrozzeria',
          ],
        },
        {
          category: 'Varie',
          equipment: ['LHD'],
        },
        {
          category: 'Sicurezza e sistemi avanzati di assistenza alla guida',
          equipment: [
            'Livellamento fari',
            'Chiusura a doppia mandata',
            'Sistema Stop/Start',
            'Sensori di parcheggio posteriori',
            'Fari di avvicinamento integrati',
            'Sensori di parcheggio posteriori',
            'Accensione con pulsante Start',
            'Protezione perimetrica',
            'Lane Departure Warning - Avviso di Cambio Corsia',
            'Tracciabilità veicolo rubato',
            'Airbag removibile',
            'Intelligent Emergency Braking',
            'Forward Facing Camera',
          ],
        },
        {
          category: 'Motorizzazioni e dinamica di guida',
          equipment: [
            'Cruise Control - Controllo della velocità di crociera',
            'All Wheel Drive',
            'Terrain Optimisation',
            'Efficient Drive Line',
          ],
        },
        {
          category: 'Sedili e interni',
          equipment: [
            'Pomello del cambio Rotary',
            'Volante in pelle',
            'Rivestimento del cielo Cirrus',
            'Cinque sedili',
            'Riscaldatore elettrico (PTC)',
            "Luce d'ambiente",
            'Pacchetto non fumatori',
            'Poggia testa posteriore centrale',
            'Sedili pieghevoli e frazionabili 60/40',
            'Aria Condizionata',
            'Griglia Brunel',
            'Sedili regolabili manualmente',
            'Cubby Box Low Line',
            'Specchietti della visiera',
            'KPH only Instrument Pack',
            'Tessuto pieno fiore',
            'Colonna del volante regolabile',
            'Protezione di carico',
            'Plancia Ebony',
            'Finiture interne Ebony / Ebony',
            'Tappetini anteriori',
            'Luci da lettura',
            'Sedile guidatore regolabile manualmente – 6 regolazioni',
            'Sedile passeggero a 6 regolazioni manuali',
            'Sedili regolabili manualmente – 6X6 regolazioni',
          ],
        },
      ],
      panoramicViews: {
        interior: false,
        exterior: false,
      },
    },
    {
      id: '117165',
      name: '2016 XE',
      images: [
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_D6027C7C958F8348.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_8D73D0CE0D4D3EA5.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_A5A86BC0A79FD960.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_21B93B5EE8F126DC.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_0AF9C03ACF02B755.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_FF21B2433C16F537.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_74C1A8154291453B.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_8F864029CC0DC6D9.jpg',
        'http://images-ims.motortrak.com/S/SAJ/AB4/DN3/GA9/425/00/1024/SAJAB4DN3GA942500_0_DFCE164089CDBC0A.jpg',
      ],
      odometer: { units: 'Km', reading: 66497 },
      engine: '2.0 i4 Diésel',
      engineCapacity: 2000,
      handDrive: 'L',
      transmission: 'Manual',
      fuel: 'Diésel',
      views: 75,
      price: {
        currency: 'EUR',
        value: 19900,
        monthly: null,
        apr: null,
        interestRate: null,
        costToRun: null,
      },
      co2EmissionsCombined: '0',
      distance: null,
      retailerName: 'Premiercar',
      retailerId: '15588',
      retailerPhone: '968 858315/39',
      description: 'XE 2.0 I4 DIÉSEL PURE',
      interior: 'Jet',
      exterior: 'Polaris white',
      bodystyle: 'Saloon',
      registration: '2016',
      location: 'Murcia',
      equipmentMetaData: [
        'Cámara parking trasero',
        'Cámara parking trasero',
        'Cámara parking trasero',
        'Sensor de lluvia automático',
        'Control de crucero',
        'Sistema inteligente stop/start',
        'Bluetooth',
        'Faros xenon',
        'Sistema control por voz',
        'Monitor de presión de neumáticos',
        'Pespunte en contraste',
        'Pantalla táctil',
        'Navegador',
        'Asientos traseros abatibles',
      ],
      modelGroupName: 'XE',
      registrationDate: '2016-07-29T00:00:00',
      colourWithTrim: 'Polaris white, Jet',
      powerOutput: '180PS',
      secondaryInterior: null,
      secondaryExterior: null,
      veneer: null,
      modelCollectionName: null,
      modelVariant: 'pure',
      bespokeFeatures: null,
      approved: true,
      ageMonths: null,
      engineDetails: {
        co2EmissionsCombined: 0,
        co2EmissionsMax: null,
        co2EmissionsMin: null,
        combinedConsumption: null,
        powerKw: null,
        consumptionUrban: null,
        consumptionExtraurban: null,
        emissionsClass: null,
        starRating: null,
        fuelEconomy: null,
        maxRange: null,
        minPowerKw: null,
        maxPowerKw: null,
      },
      warranty: { remainingWarrantyWholeYears: null, expiryDate: null },
      otherEquipment: [
        {
          category: 'Asientos y acabados interiores',
          equipment: [
            'Revestimiento de techo morzine',
            'Retrovisor interior electrocrómico',
            'Compartimento delantero con toma de correiente',
            'Iluminación interior - phosphor blue',
            'Pespunte en puerta - sin contraste',
            'Pespunte en salpicadero - sin contraste',
            'Pespunte en consola - jet',
            'Suedecloth',
            'Acabado pintado',
            'Toma adicional 12v',
            'Volante en piel sofgrain',
            'Precalefactor eléctrico (ptc)',
            'Asientos delanteros con ajuste eléctrico',
            'Reposabrazos trasero con posavasos',
            'Revestimiento del techo en light oyster',
            'Embellecedor gloss black',
            'Pack de invierno',
          ],
        },
        {
          category: 'Acabado exterior',
          equipment: [
            'Lavafaros',
            'Salidas de aire laterales en chrome',
            'Rejilla frontal en gloss black con contorno chrome',
            'Espejo convexo',
            'Capó desplegable',
            'Cristales transparentes',
            'Depósito de combustible con cierre centralizado',
            'Contorno de ventanas en chrome black',
          ],
        },
        {
          category: 'Llantas y opciones de llantas',
          equipment: ['Land rover tyre repair system'],
        },
        {
          category: 'Información, comunicación y entretenimiento',
          equipment: [
            'Sistema de sonido analógico',
            'Incontrol protect',
            'Mapeo navegador - región 1 (europa)',
            'Sos/llamada de asistenccia',
          ],
        },
        {
          category: 'Trasmisión y dinámica de conducción',
          equipment: [
            'Suspensión "touring"',
            'Suspensión pasiva',
            'Sensor de ocupancia',
          ],
        },
        {
          category: 'Seguridad & sistemas de asistencia a la conducción',
          equipment: [
            'Seguimiento de vehículo robado',
            'Camera de seguimiento delantera',
            'Cierre doble',
            'Freno de emergencia autónomo con cámara frontal',
          ],
        },
      ],
      panoramicViews: { interior: false, exterior: false },
    },
    {
      id: '24851978',
      name: '2014 Lamborghini Aventador LP 700-4',
      images: [
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-0.jpg',
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-2.jpg',
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-3.jpg',
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-4.jpg',
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-5.jpg',
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-6.jpg',
        '//iproxy2.motortrak.com/2014/lamborghini/nero_aldebaran_(black)-Lamborghini_Singapore-ZHWEC1ZDXELA02430-1024-7.jpg',
      ],
      odometer: { units: 'km', reading: 3000 },
      engine: null,
      engineCapacity: 6500,
      handDrive: 'R',
      transmission: 'Automatic',
      fuel: 'Petrol',
      views: 0,
      price: {
        currency: 'SGD',
        value: 898000,
        monthly: null,
        apr: null,
        interestRate: null,
        costToRun: null,
      },
      co2EmissionsCombined: '0',
      distance: null,
      retailerName: 'Lamborghini Singapore',
      retailerId: '127836',
      retailerPhone: '(+65) 6565 5995',
      description: 'Aventador LP 700-4',
      interior: 'Nero Ade (Black)',
      exterior: 'Nero Aldebaran (Black)',
      bodystyle: '2-Door Coupe',
      registration: '2014',
      location: 'Singapore',
      equipmentMetaData: null,
      modelGroupName: 'Aventador',
      registrationDate: null,
      colourWithTrim: 'Nero Aldebaran (Black), Nero Ade (Black)',
      powerOutput: null,
      secondaryInterior: null,
      secondaryExterior: null,
      veneer: null,
      modelCollectionName: null,
      modelVariant: null,
      bespokeFeatures: null,
      approved: false,
      ageMonths: null,
      engineDetails: {
        co2EmissionsCombined: 0,
        co2EmissionsMax: null,
        co2EmissionsMin: null,
        combinedConsumption: null,
        powerKw: null,
        consumptionUrban: null,
        consumptionExtraurban: null,
        emissionsClass: null,
        starRating: null,
        fuelEconomy: null,
        maxRange: null,
        minPowerKw: null,
        maxPowerKw: null,
      },
      warranty: { remainingWarrantyWholeYears: null, expiryDate: null },
      otherEquipment: [
        {
          category: 'default',
          equipment: [
            'Bluetooth',
            'Active Speakers (4)',
            'Board Computer',
            '7 Speed Isr Gearbox',
            'Rear Carbon Ceramic Brake With Black Caliper',
            'Electrical Seats Adjustment',
            'Heated Seats',
            'Park Assist (Sensors And Rear View Camera)',
            'Transparent Engine Bonnet',
            'Multifunction Steering Wheel In Smooth Leather',
            'Anti-Theft System',
            'Front Carbon Ceramic Brake With Black Caliper',
            'Branding Package',
            'Roof Lining And Pillars In Leather',
            'Elegante Interior',
            'T-Shaped Engine Cover In Carbon Fiber',
            'Navigation System',
            'Start/Stop System With Cylinder Deactivation',
            'Iperione Rims 19/20 Silver',
            'ABS',
            'Adjustable Steering Column',
            'Air Conditioning',
            'Alloy Wheels',
            'Automatic Headlights',
            'Central Door Locking',
            'Daytime Running Lamps',
            'ESP',
            'Electric Mirrors',
            'Electric Parking Brake',
            'Electric Windows',
            'Electrically Adjustable Seats',
            'Navigation System',
            'Parking Assistance',
            'Permanent four-wheel drive',
            'Power Steering',
            'Reversing Camera',
            'Transparent Engine Cover',
          ],
        },
      ],
      panoramicViews: { interior: false, exterior: false },
    },
  ],
};

const SearchResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
  * {
    box-sizing: inherit;
  }
`;

const Filters = styled.div`
  width: 250px;
  border: 1px solid #ccc;
  height: 600px;
  margin: 5px;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #ccc;
`;

export default props => {
  const results = searchResults.contents;
  return (
    <WindowDimensionsProvider>
      <SearchWrapper>
        <Filters>filters</Filters>
        <SearchResultsWrapper>
          {results.map(result => (
            <SearchResultTile key={result.id} {...props} vehicle={result} />
          ))}
        </SearchResultsWrapper>
      </SearchWrapper>
    </WindowDimensionsProvider>
  );
};

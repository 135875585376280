// @flow
/* eslint-disable import/prefer-default-export */
import without from 'ramda/src/without';

export const newArray = (currentArr: any[] = [], value: any) => {
  if (currentArr.some(i => i === value)) {
    return without([value], currentArr);
  }
  return [...currentArr, value];
};

// @flow
import React from 'react';
import styled from 'styled-components';
import Select, { type HandlerRendererResult, Creatable } from 'react-select';
import 'react-select/dist/react-select.css';

import IoChevronDown from 'react-icons/lib/io/chevron-down';
import IoChevronUp from 'react-icons/lib/io/chevron-up';

import type { Theme } from '../theme';

type ArrowRendererProps = {
  isOpen: Boolean,
  onMouseDown: Function,
};

const ArrowButton = styled.button`
  background: transparent;
  border: none;
  padding-top: 0;
`;

const IsOpenIcon = styled(IoChevronUp)`
  displayname: 'IsOpenIcon';
`;
const IsClosedIcon = styled(IoChevronDown)`
  displayname: 'IsClosedIcon';
`;

const defaultArrows = ({ isOpen, onMouseDown }: ArrowRendererProps) => (
  <ArrowButton onMouseDown={onMouseDown}>
    {isOpen ? <IsOpenIcon /> : <IsClosedIcon />}
  </ArrowButton>
);

const isDropdownCreatable = creatable => styled(creatable ? Creatable : Select)`
  displayName: 'StyledSelect',
  border-radius: 0;
  border: 1px solid
    ${(props: { theme: Theme }) =>
      props.themeStyle === 'dark'
        ? props.theme.colours.secondary03
        : props.theme.colours.secondary02};
  font-family: ${(props: { theme: Theme }) => props.theme.fontFamily};
  font-size: ${(props: { theme: Theme }) => props.theme.fontSizeContent};

  & .Select-control {
    height: ${props => props.size}px;
    border-radius: 0;
    border: none;
    &:hover {
      box-shadow: none;
      cursor: pointer;
    }

    & .Select-value {
      ${prop =>
        prop.multi
          ? `
          border: none;
          color: #757575;
          margin: 3px;
          &  .Select-value-icon, .Select-value-label {
            border: none;
            background-color: #F3F3F3;
          }`
          : `line-height: ${prop.size}px !important`}
  }

  & .Select-input {
    height: ${props => props.size}px;
  }

  & .Select-placeholder {
    display: flex;
    align-items: center;
  }


  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }
`;

type Props<Option> = {
  arrowRenderer?: ArrowRendererProps => Node,
  disabled?: boolean,
  input: {
    value?: Option,
    onChange: Option => void,
  },
  name: string,
  options: Option[],
  valueKey: string,
  valueRenderer: Option => HandlerRendererResult,
  optionRenderer: Option => HandlerRendererResult,
  clearable?: boolean,
  size: Number,
  themeStyle: 'light' | 'dark',
  creatable: boolean,
  onNewOption?: Function,
  promptText?: string,
  showNewOptionAtTop?: boolean,
  isValidNewOption?: Function,
  multi: boolean,
};

export default function Dropdown<Option>({
  arrowRenderer,
  disabled,
  input,
  options,
  valueKey,
  valueRenderer,
  optionRenderer,
  clearable,
  size,
  themeStyle,
  creatable,
  onNewOption,
  promptText,
  isValidNewOption,
  showNewOptionAtTop,
  multi,
}: Props<Option>) {
  const StyledSelect = isDropdownCreatable(creatable);

  return (
    <StyledSelect
      value={input.value}
      options={options}
      onChange={input.onChange}
      disabled={disabled}
      arrowRenderer={arrowRenderer}
      valueKey={valueKey}
      valueRenderer={valueRenderer}
      optionRenderer={optionRenderer}
      clearable={clearable}
      size={size}
      themeStyle={themeStyle}
      placeholder={input.placeholder}
      newOptionCreator={onNewOption}
      promptTextCreator={promptText}
      showNewOptionAtTop={showNewOptionAtTop}
      isValidNewOption={isValidNewOption}
      autosize={false}
      multi={multi}
    />
  );
}

Dropdown.defaultProps = {
  arrowRenderer: defaultArrows,
  disabled: false,
  valueKey: 'value',
  valueRenderer: (i: { label: any }) => i.label,
  optionRenderer: (i: { label: any }) => i.label,
  clearable: false,
  size: 24,
  themeStyle: 'dark',
  creatable: false,
  onNewOption: null,
  promptText: label => `Add "${label}"`,
  showNewOptionAtTop: true,
  multi: false,
};

import { useState, useEffect } from 'react';

export function useCarousel(scrollerRef, slidesCount) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null);

  const onThumbnailClick = index => {
    setPrevIndex(currentIndex);
    setCurrentIndex(index);
  };

  const onButtonClicked = direction => {
    const index = currentIndex + direction;
    let nextIndex = index;

    if (index < 0) {
      nextIndex = slidesCount - 1;
    }

    if (index >= slidesCount) {
      nextIndex = 0;
    }

    setPrevIndex(currentIndex);
    setCurrentIndex(nextIndex);
  };

  const clickLeft = () => onButtonClicked(-1);
  const clickRight = () => onButtonClicked(1);

  useEffect(() => {
    if (!scrollerRef || !scrollerRef.current) {
      return;
    }
    const { clientWidth, scrollWidth } = scrollerRef.current;
    const singleScrollDist = (scrollWidth - clientWidth) / (slidesCount - 1);
    const scrollDist = singleScrollDist * currentIndex;

    // eslint-disable-next-line no-param-reassign
    scrollerRef.current.style.left = `${scrollDist * -1}px`;
  }, [currentIndex]);

  return {
    currentIndex,
    prevIndex,
    clickLeft,
    clickRight,
    onThumbnailClick,
  };
}

// @flow
import React, { Fragment, useRef } from 'react';
import styled from 'styled-components';

import Spinner from '../Spinner';
import useInterior from './useInterior';

const Player = styled.div`
  width: 100%;
  visibility: hidden;

  &.visible {
    visibility: visible;
    cursor: grab;
  }
  &.visible:active {
    cursor: grabbing;
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type Props = {
  loader: Object,
  interior: Object,
  spinnerColour: Object,
};

export default ({ loader, interior, spinnerColour }: Props) => {
  const ref = useRef();
  useInterior(ref, loader, interior);
  return (
    <Fragment>
      <Player ref={ref} />
      <Loader id="360-player-spinner">
        <Spinner colour={spinnerColour} />
      </Loader>
    </Fragment>
  );
};

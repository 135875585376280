// @flow
import transparentize from 'polished/lib/color/transparentize';

export type Theme = {
  bulletPointSize: string,
  fontFamily: string,
  fontSizeHeader: string,
  fontSizeSubHeader: string,
  fontSizeContent: string,
  fontSizeFormLabel: string,
  fontSizeFormControls: string,
  fontSizeSmall: string,
  colours: {
    primary01: string,
    primary02: string,
    primary03: string,
    secondary01: string,
    secondary02: string,
    secondary03: string,
    secondary04: string,
    secondary05: string,
    secondary06: string,
    danger01: string,
    danger02: string,
    danger03: string,
    disabled01: string,
    disabled02: string,
    highlight01: string,
    highlight02: string,
    lowlight: string,
    action: string,
    connectAutoBlue: string,
    linkAction: string,
    placeholder: string,
    help: string,
    success: string,
  },
  layout: {
    halfPadding: string,
    standardPadding: string,
    doublePadding: string,
  },
  mixins: {
    boxShadow: Function,
  },
};

const boxShadow = (
  colourHex,
  opacity,
  xOffset,
  yOffset,
  blur,
  spread,
  inset,
) => `box-shadow:
  ${xOffset ? `${xOffset}px` : 0}
  ${yOffset ? `${yOffset}px` : 0}
  ${blur ? `${blur}px` : 0}
  ${spread ? `${spread}px` : 0}
  ${transparentize(opacity, colourHex)};
  ${inset ? 'inset' : ''}
`;

export const mixins = {
  boxShadow,
};

const theme: Theme = {
  bulletPointSize: '8px',
  fontFamily: 'Lato, Helvetica, open sans',
  fontSizeHeader: '20px',
  fontSizeSubHeader: '16px',
  fontSizeContent: '14px',
  fontSizeFormLabel: '13px',
  fontSizeFormControls: '12px',
  fontSizeSmall: '11px',
  colours: {
    primary01: '#0065DB',
    primary02: '#00418E',
    primary03: '#FFFFFF',
    secondary01: '#FFFFFF',
    secondary02: '#D6D6D6',
    secondary03: '#474747',
    secondary04: '#F3F3F3',
    secondary05: '#444444',
    secondary06: '#373737',
    danger01: '#FF4949',
    danger02: '#AC2D2D',
    danger03: '#FFFFFF',
    disabled01: '#757575',
    disabled02: '#D6D6D6',
    highlight01: '#757575',
    highlight02: '#FFFFFF',
    lowlight: '#D6D6D6',
    action: '#9E1B32',
    connectAutoBlue: '#2078DC',
    linkAction: '#9E1B32',
    placeholder: '#A0A0A0',
    help: '#FFF3BC',
    success: '#74BC25',
  },
  layout: {
    halfPadding: '9px',
    standardPadding: '18px',
    doublePadding: '36px',
  },
  mixins,
};

export default theme;

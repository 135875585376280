// @flow
import React from 'react';
import styled from 'styled-components';
import { bands } from 'cms-modules';
import useActions from './useActions';

const Band = bands.Band.component;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
`;

const Button = styled.div`
  height: 20px;
  padding: 2px;
  border-style: box-border;
  cursor: pointer;
  background: ${({ disabled }) => (disabled ? '#D6D6D6' : '#0065db')};
  color: #ffffff;
  padding: 2px;
  border: 1px solid #ffffff;
`;

export default function WrappedBand(props: any) {
  const { band } = props;

  const { isFirst, isLast, actions } = useActions(band);
  return (
    <Wrapper>
      <ButtonContainer>
        {actions.moveUp && (
          <Button onClick={isFirst ? null : actions.moveUp} disabled={isFirst}>
            <img
              src="https://res.cloudinary.com/motortrak/image/upload/v1573740612/editor/band-arrow-up.svg"
              alt="Down Button"
            />
          </Button>
        )}
        {actions.moveDown && (
          <Button onClick={isLast ? null : actions.moveDown} disabled={isLast}>
            <img
              src="https://res.cloudinary.com/motortrak/image/upload/v1573740612/editor/band-arrow-down.svg"
              alt="Up Button"
            />
          </Button>
        )}
        {actions.edit && <Button onClick={actions.edit}>edit band</Button>}
        {actions.remove && (
          <Button onClick={actions.remove}>delete band</Button>
        )}
      </ButtonContainer>
      <Band {...props} />
    </Wrapper>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import Chunky from '../JaguarIcons/Arrows/Chunky';
import media from '../../theme';

type Props = {
  title: string,
};

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: #444;
  line-height: 40px;
  height: 40px;
  text-align: center;
  ${media.min.medium`
    display: none`};
`;

const Chevron = styled.div`
  display: flex;
  position: absolute;
  left: 15px;
  top: 25px;
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
`;

const FilterSectionTitle = (props: Props) => (
  <TitleContainer>
    {props.title}
    <Chevron>
      <Chunky colour="#7E7E7E" />
    </Chevron>
  </TitleContainer>
);

export default FilterSectionTitle;

// @flow
import React from 'react';

import FormField from './FormField';
import FieldError from './FieldError';
import WidthWrapper from './WidthWrapper';

import { GlobalSelect, selectStyleOverride } from '../Global';

import type { Font } from '../../../types';

const overrideStyles = {
  font: {
    padding: {
      left: '10',
      right: '10',
    },
  },
};

type Props = {
  label: string,
  required: boolean,
  type: string,
  name: string,
  keyValue: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  font: Font,
  uiElements: Object,
};

export default ({
  label,
  required,
  name,
  value,
  options,
  onChange,
  onBlur,
  error,
  isDisabled,
  truncateChars = false,
  font,
  uiElements: { select },
  translations,
}: Props) => {
  return (
    <WidthWrapper>
      <FormField label={label} name={name} required={required} font={font}>
        <GlobalSelect
          name={name}
          id={name}
          value={value}
          onChange={e => {
            onChange({ name: e.target.name, value: e.target.value });
          }}
          onBlur={e => {
            onBlur({ name: e.target.name, value: e.target.value });
          }}
          error={error}
          disabled={isDisabled}
          truncateChars={truncateChars}
          ariaRequired={required}
          styleOverride={() => selectStyleOverride(overrideStyles)}
          {...select}
        >
          {options.map(translationKey => (
            <option
              key={translationKey}
              value={translations[`${translationKey}Value`]}
            >
              {translations[`${translationKey}Label`]}
            </option>
          ))}
        </GlobalSelect>
        {error && <FieldError>{error}</FieldError>}
      </FormField>
    </WidthWrapper>
  );
};

import { put, call, takeLatest, select } from 'redux-saga/effects';
import querystring from 'querystring';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import {
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getAVLConfig,
} from '../selectors/settings';
import { engineSizeValueDown } from './helper';
import { actions as errorActions } from '../errors';

function* getSearchFilters(action) {
  const { payload } = action;
  try {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);

    const location = yield select(
      state => state.shared.sessionPreferences.location || {},
    );

    let { filters } = payload;
    filters = Object.keys(location)
      ? `${filters}&${querystring.stringify(location)}`
      : filters;

    const url = `${baseUrl}inventory/make/${make}/market/${market}/filters?${filters}`;

    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });
    yield put(
      actions.getSearchFiltersSuccess({
        filters: {
          ...response.data,
          engineSizes:
            (response.data.engineSizes &&
              response.data.engineSizes
                .sort((a, b) => a - b)
                .map(e => engineSizeValueDown(e))) ||
            [],
        },
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* getAllSearchFilters(action) {
  const { payload } = action;
  try {
    const { locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const url = `${baseUrl}inventory/make/${payload.make}/market/${
      payload.market
    }/filters`;

    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });
    yield put(
      actions.getAllSearchFiltersSuccess({
        allFilters: {
          ...response.data,
          engineSizes:
            (response.data.engineSizes &&
              response.data.engineSizes
                .sort((a, b) => a - b)
                .map(e => engineSizeValueDown(e))) ||
            [],
        },
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* updateQueryString(action) {
  const { payload = {} } = action;
  const { keepUrl } = payload;

  if (!keepUrl) {
    const models = yield select(
      state => state.shared.searchFilter.selectedCheckboxFilters.models,
    );

    let queryString = querystring.parse(window.location.search.substring(1));
    if (models.length) {
      queryString = {
        ...queryString,
        models: [...models],
      };
    }
    if (action.type === constants.RESET_FILTERS) {
      queryString = '';
    }
    const query = querystring.stringify(queryString);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}${query ? `?${query}` : ''}`,
    );
  }
}

export default function* effects() {
  yield takeLatest(constants.GET_SEARCH_FILTERS, getSearchFilters);
  yield takeLatest(constants.GET_ALL_SEARCH_FILTERS, getAllSearchFilters);
  yield takeLatest(
    [
      constants.TOGGLE_CHECKBOX_FILTER,
      constants.CLEAR_CHECKBOX_SECTION,
      constants.UPDATE_QUERY,
      constants.RESET_FILTERS,
    ],
    updateQueryString,
  );
}

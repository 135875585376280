// @flow
import React from 'react';
import styled from 'styled-components';

import type { Item } from '../../types';

import { HeadingTwo, fontStyleOverride } from '../Global';

const FooterBottomLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
`;

const FooterBottomLink = styled.a`
  margin: 15px 5px 0;
  text-decoration: none;
`;

export type FooterLinkTheme = {
  colour: string,
  hoverColour: string,
  textTransform: string,
  characterSpacing: string,
  fontSize: string,
  fontFamily: string,
};

type Props = {
  theme: FooterLinkTheme,
  preview: boolean,
  items: Item[],
  navigateIfInternal: (link: string) => boolean,
};

export default function FooterBottomNavigation({
  items = [],
  preview,
  theme: { font, hoverColour },
  navigateIfInternal,
}: Props) {
  const isExternal = href => href.match(/^http/);
  return (
    <FooterBottomLinks>
      {items.map(({ label, link, itemId }) => (
        <FooterBottomLink
          key={itemId}
          borderColour={font.colour}
          href={link}
          target={isExternal(link) ? '_blank' : '_self'}
          onClick={event => {
            if (preview || navigateIfInternal(link)) {
              event.preventDefault();
            }
          }}
        >
          <HeadingTwo
            styleOverride={() => `
              ${fontStyleOverride(font)}
              padding: 8px;
                &:hover {
                ${hoverColour && `color: ${hoverColour.value}`};
                }
              `}
          >
            {label}
          </HeadingTwo>
        </FooterBottomLink>
      ))}
    </FooterBottomLinks>
  );
}

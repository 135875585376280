// @flow
import React, { Component } from 'react';
import MyAccountDetails from '../../components/MyAccount/MyAccount';
import SubNavBar from '../../components/SubNavBar';
import { actions as routerActions } from '../../actions/router';
import {
  getCustomerDetails,
  isTokenValid,
} from '../../shared/CustomerLogin/reducer';
import type { Option } from '../../types';
import { vanityUrlFormatter } from '../../helpers/vehicle';

export interface Config {
  translations: Object;
  iconColour?: Option;
  subNavBarItemSelectedBackgroundColour: Option;
  subNavBarItemSelectedFontColour: Option;
}

type Deal = {
  vehicle: Object,
  finance: Object,
  translations: Object,
};

type Props = {
  config: Config,
  actions: {
    init: () => void,
    changeAccountView: (context: string) => void,
    saveUserDetails: (userDetails: Object) => void,
    changePassword: (passwords: Object) => void,
    deleteAccount: (password: string) => void,
    loadTestDrives: () => void,
    loadMyDeals: () => void,
    reset: () => void,
    printMyDeal: (deal: Deal) => void,
  },
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  dispatch: Function,
  pathByModule: Function,
  featureFlags: Object,
  globalStyling: Object,
  finance: Object,
  marketInfo: Object,
};

export default class MyAccount extends Component<Props, *> {
  componentDidMount = () => {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      dispatch,
      shared,
      actions: { init, loadTestDrives, loadMyDeals, signOut },
    } = this.props;
    if (isTokenValid(shared)) {
      init();
      loadTestDrives();
      loadMyDeals();
    } else {
      dispatch(routerActions.navigate('/signin'));
      dispatch(signOut());
    }
  };

  componentWillUnmount = () => {
    this.props.actions.reset();
  };

  render = () => {
    const {
      state: { context, detailsFeedback, testDrives, myDeals, printing },
      config,
      dispatch,
      actions,
      history,
      preview,
      shared,
      pathByModule,
      featureFlags,
      globalStyling,
      marketInfo,
      finance: globalFinance,
    } = this.props;
    const fnIfNotPreview = fn => (preview ? () => {} : fn);
    const userDetails = preview
      ? { firstName: 'NAME' }
      : getCustomerDetails(shared) || {};
    const links = [
      {
        text: config.translations.myAccountMyAccountLabel,
        onClick: fnIfNotPreview(() => actions.changeAccountView('Account')),
        availableOnMobile: true,
        visible: true,
        path: 'Account',
      },
      {
        text: config.translations.myAccountDetailsLabel,
        onClick: fnIfNotPreview(() => actions.changeAccountView('Details')),
        availableOnMobile: true,
        visible: true,
        path: 'Details',
      },
      {
        text: config.translations.myAccountDealsLabel,
        onClick: fnIfNotPreview(() => actions.changeAccountView('Deals')),
        availableOnMobile: true,
        visible: true,
        path: 'Deals',
      },
      {
        text: config.translations.myAccountTestDrivesLabel,
        onClick: fnIfNotPreview(() => actions.changeAccountView('TestDrives')),
        availableOnMobile: true,
        visible: featureFlags.testDrive,
        path: 'TestDrives',
      },
    ];
    return context ? (
      <div>
        <SubNavBar
          onBackButtonClick={history.goBack}
          links={links.filter(l => l.visible)}
          title={config.translations.myAccount}
          subNavBGColour={config.subNavBGColour}
          subNavBorderColour={config.subNavBorderColour}
          subNavChevronColour={config.subNavChevronColour}
          subNavFontTheme={config.subNavFont}
          selected={links.find(link => link.path === context)}
          subNavBarItemSelectedBackgroundColour={
            config.subNavBarItemSelectedBackgroundColour
          }
          globalStyling={globalStyling}
          subNavBarItemSelectedFontColour={
            config.subNavBarItemSelectedFontColour
          }
        />
        <MyAccountDetails
          finance={globalFinance}
          globalStyling={globalStyling}
          featureFlags={featureFlags}
          translations={config.translations}
          iconColour={config.iconColour && config.iconColour.value}
          context={context}
          changeAccountView={view =>
            fnIfNotPreview(() => actions.changeAccountView(view))
          }
          userDetails={userDetails}
          saveUserDetails={uDetails =>
            fnIfNotPreview(() => actions.saveUserDetails(uDetails))
          }
          changePassword={passwords =>
            fnIfNotPreview(() => actions.changePassword(passwords))
          }
          testDrives={testDrives}
          myDeals={myDeals}
          deleteMyDeal={fnIfNotPreview(vehicle =>
            actions.deleteMyDeal(vehicle.id),
          )}
          printMyDeal={finance => actions.printMyDeal(finance)}
          onDeleteTestDriveClick={fnIfNotPreview(vehicle =>
            actions.deleteTestDrive(vehicle.vin),
          )}
          onContactClick={vehicle =>
            fnIfNotPreview(
              dispatch(routerActions.navigate(`/enquiry/${vehicle.id}`)),
            )
          }
          goToVdp={vehicle =>
            fnIfNotPreview(
              dispatch(
                routerActions.navigateWithPayload(
                  `vdp/${vehicle.id}-${vanityUrlFormatter(
                    config,
                    vehicle,
                    marketInfo.locale,
                  )}`,
                  vehicle,
                ),
              ),
            )
          }
          deleteAccount={password =>
            fnIfNotPreview(() => actions.deleteAccount(password))
          }
          detailsFeedback={detailsFeedback}
          signOut={fnIfNotPreview(() => actions.signOut())}
          onShortListClick={() =>
            dispatch(routerActions.navigate(pathByModule('Shortlist')))
          }
          printing={printing}
          brandLogoUrl={config.brandLogoUrl}
          config={config}
        />
      </div>
    ) : null;
  };
}

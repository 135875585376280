// @flow
import {
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'ModSiteSearch';

export const constants = moduleConstants(modulePrefix, [
  'SearchFor',
  'SearchForSuccess',
]);

const id = 'SiteSearch';

export type Item = {
  title: string,
  content: string,
  link: string,
  key: string,
};

export type State = {
  searchResults: Item[],
};

type Action = {
  type: string,
  payload: {
    error: string,
    results: Item[],
  },
};

export const actions = {
  searchFor: moduleActionWithArgs(constants.SearchFor, id, term => ({ term })),
  searchForSuccess: moduleActionWithArgs(
    constants.SearchForSuccess,
    id,
    results => ({ results }),
  ),
};

export const initialState = {
  searchResults: [],
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SearchForSuccess:
      return {
        ...state,
        searchResults: action.payload.results,
      };
    default:
      return state;
  }
}

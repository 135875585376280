/* eslint-disable no-mixed-operators */
import PageSizes from './pdfPageSizes';

const [pageWidth] = PageSizes.A4;
const defaultWidth = 500;
const defaultLineOffset = 10;

const titleize = str =>
  str && str.length > 0
    ? `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`
    : '';

const replaceAll = (str, mapObj) => {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  return str.replace(re, matched => mapObj[matched]);
};

const extractParanthesisText = str => {
  const start = str.indexOf('(');
  const end = str.indexOf(')');
  if (start > 0 && end > 0) {
    return str.substring(start + 1, end);
  }
  return '';
};

const deleteParenthesisText = str => {
  const start = str.indexOf('(');
  if (start > 0) {
    return str.split('(')[0];
  }
  return str;
};

const defaultTable = () => ({
  table: {
    headerRows: 0,
    widths: [125, 125, 125, 125],
  },
  layout: {
    vLineColor: 'white',
    hLineColor: 'white',
  },
});

const convertBlobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

const convertImgToBase64 = url =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => convertBlobToBase64(blob))
    .catch(e => {
      /* eslint-disable no-console */
      console.warn('Failed to return an image from fetch request', e);
      /* eslint-enable no-console */
    });

const setImage = (imgBase64, imgSize, style, position = null) => {
  if (position) {
    return {
      image: `${imgBase64}`,
      fit: imgSize,
      style,
      position,
    };
  }
  return {
    image: `${imgBase64}`,
    fit: imgSize,
    style,
  };
};

const getUrl = url =>
  fetch(url)
    .then(response => response.json())
    .then(json => json);

const getVehicleImgsBase64 = async url => {
  const strs = await getUrl(url);
  return strs.images.map(str => {
    if (!str.includes('data:image/jpeg;base64')) {
      return `data:image/jpeg;base64,${str}`;
    }
    return str;
  });
};

const setLine = style => {
  const line = { ...style, ...{ type: 'line' } };
  return [{ canvas: [line] }];
};

const setText = (text, style, position = {}) =>
  Object.assign({}, { text }, style, position);

const getSpacer = ({ height, width = pageWidth, x = 0, y = 0 }) => [
  {
    canvas: [
      {
        type: 'rect',
        x,
        y,
        w: width,
        h: height,
        lineColor: '#FFF',
      },
    ],
  },
];

const defaultDetailCell = text => ({
  text: `${text}`,
  bold: true,
  alignment: 'left',
  color: '#7E7E7E',
  fontSize: 10,
  margin: [5, 2],
});

const defaultDetailCellLine = text => [
  {
    text: `${text}`,
    alignment: 'left',
    color: '#7E7E7E',
    bold: true,
    fontSize: 10,
    margin: [10, 0],
  },
];

const breakPoint = ({
  x,
  w = 20,
  y = -5,
  h = 5,
  color = 'white',
  lineColor = 'white',
}) => ({
  canvas: [
    {
      type: 'rect',
      x,
      y,
      w,
      h,
      lineColor,
      color,
    },
  ],
});

const setDetailsTitle = (title, lineColor) => [
  {
    text: `${title}`,
    alignment: 'left',
    bold: true,
  },
  {
    canvas: [
      {
        type: 'line',
        lineWidth: 2,
        color: `${lineColor}`,
        x1: 0,
        y1: 5,
        x2: 500,
        y2: 5,
      },
    ],
  },
];

const lineBreaks = rowCount => {
  /* eslint-disable no-console */
  const bp = defaultWidth / rowCount - defaultLineOffset / 2;
  /* eslint-enable no-console */
  const bps = Array.from(Array(rowCount - 1)).map((o, index) => {
    const x = bp * (index + 1);
    return breakPoint({ x });
  });
  return bps;
};

const defaultDetailLineCell = (text, rowCount) => {
  const lineCell = defaultDetailCellLine(text);
  const breakpoints = lineBreaks(rowCount);
  lineCell.push(breakpoints);
  return lineCell;
};

const setDetailAndLine = (rowCount, label = '', value = '') => {
  const valCell =
    label !== ''
      ? defaultDetailCell(value)
      : defaultDetailLineCell(value, rowCount);
  if (label) {
    const labCell = defaultDetailLineCell(label, rowCount);
    return [labCell, valCell];
  }
  return [valCell];
};

const setDetail = (label = '', value = '') => {
  const valCell = defaultDetailCell(value);
  if (label) {
    const labCell = defaultDetailCell(label);
    return [labCell, valCell];
  }
  return [valCell];
};

const generateRow = rowCount => row => {
  const processed = row.map(({ label, value }, index) =>
    index === 0
      ? setDetailAndLine(rowCount, label, value)
      : setDetail(label, value),
  );
  return [].concat(...processed);
};

const generateImgTableRow = row =>
  row.map(imgBase64 => {
    const img = setImage(imgBase64, [100, 100], ['center']);
    return img;
  });

const checkTable = data => {
  const last = data.length - 1;
  const firstLen = data[0].length;
  let lastLen = data[last].length;
  if (firstLen !== lastLen) {
    while (lastLen !== firstLen) {
      data[last].push({});
      lastLen += 1;
    }
  }

  return data;
};

const setUnderlinedTable = (rows, table, rowCount) => {
  const generateTableRow = generateRow(rowCount);
  const tableBodyData = rows.map(generateTableRow);
  const checked = checkTable(tableBodyData);
  const tbl = table;
  tbl.table.body = checked;
  return tbl;
};

const setImgTable = (rows, table) => {
  const tableBodyData = rows.map(generateImgTableRow);
  const checked = checkTable(tableBodyData);
  const tbl = table;
  tbl.table.body = checked;
  return tbl;
};

const chunk = (array, size) => {
  const chunked = [];
  let index = 0;
  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }
  return chunked;
};

const setHeaderTable = (
  tableFunc,
  title,
  tableData,
  rowCount,
  table,
  lineColor,
) => {
  const tbl = table || defaultTable();
  const header = setDetailsTitle(title, lineColor);
  const rows = chunk(tableData, rowCount);
  const spacer5 = getSpacer({ height: 5, y: 10 });
  const generatedTable = tableFunc(rows, tbl, rowCount);
  return [].concat(header, spacer5, generatedTable);
};

const setDetailSection = ({
  title,
  tableData,
  rowCount = 2,
  table,
  lineColor = 'black',
}) => {
  const tbl = setHeaderTable(
    setUnderlinedTable,
    title,
    tableData,
    rowCount,
    table,
    lineColor,
  );
  return tbl;
};

const setImageTable = ({
  title,
  tableData,
  rowCount = 4,
  table,
  lineColor = 'black',
}) => {
  const tbl = setHeaderTable(
    setImgTable,
    title,
    tableData,
    rowCount,
    table,
    lineColor,
  );
  return tbl;
};

const setPageBreak = () => ({
  text: '',
  pageBreak: 'after',
});

const setColumn = data => {
  const col = { columns: [].concat(data) };
  return col;
};

const setColumns = (columnData, colNumber) => {
  const cols = chunk(columnData, colNumber);
  const c = cols.map(col => setColumn(col));
  return c;
};

export {
  titleize,
  replaceAll,
  extractParanthesisText,
  deleteParenthesisText,
  convertImgToBase64,
  getVehicleImgsBase64,
  setLine,
  setText,
  setDetailSection,
  setImageTable,
  getSpacer,
  setColumns,
  setPageBreak,
  chunk,
};

// @flow
import { useSelector } from 'react-redux';

export default () => {
  const saveStatus = useSelector(state => state.config.saveStatus);
  const blogs = useSelector(state => state.blogs);
  let blogStatus: string | null = null;

  if (blogs.saving) {
    blogStatus = 'saving';
  } else if (blogs.dirtyBlogs) {
    blogStatus = 'unsaved';
  } else {
    blogStatus = 'saved';
  }

  return { saveStatus, blogStatus };
};

// @flow
import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import Well from './Well';
import VdpPerformance from './VdpPerformance';
import VdpHighlightedFeatures from './VdpHighlightedFeatures';
import VdpOtherEquipment from './VdpOtherEquipment';
import VdpSpecification from './VdpSpecification';
import VdpFeatures from './VdpFeatures';
import ContentWrapper from './ContentWrapper';
import { HeadingTwo, fontStyleOverride } from './Global';

const WellRow = styled.div.withConfig({
  displayName: 'WellRow',
})`
  margin: 0 auto;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? '0' : '0 20px')};
`;

const WellFeatures = styled.div.withConfig({
  displayName: 'WellFeaturesRow',
})`
  background-color: #f0f0f0;
  padding: 50px 60px;
`;

const WellOtherFeatures = styled.div.withConfig({
  displayName: 'WellOtherFeaturesRow',
})`
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? `${backgroundColour.value}` : '#ffffff'};
  padding: ${({ padding }) => (padding ? `${padding}` : '50px 60px')};
`;

const WellSpecs = styled.div.withConfig({
  displayName: 'WellSpecsRow',
})`
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? `${backgroundColour}` : '#f0f0f0'};
  padding: 50px 60px;
`;

const WellPerformance = styled.div.withConfig({
  displayName: 'WellPerformanceRow',
})`
  background-color: #ffffff;
  padding: 50px 60px;
`;

const WellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const WellFeaturesHighlights = styled.div`
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? `${backgroundColour}` : '#f0f0f0'};
  padding: 50px 60px;
`;

type Props = {
  featureFlags: Object,
  configuration: any,
  translations: Object,
  showRegistration: boolean,
  showOdometerInformation: boolean,
  contentWidth: number,
  specificationList: Object,
  equipmentList: Object,
  featuresList: Object,
  headerFont: Object,
  wellWrapper: boolean,
  showEngineCapacity: boolean,
  locale: String,
  featuredSpec: Object,
};

export default function VdpWell({
  featureFlags,
  configuration,
  translations,
  showRegistration,
  showOdometerInformation,
  contentWidth,
  specificationList,
  equipmentList,
  featuresList,
  headerFont,
  wellWrapper,
  showEngineCapacity,
  locale,
  featuredSpec,
}: Props) {
  const { backgroundColour = '', orderSpecSection = '' } =
    specificationList || {};
  const { bgColour = '', orderEquipmentSection = '', rowPadding = '' } =
    equipmentList || {};
  const { orderFeaturesSection = '' } = featuresList || {};

  const displayFeatures =
    configuration.specification.warranty.remainingWarrantyWholeYears > 0 ||
    configuration.co2Emission > 0;

  return (
    <WellContainer>
      <Well>
        {configuration.highlightedFeatures && (
          <WellFeatures>
            <ContentWrapper contentWidth={!wellWrapper && contentWidth}>
              <WellRow>
                <HeadingTwo styleOverride={() => fontStyleOverride(headerFont)}>
                  {translations.vdpHighlightedFeaturesHeader}
                </HeadingTwo>
              </WellRow>
              <WellRow>
                <VdpHighlightedFeatures
                  features={configuration.highlightedFeatures}
                  columns={3}
                />
              </WellRow>
            </ContentWrapper>
          </WellFeatures>
        )}
      </Well>
      <Well order={orderEquipmentSection}>
        {configuration.otherEquipment && (
          <WellOtherFeatures backgroundColour={bgColour} padding={rowPadding}>
            <ContentWrapper contentWidth={contentWidth} colour={bgColour.value}>
              <WellRow padding={wellWrapper}>
                <HeadingTwo styleOverride={() => fontStyleOverride(headerFont)}>
                  {translations.vdpOtherEquipmentHeader}
                </HeadingTwo>
              </WellRow>
              <WellRow padding={wellWrapper}>
                <VdpOtherEquipment
                  equipment={configuration.otherEquipment}
                  columns={3}
                  styles={equipmentList}
                />
              </WellRow>
            </ContentWrapper>
          </WellOtherFeatures>
        )}
      </Well>
      <Well order={orderSpecSection}>
        {featuredSpec &&
          configuration.specification.engineDetails.powerKw &&
          configuration.specification.engineDetails.consumptionExtraurban &&
          configuration.specification.engineDetails.combinedConsumption &&
          configuration.specification.engineDetails.co2EmissionsCombined &&
          configuration.specification.engineDetails.consumptionUrban &&
          configuration.specification.engineDetails.emissionsClass && (
            <WellSpecs backgroundColour={backgroundColour}>
              <ContentWrapper contentWidth={contentWidth}>
                <WellRow padding={wellWrapper}>
                  <HeadingTwo
                    styleOverride={() => fontStyleOverride(headerFont)}
                  >
                    {translations.vdpSpecificationHeader}
                  </HeadingTwo>
                </WellRow>
                <WellRow padding={wellWrapper}>
                  <VdpSpecification
                    id="vehicle-specification"
                    specification={configuration.specification}
                    columns={3}
                    translations={translations}
                    registration={
                      (configuration.registrationDate &&
                        format(configuration.registrationDate, 'DD-MM-YYYY')) ||
                      configuration.registration
                    }
                    showRegistration={showRegistration}
                    showOdometerInformation={showOdometerInformation}
                    styles={specificationList}
                    showEngineCapacity={showEngineCapacity}
                    locale={locale}
                    featuredSpec={featuredSpec}
                    vehicleInfo={configuration}
                  />
                </WellRow>
              </ContentWrapper>
            </WellSpecs>
          )}

        {configuration.specification && !featuredSpec && (
          <WellSpecs backgroundColour={backgroundColour}>
            <ContentWrapper contentWidth={contentWidth}>
              <WellRow padding={wellWrapper}>
                <HeadingTwo styleOverride={() => fontStyleOverride(headerFont)}>
                  {translations.vdpSpecificationHeader}
                </HeadingTwo>
              </WellRow>
              <WellRow padding={wellWrapper}>
                <VdpSpecification
                  id="vehicle-specification"
                  specification={configuration.specification}
                  columns={3}
                  translations={translations}
                  registration={
                    (configuration.registrationDate &&
                      format(configuration.registrationDate, 'DD-MM-YYYY')) ||
                    configuration.registration
                  }
                  showRegistration={showRegistration}
                  showOdometerInformation={showOdometerInformation}
                  styles={specificationList}
                  showEngineCapacity={showEngineCapacity}
                  locale={locale}
                  vehicleInfo={configuration}
                />
              </WellRow>
            </ContentWrapper>
          </WellSpecs>
        )}
        {configuration.performance && (
          <WellPerformance>
            <WellRow>
              <HeadingTwo styleOverride={() => fontStyleOverride(headerFont)}>
                {translations.vdpPerformanceHeader}
              </HeadingTwo>
            </WellRow>
            <WellRow>
              <VdpPerformance performance={configuration.performance} />
            </WellRow>
          </WellPerformance>
        )}
      </Well>

      {featureFlags.vdpFeatures && displayFeatures && (
        <Well order={orderFeaturesSection}>
          <WellFeaturesHighlights
            backgroundColour={featuresList.backgroundColour}
          >
            <ContentWrapper contentWidth={contentWidth}>
              <WellRow padding={wellWrapper}>
                <HeadingTwo styleOverride={() => fontStyleOverride(headerFont)}>
                  {translations.vdpFeaturesHeader}
                </HeadingTwo>
              </WellRow>
              <WellRow padding={wellWrapper}>
                <VdpFeatures
                  combinedConsumption={
                    configuration.specification.engineDetails
                      .combinedConsumption
                  }
                  specification={configuration.specification}
                  featuresList={featuresList}
                  translations={translations}
                />
              </WellRow>
            </ContentWrapper>
          </WellFeaturesHighlights>
        </Well>
      )}
    </WellContainer>
  );
}

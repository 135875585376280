// @flow

import React from 'react';

import { Dropdown, FieldLabel } from 'cms-ui';

import { type FieldProps } from '../models/fields';

export default function Styles({
  name,
  values: { id: value },
  ids: { id },
  onChange,
  ...props
}: FieldProps) {
  const input = {
    value,
    onChange: e => onChange(id, (e || {}).value),
  };
  const options = props.options.map(option => ({
    label: option,
    value: option,
  }));
  return (
    <FieldLabel label={name}>
      <Dropdown name={name} input={input} options={options} />
    </FieldLabel>
  );
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import SearchResultTile from '../SearchResultTile';
import SearchResultGridTile from '../SearchResultGridTile';
import CompareIcon from '../JaguarIcons/Global/Compare';
import HeartIcon from '../JaguarIcons/Global/Heart';
import media from '../../theme';

const Results = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  ${({ direction }) => direction === 'rtl' && 'margin-left: 10px;'};
`;

const CompareIconButton = (
  onCompareClick,
  iconColour,
  compareShortlistIconWidth = 2,
) => (
  <IconWrapper onClick={onCompareClick}>
    <CompareIcon
      width={`${compareShortlistIconWidth}em`}
      height="1.75em"
      colour={iconColour}
    />
  </IconWrapper>
);

const HeartIconButton = (
  onHeartClick,
  iconColour,
  compareShortlistIconWidth = 2,
  direction,
) => (
  <IconWrapper onClick={onHeartClick} direction={direction}>
    <HeartIcon
      width={`${compareShortlistIconWidth}em`}
      height="1.75em"
      colour={iconColour}
    />
  </IconWrapper>
);

const DoubleEmptyTile = () => (
  <Fragment>
    <ModelGridTileWrapper numberOfColumns={4} />
    <ModelGridTileWrapper numberOfColumns={4} />
  </Fragment>
);

const ModelGridTileWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  ${media.min.large`
    width: 49%;
  `};
  ${media.min.extraLarge`
    width: ${({ numberOfColumns }) => `${100 / numberOfColumns - 1}%`};
  `};
`;

type Props = {
  config: Object,
  results: Object,
  translations: Object,
  onMoreInfoClick: Function,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  getCompareIconColour: Function,
  globalStyling: Object,
  filtersExpanded: boolean,
  visibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  showGridTiles: boolean,
  badgeStyling: Object,
  pricingSectionImage: string,
  headerItemFont: Object,
  gridViewColumns: number,
  showFinancePopup: boolean,
  handleZeroPriceAsText: boolean,
  showNumberOfImagesInsteadOfViews: boolean,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
  backgroundColour: Option,
  optionalVehicleTitle: boolean,
  vehicleTitleFont: Object,
  vehicleSubTitleFont: Object,
  vehicleSpecContainer: Object,
  actionRowStyles: Object,
  fullSubtitle: boolean,
  countDivMargin: boolean,
  compareShortlistIconWidth: boolean,
  showOnDemand?: boolean,
  icon360: string,
};
export default function GridView({
  config,
  results,
  translations,
  onMoreInfoClick,
  compareVehicle,
  shortlistVehicle,
  getShortListedIconColour,
  getCompareIconColour,
  filtersExpanded,
  globalStyling,
  visibility,
  locale,
  vehicleModelTitleFontWeight,
  showGridTiles,
  badgeStyling,
  pricingSectionImage,
  financeHeadingFont,
  financeSubHeadingFont,
  headerItemFont,
  gridViewColumns,
  showFinancePopup,
  handleZeroPriceAsText,
  showNumberOfImagesInsteadOfViews,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
  backgroundColour,
  optionalVehicleTitle,
  vehicleTitleFont,
  vehicleSubTitleFont,
  vehicleSpecContainer,
  actionRowStyles,
  fullSubtitle,
  countDivMargin,
  compareShortlistIconWidth,
  showOnDemand,
  icon360,
}: Props) {
  const usePadding = results && results.length > gridViewColumns;
  return (
    <Results length={results.length}>
      {results.map(vehicle =>
        showGridTiles ? (
          <ModelGridTileWrapper
            key={vehicle.id}
            numberOfColumns={gridViewColumns}
            paddingBottom={usePadding}
          >
            <SearchResultGridTile
              config={config}
              key={vehicle.id}
              align="left"
              onHeartClick={() => {}}
              vehicle={vehicle}
              placeholdingImage={results.placeholdingImage}
              onMoreInfoClicked={() => onMoreInfoClick('VDP', vehicle)}
              translations={translations}
              compareVehicle={compareVehicle}
              shortlistVehicle={shortlistVehicle}
              shortListedIconColour={getShortListedIconColour(vehicle.id)}
              compareIconColour={getCompareIconColour(vehicle.id)}
              renderTopRightIcon={() => {}}
              globalStyling={globalStyling}
              visibility={visibility}
              locale={locale}
              badgeStyling={badgeStyling}
              headerItemFont={headerItemFont}
              handleZeroPriceAsText={handleZeroPriceAsText}
              showNumberOfImagesInsteadOfViews={
                showNumberOfImagesInsteadOfViews
              }
            />
          </ModelGridTileWrapper>
        ) : (
          <ModelGridTileWrapper numberOfColumns={gridViewColumns}>
            <SearchResultTile
              key={vehicle.id}
              align="left"
              onHeartClick={() => {}}
              carResult={vehicle}
              placeholdingImage={results.placeholdingImage}
              checkLength={results.length < 3}
              onMoreInfoClicked={() => onMoreInfoClick('VDP', vehicle)}
              translations={translations}
              filtersExpanded={filtersExpanded}
              renderTopRightIcon={() => {}}
              renderBottomIcon1={() =>
                CompareIconButton(
                  () => compareVehicle(vehicle),
                  getCompareIconColour(vehicle.id),
                  compareShortlistIconWidth,
                )
              }
              renderBottomIcon2={() =>
                HeartIconButton(
                  () => shortlistVehicle(vehicle),
                  getShortListedIconColour(vehicle.id),
                  compareShortlistIconWidth,
                  globalStyling.direction,
                )
              }
              globalStyling={globalStyling}
              visibility={visibility}
              locale={locale}
              vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
              pricingSectionImage={pricingSectionImage}
              financeHeadingFont={financeHeadingFont}
              financeSubHeadingFont={financeSubHeadingFont}
              showFinancePopup={showFinancePopup}
              badgeWithStripUrl={badgeWithStripUrl}
              badgeWithoutStripUrl={badgeWithoutStripUrl}
              warrantyBadgeMonths={warrantyBadgeMonths}
              warrantyBadgeMaxMileage={warrantyBadgeMaxMileage}
              showFinanceCellBorder={false}
              backgroundColour={backgroundColour}
              optionalVehicleTitle={optionalVehicleTitle}
              vehicleTitleFont={vehicleTitleFont}
              vehicleSubTitleFont={vehicleSubTitleFont}
              vehicleSpecContainer={vehicleSpecContainer}
              actionRowStyles={actionRowStyles}
              fullSubtitle={fullSubtitle}
              countDivMargin={countDivMargin}
              compareShortlistIconWidth={compareShortlistIconWidth}
              showOnDemand={showOnDemand}
              onDemandFont={config.onDemandFont}
              icon360={icon360}
            />
          </ModelGridTileWrapper>
        ),
      )}
      {gridViewColumns === 3 && results.length % 3 === 2 && (
        <ModelGridTileWrapper numberOfColumns={3} />
      )}
      {gridViewColumns === 4 && results.length % 4 === 3 && (
        <ModelGridTileWrapper numberOfColumns={4} />
      )}
      {gridViewColumns === 4 && results.length % 4 === 2 && <DoubleEmptyTile />}
    </Results>
  );
}

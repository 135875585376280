// @flow
/* eslint-env browser */

import React, { Component } from 'react';
import styled from 'styled-components';

import UserLocation from './UserLocation';

const ContentWrapper = styled.div`
  color: #000000;
  width: 100%;
`;

type Location = {
  lat: number,
  long: number,
  rawLocation: string,
};

type Props = {
  handleLocationChange: Location => void,
  placeholder: string,
  locationIconColour: string,
  translations: Object,
  rawLocation: string,
  searchCountryCode: string,
  locationIconUrl: string,
};

export default class LocationSearchFilter extends Component<Props, State> {
  handleLocationChange = (latitude, longitude) => {
    this.props.handleLocationChange({
      latitude,
      longitude,
    });
  };

  render() {
    const {
      placeholder,
      searchCountryCode,
      locationIconUrl,
      location: { latitude, longitude },
    } = this.props;

    return (
      <ContentWrapper>
        <UserLocation
          placeholderText={placeholder}
          iconUrl={locationIconUrl}
          browserLocationCallback={this.handleLocationChange}
          inputLocationCallback={this.handleLocationChange}
          options={{
            latitude,
            longitude,
            countryCode: searchCountryCode,
            autocompletePosition: 'below',
          }}
        />
      </ContentWrapper>
    );
  }
}

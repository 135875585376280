export const constants = {
  SET_MARKET_SELECTION: 'SET_MARKET_SELECTION',
};

export const actions = {
  setMarketSelection: payload => ({
    type: constants.SET_MARKET_SELECTION,
    payload,
  }),
};

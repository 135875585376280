// @flow
import React from 'react';
import { Dropdown, FieldLabel } from 'cms-ui';
import { type FieldProps } from '../models/fields';

const alignments = {
  'Top Left': { horizontalAlignment: 'left', verticalAlignment: 'top' },
  'Top Right': { horizontalAlignment: 'right', verticalAlignment: 'top' },
  'Top Center': { horizontalAlignment: 'center', verticalAlignment: 'top' },
  'Middle Left': { horizontalAlignment: 'left', verticalAlignment: 'center' },
  'Middle Right': { horizontalAlignment: 'right', verticalAlignment: 'center' },
  Center: {
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
  },
  'Bottom Left': { horizontalAlignment: 'left', verticalAlignment: 'bottom' },
  'Bottom Center': {
    horizontalAlignment: 'center',
    verticalAlignment: 'bottom',
  },
  'Bottom Right': { horizontalAlignment: 'right', verticalAlignment: 'bottom' },
};

const options = Object.entries(alignments).map(([key, value]) => ({
  label: key,
  value,
}));

const HorizontalVerticalAlign = ({
  name,
  values,
  ids,
  onChange,
}: FieldProps) => {
  const { verticalAlignment, horizontalAlignment } = values;
  const {
    verticalAlignment: verticalAlignmentId,
    horizontalAlignment: horizontalAlignmentId,
  } = ids;

  const input = {
    value: options.find(
      option =>
        option.value.horizontalAlignment === horizontalAlignment &&
        option.value.verticalAlignment === verticalAlignment,
    ),
    onChange: e => {
      onChange(verticalAlignmentId, e.value.verticalAlignment);
      onChange(horizontalAlignmentId, e.value.horizontalAlignment);
    },
  };

  return (
    <FieldLabel label={name}>
      <Dropdown name={name} input={input} options={options} />
    </FieldLabel>
  );
};

export default HorizontalVerticalAlign;

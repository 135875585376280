// @flow
import { put, call, select, all } from 'redux-saga/effects';
import uuid from 'uuid/v1';

import { actions, constants } from '../actions/page';
import { actions as contextActions } from '../actions/context';
import type {
  SavePageAction,
  CreatePageAction,
  LoadPagesAction,
  AddPodToBandAction,
  AddBandToPageAction,
} from '../actions/page';

import { post, get, remove, put as update } from '../../helpers/http';
import {
  API_BASE_URL_SITE_PAGES,
  API_BASE_URL_SITE_GLOBAL,
} from '../../constants/apis';
import watch from '../../helpers/watch';
import httpError from '../../helpers/error';

export type PagePayload = {
  pageName: string,
  relativePath: string,
  pageTemplateId: string,
  siteId: string,
  dirty?: boolean,
  seo: {
    title: string,
    description: string,
  },
  modules: Module[],
};

const seoToPayload = ({ title, description }) => ({
  title,
  description,
});

const withoutDescription = (xs = []) =>
  xs.map(x => ({ ...x, description: undefined }));

export const up = ({
  pageName,
  seo,
  modules,
  relativePath,
  pageType,
  ...rest
}: PagePayload) => ({
  name: pageName,
  configuration: {
    seo: seoToPayload(seo),
    modules: withoutDescription(modules),
    pageType,
  },
  relativePath: relativePath || '/',
  ...rest,
  dirty: undefined,
});

const configurationToSeoOrDefault = conf =>
  !conf.seo
    ? {}
    : {
        title: conf.seo.title,
        description: conf.seo.description || '',
      };

const configurationToModulesOrDefault = conf =>
  !conf.modules ? [] : conf.modules.map(m => ({ ...m }: Object));

const configurationToPageTypeOrDefault = conf => conf.pageType || conf.pageType;

export const payloadToPage = ({
  id,
  name,
  relativePath,
  configuration,
  pageTemplateId,
  required,
}) => ({
  id,
  pageName: name,
  relativePath: relativePath || '',
  pageTemplateId,
  pageType: configurationToPageTypeOrDefault(configuration),
  seo: configurationToSeoOrDefault(configuration),
  modules: configurationToModulesOrDefault(configuration),
  required,
});

export const down = ({ data: { contents } }: Array) =>
  contents.map(payloadToPage);

export function* createPage(
  token: string,
  action: CreatePageAction,
): Generator<*, *, *> {
  const { payload, breakout } = action;
  const url = API_BASE_URL_SITE_PAGES(payload.siteId);
  try {
    const data = yield up(payload);
    const response = yield call(post, {
      url,
      token,
      data,
    });

    yield put(actions.createPageSuccess(payloadToPage(response.data)));
    yield put(
      contextActions.setBreakoutContext(breakout ? { name: breakout } : ''),
    );

    yield put(
      contextActions.setPageContext(response.data.id, response.data.name),
    );
  } catch (error) {
    yield put(
      actions.createPageFailure(httpError(error, 'Failed to create page')),
    );
  }
}

export function* createForm(
  token: string,
  action: SavePageAction,
): Generator<*, *, *> {
  const { payload } = action;
  const url = API_BASE_URL_SITE_PAGES(payload.siteId);
  const podInstanceId = uuid();
  const bandInstanceId = uuid();
  const podId = 'Form';
  const createBandConfig = () => {
    return {
      config: {
        columns: 1,
        pods: Array.from({ length: 1 }, () => ({
          id: podId,
          instanceId: podInstanceId,
          config: { name: 'New Form', fields: [], translations: {} },
        })),
      },
      name: 'Band',
      id: 'Band',
      instanceId: bandInstanceId,
    };
  };
  try {
    const data = yield up(payload);
    const response = yield call(post, {
      url,
      token,
      data,
    });
    yield put(actions.createPageSuccess(payloadToPage(response.data)));
    yield put(
      actions.addBandToPage(() => {}, response.data.id, createBandConfig()),
    );
    yield put(
      contextActions.setPodContext({ id: podId, instanceId: podInstanceId }),
    );
    yield put(
      contextActions.setBreakoutContext({
        name: 'EditForm',
        data: { id: podInstanceId, pageId: payload.id },
      }),
    );
    yield put(contextActions.setPreviousBreakoutContext({ name: 'listForms' }));
    yield put(contextActions.setSliceContext('Form'));
    yield put(contextActions.setGlobalContext('Edit Form Content'));
  } catch (error) {
    yield put(
      actions.createPageFailure(httpError(error, 'Failed to create form')),
    );
  }
}

export function* loadPages(
  token: string,
  action: LoadPagesAction,
): Generator<*, *, *> {
  const { siteId } = action.payload;
  const url = API_BASE_URL_SITE_PAGES(siteId);
  try {
    const response = yield call(get, { url, token });
    const pages = yield down(response);
    yield put(actions.loadPagesSuccess(pages));
    const contentPages = pages.filter(p => p.pageType !== 'Form');
    const { id: defaultPageId, pageName: defaultPageName } =
      contentPages.find(p => p.relativePath === '/') || contentPages[0] || {};
    yield put(contextActions.setPageContext(defaultPageId, defaultPageName));
  } catch (error) {
    yield put(
      actions.loadPagesFailure(httpError(error, 'Failed to load pages')),
    );
  }
}

export function* deletePage(
  token: string,
  siteId: string,
  pageId: string,
): Generator<*, *, *> {
  const url = `${API_BASE_URL_SITE_PAGES(siteId)}/${pageId}`;
  yield call(remove, { url, token });
  yield put(actions.deletePageSuccess(pageId));
}

export function* addAction(
  action: AddBandToPageAction | AddPodToBandAction,
): Generator<*, *, *> {
  yield put(action.payload.action());
}

export function* savePage(
  token: string,
  siteId: string,
  page: any,
): Generator<*, *, *> {
  const url = API_BASE_URL_SITE_PAGES(siteId, page.id);
  const data = yield up(page);
  yield call(update, {
    url,
    token,
    data,
  });
}
export function* saveGlobalModules(
  token: string,
  siteId,
  globalModules,
): Generator<*, *, *> {
  const url = API_BASE_URL_SITE_GLOBAL(siteId);
  yield call(update, { url, token, data: globalModules });
}

export function* saveChanges(
  token: string,
  action: SaveChangesAction,
): Generator<*, *, *> {
  const siteId = action.payload;
  const { site, pages, bands, pods, lastSaveTime } = yield select(
    state => state.config,
  );

  const updatedPodIds = Object.values(pods)
    .filter(p => p.lastUpdated > lastSaveTime)
    .map(p => p.instanceId);

  const updatedBandIds = Object.values(bands)
    .filter(
      b =>
        b.lastUpdated > lastSaveTime ||
        b.config.pods.some(p => updatedPodIds.includes(p)),
    )
    .map(b => b.instanceId);

  const updatedPages = Object.values(pages).filter(
    p =>
      p.lastUpdated > lastSaveTime ||
      p.modules.some(m => updatedBandIds.includes(m)),
  );

  const modIdToBand = modId => ({
    ...bands[modId],
    config: {
      ...bands[modId].config,
      pods: bands[modId].config.pods.map(podId => pods[podId]),
    },
  });

  const pagesToSave = updatedPages
    .filter(p => !p.removed)
    .map(page => ({
      ...page,
      modules: page.modules.map(modIdToBand),
    }));

  const globalModulesToSave = site.filter(modId =>
    updatedBandIds.includes(modId),
  );

  const pagesToDelete = Object.values(pages).filter(page => page.removed);
  try {
    yield all(pagesToSave.map(page => call(savePage, token, siteId, page)));
    yield all(
      pagesToDelete.map(page => call(deletePage, token, siteId, page.id)),
    );
    if (globalModulesToSave.length) {
      const globalModules = {
        config: {
          modules: site.map(modIdToBand),
        },
      };
      yield call(saveGlobalModules, token, siteId, globalModules);
    }
    yield put(actions.saveChangesSuccess());
  } catch (error) {
    yield put(
      actions.saveChangesFailure(httpError(error, 'Failed to save page')),
    );
  }
}

export default [
  watch(constants.CreateForm, createForm, true),
  watch(constants.CreatePage, createPage, true),
  watch(constants.LoadPages, loadPages, true),
  watch(constants.AddBandToPage, addAction),
  watch(constants.SaveChanges, saveChanges, true),
];

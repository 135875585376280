// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Expand from 'react-expand-animated';

import {
  HeadingOne,
  Paragraph,
  fontStyleOverride,
} from '../../components/Global';
import media from '../../../theme';

const TileWrapper = styled.div`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100%;
  }
  border: 1px solid #dfdfdf;
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 841px;
  margin: 0 auto;
  background-color: #ffffff;
  position: relative;
  ${({ model }) => !model && 'border: 0'};
  ${({ model }) => model && 'border-top: 1px solid #dfdfdf;'};
  &:last-child {
    ${({ model }) => model && 'border-bottom: 1px solid #dfdfdf;'};
  }
  &:first-child {
    margin-top: 0;
  }
  ${media.min.small`
    ${({ model }) => model && 'border: 1px solid #DFDFDF;'};
    ${({ model, open }) => model && open && 'border-bottom: none;'};
    margin-top: ${({ model }) => (model ? '10px' : 0)};
  `};
`;

const ImageTextWrapper = styled.div`
  width: 100%;
  display: block;
  ${media.min.small`
    display: flex;
  `};
`;

const ImageWrapper = styled.div`
  flex: 0 0 45%;
`;

const Image = styled.img`
  max-width: 100%;
  min-width: 100%;
`;

const TextWrapper = styled.div`
  flex: 1 0 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -80px;
  ${media.min.small`
    margin: 0;
  `};
`;

const TitleDescription = styled.div`
  margin: 0 auto;
  margin: 0;
  width: 100%;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10px;
  @media (max-width: 575px) {
    padding: 60px 20px 10px 20px;
    text-align: center;
  }
`;

const ShowVariantsWrapper = styled.div`
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0);
  align-self: flex-start;
  margin-bottom: -2px;
  justify-content: center;
  @media (max-width: 575px) {
    align-self: center;
  }
`;

const ShowVariants = styled.div`
  display: flex;
  margin: -15px auto -1px auto;
  border: 1px solid rgba(255, 255, 255, 0);
  padding-bottom: 10px;
  padding-right: 15px;
  padding: 10px;
  ${media.max.small`
    margin: 0 auto -1px auto;
  `};
  ${({ open }) =>
    open && 'border: 1px solid #00AAEE;border-bottom:3px solid #fff;'};
`;

const ExpandWrapper = styled.div`
  width: 100%;
  max-width: 841px;
  margin: 0 auto;
  box-sizing: border-box;
  ${({ open }) => open && 'border: 1px solid #00AAEE'};
`;

const Selection = styled.img`
  margin: 10px;
`;

const TileContainer = styled.div`
  margin: 10px 30px 0 30px;
  @media (max-width: 430px) {
    margin: 10px 5px 0 5px;
  }
`;

const SelectionWrapper = styled.div`
  @media (max-width: 575px) {
    position: absolute;
    right: 0;
  }
`;

type TileProps = {
  image: string,
  name: string,
  description: string,
  onClick: Function,
  setOpen: Function,
  config: Object,
  open: boolean,
  selectionUrl: string,
  hasVariants?: boolean,
  isModel?: boolean,
};
const Tile = ({
  image,
  name,
  description,
  onClick,
  setOpen,
  config: {
    titleFont,
    descFont,
    labelFont,
    translations,
    arrowIconClosedUrl,
    arrowIconOpenUrl,
  },
  open,
  selectionUrl,
  hasVariants,
  isModel,
}: TileProps) => (
  <TileWrapper onClick={onClick} open={open && hasVariants} model={isModel}>
    <ImageTextWrapper>
      <ImageWrapper>
        <Image src={image} alt={translations.imageLabel} />
      </ImageWrapper>
      <TextWrapper>
        <TitleDescription>
          <HeadingOne
            styleOverride={() => `
                ${fontStyleOverride(titleFont)}`}
          >
            {name}
          </HeadingOne>
          <Paragraph styleOverride={() => fontStyleOverride(descFont)}>
            {description}
          </Paragraph>
        </TitleDescription>
        {isModel && hasVariants && (
          <ShowVariantsWrapper open={open}>
            <ShowVariants
              onClick={event => {
                event.stopPropagation();
                setOpen(!open);
              }}
              open={open}
            >
              <Paragraph
                styleOverride={() => `
                  line-height: initial;
                  padding: 0px 5px 0px 0;
                  ${fontStyleOverride(labelFont)};
                `}
                mobileStyleOverride={() => [
                  {
                    queryPath: 'max.small',
                    template: 'width: 100%; text-align: center;',
                  },
                ]}
              >
                {open ? translations.hideVariants : translations.showVariants}
              </Paragraph>
              <img
                src={open ? arrowIconOpenUrl : arrowIconClosedUrl}
                alt="show variants arrow"
              />
            </ShowVariants>
          </ShowVariantsWrapper>
        )}
      </TextWrapper>
    </ImageTextWrapper>
    <SelectionWrapper>
      <Selection src={selectionUrl} />
    </SelectionWrapper>
  </TileWrapper>
);

type Props = {
  modelGroup: Object,
  config: Object,
  onModelTileClick: (model: Object) => void,
  onVariantTileClick: (model: Object, variant: string) => void,
  selectedModels: string[],
  selectedVariants: string[],
};
export default ({
  modelGroup,
  config,
  onModelTileClick,
  onVariantTileClick,
  selectedModels,
  selectedVariants,
}: Props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (modelGroup.variants) {
      modelGroup.variants.forEach(v => {
        const image = document.createElement('img');
        image.src = v.image;
        image.style.visibility = 'hidden';
        image.style.display = 'none';
        document.getElementsByTagName('body')[0].appendChild(image);
      });
    }
  }, []);
  const modelSelectionUrl = () => {
    if (
      selectedModels.includes(modelGroup.modelGroupName) &&
      (modelGroup.variants &&
        modelGroup.variants.every(v => selectedVariants.includes(v.value)))
    ) {
      return config.selectedUrl;
    }
    if (
      selectedModels.includes(modelGroup.modelGroupName) &&
      modelGroup.variants &&
      modelGroup.variants.some(v => selectedVariants.includes(v.value))
    ) {
      return config.partSelectedUrl;
    }
    if (
      selectedModels.includes(modelGroup.modelGroupName) &&
      !modelGroup.variants
    ) {
      return config.selectedUrl;
    }
    return config.unselectedUrl;
  };
  return (
    <TileContainer>
      <Tile
        open={open}
        name={modelGroup.modelGroupName}
        description={modelGroup.modelSubTitle}
        image={modelGroup.imageDomain}
        config={config}
        onClick={() => {
          onModelTileClick(modelGroup);
          if (!open && !selectedModels.includes(modelGroup.modelGroupName)) {
            setOpen(true);
          }
          if (open && selectedModels.includes(modelGroup.modelGroupName)) {
            setOpen(false);
          }
        }}
        setOpen={setOpen}
        selectionUrl={modelSelectionUrl()}
        hasVariants={modelGroup.variants && modelGroup.variants.length > 1}
        isModel
      />
      <ExpandWrapper
        open={open && modelGroup.variants && modelGroup.variants.length > 1}
      >
        <Expand open={open} duration={300}>
          {modelGroup.variants &&
            modelGroup.variants.length > 1 &&
            modelGroup.variants.map(v => (
              <Tile
                key={v.name}
                open={open}
                name={v.name}
                description={v.description}
                image={v.image}
                config={config}
                onClick={() => onVariantTileClick(modelGroup, v.value)}
                setOpen={setOpen}
                selectionUrl={
                  selectedModels.includes(modelGroup.modelGroupName) &&
                  selectedVariants.some(variant => variant === v.value)
                    ? config.selectedUrl
                    : config.unselectedUrl
                }
              />
            ))}
        </Expand>
      </ExpandWrapper>
    </TileContainer>
  );
};

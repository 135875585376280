// @flow
import React from 'react';
import styled from 'styled-components';

import FormGroup from './FormGroup';
import Input from './Input';
import TextArea from './TextArea';
import Label from './Label';
import Column from './Column';

import type { Theme } from '../theme';
import type { Analytic } from './AnalyticsEditor';

const Container = styled.div`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeContent};
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hint = styled.span`
  font-color: ${({ theme }) => theme.colours.secondary03};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormLabel};
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  display: inline-block;
  line-height: 2;
  width: '50%'};
`;

export type Seo = {
  title: string,
  description: string,
  analytics: { id: string, value: Analytic }[],
};

type Props = {
  value: Seo,
  onChange: (value: Seo) => void,
};

export default function PageSeo({ value, onChange }: Props) {
  const patch = (change): Seo => ({ ...value, ...change });

  return (
    <Container>
      <FormGroup horizontal>
        <Label width="30%">SEO title</Label>
        <Column width="70%">
          <Input
            name="seo-page-title"
            type="text"
            onChange={e => onChange(patch({ title: e.target.value }))}
            value={value.title}
            placeholder="my-page"
            prefix={null}
          />
        </Column>
      </FormGroup>
      <FormGroup>
        <Title>
          <Label width="50%">SEO description</Label>
          <Hint>Max. 200 characters</Hint>
        </Title>
        <Column>
          <TextArea
            value={value.description}
            onChange={description => onChange(patch({ description }))}
            placeholder="Type desired text..."
            rows={8}
          />
        </Column>
      </FormGroup>
    </Container>
  );
}

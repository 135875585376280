// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../../theme';
import type { Font, Option } from '../../types';
import {
  HeadingOne,
  HeadingTwo,
  fontStyleOverride,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';
import PathnameSubNav from '../../components/PathnameSubNav';

const Container = styled.div`
  width: 100%;
  text-align: ${({ align }) => `${align || 'unset'}`};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom !== undefined ? `${paddingBottom}px` : '32px'};
`;

const HeaderFontUnderline = styled.span`
  &:after {
    display: ${({ display }) => (display ? `${display}` : 'none')};
    border-bottom: ${({ borderBottomColour, borderSize }) =>
      borderBottomColour && borderSize
        ? `${borderBottomColour} ${borderSize}px solid`
        : 'none'};
    content: ' ';
    height: ${({ height }) => (height ? `${height}px` : 0)};
    width: ${({ width, fullWidth }) =>
      fullWidth ? '100%' : width ? `${width}px` : 0};
  }
`;

const MobileOnly = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

const DesktopOnly = styled.div`
  display: none;
  ${media.min.large`
    display: block;
  `};
`;

export interface Config {
  pageTitleFont: Font;
  pageSubTitleFont: Font;
  translations: {
    pageTitle: string,
    pageSubTitle: string,
  };
  backgroundColour: Option;
  pageTitleUnderlineShow: Boolean;
  pageTitleUnderline: Object;
}

type Props = {
  config: Config,
  globalStyling: Object,
};

export default function PageTitle({ config, globalStyling, history }: Props) {
  return (
    <Fragment>
      {!config.hideInMobile && (
        <MobileOnly>
          <PathnameSubNav
            globalStyling={globalStyling}
            onBackButtonClick={history.goBack}
            config={config}
          />
        </MobileOnly>
      )}
      <DesktopOnly>
        <ContentWrapper
          contentWidth={globalStyling.contentWidth}
          colour={config.backgroundColour}
        >
          <Container
            align={config.alignment}
            paddingBottom={config.paddingBottom}
          >
            {config.translations.pageTitle && (
              <HeadingOne
                styleOverride={() =>
                  `${fontStyleOverride(config.pageTitleFont)} margin-bottom: 0;`
                }
              >
                {config.pageTitleUnderlineShow && (
                  <HeaderFontUnderline
                    display={config.pageTitleUnderline.display}
                    borderBottomColour={config.pageTitleUnderline.colour.value}
                    borderSize={config.pageTitleUnderline.borderSize}
                    height={config.pageTitleUnderline.height}
                    width={config.pageTitleUnderline.width}
                    fullWidth={config.pageTitleUnderline.fullWidth}
                  >
                    {config.translations.pageTitle}
                  </HeaderFontUnderline>
                )}
                {!config.pageTitleUnderlineShow &&
                  config.translations.pageTitle}
              </HeadingOne>
            )}
            {config.translations.pageSubTitle && (
              <HeadingTwo
                styleOverride={() => fontStyleOverride(config.pageSubTitleFont)}
              >
                {config.translations.pageSubTitle}
              </HeadingTwo>
            )}
          </Container>
        </ContentWrapper>
      </DesktopOnly>
    </Fragment>
  );
}

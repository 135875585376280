export const constants = {
  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
};

export const actions = {
  setError: error => ({ type: constants.SET_ERROR, payload: error }),
  clearError: () => ({ type: constants.CLEAR_ERROR }),
};

const initialState = {
  error: null,
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.SET_ERROR:
      return {
        ...state,
        error: `${payload}`,
      };
    case constants.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

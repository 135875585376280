// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import DropDown from '../DropDown';
import type { Option } from '../../types';

type Props = {
  options: Option[],
  initialSelectedValue?: Option,
  placeholder?: ?string,
  onOptionClick: Function,
  getLabelFromOption?: Function,
  getValueFromOption?: Function,
  width?: ?number,
  layout: ?string,
  border: ?boolean,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.border === true &&
    css`
      border: 1px solid #ccc;
    `} width: 100%;
`;

const theme = {
  link: {
    font: {
      colour: {
        value: '#818181',
        label: '#818181',
      },
      kerning: 1,
      fontSize: '12',
      typeface: {
        label: 'ProximaNova-Regular',
        value: 'ProximaNova-Regular',
      },
      transform: 'none',
    },
  },
  dropdown: {
    font: {
      colour: {
        value: '#333333',
        label: '#333333',
      },
      kerning: 1,
      fontSize: '12',
      typeface: {
        label: 'ProximaNova-Regular',
        value: 'ProximaNova-Regular',
      },
      transform: 'uppercase',
    },
  },
};

export default function DropDownFilter(props: Props) {
  return (
    <Container border={props.border}>
      <DropDown position="relative" theme={theme} {...props} />
    </Container>
  );
}

DropDownFilter.defaultProps = {
  getLabelFromOption: i => i.label,
  getValueFromOption: i => i.value,
  height: 40,
  border: true,
};

export const convertVehiclePriceCurrency = (
  vehicle,
  exchangeRates,
  currency,
) => {
  if (!vehicle || (vehicle.price && vehicle.price.value === 0)) {
    return vehicle;
  }
  const originalValue = vehicle.price.originalValue || vehicle.price.value;
  const originalCurrency =
    vehicle.price.originalCurrency || vehicle.price.currency;

  let value = originalValue;
  if (currency !== originalCurrency) {
    const newBaseRate = exchangeRates[vehicle.price.currency];
    value = Math.ceil(vehicle.price.value / newBaseRate);
  }

  const price = {
    ...vehicle.price,
    value,
    currency,
    originalValue,
    originalCurrency,
  };
  return {
    ...vehicle,
    price,
  };
};

export const convertVehiclesPriceCurrency = (
  vehicles,
  exchangeRates,
  currency,
) => vehicles.map(v => convertVehiclePriceCurrency(v, exchangeRates, currency));

export const convertSecondaryPriceCurrency = (
  value,
  exchangeRates,
  exchangeCurrency,
  incomingCurrency,
) => {
  if (value === 0) {
    return value;
  }

  let exchangeValue = value;
  if (
    exchangeCurrency !== incomingCurrency &&
    exchangeRates &&
    exchangeCurrency
  ) {
    const newBaseRate = exchangeRates[incomingCurrency];

    exchangeValue = Math.round(value * newBaseRate);
  }
  return exchangeValue;
};

export const convertPriceCurrency = (
  value,
  exchangeRates,
  exchangeCurrency,
  incomingCurrency,
  step = 1,
  type = 'max',
) => {
  if (value === 0) {
    return value;
  }

  const roundFunc = val => (type === 'max' ? Math.ceil(val) : Math.floor(val));

  if (
    exchangeCurrency !== incomingCurrency &&
    exchangeRates &&
    exchangeCurrency
  ) {
    const newBaseRate = exchangeRates[incomingCurrency];
    return roundFunc(value / newBaseRate / step) * step;
  }
  return roundFunc(value / step) * step;
};

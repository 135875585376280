export const initialState = {
  dealers: [
    {
      name: 'Connect auto',
      phone: '123456789',
      country: 'uk',
      address: ['59 Belford Rd', 'Edinburgh', 'EH4 3SD'],
      location: {
        coordinates: [55.951168, -3.2226967],
      },
    },
    {
      name: 'Dean bridge',
      phone: '123456789',
      country: 'uk',
      address: ['Queensferry Rd', 'Edinburgh', 'EH4 3JE'],
      location: {
        coordinates: [55.9521696, -3.2168732],
      },
    },
    {
      name: 'Rose Fountain',
      phone: '123456789',
      country: 'uk',
      address: ['Princess Street', 'Edinburgh', 'EH4 3FE'],
      location: {
        coordinates: [55.9505735, -3.211459],
      },
    },
    {
      name: 'Castle',
      phone: '123456789',
      country: 'uk',
      address: ['Castle Rd', 'Edinburgh', 'EH4 3U2'],
      location: {
        coordinates: [55.9468914, -3.2314204],
      },
    },
  ],
  centerPoint: [55.951168, -3.2226967],
  status: 'FetchingSuccess',
  error: null,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

import React from 'react';

const RegularcaseEnabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#0065DB" d="M1.5 1.5h15v15h-15z" />
      <path
        strokeOpacity={0.296}
        stroke="#0065DB"
        strokeWidth={4}
        d="M-1-1h20v20H-1z"
      />
      <path stroke="#FFF" d="M.5.5h17v17H.5z" />
      <path
        d="M10.07 12.221h-.894a.391.391 0 0 1-.387-.262l-.465-1.27H5.747l-.465 1.27a.407.407 0 0 1-.383.262H4L6.444 6h1.183l2.444 6.221zM6.045 9.872h1.983l-.757-2.07a9.45 9.45 0 0 1-.237-.73c-.04.146-.08.282-.118.408a4.574 4.574 0 0 1-.114.331l-.757 2.061zm8.067 2.35h-.478c-.1 0-.18-.016-.237-.046-.057-.03-.1-.091-.129-.183l-.094-.314c-.112.1-.222.189-.33.265-.107.076-.218.14-.333.191a1.7 1.7 0 0 1-.366.116 2.2 2.2 0 0 1-.43.04c-.186 0-.358-.026-.516-.076a1.113 1.113 0 0 1-.407-.226 1.026 1.026 0 0 1-.264-.374 1.328 1.328 0 0 1-.095-.521c0-.166.044-.33.131-.493.088-.162.233-.308.437-.438.204-.131.475-.24.813-.325a5.747 5.747 0 0 1 1.26-.147v-.258c0-.295-.062-.514-.186-.656-.125-.142-.307-.213-.545-.213a1.317 1.317 0 0 0-.729.196l-.234.135a.476.476 0 0 1-.237.06.307.307 0 0 1-.185-.055.44.44 0 0 1-.125-.138l-.193-.34c.507-.464 1.12-.697 1.837-.697.258 0 .488.043.69.127.202.085.374.202.514.353.14.15.248.33.32.54.074.21.11.439.11.688v2.788zm-2.066-.663c.11 0 .21-.01.302-.03.091-.02.178-.05.26-.09.082-.041.16-.09.237-.15.076-.058.152-.128.23-.208v-.744c-.31.014-.569.04-.777.08a2.064 2.064 0 0 0-.501.148.644.644 0 0 0-.269.21.467.467 0 0 0-.08.263c0 .187.056.32.166.4.11.08.255.12.432.12z"
        fill="#0065DB"
      />
    </g>
  </svg>
);

export default RegularcaseEnabled;

// @flow
import React, { Component, type Node } from 'react';
import styled from 'styled-components';

import { HeadingThree, fontStyleOverride } from '../../components/Global';

const FilterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #d5d5d5;
  &:last-child {
    border-bottom: 1px solid #d5d5d5;
  }
`;

const FilterSectionTitle = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
    &:hover {
      background-color: #d5d5d5;
    }
  `};
`;

type Props = {
  children: Node,
  title: string,
  active: boolean,
  disabled: boolean,
  globalStyling: Object,
  stylingConfig: Object,
};

const IconWrapper = styled.img`
  width: 20px;
  height: 20px;
`;

export default class FilterSection extends Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.active && !this.props.disabled,
    };
  }

  componentDidUpdate() {
    if (this.props.disabled && this.state.open) {
      this.setState(() => ({ open: false }));
    }
  }

  setOpenState(open: boolean) {
    this.setState(() => ({ open }));
  }

  onClickHandler = () => {
    if (!this.props.disabled) {
      this.setState(prevState => ({ open: !prevState.open }));
    }
  };

  render = () => {
    const {
      children,
      title,
      active,
      disabled,
      globalStyling: {
        colours: { primaryBrandColour },
      },
      stylingConfig,
    } = this.props;
    const { open } = this.state;
    const displayIcon =
      (open && stylingConfig.filterSectionOpenIcon) ||
      (!open && stylingConfig.filterSectionCloseIcon);
    const disabledIcon = stylingConfig.filterSectionDisabledIcon;
    const titleColour = active
      ? primaryBrandColour
      : stylingConfig.filterHeadingFont.colour;
    const filterIcon = open
      ? stylingConfig.filterSectionOpenIcon
      : stylingConfig.filterSectionCloseIcon;
    return (
      <FilterSectionWrapper>
        <FilterSectionTitle disabled={disabled} onClick={this.onClickHandler}>
          <HeadingThree
            styleOverride={() => `
              ${fontStyleOverride({
                ...stylingConfig.filterHeadingFont,
                colour: disabled ? { value: '#d5d5d5' } : titleColour,
              })}
            `}
          >
            {title}
          </HeadingThree>
          {disabled && disabledIcon && (
            <IconWrapper src={disabledIcon} alt="" />
          )}
          {!disabled && displayIcon && <IconWrapper src={filterIcon} alt="" />}
        </FilterSectionTitle>
        {open && children}
      </FilterSectionWrapper>
    );
  };
}

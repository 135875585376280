// @flow
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg > rect:not([fill='none']) {
    fill: ${props => props.colour};
  }
  svg > path:not([fill]) {
    fill: ${props => props.colour};
  }
  svg {
    width: ${props => props.width};
    height: ${props => props.height};
  }
`;
export default function LetterB({ colour, width, height }: IconProps) {
  return (
    <IconWrapper colour={colour} width={width} height={height}>
      <svg
        viewBox="0 0 18 18"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="1em"
        height="1em"
      >
        <defs>
          <path id="a" d="M0 0h18v18H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <use fill="#FFF" xlinkHref="#a" />
          <path stroke="#0065DB" d="M.5.5h17v17H.5z" />
          <path fill="#D6D6D6" d="M2 2h14v14H2z" />
          <text
            fontFamily="ProximaNova-Bold, Proxima Nova"
            fontSize={12}
            fontWeight="bold"
            fill="#0065DB"
          >
            <tspan x={5.466} y={13}>
              B
            </tspan>
          </text>
        </g>
      </svg>
    </IconWrapper>
  );
}
LetterB.defaultProps = {
  colour: null,
  width: null,
  height: null,
};

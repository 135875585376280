// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import PageSeo from './PageSeo';
import PageDetails from './PageDetails';

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeContent};
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  ${({ topSection, theme }) =>
    topSection
      ? `padding: ${theme.layout.halfPadding} 10px`
      : 'padding: 0 10px'};
  ${({ topSection, theme }) =>
    topSection ? `margin: ${theme.layout.standardPadding} 0;` : ''}
  ${({ topSection, theme }) =>
    topSection ? `border-bottom: 1px solid ${theme.colours.secondary02};` : ''}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
`;

type pageTemplateOption = { value: string, label: string };

type Page = {
  pageName: string,
  pageTemplateId: string,
  relativePath: string,
  siteId: string,
};
type Props = {
  pageTemplates: pageTemplateOption[],
  onChange: (value: Page) => void,
  value: Page,
  showAccordion: (string, boolean) => void,
  renderButton?: Object => Node,
  canChangePageType: boolean,
};

export default function CreatePage({
  pageTemplates,
  value,
  onChange,
  renderButton,
  canChangePageType,
}: Props) {
  return (
    <Container>
      <Section topSection>
        <PageDetails
          pageTemplates={pageTemplates}
          value={value}
          onChange={onChange}
          canChangeType={canChangePageType}
        />
      </Section>
      <Section>
        <PageSeo
          value={value.seo}
          onChange={seo => onChange({ ...value, seo })}
        />
        {renderButton && (
          <ButtonContainer>{renderButton(value)}</ButtonContainer>
        )}
      </Section>
    </Container>
  );
}

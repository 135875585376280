// @flow
import React, { Fragment, type Node } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ config }) => ({
  finance: {
    ...config.config.global.finance,
    locale: config.settings.locale,
  },
});

export const translateFinanceTemplate = (translations: Object) => (
  translationsKey,
  placeholdersAndValues,
) => {
  const template = translations[translationsKey];
  const replaceValue = (t, key) =>
    t.replace(`{${key}}`, placeholdersAndValues[key]);
  return template
    ? Object.keys(placeholdersAndValues).reduce(replaceValue, template)
    : '';
};

type Props = {
  finance: Object,
  component: Node,
};
const FinanceContainer = ({ component: Component, ...props }: Props) => (
  <Fragment>
    <Component
      {...props}
      translateFinanceTemplate={translateFinanceTemplate(
        props.finance.translations,
      )}
    />
  </Fragment>
);

export default connect(mapStateToProps)(FinanceContainer);

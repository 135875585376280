// @flow
import takeWhile from 'ramda/src/takeWhile';
import merge from 'ramda/src/merge';
import takeLastWhile from 'ramda/src/takeLastWhile';
import { constants, type Action } from '../actions/users';
import type { State } from '../types/users';

const initialState: State = {
  users: [],
  roles: [],
  emailAddress: undefined,
  error: undefined,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.LoadUsersSuccess:
      return {
        ...state,
        users: action.payload,
      };
    case constants.AddUserSuccess:
      return {
        ...state,
        users: [...state.users, action.payload],
        emailAddress: undefined,
        error: undefined,
      };
    case constants.RemoveUserSuccess: {
      const user = action.payload;
      return {
        ...state,
        users: state.users.filter(u => u !== user),
      };
    }
    case constants.LoadRolesSuccess:
      return {
        ...state,
        roles: action.payload,
        selectedUserRole: action.payload[0],
      };
    case constants.SelectedRoleChanged:
      return {
        ...state,
        selectedUserRole: action.payload,
      };
    case constants.EmailAddressChanged:
      return {
        ...state,
        emailAddress: action.payload,
      };
    case constants.LoadUsersFailed:
    case constants.AddUserFailed:
    case constants.AttemptAddUserFailed:
    case constants.LoadRolesFailed:
      return {
        ...state,
        error: action.payload,
      };
    case constants.RemoveUserFailed: {
      const { payload } = action;
      const notPayloadUser = u => u !== payload.user;
      return {
        ...state,
        users: [
          ...takeWhile(notPayloadUser, state.users),
          merge(payload.user, { error: payload.error }),
          ...takeLastWhile(notPayloadUser, state.users),
        ],
      };
    }
    default:
      return state;
  }
}

// @flow
import {
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MAP_SEARCH';

export const constants = moduleConstants(modulePrefix, [
  'GET_RETAILERS',
  'GET_RETAILERS_SUCCESS',
]);

export const id = 'RetailerLocationSearch';

export const actions = {
  getRetailers: moduleActionWithArgs(
    constants.GET_RETAILERS,
    id,
    payload => payload,
  ),
  getRetailersSuccess: moduleActionWithArgs(
    constants.GET_RETAILERS_SUCCESS,
    id,
    payload => payload,
  ),
  getLocation: moduleActionWithArgs(
    constants.GET_LOCATION,
    id,
    payload => payload,
  ),
};

export const initialState = {
  retailers: [],
};

type State = {
  retailers: [],
  loadingNearest: false,
};

type Action = {
  type: string,
  payload: {
    retailers: [],
    centreLocation: Object,
  },
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.GET_RETAILERS: {
      const { lat, lng } = action.payload || {};
      return {
        ...state,
        loadingNearest: !!(lat && lng),
      };
    }
    case constants.GET_RETAILERS_SUCCESS: {
      const {
        retailers,
        centreLocation: {
          coordinates: [lat, lng],
        },
      } = action.payload;
      const formattedRetailers = retailers.map(retailer => ({
        ...retailer,
        location: {
          lat: retailer.location.coordinates[0],
          lng: retailer.location.coordinates[1],
        },
      }));
      return {
        ...state,
        retailers: formattedRetailers,
        centreLocation: { lat, lng },
        loadingNearest: false,
      };
    }
    default:
      return state;
  }
}

// @flow
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Youtube from '@u-wave/react-youtube';

const YouTubeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 100%;
  width: 100%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const PlayButton = styled.button`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  z-index: 55;
  border: 0;
  color: white;
`;

type Props = {
  src: string,
  isCurrent: boolean,
  handleComplete: () => void,
  autoplay: boolean,
  muted: boolean,
};

export default (props: Props) => {
  const { src, isCurrent, handleComplete, autoplay, muted = true } = props;
  const videoRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);
  const YT_PLAYER_STATE = {
    PLAYING: 1,
    BUFFERING: 3,
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (!isCurrent) {
      videoRef.current.pauseVideo();
    }

    if (isCurrent && autoplay) {
      videoRef.current.seekTo(0);
      videoRef.current.playVideo();
    }

    if (
      isCurrent &&
      !autoplay &&
      videoRef.current.getPlayerState() === YT_PLAYER_STATE.PLAYING
    ) {
      videoRef.current.seekTo(0);
      videoRef.current.pauseVideo();
    }
  }, [isCurrent, autoplay, isMounted]);

  const handleMounted = event => {
    videoRef.current = event.target;
    setIsMounted(true);

    if (!autoplay) {
      setIsShowButton(true);
    }
  };

  const handleStateChange = event => {
    if (autoplay) {
      return;
    }

    if (
      event.data === YT_PLAYER_STATE.PLAYING ||
      event.data === YT_PLAYER_STATE.BUFFERING
    ) {
      setIsShowButton(false);
    } else {
      setIsShowButton(true);
    }
  };

  return (
    <YouTubeWrapper>
      <Youtube
        video={src}
        onEnd={handleComplete}
        onReady={handleMounted}
        onStateChange={handleStateChange}
        width="100%"
        height="100%"
        showRelatedVideos={false}
        modestBranding
        controls={false}
        muted={muted}
        autoplay={autoplay}
      />
      {!autoplay && isShowButton && (
        <PlayButton
          onClick={() => {
            videoRef.current.seekTo(0);
            videoRef.current.playVideo();
          }}
        />
      )}
    </YouTubeWrapper>
  );
};

// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import FinanceExample from './FinanceExample';
import Plus from './JaguarIcons/Global/Plus';
import Minus from './JaguarIcons/Global/Minus';
import { HeadingTwo, fontStyleOverride } from './Global';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => (margin ? `${margin}` : '16px 10px')};
`;

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

const Body = styled.span`
  color: ${({ config }) =>
    config.fontColour ? `${config.fontColour.value}` : '#7e7e7e'};
  margin: ${({ margin }) => (margin ? `${margin}` : '27px 0')};
`;

const BodyText = styled.div`
  ${({ fontStyles }) =>
    fontStyles.typeface && `font-family: ${fontStyles.typeface.value};`};
  font-size: ${({ fontStyles }) =>
    fontStyles.fontSize ? `${fontStyles.fontSize}px;` : '16px'};
  margin-bottom: 27px;
`;

const PlusIcon = styled.span`
  ${({ iconFont }) => iconFont.fontSize && `font-size: ${iconFont.fontSize}px`};
  ${({ iconFont }) =>
    iconFont.lineHeight && `line-height: ${iconFont.lineHeight}px`};
  ${({ iconColour }) => iconColour.value && `color: ${iconColour.value}`};
`;

const MinusIcon = styled.span`
  ${({ iconFont }) => iconFont.fontSize && `font-size: ${iconFont.fontSize}px`};
  ${({ iconFont }) =>
    iconFont.lineHeight && `line-height: ${iconFont.lineHeight}px`};
  ${({ iconColour }) => iconColour.value && `color: ${iconColour.value}`};
`;

type Props = {
  representativeExample: Object,
  preview: boolean,
  finance: Object,
  config: Object,
};

export default class RepresentativeExample extends Component<Props, *> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: props.finance.repexDefaultState === 'open',
    };
  }

  toggle = () => {
    if (!this.props.preview) {
      this.setState(prevState => ({
        open: !prevState.open,
      }));
    }
  };

  render() {
    const { representativeExample, config, ...props } = this.props;
    const defaultTitleFont = { fontSize: 16 };
    const {
      optionalIcons = '',
      iconFont = '',
      iconColour = '',
      titleFont = '',
    } = config || {};
    return (
      <Container data-qa-hook="finance-example" margin={config.containerMargin}>
        <Toggle onClick={this.toggle}>
          <HeadingTwo
            styleOverride={() => `
              ${fontStyleOverride(
                (config && config.titleFont) || defaultTitleFont,
              )}
              ${titleFont && `font-family: ${titleFont.typeface}`};
              `}
          >
            {props.translateFinanceTemplate('financeMyDealTitle', {
              PRODUCT: props.finance.defaultProduct,
            })}
          </HeadingTwo>
          {this.state.open ? (
            <Fragment>
              {optionalIcons ? (
                <MinusIcon iconFont={iconFont} iconColour={iconColour}>
                  &#45;
                </MinusIcon>
              ) : (
                <Minus />
              )}
            </Fragment>
          ) : (
            <Fragment>
              {optionalIcons ? (
                <PlusIcon iconFont={iconFont} iconColour={iconColour}>
                  &#43;
                </PlusIcon>
              ) : (
                <Plus />
              )}
            </Fragment>
          )}
        </Toggle>
        {this.state.open && representativeExample && (
          <Body config={config} margin={config.bodyMargin}>
            <BodyText fontStyles={config && config.bodyFont}>
              {props.translateFinanceTemplate('financeAccordionSubtitle', {
                MODEL: representativeExample.model,
                DURATION: representativeExample.numberOfPayments,
              })}
            </BodyText>
            <FinanceExample
              {...props}
              data={representativeExample}
              labelFontOverride={config && config.infoLabelFont}
              valueFontOverride={config && config.infoValueFont}
              financePricingTable={config.financePricingTable}
            />
          </Body>
        )}
      </Container>
    );
  }
}

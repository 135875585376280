// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../../theme';

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.filtersExpanded ? '100%' : 'auto')};
  justify-content: flex-end;
  ${media.max.large`
    width: 100%;
    height: auto;
  `};
`;
const Sort = styled.div`
  display: flex;
  ${media.max.large`
    width: 100%;
  `};
`;

const Select = styled.select`
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  width: 250px;
  height: 45px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid #999999;
  padding-left: 15px;
  padding-right: 15px;
  color: #444444;
  background-color: #fff;
  ${media.max.large`
    width: 100%;
  `};
  &:focus {
    outline: none;
  }
`;

type Props = {
  sortOptions: Object,
  sortValue: string,
  filtersExpanded?: boolean,
  onSortChange: Function,
  selectedView: 'GRID' | 'LIST',
  onViewChange: Function,
  translations: Object,
  showDistance: boolean,
  showMileage: boolean,
  showAge: boolean,
  showPrice: boolean,
  fontFamily: string,
  gridSelected: string,
  gridDeselected: string,
  listSelected: string,
  listDeselected: string,
};
export default function SortMenuBar({
  sortOptions,
  sortValue,
  filtersExpanded,
  onSortChange,
  translations,
  showDistance,
  showMileage = true,
  showAge = true,
  showPrice = true,
  fontFamily,
}: Props) {
  const sortOptionsField = fieldValue =>
    (Object.values(sortOptions).find(so => so.value === fieldValue) || {})
      .field;

  const sortOptionsOrder = fieldValue =>
    (Object.values(sortOptions).find(so => so.value === fieldValue) || {})
      .order;

  const updateFilters = sortField => {
    const payload = [
      { key: 'sortField', value: [sortOptionsField(sortField)] },
      { key: 'sortDirection', value: [sortOptionsOrder(sortField)] },
    ];
    onSortChange(payload, sortField);
  };

  return (
    <SortContainer filtersExpanded={filtersExpanded}>
      <Sort>
        <Select
          data-qa-hook="sort-dropdown"
          value={sortValue}
          onChange={e => updateFilters(e.target.value)}
          fontFamily={fontFamily}
        >
          {showPrice && (
            <Fragment>
              <option
                value={sortOptions.priceAsc.value}
                field={sortOptions.priceAsc.field}
                order={sortOptions.priceAsc.order}
              >
                {translations.searchResultsSortPriceAscLabel}
              </option>
              <option
                value={sortOptions.priceDesc.value}
                field={sortOptions.priceDesc.field}
                order={sortOptions.priceDesc.order}
              >
                {translations.searchResultsSortPriceDescLabel}
              </option>
            </Fragment>
          )}
          {showMileage && (
            <Fragment>
              <option
                value={sortOptions.mileageAsc.value}
                field={sortOptions.mileageAsc.field}
                order={sortOptions.mileageAsc.order}
              >
                {translations.searchResultsSortMileageAscLabel}
              </option>
              <option
                value={sortOptions.mileageDesc.value}
                field={sortOptions.mileageDesc.field}
                order={sortOptions.mileageDesc.order}
              >
                {translations.searchResultsSortMileageDescLabel}
              </option>
            </Fragment>
          )}
          {showAge && (
            <Fragment>
              <option
                value={sortOptions.ageAsc.value}
                field={sortOptions.ageAsc.field}
                order={sortOptions.ageAsc.order}
              >
                {translations.searchResultsSortAgeAscLabel}
              </option>
              <option
                value={sortOptions.ageDesc.value}
                field={sortOptions.ageDesc.field}
                order={sortOptions.ageDesc.order}
              >
                {translations.searchResultsSortAgeDescLabel}
              </option>
            </Fragment>
          )}
          {showDistance && (
            <option
              value={sortOptions.distanceAsc.value}
              field={sortOptions.distanceAsc.field}
              order={sortOptions.distanceAsc.order}
            >
              {translations.searchResultsSortDistanceLabel}
            </option>
          )}
        </Select>
      </Sort>
    </SortContainer>
  );
}
SortMenuBar.defaultProps = {
  filtersExpanded: false,
};

// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Label,
  InputField,
  Button,
  FieldLabel,
  Accordion,
  SectionMenuTitle,
  ButtonWithConfirm,
} from 'cms-ui';

import type { Link, ExternalLinkAction } from '../state/types/siteBuilder';
import type { Theme } from '../theme';

const Container = styled.div.withConfig({
  type: 'Container',
})`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeContent};
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div.withConfig({
  displayName: 'ButtonContainer',
})`
  margin-top: 15px;
`;

const FieldLabelContainer = styled.div.withConfig({
  displayName: 'FieldLabelContainer',
})`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button).withConfig({ displayName: 'StyledButton' })`
  margin-top: 10px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const renderModalContents = onClick => closeSelfFn => (
  <Center>
    <p>Are you sure you want to delete this link?</p>
    <StyledButton
      small
      fullWidth
      danger
      textTransform="uppercase"
      onClick={onClick}
    >
      Confirm Delete
    </StyledButton>
    <StyledButton
      small
      fullWidth
      textTransform="uppercase"
      onClick={closeSelfFn}
    >
      Cancel
    </StyledButton>
  </Center>
);

type Props = {
  value: Link,
  action: ExternalLinkAction,
  ui: { externalLink: boolean },
  onFieldChange: (value: Link) => void,
  onSave: (value: Link) => void,
  onDelete: (value: Link) => void,
  showAccordion: (id: string, show: boolean) => void,
};

export default function ExternalLink({
  value,
  onFieldChange,
  onSave,
  onDelete,
  action,
  ui,
  showAccordion,
}: Props) {
  const onChangeLinkLabel = name => onFieldChange({ ...value, name });
  const onChangeLinkUrl = url => onFieldChange({ ...value, url });
  const titleText = action === 'Add' ? 'Add a new' : 'Edit';
  return (
    <div>
      <SectionMenuTitle>{`${titleText} external link`}</SectionMenuTitle>
      <Accordion
        titleContents={<div>{`${titleText} external link`}</div>}
        bodyContents={
          <Container>
            <FieldLabelContainer>
              <FieldLabel label="Link name" inputFlexMode="1">
                <InputField
                  input={{
                    onChange: e => onChangeLinkLabel(e.target.value),
                    name: 'linkName',
                    value: value.name,
                  }}
                  type="text"
                  placeholder="Type link name..."
                />
              </FieldLabel>
            </FieldLabelContainer>
            <Label>URL</Label>
            <InputField
              input={{
                onChange: e => onChangeLinkUrl(e.target.value),
                name: 'linkUrl',
                value: value.url,
              }}
              type="text"
              placeholder="http://..."
            />
            <ButtonContainer>
              {action === 'Add' ? (
                <Button
                  onClick={() => onSave(value)}
                  small
                  fullWidth
                  primary
                  textTransform="uppercase"
                >
                  Create external link
                </Button>
              ) : (
                <ButtonWithConfirm
                  buttonText="Delete external link"
                  renderModalContents={closeSelfFn =>
                    renderModalContents(() => onDelete(value))(closeSelfFn)
                  }
                />
              )}
            </ButtonContainer>
          </Container>
        }
        open={ui.externalLink}
        onTitleClick={() => showAccordion('externalLink', !ui.externalLink)}
      />
    </div>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const Icon = styled.img`
  height: 20px;
  margin-left: 2px;
`;

type Props = {
  rating: number,
  max: number,
  fill: string,
  half: string,
  empty: string,
};

const Rating = ({ rating, max, fill, half, empty }: Props) => {
  let stars = [];
  for (let i = 0; i < max; i++) {
    stars = [
      ...stars,
      <Icon
        alt={
          rating - i >= 1 ? 'star' : rating - i > 0 ? 'half star' : 'no star'
        }
        src={`${rating - i >= 1 ? fill : rating - i > 0 ? half : empty}`}
      />,
    ];
  }

  return <Row>{stars}</Row>;
};

export { Rating };

// @flow
import React from 'react';
import styled from 'styled-components';

import { HeadingOne, Paragraph } from '../Global';
import media from '../../theme';

const RadioButtonRow = styled.div`
  display: flex;
  align-items: center;

  ${media.max.medium` flex-wrap: wrap;`};
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const Label = styled.div`
  font-size: 16px;
  color: ${({ error }) => (error ? '#9e1b32' : '#444444')};
  margin-left: 4px;
`;

const RadioLabel = styled.div`
  font-size: 16px;
  color: ${({ error }) => (error ? '#9e1b32' : '#444444')};
  margin-left: 4px;
  white-space: nowrap;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 10px;
`;

const Td = styled.td`
  border: 1px solid #7e7e7e;
  padding: 10px;
  font-family: ${({ font }) => font || 'ProximaNova-Regular'};
  color: #444444;
  font-size: 13px;
  ${({ width }) => width && `width: ${width}%;`}
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 14px;
  line-height: 17px;
  ${media.max.medium` width: 100%;`};
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ddd;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  &:checked + ${RadioButtonLabel} {
    background: #fff;
    border: 1px solid #ddd;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 3px;
      background: ${({ globalStyling }) =>
        globalStyling
          ? globalStyling.colours.primaryBrandColour.value
          : '#dddddd'};
    }
  }
`;

type Props = {
  translations: Object,
  onRadioSelect: Object => void,
  onRadioSelectMultiple: Object => void,
  onColour: string,
  labelFontSize?: Number,
  checkboxDimension?: Number,
  linkColour?: Object,
  globalStyling: Object,
  checkBoxError: string,
  enquiryFormStyles: Object,

  dataCollectionConsent: Object,
  dataCollectionConsentKey: string,
  promotionConsent: Object,
  promotionConsentKey: string,
  thirdPartyConsent: Object,
  thirdPartyConsentKey: string,
  personalInfoHandling: Object,
  personalInfoHandlingKey: string,
  dataCollectionConsentError: string,
  promotionConsentError: string,
  promotionConsentError: string,
};

const JLRKoreaSpecificConsent = ({
  translations,
  onRadioSelect,
  onRadioSelectMultiple,
  dataCollectionConsent,
  dataCollectionConsentError,
  dataCollectionConsentKey,
  promotionConsent,
  promotionConsentError,
  promotionConsentKey,
  thirdPartyConsent,
  thirdPartyConsentError,
  thirdPartyConsentKey,
  personalInfoHandling,
  personalInfoHandlingError,
  personalInfoHandlingKey,
  consentToAll,
  consentToAllKey,
  globalStyling,
}: Props) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <HeadingOne
        styleOverride={() => `
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;`}
      >
        {translations.personalInfoCollection}
      </HeadingOne>
      <Paragraph
        styleOverride={() => `
        font-size: 14px;
        line-height: 17px;
        margin-top: 20px;`}
      >
        {translations.dearJlrKorea}
      </Paragraph>
      <Paragraph
        styleOverride={() => `
        font-size: 14px;
        line-height: 17px;
        margin-top: 20px;`}
      >
        {translations.privacyAct}
      </Paragraph>
      <RadioButtonRow>
        <StyledParagraph styleOverride={() => `margin-right: 40px;`}>
          {translations.allAgree}
        </StyledParagraph>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={consentToAll === '1'}
            onChange={event => onRadioSelectMultiple(event)}
            type="radio"
            id="consentToAllYes"
            name={consentToAllKey}
            value="1"
          />
          <RadioButtonLabel />
          <RadioLabel htmlFor="consentToAllYes">
            {translations.agree}
          </RadioLabel>
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            globalStyling={globalStyling}
            checked={consentToAll === '0'}
            onChange={event => onRadioSelectMultiple(event)}
            type="radio"
            id="consentToAllNo"
            name={consentToAllKey}
            value="0"
          />
          <RadioButtonLabel />
          <RadioLabel htmlFor="marketingNo">
            {translations.doNotAgree}
          </RadioLabel>
        </RadioButtonWrapper>
      </RadioButtonRow>
      <RadioButtonRow>
        <StyledParagraph>{translations.dataCollectionConsent}</StyledParagraph>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={dataCollectionConsent === '1'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="dataCollectionYes"
            name={dataCollectionConsentKey}
            value="1"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={dataCollectionConsentError}
            htmlFor="dataCollectionYes"
          >
            {translations.agree}
          </RadioLabel>
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={dataCollectionConsent === '0'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="dataCollectionNo"
            name={dataCollectionConsentKey}
            value="0"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={dataCollectionConsentError}
            htmlFor="dataCollectionNo"
          >
            {translations.doNotAgree}
          </RadioLabel>
        </RadioButtonWrapper>
      </RadioButtonRow>
      {dataCollectionConsentError && (
        <Label error={dataCollectionConsentError}>
          {translations.serviceWarning}
        </Label>
      )}
      <Table>
        <tr>
          <Td width={30}>{translations.collectionItem}</Td>
          <Td>{translations.collectionItemText}</Td>
        </tr>
        <tr>
          <Td width={30}>{translations.purposeOfCollectionAndUse}</Td>
          <Td>{translations.purposeOfCollectionText}</Td>
        </tr>
        <tr>
          <Td width={30}>{translations.retentionAndUsePeriod}</Td>
          <Td>{translations.retentionAndUsePeriodText}</Td>
        </tr>
      </Table>
      <Paragraph>{translations.refuseConsent}</Paragraph>

      <RadioButtonRow>
        <StyledParagraph>{translations.marketingConsent}</StyledParagraph>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={promotionConsent === '1'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="promotionConsentYes"
            name={promotionConsentKey}
            value="1"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={promotionConsentError}
            htmlFor="promotionConsentYes"
          >
            {translations.agree}
          </RadioLabel>
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={promotionConsent === '0'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="promotionConsentNo"
            name={promotionConsentKey}
            value="0"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={promotionConsentError}
            htmlFor="promotionConsentNo"
          >
            {translations.doNotAgree}
          </RadioLabel>
        </RadioButtonWrapper>
      </RadioButtonRow>
      {promotionConsentError && (
        <Label error={promotionConsentError}>
          {translations.serviceWarning}
        </Label>
      )}
      <Paragraph>{translations.refuseConsent}</Paragraph>
      <RadioButtonRow>
        <StyledParagraph>{translations.thirdPartyProvision}</StyledParagraph>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={thirdPartyConsent === '1'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="thirdPartyConsentYes"
            name={thirdPartyConsentKey}
            value="1"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={thirdPartyConsentError}
            htmlFor="thirdPartyConsentYes"
          >
            {translations.agree}
          </RadioLabel>
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={thirdPartyConsent === '0'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="thirdPartyConsentNo"
            name={thirdPartyConsentKey}
            value="0"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={thirdPartyConsentError}
            htmlFor="thirdPartyConsentNo"
          >
            {translations.doNotAgree}
          </RadioLabel>
        </RadioButtonWrapper>
      </RadioButtonRow>
      {thirdPartyConsentError && (
        <Label error={thirdPartyConsentError}>
          {translations.serviceWarning}
        </Label>
      )}
      <Table>
        <tr>
          <Td width={30}>{translations.recipientOfData}</Td>
          <Td>{translations.recipientOfDataText}</Td>
        </tr>
        <tr>
          <Td width={30}>{translations.purposeOfUse}</Td>
          <Td>{translations.purposeOfUseText}</Td>
        </tr>
        <tr>
          <Td width={30}>{translations.personalInfoProvided}</Td>
          <Td>{translations.personalInfoProvidedText}</Td>
        </tr>
        <tr>
          <Td width={30}>{translations.retentionAndUsePeriod}</Td>
          <Td>{translations.retentionAndUsePeriodText}</Td>
        </tr>
      </Table>
      <Paragraph>{translations.refuseConsent}</Paragraph>
      <RadioButtonRow>
        <StyledParagraph>{translations.personalInfoHandling}</StyledParagraph>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={personalInfoHandling === '1'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="personalInfoHandlingYes"
            name={personalInfoHandlingKey}
            value="1"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={personalInfoHandlingError}
            htmlFor="personalInfoHandlingYes"
          >
            {translations.agree}
          </RadioLabel>
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <RadioButton
            globalStyling={globalStyling}
            checked={personalInfoHandling === '0'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="personalInfoHandlingNo"
            name={personalInfoHandlingKey}
            value="0"
          />
          <RadioButtonLabel />
          <RadioLabel
            error={personalInfoHandlingError}
            htmlFor="personalInfoHandlingNo"
          >
            {translations.doNotAgree}
          </RadioLabel>
        </RadioButtonWrapper>
      </RadioButtonRow>
      {personalInfoHandlingError && (
        <Label error={personalInfoHandlingError}>
          {translations.serviceWarning}
        </Label>
      )}
      <Table>
        <tr>
          <Td width={30}>{translations.entrusteeOfPersonalInfo}</Td>
          <Td width={30}>{translations.contentAndScope}</Td>
          <Td width={30}>{translations.consignmentItem}</Td>
        </tr>
        <tr>
          <Td>{translations.jlrEntrustee}</Td>
          <Td>{translations.jlrScopeOfWork}</Td>
          <Td rowSpan="2">{translations.consignmentItemText}</Td>
        </tr>
        <tr>
          <Td>{translations.otherEntrustee}</Td>
          <Td>{translations.otherScopeOfWork}</Td>
        </tr>
      </Table>
      <Paragraph>{translations.privacyPolicyReferal}</Paragraph>
    </div>
  );
};
export default JLRKoreaSpecificConsent;

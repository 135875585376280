// @flow

import React from 'react';

import { Dropdown, FieldLabel } from 'cms-ui';

import { type FieldProps } from '../models/fields';

export default function Page({
  values: { id: value },
  ids: { id },
  name,
  onChange,
  data,
  ...props
}: FieldProps) {
  const input = {
    value,
    onChange: e => onChange(id, e || { value: null }),
  };
  const options = [
    ...data.pages.map(({ pageName, relativePath }) => ({
      label: pageName,
      value: relativePath,
    })),
    ...(props.withExternalLink
      ? data.externalLinks.map(({ name: n, url }) => ({ label: n, value: url }))
      : []),
  ];
  return (
    <FieldLabel label={name}>
      <Dropdown name={name} input={input} options={options} />
    </FieldLabel>
  );
}

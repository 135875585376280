// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';
import {
  required,
  validEmail,
  valueMatches,
  valueMatchesRequired,
  validPhone,
  meetsPasswordPolicy,
} from '../../helpers/validation';

export const modulePrefix = 'MOD_TEST_DRIVE';

export const constants = moduleConstants(modulePrefix, [
  'GET_VEHICLE_INFO',
  'GET_VEHICLE_INFO_SUCCESS',
  'GET_VEHICLE_INFO_FAILURE',
  'SET_FORM_VALUE_FROM_DROPDOWN',
  'SET_BOOKING_FORM_VALUE',
  'CONTINUE_AS_GUEST',
  'GUEST_DETAILS_SUBMITTED',
  'LOGIN_AND_CONFIRM',
  'LOGIN_SUCCESS',
  'LOGIN_FAILED',
  'SOMETHING_WENT_WRONG',
  'USER_LOGGED_IN',
  'SEND_TEST_DRIVE_LEAD_SUCCESS',
  'SAVE_TEST_DRIVE_SUCCESS',
  'CONFIRM_DATE_AND_TIME',
  'CONFIRM_REQUEST',
  'TOGGLE_CALENDAR',
  'INTERESTED_CLICKED',
  'RADIO_GROUP_MODE_CHANGED',
  'RESET',
  'CHANGE_CONTEXT',
]);

export type SelectDateModeValue = 'AnyDate' | 'SpecificDate';

export type SelectDateMode = { value: SelectDateModeValue };

export type SelectTimeModeValue = 'Any' | 'Morning' | 'Afternoon';

export type SelectTimeMode = { value: SelectTimeModeValue };

export const id = 'TestDrive';

const selectDateModeOptionsList = [
  { value: 'AnyDate' },
  { value: 'SpecificDate' },
];

const selectTimeModeOptionsList = [
  { label: 'Any', value: 'Any' },
  { label: 'Morning', value: 'Morning' },
  { label: 'Afternoon', value: 'Afternoon' },
];

export const actions = {
  getVehicleInfo: moduleActionWithArgs(
    constants.GET_VEHICLE_INFO,
    id,
    vehicleId => ({
      vehicleId,
    }),
  ),
  getVehicleInfoSuccess: moduleActionWithArgs(
    constants.GET_VEHICLE_INFO_SUCCESS,
    id,
    config => config,
  ),
  getVehicleInfoFailure: moduleActionWithArgs(
    constants.GET_VEHICLE_INFO_FAILURE,
    id,
    error => error,
  ),
  setFormValueFromDropdown: moduleActionWithArgs(
    constants.SET_FORM_VALUE_FROM_DROPDOWN,
    id,
    (selected, translations) => ({
      selected,
      translations,
    }),
  ),
  setBookingFormValue: moduleActionWithArgs(
    constants.SET_BOOKING_FORM_VALUE,
    id,
    (key, value, translations, validation) => ({
      key,
      value,
      translations,
      validation,
    }),
  ),
  continueAsGuest: moduleAction(constants.CONTINUE_AS_GUEST, id),
  guestDetailsSubmitted: moduleActionWithArgs(
    constants.GUEST_DETAILS_SUBMITTED,
    id,
    (guestDetails, newAccount) => ({
      guestDetails,
      newAccount,
    }),
  ),
  toggleCalendar: moduleAction(constants.TOGGLE_CALENDAR, id),
  setSelectDateMode: moduleActionWithArgs(
    constants.SET_SELECT_DATE_MODE,
    id,
    selectedValue => ({
      selectedValue,
    }),
  ),
  loginAndConfirm: moduleActionWithArgs(
    constants.LOGIN_AND_CONFIRM,
    id,
    loginDetails => ({
      ...loginDetails,
    }),
  ),
  loginSuccess: moduleAction(constants.LOGIN_SUCCESS, id),
  loginFailed: moduleActionWithArgs(constants.LOGIN_FAILED, id, payload => ({
    ...payload,
  })),
  userLoggedIn: moduleAction(constants.USER_LOGGED_IN, id),
  somethingWentWrong: moduleAction(constants.SOMETHING_WENT_WRONG, id),
  sendTestDriveLeadSuccess: moduleActionWithArgs(
    constants.SEND_TEST_DRIVE_LEAD_SUCCESS,
    id,
    (userCreated, newUserEmail) => ({
      userCreated,
      newUserEmail,
    }),
  ),
  saveTestDriveSuccess: moduleActionWithArgs(
    constants.SAVE_TEST_DRIVE_SUCCESS,
    id,
    guestDetails => ({ guestDetails }),
  ),
  confirmDateAndTime: moduleAction(constants.CONFIRM_DATE_AND_TIME, id),
  confirmRequest: moduleActionWithArgs(
    constants.CONFIRM_REQUEST,
    id,
    payload => payload,
  ),
  setSelectRadioGroupMode: moduleActionWithArgs(
    constants.RADIO_GROUP_MODE_CHANGED,
    id,
    (key, value) => ({
      key,
      value,
    }),
  ),
  interestedClicked: moduleAction(constants.INTERESTED_CLICKED, id),
  reset: moduleAction(constants.RESET, id),
  changeContext: moduleActionWithArgs(
    constants.CHANGE_CONTEXT,
    id,
    payload => payload,
  ),
};

function validate(form: Object, translations: Object) {
  return {
    title: required(form.title, translations),
    firstname: required(form.firstname, translations),
    lastname: required(form.lastname, translations),
    email: validEmail(form.email, translations),
    emailConfirm: valueMatchesRequired(
      form.email,
      form.emailConfirm,
      translations,
    ),
    phone: validPhone(form.phone, translations),
    postcode: required(form.postcode, translations),
    accountPassword: meetsPasswordPolicy(
      form.accountPassword,
      translations,
      false,
    ),
    accountPasswordConfirm: valueMatches(
      form.accountPassword,
      form.accountPasswordConfirm,
      translations.formValidationPasswordMatch,
    ),
    termsAndConditionsNotChecked: !form.termsAndConditionsAccept
      ? [translations.testDriveUserDetailsTermsAndConditionsNotChecked]
      : undefined,
  };
}

export const initialState = {
  testDriveConfig: null,
  error: null,
  context: 'DateAndTime',
  open: false,
  selectDateModeOptions: selectDateModeOptionsList,
  selectDateMode: selectDateModeOptionsList[0],
  selectTimeModeOptions: selectTimeModeOptionsList,
  selectTimeMode: selectTimeModeOptionsList[0],
  selectedDateMode: 'AnyDate',
  selectedTimeMode: 'Any',
  disableCalendar: true,
  bookingForm: {
    interestedChecked: false,
    termsAndConditionsAccept: false,
  },
  bookingFormError: {},
  somethingWentWrong: false,
  userCreated: false,
  newUserEmail: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.RESET:
      return {
        ...initialState,
        error: state.error,
      };
    case constants.GET_VEHICLE_INFO_SUCCESS: {
      const { testDriveConfig } = action.payload;
      return {
        ...state,
        testDriveConfig,
      };
    }
    case constants.GET_VEHICLE_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case constants.SET_FORM_VALUE_FROM_DROPDOWN: {
      const { selected, translations } = action.payload;
      const bookingForm = {
        ...state.bookingForm,
        [selected.keyValue]: selected.value,
      };
      const errors = validate(bookingForm, translations);
      return {
        ...state,
        bookingForm,
        bookingFormError: {
          ...state.bookingFormError,
          [selected.keyValue]: errors[selected.keyValue],
        },
      };
    }
    case constants.SET_BOOKING_FORM_VALUE: {
      const { key, value, translations, validation } = action.payload;
      let bookingForm = {};
      if (key === 'date') {
        const toUTC = Date.UTC(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
        );
        const getTimezoneOffset = value.getTimezoneOffset() * 60000;
        const getEpoch = toUTC + getTimezoneOffset;
        bookingForm = {
          ...state.bookingForm,
          [key]: getEpoch,
        };
      } else {
        bookingForm = {
          ...state.bookingForm,
          [key]: value,
        };
      }

      const bookingFormError = {
        ...state.bookingFormError,
      };
      if (validation) {
        const errors = validate(bookingForm, translations);
        bookingFormError[key] = errors[key];
      }

      return {
        ...state,
        bookingForm,
        bookingFormError,
      };
    }
    case constants.RADIO_GROUP_MODE_CHANGED: {
      const { key, value } = action.payload;
      let newBookingForm = {
        ...state.bookingForm,
      };
      if (
        !state.bookingForm.date &&
        key === 'selectedDateMode' &&
        value === 'SpecificDate'
      ) {
        newBookingForm = {
          ...state.bookingForm,
          date: new Date(),
        };
      }
      return {
        ...state,
        open: false,
        disableCalendar:
          key === 'selectedDateMode'
            ? value === 'AnyDate'
            : state.disableCalendar,
        [key]: value,
        bookingForm: newBookingForm,
      };
    }
    case constants.CONTINUE_AS_GUEST:
      return {
        ...state,
        context: 'UserDetails',
      };
    case constants.GUEST_DETAILS_SUBMITTED: {
      return {
        ...state,
        guestDetails: action.payload.guestDetails,
      };
    }
    case constants.TOGGLE_CALENDAR:
      return {
        ...state,
        open: !state.open,
        disableCalendar: state.selectedDateMode === 'AnyDate',
      };
    case constants.CONFIRM_DATE_AND_TIME:
      return {
        ...state,
        context: 'Login',
      };
    case constants.CONFIRM_REQUEST: {
      const bookingFormError = validate(
        action.payload.bookingForm,
        action.payload.translations,
      );
      const valid =
        Object.values(bookingFormError).filter(val => val !== undefined)
          .length === 0;
      return {
        ...state,
        bookingFormError,
        context: valid ? 'Summary' : state.context,
      };
    }
    case constants.LOGIN_SUCCESS: {
      return {
        ...state,
        loginFailed: undefined,
      };
    }
    case constants.SEND_TEST_DRIVE_LEAD_SUCCESS: {
      return {
        ...state,
        userCreated: action.payload.userCreated,
        newUserEmail: action.payload.newUserEmail,
        context: 'Summary',
      };
    }
    case constants.LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: action.payload.reason,
      };
    }
    case constants.SOMETHING_WENT_WRONG: {
      return {
        ...state,
        context: 'DateAndTime',
        somethingWentWrong: true,
      };
    }
    case constants.INTERESTED_CLICKED: {
      return {
        ...state,
        bookingForm: {
          ...state.bookingForm,
          interestedChecked: !state.bookingForm.interestedChecked,
        },
      };
    }
    case constants.CHANGE_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }

    default:
      return state;
  }
}

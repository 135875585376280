// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import type { Theme } from '../theme';

type TabProp = {
  title: string,
  component: () => Node,
};

type Props = {
  items: TabProp[],
};

type ThemeProps = Props & { theme: Theme, active: boolean };

const TabsContainer = styled.div.withConfig({
  displayName: 'TabsContainer',
})`
  width: 100%;
  border: 1px solid ${(props: ThemeProps) => props.theme.colours.secondary02};
  background-color: ${(props: ThemeProps) => props.theme.colours.secondary01};
`;
const Tab = styled.button.withConfig({
  displayName: 'Tab',
})`
  border: 0;
  background-color: ${(props: ThemeProps) => props.theme.colours.secondary01};
  ${(props: ThemeProps) =>
    `${
      props.active
        ? `
    color: ${props.theme.colours.primary01};
    border-bottom: 3px solid;
  `
        : ''
    }`}
  font-weight: 600;
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeContent}`}
  outline: none;
  margin-top: 1px;
  min-width: 100px;
  text-transform: capitalize;
  padding: 0;
  height: 40px;
  cursor: pointer;
`;
const TabContent = styled.div.withConfig({
  displayName: 'TabContent',
})`
  display: ${(props: ThemeProps) => (props.active ? 'block' : 'none')};
  padding-top: 10px;
`;

export default class Tabs extends React.Component<Props, *> {
  static defaultProps = {
    items: [],
  };

  state = {
    activeIndex: 0,
  };

  onTabClick = (activeIndex: number) => this.setState(() => ({ activeIndex }));

  render = () => {
    const { items } = this.props;
    const { activeIndex } = this.state;
    return (
      <div>
        <TabsContainer>
          {items.map(({ title }, index) => (
            <Tab
              key={`${title}-${index.toString()}`}
              onClick={() => this.onTabClick(index)}
              active={index === activeIndex}
              data-qa-hook={`${title}-tab`}
            >
              {title}
            </Tab>
          ))}
        </TabsContainer>
        <div>
          {items.map(({ title, component: Component }, index) => (
            <TabContent
              key={`${title}-${index.toString()}`}
              active={index === activeIndex}
            >
              <Component />
            </TabContent>
          ))}
        </div>
      </div>
    );
  };
}

// @flow
import React from 'react';
import { IconWrapper } from '..';

export default function CarouselUnselected({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 12 12" width="1em" height="1em">
        <circle
          cx={6}
          cy={6}
          r={5}
          fillRule="nonzero"
          stroke={colour}
          strokeWidth={2}
          fill="none"
        />
      </svg>
    </IconWrapper>
  );
}

CarouselUnselected.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

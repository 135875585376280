// @flow
import React from 'react';
import { Dropdown, FieldLabel } from 'cms-ui';
import { type FieldProps } from '../models/fields';

const options = ['top', 'center', 'bottom'].map(option => ({
  label: option,
  value: option,
}));

export default function VerticalAlign({
  name,
  values: { id: value },
  ids: { id },
  onChange,
}: FieldProps) {
  const input = {
    value,
    onChange: e => onChange(id, e.value),
  };

  return (
    <FieldLabel label={name}>
      <Dropdown name={name} input={input} options={options} />
    </FieldLabel>
  );
}

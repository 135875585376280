import { config } from 'cms-modules';

const { Config, Slice, Section, MultiDropdown, Text, Toggle } = config;

const options = [
  { label: 'Year', value: 'registration' },
  { label: 'Exterior Colour', value: 'exterior' },
  { label: 'Interior Colour', value: 'interior' },
  { label: 'Mileage', value: 'odometer.reading' },
  { label: 'Transmission', value: 'transmission' },
  { label: 'Fuel Type', value: 'fuel' },
  { label: 'Power', value: 'powerOutput' },
  { label: 'Model', value: 'modelGroupName' },
  { label: 'Variant', value: 'modelVariant' },
];

const editor = Config(
  'Vehicle Tile',
  { id: 'VehicleTile' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      MultiDropdown('Title', 'title', { options, limit: 6 }),
      MultiDropdown('Description', 'description', { options, limit: 6 }),
      MultiDropdown('Highlights', 'highlights', { options, limit: 6 }),
      Text('Button text', 'ctaButtonLabel'),
      Toggle('Number of views', 'showViews'),
      Text('Views label', 'viewsLabel'),
    ),
  ),
);

export default editor;

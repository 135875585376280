// @flow

import React from 'react';
import { SectionMenuBack, Button } from 'cms-ui';
import { useDispatch, useSelector } from 'react-redux';

import CreatePageContainer from '../containers/CreatePageContainer';
import PageSettings from './PageSettings';
import SelectPageMenu from './SelectPageMenu';
import SelectFormMenu from './SelectFormMenu';
import SelectBlogMenu from './SelectBlogMenu';
import EditBlogMenu from './EditBlogMenu';
import EditForm from './EditForm';
import EditSearchResults from './EditSearchResults';
import PAGE_TYPES, { HIDDEN_PAGE_TYPES } from '../constants/pageTypes';

import { actions as contextActions } from '../state/actions/context';

import useCreateBlog from './hooks/useCreateBlog';
import useCreateForm from './hooks/useCreateForm';

export const breakoutType = {
  createPage: 'CreatePage',
  listPages: 'ListPages',
  editPage: 'EditPage',
  createPost: 'CreatePost',
  listPosts: 'ListPosts',
  editPost: 'EditPost',
  createForm: 'CreateForm',
  listForms: 'ListForms',
  editForm: 'EditForm',
  editSearchResults: 'EditSearchResults',
};

const addPageText = 'Add new page';

const getPageById = (pages, id) => pages.find(p => p.id === id);

const renderButton = (text, onClick) => (
  <Button onClick={onClick} small fullWidth primary textTransform="uppercase">
    {text}
  </Button>
);

export default function BreakoutWrapper() {
  // TODO move to hook file
  const context = useSelector(state => state.context);
  const { breakout } = context;
  const dispatch = useDispatch();

  const setBreakoutContext = brk =>
    dispatch(contextActions.setBreakoutContext(brk));

  const setPageContext = (id, pageName) =>
    dispatch(contextActions.setPageContext(id, pageName));
  const pageConfigs = useSelector(state => state.config.pages);
  const pages = Object.values(pageConfigs)
    .filter(p => !HIDDEN_PAGE_TYPES.includes(p.pageType))
    .filter(p => !p.removed); // TODO refactor out duplicate code

  const forms = Object.values(pageConfigs)
    .filter(p => p.pageType === PAGE_TYPES.FORM)
    .filter(p => !p.removed);

  const { createBlog } = useCreateBlog();
  const { createForm } = useCreateForm();

  switch (breakout.name) {
    case breakoutType.createPage:
      return (
        <>
          <SectionMenuBack
            onClick={() => setBreakoutContext({ name: breakoutType.listPages })}
          >
            {addPageText}
          </SectionMenuBack>
          <CreatePageContainer
            renderButton={onClick => renderButton(addPageText, onClick)}
          />
        </>
      );
    case breakoutType.listPages:
      return (
        <>
          <SectionMenuBack
            onClick={() => setBreakoutContext('')}
            onPlusClick={() =>
              setBreakoutContext({ name: breakoutType.createPage })
            }
          >
            {'Select a page'}
          </SectionMenuBack>
          <SelectPageMenu
            onEnterBreakout={setBreakoutContext}
            pages={pages}
            setPage={setPageContext}
          />
        </>
      );
    case breakoutType.editPage:
      return (
        <>
          <SectionMenuBack
            onClick={() => setBreakoutContext({ name: breakoutType.listPages })}
          >
            {'Edit page'}
          </SectionMenuBack>
          <PageSettings page={getPageById(pages, context.page.id)} />
        </>
      );
    case breakoutType.listPosts:
      return (
        <>
          <SectionMenuBack
            onClick={() => setBreakoutContext('')}
            onPlusClick={createBlog}
          >
            {'Select a post'}
          </SectionMenuBack>
          <SelectBlogMenu onEnterBreakout={setBreakoutContext} />
        </>
      );
    case breakoutType.editPost:
      return (
        <>
          <SectionMenuBack
            onClick={() => {
              dispatch(contextActions.setGlobalContext(null));
              setBreakoutContext({ name: breakoutType.listPosts });
            }}
          >
            {'Edit Post'}
          </SectionMenuBack>
          <EditBlogMenu />
        </>
      );
    case breakoutType.listForms:
      return (
        <>
          <SectionMenuBack
            onClick={() => {
              dispatch(contextActions.setGlobalContext(null));
              dispatch(contextActions.setPodContext(null));
              setBreakoutContext('');
            }}
            onPlusClick={createForm}
          >
            {'Select a form'}
          </SectionMenuBack>
          <SelectFormMenu
            onEnterBreakout={setBreakoutContext}
            forms={forms}
            setPage={setPageContext}
          />
        </>
      );
    case breakoutType.editForm:
      return (
        <>
          <EditForm
            onLeaveModule={() => {
              dispatch(contextActions.setGlobalContext(null));
              dispatch(
                contextActions.setBreakoutContext({
                  name: breakoutType.listForms,
                }),
              );
              dispatch(contextActions.setPodContext(null));
            }}
          />
        </>
      );
    case breakoutType.editSearchResults:
      return (
        <>
          <SectionMenuBack onClick={() => setBreakoutContext('')}>
            Search Results
          </SectionMenuBack>
          <EditSearchResults
            page={context.page}
            breakout={breakoutType.editSearchResults}
          />
        </>
      );
    default:
      return null;
  }
}

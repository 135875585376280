// @flow
import React from 'react';
import styled from 'styled-components';
import TranslationUploader from './TranslationUploader';
import type { Translation } from '../state/types/translation';
import type { Theme } from '../theme';

type Props = {
  siteId: string,
  translations: Translation[],
  stageFileForImport: string => File => void,
  importFile: (string, string, string, string) => File => void,
  exportFile: (string, string, string, string) => () => void,
  siteDefaultLanguageIso: string,
};

type ThemeProps = Props & { theme: Theme };

const Container = styled.div.withConfig({ displayName: 'Container' })`
  display: flex;
  flex-direction: column;
  padding: ${(props: ThemeProps) => `${props.theme.layout.doublePadding}`};
}

  > * {
    margin-bottom: ${(props: ThemeProps) =>
      `${props.theme.layout.standardPadding}`};
  }
`;

const Header = styled.h2`
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeHeader}`};
  font-weight: 600;
`;

export default function TranslationUploaders({
  siteId,
  translations,
  stageFileForImport,
  importFile,
  exportFile,
  siteDefaultLanguageIso,
}: Props) {
  return (
    <Container>
      <Header>Translations</Header>

      {translations.map((t: Translation) => {
        const stageFile = stageFileForImport(t.id);
        const importFn = importFile(
          siteId,
          t.id,
          siteDefaultLanguageIso,
          t.isoCode,
        );
        const exportFn = exportFile(
          siteId,
          t.id,
          siteDefaultLanguageIso,
          t.isoCode,
        );

        return (
          <TranslationUploader
            key={t.id}
            name={`${t.language}-translation`}
            language={t.language}
            uploading={t.uploading}
            lastUpdated={t.lastUpdated}
            filename={t.filename}
            file={t.file}
            onFileUploadChange={e => stageFile(e.target.files[0])}
            onImportClick={importFn}
            onExportClick={exportFn}
            showFailureMessage={!!t.failure}
            showSuccessMessage={!!t.success}
          />
        );
      })}
    </Container>
  );
}

// @flow

import React from 'react';
import { FontEditor } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const defaultFont = {
  colour: undefined,
  typeface: undefined,
  fontSize: 2,
  kerning: 4,
  transform: undefined,
};

export default function Font({
  name,
  values: { id: value = defaultFont },
  ids: { id },
  onChange,
  data,
  ...props
}: FieldProps) {
  return (
    <FontEditor
      name={id}
      label={name}
      colours={data.colours}
      typefaces={data.fonts}
      transformOpen={false}
      value={value}
      onChange={val => onChange(id, val)}
      {...props}
    />
  );
}

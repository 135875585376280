// @flow
import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'cms-ui';

import TabMenu from './TabMenu';

const Heading = styled.div`
  height: 100px;
  width: auto;
  color: #474747;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  margin: 25px 0px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonWithMargin = styled(Button)`
  margin-left: 10px;
`;

const ModalContentContainer = styled.div.withConfig({
  displayName: 'ModalContentContainer',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  width: 460px;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizeContent};
`;

const ModelContentRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ModalContentHeader = styled.div`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizeSubHeader};
  font-weight: 600;
`;

type Props = {
  logos: Object,
  branding: {
    logos: Object,
    name: string,
    colours: Object,
    fonts: Object,
    uiElements: Object,
  },
  addBrandColour: () => void,
  removeBrandColour: (hex: string) => void,
  saveBrandColour: (hex: string) => void,
  selectBrandColour: (key: string, value: string) => void,
  addBrandFont: (font: File) => void,
  selectBrandFont: (key: string, font: Object) => void,
  removeBrandFont: (fontName: string) => void,
  addBrandLogo: (image: File, forKey: string) => void,
  removeBrandLogo: (forKey: string) => void,
  saveChanges: (branding: any) => void,
  cancelChanges: () => void,
  updateUiElement: (element: string, key: string, value: string) => void,
  updateButtonStyle: (element: string, value: string) => void,
};

export default class TemplateOptions extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showConfirm: false,
    };
  }

  showConfirmDialog = () => {
    this.setState({
      showConfirm: true,
    });
  };

  hideConfirmDialog = () => {
    this.setState({
      showConfirm: false,
    });
  };

  saveAndHideConfirmDialog = () => {
    const { saveChanges, branding, logos } = this.props;
    saveChanges(branding, logos);
    this.hideConfirmDialog();
  };

  render() {
    const {
      branding: { colours, fonts, uiElements },
      addBrandColour,
      removeBrandColour,
      saveBrandColour,
      selectBrandColour,
      removeBrandLogo,
      addBrandLogo,
      addBrandFont,
      selectBrandFont,
      removeBrandFont,
      cancelChanges,
      updateUiElement,
      updateButtonStyle,
      logos,
    } = this.props;

    return (
      <div>
        <Heading>Template options</Heading>
        <TabMenu
          brandingColours={colours}
          fonts={fonts}
          addBrandColour={addBrandColour}
          removeBrandColour={removeBrandColour}
          saveBrandColour={saveBrandColour}
          selectBrandColour={selectBrandColour}
          addBrandFont={addBrandFont}
          selectBrandFont={selectBrandFont}
          removeBrandFont={removeBrandFont}
          brandLogos={logos}
          addLogo={addBrandLogo}
          removeLogo={removeBrandLogo}
          uiElements={uiElements}
          updateUiElement={updateUiElement}
          updateButtonStyle={updateButtonStyle}
        />
        <ButtonContainer>
          <Button onClick={cancelChanges}>Cancel</Button>
          <ButtonWithMargin primary onClick={this.showConfirmDialog}>
            Save Changes
          </ButtonWithMargin>
        </ButtonContainer>
        {this.state.showConfirm && (
          <Modal overlay onClickOutside={this.hideConfirmDialog}>
            <ModalContentContainer>
              <ModalContentHeader>Are you sure?</ModalContentHeader>
              <ModelContentRow>
                This will cascade styling changes through the sites.
              </ModelContentRow>
              <ModelContentRow>
                <Button onClick={this.hideConfirmDialog}>Cancel</Button>
                <ButtonWithMargin
                  primary
                  onClick={this.saveAndHideConfirmDialog}
                >
                  Yes, Apply Changes
                </ButtonWithMargin>
              </ModelContentRow>
            </ModalContentContainer>
          </Modal>
        )}
      </div>
    );
  }
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Technicians({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 40 40">
        <path d="M10.768 18.188a1.29 1.29 0 0 0-1.827 1.823l5.951 5.947c.241.244.57.38.914.375h.065c.363-.032.7-.2.944-.47L31.707 8.028a1.296 1.296 0 1 0-1.984-1.667L15.711 23.126l-4.943-4.938zM27.96 3.781C20.08-1.147 9.756.625 3.97 7.9c-5.785 7.274-5.188 17.732 1.387 24.3 6.576 6.569 17.034 7.155 24.302 1.362 7.268-5.792 9.03-16.118 4.093-23.992a1.28 1.28 0 0 0-2.172 1.352 15.57 15.57 0 0 1-2.204 19.44c-5.806 5.795-15.095 6.14-21.314.79C1.845 25.801.796 16.565 5.658 9.959c4.861-6.607 13.991-8.354 20.948-4.008a1.279 1.279 0 1 0 1.354-2.17z" />
      </svg>
    </IconWrapper>
  );
}

Technicians.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

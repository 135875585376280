/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import * as THREE from 'three';
import Hammer from 'hammerjs';

import createModal from './createModal';

let scene;
let camera;
let renderer;
let lat = 0;
let long = 0;
let markers = [];
export default function usePlayer(player, spinner, { url, viewPoints }) {
  const [loaded, setLoaded] = useState(false);

  const aspectRatio = 1 / (9 / 16);
  const resizePlayer = () => {
    camera.aspect = aspectRatio;
    camera.updateProjectionMatrix();
    renderer.setSize(
      player.current.clientWidth,
      player.current.clientWidth / camera.aspect,
    );
    player.current.height = player.current.clientWidth / camera.aspect;
    document.getElementById('viewpoint-modal-wrapper').style.height = `${
      player.current.height
    }px`;
  };

  const initialisePlayer = () => {
    THREE.TextureLoader.prototype.crossOrigin = '';
    const width = player.current.clientWidth;
    const height = player.current.clientWidth / aspectRatio;
    // ADD SCENE
    scene = new THREE.Scene();
    // ADD CAMERA
    camera = new THREE.PerspectiveCamera(75, width / height, 1, 1100);
    camera.target = new THREE.Vector3(0, 0, 0);
    // ADD RENDERER
    renderer = new THREE.WebGLRenderer();
    renderer.setClearColor('#FFFFFF');
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.domElement.id = 'interior-canvas';
    player.current.appendChild(renderer.domElement);
  };

  const activateMarker = (x, y) => {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2(x, y);
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(markers);
    if (intersects.length === 0) return;
    const markerIndex = intersects[0].object.name;
    const marker = viewPoints[markerIndex];
    createModal(marker, renderer.domElement);
  };

  const latLonToCoords = (latitude, longitude, distance = 500) => {
    const phi = THREE.Math.degToRad(90 - latitude);
    const theta = THREE.Math.degToRad(longitude);

    return {
      x: distance * Math.sin(phi) * Math.cos(theta),
      y: distance * Math.cos(phi),
      z: distance * Math.sin(phi) * Math.sin(theta),
    };
  };

  const initialiseTouchGestures = () => {
    const hm = new Hammer.Manager(player.current);
    hm.add(new Hammer.Pan({ threshold: 20 }));
    hm.add(new Hammer.Tap());
    hm.on('panmove', event => {
      long += -event.velocityX * 4;
      lat += event.velocityY * 4;
    });
    hm.on('tap', event => {
      const { x, y } = event.center;
      const containerCoords = player.current.getBoundingClientRect();
      const normalisedX =
        ((x - containerCoords.left) / containerCoords.width) * 2 - 1;
      const normalisedY =
        1 - ((y - containerCoords.top) / containerCoords.height) * 2;
      activateMarker(normalisedX, normalisedY);
    });
  };

  const fetchTexture = async () => {
    await new Promise((resolve, reject) =>
      new THREE.TextureLoader().load(
        url,
        texture => {
          camera.target = new THREE.Vector3(0, 0, 0);
          const geometry = new THREE.SphereGeometry(500, 60, 40).scale(
            -1,
            1,
            1,
          );
          const material = new THREE.MeshBasicMaterial({ map: texture });
          const mesh = new THREE.Mesh(geometry, material);
          mesh.name = 'panorama';
          mesh.rotation.y = Math.PI;
          scene.add(mesh);

          const radius = 48;
          const quality = 64;
          const distance = 500 - radius / 2;
          markers =
            viewPoints &&
            viewPoints.map((marker, index) => {
              const { x, y, z } = latLonToCoords(
                (0.5 - marker.y) * 170,
                (0.5 - marker.x) * 360,
                distance,
              );

              const markerArea = new THREE.Mesh(
                new THREE.CircleGeometry(radius, quality),
                new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 }),
              );

              const outer = new THREE.Mesh(
                new THREE.RingGeometry(radius, radius * 0.9, quality),
                new THREE.MeshBasicMaterial({
                  color: 0xff5d6f,
                  side: THREE.DoubleSide,
                }),
              );

              const inner = new THREE.Mesh(
                new THREE.CircleGeometry(radius * 0.7, quality),
                new THREE.MeshBasicMaterial({ color: 0xff5d6f }),
              );

              [markerArea, outer, inner].forEach(obj => {
                scene.add(obj);
                obj.name = index; // eslint-disable-line no-param-reassign
                obj.position.set(x, y, z);
                obj.lookAt(new THREE.Vector3(0, 0, 0));
              });

              return markerArea;
            });
          setLoaded(true);
          resolve(scene);
        },
        () => {},
        reject,
      ),
    );
  };

  const animate = () => {
    lat = Math.max(-85, Math.min(85, lat));
    const { x, y, z } = latLonToCoords(lat, long);

    camera.target.x = x;
    camera.target.y = y;
    camera.target.z = z;
    camera.lookAt(camera.target);
    renderer.render(scene, camera);

    window.requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (loaded) {
      animate();
      player.current.classList.add('visible');
      document.getElementById('360-player-spinner').style.display = 'none';
    } else {
      window.addEventListener('resize', resizePlayer);
      initialisePlayer();
      initialiseTouchGestures();
      fetchTexture();
    }
  }, [loaded]);

  return {};
}

// @flow
/* eslint-disable camelcase */
import { put, call, all, select } from 'redux-saga/effects';
import { actions, constants } from '../actions/siteBuilder';
import type {
  SaveGlobalModulesAction,
  PublishSiteAction,
  PublishPreviewSiteAction,
  DeleteMediaContentAction,
  UploadMediaContentAction,
} from '../actions/siteBuilder';
import { get, post, remove } from '../../helpers/http';
import watch from '../../helpers/watch';
import { mediaResourcesDown } from '../../helpers/media';
import {
  API_BASE_URL_SITE_GLOBAL,
  API_BASE_URL_SITE_PUBLISH,
  API_BASE_URL_SITE_PUBLISH_PREVIEW,
  MEDIA_SERVICE_URL,
  INTERNAL_PUBLISH_DOMAIN,
} from '../../constants/apis';
import settings from '../../settings';

export const up = (site: Object) => ({ config: site.global });
export const down = (payload: Object) => payload.config;
export const getFolder = () =>
  `${settings.environment}/cms/${settings.brand}/content`;

export function* loadGlobalModules(
  token: string,
  action: SaveGlobalModulesAction,
): Generator<*, *, *> {
  const siteId = action.payload;
  const url = API_BASE_URL_SITE_GLOBAL(siteId);

  try {
    const result = yield call(get, { url, token });

    const globalModulesOrDefault = Object.keys(result.data.config).length
      ? result.data
      : { config: { modules: [] } };
    const data = down(globalModulesOrDefault);

    yield put(actions.loadGlobalModulesSuccess(data));
  } catch (error) {
    if (error.message.includes('404')) {
      yield put(actions.loadGlobalModulesSuccess({ modules: [] }));
    } else {
      yield put(actions.loadGlobalModulesFailure({ message: error.message }));
    }
  }
}

function* getHomePage() {
  const pages = yield select(state => Object.values(state.config.pages));

  return pages
    ? pages.find(p => p.relativePath === '/') || pages[0]
    : { relativePath: '/' };
}

export function* publishSite(
  token: string,
  action: PublishSiteAction,
): Generator<*, *, *> {
  const url = API_BASE_URL_SITE_PUBLISH(action.payload);
  try {
    yield call(post, { url, token });
    const siteId = yield select(state => state.site.currentSiteId);
    const homePage = yield getHomePage();
    const publishedUrl = `https://${siteId}.${INTERNAL_PUBLISH_DOMAIN}${
      homePage.relativePath
    }`;
    yield put(actions.publishSiteSuccess(publishedUrl));
  } catch (error) {
    yield put(actions.publishSiteFailure({ message: error.message }));
  }
}

export function* publishPreviewSite(
  token: string,
  action: PublishPreviewSiteAction,
): Generator<*, *, *> {
  const url = API_BASE_URL_SITE_PUBLISH_PREVIEW(action.payload.siteId);
  const { previewWindow } = action.payload;
  try {
    yield call(post, { url, token });
    yield put(actions.publishPreviewSiteSuccess());

    const homePage = yield getHomePage();

    previewWindow.location = `${settings.previewBase}${
      homePage.relativePath
    }?siteId=${action.payload.siteId}&environment=${
      settings.environment
    }&defaultLanguage=${action.payload.defaultLanguageIso}`;
  } catch (error) {
    yield put(actions.publishPreviewSiteFailure({ message: error.message }));
    previewWindow.document.write(
      `<html><head><title>Preview Failed To Publish</title></head><body><h1>Preview Failed To Publish</h1><p>${
        error.message
      }</p></body></html>`,
    );
  }
}

export function* loadMediaContent(token: string): Generator<*, *, *> {
  const url = mediaType =>
    `${MEDIA_SERVICE_URL}/${mediaType}/${settings.product}/${settings.brand}`;
  try {
    const [
      {
        data: { result: images },
      },
      {
        data: { result: videos },
      },
    ] = yield all([
      call(get, { url: url('image'), token }),
      call(get, { url: url('video'), token }),
    ]);
    yield put(
      actions.loadMediaContentSuccess({
        images: mediaResourcesDown(images),
        videos: mediaResourcesDown(videos),
      }),
    );
  } catch (error) {
    yield put(actions.loadMediaContentFailure(error.message));
  }
}

export function* deleteMediaContent(
  token: string,
  action: DeleteMediaContentAction,
): Generator<*, *, *> {
  const { mediaPublicId, type } = action.payload;
  const url = `${MEDIA_SERVICE_URL}/${
    type === 'video' ? 'video' : 'image'
  }/${encodeURIComponent(mediaPublicId)}`;

  try {
    yield call(remove, { url, token });
    yield put(actions.deleteMediaContentSuccess(mediaPublicId, type));
  } catch (error) {
    yield put(actions.deleteMediaContentFailure(error.message));
  }
}

export function* uploadMediaContent(
  token: string,
  action: UploadMediaContentAction,
): Generator<*, *, *> {
  const { image, transform, type } = action.payload;
  const url = `${MEDIA_SERVICE_URL}`;
  const folder = getFolder();

  try {
    const {
      data: { result },
    } = yield call(post, {
      url,
      token,
      data: { transform, image, tags: [settings.brand], folder },
    });

    yield put(actions.uploadMediaContentSuccess([result], type));
  } catch (error) {
    yield put(actions.uploadMediaContentFailure(error.message));
  }
}

export default [
  watch(constants.LoadGlobalModules, loadGlobalModules, true),
  watch(constants.PublishSite, publishSite, true),
  watch(constants.PublishPreviewSite, publishPreviewSite, true),
  watch(constants.LoadMediaContent, loadMediaContent, true),
  watch(constants.DeleteMediaContent, deleteMediaContent, true),
  watch(constants.UploadMediaContent, uploadMediaContent, true),
];

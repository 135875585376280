// @flow
import React from 'react';
import styled from 'styled-components';
import without from 'ramda/src/without';
import { Button } from 'cms-ui';

import CheckboxGroup from '../CheckboxGroup';

export type Props = {
  socialShare: Object,
  saveSocialShareOptions: () => void,
  updateSocialShareOptions: shareOptions => void,
  siteId: string,
};

const Container = styled.div.withConfig({ displayName: 'Container' })`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }: ThemeProps) => `${theme.layout.doublePadding}`};
}

  > * {
    margin-bottom: ${({ theme }: ThemeProps) =>
      `${theme.layout.standardPadding}`};
  }
`;

const Header = styled.h2`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeHeader}`};
`;

const newSocialShareTypes = (
  socialMediaTypes: string[],
  socialMedia: string,
) => {
  if (socialMediaTypes.some(s => s === socialMedia)) {
    return without(socialMedia, socialMediaTypes);
  }
  return [...socialMediaTypes, socialMedia];
};

const convertToOption = (socialShare, ap) => ({
  selected: socialShare.socialMediaTypes.some(p => ap === p),
  label: ap,
});

const ButtonContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
`;

export default function SocialShareOptions({
  socialShare,
  updateSocialShareOptions,
  saveSocialShareOptions,
  siteId,
}: Props) {
  return (
    <Container>
      <Header>Social share options</Header>
      <CheckboxGroup
        options={socialShare.availableShareDestinations.map(ap =>
          convertToOption(socialShare, ap),
        )}
        onOptionClick={idx =>
          updateSocialShareOptions({
            ...socialShare,
            socialMediaTypes: newSocialShareTypes(
              socialShare.socialMediaTypes,
              socialShare.availableShareDestinations[idx],
            ),
          })
        }
      />
      <ButtonContainer>
        <Button onClick={() => saveSocialShareOptions(siteId)} primary>
          Save
        </Button>
      </ButtonContainer>
    </Container>
  );
}

import Icon from './Icon';

export { id as reducerId } from './reducer';

export { default as component } from './CompareGrid';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';
export { actions, constants, reducer, initialState } from './reducer';

export const name = 'CompareGrid';
export const id = 'CompareGrid';
export const availableToAdministration = false;
export const description = 'Compare Page';
export const icon = Icon;

// @flow
import React from 'react';
import styled from 'styled-components';

import IoSocialFacebook from 'react-icons/lib/io/social-facebook';
import IoSocialTwitter from 'react-icons/lib/io/social-twitter';
import IoSocialInstagram from 'react-icons/lib/io/social-instagram';
import IoSocialYoutube from 'react-icons/lib/io/social-youtube';
import IoSocialLinkedin from 'react-icons/lib/io/social-linkedin';
import media from '../../theme';

type SocialLinkProps = {
  colour: string,
};

const SocialLinks = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  ${media.max.large`
    padding: 8px 16px;
  `};
`;

const SocialLink = styled.a`
  color: ${(props: SocialLinkProps) => props.colour};
  font-size: 26px;
  padding: 0 6px;
`;

const IconWrapper = styled.img`
  width: 26px;
  height: 26px;
`;
type Props = {
  urlFacebook: string,
  urlTwitter: string,
  urlYouTube: string,
  urlInstagram: string,
  urlLinkedIn: string,
  socialIconsColour: string,
  preview: boolean,
  facebookIconLink: string,
  twitterIconLink: string,
  youtubeIconLink: string,
  instagramIconLink: string,
  linkedinIconLink: string,
};

export default function SocialMediaLinks({
  socialIconsColour,
  urlLinkedIn,
  urlInstagram,
  urlYouTube,
  urlTwitter,
  urlFacebook,
  preview,
  facebookIconLink,
  twitterIconLink,
  youtubeIconLink,
  instagramIconLink,
  linkedinIconLink,
}: Props) {
  const interceptClick = preview ? event => event.preventDefault() : () => {};
  return (
    <SocialLinks>
      {urlFacebook && (
        <li>
          <SocialLink
            colour={socialIconsColour}
            href={urlFacebook}
            target="_blank"
            onClick={interceptClick}
          >
            {facebookIconLink ? (
              <IconWrapper src={facebookIconLink} alt="" />
            ) : (
              <IoSocialFacebook />
            )}
          </SocialLink>
        </li>
      )}
      {urlTwitter && (
        <li>
          <SocialLink
            colour={socialIconsColour}
            href={urlTwitter}
            target="_blank"
            onClick={interceptClick}
          >
            {twitterIconLink ? (
              <IconWrapper src={twitterIconLink} alt="" />
            ) : (
              <IoSocialTwitter />
            )}
          </SocialLink>
        </li>
      )}
      {urlYouTube && (
        <li>
          <SocialLink
            colour={socialIconsColour}
            href={urlYouTube}
            target="_blank"
            onClick={interceptClick}
          >
            {youtubeIconLink ? (
              <IconWrapper src={youtubeIconLink} alt="" />
            ) : (
              <IoSocialYoutube />
            )}
          </SocialLink>
        </li>
      )}
      {urlInstagram && (
        <li>
          <SocialLink
            colour={socialIconsColour}
            href={urlInstagram}
            target="_blank"
            onClick={interceptClick}
          >
            {instagramIconLink ? (
              <IconWrapper src={instagramIconLink} alt="" />
            ) : (
              <IoSocialInstagram />
            )}
          </SocialLink>
        </li>
      )}
      {urlLinkedIn && (
        <li>
          <SocialLink
            colour={socialIconsColour}
            href={urlLinkedIn}
            target="_blank"
            onClick={interceptClick}
          >
            {linkedinIconLink ? (
              <IconWrapper src={linkedinIconLink} alt="" />
            ) : (
              <IoSocialLinkedin />
            )}
          </SocialLink>
        </li>
      )}
    </SocialLinks>
  );
}

// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as routerActions } from '../../actions/router';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortlistActions } from '../../shared/shortlist';
import templates from './templates';

export default props => {
  const dispatch = useDispatch();
  const sharedCompareVehicles =
    useSelector(
      state => state.shared.compare && state.shared.compare.vehicles,
    ) || [];
  const sharedShortlistVehicles =
    useSelector(
      state => state.shared.shortlist && state.shared.shortlist.vehicles,
    ) || [];
  const Template = templates.DefaultTemplate;
  const { id } = props.vehicle;

  const isCompare = sharedCompareVehicles.some(v => id === v.id);
  const isShortlisted = sharedShortlistVehicles.some(v => id === v.id);

  const handleMoreInfoClick = () =>
    dispatch(routerActions.navigate(`VDP/${id}`));

  const handleCompareVehicleClick = () =>
    dispatch(compareActions.toggleVehicle(props.vehicle));

  const handleShortlistVehicleClick = () =>
    dispatch(shortlistActions.toggleVehicle(props.vehicle));

  return (
    <Template
      {...props}
      isCompare={isCompare}
      isShortlisted={isShortlisted}
      handleMoreInfoClick={handleMoreInfoClick}
      handleCompareVehicleClick={handleCompareVehicleClick}
      handleShortlistVehicleClick={handleShortlistVehicleClick}
    />
  );
};

import Text from './Text';
import Image from './Image';
import TextImage from './TextImage';
import TextCTA from './TextCTA';
import ImageTitle from './ImageTitle';
import Brick from './Brick';
import Spacer from './Spacer';
import IconGrid from './IconGrid';
import Video from './Video';
import HeroCarousel from './HeroCarousel';
import VehicleSpecifications from './VehicleSpecifications';
import VehicleCarousel from './VehicleCarousel';
import VehicleEquipment from './VehicleEquipment';
import QuickContact from './QuickContact';
import Heading from './Heading';
import VehicleRetailerInfo from './VehicleRetailerInfo';
import Header from './Header';
import Footer from './Footer';
import Filters from './Filters';
import BlogPost from './BlogPost';
import CookiePolicy from './CookiePolicy';
import EnquiryRedirect from './EnquiryRedirect';
import Form from './Form';
import FeatureInventoryRange from './FeatureInventoryRange';
import SearchResults from './SearchResults';
import PostSummary from './PostSummary';

import * as ModelSearchPod from './ModelSearch';
import * as FeaturedVehiclePod from './FeaturedVehicle';
import * as DealerLocatorPod from './DealerLocator';

const { default: ModelSearch, ...modelSearchReducer } = ModelSearchPod;
const {
  default: FeaturedVehicle,
  ...featuredVehicleReducer
} = FeaturedVehiclePod;
const { default: DealerLocator, ...dealerLocatorReducer } = DealerLocatorPod;

const pods = {
  Text,
  Image,
  TextImage,
  TextCTA,
  ImageTitle,
  Brick,
  Spacer,
  IconGrid,
  Video,
  HeroCarousel,
  VehicleSpecifications,
  VehicleCarousel,
  VehicleEquipment,
  QuickContact,
  Heading,
  VehicleRetailerInfo,
  ModelSearch,
  PostSummary,
  Header,
  Footer,
  Filters,
  BlogPost,
  FeatureInventoryRange,
  CookiePolicy,
  EnquiryRedirect,
  FeaturedVehicle,
  Form,
  SearchResults,
  DealerLocator,
};

export const podReducers = {
  modelSearchReducer,
  featuredVehicleReducer,
  dealerLocatorReducer,
};

export default pods;

// @flow
/* eslint-disable import/prefer-default-export */

const INDEX = () => '/';

const SITES = (siteId: string) => `/sites/${siteId}`;

const SITE_CONFIG = (siteId: string) => `${SITES(siteId)}/config`;

const CLONE = (siteId: string) => `${SITES(siteId)}/clone`;

const SITE_BUILDER = (siteId: string) => `${SITES(siteId)}/builder`;

export const TO = {
  INDEX,
  SITE_CONFIG,
  SITE_BUILDER,
  CLONE,
};

// @flow
import type { Site, Branding, Logos } from '../reducers/editor';
import type { Error } from '../types/error';

const LoadSites: 'LOAD SITES' = 'LOAD SITES';
export type LoadSitesAction = { type: typeof LoadSites };
const loadSites = (): LoadSitesAction => ({
  type: LoadSites,
});

const LoadSitesSuccessful: 'SITES LOAD SUCCESS' = 'SITES LOAD SUCCESS';
export type LoadSitesSuccessfulAction = {
  type: typeof LoadSitesSuccessful,
  payload: Site[],
};
const loadSitesSuccessful = (sites: Site[]): LoadSitesSuccessfulAction => ({
  type: LoadSitesSuccessful,
  payload: sites,
});

const LoadSitesFailure: 'SITES LOAD FAILURE' = 'SITES LOAD FAILURE';
export type LoadSitesFailureAction = {
  type: typeof LoadSitesFailure,
  payload: Error,
};
const loadSitesFailure = (error: Error): LoadSitesFailureAction => ({
  type: LoadSitesFailure,
  payload: error,
});

const LoadBranding: 'LOAD BRANDING' = 'LOAD BRANDING';
export type LoadBrandingAction = { type: typeof LoadBranding };
const loadBranding = (): LoadBrandingAction => ({
  type: LoadBranding,
});

const LoadBrandingSuccess: 'LOAD BRANDING SUCCESS' = 'LOAD BRANDING SUCCESS';
export type LoadBrandingSuccessAction = {
  type: typeof LoadBrandingSuccess,
  payload: Branding,
};
const loadBrandingSuccess = (
  branding: Branding,
): LoadBrandingSuccessAction => ({
  type: LoadBrandingSuccess,
  payload: branding,
});

const LoadBrandingFailure: 'LOAD BRANDING FAILURE' = 'LOAD BRANDING FAILURE';
export type LoadBrandingFailureAction = {
  type: typeof LoadBrandingFailure,
  payload: Error,
};
const loadBrandingFailure = (error: Error): LoadBrandingFailureAction => ({
  type: LoadBrandingFailure,
  payload: error,
});

const SaveBranding: 'SAVE BRANDING' = 'SAVE BRANDING';
export type SaveBrandingAction = { type: typeof SaveBranding };
const saveBranding = (
  branding: Branding,
  logos: Logos,
): SaveBrandingAction => ({
  type: SaveBranding,
  payload: { branding, logos },
});

const SaveBrandingSuccess: 'SAVE BRANDING SUCCESS' = 'SAVE BRANDING SUCCESS';
export type SaveBrandingSuccessAction = {
  type: typeof SaveBrandingSuccess,
  payload: Branding,
};
const saveBrandingSuccess = (
  branding: Branding,
): SaveBrandingSuccessAction => ({
  type: SaveBrandingSuccess,
  payload: branding,
});

const SaveBrandingFailure: 'SAVE BRANDING FAILURE' = 'SAVE BRANDING FAILURE';
export type SaveBrandingFailureAction = {
  type: typeof SaveBrandingFailure,
  payload: Error,
};
const saveBrandingFailure = (error: Error): SaveBrandingFailureAction => ({
  type: SaveBrandingFailure,
  payload: error,
});

const AddBrandColour: 'ADD BRAND COLOUR' = 'ADD BRAND COLOUR';
export type AddBrandColourAction = { type: typeof AddBrandColour };
const addBrandColour = (): AddBrandColourAction => ({
  type: AddBrandColour,
});

const RemoveBrandColour: 'REMOVE BRAND COLOUR' = 'REMOVE BRAND COLOUR';
export type RemoveBrandColourAction = {
  type: typeof RemoveBrandColour,
  payload: string,
};
const removeBrandColour = (hex: string): RemoveBrandColourAction => ({
  type: RemoveBrandColour,
  payload: hex,
});

const SaveBrandColour: 'SAVE BRAND COLOUR' = 'SAVE BRAND COLOUR';
export type SaveBrandColourAction = {
  type: typeof SaveBrandColour,
  payload: string,
};
const saveBrandColour = (hex: string): SaveBrandColourAction => ({
  type: SaveBrandColour,
  payload: hex,
});

const SelectBrandColour: 'SELECT BRAND COLOUR' = 'SELECT BRAND COLOUR';
export type SelectBrandColourAction = {
  type: typeof SelectBrandColour,
  payload: { key: string, value: string },
};
const selectBrandColour = (
  key: string,
  value: string,
): SelectBrandColourAction => ({
  type: SelectBrandColour,
  payload: { key, value },
});

const AddBrandFont: 'ADD BRAND FONT' = 'ADD BRAND FONT';
export type AddBrandFontAction = {
  type: typeof AddBrandFont,
  payload: File,
};
const addBrandFont = (font: File): AddBrandFontAction => ({
  type: AddBrandFont,
  payload: font,
});

const SelectBrandFont: 'SELECT BRAND FONT' = 'SELECT BRAND FONT';
export type SelectBrandFontAction = {
  type: typeof SelectBrandFont,
  payload: Object,
};
const selectBrandFont = (key: string, font: Object): SelectBrandFontAction => ({
  type: SelectBrandFont,
  payload: {
    key,
    font,
  },
});

const RemoveBrandFont: 'REMOVE BRAND FONT' = 'REMOVE BRAND FONT';
export type RemoveBrandFontAction = {
  type: typeof RemoveBrandFont,
  payload: string,
};
const removeBrandFont = (fontName: string): RemoveBrandFontAction => ({
  type: RemoveBrandFont,
  payload: fontName,
});

const FontUploadComplete: 'FONT UPLOAD COMPLETE' = 'FONT UPLOAD COMPLETE';
export type FontUploadCompleteAction = {
  type: typeof FontUploadComplete,
  payload: {
    label: string,
    value: string,
    type: string,
    custom: boolean,
    id: string,
  },
};
const fontUploadComplete = (
  name: string,
  id: string,
  ext: string,
): FontUploadCompleteAction => ({
  type: FontUploadComplete,
  payload: {
    label: name,
    value: name,
    id,
    type: ext,
    custom: true,
  },
});
const FontUploadError: 'FONT UPLOAD ERROR' = 'FONT UPLOAD ERROR';
export type FontUploadErrorAction = {
  type: typeof FontUploadError,
  payload: Object,
};
const fontUploadError = (payload: Object): FontUploadErrorAction => ({
  type: FontUploadError,
  payload,
});
const FontUploadProgress: 'FONT UPLOAD PROGRESS' = 'FONT UPLOAD PROGRESS';
export type FontUploadProgressAction = {
  type: typeof FontUploadProgress,
  payload: Object,
};
const fontUploadProgress = (payload: Object): FontUploadProgressAction => ({
  type: FontUploadProgress,
  payload,
});

const IsLoggingOut: 'LOGGING OUT' = 'LOGGING OUT';
type IsLoggingOutAction = { type: typeof IsLoggingOut };
const isLoggingOut = (loggingOut: boolean): IsLoggingOutAction => ({
  type: IsLoggingOut,
  payload: {
    loggingOut,
  },
});

const ParseBrandLogo: 'PARSE BRAND LOGO' = 'PARSE BRAND LOGO';
export type ParseBrandLogoAction = {
  type: typeof ParseBrandLogo,
  payload: {
    image: File,
    forKey: string,
  },
};
const parseBrandLogo = (image: File, forKey: string): ParseBrandLogoAction => ({
  type: ParseBrandLogo,
  payload: {
    image,
    forKey,
  },
});

const ParseBrandLogoSuccess: 'PARSE BRAND LOGO SUCCESS' =
  'PARSE BRAND LOGO SUCCESS';
export type ParseBrandLogoSuccessAction = {
  type: typeof ParseBrandLogoSuccess,
  payload: {
    image: string,
    forKey: string,
  },
};

const parseBrandLogoSuccess = (
  image: string,
  forKey: string,
): ParseBrandLogoSuccessAction => ({
  type: ParseBrandLogoSuccess,
  payload: {
    image,
    forKey,
  },
});
const ParseBrandLogoFailure: 'PARSE BRAND LOGO FAILURE' =
  'PARSE BRAND LOGO FAILURE';
export type ParseBrandLogoFailureAction = {
  type: typeof ParseBrandLogoFailure,
  payload: string,
};
const parseBrandLogoFailure = (error: string): ParseBrandLogoFailureAction => ({
  type: ParseBrandLogoFailure,
  payload: error,
});

const RemoveBrandLogo: 'REMOVE BRAND LOGO' = 'REMOVE BRAND LOGO';
export type RemoveBrandLogoAction = {
  type: typeof RemoveBrandLogo,
  payload: string,
};
const removeBrandLogo = (forKey: string): RemoveBrandLogoAction => ({
  type: RemoveBrandLogo,
  payload: forKey,
});

const UpdateUiElement: 'UPDATE UI ELEMENT' = 'UPDATE UI ELEMENT';
export type UpdateUiElementAction = {
  type: UpdateUiElement,
  payload: {
    element: string,
    key: string,
    value: string,
  },
};
const updateUiElement = (element, key, value): UpdateUiElementAction => ({
  type: UpdateUiElement,
  payload: {
    element,
    key,
    value,
  },
});

const UpdateButtonStyle: 'UPDATE BUTTON STYLE' = 'UPDATE BUTTON STYLE';
export type UpdateButtonStyleAction = {
  type: UpdateButtonStyle,
  payload: {
    element: string,
    value: string,
  },
};
const updateButtonStyle = (element, value): UpdateButtonStyleAction => ({
  type: UpdateButtonStyle,
  payload: {
    element,
    value,
  },
});

const ResetSuccessMessages: 'RESET_SUCCESS_MESSAGES' = 'RESET_SUCCESS_MESSAGES';
export type ResetSuccessMessagesAction = {
  type: ResetSuccessMessages,
};
const resetSuccessMessages = (): UpdateButtonStyleAction => ({
  type: ResetSuccessMessages,
});

const ResetFailureMessages: 'RESET_FAILURE_MESSAGES' = 'RESET_FAILURE_MESSAGES';
export type ResetFailureMessagesAction = {
  type: ResetFailureMessages,
};
const resetFailureMessages = (): UpdateButtonStyleAction => ({
  type: ResetFailureMessages,
});

export const actions = {
  loadBranding,
  loadBrandingSuccess,
  loadBrandingFailure,
  saveBranding,
  saveBrandingSuccess,
  saveBrandingFailure,
  loadSites,
  loadSitesSuccessful,
  loadSitesFailure,
  addBrandColour,
  removeBrandColour,
  saveBrandColour,
  selectBrandColour,
  addBrandFont,
  selectBrandFont,
  removeBrandFont,
  fontUploadComplete,
  fontUploadError,
  fontUploadProgress,
  isLoggingOut,
  parseBrandLogo,
  parseBrandLogoSuccess,
  parseBrandLogoFailure,
  removeBrandLogo,
  updateUiElement,
  updateButtonStyle,
  resetSuccessMessages,
  resetFailureMessages,
};

export const constants = {
  LoadBranding,
  LoadBrandingSuccess,
  LoadBrandingFailure,
  SaveBranding,
  SaveBrandingSuccess,
  SaveBrandingFailure,
  LoadSites,
  LoadSitesSuccessful,
  LoadSitesFailure,
  AddBrandColour,
  RemoveBrandColour,
  SaveBrandColour,
  SelectBrandColour,
  AddBrandFont,
  SelectBrandFont,
  RemoveBrandFont,
  FontUploadComplete,
  FontUploadError,
  FontUploadProgress,
  IsLoggingOut,
  ParseBrandLogo,
  ParseBrandLogoSuccess,
  ParseBrandLogoFailure,
  RemoveBrandLogo,
  UpdateUiElement,
  UpdateButtonStyle,
  ResetSuccessMessages,
  ResetFailureMessages,
};

export type Action =
  | LoadBrandingAction
  | LoadBrandingSuccessAction
  | LoadBrandingFailureAction
  | LoadSitesAction
  | LoadSitesSuccessfulAction
  | LoadSitesFailureAction
  | AddBrandColourAction
  | RemoveBrandColourAction
  | SaveBrandColourAction
  | SelectBrandColourAction
  | AddBrandFontAction
  | SelectBrandFontAction
  | RemoveBrandFontAction
  | FontUploadCompleteAction
  | FontUploadErrorAction
  | FontUploadProgressAction
  | IsLoggingOut
  | ParseBrandLogoAction
  | ParseBrandLogoSuccessAction
  | ParseBrandLogoFailureAction
  | RemoveBrandLogoAction
  | UpdateUiElementAction
  | UpdateButtonStyleAction
  | ResetSuccessMessagesAction
  | ResetFailureMessagesAction;

// @flow
/* eslint-env browser */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import {
  InputField,
  SelectField,
  TextAreaField,
} from '../../../components/FormFields';
import PersonalDataConsent from '../../../components/PersonalDataConsent';
import ConfirmationWindow from '../../../components/ConfirmationWindow';
import { Button, Paragraph } from '../../../components/Global';
import media from '../../../theme';
import { type SubmissionStatus } from '../../../modules/Enquiry/reducer';
import { required, validEmail, valueIsTrue } from '../../../helpers/validation';
import { titleOptions } from '../../../helpers/formTitleOptions';
import { countryOptions } from '../../../helpers/countryOptions';
import LabelledCheckBox from '../../../components/LabelledCheckBox';
import { translateFromTemplate } from '../../../shared/localisation/translateFromTemplate';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const EnquiryFormContainer = styled.div`
  ${media.max.large`
  ${({ width }) => `width: ${width ? `${width}px` : '90%'}`};
    padding: 0 5%;
  `};
  width: 40%;
  ${({ width }) => `width: ${width ? `${width}px` : '40%'}`};
  height: auto;
  margin: 40px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${media.min.large`
    flex-direction: row;
  `};
`;

const MultiFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  > div {
    ${media.max.large`
      width: 48%;
  `};
  }

  ${media.min.large`
    width: 48%;
  `};
`;

const LineBreak = styled.div`
  border-bottom: 1px solid #dedede;
  margin-bottom: 30px;
`;

const ActionsContainer = styled.div`
  ${media.max.large`
    display: block;
  `};
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ActionButtonWrapper = styled.div`
  ${media.max.large`
    padding: 10px 0;
  `};
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #9e1b32;
  margin-top: 10px;
  ${media.max.large`
    justify-content: center;
  `};
`;

type Props = {
  config: Object,
  vehicle: Object,
  history: Object,
  error: string,
  submissionStatus: SubmissionStatus,
  globalStyling: Object,
  submitMessage(form: Object): void,
  enquiryFormGoBack(): void,
  requestPrivacyPolicy(payload: Object): void,
};

type State = {
  enquiryType: Object,
  title: Object,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  postCode: string,
  vehicleOfInterest: string,
  comments: string,
  phoneConsent: boolean,
  emailConsent: boolean,
  smsConsent: boolean,
  errors: Object,
  address1: string,
  city: string,
  county: string,
  country: Object,
  tradeInVisible: boolean,
  marketingConsent: ?string,
  customisedServicesConsent: ?boolean,
  vehicleBrand: string,
  vehicleModel: string,
  vehicleVersion: string,
  accepted: boolean,
};

export default class EnquiryForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      title: {
        label: props.config.translations.formTitleSelect,
        value: '',
      },
      enquiryType: {
        label: 'General Enquiry',
        value: 'general_enquiry',
      },
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      postCode: '',
      comments: '',
      phoneConsent: false,
      emailConsent: false,
      smsConsent: false,
      marketingConsent: null,
      customisedServicesConsent: null,
      errors: {},
      address1: '',
      address2: '',
      city: '',
      county: '',
      country: {
        label: props.config.translations.formCountrySelect,
        value: '',
      },
      tradeInVisible: false,
      vehicleBrand: '',
      vehicleModel: '',
      vehicleVersion: '',
      accepted: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { accepted, errors } = this.state;
    if (prevState.accepted !== accepted) {
      this.setState(() => ({
        errors: { ...errors, ...this.validate('accepted', accepted) },
      }));
    }
  }

  onSelectChange = ({
    keyValue,
    value,
    label,
  }: {
    keyValue: string,
    value: any,
    label: string,
  }) => {
    const { errors } = this.state;
    const error = this.validate(keyValue, { value, label });
    this.setState({
      [keyValue]: { label, value },
      errors: {
        ...errors,
        ...error,
      },
    });
  };

  onInputChange = (formKey: string, value: string | boolean) => {
    const { errors } = this.state;
    const error = this.validate(formKey, value);
    this.setState({
      [formKey]: value,
      errors: {
        ...errors,
        ...error,
      },
    });
  };

  onRadioSelect = (event: SyntheticEvent<HTMLInputElement>) => {
    const { errors } = this.state;
    const {
      currentTarget: { name, value },
    } = event;
    const error = this.validate(name, value);
    this.setState(() => ({
      [name]: value,
      errors: {
        ...errors,
        ...error,
      },
    }));
  };

  onSubmitMessage = () => {
    const { submitMessage, vehicle } = this.props;
    const { title, enquiryType, country } = this.state;
    const { errors } = this.state;
    const newErrors = Object.entries(this.state)
      .map(entry => this.validate(entry[0], entry[1]))
      .filter(entry => entry !== undefined)
      .reduce((acc, currVal) => ({ ...acc, ...currVal }), {});

    if (
      Object.values(newErrors).filter(error => error !== undefined).length > 0
    ) {
      window.scroll(0, 0);
      this.setState({ errors: { ...errors, ...newErrors } });
      return;
    }
    submitMessage({
      ...{
        ...vehicle,
        ...this.state,
        title: title.value,
        enquiryType: enquiryType.value,
        country: country.value,
      },
    });
  };

  validate = (formKey: string, value: any) => {
    const {
      config: { translations },
    } = this.props;
    const { tradeInVisible } = this.state;

    const excludeKeys = ['vehicleBrand', 'vehicleModel'];
    const nonValidatedKeys = [
      'enquiryType',
      'vehicleOfInterest',
      'phoneConsent',
      'smsConsent',
      'emailConsent',
      'errors',
      'tradeInVisible',
      'vehicleVersion',
      'customisedServicesConsent',
      'marketingConsent',
      'address2',
    ];
    let actualValidationKeys = [...nonValidatedKeys];

    if (!tradeInVisible) {
      actualValidationKeys = [...actualValidationKeys, ...excludeKeys];
    }

    if (actualValidationKeys.includes(formKey)) return undefined;

    let validations = {
      title: required,
      firstName: required,
      lastName: required,
      address1: required,
      city: required,
      county: required,
      postCode: required,
      country: required,
      email: validEmail,
      phone: required,
      comments: required,
      accepted: valueIsTrue,
    };

    if (tradeInVisible) {
      validations = {
        ...validations,
        vehicleBrand: required,
        vehicleModel: required,
      };
    }

    if (formKey === 'title' || formKey === 'country') {
      return {
        [formKey]: validations[formKey](value.value, translations),
      };
    }
    return { [formKey]: validations[formKey](value, translations) };
  };

  render() {
    const {
      config: {
        translations,
        marketingLink,
        showUserCommunication,
        formWidth,
        enquiryFormStyles = {},
        tacLink,
      },
      vehicle,
      history,
      error,
      submissionStatus,
      globalStyling,
      requestPrivacyPolicy,
    } = this.props;

    const vehicleOfInterest = vehicle
      ? `${vehicle.registration} ${vehicle.description}`
      : '';

    const {
      title,
      firstName,
      lastName,
      email,
      phone,
      postCode,
      comments,
      phoneConsent,
      emailConsent,
      smsConsent,
      address1,
      county,
      city,
      country,
      marketingConsent,
      customisedServicesConsent,
      tradeInVisible,
      vehicleBrand,
      vehicleModel,
      vehicleVersion,
      errors,
      accepted,
    } = this.state;

    const hasError = field => errors[field] && errors[field][0];
    return submissionStatus === 'Successful' ? (
      <Container>
        <ConfirmationWindow
          translations={translations}
          circleTickColour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
          onButtonClick={history.goBack}
        />
      </Container>
    ) : (
      <Container>
        <EnquiryFormContainer width={formWidth}>
          <SelectField
            type="text"
            keyValue="title"
            value={title.value}
            onChange={this.onSelectChange}
            label={translations.titleLabel}
            options={titleOptions(translations)}
            error={hasError('title')}
            globalStyling={globalStyling}
            doubleRow
            required
          />
          <Row>
            <InputField
              type="text"
              label={translations.firstNameLabel}
              value={firstName}
              onChange={this.onInputChange}
              keyValue="firstName"
              error={hasError('firstName')}
              doubleRow
              required
            />
            <InputField
              type="text"
              label={translations.lastNameLabel}
              value={lastName}
              onChange={this.onInputChange}
              keyValue="lastName"
              error={hasError('lastName')}
              doubleRow
              required
            />
          </Row>
          <Row>
            <InputField
              type="text"
              label={translations.address1Label}
              value={address1}
              onChange={this.onInputChange}
              keyValue="address1"
              error={hasError('address1')}
              doubleRow
              required
            />
            <InputField
              type="text"
              label={translations.cityLabel}
              value={city}
              onChange={this.onInputChange}
              keyValue="city"
              error={hasError('city')}
              doubleRow
              required
            />
          </Row>
          <Row>
            <MultiFieldRow>
              <InputField
                type="text"
                label={translations.countyLabel}
                value={county}
                onChange={this.onInputChange}
                keyValue="county"
                error={hasError('county')}
                doubleRow
                required
              />
              <InputField
                type="text"
                label={translations.postCodeLabel}
                value={postCode}
                onChange={this.onInputChange}
                keyValue="postCode"
                error={hasError('postCode')}
                doubleRow
                required
              />
            </MultiFieldRow>
            <SelectField
              type="text"
              keyValue="country"
              value={country.value}
              onChange={this.onSelectChange}
              label={translations.countryLabel}
              options={countryOptions}
              error={hasError('country')}
              globalStyling={globalStyling}
              doubleRow
              required
            />
          </Row>
          <Row>
            <InputField
              type="text"
              label={translations.emailLabel}
              value={email}
              onChange={this.onInputChange}
              keyValue="email"
              error={hasError('email')}
              doubleRow
              required
            />
            <InputField
              type="text"
              label={translations.phoneLabel}
              value={phone}
              onChange={this.onInputChange}
              keyValue="phone"
              error={hasError('phone')}
              doubleRow
              required
            />
          </Row>
          <InputField
            type="text"
            label={translations.vehicleOfInterestLabel}
            value={vehicleOfInterest}
            onChange={this.onInputChange}
            keyValue="vehicleOfInterest"
            disabled={!!vehicle}
            truncateChars
          />
          <TextAreaField
            type="text"
            label={translations.commentsLabel}
            value={comments}
            onChange={this.onInputChange}
            keyValue="comments"
            error={hasError('comments')}
            required
          />
          <Fragment>
            <Row style={{ marginBottom: '16px' }}>
              <LabelledCheckBox
                onClick={() => {
                  this.setState(() => ({
                    tradeInVisible: !tradeInVisible,
                    vehicleBrand: '',
                    vehicleModel: '',
                    vehicleVersion: '',
                    errors: {
                      ...errors,
                      vehicleBrand: undefined,
                      vehicleModel: undefined,
                      vehicleVersion: undefined,
                    },
                  }));
                }}
                checked={tradeInVisible}
                label={translations.requestTradeInLabel}
                onColour="#DFB400"
                globalStyling={globalStyling}
              />
            </Row>
            {tradeInVisible && (
              <Fragment>
                <Row>
                  <InputField
                    type="text"
                    label={translations.vehicleBrandLabel}
                    value={vehicleBrand}
                    onChange={this.onInputChange}
                    keyValue="vehicleBrand"
                    error={hasError('vehicleBrand')}
                    doubleRow
                    required
                  />
                  <InputField
                    type="text"
                    label={translations.vehicleModelLabel}
                    value={vehicleModel}
                    onChange={this.onInputChange}
                    keyValue="vehicleModel"
                    error={hasError('vehicleModel')}
                    disabled={!vehicleBrand}
                    doubleRow
                    required
                  />
                </Row>
                <Row>
                  <InputField
                    type="text"
                    label={translations.vehicleVersionLabel}
                    value={vehicleVersion}
                    onChange={this.onInputChange}
                    keyValue="vehicleVersion"
                    disabled={!vehicleBrand}
                    doubleRow
                  />
                </Row>
              </Fragment>
            )}
          </Fragment>
          <div
            style={{ fontSize: '13px', color: '#444444', marginBottom: '30px' }}
          >
            {translations.asteriskFormRules}
          </div>
          <LineBreak />
          <PersonalDataConsent
            translations={translations}
            onCheckBoxClick={this.onInputChange}
            onRadioSelect={this.onRadioSelect}
            customisedServices={customisedServicesConsent}
            customisedServicesKey="customisedServicesConsent"
            customisedServicesError={hasError('customisedServicesConsent')}
            marketing={marketingConsent}
            marketingKey="marketingConsent"
            marketingError={hasError('marketingConsent')}
            phoneChecked={phoneConsent}
            phoneKey="phoneConsent"
            emailChecked={emailConsent}
            emailKey="emailConsent"
            smsChecked={smsConsent}
            smsKey="smsConsent"
            onColour={
              globalStyling.colours.primaryBrandColour &&
              globalStyling.colours.primaryBrandColour.value
            }
            linkColour={globalStyling.colours.primaryBrandColour}
            marketingLink={marketingLink}
            showUserCommunication={showUserCommunication}
            enquiryFormStyles={enquiryFormStyles}
            globalStyling={globalStyling}
          />
          <LineBreak />
          <Row>
            <a href={tacLink} target="_blank" rel="noopener noreferrer">
              <Paragraph
                styleOverride={() => `
                  margin-top: 0;
                  text-decoration: underline;
                  cursor: pointer;
                `}
              >
                {translations.tacLabel}
              </Paragraph>
            </a>
          </Row>
          <Row>
            <Paragraph
              tabIndex={0}
              styleOverride={() => `
                margin-top: 0;
                text-decoration: underline;
                cursor: pointer;
                &:focus {
                  color: #aaaaaa;
                  outline: none;
                }
              `}
              onClick={() =>
                requestPrivacyPolicy(vehicle && vehicle.retailerInformation)
              }
            >
              {translations.privPolLabel}
            </Paragraph>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <LabelledCheckBox
              onClick={() => {
                this.setState(() => ({ accepted: !accepted }));
              }}
              checked={accepted}
              label={translateFromTemplate(
                'tacAccept',
                {
                  DEALER:
                    vehicle &&
                    vehicle.retailerInformation &&
                    vehicle.retailerInformation.name,
                },
                translations,
              )}
              onColour="#DFB400"
              globalStyling={globalStyling}
            />
          </Row>
          <div style={{ width: '100%', display: 'block' }}>
            {hasError('accepted') && (
              <Error
                style={{ justifyContent: 'flex-start', marginBottom: '20px' }}
              >
                {translations.formValidationTermsConditionsAccept}
              </Error>
            )}
          </div>
          <ActionsContainer>
            <ActionButtonWrapper>
              <Button
                applyStyle="secondary"
                buttonStyle={
                  globalStyling.uiElements.secondaryButton &&
                  globalStyling.uiElements.secondaryButton.buttonStyle
                }
                text={translations.cancelActionButton}
                onClick={history.goBack}
              />
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                applyStyle="primary"
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
                text={translations.submitMessageActionButton}
                onClick={this.onSubmitMessage}
              />
            </ActionButtonWrapper>
          </ActionsContainer>
          {error && <Error>{translations.somethingWentWrong}</Error>}
        </EnquiryFormContainer>
      </Container>
    );
  }
}

// @flow

import React from 'react';
import { GlobalStyling } from 'cms-modules';
import PreviewCookiePolicy from './PreviewCookiePolicy';
import useEditCookiePolicy from './hooks/useEditCookiePolicy';

export default function EditFormContent() {
  const { pod, globalStyling } = useEditCookiePolicy();

  return (
    <>
      <GlobalStyling globalStyling={globalStyling} />
      <PreviewCookiePolicy globalStyling={globalStyling} config={pod.config} />
    </>
  );
}

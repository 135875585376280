// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SectionMenuItem } from 'cms-ui';
import uuid from 'uuid/v1';

import { actions as pageActions } from '../state/actions/page';
import { actions as contextActions } from '../state/actions/context';
import heading from '../pods/heading';
import vehicleTile from '../pods/vehicleTile';
import PAGE_TYPES from '../constants/pageTypes';

const MenuContent = styled.span`
  display: flex;
  align-items: center;
`;

type Props = {
  breakout: string,
};

const createBand = pods => ({
  config: {
    columns: pods.length,
    pods: [...pods],
  },
  name: 'Band',
  id: 'Band',
  instanceId: uuid(),
});

const headingBand = () =>
  createBand([
    {
      id: heading.id,
      config: {
        ...heading.defaultConfig,
        translations: {
          headingText: 'Search Results',
          bodyText: '',
        },
      },
      instanceId: uuid(),
    },
  ]);

const searchResultsBand = () =>
  createBand([
    {
      id: vehicleTile.id,
      config: {
        ...vehicleTile.defaultConfig,
      },
      instanceId: uuid(),
    },
  ]);

const EditSearchResults = ({ breakout }: Props) => {
  const dispatch = useDispatch();
  const page = useSelector(state =>
    Object.values(state.config.pages).find(
      p => p.pageType === PAGE_TYPES.SEARCH_RESULTS,
    ),
  );

  const bands = useSelector(state =>
    page.modules.map(bandId => state.config.bands[bandId]),
  );
  const pods = useSelector(state =>
    bands.map(b => b.config.pods.map(podId => state.config.pods[podId])).flat(),
  );
  const setPodContext = (pod, defaultSlice) => {
    dispatch(contextActions.setPreviousBreakoutContext({ name: breakout }));
    dispatch(
      contextActions.setPodContext({ id: pod.id, instanceId: pod.instanceId }),
    );
    dispatch(contextActions.setSliceContext(defaultSlice));
  };
  useEffect(() => {
    if (!bands.length) {
      dispatch(pageActions.addBandToPage(() => {}, page.id, headingBand()));
      dispatch(
        pageActions.addBandToPage(() => {}, page.id, searchResultsBand()),
      );
    }
  }, [dispatch, bands.length, page.id]);

  return (
    <>
      <SectionMenuItem
        withBorder
        onClick={() =>
          setPodContext(pods.find(p => p.id === 'Heading'), 'Content')
        }
      >
        <MenuContent>Page Title</MenuContent>
      </SectionMenuItem>
      <SectionMenuItem withBorder>
        <MenuContent>Available vehicles title</MenuContent>
      </SectionMenuItem>
      <SectionMenuItem
        withBorder
        onClick={() =>
          setPodContext(pods.find(p => p.id === 'SearchResults'), 'Content')
        }
      >
        <MenuContent>Vehicle tiles</MenuContent>
      </SectionMenuItem>
      <SectionMenuItem withBorder>
        <MenuContent>Filters</MenuContent>
      </SectionMenuItem>
    </>
  );
};

export default EditSearchResults;

// @flow
import React from 'react';
import styled from 'styled-components';

const Selector = styled.div`
  min-width: 84px;
  max-width: 84px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colours.secondary05};
  background: ${({ theme, selected }) =>
    selected ? theme.colours.action : theme.colours.secondary01};
  ${({ theme, selected }) =>
    selected ? `color: ${theme.colours.primary03}` : ''}
  padding: ${({ theme }) => theme.layout.halfPadding};
  cursor: ${({ draggable }) => (draggable ? 'grab' : 'not-allowed')};
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 84px;
  max-width: 84px;
  height: 100px;
  z-index: 1;
  background: #000000;
  opacity: 0.2;
`;

const Icon = styled.img`
  padding: ${({ theme }) =>
    `${theme.layout.halfPadding} 0 ${theme.layout.standardPadding}`};
`;

const Name = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizeSmall};
`;

const PodSelector = ({
  pod: { name, icon, available, selected, onClick, onDragStart, onDragEnd },
}) => (
  <Selector
    draggable={available}
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    onClick={onClick}
    selected={selected}
  >
    <Icon draggable="false" src={icon} />
    <Name>{name}</Name>
    {!available && <Overlay />}
  </Selector>
);

export default PodSelector;

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import ToggleIcon from './ToggleIcon';
import { localiseCurrency } from '../shared/localisation/numbers';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';
import { translateFinanceFromTemplate } from '../helpers/finance';
import type { CarModel, Font } from '../types';
import FinancePopup from './FinancePopup';
import {
  HeadingOne,
  HeadingThree,
  Paragraph,
  fontStyleOverride,
} from './Global';
import Spinner from './Spinner';
import media from '../theme';

const alignItems = {
  left: 'flext-start',
  center: 'center',
  right: 'flex-end',
};

const IconWrapper = styled.div`
  cursor: pointer;
`;

const TileItemContainer = styled.div`
  background-color: ${({ backgroundColour }) =>
    (backgroundColour && backgroundColour.value) || 'transparent'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ padding }) => (padding ? `${padding}` : '0 0 16px 16px')};
  width: 100%;
  ${media.max.large`
    padding: 4px 8px 4px;
  `};
  opacity: ${({ modelExistsOrSelected }) =>
    modelExistsOrSelected ? '1' : '0.5'};
`;

const TileContentWrapper = styled.div`
  ${({ borderColour }) =>
    borderColour && `border: 1px solid ${borderColour.value}`};
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour}`};
  padding: 12px;
  height: 100%;
  cursor: ${({ count }) => (count ? 'pointer' : 'cursor')};
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModelAndPrice = styled.div`
  align-self: left;
`;

const Image = styled.img`
  display: flex;
  align-self: center;
  width: 100%;
`;

export type ItemTheme = {
  height?: number,
  align: string,
};

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => `${marginTop || -40}px`};
  align-items: ${(props: ItemTheme) =>
    props.align ? alignItems[props.align] : alignItems.left};
`;

const FinanceText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 103%;
`;

const Gap = styled.div`
  margin-right: 16px;
`;

export type Theme = {
  colour: string,
  textTransform: string,
  characterSpacing: string,
  fontSize: string,
  fontFamily: string,
};

type Props = {
  translations: Translations,
  align: string,
  isSelected: boolean,
  onIconClick: Function,
  carModel: CarModel,
  titleFont: Font,
  featureFlags: Object,
  width: string,
  backgroundColour: Option,
  borderColour: Option,
  showStockNumber: boolean,
  showPrice: boolean,
  showSubtitle: boolean,
  locale: string,
  modelCardStyling: Object,
  globalStyling: Object,
  showFinancePopup: boolean,
  titleBottomMargin: Object,
};

type ToggleProps = {
  onClick: boolean => void,
  isSelected: boolean,
  icon: string,
  iconActive: string,
};

function Toggle({ onClick, isSelected, icon, iconActive }: ToggleProps) {
  return isSelected ? (
    <IconWrapper onClick={onClick}>
      <ToggleIcon icon={iconActive} />
    </IconWrapper>
  ) : (
    <IconWrapper onClick={onClick}>
      <ToggleIcon icon={icon} />
    </IconWrapper>
  );
}

const ToggleButton = styled(Toggle)`
  align-self: right;
`;

export default function ModelGridTile({
  isSelected,
  align,
  onIconClick,
  carModel,
  translations,
  titleFont,
  featureFlags,
  backgroundColour,
  borderColour,
  showStockNumber,
  showPrice,
  showSubtitle,
  modelCardStyling,
  locale,
  globalStyling,
  titleBottomMargin,
  showFinancePopup,
}: Props) {
  const {
    modelDisplay,
    minimumPrice,
    count,
    imageUrl,
    finance,
    financeLoaded,
    modelSubTitle,
    currency,
  } = carModel;

  const FinanceLoadingText = () => (
    <Fragment>
      <Spinner colour={globalStyling.colours.primaryBrandColour} size={12} />
      <Gap />
      {translations.financeLoadingText}
    </Fragment>
  );

  const renderFinance = () => {
    if (featureFlags.finance) {
      return finance ? (
        <FinanceText>
          <HeadingThree
            styleOverride={() => `
                margin: 0;
                font-size: 15px;
                color: #000000;
                ${fontStyleOverride(modelCardStyling.modelCardBottomContainer)}
              `}
          >
            {translateFinanceFromTemplate(
              translations.financeFrom,
              finance,
              locale,
            )}
          </HeadingThree>

          {showFinancePopup && (
            <FinancePopup
              translations={translations}
              data={finance}
              locale={locale}
            />
          )}
        </FinanceText>
      ) : (
        <FinanceText>
          <HeadingThree
            styleOverride={() => `
                margin: 0;
                font-size: 15px;
                color: #000000;
                line-height: 24px;
                display: flex;
                align-items: center;
              `}
          >
            {featureFlags.finance && !financeLoaded && count ? (
              <FinanceLoadingText />
            ) : (
              '\u00A0'
            )}
          </HeadingThree>
        </FinanceText>
      );
    }
    return null;
  };

  const priceFrom =
    showPrice && minimumPrice && currency
      ? translateFromTemplate(
          'searchCarFilterFromValue',
          {
            VALUE: localiseCurrency(minimumPrice, locale, currency, 0),
          },
          translations,
        )
      : '';
  const subtitle = showSubtitle ? '' : ''; // ToDo: add subtitle from car here
  const showSubheader = showSubtitle || showPrice;
  const subheader = `${subtitle}${
    showSubtitle && showPrice ? ', ' : ''
  }${priceFrom || '\u00A0'}`;

  const imageToDisplay = imageUrl || globalStyling.placeholderBrandImageUrl;
  const modelExistsOrSelected = count || isSelected;
  return (
    <TileItemContainer
      backgroundColour={backgroundColour}
      padding={modelCardStyling.tilePadding}
      modelExistsOrSelected={modelExistsOrSelected}
      onClick={modelExistsOrSelected ? onIconClick : () => {}}
    >
      <TileContentWrapper
        borderColour={borderColour}
        backgroundColour={
          modelCardStyling &&
          modelCardStyling.modelCardBGColour &&
          modelCardStyling.modelCardBGColour.value
        }
        count={modelExistsOrSelected}
      >
        <TopContainer>
          <ModelAndPrice>
            <HeadingOne
              styleOverride={() => `
                ${fontStyleOverride(titleFont)}
                margin-top: 0;
                ${titleBottomMargin && `margin-bottom:${titleBottomMargin}px`}
              `}
            >
              {modelDisplay}
            </HeadingOne>
            {modelSubTitle && (
              <Paragraph
                styleOverride={() => `
                    margin: 0;
                    font-size: 15px;
                    color: #000000;
                    ${fontStyleOverride(modelCardStyling.subtitleFont)}
                  `}
              >
                {modelSubTitle}
              </Paragraph>
            )}
            {showSubheader ? (
              <HeadingThree
                styleOverride={() => `
                  margin: 0;
                  font-size: 16px;
                  color: #333333;
                  ${fontStyleOverride(modelCardStyling.subtitleFont)}
                `}
              >
                {subheader}
              </HeadingThree>
            ) : null}
          </ModelAndPrice>
          {modelExistsOrSelected && (
            <ToggleButton
              isSelected={isSelected}
              icon={
                modelCardStyling.modelSelectIcon
                  ? modelCardStyling.modelSelectIcon.icon
                  : undefined
              }
              iconActive={
                modelCardStyling.modelSelectIcon &&
                modelCardStyling.modelSelectIcon.iconActive
              }
            />
          )}
        </TopContainer>
        {imageToDisplay && <Image src={imageToDisplay} />}
        <BottomContainer
          align={align}
          marginTop={
            modelCardStyling.modelCardBottomContainer &&
            modelCardStyling.modelCardBottomContainer.marginTop
          }
        >
          {showStockNumber && (
            <HeadingThree
              styleOverride={() => `
              margin: 0;
              font-size: 16px;
              ${fontStyleOverride(modelCardStyling.modelCardBottomContainer)}
            `}
            >
              {translateFromTemplate(
                count !== 1
                  ? 'searchCarFilterNumberAvailable'
                  : 'searchCarFilterNumberAvailableSingle',
                {
                  COUNT: count,
                },
                translations,
              )}
            </HeadingThree>
          )}
          {renderFinance()}
        </BottomContainer>
      </TileContentWrapper>
    </TileItemContainer>
  );
}

ModelGridTile.defaultProps = {
  onClick: () => {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  selected: false,
  showStockNumber: true,
  showPrice: true,
  showSubtitle: true,
};

ToggleButton.defaultProps = {
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1552917142/cms/global/icons/checkbox-disabled-default.svg',
  iconActive:
    'https://res.cloudinary.com/motortrak/image/upload/v1552917142/cms/global/icons/checkbox-enabled-default.svg',
};

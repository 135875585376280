// @flow
import React from 'react';
import styled from 'styled-components';
import Tick from '../JaguarIcons/Global/Tick';

type Props = {
  value: string,
  isDisabled?: boolean,
  isChecked?: boolean,
  handleClick: Function,
  id: string,
  isCheckedColour: string,
};

const Group = styled.label.withConfig({
  displayName: 'Group',
})`
  height: 55px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  color: #444;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
`;

const Input = styled.input.withConfig({
  displayName: 'Input',
})`
  display: none;

  &:checked + span {
    display: block;
  }
`;

const TickContainer = styled.span.withConfig({
  displayName: 'TickContainer',
})`
  margin: 5px 5px 0 0;
  order: 2;
  display: none;
`;

const Label = styled.span.withConfig({
  displayName: 'Label',
})`
  order: 1;
  color: ${({ isDisabled }) => (isDisabled ? '#ACACAC' : '#666')};
  font-size: 16px;
  white-space: nowrap;
`;

export default function CheckboxGroup({
  isDisabled,
  isChecked,
  value,
  handleClick,
  id,
  isCheckedColour,
}: Props) {
  return (
    <Group visible={!isDisabled}>
      <Input
        checked={isChecked}
        value={value}
        id={id}
        onChange={handleClick}
        disabled={isDisabled}
        type="checkbox"
      />
      <TickContainer isDisabled={isDisabled}>
        <Tick
          width="24px"
          height="24px"
          colour={isCheckedColour}
          fill={isCheckedColour}
        />
      </TickContainer>
      <Label isDisabled={isDisabled}>{value}</Label>
    </Group>
  );
}

CheckboxGroup.defaultProps = {
  isDisabled: false,
  isChecked: true,
};

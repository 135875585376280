// @flow
import React, { useCallback } from 'react';
import styled from 'styled-components';

import SingleSelectFilter from './SingleSelectFilter';
import UserLocation from '../../components/UserLocation';
import { HeadingThree } from '../../components/Global';

const ContentWrapper = styled.div`
  padding: 16px;
`;

const HeadingWrapper = styled.div`
  padding-bottom: 8px;
`;

const FilterWrapper = styled.div`
  padding-top: 16px;
`;

type Props = {
  location: string,
  selectedFilters: Object,
  availableFilterValues: Object,
  globalStyling: Object,
  updateFilters: filterObj => void,
  translations: Object,
  filterHeadingFont: Object,
  filtersStyling: Object,
  changeLocation: string => void,
  searchCountryCode: string,
};

export default function LocationDistanceFilter(props: Props) {
  const {
    updateSessionPreferences,
    location: { latitude, longitude },
  } = props;

  const handleLocationChange = useCallback(
    (lat, long) => {
      updateSessionPreferences('location', {
        latitude: lat,
        longitude: long,
      });
    },
    [updateSessionPreferences],
  );

  return (
    <div>
      <ContentWrapper>
        {props.translations.yourLocation && (
          <HeadingWrapper>
            <HeadingThree
              styleOverride={() => props.filtersStyling.filterHeadingFont}
            >
              {props.translations.yourLocation}
            </HeadingThree>
          </HeadingWrapper>
        )}
        <UserLocation
          direction={props.globalStyling.direction}
          placeholderText={props.translations.locationInputPlaceholder}
          iconUrl={props.filtersStyling.filterLocationIcon}
          browserLocationCallback={handleLocationChange}
          inputLocationCallback={handleLocationChange}
          options={{
            latitude,
            longitude,
          }}
        />
        <FilterWrapper>
          {props.translations.searchIn && (
            <HeadingWrapper>
              <HeadingThree
                styleOverride={() => props.filtersStyling.filterHeadingFont}
              >
                {props.translations.searchIn}
              </HeadingThree>
            </HeadingWrapper>
          )}
          <SingleSelectFilter
            filterKey="maxDistance"
            data={props.availableFilterValues.maxDistance}
            selectedData={props.selectedFilters.maxDistance}
            updateFilters={props.updateFilters}
            distanceToLabel={props.translations.distanceToLabel}
          />
        </FilterWrapper>
      </ContentWrapper>
    </div>
  );
}

export default function() {
  return {
    translations: {
      searchCarNumberAvailable: 'Available',
      searchHeader: 'SEARCH JAGUAR APPROVED CARS',
      searchHeaderLocationDistance: 'LOCATION & DISTANCE',
      searchHeaderPriceRange: 'PRICE RANGE',
      searchHeaderModelVariant: 'MODEL & VARIANT',
      searchHeaderEngineTransmission: 'ENGINE & TRANSMISSION',
      searchHeaderBodyStyle: 'BODY STYLE',
      searchHeaderAgeMileage: 'AGE & ODOMETER',
      searchHeaderColour: 'COLOUR',
      searchHeaderFeatures: 'FEATURES',
      mobileSearchMenuLabel: 'Filters - {COUNT} selections',
      mobileSearchMenuNoCountLabel: 'Filters',
      searchSubHeaderLocation: 'Location',
      searchSubHeaderDistance: 'Distance',
      searchLocationInputPlaceholder: 'Postcode/Town/Place',
      searchDistanceNationwide: 'Nationwide',
      checkboxAnySelection: 'Any',
      searchCarFilterFromValue: 'From {VALUE}',
      searchCarFilterNumberAvailable: '{COUNT} available',
      searchCarFilterNumberAvailableSingle: '1 available',
      searchSubHeaderPrice: 'Price',
      searchSubHeaderModel: 'Model',
      searchSubHeaderVariant: 'Variant',
      searchSubHeaderEngineSize: 'Engine Size',
      searchSubHeaderEngine: 'Engine Type',
      searchSubHeaderTransmission: 'Transmission',
      searchSubHeaderAge: 'Age',
      searchSubHeaderMileage: 'Odometer',
      searchSubHeaderExteriorColours: 'Exterior Colours',
      searchSubHeaderInteriorShades: 'Interior Colours',
      searchPriceByCash: 'cash price',
      searchPriceByMonth: 'monthly budget',
      searchSubHeaderPriceMonthly: 'Monthly',
      searchPriceByMonthText:
        'Search for vehicles that match your monthly budget. All initial results are calculated over a {DURATION}-month term with {DEPOSIT}€ deposit.',
      unitPrice: '€',
      unitAge: 'years',
      unitDistant: 'kilometres',
      searchHeaderMobile: 'SEARCH APPROVED',
      searchHeadingFilters: 'How would you like to filter your search',
      searchCTAReset: 'RESET',
      searchCTADone: 'DONE',
      searchCTASearch: 'SEARCH NOW',
      searchCTAResetSearch: 'RESET SEARCH',
      vehiclePrice: 'Vehicle Price',
      respresentativeExampleHeading:
        'Representative Hire Purchase Finance Example',
      noResultsText: 'No models found with that criteria',
      monthlyBudgetFailureText:
        'Sorry, we are unable to process monthly budgets at this time. Please try again later.',
      representativeExampleBody:
        'Representative example for {MODEL} subject to {DURATION} month agreement',
      financeFrom:
        'Finance from {FINANCEPRICE} a month for {DURATION} months / {APR}% APR',
      financePopupTitle: 'Finance Information',
      financePopupSubtitle: 'HP Finance Example',
      financeFinalPayment: 'Final Payment',
      financeMonthlyPayment: 'Monthly Payment',
      financeVehiclePrice: 'Vehicle Price',
      financeDeposit: 'Deposit',
      financeDurationOfAgreement: 'Duration of Agreement (Months)',
      financeNumberOfPayments: 'Number of Payments',
      financeInterestRate: 'Interest Rate',
      financeAPR: 'APR',
      financeTotalCredit: 'Total Credit',
      financeTotalInstalments: 'Total Instalments',
      financeTotalAmountOwed: 'Total Amount Owed',
      financePopupParagraph:
        'Estas condiciones se mantendrán en vigor durante un periodo mínimo de 15 días naturales desde la fecha de su impresión y se aplicaran previo análisis crediticio favorable de FCA Capital España, EFC, SAU. El PVP es orientativo y no incluye ofertas promocionales ocasionales ni otras revisiones de precios que obedezcan a otros factores. Acuda a su concesionario más cercano para que le facilite el PVP así como un plan de financiación personalizado.',
      financeLoadingText: 'Finance loading...',
      locationFilterLabel: 'Your Location',
    },
  };
}

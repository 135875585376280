// @flow
import React from 'react';
import styled from 'styled-components';

import CircleTick from './JaguarIcons/Global/CircleTick';
import { Button } from './Global';
import media from '../theme';

const Container = styled.div`
  width: 40%;
  height: auto;
  margin: 10% 30%;
  border: 1px solid #dedede;
  padding: 30px 0;

  ${media.max.large`
    width: 100%;
    border: none;
    margin: 0;
  `};
`;
const Image = styled.div`
  display: flex;
  justify-content: center;
`;
const Header = styled.div`
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
`;
const Content = styled.div`
  color: #444444;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  width: 50%;
  white-space: pre-line;
  margin: 0 auto;
`;
const ActionButton = styled.div`
  width: 30%;
  margin: 30px auto 0 auto;
  ${media.max.large`
    width: 40%;
    margin-left: 30%;
  `};
`;

const confirmationButtonStyle = () => `
  width: 100%;
`;

type Props = {
  translations: Object,
  circleTickColour: string,
  onButtonClick: () => void,
};

export default function ConfirmationWindow({
  translations,
  circleTickColour,
  onButtonClick,
}: Props) {
  return (
    <Container id="enquiry-submitted">
      <Image>
        <CircleTick width="4em" height="4em" colour={circleTickColour} />
      </Image>
      <Header>{translations.confirmationHeaderText}</Header>
      <Content>{translations.confirmationContentText}</Content>
      <ActionButton>
        <Button
          data-qa-hook="confirmation-button"
          onClick={onButtonClick}
          applyStyle="primary"
          buttonStyle="ButtonStyleBTT"
          text={translations.confirmationGoBackLabel}
          styleOverride={confirmationButtonStyle}
        />
      </ActionButton>
    </Container>
  );
}

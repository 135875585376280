// @flow

import React from 'react';
import styled from 'styled-components';
import { Banner } from 'cms-ui';

const Logo = styled.img`
  width: 10vh;
`;

const BrandFontTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeHeader};
  text-transform: capitalize;
  margin-left: 30px;
`;

type BrandedHeaderProps = {
  logo: string,
  title: string,
};

export default function BrandedHeader({ logo, title }: BrandedHeaderProps) {
  return (
    <Banner>
      <Logo src={logo} />
      <BrandFontTitle>{title}</BrandFontTitle>
    </Banner>
  );
}

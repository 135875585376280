// @flow

import React from 'react';
import styled from 'styled-components';
import ReactMde, { commands } from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../state/actions/blogs';

const listCommands = [
  {
    commands: [commands.headerCommand, commands.strikeThroughCommand],
  },
  {
    commands: [
      commands.orderedListCommand,
      commands.unorderedListCommand,
      commands.checkedListCommand,
      commands.imageCommand,
    ],
  },
];

const StyledReactMde = styled(ReactMde)`
  .markdown-text-editor .mde-tabs {
    display: none;
  }
`;

const Container = styled.div`
  font-size: 13px;
`;

export default function EditBlogContent() {
  const {
    breakout: {
      data: { id },
    },
  } = useSelector(state => state.context);
  const blog = useSelector(state => state.blogs.blogs.find(b => b.id === id));
  const dispatch = useDispatch();
  const {
    config: { bodyText },
  } = blog;
  const setBlogText = newBlogText =>
    dispatch(
      actions.updateBlogInContext({
        ...blog,
        config: {
          ...blog.config,
          bodyText: newBlogText,
        },
      }),
    );

  return (
    <Container>
      <StyledReactMde
        className="markdown-text-editor"
        onChange={setBlogText}
        value={bodyText}
        commands={listCommands}
      />
    </Container>
  );
}

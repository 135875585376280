import {
  Config,
  Slice,
  Section,
  Colour,
  Font,
  Pixels,
  Align,
  Toggle,
  URL,
  TextArea,
  Items,
  Link,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'Footer',
  { id: 'footer' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      'Top Tier',
      { icon: SectionIcons.TopTier },
      Pixels('Height', 'topHeight'),
      Align('Align', 'topTierAlign'),
    ),
    Section(
      'Middle Tier',
      { icon: SectionIcons.MidTier },
      Pixels('Height', 'midHeight'),
      Align('Align', 'midTierAlign'),
    ),
    Section(
      'Bottom Tier',
      { icon: SectionIcons.BottomTier },
      Pixels('Height', 'bottomHeight'),
      Align('Align', 'bottomTierAlign'),
    ),
    Section(
      'Global',
      {},
      Toggle('Sticky Footer?', 'stickyFooter'),
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Top Tier',
      { icon: SectionIcons.TopTier },
      Items('PagesAndLinks', 'topItems'),
    ),
    Section(
      'Middle Tier',
      { icon: SectionIcons.MidTier },
      URL('Facebook', 'urlFacebook'),
      URL('Twitter', 'urlTwitter'),
      URL('Instagram', 'urlInstagram'),
      URL('YouTube', 'urlYouTube'),
      URL('LinkedIn', 'urlLinkedIn'),
    ),
    Section(
      'Bottom Tier',
      { icon: SectionIcons.BottomTier },
      TextArea('Custom Text', 'bottomText'),
      Link('Link Text', 'bottomLinkText'),
      Toggle('Show image', 'showBottomImage'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Top Tier',
      { icon: SectionIcons.TopTier },
      Colour('Background', 'topBGColour'),
      Font('Link', 'topLinkFont'),
      Colour('Link Hover', 'topLinkHoverColour'),
      Pixels('Top Border Height', 'topTierBorderTopHeight', {
        max: 10,
      }),
      Colour('Top Border Colour', 'topTierBorderTopColour'),
    ),
    Section(
      'Middle Tier',
      { icon: SectionIcons.MidTier },
      Colour('Background', 'midBGColour'),
      Colour('Social Media Icons', 'socialIconsColour'),
      Pixels('Top Border Height', 'midTierBorderTopHeight', {
        max: 10,
      }),
      Colour('Top Border Colour', 'midTierBorderTopColour'),
    ),
    Section(
      'Bottom Tier',
      { icon: SectionIcons.BottomTier },
      Colour('Background', 'bottomBGColour'),
      Font('Text Colour', 'bottomFont'),
      Font('Bottom Link Text', 'bottomLinkFont'),
      Pixels('Top Border Height', 'bottomTierBorderTopHeight', {
        max: 10,
      }),
      Colour('Top Border Colour', 'bottomTierBorderTopColour'),
    ),
  ),
);

export default config;

import brick from './brick';
import text from './text';
import iconGrid from './iconGrid';
import image from './image';
import imageTitle from './imageTitle';
import textCta from './textCta';
import textImage from './textImage';
import heroCarousel from './heroCarousel';
import video from './video';
import heading from './heading';
import header from './header';
import footer from './footer';
import postSummary from './postSummary';
import cookiePolicy from './cookiePolicy';
import form from './form';
import vehicleTile from './vehicleTile';
import featuredRange from './featuredRange';
import dealerLocator from './dealerLocator';

export default [
  brick,
  text,
  iconGrid,
  image,
  imageTitle,
  textCta,
  textImage,
  video,
  heroCarousel,
  heading,
  header,
  footer,
  postSummary,
  cookiePolicy,
  form,
  vehicleTile,
  featuredRange,
  dealerLocator,
];

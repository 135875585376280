// @flow
import React from 'react';
import { Checkbox, CheckboxGroupSelectAll, InlineError } from '.';

type Props<Option> = {
  input: Object,
  options: Option[],
  hasSelectAll: Boolean,
  getLabelFromOption: Option => any,
  getValueFromOption: Option => any,
  meta: Object,
};

// This is a custom component for Redux Form Field component
// and is used by passing it to the Field's component prop
// input and meta are props passed from the Field component
export default function CheckboxGroup<Option>({
  input,
  options,
  hasSelectAll,
  getLabelFromOption,
  getValueFromOption,
  meta: { error, dirty },
}: Props<Option>) {
  const { value: gV, name } = input;

  const groupValue = gV || [];

  const changeOptionChecked = option => checked =>
    input.onChange(
      checked
        ? [...groupValue, option]
        : groupValue.filter(
            o => getValueFromOption(o) !== getValueFromOption(option),
          ),
    );

  return (
    <div>
      {hasSelectAll && (
        <CheckboxGroupSelectAll input={input} options={options} />
      )}
      {options.map(option => {
        const optionValue = getValueFromOption(option);
        return (
          <Checkbox
            key={optionValue}
            name={`${name}[${optionValue}]`}
            checked={groupValue.some(
              v => getValueFromOption(v) === optionValue,
            )}
            onChange={changeOptionChecked(option)}
            label={getLabelFromOption(option)}
          />
        );
      })}
      {dirty && error && (
        <InlineError error={dirty && error}>{error}</InlineError>
      )}
    </div>
  );
}

CheckboxGroup.defaultProps = {
  options: [],
  getLabelFromOption: i => i,
  getValueFromOption: i => i,
};

// @flow

import React from 'react';
import {
  TextInputEditor,
  RadioEditor,
  FormButtonEditor,
  EmailEditor,
} from './Editors';

const editors = {
  TextInput: TextInputEditor,
  Radio: RadioEditor,
  FormButton: FormButtonEditor,
  Email: EmailEditor,
};

export const FormFieldEditor = ({
  field,
  fields,
  updatePodField,
  translations,
  radioInput,
  setRadioInput,
}: props) => {
  const Editor = editors[field.fieldType];
  return (
    <Editor
      {...{
        field,
        fields,
        updatePodField,
        translations,
        radioInput,
        setRadioInput,
      }}
    />
  );
};

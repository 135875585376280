// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import { helpers } from '../../shared/filters';
import { Paragraph, fontStyleOverride } from '../../components/Global';

const ItemRow = styled.div`
  height: auto;
  cursor: pointer;
  padding: 0 16px 0 32px;
  color: ${({ colour }) => colour};
  border-bottom: 1px solid #d5d5d5;
  background-color: #ffffff;
  &:last-child {
    border-bottom: none;
  }
  display: flex;
  align-items: center;
`;

const Selection = styled(ItemRow)`
  justify-content: space-between;
  ${({ showHover }) => `${!showHover && 'cursor: default'}`};
  ${({ showHover }) =>
    showHover &&
    `&:hover {
    background-color: #f8f8f8;
  }`};
`;

type Props = {
  filterKey: string,
  data: any[],
  selectedData: any[],
  updateFilters: filterObj => void,
  globalStyling: Object,
  applyOrdering?: () => void,
  stylingConfig: Object,
  viewAllLabel: string,
};

const IconWrapper = styled.img`
  width: 20px;
  height: 20px;
`;

export default class MultiSelectFilter extends Component<Props, *> {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };
  }

  onViewAllClick = () => {
    this.setState(() => ({ showAll: true }));
  };

  render = () => {
    const {
      filterKey,
      data,
      selectedData,
      updateFilters,
      globalStyling: {
        direction,
        colours: { primaryBrandColour },
      },
      applyOrdering,
      stylingConfig,
      viewAllLabel,
      getDisabledValue,
    } = this.props;
    const { showAll } = this.state;
    const filterContent = showAll
      ? data.sort(applyOrdering)
      : data.sort(applyOrdering).slice(0, 7);
    return (
      <Fragment>
        {filterContent.map(d => {
          const itemSelected =
            selectedData && selectedData.some(sd => sd === d.value.toString());
          return (
            <Selection
              key={d.value}
              showHover={!getDisabledValue(d)}
              onClick={() =>
                (itemSelected || !getDisabledValue(d)) &&
                updateFilters([
                  {
                    key: filterKey,
                    value: helpers.newArray(
                      selectedData || [],
                      d.value.toString(),
                    ),
                  },
                ])
              }
            >
              <Paragraph
                styleOverride={() => `
                  word-wrap: break-word;
                  margin: 8px 0;
                  ${fontStyleOverride({
                    ...stylingConfig.filterMenuFont,
                    ...(getDisabledValue(d) && {
                      colour: stylingConfig.filterMenuDisabledColour || {
                        value: '#989898',
                      },
                    }),
                  })}
                `}
              >
                &#x200E;
                {direction === 'rtl' ? (
                  <span dir="ltr">
                    ({d.count}) {d.display}
                  </span>
                ) : (
                  `${d.display} (${d.count})`
                )}
              </Paragraph>
              {itemSelected && (
                <IconWrapper src={stylingConfig.filterTickIcon} alt="" />
              )}
            </Selection>
          );
        })}
        {!showAll && data.length >= 8 && (
          <ItemRow
            colour={primaryBrandColour.value}
            onClick={this.onViewAllClick}
          >
            <Paragraph
              styleOverride={() => `
              margin: 8px 0;
              ${fontStyleOverride({
                ...stylingConfig.filterMenuFont,
                ...(stylingConfig.filterMenuViewAllColour && {
                  colour: stylingConfig.filterMenuViewAllColour,
                }),
              })}
          `}
            >
              {viewAllLabel}
            </Paragraph>
          </ItemRow>
        )}
      </Fragment>
    );
  };
}

MultiSelectFilter.defaultProps = {
  applyOrdering: (a, b) => {
    if (a.display < b.display) {
      return -1;
    }
    if (a.display > b.display) {
      return 1;
    }
    // a must be equal to b
    return 0;
  },
};

// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';
import { HeadingTwo, Paragraph } from '../Global';

const Box = styled.div.withConfig({
  displayName: 'AccessoryBox',
})`
  width: 100%;
  margin: auto;
  background-color: #252525;
  display: block;
  color: #fff;
  ${media.min.large`
    width: 33.3%;
    display: table-cell;
  `};
`;
const Image = styled.div`
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${props => `${props.url && `url(${props.url})`}`};
  width: 100%;
  ${media.min.small`
    min-height: 200px;
  `};
  ${media.min.medium`
    min-height: 300px;
  `};
  ${media.min.large`
    min-height: 250px;
  `};
`;
const Banner = styled.div`
  min-height: 80px;
  padding: 30px;
`;

type Props = {
  accessory: Object,
  translations: Object,
};
export default function AccessoryBox({ accessory, translations }: Props) {
  return (
    <Box>
      <Image url={accessory.imageUrl} />
      <Banner>
        <HeadingTwo
          styleOverride={() => `
            font-size: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: #ffffff;
          `}
        >
          {translations.title}
        </HeadingTwo>
        <Paragraph
          styleOverride={() => `
            font-size: 15px;
            color: #ffffff;
          `}
        >
          {translations.description}
        </Paragraph>
      </Banner>
    </Box>
  );
}

// @flow
import type { State as SocialShare } from '../types/socialShare';

const UpdateSocialShareOptions: 'UPDATE SOCIAL SHARE OPTIONS' =
  'UPDATE SOCIAL SHARE OPTIONS';
export type UpdateSocialShareOptionsAction = {
  type: typeof UpdateSocialShareOptions,
  payload: SocialShare,
};
const updateSocialShareOptions = (
  socialShare: SocialShare,
): UpdateSocialShareOptionsAction => ({
  type: UpdateSocialShareOptions,
  payload: socialShare,
});

const SaveSocialShareOptions: 'SAVE SOCIAL SHARE OPTIONS' =
  'SAVE SOCIAL SHARE OPTIONS';
export type SaveSocialShareOptionsAction = {
  type: typeof SaveSocialShareOptions,
  payload: string,
};
const saveSocialShareOptions = (
  siteId: string,
): SaveSocialShareOptionsAction => ({
  type: SaveSocialShareOptions,
  payload: siteId,
});

const SaveSocialShareOptionsSuccess: 'SAVE SOCIAL SHARE OPTIONS SUCCESS' =
  'SAVE SOCIAL SHARE OPTIONS SUCCESS';
export type SaveSocialShareOptionsSuccessAction = {
  type: typeof SaveSocialShareOptionsSuccess,
};
const saveSocialShareOptionsSuccess = (): SaveSocialShareOptionsSuccessAction => ({
  type: SaveSocialShareOptionsSuccess,
});

const SaveSocialShareOptionsFailure: 'SAVE SOCIAL SHARE OPTIONS FAILURE' =
  'SAVE SOCIAL SHARE OPTIONS FAILURE';
export type SaveSocialShareOptionsFailureAction = {
  type: typeof SaveSocialShareOptionsFailure,
};
const saveSocialShareOptionsFailure = (): SaveSocialShareOptionsFailureAction => ({
  type: SaveSocialShareOptionsFailure,
});

export const constants = {
  UpdateSocialShareOptions,
  SaveSocialShareOptions,
  SaveSocialShareOptionsSuccess,
  SaveSocialShareOptionsFailure,
};

export const actions = {
  updateSocialShareOptions,
  saveSocialShareOptions,
  saveSocialShareOptionsSuccess,
  saveSocialShareOptionsFailure,
};

export type Action =
  | UpdateSocialShareOptionsAction
  | SaveSocialShareOptionsAction
  | SaveSocialShareOptionsSuccessAction
  | SaveSocialShareOptionsFailureAction;

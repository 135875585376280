// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

type Props = {
  children: Node,
  hasBorder?: boolean,
};

const StyledSectionTitle = styled.h4`
  width: 100%;
  display: flex;
  height: 55px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  color: #444;
  padding: 10px 0;
  justify-content: flex-start;
  border-bottom: ${({ hasBorder }) =>
    hasBorder ? '1px solid #d8d8d8' : 'none'};
`;

export default function SectionTitle(props: Props) {
  return (
    <StyledSectionTitle hasBorder={props.hasBorder}>
      {props.children}
    </StyledSectionTitle>
  );
}

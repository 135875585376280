// @flow
import type { Site, CloneModeValue, CloneMode } from '../reducers/site';
import type { Error } from '../types/error';

const CloneSite: 'CLONE SITE FROM SELECTED' = 'CLONE SITE FROM SELECTED';
export type CloneSiteAction = {
  type: typeof CloneSite,
  payload: { site: Site, siteToCloneFrom: Site, mode: CloneMode },
};
const cloneSite = (
  site: Site,
  siteToCloneFrom: Site,
  mode: CloneMode,
): CloneSiteAction => ({
  type: CloneSite,
  payload: { site, siteToCloneFrom, mode },
});

const CloneSiteFailure: 'CLONE SITE FAILURE' = 'CLONE SITE FAILURE';
export type CloneSiteFailureAction = {
  type: typeof CloneSiteFailure,
  payload: Error,
};
const cloneSiteFailure = (error: Error): CloneSiteFailureAction => ({
  type: CloneSiteFailure,
  payload: error,
});

const LoadSite: 'LOAD SITE' = 'LOAD SITE';
export type LoadSiteAction = { type: typeof LoadSite, payload: string };
const loadSite = (siteId: string): LoadSiteAction => ({
  type: LoadSite,
  payload: siteId,
});

const LoadSiteFailure: 'SITE LOAD FAILURE' = 'SITE LOAD FAILURE';
export type LoadSiteFailureAction = {
  type: typeof LoadSiteFailure,
  payload: Error,
};
const loadSiteFailure = (error: Error): LoadSiteFailureAction => ({
  type: LoadSiteFailure,
  payload: error,
});

const LoadSiteSuccess: 'SITE LOAD SUCCESS' = 'SITE LOAD SUCCESS';
export type LoadSiteSuccessAction = {
  type: typeof LoadSiteSuccess,
  payload: Site,
};
const loadSiteSuccess = (site: Site): LoadSiteSuccessAction => ({
  type: LoadSiteSuccess,
  payload: site,
});

const LoadSites: 'LOAD SITE LIST' = 'LOAD SITE LIST';
export type LoadSitesAction = { type: typeof LoadSites };
const loadSites = (): LoadSitesAction => ({
  type: LoadSites,
});

const LoadCloneableSitesFailure: 'LOAD CLONEABLE SITE LIST FAILURE' =
  'LOAD CLONEABLE SITE LIST FAILURE';
export type LoadCloneableSitesFailureAction = {
  type: typeof LoadCloneableSitesFailure,
  payload: Error,
};
const loadCloneableSitesFailure = (
  error: Error,
): LoadCloneableSitesFailureAction => ({
  type: LoadCloneableSitesFailure,
  payload: error,
});

const LoadCloneableSitesSuccess: 'LOAD CLONEABLE SITE LIST SUCCESS' =
  'LOAD CLONEABLE SITE LIST SUCCESS';
export type LoadCloneableSitesSuccessAction = {
  type: typeof LoadCloneableSitesSuccess,
  payload: Site[],
};
const loadCloneableSitesSuccess = (
  sites: Site[],
): LoadCloneableSitesSuccessAction => ({
  type: LoadCloneableSitesSuccess,
  payload: sites,
});

const LoadCloneableSites: 'LOAD CLONEABLE SITE LIST' =
  'LOAD CLONEABLE SITE LIST';
export type LoadCloneableSitesAction = { type: typeof LoadCloneableSites };
const loadCloneableSites = (siteId: string): LoadCloneableSitesAction => ({
  type: LoadCloneableSites,
  payload: siteId,
});

const LoadSitesFailure: 'LOAD SITE LIST FAILURE' = 'LOAD SITE LIST FAILURE';
export type LoadSitesFailureAction = {
  type: typeof LoadSitesFailure,
  payload: Error,
};
const loadSitesFailure = (error: Error): LoadSitesFailureAction => ({
  type: LoadSitesFailure,
  payload: error,
});

const LoadSitesSuccess: 'LOAD SITE LIST SUCCESS' = 'LOAD SITE LIST SUCCESS';
export type LoadSitesSuccessAction = {
  type: typeof LoadSitesSuccess,
  payload: Site[],
};
const loadSitesSuccess = (sites: Site[]): LoadSitesSuccessAction => ({
  type: LoadSitesSuccess,
  payload: sites,
});

const SaveSite: 'SAVE SITE' = 'SAVE SITE';
export type SaveSiteAction = { type: typeof SaveSite, payload: Site };
const saveSite = (site: Site): SaveSiteAction => ({
  type: SaveSite,
  payload: site,
});

const SaveSiteFailure: 'SAVE SITE FAILURE' = 'SAVE SITE FAILURE';
export type SaveSiteFailureAction = {
  type: typeof SaveSiteFailure,
  payload: Error,
};
const saveSiteFailure = (payload: Error): SaveSiteFailureAction => ({
  type: SaveSiteFailure,
  payload,
});

const SaveSiteSuccess: 'SAVE SITE  SUCCESS' = 'SAVE SITE  SUCCESS';
export type SaveSiteSuccessAction = {
  type: typeof SaveSiteSuccess,
  payload: Site,
};
const saveSiteSuccess = (site: Site): SaveSiteSuccessAction => ({
  type: SaveSiteSuccess,
  payload: site,
});

const SelectSite: 'SET SELECTED SITE TO CLONE FROM' =
  'SET SELECTED SITE TO CLONE FROM';
export type SelectSiteAction = { type: typeof SelectSite, payload: Site };
const selectSite = (site: Site): SelectSiteAction => ({
  type: SelectSite,
  payload: site,
});

const SkipCloning: 'SKIP CLONING' = 'SKIP CLONING';
export type SkipCloningAction = { type: typeof SkipCloning, payload: string };
const skipCloning = (siteId: string): SkipCloningAction => ({
  type: SkipCloning,
  payload: siteId,
});

const SkipCloningFailure: 'SKIP CLONING FAILURE' = 'SKIP CLONING FAILURE';
export type SkipCloningFailureAction = {
  type: typeof SkipCloningFailure,
  payload: Error,
};
const skipCloningFailure = (payload: Error): SkipCloningFailureAction => ({
  type: SkipCloningFailure,
  payload,
});

const SetCloneMode: 'SET CLONE MODE' = 'SET CLONE MODE';
export type SetCloneModeAction = {
  type: typeof SetCloneMode,
  payload: CloneModeValue,
};
const setCloneMode = (mode: CloneModeValue): SetCloneModeAction => ({
  type: SetCloneMode,
  payload: mode,
});

export const actions = {
  cloneSite,
  cloneSiteFailure,
  loadSite,
  loadSiteFailure,
  loadSites,
  loadSitesFailure,
  loadSitesSuccess,
  loadSiteSuccess,
  loadCloneableSites,
  loadCloneableSitesFailure,
  loadCloneableSitesSuccess,
  saveSite,
  saveSiteFailure,
  saveSiteSuccess,
  selectSite,
  skipCloning,
  skipCloningFailure,
  setCloneMode,
};

export const constants = {
  CloneSite,
  CloneSiteFailure,
  LoadSite,
  LoadSiteFailure,
  LoadSites,
  LoadSitesFailure,
  LoadSitesSuccess,
  LoadCloneableSites,
  LoadCloneableSitesFailure,
  LoadCloneableSitesSuccess,
  LoadSiteSuccess,
  SaveSite,
  SaveSiteFailure,
  SaveSiteSuccess,
  SelectSite,
  SkipCloning,
  SkipCloningFailure,
  SetCloneMode,
};

export type Action =
  | CloneSiteAction
  | CloneSiteFailureAction
  | LoadSiteAction
  | LoadSiteFailureAction
  | LoadSitesAction
  | LoadSitesFailureAction
  | LoadSitesSuccessAction
  | LoadSiteSuccessAction
  | SaveSiteAction
  | SaveSiteFailureAction
  | SaveSiteSuccessAction
  | SelectSiteAction
  | SkipCloningAction
  | SkipCloningFailureAction
  | SetCloneModeAction;

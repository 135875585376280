// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import IoChevronDown from 'react-icons/lib/io/chevron-down';
import { HeadingTwo, fontStyleOverride } from './Global';
import type { Font } from '../types';

export type Theme = {
  link: {
    fontFamily: string,
    fontSize: string,
    characterSpacing: string,
    textTransform: string,
    colours: {
      primary: string,
      hover?: string,
    },
  },
  dropdown: {
    fontFamily: string,
    fontSize: string,
    characterSpacing: string,
    textTransform: string,
    colours: {
      primary: string,
      hover?: string,
      background?: string,
    },
  },
};

type Props<Option> = {
  options: Option[],
  initialSelectedValue?: Option,
  placeholder?: ?string,
  theme: Theme,
  onOptionClick: Function,
  getLabelFromOption: Function,
  getValueFromOption: Function,
  getDisplayFromOption: Function,
  width?: ?number,
  height?: ?number,
  position?: string,
  layout: 'above' | 'below',
  selectedValue: string,
  direction: string,
};

const themeFromProps = ({
  font,
  hoverColour,
  backgroundColour,
  backgroundHoverColour,
  iconColour,
}: {
  font: Font,
  hoverColour: { value: string, label: string },
  backgroundColour?: { value: string, label: string },
  backgroundHoverColour?: { value: string, label: string },
}) => ({
  fontFamily: font.typeface && font.typeface.label,
  fontSize: `${font.fontSize}px`,
  characterSpacing: `${font.kerning}px`,
  textTransform: font.transform,
  colours: {
    primary: font && font.colour && font.colour.value,
    hover: hoverColour && hoverColour.value,
    background: backgroundColour && backgroundColour.value,
    backgroundHoverColour: backgroundHoverColour && backgroundHoverColour.value,
    iconColour: iconColour && iconColour.value,
  },
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
`;

const OptionsContainer = styled.div`
  overflow: hidden;
  border-top: none;
`;

const StyledOptions = styled.div.withConfig({
  displayName: 'StyledOptions',
})`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 12;
  font: ${({ theme }: { theme: Theme }) =>
    `${theme.link.fontSize} ${theme.link.fontFamily}`};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${props =>
    props.layout === 'below' &&
    css`
      position: ${({ position }) => position || 'absolute'};
    `} ${props =>
    props.layout === 'above' &&
    css`
      position: absolute;
      top: -1px;
      margin-top: ${({ numberOptions }) => -numberOptions * 44}px;
      background: #ffffff;
    `} & h2 {
    border: none;
  }
`;

const LabelContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
  cursor: pointer;
  ${({ height }) => (height ? `height: ${height}px` : 'height: unset')};
  ${({ hover }) => `${hover && `background: ${hover}`}`};
`;

const Icon = styled(IoChevronDown)`
  ${({ width }) => `${width && `width: ${width}px`}`};
  ${({ height }) => `${height && `height: ${height}px`}`};
  ${({ colour }) => colour && `color: ${colour}`};
  cursor: pointer;
`;

const isIE = /* @cc_on!@ */ false || !!document.documentMode;
export default class Dropdown extends React.Component<Props<*>, *> {
  static defaultProps = {
    options: [],
    onOptionClick: () => {},
    placeholder: '',
    initialSelectedValue: null,
    height: 30,
    layout: 'below',
  };

  constructor(props: Props<*>) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onDropdownBlur = () => this.setState(() => ({ open: false }));

  selectedOption = option =>
    this.props.options.find(
      o =>
        this.props.getValueFromOption(o) ===
        this.props.getValueFromOption(option),
    );

  placeHolderOrLabelFromOption = option =>
    option
      ? (this.props.getDisplayFromOption || this.props.getLabelFromOption)(
          this.selectedOption(option),
        )
      : this.props.placeholder;

  toggle = () => this.setState(prevState => ({ open: !prevState.open }));

  render() {
    const {
      options,
      onOptionClick,
      getLabelFromOption,
      getValueFromOption,
      theme: { link, dropdown },
      width,
      height,
      position,
      layout,
      initialSelectedValue,
      selectedValue,
      direction,
    } = this.props;
    const theme = {
      link: themeFromProps(link),
      dropdown: themeFromProps(dropdown),
    };
    const option = this.selectedOption({ value: selectedValue });
    return (
      <Container tabIndex="0" onBlur={!isIE ? this.onDropdownBlur : () => {}}>
        <LabelContainer
          onClick={this.toggle}
          height={height}
          hover={link.hoverColour}
        >
          <HeadingTwo
            styleOverride={() =>
              `letter-spacing: 1px;
              ${fontStyleOverride(link.font)}
              padding: 0 8px;
              width: auto;
              display: flex;
              align-items: center;
              margin: 0;
            `
            }
          >
            {this.placeHolderOrLabelFromOption(option || initialSelectedValue)}
          </HeadingTwo>
          <Icon
            width={
              dropdown &&
              dropdown.dropdownIconSize &&
              dropdown.dropdownIconSize.width
            }
            height={
              dropdown &&
              dropdown.dropdownIconSize &&
              dropdown.dropdownIconSize.height
            }
            colour={
              dropdown && dropdown.iconColour && dropdown.iconColour.value
            }
          />
        </LabelContainer>
        <OptionsContainer theme={theme}>
          <StyledOptions
            position={position}
            theme={theme}
            visible={this.state.open}
            width={width}
            layout={layout}
            numberOptions={options.length}
          >
            {options.map(op => (
              <HeadingTwo
                onClick={() => {
                  if (op.value !== this.props.selectedValue) {
                    onOptionClick(op);
                    this.setState(prevState => ({ open: !prevState.open }));
                  }
                }}
                key={getValueFromOption(op)}
                styleOverride={() => `
                    ${fontStyleOverride(dropdown.font)}
                    ${dropdown.backgroundColour &&
                      `background: ${dropdown.backgroundColour.value}`};
                    padding: 15px 10px;
                    border-bottom: 1px solid #ccc;
                    white-space: nowrap;
                    text-align:  ${direction === 'rtl' ? 'right' : 'left'};
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: background-color 0.5s ease;
                    &:hover {
                      ${dropdown.hoverColour &&
                        `background: ${dropdown.hoverColour.value}`};
                    }`}
              >
                {getLabelFromOption(op)}
              </HeadingTwo>
            ))}
          </StyledOptions>
        </OptionsContainer>
      </Container>
    );
  }
}

// @flow

import React from 'react';
import styled from 'styled-components';

import { TextAlignment } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Left = styled.div`
  font-size: 13px;
`;

const Right = styled.div``;

export default function Align({
  name,
  ids,
  values,
  onChange,
  ...props
}: FieldProps) {
  const { id } = ids;
  const { id: value } = values;
  return (
    <Container>
      <Left>
        <strong>{name}</strong>
      </Left>
      <Right>
        <TextAlignment
          name={id}
          value={value}
          open={false}
          onChange={val => onChange(id, val)}
          inline
          {...props}
        />
      </Right>
    </Container>
  );
}

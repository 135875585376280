export const moduleConstants = (modulePrefix, keys) =>
  keys.reduce(
    (constants, key) => ({ ...constants, [key]: `${modulePrefix}_${key}` }),
    {},
  );

export const moduleAction = (type, mod) => (page, instance) => ({
  forModuleInstance: true,
  type,
  module: { id: mod, page, instance },
});

export const moduleActionWithArgs = (type, mod, callback) => (
  page,
  instance,
  ...rest
) => ({
  payload: callback(...rest),
  ...moduleAction(type, mod)(page, instance),
});

export const mountComponent = moduleConfig => ({
  forModuleInstance: true,
  type: 'CMS_COMPONENTS_MOUNT',
  module: moduleConfig,
});

// @flow

import React from 'react';
import styled from 'styled-components';

import { TextArea as TextAreaComponent } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

export default ({
  values: { id: value },
  ids: { id },
  name,
  onChange,
  rows = 10,
}: FieldProps) => (
  <Container>
    <TextLabel>{name}</TextLabel>
    <TextAreaComponent
      value={value}
      onChange={val => onChange(id, val)}
      rows={rows}
    />
  </Container>
);

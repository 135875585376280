import { config } from 'cms-modules';

const {
  Colour,
  Config,
  Font,
  Slice,
  Section,
  EditorLabel,
  TextArea,
  ListSlice,
  Link,
  HorizontalVerticalAlign,
  Number,
  MediaPicker,
} = config;

const hintText = media =>
  media.componentType === 'image' && 'Image should be at least 1200 x 600px';

const editor = Config(
  'HeroCarousel',
  { id: 'HeroCarousel' },
  ListSlice(
    'Content',
    {
      id: 'items',
      max: 3,
      sectionLabel: 'Slide',
      buttonLabel: 'Add new slide',
    },
    EditorLabel('Title', 'listTitle'),
    MediaPicker(
      'Media',
      {
        media: 'media',
        bodyText: 'bodyText',
        headingText: 'headingText',
        accessibilityText: 'videoTranscript',
      },
      {
        mediaTypes: ['image', 'video', 'youtube'],
        hintText,
      },
    ),
    TextArea('Heading', 'headlineText', { rows: 3 }),
    TextArea('Body Text', 'bodyText'),
    HorizontalVerticalAlign('Alignment', {
      horizontalAlignment: 'horizontalAlignment',
      verticalAlignment: 'verticalAlignment',
    }),
    Link('Button', { button: 'button', text: 'button' }),
  ),
  Slice(
    'Styles',
    { id: 'styles' },
    Section(
      'Fonts',
      {},
      Font('Heading', 'headlineFont'),
      Font('Paragraph', 'bodyFont'),
      Colour('Text background', 'backgroundColour'),
    ),
    Section(
      'Slide Transition',
      {},
      Number('Slide transition speed', 'transitionTime', {
        inputLabel: 'sec',
        min: 1,
      }),
    ),
  ),
);

export default editor;

// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import { useDispatch } from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'cms-ui';

import { actions as siteBuilderActions } from '../state/actions/siteBuilder';

const CroppingContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - 960px);
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  padding: 10px 60px;
  margin: 0 45px;
`;
const Cropper = styled(ReactCrop)`
  margin: 10px;
  width: ${({ width }) => width}px;
  max-width: calc(100% - 20px) !important;
`;

type Image = {
  meta: { width: number, height: number },
  value: string,
};

type Props = {
  image: Image,
  closeCropper: () => void,
};

export default ({ image, closeCropper }: Props) => {
  const [croppingData, setCroppingData] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [scaling, setScaling] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch();

  const getScaleOfRenderedImage = element => {
    if (element) {
      const x = image.meta.width / element.componentRef.clientWidth;
      const y = image.meta.height / element.componentRef.clientHeight;
      if (x !== scaling.x || y !== scaling.y) {
        setScaling({ x, y });
      }
    }
  };

  const applyScale = (item, scale) => Math.round(item * scale);

  return (
    <>
      <CroppingContainer>
        <Cropper
          ref={getScaleOfRenderedImage}
          width={image.meta.width}
          src={image.value}
          crop={croppingData}
          onChange={newCrop => setCroppingData(newCrop)}
        />
        <ButtonContainer>
          <StyledButton secondary onClick={closeCropper}>
            Cancel
          </StyledButton>
          <StyledButton
            primary
            onClick={() => {
              const { width, height, x, y } = croppingData;
              dispatch(
                siteBuilderActions.uploadMediaContent(
                  image.value,
                  {
                    width: applyScale(width, scaling.x),
                    height: applyScale(height, scaling.y),
                    x: applyScale(x, scaling.x),
                    y: applyScale(y, scaling.y),
                    crop: 'crop',
                  },
                  'image',
                ),
              );
              closeCropper();
            }}
          >
            Crop
          </StyledButton>
        </ButtonContainer>
      </CroppingContainer>
    </>
  );
};

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { google } from '../../utilities/googleObject';

import { actions as sessionActions } from '../../shared/sessionPreferences';
import { actions as filterActions } from '../../shared/filters';
import { actions as routerActions } from '../../actions/router';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

export function useVehicleRetailer(retailerLoc, translations) {
  const dispatch = useDispatch();
  const [distanceFromRetailer, setDistanceFromRetailer] = useState(null);
  const [position, setPosition] = useState(null);

  const viewRetailerStock = retailer => {
    dispatch(
      sessionActions.updateSessionPreferences(
        'resetSearchResultsPageNumber',
        true,
      ),
    );
    dispatch(filterActions.resetFilters());
    dispatch(
      routerActions.navigateWithQuery('/searchresults', {
        retailerId: retailer.id,
      }),
    );
  };

  const makeAnEnquiry = vehicleId => {
    dispatch(routerActions.navigate(`/${vehicleId}/enquiry`));
  };

  useEffect(() => {
    if (!position) {
      return;
    }

    const { latitude: userLat, longitude: userLong } = position.coords;
    const [lat, long] = retailerLoc.coordinates;
    const distanceService = new google.maps.DistanceMatrixService();

    distanceService.getDistanceMatrix(
      {
        origins: [new google.maps.LatLng(userLat, userLong)],
        destinations: [new google.maps.LatLng(lat, long)],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          const distanceText =
            response.rows[0].elements[0].distance &&
            response.rows[0].elements[0].distance.text;
          if (distanceText) {
            setDistanceFromRetailer(
              translateFromTemplate(
                'vdpRetailerDistanceSuccess',
                {
                  DISTANCE: distanceText,
                },
                translations,
              ),
            );
          } else {
            setDistanceFromRetailer(translations.vdpRetailerDistanceFailed);
          }
        } else {
          setDistanceFromRetailer(null);
        }
      },
    );
  }, [position]);

  return {
    distanceFromRetailer,
    setDistanceFromRetailer,
    setPosition,
    viewRetailerStock,
    makeAnEnquiry,
  };
}

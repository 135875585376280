import React from 'react';

import { Dropdown } from 'cms-ui';

const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const isValidNewOption = ({ label }) => {
  return urlRegex.test(label);
};

const onNewOption = ({ label }) => {
  return {
    url: label,
    label,
    value: label,
    newLink: true,
  };
};

const input = (value, actions, siteId, onChange) => ({
  value,
  onChange: event =>
    event.newLink
      ? actions.saveAndSetExternalLink(
          { name: event.label, url: event.value },
          siteId,
          onChange,
          event,
        )
      : onChange(event),
});

const LinkDropdown = ({ name, actions, value, options, onChange, siteId }) => (
  <Dropdown
    name={`${name}-dropdown`}
    input={input(value, actions, siteId, onChange)}
    options={options}
    creatable
    onNewOption={onNewOption}
    isValidNewOption={isValidNewOption}
  />
);
export default LinkDropdown;

// @flow
import React from 'react';

import type { Font, ButtonType, Padding } from '../../types';
import { HeadingTextButton } from '../components';

interface ITextCTAConfig {
  headlineFont: Font;
  bodyFont: Font;
  button: ButtonType;
  translations: {
    headlineText: string,
    bodyText: string,
    button: string,
  };
  alignment: string;
  padding: Padding;
  backgroundColour: Option;
}

type Props = {
  config: ITextCTAConfig,
  globalStyling: Object,
  dispatch: any => void,
};

export default (props: Props) => <HeadingTextButton {...props} />;

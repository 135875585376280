// @flow
import { connect } from 'react-redux';
import { actions as searchFilterActions } from '../shared/filters';
import { actions as sessionPreferencesActions } from '../shared/sessionPreferences';
import StickySearchBar from '../components/StickySearchBar';

const mapStateToProps = ({ shared }) => ({
  filterSearchUrl: shared.searchFilter.filterSearchUrl,
  maxDistance:
    shared.filters.selectedFilters &&
    shared.filters.availableFilterValues &&
    (parseInt(shared.filters.selectedFilters.maxDistance, 10) ||
      shared.filters.availableFilterValues.maxDistance[0]),
  location: shared.sessionPreferences.location || {},
  distanceOptions: translations =>
    (shared.filters.availableFilterValues &&
      shared.filters.availableFilterValues.maxDistance.map(
        f =>
          (f.translationKey && {
            ...f,
            label: translations[f.translationKey],
          }) ||
          f,
      )) ||
    [],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetFilters: () => {
    if (ownProps.onReset) {
      ownProps.onReset();
    }
    dispatch(searchFilterActions.resetFilters());
    dispatch(searchFilterActions.initialiseFilters());
  },
  handleLocationChange: ({ latitude, longitude }: Object) => {
    dispatch(
      sessionPreferencesActions.updateSessionPreferences('location', {
        latitude,
        longitude,
      }),
    );
  },
  handleMaxDistanceChange: (maxDistance: string) =>
    dispatch(
      searchFilterActions.updateFilters([
        {
          key: 'maxDistance',
          value: maxDistance,
        },
      ]),
    ),
  buttonPress: () => {
    dispatch(searchFilterActions.searchWithFilters());
    dispatch(
      sessionPreferencesActions.updateSessionPreferences(
        'resetSearchResultsPageNumber',
        true,
      ),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StickySearchBar);

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Calculator2({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 25">
        <path d="M20.6 7.4H7.4v-3h13.2v3zM11 20H7.4v-3H11v3zm0-4.2H7.4v-3H11v3zm0-4.2H7.4v-3H11v3zm4.8 8.4h-3.6v-3h3.6v3zm0-4.2h-3.6v-3h3.6v3zm0-4.2h-3.6v-3h3.6v3zm1.2-3h3.6v3H17v-3zM20.6 20H17v-7.2h3.6V20zM5 22.4h18V2H5v20.4zM18.2 5.6h1.2v1.2h-1.2V5.6z" />
      </svg>
    </IconWrapper>
  );
}

Calculator2.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

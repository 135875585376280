import Icon from './Icon';

export { default as component } from './VideoPlayer';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'VideoPlayer';
export const id = 'VideoPlayer';
export const availableToAdministration = true;
export const description = 'Video Player';
export const icon = Icon;

// @flow
import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '../Global';
import media from '../../theme';

const NoResults = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.min.medium`
  padding-top: 100px;
  `};
`;

type Props = {
  translations: Object,
};

export default ({ translations }: Props) => (
  <NoResults>
    <img
      src="https://res.cloudinary.com/motortrak/image/upload/v1565104295/cms/global/icons/results-empty.svg"
      alt={translations.noSearchResults}
    />
    <Paragraph>{translations.noSearchResults}</Paragraph>
  </NoResults>
);

export default function defaultConfig({ logos }) {
  return {
    menuBar: ['MenuBar'],
    brandLogo: ['BrandLogo'],
    utilityBar: ['UtilityBar'],
    brandLogoUrl: logos.header,
    fallbackUrl: logos.fallback,
    menuBarItems: {
      items: [
        {
          id: '03d42eee-64da-4362-98b8-8c6463d40176',
          link: 'placeholder',
          navId: '606e6c10-5f4d-11e8-bcb6-37670068e1e5',
        },
        {
          id: '04d42eee-64da-4362-98b8-8c6463d40176',
          link: 'placeholder',
          navId: '606e6c10-5f4d-11e8-bcb6-37670068e1e6',
        },
        {
          id: '05d42eee-64da-4362-98b8-8c6463d40176',
          link: 'placeholder',
          navId: '606e6c10-5f4d-11e8-bcb6-37670068e1e7',
        },
      ],
    },
    utilityBarItems: {
      items: [
        {
          id: '03d42eee-64da-4362-98b8-8c6463d40176',
          link: 'placeholder',
          navId: '606e6c10-5f4d-11e8-bcb6-37670068e1e5',
        },
        {
          id: '04d42eee-64da-4362-98b8-8c6463d40176',
          link: 'placeholder',
          navId: '606e6c10-5f4d-11e8-bcb6-37670068e1e6',
        },
        {
          id: '05d42eee-64da-4362-98b8-8c6463d40176',
          link: 'placeholder',
          navId: '606e6c10-5f4d-11e8-bcb6-37670068e1e7',
        },
      ],
    },
    showActionsBar: true,
    searchIconAlign: 'right',
    actionsBarHeight: 50,
    menuBarPages: [],
    stickyHeader: false,
    brandLogoLink: {
      label: '',
      value: '/',
    },
    menuBarHeight: 50,
    menuBarLinkFont: {
      kerning: 1,
      fontSize: 10,
      transform: 'lowercase',
    },
    utilityBarAlign: 'right',
    utilityBarPages: [],
    utilityBarHeight: 30,
    utilityBarOptions: [
      {
        options: [
          {
            label: 'French',
            value: 'fr',
          },
          {
            label: 'English',
            value: 'en',
          },
          {
            label: 'German ',
            value: 'de',
          },
        ],
        enabledKey: 'utilityBarLanguageEnabled',
        initialSelectedValue: {
          label: 'International (English)',
          value: 'en',
        },
        key: 'Language',
      },
      {
        options: [
          {
            label: 'Euro',
            value: 'EUR',
          },
        ],
        enabledKey: 'utilityBarCurrencyEnabled',
        initialSelectedValue: {
          label: 'Currency (€)',
          value: 'EUR',
        },
        key: 'Currency',
      },
    ],
    utilityBarLinkFont: {
      kerning: 3,
      fontSize: 10,
      transform: 'uppercase',
    },
    actionsBarPageTitle: {
      editing: false,
      disabled: false,
    },
    menuBarLinkHoverColour: {
      value: '#000000',
      label: '#000000',
    },
    utilityBarDropdownFont: {
      kerning: 3,
      fontSize: 9,
      transform: 'uppercase',
    },
    actionsBarsAccountTitle: {
      editing: false,
      disabled: false,
    },
    socialShareTitle: {
      editing: false,
      disabled: true,
    },
    translations: {
      socialShareTitle: 'Social Share',
      actionsBarsAccountTitle: 'SIGN IN',
      actionsBarPageTitle: 'Page Title',
      languagePlaceholder: 'language',
      currencyPlaceholder: 'currency',
      languagePrefixLabel: 'Language - ',
      currencyPrefixLabel: 'currency',
      searchBarPlaceholder: 'search',
      locationSettingsTitle: 'location settings',
      'menuBarItems-0': 'PLACEHOLDER',
      'menuBarItems-1': 'PLACEHOLDER',
      'menuBarItems-2': 'PLACEHOLDER',
      'utilityBarItems-0': 'PLACEHOLDER',
      'utilityBarItems-1': 'PLACEHOLDER',
      'utilityBarItems-2': 'PLACEHOLDER',
    },
    brandLogoVisibleDesktop: true,
    utilityBarDropdownColour: {
      value: '#FFFFFF',
      label: '#FFFFFF',
    },
    utilityBarCurrencyEnabled: false,
    utilityBarLanguageEnabled: false,
    utilityBarLinkHoverColour: {
      value: '#F0F0F0',
      label: '#F0F0F0',
    },
    actionsBarPageFont: {
      fontSize: 12,
      kerning: 2,
    },
    actionsBarAccountFont: {
      fontSize: 12,
      kerning: 4,
    },
    showSearchBar: true,
    utilityBarBorderBottomColour: {
      value: '#000000',
      label: '#000000',
    },
    utilityBarBorderBottomHeight: 0,
    actionsBarBorderBottomColour: {
      value: '#000000',
      label: '#000000',
    },
    actionsBarBorderBottomHeight: 0,
    menuBarBorderBottomColour: {
      value: '#000000',
      label: '#000000',
    },
    menuBarBorderBottomHeight: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
  };
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Fade from './Fade';
import Icon from './StyledIcon';

const OverlayWrapper = styled.div`
  position: relative;
  &:focus {
    outline: none;
  }
`;

const Img = styled.img`
  width: 200px;
  height: auto;
  right: 0;
  background-color: #ffffff;
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 4px;
`;

type Props = {
  imageUrl: string,
  infoIcon: string,
};

export default class VdpHighlightsImageOverlay extends Component<Props, *> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      allowFade: false,
    };
  }

  onToggleImage = () => {
    const { open } = this.state;
    this.setState(() => ({ open: !open, allowFade: true }));
  };

  onCloseImage = () => {
    this.setState(() => ({ open: false, allowFade: false }));
  };

  render = () => (
    <div style={{ position: 'relative' }}>
      <OverlayWrapper
        tabIndex="0"
        onClick={this.onToggleImage}
        onBlur={this.onCloseImage}
      >
        <Icon link={this.props.infoIcon} width={16} height={16} />
      </OverlayWrapper>
      {this.state.allowFade && (
        <Fade display={this.state.open}>
          <div
            style={{
              position: 'absolute',
              right: '0px',
              top: '20px',
              zIndex: 100,
            }}
          >
            <Img src={this.props.imageUrl} alt="" />
          </div>
        </Fade>
      )}
    </div>
  );
}

// @flow
import { useState, useEffect } from 'react';

export default function(selectedValues) {
  const [selectedSliderValues, setSelectedSliderValues] = useState({
    minValue: selectedValues.min,
    maxValue: selectedValues.max,
  });

  const updateSliderValues = values => {
    const [minValue, maxValue] = values;

    setSelectedSliderValues({
      minValue,
      maxValue,
    });
  };

  useEffect(() => {
    setSelectedSliderValues({
      minValue: selectedValues.min,
      maxValue: selectedValues.max,
    });
  }, [selectedValues]);

  return {
    updateSliderValues,
    selectedSliderValues,
  };
}

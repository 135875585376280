// @flow

import React from 'react';

import BulletedList from './BulletedList';

type Props = {
  equipment: any[],
  columns: number,
  styles: Object,
};

function VdpOtherEquipment({ equipment: categories, columns, styles }: Props) {
  return (
    <div style={{ columnCount: columns, columnGap: '40px', width: '100%' }}>
      {categories.map(({ category, equipment }) => (
        <BulletedList
          key={category}
          title={categories.length > 1 ? category : ''}
          items={equipment}
          styles={styles}
        />
      ))}
    </div>
  );
}

export default VdpOtherEquipment;

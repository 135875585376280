// @flow
import React, { type Node, Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  Button,
  HeadingTwo,
  HeadingThree,
  fontStyleOverride,
  Paragraph,
} from './Global';
import Pin from './JaguarIcons/Global/Pin';
import Efficiency from './JaguarIcons/VDP/Efficiency';
import Mileage from './JaguarIcons/VDP/Mileage';
import Power from './JaguarIcons/VDP/Power';
import Manual from './JaguarIcons/Search/Manual';
import Petrol from './JaguarIcons/VDP/Fuel';
import Heart from './JaguarIcons/Global/Heart';
import Compare from './JaguarIcons/Global/Compare';
import Eye from './JaguarIcons/VDP/Eye';
import ImagesIcon from './JaguarIcons/Global/Images';
import Calendar from './JaguarIcons/VDP/Calendar';
import {
  localiseCurrency,
  localiseNumber,
} from '../shared/localisation/numbers';
import { localeFormattedDate } from '../shared/localisation/dates';
import FinancePopup from './FinancePopup';
import WarrantyBadge from './WarrantyBadge';
import { vehicleDataFormatter } from '../helpers/vehicle';
import { getWarrantyLabel } from '../helpers/getWarrantyLabel';
import OnDemandBadge from './OnDemandBadge';
import { convertSecondaryPriceCurrency } from '../shared/currencyConversion/helpers';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';
import Spinner from './Spinner';

import media from '../theme';

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour.value}`};
`;

const ImageSection = styled.div`
  &:hover {
    cursor: pointer;
  }
  position: relative;
  margin: auto;
`;

const Image = styled.img`
  ${media.max.extraLarge`
  width: 350px;
`};
  height: auto;
  max-width: ${({ maxWidth }) => `${maxWidth || 400}px`};
  display: flex;
`;

const VehicleInfoSection = styled.div``;

const Line = styled.div`
  display: ${({ visible }) => (visible === true ? 'block' : 'none')};
  border-bottom: 1px solid #dedede;
  margin: 0;
  width: 100%;
`;

const ThreeSixtyImage = styled.div`
  height: 35px;
  width: 24px;
  position: absolute;
  z-index: 20;
  top: 5px;
  ${({ direction }) => (direction === 'rtl' ? 'left: 5px;' : 'right: 5px')};
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px auto;

  span {
    color: #444;
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;

    ${({ direction }) => direction === 'rtl' && 'margin-right: 5px;'};

    ${({ actionRowStyles }) =>
      actionRowStyles &&
      actionRowStyles.fontSize &&
      `font-size: ${actionRowStyles.fontSize}px`};

    ${({ actionRowStyles }) =>
      actionRowStyles &&
      actionRowStyles.colour &&
      `color: ${actionRowStyles.colour.value}`};

    ${({ actionRowStyles }) =>
      actionRowStyles &&
      actionRowStyles.margin &&
      `margin: ${actionRowStyles.margin}`};

    ${({ actionRowStyles }) => actionRowStyles && 'font-weight: normal'};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const VehicleSpecs = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ padding }) =>
    padding && padding.paddingLeft && `padding-left: ${padding.paddingLeft}px`};
  ${({ padding }) =>
    padding &&
    padding.paddingRight &&
    `padding-right: ${padding.paddingRight}px`};
`;

const VehicleTitleHeader = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: ${({ isLambo }) => (isLambo ? '0px;' : '3px;')};
`;

const VehicleTitleSubHeader = styled.div`
  color: #7e7e7e;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  ${media.max.extraLarge`
    font-size: 13px;
  `};
`;

const ImageOverlay = styled.div.withConfig({
  displayName: 'ImageOverlay',
})`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const VisibleFooter = styled.div.withConfig({
  displayName: 'VisibleFooter',
})`
  width: calc(100% - 35px);
  height: 30px;
  position: relative;
  bottom: 0;
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 10px;
  margin-top: -30px;
  opacity: 0.7;
  align-items: center;
`;

const ViewsContainer = styled.div.withConfig({
  displayName: 'ViewsContainer',
})`
  top: 18px;
  left: 20px;
  width: 150px;
  opacity: 1;
  display: flex;
  color: #fff;
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const Views = styled.span`
  padding-top: 2px;
  ${({ direction }) => direction === 'rtl' && 'margin-right: 5px;'};
`;

const Counter = styled.div`
  text-align: center;
  display: flex;
  color: #fff;
`;

const CountDiv = styled.div`
  margin-top: 2px;

  ${({ direction }) =>
    direction === 'rtl' ? 'margin-left: 5px;' : 'margin-right: 10px;'};
`;

const VehiclePriceSection = styled.div.withConfig({
  displayName: 'VehiclePriceSection',
})`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  ${({ flexDirection }) => flexDirection && 'flex-direction: column'};
  padding: 0 10px;
  ${({ showBorder }) => showBorder && 'border-left: 1px solid #D3D3D3'};
  ${({ direction }) => direction && 'border-left: none'};
  width: ${({ width }) => `${width}%`};
  ${({ direction, showBorder }) =>
    direction === 'rtl' && showBorder && 'border-right: 1px solid #D3D3D3'};
`;

const FinanceRow = styled.div.withConfig({
  displayName: 'FinanceRow',
})`
  width: 100%;
  display: flex;
`;
const ActionsRow = styled.div.withConfig({
  displayName: 'ActionsRow',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
`;

const FinanceRowContainer = styled.div.withConfig({
  displayName: 'FinanceRowContainer',
})`
  width: 100%;
`;

const Cell = styled.div.withConfig({
  displayName: 'FinanceCell',
})`
  display: ${({ visible }) => (visible === true ? 'block' : 'none')};
  width: ${({ financeWidthOverride }) =>
    financeWidthOverride ? `${financeWidthOverride}%` : '65%'};
  border-right: ${({ showRightBorder }) =>
    showRightBorder === true ? '1px solid #d3d3d3' : '0'};
  padding: 10px
    ${({ horizontalPadding }) =>
      `${horizontalPadding !== undefined ? horizontalPadding : 20}px`};
  box-sizing: border-box;
  &:last-child {
    width: ${({ financeWidthOverride }) =>
      financeWidthOverride ? `${financeWidthOverride}%` : '35%'};
    border-right: 0;
    padding-left: ${({ horizontalPadding }) =>
      `${horizontalPadding !== undefined ? horizontalPadding : 20}px`};
  }
  ${({ financePaddingOverride }) => financePaddingOverride && 'padding: 0;'};
  ${({ hideBorders }) => hideBorders === true && 'border-right: 0'};
`;

const VehicleInfoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 20px 0 20px;
  ${({ vehicleTitleFont }) =>
    vehicleTitleFont && vehicleTitleFont.margin && 'margin: 0'};
`;

const FinanceSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`;

const generateSubTitle = (vehicle, subtitleData, locale) => {
  if (subtitleData) {
    return vehicleDataFormatter(subtitleData, vehicle, locale, ', ');
  }
  const {
    shortVehicleTitles,
    exterior,
    odometer,
    transmission,
    fuel,
  } = vehicle;
  return (shortVehicleTitles
    ? [fuel, exterior]
    : [exterior, odometer.display, transmission, fuel]
  )
    .filter(Boolean)
    .join(' | ');
};

const VehicleTitle = ({
  isLambo,
  vehicle,
  fontWeight,
  vehicleSubTitleFont,
  vehicleTitleFont,
  optionalVehicleTitle,
  locale,
  hideYear,
  shortVehicleTitles,
  subtitleData,
}) => (
  <Fragment>
    {optionalVehicleTitle ? (
      <Fragment>
        <HeadingTwo
          styleOverride={() => `
            ${fontStyleOverride(vehicleTitleFont)}
          `}
        >
          {hideYear
            ? vehicle.description
            : `${vehicle.registration} ${vehicle.description} `}
        </HeadingTwo>
        <HeadingThree
          styleOverride={() => `
            ${fontStyleOverride(vehicleSubTitleFont)}
              ${media.max.extraLarge`
                font-size: 13px;
              `};
          `}
        >
          {generateSubTitle(
            { ...vehicle, shortVehicleTitles },
            subtitleData,
            locale,
          )}
        </HeadingThree>
      </Fragment>
    ) : (
      <Fragment>
        <VehicleTitleHeader isLambo={isLambo} fontWeight={fontWeight}>
          {hideYear
            ? vehicle.description
            : `${vehicle.registration} ${vehicle.description} `}
        </VehicleTitleHeader>
        <VehicleTitleSubHeader>
          {generateSubTitle(
            { ...vehicle, shortVehicleTitles },
            subtitleData,
            locale,
          )}
        </VehicleTitleSubHeader>
      </Fragment>
    )}
  </Fragment>
);

const IconWithLabel = ({
  text,
  children,
  onClickFn,
  qaHook,
}: {
  text: string,
  children: Node,
  onClickFn: Function,
}) => (
  <IconWrapper data-qa-hook={qaHook} onClick={onClickFn}>
    {children}
    <span>{text}</span>
  </IconWrapper>
);

const displayRetailerIcon = (vehicle, vehicleSpecContainer) =>
  vehicle.retailerName &&
  vehicleSpecContainer &&
  vehicleSpecContainer.icons &&
  vehicleSpecContainer.icons.locationIcon;

const DistanceFromRetailer = ({ vehicle, vehicleSpecContainer }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      bottom: '0',
    }}
  >
    <Pin width="2em" height="2em" colour="#acacac" />
    <span style={{ color: '#444', fontSize: '15px', marginLeft: '10px' }}>
      {`${vehicle.distance} km ${
        !displayRetailerIcon(vehicle, vehicleSpecContainer)
          ? `- ${vehicle.retailerName}`
          : ''
      }`}
    </span>
  </div>
);

const IconWithInfoWrapper = ({
  label,
  value,
  children,
  labelFontStyle,
}: {
  label: string,
  value: string,
  children: Node,
  labelFontStyle: Object,
}) => {
  if (!value) return null;

  const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${labelFontStyle ? '10px 0' : '20px 0'};
    flex: 0 0 100%;
    ${media.min.extraLarge`
      flex-basis: ${labelFontStyle ? 'auto' : '50%'};
    `};
    min-width: 30%;
  `;

  return (
    <Wrapper labelFontStyle={labelFontStyle}>
      {children}
      <div
        style={{
          marginLeft:
            labelFontStyle && labelFontStyle.iconMargin
              ? labelFontStyle.iconMargin.marginLeft
              : '9px',
          marginRight:
            labelFontStyle &&
            labelFontStyle.iconMargin &&
            labelFontStyle.iconMargin.marginRight,
        }}
      >
        <div
          style={{
            lineHeight:
              labelFontStyle && labelFontStyle.labelFont
                ? labelFontStyle.labelFont.lineHeight
                : '18px',
            color:
              labelFontStyle && labelFontStyle.labelFont
                ? labelFontStyle.labelFont.color
                : '#7e7e7e',
            fontSize:
              labelFontStyle && labelFontStyle.labelFont
                ? labelFontStyle.labelFont.fontSize
                : '15px',
          }}
        >
          {label}
        </div>
        <div
          style={{
            lineHeight:
              labelFontStyle && labelFontStyle.valueFont
                ? labelFontStyle.valueFont.lineHeight
                : '18px',
            color:
              labelFontStyle && labelFontStyle.valueFont
                ? labelFontStyle.valueFont.color
                : '#444',
            fontWeight:
              labelFontStyle && labelFontStyle.valueFont ? 'normal' : '600',
            fontSize:
              labelFontStyle && labelFontStyle.valueFont
                ? labelFontStyle.valueFont.fontSize
                : '14px',
          }}
        >
          {value}
        </div>
      </div>
    </Wrapper>
  );
};

const IconWithInfo = ({
  enabled,
  label,
  value,
  labelFontStyle,
  iconWidth,
  iconHeight,
  iconSrc,
  CustomIcon,
}) =>
  enabled ? (
    <IconWithInfoWrapper
      label={label}
      value={value}
      labelFontStyle={labelFontStyle}
    >
      {iconSrc ? (
        <div>
          <img
            style={{
              width: iconWidth,
              height: iconHeight,
            }}
            src={iconSrc}
            alt={label}
            colour="#acacac"
          />
        </div>
      ) : (
        CustomIcon && (
          <CustomIcon width={iconWidth} height={iconHeight} colour="#acacac" />
        )
      )}
    </IconWithInfoWrapper>
  ) : null;

type CellProps = {
  headerText: string,
  secondaryHeaderText?: string,
  subHeaderText: string,
  renderChild: () => Node,
  financeIcon?: string,
  visible: boolean,
  headerColour: string,
  financeHeadingFont: Object,
  horizontalPadding: number,
  financeSubHeadingFont: Object,
  financeWidthOverride: number,
  hideBorders: boolean,
};

const FinanceCell = ({
  headerText,
  subHeaderText,
  financeIcon,
  visible,
  showRightBorder,
  renderChild = () => {},
  financeHeadingFont,
  horizontalPadding,
  financeWidthOverride,
  financeSubHeadingFont,
  hideBorders,
  secondaryHeaderText,
}: CellProps) => (
  <Cell
    visible={visible}
    showRightBorder={showRightBorder}
    horizontalPadding={horizontalPadding}
    hideBorders={hideBorders}
    financeWidthOverride={financeWidthOverride}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: subHeaderText ? 'flex-start' : 'center',
      }}
    >
      <HeadingTwo
        styleOverride={() => `
          ${fontStyleOverride(financeHeadingFont)};`}
      >
        {headerText}
      </HeadingTwo>
      <div style={{ paddingLeft: '5px' }}>{financeIcon}</div>
    </div>
    <HeadingTwo
      styleOverride={() => `
          ${fontStyleOverride(financeHeadingFont)};
          padding-top: 0px;
        `}
    >
      {secondaryHeaderText}
    </HeadingTwo>
    <HeadingThree
      styleOverride={() => `
        display: flex;
        width: 100%;
        justify-content: flex-start;
        font-size: 14px;
        color: #999;
      ${fontStyleOverride(financeSubHeadingFont)};`}
    >
      {subHeaderText}
    </HeadingThree>
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        top: 5,
        right: 0,
        zIndex: 100,
      }}
    >
      {renderChild()}
    </div>
  </Cell>
);

const BottomInfo = styled.div`
  border-top: 1px solid #dedede;
  display: flex;
  margin-top: auto;
  min-height: 26px;
  padding: 10px 0px;
`;

const Co2ConsumptionInformationSection = styled(BottomInfo)``;

const FinanceLoadingWrapper = styled.div`
  margin: 10px;
`;

const WarrantyBadgeWrapper = styled.div`
  align-self: center;
  margin-left: auto;
  display: flex;
`;

type Props = {
  vehicle: Object,
  placeholdingImage: string,
  vehiclePriceSectionWidth: number,
  vehiclePriceSectionLeftBorder: boolean,
  displayImage: boolean,
  onMoreInfoClick: Function,
  translations: Object,
  compareVehicle: Function,
  shortlistVehicle: Function,
  shortListedIconColour: string,
  compareIconColour: string,
  renderTopRightIcon: () => Node,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  backgroundColour: Option,
  financeCellHorizontalPadding: number,
  showFinanceCellBorder: boolean,
  imageMaxWidth: number,
  moreInfoButtonRightAlign: boolean,
  showFinancePopup: boolean,
  financeWidthOverride: number,
  vehicleTitleFont: Font,
  vehicleSubTitleFont: Font,
  optionalVehicleTitle: boolean,
  actionRowStyles: Object,
  vehicleSpecContainer: Object,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
  showOnDemand?: Object,
  icon360: string,
};

const formattedLocale = locale => locale.replace('_', '-');

const carbonTemplate = (vehicle, translations) => {
  const fuelConsumption = translateFromTemplate(
    'fuelConsumptionTemplate',
    {
      MINFUEL: vehicle.engineDetails.consumptionExtraurban,
      MAXFUEL: vehicle.engineDetails.consumptionUrban,
    },
    translations,
  );
  const co2 = translateFromTemplate(
    'co2ConsumptionTemplate',
    {
      MINCO2: vehicle.engineDetails.co2EmissionsMin,
      MAXCO2: vehicle.engineDetails.co2EmissionsMax,
    },
    translations,
  );

  return `${fuelConsumption}\n ${co2}`;
};

const electricTemplate = (vehicle, translations) =>
  translateFromTemplate(
    'electricVehicleConsumptionTemplate',
    {
      MAXDISTANCE: vehicle.engineDetails.maxRange,
      MINPOWER: vehicle.engineDetails.minPowerKw,
      MAXPOWER: vehicle.engineDetails.maxPowerKw,
    },
    translations,
  );

const co2LegalInfo = (vehicle, translations) =>
  vehicle.engine === 'EV'
    ? electricTemplate(vehicle, translations)
    : carbonTemplate(vehicle, translations);

export default function SearchResultBlock({
  vehicle,
  placeholdingImage,
  vehiclePriceSectionWidth,
  displayImage,
  onMoreInfoClick,
  translations,
  compareVehicle,
  shortlistVehicle,
  shortListedIconColour,
  compareIconColour,
  renderTopRightIcon,
  globalStyling,
  visibility,
  locale,
  vehicleModelTitleFontWeight,
  pricingSectionImage,
  financeHeadingFont,
  backgroundColour,
  financeCellHorizontalPadding,
  showFinanceCellBorder = true,
  imageMaxWidth,
  moreInfoButtonRightAlign,
  showFinancePopup,
  financeSubHeadingFont,
  financeWidthOverride,
  vehicleTitleFont,
  vehicleSubTitleFont,
  optionalVehicleTitle,
  actionRowStyles,
  vehicleSpecContainer,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
  showOnDemand,
  onDemandFont,
  icon360,
}: Props) {
  const { currency } = vehicle.price;
  const { rates, secondaryCurrency } = useSelector(state => ({
    rates: state.shared.currencyConversion.exchangeRates[currency]
      ? state.shared.currencyConversion.exchangeRates[currency].rates
      : null,
    secondaryCurrency:
      state.config.config.global.inventory.secondaryCurrency || null,
  }));
  const { financeFeatureEnabled } = useSelector(state => ({
    financeFeatureEnabled:
      state.config.config.global.featureFlags &&
      state.config.config.global.featureFlags.finance,
  }));

  const financeCellText = priceEntry =>
    localiseCurrency(priceEntry, locale, vehicle.price.currency, 2) ||
    translations.searchResultsNotAvailableLabel;

  const financeCellTextNoDigits = (priceEntry, curCurrency) =>
    localiseCurrency(priceEntry, locale, curCurrency, 0) ||
    translations.searchResultsNotAvailableLabel;

  const hasFinance = !!vehicle.finance;
  const { financeLoaded } = vehicle;

  const icons = vehicleSpecContainer && vehicleSpecContainer.icons;

  const iconWidth =
    (icons && icons.iconWidth && `${icons.iconWidth}px`) || '2em';
  const iconHeight =
    (icons && icons.iconHeight && `${icons.iconHeight}px`) || '2em';

  const FinanceLoadingText = () => (
    <FinanceSpinnerWrapper>
      <Spinner colour={globalStyling.colours.primaryBrandColour} />
      <FinanceLoadingWrapper>
        {translations.financeLoadingText}
      </FinanceLoadingWrapper>
    </FinanceSpinnerWrapper>
  );

  const { approvedVehicles } = useSelector(
    state => state.config.config.global.featureFlags,
  );
  return (
    <Container
      data-qa-hook="vehicle-search-result-block"
      key={vehicle.id}
      backgroundColour={backgroundColour}
    >
      {displayImage && (
        <ImageSection
          onClick={() =>
            vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle)
          }
        >
          {showOnDemand && (
            <OnDemandBadge
              font={onDemandFont}
              backgroundColor={globalStyling.colours.primaryBrandColour}
              foregroundColor={globalStyling.colours.primaryForegroundColour}
              text={translations.onDemandBadge}
            />
          )}
          {vehicle.images.length > 0 ? (
            <Image src={vehicle.images[0]} maxWidth={imageMaxWidth} />
          ) : (
            <Image
              src={placeholdingImage}
              alt={placeholdingImage}
              maxWidth={imageMaxWidth}
            />
          )}
          {vehicle.status === 'sold' && (
            <ImageOverlay>
              <SoldHeading>{translations.sold}</SoldHeading>
              <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
            </ImageOverlay>
          )}
          {vehicle.panoramicViews &&
            (vehicle.panoramicViews.exterior ||
              vehicle.panoramicViews.interior) && (
              <ThreeSixtyImage direction={globalStyling.direction}>
                <img src={icon360} alt="360" />
              </ThreeSixtyImage>
            )}
          <VisibleFooter>
            <ViewsContainer>
              <Eye width="1.5em" height="1.25em" colour="#FFFFFF" />
              <Views direction={globalStyling.direction}>{vehicle.views}</Views>
            </ViewsContainer>
            <Counter>
              <CountDiv direction={globalStyling.direction}>
                {' '}
                {vehicle.images.length}{' '}
              </CountDiv>
              <ImagesIcon width="1.25em" height="1.25em" colour="#FFFFFF" />
            </Counter>
          </VisibleFooter>
        </ImageSection>
      )}
      <VehicleInfoSectionContainer
        vehicleTitleFont={vehicleTitleFont}
        vehicleTitleSubFont={vehicleSubTitleFont}
        vehicleSpecContainer={vehicleSpecContainer}
        globalStyling={globalStyling}
      >
        <VehicleInfoSection>
          <VehicleTitle
            isLambo={!!pricingSectionImage}
            vehicle={vehicle}
            fontWeight={vehicleModelTitleFontWeight}
            vehicleTitleFont={vehicleTitleFont}
            vehicleSubTitleFont={vehicleSubTitleFont}
            optionalVehicleTitle={optionalVehicleTitle}
            locale={locale}
            hideYear={visibility.hideYear}
            shortVehicleTitles={visibility.shortVehicleTitles}
            subtitleData={visibility.listSubtitleData}
          />
          <Line visible />
          {!pricingSectionImage && (
            <VehicleSpecs padding={vehicleSpecContainer}>
              <IconWithInfo
                enabled={vehicle.warranty.remainingWarrantyWholeYears > 0}
                label={translations.warrantyLabel}
                value={getWarrantyLabel(
                  vehicle.warranty.remainingWarrantyWholeYears,
                  translations.warrantyYearsLabel,
                  translations.warrantyYearsLabel2to4,
                )}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.warrantyYears}
              />
              <IconWithInfo
                enabled={visibility.showOdometer && vehicle.odometer}
                label={translations.searchResultsOdometerLabel}
                value={
                  visibility.showOdometer &&
                  `${localiseNumber(vehicle.odometer.reading, locale)} ${
                    vehicle.odometer.units
                  }`
                }
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.odometerIcon}
                CustomIcon={Mileage}
              />
              <IconWithInfo
                enabled={vehicle.transmission}
                label={translations.searchResultsTransmissionLabel}
                value={vehicle.transmission}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.transmissionIcon}
                CustomIcon={Manual}
              />
              <IconWithInfo
                enabled={
                  vehicle.engineDetails &&
                  vehicle.engineDetails.combinedConsumption
                }
                label={translations.searchResultsConsumptionCombinedLabel}
                value={vehicle.engineDetails.combinedConsumption}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.fuelTypeIcon}
                CustomIcon={Petrol}
              />
              <IconWithInfo
                enabled={visibility.showFuel && vehicle.fuel}
                label={translations.searchResultsFuelLabel}
                value={vehicle.fuel}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.fuelTypeIcon}
                CustomIcon={Petrol}
              />
              <IconWithInfo
                enabled={parseInt(vehicle.co2EmissionsCombined, 10) > 0}
                label={translations.searchResultsEmissionsLabel}
                value={translateFromTemplate(
                  'co2UnitDisplayText',
                  {
                    VALUE: vehicle.co2EmissionsCombined,
                  },
                  translations,
                )}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.efficiencyIcon}
                CustomIcon={Efficiency}
              />
              <IconWithInfo
                enabled={!visibility.hideYear && vehicle.registrationDate}
                label={translations.registered}
                value={`${localeFormattedDate(
                  vehicle.registrationDate,
                  formattedLocale(locale),
                  {
                    month: '2-digit',
                    year: 'numeric',
                  },
                )} `}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                CustomIcon={Calendar}
              />
              <IconWithInfo
                enabled={vehicle.powerOutput}
                label={translations.searchResultsPowerLabel}
                value={vehicle.powerOutput}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                CustomIcon={Power}
              />
              <IconWithInfo
                enabled={displayRetailerIcon(vehicle, vehicleSpecContainer)}
                label={translations.retailerLocationLabel}
                value={vehicle.retailerName}
                labelFontStyle={vehicleSpecContainer}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconSrc={icons && icons.locationIcon}
              />
            </VehicleSpecs>
          )}
          {pricingSectionImage && (
            <VehicleSpecs>
              {vehicle.registrationDate && (
                <IconWithInfoWrapper
                  label={translations.registrationDate}
                  value={`${localeFormattedDate(
                    vehicle.registrationDate,
                    formattedLocale(locale),
                    {
                      month: '2-digit',
                      year: 'numeric',
                    },
                  )} `}
                >
                  <img
                    style={{ maxWidth: 25, maxHeight: 25 }}
                    src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Reg_Date_2x.png"
                    alt={translations.registrationDate}
                  />
                </IconWithInfoWrapper>
              )}
              {vehicle.odometer && (
                <IconWithInfoWrapper
                  label={translations.searchResultsOdometerLabel}
                  value={`${localiseNumber(vehicle.odometer.reading, locale)} ${
                    vehicle.odometer.units
                  }`}
                >
                  <img
                    style={{ maxWidth: 25, maxHeight: 25 }}
                    src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Mileage_2x.png"
                    alt={translations.searchResultsOdometerLabel}
                  />
                </IconWithInfoWrapper>
              )}
              {vehicle.registration && (
                <IconWithInfoWrapper
                  label={translations.registered}
                  value={vehicle.registration}
                >
                  <img
                    style={{ maxWidth: 25, maxHeight: 25 }}
                    src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Model_Year_2x.png"
                    alt={translations.registered}
                  />
                </IconWithInfoWrapper>
              )}
              {vehicle.exterior && (
                <IconWithInfoWrapper
                  label={translations.exterior}
                  value={vehicle.exterior}
                >
                  <img
                    style={{ maxWidth: 25, maxHeight: 25 }}
                    src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Exterior_2x.png"
                    alt={translations.exterior}
                  />
                </IconWithInfoWrapper>
              )}
              {vehicle.interior && (
                <IconWithInfoWrapper
                  label={translations.interior}
                  value={vehicle.interior}
                >
                  <img
                    style={{ maxWidth: 25, maxHeight: 25 }}
                    src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Interior_2x.png"
                    alt={translations.interior}
                  />
                </IconWithInfoWrapper>
              )}
              {vehicle.transmission && (
                <IconWithInfoWrapper
                  label={translations.searchResultsTransmissionLabel}
                  value={vehicle.transmission}
                >
                  <img
                    src="https://res.cloudinary.com/motortrak/image/upload/v1556285220/cms/lamborghini/content/Transmission.svg"
                    alt={translations.searchResultsTransmissionLabel}
                  />
                </IconWithInfoWrapper>
              )}
            </VehicleSpecs>
          )}
        </VehicleInfoSection>
        {translations.co2ConsumptionTemplate && (
          <Co2ConsumptionInformationSection>
            <Paragraph
              styleOverride={() =>
                'font-size: 11px; padding-left: 20px; white-space: pre-line'
              }
            >
              {co2LegalInfo(vehicle, translations)}
            </Paragraph>
          </Co2ConsumptionInformationSection>
        )}
        <BottomInfo>
          {vehicle.distance !== undefined && vehicle.distance !== null && (
            <DistanceFromRetailer
              vehicle={vehicle}
              vehicleSpecContainer={vehicleSpecContainer}
            />
          )}
          <WarrantyBadgeWrapper>
            <WarrantyBadge
              vehicle={vehicle}
              badgeWithStripUrl={badgeWithStripUrl}
              badgeWithoutStripUrl={badgeWithoutStripUrl}
              warrantyOdometer={vehicle.odometer.reading}
              warrantyBadgeMonths={warrantyBadgeMonths}
              warrantyBadgeMaxMileage={warrantyBadgeMaxMileage}
            />
          </WarrantyBadgeWrapper>
        </BottomInfo>
      </VehicleInfoSectionContainer>
      <VehiclePriceSection
        width={vehiclePriceSectionWidth}
        flexDirection={visibility.flexDirection}
        direction={globalStyling.direction}
        showBorder={
          visibility.showVehiclePriceSectionLeftBorder !== undefined
            ? visibility.showVehiclePriceSectionLeftBorder
            : true
        }
      >
        <FinanceRowContainer>
          <FinanceRow
            style={{
              position: 'relative',
              justifyContent: hasFinance ? 'initial' : '',
            }}
          >
            <FinanceCell
              headerText={financeCellTextNoDigits(
                vehicle.price.value && Number(vehicle.price.value),
                currency,
              )}
              secondaryHeaderText={
                secondaryCurrency &&
                rates &&
                financeCellTextNoDigits(
                  convertSecondaryPriceCurrency(
                    vehicle.price.value,
                    rates,
                    currency,
                    secondaryCurrency,
                  ),
                  secondaryCurrency,
                )
              }
              subHeaderText={
                secondaryCurrency && rates
                  ? translateFromTemplate(
                      'exchangeRate',
                      {
                        EXCHANGERATE: rates[secondaryCurrency].toFixed(2),
                      },
                      translations,
                    )
                  : translations.searchResultsPriceLabel
              }
              visible={visibility.showPrice}
              showRightBorder={
                showFinanceCellBorder &&
                visibility.showApr &&
                hasFinance &&
                financeLoaded
              }
              headerColour={visibility.financeCellHeaderColour}
              renderChild={() =>
                !hasFinance && vehicle.status === 'sold' && renderTopRightIcon()
              }
              financeHeadingFont={financeHeadingFont}
              horizontalPadding={financeCellHorizontalPadding}
              hideBorders={visibility.hideBorders}
              financeSubHeadingFont={financeSubHeadingFont}
              financeWidthOverride={financeWidthOverride}
            />
            {hasFinance && (
              <FinanceCell
                headerText={
                  hasFinance && financeLoaded && `${vehicle.finance.apr}% `
                }
                subHeaderText={translations.searchResultsAprLabel}
                renderChild={() =>
                  vehicle.status === 'sold' && renderTopRightIcon()
                }
                visible={visibility.showApr && hasFinance && financeLoaded}
                financeHeadingFont={financeHeadingFont}
                horizontalPadding={financeCellHorizontalPadding}
                showRightBorder={showFinanceCellBorder}
                hideBorders={visibility.hideBorders}
                financeSubHeadingFont={financeSubHeadingFont}
                financeWidthOverride={financeWidthOverride}
              />
            )}
          </FinanceRow>
          {financeFeatureEnabled && !financeLoaded ? (
            <FinanceLoadingText />
          ) : (
            '\u00A0'
          )}
          <Line
            visible={
              !visibility.hideBorders &&
              showFinanceCellBorder &&
              visibility.showMonthly &&
              hasFinance &&
              financeLoaded
            }
          />
          {hasFinance && (
            <FinanceRow
              style={{ marginTop: visibility.hasMargin ? '-20px' : '' }}
            >
              <FinanceCell
                headerText={
                  hasFinance &&
                  financeLoaded &&
                  financeCellText(
                    hasFinance &&
                      parseFloat(
                        financeLoaded &&
                          vehicle.finance.monthlyPrice
                            .replace('.', '')
                            .replace(',', '.'),
                      ),
                  )
                }
                subHeaderText={translations.searchResultsMonthlyLabel}
                financeIcon={
                  hasFinance &&
                  (vehicle.status !== 'sold' &&
                    vehicle.finance &&
                    showFinancePopup && (
                      <FinancePopup
                        translations={translations}
                        data={vehicle.finance}
                        locale={locale}
                      />
                    ))
                }
                visible={visibility.showMonthly && hasFinance && financeLoaded}
                showRightBorder={
                  showFinanceCellBorder && visibility.showMonthly
                }
                financeHeadingFont={financeHeadingFont}
                horizontalPadding={financeCellHorizontalPadding}
                hideBorders={visibility.hideBorders}
                financeSubHeadingFont={financeSubHeadingFont}
                financeWidthOverride={financeWidthOverride}
              />
              <FinanceCell
                headerText={`${vehicle.finance.interestRate}% `}
                subHeaderText={translations.searchResultsInterestRateLabel}
                visible={
                  visibility.showInterestRate && hasFinance && financeLoaded
                }
                financeHeadingFont={financeHeadingFont}
                horizontalPadding={financeCellHorizontalPadding}
                showRightBorder={showFinanceCellBorder}
                hideBorders={visibility.hideBorders}
                financeSubHeadingFont={financeSubHeadingFont}
                financeWidthOverride={financeWidthOverride}
              />
            </FinanceRow>
          )}
          {translations.vatDisclaimer && (
            <FinanceRow>
              <Paragraph styleOverride={() => 'font-size: 11px'}>
                {translations.vatDisclaimer}
              </Paragraph>
            </FinanceRow>
          )}
          <Line
            visible={
              !visibility.hideBorders &&
              visibility.showMonthly &&
              hasFinance &&
              financeLoaded
            }
            style={{ paddingTop: visibility.hasPadding ? '10px' : '' }}
          />
        </FinanceRowContainer>
        {approvedVehicles && vehicle.approved && pricingSectionImage && (
          <img
            style={{ width: '100%', height: '40px' }}
            src={pricingSectionImage}
            alt=""
          />
        )}
        {vehicle.status !== 'sold' && (
          <ActionsRow actionRowStyles={actionRowStyles}>
            <ActionsContainer
              actionRowStyles={actionRowStyles}
              direction={globalStyling.direction}
            >
              <IconWithLabel
                qaHook="compare-vehicle-button"
                text={translations.searchResultsAddToCompare}
                onClickFn={() => compareVehicle(vehicle)}
              >
                <Compare
                  width={
                    actionRowStyles && actionRowStyles.iconWidth
                      ? actionRowStyles.iconWidth
                      : '2em'
                  }
                  height={
                    actionRowStyles && actionRowStyles.iconHeight
                      ? actionRowStyles.iconHeight
                      : '2em'
                  }
                  colour={compareIconColour}
                />
              </IconWithLabel>
              <IconWithLabel
                qaHook="shortlist-vehicle-button"
                text={translations.searchResultsSaveVehicle}
                onClickFn={() => shortlistVehicle(vehicle)}
              >
                <Heart
                  width={
                    actionRowStyles && actionRowStyles.iconWidth
                      ? actionRowStyles.iconWidth
                      : '2em'
                  }
                  height={
                    actionRowStyles && actionRowStyles.iconHeight
                      ? actionRowStyles.iconHeight
                      : '2em'
                  }
                  colour={shortListedIconColour}
                />
              </IconWithLabel>
            </ActionsContainer>
            <Line visible={visibility.showMonthly && !visibility.hideBorders} />
            <Button
              data-qa-hook="more-info-button"
              onClick={() =>
                vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle)
              }
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              applyStyle="primary"
              text={translations.searchResultTileMoreInfo}
              styleOverride={() => `
                max-width: 100%;
                margin: 10px;
                align-self: auto;
                ${moreInfoButtonRightAlign && 'align-self: flex-end;'}
                `}
            />
          </ActionsRow>
        )}
      </VehiclePriceSection>
    </Container>
  );
}

// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { actions } from '../../shared/filters';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import media from '../../theme';

import { optionComponents } from './FiltersLookup';
import { Button } from '../../components/Global';

const FiltersWrapper = styled.div`
  ${({ backgroundColour }) =>
    backgroundColour && `background: ${backgroundColour};`};
  border: 1px solid #d5d5d5;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  ${media.max.medium`
    flex-direction: row-reverse;
  `};
`;

export type FilterProps = {
  config: Object,
  selectedFilters: Object,
  availableFilterValues: Object,
  updateFilters: Object => void,
  globalStyling: Object,
  resetFilters: string => void,
  searchWithFilters: () => void,
  hideSearch: boolean,
  locale: string,
  changeLocation: string => void,
  toggle: () => void,
  currencyCode: string,
  shared: Object,
  searchCountryCode: string,
};

class Filters extends Component<FilterProps, *> {
  constructor(props) {
    super(props);
    this.filterRefs = {};
    props.config.filterOptions.items.forEach(element => {
      this.filterRefs[element.lookupKey] = React.createRef();
    });
  }

  resetFilters = () => {
    const { resetFilters } = this.props;
    this.collapseFilters();
    resetFilters();
  };

  collapseFilters = () => {
    const { config } = this.props;
    config.filterOptions.items.forEach(element => {
      const { current } = this.filterRefs[element.lookupKey];
      if (current && current.setOpenState) {
        current.setOpenState(false);
      }
    });
  };

  render() {
    const { props } = this;
    const filtersBackgroundColour =
      props.config.stylingConfig && props.config.stylingConfig.backgroundColour;
    return props.availableFilterValues ? (
      <Fragment>
        <FiltersWrapper backgroundColour={filtersBackgroundColour}>
          {props.config.filterOptions.items
            .filter(o => !o.locked && o.active)
            .map(fo =>
              optionComponents[fo.lookupKey](
                fo.lookupKey,
                props,
                this.filterRefs[fo.lookupKey],
              ),
            )}
        </FiltersWrapper>
        <ActionsWrapper>
          <Button
            text={props.config.translations.searchCTAApply}
            onClick={() => props.toggle()}
            applyStyle="primary"
            buttonStyle={
              props.globalStyling.uiElements.primaryButton &&
              props.globalStyling.uiElements.primaryButton.buttonStyle
            }
            styleOverride={() =>
              `width: 100%;
              margin-bottom: 8px;
              display:none;`
            }
            mobileStyleOverride={() => 'display:flex; margin: 0 10px 8px 5px;'}
          />
          {!props.hideSearch && (
            <Button
              text={props.config.translations.searchCTA}
              onClick={() => props.searchWithFilters()}
              applyStyle="primary"
              buttonStyle={
                props.globalStyling.uiElements.primaryButton &&
                props.globalStyling.uiElements.primaryButton.buttonStyle
              }
              styleOverride={() =>
                `width: 100%;
              margin-bottom: 8px;`
              }
              mobileStyleOverride={() =>
                'display: none; margin: 0 10px 8px 5px;'
              }
            />
          )}
          <Button
            text={props.config.translations.resetCTA}
            onClick={this.resetFilters}
            applyStyle="secondary"
            buttonStyle={
              props.globalStyling.uiElements.secondaryButton &&
              props.globalStyling.uiElements.secondaryButton.buttonStyle
            }
            styleOverride={() =>
              `width: 100%;
              margin-bottom: 8px;`
            }
            mobileStyleOverride={() => 'margin: 0 5px 8px 10px;'}
          />
        </ActionsWrapper>
      </Fragment>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = ({ router, shared, config }) => ({
  ...shared.filters,
  page: router.path,
  locale: config.settings.locale,
  currencyCode: config.config.global.inventory.currencyCode,
  location: shared.sessionPreferences.location || {},
});

const mapDispatchToProps = dispatch => ({
  updateSessionPreferences: (key, value) => {
    dispatch(sessionActions.updateSessionPreferences(key, value));
  },
  updateFilters: payload => dispatch(actions.updateFilters(payload)),
  resetFilters: () => {
    dispatch(actions.resetFilters());
    const query = window.location.search.substring(1);
    dispatch(actions.initialiseFilters(query));
  },
  searchWithFilters: () => {
    dispatch(actions.searchWithFilters());
    dispatch(
      sessionActions.updateSessionPreferences(
        'resetSearchResultsPageNumber',
        true,
      ),
    );
  },
  changeLocation: payload => dispatch(actions.changeLocation(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(Filters);

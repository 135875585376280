import omit from 'ramda/src/omit';

export default function applyModelVariantLogic(filtersObject) {
  const keys = Object.keys(filtersObject);
  if (
    keys.find(k => k === 'variant' || k === 'modelVariants') &&
    !keys.find(k => k === 'model')
  ) {
    return omit(['variant', 'modelVariants'], filtersObject);
  }
  return filtersObject;
}

const convertToPounds = (value, exchange) => {
  if (value === 0) {
    return value;
  }

  return Math.round(value * exchange.rates.GBP);
};

// AVL3-2528 - Temporary fix to handle fluctuating currency conversion rates.
// Adds a 1% buffer to price filter values.
// - Updated to 1.5%
export const adjustPriceRangeFilterValues = (filtersObject, exchange) => {
  return Object.keys(filtersObject).reduce((acc, key) => {
    if (key === 'minDefaultPrice' || key === 'maxDefaultPrice') {
      const priceValue = Number(filtersObject[key]);
      const convertedGBPValue = exchange
        ? convertToPounds(priceValue, exchange)
        : priceValue;

      const coef = key === 'minDefaultPrice' ? -1 : 1;
      const adjustedValue = Math.round(
        convertedGBPValue + (convertedGBPValue / 66.666) * coef,
      );

      return { ...acc, [key]: adjustedValue };
    }

    return acc;
  }, filtersObject);
};

// @flow
import React from 'react';

import { HeadingTextButton, Image, Wrapper } from '../../components';
import type { PostSummaryConfig } from '../types';

type Props = {
  config: PostSummaryConfig,
  globalStyling: Object,
  postData: Object,
};

export default ({ config }: Props) => (
  <Wrapper>
    <HeadingTextButton config={config}>
      {({
        config: {
          translations: { imageLabel },
          image: { src },
          imageBehaviour,
          imagePosition,
          imageHeight,
        },
      }) => (
        <Image
          {...{ src, imageBehaviour, imagePosition, imageHeight }}
          role="img"
          aria-label={imageLabel}
        />
      )}
    </HeadingTextButton>
  </Wrapper>
);

// @flow
import React from 'react';

const getBadge = (
  vehicle,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyOdometer,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
) => {
  if (vehicle.approved && warrantyOdometer < warrantyBadgeMaxMileage) {
    return vehicle.ageMonths < warrantyBadgeMonths
      ? badgeWithStripUrl
      : badgeWithoutStripUrl;
  }
  return null;
};

export default ({
  vehicle,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyOdometer,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
}) => {
  const badgeUrl = getBadge(
    vehicle,
    badgeWithStripUrl,
    badgeWithoutStripUrl,
    warrantyOdometer,
    warrantyBadgeMonths,
    warrantyBadgeMaxMileage,
  );
  return badgeUrl ? <img src={badgeUrl} alt="warranty badge" /> : null;
};

// @flow
import React, { Component } from 'react';
import ShortlistedVehicles from '../../components/ShortlistedVehicles';
import { screenSizeOptions } from '../../theme';
import SubNav from '../../containers/SubNav';
import { actions as financeActions } from '../../shared/financeCalculator';
import { actions as routerActions } from '../../actions/router';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortlistActions } from '../../shared/shortlist';
import { previewShortListedVehicles } from './preview.config';
import { getVehicles as compareVehicles } from '../../shared/compare/reducer';
import { getVehicles as shortlistVehicles } from '../../shared/shortlist/reducer';
import { sortOptions } from '../../shared/sortOptions/settings';
import type { Font } from '../types';
import ContentWrapper from '../../components/ContentWrapper';
import { vanityUrlFormatter } from '../../helpers/vehicle';

export interface Config {
  translations: Object;
  subNavFont?: Font;
  subNavBorderColour?: string;
  subNavChevronColour?: string;
  showNavBarItemBorder?: boolean;
  visibility: Object;
  headingAlignment: string;
  compareIconListedColour: Object;
  shortlistIconListedColour: Object;
  subNavHeight: string;
  alignMenuItems: string;
  navBarBottomBorder: string;
  headingBottomLineDisable: boolean;
}

type Props = {
  dispatch: Function,
  config: Config,
  actions: {
    toggleOptionalExtras: Function,
    init: Function,
    toggleVehicle: Function,
  },
  pathByModule: string => any,
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  marketInfo: Object,
  globalStyling: Object,
};

type State = {
  sortField: {
    field: string,
    order: string,
    value: string,
  },
  selectedView: 'GRID' | 'LIST',
  sortValueDictionary: Object,
};

const themeFromProps = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
}: Font): Theme => ({
  fontFamily: typeface ? typeface.label : '',
  fontSize: fontSize ? `${fontSize}px` : '',
  characterSpacing: kerning ? `${kerning}px` : '',
  textTransform: transform || '',
  colour: colour ? colour.value : '',
});

export default class Shortlist extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const selectedView =
      window.innerWidth < screenSizeOptions.large ? 'GRID' : 'LIST';

    const sortValueDictionary = {
      priceAsc: (a, b) => a.price.value - b.price.value,
      priceDesc: (a, b) => b.price.value - a.price.value,
      mileageAsc: (a, b) => a.odometer.reading - b.odometer.reading,
      mileageDesc: (a, b) => b.odometer.reading - a.odometer.reading,
      ageAsc: (a, b) => b.registration - a.registration,
      ageDesc: (a, b) => a.registration - b.registration,
    };
    this.state = {
      sortField: this.props.shared.sessionPreferences.searchSort,
      selectedView,
      sortValueDictionary,
    };
  }

  componentDidMount() {
    const {
      actions: { init },
      dispatch,
      preview,
      shared,
    } = this.props;
    init();
    if (!preview && !shared.financeCalculator.representativeExample) {
      dispatch(financeActions.loadRepresentativeExample());
    }
    window.addEventListener('resize', this.onViewportChange);
    window.scroll(0, 0);

    if (
      !preview &&
      shared.sessionPreferences.currency &&
      shared.currencyConversion.exchangeRates[
        shared.sessionPreferences.currency
      ]
    ) {
      dispatch(
        shortlistActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { shared, dispatch } = this.props;
    if (
      shared.sessionPreferences.currency &&
      shared.currencyConversion.exchangeRates[
        shared.sessionPreferences.currency
      ] &&
      prevProps.shared.currencyConversion.exchangeRates !==
        shared.currencyConversion.exchangeRates
    ) {
      dispatch(
        shortlistActions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      dispatch(shortlistActions.getVehicles());
    }
  }

  onSortChange = (value: string) => {
    this.setState({
      sortField: sortOptions[value],
    });
  };

  onViewportChange = () => {
    if (window.innerWidth < screenSizeOptions.large) {
      this.setState(() => ({ selectedView: 'GRID' }));
    }
  };

  onViewChange = (selectedView: 'GRID' | 'LIST') =>
    this.setState(() => ({ selectedView }));

  props: Props;

  render = () => {
    const { selectedView, sortValueDictionary } = this.state;
    const {
      history,
      pathByModule,
      preview,
      config,
      shared,
      dispatch,
      globalStyling,
      marketInfo,
    } = this.props;
    const shortlistedVehicles = preview ? [] : shortlistVehicles(shared);
    const sharedCompareVehicles = preview ? [] : compareVehicles(shared);
    const sharedShortListedVehicles = preview
      ? previewShortListedVehicles
      : shortlistVehicles(shared);

    const onMoreInfoClick = (name, vehicle) => {
      const vehicleMetaInfo = vanityUrlFormatter(
        config,
        vehicle,
        shared.sessionPreferences.language,
      );
      dispatch(routerActions.navigate(`/vdp/${vehicle.id}-${vehicleMetaInfo}`));
    };

    const representativeExample = preview
      ? {}
      : shared.financeCalculator.representativeExample;

    const isListed = (id: string, vehicles: Vehicle[]) =>
      vehicles.find(vehicle => vehicle.id === (id || {}));

    const getCompareIconColour = (id: string) =>
      isListed(id, sharedCompareVehicles)
        ? config.compareIconListedColour && config.compareIconListedColour.value
        : '#ACACAC';

    const getShortListedIconColour = (id: string) =>
      isListed(id, sharedShortListedVehicles)
        ? config.shortlistIconListedColour &&
          config.shortlistIconListedColour.value
        : '#ACACAC';

    const { distanceAsc, ...optionsForSort } = sortOptions;

    return (
      <div>
        <SubNav
          pathByModule={pathByModule}
          title={config.translations.mobileVehicleDetails}
          preview={preview}
          onBackButtonClick={history.goBack}
          translations={config.translations}
          subNavBGColour={config.subNavBGColour}
          subNavBorderColour={config.subNavBorderColour}
          subNavFontTheme={
            config.subNavFont && themeFromProps(config.subNavFont)
          }
          subNavChevronColour={config.subNavChevronColour}
          subNavBarItemSelectedBackgroundColour={
            config.subNavBarItemSelectedBackgroundColour
          }
          subNavBarItemSelectedFontColour={
            config.subNavBarItemSelectedFontColour
          }
          contentWidth={globalStyling.contentWidth}
          showNavBarItemBorder={config.showNavBarItemBorder}
          subNavHeight={config.subNavHeight}
          alignMenuItems={config.alignMenuItems}
          navBarBottomBorder={config.navBarBottomBorder}
          globalStyling={globalStyling}
        />
        <ContentWrapper contentWidth={globalStyling.contentWidth}>
          <ShortlistedVehicles
            translations={config.translations}
            vehicles={shortlistedVehicles}
            placeholdingImage={shared.shortlist.placeholdingImage}
            representativeExample={representativeExample}
            sortOptions={optionsForSort}
            sortValue={this.state.sortField.value}
            sortValueDictionary={sortValueDictionary}
            onSortChange={this.onSortChange}
            onViewChange={this.onViewChange}
            selectedView={selectedView}
            getCompareIconColour={getCompareIconColour}
            getShortListedIconColour={getShortListedIconColour}
            onMoreInfoClick={onMoreInfoClick}
            onHeartClick={vehicle =>
              dispatch(shortlistActions.toggleVehicle(vehicle))
            }
            onCloseClick={vehicle =>
              dispatch(shortlistActions.toggleVehicle(vehicle))
            }
            onCompareClick={vehicle =>
              dispatch(compareActions.toggleVehicle(vehicle))
            }
            compareVehicle={vehicle =>
              dispatch(compareActions.toggleVehicle(vehicle))
            }
            shortlistVehicle={vehicle =>
              dispatch(shortlistActions.toggleVehicle(vehicle))
            }
            globalStyling={globalStyling}
            visibility={config.visibility}
            locale={marketInfo.locale}
            headingFont={config.headingFont}
            subHeadingFont={config.subHeadingFont}
            headingAlignment={config.headingAlignment}
            preview={preview}
            headingBottomLineDisable={config.headingBottomLineDisable}
            financeWidthOverride={config.financeWidthOverride}
            financeHeadingFont={config.financeHeadingFont}
            vehicleSpecContainer={config.vehicleSpecContainer}
            icon360={config.icon360}
          />
        </ContentWrapper>
      </div>
    );
  };
}

// @flow
import React from 'react';
import styled from 'styled-components';
import IoNavicon from 'react-icons/lib/io/navicon';
import IoLocked from 'react-icons/lib/io/locked';

import DragList from '../DragList';
import CheckboxSwitch from '../CheckboxSwitch';
import { updateItem } from './items-helper';

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHandle = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};
`;

const ItemLabel = styled.div`
  display: flex;
  align-items: center;
`;

export type Item = {
  label: string,
  id: string,
  active: boolean,
  locked: boolean,
};

type Props = {
  name: string,
  value: Item[],
  onChange: (items: Item[]) => void,
};

export default function DraggableListEditor(props: Props) {
  const { value: items, onChange, name } = props;
  return (
    <DragList
      items={items}
      renderItem={(RenderHandle, item) => (
        <ItemRow>
          <ItemLabel>
            <RenderHandle disabled={item.locked} />
            {item.label}
          </ItemLabel>
          <CheckboxSwitch
            name={`${name}-toggle-${item.id}`}
            value={item.active}
            onChange={() => onChange(updateItem(item.id, !item.active, items))}
            disabled={item.locked}
            onComponent={
              item.locked ? (
                <IoLocked size={16} color="#FFFFFF" />
              ) : (
                <span>ON</span>
              )
            }
          />
        </ItemRow>
      )}
      renderHandle={({ disabled }) => (
        <StyledHandle disabled={disabled}>
          <IoNavicon size={30} />
        </StyledHandle>
      )}
      onSortEnd={onChange}
    />
  );
}

// @flow
import React from 'react';

import VdpPerformance from '../../components/VdpPerformance';

export interface Performance {
  acceleration: string;
  emissions: string;
  maxSpeed: string;
  maxSpeedUnit: string;
  fuelConsumption: string;
  fuelConsumptionUnit: string;
  maxPower: string;
}

export interface Config {
  performance: Performance;
}

type Props = {
  config: Config,
};
export default function VehiclePerformance({ config }: Props) {
  return <VdpPerformance performance={config.performance} />;
}

// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
};

const StyledBoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${(props: Props) => props.theme.layout.doublePadding};
`;

export default function BoxList(props: Props) {
  return <StyledBoxList {...props} />;
}

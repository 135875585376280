// @flow
import React from 'react';

import { HeadingOne, Paragraph, fontStyleOverride } from '../components/Global';
import { Wrapper, TextWrapper } from '../components';
import type { Font, Padding, Option, Alignment } from '../../types';

interface IHeadingConfig {
  translations: {
    headingText: string,
    bodyText?: string,
  };
  padding: Padding;
  headingFont?: Font;
  bodyFont?: Font;
  backgroundColour: Option;
  alignment: Alignment;
}

type Props = {
  config: IHeadingConfig,
};

export default ({
  config: {
    translations,
    headingFont,
    bodyFont,
    padding,
    backgroundColour,
    alignment,
  },
}: Props) => (
  <Wrapper backgroundColour={backgroundColour}>
    <TextWrapper padding={padding} alignment={alignment}>
      <HeadingOne styleOverride={() => fontStyleOverride(headingFont)}>
        {translations.headingText}
      </HeadingOne>
      {translations.bodyText && (
        <Paragraph styleOverride={() => fontStyleOverride(bodyFont)}>
          {translations.bodyText}
        </Paragraph>
      )}
    </TextWrapper>
  </Wrapper>
);

// @flow
import type { Link, ExternalLinkAction } from '../reducers/externalLink';
import type { Error } from '../types/error';

const LoadLinks: 'LOAD LINKS' = 'LOAD LINKS';
export type LoadLinksAction = {
  type: typeof LoadLinks,
  payload: { siteId: string },
};
const loadLinks = (siteId: string): LoadLinksAction => ({
  type: LoadLinks,
  payload: { siteId },
});

const LoadLinksFailure: 'LOAD LINKS FAILURE' = 'LOAD LINKS FAILURE';
export type LoadLinksFailureAction = {
  type: typeof LoadLinksFailure,
  payload: Error,
};
const loadLinksFailure = (payload: Error): LoadLinksFailureAction => ({
  type: LoadLinksFailure,
  payload,
});

const LoadLinksSuccess: 'LOAD LINKS SUCCESS' = 'LOAD LINKS SUCCESS';
export type LoadLinksSuccessAction = {
  type: typeof LoadLinksSuccess,
  payload: Link[],
};
const loadLinksSuccess = (links: Link[]): LoadLinksSuccessAction => ({
  type: LoadLinksSuccess,
  payload: links,
});

const SetLinkInContext: 'SET LINK CONTEXT' = 'SET LINK CONTEXT';
export type SetLinkInContextAction = {
  type: typeof SetLinkInContext,
  payload: { link: Link, linkAction: ExternalLinkAction },
};
const setLinkInContext = (
  link: Link,
  linkAction: ExternalLinkAction,
): SetLinkInContextAction => ({
  type: SetLinkInContext,
  payload: { link, linkAction },
});

const FieldChangeLink: 'FIELD CHANGE LINK' = 'FIELD CHANGE LINK';
export type FieldChangeLinkAction = {
  type: typeof FieldChangeLink,
  payload: Link,
};
const fieldChangeLink = (link: Link): FieldChangeLinkAction => ({
  type: FieldChangeLink,
  payload: link,
});

const SaveLink: 'SAVE LINK' = 'SAVE LINK';
export type SaveLinkAction = {
  type: typeof SaveLink,
  payload: {
    link: Link,
    siteId: string,
    onChange?: Function,
    event?: Event,
  },
};
const saveLink = (
  link: Link,
  siteId: string,
  onChange?: Function,
  event?: Event,
): SaveLinkAction => ({
  type: SaveLink,
  payload: { link, siteId, onChange, event },
});

const SaveLinkFailure: 'SAVE LINK FAILURE' = 'SAVE LINK FAILURE';
export type SaveLinkFailureAction = {
  type: typeof SaveLinkFailure,
  payload: Error,
};
const saveLinkFailure = (payload: Error): SaveLinkFailureAction => ({
  type: SaveLinkFailure,
  payload,
});

const SaveLinkSuccess: 'SAVE LINK SUCCESS' = 'SAVE LINK SUCCESS';
export type SaveLinkSuccessAction = {
  type: typeof SaveLinkSuccess,
  payload: Link,
};
const saveLinkSuccess = (link: Link): SaveLinkSuccessAction => ({
  type: SaveLinkSuccess,
  payload: link,
});

const DeleteLink: 'DELETE LINK' = 'DELETE LINK';
export type DeleteLinkAction = {
  type: typeof DeleteLink,
  payload: { link: Link, siteId: string },
};
const deleteLink = (link: Link, siteId: string): DeleteLinkAction => ({
  type: DeleteLink,
  payload: { link, siteId },
});

const DeleteLinkFailure: 'DELETE LINK FAILURE' = 'DELETE LINK FAILURE';
export type DeleteLinkFailureAction = {
  type: typeof DeleteLinkFailure,
  payload: Error,
};
const deleteLinkFailure = (payload: Error): DeleteLinkFailureAction => ({
  type: DeleteLinkFailure,
  payload,
});

const DeleteLinkSuccess: 'DELETE LINK SUCCESS' = 'DELETE LINK SUCCESS';
export type DeleteLinkSuccessAction = {
  type: typeof DeleteLinkSuccess,
  payload: string,
};
const deleteLinkSuccess = (id: string): DeleteLinkSuccessAction => ({
  type: DeleteLinkSuccess,
  payload: id,
});

export const actions = {
  loadLinks,
  loadLinksSuccess,
  loadLinksFailure,
  setLinkInContext,
  fieldChangeLink,
  saveLink,
  saveLinkFailure,
  saveLinkSuccess,
  deleteLink,
  deleteLinkFailure,
  deleteLinkSuccess,
};

export const constants = {
  LoadLinks,
  LoadLinksSuccess,
  LoadLinksFailure,
  SetLinkInContext,
  FieldChangeLink,
  SaveLink,
  SaveLinkFailure,
  SaveLinkSuccess,
  DeleteLink,
  DeleteLinkFailure,
  DeleteLinkSuccess,
};

export type Action =
  | LoadLinksAction
  | LoadLinksFailureAction
  | LoadLinksSuccessAction
  | SetLinkInContextAction
  | FieldChangeLinkAction
  | SaveLinkAction
  | SaveLinkFailureAction
  | SaveLinkSuccessAction
  | DeleteLinkAction
  | DeleteLinkFailureAction
  | DeleteLinkSuccessAction;

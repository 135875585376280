// @flow
import React from 'react';
import ReactMarkdown from 'react-markdown';

import {
  HeadingOne,
  HeadingTwo,
  Paragraph,
  fontStyleOverride,
} from '../Global';
import { Wrapper, TextWrapper } from '..';
import type { Font, Padding, Option } from '../../../types';

interface ITextConfig {
  bodyText: string;
  padding: Padding;
  bodyFont?: Font;
  alignment: string;
  backgroundColour: Option;
}

type Props = {
  config: ITextConfig,
};

type MarkdownProps = {
  level?: number,
  children?: Node,
};

const disallowedTypes = [
  'delete',
  'linkReference',
  'imageReference',
  'table',
  'tableHead',
  'tableBody',
  'tableRow',
  'tableCell',
  'definition',
  'html',
  'virtualHtml',
  'parsedHtml',
  'listItem',
];
export default ({
  config: { bodyText, bodyFont, padding, alignment, backgroundColour },
}: Props) => {
  const MarkdownRenderers = {
    paragraph: (props: MarkdownProps) => (
      <Paragraph
        styleOverride={() => `${fontStyleOverride(bodyFont)} display: inline;`}
      >
        {props.children}
      </Paragraph>
    ),
    heading: (props: MarkdownProps) => {
      switch (props.level) {
        case 2:
          return <HeadingTwo>{props.children}</HeadingTwo>;
        default:
          return <HeadingOne>{props.children}</HeadingOne>;
      }
    },
  };

  return (
    <Wrapper backgroundColour={backgroundColour}>
      {bodyText && (
        <TextWrapper padding={padding} alignment={alignment}>
          <ReactMarkdown
            renderers={MarkdownRenderers}
            disallowedTypes={disallowedTypes}
            escapeHtml={false}
            unwrapDisallowed
          >
            {bodyText}
          </ReactMarkdown>
        </TextWrapper>
      )}
    </Wrapper>
  );
};

// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Wrapper } from '../components';
import { getLocale } from '../../shared/selectors/settings';
import { vehiclePropFormatter } from '../../helpers/vehicle';

const SpecContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Spec = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
`;

const SpecLabel = styled.div`
  display: flex;
  flex: 0 0 50%;
  font-weight: bold;
`;

const SpecValue = styled.div`
  display: flex;
  flex: 0 0 50%;
`;

const specificationSelector = state => {
  if (!state.dataProvider.vehicleInfo) {
    return {};
  }

  return state.dataProvider.vehicleInfo.specification;
};

type VDPSpecProperty = {
  prop: string,
  translation: string,
  format?: string,
};

interface IVDPSpecsConfig {
  translations: Object;
  properties: VDPSpecProperty[];
}

type Props = {
  config: IVDPSpecsConfig,
};

export default (props: Props) => {
  const specification = useSelector(specificationSelector);
  const locale = useSelector(getLocale);
  const {
    config: { properties, translations },
  } = props;

  return (
    Object.keys(specification).length && (
      <Wrapper>
        <SpecContainer>
          {properties
            .map(p => ({
              label: translations[p.translation],
              value: vehiclePropFormatter(p, specification, locale),
            }))
            .filter(i => i.value !== undefined)
            .map(({ label, value }) => (
              <Spec key={`${label}${value}`}>
                <SpecLabel>{label}</SpecLabel>
                <SpecValue>{value}</SpecValue>
              </Spec>
            ))}
        </SpecContainer>
      </Wrapper>
    )
  );
};

export default {
  translations: {
    registerHeader: 'register',
    registerContentHeader: 'Complete your details to create an account',
    registerTitleLabel: 'Title',
    registerFirstNameLabel: 'First Name',
    registerLastNameLabel: 'Last Name',
    registerEmailLabel: 'Email',
    registerEmailConfirmLabel: 'Confirm Email',
    registerPhoneLabel: 'Phone',
    registerPostcodeLabel: 'Postcode',
    registerVehicleInterestLabel: 'Vehicle of Interest',
    registerCurrentVehicleLabel: 'Current Vehicle',
    registerCurrentVehiclePlaceholder: 'Make/Model',
    registerPasswordLabel: 'Password',
    registerRepeatPasswordLabel: 'Repeat Password',
    registerTermsCondsAccept: 'I accept the Terms & Conditions',
    registerButton: 'register',
    registerFailure: 'Something went wrong, please try again later.',
    emailVerifyHeader: "You're Almost Done...",
    emailVerifyEmailSent:
      'A verification email has been sent to the address below. Open this email and click the link to verify your account.',
    emailVerifyNavigationText:
      'Once you verify your account, use your email address and password to ',
    emailVerifyNavigationTextLink: 'login',
  },
};

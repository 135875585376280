// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Refresh({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg
        viewBox="0 0 24 24"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="1em"
        height="1em"
      >
        <path
          d="M12.938 2C7.543 2 3.15 6.225 2.899 11.524H0l4.348 5.635 4.277-5.635H5.774c.25-3.716 3.362-6.667 7.163-6.667 3.964 0 7.188 3.204 7.188 7.143 0 3.939-3.224 7.143-7.188 7.143a7.188 7.188 0 0 1-5.63-2.718l-1.77 2.332a10.052 10.052 0 0 0 7.4 3.243C18.495 22 23 17.522 23 12S18.494 2 12.937 2z"
          fill={colour}
        />
      </svg>
    </IconWrapper>
  );
}

Refresh.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

/* eslint-disable */
import { delay } from 'redux-saga';
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import { mapGroupAndFinance as getGroupFinance } from '../../shared/financeCalculator/effects';
import {
  inventorySearchServiceUrl,
  getModelGroupsBaseUrl,
  getAVLConfig,
  appendLocaleQueryString,
  getFinanceStatus,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

let modelGroupsCache;

function* mapAffordableGroupAndFinance(group, page, instance) {
  try {
    const groupWithFinance = yield getGroupFinance(group);
    yield put(actions.getFinanceSuccess(page, instance, groupWithFinance));
  } catch (error) {
    // noop leave group as is
  }
}

function* getModelGroups(action) {
  const {
    module: { page, instance },
  } = action;
  try {
    const { locale, make, country, market } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const financeStatus = yield select(getFinanceStatus);
    const inventoryUrl = `${yield select(
      inventorySearchServiceUrl,
    )}inventory/make/${make}/market/${market}/groups`;
    const modelGroupsUrl = `${yield select(
      getModelGroupsBaseUrl,
    )}${make.replace(' ', '')}/${country}/${locale}/model_explore`;

    const updateAfterLanguageChange =
      modelGroupsCache &&
      modelGroupsCache.length > 0 &&
      modelGroupsCache[0].locale !== locale
        ? true
        : false;
    const modelGroupsData =
      modelGroupsCache && !updateAfterLanguageChange
        ? [yield { data: modelGroupsCache }]
        : [
            call(get, {
              url: modelGroupsUrl,
            }),
          ];

    const [{ data: inventory }, { data: modelGroups }] = yield all([
      call(get, {
        url: inventoryUrl,
        ...(withLocaleQueryString && {
          config: localeConfigSerializer(locale),
        }),
      }),
      ...modelGroupsData,
    ]);

    if (!modelGroupsCache) {
      modelGroupsCache = modelGroups;
    }

    const result = modelGroups
      .sort((a, b) => a.position - b.position)
      .map(
        ({
          modelGroupName,
          imageDomain,
          modelGroupDescription,
          externalUrl,
        }) => {
          const {
            minimumPrice,
            count,
            apr,
            financePrice,
            isModelAvailable = true,
            finance,
            financeVehicleId,
            modelDisplay,
          } = inventory.contents.find(
            ({ model }) =>
              model.replace(' ', '').toLowerCase() ===
              modelGroupName.replace(' ', '').toLowerCase(),
          ) || { isModelAvailable: false };
          return {
            model: modelGroupName,
            priceFrom: minimumPrice,
            imageUrl: imageDomain,
            availability: count,
            description: modelGroupDescription,
            externalUrl,
            apr,
            financePrice,
            isModelAvailable,
            finance,
            financeVehicleId,
            modelDisplay,
          };
        },
      );

    yield put(
      actions.getModelGroupsSuccess(page, instance, { modelGroups: result }),
    );
    if (financeStatus) {
      yield all(
        result.map(group =>
          call(mapAffordableGroupAndFinance, group, page, instance),
        ),
      );
    }
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* ExploreRangeSaga() {
  yield takeEvery(constants.GET_MODEL_GROUPS, getModelGroups);
}

// @flow

import React from 'react';
import styled from 'styled-components';
import media from '../theme';

import { getWarrantyLabel } from '../helpers/getWarrantyLabel';
import { HeadingThree, Paragraph, fontStyleOverride } from './Global';

const WellFeaturesItemsContainer = styled.div`
  ${media.min.large`
    display: flex;
    flex: 1;
    width: 100%;
    @supports (display: grid) {
      display: grid;
      grid-template-columns: ${({ warrantyYears, combinedConsumption }) =>
        warrantyYears &&
        warrantyYears > 0 &&
        combinedConsumption &&
        combinedConsumption > 0
          ? '1fr 1fr 1fr'
          : '1fr 1fr'};
      grid-column-gap: 40px;
      grid-row-gap: 20px;
    }
  `};
`;

const WellFeaturesItem = styled.div`
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  display: flex;
  margin: 20px 0;
  padding: 20px;
  ${media.min.large`
    width: 33.3%;
    @supports (display: grid) {
      margin: 0;
      width: unset;
    }
  `};
`;

const WellFeaturesItemIcon = styled.img`
  margin-right: 10px;
  width: 40px;
`;

type Props = {
  specification: any,
  featuresList: Object,
  combinedConsumption: string,
  translations: Object,
};

function VdpFeatures({
  featuresList,
  specification,
  combinedConsumption,
  translations,
}: Props) {
  return (
    <WellFeaturesItemsContainer
      warrantyYears={specification.warranty.remainingWarrantyWholeYears}
      combinedConsumption={combinedConsumption}
    >
      {specification.warranty.remainingWarrantyWholeYears > 0 && (
        <WellFeaturesItem>
          <WellFeaturesItemIcon
            src={featuresList.warrantyIcon.value}
            alt={featuresList.warrantyIcon.label}
          />
          <div>
            <Paragraph
              styleOverride={() => `
              ${fontStyleOverride(featuresList.itemHeading)}
              margin: 5px 0;
            `}
            >
              {translations.warrantyHeading}
            </Paragraph>
            <HeadingThree
              styleOverride={() => `
              ${fontStyleOverride(featuresList.value)}
              margin: 0;
            `}
            >
              {getWarrantyLabel(
                specification.warranty.remainingWarrantyWholeYears,
                translations.warrantyYearsLabel,
                translations.warrantyYearsLabel2to4,
              )}
            </HeadingThree>
          </div>
        </WellFeaturesItem>
      )}

      {specification.odometer.display && (
        <WellFeaturesItem>
          <WellFeaturesItemIcon
            src={featuresList.odometerIcon.value}
            alt={featuresList.odometerIcon.label}
          />
          <div>
            <Paragraph
              styleOverride={() => `
              ${fontStyleOverride(featuresList.itemHeading)}
              margin: 5px 0;
            `}
            >
              {translations.mileageHeading}
            </Paragraph>
            <HeadingThree
              styleOverride={() => `
              ${fontStyleOverride(featuresList.value)}
              margin: 0;
            `}
            >
              {specification.odometer.display}
            </HeadingThree>
          </div>
        </WellFeaturesItem>
      )}

      {combinedConsumption > 0 && (
        <WellFeaturesItem>
          <WellFeaturesItemIcon
            src={featuresList.consumptionCombinedIcon.value}
            alt={featuresList.consumptionCombinedIcon.label}
          />
          <div>
            <Paragraph
              styleOverride={() => `
              ${fontStyleOverride(featuresList.itemHeading)}
              margin: 5px 0;
            `}
            >
              {translations.consumptionCombined}
            </Paragraph>
            <HeadingThree
              styleOverride={() => `
              ${fontStyleOverride(featuresList.value)}
              margin: 0;
            `}
            >
              {combinedConsumption} {translations.mpgLabel}
            </HeadingThree>
          </div>
        </WellFeaturesItem>
      )}
    </WellFeaturesItemsContainer>
  );
}

export default VdpFeatures;

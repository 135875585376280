// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${(props: { theme: Theme }) => props.theme.colours.lowlight};
`;

const Box = styled.div.withConfig({
  displayName: 'Box',
})`
  width: 24px;
  height: 24px;
  background-color: ${({ value }) => value};
  border: 1px solid
    ${(props: { theme: Theme }) => props.theme.colours.secondary03};
  box-shadow: 0px 0px 0px 3px
    ${(props: { theme: Theme }) => props.theme.colours.highlight02} inset;
  min-width: 24px;
`;

const Label = styled.span.withConfig({
  displayName: 'Label',
})`
  font-family: ${(props: { theme: Theme }) => props.theme.fontFamily};
  font-size: ${(props: { theme: Theme }) => props.theme.fontSizeContent};
  color: ${(props: { theme: Theme }) => props.theme.colours.highlight01};
  text-align: right;
  margin-right: 10px;
  min-width: 35px;
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`;

type Props = {
  label: string,
  value: string,
};

export default function ColourField({ label, value }: Props) {
  return (
    <Container>
      <Label>{label}</Label>
      <Box value={value} />
    </Container>
  );
}

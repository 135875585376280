// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

type Props = {
  children: Node,
};

const StyledSubSection = styled.div`
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  box-sizing: border-box;
`;

export default function SubSection(props: Props) {
  return <StyledSubSection>{props.children}</StyledSubSection>;
}

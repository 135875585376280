// @flow
import React, { type Node, Fragment } from 'react';
import styled from 'styled-components';
import { Button, HeadingThree } from './Global';
import { localiseCurrency } from '../shared/localisation/numbers';
import Heart from './JaguarIcons/Global/Heart';
import Compare from './JaguarIcons/Global/Compare';
import Icon from './StyledIcon';
import {
  ImageTileSection,
  VehicleTitle,
  VehicleSubTitle,
  ExteriorSection,
  BespokeFeatureSection,
  IconWithLabel,
  RetailerSection,
} from './SearchResultGridBlock';

const TileItemContainer = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor && `${backgroundColor}`};
  display: flex;
  flex-direction: column;
  border: 1px solid #CCCCCC;
  flex: 1 0 0%;
  height:100%;
  justify-content: flex-start;
}
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const HorizontalLine = styled.hr`
  display: ${({ visible }) => (visible === true ? 'block' : 'none')};
  height: 1px;
  border: 0;
  border-top: 1px solid #dedede;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const MoreInfoRow = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
`;

const FinanceRow = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  max-width: 100%;
  padding: 8px 0 8px 16px;
  &:first-child {
    padding-left: 24px;
  }
`;

export type ItemTheme = {
  height?: number,
  align: string,
};

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0 8px 16px;
  ${({ badgeBackgroundColour }) =>
    badgeBackgroundColour &&
    `
    background-color: ${badgeBackgroundColour};
    color:#FFFFFF;`};
`;

const VehicleSpecs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const PriceWrapper = styled.div`
  text-align: left;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const ActionsContainer = styled.div`
  display: flex;
  width: 60px;
  justify-content: space-between;
`;

const TitleRow = styled.div`
  display: flex;
  padding-top: 8px;
  line-height: 16px;
`;

const VdpButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  config: Object,
  align: string,
  vehicle: Object,
  placeholdingImage: string,
  checkLength: boolean,
  onMoreInfoClicked: Function,
  translations: Object,
  shortList: boolean,
  renderTopRightIcon: () => Node,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  tileBackgroundColor: Object,
  showLinearBackground: boolean,
  headerItemFont: Object,
  vehicleModelTitleFontWeight: number,
  compareVehicle: Function,
  shortlistVehicle: Function,
  shortListedIconColour: string,
  compareIconColour: string,
  headerItemFont: Object,
  badgeStyling: Object,
  handleZeroPriceAsText: boolean,
  showNumberOfImagesInsteadOfViews: boolean,
};

export default function SearchResultGridTile({
  config,
  align,
  vehicle,
  placeholdingImage,
  translations,
  renderTopRightIcon,
  compareVehicle,
  shortlistVehicle,
  shortListedIconColour,
  compareIconColour,
  onMoreInfoClicked,
  globalStyling,
  visibility,
  locale,
  headerItemFont,
  badgeStyling,
  handleZeroPriceAsText,
  showNumberOfImagesInsteadOfViews,
}: Props) {
  const { status } = vehicle;

  const imageSrc =
    vehicle.images.length > 0 ? vehicle.images[0] : placeholdingImage;
  const badge =
    badgeStyling &&
    badgeStyling.badgeVariant &&
    vehicle.modelVariant &&
    vehicle.modelVariant.toLowerCase() ===
      badgeStyling.badgeVariant.toLowerCase();
  const bespokeFeaturesColour = badge ? '#FFFFFF' : '#00AAEE';
  const starIcon = badge
    ? badgeStyling && badgeStyling.whiteStarIcon
    : config.starIcon;
  const showModelAndVariant =
    badgeStyling &&
    badgeStyling.displayModelAndVariant &&
    vehicle.modelVariant !== null;
  const localisePrice = localiseCurrency(
    vehicle.price.value,
    locale,
    vehicle.price.currency,
    0,
  );
  const handleZeroPrice =
    vehicle.price.value === 0 ? translations.zeroPriceText : localisePrice;
  const handlePrice = handleZeroPriceAsText ? handleZeroPrice : localisePrice;
  const displayExteriorSection =
    vehicle.exterior || vehicle.interior || vehicle.veneer;
  return (
    <TileItemContainer data-qa-hook="vehicle-tile" backgroundColor="white">
      <TitleContainer
        badgeBackgroundColour={
          badge && badgeStyling && badgeStyling.badgeBackgroundColour
        }
      >
        <TitleRow>
          <VehicleTitle
            vehicle={vehicle}
            titleFont={headerItemFont && headerItemFont.titleFont}
            subtitleFont={headerItemFont && headerItemFont.subtitleFont}
            visible={visibility}
            badge={badge}
            globalStyling={globalStyling}
            badgeStyling={badgeStyling}
            displayModelAndVariant={showModelAndVariant}
          />
        </TitleRow>
        <TitleRow>
          <VehicleSubTitle
            vehicle={vehicle}
            locale={locale}
            titleFont={headerItemFont && headerItemFont.titleFont}
            subtitleFont={headerItemFont && headerItemFont.subtitleFont}
            visible={visibility}
            badge={badge}
            globalStyling={globalStyling}
            badgeStyling={badgeStyling}
          />
        </TitleRow>
        <TitleRow>
          <BadgeWrapper>
            {vehicle.modelCollectionName && (
              <IconWithLabel
                text={vehicle.modelCollectionName}
                styleOverride={() => `
            font-size: 14px;
            padding-left:0px;
            color:${bespokeFeaturesColour};`}
              >
                <Icon link={starIcon} width={16} height={16} />
              </IconWithLabel>
            )}
          </BadgeWrapper>
        </TitleRow>
      </TitleContainer>

      {status === 'sold' && renderTopRightIcon()}

      <ImageTileSection
        vehicle={vehicle}
        imageSrc={imageSrc}
        translations={translations}
        onMoreInfoClick={onMoreInfoClicked}
        width="100%"
        showNumberOfImages={showNumberOfImagesInsteadOfViews}
        icon={config.imagesIcon}
      />
      <BottomContainer align={align}>
        <FinanceRow>
          <PriceWrapper>
            <HeadingThree>{handlePrice}</HeadingThree>
          </PriceWrapper>
        </FinanceRow>
        <HorizontalLine visible />
        {displayExteriorSection && (
          <Fragment>
            <FinanceRow>
              <VehicleSpecs>
                <ExteriorSection
                  vehicle={vehicle}
                  translations={translations}
                  icon={config.coloursIcon}
                />
              </VehicleSpecs>
            </FinanceRow>
            <HorizontalLine visible />
          </Fragment>
        )}
        {vehicle.bespokeFeatures && (
          <Fragment>
            <FinanceRow>
              <BespokeFeatureSection
                vehicle={vehicle}
                icon={config.designIcon}
              />
            </FinanceRow>
            <HorizontalLine visible />
          </Fragment>
        )}
        {visibility.showDistance && (
          <Fragment>
            <FinanceRow>
              <RetailerSection
                vehicle={vehicle}
                icon={config.pinIcon}
                translations={translations}
              />
            </FinanceRow>
            <HorizontalLine visible />
          </Fragment>
        )}
        <MoreInfoRow>
          <Fragment>
            <ActionsContainer>
              <IconWithLabel
                styleOverride={() => 'cursor: pointer;'}
                qaHook="compare-vehicle-button"
                text={translations.searchResultsAddToCompare}
                onClickFn={() => compareVehicle(vehicle)}
                hideOnMobile
              >
                <Compare
                  width="24px"
                  height="24px"
                  colour={compareIconColour}
                />
              </IconWithLabel>
              <IconWithLabel
                styleOverride={() => 'cursor: pointer;'}
                qaHook="shortlist-vehicle-button"
                text={translations.searchResultsSaveVehicle}
                onClickFn={() => shortlistVehicle(vehicle)}
              >
                <Heart
                  width="24px"
                  height="24px"
                  colour={shortListedIconColour}
                />
              </IconWithLabel>
            </ActionsContainer>
            {status !== 'sold' && (
              <VdpButtonWrapper>
                <Button
                  data-qa-hook="more-info-button"
                  onClick={status !== 'sold' && onMoreInfoClicked}
                  text={translations.searchResultTileMoreInfo}
                  buttonStyle={
                    globalStyling.uiElements.primaryButton &&
                    globalStyling.uiElements.primaryButton.buttonStyle
                  }
                  applyStyle="primary"
                />
              </VdpButtonWrapper>
            )}
          </Fragment>
        </MoreInfoRow>
      </BottomContainer>
    </TileItemContainer>
  );
}

SearchResultGridTile.defaultProps = {
  onMoreInfoClicked: () => {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  images: [],
  renderTopRightIcon: () => {},
  price: {},
  buttonTheme: {
    fontFamily: 'inherit',
  },
};

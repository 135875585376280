// @flow
import React from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';
import type { Theme } from '../theme';

type Props = {
  input: Object,
  options: Object,
};

type ContainerProps = {
  theme: Theme,
};

const SelectAllContainer = styled.div`
  border-bottom: 1px solid
    ${(props: ContainerProps) => props.theme.colours.secondary02};
  margin: 10px 0;
`;

export default function CheckboxGroupSelectAll({ input, options }: Props) {
  const value = input.value || [];
  const checked = value.length === options.length;

  const selectAll = newChecked =>
    input.onChange(newChecked ? [...options] : []);

  return (
    <SelectAllContainer>
      <Checkbox checked={checked} onChange={selectAll} label="Select All" />
    </SelectAllContainer>
  );
}

// Logic for Poland whereby an extra translation key is required for 'Years'
// when warranty is between 2 and 4 years
export const getWarrantyLabel = (
  remainingWarrantyYears,
  warrantyLabelDefault,
  warrantyLabel2to4,
) =>
  warrantyLabel2to4 && remainingWarrantyYears > 1 && remainingWarrantyYears < 5
    ? `${remainingWarrantyYears} ${warrantyLabel2to4}`
    : `${remainingWarrantyYears} ${warrantyLabelDefault}`;

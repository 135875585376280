// @flow
/* eslint-disable no-return-assign, no-param-reassign */
import React, { Fragment } from 'react';
import styled from 'styled-components';

import FooterNavigation from '../../components/FooterNavigation';
import FooterBottomNavigation from '../../components/FooterBottomNavigation';
import SocialMediaLinks from '../../components/SocialMediaLinks';
import type { Option, Font, SectionStyles, Item } from '../../types';
import media from '../../theme';
import { HeadingTwo, fontStyleOverride } from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';

const justifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Link = styled.a`
  text-decoration: none;
  ${({ colour }) => colour && `color: ${colour.value}`};
`;

const Section = styled.div`
  list-style-type: none;
  align-items: center;
  display: flex;
  ${({ borderTop }) => `${borderTop && `border-top: ${borderTop}`}`};
  ${({ borderBottom }) =>
    `${borderBottom && `border-bottom: ${borderBottom}`}`};
  justify-content: ${(props: SectionStyles) =>
    props.align ? justifyContent[props.align] : justifyContent.left};
  background-color: ${(props: SectionStyles) => props.backgroundColour};
  ${media.min.large`
    height: ${(props: SectionStyles) => props.height}px;
  `} ${media.max.large`
    padding: 10px;
  `};
`;

const Image = styled.img`
  height: ${props => props.height}px;
  margin: 16px 0;
`;

const SectionWithTextAlign = styled(Section)`
  text-align: ${({ align }) => align || 'left'};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  white-space: pre-line;
  overflow: hidden;
  flex-flow: column;
`;

const Links = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
`;

const FooterContainer = styled.div`
  ${({ height }) => `${height && `height: ${height}px`}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.max.large`
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const FooterWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export interface Config {
  topSectionPosition: string;
  topActive: boolean;
  topHeight: number;
  topComponents: string[];
  topItems: { items: Item[] };
  topBGColour: Option;
  topLinkFont: Font;
  topLinkHoverColour: Option;
  midHeight: number;
  midComponents: string[];
  midBGColour: Option;
  urlFacebook: string;
  urlTwitter: string;
  urlInstagram: string;
  urlYouTube: string;
  urlLinkedIn: string;
  socialIconsColour: Option;
  bottomComponents: string[];
  bottomHeight: number;
  bottomFont: Font;
  bottomBGColour: Option;
  topTierAlign: string;
  midTierAlign: string;
  bottomTierAlign: string;
  bottomLinkText: { url: string, label: string };
  bottomLinkFont: Font;
  bottomImage: { height: number, url: string, fallbackUrl: string };
  translations: Object;
  topTierBorderTopColour: Option;
  topTierBorderTopHeight: number;
  midTierBorderTopColour: Option;
  midTierBorderTopHeight: number;
  bottomTierBorderTopColour: Option;
  bottomTierBorderTopHeight: number;
  showAdditionalTopSection: boolean;
}

type Props = {
  config: Config,
  preview: boolean,
  navigateIfInternal: (link: string) => boolean,
  globalStyling: Object,
};

export default function Footer({
  config,
  preview,
  navigateIfInternal,
  globalStyling,
}: Props) {
  const pageBackgroundColour =
    globalStyling.colours.pageBackgroundColour &&
    globalStyling.colours.pageBackgroundColour.value;
  const sectionBackgroundColour = colour => colour || pageBackgroundColour;
  const extractColour = colour => colour && colour.value;
  const oneRowFooter = () => (
    <ContentWrapper
      colour={config.topBGColour}
      contentWidth={globalStyling.contentWidth}
    >
      <FooterContainer height={config.topHeight}>
        <FooterNavigation
          theme={{
            font: config.topLinkFont,
            hoverColour: config.topLinkHoverColour,
          }}
          items={config.topItems.items.map((value, idx) => ({
            ...value,
            label: config.translations[`topItems-${idx}`],
          }))}
          preview={preview}
          navigateIfInternal={navigateIfInternal}
          oneRow
        />
        <SocialMediaLinks
          {...config}
          socialIconsColour={config.socialIconsColour.value}
          preview={preview}
          facebookIconLink={config.facebookIconLink}
          twitterIconLink={config.twitterIconLink}
          youtubeIconLink={config.youtubeIconLink}
          instagramIconLink={config.instagramIconLink}
          linkedinIconLink={config.linkedinIconLink}
        />
      </FooterContainer>
    </ContentWrapper>
  );
  return (
    <FooterWrapper>
      {config.oneRowFooter ? (
        oneRowFooter()
      ) : (
        <Fragment>
          {config.showAdditionalTopSection && (
            <ContentWrapper
              colour={config.topBGColour}
              contentWidth={globalStyling.contentWidth}
            >
              <Section
                height="50"
                backgroundColour={sectionBackgroundColour(
                  extractColour(config.topBGColour),
                )}
                align={config.topTierAlign}
              />
            </ContentWrapper>
          )}
          <ContentWrapper
            colour={config.topBGColour}
            contentWidth={globalStyling.contentWidth}
          >
            <Section
              height={config.topHeight}
              backgroundColour={sectionBackgroundColour(
                extractColour(config.topBGColour),
              )}
              align={config.topTierAlign}
              borderTop={`${
                config.topTierBorderTopHeight
              }px solid ${config.topTierBorderTopColour &&
                config.topTierBorderTopColour.value}`}
            >
              <FooterNavigation
                theme={{
                  font: config.topLinkFont,
                  hoverColour: config.topLinkHoverColour,
                }}
                align={config.topTierAlign}
                items={config.topItems.items.map((value, idx) => ({
                  ...value,
                  label: config.translations[`topItems-${idx}`],
                }))}
                preview={preview}
                navigateIfInternal={navigateIfInternal}
              />
            </Section>
          </ContentWrapper>
          <ContentWrapper
            colour={config.midBGColour}
            contentWidth={globalStyling.contentWidth}
          >
            <Section
              height={config.midHeight}
              backgroundColour={sectionBackgroundColour(
                extractColour(config.midBGColour),
              )}
              align={config.midTierAlign}
              borderTop={`${
                config.midTierBorderTopHeight
              }px solid ${config.midTierBorderTopColour &&
                config.midTierBorderTopColour.value}`}
            >
              <SocialMediaLinks
                {...config}
                socialIconsColour={config.socialIconsColour.value}
                preview={preview}
              />
            </Section>
          </ContentWrapper>
          <SectionWithTextAlign
            height={config.bottomHeight}
            visible={config.translations.bottomLinkText}
            backgroundColour={sectionBackgroundColour(
              extractColour(config.bottomBGColour),
            )}
            align={config.bottomTierAlign}
            borderTop={`${
              config.bottomTierBorderTopHeight
            }px solid ${config.bottomTierBorderTopColour &&
              config.bottomTierBorderTopColour.value}`}
          >
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              {config.showBottomImage && config.bottomImage && (
                <Image
                  height={config.bottomImage.height}
                  src={config.bottomImage.url}
                  onError={({ target }) =>
                    (target.src = config.bottomImage.fallbackUrl)
                  }
                />
              )}
              <HeadingTwo
                styleOverride={() => `
                ${fontStyleOverride(config.bottomFont)}
                width: 80%;
                margin: 0 auto;
              `}
              >
                {config.translations.bottomText}
              </HeadingTwo>
              {config.bottomItems ? (
                <FooterBottomNavigation
                  theme={{
                    font: config.bottomLinkFont,
                    hoverColour: config.bottomLinkHoverColour,
                  }}
                  items={config.bottomItems.items.map((value, idx) => ({
                    ...value,
                    label: config.translations[`bottomItems-${idx}`],
                  }))}
                  preview={preview}
                  navigateIfInternal={navigateIfInternal}
                />
              ) : (
                <Links>
                  {config.translations.bottomLinkText && (
                    <Link
                      href={config.bottomLinkText.url}
                      onClick={event => {
                        if (
                          preview ||
                          navigateIfInternal(config.bottomLinkText.url)
                        ) {
                          event.preventDefault();
                        }
                      }}
                    >
                      <HeadingTwo
                        styleOverride={() =>
                          fontStyleOverride(config.bottomLinkFont)
                        }
                      >
                        {config.translations.bottomLinkText}
                      </HeadingTwo>
                    </Link>
                  )}
                </Links>
              )}
            </ContentWrapper>
          </SectionWithTextAlign>
        </Fragment>
      )}
    </FooterWrapper>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import InputField from 'cms-ui/dist/components/InputField';

import { components } from 'cms-modules';
import { TextTransform } from 'cms-ui';

import ColourSelector from './ColourSelector/ColourSelector';
import Selector from './Selector/Selector';

const Container = styled.div`
  padding: 25px;
  width: 790px;
`;

const Heading = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #474747;
  margin-bottom: 25px;
`;

const SubHeading = styled.div`
  font-size: 13px;
  color: #757575;
  margin-bottom: 5px;
`;

const OptionContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 25px 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flext-start;
  width: 100%;
  height: auto;
  padding: 15px 0;
`;

const Item = styled.div`
  margin-right: 15px;
`;

const ItemTitle = styled.div`
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  color: #757575;
  margin-bottom: 3px;
`;

const TopNote = styled.div`
  margin-bottom: 25px;
  color: #656565;
  font-size: 13px;
`;

type Props = {
  brandColours: {
    availableColours: Object[],
    primaryBackgroundColour: Object,
    primaryForegroundColour: Object,
    primaryBorderColour: Object,
    primaryBackgroundHoverColour: Object,
    primaryForegroundHoverColour: Object,
    primaryBorderHoverColour: Object,
    secondaryBackgroundColour: Object,
    secondaryForegroundColour: Object,
    secondaryBorderColour: Object,
    secondaryBackgroundHoverColour: Object,
    secondaryForegroundHoverColour: Object,
    secondaryBorderHoverColour: Object,
    tertiaryBackgroundColour: Object,
    tertiaryForegroundColour: Object,
    tertiaryBorderColour: Object,
    tertiaryBackgroundHoverColour: Object,
    tertiaryForegroundHoverColour: Object,
    tertiaryBorderHoverColour: Object,
  },
  selectBrandColour: (key: string, value: string) => void,
  fonts: Object,
  selectBrandFont: (key: string, font: Object) => void,
  openCloseTextTransform: boolean => void,
  transformOpen: Boolean,
  onChange: (value: string) => void,
  uiElements: Object,
  updateUiElement: (element: string, key: string, value: string) => void,
  updateButtonStyle: (element: string, value: string) => void,
};

export default (props: Props) => {
  const {
    brandColours: {
      availableColours,
      primaryBackgroundColour,
      primaryForegroundColour,
      primaryBorderColour,
      primaryBackgroundHoverColour,
      primaryForegroundHoverColour,
      primaryBorderHoverColour,
      secondaryBackgroundColour,
      secondaryForegroundColour,
      secondaryBorderColour,
      secondaryBackgroundHoverColour,
      secondaryForegroundHoverColour,
      secondaryBorderHoverColour,
      tertiaryBackgroundColour,
      tertiaryForegroundColour,
      tertiaryBorderColour,
      tertiaryBackgroundHoverColour,
      tertiaryForegroundHoverColour,
      tertiaryBorderHoverColour,
    },
    selectBrandColour,
    fonts: {
      availableFonts,
      primaryButtonFont,
      secondaryButtonFont,
      tertiaryButtonFont,
    },
    uiElements: { primaryButton, secondaryButton, tertiaryButton },
    selectBrandFont,
    updateUiElement,
  } = props;

  const actualAvailableFonts = availableFonts.reduce(
    (acc, curr) =>
      acc.some(entry => entry.label === curr.label) ? acc : [...acc, curr],
    [],
  );

  const Button = components.NewButton.default;

  const getStylePrimary = () => `
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      min-width: 150px;
      width: auto;
      min-height: 35px;
      height: ${primaryButton.primaryButtonHeight}px;
      text-transform: ${primaryButton.primaryButtonCasing};
      ${primaryBackgroundColour &&
        `background-color: ${primaryBackgroundColour.value}`};
      ${primaryForegroundColour && `color: ${primaryForegroundColour.value}`};
      ${primaryBorderColour &&
        `border: 1px solid ${primaryBorderColour.value}`};
      ${primaryButtonFont && `font-family: ${primaryButtonFont.value}`};
      svg > rect:not([fill='none']) {
      ${primaryForegroundColour && `fill: ${primaryForegroundColour.value}`};
      }
      svg > path:not([fill]) {
        ${primaryForegroundColour && `fill: ${primaryForegroundColour.value}`};
      }
      &:hover {
        ${primaryBackgroundHoverColour &&
          `background-color: ${primaryBackgroundHoverColour.value}`};
        ${primaryForegroundHoverColour &&
          `color: ${primaryForegroundHoverColour.value}`};
        ${primaryBorderHoverColour &&
          `border: 1px solid ${primaryBorderHoverColour.value}`};
        svg > rect:not([fill='none']) {
        ${primaryForegroundHoverColour &&
          `fill: ${primaryForegroundHoverColour.value}`};
        }
        svg > path:not([fill]) {
          ${primaryForegroundHoverColour &&
            `fill: ${primaryForegroundHoverColour.value}`};
        }
      }
    `;

  const getStyleSecondary = () => `
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      min-width: 150px;
      width: auto;
      min-height: 35px;
      height: ${secondaryButton.secondaryButtonHeight}px;
      text-transform: ${secondaryButton.secondaryButtonCasing};
      ${secondaryBackgroundColour &&
        `background-color: ${secondaryBackgroundColour.value}`};
      ${secondaryForegroundColour &&
        `color: ${secondaryForegroundColour.value}`};
      ${secondaryBorderColour &&
        `border: 1px solid ${secondaryBorderColour.value}`};
      ${secondaryButtonFont && `font-family: ${secondaryButtonFont.value}`};
      svg > rect:not([fill='none']) {
      ${secondaryForegroundColour &&
        `fill: ${secondaryForegroundColour.value}`};
      }
      svg > path:not([fill]) {
        ${secondaryForegroundColour &&
          `fill: ${secondaryForegroundColour.value}`};
      }
      &:hover {
        ${secondaryBackgroundHoverColour &&
          `background-color: ${secondaryBackgroundHoverColour.value}`};
        ${secondaryForegroundHoverColour &&
          `color: ${secondaryForegroundHoverColour.value}`};
        ${secondaryBorderHoverColour &&
          `border: 1px solid ${secondaryBorderHoverColour.value}`};
        svg > rect:not([fill='none']) {
          fill: ${secondaryForegroundHoverColour &&
            `${secondaryForegroundHoverColour.value}`};
        }
        svg > path:not([fill]) {
          fill: ${secondaryForegroundHoverColour &&
            `${secondaryForegroundHoverColour.value}`};
        }
      }
    `;

  const getStyleTertiary = () => `
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      min-width: 150px;
      width: auto;
      min-height: 35px;
      height: ${tertiaryButton.tertiaryButtonHeight}px;
      text-transform: ${tertiaryButton.tertiaryButtonCasing};
      ${tertiaryBackgroundColour &&
        `background-color: ${tertiaryBackgroundColour.value}`};
      ${tertiaryForegroundColour && `color: ${tertiaryForegroundColour.value}`};
      ${tertiaryBorderColour &&
        `border: 1px solid ${tertiaryBorderColour.value}`};
      ${tertiaryButtonFont && `font-family: ${tertiaryButtonFont.value}`};
      svg > rect:not([fill='none']) {
      ${tertiaryForegroundColour && `fill: ${tertiaryForegroundColour.value}`};
      }
      svg > path:not([fill]) {
        ${tertiaryForegroundColour &&
          `fill: ${tertiaryForegroundColour.value}`};
      }
      &:hover {
        ${tertiaryBackgroundHoverColour &&
          `background-color: ${tertiaryBackgroundHoverColour.value}`};
        ${tertiaryForegroundHoverColour &&
          `color: ${tertiaryForegroundHoverColour.value}`};
        ${tertiaryBorderHoverColour &&
          `border: 1px solid ${tertiaryBorderHoverColour.value}`};
        svg > rect:not([fill='none']) {
          fill: ${tertiaryForegroundHoverColour &&
            `${tertiaryForegroundHoverColour.value}`};
        }
        svg > path:not([fill]) {
          fill: ${tertiaryForegroundHoverColour &&
            `${tertiaryForegroundHoverColour.value}`};
        }
      }
    `;

  const ButtonStyleOptions = [
    {
      label: 'Style 1',
      value: 'ButtonStyle1',
    },
    {
      label: 'Style 2',
      value: 'ButtonStyle2',
    },
    {
      label: 'Style 3',
      value: 'ButtonStyle3',
    },
  ];
  return (
    <Container>
      <TopNote>
        Note: Newly added fonts need to be saved before they appear on the
        preview.
      </TopNote>
      <Heading>Primary Buttons</Heading>
      <SubHeading>Preview</SubHeading>
      <Button
        buttonStyle={primaryButton.buttonStyle}
        text="Primary Button"
        applyStyle="primary"
        styleOverride={getStylePrimary}
      />
      <OptionContainer>
        <Section>
          <Item>
            <ItemTitle>Button Style</ItemTitle>
            <Selector
              qaHook="button-primary-style"
              options={ButtonStyleOptions}
              selected={ButtonStyleOptions.find(
                s => s.value === primaryButton.buttonStyle,
              )}
              onOptionClick={(label, value) =>
                props.updateButtonStyle(label, value.value)
              }
              forKey="primaryButton"
            />
          </Item>
          <Item>
            <ItemTitle>Font</ItemTitle>
            <Selector
              qaHook="button-primary-font"
              options={actualAvailableFonts}
              selected={primaryButtonFont}
              onOptionClick={selectBrandFont}
              forKey="primaryButtonFont"
            />
          </Item>
          <Item>
            <ItemTitle>Height</ItemTitle>
            <InputField
              maxInputWidth={40}
              type="number"
              inputLabel="px"
              inputLabelPosition="right"
              inputFlexMode="1"
              height="45"
              input={{
                onChange: e =>
                  updateUiElement(
                    'primaryButton',
                    'primaryButtonHeight',
                    e.target.value,
                  ),
                name: 'primaryButtonHeight-input',
                value: primaryButton.primaryButtonHeight,
              }}
            />
          </Item>
          <Item>
            <ItemTitle>Transform Case</ItemTitle>
            <TextTransform
              name="primary-button-font-text-transform"
              value={primaryButton.primaryButtonCasing}
              onChange={transform =>
                updateUiElement(
                  'primaryButton',
                  'primaryButtonCasing',
                  transform,
                )
              }
              inline
            />
          </Item>
        </Section>
        <Section>
          <Item>
            <ItemTitle>Background Colour</ItemTitle>
            <ColourSelector
              qaHook="background-primary-colour"
              brandColours={availableColours}
              selected={primaryBackgroundColour}
              selectBrandColour={selectBrandColour}
              forKey="primaryBackgroundColour"
            />
          </Item>
          <Item>
            <ItemTitle>Foreground Colour</ItemTitle>
            <ColourSelector
              qaHook="foreground-primary-colour"
              brandColours={availableColours}
              selected={primaryForegroundColour}
              selectBrandColour={selectBrandColour}
              forKey="primaryForegroundColour"
            />
          </Item>
          <Item>
            <ItemTitle>Border Colour</ItemTitle>
            <ColourSelector
              qaHook="border-primary-colour"
              brandColours={availableColours}
              selected={primaryBorderColour}
              selectBrandColour={selectBrandColour}
              forKey="primaryBorderColour"
            />
          </Item>
        </Section>
        <Section>
          <Item>
            <ItemTitle>Hover Background Colour</ItemTitle>
            <ColourSelector
              qaHook="background-primary-hover-colour"
              brandColours={availableColours}
              selected={primaryBackgroundHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="primaryBackgroundHoverColour"
            />
          </Item>
          <Item>
            <ItemTitle>Hover Foreground Colour</ItemTitle>
            <ColourSelector
              qaHook="foreground-primary-hover-colour"
              brandColours={availableColours}
              selected={primaryForegroundHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="primaryForegroundHoverColour"
            />
          </Item>
          <Item>
            <ItemTitle>Hover Border Colour</ItemTitle>
            <ColourSelector
              qaHook="border-primary-hover-colour"
              brandColours={availableColours}
              selected={primaryBorderHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="primaryBorderHoverColour"
            />
          </Item>
        </Section>
      </OptionContainer>

      <Heading>Secondary Buttons</Heading>
      <SubHeading>Preview</SubHeading>
      <Button
        buttonStyle={secondaryButton.buttonStyle}
        text="Secondary Button"
        applyStyle="secondary"
        styleOverride={getStyleSecondary}
      />
      <OptionContainer>
        <Section>
          <Item>
            <ItemTitle>Button Style</ItemTitle>
            <Selector
              qaHook="button-secondary-style"
              options={ButtonStyleOptions}
              selected={ButtonStyleOptions.find(
                s => s.value === secondaryButton.buttonStyle,
              )}
              onOptionClick={(label, value) =>
                props.updateButtonStyle(label, value.value)
              }
              forKey="secondaryButton"
            />
          </Item>
          <Item>
            <ItemTitle>Font</ItemTitle>
            <Selector
              qaHook="button-primary-font"
              options={actualAvailableFonts}
              selected={secondaryButtonFont}
              onOptionClick={selectBrandFont}
              forKey="secondaryButtonFont"
            />
          </Item>
          <Item>
            <ItemTitle>Height</ItemTitle>
            <InputField
              maxInputWidth={40}
              type="number"
              inputLabel="px"
              inputLabelPosition="right"
              inputFlexMode="1"
              height="45"
              input={{
                onChange: e =>
                  updateUiElement(
                    'secondaryButton',
                    'secondaryButtonHeight',
                    e.target.value,
                  ),
                name: 'secondaryButtonHeight-input',
                value: secondaryButton.secondaryButtonHeight,
              }}
            />
          </Item>
          <Item>
            <ItemTitle>Transform Case</ItemTitle>
            <TextTransform
              name="secondary-button-font-text-transform"
              value={secondaryButton.secondaryButtonCasing}
              onChange={transform =>
                updateUiElement(
                  'secondaryButton',
                  'secondaryButtonCasing',
                  transform,
                )
              }
              inline
            />
          </Item>
        </Section>
        <Section>
          <Item>
            <ItemTitle>Background Colour</ItemTitle>
            <ColourSelector
              qaHook="background-secondary-colour"
              brandColours={availableColours}
              selected={secondaryBackgroundColour}
              selectBrandColour={selectBrandColour}
              forKey="secondaryBackgroundColour"
            />
          </Item>
          <Item>
            <ItemTitle>Foreground Colour</ItemTitle>
            <ColourSelector
              qaHook="foreground-secondary-colour"
              brandColours={availableColours}
              selected={secondaryForegroundColour}
              selectBrandColour={selectBrandColour}
              forKey="secondaryForegroundColour"
            />
          </Item>
          <Item>
            <ItemTitle>Border Colour</ItemTitle>
            <ColourSelector
              qaHook="border-secondary-colour"
              brandColours={availableColours}
              selected={secondaryBorderColour}
              selectBrandColour={selectBrandColour}
              forKey="secondaryBorderColour"
            />
          </Item>
        </Section>
        <Section>
          <Item>
            <ItemTitle>Hover Background Colour</ItemTitle>
            <ColourSelector
              qaHook="background-secondary-hover-colour"
              brandColours={availableColours}
              selected={secondaryBackgroundHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="secondaryBackgroundHoverColour"
            />
          </Item>
          <Item>
            <ItemTitle>Hover Foreground Color</ItemTitle>
            <ColourSelector
              qaHook="foreground-secondary-hover-colour"
              brandColours={availableColours}
              selected={secondaryForegroundHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="secondaryForegroundHoverColour"
            />
          </Item>
          <Item>
            <ItemTitle>Hover Border Colour</ItemTitle>
            <ColourSelector
              qaHook="border-secondary-hover-colour"
              brandColours={availableColours}
              selected={secondaryBorderHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="secondaryBorderHoverColour"
            />
          </Item>
        </Section>
      </OptionContainer>

      <Heading>Tertiary Buttons</Heading>
      <SubHeading>Preview</SubHeading>
      <Button
        buttonStyle={tertiaryButton.buttonStyle}
        text="Tertiary Button"
        applyStyle="tertiary"
        styleOverride={getStyleTertiary}
      />
      <OptionContainer>
        <Section>
          <Item>
            <ItemTitle>Button Style</ItemTitle>
            <Selector
              qaHook="button-tertiary-style"
              options={ButtonStyleOptions}
              selected={ButtonStyleOptions.find(
                s => s.value === tertiaryButton.buttonStyle,
              )}
              onOptionClick={(label, value) =>
                props.updateButtonStyle(label, value.value)
              }
              forKey="tertiaryButton"
            />
          </Item>
          <Item>
            <ItemTitle>Font</ItemTitle>
            <Selector
              qaHook="button-tertiary-font"
              options={actualAvailableFonts}
              selected={tertiaryButtonFont}
              onOptionClick={selectBrandFont}
              forKey="tertiaryButtonFont"
            />
          </Item>
          <Item>
            <ItemTitle>Height</ItemTitle>
            <InputField
              maxInputWidth={40}
              type="number"
              inputLabel="px"
              inputLabelPosition="right"
              inputFlexMode="1"
              height="45"
              input={{
                onChange: e =>
                  updateUiElement(
                    'tertiaryButton',
                    'tertiaryButtonHeight',
                    e.target.value,
                  ),
                name: 'tertiaryButtonHeight-input',
                value: tertiaryButton.tertiaryButtonHeight,
              }}
            />
          </Item>
          <Item>
            <ItemTitle>Transform Case</ItemTitle>
            <TextTransform
              name="tertiary-button-font-text-transform"
              value={tertiaryButton.tertiaryButtonCasing}
              onChange={transform =>
                updateUiElement(
                  'tertiaryButton',
                  'tertiaryButtonCasing',
                  transform,
                )
              }
              inline
            />
          </Item>
        </Section>
        <Section>
          <Item>
            <ItemTitle>Background Colour</ItemTitle>
            <ColourSelector
              qaHook="background-tertiary-colour"
              brandColours={availableColours}
              selected={tertiaryBackgroundColour}
              selectBrandColour={selectBrandColour}
              forKey="tertiaryBackgroundColour"
            />
          </Item>
          <Item>
            <ItemTitle>Foreground Colour</ItemTitle>
            <ColourSelector
              qaHook="foreground-tertiary-colour"
              brandColours={availableColours}
              selected={tertiaryForegroundColour}
              selectBrandColour={selectBrandColour}
              forKey="tertiaryForegroundColour"
            />
          </Item>
          <Item>
            <ItemTitle>Border Colour</ItemTitle>
            <ColourSelector
              qaHook="border-tertiary-colour"
              brandColours={availableColours}
              selected={tertiaryBorderColour}
              selectBrandColour={selectBrandColour}
              forKey="tertiaryBorderColour"
            />
          </Item>
        </Section>
        <Section>
          <Item>
            <ItemTitle>Hover Background Colour</ItemTitle>
            <ColourSelector
              qaHook="background-tertiary-hover-colour"
              brandColours={availableColours}
              selected={tertiaryBackgroundHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="tertiaryBackgroundHoverColour"
            />
          </Item>
          <Item>
            <ItemTitle>Hover Foreground Colour</ItemTitle>
            <ColourSelector
              qaHook="foreground-tertiary-hover-colour"
              brandColours={availableColours}
              selected={tertiaryForegroundHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="tertiaryForegroundHoverColour"
            />
          </Item>
          <Item>
            <ItemTitle>Hover Border Colour</ItemTitle>
            <ColourSelector
              qaHook="border-tertiary-hover-colour"
              brandColours={availableColours}
              selected={tertiaryBorderHoverColour}
              selectBrandColour={selectBrandColour}
              forKey="tertiaryBorderHoverColour"
            />
          </Item>
        </Section>
      </OptionContainer>
    </Container>
  );
};

// @flow
import React from 'react';
import SubNavBar from './SubNavBar';

type Props = {
  links: Link[],
  onBackButtonClick?: () => void,
  title?: string,
  isExploreRange?: boolean,
  subNavBGColour?: Option,
  subNavBorderColour?: Option,
  subNavFontTheme?: Theme,
  subNavChevronColour?: Option,
  showNavBarItemBorder?: boolean,
  exploreRangeBackgroundColor?: string,
  exploreRangeForegroundColour?: Option,
  subNavHeight?: string,
  alignMenuItems?: string,
  navBarBottomBorder: string,
  subNavBarItemSelectedBackgroundColour: Option,
  subNavBarItemSelectedFontColour: Option,
  contentWidth?: number,
};

export default function PathnameSubNavBar(props: Props) {
  const selected = props.links.find(
    link => link.path === window.location.pathname.substr(1),
  );
  return <SubNavBar {...props} selected={selected} />;
}

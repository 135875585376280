import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import templates from './Templates';
import { actions } from './reducer';
import { vehicleDataFormatter } from '../../helpers/vehicle';
import { convertSecondaryPriceCurrency } from '../../shared/currencyConversion/helpers';
import {
  localiseCurrency,
  localiseNumber,
} from '../../shared/localisation/numbers';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortListActions } from '../../shared/shortlist';
import { actions as routerActions } from '../../actions/router';

type FeaturedVehicleHook = {
  Template: () => Node,
  vehicle: Object,
  locale: string,
  shared: Object,
  globalStyling: Object,
};

export function useFeaturedVehicle(
  template,
  vehicleId,
  translations,
): FeaturedVehicleHook {
  const Template = templates[template];
  const dispatch = useDispatch();
  const { vehicle, currency, locale, shared, globalStyling } = useSelector(
    state => ({
      vehicle: state.featuredVehicles[vehicleId],
      currency: state.shared.sessionPreferences.currency,
      locale: state.config.settings.locale,
      shared: state.shared,
      globalStyling: state.config.config.global.globalStyling,
    }),
  );

  useEffect(() => {
    dispatch(actions.getFeaturedVehicle(vehicleId));
  }, []);

  const generateSubTitle = subtitleData => {
    if (subtitleData) {
      return vehicleDataFormatter(subtitleData, vehicle, locale, ' | ');
    }
    return (vehicle.powerOutput
      ? [
          vehicle.powerOutput,
          vehicle.specification.transmission,
          vehicle.specification.fuelType,
        ]
      : [vehicle.specification.transmission, vehicle.specification.fuelType]
    )
      .filter(Boolean)
      .join(' | ');
  };

  const priceNoDigits = (priceValue, rates, priceCurrency) => {
    const exchangeValue = convertSecondaryPriceCurrency(
      priceValue,
      rates && rates,
      priceCurrency,
      currency || priceCurrency,
    );
    return (
      localiseCurrency(exchangeValue, locale, currency || priceCurrency, 0) ||
      translations.notAvailableLabel
    );
  };

  const mileageNoDigits = reading =>
    localiseNumber(reading, locale, 0) || translations.notAvailableLabel;

  const compareVehicle = () => dispatch(compareActions.toggleVehicle(vehicle));
  const shortlistVehicle = () =>
    dispatch(shortListActions.toggleVehicle(vehicle));
  const onMoreInfoClick = id => dispatch(routerActions.navigate(`VDP/${id}`));

  return {
    Template,
    vehicle,
    locale,
    shared,
    globalStyling,
    priceNoDigits,
    mileageNoDigits,
    generateSubTitle,
    compareVehicle,
    shortlistVehicle,
    onMoreInfoClick,
  };
}

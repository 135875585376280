// @flow
import { select, call, put } from 'redux-saga/effects';
import { get, put as save } from '../../helpers/http';

import { POSTS_SERVICE_URL } from '../../constants/apis';
import watch from '../../helpers/watch';
import { actions, constants } from '../actions/blogs';

function* loadBlogs() {
  const currentSiteId = yield select(state => state.site.currentSiteId);

  const token = yield select(state => state.auth.token);
  const url = `${POSTS_SERVICE_URL}${currentSiteId}/posts`;
  try {
    const result = yield call(get, { url, token });

    yield put(actions.loadBlogsSuccessful(result.data.data));
  } catch (error) {
    yield put(actions.loadBlogsFailure({ message: error.message }));
  }
}

function* saveBlog(token, action) {
  const blog = action.payload;
  const currentSiteId = yield select(state => state.site.currentSiteId);
  const url = `${POSTS_SERVICE_URL}${currentSiteId}/posts/${blog.id}`;

  try {
    const result = yield call(save, { url, token, data: blog });
    yield put(actions.saveBlogSuccessful(result.data));
  } catch (error) {
    yield put(actions.saveBlogFailure({ message: error.message }));
  }
}

export default [
  watch(constants.LoadBlogs, loadBlogs, true),
  watch(constants.SaveBlog, saveBlog, true),
];

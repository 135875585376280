// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { Tabs, MediaUpload, MediaGallery, Modal } from 'cms-ui';
import type { Theme } from '../theme';
import type { MediaType, MediaResource } from '../state/types/media';
import settings from '../settings';
import OpenClose from './OpenClose';
import Close from '../assets/Close';
import CropImage from './CropImage';

type Mode = 'Global' | 'Module';
type Item = { label: string, publicId: string };
type Props = {
  images: MediaResource[],
  videos: MediaResource[],
  icons: MediaResource[],
  onUploadMediaSuccess: (resources: MediaResource[], type: MediaType) => void,
  onUploadMediaFailure: (error: string) => void,
  onDeleteMediaItem: (publicId: string, type: MediaType) => void,
};

const mediaTypeFormats = {
  image: ['png', 'gif', 'jpeg', 'svg'],
  video: ['mp4'],
};

const mediaTypeDropFileText = {
  image: 'Drop images here',
  video: 'Drop videos here',
  svg: 'Drop icons here',
};

const withMedia = ({
  items,
  type,
  onUploadMediaSuccess,
  onUploadMediaFailure,
  onSelectItem,
  onDeleteItem,
  mode,
}: {
  items: MediaResource[],
  type: MediaType,
  onUploadMediaSuccess: (resources: MediaResource[], type: MediaType) => void,
  onUploadMediaFailure: (error: string) => void,
  onSelectItem?: (item: Item) => void,
  onDeleteItem?: (publicId: string, type: MediaType) => void,
  mode: Mode,
}) => () => {
  const folder =
    settings.environment !== 'prod'
      ? `${settings.environment}/${settings.product}/${settings.brand}`
      : `${settings.product}/${settings.brand}`;
  const [image, setImage] = useState(null);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {image ? (
        <CropImage closeCropper={() => setImage(null)} image={image} />
      ) : (
        <>
          <MediaUpload
            allowedTypeFormats={mediaTypeFormats[type]}
            accountName={settings.mediaAccountName}
            preset={settings.mediaUploadPreset}
            brandFolder={folder}
            tags={[settings.brand]}
            onUploadMediaSuccess={resources =>
              onUploadMediaSuccess(resources, type)
            }
            onUploadMediaFailure={onUploadMediaFailure}
            dropFileText={mediaTypeDropFileText[type]}
          />
          <MediaGallery
            items={items}
            type={type}
            accountName={settings.mediaAccountName}
            mediaBaseUrl={settings.mediaBaseUrl}
            onSelectItem={onSelectItem}
            onDeleteItem={(item: Item) =>
              onDeleteItem && onDeleteItem(item.publicId, type)
            }
            onCropItem={(item: Item) => setImage(item)}
            mode={mode}
          />
        </>
      )}
    </div>
  );
};

type ThemeProps = { theme: Theme };

const Container = styled.div.withConfig({ displayName: 'Container' })`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }: ThemeProps) => `${theme.layout.doublePadding}`};
  > * {
    margin-bottom: ${({ theme }: ThemeProps) =>
      `${theme.layout.standardPadding}`};
  }
`;

const Header = styled.h2.withConfig({ displayName: 'Header' })`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeHeader}`};
`;

const HeaderContainer = styled.h2.withConfig({
  displayName: 'HeaderContainer',
})`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeHeader}`};
  display: flex;
  text-transform: capitalize;
  align-items: center;
  padding: 10px;
`;

const Title = styled.div.withConfig({
  displayName: 'Title',
})`
  display: flex;
  flex: 1 0 0%;
  justify-content: space-evenly;
  font-weight: bold;
`;

const CloseButtonWrapper = styled.div.withConfig({
  displayName: 'CloseButtonWrapper',
})`
  cursor: pointer;
`;

type MediaLibraryInModalProps = {
  items: MediaResource[],
  type: MediaType,
  onUploadMediaSuccess: (resources: MediaResource[], type: MediaType) => void,
  onUploadMediaFailure: (error: string) => void,
  onSelectItem: (item: Item) => void,
  onDeleteMediaItem: (publicId: string, type: MediaType) => void,
  openComponent: (props: { onClick: () => void, item: Item }) => Node,
};

export class MediaLibraryInModal extends React.Component<
  MediaLibraryInModalProps,
  *,
> {
  state = {
    selectedItem: undefined,
  };

  props: MediaLibraryInModalProps;

  render() {
    const {
      items,
      type,
      onUploadMediaSuccess,
      onUploadMediaFailure,
      onSelectItem,
      onDeleteMediaItem,
      openComponent: Open,
    } = this.props;

    const MediaComponent = ({
      onSelectItemHandler,
    }: {
      onSelectItemHandler: (item: Item) => void,
    }) =>
      withMedia({
        items,
        type,
        onUploadMediaSuccess,
        onUploadMediaFailure,
        onSelectItem: onSelectItemHandler,
        onDeleteItem: onDeleteMediaItem,
        mode: 'Module',
      })();
    return (
      <OpenClose>
        {({ open, openClose }) => (
          <div style={{ width: '100%' }}>
            {open ? (
              <Modal fullScreen onClickOutside={openClose}>
                <HeaderContainer>
                  <Title data-qa-hook="media-library-title">
                    Media Library
                  </Title>
                  <CloseButtonWrapper onClick={openClose}>
                    <Close width="1em" height="1em" colour="#ACACAC" />
                  </CloseButtonWrapper>
                </HeaderContainer>
                <MediaComponent
                  onSelectItemHandler={item => {
                    this.setState(
                      () => ({ selectedItem: item }),
                      () => {
                        onSelectItem(item);
                        openClose();
                      },
                    );
                  }}
                />
              </Modal>
            ) : (
              <Open onClick={openClose} item={this.state.selectedItem} />
            )}
          </div>
        )}
      </OpenClose>
    );
  }
}

export default function MediaLibrary({
  images,
  videos,
  onUploadMediaSuccess,
  onUploadMediaFailure,
  onDeleteMediaItem,
}: Props) {
  return (
    <Container>
      <Header data-qa-hook="media-library-title">Media Library</Header>
      <Tabs
        items={[
          {
            title: 'images',
            component: withMedia({
              items: images,
              type: 'image',
              onUploadMediaSuccess,
              onUploadMediaFailure,
              onDeleteItem: onDeleteMediaItem,
              mode: 'Global',
            }),
          },
          {
            title: 'videos',
            component: withMedia({
              items: videos,
              type: 'video',
              onUploadMediaSuccess,
              onUploadMediaFailure,
              onDeleteItem: onDeleteMediaItem,
              mode: 'Module',
            }),
          },
        ]}
      />
    </Container>
  );
}

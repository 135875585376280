// @flow
import React from 'react';
import styled from 'styled-components';
import IoAndroidCheckboxOutlineBlank from 'react-icons/lib/io/android-checkbox-outline-blank';
import IoAndroidCheckbox from 'react-icons/lib/io/android-checkbox';

type CheckboxOption = {
  selected: boolean,
  label: string,
};

export type Props = {
  options: CheckboxOption[],
  onOptionClick: (idx: number) => void,
};

const Container = styled.div`
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  padding: 35px 2.5%;
  background-color: #ffffff;
`;

const Label = styled.div`
  color: #474747;
  font-size: 14px;
  margin: 10px 0 10px 8px;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 100px;
  cursor: pointer;
`;

const Selected = styled(IoAndroidCheckbox)`
  color: #0065db;
`;

const Unselected = styled(IoAndroidCheckboxOutlineBlank)`
  color: #0065db;
`;

export default function CheckboxGroup({ options, onOptionClick }: Props) {
  return (
    <Container>
      {options.map((option, idx) => (
        <CheckboxRow onClick={() => onOptionClick(idx)} key={option.label}>
          {option.selected ? <Selected /> : <Unselected />}
          <Label>{option.label}</Label>
        </CheckboxRow>
      ))}
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import YouTubePlayer from '../YouTubePlayer';

import Player from '../360/Player';

type Props = {
  hideMedia: Function,
  mediaType: 'video' | 'view360',
  video: any,
  view360: any,
  vehicleInfo: Object,
  enable360View: boolean,
  globalStyling: Object,
  close360Icon: string,
  translations: Object,
  imageCarousel: Object,
};

const Container = styled.div`
  position: relative;
  z-index: 9;
`;

export default function renderMedia({
  hideMedia,
  mediaType,
  vehicleInfo: { media },
  enable360View,
  globalStyling,
  close360Icon,
  translations,
  parsedYouTubeId,
}: Props) {
  return (
    <Container>
      {mediaType === 'view360' && enable360View && (
        <Player
          imagesIcon={close360Icon}
          toggleImageView={hideMedia}
          globalStyling={globalStyling}
          media={media}
          translations={translations}
        />
      )}
      {mediaType === 'video' && (
        <YouTubePlayer
          src={parsedYouTubeId}
          autoplay
          toggleImageView={hideMedia}
          imagesIcon={close360Icon}
        />
      )}
    </Container>
  );
}

// @flow
import React, { Component } from 'react';

import { actions as routerActions } from '../../actions/router';
import { actions as leadsActions } from '../../shared/leadsAuthentication';
import { isTokenValid } from '../../shared/CustomerLogin/reducer';

import RegisterContainer from '../../components/Register';

export interface Config {
  translations: Object;
  showJLRMarketingAndDistributionConsent: boolean;
}

type Props = {
  config: Config,
  actions: {
    init: () => void,
    register: (user: Object) => void,
  },
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  dispatch: Function,
  globalStyling: Object,
};

export default class Register extends Component<Props, *> {
  componentDidMount = () => {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      shared,
      actions: { init },
      dispatch,
    } = this.props;
    if (isTokenValid(shared)) {
      dispatch(routerActions.navigate('/'));
    } else {
      init();
      dispatch(leadsActions.getLeadsToken());
    }
  };

  render = () => {
    const {
      state,
      config,
      actions,
      preview,
      dispatch,
      globalStyling,
    } = this.props;
    const fnIfNotPreview = fn => (preview ? () => {} : fn);
    return (
      <RegisterContainer
        translations={config.translations}
        termsLink={config.termsAndConditionsLink}
        register={user => fnIfNotPreview(() => actions.register(user))}
        registerFailed={state.registerFailed}
        context={state.context}
        fnIfNotPreview={fnIfNotPreview}
        goToPage={() =>
          fnIfNotPreview(() => dispatch(routerActions.navigate('signin')))
        }
        globalStyling={globalStyling}
        registerTextTransform={config.registerTextTransform}
        marketingLink={config.marketingLink}
        showUserCommunication={config.showUserCommunication}
        dataConsentFont={config.dataConsentFont}
        checkboxLabelStyles={config.checkboxLabelFont}
        showJLRMarketingAndDistributionConsent={
          config.showJLRMarketingAndDistributionConsent
        }
      />
    );
  };
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import UserLocation from '../../components/UserLocation';
import { useDealerLocator } from './useDealerLocator';
import { Paragraph, fontStyleOverride } from '../components/Global';
import { type Option, Media } from '../../types';
import { dealerPodStatus } from './reducer';

export interface IDealerLocatorConfig {
  flipCoordinates: Boolean;
  defaultCenterPoint: {
    lat: Number,
    lng: Number,
  };
  defaultZoomLevel: Number;
  pinIcon: Media;
  selectedPinIcon: Media;
  clusterIcon: Media;
  locationIcon: Media;
  clusterFontColour: Option;
  translations: {
    description: String,
    inputPlaceholder: String,
    phoneLabel: String,
    noDealers: String,
    buttonText: String,
  };
}

type Props = {
  config: IDealerLocatorConfig,
  globalStyling: Object,
  dispatch: (action: Function) => void,
};

const Wrapper = styled.div`
  width: 100%;
`;
const HeadingWrapper = styled.div`
  margin: 30px auto;
  max-width: 600px;
`;
const MapWrapper = styled.div`
  height: 500px;
  position: relative;
`;
const DisabledOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: rgba(00, 00, 00, 0.5);
  top: 0;
`;

export default ({ config, globalStyling, dispatch }: Props) => {
  const { locationIcon } = config;
  const {
    countryCode,
    location,
    ref,
    locationCallback,
    dealers,
    status,
  } = useDealerLocator(dispatch, config, globalStyling);
  const error =
    status === dealerPodStatus.fetchingFailure || dealers.length === 0;
  return (
    <Wrapper>
      <HeadingWrapper>
        {error ? (
          <Paragraph
            styleOverride={() =>
              fontStyleOverride({
                alignment: 'center',
              })
            }
          >
            {config.translations.noDealers}
          </Paragraph>
        ) : (
          <Fragment>
            <Paragraph
              styleOverride={() =>
                fontStyleOverride({ padding: { bottom: 40 } })
              }
            >
              {config.translations.description}
            </Paragraph>

            <UserLocation
              placeholderText={config.translations.inputPlaceholder}
              iconUrl={locationIcon.src}
              direction={globalStyling.direction}
              browserLocationCallback={locationCallback}
              inputLocationCallback={locationCallback}
              options={{
                countryCode,
                latitude: location.latitude,
                longitude: location.longitude,
              }}
            />
          </Fragment>
        )}
      </HeadingWrapper>
      <MapWrapper>
        <div ref={ref} id="map" style={{ height: '100%' }} />
        {error && <DisabledOverlay />}
      </MapWrapper>
    </Wrapper>
  );
};

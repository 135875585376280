// @flow
import React from 'react';
import styled from 'styled-components';
import ThinLeft from '../JaguarIcons/Arrows/ThinLeft';
import ThinRight from '../JaguarIcons/Arrows/ThinRight';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${({ left }) => (left ? `left: ${left}px` : '')};
  ${({ right }) => (right ? `right: ${right}px` : '')};
  top: 40%;
  width: 30px;
  height: 50px;
  text-align: center;
  background: #000000;
  opacity: 0.7;
  border-radius: 1px;
  cursor: pointer;
`;

const IconWrapper = styled.img`
  width: 16px;
  height: 16px;
`;

const renderNavigation = ({
  goToNext,
  goToPrev,
  arrowRight,
  arrowLeft,
}: CarouselNavigation) => [
  <ButtonContainer key="1" left="20" onClick={() => goToPrev()}>
    {arrowLeft ? (
      <IconWrapper src={arrowLeft} alt="" />
    ) : (
      <ThinLeft width="1.5em" height="1.5em" colour="#FFFFFF" />
    )}
  </ButtonContainer>,
  <ButtonContainer key="2" right="20" onClick={() => goToNext()}>
    {arrowRight ? (
      <IconWrapper src={arrowRight} alt="" />
    ) : (
      <ThinRight width="1.5em" height="1.5em" colour="#FFFFFF" />
    )}
  </ButtonContainer>,
];

export default renderNavigation;

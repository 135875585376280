// @flow
/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';

const styledPanel = `
  #cloudinary-widget .panel.local {
    margin:0;
    height:350px;
    padding:0;
  }
`;

const styledModalOverlay = `
  #cloudinary-overlay.modal {
    background-color: transparent;
  }
`;

const hideNavBar = `
  #cloudinary-navbar {
    display: none;
  }
`;

const styledInlineModalOverlay = `
  #cloudinary-overlay.inline .widget {
    border: 1px solid white;
  }
`;

const styledDragArea = `
  #cloudinary-widget .drag_area {
    height: 250px;
    margin:10px;
  }
`;

const styledDragAreaContent = `
  #cloudinary-widget .drag_content {
    margin-top:50px;
  }
`;

const styledDragAreaContentLabel = `
  #cloudinary-widget .panel.local .drag_area .drag_content .label {
    font-family: Arial;
    font-size:16px;
    color:#0065DB;
    font-weight:bold;
    color:#0065DB;
  }
`;

const styledDragAreaContentOr = `
  #cloudinary-widget .or {
    font-family: Arial;
    font-size:16px;
    color:#0065DB;
    font-weight:bold;
  }
`;

const styledUploadButton = `
  #cloudinary-widget .upload_button_holder .button {
    font-family: Arial;
    font-size:16px;
    background-color:#0065DB;
    color:#FFFFFF;
    border:0;
    border-radius: 0;
    font-weight:bold;
  }
`;

const stylesheet = `${styledModalOverlay}${styledPanel}${hideNavBar}${styledInlineModalOverlay}${styledDragArea}${styledDragAreaContent}${styledUploadButton}${styledDragAreaContentOr}${styledDragAreaContentLabel}`;

const MediaUploadContainer = styled.div.withConfig({
  displayName: 'MediaUploadContainer',
})`
  max-height: 350px;
  width: 100%;
  overflow-y: hidden;
  height: 350px;
`;

type MediaType = 'image' | 'video';
type MediaResource = {
  version: string,
  type: string,
  format: string,
  public_id: string,
  existing: boolean,
  bytes: number,
  height: number,
  width: number,
  thumbnail_url: string,
  tags: string[],
  resource_type: MediaType,
};

type Props = {
  allowedTypeFormats: string[],
  onUploadMediaFailure: (error: string) => void,
  onUploadMediaSuccess: (resources: MediaResource[]) => void,
  accountName: string,
  preset: string,
  brandFolder: string,
  tags: string[],
  dropFileText: string,
};

export default class MediaUpload extends React.Component<Props, *> {
  static defaultProps = {
    onUploadMediaSuccess: () => {},
    onUploadMediaFailure: () => {},
    allowedTypeFormats: ['png', 'gif', 'jpeg', 'svg', 'mp4'],
    /** any string accountName and preset values are required
     * for the widget to load successfully.
     * this is here for styleguide mainly
     */
    accountName: 'name',
    preset: 'preset',
    tags: [],
    dropFileText: 'Drop files here',
  };

  componentDidMount = () => {
    const {
      onUploadMediaSuccess,
      onUploadMediaFailure,
      allowedTypeFormats,
      accountName,
      preset,
      brandFolder,
      tags,
      dropFileText,
    } = this.props;
    this.widget = window.cloudinary.openUploadWidget(
      {
        cloud_name: accountName,
        upload_preset: preset,
        folder: `${brandFolder}/content`,
        tags,
        theme: 'minimal',
        show_powered_by: false,
        stylesheet,
        sources: ['local'],
        client_allowed_formats: allowedTypeFormats,
        keep_widget_open: true,
        inline_container: `#${this.id}`,
        text: {
          'sources.local.drop_files': dropFileText,
        },
      },
      (error, result) => {
        if (error) {
          const intervalId = window.setInterval(() => {
            onUploadMediaFailure(error.message);
            window.clearInterval(intervalId);
          }, this.timeout);
        } else {
          onUploadMediaSuccess(result);
        }
      },
    );
  };

  componentWillUnmount = () => {
    // @ may need to do some sort clean up
    console.warn('MediaUpload Component WillUnmount', this.widget);
  };

  id = `id-${Date.now().toString()}`;

  widget = undefined;

  timeout = 1500;

  render() {
    return (
      <MediaUploadContainer
        id={this.id}
        data-qa-hook={`${this.id}-media-upload`}
      />
    );
  }
}

export default (viewpoint, canvas) => {
  const modalWrapper = document.createElement('div');
  modalWrapper.id = 'viewpoint-modal-wrapper';
  modalWrapper.style.height = `${canvas.height}px`;

  const modalClose = document.createElement('div');
  modalClose.id = 'viewpoint-modal-close';
  modalClose.innerHTML = 'X';
  modalClose.onclick = () => {
    document.getElementById(canvas.id).parentNode.removeChild(modalWrapper);
  };

  const modalContent = document.createElement('div');
  modalContent.id = 'viewpoint-modal-content';

  const viewpointImg = document.createElement('img');
  viewpointImg.id = 'viewpoint-image';
  viewpointImg.src = viewpoint.url;

  const viewpointDesc = document.createElement('p');
  viewpointDesc.id = 'viewpoint-desc';
  viewpointDesc.innerHTML = viewpoint.description;

  modalContent.appendChild(viewpointImg);
  modalContent.appendChild(viewpointDesc);
  modalWrapper.appendChild(modalClose);
  modalWrapper.appendChild(modalContent);
  document.getElementById(canvas.id).parentNode.appendChild(modalWrapper);
};

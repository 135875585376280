// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Mail({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 22">
        <path d="M13.438 14.842L24.878 7v12.256H2V7.001l11.438 7.84zM2 4h22.877v1.581l-11.439 7.644L2 5.582V4z" />
      </svg>
    </IconWrapper>
  );
}

Mail.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import type { Item } from './DraggableListEditor';

export const updateItem = (id: string, value: ?string, items: Item[]) => {
  const index = items.findIndex(i => i.id === id);
  return [
    ...items.slice(0, index),
    { ...items[index], active: value },
    ...items.slice(index + 1),
  ];
};

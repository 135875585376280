// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Play({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 24 21">
        <path d="M15.21 10.175l-5.104-3.491a.393.393 0 0 0-.616.325v6.982a.39.39 0 0 0 .616.325l5.104-3.491a.393.393 0 0 0 0-.65zm-4.93 3.07V7.752l4.008 2.747-4.008 2.744zM11.5 1.243a9.256 9.256 0 1 0 9.256 9.256A9.267 9.267 0 0 0 11.5 1.244zm0 17.723a8.467 8.467 0 1 1 8.467-8.467 8.477 8.477 0 0 1-8.467 8.467z" />
      </svg>
    </IconWrapper>
  );
}

Play.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

import {
  Config,
  Slice,
  Section,
  Toggle,
  Colour,
  Font,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'Representative Example',
  { id: 'Repex' },
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Colour('Background Colour', 'backgroundColour'),
      Font('Title Text', 'titleFont'),
      Toggle('Horizontal Border', 'horizontalBorder'),
      Toggle('Vertical Border', 'verticalBorder'),
      Colour('Border Colour', 'borderColour'),
    ),
  ),
);

export default config;

// @flow
import React, { Component } from 'react';
import RetailerMap, { type Location } from '../../components/RetailerMap';
import type { State, Props } from './types';
import { actions as routerActions } from '../../actions/router';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { actions as filterActions } from '../../shared/filters';

export default class RetailerLocationSearch extends Component<Props, State> {
  componentDidMount = () => {
    const { actions } = this.props;
    actions.getRetailers({ forceUpdate: true });
  };

  componentDidUpdate = (prevProps: Props) => {
    const { actions, shared } = this.props;
    if (
      prevProps.shared.sessionPreferences.market !==
      shared.sessionPreferences.market
    ) {
      actions.getRetailers({ forceUpdate: true });
    }
  };

  returnPlace = (position: Location) => {
    const { actions } = this.props;
    actions.getRetailers({
      lat: typeof position.lat === 'function' ? position.lat() : position.lat,
      lng: typeof position.lng === 'function' ? position.lng() : position.lng,
    });
  };

  render() {
    const {
      dispatch,
      pathByModule,
      actions,
      config,
      globalStyling,
      state,
    } = this.props;
    const goToModule = (name, retailerId) =>
      dispatch(
        routerActions.navigateWithQuery(pathByModule(name), { retailerId }),
      );
    const resetFilters = () => {
      dispatch(searchFilterActions.resetFilters());
      dispatch(filterActions.resetFilters());
    };
    return (
      <RetailerMap
        inputBoxOverride={config.inputBoxFont}
        infoWindowButtonType={config.infoWindowButtonType}
        searchCountryCode={config.searchCountryCode}
        globalStyling={globalStyling}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '600px' }} />}
        retailers={state.retailers}
        translations={config.translations}
        defaultCenter={config.mapCentreLocation}
        centreLocation={state.centreLocation}
        returnPlace={this.returnPlace}
        viewStockAction={retailer => {
          resetFilters();
          goToModule(config.searchModule || 'SearchResults', retailer.id);
        }}
        zoomChangedAction={actions.getRetailers}
        locationIconColour={config.locationIconColour}
        pin={config.pin}
        mapSearchBoxColour={config.mapSearchBoxColour}
        mapZoomLevel={config.mapZoomLevel}
        headerFont={config.headerFont}
        flipCoordinates={config.flipCoordinates}
        loadingNearest={state.loadingNearest}
        config={config}
      />
    );
  }
}

export interface Config {}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import Tick from './JaguarIcons/Global/Tick';
import Plus from './JaguarIcons/Global/Plus';
import media from '../theme';
import { localiseCurrency } from '../shared/localisation/numbers';
import { translateFinanceFromTemplate } from '../helpers/finance';
import type { Car, Font } from '../../types';
import FinancePopup from './FinancePopup';
import { HeadingOne, HeadingThree, fontStyleOverride } from './Global';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';
import Spinner from './Spinner';

const alignItems = {
  left: 'flext-start',
  center: 'center',
  right: 'flex-end',
};

const IconWrapper = styled.div.withConfig({
  displayName: 'IconWrapper',
})`
  width: 27px;
  height: 28px;
  padding: 2px 0 0 3px;
  border-radius: 50%;
  background-color: ${({ colour }) => colour};
  margin-top: 5px;
`;

const namedDiv = displayName => styled.div.withConfig({ displayName });

const TileItemContainer = namedDiv('TileItemContainer')`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  ${media.min.medium`
    max-width: 336px;
  `};
  ${media.max.medium`
    width: calc(50% - 80px);
  `};
  ${media.max.small`
    width: 100%;
  `};
`;

const TopContainer = namedDiv('TopContainer')`
  display: flex;
  justify-content: space-between;
`;

const ModelAndPrice = namedDiv('ModelAndPrice')`
  align-self: left;
`;

const Image = styled.img.withConfig({
  displayName: 'Image',
})`
  display: flex;
  align-self: center;
  margin-top: -20px;
  max-width: 380px;
  ${media.max.small`
    max-width: 300px;
  `};
`;

export type ItemTheme = {
  height?: number,
  align: string,
};

const BottomContainer = namedDiv('BottomContainer')`
  display: flex;
  flex-direction: column;
  align-items: ${(props: ItemTheme) =>
    props.align ? alignItems[props.align] : alignItems.left};
  margin-top: -40px;
`;

const Divider = styled.hr.withConfig({
  displayName: 'Divider',
})`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

const FinanceText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type ToggleProps = {
  onClick: boolean => void,
  isSelected: boolean,
  selectedIconColour: string,
};

function Toggle({ onClick, isSelected, selectedIconColour }: ToggleProps) {
  return isSelected ? (
    <IconWrapper colour={selectedIconColour} onClick={onClick}>
      <Tick width="25px" height="25px" colour="#FFFFFF" fill="#FFFFFF" />
    </IconWrapper>
  ) : (
    <IconWrapper colour="#7e7e7e" onClick={onClick}>
      <Plus width="25px" height="25px" colour="#FFFFFF" />
    </IconWrapper>
  );
}

const ToggleButton = styled(Toggle).withConfig({
  displayName: 'ToggleButton',
})`
  align-self: right;
`;

const FinanceLoadingWrapper = styled.div`
  margin: 10px;
`;

export type Theme = {
  colour: string,
  textTransform: string,
  characterSpacing: string,
  fontSize: string,
  fontFamily: string,
};

type Props = {
  translations: Translations,
  align: string,
  isSelected: boolean,
  onIconClick: Function,
  car: Car,
  selectedIconColour: string,
  titleFont: Font,
  featureFlags: Object,
  locale: string,
  globalStyling: Object,
};

export default function FilterByTile({
  isSelected,
  align,
  onIconClick,
  car,
  translations,
  selectedIconColour,
  titleFont,
  featureFlags,
  locale,
  globalStyling,
}: Props) {
  const {
    model,
    modelDisplay,
    currencyType,
    minimumPrice,
    count,
    imageUrl,
    finance,
    financeLoaded,
  } = car;

  const FinanceLoadingText = () => (
    <Fragment>
      <Spinner colour={globalStyling.colours.primaryBrandColour} />
      <FinanceLoadingWrapper>
        {translations.financeLoadingText}
      </FinanceLoadingWrapper>
    </Fragment>
  );

  const renderFinance = () => {
    if (featureFlags.finance) {
      return finance ? (
        <FinanceText>
          <HeadingThree
            styleOverride={() => `
                margin: 0;
                font-size: 15px;
                color: #000000;
              `}
          >
            {translateFinanceFromTemplate(
              translations.financeFrom,
              finance,
              locale,
            )}
          </HeadingThree>

          <FinancePopup
            translations={translations}
            data={finance}
            locale={locale}
          />
        </FinanceText>
      ) : (
        <FinanceText>
          <HeadingThree
            styleOverride={() => `
                margin: 0;
                font-size: 15px;
                color: #000000;
                line-height: 24px;
                display: flex;
                flex-direction: row;
              `}
          >
            {featureFlags.finance && !financeLoaded ? (
              <FinanceLoadingText />
            ) : (
              '\u00A0'
            )}
          </HeadingThree>
        </FinanceText>
      );
    }
    return null;
  };

  return (
    <TileItemContainer>
      <TopContainer>
        <ModelAndPrice>
          <HeadingOne
            styleOverride={() => `
              ${fontStyleOverride(titleFont)}
              margin-top: 0;
            `}
          >
            {modelDisplay || model}
          </HeadingOne>
          {minimumPrice && (
            <HeadingThree
              styleOverride={() => `
                margin: 0;
                font-size: 15px;
                color: #000000;
              `}
            >
              {translateFromTemplate(
                'searchCarFilterFromValue',
                {
                  VALUE: localiseCurrency(
                    minimumPrice,
                    locale,
                    currencyType,
                    0,
                  ),
                },
                translations,
              )}
            </HeadingThree>
          )}
        </ModelAndPrice>
        <ToggleButton
          onClick={onIconClick}
          isSelected={isSelected}
          selectedIconColour={selectedIconColour}
        />
      </TopContainer>
      {imageUrl && <Image src={imageUrl} />}
      <BottomContainer align={align}>
        <HeadingThree
          styleOverride={() => `
            margin: 0;
            font-size: 16px;
          `}
        >
          {translateFromTemplate(
            count >= 2
              ? 'searchCarFilterNumberAvailable'
              : 'searchCarFilterNumberAvailableSingle',
            {
              COUNT: count,
            },
            translations,
          )}
        </HeadingThree>
        <Divider />
        {renderFinance()}
      </BottomContainer>
    </TileItemContainer>
  );
}

FilterByTile.defaultProps = {
  onClick: () => {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  selected: false,
};

import brandSagas from './brand';
import siteSagas from './site';
import siteBuilderSagas from './siteBuilder';
import editorSagas from './editor';
import authSagas from './auth';
import routerSaga from './router';
import pageSaga from './page';
import pageTemplatesSaga from './pageTemplates';
import translationSaga from './translation';
import linksSaga from './externalLink';
import usersSaga from './users';
import financeSaga from './finance';
import socialShareSaga from './socialShare';
import settingsSaga from './settings';
import blogsSaga from './blogs';

export default [
  ...authSagas,
  ...brandSagas,
  ...siteSagas,
  ...siteBuilderSagas,
  ...editorSagas,
  ...routerSaga,
  ...pageSaga,
  ...pageTemplatesSaga,
  ...translationSaga,
  ...linksSaga,
  ...usersSaga,
  ...financeSaga,
  ...socialShareSaga,
  ...settingsSaga,
  ...blogsSaga,
];

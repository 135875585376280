import Icon from '../../modules/MyAccount/Icon';

export { id as reducerId } from '../../modules/MyAccount/reducer';

export { default as component } from './MyAccount';
export { default as effects } from '../../modules/MyAccount/effects';
export { default as config } from '../../modules/MyAccount/config';
export {
  default as defaultConfig,
} from '../../modules/MyAccount/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/MyAccount/reducer';

export const name = 'My Account';
export const id = 'MyAccount';
export const availableToAdministration = false;
export const description =
  'A module that allows the user to view/amend account details and view their test drives';
export const icon = Icon;

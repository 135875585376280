// @flow
import React from 'react';
import styled from 'styled-components';

import FieldError from './FieldError';
import WidthWrapper from './WidthWrapper';
import media from '../../../theme';

type Props = {
  label: string,
  required: boolean,
  name: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  error?: string,
  isDisabled: boolean,
  placeholder: string,
  truncateChars: boolean,
  font: Font,
};

const CheckboxGroupTitle = styled.div`
  margin-bottom: 10px;
`;

const FormFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  ${media.min.medium`
    padding: 20px 5px;
  `};
`;

const defaultBackgroundImage =
  'https://res.cloudinary.com/motortrak/image/upload/v1571994264/cms/global/icons/Tick.svg';

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${({ backgroundImage }) =>
    backgroundImage || defaultBackgroundImage});
  background-repeat: no-repeat;
  background-position: 35% 30%;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid ${({ borderColour }) => borderColour || '#999999'};
  background-color: ${({ backgroundColour }) => backgroundColour || '#dddddd'};

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxLabel = styled.label`
  height: 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const CheckboxLabelText = styled.span`
  margin-left: 10px;
  display: flex;
`;

export default ({
  required,
  name,
  label,
  onChange,
  options,
  value: values,
  error,
  uiElements: { checkbox },
  translations,
}: Props) => {
  function updateChecked(key) {
    const value = { ...values };
    value[key] = !value[key];
    onChange({ name, value });
  }

  return (
    <WidthWrapper>
      <FormFieldContainer role="group" aria-labelledby="cbGroupName">
        <CheckboxGroupTitle id="cbGroupName">
          {label} {required && '*'}
        </CheckboxGroupTitle>
        {options.map(translationKey => {
          const optionLabel = translations[translationKey];

          return (
            <CheckboxLabel htmlFor={optionLabel} key={translationKey}>
              <StyledCheckbox checked={values[translationKey]} {...checkbox}>
                <Icon {...checkbox} />
              </StyledCheckbox>
              <HiddenCheckbox
                type="checkbox"
                id={optionLabel}
                name={translationKey}
                checked={values[translationKey]}
                onChange={e => updateChecked(e.target.name)}
                ariaRequired={required}
              />
              <CheckboxLabelText>{optionLabel}</CheckboxLabelText>
            </CheckboxLabel>
          );
        })}

        {error && <FieldError>{error}</FieldError>}
      </FormFieldContainer>
    </WidthWrapper>
  );
};

import React, { createContext, useContext, useState, useEffect } from 'react';

const WindowDimensionsCtx = createContext(null);

const windowDims = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState(windowDims());

  useEffect(() => {
    let isThrottled = false;
    const delay = 200;

    const handleResize = () => {
      if (!isThrottled) {
        setDimensions(windowDims());

        isThrottled = true;

        setTimeout(() => {
          isThrottled = false;
        }, delay);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  );
};

export default WindowDimensionsProvider;

export const useWindowDimensions = () => {
  return useContext(WindowDimensionsCtx);
};

// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

import CheckboxSwitch from './CheckboxSwitch';
import EditText from './EditText';

type Text = {
  value: string,
  disabled: boolean,
  editing: boolean,
};

type Props = {
  name: string,
  text: Text,
  small: boolean,
  hasToggle: boolean,
  onChange: (text: Text) => void,
};

const namedDiv = displayName => styled.div.withConfig({ displayName });

const TexEditorContainer = namedDiv('TexEditorContainer')`
  display: flex;
  justify-content: space-between;
 `;

const EditTextContainer = namedDiv('EditTextContainer')`
  display: flex;
  position: relative;
  padding-right: ${({ theme }: { theme: Theme }) =>
    `${theme.layout.halfPadding}`};
`;

const Overlay = namedDiv('Overlay')`
  display: ${({ visible }: { visible: boolean }) =>
    visible ? 'flex' : 'none'};
  position: absolute;
  opacity: .3;
  width: 100%;
  height: ${({ small }: { small: boolean }) => (small ? '100%' : '70%')};
  background: ${({ theme }: { theme: Theme }) =>
    `${theme.colours.secondary02}`};
`;

export default function TextEditor({
  onChange,
  text,
  settings,
  ids,
  name,
  small,
  hasToggle,
}: Props) {
  const { settings: settingsId, text: textId } = ids;
  return (
    <TexEditorContainer>
      <EditTextContainer>
        <EditText
          value={text}
          editing={settings.editing}
          onChange={value => onChange(textId, value)}
          onEdit={editing => onChange(settingsId, { ...settings, editing })}
          onDelete={() => {}}
          editOnly
          small={small}
        />
        <Overlay visible={settings.disabled} small={small} />
      </EditTextContainer>
      {hasToggle && !text.editing ? (
        <CheckboxSwitch
          name={`${name}-"text-editor-toggle"`}
          onChange={() =>
            onChange(settingsId, { ...settings, disabled: !settings.disabled })
          }
          value={!text.disabled}
        />
      ) : null}
    </TexEditorContainer>
  );
}

TextEditor.defaultProps = {
  name: '',
  small: false,
  hasToggle: false,
  text: {},
  onChange: () => {},
};

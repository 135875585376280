import {
  Config,
  Slice,
  Section,
  TextArea,
  Colour,
  Font,
} from '../../helpers/config';
import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'CompareGridPage',
  {
    id: 'compare-grid-page',
  },
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Font('Sub header font', 'subHeaderFont'),
      Font('Information font', 'infoFont'),
      Colour('Border Colour', 'borderColour'),
      Colour('Close Icon Colour', 'closeIconColour'),
      Colour('Contact Icon Colour', 'phoneIconColour'),
      Colour(
        'Optional extras row background',
        'optionalExtrasBackgroundColour',
      ),
      Colour('Shortlist icon colour', 'shortlistIconListedColour'),
      TextArea('Call to action path', 'ctaPath'),
    ),
  ),
);

export default config;

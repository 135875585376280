import Icon from './Icon';

export { default as component } from './BackToTop';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'BackToTop';
export const id = 'BackToTop';
export const availableToAdministration = false;
export const description =
  'Add clickable button that scrolls user back to top of page';
export const icon = Icon;
export const global = true;
export const required = false;
export const position = 3;

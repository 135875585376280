import {
  Config,
  Slice,
  Section,
  Image,
  TextArea,
  Colour,
  Font,
} from '../../helpers/config';

const config = Config(
  'Image title',
  { id: 'imagetitle' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Global',
      {},
      Image('Image', 'selectedImage'),
      TextArea('Header', 'headlineText'),
      TextArea('Description', 'subHeading'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      {},
      Colour('Background Colour', 'backgroundColour'),
      Font('Header', 'headlineFont'),
      Font('Description', 'bodyFont'),
    ),
  ),
);

export default config;

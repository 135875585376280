/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import ContentWrapper from '../../components/ContentWrapper';
import {
  HeadingTwo,
  Paragraph,
  HeadingThree,
  fontStyleOverride,
} from '../../components/Global';
import media from '../../theme';
import { vehicleDataFormatter } from '../../helpers/vehicle';

const criteria = itemLength => [2, 4].includes(itemLength);
const getNumberOfColumns = (
  itemLength: number,
  meetsCritera: string,
  defaultValue: string,
) => (criteria(itemLength) ? meetsCritera : defaultValue);

const Container = styled.div`
  ${media.min.medium`
    padding: 32px 0px;
  `};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${media.min.medium`flex-direction: row;`};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  ${media.min.medium`
    flex-basis: ${({ itemCount }) =>
      getNumberOfColumns(itemCount, '50%', '33.3%')};
    @supports(display: grid) {
      padding: 0px 32px 32px 0px;
      &:nth-child(${({ itemCount }) =>
        getNumberOfColumns(itemCount, '2n+2', '3n+3')}) {
        padding-right: 0px;
      }
    }
  `};
`;

const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  align-items: center;
  border: 1px solid #989898;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour.value};`};
`;

const Image = styled.img`
  width: auto;
  margin: 8px 16px;
  ${media.min.medium`
    width: 20%;
    min-width: 2.5em;
    height: 2.5em;
    margin: 20px 24px 20px 32px;
  `};
`;

const ItemText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function VehicleDataGrid({
  config,
  globalStyling,
  shared,
  marketInfo: { locale },
}: Props) {
  const { vehicle } = shared.vehicleInformation;

  return (
    vehicle && (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <Container>
          <HeadingTwo
            styleOverride={() =>
              `margin: 0px 0px 32px;
              font-size: 28px;
              line-height: 35px;
              ${fontStyleOverride(config.headingFont)}`
            }
          >
            {config.translations.heading}
          </HeadingTwo>
          <ItemsWrapper>
            {config.items.map(item => (
              <ItemWrapper itemCount={config.items.length}>
                <Item backgroundColour={config.itemBackgroundColour}>
                  <Image src={item.image.value} alt={item.image.label} />
                  <ItemText>
                    <Paragraph
                      styleOverride={() =>
                        `margin: 0;
                        font-size: 16px;
                        line-height: 19px;
                        ${fontStyleOverride(config.titleFont)}`
                      }
                    >
                      {item.translations.title}
                    </Paragraph>
                    <HeadingThree
                      styleOverride={() =>
                        `margin: 0;
                        line-height: 25px;
                        font-size: 20px;
                        ${fontStyleOverride(config.dataFont)}`
                      }
                    >
                      {vehicleDataFormatter(item.data, vehicle, locale)}
                    </HeadingThree>
                  </ItemText>
                </Item>
              </ItemWrapper>
            ))}
          </ItemsWrapper>
        </Container>
      </ContentWrapper>
    )
  );
}

export const constants = {
  GET_EXCHANGE_RATES: 'GET_EXCHANGE_RATES',
  GET_EXCHANGE_RATES_SUCCESS: 'GET_EXCHANGE_RATES_SUCCESS',
};

export const actions = {
  getExchangeRates: payload => ({
    type: constants.GET_EXCHANGE_RATES,
    payload,
  }),
  getExchangeRatesSuccess: payload => ({
    type: constants.GET_EXCHANGE_RATES_SUCCESS,
    payload,
  }),
};

export const initialState = {
  exchangeRates: {},
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.GET_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          [payload.base]: payload,
        },
      };
    default:
      return state;
  }
}

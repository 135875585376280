// @flow
import { moduleAction, moduleConstants } from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_COMPARE_GRID';

export const constants = moduleConstants(modulePrefix, [
  'TOGGLE_OPTIONAL_EXTRAS',
  'INIT',
]);

export const id = 'CompareGrid';

export const actions = {
  toggleOptionalExtras: moduleAction(constants.TOGGLE_OPTIONAL_EXTRAS, id),
  init: moduleAction(constants.INIT, id),
};

export const initialState = {
  comparedVehicles: [],
  optionalExtras: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.TOGGLE_OPTIONAL_EXTRAS: {
      return {
        ...state,
        optionalExtras: !state.optionalExtras,
      };
    }
    default:
      return state;
  }
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Paragraph } from '../Global';

import LabelledCheckBox from '../LabelledCheckBox';
import media from '../../theme';

const CheckboxContainer = styled.div`
  display: flex;
  margin: 16px 0;
  ${media.max.large`
    width: 100%;
    flex-direction: column;
    height: 90px;
    justify-content: space-between;
  `};
`;

const RadioButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  &:last-child {
    margin-left: 40px;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: ${({ error }) => (error ? '#9e1b32' : '#333333')};
  margin-left: 4px;
`;

// this has jag colour but we are using as error colour too
const ErrorLabel = styled.span`
  color: #9e1b32;
  margin-left: 15px;
`;

const SubHeader = styled(Paragraph)`
  ${({ globalStyling }) =>
    globalStyling &&
    `font-family: ${globalStyling &&
      globalStyling.fonts.highlightedLabelsFont.value};
  font-weight: 700;
  `};
`;

const Link = styled.a`
  ${({ color }) => color && `color: ${color}`};
`;

const ConsentBodySectionOne = (font, translations) => (
  <Fragment>
    <Paragraph styleOverride={() => font}>
      {translations.marketingConsentItemOne}
      <br />
      {translations.marketingConsentItemTwo}
      <br />
      {translations.marketingConsentItemThree}
    </Paragraph>
  </Fragment>
);

type Props = {
  translations: Object,
  onRadioSelect: Object => void,
  onCheckBoxClick: (formKey: string, value: string | boolean) => void,
  phoneChecked: boolean,
  phoneKey: string,
  emailChecked: boolean,
  emailKey: string,
  onColour: string,
  labelFontSize?: Number,
  checkboxDimension?: Number,
  linkColour?: Object,
  globalStyling: Object,
  marketingLink: string,
  marketingKey: string,
  marketing: string,
  marketingError: string,
  dataConsentFont: Font,
  checkBoxError: string,
};

export default function RRStyle1({
  translations,
  onRadioSelect,
  onCheckBoxClick,
  marketing,
  marketingError,
  marketingKey,
  phoneChecked,
  labelFontSize,
  globalStyling,
  onColour,
  emailChecked,
  phoneKey,
  emailKey,
  dataConsentFont,
  checkBoxError,
}: Props) {
  return (
    <Fragment>
      <div>
        <SubHeader
          globalStyling={globalStyling}
          styleOverride={() => dataConsentFont}
        >
          {translations.marketingConsentContentSubheader}{' '}
        </SubHeader>
        {ConsentBodySectionOne(dataConsentFont, translations)}
        <Paragraph styleOverride={() => dataConsentFont}>
          {translations.marketingConsentContentOne}
        </Paragraph>
        <Paragraph styleOverride={() => dataConsentFont}>
          {translations.moreInformationOneBodyOne}
        </Paragraph>
        <Paragraph styleOverride={() => dataConsentFont}>
          {translations.moreInformationOneBodyTwo}{' '}
          <Link
            colour={
              globalStyling && globalStyling.colours.primaryFontColour.value
            }
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.rolls-roycemotorcars.com/en-GB/information/privacy-policy.html"
          >
            {translations.privacyPolicyHyperLinkText}
          </Link>
        </Paragraph>
      </div>
      <RadioButtonRow>
        <RadioButtonWrapper>
          <input
            checked={marketing === 'yes'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="marketingYes"
            name={marketingKey}
            value="yes"
          />
          <Label error={marketingError} htmlFor="marketingYes">
            {translations.marketingConsentYes}
          </Label>
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <input
            checked={marketing === 'no'}
            onChange={event => onRadioSelect(event)}
            type="radio"
            id="marketingNo"
            name={marketingKey}
            value="no"
          />
          <Label error={marketingError} htmlFor="marketingNo">
            {translations.marketingConsentNo}
          </Label>
        </RadioButtonWrapper>
        {marketingError && (
          <ErrorLabel>{translations.formValidationRequired}</ErrorLabel>
        )}
      </RadioButtonRow>
      {marketing && marketing === 'yes' && (
        <Fragment>
          <Paragraph styleOverride={() => dataConsentFont}>
            {translations.pleaseSelectChannelsText}
          </Paragraph>
          <CheckboxContainer>
            <LabelledCheckBox
              onClick={() => onCheckBoxClick(phoneKey, !phoneChecked)}
              checked={phoneChecked}
              label={translations.dataConsentPhoneLabel}
              onColour={onColour}
              labelFontSize={labelFontSize}
              checkboxDimension={1}
              globalStyling={globalStyling}
            />
            <LabelledCheckBox
              onClick={() => onCheckBoxClick(emailKey, !emailChecked)}
              checked={emailChecked}
              label={translations.dataConsentEmailLabel}
              onColour={onColour}
              labelFontSize={labelFontSize}
              checkboxDimension={1}
              globalStyling={globalStyling}
            />
          </CheckboxContainer>
          {checkBoxError && (
            <ErrorLabel>{translations.formValidationRequired}</ErrorLabel>
          )}
          <div>
            <Paragraph styleOverride={() => dataConsentFont}>
              {translations.moreInformationTwoBodyOne}
              <br />
              {translations.moreInformationTwoBodyTwo}
              <br />
              {translations.moreInformationTwoBodyThree}{' '}
              {translations.moreInformationTwoBodyEmail}
              {'.'}
              <br />
              {translations.moreInformationTwoBodyFour}{' '}
              <Link
                colour={
                  globalStyling && globalStyling.colours.primaryFontColour.value
                }
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.rolls-roycemotorcars.com/en-GB/information/privacy-policy.html"
              >
                {translations.privacyPolicyHyperLinkText}
              </Link>
              <br />
              {translations.marketingConsentContentDisclaimer}
            </Paragraph>
          </div>
        </Fragment>
      )}
      <div style={{ paddingBottom: '16px' }} />
    </Fragment>
  );
}

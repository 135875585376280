// @flow
import React from 'react';
import IconRadioGroup from '../IconRadioGroup';

import LeftDisabled from './LeftAlignDisabled';
import MiddleDisabled from './MiddleAlignDisabled';
import RightDisabled from './RightAlignDisabled';
import LeftEnabled from './LeftAlignEnabled';
import MiddleEnabled from './MiddleAlignEnabled';
import RightEnabled from './RightAlignEnabled';

type Icon = { base: string, active: any, disabled: string };
export type Option = { icons: Icon, value: string };

type Props = {
  name: string,
  value: string,
  open: boolean,
  onChange?: string => void,
  onOpen?: () => void,
  onClose?: () => void,
  inline?: boolean,
  leftRightOnly?: boolean,
};

const all: Option[] = [
  {
    icons: { base: LeftDisabled, active: LeftEnabled, disabled: 'blue' },
    value: 'left',
  },
  {
    icons: { base: MiddleDisabled, active: MiddleEnabled, disabled: 'blue' },
    value: 'center',
  },
  {
    icons: { base: RightDisabled, active: RightEnabled, disabled: 'blue' },
    value: 'right',
  },
];
const [left, middle, right] = all;

const options = [left, middle, right];
const twoOptions = [left, right];

export default function TextAlignment({
  value,
  name,
  open,
  onChange,
  onClose,
  onOpen,
  inline,
  leftRightOnly,
}: Props) {
  const usedOptions = leftRightOnly ? twoOptions : options;
  const objValue: Option =
    usedOptions.find(o => o.value === value) || usedOptions[0];
  return (
    <IconRadioGroup
      name={`${name}Alignment`}
      options={usedOptions}
      selectedValue={objValue}
      open={inline || open}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      inline={inline}
    />
  );
}

TextAlignment.defaultProps = {
  inline: false,
  leftRightOnly: false,
  onChange: null,
  onOpen: null,
  onClose: null,
};

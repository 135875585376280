import React from 'react';

const UppercaseDisabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#757575" d="M1.5 1.5h15v15h-15z" />
      <path
        d="M9 11.991h-.894a.391.391 0 0 1-.387-.262l-.465-1.27H4.677l-.465 1.27a.407.407 0 0 1-.383.262H2.93L5.374 5.77h1.183L9 11.991zM4.975 9.642h1.983L6.2 7.572a9.45 9.45 0 0 1-.237-.73c-.04.146-.08.282-.118.408a4.574 4.574 0 0 1-.114.331l-.757 2.061zm10.136 2.35h-.895a.391.391 0 0 1-.387-.263l-.465-1.27h-2.577l-.465 1.27a.407.407 0 0 1-.382.262h-.9l2.444-6.221h1.183l2.444 6.221zm-4.027-2.35h1.983l-.757-2.07a9.45 9.45 0 0 1-.236-.73c-.04.146-.08.282-.119.408a4.574 4.574 0 0 1-.114.331l-.757 2.061z"
        fill="#757575"
      />
    </g>
  </svg>
);

export default UppercaseDisabled;

import Icon from '../../modules/Register/Icon';

export { id as reducerId } from '../../modules/Register/reducer';

export { default as component } from './Register';
export { default as effects } from '../../modules/Register/effects';
export { default as config } from '../../modules/Register/config';
export { default as defaultConfig } from '../../modules/Register/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/Register/reducer';

export const name = 'Register';
export const id = 'Register';
export const availableToAdministration = false;
export const description =
  'A module that allows the user to register for an account.';
export const icon = Icon;

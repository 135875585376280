import validate from 'validate.js';

export const required = value => (value ? undefined : 'Required');
export const isValidSiteName = value =>
  validate.single(value, { presence: true, format: '[a-zA-Z ]+' });
export const isValidUrl = value =>
  validate.single(value, { presence: true, url: true });
export const requiredCheckboxGroup = value =>
  validate.isArray(value) && value.length ? undefined : 'Required';

export const siteValidator = values => {
  const errors = { configuration: {}, name: '' };

  if (values && values.configuration) {
    errors.name = required(values.name);
    errors.configuration.externalUrl = isValidUrl(
      values.configuration.externalUrl,
    );
    errors.configuration.languages = requiredCheckboxGroup(
      values.configuration.languages,
    );
    errors.configuration.currencies = requiredCheckboxGroup(
      values.configuration.currencies,
    );
    errors.configuration.defaultLanguage = required(
      values.configuration.defaultLanguage,
    );
    errors.configuration.defaultCurrency = required(
      values.configuration.defaultCurrency,
    );
  }

  return errors;
};

// @flow
import React from 'react';
import { connect } from 'react-redux';
import SiteBuilder from '../components/SiteBuilder';
import { actions as editorActions } from '../state/actions/editor';
import { actions as siteActions } from '../state/actions/site';
import { actions as pageActions } from '../state/actions/page';
import { actions as settingsActions } from '../state/actions/settings';
import { actions as siteBuilderActions } from '../state/actions/siteBuilder';
import { actions as externalLinkActions } from '../state/actions/externalLink';
import { actions as contextActions } from '../state/actions/context';
import settings from '../settings';
import type { Props as SiteBuilderProps } from '../components/SiteBuilder';
import type { SiteId } from './SiteContainer';
import { HIDDEN_PAGE_TYPES } from '../constants/pageTypes';

const mapStateToProps = ({
  siteBuilder,
  global,
  editor,
  context,
  config,
  site,
}) => {
  let defaultLanguage = null;

  if (
    site.sites[site.currentSiteId] &&
    site.sites[site.currentSiteId].configuration
  ) {
    ({ defaultLanguage } = site.sites[site.currentSiteId].configuration);
  }

  return {
    globalStyling: {
      fonts: editor.branding.fonts,
      colours: editor.branding.colours,
    },
    brandName: editor.branding.name,
    moduleName: context.module,
    selectedScreenSize: siteBuilder.selectedScreenSize,
    site: siteBuilder.current,
    showScreenSizeSelector: !context.global || context.global === 'Global',
    publish: siteBuilder.publish,
    defaultLanguage,
    environment: settings.environment,
    previewBase: settings.previewBase,
    pages: Object.values(config.pages)
      .filter(p => !HIDDEN_PAGE_TYPES.includes(p.pageType)) // TODO refactor out this duplicated code
      .filter(p => !p.removed),
    currentPage: context.page,
    global,
  };
};

const mapDispatchToProps = dispatch => ({
  onScreenSizeChange: size =>
    dispatch(siteBuilderActions.setPreviewScreenSize(size)),
  onSaveChanges: siteId => {
    dispatch(pageActions.saveChanges(siteId));
  },
  onPublish: (siteId: string) =>
    dispatch(siteBuilderActions.publishSite(siteId)),
  onPublishClick: () => dispatch(siteBuilderActions.publishSiteRequested()),
  onPublishPreviewClick: (siteId: string, defaultLangaugeIso: string) => {
    // open the window here so triggered by user click and wont get stopped by popup blockers
    const previewWindow = window.open();
    dispatch(
      siteBuilderActions.publishPreviewSite(
        siteId,
        defaultLangaugeIso,
        previewWindow,
      ),
    );
  },

  onPublishCancelled: () => dispatch(siteBuilderActions.publishSiteCancelled()),
  // eslint-disable-next-line no-console
  onMenuClick: () => console.warn('TODO - top bar menu'),
  loadBranding: () => dispatch(editorActions.loadBranding()),
  loadSite: (siteId: string) => dispatch(siteActions.loadSite(siteId)),
  loadPages: (siteId: string) => dispatch(pageActions.loadPages(siteId)),
  loadLinks: (siteId: string) =>
    dispatch(externalLinkActions.loadLinks(siteId)),
  loadGlobalModules: (siteId: string) =>
    dispatch(siteBuilderActions.loadGlobalModules(siteId)),
  loadMediaContent: () => dispatch(siteBuilderActions.loadMediaContent()),
  loadSettings: (siteId: string) =>
    dispatch(settingsActions.loadSettings(siteId)),
  setPageContext: (pageId: string, pageName: string) => {
    dispatch(contextActions.setPageContext(pageId, pageName));
    dispatch(contextActions.clearContext());
  },
  onHomeMenuClick: () => dispatch(contextActions.clearContext()),
});

type Props = SiteBuilderProps & {
  loadBranding: () => void,
  loadMediaContent: () => void,
  loadLinks: SiteId => void,
  loadSite: SiteId => void,
  loadPages: SiteId => void,
  loadSettings: SiteId => void,
  loadGlobalModules: SiteId => void,
  loadLinkedModules: SiteId => void,
  siteId: string,
};

class SiteBuilderLifecycle extends React.Component<Props, *> {
  componentDidMount() {
    this.props.loadBranding();
    this.props.loadMediaContent();
    this.props.loadSite(this.props.siteId);
    this.props.loadPages(this.props.siteId);
    this.props.loadLinks(this.props.siteId);
    this.props.loadGlobalModules(this.props.siteId);
    this.props.loadSettings(this.props.siteId);
  }

  render() {
    return <SiteBuilder {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteBuilderLifecycle);

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Phone({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 30 25">
        <path d="M23.839 18.82c-1.118 2.708-3.766 3.178-4.649 3.178-.236 0-4.237.236-9.946-5.18-4.648-4.414-5.178-9.064-5.237-9.947-.06-.883.177-3.061 3.178-4.65.412-.236 1.118-.295 1.294-.118.06.059 2.59 4.238 2.649 4.356.058.117.118.294.118.412 0 .176-.118.412-.413.706-.294.294-.588.53-.882.765-.295.236-.589.471-.883.765-.236.236-.353.471-.353.648 0 .412.353 2.001 3.12 4.415 2.765 2.413 4.06 3.12 4.177 3.12.118.058.236.058.294.058.177 0 .412-.117.648-.412l1.412-1.766c.294-.294.53-.412.706-.412.118 0 .294.06.413.118.117.059 4.295 2.472 4.354 2.59.294.235.118.942 0 1.354" />
      </svg>
    </IconWrapper>
  );
}

Phone.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

import editor from './editor';

export default {
  id: 'SearchResults',
  name: 'Vehicle Tile',
  description: 'Edit a vehicle tile',
  defaultSlice: 'Content',
  defaultConfig: {
    highlights: [
      { prop: 'registration', translation: 'registration' },
      { prop: 'interior', translation: 'interior' },
      { prop: 'exterior', translation: 'exterior' },
      { prop: 'transmission', translation: 'transmission' },
    ],
    title: [
      { prop: 'registration' },
      { prop: 'modelGroupName' },
      { prop: 'modelVariant' },
    ],
    description: [{ prop: 'exterior' }, { prop: 'interior' }],
    pricing: [{ prop: 'totalPrice' }],
    showViews: true,
    translations: {
      buttonText: 'View',
      registration: 'Registration',
      interior: 'Interior',
      exterior: 'Exterior',
      transmission: 'Transmission',
      ctaButtonLabel: 'View',
      viewsLabel: 'Views',
      approvedLabel: 'Approved',
      'odometer.reading': 'Mileage',
      modelYear: 'Model Year',
    },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  requirements: {
    columns: [1],
  },
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Stopwatch({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 25 21">
        <path d="M12.49 3.612c4.268.429 7.587 3.707 7.587 7.674-.001 4.15-3.635 7.556-8.224 7.709-4.59.152-8.49-3.003-8.83-7.141-.338-4.14 3.006-7.779 7.57-8.238v-.9H9.17V1h4.742v1.712H12.49v.9zM11.54 18.14c4.173 0 7.588-3.085 7.588-6.856s-3.415-6.856-7.588-6.856c-4.172 0-7.587 3.085-7.587 6.856 0 3.77 3.415 6.856 7.587 6.856zm-4.345-2.884c-1.212-1.06-1.892-2.5-1.888-4-.004-1.499.676-2.938 1.888-3.999 1.213-1.06 2.858-1.654 4.573-1.651v11.302c-1.715.003-3.36-.591-4.573-1.652zM17.73 2.675A10.289 10.289 0 0 1 21 5.64l-1.345 1.22c-.794-1.224-1.92-2.245-3.27-2.965l1.345-1.22z" />
      </svg>
    </IconWrapper>
  );
}

Stopwatch.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

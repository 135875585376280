// @flow
import type { Font, ButtonType, Link } from '../../types';

export const fontStyleOverride = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
  alignment,
  padding,
}: Font = {}) =>
  [
    typeface && `font-family: ${typeface.value};`,
    fontSize && `font-size: ${fontSize}px;`,
    kerning !== undefined && `letter-spacing: ${kerning}px;`,
    transform && `text-transform: ${transform};`,
    colour && `color: ${colour.value};`,
    alignment && `text-align: ${alignment};`,
    padding && padding.top && `padding-top: ${padding.top}px;`,
    padding && padding.bottom && `padding-bottom: ${padding.bottom}px;`,
    padding && padding.left && `padding-left: ${padding.left}px;`,
    padding && padding.right && `padding-right: ${padding.right}px;`,
  ]
    .filter(Boolean)
    .join('');

export const buttonStyleOverride = ({
  font,
  backgroundColour,
  hoverBackgroundColour,
  buttonSize,
  hoverColour,
}: ButtonType = {}) =>
  [
    fontStyleOverride(font),
    backgroundColour && `background-color: ${backgroundColour.value};`,
    hoverBackgroundColour &&
      `background-color: ${hoverBackgroundColour.value};`,
    buttonSize && buttonSize.width && `width: ${buttonSize.width};`,
    buttonSize && buttonSize.height && `height: ${buttonSize.height};`,
    hoverColour && `color: ${hoverColour.value};`,
  ]
    .filter(Boolean)
    .join('');

export const linkStyleOverride = ({ borderColour, borderBottom }: Link = {}) =>
  [
    borderColour && `border-color: ${borderColour.value};`,
    borderBottom && `border-bottom: solid ${borderBottom}px;`,
  ]
    .filter(Boolean)
    .join('');

export const selectStyleOverride = ({
  font,
  borderColour,
  borderWidth,
  width,
  height,
  margin,
}: SelectType = {}) =>
  [
    fontStyleOverride(font),
    borderColour && `border-color: ${borderColour.value};`,
    borderWidth && `border-width: ${borderWidth.value};`,
    width && `width: ${width};`,
    height && `height: ${height};`,
    margin &&
      Object.keys(margin)
        .map(k => `margin-${k}: ${margin[k]};`)
        .filter(Boolean),
  ].filter(Boolean);

// @flow
import React from 'react';
import styled from 'styled-components';
import Image from '../Image';
import { Link, fontStyleOverride } from '../Global';
import { type Font } from '../../../types';

const LinksList = styled.ul`
  padding: 0;
  margin: 0;
`;

const MenuListItem = styled.li`
  margin: 0;
  padding: 0 20px;
  list-style: none;
  display: inline-block;
`;

type Props = {
  links: [
    {
      iconUrl: string,
      label: string,
      url: string,
    },
  ],
  font: Font,
};

export default ({ links, font }: Props) => (
  <LinksList>
    {links
      .filter(link => link.url)
      .map(link => (
        <MenuListItem key={link.label}>
          <Link styleOverride={() => fontStyleOverride(font)} href={link.url}>
            {link.iconUrl && (
              <img
                src={link.iconUrl}
                backgroundSize="contain"
                height="26"
                alt={link.label}
              />
            )}
            {!link.iconUrl && link.label}
          </Link>
        </MenuListItem>
      ))}
  </LinksList>
);

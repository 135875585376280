// @flow
import React from 'react';
import { connect } from 'react-redux';
import Homepage, { navOptions } from '../components/Homepage';
import type { NavContext } from '../components/Homepage';
import { actions as editorActions } from '../state/actions/editor';
import { actions as siteActions } from '../state/actions/site';
import { actions as routerActions } from '../state/actions/router';
import { actions as siteBuilderActions } from '../state/actions/siteBuilder';
import type { Branding } from '../state/reducers/editor';
import type { Site } from '../state/reducers/site';

const mapStateToProps = ({ site, editor }) => ({
  sites: site.sites,
  branding: editor.branding,
});

const mapDispatchToProps = dispatch => ({
  navigate: route => dispatch(routerActions.navigate(route)),
  loadSites: () => dispatch(siteActions.loadSites()),
  loadBranding: () => dispatch(editorActions.loadBranding()),
  loadMediaContent: () => dispatch(siteBuilderActions.loadMediaContent()),
});

type Props = {
  sites: { [id: $PropertyType<Site, 'id'>]: Site },
  branding: Branding,
  navigate: Function,
  loadSites: Function,
  loadBranding: Function,
  onTabClick: (tab: string) => void,
  loadMediaContent: () => void,
};

type State = {
  navContext: NavContext,
};

class HomePageLifecycle extends React.Component<Props, State> {
  state = {
    navContext: navOptions.SITE_LIST,
  };

  componentDidMount() {
    this.props.loadBranding();
    this.props.loadMediaContent();
  }

  onTabClick = (tab: NavContext) => {
    this.setState(() => ({ navContext: tab }));
  };

  props: Props;

  render() {
    return (
      <Homepage
        {...{
          ...this.props,
          navContext: this.state.navContext,
          onTabClick: this.onTabClick,
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePageLifecycle);

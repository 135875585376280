// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Video } from 'cloudinary-react';
import type { Theme } from '../theme';

const mediaTypes = ['image', 'video', 'svg'];
type MediaType = 'image' | 'video' | 'svg';
type MediaMetaInfo = {
  width: number,
  height: number,
  format: string,
  version: number,
  size: string,
};
type Item = { label: string, publicId: string, value: string };
type MediaItem = Item & {
  meta: MediaMetaInfo,
  withDelete: boolean,
};

type Mode = 'Global' | 'Module';

type Props = {
  items: MediaItem[],
  type: MediaType,
  accountName: string,
  mediaBaseUrl: string,
  onDeleteItem: (item: Item) => void,
  onSelectItem: (item: Item) => void,
  onCropItem: (item: Item) => void,
  mode: Mode,
};

type MediaProps = {
  item: MediaItem,
  onDelete: (item: Item) => void,
  onSelect: (item: Item) => void,
  onCrop: (item: Item) => void,
};

type ThemeProps = Props & {
  theme: Theme,
  left: boolean,
  disabled: boolean,
  mode: Mode,
};

const TileContainer = styled.div.withConfig({
  displayName: 'TileContainer',
})`
  display: flex;
  flex-basis: 18%;
  flex-direction: column;
  min-width: 150px;
  margin: 1%;
`;

const MediaGalleryContainer = styled.div.withConfig({
  displayName: 'MediaGalleryContainer',
})`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props: ThemeProps) => props.theme.colours.secondary01};
  justify-content: flex-start;
  width: 100%;
`;

const TileInfoContainer = styled.div.withConfig({
  displayName: 'TileInfoContainer',
})`
  background-color: ${(props: ThemeProps) => props.theme.colours.secondary04};
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 5px;
`;

const TileMetaContainer = styled.div.withConfig({
  displayName: 'TileMetaContainer',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props: ThemeProps) => props.theme.fontSizeFormControls};
  font-weight: 500;
`;

const NavButton = styled.button.withConfig({
  displayName: 'NavButton',
})`
  height: 40px;
  width: 32px;
  border: 1px solid ${(props: ThemeProps) => props.theme.colours.secondary02};
  background-color: ${(props: ThemeProps) => props.theme.colours.secondary01};
  cursor: ${(props: ThemeProps) =>
    props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${(props: ThemeProps) => (props.disabled ? '0.5' : '1')};

  :after {
    content: '';
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    display: inline-block;
    height: 0.5em;
    left: 0;
    position: relative;
    top: 0.3em;
    transform: rotate(${(props: ThemeProps) => (props.left ? '225' : '45')}deg);
    vertical-align: top;
    width: 0.5em;
    color: ${(props: ThemeProps) => props.theme.colours.primary01};
  }
`;

const Pagination = styled.div.withConfig({
  displayName: 'Pagination',
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
`;

const Label = styled.span.withConfig({
  displayName: 'Label',
})`
  padding: 10px;
  color: ${(props: ThemeProps) => props.theme.colours.secondary02};
`;

const EllipsisLabel = styled.span.withConfig({
  displayName: 'EllipsisLabel',
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 10%);
`;

const IconButton = styled.input`
  padding: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const imageTransformationParameter = 'w_150,h_150';
const imageUrl = (baseUrl, format, publicId, version) =>
  `${[
    baseUrl,
    'image',
    'upload',
    imageTransformationParameter,
    `v${version}`,
    publicId,
  ].join('/')}.${format}`;

const TileInfo = ({
  label,
  meta,
  withDelete,
  onDelete,
  onCrop,
}: MediaItem & {
  onDelete: () => void,
  onCrop: boolean | ((item: Item) => void),
}) => (
  <TileInfoContainer data-qa-hook={`${label}-container`}>
    <EllipsisLabel data-qa-hook={`${label}-label`}>{label}</EllipsisLabel>
    <TileMetaContainer>
      <span data-qa-hook={`${label}-meta`}>
        {meta && `${meta.width} x ${meta.height}, ${meta.size}`}
      </span>
      <ButtonContainer>
        {withDelete && (
          <IconButton
            type="image"
            src="https://res.cloudinary.com/motortrak/image/upload/v1570186934/editor/delete-icon.svg"
            alt="delete image"
            onClick={onDelete}
            data-qa-hook={`${label}-delete`}
          />
        )}
        {onCrop && (
          <IconButton
            type="image"
            src="https://res.cloudinary.com/motortrak/image/upload/v1570021938/editor/edit-icon.svg"
            alt="Crop image"
            onClick={onCrop}
          />
        )}
      </ButtonContainer>
    </TileMetaContainer>
  </TileInfoContainer>
);

const withMedia = Component => ({
  item: { label, publicId, meta, withDelete },
  item,
  onDelete,
  onSelect,
  onCrop,
}: MediaProps) => (
  <TileContainer>
    <Component
      publicId={publicId}
      version={meta && meta.version}
      format={meta && meta.format}
      onSelect={() => onSelect(item)}
    />
    <TileInfo
      label={`${label}${meta && meta.format ? `.${meta.format}` : ''}`}
      meta={meta}
      withDelete={withDelete}
      onDelete={() => onDelete(item)}
      onCrop={onCrop && (() => onCrop(item))}
    />
  </TileContainer>
);

const Image = styled.img.withConfig({
  displayName: 'Image',
})`
  cursor: ${(props: ThemeProps) =>
    props.mode === 'Module' ? 'pointer' : 'unset'};
  height: 150px;
  object-fit: cover;
  object-position: 50% 50%;
`;

const VideoWrapper = styled.div.withConfig({
  displayName: 'VideoWrapper',
})`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: ${(props: ThemeProps) =>
    props.mode === 'Module' ? 'pointer' : 'unset'};
`;

const VideoComponent = (accountName, mode) => ({
  publicId,
  onSelect,
}: {
  publicId: string,
  onSelect: () => void,
}) => (
  <VideoWrapper mode={mode} onClick={mode === 'Module' ? onSelect : null}>
    <Video
      width="100%"
      height="100%"
      cloudName={accountName}
      publicId={publicId}
      controls={mode === 'Global'}
      data-qa-hook={`${publicId}-video`}
    />
  </VideoWrapper>
);

const ImageComponent = (baseUrl, mode) => ({
  publicId,
  format,
  version,
  onSelect,
}: {
  publicId: string,
  format: string,
  version: number,
  onSelect: () => void,
}) => (
  <Image
    mode={mode}
    src={imageUrl(baseUrl, format, publicId, version || 1)}
    onClick={mode === 'Module' ? onSelect : null}
    data-qa-hook={`${publicId}-image`}
  />
);

export default class MediaGallery extends React.Component<Props, *> {
  static defaultProps = {
    items: [],
    type: 'image',
    accountName: '',
    mediaBaseUrl: '',
    onDeleteItem: () => {},
    onSelectItem: () => {},
    mode: 'Global',
  };

  state = {
    currentPage: 1,
    itemsPerPage: 10,
  };

  onPrevNav = () =>
    this.setState(({ currentPage }) => ({ currentPage: currentPage - 1 }));

  onNextNav = () =>
    this.setState(({ currentPage }) => ({ currentPage: currentPage + 1 }));

  maxPageNumber = Math.ceil(this.props.items.length / this.state.itemsPerPage);

  render() {
    const {
      items,
      type,
      accountName,
      onDeleteItem,
      onCropItem,
      onSelectItem,
      mediaBaseUrl,
      mode,
    } = this.props;
    const { currentPage, itemsPerPage } = this.state;
    if (mediaTypes.includes(type)) {
      const Tile = withMedia(
        type === 'image'
          ? ImageComponent(mediaBaseUrl, mode)
          : VideoComponent(accountName, mode),
      );
      const lastItemIndex = currentPage * itemsPerPage;
      const firstItemIndex = lastItemIndex - itemsPerPage;
      const itemsToDisplay = items.slice(firstItemIndex, lastItemIndex);

      return (
        <Fragment>
          <MediaGalleryContainer>
            {itemsToDisplay.map(item => (
              <Tile
                key={`${item.publicId}`}
                onSelect={onSelectItem}
                onDelete={onDeleteItem}
                onCrop={type === 'image' && onCropItem}
                item={item}
              />
            ))}
          </MediaGalleryContainer>
          {items.length > 0 && (
            <Pagination>
              <Label>
                {`${firstItemIndex + 1} - ${
                  lastItemIndex > items.length ? items.length : lastItemIndex
                } of ${items.length}`}
              </Label>
              <NavButton
                onClick={this.onPrevNav}
                disabled={currentPage === 1}
                left
              />
              <NavButton
                onClick={this.onNextNav}
                disabled={this.maxPageNumber === currentPage}
              />
            </Pagination>
          )}
        </Fragment>
      );
    }
    return null;
  }
}

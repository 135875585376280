// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme, Font } from '../../types';
import fallbackFonts from '../../components/Global/fallbackFonts';

import media from '../../theme';

import { actions as routerActions } from '../../actions/router';

import { SlideOne, SlideTwo, SlideThree, SlideFour } from './Slides';
import { PaginationOne, PaginationTwo } from './Pagination';

type Option = {
  value: ?string,
  label: ?string,
};

export const themeFromProps = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
  lineHeight,
  fontWeight,
}: Font): Theme => ({
  fontFamily: typeface ? `${typeface.label},${fallbackFonts}` : '',
  fontSize: fontSize ? `${fontSize}px` : '',
  characterSpacing: kerning ? `${kerning}px` : '',
  textTransform: transform || '',
  colour: colour ? colour.value : '',
  lineHeight: lineHeight ? `${lineHeight}px` : '',
  fontWeight: fontWeight ? `${fontWeight}` : '',
});

const Placeholder = styled.div.withConfig({ displayName: 'Placeholder' })`
  ${media.max.extraLarge`height: 600px;`};
  ${media.max.medium`height: 450px;`};
  ${media.max.large`height: 250px;`};
`;

const Container = styled.div.withConfig({ displayName: 'Container' })`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 1px;
`;

export const slideContentStyles = {
  slideContentOne: SlideOne,
  slideContentTwo: SlideTwo,
  slideContentThree: SlideThree,
  slideContentFour: SlideFour,
};

export const paginationStyles = {
  paginationOne: PaginationOne,
  paginationTwo: PaginationTwo,
};

export interface Config {
  displaySlide: number;
  slideHeaderFont: Font;
  slideBodyFont: Font;
  slideButtonFont: Font;
  buttonBGColour: Option;
  slidesLoopEnabled: boolean;
  items: Object[];
  bodyBGColour: Option;
  headerBGColour: Option;
  backgroundOpacity: string;
  contentWidth: string;
  slideContentStyle: Option;
  paginationStyle: Option;
  slideTransitionSpeed: number;
  slideButtonStyle: Object;
  headerPositionStyle: Object;
  paginationOpacity: number;
  slideContentLeft: number;
  buttonWidth: number;
  buttonHeight: number;
  buttonTextAlignment: string;
}

type State = {
  counter: number,
  step: number,
};

type Props = {
  config: Config,
  preview: boolean,
  dispatch: Function,
  globalStyling: Object,
};

export default class HeroCarousel extends React.Component<Props, State> {
  state = { counter: 0, step: 0 };

  componentDidMount() {
    if (!this.props.config.slidesLoopEnabled) return;
    this.interval = setInterval(() => {
      this.nextSlide();
    }, this.props.config.slideTransitionSpeed * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  interval = 0;

  nextSlide() {
    const { counter, step } = this.state;
    const numSlides = this.props.config.items.length;

    if (numSlides === 1) return;

    this.setState({
      counter: (counter + 1) % numSlides,
      step: (step + 1) % 3,
    });
  }

  handleClick = (index: number) => {
    this.setState({ counter: index });
  };

  render() {
    const { dispatch, config, globalStyling } = this.props;
    const displayItem = this.props.preview
      ? config.displaySlide
      : this.state.counter;

    const slide = config.items[displayItem];

    const theme = {
      linkBackgroundColour: config.buttonBGColour,
      contentWidth: config.contentWidth,
      buttonOverrideStyle: config.slideButtonStyle,
      headerPositionStyle: config.headerPositionStyle,
      headerTheme: {
        ...themeFromProps(config.slideHeaderFont || {}),
        backgroundColour: config.headerBGColour && config.headerBGColour.value,
        backgroundOpacity: config.backgroundOpacity,
      },
      bodyTheme: {
        ...themeFromProps(config.slideBodyFont || {}),
        backgroundColour: config.bodyBGColour && config.bodyBGColour.value,
        backgroundOpacity: config.backgroundOpacity,
      },
      linkTheme: {
        ...themeFromProps(config.slideButtonFont || {}),
        buttonWidth: config.buttonWidth,
        buttonHeight: config.buttonHeight,
        alignment: config.buttonTextAlignment,
        backgroundColour: config.buttonBGColour && config.buttonBGColour.value,
        buttonStyle:
          globalStyling.uiElements.primaryButton &&
          globalStyling.uiElements.primaryButton.buttonStyle,
      },
    };

    const Slide = slideContentStyles[config.slideContentStyle.value];
    const Pagination = paginationStyles[config.paginationStyle.value];
    return (
      <Container>
        {slide ? (
          <Slide
            {...theme}
            {...slide}
            preview={this.props.preview}
            onLinkClick={url => dispatch(routerActions.navigate(url))}
            slideContentWidth={globalStyling.contentWidth}
            headerMobileFontSize={config.headerMobileFontSize}
            top={config.slideContentTop}
            headerLeftPadding={config.headerLeftPadding}
            headerRightPadding={config.headerRightPadding}
          />
        ) : (
          <Placeholder />
        )}
        <Pagination
          items={config.items}
          onPageclick={item => this.handleClick(item)}
          activeItem={displayItem}
          activeColour={config.paginationActiveColour.value}
          inactiveColour={config.paginationInactiveColour.value}
          paginationOpacity={config.paginationOpacity}
          align={slide && slide.align}
          alignmentWidth={slide && slide.alignmentWidth}
          paginationWidth={globalStyling.contentWidth}
        />
      </Container>
    );
  }
}

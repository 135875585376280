export default {
  translations: {
    myAccount: 'my account',
    myAccountMyAccountLabel: 'my account',
    myAccountDealsLabel: 'my deals',
    myAccountTestDrivesLabel: 'my test drives',
    myAccountDetailsLabel: 'my details',
    myAccountShortlistLabel: 'shortlist',
    myAccountSignOutLabel: 'sign out',
    myAccountGreeting: 'Hello',
    myAccountGreetingContent:
      'Within your account you can edit your personal details, and saved deals. View your shortlisted cars, and requested test drives.',
    myAccountSaveActionButton: 'save details',
    myAccountConfirmDeletionMessage:
      'Are you sure you want to delete your account? This cannot be undone.',
    myAccountChangePasswordSuccess: 'Your password has been changed.',
    myAccountChangePasswordFailure:
      'Something went wrong, please try again later.',
    myAccountSaveDetailsSuccess: 'Your details have been changed.',
    myAccountSaveDetailsFailure:
      'Something went wrong, please try again later.',
    myAccountRetailerPhone: 'Phone',
    myAccountRetailerContact: 'Contact',
    myAccountDealPrice: 'Price',
    myAccountDealMonthlyPrice: 'Monthly',
    myAccountDealAPR: 'APR',
    myAccountDealEdit: 'Edit',
    myAccountDealDelete: 'Delete',
    myAccountDetailsCurrentPassword: 'Current password',
    myAccountDetailsNewPassword: 'New password',
    myAccountDetailsConfirmNewPassword: 'Confirm new password',
    myAccountDetailsHeader: 'MY DETAILS',
    myAccountDetailsSubHeader:
      'Edit or update your personal details and preferences here.',
    printDealLabel: 'print my deal',
    finalPayment: 'Final Payment',
    financeMonthlyPayment: 'Monthly Payment',
    financeNumberOfPayments: 'Number of Payments',
    financeDurationOfAgreement: 'Duration of Agreement (Months)',
    financeTotalCredit: 'Total Credit',
    financeTotalInstalments: 'Total Instalments',
    financeTotalAmountOwed: 'Total Amount Owed',
    financeInterestRate: 'Interest Rate',
    financeAPR: 'APR',
    financeDeposit: 'Deposit',
    financeTotalCost: 'Vehicle Price',
    respresentativeExampleHeading:
      'Representative Hire Purchase Finance Example',
    representativeExampleTermsAndConditions:
      'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21',
    myDealDocTitle: 'MY DEAL',
    price: 'Price',
    monthly: 'Monthly',
    financeValidFom: 'Valid from',
    financeValidUntil: 'Offer valid until',
    financeTermsAndConditions:
      'Estas condiciones se mantendrán en vigor durante un periodo mínimo de 15 días naturales desde la fecha de su impresión y se aplicaran previo análisis crediticio favorable de FCA Capital España, EFC, SAU. El PVP es orientativo y no incluye ofertas promocionales ocasionales ni otras revisiones de precios que obedezcan a otros factores. Acuda a su concesionario más cercano para que le facilite el PVP así como un plan de financiación personalizado.',
  },
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Inspection({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 40 40">
        <path d="M20.92 4.722c-.644 0-1.202-.028-1.756.004a1.882 1.882 0 0 1-2.017-1.771 1.862 1.862 0 0 1 1.877-1.921 51.607 51.607 0 0 1 3.745 0 1.804 1.804 0 0 1 1.85 1.93c.01 3.43.102 6.863-.048 10.288-.124 2.84-1.404 5.255-3.488 7.221a11.208 11.208 0 0 1-5.512 2.836.402.402 0 0 0-.415.463c.034 1.698 0 3.397.09 5.092a6.534 6.534 0 0 0 5.088 5.875 6.664 6.664 0 0 0 8.11-5.522.402.402 0 0 0-.318-.498 5.565 5.565 0 0 1-3.346-4.345c-.504-2.859 1.705-5.835 4.582-6.315a5.674 5.674 0 0 1 6.532 4.476c.5 2.512-.8 5.038-3.134 6.093-.38.14-.639.493-.658.897-.472 3.651-2.37 6.37-5.662 7.991a10.32 10.32 0 0 1-14.06-5.02 10.971 10.971 0 0 1-.921-4.511c-.023-1.355-.02-2.71.007-4.064a.533.533 0 0 0-.498-.652 11.031 11.031 0 0 1-7.193-4.956 9.978 9.978 0 0 1-1.712-5.328C2.013 9.705 2.001 6.42 2 3.14c0-1.396.776-2.12 2.192-2.12a259.5 259.5 0 0 1 3.253 0c.978-.083 1.856.598 2.02 1.567a1.823 1.823 0 0 1-1.654 2.12c-.558.023-1.118.018-1.677.005-.304-.01-.448.068-.443.403.037 2.709-.115 5.436.136 8.127.277 2.986 2.058 5.051 4.88 6.139a7.54 7.54 0 0 0 10.076-5.795c.12-1.085.165-2.178.135-3.27.02-1.6.005-3.203.005-4.803v-.792h-.003zm9.453 21.709a2.848 2.848 0 1 0-2.887-2.84 2.823 2.823 0 0 0 2.887 2.84z" />
      </svg>
    </IconWrapper>
  );
}

Inspection.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

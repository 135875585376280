// @flow
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Global';
import UserLocation from '../../components/UserLocation';
import useLocation from '../../shared/location';
import media from '../../theme';
import { useLocationHook } from './useLocationHook';

const SearcherContainer = styled.div`
  border-top: 1px solid #d5d5d5;
  outline: none;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const SearchWrapper = styled.div`
  display: block;
  padding: 5px;
  box-sizing: border-box;
  margin: 0 auto;
  ${media.min.small`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 550px;
  `};
  ${media.min.medium`
    width: 650px;
  `};
  ${media.min.large`
    width: 800px;
  `};
`;

const SearcherBar = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const ButtonDiv = styled.div`
  padding: 5px 0px 5px;
  ${media.min.small`
    padding-left: 0px;
  `};
`;

type Props = {
  count: number,
  config: Object,
  shared: Object,
  globalStyling: Object,
};

export default ({
  config: { translations, locationIconUrl },
  globalStyling: {
    direction,
    uiElements: {
      primaryButton: { buttonStyle },
    },
  },
}: Props) => {
  const {
    onButtonClick,
    updateLocationFilter,
    latitude,
    longitude,
  } = useLocationHook();
  const { lat, long, setLat, setLong } = useLocation();
  return (
    <SearcherContainer id="sticky-search">
      <SearchWrapper>
        <SearcherBar>
          <UserLocation
            direction={direction}
            placeholderText={translations.locationPlaceholder}
            iconUrl={locationIconUrl}
            browserLocationCallback={(latResponse, longResponse) => {
              setLat(latResponse);
              setLong(longResponse);
              updateLocationFilter(latResponse, longResponse);
            }}
            inputLocationCallback={(latResponse, longResponse) => {
              setLat(latResponse);
              setLong(longResponse);
              updateLocationFilter(latResponse, longResponse);
            }}
            options={{
              autocompletePosition: 'above',
              latitude,
              longitude,
            }}
          />
        </SearcherBar>
        <ButtonDiv>
          <Button
            buttonStyle={buttonStyle}
            styleOverride={() => `
              height: 46px;
              border-radius: 0px;
            `}
            mobileStyleOverride={() => 'height: 46px;'}
            text={translations.searchCTASearch}
            onClick={onButtonClick}
            disabled={!(latitude && longitude) && !(lat && long)}
          />
        </ButtonDiv>
      </SearchWrapper>
    </SearcherContainer>
  );
};

// @flow

import React from 'react';
import styled from 'styled-components';

import { InputField } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Left = styled.div`
  display: flex;
  flex: 2 0 0%;
  font-size: 13px;
`;

const Right = styled.div`
  display: flex;
  flex: 1 0 0%;
  justify-content: flex-end;
`;

export default ({
  name,
  values: { id: value },
  ids: { id },
  onChange,
  inputLabel,
  min,
  max,
  step,
}: FieldProps) => {
  const input = {
    value,
    onChange: event => {
      const inputValue = event.target.value;
      const converted = inputValue ? parseFloat(inputValue) : 0;

      if (!Number.isNaN(converted)) {
        let newValue = Math.min(converted, max || converted);
        newValue = Math.max(newValue, min || newValue);
        onChange(id, newValue);
      }
    },
  };
  return (
    <Container>
      <Left>
        <strong>{name}</strong>
      </Left>
      <Right>
        <InputField
          input={input}
          type="number"
          maxInputWidth={54}
          inputLabel={inputLabel}
          inputLabelPosition="right"
          name={name}
          initialValue={value}
          inputFlexMode="1"
          min={min || 0}
          max={max}
          step={step || 1}
        />
      </Right>
    </Container>
  );
};

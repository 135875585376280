// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import FormField from './FormField';

const Select = styled.select`
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-family: inherit;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#9e1b32' : '#d8d8d8')};
  padding: 0px 15px;
  color: #7e7e7e;
  background-color: #fff;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
`;

type Props<Option> = {
  keyValue: string,
  value: Option,
  onChange: (input: { keyValue: string, value: any }) => void,
  disabled: boolean,
  options: Option[],
  valueFromOption: (input: Option) => any,
  labelFromOption: (input: Option) => any,
  doubleRow: boolean,
  label: string,
  required: boolean,
  children: Node,
  error?: string,
  value: string,
};

export default function SelectField<Option>({
  doubleRow,
  label,
  required,
  keyValue,
  onChange,
  disabled,
  options,
  valueFromOption,
  labelFromOption,
  error,
  value,
  globalStyling,
}: Props<Option>) {
  return (
    <FormField
      doubleRow={doubleRow}
      label={label}
      required={required}
      globalStyling={globalStyling}
    >
      <Select
        onChange={({ target: { value: val, options: op, selectedIndex } }) => {
          const optionLabel = op[selectedIndex].innerText;
          onChange({ keyValue, value: val, label: optionLabel });
        }}
        disabled={disabled}
        error={error}
        value={value}
        dir={globalStyling.direction}
      >
        {options
          .filter(opt => opt.label !== undefined)
          .map(o => {
            const optionLabel = labelFromOption(o);
            const optionValue = valueFromOption(o);
            return (
              <option key={`${optionValue}${optionLabel}`} value={optionValue}>
                {optionLabel}
              </option>
            );
          })}
      </Select>
      {error && (
        <div style={{ color: '#9e1b32', textAlign: 'left' }}>{error}</div>
      )}
    </FormField>
  );
}

SelectField.defaultProps = {
  valueFromOption: (i: { value: any }) => i.value,
  labelFromOption: (i: { label: any }) => i.label,
  error: '',
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Eye({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 25 18">
        <path d="M12 3C7.494 3 3.619 5.749 2.087 9.65 3.619 13.551 7.494 16.3 12 16.3s8.381-2.749 9.913-6.65C20.381 5.749 16.506 3 12 3zm0 11.083c-2.523 0-4.506-1.95-4.506-4.433 0-2.483 1.983-4.433 4.506-4.433s4.506 1.95 4.506 4.433c0 2.483-1.983 4.433-4.506 4.433zm0-7.093c-1.532 0-2.704 1.153-2.704 2.66 0 1.507 1.172 2.66 2.704 2.66 1.532 0 2.704-1.153 2.704-2.66 0-1.507-1.172-2.66-2.704-2.66z" />
      </svg>
    </IconWrapper>
  );
}

Eye.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

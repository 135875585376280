// @flow
import React, { Fragment } from 'react';
import difference from 'ramda/src/difference';
import { helpers } from '../../shared/filters';
import RegionCheckbox from './RegionCheckbox';

type Props = {
  region: string,
  subregions: [],
  filtersStyling: Object,
  updateFilters: Function,
  selectedData: any[],
  renderIcon: Function,
  filterKey: string,
};

export default function RegionCheckboxGroup({
  filtersStyling,
  region,
  subregions,
  updateFilters,
  filterKey,
  selectedData,
  renderIcon,
}: Props) {
  const updateFiltersForRegions = values => {
    let filteredValues = values.filter(v => v.enabled).map(v => v.name);

    if (selectedData) {
      filteredValues = filteredValues.every(v => selectedData.includes(v))
        ? difference(selectedData, filteredValues)
        : [...filteredValues, ...selectedData];
    }

    updateFilters([
      {
        key: filterKey,
        value: filteredValues,
      },
    ]);
  };

  const updateFiltersForSubregions = (subRegion, enabled) => {
    if (enabled) {
      updateFilters([
        {
          key: filterKey,
          value: helpers.newArray(
            selectedData && selectedData.length > 0
              ? selectedData.filter(Boolean)
              : [],
            subRegion,
          ),
        },
      ]);
    }
  };

  const shouldRenderIcon = (checkBoxEnabled, renderLogic) =>
    checkBoxEnabled
      ? renderIcon(
          renderLogic,
          filtersStyling.checkboxSelectedIcon,
          filtersStyling.checkboxIcon,
        )
      : null;

  const enabledSubregions = subregions.filter(sr => sr.enabled);
  const enableRegion = subregions.some(s => s.enabled);
  const shouldRenderRegion =
    enableRegion &&
    selectedData &&
    difference(enabledSubregions.map(sr => sr.name), selectedData).length === 0;

  return (
    <Fragment>
      <RegionCheckbox
        key={region}
        onClick={() => updateFiltersForRegions(subregions)}
        label={region}
        filtersStyling={filtersStyling}
        renderIcon={shouldRenderIcon(enableRegion, shouldRenderRegion)}
        enabled={enableRegion}
      />
      {subregions.map(subregion => {
        const shouldRenderSubregionIcon =
          subregion.enabled &&
          selectedData &&
          selectedData.includes(subregion.name);
        return (
          <RegionCheckbox
            key={`${region}-${subregion.name}`}
            onClick={() =>
              updateFiltersForSubregions(subregion.name, subregion.enabled)
            }
            label={subregion.name}
            filtersStyling={filtersStyling}
            renderIcon={shouldRenderIcon(
              subregion.enabled,
              shouldRenderSubregionIcon,
            )}
            region
            enabled={subregion.enabled}
          />
        );
      })}
    </Fragment>
  );
}

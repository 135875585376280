// @flow
import React from 'react';
import styled from 'styled-components';

const StyledHeaderBanner = styled.div`
  width: 100%;
  height: 100px;
  margin-top: -100px;
  z-index: 10;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledBannerBlock = styled.div`
  width: calc(25% - 1px);
  height: 100%;
  border-right: 1px solid white;
  display: inline-block;

  &:last-child {
    border: none;
  }

  .section {
    display: inline-block;
    color: white;
    height: 100%;

    span {
      display: block;
      vertical-align: middle;
    }

    span:first-child {
      line-height: 50px;
    }
  }
  .right-section {
    float: right;
    width: 75%;
  }
  .left-section {
    width: 25%;

    .image-class {
      width: 100%;
      height: 100%;
      background: url(${props => props.image}) no-repeat;
      background-size: 80% 80%;
      background-position: center;
    }
  }
`;

const BannerBlock = (block, index) => (
  <StyledBannerBlock key={index} image={block.image}>
    <div className="right-section section">
      <span>{block.text}</span>
      <span>{block.subText}</span>
    </div>
    <div className="left-section section">
      <div className="image-class" />
    </div>
  </StyledBannerBlock>
);

const renderBanner = bannerInfo =>
  bannerInfo.map((section, index) => BannerBlock(section, index));

const HeaderBanner = ({ bannerInfo }: { bannerInfo: [] }) => (
  <StyledHeaderBanner>{renderBanner(bannerInfo)}</StyledHeaderBanner>
);

export default HeaderBanner;

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Slider, { Handle } from 'rc-slider';
import media from '../../../theme';
import 'rc-slider/assets/index.css';
import { Paragraph, fontStyleOverride } from '../../../components/Global';
import {
  getClosestDivisableStep,
  roundRangeValues,
} from '../../../utilities/mathHelper';

const ContentWrapper = styled.div`
  ${media.max.medium`
     padding: 8px 30px 16px 30px;
  `};
  padding: 8px 16px 16px 16px;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SliderWrapper = styled.div`
  padding: 0 8px;
`;

const HandleContainer = styled(Handle)`
  margin-top: -8px;
  position: absolute;
  touch-action: pan-x;
  cursor: grab;
  outline: none;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  ${({ src }) => `background-image: url(${src})`};
  background-size: cover;
`;

type Props = {
  filterKey: string,
  filterLabel: string,
  step?: number,
  minValue?: number,
  maxValue: number,
  selectedValue: number,
  globalStyling: Object,
  updateFilters: Object => void,
  stylingConfig: Object,
  filtersStyling: Object,
  formatter?: any => string,
};

export default class OneHandledRangeFilter extends Component<Props, *> {
  static defaultProps = {
    step: 1,
    minValue: 0,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      currentValue: props.selectedValue,
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    const { selectedValue } = this.props;
    if (prevProps.selectedValue !== selectedValue) {
      this.updateSliderValue(selectedValue);
    }
  };

  updateSliderValue = (value: number) => {
    this.setState(() => ({ currentValue: this.roundValues(value) }));
  };

  updateFilter = (value: number) => {
    const { filterKey } = this.props;
    this.props.updateFilters([
      { key: filterKey, value: this.roundValues(value) },
    ]);
  };

  roundValues = value => {
    const { step, minValue, maxValue } = this.props;
    const minRangeValue = Number(minValue) || 0;
    const maxRangeValue = Number(maxValue);

    return roundRangeValues(
      minRangeValue,
      maxRangeValue,
      value,
      value,
      step,
    )[0];
  };

  render = () => {
    const {
      filterLabel,
      step,
      minValue,
      maxValue,
      globalStyling: {
        direction,
        colours: { primaryBrandColour },
      },
      stylingConfig,
      filtersStyling,
      formatter,
    } = this.props;
    const { currentValue } = this.state;

    const CustomHandle = props => (
      <HandleContainer
        key={props.index}
        value={props.value}
        offset={props.offset}
        reverse={direction === 'rtl'}
      >
        <IconWrapper src={stylingConfig.filterSliderIcon} alt="" />
      </HandleContainer>
    );

    const closestDivisibleStep = getClosestDivisableStep(
      Number(minValue || 0),
      Number(maxValue),
      step,
    );

    return (
      <ContentWrapper>
        <Labels>
          <Paragraph
            styleOverride={() => `
            ${fontStyleOverride(filtersStyling.filterMenuFont)}
          `}
          >
            {filterLabel}
          </Paragraph>
          <Paragraph
            styleOverride={() => `
            ${fontStyleOverride(filtersStyling.filterMenuFont)}
          `}
          >
            {formatter(currentValue)}
          </Paragraph>
        </Labels>
        <SliderWrapper>
          <Slider
            trackStyle={{ backgroundColor: primaryBrandColour.value }}
            handle={CustomHandle}
            step={closestDivisibleStep}
            value={currentValue}
            min={minValue || 0}
            max={maxValue}
            onChange={this.updateSliderValue}
            onAfterChange={this.updateFilter}
            reverse={direction === 'rtl'}
          />
        </SliderWrapper>
      </ContentWrapper>
    );
  };
}

OneHandledRangeFilter.defaultProps = {
  formatter: value => value,
};

// @flow
import React from 'react';
import styled from 'styled-components';

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0;
  padding-bottom: 20px;
`;

export default function Fieldset(props: Object) {
  return <StyledFieldset {...props} />;
}

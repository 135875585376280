// @flow
import React from 'react';
import styled from 'styled-components';
import { Button } from 'cms-ui';

import ProductList from './ProductList';
import DefaultProduct from './DefaultProduct';
import AccordionSettings from './AccordionSettings';

import type { Theme } from '../../theme';

export type Props = {
  finance: Object,
  updateFinanceOptions: (finance: Object) => void,
  saveFinanceOptions: () => void,
  siteId: string,
};

type ThemeProps = { theme: Theme };

const Container = styled.div.withConfig({ displayName: 'Container' })`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }: ThemeProps) => `${theme.layout.doublePadding}`};
}

  > * {
    margin-bottom: ${({ theme }: ThemeProps) =>
      `${theme.layout.standardPadding}`};
  }
`;

const Header = styled.h2`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeHeader}`};
`;

const ButtonContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
`;

export default function FinanceOptions(props: Props) {
  return (
    <Container>
      <Header>Finance Options</Header>
      <ProductList {...props} />
      <DefaultProduct {...props} />
      <AccordionSettings {...props} />
      <ButtonContainer>
        <Button onClick={() => props.saveFinanceOptions(props.siteId)} primary>
          Save
        </Button>
      </ButtonContainer>
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v1';
import media from '../theme';
import ModelGridTile from './ModelGridTile';

const FilterTileGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ModelGridTileWrapper = styled.div`
  width: 100%;
  ${media.min.small`
    width: 50%;
  `};
  ${media.min.medium`
    width: ${({ numberOfColumns }) =>
      `${100 / Math.max(numberOfColumns - 1, 2)}%`};
  `};
  ${media.min.large`
    width: ${({ numberOfColumns }) => `${100 / numberOfColumns}%`};
  `};
`;

export type ModelMatrixGridProps = {
  carModels: CarModel[],
  onItemClick: (id: string) => void,
  selectedModels: [],
  translations: Object,
  selectedIconColour: string,
  locale: string,
  titleFont: Font,
  featureFlags: Object,
  numberOfColumns: number,
  showStockNumber: boolean,
  showPrice: boolean,
  showSubtitle: boolean,
  backgroundColour: Option,
  borderColour: Option,
  modelCardStyling: Object,
  globalStyling: Object,
  titleBottomMargin: string,
  showFinancePopup: boolean,
};

export default function ModelMatrixGrid({
  carModels = [],
  onItemClick,
  selectedModels = [],
  translations,
  selectedIconColour,
  locale,
  titleFont,
  featureFlags,
  numberOfColumns,
  showStockNumber,
  showPrice,
  showSubtitle,
  backgroundColour,
  borderColour,
  modelCardStyling,
  globalStyling,
  titleBottomMargin,
  showFinancePopup,
}: ModelMatrixGridProps) {
  return carModels ? (
    <FilterTileGrid>
      {carModels
        .sort((a, b) => a.position - b.position)
        .map((carModel: CarModel) => (
          <ModelGridTileWrapper numberOfColumns={numberOfColumns} key={uuid()}>
            <ModelGridTile
              align="left"
              onIconClick={() => onItemClick(carModel.model)}
              carModel={carModel}
              isSelected={
                selectedModels && selectedModels.includes(carModel.model)
              }
              translations={translations}
              selectedIconColour={selectedIconColour}
              locale={locale}
              titleFont={titleFont}
              featureFlags={featureFlags}
              width={`${100 / numberOfColumns}%`}
              backgroundColour={backgroundColour}
              borderColour={borderColour}
              showStockNumber={showStockNumber}
              showPrice={showPrice}
              showSubtitle={showSubtitle}
              modelCardStyling={modelCardStyling}
              globalStyling={globalStyling}
              titleBottomMargin={titleBottomMargin}
              showFinancePopup={showFinancePopup}
            />
          </ModelGridTileWrapper>
        ))}
    </FilterTileGrid>
  ) : null;
}

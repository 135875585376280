// @flow
import React from 'react';
import styled from 'styled-components';
import { Button, ButtonWithConfirm } from 'cms-ui';
import type { Theme } from '../theme';
import type { User } from '../state/types/users';

type Props = {
  users: User[],
  removeUser: User => void,
};

type ThemeProps = { theme: Theme };

const Container = styled.div.withConfig({ displayName: 'UsersList' })`
  font-family: ${({ theme }: ThemeProps) => `${theme.fontFamily}`};
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }: ThemeProps) => `${theme.colours.secondary02}`};
  background-color: ${({ theme }: ThemeProps) => `${theme.colours.primary03}`};
  padding: ${({ theme }: ThemeProps) => `${theme.layout.standardPadding}`};
  min-width: 800px;
`;

const RowContainer = styled.div.withConfig({ displayName: 'RowContainer' })`
  display: flex;
`;

const ColumnContainer = styled.div.withConfig({
  displayName: 'ColumnContainer',
})`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px ${({ theme }: ThemeProps) => `${theme.layout.standardPadding}`};
`;

const BoldColumnContainer = styled(ColumnContainer).withConfig({
  displayName: 'BoldColumnContainer',
})`
  font-weight: 600;
  width: 300px;
`;

const RemoveColumnContainer = styled(ColumnContainer).withConfig({
  displayName: 'RemoveColumnContainer',
})`
  width: 300px;
  padding: 0 ${({ theme }: ThemeProps) => `${theme.layout.standardPadding}`};
`;

const StyledButton = styled(Button).withConfig({ displayName: 'StyledButton' })`
  margin-top: 10px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FailureMessage = styled.span`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeFormControls}`};
  color: ${({ theme }: ThemeProps) => `${theme.colours.danger01}`};
  padding: 11px 5px;
`;

const renderModalContents = (onClick, closeSelfFn) => (
  <Center>
    <p>Are you sure you want to remove this user?</p>
    <StyledButton
      small
      fullWidth
      danger
      textTransform="uppercase"
      onClick={() => {
        onClick();
        closeSelfFn();
      }}
    >
      Confirm Remove
    </StyledButton>
    <StyledButton
      small
      fullWidth
      textTransform="uppercase"
      onClick={closeSelfFn}
    >
      Cancel
    </StyledButton>
  </Center>
);

const renderUser = (user, removeUser) => {
  const removeThisUser = () => removeUser(user);

  return (
    <RowContainer key={user.id}>
      <BoldColumnContainer>{user.email}</BoldColumnContainer>
      <ColumnContainer>{user.role.name}</ColumnContainer>
      <RemoveColumnContainer>
        <RowContainer>
          {!user.currentUser && (
            <ButtonWithConfirm
              buttonText="remove"
              renderModalContents={closeSelfFn =>
                renderModalContents(removeThisUser, closeSelfFn)
              }
              buttonProps={{
                link: true,
                primary: true,
                fullWidth: false,
                danger: false,
                textTransform: 'lowercase',
              }}
            />
          )}
          {user.error && <FailureMessage>{user.error}</FailureMessage>}
        </RowContainer>
      </RemoveColumnContainer>
    </RowContainer>
  );
};

export default function UsersList({ users = [], removeUser }: Props) {
  return (
    <Container>
      {users.length > 0
        ? users.map(u => renderUser(u, removeUser))
        : 'No users'}
    </Container>
  );
}

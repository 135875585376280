// @flow
import React from 'react';
import styled from 'styled-components';
import { HeadingTwo, fontStyleOverride } from '../Global';

type Props = {
  globalStyling: Object,
  links: Object,
  navigate: () => void,
};

const HeaderSubNav = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  width: 100%;
  margin-left: 150px;
`;

export default function({
  globalStyling: {
    colours: { primaryBrandColour },
  },
  links,
  navigate,
  config,
}: Props) {
  return (
    <HeaderSubNav>
      {links.map(link => (
        <HeadingTwo
          key={link.itemId}
          styleOverride={() => `
            ${fontStyleOverride(config.subLinkFont)};
            box-sizing: border-box;
            cursor: pointer;
            line-height: 55px;
            position: relative;
            width: 25%;

            &:before {
              position: absolute;
              bottom: 0;
              left: 0;
              content: '';
              width: 100%;
              height: 1px;
              border-bottom: 1px solid #323232;
            }
            &:after {
              position: absolute;
              bottom: 0;
              left: 0;
              content: '';
              width: 0%;
              height: 1px;
              border-bottom: 1px solid ${primaryBrandColour.value};
              transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }

            &:hover:after {
              width: 100%;
            }
          `}
          onClick={() => navigate(link.link)}
        >
          {link.text}
        </HeadingTwo>
      ))}
    </HeaderSubNav>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import { Wrapper, TextWrapper, Icon } from '../components';
import { HeadingOne, Paragraph, fontStyleOverride } from '../components/Global';
import type { Option, Font, Border, Padding } from '../../types';

const Brick = styled.div`
  display: flex;
  flex-direction: ${({ alignment }) => alignment};
  align-items: center;
`;

const BrickText = styled.div`
  ${({ alignment }) => `padding-${alignment}: 16px;`};
`;

interface IBrickConfig {
  translations: {
    headlineText: string,
    bodyText: string,
    imageLabel: string,
  };
  image: Option;
  headlineFont: Font;
  bodyFont: Font;
  alignment: string;
  padding: Padding;
  border: Border;
  borderColour: Option;
  backgroundColour: Option;
}

type Props = {
  config: IBrickConfig,
};

const align = {
  left: 'row',
  right: 'row-reverse',
};

export default ({
  config: {
    translations,
    image,
    headlineFont,
    bodyFont,
    alignment,
    padding,
    border,
    borderColour,
    backgroundColour,
    imagePosition,
  },
}: Props) => (
  <Wrapper
    border={border}
    backgroundColour={backgroundColour}
    borderColour={borderColour}
  >
    <TextWrapper padding={padding}>
      <Brick alignment={align[alignment]}>
        {image && (
          <Icon
            src={image.src}
            imagePosition={imagePosition}
            imageHeight="40"
            role="img"
            aria-label={translations.imageLabel}
          />
        )}
        <BrickText alignment={alignment}>
          <HeadingOne styleOverride={() => fontStyleOverride(headlineFont)}>
            {translations.headlineText}
          </HeadingOne>
          <Paragraph styleOverride={() => fontStyleOverride(bodyFont)}>
            {translations.bodyText}
          </Paragraph>
        </BrickText>
      </Brick>
    </TextWrapper>
  </Wrapper>
);

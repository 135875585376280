// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import { SelectField } from './FormFields';
import { Button } from './Global';

type Option = {
  value: number,
  label: string,
};

type RenderProp = () => Node;

type Props = {
  formOptions: {
    placeholder?: ?string,
    initialSelectedValue?: Option,
    key: string,
    label: string,
    options: Option[],
  }[],
  onSubmit: (keys: any) => void,
  title: string,
  renderClose?: RenderProp,
  onClose?: () => void,
  initialValues: Object,
  globalStyling: Object,
};

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

const Title = styled.h3.withConfig({
  displayName: 'Title',
})`
  text-align: center;
  text-transform: uppercase;
`;

const CloseButtonWrapper = styled.div.withConfig({
  displayName: 'CloseButtonWrapper',
})`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`;

const buildKeysWithInitialValues = (options, initialValues) =>
  options.reduce(
    (ac, { key }) => ({
      ...ac,
      [key]: initialValues[key.toLowerCase()],
    }),
    {},
  );

export default class FormLocation extends React.Component<Props, *> {
  static defaultProps = {
    onClose: () => {},
  };

  constructor(props: Props) {
    super(props);
    const { formOptions, initialValues } = this.props;
    this.state = {
      keys: buildKeysWithInitialValues(formOptions, initialValues),
    };
  }

  render() {
    const {
      formOptions,
      onSubmit,
      title,
      confirm,
      onClose,
      renderClose,
      initialValues,
      globalStyling,
    } = this.props;
    return (
      <Container>
        {renderClose && (
          <CloseButtonWrapper onClick={onClose}>
            {renderClose()}
          </CloseButtonWrapper>
        )}
        <Title>{title}</Title>
        {formOptions.map(
          ({
            placeholder,
            key,
            label: selectLabel,
            options,
            includePlaceholderOption,
          }) => (
            <SelectField
              key={key}
              doubleRow={false}
              type="text"
              value={this.state.keys[key]}
              onChange={data => {
                const { value } = data;
                this.setState(prevState => ({
                  keys: { ...prevState.keys, [key]: value },
                }));
              }}
              placeholder={!includePlaceholderOption && placeholder}
              label={selectLabel}
              options={options}
              initialValue={initialValues[key.toLowerCase()]}
              globalStyling={globalStyling}
            />
          ),
        )}
        <Button
          buttonStyle="ButtonStyle1"
          onClick={() => onSubmit(this.state.keys)}
          text={confirm}
        />
      </Container>
    );
  }
}

import { Config, Slice, Section, Colour, Toggle } from '../../helpers/config';

const config = Config(
  'BackToTop',
  { id: 'BackToTop' },
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      {},
      Toggle('Active', 'active'),
      Colour('Background colour', 'buttonBackgroundColour'),
      Colour('Icon colour', 'buttonIconColour'),
    ),
  ),
);

export default config;

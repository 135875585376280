import React from 'react';

const GlobalSettingsIcon = props => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <rect fill="#0065DB" x={2} width={1} height={21} rx={0.5} />
        <circle stroke="#0065DB" fill="#FFF" cx={2.5} cy={6.5} r={2.5} />
      </g>
      <g transform="translate(9 2)">
        <rect fill="#0065DB" x={2} width={1} height={21} rx={0.5} />
        <circle stroke="#0065DB" fill="#FFF" cx={2.5} cy={15.5} r={2.5} />
      </g>
      <g transform="translate(16 1)">
        <rect fill="#0065DB" x={2} y={1} width={1} height={21} rx={0.5} />
        <circle stroke="#0065DB" fill="#FFF" cx={2.5} cy={2.5} r={2.5} />
      </g>
    </g>
  </svg>
);

export default GlobalSettingsIcon;

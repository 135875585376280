// @flow
import React from 'react';
import styled from 'styled-components';

import type { Option, Font } from '../../types';
import {
  HeadingTwo,
  Button,
  Paragraph,
  fontStyleOverride,
  buttonStyleOverride,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';
import { actions as routerActions } from '../../actions/router';
import media from '../../theme';

export interface Config {
  buttonText: { url: string, label: string };
  headlineFont: Font;
  bodyFont: Font;
  smallBodyFont: Font;
  alignment: string;
  backgroundColour: Option;
  buttonColour: Option;
  buttonBackgroundColour: Option;
  buttonHover: Option;
  buttonFont: Font;
  buttonSize: Object;
  translations: {
    headlineText: string,
    bodyText: string,
    smallBodyText: string,
    buttonText: string,
  };
  headerBorders: {
    active: boolean,
    borderThickness: Number,
    borderStyle: string,
  };
  applyWhiteSpace: boolean;
  buttonHoverColour: Option;
  containerHorizontalPadding: number;
  containerVerticalPadding: number;
}

type Props = {
  config: Config,
  preview: boolean,
  globalStyling: Object,
  dispatch: Function,
};

const Container = styled.div.withConfig({
  displayName: 'TextCTAContainer',
})`
  text-align: ${({ align }) => `${align || 'unset'}`};
  padding: ${({ verticalPadding }) => `${verticalPadding || 32}px`}
    ${({ horizontalPadding }) => `${horizontalPadding || 0}px`};

  ${media.max.medium`
    padding: 32px 20px;
  `};
  ${({ border }) =>
    `
    border-bottom: 1px solid ${border};
  `};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  margin: 10px;
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth}px;
    margin: 10px auto;
  `};
`;

const InjectBorders = styled.div`
  border-top: ${({ thickness, borderStyle }) =>
    `${thickness || '0'}px ${borderStyle || 'solid'}`};
  border-bottom: ${({ thickness, borderStyle }) =>
    `${thickness || '0'}px ${borderStyle || 'solid'}`};
  display: inline-block;
`;

export default function TextCTA({ config, globalStyling, dispatch }: Props) {
  return (
    <ContentWrapper
      colour={config.backgroundColour && config.backgroundColour}
      contentWidth={globalStyling.contentWidth}
    >
      <Container
        align={config.alignment}
        horizontalPadding={config.containerHorizontalPadding}
        verticalPadding={config.containerVerticalPadding}
        border={config.bottomBorder && config.borderColour.value}
      >
        {config.translations.headlineText && (
          <TextContainer maxWidth={config.contentMaxWidth}>
            <HeadingTwo
              styleOverride={() => `
                ${fontStyleOverride(config.headlineFont)}
                max-width: 800px;
                margin: auto;
                ${config.headlineFont &&
                  config.headlineFont.marginBottom &&
                  `margin-bottom: ${config.headlineFont.marginBottom}px`};
              `}
            >
              <InjectBorders
                thickness={
                  config.headerBorders && config.headerBorders.active
                    ? config.headerBorders.borderThickness
                    : null
                }
                borderStyle={
                  config.headerBorders && config.headerBorders.active
                    ? config.headerBorders.borderStyle
                    : null
                }
              >
                {config.translations.headlineText}
              </InjectBorders>
            </HeadingTwo>
          </TextContainer>
        )}
        {config.translations.bodyText && (
          <TextContainer maxWidth={config.contentMaxWidth}>
            <Paragraph
              styleOverride={() =>
                `${fontStyleOverride(config.bodyFont)};
                 ${config.applyWhiteSpace && 'white-space: pre-wrap;'};
                `
              }
            >
              {config.translations.bodyText}
            </Paragraph>
          </TextContainer>
        )}
        {config.translations.smallBodyText && (
          <TextContainer maxWidth={config.contentMaxWidth}>
            <Paragraph
              styleOverride={() =>
                `${fontStyleOverride(
                  config.smallBodyFont,
                )} ${config.applyWhiteSpace && 'white-space: pre-wrap;'}`
              }
            >
              {config.translations.smallBodyText}
            </Paragraph>
          </TextContainer>
        )}
        {config.buttonText.on && (
          <ButtonContainer>
            <Button
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              applyStyle="primary"
              styleOverride={() =>
                buttonStyleOverride(
                  config.buttonFont,
                  config.buttonBackgroundColour,
                  config.buttonHover,
                  config.buttonSize,
                  config.buttonHoverColour,
                )
              }
              text={config.translations.buttonText}
              onClick={() =>
                dispatch(routerActions.navigate(config.buttonText.url))
              }
            />
          </ButtonContainer>
        )}
      </Container>
    </ContentWrapper>
  );
}

// @flow
/* eslint-disable import/prefer-default-export */
export const positionLayout = (position: Object) =>
  position
    ? [
        position.top && `top: ${position.top}px;`,
        position.right && `right: ${position.right}px;`,
        position.bottom && `bottom: ${position.bottom}px;`,
        position.left && `left: ${position.left}px;`,
      ]
        .filter(Boolean)
        .join('')
    : '';

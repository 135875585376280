// @flow
import React from 'react';
import styled from 'styled-components';
import IoCheckmark from 'react-icons/lib/io/checkmark';
import { format } from 'date-fns';
import { Button, Loader } from 'cms-ui';
import { FileUpload } from '.';

import { type Theme } from '../theme';

export type Props = {
  name: string,
  onFileUploadChange: Function,
  language: string,
  lastUpdated?: ?Date,
  filename?: ?string,
  file?: ?File,
  onImportClick: Function,
  onExportClick: Function,
  uploading: boolean,
  showSuccessMessage: boolean,
  showFailureMessage: boolean,
};

type ThemeProps = Props & { theme: Theme };

const Container = styled.div.withConfig({ displayName: 'TranslationUploader' })`
  font-family: ${(props: ThemeProps) => `${props.theme.fontFamily}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid
    ${(props: ThemeProps) => `${props.theme.colours.secondary02}`};
  background-color: ${(props: ThemeProps) =>
    `${props.theme.colours.primary03}`};
  padding: ${(props: ThemeProps) => `${props.theme.layout.standardPadding}`};
`;

const LabelContainer = styled.div.withConfig({ displayName: 'LabelContainer' })`
  display: flex;
  flex-direction: column;
  width: 120px;
  min-width: 120px;
`;

const Label = styled.h2.withConfig({ displayName: 'Label' })`
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeHeader}`};
  color: ${(props: ThemeProps) => `${props.theme.colours.secondary03}`};
  margin: 0;
`;

const SubLabel = styled.span`
  color: ${(props: ThemeProps) => `${props.theme.colours.highlight01}`};
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeFormControls}`};
`;

const FileUploadContainer = styled.div.withConfig({
  displayName: 'FileUploadContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  background-color: ${(props: ThemeProps) =>
    `${props.theme.colours.secondary04}`};
`;

const UploadingContainer = styled.div.withConfig({
  displayName: 'UploadingContainer',
})`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 400px;

  > span {
    margin-right: 10px;
  }
`;

const FileName = styled.span.withConfig({ displayName: 'FileName' })`
  color: ${({ haveFile, theme }: { haveFile: boolean, theme: Theme }) =>
    haveFile ? `${theme.colours.secondary03}` : `${theme.colours.highlight01}`};
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeFormLabel}`};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;

const Centre = styled.div.withConfig({ displayName: 'Centre' })`
  display: flex;
  flex-direction: column;
`;

const SuccessMessage = styled.span`
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeFormControls}`};
  color: ${(props: ThemeProps) => `${props.theme.colours.success}`};
  text-align: right;
  margin-top: 4px;
`;

const FailureMessage = styled.span`
  font-size: ${(props: ThemeProps) => `${props.theme.fontSizeFormControls}`};
  color: ${(props: ThemeProps) => `${props.theme.colours.danger01}`};
  text-align: right;
  margin-top: 4px;
`;

const defaultFilenameText = 'No file chosen';

export default function TranslationUploader({
  name,
  onFileUploadChange,
  language,
  lastUpdated,
  filename,
  file,
  onImportClick,
  onExportClick,
  uploading,
  showSuccessMessage,
  showFailureMessage,
}: Props) {
  return (
    <Container>
      <LabelContainer>
        <Label>{language}</Label>
        <SubLabel>Last updated</SubLabel>
        <SubLabel>
          {lastUpdated ? format(lastUpdated, 'D/M/YY [-] HH:mm') : 'never'}
        </SubLabel>
      </LabelContainer>
      {uploading ? (
        <UploadingContainer>
          <Loader visible />
          <SubLabel>Import in progress</SubLabel>
        </UploadingContainer>
      ) : (
        <Centre>
          <FileUploadContainer>
            <FileUpload
              input={{ name, onChange: onFileUploadChange }}
              label="choose file"
              accept=".csv"
            />
            <FileName haveFile={filename !== defaultFilenameText}>
              {filename}
            </FileName>
            <Button
              disabled={!file}
              onClick={() => onImportClick(file)}
              primary
              textTransform="uppercase"
            >
              Import
            </Button>
          </FileUploadContainer>
          {showSuccessMessage && (
            <SuccessMessage>
              Import completed <IoCheckmark />
            </SuccessMessage>
          )}
          {showFailureMessage && (
            <FailureMessage>Import failed. Please try again.</FailureMessage>
          )}
        </Centre>
      )}
      <Button onClick={onExportClick} primary textTransform="uppercase">
        Export
      </Button>
    </Container>
  );
}

TranslationUploader.defaultProps = {
  lastUpdated: undefined,
  filename: defaultFilenameText,
  uploading: false,
  file: null,
  showSuccessMessage: false,
  showFailureMessage: false,
};

// @flow
import React from 'react';

import styled from 'styled-components';
import Text from '../../components/Text';
import LinksList from '../../components/LinksList';
import type { FooterTemplateConfig } from '../types';

const alignmentToFlex = alignment =>
  (alignment &&
    {
      left: 'flex-start',
      right: 'flex-end',
    }[alignment]) ||
  'center';

const FooterWrapper = styled.div`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  width: 100%;
  margin: 0;
  position: relative;
  ${({ backgroundColour }) =>
    backgroundColour ? `background-color: ${backgroundColour.value}` : ''};
  * {
    box-sizing: border-box;
  }
`;

const NavSectionWrapper = styled.nav`
  display: flex;
  width: 100%;
  justify-content: ${({ horizontalAlignment }) =>
    alignmentToFlex(horizontalAlignment)};
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100px;
  height: 100%;
  align-items: center;
  justify-content: ${({ horizontalAlignment }) =>
    alignmentToFlex(horizontalAlignment)};
`;

const Logo = styled.img`
  max-width: 150px;
  max-height: 50px;
`;

type Props = {
  config: FooterTemplateConfig,
};

export default ({
  config: {
    nav = { links: [] },
    social = { links: [] },
    logo = {},
    footerFont = {},
    footnoteFont = {},
    translations = {
      imageLabel: string,
    },
  },
}: Props) => {
  const socialLinks = social.links
    .filter(link => link && typeof link.url === 'string')
    .map(
      link =>
        link.url.length && {
          ...link,
          label: translations[link.key],
        },
    );
  return (
    <FooterWrapper>
      <NavSectionWrapper horizontalAlignment={nav.horizontalAlignment}>
        <LinksList links={nav.links} font={footerFont} />
      </NavSectionWrapper>
      <NavSectionWrapper>
        <LinksList links={socialLinks} font={footerFont} />
      </NavSectionWrapper>
      <NavSectionWrapper>
        <LogoWrapper horizontalAlignment={logo.horizontalAlignment}>
          <Logo alt={translations.imageLabel} src={logo.src} />
        </LogoWrapper>
      </NavSectionWrapper>
      {translations.footnoteText && (
        <NavSectionWrapper>
          <Text
            config={{
              bodyText: translations.footnoteText,
              bodyFont: footnoteFont,
              alignment: 'center',
            }}
          />
        </NavSectionWrapper>
      )}
    </FooterWrapper>
  );
};

import {
  generateMaxDistanceUrl,
  generateLocationUrl,
  generateRangeUrl,
  generateCheckboxFilterUrl,
} from './helper';

export const constants = {
  TOGGLE_CHECKBOX_FILTER: 'SearchFilters:ToggleCheckbox',
  SELECT_MULTIPLE_CHECKBOX_FILTERS: 'SearchFilters:SelectMultipleCheckboxes',
  CLEAR_CHECKBOX_SECTION: 'SearchFilters:ClearCheckboxSection',
  CHANGE_RANGE_FILTER: 'SearchFilters:ChangeRangeFilter',
  CHANGE_LOCATION: 'SearchFilters:ChangeLocation',
  CHANGE_MAX_DISTANCE: 'SearchFilters:MaxDistance',
  RESET_FILTERS: 'SearchFilters:ResetFilters',
  GET_SEARCH_FILTERS: 'SearchFilters:GetAvailableFilters',
  GET_SEARCH_FILTERS_SUCCESS: 'SearchFilters:GetAvailableFiltersSuccess',
  GET_ALL_SEARCH_FILTERS: 'SearchFilters:GetAllFilters',
  GET_ALL_SEARCH_FILTERS_SUCCESS: 'SearchFilters:GetAllFiltersSuccess',
  FILTER_BY_MODEL_RANGE: 'SearchFilters:FilterByModelRange',
  REMOVE_RANGE_FILTER: 'SearchFilters:RemoveRangeFilter',
  UPDATE_QUERY: 'SearchFilters:UpdateQuery',
};

export const actions = {
  toggleCheckboxFilter: payload => ({
    type: constants.TOGGLE_CHECKBOX_FILTER,
    payload,
  }),
  selectMultipleCheckboxes: payload => ({
    type: constants.SELECT_MULTIPLE_CHECKBOX_FILTERS,
    payload,
  }),
  clearCheckboxSection: payload => ({
    type: constants.CLEAR_CHECKBOX_SECTION,
    payload,
  }),
  changeRangeFilter: payload => ({
    type: constants.CHANGE_RANGE_FILTER,
    payload,
  }),
  changeLocation: payload => ({ type: constants.CHANGE_LOCATION, payload }),
  changeMaxDistance: payload => ({
    type: constants.CHANGE_MAX_DISTANCE,
    payload,
  }),
  resetFilters: payload => ({ type: constants.RESET_FILTERS, payload }),
  resetRetailer: payload => ({ type: constants.RESET_RETAILER, payload }),
  getSearchFilters: payload => ({
    type: constants.GET_SEARCH_FILTERS,
    payload,
  }),
  getSearchFiltersSuccess: payload => ({
    type: constants.GET_SEARCH_FILTERS_SUCCESS,
    payload,
  }),
  getAllSearchFilters: payload => ({
    type: constants.GET_ALL_SEARCH_FILTERS,
    payload,
  }),
  getAllSearchFiltersSuccess: payload => ({
    type: constants.GET_ALL_SEARCH_FILTERS_SUCCESS,
    payload,
  }),
  filterByModelRange: payload => ({
    type: constants.FILTER_BY_MODEL_RANGE,
    payload,
  }),
  removeRangeFilter: payload => ({
    type: constants.REMOVE_RANGE_FILTER,
    payload,
  }),
  updateQuery: () => ({
    type: constants.UPDATE_QUERY,
  }),
};

export const initialState = {
  selectedCheckboxFilters: {
    models: [],
    variants: [],
    engine: [],
    engineSizes: [],
    transmissions: [],
    bodyStyles: [],
    exteriorColours: [],
    interiorShades: [],
    features: [],
  },
  selectedRangeFilters: {},
  location: {},
  searchFilters: {
    loaded: false,
    loadingAllFilters: false,
    loadingAvailableFilters: false,
    allFilters: {},
    availableFilters: {},
  },
  searchUrl: '',
  modelSearchUrl: '',
  filterSearchUrl: '',
  retailer: '',
  resetRangeCount: 0,
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  const { selectedCheckboxFilters } = state;
  switch (type) {
    case constants.FILTER_BY_RETAILER:
      return {
        ...initialState,
        retailer: payload,
      };
    case constants.SELECT_MULTIPLE_CHECKBOX_FILTERS: {
      const { section, values } = payload;
      const selected = [...values];
      const updatedFilters = {
        ...selectedCheckboxFilters,
        [section]: selected,
      };
      return {
        ...state,
        selectedCheckboxFilters: updatedFilters,
        searchUrl: {
          ...state.searchUrl,
          checkboxUrl: generateCheckboxFilterUrl(updatedFilters),
        },
        modelSearchUrl: {
          ...state.modelSearchUrl,
          checkboxUrl: generateCheckboxFilterUrl({
            ...updatedFilters,
            models: [],
          }),
        },
        filterSearchUrl: {
          ...state.filterSearchUrl,
          checkboxUrl: generateCheckboxFilterUrl({
            models: updatedFilters.models,
          }),
        },
      };
    }
    case constants.FILTER_BY_MODEL_RANGE:
    case constants.TOGGLE_CHECKBOX_FILTER: {
      const { section, filter } = payload;
      const filterGroup = selectedCheckboxFilters[section] || [];
      const isSelected = filterGroup.includes(filter);
      const selected = isSelected
        ? filterGroup.filter(f => f !== filter)
        : [...filterGroup, filter];
      const updatedFilters = {
        ...selectedCheckboxFilters,
        [section]: selected,
      };
      return {
        ...state,
        selectedCheckboxFilters: updatedFilters,
        searchUrl: {
          ...state.searchUrl,
          checkboxUrl: generateCheckboxFilterUrl(updatedFilters),
        },
        modelSearchUrl: {
          ...state.modelSearchUrl,
          checkboxUrl: generateCheckboxFilterUrl({
            ...updatedFilters,
            models: [],
          }),
        },
        filterSearchUrl: {
          ...state.filterSearchUrl,
          checkboxUrl: generateCheckboxFilterUrl({
            models: updatedFilters.models,
          }),
        },
      };
    }
    case constants.CLEAR_CHECKBOX_SECTION: {
      const { section } = payload;
      const updatedFilters = {
        ...state.selectedCheckboxFilters,
        [section]: [],
      };
      const checkboxUrl = generateCheckboxFilterUrl(updatedFilters);
      const filterCheckBoxUrl = generateCheckboxFilterUrl({
        models: updatedFilters.models,
      });
      return {
        ...state,
        selectedCheckboxFilters: updatedFilters,
        searchUrl: { ...state.searchUrl, checkboxUrl },
        modelSearchUrl: { ...state.modelSearchUrl, checkboxUrl },
        filterSearchUrl: {
          ...state.filterSearchUrl,
          checkboxUrl: filterCheckBoxUrl,
        },
      };
    }
    case constants.CHANGE_RANGE_FILTER: {
      const { id, values } = payload;
      const selectedRanges = {
        ...state.selectedRangeFilters,
        [id]: { min: values[0], max: values[1], active: true },
      };
      return {
        ...state,
        selectedRangeFilters: selectedRanges,
        searchUrl: {
          ...state.searchUrl,
          rangeUrl: generateRangeUrl(selectedRanges),
        },
        modelSearchUrl: {
          ...state.modelSearchUrl,
          rangeUrl: generateRangeUrl(selectedRanges),
        },
        filterSearchUrl: {
          ...state.filterSearchUrl,
          rangeUrl: generateRangeUrl(selectedRanges),
        },
      };
    }
    case constants.REMOVE_RANGE_FILTER: {
      const { id } = payload;
      const selectedRanges = {
        ...state.selectedRangeFilters,
        [id]: { ...state.selectedRangeFilters[id], active: false },
      };
      return {
        ...state,
        ...(state.selectedRangeFilters[id]
          ? {
              selectedRangeFilters: selectedRanges,
              searchUrl: {
                ...state.searchUrl,
                rangeUrl: generateRangeUrl(selectedRanges),
              },
              modelSearchUrl: {
                ...state.modelSearchUrl,
                rangeUrl: generateRangeUrl(selectedRanges),
              },
              filterSearchUrl: {
                ...state.filterSearchUrl,
                rangeUrl: generateRangeUrl(selectedRanges),
              },
            }
          : {}),
      };
    }
    case constants.CHANGE_LOCATION: {
      const { location } = payload;
      return {
        ...state,
        location,
        searchUrl: {
          ...state.searchUrl,
          locationUrl: generateLocationUrl(location),
        },
        modelSearchUrl: {
          ...state.modelSearchUrl,
          locationUrl: generateLocationUrl(location),
        },
        filterSearchUrl: {
          ...state.filterSearchUrl,
          rangeUrl: generateLocationUrl(location),
        },
      };
    }
    case constants.CHANGE_MAX_DISTANCE: {
      const { maxDistance } = payload;
      return {
        ...state,
        maxDistance,
        searchUrl: {
          ...state.searchUrl,
          maxDistanceUrl: generateMaxDistanceUrl(maxDistance),
        },
        modelSearchUrl: {
          ...state.modelSearchUrl,
          maxDistanceUrl: generateMaxDistanceUrl(maxDistance),
        },
        filterSearchUrl: {
          ...state.filterSearchUrl,
          maxDistanceUrl: generateMaxDistanceUrl(maxDistance),
        },
      };
    }
    case constants.RESET_FILTERS: {
      const { allFilters } = state.searchFilters;
      return {
        ...initialState,
        searchFilters: {
          ...state.searchFilters,
          availableFilters: allFilters,
          resetRangeCount: Math.random(),
        },
      };
    }
    case constants.GET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          loadingAvailableFilters: true,
        },
      };
    }
    case constants.GET_SEARCH_FILTERS_SUCCESS: {
      const { filters } = action.payload;
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          availableFilters: filters,
          loadingAvailableFilters: false,
        },
      };
    }
    case constants.GET_ALL_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: { ...state.searchFilters, loadingAllFilters: true },
      };
    }
    case constants.GET_ALL_SEARCH_FILTERS_SUCCESS: {
      const { allFilters } = action.payload;
      return {
        ...state,
        searchFilters: {
          allFilters,
          availableFilters: allFilters,
          loadingAllFilters: false,
          loaded: true,
        },
      };
    }
    default:
      return state;
  }
}

// @flow
import React, { Component } from 'react';

import EnquiryForm from '../../components/EnquiryForm';
import TemplateTwo from './Templates/TemplateTwo';
import { actions as leadsActions } from '../../shared/leadsAuthentication';
import { getCustomerDetails } from '../../shared/CustomerLogin/reducer';
import { type Font, type Option } from '../../types';
import { actions as routerActions } from '../../actions/router';
import { actions as enquiryActions } from '../../actions/enquiry';

export interface Config {
  translations: Object;
  backgroundImage: string;
  defaultHeaderFont: Font;
  defaultHeaderBackgroundColour: Option;
  largeHeaderFont: Font;
  largeHeaderBackgroundColour: Option;
  hideImageContainer: boolean;
  marketingLink: string;
  templateType: string;
}

type Props = {
  config: Config,
  dispatch: Function,
  actions: {
    submitMessage: (form: Object) => void,
    init: Function,
    enquiryFormGoBack: () => void,
    resetEnquiry: () => void,
    requestPrivacyPolicy: Object => void,
    getEnquiryVehicleInfo: () => void,
  },
  pathByModule: string => any,
  state: Object,
  shared: Object,
  preview: boolean,
  history: Object,
  vehicleInContext: Object,
  globalStyling: Object,
};

export default class Enquiry extends Component<Props> {
  componentDidMount() {
    // We do this to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      actions: { init, resetEnquiry },
      router: { path },
      dispatch,
    } = this.props;

    dispatch(leadsActions.getLeadsToken());
    init();
    resetEnquiry();

    const [vehicleId] = path.split('/').reverse();
    dispatch(enquiryActions.getEnquiryVehicleInfo(vehicleId));
  }

  render() {
    const {
      state: { error, submissionStatus, locale },
      actions: { submitMessage, enquiryFormGoBack, requestPrivacyPolicy },
      history,
      preview,
      config,
      shared,
      vehicleInContext,
      globalStyling,
    } = this.props;
    const noUser = {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
    };
    const userDetails = preview ? noUser : getCustomerDetails(shared) || noUser;
    switch (config.templateType) {
      case 'TemplateTwo':
        return (
          <TemplateTwo
            config={config}
            vehicle={vehicleInContext}
            history={history}
            globalStyling={globalStyling}
            submitMessage={submitMessage}
            enquiryFormGoBack={enquiryFormGoBack}
            submissionStatus={submissionStatus}
            requestPrivacyPolicy={requestPrivacyPolicy}
            error={error}
          />
        );
      default:
        return (
          <EnquiryForm
            preview={preview}
            vehicle={vehicleInContext}
            history={history}
            translations={config.translations}
            error={error}
            submitMessage={submitMessage}
            submissionStatus={submissionStatus}
            enquiryFormGoBack={enquiryFormGoBack}
            locale={locale}
            userDetails={userDetails}
            backgroundImage={config.backgroundImage}
            globalStyling={globalStyling}
            defaultHeaderFont={config.defaultHeaderFont}
            defaultHeaderBackgroundColour={config.defaultHeaderBackgroundColour}
            largeHeaderFont={config.largeHeaderFont}
            largeHeaderBackgroundColour={config.largeHeaderBackgroundColour}
            enquiryTypeDropdownVisible={config.enquiryTypeDropdownVisible}
            showPostCodeField={config.showPostCodeField}
            showAddressFields={config.showAddressFields}
            showVehicleOfInterest={config.showVehicleOfInterest}
            showVehicleOfInterestSingle={config.showVehicleOfInterestSingle}
            showAddressRows={config.showAddressRows}
            showBookingFields={config.showBookingFields}
            showDualCountyPostCodeRow={config.showDualCountyPostCodeRow}
            calendarStyles={config.calendarStyles}
            enquiryTypes={config.enquiryTypes}
            hideImageContainer={config.hideImageContainer}
            rrVehicleOfInterest={config.rrVehicleOfInterest}
            marketingLink={config.marketingLink}
            applyRRValidations={config.applyRRValidations}
            onDemandValidations={config.onDemandValidations}
            showUserCommunication={config.showUserCommunication}
            formWidth={config.formWidth}
            dataConsentFont={config.dataConsentFont}
            showRRCustomDataConsentSection={
              config.showRRCustomDataConsentSection
            }
            showJLRMarketingAndDistributionConsent={
              config.showJLRMarketingAndDistributionConsent
            }
            showJLRKoreaSpecificConsent={config.showJLRKoreaSpecificConsent}
            showTradeInFields={config.showTradeInFields}
            enquiryFormStyles={config.fontStyles}
            hideYear={config.hideYear}
            enquiryTypeLabel={config.enquiryTypeLabel}
            enquiryTypeValue={config.enquiryTypeValue}
            checkboxLabelStyles={config.checkboxLabelFont}
            useTimeOfDay={config.useTimeOfDay}
          />
        );
    }
  }
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function TestDrive({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 29 24">
        <path d="M13.5 1.7c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm0 1.8c3.8 0 7 2.7 7.7 6.3.1.5-.2 1-.8 1.1H20l-2.7-.7C16.6 8.9 15.1 8 13.5 8c-1.6 0-3 .9-3.8 2.2l-2.8.7c-.1 0-.2.1-.4 0-.5-.1-.9-.6-.8-1.1.8-3.6 4-6.3 7.8-6.3zm0 12.3c-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3 1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3zm-7.3-1.4c-.2-.5 0-1 .5-1.1.2-.1.3-.1.5-.1l2.1.4c.3 1.4 1.4 2.5 2.8 3l.4 2.6c-3-.3-5.3-2.2-6.3-4.8zm8.5 4.9l.4-2.6c1.3-.5 2.4-1.7 2.7-3l2.1-.4c.2 0 .4 0 .6.1.5.2.5.7.5 1.2-1.2 2.4-3.5 4.3-6.3 4.7z" />
        <circle cx={13.5} cy={12.5} r={2.8} />
      </svg>
    </IconWrapper>
  );
}

TestDrive.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import Selector from './Selector/Selector';
import ColourSelector from './ColourSelector/ColourSelector';
import FileUpload from './FileUpload';

import { extractFileExtensions } from '../helpers/fontParser';

const Container = styled.div`
  padding: 25px;
  width: 790px;
`;
const Heading = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #474747;
`;
const SubHeading = styled.div`
  font-size: 13px;
  color: #757575;
`;
const FontRow = styled.div`
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
`;
const FontLabel = styled.div`
  color: #757575;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
`;
const ColourPickerRow = styled.div`
  display: flex;
  margin-top: 10px;
  position: relative;
`;
const ColouredActionLink = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #d0021b;
`;
const LineBreak = styled.div`
  margin: 25px 0;
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
`;
const SectionBreak = styled.div`
  margin-bottom: 25px;
`;
const DeletionBox = styled.div`
  height: 40px;
  border: 1px solid #ff4949;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
`;
const Button = styled.div`
  font-size: 10px;
  font-weight: Bold;
  height: 25px;
  min-width: 65px;
  box-sizing: border-box;
  border: 1px solid #474747;
  color: #474747;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;

  ${({ danger }) =>
    danger &&
    'border: 1px solid #FF4949; background-color: #FF4949; color: #ffffff'};
`;

type Props = {
  brandColours: {
    availableColours: Object[],
    primaryBrandColour: Object,
    secondaryBrandColour: Object,
    pageBackgroundColour: Object,
    primaryFontColour: Object,
    secondaryFontColour: Object,
    paragraphFontColour: Object,
    highlightedLabelsColour: Object,
  },
  fonts: Object,
  addBrandColour: () => void,
  removeBrandColour: (hex: string) => void,
  saveBrandColour: (hex: string) => void,
  selectBrandColour: (key: string, value: string) => void,
  addBrandFont: (font: File) => void,
  selectBrandFont: (key: string, font: Object) => void,
  removeBrandFont: (fontName: string) => void,
};
type State = {
  fontForDeletion: string,
};
export default class TemplateBrandColours extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fontForDeletion: '',
    };
  }

  onDeleteClick = (fontForDeletion: string) => {
    this.setState(() => ({ fontForDeletion }));
  };

  onCancelClick = () => {
    this.setState(() => ({ fontForDeletion: '' }));
  };

  onDeleteConfirmClick = () => {
    const { fontForDeletion } = this.state;
    this.setState(() => ({ fontForDeletion: '' }));
    this.props.removeBrandFont(fontForDeletion);
  };

  props: Props;

  render = () => {
    const {
      brandColours: {
        availableColours,
        primaryFontColour,
        secondaryFontColour,
        paragraphFontColour,
        highlightedLabelsColour,
      },
      selectBrandColour,
      addBrandFont,
      selectBrandFont,
      fonts: {
        availableFonts,
        primaryFont,
        secondaryFont,
        paragraphFont,
        highlightedLabelsFont,
      },
    } = this.props;
    const { fontForDeletion } = this.state;

    const actualAvailableFonts = availableFonts.reduce(
      (acc, curr) =>
        acc.some(entry => entry.label === curr.label) ? acc : [...acc, curr],
      [],
    );

    return (
      <Container>
        <Heading>Fonts</Heading>
        <div
          style={{ display: 'flex', marginTop: '15px', alignItems: 'center' }}
        >
          <div style={{ marginRight: '20px', width: '150px' }}>
            <FileUpload
              input={{
                name: 'name',
                onChange: e => addBrandFont(e.target.files[0]),
              }}
              label="select file"
              accept=".woff, .otf, .ttf, .eot"
            />
          </div>
          <SubHeading>
            Note: You can upload fonts in TTF, OTF, WOFF formats. To support
            fonts in internet explorer make sure you upload fonts in EOT format.
          </SubHeading>
        </div>
        <LineBreak />
        {actualAvailableFonts.map(font =>
          font.label === fontForDeletion ? (
            <FontRow key={font.label || 'temp'}>
              <DeletionBox>
                This font may already be in use.
                <b style={{ marginLeft: '5px' }}>Are you sure?</b>
                <Button
                  data-qa-hook="cancel-delete-button"
                  onClick={this.onCancelClick}
                >
                  CANCEL
                </Button>
                <Button
                  data-qa-hook="confirm-delete-button"
                  onClick={this.onDeleteConfirmClick}
                  danger
                >
                  DELETE
                </Button>
              </DeletionBox>
            </FontRow>
          ) : (
            <FontRow key={font.value}>
              <FontLabel data-qa-hook="font-label">
                {`${font.label} (${extractFileExtensions(
                  font.label,
                  availableFonts,
                )})`}
              </FontLabel>
              <ColouredActionLink
                data-qa-hook="font-delete-button"
                onClick={() => this.onDeleteClick(font.label)}
              >
                delete
              </ColouredActionLink>
            </FontRow>
          ),
        )}
        <LineBreak />
        <Heading data-qa-hook="brand-primary-font-heading">
          Brand primary font
        </Heading>
        <SubHeading>
          Used in page headings and branded text elements.
        </SubHeading>
        <ColourPickerRow>
          <Selector
            qaHook="brand-primary-font"
            options={actualAvailableFonts}
            selected={primaryFont}
            onOptionClick={selectBrandFont}
            forKey="primaryFont"
          />
          <ColourSelector
            qaHook="brand-primary-font"
            brandColours={availableColours}
            selected={primaryFontColour}
            selectBrandColour={selectBrandColour}
            forKey="primaryFontColour"
          />
        </ColourPickerRow>
        <SectionBreak />
        <Heading>Secondary font (optional)</Heading>
        <SubHeading>
          Used in normal headings (e.g. secondary page headings).
        </SubHeading>
        <ColourPickerRow>
          <Selector
            qaHook="brand-secondary-font"
            options={actualAvailableFonts}
            selected={secondaryFont}
            onOptionClick={selectBrandFont}
            forKey="secondaryFont"
          />
          <ColourSelector
            qaHook="brand-secondary-font"
            brandColours={availableColours}
            selected={secondaryFontColour}
            selectBrandColour={selectBrandColour}
            forKey="secondaryFontColour"
          />
        </ColourPickerRow>
        <SectionBreak />
        <Heading>Paragraph font (optional)</Heading>
        <SubHeading>Used in modules and pages with paragraph text.</SubHeading>
        <ColourPickerRow>
          <Selector
            qaHook="brand-paragraph-font"
            options={actualAvailableFonts}
            selected={paragraphFont}
            onOptionClick={selectBrandFont}
            forKey="paragraphFont"
          />
          <ColourSelector
            qaHook="brand-paragraph-font"
            brandColours={availableColours}
            selected={paragraphFontColour}
            selectBrandColour={selectBrandColour}
            forKey="paragraphFontColour"
          />
        </ColourPickerRow>
        <SectionBreak />
        <Heading>Highlighted labels (optional)</Heading>
        <SubHeading>
          Used in modules and pages with highlighted text (e.g. pricing,
          highlighted paragraph text).
        </SubHeading>
        <ColourPickerRow>
          <Selector
            qaHook="brand-highlighted-labels-font"
            options={actualAvailableFonts}
            selected={highlightedLabelsFont}
            onOptionClick={selectBrandFont}
            forKey="highlightedLabelsFont"
          />
          <ColourSelector
            qaHook="brand-highlighted-labels-font"
            brandColours={availableColours}
            selected={highlightedLabelsColour}
            selectBrandColour={selectBrandColour}
            forKey="highlightedLabelsColour"
          />
        </ColourPickerRow>
        <SectionBreak />
      </Container>
    );
  };
}

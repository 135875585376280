// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_ENQUIRY';

export const constants = moduleConstants(modulePrefix, [
  'SUBMIT_MESSAGE',
  'SUBMIT_MESSAGE_SUCCESSFUL',
  'SUBMIT_MESSAGE_FAILURE',
  'RESET_ENQUIRY',
  'GET_LOCALE_SUCCESS',
  'INIT',
  'REQUEST_PRIVACY_POLICY',
]);

export const id = 'Enquiry';

export const actions = {
  submitMessage: moduleActionWithArgs(
    constants.SUBMIT_MESSAGE,
    id,
    (enquiryForm, preview = false) => ({ enquiryForm, preview }),
  ),
  submitMessageSuccessful: moduleAction(
    constants.SUBMIT_MESSAGE_SUCCESSFUL,
    id,
  ),
  submitMessageFailure: moduleActionWithArgs(
    constants.SUBMIT_MESSAGE_FAILURE,
    id,
    error => error,
  ),
  resetEnquiry: moduleAction(constants.RESET_ENQUIRY, id),
  init: moduleAction(constants.INIT, id),
  getLocaleSuccess: moduleActionWithArgs(
    constants.GET_LOCALE_SUCCESS,
    id,
    payload => payload,
  ),
  requestPrivacyPolicy: moduleActionWithArgs(
    constants.REQUEST_PRIVACY_POLICY,
    id,
    payload => payload,
  ),
};

export type SubmissionStatus =
  | 'NotRequested'
  | 'Requested'
  | 'Successful'
  | 'Failed';
type State = {
  error: any,
  submissionStatus: SubmissionStatus,
};
export const initialState: State = {
  error: null,
  submissionStatus: 'NotRequested',
  locale: '',
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.SUBMIT_MESSAGE:
      return {
        ...state,
        error: null,
        submissionStatus: 'Requested',
      };
    case constants.SUBMIT_MESSAGE_SUCCESSFUL:
      return {
        ...state,
        submissionStatus: 'Successful',
      };
    case constants.SUBMIT_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        submissionStatus: 'Failed',
      };
    case constants.RESET_ENQUIRY:
      return {
        ...state,
        error: null,
        submissionStatus: 'NotRequested',
      };
    case constants.GET_LOCALE_SUCCESS:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Manual({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 30 30">
        <path d="M22.364 15.636V5.818a.818.818 0 1 1 1.636 0v10.637a.818.818 0 0 1-.818.818h-7.364v9.818a.818.818 0 0 1-1.636 0v-9.818H7.636v9.818a.818.818 0 0 1-1.636 0V5.818a.818.818 0 0 1 1.636 0v9.818h6.546V5.818a.818.818 0 0 1 1.636 0v9.818h6.546z" />
      </svg>
    </IconWrapper>
  );
}

Manual.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

import {
  Config,
  Slice,
  Section,
  TextArea,
  Font,
  Align,
  Colour,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'PageTitle',
  { id: 'PageTitle' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      'Global',
      { icon: SectionIcons.SideCarousel },
      Align('Alignment', 'alignment'),
    ),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      TextArea('Page Title', 'pageTitle'),
      TextArea('Page Subtitle', 'pageSubTitle'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Font('Page Title', 'pageTitleFont'),
      Font('Page SubTitle', 'pageSubTitleFont'),
      Colour('Background', 'backgroundColour'),
    ),
  ),
);

export default config;

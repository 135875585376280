// @flow
import React from 'react';
import styled from 'styled-components';
import type { Theme } from '../theme';
import ComponentList from './ComponentList';
import Select from './Select';
import Button from './Button';

type SelectedComponent = {
  componentName: string,
  open: boolean,
  value: string,
};
type Props = {
  options: Array<{ value: string, label: string }>,
  selectedComponents: Array<SelectedComponent>,
  onChange: (index: number, value: string) => void,
  onOpen: (index: number) => void,
  onClose: (index: number) => void,
  onDelete: (index: number) => void,
  onSelectChange: (value: string) => void,
  onAdd: () => void,
};
type ThemeProps = {
  theme: Theme,
};

const StyledLabel = styled.label.withConfig({
  displayName: 'StyledLabel',
})`
  color: ${(props: ThemeProps) => props.theme.colours.secondary06};
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  font-weight: 600;
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
`;

const StyledSelect = styled.div.withConfig({
  displayName: 'StyledSelect',
})`
  & select {
    display: inline;
  }
`;

const StyledButton = styled(Button).withConfig({
  displayName: 'StyledButton',
})`
  margin-left: 10px;
  height: 25px;
  padding: 5px 10px;
`;

export default function ComponentSelector({
  options,
  onSelectChange,
  onAdd,
  selectedComponents,
  onChange,
  onClose,
  onOpen,
  onDelete,
}: Props) {
  return (
    <div>
      <StyledLabel>Components</StyledLabel>
      <StyledSelect>
        <Select
          name="componentSelectList"
          options={options || []}
          selectedValue={options[0].value || ''}
          onChange={event => onSelectChange(event.target.value)}
        />
        <StyledButton primary onClick={onAdd}>
          ADD
        </StyledButton>
      </StyledSelect>
      <ComponentList
        selectedComponents={selectedComponents}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        onDelete={onDelete}
      />
    </div>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

type Props = {
  loaded: boolean,
  globalStyling: Object,
  label: string,
  flexDirection: string,
  children: () => Node,
};

const SpinnerSection = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100px;
`;

export default function SpinnerWrapper({
  loaded,
  globalStyling,
  label,
  children,
}: Props) {
  return loaded ? (
    children()
  ) : (
    <SpinnerSection>
      <Spinner colour={globalStyling.colours.primaryBrandColour} />
      {label}
    </SpinnerSection>
  );
}

SpinnerWrapper.defaultProps = {
  label: '',
};

// @flow
import React from 'react';
import styled from 'styled-components';

import HirePurchase from './HirePurchase';
import PersonalContractPurchase from './PersonalContractPurchase';
import Leasing from './Leasing';

const Container = styled.div.withConfig({
  displayName: 'FinanceProductsContainer',
})`
  width: 100%;
  height: auto;
`;

type Props = {
  globalStyling: Object,
  translations: Object,
  context: 'HP' | 'PCP' | 'Leasing',
  title: string,
  downpaymentRange: RangeConfig,
  termsRange: RangeConfig,
  financeExample: Object,
  translations: Object,
  financeExampleRenderProp: (financeExample: Object) => Node,
  requestFinanceUpdate: (
    duration: number,
    downpayment: number,
    additionalServices: string[],
  ) => void,
  globalStyling: Object,
  locale: string,
  showDescription: boolean,
};

const contextRenderer = {
  RS: (props: Props) => (
    <HirePurchase
      productKey="RS"
      downpaymentRange={props.downpaymentRange}
      termsRange={props.termsRange}
      financeExample={props.financeExample}
      financeExampleRenderProp={props.financeExampleRenderProp}
      requestFinanceUpdate={props.requestFinanceUpdate}
      translations={props.translations}
      globalStyling={props.globalStyling}
      locale={props.locale}
      showDescription={props.showDescription}
    />
  ),
  PC: (props: Props) => (
    <PersonalContractPurchase
      productKey="PC"
      downpaymentRange={props.downpaymentRange}
      termsRange={props.termsRange}
      financeExample={props.financeExample}
      financeExampleRenderProp={props.financeExampleRenderProp}
      requestFinanceUpdate={props.requestFinanceUpdate}
      translations={props.translations}
      globalStyling={props.globalStyling}
      locale={props.locale}
      showDescription={props.showDescription}
    />
  ),
  LE: (props: Props) => (
    <Leasing
      productKey="LE"
      downpaymentRange={props.downpaymentRange}
      termsRange={props.termsRange}
      financeExample={props.financeExample}
      financeExampleRenderProp={props.financeExampleRenderProp}
      requestFinanceUpdate={props.requestFinanceUpdate}
      translations={props.translations}
      globalStyling={props.globalStyling}
      locale={props.locale}
      showDescription={props.showDescription}
    />
  ),
};

export default function FinanceProducts(props: Props) {
  return <Container>{contextRenderer[props.context](props)}</Container>;
}

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ScrollLock from 'react-scrolllock';
import styled from 'styled-components';
import media from '../../theme';

export function useModal() {
  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  return {
    isModalVisible,
    toggleModal,
  };
}

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  overflow: hidden;
  transition: visibility 0.35s, opacity 0.35s;
  visibility: visible;
  opacity: 1;
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  * {
    box-sizing: border-box;
  }
  * img {
    display: block;
  }
  ${media.min.medium`
    height: auto;
    ${({ width }) => width && `width: ${width};`}
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `};
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  background: white;
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  margin: 10px 10px;
  cursor: pointer;
  z-index: 999;
  font-size: 1.5em;
  color: white;
`;

const CloseImg = styled.img`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 40px;
  padding: 5px;
`;

const closeImgURL =
  'https://res.cloudinary.com/motortrak/image/upload/v1564484782/cms/global/icons/x.svg';

export const Modal = ({ isModalVisible, hide, width, children }) =>
  isModalVisible
    ? ReactDOM.createPortal(
        <Overlay>
          <Close onClick={hide}>
            <CloseImg src={closeImgURL} alt="close" />
          </Close>
          <ModalContentWrapper
            modalWidth={width}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <ModalContent>{children}</ModalContent>
          </ModalContentWrapper>
          <ScrollLock />
        </Overlay>,
        document.body,
      )
    : null;

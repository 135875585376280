// @flow
import React from 'react';
import styled from 'styled-components';

import Select from './Select';
import Input from './Input';
import Label from './Label';
import Column from './Column';
import FormGroup from './FormGroup';

const Container = styled.div.withConfig({
  type: 'Container',
})`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeContent};
  display: flex;
  flex-direction: column;
`;

const ReadonlyPageType = styled.span`
  line-height: 30px;
`;

type PageTemplateOption = { value: string, label: string };

type Page = {
  pageName: string,
  pageTemplateId: string,
  relativePath: string,
  siteId: string,
};
type Props = {
  pageTemplates: PageTemplateOption[],
  onChange: (value: Page) => void,
  value: Page,
  canChangeType: boolean,
};

export default function PageDetails({
  pageTemplates,
  value,
  onChange,
  canChangeType,
}: Props) {
  const onChangePageName = pageName => onChange({ ...value, pageName });
  const onChangePageTemplate = pageTemplateId =>
    onChange({ ...value, pageTemplateId });
  const onChangeRelativePath = relativePath =>
    onChange({ ...value, relativePath: `/${relativePath}` });
  const relativePath = value.relativePath
    ? value.relativePath.replace(/^\//, '')
    : '';
  const pageTemplate = pageTemplates.find(
    p => p.value === value.pageTemplateId,
  );

  return (
    <Container>
      <FormGroup horizontal>
        <Label width="30%">Page name</Label>
        <Column width="70%">
          <Input
            name="pageName"
            value={value.pageName}
            type="text"
            onChange={e => onChangePageName(e.target.value)}
            placeholder="Type page name..."
            prefix=""
          />
        </Column>
      </FormGroup>
      <FormGroup horizontal>
        <Label width="30%">Page type</Label>
        <Column width="70%">
          {canChangeType ? (
            <Select
              placeholder="Select page type"
              name="pageTemplateId"
              onChange={e => onChangePageTemplate(e.target.value)}
              options={pageTemplates}
            />
          ) : (
            pageTemplate && (
              <ReadonlyPageType>{pageTemplate.label}</ReadonlyPageType>
            )
          )}
        </Column>
      </FormGroup>
      <FormGroup horizontal>
        <Label width="25%">URL</Label>
        <Column width="75%">
          {value.required ? (
            <ReadonlyPageType>{`name.com/${relativePath}`}</ReadonlyPageType>
          ) : (
            <Input
              name="relativePath"
              value={relativePath}
              type="text"
              onChange={e => onChangeRelativePath(e.target.value)}
              prefix="name.com/"
              placeholder="my-page"
            />
          )}
        </Column>
      </FormGroup>
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import Plus from '../JaguarIcons/Global/Plus';
import Minus from '../JaguarIcons/Global/Minus';
import { Button } from '../Global';
import SearchFiltersContainer from '../../containers/SearchFiltersContainer';

const FiltersContainer = styled.div`
  width: ${props => (props.filtersExpanded ? '400px' : '50%')};
  height: auto;
  overflow-x: hidden;
  ${props =>
    props.filtersExpanded &&
    'border-right: 1px solid #acacac; box-sizing: border-box; padding-right: 20px;'};
`;
const FilterAccordion = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  justify-content: flex-start;
`;
const FilterButton = styled.div`
  border: 1px solid #999999;
  height: 45px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
  padding: 0 15px;
  cursor: pointer;
`;
const Filters = styled.div`
  display: ${props => (props.filtersExpanded ? 'block' : 'none')};
  width: 100%;
  padding-bottom: 50px;
`;

type Props = {
  filtersExpanded?: boolean,
  onFilterMenuClick: Function,
  translations: Object,
  preview: boolean,
  resetFilters: Function,
  config: Object,
  history: Object,
  globalStyling: Object,
  featureFlags: Object,
};

const Icon = ({ filtersExpanded }: { filtersExpanded: boolean }) => (
  <div>
    {filtersExpanded ? (
      <Minus width="1.5em" height="1.5em" colour="#7e7e7e" />
    ) : (
      <Plus width="1.5em" height="1.5em" colour="#7e7e7e" />
    )}
  </div>
);

export default function FilterMenuBar({
  filtersExpanded,
  onFilterMenuClick,
  translations,
  preview,
  resetFilters,
  config,
  history,
  globalStyling,
  featureFlags,
}: Props) {
  return (
    <FiltersContainer filtersExpanded={filtersExpanded}>
      <FilterAccordion>
        <FilterButton
          data-qa-hook="filters-accordion"
          onClick={onFilterMenuClick}
        >
          {translations.searchResultsFilterLabel}
          <Icon filtersExpanded={filtersExpanded} />
        </FilterButton>
      </FilterAccordion>
      <Filters filtersExpanded={filtersExpanded}>
        {preview ? null : (
          <div>
            <SearchFiltersContainer
              config={config}
              history={history}
              featureFlags={featureFlags}
            />
            <Button
              onClick={resetFilters}
              text={translations.searchCTAReset}
              applyStyle="secondary"
              buttonStyle={
                globalStyling.uiElements.secondaryButton &&
                globalStyling.uiElements.secondaryButton.buttonStyle
              }
              styleOverride={() => `
                width: 100%;
                margin-top: 20px;
              `}
            />
          </div>
        )}
      </Filters>
    </FiltersContainer>
  );
}
FilterMenuBar.defaultProps = {
  filtersExpanded: false,
};

// @flow
/* eslint-env browser */
import axios from 'axios';
import querystring from 'querystring';
import compose from 'ramda/src/compose';
import { errorType } from './error';

const axiosRequestErrorMap = {
  put: errorType.Update,
  get: errorType.Load,
  delete: errorType.Delete,
  post: errorType.Create,
};

export const defaultConfig = ({ token }: { token: string }) => ({
  responseType: 'json',
  headers: token ? { Authorization: `Bearer ${token}` } : {},
});

export const blobContentConfig = (token: string) => ({
  ...defaultConfig({ token }),
  responseType: 'blob',
});

export const localeConfigSerializer = (
  locale: string,
  additionalParams: any,
) => ({
  paramsSerializer: (params: Object) => querystring.stringify(params),
  params: {
    ...(locale && { languageCode: locale }),
    ...additionalParams,
  },
});

export const multipartFormConfig = (token: string) => {
  const postConfig = defaultConfig({ token });

  return {
    ...postConfig,
    headers: {
      ...postConfig.headers,
      ...{
        'content-type': 'multipart/form-data',
      },
    },
  };
};

type Options = {
  url: string,
  config: Object,
  data?: Object,
  token: string,
};

export function get(options: Options): Promise<{ data: * }> {
  const { url, config = {}, token } = options;
  return axios.get(url, { ...defaultConfig({ token }), ...config });
}

export function post(options: Options): Promise<{ data: * }> {
  const { url, config = {}, data, token } = options;
  return axios.post(url, data, { ...defaultConfig({ token }), ...config });
}

export function put(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.put(url, data, { ...defaultConfig({ token }), ...config });
}

export function remove(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.delete(url, { ...defaultConfig({ token }), data, ...config });
}

const extractLongFileName = headers =>
  headers['content-disposition'].split(' ')[1];
const pretifyLongFileName = longFileName =>
  longFileName
    ? longFileName.replace('filename=', '').replace(';', '')
    : 'download.csv';

const fileNameFromHeaders = compose(
  pretifyLongFileName,
  extractLongFileName,
);

const blobFactory = (headers, data) =>
  new Blob([data], {
    type: headers['content-type'] || 'application/octet-stream',
  });

const downloadWithHiddenAnchorTag = (blob, fileName) => {
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  window.document.body.appendChild(tempLink);
  tempLink.click();
  window.document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

export const fileDownload = ({
  headers,
  data,
}: {
  headers: { 'content-disposition': string, 'content-type': string },
  data: any,
}) => {
  const fileName = fileNameFromHeaders(headers);
  const blob = blobFactory(headers, data);

  return navigator.msSaveBlob
    ? navigator.msSaveBlob(blob, fileName)
    : downloadWithHiddenAnchorTag(blob, fileName);
};

export function httpClientIntercept(next: Function): any {
  axios.interceptors.response.use(
    response => response,
    error => {
      const { config, response } = error;
      // eslint-disable-next-line no-console
      console.warn('[HTTP ERROR INTERCEPTOR]:', error);

      next(response);

      return Promise.reject({
        message: error.message,
        ...(config && {
          type:
            axiosRequestErrorMap[
              error.config && error.config.method.toLowerCase()
            ],
        }),
        ...(response && { status: response.status }),
      });
    },
  );
}

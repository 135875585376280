// @flow
import { constants, type Action } from '../actions/router';

export type Route = 'Index' | 'Clone' | 'Configure' | 'Edit';

export type State = {
  location: ?Location,
  site: ?string,
  route: Route,
};

const initialState: State = {
  location: null,
  site: null,
  route: 'Index',
};

const getRoute = (pathname: string) => {
  // Ignore leading slash
  const [siteId, action] = pathname
    .slice(1)
    .split('/')
    .slice(1);
  switch (action) {
    case 'clone':
      return { route: 'Clone', site: siteId };
    case 'config':
      return { route: 'Configure', site: siteId };
    case 'builder':
      return { route: 'Edit', site: siteId };
    default:
      return { route: 'Index' };
  }
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.NavigateSuccess: {
      return {
        ...state,
        location: action.payload,
        ...getRoute(action.payload.pathname),
      };
    }

    default:
      return state;
  }
}

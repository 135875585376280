// @flow
import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import { actions as editorActions } from '../state/actions/editor';

import { type Error } from '../state/types/error';

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colours.secondary03};
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
`;

const mapStateToProps = state => ({
  success: state.notification.success,
  error: state.notification.error,
});
const mapDispatchToProps = dispatch => ({
  resetSuccessMessages: () => dispatch(editorActions.resetSuccessMessages()),
  resetErrorMessages: () => dispatch(editorActions.resetFailureMessages()),
});

const toastify = (error, success, reset) => {
  const options = {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  };

  const toastConfig = {
    ...options,
    onClose: () => {
      reset();
    },
  };

  if (error) toast.error(`${error.message}`, toastConfig);
  if (success) toast.success(`${success.message}`, toastConfig);
};

function App({
  children,
  success,
  error,
  resetSuccessMessages,
  resetErrorMessages,
}: {
  children: Node,
  success: Object,
  error: Error,
  resetSuccessMessages: () => void,
  resetErrorMessages: () => void,
}) {
  if (success)
    toastify(
      null,
      { message: 'Changes Saved', key: 'success' },
      resetSuccessMessages,
    );
  if (error) toastify({ ...error, key: 'error' }, null, resetErrorMessages);
  return (
    <Container>
      <ToastContainer />
      {children}
    </Container>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

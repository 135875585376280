// @flow
import React from 'react';
import styled from 'styled-components';

import Inspection from './JaguarIcons/Approved/Inspection';
import Mileage from './JaguarIcons/Approved/Mileage';
import RoadTest from './JaguarIcons/Approved/RoadTest';
import Technicians from './JaguarIcons/Approved/Technicians';
import TwentyFourHours from './JaguarIcons/Approved/TwentyFourHours';
import Warranty from './JaguarIcons/Approved/Warranty';
import ContentWrapper from './ContentWrapper';

import media from '../theme';

const Container = styled.div`
  width: 100%;
  height: auto;
  ${({ backgroundImage }) =>
    backgroundImage && `background: url(${backgroundImage});`};
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour};`};
  background-size: 100% 100%;
  ${({ horizontalPadding }) =>
    horizontalPadding
      ? `padding: ${horizontalPadding}px 0`
      : 'padding: 100px 0;'};
  ${media.max.medium`
    ${({ backgroundImageMobile }) =>
      backgroundImageMobile && `background: url(${backgroundImageMobile})`};
    background-size: 100% 100%;
  `};
`;

const TopSectionContainer = styled.div`
  width: 50%;
  text-align: center;
  margin: 0 25%;
  color: #ffffff;
  ${media.max.large`
    width: 80%;
    margin: 0 10%;
  `};
`;

const Heading = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 3px;
`;

const ItemHeading = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 15px;
  margin-bottom: 10px;
  letter-spacing: 3px;
  margin-top: 15px;
`;

const Content = styled.div`
  font-size: 18px;
  line-height: 22px;
`;

const ItemContent = styled.div`
  color: #f0f0f0;
  font-size: 15px;
  line-height: 21px;
`;

type TopSectionProps = {
  headingText: string,
  contentText: string,
};

const TopSection = ({ headingText, contentText }: TopSectionProps) => (
  <TopSectionContainer>
    <Heading>{headingText}</Heading>
    <Content>{contentText}</Content>
  </TopSectionContainer>
);

const BenefitBox = styled.div`
  width: 30%;
  text-align: center;
  margin-top: 30px;
  ${media.max.large`
    width: 100%;
  `};
`;

const BottomSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #ffffff;
  ${media.max.large`
    padding: 0 5%;
  `};
`;

const iconSelector = {
  Warranty: <Warranty colour="#acacac" height="3em" width="3em" />,
  Mileage: <Mileage colour="#acacac" height="3em" width="3em" />,
  RoadTest: <RoadTest colour="#acacac" height="3em" width="3em" />,
  Technicians: <Technicians colour="#acacac" height="3em" width="3em" />,
  TwentyFourHours: (
    <TwentyFourHours colour="#acacac" height="3em" width="3em" />
  ),
  Inspection: <Inspection colour="#acacac" height="3em" width="3em" />,
};

type Option = { icon: string, heading: string, content: string };
type BottomSectionProps = {
  options: Option[],
};

const BottomSection = ({ options }: BottomSectionProps) => (
  <BottomSectionContainer>
    {options.map((o, index) => (
      <BenefitBox key={`approved-benefits-item${index.toString()}`}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {iconSelector[o.icon] || null}
        </div>
        <ItemHeading>{o.heading}</ItemHeading>
        <ItemContent>{o.content}</ItemContent>
      </BenefitBox>
    ))}
  </BottomSectionContainer>
);

type Props = {
  backgroundImage: string,
  backgroundImageMobile: string,
  backgroundColour: string,
  topSection: TopSectionProps,
  bottomSection: BottomSectionProps,
  contentWidth: number,
  horizontalPadding: number,
};

export default function ApprovedBenefits({
  backgroundImage,
  backgroundImageMobile,
  backgroundColour,
  topSection,
  bottomSection,
  contentWidth,
  horizontalPadding,
}: Props) {
  return (
    <Container
      id="provenance"
      horizontalPadding={horizontalPadding}
      backgroundImage={backgroundImage}
      backgroundImageMobile={backgroundImageMobile}
      backgroundColour={backgroundColour}
    >
      <ContentWrapper contentWidth={contentWidth}>
        <TopSection
          headingText={topSection.headingText}
          contentText={topSection.contentText}
        />
        <BottomSection options={bottomSection.options} />
      </ContentWrapper>
    </Container>
  );
}

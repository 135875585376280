import React from 'react';
import { Dropdown, Checkbox } from 'cms-ui';
import styled from 'styled-components';
import { FormFieldEditor } from './FormFieldEditor';
import { defaultConfigs } from './defaultConfigs';

const ActionButtonWrapper = styled.div`
      display: flex;
      padding-left: 10px;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }}
`;
const EditingWrapper = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  height: auto;
  border: 1px solid #0065db;
`;

const FieldSelector = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const SectionColumn = styled.div`
  display: flex;
`;

const Icon = styled.img`
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
`;

const ActionButtons = ({ field, fields, updatePodField }) => (
  <ActionButtonWrapper>
    <Icon
      src="https://res.cloudinary.com/motortrak/image/upload/v1570186934/editor/delete-icon.svg"
      onClick={() => {
        updatePodField('fields', fields.filter(fi => fi.id !== field.id));
      }}
    />
    <Icon
      src="https://res.cloudinary.com/motortrak/image/upload/v1578671548/editor/okay-icon.svg"
      onClick={() => {
        updatePodField(
          'fields',
          fields.map(f =>
            f.id === field.id ? { ...field, editing: false } : f,
          ),
        );
      }}
    />
  </ActionButtonWrapper>
);

export const EditField = ({
  field,
  updatePodField,
  fields,
  formFieldTypes,
  uiElements,
  translations,
  radioInput,
  setRadioInput,
}) => {
  return (
    <>
      <EditingWrapper>
        <FieldSelector>
          <SectionColumn>Section Type</SectionColumn>
          <SectionColumn>
            <FlexWrapper>
              <Dropdown
                input={{
                  value: (
                    formFieldTypes.find(opt => opt.value === field.fieldType) ||
                    {}
                  ).value,
                  onChange: option => {
                    updatePodField(
                      'fields',
                      fields.map(m =>
                        m === field
                          ? {
                              ...m,
                              ...defaultConfigs[option.value],
                              uiElements,
                              fieldType: option.value,
                              editing: true,
                            }
                          : m,
                      ),
                    );
                  },
                }}
                size={36}
                options={formFieldTypes}
              />
              <Checkbox
                name="required"
                label="Required"
                checked={field.required}
                onChange={e => {
                  updatePodField(
                    'fields',
                    fields.map(f =>
                      f.id === field.id ? { ...field, required: e } : f,
                    ),
                  );
                }}
              />
            </FlexWrapper>
          </SectionColumn>
        </FieldSelector>
        <div>
          <FormFieldEditor
            {...{
              field,
              fields,
              updatePodField,
              translations,
              radioInput,
              setRadioInput,
            }}
          />
        </div>
      </EditingWrapper>
      {ActionButtons({ field, fields, updatePodField })}
    </>
  );
};

// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ColourSelection, FieldLabel, InputField } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const GrowingColourSelection = styled(ColourSelection).withConfig({
  displayName: 'GrowingColourSelection',
})`
  flex-grow: 1;
`;

const stringToRgba = rgba =>
  rgba
    .substring(5)
    .split(')')[0]
    .split(',');

const rgbaAndOpacityToString = (rgba, opacity) =>
  rgba && opacity ? `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${opacity})` : null;

export default function Colour({
  name,
  values: { id: value },
  ids: { id },
  onChange,
  data,
  hideOpacity,
}: FieldProps) {
  const [rgba, setRgba] = useState();
  const [opacity, setOpacity] = useState(1);

  const input = {
    value: rgbaAndOpacityToString(rgba, 1),
    onChange: event =>
      onChange(id, {
        value: event
          ? rgbaAndOpacityToString(stringToRgba(event.value), opacity)
          : null,
      }),
  };

  const opacityInput = {
    value: opacity,
    onChange: event =>
      onChange(id, { value: rgbaAndOpacityToString(rgba, event.target.value) }),
    step: '0.1',
  };

  useEffect(() => setOpacity(rgba ? rgba[3] : 1), [rgba]);

  useEffect(() => {
    if (value && !value.value) {
      setRgba(stringToRgba(value));
    } else if (value && value.value) {
      setRgba(stringToRgba(value.value));
    }
  }, [value]);

  return (
    <>
      <FieldLabel label={name}>
        <GrowingColourSelection input={input} options={data.colours} />
      </FieldLabel>
      {!hideOpacity && value && (
        <FieldLabel label="Opacity">
          <InputField
            input={opacityInput}
            type="number"
            maxInputWidth={54}
            name={name}
            initialValue={opacity}
            inputFlexMode="1"
            min="0"
            max="1"
          />
        </FieldLabel>
      )}
    </>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import type { Node } from 'react';

import type { Theme } from '../theme';

const StyledLabel = styled.label.withConfig({
  type: 'Label',
})`
  font-color: ${({ large, theme }: { large?: boolean, theme: Theme }) =>
    large ? theme.colours.highlight01 : theme.colours.secondary03};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormLabel};
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  line-height: 2;
  width: ${({ width }) => width || '100%'};
  padding-right: 0.5em;
  box-sizing: border-box;
`;

type Props = {
  large?: boolean,
  children?: Node,
};

export default function Label(props: Props) {
  return <StyledLabel {...props} />;
}

Label.defaultProps = {
  large: false,
  children: null,
};

import { resolve } from 'url';
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import querystring from 'querystring';
import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { mapGroupAndFinance as getGroupFinance } from '../../shared/financeCalculator/effects';
import {
  getModelGroupsBaseUrl,
  getAVLConfig,
  inventorySearchServiceUrl,
  getPlaceholderBrandImageUrl,
  appendLocaleQueryString,
  getInventoryStatus,
  getFinanceStatus,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';

let modelGroupsCache;

const down = ({ imageDomain, modelGroupName, position, modelSubTitle }) => ({
  name: modelGroupName.trim(),
  modelSubTitle,
  imageUrl: imageDomain,
  position,
});

function* mapGroupAndFinance(group, placeholdingImage, page, instance) {
  try {
    const financeStatus = yield select(getFinanceStatus);
    if (financeStatus) {
      const groupWithFinance = yield getGroupFinance(group);
      yield put(
        actions.getFinanceSuccess(page, instance, {
          group: groupWithFinance,
          placeholdingImage,
        }),
      );
    }
  } catch (error) {
    // noop leave group as is
  }
}

function* getInventoryGroup(action) {
  const {
    payload: { filters },
    module: { page, instance },
  } = action;

  try {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const inventoryStatus = yield select(getInventoryStatus);
    const financeStatus = yield select(getFinanceStatus);

    const location = yield select(
      state => state.shared.sessionPreferences.location || {},
    );

    const filtersQuery = Object.keys(location)
      ? `${filters}&${querystring.stringify(location)}`
      : filters;

    const url = `${baseUrl}inventory/make/${make}/market/${market}/groups?${filtersQuery}&status=${inventoryStatus}`;

    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);

    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });

    const inventory = response.data.contents;

    yield put(
      actions.getInventoryGroupSuccess(page, instance, {
        inventoryGroup: inventory,
        placeholdingImage,
      }),
    );
    // TODO check config for show finance
    if (financeStatus) {
      yield all(
        inventory.map(group =>
          call(mapGroupAndFinance, group, placeholdingImage, page, instance),
        ),
      );
    }
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* getModelGroupsAndInventory(action) {
  const {
    payload: { filters },
    module: { page, instance },
  } = action;
  try {
    const { locale, make, market, country } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
    const inventoryUrl = `${inventoryBaseUrl}inventory/make/${make}/market/${market}/groups?${filters}`;
    const financeStatus = yield select(getFinanceStatus);

    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);

    const modelGroupsUrl = yield call(
      resolve,
      yield select(getModelGroupsBaseUrl),
      `${make.replace(' ', '')}/${country}/${locale}/model_search`,
    );

    const modelGroupsData = modelGroupsCache
      ? [yield { data: modelGroupsCache }]
      : [
          call(get, {
            url: modelGroupsUrl,
          }),
        ];

    const [
      {
        data: { contents: inventory },
      },
      { data: modelGroups },
    ] = yield all([
      call(get, {
        url: inventoryUrl,
        ...(withLocaleQueryString && {
          config: localeConfigSerializer(locale),
        }),
      }),
      ...modelGroupsData,
    ]);

    if (!modelGroupsCache) {
      modelGroupsCache = modelGroups;
    }

    yield put(
      actions.getModelGroupsAndInventorySuccess(page, instance, {
        modelGroups: modelGroups.map(down),
        inventoryGroup: inventory,
        placeholdingImage,
      }),
    );

    // TODO check config for show finance
    if (financeStatus) {
      yield all(
        inventory.map(group =>
          call(mapGroupAndFinance, group, placeholdingImage, page, instance),
        ),
      );
    }
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* resetFilters() {
  try {
    yield put(searchFilterActions.resetFilters({ keepUrl: true }));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* SearchSaga() {
  yield takeEvery(constants.GET_INVENTORY_GROUP, getInventoryGroup);
  yield takeEvery(
    constants.GET_MODEL_GROUPS_AND_INVENTORY,
    getModelGroupsAndInventory,
  );
  yield takeEvery(constants.INIT, resetFilters);
}

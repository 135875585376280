// @flow
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg > rect:not([fill='none']) {
    fill: ${props => props.colour};
  }
  svg > path:not([fill]) {
    fill: ${props => props.colour};
  }
  svg {
    width: ${props => props.width};
    height: ${props => props.height};
  }
`;
export default function SocialMedia({ colour, width, height }: IconProps) {
  return (
    <IconWrapper colour={colour} width={width} height={height}>
      <svg viewBox="0 0 18 18" width="1em" height="1em">
        <g stroke="#0065DB" fill="none" fillRule="evenodd">
          <path d="M.5 1.5h17v15H.5z" />
          <g transform="translate(4 4)">
            <circle fill="#0065DB" cx={8.333} cy={1.667} r={1.667} />
            <circle fill="#0065DB" cx={8.333} cy={8.333} r={1.667} />
            <circle fill="#0065DB" cx={1.667} cy={5} r={1.667} />
            <path
              d="M6.111 2.778L3.89 3.888M6.082 7.277l-2.164-1.22"
              strokeLinecap="square"
            />
          </g>
        </g>
      </svg>
    </IconWrapper>
  );
}
SocialMedia.defaultProps = {
  colour: null,
  width: null,
  height: null,
};

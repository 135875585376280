import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid/v1';
import settings from '../../settings';

import { actions as pageActions } from '../../state/actions/page';
import { actions as contextActions } from '../../state/actions/context';
import { breakoutType } from '../BreakoutWrapper';
import PAGE_TYPES from '../../constants/pageTypes';

const createPageConfig = siteId => ({
  siteId,
  id: uuid(),
  pageTemplateId: settings.formPageInstanceId,
  seo: { title: '', description: '' },
  relativePath: '/searchresults',
  pageType: PAGE_TYPES.SEARCH_RESULTS,
  pageName: 'Search Results',
  modules: [],
});

const useSearchResults = () => {
  const currentSiteId = useSelector(state => state.site.currentSiteId);
  const pages = useSelector(state => state.config.pages);
  const searchResults = Object.values(pages).find(
    page => page.pageType === PAGE_TYPES.SEARCH_RESULTS,
  );
  const dispatch = useDispatch();

  const editSearchResults = () => {
    if (searchResults) {
      dispatch(
        contextActions.setPageContext(searchResults.id, searchResults.pageName),
      );
      dispatch(
        contextActions.setBreakoutContext({
          name: breakoutType.editSearchResults,
        }),
      );
    } else {
      const newPage = createPageConfig(currentSiteId);
      dispatch(pageActions.createPage(newPage, breakoutType.editSearchResults));
    }
  };
  return { editSearchResults };
};

export default useSearchResults;

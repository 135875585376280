// @flow
import React, { Component } from 'react';
import { actions as routerActions } from '../../actions/router';
import RequestResetPasswordContainer from '../../components/RequestResetPassword';
import ResetPasswordContainer from '../../components/ResetPassword';

export interface Config {
  translations: Object;
}

type Props = {
  config: Config,
  actions: {},
  state: Object,
  history: Object,
  preview: boolean,
  shared: Object,
  dispatch: Function,
  globalStyling: Object,
};

export default class ResetPassword extends Component<Props, *> {
  componentDidMount = () => {
    // use fragment as always kept client side
    const fragment = this.props.history.location.hash;
    this.resetToken = fragment && fragment.substr(1);
    const {
      actions: { init },
    } = this.props;
    init();
  };

  resetToken = undefined;

  render = () => {
    const { state, config, actions, dispatch, globalStyling } = this.props;
    const submitResetRequest = email => actions.requestPasswordReset({ email });
    const changePassword = password =>
      actions.resetPassword({ password, token: this.resetToken });
    const goToLogin = () => dispatch(routerActions.navigate('/signin'));
    return this.resetToken ? (
      <ResetPasswordContainer
        globalStyling={globalStyling}
        translations={config.translations}
        resetPasswordStatus={state.resetPasswordStatus}
        changePassword={changePassword}
        goToLogin={goToLogin}
      />
    ) : (
      <RequestResetPasswordContainer
        globalStyling={globalStyling}
        resetRequestStatus={state.resetRequestStatus}
        translations={config.translations}
        goToLogin={goToLogin}
        submitResetRequest={submitResetRequest}
      />
    );
  };
}

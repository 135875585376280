// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import Pagination from './Pagination';
import GridView from './GridView';
import ListView from './ListView';
import Spinner from '../Spinner';

const Results = styled.div`
  width: ${props => (props.filtersExpanded ? '97.5%' : '100%')};
  ${props => props.filtersExpanded && 'margin-left: 2.5%'};
  text-align: left;
`;

const SpinnerSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
`;

type Props = {
  translations: Object,
  results: Object,
  onMoreInfoClick: Function,
  onShowAllResultsClick: Function,
  onPageNumberChange: Function,
  selectedView: 'GRID' | 'LIST',
  filtersExpanded: boolean,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  getCompareIconColour: Function,
  globalStyling: Object,
  tilesVisibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  headerItemFont: Object,
  showGridTiles: boolean,
  headerItemFont: Object,
  badgeStyling: Object,
  financeHeadingFont: Object,
  showFinancePopup: boolean,
  infiniteScroll: boolean,
  currentPage: number,
  totalResults: number,
  pageSize: number,
  countDivMargin: boolean,
  compareShortlistIconWidth: number,
};

const resultsToDisplay = (results, currentPage, infiniteScroll) =>
  infiniteScroll
    ? Object.entries(results)
        .sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
        .reduce((previous, entry) => [...previous, ...entry[1]], [])
    : results[currentPage];

const totalResultsLoaded = results =>
  Object.values(results).reduce(
    (previous, value) => [...previous, ...value],
    [],
  ).length;

export default function VehicleResults({
  results,
  onMoreInfoClick,
  translations,
  onShowAllResultsClick,
  onPageNumberChange,
  selectedView,
  filtersExpanded,
  compareVehicle,
  shortlistVehicle,
  getShortListedIconColour,
  getCompareIconColour,
  globalStyling,
  tilesVisibility,
  locale,
  vehicleModelTitleFontWeight,
  headerItemFont,
  showGridTiles,
  badgeStyling,
  financeHeadingFont,
  showFinancePopup,
  infiniteScroll,
  currentPage,
  totalResults,
  pageSize,
  countDivMargin,
  compareShortlistIconWidth,
}: Props) {
  return totalResults ? (
    <Results filtersExpanded={filtersExpanded}>
      {selectedView === 'GRID' ? (
        <GridView
          results={resultsToDisplay(results, currentPage, infiniteScroll)}
          translations={translations}
          onMoreInfoClick={onMoreInfoClick}
          compareVehicle={compareVehicle}
          shortlistVehicle={shortlistVehicle}
          getShortListedIconColour={getShortListedIconColour}
          getCompareIconColour={getCompareIconColour}
          filtersExpanded={filtersExpanded}
          globalStyling={globalStyling}
          visibility={tilesVisibility || {}}
          locale={locale}
          vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
          headerItemFont={headerItemFont}
          showGridTiles={showGridTiles}
          badgeStyling={badgeStyling}
          financeHeadingFont={financeHeadingFont}
          showFinancePopup={showFinancePopup}
          countDivMargin={countDivMargin}
          compareShortlistIconWidth={compareShortlistIconWidth}
        />
      ) : (
        <ListView
          results={resultsToDisplay(results, currentPage, infiniteScroll)}
          translations={translations}
          onMoreInfoClick={onMoreInfoClick}
          filtersExpanded={filtersExpanded}
          compareVehicle={compareVehicle}
          shortlistVehicle={shortlistVehicle}
          getShortListedIconColour={getShortListedIconColour}
          getCompareIconColour={getCompareIconColour}
          globalStyling={globalStyling}
          visibility={tilesVisibility || {}}
          locale={locale}
          vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
          headerItemFont={headerItemFont}
          showGridTiles={showGridTiles}
          badgeStyling={badgeStyling}
          showFinancePopup={showFinancePopup}
        />
      )}
      {!infiniteScroll ? (
        <Pagination
          pageSize={pageSize}
          currentPage={currentPage}
          totalResults={totalResults}
          onShowAllResultsClick={onShowAllResultsClick}
          onPageNumberChange={onPageNumberChange}
          translations={translations}
          globalStyling={globalStyling}
        />
      ) : (
        totalResults > totalResultsLoaded(results) && (
          <Fragment>
            <SpinnerSection>
              <Spinner colour={globalStyling.colours.primaryBrandColour} />
            </SpinnerSection>
          </Fragment>
        )
      )}
    </Results>
  ) : (
    <Results> {translations.noSearchResults} </Results>
  );
}

// @flow
import React from 'react';
import styled, { keyframes } from 'styled-components';
import IoLoadC from 'react-icons/lib/io/load-c';
import type { Theme } from '../../theme';

type Props = {
  visible: boolean,
  size: number,
};

type ThemeProps = Props & { theme: Theme };

const spin = keyframes`
from {transform: rotate(0deg);}
to {transform: rotate(359deg);}
`;

const Container = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const Spinner = styled(IoLoadC)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${(props: ThemeProps) => `${props.theme.colours.connectAutoBlue}`};
  animation: ${spin} 0.6s infinite linear;
`;

export default function Loader({ visible, size }: Props) {
  return (
    <Container visible={visible}>
      <Spinner size={size} />
    </Container>
  );
}

Loader.defaultProps = {
  size: 20,
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Location({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 25">
        <path d="M21.195 11.634a7.721 7.721 0 0 0-6.83-6.818V3h-1.72v1.816a7.558 7.558 0 0 0-6.83 6.818H4v1.732h1.816a7.721 7.721 0 0 0 6.829 6.818V22h1.72v-1.816a7.721 7.721 0 0 0 6.83-6.818h1.816v-1.732h-1.816zM13.5 18.548a6.048 6.048 0 1 1 6.059-6.048 6.017 6.017 0 0 1-6.059 6.048z" />
      </svg>
    </IconWrapper>
  );
}

Location.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

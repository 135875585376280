import Icon from '../../modules/ExploreRange/Icon';

export { id as reducerId } from '../../modules/ExploreRange/reducer';

export { default as component } from './ExploreRange';
export { default as effects } from '../../modules/ExploreRange/effects';
export { default as config } from '../../modules/ExploreRange/config';
export {
  default as defaultConfig,
} from '../../modules/ExploreRange/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/ExploreRange/reducer';

export const name = 'Explore Range';
export const id = 'ExploreRange';
export const availableToAdministration = false;
export const description = 'Explore Vehicle Range';
export const icon = Icon;

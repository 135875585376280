// @flow

import React from 'react';
import styled from 'styled-components';

import { AccordionList } from 'cms-ui';
import { SectionIcon, ConfigRow } from '.';

const Section = (
  fields,
  config,
  data,
  listIndex,
  show,
  onFieldChange,
  actions,
  siteId,
) =>
  fields.map(({ name, ids, datatype, props }) => (
    <ConfigRow
      {...{
        name,
        ids,
        datatype,
        data,
        props,
        config,
        onFieldChange,
        actions,
        siteId,
        show: show === listIndex,
      }}
    />
  ));

const TitleContents = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  height: 1em;
  margin-right: 10px;
`;

type IconProps = { src: string };
const IconOrNull = ({ src }: IconProps) =>
  src ? (
    <IconWrapper>
      <SectionIcon icon={src} />
    </IconWrapper>
  ) : null;

const StackedIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: ${({ theme }) => `${theme.layout.halfPadding}`};
`;

const disableFilter = `brightness(0) saturate(100%) invert(94%) sepia(13%) saturate(0%) hue-rotate(188deg) brightness(93%) contrast(85%)`;
const primaryFilter = `brightness(0) saturate(100%) invert(25%) sepia(96%) saturate(1892%) hue-rotate(200deg) brightness(94%) contrast(105%)`;

const Icon = styled.img`
  height: 16px;
  filter: ${({ disable }) => (disable ? disableFilter : primaryFilter)};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`;

type Props = {
  data: any,
  index: number,
  config: any,
  fields: any[],
  show: number,
  id: string,
  max: number,
  show: number,
  icon: any,
  sectionLabel: string,
  buttonLabel: string,
  sections: any[],
  onAddSection: Function,
  onDeleteSection: Function,
  onToggleSection: Function,
  onFieldChange: Function,
  onListReOrder: Function,
  actions: any,
  siteId: string,
};

export default function ConfigureList({
  data,
  config,
  fields,
  id,
  max,
  show,
  icon,
  sectionLabel,
  buttonLabel,
  onAddSection,
  onDeleteSection,
  onToggleSection,
  onFieldChange,
  onListReOrder,
  actions,
  siteId,
}: Props) {
  const moveItem = (index, newIndex) => {
    const newOrder = [...config.items];
    [newOrder[index], newOrder[newIndex]] = [
      newOrder[newIndex],
      newOrder[index],
    ];
    onListReOrder('items', newOrder);
  };

  const handleClick = (listIndex, newIndex) => {
    if (show === listIndex) {
      onToggleSection(config.id, newIndex);
    }
    moveItem(listIndex, newIndex);
  };

  return (
    <AccordionList
      items={config[id].map((item, listIndex) => {
        const listLength = config[id].length;
        return {
          titleContents: (
            <TitleContents>
              <StackedIconWrapper>
                <Icon
                  src="https://res.cloudinary.com/motortrak/image/upload/v1573740612/editor/band-arrow-up.svg"
                  alt="Up Button"
                  disable={listIndex === 0}
                  onClick={() => {
                    if (listIndex !== 0) handleClick(listIndex, listIndex - 1);
                  }}
                  role="button"
                />
                <Icon
                  src="https://res.cloudinary.com/motortrak/image/upload/v1573740612/editor/band-arrow-down.svg"
                  alt="Down Button"
                  disable={listLength === listIndex + 1}
                  onClick={() => {
                    if (listLength !== listIndex + 1)
                      handleClick(listIndex, listIndex + 1);
                  }}
                  role="button"
                />
              </StackedIconWrapper>
              <IconOrNull src={icon} />
              {item.listTitle
                ? item.listTitle
                : `${sectionLabel} ${listIndex + 1}`}
            </TitleContents>
          ),
          bodyContents: Section(
            fields,
            config[id][listIndex],
            data,
            listIndex,
            show,
            (key, value) => onFieldChange(id, listIndex, key, value),
            actions,
            siteId,
          ),
          open: show === listIndex,
        };
      })}
      onAdd={onAddSection}
      addText={buttonLabel}
      onDelete={i => onDeleteSection(config[id][i].id)}
      onToggle={i => {
        onToggleSection(config.id, i);
      }}
      disableOn={items => items.length >= max}
    />
  );
}

// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { CheckboxSwitch, FieldLabel } from 'cms-ui';
import { type FieldProps } from '../models/fields';
import Media from './Media';

const upload = 'video';
const youtube = 'youtube';
const image = 'image';

const ComponentTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const LabelContainer = styled.div`
  padding-top: 10px;
`;

const mediaTypesMap = {
  [upload]: <option value={upload}>Video</option>,
  [youtube]: <option value={youtube}>YouTube</option>,
  [image]: <option value={image}>Image</option>,
};

export default class MediaPicker extends Component<FieldProps, *> {
  onComponentTypeChange = event => {
    const {
      onChange,
      values: { media },
      ids: { media: id },
    } = this.props;
    const componentType = event.target.value;
    onChange(id, { ...media, componentType, src: null });
  };

  onVideoSrcChange = event => {
    const {
      onChange,
      values: { media },
      ids: { media: id },
    } = this.props;
    onChange(id, { ...media, src: event.target.value });
  };

  onSoundChange = event => {
    const {
      onChange,
      values: { media },
      ids: { media: id },
    } = this.props;
    onChange(id, { ...media, muted: event.target.value === 'true' });
  };

  onAutoPlayChange = event => {
    const {
      onChange,
      values: { media },
      ids: { media: id },
    } = this.props;

    onChange(id, { ...media, autoplay: !(event.target.value === 'true') });
  };

  render() {
    const {
      values,
      values: { media },
      ids,
      onChange,
      data,
      actions,
      mediaTypes,
      hintText,
    } = this.props;
    return (
      <div>
        <ComponentTypeWrapper>
          <div>Media Type</div>
          <select
            onChange={this.onComponentTypeChange}
            value={media.componentType}
          >
            {mediaTypes.map(m => mediaTypesMap[m])}
          </select>
        </ComponentTypeWrapper>
        {(() => {
          switch (media.componentType) {
            case upload:
              return (
                <>
                  <Media
                    values={values}
                    ids={ids}
                    onChange={onChange}
                    data={data}
                    actions={actions}
                    hintText={hintText}
                    type="video"
                  />
                  <LabelContainer>
                    <FieldLabel label="Sound" inputFlexMode="0">
                      <CheckboxSwitch
                        name="sound-toggle"
                        onChange={this.onSoundChange}
                        value={!(media.muted === undefined || media.muted)}
                      />
                    </FieldLabel>
                    <FieldLabel label="Auto-play" inputFlexMode="0">
                      <CheckboxSwitch
                        name="Auto-play-toggle"
                        onChange={this.onAutoPlayChange}
                        value={media.autoplay}
                      />
                    </FieldLabel>
                  </LabelContainer>
                </>
              );
            case youtube:
              return (
                <>
                  <input
                    type="text"
                    placeholder="YouTube video id"
                    onChange={this.onVideoSrcChange}
                    value={media.src}
                  />
                  <LabelContainer>
                    <FieldLabel label="Sound" inputFlexMode="0">
                      <CheckboxSwitch
                        name="sound-toggle"
                        onChange={this.onSoundChange}
                        value={!(media.muted === undefined || media.muted)}
                      />
                    </FieldLabel>
                    <FieldLabel label="Auto-play" inputFlexMode="0">
                      <CheckboxSwitch
                        name="Auto-play-toggle"
                        onChange={this.onAutoPlayChange}
                        value={media.autoplay}
                      />
                    </FieldLabel>
                  </LabelContainer>
                </>
              );
            case image:
              return (
                <Media
                  values={values}
                  ids={ids}
                  onChange={onChange}
                  data={data}
                  actions={actions}
                  hintText={hintText}
                  type="image"
                />
              );
            default:
              return <div>Unknown media type</div>;
          }
        })()}
      </div>
    );
  }
}

// @flow
import type { Error } from '../types/error';
import type { Blog } from '../reducers/blogs';

const LoadBlogs: 'LOAD BLOGS' = 'LOAD BLOGS';
type LoadBlogsAction = { type: typeof LoadBlogs };
const loadBlogs = (): LoadBlogsAction => ({
  type: LoadBlogs,
});

const LoadBlogsSuccessful: 'LOAD BLOGS SUCCEEDED' = 'LOAD BLOGS SUCCEEDED';
type LoadBlogsSuccessfulAction = {
  type: typeof LoadBlogsSuccessful,
  payload: Blog[],
};

const loadBlogsSuccessful = (blogs: Blog[]): LoadBlogsSuccessfulAction => ({
  type: LoadBlogsSuccessful,
  payload: blogs,
});

const LoadBlogsFailure: 'LOAD BLOGS DATA FAILED' = 'LOAD BLOGS DATA FAILED';
type LoadBlogsFailureAction = { type: typeof LoadBlogsFailure, payload: Error };
const loadBlogsFailure = (error: Error): LoadBlogsFailureAction => ({
  type: LoadBlogsFailure,
  payload: error,
});

const SaveBlog: 'SAVE BLOG' = 'SAVE BLOG';
type SaveBlogAction = { type: typeof SaveBlog, payload: Blog };
const saveBlog = (blog: Blog): SaveBlogAction => ({
  type: SaveBlog,
  payload: blog,
});

const SaveBlogSuccessful: 'SAVE BLOG SUCCEEDED' = 'SAVE BLOG SUCCEEDED';
type SaveBlogSuccessfulAction = {
  type: typeof SaveBlogSuccessful,
  payload: Blog,
};

const saveBlogSuccessful = (blog: Blog): SaveBlogsSuccessfulAction => ({
  type: SaveBlogSuccessful,
  payload: blog,
});

const SaveBlogFailure: 'SAVE BLOG DATA FAILED' = 'SAVE BLOG DATA FAILED';
type SaveBlogFailureAction = { type: typeof SaveBlogsFailure, payload: Error };
const saveBlogFailure = (error: Error): SaveBlogsFailureAction => ({
  type: SaveBlogsFailure,
  payload: error,
});

const UpdateBlogInContext: 'UPDATE OF BLOG IN CONTEXT' =
  'UPDATE OF BLOG IN CONTEXT';
type UpdateBlogInContextAction = {
  type: typeof UpdateBlogInContext,
  payload: object,
};
const updateBlogInContext = (blog: Blog) => ({
  type: UpdateBlogInContext,
  payload: blog,
});

export const actions = {
  loadBlogs,
  loadBlogsSuccessful,
  loadBlogsFailure,
  saveBlog,
  saveBlogSuccessful,
  saveBlogFailure,
  updateBlogInContext,
};

export const constants = {
  LoadBlogs,
  LoadBlogsSuccessful,
  LoadBlogsFailure,
  SaveBlog,
  SaveBlogSuccessful,
  SaveBlogFailure,
  UpdateBlogInContext,
};

export type Action =
  | LoadBlogsAction
  | LoadBlogsSuccessfulAction
  | LoadBlogsFailureAction
  | UpdateBlogInContextAction
  | SaveBlogAction
  | SaveBlogSuccessfulAction
  | SaveBlogFailureAction;

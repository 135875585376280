// @flow
import React from 'react';
import { connect } from 'react-redux';
import TranslationUploaders from '../components/TranslationUploaders';
import { actions } from '../state/actions/translation';

const getLanguageIso = (languages, id) =>
  (languages.find(l => l.id === id) || {}).isoCode;
const getDefaultLanguageIso = config =>
  getLanguageIso(config.languages, config.defaultLanguageId);

const mapStateToProps = ({ translation, site }) => ({
  translations: translation.translations,
  siteId: site.currentSiteId,
  siteDefaultLanguageIso: getDefaultLanguageIso(
    site.sites[site.currentSiteId].configuration,
  ),
});

const mapDispatchToProps = dispatch => ({
  stageFileForImport: id => file =>
    dispatch(actions.stageFileForImport(id, file)),
  importFile: (siteId, transitionId, defaultIso, iso) => file =>
    dispatch(
      actions.importTranslationFile(
        siteId,
        transitionId,
        file,
        defaultIso,
        iso,
        new Date(),
      ),
    ),
  exportFile: (siteId, transitionId, defaultIso, iso) => () =>
    dispatch(
      actions.exportTranslationFile(siteId, transitionId, defaultIso, iso),
    ),
  loadTranslations: siteId => dispatch(actions.loadTranslations(siteId)),
});

class TranslationUploadersWithLifeCycle extends React.Component<*, *> {
  componentDidMount = () => {
    this.props.loadTranslations(this.props.siteId);
  };

  render() {
    return <TranslationUploaders {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TranslationUploadersWithLifeCycle);

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../theme';
import {
  HeadingTwo,
  HeadingThree,
  fontStyleOverride,
  Paragraph,
} from './Global';
import { vehiclePropFormatter } from '../helpers/vehicle';

const FeaturedSpecRowBody = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.max.medium` flex-direction: column;`};
`;

const FeaturedSpecBody = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1 0 0%;
  ${({ featuredSpec }) =>
    featuredSpec && featuredSpec.borderColour
      ? `border-bottom: 1px solid ${featuredSpec.borderColour.value}`
      : 'border-bottom: 1px solid #ACACAC;'};

  ${media.max.medium`
    margin: 0;
  `};
`;

const DataRow = ({ featuredSpec, label, value, capitaliseValue }) =>
  label && value ? (
    <FeaturedSpecRowBody>
      <FeaturedSpecBody>
        <HeadingThree
          styleOverride={() => `
          ${fontStyleOverride(featuredSpec.infoLabelFont)}
        `}
        >
          {label}
        </HeadingThree>
        <HeadingThree
          styleOverride={() => `
            ${fontStyleOverride(featuredSpec.infoValueFont)}
            ${capitaliseValue && 'text-transform: capitalize;'}
        `}
        >
          {value}
        </HeadingThree>
      </FeaturedSpecBody>
    </FeaturedSpecRowBody>
  ) : null;

type Props = {
  vehicleInfo: Object,
  translations: Object,
  featuredSpec: Object,
};

function LegacyVdpFeaturedSpecification({
  vehicleInfo,
  translations,
  featuredSpec,
}: Props) {
  return (
    <Fragment>
      <HeadingTwo
        styleOverride={() => `
          ${fontStyleOverride(featuredSpec.title)}
        `}
      >
        {translations.vdpSpecificationHeader}
      </HeadingTwo>

      <DataRow
        featuredSpec={featuredSpec}
        label={translations.model}
        value={vehicleInfo.modelGroupName}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.powerOutput}
        value={vehicleInfo.powerOutput}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.variant}
        value={vehicleInfo.modelVariant}
        capitaliseValue
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.engine}
        value={vehicleInfo.specification.engine}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.registration}
        value={vehicleInfo.registration}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.exterior}
        value={vehicleInfo.specification.exterior}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.interior}
        value={vehicleInfo.specification.interior}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.display}
        value={vehicleInfo.specification.odometer.display}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.transmission}
        value={vehicleInfo.specification.transmission}
      />
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.fuelType}
        value={vehicleInfo.specification.fuelType}
      />
      {!featuredSpec.hideCapacity && (
        <DataRow
          featuredSpec={featuredSpec}
          label={translations.engine}
          value={`${vehicleInfo.specification.capacity} ${
            translations.engineUnit ? translations.engineUnit : ''
          }`}
        />
      )}
      <DataRow
        featuredSpec={featuredSpec}
        label={translations.bodystyle}
        value={vehicleInfo.specification.bodystyle}
      />
    </Fragment>
  );
}

const ConfigVdpFeaturedSpecification = ({
  vehicleInfo,
  translations,
  featuredSpec,
  locale,
  dataConfig,
}: Props) => (
  <Fragment>
    <HeadingTwo
      styleOverride={() => `
        ${fontStyleOverride(featuredSpec.title)}
      `}
    >
      {translations.vdpSpecificationHeader}
    </HeadingTwo>

    {dataConfig.map(data => (
      <DataRow
        featuredSpec={featuredSpec}
        label={translations[data.translationProp]}
        value={vehiclePropFormatter(data, vehicleInfo, locale)}
        key={data.prop}
      />
    ))}
    {translations.specificationsFootnote && (
      <Paragraph
        styleOverride={() =>
          `${fontStyleOverride(featuredSpec.infoValueFont)}; 
            margin-bottom: 0; 
            padding-bottom: 0;`
        }
      >
        {translations.specificationsFootnote}
      </Paragraph>
    )}
  </Fragment>
);

function VdpFeaturedSpecification({
  vehicleInfo,
  translations,
  featuredSpec,
  locale,
  dataConfig,
}: Props) {
  const FeatureSpecification = dataConfig
    ? ConfigVdpFeaturedSpecification
    : LegacyVdpFeaturedSpecification;
  return (
    <FeatureSpecification
      vehicleInfo={vehicleInfo}
      translations={translations}
      featuredSpec={featuredSpec}
      locale={locale}
      dataConfig={dataConfig}
    />
  );
}

export default VdpFeaturedSpecification;

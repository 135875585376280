// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Magnify({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 31 31" width="1em" height="1em">
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" d="M8.238 11.65h9.12v1.536h-9.12z" />
          <path fill="#FFF" d="M13.558 7.81v9.215h-1.52V7.81z" />
          <path
            d="M2.606 12.361c0-5.457 4.44-9.898 9.898-9.898 5.458 0 9.898 4.44 9.898 9.898 0 5.458-4.44 9.898-9.898 9.898-5.457 0-9.898-4.44-9.898-9.898zm28.31 16.32l-9.244-9.244a11.526 11.526 0 0 0 2.421-7.076c0-6.39-5.199-11.588-11.589-11.588C6.114.773.916 5.97.916 12.36c0 6.39 5.199 11.589 11.588 11.589 2.663 0 5.117-.905 7.076-2.42l9.243 9.243 2.093-2.093z"
            fill="#FEFEFE"
          />
        </g>
      </svg>
    </IconWrapper>
  );
}

Magnify.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

import { put, select, call, takeEvery } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { post } from '../../helpers/http';
import {
  avlCustomersServiceUrl,
  getAVLConfig,
} from '../../shared/selectors/settings';

export function* requestPasswordReset(action) {
  const {
    module: { page, instance },
    payload,
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer/reset-password-request`;
    const { siteId } = yield select(getAVLConfig);
    yield call(post, {
      url,
      data: {
        ...payload,
        siteId,
      },
    });
    yield put(actions.requestPasswordResetSuccess(page, instance));
  } catch (error) {
    yield put(actions.requestPasswordResetFailure(page, instance));
  }
}

export function* resetPassword(action) {
  const {
    module: { page, instance },
    payload,
  } = action;
  try {
    const baseUrl = yield select(avlCustomersServiceUrl);
    const url = `${baseUrl}customer/reset-password`;
    const { siteId } = yield select(getAVLConfig);
    yield call(post, {
      url,
      data: {
        ...payload,
        siteId,
      },
    });
    yield put(actions.resetPasswordSuccess(page, instance));
  } catch (error) {
    yield put(actions.resetPasswordFailure(page, instance));
  }
}

function* ResetPasswordSaga() {
  yield takeEvery(constants.REQUEST_PASSWORD_RESET, requestPasswordReset);
  yield takeEvery(constants.RESET_PASSWORD, resetPassword);
}

export default ResetPasswordSaga;

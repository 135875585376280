// @flow
import React from 'react';
import styled from 'styled-components';

const Hr = styled.div`
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
`;

export default function Line() {
  return <Hr />;
}

// @flow
import React from 'react';
import styled from 'styled-components';
import UsersList from './UsersList';
import AddUser from './AddUser';
import type { Theme } from '../theme';
import type { User, Role } from '../state/types/users';

type Props = {
  users: User[],
  removeUser: User => void,
  roles: Role[],
  selectedUserRole: Role,
  onSelectedUserRoleChanged: Role => void,
  addUserIfValid: User => void,
  emailAddress: string,
  onEmailAddressChanged: string => void,
  error: string,
};

type ThemeProps = { theme: Theme };

const Container = styled.div.withConfig({ displayName: 'Container' })`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }: ThemeProps) => `${theme.layout.doublePadding}`};
}

  > * {
    margin-bottom: ${({ theme }: ThemeProps) =>
      `${theme.layout.standardPadding}`};
  }
`;

const Header = styled.h2`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeHeader}`};
`;

export default function UsersEditor({
  users,
  removeUser,
  roles,
  addUserIfValid,
  emailAddress,
  onEmailAddressChanged,
  selectedUserRole,
  onSelectedUserRoleChanged,
  error,
}: Props) {
  return (
    <Container>
      <Header>Manage Users</Header>
      <AddUser
        roles={roles}
        addUserIfValid={addUserIfValid}
        emailAddress={emailAddress}
        onEmailAddressChanged={onEmailAddressChanged}
        selectedUserRole={selectedUserRole}
        onSelectedUserRoleChanged={onSelectedUserRoleChanged}
        error={error}
      />
      <UsersList users={users} removeUser={removeUser} />
    </Container>
  );
}

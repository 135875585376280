// @flow
import styled from 'styled-components';
import media from '../../../theme';

export default styled.div`
  width: 100%;
  background-image: url(${props => props.src});
  background-size: ${props => props.backgroundSize || 'cover'};
  background-repeat: no-repeat;
  background-position: center;
  min-height: ${props => props.imageHeight || 300}px;

  ${media.max.medium`
    padding-bottom: 75%;
  `};
`;

// @flow
import React from 'react';
import styled from 'styled-components';

import SearchResultBlock from '../SearchResultBlock';
import SearchResultGrid from '../SearchResultGridBlock';

const Results = styled.div`
  width: 100%;
`;

type Props = {
  results: Object,
  translations: Object,
  onMoreInfoClick: Function,
  filtersExpanded: boolean,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  getCompareIconColour: Function,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  headerItemFont: Object,
  showGridTiles: boolean,
  badgeStyling: Object,
  headerItemFont: Font,
  financeHeadingFont: Font,
  showFinancePopup: boolean,
  financeSubHeadingFont: Font,
};
export default function ListView({
  results,
  translations,
  onMoreInfoClick,
  filtersExpanded,
  compareVehicle,
  shortlistVehicle,
  getShortListedIconColour,
  getCompareIconColour,
  globalStyling,
  visibility,
  locale,
  vehicleModelTitleFontWeight,
  headerItemFont,
  showGridTiles,
  badgeStyling,
  financeHeadingFont,
  showFinancePopup,
  financeSubHeadingFont,
}: Props) {
  return (
    <Results>
      {results.map(vehicle =>
        showGridTiles ? (
          <SearchResultGrid
            key={vehicle.id}
            vehicle={vehicle}
            placeholdingImage={results.placeholdingImage}
            translations={translations}
            onMoreInfoClick={onMoreInfoClick}
            filtersExpanded={filtersExpanded}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
            shortListedIconColour={getShortListedIconColour(vehicle.id)}
            compareIconColour={getCompareIconColour(vehicle.id)}
            globalStyling={globalStyling}
            visibility={visibility}
            locale={locale}
            vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
            badgeStyling={badgeStyling}
            headerItemFont={headerItemFont}
          />
        ) : (
          <SearchResultBlock
            key={vehicle.id}
            vehicle={vehicle}
            placeholdingImage={results.placeholdingImage}
            translations={translations}
            onMoreInfoClick={onMoreInfoClick}
            vehiclePriceSectionWidth={filtersExpanded ? 40 : 25}
            displayImage={!filtersExpanded}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
            shortListedIconColour={getShortListedIconColour(vehicle.id)}
            compareIconColour={getCompareIconColour(vehicle.id)}
            globalStyling={globalStyling}
            visibility={visibility}
            locale={locale}
            vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
            badgeStyling={badgeStyling}
            headerItemFont={headerItemFont}
            financeHeadingFont={financeHeadingFont}
            showFinancePopup={showFinancePopup}
            financeSubHeadingFont={financeSubHeadingFont}
          />
        ),
      )}
    </Results>
  );
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Text, Image, Overlay, TextWrapper } from '../../components';

import type { IBlogPostConfig, IPostdata } from '../types';

type Props = {
  config: IBlogPostConfig,
  postData: IPostdata,
};

export default ({
  config,
  config: { imageBehaviour, imagePosition, imageHeight },
  postData: {
    translations: { headlineText, bodyText, imageLabel },
    image: { src },
    globalStyling: { uiElements = {} } = {},
    headlineFont,
    padding,
    verticalAlignment,
    textPadding,
    dispatch,
    ...overlayConfig
  },
}: Props) => {
  return (
    <div>
      <Image
        {...{ src, imageBehaviour, imagePosition, imageHeight }}
        role="img"
        aria-label={imageLabel}
      >
        <Overlay
          {...{
            ...overlayConfig,
            headlineText,
            verticalAlignment,
            padding,
            headlineFont,
            uiElements,
            dispatch,
          }}
        />
      </Image>
      <TextWrapper padding={textPadding}>
        <Text config={{ ...config, bodyText }} />
      </TextWrapper>
    </div>
  );
};

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import MyAccountHeading from './MyAccountHeading';
import Print from '../JaguarIcons/VDP/Print';
import { Button } from '../Global';
import media from '../../theme';
import { localiseCurrency } from '../../shared/localisation/numbers';
import Spinner from '../Spinner';

const DesktopView = styled.div`
  display: block;
  width: 83%;
  ${media.max.medium`
    display: none;
  `};
`;

const MobileView = styled.div`
  display: none;
  ${media.max.medium`
    display: flex;
    flex-direction: column;
  `};
`;

const ActionButton = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:first-child {
    border-bottom: 1px solid #dedede;
  }
`;
const ActionButtonText = styled.div`
  padding-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #343434;
`;

const MyAccountContent = styled.div`
  background-color: #f0f0f0;
  color: #000000;
  height: auto;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
`;

const TileItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  ${media.min.large`
    flex-direction: row;
    height: 220px;
  `};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.min.large`
    width: 50%;
    padding: 10px;
  `};
`;

const HeadingContainer = styled.div`
  margin-left: 15px;
  margin-right: 10px;
  padding: 10px;
  ${media.min.large`
    padding: 0;
  `};
`;

const ModelName = styled.div`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  &:hover {
    cursor: pointer;
  }
`;

const SubHeading = styled.div`
  font-size: 15px;
  color: #7e7e7e;
  line-height: 30px;
  text-transform: uppercase;
  font-size: 14px;
`;

const BottomContainer = styled.div`
  ${media.min.large`
    width: 20%;
    margin: auto;
  `};
`;

const InfoContainer = styled.div`
  padding: 10px 0;
  display: flex;
`;

const TableWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.8rem;
  margin: 0.5rem;
  line-height: 1.5;
  flex: 1 1 auto;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`;

const TableCol = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0.5em;
  word-break: break-word;
  width: 100%;
  white-space: nowrap;
  width: 100%
  &:first-child {
    border-right: 1px solid #dedede;
    ${media.min.large`
      margin-right: 40px;
    `};
  }
  &:last-child {
    border-left: 1px solid #dedede;
    ${media.min.large`
      padding-left: 40px;
    `};
  }
`;

const DealValue = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-size: 20px;
`;

const DealText = styled.div`
  font-size: 14px;
  color: #999999;
`;

const ImageContainer = styled.div`
  width: auto;
  height: 100%;
  position: relative;
  ${media.min.large`
    width: 25%;
    &:hover{
      cursor: pointer;
    }
  `};
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  ${media.min.large`
    flex-direction: column;
    padding: 0;
  `};
`;

const HorizontalLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-top: 1px solid #dedede;
  justify-content: center;
`;

const VerticalLine = styled.div`
  display: block;
  height: 220px;
  border: 0;
  border-left: 1px solid #dedede;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 1%;
`;

const ImageOverlay = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const ExpiredSection = styled.div`
  color: ${props => (props.colour ? `${props.colour}` : '#fe0000')};
`;

type Deal = {
  vehicle: Object,
  finance: Object,
};

export type PrintDeal = Deal & { translations: Object };

export type MyDeal = {
  myDeal: Deal,
  translations: Object,
  globalStyling: Object,
  goToVdp: (vehicle: Object) => void,
  deleteMyDeal: (vehicle: Object) => void,
  printMyDeal: (deal: PrintDeal) => void,
};

type Props = {
  myDeals: MyDeal[],
  translations: Object,
  goToVdp: (vehicle: Object) => void,
  deleteMyDeal: (vehicle: Object) => void,
  printMyDeal: (deal: PrintDeal) => void,
  iconColour?: string,
  globalStyling: Object,
  printing: Array,
};

function hasExpired(validUntil) {
  const today = new Date();
  const validUntilDay = new Date(validUntil);
  return !validUntil || today.getTime() - validUntilDay.getTime() >= 0;
}

const MyDealsInfo = ({
  vehicle,
  finance,
  placeholdingImage,
  translations,
  goToVdp,
  deleteMyDeal,
  printMyDeal,
  iconColour,
  globalStyling,
  expired,
  printing,
  pdfConfig,
}: MyDeals) => (
  <TileItemContainer>
    <ImageContainer
      onClick={() => goToVdp({ ...vehicle, finance: expired ? null : finance })}
    >
      {vehicle.images && vehicle.images[0] ? (
        <Image src={vehicle.images[0]} alt={vehicle.images[0]} />
      ) : (
        <Image src={placeholdingImage} alt={placeholdingImage} />
      )}
      {vehicle.status === 'sold' && (
        <ImageOverlay>
          <SoldHeading>{translations.sold}</SoldHeading>
          <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
        </ImageOverlay>
      )}
    </ImageContainer>
    <DetailsContainer>
      <HeadingContainer>
        {expired && (
          <ExpiredSection
            colour={
              globalStyling.colours.primaryBrandColour &&
              globalStyling.colours.primaryBrandColour.value
            }
          >
            {translations.myAccountDealHasExpired}
          </ExpiredSection>
        )}
        <ModelName
          onClick={() =>
            goToVdp({ ...vehicle, finance: expired ? null : finance })
          }
        >
          {vehicle.year} {vehicle.model}
        </ModelName>
        <SubHeading>
          {[
            vehicle.specification &&
              vehicle.specification.odometer &&
              vehicle.specification.odometer.display,
            vehicle.specification && vehicle.specification.engine,
            vehicle.specification && vehicle.specification.transmission,
            vehicle.specification && vehicle.specification.fuel,
          ]
            .filter(Boolean)
            .join(' | ')}
        </SubHeading>
      </HeadingContainer>
      <InfoContainer>
        <TableWrap>
          <TableRow>
            <TableCol>
              <DealValue>
                {localiseCurrency(
                  finance.vehiclePrice,
                  'es-ES',
                  finance.currency,
                  0,
                )}
              </DealValue>
              <DealText>{translations.myAccountDealPrice}</DealText>
            </TableCol>
            {finance.monthlyPrice && (
              <TableCol>
                <DealValue>
                  {localiseCurrency(
                    parseFloat(
                      finance.monthlyPrice.replace('.', '').replace(',', '.'),
                    ),
                    'es-ES',
                    finance.currency,
                    2,
                  )}
                </DealValue>
                <DealText>{translations.myAccountDealMonthlyPrice}</DealText>
              </TableCol>
            )}
            <TableCol>
              <DealValue>{finance.apr}%</DealValue>
              <DealText>{translations.myAccountDealAPR}</DealText>
            </TableCol>
          </TableRow>
        </TableWrap>
      </InfoContainer>
    </DetailsContainer>
    <VerticalLine />
    <BottomContainer>
      <ButtonContainer>
        {vehicle.status !== 'sold' && (
          <Button
            styleOverride={() => `
            margin: 5px 0;`}
            onClick={() =>
              goToVdp({ ...vehicle, finance: expired ? null : finance })
            }
            text={
              expired
                ? translations.myAccountDealUpdate
                : translations.myAccountDealEdit
            }
            applyStyle="secondary"
            buttonStyle={
              globalStyling.uiElements.secondaryButton &&
              globalStyling.uiElements.secondaryButton.buttonStyle
            }
          />
        )}
        <Button
          styleOverride={() => `
          margin: 5px 0;`}
          onClick={() => deleteMyDeal(vehicle)}
          text={translations.myAccountDealDelete}
          applyStyle="secondary"
          buttonStyle={
            globalStyling.uiElements.secondaryButton &&
            globalStyling.uiElements.secondaryButton.buttonStyle
          }
        />
        {vehicle.status !== 'sold' && !expired && (
          <ActionButton
            data-qa-hook="print-my-deal-button"
            onClick={() =>
              !printing.includes(vehicle.id) &&
              printMyDeal({ vehicle, finance, pdfConfig })
            }
          >
            {printing.includes(vehicle.id) ? (
              <Fragment>
                <Spinner colour={{ value: iconColour }} />
                <ActionButtonText>{translations.printingDeal}</ActionButtonText>
              </Fragment>
            ) : (
              <Fragment>
                <Print width="2em" height="2em" colour={iconColour} />
                <ActionButtonText>
                  {translations.printDealLabel}
                </ActionButtonText>
              </Fragment>
            )}
          </ActionButton>
        )}
      </ButtonContainer>
    </BottomContainer>
  </TileItemContainer>
);

const MyDealsInfoMobile = ({
  vehicle,
  placeholdingImage,
  finance,
  translations,
  goToVdp,
  deleteMyDeal,
  printMyDeal,
  iconColour,
  globalStyling,
  expired,
  pdfConfig,
}: MyDeals) => (
  <TileItemContainer>
    <HeadingContainer>
      {expired && (
        <ExpiredSection
          colour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
        >
          {translations.myAccountDealHasExpired}
        </ExpiredSection>
      )}
      <ModelName
        onClick={() =>
          goToVdp({ ...vehicle, finance: expired ? null : finance })
        }
      >
        {vehicle.year} {vehicle.model}
      </ModelName>
      <SubHeading>
        {[
          vehicle.specification &&
            vehicle.specification.odometer &&
            vehicle.specification.odometer.display,
          vehicle.specification && vehicle.specification.engine,
          vehicle.specification && vehicle.specification.transmission,
          vehicle.specification && vehicle.specification.fuel,
        ]
          .filter(Boolean)
          .join(' | ')}
      </SubHeading>
    </HeadingContainer>
    <ImageContainer onClick={() => goToVdp({ ...vehicle, ...finance })}>
      {vehicle.images[0] ? (
        <Image src={vehicle.images[0]} alt={vehicle.images[0]} />
      ) : (
        <Image src={placeholdingImage} alt={placeholdingImage} />
      )}
      {vehicle.status === 'sold' && (
        <div>
          <ImageOverlay>
            <SoldHeading>{translations.sold}</SoldHeading>
            <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
          </ImageOverlay>
        </div>
      )}
    </ImageContainer>
    <BottomContainer>
      <InfoContainer>
        <TableWrap>
          <TableRow>
            <TableCol>
              <DealValue>
                {localiseCurrency(
                  finance.vehiclePrice,
                  'es-ES',
                  finance.currency,
                  0,
                )}
              </DealValue>
              <DealText>{translations.myAccountDealPrice}</DealText>
            </TableCol>
            {finance.monthlyPrice && (
              <TableCol>
                <DealValue>
                  {localiseCurrency(
                    parseFloat(
                      finance.monthlyPrice.replace('.', '').replace(',', '.'),
                    ),
                    'es-ES',
                    finance.currency,
                    2,
                  )}
                </DealValue>
                <DealText>{translations.myAccountDealMonthlyPrice}</DealText>
              </TableCol>
            )}
            <TableCol>
              <DealValue>{finance.apr}%</DealValue>
              <DealText>{translations.myAccountDealAPR}</DealText>
            </TableCol>
          </TableRow>
        </TableWrap>
      </InfoContainer>
      <HorizontalLine />
      <ButtonContainer>
        {vehicle.status !== 'sold' && (
          <Button
            mobileStyleOverride={() => `
            width: 45%;
            margin: 0 10px;`}
            onClick={() =>
              goToVdp({ ...vehicle, finance: expired ? null : finance })
            }
            text={
              expired
                ? translations.myAccountDealUpdate
                : translations.myAccountDealEdit
            }
            applyStyle="secondary"
            buttonStyle={
              globalStyling.uiElements.secondaryButton &&
              globalStyling.uiElements.secondaryButton.buttonStyle
            }
          />
        )}
        <Button
          mobileStyleOverride={() => `
          width: 45%;
          margin: 0 10px;`}
          onClick={() => deleteMyDeal(vehicle)}
          text={translations.myAccountDealDelete}
          applyStyle="secondary"
          buttonStyle={
            globalStyling.uiElements.secondaryButton &&
            globalStyling.uiElements.secondaryButton.buttonStyle
          }
        />
      </ButtonContainer>
      {vehicle.status !== 'sold' && !expired && (
        <ActionButton
          data-qa-hook="print-my-deal-button"
          onClick={() => printMyDeal({ vehicle, finance, pdfConfig })}
        >
          <Print width="2em" height="2em" colour={iconColour} />
          <ActionButtonText>{translations.printDealLabel}</ActionButtonText>
        </ActionButton>
      )}
    </BottomContainer>
  </TileItemContainer>
);

export default function MyAccountDeals(props: Props) {
  const financeCellText = priceEntry =>
    localiseCurrency(priceEntry, 'es-ES', props.myDeals.finance.currency, 0) ||
    props.translations.searchResultsNotAvailableLabel;
  const goToVdpIfNotSold = vehicleAndFinance =>
    vehicleAndFinance.status !== 'sold' && props.goToVdp(vehicleAndFinance);

  const pdfConfig = {
    brandLogoUrl: props.brandLogoUrl,
    translations: props.translations,
    pdfLineColor: { value: props.iconColour },
  };
  return (
    <div>
      <MyAccountHeading
        heading={props.translations.myAccountDealsHeading}
        subHeading={props.translations.myAccountDealsSubheading}
      />
      <MyAccountContent>
        <DesktopView>
          {props.myDeals.map(myDeal => (
            <MyDealsInfo
              financeTranslations={props.finance.translations}
              key={myDeal.vehicle.id}
              translations={props.translations}
              goToVdp={goToVdpIfNotSold}
              deleteMyDeal={props.deleteMyDeal}
              printMyDeal={props.printMyDeal}
              financeCellText={financeCellText}
              iconColour={props.iconColour}
              globalStyling={props.globalStyling}
              expired={
                myDeal.finance ? hasExpired(myDeal.finance.validUntil) : true
              }
              printing={props.printing}
              pdfConfig={pdfConfig}
              {...myDeal}
            />
          ))}
        </DesktopView>
        <MobileView>
          {props.myDeals.map(myDeal => (
            <MyDealsInfoMobile
              financeTranslations={props.finance.translations}
              key={myDeal.vehicle.id}
              translations={props.translations}
              goToVdp={goToVdpIfNotSold}
              deleteMyDeal={props.deleteMyDeal}
              printMyDeal={props.printMyDeal}
              financeCellText={financeCellText}
              iconColour={props.iconColour}
              globalStyling={props.globalStyling}
              expired={
                myDeal.finance ? hasExpired(myDeal.finance.validUntil) : true
              }
              pdfConfig={pdfConfig}
              {...myDeal}
            />
          ))}
        </MobileView>
      </MyAccountContent>
    </div>
  );
}

MyAccountDeals.defaultProps = {
  iconColour: '#9E1B32',
};

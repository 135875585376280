// @flow
import React from 'react';
import styled from 'styled-components';
import type { Range } from './search-filters-helper';
import {
  localiseCurrency,
  localiseNumber,
} from '../../shared/localisation/numbers';

type Props = {
  range?: Range,
  unit?: string,
  heading?: string,
  locale?: string,
  unit?: string,
  id?: string,
};

const StyledRangeHeading = styled.h4`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 16px;
  color: #444;
`;

const WithSpaceRight = styled.span`
  padding-right: 5px;
`;

export default function RangeHeading({
  heading,
  range = { min: 0, max: 0 },
  locale,
  unit,
  id,
}: Props) {
  return (
    <StyledRangeHeading>
      <span>{heading}</span>
      <span data-qa-hook="range-values">
        <WithSpaceRight>
          {id == 'priceRange'
            ? localiseCurrency(range.min, locale, '', 0)
            : localiseNumber(range.min, locale)}
        </WithSpaceRight>
        <WithSpaceRight>-</WithSpaceRight>
        <WithSpaceRight>
          {id == 'priceRange'
            ? localiseCurrency(range.max, locale, '', 0)
            : localiseNumber(range.max, locale)}
        </WithSpaceRight>
        {id !== 'priceRange' ? <WithSpaceRight>{unit}</WithSpaceRight> : ''}
      </span>
    </StyledRangeHeading>
  );
}

RangeHeading.defaultProps = {
  heading: '',
  unit: '',
};

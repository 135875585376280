// @flow
import React from 'react';
import { IconWrapper } from '../../components';

export default function Icon({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 18 18" width="1em" height="1em">
        <g fill="none" fillRule="evenodd">
          <path fill="#D6D6D6" d="M2 15v-3h14v3z" />
          <path fill="#0065DB" d="M2 7h6v4H2z" />
          <path stroke="#0065DB" d="M.5 1.5h17v15H.5z" />
          <path fill="#0065DB" d="M10 7h6v4h-6z" />
          <path fill="#D6D6D6" d="M2 6V3h14v3z" />
        </g>
      </svg>
    </IconWrapper>
  );
}

Icon.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

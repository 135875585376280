// @flow

import React, { type Element } from 'react';
import styled from 'styled-components';

import { InputField } from 'cms-ui';
import Media from './Media';

import { type FieldProps } from '../models/fields';

const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};
`;

type DefaultIcon = {
  defaultIcon: string,
  key: string,
};

const defaultIcons: DefaultIcon[] = [
  {
    defaultIcon:
      'https://res.cloudinary.com/motortrak/image/upload/v1574934837/editor/facebook.svg',
    key: '1',
  },
  {
    defaultIcon:
      'https://res.cloudinary.com/motortrak/image/upload/v1574934837/editor/twitter.svg',
    key: '2',
  },
  {
    defaultIcon:
      'https://res.cloudinary.com/motortrak/image/upload/v1574934837/editor/linkedin.svg',
    key: '3',
  },
  {
    defaultIcon:
      'https://res.cloudinary.com/motortrak/image/upload/v1574934837/editor/youtube.svg',
    key: '4',
  },
  {
    defaultIcon:
      'https://res.cloudinary.com/motortrak/image/upload/v1574934837/editor/instagram.svg',
    key: '5',
  },
];

export default function SocialLinks({
  ids,
  values,
  data,
  actions,
  name,
  onChange,
}: FieldProps) {
  const { links: linksId, translations: translationsId } = ids;
  const { links, translations } = values;

  const getLink = key => links.find(link => link.key === key);
  const getDefaultIcon = key =>
    defaultIcons.find(icon => icon.key === key).defaultIcon;

  const removeDirtyLinks = allLinks =>
    allLinks
      .filter(
        ({ url, iconUrl, key } = {}) =>
          (iconUrl && iconUrl !== getDefaultIcon(key)) ||
          (iconUrl && url && url.length) ||
          (url && url.length),
      )
      .sort((a, b) => a.key - b.key);

  const mapLinks = (key, keyToUpdate, event) => {
    let newLinks;

    if (getLink(key)) {
      newLinks = links.map(link =>
        link.key === key
          ? {
              ...link,
              [keyToUpdate]:
                keyToUpdate === 'iconUrl'
                  ? event
                  : event.target.value.trimStart(),
            }
          : link,
      );
    } else {
      newLinks = [
        ...links,
        {
          ...(keyToUpdate !== 'iconUrl' && {
            [keyToUpdate]: event.target.value.trimStart(),
          }),
          iconUrl: keyToUpdate === 'iconUrl' ? event : getDefaultIcon(key),
          key,
        },
      ];
    }
    onChange(linksId, removeDirtyLinks(newLinks));
  };

  const urlInput = key => {
    const curLink = getLink(key);
    return {
      value: curLink ? curLink.url : '',
      placeholder: 'https://...',
      onChange: event => mapLinks(key, 'url', event),
    };
  };

  const altTextInput = key => ({
    value: translations[key],
    placeholder: 'Alternate Text',
    onChange: event => {
      onChange(translationsId, {
        ...translations,
        [key]: event.target.value.trimStart(),
      });
    },
  });

  const onMediaChange = (_, { src }, key) => mapLinks(key, 'iconUrl', src);

  return defaultIcons.map<Element<'div'>>((social: DefaultIcon) => {
    const link = getLink(social.key) || {
      iconUrl: social.defaultIcon,
      key: social.key,
    };

    return (
      <div key={`${social.key}`}>
        {name && <TextLabel>{`${name} ${social.key}`}</TextLabel>}
        <Row>
          <InputField
            input={urlInput(social.key)}
            type="text"
            name={name}
            initialValue={link ? link.url : ''}
            inputFlexMode="1"
          />
        </Row>
        <Row>
          <InputField
            input={altTextInput(social.key)}
            type="text"
            name={name}
            initialValue={link ? link.label : ''}
            inputFlexMode="1"
          />
        </Row>
        <Row>
          <Media
            values={{
              media: {
                src: link ? link.iconUrl : '',
              },
            }}
            name={name}
            ids={ids}
            onChange={(id, media) => onMediaChange(id, media, social.key)}
            data={data}
            actions={actions}
            hideLabel
            customDefault={{
              src: getDefaultIcon(social.key),
              label: 'Default Icon',
            }}
            type="image"
          />
        </Row>
      </div>
    );
  });
}

// @flow
import React from 'react';
import { connect } from 'react-redux';
import UsersEditor from '../components/UsersEditor';
import { actions } from '../state/actions/users';

const mapStateToProps = ({ users }) => ({
  users: users.users,
  roles: users.roles,
  emailAddress: users.emailAddress,
  selectedUserRole: users.selectedUserRole,
  error: users.error,
});

const mapDispatchToProps = dispatch => ({
  removeUser: user => dispatch(actions.removeUser(user)),
  addUserIfValid: user => dispatch(actions.attemptAddUser(user)),
  loadUsers: () => dispatch(actions.loadUsers()),
  loadRoles: () => dispatch(actions.loadRoles()),
  onSelectedUserRoleChanged: role =>
    dispatch(actions.selectedRoleChanged(role)),
  onEmailAddressChanged: emailAddress =>
    dispatch(actions.emailAddressChanged(emailAddress)),
});

class UsersEditorWithLifeCycle extends React.Component<*, *> {
  componentDidMount = () => {
    this.props.loadUsers();
    this.props.loadRoles();
  };

  render() {
    return <UsersEditor {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersEditorWithLifeCycle);

// @flow
import React from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import uuid from 'uuid/v1';
import DragHandle from './DragHandle';
import EditLink from '../EditLink';

const EditList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListContainer = styled(
  SortableContainer(({ children }) => <EditList>{children}</EditList>),
)``;

const IconWrapper = styled(DragHandle)`
  min-width: 12px;
  min-height: 14px;
  margin: 4px;
`;
const DragHandleIcon = SortableHandle(() => <IconWrapper />);

const NoBulletListItem = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  user-select: none;
  [class^='EditTextContainer'] {
    flex: 1 0 0%;
  }
`;

const SortableItem = SortableElement(({ hideIcon, children }) => (
  <NoBulletListItem>
    {!hideIcon && <DragHandleIcon />}
    {children}
  </NoBulletListItem>
));

const EditItem = styled(SortableItem).withConfig({
  displayName: 'EditItem',
})`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-bottom: 29px;
`;

type Props = {
  items: { itemId: string, value: string }[],
  itemBeingEdited: ?uuid,
  onEdit: (index: number) => (editing: boolean) => void,
  onChange: (index: number) => (newValue: string) => void,
  onDelete: (index: number) => () => void,
  onSort: (oldIndex: number) => (newIndex: number) => void,
  buttonText: string,
};

export default function EditableList({
  items,
  itemBeingEdited,
  onEdit: handleEdit,
  onChange: handleChange,
  onDelete: handleDelete,
  onSort: handleSort,
  options,
  actions,
  siteId,
  buttonText,
}: Props) {
  const draggable = !handleSort;
  return (
    <div>
      <ListContainer useDragHandle items={items} onSortEnd={handleSort}>
        {items.length > 0 &&
          items.map((item, index) => (
            <EditItem
              key={item.itemId}
              index={index}
              value={item}
              hideIcon={itemBeingEdited === item.itemId}
              disabled={draggable}
            >
              <EditLink
                value={item}
                editing={itemBeingEdited === item.itemId}
                onChange={handleChange(item.itemId)}
                onEdit={handleEdit(item.itemId)}
                onDelete={handleDelete(item.itemId)}
                options={options}
                actions={actions}
                siteId={siteId}
                buttonText={buttonText}
              />
            </EditItem>
          ))}
      </ListContainer>
    </div>
  );
}

EditableList.defaultProps = {
  items: [],
  onChange: () => {},
  onEdit: () => {},
  onDelete: () => {},
};

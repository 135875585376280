// @flow
import React from 'react';
import styled from 'styled-components';

import { Button, Modal } from 'cms-ui';

import EditorHeader from './EditorHeader';
import type { ScreenSizeOption } from '../screenSizeOptions';
import type { PublishState } from '../state/types/siteBuilder';
import useSaveStatus from './hooks/useSaveStatus';

import Sidebar from './Sidebar';
// These are each sufficiently complex in their own right as to justify Containers
import SiteBuilderViewContainer from '../containers/SiteBuilderViewContainer';

const FlexParent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const BuilderBody = styled.div`
  display: flex;
  overflow: hidden;
`;

const FlexChild = styled.div`
  width: ${props => props.width};
  height: ${props => props.height || 'auto'};
  background-color: #f3f3f3;
  overflow: ${props => props.overflow || 'unset'};
`;

const NoShrink = styled.div`
  flex-shrink: 0;
`;

const StyledModalButton = styled(Button).withConfig({
  displayName: 'StyledButton',
})`
  margin: 5px;
  width: 220px;
`;

const ModalContentContainer = styled.div.withConfig({
  displayName: 'ModalContentContainer',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  width: 460px;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizeContent};
`;

const ModalContentRow = styled.div`
  display: flex;
`;

const ModalContentRowText = styled.div`
  padding: 10px 0;
`;

const ModalContentHeader = styled.div`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizeSubHeader};
  font-weight: 600;
`;

export type Props = {
  brandName: string,
  moduleName: string,
  onScreenSizeChange: string => void,
  selectedScreenSize: string,
  onSaveChanges: any => void,
  onPublish: string => void,
  onPublishClick: () => void,
  onPublishCancelled: () => void,
  onPublishPreviewClick: () => void,
  onMenuClick: any => void, // TODO topbar onMenuClick
  saveStatus: 'unsaved' | 'saved' | 'saving',
  blogStatus: 'saved' | 'unsaved',
  publish: PublishState,
  selectedScreenSize: ScreenSizeOption,
  siteId: string,
  showScreenSizeSelector: boolean,
  dirtyPages: Page[],
  defaultLanguage: any,
  environment: string,
  previewBase: string,
  linkedModules: any,
  linkedModulesDirty: boolean,
  pages: Page[],
  setPageContext: (string, string) => void,
  currentPage: string,
  onHomeMenuClick: () => void,
  global: Object,
};

type PublishRequestedProps = {
  onPublishCancelled: () => void,
  onPublish: () => void,
  onPublishPreviewClick: () => void,
};

const PublishRequestedModal = ({
  onPublishCancelled,
  onPublish,
  onPublishPreviewClick,
}: PublishRequestedProps) => (
  <ModalContentContainer>
    <ModalContentHeader>Publish all changes?</ModalContentHeader>
    <ModalContentRow>
      <ModalContentRowText>Before publishing you can</ModalContentRowText>{' '}
      <Button primary link onClick={onPublishPreviewClick}>
        preview your changes
      </Button>
    </ModalContentRow>
    <ModalContentRow>
      <StyledModalButton textTransform="uppercase" onClick={onPublishCancelled}>
        Cancel
      </StyledModalButton>
      <StyledModalButton primary textTransform="uppercase" onClick={onPublish}>
        Yes, Publish changes
      </StyledModalButton>
    </ModalContentRow>
  </ModalContentContainer>
);

type PublishingModalProps = {
  message: string,
};

const PublishingModal = ({ message }: PublishingModalProps) => (
  <ModalContentContainer>
    <ModalContentRow>{message}</ModalContentRow>
  </ModalContentContainer>
);

type PublishedProps = {
  publishedUrl?: string,
  siteId: string,
  environment: string,
  defaultLanguage: any,
  previewBase: string,
};

const PublishedModal = ({ publishedUrl }: PublishedProps) => (
  <ModalContentContainer>
    <ModalContentRow>Tick here!</ModalContentRow>
    <ModalContentRow>Website published</ModalContentRow>
    <Button link primary onClick={() => window.open(publishedUrl)}>
      {publishedUrl || 'click to view'}
    </Button>
  </ModalContentContainer>
);

type PublishedFailedProps = {
  errorMessage: ?string,
};

const PublishFailedModal = ({ errorMessage }: PublishedFailedProps) => (
  <ModalContentContainer>
    <ModalContentRow>Unable to publish site at this time</ModalContentRow>
    <ModalContentRow>{errorMessage}</ModalContentRow>
  </ModalContentContainer>
);

const renderModalContent = (
  publish,
  onPublishCancelled,
  onPublish,
  onPublishPreviewClick,
  siteId,
  defaultLanguage,
  environment,
  previewBase,
) => {
  switch (publish.publishStatus) {
    case 'requested':
      return (
        <PublishRequestedModal
          onPublish={onPublish}
          onPublishCancelled={onPublishCancelled}
          onPublishPreviewClick={onPublishPreviewClick}
        />
      );
    case 'publishing':
      return <PublishingModal message="Publishing. Please wait..." />;
    case 'publishing preview':
      return <PublishingModal message="Publishing Preview. Please wait..." />;
    case 'published':
      return (
        <PublishedModal
          publishedUrl={publish.publishedUrl}
          siteId={siteId}
          defaultLanguage={defaultLanguage}
          environment={environment}
          previewBase={previewBase}
        />
      );
    case 'failed':
      return (
        <PublishFailedModal
          errorMessage={publish.error && publish.error.message}
        />
      );
    default:
      return <Button onClick={onPublishCancelled}>Cancel</Button>;
  }
};

const renderModalDialogue = (
  publish,
  onPublishCancelled,
  onPublish,
  onPublishPreviewClick,
  siteId,
  defaultLanguage,
  environment,
  previewBase,
) => (
  <Modal overlay onClickOutside={onPublishCancelled}>
    {renderModalContent(
      publish,
      onPublishCancelled,
      onPublish,
      onPublishPreviewClick,
      siteId,
      defaultLanguage,
      environment,
      previewBase,
    )}
  </Modal>
);

export default function SiteBuilder({
  brandName,
  onScreenSizeChange,
  selectedScreenSize,
  onSaveChanges,
  onPublish,
  onPublishClick,
  onPublishPreviewClick,
  onPublishCancelled,
  onMenuClick,
  publish,
  siteId,
  showScreenSizeSelector,
  defaultLanguage,
  environment,
  previewBase,
  pages,
  setPageContext,
  currentPage,
  onHomeMenuClick,
}: Props) {
  const { saveStatus, blogStatus } = useSaveStatus();
  return (
    <FlexParent>
      <NoShrink>
        <EditorHeader
          brandName={brandName}
          onScreenSizeChange={onScreenSizeChange}
          onSaveChanges={() => onSaveChanges(siteId)}
          onPublishClick={onPublishClick}
          onMenuClick={onMenuClick}
          saveStatus={saveStatus}
          blogStatus={blogStatus}
          selectedScreenSize={selectedScreenSize.name}
          showScreenSizeSelector={showScreenSizeSelector}
          pages={pages}
          setPageContext={setPageContext}
          currentPage={currentPage}
          onHomeMenuClick={onHomeMenuClick}
        />
      </NoShrink>
      {publish.publishStatus !== 'none' &&
        renderModalDialogue(
          publish,
          onPublishCancelled,
          () => onPublish(siteId),
          () => onPublishPreviewClick(siteId, defaultLanguage.isoCode),
          siteId,
          defaultLanguage,
          environment,
          previewBase,
        )}
      <BuilderBody>
        <FlexChild
          width="289px"
          height="calc(100vh - 52px - 37px)"
          overflow="auto"
        >
          <Sidebar />
        </FlexChild>
        <FlexChild
          width="calc(100vw - 289px)"
          style={{ borderLeft: '1px solid grey', overflow: 'auto' }}
        >
          <SiteBuilderViewContainer />
        </FlexChild>
      </BuilderBody>
    </FlexParent>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import SocialShareIcon from '../../components/JaguarIcons/Global/SocialShare';
import SocialSharePopup from '../../components/SocialSharePopup';
import { fontStyleOverride } from '../../components/Global';

type Props = {
  socialShare: Object,
  config: object,
};

const SocialShareButton = styled.div`
  ${({ font }) => fontStyleOverride(font)};
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.img`
  width: ${({ width }) => width || '12px'};
  height: ${({ height }) => height || '11px'};
  padding-right: 8px;
`;

export default function(props: Props) {
  const { socialShare, config } = props;
  return (
    <SocialSharePopup socialShare={socialShare} config={config}>
      <SocialShareButton font={config.socialShareFont}>
        {config.socialShareIcon ? (
          <IconWrapper
            src={config.socialShareIcon}
            alt=""
            width={config.socialShareIconWidth}
            height={config.socialShareIconHeight}
          />
        ) : (
          <SocialShareIcon
            width={`${config.socialShareIconWidth}px`}
            height={`${config.socialShareIconHeight}px`}
          />
        )}
        <span>{config.translations.socialShare}</span>
      </SocialShareButton>
    </SocialSharePopup>
  );
}

// @flow

import React from 'react';
import styled from 'styled-components';
import ReactMde, { commands } from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import './markdown.css';

import { type FieldProps } from '../models/fields';

const Container = styled.div`
  font-size: 13px;
`;

const listCommands = [
  {
    commands: [commands.headerCommand],
  },
  {
    commands: [
      commands.orderedListCommand,
      commands.unorderedListCommand,
      commands.checkedListCommand,
    ],
  },
];

export default function Markdown({
  name,
  ids: { text: id },
  values: { text },
  onChange,
}: FieldProps) {
  return (
    <Container>
      <strong>{name}</strong>
      <ReactMde
        className="markdown-text-editor"
        onChange={val => onChange(id, val)}
        value={text}
        commands={listCommands}
      />
    </Container>
  );
}

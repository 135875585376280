// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
};

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props: Props) => props.theme.layout.standardPadding};
  font-family: ${(props: Props) => props.theme.fontFamily};
  font-size: ${(props: Props) => props.theme.fontSizeSubHeader};
  background: ${(props: Props) => props.theme.colours.secondary02};
  margin: ${(props: Props) => props.theme.layout.doublePadding};
  width: 240px;
  height: 180px;
`;

export default function Box(props: Props) {
  return <StyledBox {...props} />;
}

import editor from './editor';

export default {
  id: 'Image',
  name: 'Image Pod',
  description: 'Add an image to the page',
  defaultSlice: 'Content',
  defaultConfig: {
    image: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
      label: 'Default Image',
    },
    translations: {},
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  requirements: {
    columns: [1, 2, 3, 4],
  },
};

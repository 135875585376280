import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'SimilarVehicles';

export const constants = moduleConstants(modulePrefix, [
  'GET_SIMILAR_VEHICLES',
  'GET_SIMILAR_VEHICLES_SUCCESS',
  'SIMILAR_VEHICLES_NEXT',
  'SIMILAR_VEHICLES_PREV',
  'SIMILAR_VEHICLES_NEXT_MOBILE',
  'SIMILAR_VEHICLES_PREV_MOBILE',
  'INIT',
]);

export const id = 'SimilarVehicles';

export const actions = {
  init: moduleAction(constants.INIT, id),
  getSimilarVehicles: moduleActionWithArgs(
    constants.GET_SIMILAR_VEHICLES,
    id,
    vehicleId => ({
      vehicleId,
    }),
  ),
  getSimilarVehiclesSuccess: moduleActionWithArgs(
    constants.GET_SIMILAR_VEHICLES_SUCCESS,
    id,
    payload => payload,
  ),
  similarVehiclesNext: moduleAction(constants.SIMILAR_VEHICLES_NEXT, id),
  similarVehiclesPrev: moduleAction(constants.SIMILAR_VEHICLES_PREV, id),
  similarVehiclesNextMobile: moduleAction(
    constants.SIMILAR_VEHICLES_NEXT_MOBILE,
    id,
  ),
  similarVehiclesPrevMobile: moduleAction(
    constants.SIMILAR_VEHICLES_NEXT_MOBILE,
    id,
  ),
};

export const initialState = {
  error: null,
  similarVehicles: null,
  vehiclesInView: null,
  similarVehiclePage: 1,
  similarVehiclePageTotal: null,
  selectedVehicleMobile: 0,
  similarVehiclePageMobile: 1,
  similarVehiclePageTotalMobile: null,
};

const maxVehiclesPerPage = 3;

function defineVehiclesInView(state, similarVehiclePage) {
  return state.similarVehicles.slice(
    maxVehiclesPerPage * (similarVehiclePage - 1),
    // eslint-disable-next-line
    maxVehiclesPerPage * (similarVehiclePage - 1) + maxVehiclesPerPage,
  );
}

function similarVehiclesNextMobile(state) {
  let { selectedVehicleMobile } = state;
  selectedVehicleMobile =
    selectedVehicleMobile === state.vehiclesInView.length - 1
      ? 0
      : selectedVehicleMobile + 1;

  let { similarVehiclePageMobile } = state;
  similarVehiclePageMobile =
    similarVehiclePageMobile === state.similarVehiclePageTotalMobile
      ? 1
      : (similarVehiclePageMobile += 1);

  let { similarVehiclePage } = state;
  similarVehiclePage =
    state.selectedVehicleMobile === state.vehiclesInView.length - 1
      ? (similarVehiclePage += 1)
      : similarVehiclePage;

  if (state.similarVehiclePageMobile === state.similarVehiclePageTotalMobile) {
    similarVehiclePage = 1;
  }

  const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  return {
    ...state,
    selectedVehicleMobile,
    similarVehiclePageMobile,
    similarVehiclePage,
    vehiclesInView,
  };
}

function similarVehiclesPrevMobile(state) {
  let { similarVehiclePageMobile } = state;
  similarVehiclePageMobile =
    similarVehiclePageMobile === 1
      ? state.similarVehiclePageTotalMobile
      : (similarVehiclePageMobile -= 1);

  let { similarVehiclePage } = state;
  similarVehiclePage =
    state.selectedVehicleMobile === 0
      ? (similarVehiclePage -= 1)
      : similarVehiclePage;

  if (state.similarVehiclePageMobile === 1) {
    similarVehiclePage = state.similarVehiclePageTotal;
  }

  const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  let { selectedVehicleMobile } = state;
  selectedVehicleMobile =
    selectedVehicleMobile === 0
      ? vehiclesInView.length - 1
      : (selectedVehicleMobile -= 1);

  return {
    ...state,
    selectedVehicleMobile,
    similarVehiclePageMobile,
    similarVehiclePage,
    vehiclesInView,
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_SIMILAR_VEHICLES_SUCCESS: {
      const { similarVehicles } = action.payload;
      const similarVehiclePageTotal = Math.ceil(
        similarVehicles.length / maxVehiclesPerPage,
      );
      const vehiclesInView = similarVehicles.slice(0, 3);
      return {
        ...state,
        similarVehicles,
        vehiclesInView,
        similarVehiclePageTotal,
        similarVehiclePageTotalMobile: similarVehicles.length,
      };
    }
    case constants.SIMILAR_VEHICLES_NEXT: {
      let { similarVehiclePage } = state;
      similarVehiclePage =
        similarVehiclePage === state.similarVehiclePageTotal
          ? 1
          : (similarVehiclePage += 1);
      const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);
      return {
        ...state,
        vehiclesInView,
        similarVehiclePage,
      };
    }
    case constants.SIMILAR_VEHICLES_PREV: {
      let { similarVehiclePage } = state;
      similarVehiclePage =
        similarVehiclePage === 1
          ? state.similarVehiclePageTotal
          : (similarVehiclePage -= 1);
      const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);
      return {
        ...state,
        vehiclesInView,
        similarVehiclePage,
      };
    }
    case constants.SIMILAR_VEHICLES_NEXT_MOBILE: {
      return similarVehiclesNextMobile(state);
    }

    case constants.SIMILAR_VEHICLES_PREV_MOBILE: {
      return similarVehiclesPrevMobile(state);
    }
    default:
      return state;
  }
}

// @flow
import React, { type Node } from 'react';
import { createElement, mediaProps } from './styledComponentFactory';

const Heading = createElement('h2');

type Props = {
  styleOverride?: () => string,
  mobileStyleOverride?: () => { queryPath: string, template: string }[],
  children?: Node,
};

export default function HeadingTwo({
  styleOverride,
  mobileStyleOverride,
  ...props
}: Props) {
  return (
    <Heading
      styleOverride={styleOverride}
      {...{ ...props, ...mediaProps(mobileStyleOverride()) }}
    />
  );
}

HeadingTwo.defaultProps = {
  styleOverride: () => '',
  mobileStyleOverride: () => [],
  children: null,
};

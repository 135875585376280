// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import { InputField } from './FormFields';
import ConfirmationWindow from './ConfirmationWindow';
import media from '../theme';
import { HeadingOne, Button } from './Global';
import {
  passwordMatchesRequired,
  meetsPasswordPolicy,
} from '../helpers/validation';

const ResetPasswordContent = styled.div`
  padding: 50px 35%;
  ${media.max.large`
    padding: 50px 5%;
  `};
`;
const ResetPasswordFailure = styled.div`
  font-size: 16px;
  color: #9e1b32;
  font-weight: 600;
  margin-bottom: 10px;
`;

type Props = {
  translations: Object,
  resetPasswordStatus: 'NONE' | 'SUCCESS' | 'EXPIRED',
  changePassword: string => void,
  goToLogin: () => void,
  globalStyling: Object,
};

type State = {
  password: string,
  repeatPassword: string,
  errors: Object,
};

export default class ResetPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      repeatPassword: '',
      errors: {},
    };
  }

  onInputChange = (formKey: string, value: any) => {
    const error = this.validateSingle(formKey, value);
    this.setState(prevState => ({
      [formKey]: value,
      errors: { ...prevState.errors, ...error },
    }));
  };

  onFormSubmit = (event: Event) => {
    const validations = this.validateForm();
    this.setState(() => ({ errors: validations }));
    const valid =
      Object.values(validations).filter(val => val !== undefined).length === 0;
    if (valid) {
      const { changePassword } = this.props;
      const { password } = this.state;
      changePassword(password);
    }
    event.preventDefault();
  };

  validateSingle = (formKey: string, value: string) => {
    let error;

    switch (formKey) {
      case 'password': {
        error = meetsPasswordPolicy(value, this.props.translations, true);
        break;
      }
      case 'repeatPassword': {
        error = passwordMatchesRequired(
          this.state.password,
          value,
          this.props.translations,
        );
        break;
      }
      default:
        break;
    }

    return { [formKey]: error };
  };

  validateForm = () => {
    const validations = {
      password: meetsPasswordPolicy(
        this.state.password,
        this.props.translations,
        true,
      ),
      repeatPassword: passwordMatchesRequired(
        this.state.password,
        this.state.repeatPassword,
        this.props.translations,
      ),
    };

    return validations;
  };

  render = () => {
    const {
      translations,
      resetPasswordStatus,
      goToLogin,
      globalStyling,
    } = this.props;
    const { password, repeatPassword, errors } = this.state;
    const hasError = field => errors[field] && errors[field][0];

    return resetPasswordStatus === 'SUCCESS' ? (
      <ConfirmationWindow
        translations={translations}
        onButtonClick={goToLogin}
        circleTickColour={
          globalStyling.colours.primaryBrandColour &&
          globalStyling.colours.primaryBrandColour.value
        }
      />
    ) : (
      <div>
        <HeadingOne
          styleOverride={() => `
            height: 75px;
            border-bottom: 1px solid #d8d8d8;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            text-transform: uppercase;
          `}
        >
          {translations.changePasswordHeader}
        </HeadingOne>
        <ResetPasswordContent>
          {resetPasswordStatus === 'EXPIRED' && (
            <ResetPasswordFailure>
              {translations.expiredMessage}
            </ResetPasswordFailure>
          )}
          <form onSubmit={this.onFormSubmit}>
            <InputField
              type="password"
              label={translations.newPassword}
              value={password}
              onChange={this.onInputChange}
              keyValue="password"
              error={hasError('password')}
            />
            <InputField
              type="password"
              label={translations.confirmPassword}
              value={repeatPassword}
              onChange={this.onInputChange}
              keyValue="repeatPassword"
              error={hasError('repeatPassword')}
            />
            <Button
              styleOverride={() => `
                width: 100%;
              `}
              type="submit"
              text={translations.changeButtonText}
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
            />
          </form>
        </ResetPasswordContent>
      </div>
    );
  };
}

// @flow
import React from 'react';
import styled from 'styled-components';
import { Handle } from 'rc-slider';
import Chunky from '../JaguarIcons/Arrows/Chunky';

type Props = {
  offset: number,
  value: number,
  index: number,
};

const HandleContainer = styled(Handle)`
  width: 25px;
  height: 25px;
  border: 1px solid #0c0c0c;
  margin-top: -11px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  touch-action: pan-x;
  cursor: grab;
`;

const Arrow = styled.div`
  position: absolute;
  left: ${({ left }) => left || '7px'};
  top: ${({ top }) => top || '3px'};
  width: 10px;
  transform: ${({ transform }) => transform || 'none'};
`;

export default function StyledHandle(props: Props) {
  return (
    <HandleContainer
      data-qa-hook="slider-handle"
      key={props.index}
      value={props.value}
      offset={props.offset}
    >
      <Arrow>
        <Chunky height="10px" colour="#0C0C0C" />
      </Arrow>
      <Arrow transform="rotateY(180deg)" left="5px">
        <Chunky height="10px" colour="#0C0C0C" />
      </Arrow>
    </HandleContainer>
  );
}

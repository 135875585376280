// @flow
import React from 'react';
import type { Element } from 'react';
import styled from 'styled-components';
import { lensPath, view } from 'ramda';
import * as Fields from '../fields';
import type { EditorField } from '../state/types/siteBuilder';

const FieldRow = styled.div`
  margin-bottom: 16px;
`;

const NotFound = ({ datatype }) => ({
  name,
  id,
  value,
}: EditorField): Element<*> => (
  <div>
    No Editor found for type <strong>{datatype}</strong> [{value}] ({name}:{id})
  </div>
);

export default ({
  name,
  ids,
  datatype,
  data,
  props,
  config,
  onFieldChange,
  actions,
  show,
  siteId,
}) => {
  const DataType = Fields[datatype] || NotFound({ datatype });
  const values = Object.entries(ids).reduce((acc, [fieldKey, id]) => {
    return {
      ...acc,
      [fieldKey]: view(lensPath(id.split('.')), config),
    };
  }, {});

  return (
    show && (
      <FieldRow key={`${name}-${Object.values(ids).join('-')}-${datatype}`}>
        <DataType
          name={name}
          ids={ids}
          values={values}
          data={data}
          onChange={onFieldChange}
          actions={actions}
          siteId={siteId}
          {...props}
        />
      </FieldRow>
    )
  );
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function User({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 30 25">
        <path d="M16.006 14h-3.997c-3.462 0-5.346.725-6.22 2.048-.672 1.017-.813 2.145-.782 4.952h17.988c-.082-5.265-1.374-7-6.99-7zm0-2c7.097 0 8.973 2.86 8.993 9.446L25 22v1H3.014v-1c0-.164 0-.336-.003-.541l-.004-.384c-.038-3.265.13-4.642 1.113-6.13C5.427 12.969 7.94 12 12.009 12h3.997zM13.5 11c1.92 0 3.5-2.102 3.5-4.577C17 4.256 15.698 3 13.5 3S10 4.256 10 6.423C10 8.88 11.603 11 13.5 11zm0 2C10.357 13 8 9.882 8 6.423 8 3.135 10.213 1 13.5 1S19 3.135 19 6.423C19 9.9 16.67 13 13.5 13z" />
      </svg>
    </IconWrapper>
  );
}

User.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

import { select, call } from 'redux-saga/effects';
import { get, localeConfigSerializer } from '../../helpers/http';
import { getToken } from '../CustomerLogin/reducer';
import {
  getAVLConfig,
  appendLocaleQueryString,
  inventorySearchServiceUrl,
} from '../selectors/settings';

export function* fetchVehicleStatuses(vehicleIds) {
  const token = yield select(state => getToken(state.shared));
  const { make, market, region, locale } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);

  const { paramsSerializer, params } = localeConfigSerializer(locale);

  const vehicleStatusesResponse = yield call(get, {
    url: region
      ? `${yield select(
          inventorySearchServiceUrl,
        )}inventory/make/${make}/region/${region}/vehicle-status`
      : `${yield select(
          inventorySearchServiceUrl,
        )}inventory/make/${make}/market/${market}/vehicle-status`,
    config: {
      paramsSerializer,
      params: {
        ids: vehicleIds,
        ...(withLocaleQueryString && params),
      },
    },
    token,
  });

  return vehicleStatusesResponse ? vehicleStatusesResponse.data.contents : [];
}

export const mapStatus = (vehicleId, statuses) => {
  const vehicleStatus = statuses.filter(s => s.id === vehicleId);
  return vehicleStatus.length > 0 ? vehicleStatus[0].status : 'retail';
};

// @flow
import React from 'react';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';

import type { Node } from 'react';
import type { Theme } from '../theme';

type Position = {
  x: number,
  y: number,
  screenWidth: number,
  screenHeight: number,
};

type Props = {
  children?: ?Node,
  onClickOutside?: ?() => void,
  position?: ?Position,
  overlay?: boolean,
  fullScreen?: boolean,
};

type ThemeProps = Props & { theme: Theme };

const aboveCentre = (pos: Position): boolean => pos.y < pos.screenHeight / 2;
const leftOfCentre = (pos: Position): boolean => pos.x < pos.screenWidth / 2;
const topBottomCssString = (pos: Position) =>
  aboveCentre(pos)
    ? `top: ${pos.y}px`
    : `bottom: ${pos.screenHeight - pos.y}px`;
const leftRightCssString = (pos: Position) =>
  leftOfCentre(pos)
    ? `left: ${pos.x}px`
    : `right: ${pos.screenWidth - pos.x}px`;
const calTopPosition = position =>
  position ? topBottomCssString(position) : 'top: 50%';
const calLeftPosition = position =>
  position ? leftRightCssString(position) : 'left: 50%';

const Overlay = styled.div.withConfig({
  displayName: 'Overlay',
})`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: ${(props: ThemeProps) =>
    props.position
      ? transparentize(1, props.theme.colours.secondary02)
      : transparentize(0.3, props.theme.colours.secondary05)};
`;

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  background-color: ${(props: ThemeProps) => props.theme.colours.primary03};
  position: fixed;
  ${(props: Props) =>
    props.fullScreen ? 'top: 3vh' : calTopPosition(props.position)};
  ${(props: Props) =>
    props.fullScreen ? 'left: 2vw' : calLeftPosition(props.position)};
  transform: ${(props: Props) =>
    props.position || props.fullScreen ? 'none' : 'translate(-50%, -50%)'};
  max-width: ${(props: Props) => (props.fullScreen ? '100' : '30')}%;
  ${(props: Props) =>
    props.fullScreen
      ? `
    height: 95vh;
    overflow-y: scroll;
    width: 96vw;
    `
      : ''} display: flex;
  flex-direction: column;
  padding: ${(props: ThemeProps) =>
    props.fullScreen ? '4vh' : props.theme.layout.standardPadding};
  box-shadow: 0 6px 14px 0
    ${(props: ThemeProps) =>
      transparentize(0.2, props.theme.colours.secondary05)};
  z-index: 1000;
  min-width: 360px;
`;

export default function Modal({
  children,
  onClickOutside,
  position,
  overlay,
  fullScreen,
}: Props) {
  return (
    <div>
      {overlay ? (
        <Overlay onClick={onClickOutside} position={position} />
      ) : null}
      <Container position={position} fullScreen={fullScreen}>
        {children}
      </Container>
    </div>
  );
}

Modal.defaultProps = {
  children: null,
  onClickOutside: () => {},
  position: null,
  overlay: true,
  fullScreen: false,
};

// @flow
import React from 'react';
import styled from 'styled-components';

import GridItem from '../../components/GridItem';
import type { Font, Option } from '../../types';
import media from '../../theme';
import ContentWrapper from '../../components/ContentWrapper';
import { actions as routerActions } from '../../actions/router';

type Item = {
  image: { label: string, value: string },
  link: { url: string, label: string },
  translations: {
    headline: string,
    body: string,
    link: string,
  },
};

export interface Config {
  items: Item[];
  padding: string;
  gridItemsAlign: string;
  gridBackgroundColour: Option;
  gridItemHeaderFont: Font;
  gridItemBodyFont: Font;
  gridItemLinkFont: Font;
  buttonBackgroundColour: Option;
  buttonHover: Option;
  buttonFont: Font;
  buttonSize: Object;
  buttonType: string;
  column: Object;
  buttonStyle: string;
  gridGap: number;
}

type Props = {
  config: Config,
  globalStyling: Object,
  dispatch: Function,
  test: string,
};

const criteria = itemLength => [2, 4].includes(itemLength);
const getNumberOfColumns = (
  itemLength: number,
  meetsCritera: string,
  defaultValue: string,
) => {
  if (criteria(itemLength)) return meetsCritera;
  return defaultValue;
};

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.min.medium`flex-direction: row;`};
`;

const GridItemWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  ${media.min.medium`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: ${({ itemCount }) =>
      getNumberOfColumns(itemCount, '50%', `${100 / 3}%`)};
    @supports(display: grid) {
      padding: ${({ gridGap }) => (gridGap ? `0 ${gridGap / 2}px` : '0 5px')};
    }
  `};
`;

export default function Grid({ config, globalStyling, dispatch }: Props) {
  const buttonProps = {
    backgroundColour: config.buttonBackgroundColour,
    hover: config.buttonHover,
    font: config.buttonFont,
    buttonSize: config.buttonSize,
    buttonType: config.buttonType,
    buttonStyle: config.buttonStyle,
  };

  const alignItems = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  const { gridGap } = config;
  const isExternal = href => href.match(/^http/);
  const actionButton = link =>
    isExternal(link)
      ? window.open(link, '_blank')
      : dispatch(routerActions.navigate(link));
  return (
    <ContentWrapper
      colour={config.gridBackgroundColour && config.gridBackgroundColour}
      contentWidth={globalStyling.contentWidth}
    >
      <div
        style={{
          padding: gridGap ? `${gridGap}px ${gridGap / 2}px` : '10px 5px',
        }}
      >
        <GridWrapper>
          {config.items.map((it, index) => (
            <GridItemWrapper
              key={`GridItem${index + 1}`}
              itemCount={config.items.length}
              gridGap={gridGap}
            >
              <GridItem
                linkText={it.translations.link}
                imageUrl={it.image.value}
                headline={it.translations.headline}
                bodyText={it.translations.body}
                bodyTextTheme={config.gridItemBodyFont || {}}
                headlineTheme={config.gridItemHeaderFont || {}}
                align={alignItems[config.gridItemsAlign]}
                buttonProps={buttonProps}
                globalStyling={globalStyling}
                onClickLink={() => actionButton(it.link.url)}
                bgColour={
                  config.itemBackgroundColour &&
                  config.itemBackgroundColour.value
                }
                imageAspectRatioPercentage={
                  config.gridItemImageAspectRatioPercentage
                }
              />
            </GridItemWrapper>
          ))}
        </GridWrapper>
      </div>
    </ContentWrapper>
  );
}

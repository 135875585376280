// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Clone } from '../components';
import { actions } from '../state/actions/site';
import type { Site } from '../state/reducers/site';

const mapStateToProps = ({ site, editor }) => ({
  cloneableSites: site.cloneableSites,
  selectedForCloning: site.selectedForCloning,
  loading: site.loading,
  logoUrl: editor.branding.logoUrl,
  cloneModeOptions: site.cloneModeOptions,
  cloneMode: site.cloneMode,
});

const mapDispatchToProps = (dispatch, { siteId }) => ({
  loadCloneableSites: () => dispatch(actions.loadCloneableSites(siteId)),
  selectSite: site => dispatch(actions.selectSite(site)),
  cloneSite: (siteToCloneFrom, mode) =>
    dispatch(actions.cloneSite(siteId, siteToCloneFrom, mode)),
  skipClone: () => dispatch(actions.skipCloning(siteId)),
  setCloneMode: mode => dispatch(actions.setCloneMode(mode)),
});

type Props = {
  loadCloneableSites: () => void,
  selectSite: Site => void,
  cloneSite: Site => void,
  skipClone: () => void,
  loading: boolean,
  logoUrl: string,
};

class CloneWithLifecycle extends React.Component<Props, *> {
  componentDidMount() {
    this.props.loadCloneableSites();
  }

  render() {
    const { loadCloneableSites, ...props } = this.props;
    return <Clone {...props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloneWithLifecycle);

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../theme';
import { HeadingTwo, Button, fontStyleOverride } from './Global';
import { localiseCurrency } from '../shared/localisation/numbers';
import VdpHighlightsImageOverlay from './VdpHighlightsImageOverlay';
import SocialShareButton from '../modules/Header/SocialShareButton';
import Icon from './StyledIcon';
import Spinner from './Spinner';

const Container = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  background-color: #ffffff;
  flex-direction: column;
`;

const FinanceCellContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
`;

const FinanceSection = styled.div`
  ${({ showBorder }) => showBorder && 'border-bottom: 1px solid #dedede;'};
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`;

const FinanceCell = styled.div`
  &:first-child {
    ${({ showBorder }) => showBorder && 'border-bottom: 1px solid #dedede;'};
    box-sizing: border-box;
  }
`;

const HighlightCell = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 8px 0;
`;

const HighlightCellLabel = styled.div`
  font-size: 16px;
  color: #8b8b8b;
`;

const HighlightCellIcon = styled.div``;

type Info = { label: string, value: string };

type Props = {
  config: Object,
  vehicleInfo: Object,
  translations: Object,
  price: Info,
  globalStyling: Object,
  makeAnEnquiry: Function,
  makeATestDriveRequest: Function,
  makeAReservation: Function,
  goToAnchor: (element: string) => void,
  iconColour: string,
  socialShareConfig: Object,
  socialShare: Object,
  handleZeroPriceAsText: boolean,
  featureFlags: Object,
  printVehiclePdf: () => void,
  printingState: Object,
};

const DesignHighlightsDataContainer = styled.div`
  padding: 16px 0;
  font-size: 14px;
  color: #8b8b8b;
  display: flex;
  flex-direction: row;
`;
const DesignHighlightsDataColumn = styled.div`
  ${({ padding }) => padding && 'padding-left: 10px;'};
  display: flex;
  flex-direction: column;
`;
const DesignHighlightsDataRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;
const DesignHighlightsLabel = styled.div`
  font-size: 14px;
`;
const DesignHighlightsValue = styled.div`
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  padding-left: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  div:first-child {
    margin: auto;
  }
`;

const LabelWithIconWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  align-items: center;
  ${({ cursorPointer }) => cursorPointer && 'cursor: pointer;'};
`;

const AnchorText = styled.div`
  color: #00aaee;
  font-size: 14px;
  font-weight: 600;
`;

const ErrorAnchorText = styled(AnchorText)`
  cursor: pointer;
`;

const DesignHighlightsAnchorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  div:first-child {
    justify-content: flex-start;
  }
`;

const PhoneLink = styled.a`
  display: flex;
  flex-direction: column;
  width: 47%;
  text-decoration: none;

  ${media.max.medium`
    width:100%;
    padding: 0px 16px 16px 16px;
`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 16px;
  ${media.max.medium`
    flex-wrap: wrap;
    padding: 0;
  `};
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  ${media.max.medium`
    width:100%;
    padding: 0px 16px 16px 16px;
  `};
`;

const LabelIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.max.medium`
    flex-direction: row;
    width: 100%;
`};
`;

export function labelWithIcon(label, icon, onClick) {
  return (
    <LabelWithIconWrapper onClick={onClick} cursorPointer>
      <Icon link={icon} width={16} height={16} />
      <div style={{ marginLeft: '8px' }}>
        <AnchorText>{label}</AnchorText>
      </div>
    </LabelWithIconWrapper>
  );
}

export default function VdpHighlights({
  config,
  vehicleInfo,
  translations,
  globalStyling,
  makeAnEnquiry,
  makeATestDriveRequest,
  makeAReservation,
  goToAnchor,
  socialShareConfig,
  socialShare,
  handleZeroPriceAsText,
  printVehiclePdf,
  printingState,
}: Props) {
  const locale = vehicleInfo.locale.replace('_', '-');
  const showSpecification =
    vehicleInfo.specification &&
    (vehicleInfo.specification.exterior ||
      vehicleInfo.specification.interior ||
      vehicleInfo.specification.veneer) &&
    vehicleInfo.specification;
  const showDesignHeading =
    showSpecification &&
    vehicleInfo.bespokeFeatures &&
    vehicleInfo.bespokeFeatures.length > 0;
  const showDesignAnchor =
    vehicleInfo.bespokeFeatures && vehicleInfo.bespokeFeatures.length > 5;
  const localisePrice = localiseCurrency(
    vehicleInfo.price.value,
    locale,
    vehicleInfo.price.currency,
    0,
  );
  const handleZeroPrice =
    vehicleInfo.price.value === 0 ? translations.zeroPriceText : localisePrice;
  const handlePrice = handleZeroPriceAsText ? handleZeroPrice : localisePrice;

  const printingDisplay = printingState.error ? (
    <ErrorAnchorText onClick={printVehiclePdf}>
      {translations.printError}
    </ErrorAnchorText>
  ) : (
    labelWithIcon(translations.printPage, config.printIcon, printVehiclePdf)
  );

  const buttonActions = {
    testdrive: 'testdrive',
    reserve: 'reserve',
    contact: 'contact',
    phone: 'phone',
  };

  return (
    <Fragment>
      <Container data-qa-hook="my-deal">
        <Fragment>
          <FinanceCellContainer data-qa-hook="pricing-and-apr">
            <FinanceSection showBorder>
              <FinanceCell>
                <HeadingTwo
                  styleOverride={() => `
                    ${fontStyleOverride(config.headlineFont)}
                  `}
                >
                  {handlePrice}
                  {vehicleInfo.price && vehicleInfo.price.label}
                </HeadingTwo>
              </FinanceCell>
              <br />
            </FinanceSection>
            {showDesignHeading && (
              <Fragment>
                <FinanceSection>
                  <HeadingTwo
                    styleOverride={() => `
                width:100%;
                padding-top:16px;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: ${vehicleInfo.finance ? 'inherit' : '0.72px'};
              `}
                  >
                    {translations.designHighlights}
                  </HeadingTwo>
                </FinanceSection>
                <DesignHighlightsDataContainer>
                  <DesignHighlightsDataColumn>
                    <IconWrapper>
                      <Icon link={config.coloursIcon} width={18} height={18} />
                    </IconWrapper>
                  </DesignHighlightsDataColumn>
                  <DesignHighlightsDataColumn padding>
                    {showSpecification.exterior && (
                      <DesignHighlightsDataRow>
                        <DesignHighlightsLabel>
                          {translations.exterior}
                        </DesignHighlightsLabel>
                        <DesignHighlightsValue>
                          {vehicleInfo.specification &&
                            vehicleInfo.specification.exterior}
                        </DesignHighlightsValue>
                      </DesignHighlightsDataRow>
                    )}
                    {showSpecification.interior && (
                      <DesignHighlightsDataRow>
                        <DesignHighlightsLabel>
                          {translations.interior}
                        </DesignHighlightsLabel>
                        <DesignHighlightsValue>
                          {vehicleInfo.specification &&
                            vehicleInfo.specification.interior}
                        </DesignHighlightsValue>
                      </DesignHighlightsDataRow>
                    )}
                    {showSpecification.veneer && (
                      <DesignHighlightsDataRow>
                        <DesignHighlightsLabel>
                          {translations.veneer}
                        </DesignHighlightsLabel>
                        <DesignHighlightsValue>
                          {vehicleInfo.specification &&
                            vehicleInfo.specification.veneer}
                        </DesignHighlightsValue>
                      </DesignHighlightsDataRow>
                    )}
                  </DesignHighlightsDataColumn>
                </DesignHighlightsDataContainer>
              </Fragment>
            )}
            {vehicleInfo.bespokeFeatures &&
              vehicleInfo.bespokeFeatures.slice(0, 5).map(feature => (
                <FinanceSection showBorder key={`highlights-${feature.name}`}>
                  <HighlightCell>
                    <HighlightCellLabel>{feature.name}</HighlightCellLabel>
                    <HighlightCellIcon>
                      {feature.imageUrl && (
                        <VdpHighlightsImageOverlay
                          imageUrl={feature.imageUrl}
                          infoIcon={config.infoIcon}
                        />
                      )}
                    </HighlightCellIcon>
                  </HighlightCell>
                  <br />
                </FinanceSection>
              ))}
            <FinanceSection>
              <DesignHighlightsAnchorsContainer>
                {showDesignAnchor && (
                  <LabelIconWrapper>
                    {labelWithIcon(
                      translations.viewMoreDesignHotWord,
                      config.designIcon,
                      () => goToAnchor('design-list'),
                    )}
                  </LabelIconWrapper>
                )}
                {config.showApprovedBenefits && (
                  <LabelIconWrapper>
                    {labelWithIcon(
                      translations.whyProvenanceHotWord,
                      config.questionmarkIcon,
                      () => goToAnchor('provenance'),
                    )}
                  </LabelIconWrapper>
                )}
              </DesignHighlightsAnchorsContainer>
            </FinanceSection>
            <FinanceSection style={{ fontWeight: '600', paddingTop: '16px' }}>
              <DesignHighlightsAnchorsContainer
                style={{ alignItems: 'flex-end' }}
              >
                {socialShareConfig && socialShareConfig.socialShareEnabled && (
                  <LabelIconWrapper>
                    <SocialShareButton
                      {...{
                        config: { ...config.socialShareConfig, ...config },
                      }}
                      socialShare={socialShare}
                    />
                  </LabelIconWrapper>
                )}
                {printingState.isPrinting ? (
                  <LabelWithIconWrapper>
                    <Spinner
                      size={10}
                      colour={globalStyling.colours.primaryBrandColour}
                    />
                  </LabelWithIconWrapper>
                ) : (
                  <LabelIconWrapper>{printingDisplay}</LabelIconWrapper>
                )}
              </DesignHighlightsAnchorsContainer>
            </FinanceSection>
          </FinanceCellContainer>
        </Fragment>
      </Container>
      <br />
      <Container>
        <TitleContainer>
          <HeadingTwo
            styleOverride={() => `
                width:100%;
                font-size: 18px;
                font-weight: 600;
              `}
          >
            {vehicleInfo.retailerInformation &&
              vehicleInfo.retailerInformation.name}
          </HeadingTwo>
        </TitleContainer>
        <ContentContainer>
          <FinanceSection>
            <ButtonWrapper>
              {config.buttonsToDisplay &&
                config.buttonsToDisplay.includes(buttonActions.testdrive) && (
                  <ButtonColumn>
                    <Button
                      text={translations.vdpBookTestDrive}
                      applyStyle="primary"
                      buttonStyle={
                        globalStyling.uiElements.primaryButton &&
                        globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      onClick={makeATestDriveRequest}
                    />
                  </ButtonColumn>
                )}
              {config.buttonsToDisplay &&
                config.buttonsToDisplay.includes(buttonActions.reserve) && (
                  <ButtonColumn>
                    <Button
                      text={translations.vdpReserveThisVehicle}
                      applyStyle="primary"
                      buttonStyle={
                        globalStyling.uiElements.primaryButton &&
                        globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      onClick={makeAReservation}
                    />
                  </ButtonColumn>
                )}
            </ButtonWrapper>
          </FinanceSection>
          <FinanceSection>
            <ButtonWrapper>
              {config.buttonsToDisplay &&
                config.buttonsToDisplay.includes(buttonActions.phone) && (
                  <PhoneLink
                    href={`tel:${vehicleInfo.retailerInformation.phone}`}
                  >
                    <Button
                      text={vehicleInfo.retailerInformation.phone}
                      applyStyle="secondary"
                      buttonStyle={
                        globalStyling.uiElements.primaryButton &&
                        globalStyling.uiElements.primaryButton.buttonStyle
                      }
                    />
                  </PhoneLink>
                )}
              {config.buttonsToDisplay &&
                config.buttonsToDisplay.includes(buttonActions.contact) && (
                  <ButtonColumn>
                    <Button
                      text={translations.vdpContactUs}
                      applyStyle="secondary"
                      buttonStyle={
                        globalStyling.uiElements.primaryButton &&
                        globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      onClick={makeAnEnquiry}
                    />
                  </ButtonColumn>
                )}
            </ButtonWrapper>
          </FinanceSection>
        </ContentContainer>
      </Container>
    </Fragment>
  );
}

VdpHighlights.defaultProps = {
  iconColour: '#9E1B32',
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function GridEnabled({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 45 45">
        <g fill="none" fillRule="evenodd">
          <path fill={background} d="M0 0h45v45H0z" />
          <path
            fill={colour}
            d="M11 11h6v6h-6zM11 19h6v6h-6zM11 27h6v6h-6zM19 11h6v6h-6zM19 19h6v6h-6zM19 27h6v6h-6zM27 11h6v6h-6zM27 19h6v6h-6zM27 27h6v6h-6z"
          />
        </g>
      </svg>
    </IconWrapper>
  );
}

GridEnabled.defaultProps = {
  colour: '#D8D8D8',
  width: null,
  height: null,
  background: '#252525',
};

export default function() {
  return {
    translations: {
      bodyText: 'Body placeholder',
      headlineText: 'Headline Placeholder',
      smallBodyText: 'Small body placeholder',
      buttonText: 'Button placeholder',
    },
    buttonFont: {
      fontSize: '14',
      kerning: '1',
      transform: 'uppercase',
    },
    alignment: 'center',
    headlineFont: {
      fontSize: '24',
      kerning: '1',
      transform: 'uppercase',
    },
    bodyFont: {
      fontSize: '24',
      kerning: '1',
      transform: 'none',
    },
    smallBodyFont: {
      fontSize: '18',
      kerning: '1',
      transform: 'lowercase',
    },
    buttonText: {
      on: true,
    },
    preview: true,
  };
}

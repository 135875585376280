// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { Button } from 'cms-ui';
import { useDispatch } from 'react-redux';
import { actions } from '../state/actions/editor';
import useSaveStatus from './hooks/useSaveStatus';
import settings from '../settings';

const Items = styled.div`
  display: flex;
`;

const Nav = styled.nav`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeContent};
  background: ${({ theme }) => theme.colours.white};
  display: flex;
  flex-direction: row-reverse;
  height: 37px;
`;

const BlurBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.3);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ConfirmationBox = styled.div`
  background-color: #ffffff;
  width: 300px;
`;

const Heading = styled.h4`
  margin: 0px;
`;

const HeadingContainer = styled.div`
  ${({ underline }) => (underline ? 'border-bottom: 2px solid #e6e6e6;' : '')}
  padding: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const Padding = styled.div`
  padding: 0px 10px 10px 10px;
`;

const Paragraph = styled.div`
  margin: 12px 0px 14px 0px;
`;

type LogoutProps = {
  onLogout: Function,
  children?: Node,
};

export default function LogoutWrapper({ onLogout, children }: LogoutProps) {
  const dispatch = useDispatch();
  const { saveStatus, blogStatus } = useSaveStatus();
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    dispatch(actions.isLoggingOut(!showPopUp));
    setShowPopUp(!showPopUp);
  };

  const handleClick = () => {
    return settings.authOn ? togglePopUp() : () => {};
  };

  const unsavedChanges = () =>
    saveStatus === 'unsaved' || blogStatus === 'unsaved';

  return (
    <>
      {showPopUp && (
        <BlurBackground>
          <ConfirmationBox>
            <HeadingContainer underline={unsavedChanges()}>
              <Heading>Are you sure you want to log out?</Heading>
            </HeadingContainer>
            <Padding>
              {unsavedChanges() && (
                <Paragraph>
                  Your unsaved changes will be lost if you log out now
                </Paragraph>
              )}
              <ButtonRow>
                <Button secondary onClick={togglePopUp}>
                  CANCEL
                </Button>
                <Button primary onClick={onLogout}>
                  LOG OUT
                </Button>
              </ButtonRow>
            </Padding>
          </ConfirmationBox>
        </BlurBackground>
      )}
      <Nav>
        <Items>
          <Button link primary onClick={handleClick}>
            LOGOUT
          </Button>
        </Items>
      </Nav>
      {children}
    </>
  );
}

// @flow
import type { Brand } from '../reducers/brand';
import type { Error } from '../types/error';

const LoadBrand: 'LOAD BRAND DATA' = 'LOAD BRAND DATA';
type LoadBrandAction = { type: typeof LoadBrand };
const loadBrand = (): LoadBrandAction => ({
  type: LoadBrand,
});

const LoadBrandSuccessful: 'LOAD BRAND DATA SUCCEEDED' =
  'LOAD BRAND DATA SUCCEEDED';
type LoadBrandSuccessfulAction = {
  type: typeof LoadBrandSuccessful,
  payload: Brand,
};
const loadBrandSuccessful = (brand: Brand): LoadBrandSuccessfulAction => ({
  type: LoadBrandSuccessful,
  payload: brand,
});

const LoadBrandFailure: 'LOAD BRAND DATA FAILED' = 'LOAD BRAND DATA FAILED';
type LoadBrandFailureAction = { type: typeof LoadBrandFailure, payload: Error };
const loadBrandFailure = (error: Error): LoadBrandFailureAction => ({
  type: LoadBrandFailure,
  payload: error,
});

export const constants = {
  LoadBrand,
  LoadBrandSuccessful,
  LoadBrandFailure,
};

export const actions = {
  loadBrand,
  loadBrandSuccessful,
  loadBrandFailure,
};

export type Action =
  | LoadBrandAction
  | LoadBrandSuccessfulAction
  | LoadBrandFailureAction;

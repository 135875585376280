import Icon from './Icon';

export { default as component } from './RetailerInformation';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Retailer Information';
export const id = 'RetailerInformation';
export const availableToAdministration = false;
export const description = 'Information about a retailer';
export const icon = Icon;

// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
};

const StyledSectionHeading = styled.h2`
  color: ${(props: Props) => props.theme.colours.secondary03};
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
`;

export default function SectionHeading(props: Props) {
  return <StyledSectionHeading {...props} />;
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Email({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 24 24" width="1em" height="1em">
        <g fill={colour} fillRule="evenodd">
          <path
            d="M21.04 3.037H2.93A2.933 2.933 0 0 0 0 5.967v12.378a2.933 2.933 0 0 0 2.93 2.93h18.105a2.933 2.933 0 0 0 2.93-2.93V5.973a2.93 2.93 0 0 0-2.925-2.936zm1.591 15.308c0 .877-.714 1.591-1.591 1.591H2.93a1.594 1.594 0 0 1-1.591-1.591V5.973c0-.878.714-1.592 1.592-1.592h18.104c.877 0 1.591.714 1.591 1.592v12.372h.005z"
            fillRule="nonzero"
          />
          <path d="M15.114 12.002l5.861-5.256a.673.673 0 0 0 .05-.947.673.673 0 0 0-.947-.05l-8.083 7.255-1.577-1.408c-.005-.005-.01-.01-.01-.015a.992.992 0 0 0-.109-.094L3.883 5.745a.669.669 0 0 0-.947.054.669.669 0 0 0 .054.947l5.93 5.301-5.905 5.529a.672.672 0 0 0 .917.982l5.995-5.608 1.627 1.452a.669.669 0 0 0 .892-.004l1.671-1.498 5.96 5.663A.669.669 0 0 0 21 17.59l-5.885-5.589z" />
        </g>
      </svg>
    </IconWrapper>
  );
}

Email.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

export const constants = {
  INIT: 'INIT',
  UPDATE_SESSION_PREFERENCES: 'UPDATE_SESSION_PREFERENCES',
  UPDATE_MULTIPLE_SESSION_PREFERENCES: 'UPDATE_MULTIPLE_SESSION_PREFERENCES',
};

export const actions = {
  updateSessionPreferences: (key, value) => ({
    type: constants.UPDATE_SESSION_PREFERENCES,
    payload: {
      key,
      value,
    },
  }),
  updateMultipleSessionPreferences: preferences => ({
    type: constants.UPDATE_MULTIPLE_SESSION_PREFERENCES,
    payload: preferences,
  }),
};

export const initialState = {
  error: null,
  cookiesAccepted: false,
  searchSort: null,
  language: null,
  infiniteScroll: false,
  disableCurrencyPopup: false,
  currency: null,
  marketSelected: false,
  location: {},
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.UPDATE_SESSION_PREFERENCES:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case constants.UPDATE_MULTIPLE_SESSION_PREFERENCES:
      return {
        ...state,
        ...payload.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.key]: curr.value,
          }),
          {},
        ),
      };
    default:
      return state;
  }
}

import { config } from 'cms-modules';

const {
  Config,
  Slice,
  Section,
  Font,
  Colour,
  Styles,
  TextArea,
  Text,
  Align,
} = config;
const type = ['full-width', 'compact'];

const placementOptions = [
  'top-left',
  'top-center',
  'top-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
];

const editor = Config(
  'Cookie Policy',
  { id: 'Cookie Policy' },
  Slice(
    'Layout',
    { id: 'Layout' },
    Section(
      'Layout',
      {},
      Styles('Compact', 'type', { options: type }),
      Styles('Placement', 'placement', { options: placementOptions }),
    ),
  ),
  Slice(
    'Content',
    { id: 'Content' },
    Section(
      'Text',
      {},
      TextArea('Body Text', 'bodyText'),
      Text('Button Text', 'buttonText'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'Styling' },
    Section(
      'Fonts',
      {},
      Font('Body text', 'bodyFont'),
      Colour('Background Colour', 'backgroundColour'),
      Align('Button Alignment', 'alignment'),
    ),
  ),
);

export default editor;

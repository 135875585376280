// @flow
import React from 'react';
import styled from 'styled-components';
import splitEvery from 'ramda/src/splitEvery';

import media from '../../theme';
import type { Option, Font } from '../../types';
import {
  HeadingOne,
  Paragraph,
  fontStyleOverride,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';

const Image = styled.img`
  width: 20%;
  min-width: 2.5em;
  height: 2.5em;
  ${({ styles }) =>
    `${
      styles.direction === 'rtl' ? 'margin-left: 10px;' : 'margin-right: 10px;'
    }`};
  ${media.max.medium`
    width: auto;
    ${({ styles }) =>
      `${
        styles.direction === 'rtl'
          ? 'margin-left: 20px;'
          : 'margin-right: 20px;'
      }`};
  `};
`;

const StyledImage = styled(Image)`
  ${({ styles }) =>
    `${styles.brickItemIcon &&
      `width:   ${styles.brickItemIcon.width.value}`}`};
  ${({ styles }) =>
    `${styles.brickItemIcon &&
      `height:   ${styles.brickItemIcon.height.value}`}`};
  ${({ styles }) =>
    `${styles.brickItemIcon &&
      `min-width:   ${styles.brickItemIcon.minWidth}`}`};
  ${({ styles }) =>
    `${styles.brickItemIcon &&
      styles.brickItemIcon.MarginRight &&
      `${styles.direction === 'rtl' ? 'margin-left:' : 'margin-right:'}   ${
        styles.brickItemIcon.MarginRight.value
      }`}`};
`;

type Item = {
  image: { label: string, value: string },
  translations: {
    headlineText: string,
    descriptionText: string,
  },
};

export interface Config {
  items: Item[];
  padding: string;
  brickBackgroundColour: Option;
  brickItemHeaderFont: Font;
  brickItemDescriptionFont: Font;
  brickPartitionBorderActive: boolean;
  brickPartitionBorderColour: Option;
}

type Props = {
  config: Config,
  globalStyling: Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  box-sizing: border-box;

  ${({ itemLength }) =>
    itemLength === 4
      ? media.min.large`flex-direction: row;`
      : media.min.medium`flex-direction: row;`};
`;

const BrickWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  padding: 24px 20px;
`;

const BrickContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  align-items: center;
`;

const BrickText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StackedItemWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  ${media.min.medium`
    flex-direction: row;
  `};
`;

type BrickProps = {
  config: Object,
  brick: Object,
  index: number,
};
const Brick = ({ config, brick, direction }: BrickProps) => (
  <BrickWrapper>
    <BrickContent>
      <StyledImage
        src={brick.image && brick.image.value}
        alt={brick.image && brick.image.label}
        styles={{ ...config, direction }}
      />
      <BrickText>
        <HeadingOne
          styleOverride={() => `
          ${fontStyleOverride(config.brickItemHeaderFont)};
          margin: 0;
          `}
        >
          {brick.translations.headlineText}
        </HeadingOne>
        {brick.translations.descriptionText && (
          <Paragraph
            styleOverride={() => `
            ${fontStyleOverride(config.brickItemDescriptionFont)};
            margin: 0;
            `}
          >
            {brick.translations.descriptionText}
          </Paragraph>
        )}
      </BrickText>
    </BrickContent>
  </BrickWrapper>
);

const StackedItems = ({ config, globalStyling }: Props) => {
  if (config.items.length === 4) {
    return (
      <Wrapper itemLength={config.items.length}>
        {splitEvery(2, config.items).map((brickPair, idx) => (
          <StackedItemWrapper key={`brickPair-${idx + 1}`}>
            {brickPair.map((brick, index) => (
              <Brick
                key={`brickItem-${index + 1}`}
                brick={brick}
                config={config}
                direction={globalStyling.direction}
              />
            ))}
          </StackedItemWrapper>
        ))}
      </Wrapper>
    );
  }
  return (
    <Wrapper itemLength={config.items.length}>
      {config.items.map((brick, index) => (
        <Brick
          key={`brickItem-${index + 1}`}
          brick={brick}
          config={config}
          direction={globalStyling.direction}
        />
      ))}
    </Wrapper>
  );
};

export default function BrickPanel(props: Props) {
  const { config, globalStyling } = props;
  return (
    <ContentWrapper
      colour={config.brickBackgroundColour && config.brickBackgroundColour}
      contentWidth={globalStyling.contentWidth}
    >
      <StackedItems {...props} />
    </ContentWrapper>
  );
}

import { config } from 'cms-modules';

const {
  AlignLeftRight,
  Colour,
  Config,
  Font,
  Slice,
  Section,
  TextArea,
  MediaPicker,
} = config;

const editor = Config(
  'Video',
  { id: 'Video' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section('Layout', {}, AlignLeftRight('Alignment', 'horizontalAlignment')),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      MediaPicker(
        'Video',
        { media: 'media', bodyText: 'bodyText', headingText: 'headingText' },
        { mediaTypes: ['video', 'youtube'] },
      ),
      TextArea('Heading', 'headlineText', { rows: 3 }),
      TextArea('Paragraph', 'bodyText'),
      TextArea('Video Transcript', 'transcript'),
    ),
  ),
  Slice(
    'Styles',
    { id: 'styles' },
    Section(
      'Fonts',
      {},
      Font('Heading', 'headlineFont'),
      Font('Paragraph', 'bodyFont'),
      Colour('Background Colour', 'backgroundColour'),
    ),
  ),
);

export default editor;

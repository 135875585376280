// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Tick({
  background,
  colour,
  width,
  height,
  fill,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 29 25">
        <path
          fill={fill}
          d="M23.708 5.331a.998.998 0 0 0-1.412 0L10.34 17.29l-4.596-4.597a.998.998 0 0 0-1.412 1.412l5.302 5.302a.998.998 0 0 0 1.412 0L23.708 6.743a.998.998 0 0 0 0-1.412z"
        />
      </svg>
    </IconWrapper>
  );
}

Tick.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
  fill: 'none',
};

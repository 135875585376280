// @flow
/* eslint-disable no-unused-vars */
import * as opentype from 'opentype.js/dist/opentype';
import eotParser from 'eot-parser';
import fileType from 'file-type-es5';

import fileReader from './fileReader';

const supportedFontFormats = ['eot', 'ttf', 'otf', 'woff'];
function parserStrategy(fontType) {
  switch (fontType) {
    case 'ttf':
    case 'otf':
    case 'woff':
      return {
        parse: arrayBuffer =>
          new Promise(resolve => {
            const font = opentype.parse(arrayBuffer);
            const fn = font.names.fullName;
            // Grab the English name if you can, otherwise literally anything else will do.
            return resolve(
              fn.en.replace(' ', '') ||
                fn[Object.keys(fn)[0]].replace(' ', '') ||
                'Unknown Font',
            );
          }),
      };
    case 'eot':
      return {
        parse: arrayBuffer =>
          eotParser(Buffer.from(arrayBuffer)).then(result => result.fullName),
      };
    default:
      return {
        parse: arrayBuffer => Promise.resolve(''),
      };
  }
}
export async function getFontName(file: Blob) {
  try {
    const arrayBuffer = await fileReader(file);
    const { ext } = fileType(arrayBuffer);
    if (!supportedFontFormats.includes(ext)) {
      // We could not throw here???
      throw new Error('Unsupported file type');
    }
    const strategy = parserStrategy(ext);
    const fontName = await strategy.parse(arrayBuffer);
    return fontName;
  } catch (error) {
    throw error;
  }
}

export const extractFileExtensions = (label: string, fonts: Object[]) =>
  fonts
    .filter(f => f.label === label)
    .map(f => f.type.toLowerCase())
    .join(', ');

import styled from 'styled-components';

import media from '../../../theme';

export default styled.div`
  width: 100%;
  ${media.min.medium`
    width: 360px;
  `};
`;

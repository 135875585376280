// @flow
import type { Error } from '../types/error';
import type { Translation } from '../types/translation';

const StageFileForImport: 'STAGE FILE FOR IMPORT' = 'STAGE FILE FOR IMPORT';
export type StagedFilePayload = {
  id: string,
  file: File,
};
export type StageFileAction = {
  type: typeof StageFileForImport,
  payload: StagedFilePayload,
};
const stageFileForImport = (id: string, file: File): StageFileAction => ({
  type: StageFileForImport,
  payload: {
    id,
    file,
  },
});

const LoadTranslations: 'LOAD TRANSLATIONS' = 'LOAD TRANSLATIONS';
type LoadTranslationsPayload = {
  siteId: string,
};
export type LoadTranslationsAction = {
  type: typeof LoadTranslations,
  payload: LoadTranslationsPayload,
};
const loadTranslations = (siteId: string): LoadTranslationsAction => ({
  type: LoadTranslations,
  payload: {
    siteId,
  },
});

const LoadTranslationsSuccess: 'LOAD TRANSLATIONS SUCCESS' =
  'LOAD TRANSLATIONS SUCCESS';
type LoadTranslationsSuccessPayload = { translations: Translation[] };
export type LoadTranslationsSuccessAction = {
  type: typeof LoadTranslationsSuccess,
  payload: LoadTranslationsSuccessPayload,
};
const loadTranslationsSuccess = (
  translations: Translation[],
): LoadTranslationsSuccessAction => ({
  type: LoadTranslationsSuccess,
  payload: {
    translations,
  },
});

const LoadTranslationsFailure: 'LOAD TRANSLATIONS FAILURE' =
  'LOAD TRANSLATIONS FAILURE';
export type LoadTranslationsFailureAction = {
  type: typeof LoadTranslationsFailure,
  payload: Error,
};
const loadTranslationsFailure = (
  payload: Error,
): LoadTranslationsFailureAction => ({
  type: LoadTranslationsFailure,
  payload,
});

const ImportTranslationFile: 'IMPORT TRANSLATION FILE' =
  'IMPORT TRANSLATION FILE';
type ImportTranslationFilePayload = {
  siteId: string,
  translationId: string,
  file: File,
  defaultIso: string,
  translationIso: string,
  date: Date,
};
export type ImportTranslationFileAction = {
  type: typeof ImportTranslationFile,
  payload: ImportTranslationFilePayload,
};
const importTranslationFile = (
  siteId: string,
  translationId: string,
  file: File,
  defaultIso: string,
  translationIso: string,
  date: Date,
): ImportTranslationFileAction => ({
  type: ImportTranslationFile,
  payload: { siteId, translationId, file, defaultIso, translationIso, date },
});

const ExportTranslationFile: 'EXPORT TRANSLATION FILE' =
  'EXPORT TRANSLATION FILE';
type ExportTranslationFilePayload = {
  siteId: string,
  translationId: string,
  defaultIso: string,
  translationIso: string,
};
export type ExportTranslationFileAction = {
  type: typeof ExportTranslationFile,
  payload: ExportTranslationFilePayload,
};
const exportTranslationFile = (
  siteId: string,
  translationId: string,
  defaultIso: string,
  translationIso: string,
): ExportTranslationFileAction => ({
  type: ExportTranslationFile,
  payload: { siteId, translationId, defaultIso, translationIso },
});

const ExportTranslationFileFailure: 'EXPORT TRANSLATIONS FILE FAILURE' =
  'EXPORT TRANSLATIONS FILE FAILURE';
export type ExportTranslationFileFailureAction = {
  type: typeof ExportTranslationFileFailure,
  payload: Error,
};
const exportTranslationFileFailure = (
  payload: Error,
): ExportTranslationFileFailureAction => ({
  type: ExportTranslationFileFailure,
  payload,
});

const MarkTranslationAsUploading: 'MARK TRANSLATION AS UPLOADING' =
  'MARK TRANSLATION AS UPLOADING';
export type MarkTranslationAsUploadingPayload = { id: string };
export type MarkTranslationAsUploadingAction = {
  type: typeof MarkTranslationAsUploading,
  payload: MarkTranslationAsUploadingPayload,
};
const markTranslationAsUploading = (
  id: string,
): MarkTranslationAsUploadingAction => ({
  type: MarkTranslationAsUploading,
  payload: { id },
});

const TranslationUploadedSuccess: 'TRANSLATION UPLOADED SUCCESSFULLY' =
  'TRANSLATION UPLOADED SUCCESSFULLY';
export type TranslationUploadedSuccessPayload = {
  id: string,
  lastUpdated: Date,
};
export type TranslationUploadedSuccessAction = {
  type: typeof TranslationUploadedSuccess,
  payload: TranslationUploadedSuccessPayload,
};
const translationUploadedSuccess = (
  id: string,
  lastUpdated: Date,
): TranslationUploadedSuccessAction => ({
  type: TranslationUploadedSuccess,
  payload: { id, lastUpdated },
});

const TranslationUploadFailure: 'TRANSLATION UPLOAD FAILURE' =
  'TRANSLATION UPLOAD FAILURE';
type TranslationUploadFailurePayload = { id: string };
export type TranslationUploadFailureAction = {
  type: typeof TranslationUploadFailure,
  payload: TranslationUploadFailurePayload,
};
const translationUploadFailure = (
  id: string,
): TranslationUploadFailureAction => ({
  type: TranslationUploadFailure,
  payload: { id },
});

export const constants = {
  StageFileForImport,
  LoadTranslations,
  LoadTranslationsSuccess,
  LoadTranslationsFailure,
  ImportTranslationFile,
  ExportTranslationFile,
  ExportTranslationFileFailure,
  MarkTranslationAsUploading,
  TranslationUploadedSuccess,
  TranslationUploadFailure,
};

export const actions = {
  stageFileForImport,
  loadTranslations,
  loadTranslationsSuccess,
  loadTranslationsFailure,
  importTranslationFile,
  exportTranslationFile,
  exportTranslationFileFailure,
  markTranslationAsUploading,
  translationUploadedSuccess,
  translationUploadFailure,
};

export type Action =
  | StageFileAction
  | LoadTranslationsAction
  | LoadTranslationsSuccessAction
  | LoadTranslationsFailureAction
  | ImportTranslationFileAction
  | ExportTranslationFileAction
  | MarkTranslationAsUploadingAction
  | TranslationUploadedSuccessAction
  | TranslationUploadFailureAction;

import Icon from './Icon';

export { id as reducerId } from './reducer';

export { default as component } from './ShortlistGrid';
export { default as effects } from './effects';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';
export { actions, constants, reducer, initialState } from './reducer';

export const name = 'ShortlistGrid';
export const id = 'ShortlistGrid';
export const availableToAdministration = false;
export const description =
  'A module that displays the shortlisted vehicles of the user';
export const icon = Icon;

import {
  moduleActionWithArgs,
  moduleAction,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_CUSTOMER_LOGIN';
const id = 'Login';

export const constants = moduleConstants(modulePrefix, [
  'CREATE_ACCOUNT',
  'LOGIN',
  'LOGIN_SUCCESS',
  'LOGIN_FAILURE',
  'CREATE_CUSTOMER_SUCCESS',
  'CREATE_CUSTOMER_FAILURE',
  'GET_CUSTOMER_SUCCESS',
  'GET_CUSTOMER_FAILURE',
  'SAVE_USER_DETAILS',
  'SAVE_USER_DETAILS_SUCCESS',
  'SAVE_USER_DETAILS_FAILURE',
  'CHANGE_PASSWORD',
  'CHANGE_PASSWORD_SUCCESS',
  'CHANGE_PASSWORD_FAILURE',
  'DELETE_ACCOUNT',
  'DELETE_ACCOUNT_SUCCESS',
  'DELETE_ACCOUNT_FAILURE',
  'SIGN_OUT',
]);

const MILLISECONDS_IN_MINUTE = 60000;
const OFFSET = 10 * MILLISECONDS_IN_MINUTE;

export const actions = {
  postCreateAccount: moduleActionWithArgs(
    constants.CREATE_ACCOUNT,
    id,
    payload => ({
      ...payload,
    }),
  ),
  postLogin: moduleActionWithArgs(constants.LOGIN, id, payload => ({
    ...payload,
  })),
  postLoginSuccess: moduleActionWithArgs(
    constants.LOGIN_SUCCESS,
    id,
    payload => ({ ...payload }),
  ),
  postLoginFailure: moduleActionWithArgs(
    constants.LOGIN_FAILURE,
    id,
    payload => ({ ...payload }),
  ),
  createCustomerAccountSuccess: moduleActionWithArgs(
    constants.CREATE_CUSTOMER_SUCCESS,
    id,
    payload => ({
      ...payload,
    }),
  ),
  createCustomerAccountFailure: moduleActionWithArgs(
    constants.CREATE_CUSTOMER_FAILURE,
    id,
    failMessage => ({
      ...failMessage,
    }),
  ),
  postGetCustomerSuccess: moduleActionWithArgs(
    constants.GET_CUSTOMER_SUCCESS,
    id,
    payload => ({
      ...payload,
    }),
  ),
  postGetCustomerFailure: moduleActionWithArgs(
    constants.GET_CUSTOMER_FAILURE,
    id,
    failMessage => ({
      ...failMessage,
    }),
  ),
  saveUserDetails: moduleActionWithArgs(
    constants.SAVE_USER_DETAILS,
    id,
    details => ({
      ...details,
    }),
  ),
  saveUserDetailsSuccess: moduleActionWithArgs(
    constants.SAVE_USER_DETAILS_SUCCESS,
    id,
    successMessage => ({ ...successMessage }),
  ),
  saveUserDetailsFailure: moduleActionWithArgs(
    constants.SAVE_USER_DETAILS_FAILURE,
    id,
    failMessage => ({ ...failMessage }),
  ),
  changePassword: moduleActionWithArgs(
    constants.CHANGE_PASSWORD,
    id,
    passwords => ({
      ...passwords,
    }),
  ),
  changePasswordSuccess: moduleActionWithArgs(
    constants.CHANGE_PASSWORD_SUCCESS,
    id,
    successMessage => ({ ...successMessage }),
  ),
  changePasswordFailure: moduleActionWithArgs(
    constants.CHANGE_PASSWORD_FAILURE,
    id,
    failMessage => ({ ...failMessage }),
  ),
  deleteAccount: moduleActionWithArgs(
    constants.DELETE_ACCOUNT,
    id,
    password => ({ ...password }),
  ),
  deleteAccountSuccess: moduleAction(constants.DELETE_ACCOUNT_SUCCESS, id),
  deleteAccountFailure: moduleActionWithArgs(
    constants.DELETE_ACCOUNT_FAILURE,
    id,
    failMessage => ({
      ...failMessage,
    }),
  ),
  signOut: moduleAction(constants.SIGN_OUT, id),
};

export const initialState = {
  // @TODO: authenticationDetails will be renamed
  authenticationDetails: null,
  customerDetails: null,
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.LOGIN_SUCCESS: {
      return {
        ...state,
        authenticationDetails: payload.auth,
      };
    }
    case constants.LOGIN_FAILURE: {
      return {
        ...state,
        authenticationDetails: null,
      };
    }
    case constants.GET_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customerDetails: payload.customerDetails,
      };
    }
    case constants.DELETE_ACCOUNT_SUCCESS:
    case constants.SIGN_OUT: {
      return {
        ...state,
        customerDetails: null,
        authenticationDetails: null,
      };
    }
    default:
      return state;
  }
}

// selectors
export const getToken = state =>
  (state.customerLogin.authenticationDetails || {}).accessToken;
export const getCustomerDetails = state => state.customerLogin.customerDetails;
export const isTokenValid = state =>
  Date.parse((state.customerLogin.authenticationDetails || {}).expiresAt) >
  Date.now() + OFFSET;

const tokenExists = state => !!state.customerLogin.authenticationDetails;

export const selectors = {
  getToken,
  getCustomerDetails,
  isTokenValid,
  tokenExists,
};

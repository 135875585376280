export default {
  translations: {
    compareLink: 'Compare',
    shortlistLink: 'shortlist',
    compareVehiclesHeader: 'compare',
    compareVehiclesSubHeader:
      'Compare key features and specifications of u p to 3 jaguar cars side-by-side.',
    comparePrice: 'Price',
    compareVehiclesModelYear: 'Model Year',
    compareVehiclesExterior: 'Exterior',
    compareVehiclesInterior: 'Interior',
    compareVehiclesOdometer: 'Kilometer',
    compareVehiclesTransmission: 'Transmission',
    compareVehiclesBodystyle: 'Body Style',
    compareVehiclesEngine: 'Engine',
    compareVehiclesFuelType: 'Fuel Type',
    compareVehiclesRegistration: 'Registration',
    compareVehiclesLocation: 'Location',
    compareVehiclesOptionalExtras: 'optional extras',
    compareVehiclesRequestInfo: 'request information',
    compareVehiclesSaveText: 'Save',
    compareVehiclesEmissions: 'CO2',
    compareVehiclesFuelConsumption: 'Distance/PG',
  },
};

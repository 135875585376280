// @flow
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  Button,
  buttonStyleOverride,
  HeadingTwo,
  fontStyleOverride,
  Paragraph,
} from '../Global';
import { Wrapper, TextWrapper } from '..';
import { actions as routerActions } from '../../../actions/router';
import type { Font, ButtonType, Padding } from '../../../types';

interface IHeadingTextButtonConfig {
  alignment: string;
  headlineFont: Font;
  translations: {
    headlineText: string,
    bodyText: string,
    buttonText: string,
  };
  padding: Padding;
  bodyFont: Font;
  alignment: string;
  button: ButtonType;
  backgroundColour: Option;
}

type Props = {
  config: IHeadingTextButtonConfig,
  globalStyling: Object,
  dispatch: any => void,
  children: any,
};

const buttonAlignMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.alignment || 'center'};
`;

export default (props: Props) => {
  const {
    config: {
      headlineFont,
      translations,
      bodyFont,
      alignment,
      button,
      padding,
      backgroundColour,
    },
    globalStyling: { uiElements = {} } = {},
    children,
  } = props;

  const dispatch = useDispatch();
  return (
    <Wrapper backgroundColour={backgroundColour}>
      {children && children(props)}
      <TextWrapper padding={padding}>
        <HeadingTwo
          styleOverride={() =>
            fontStyleOverride({ ...headlineFont, alignment })
          }
        >
          {translations && translations.headlineText}
        </HeadingTwo>
        {translations.bodyText && (
          <Paragraph
            styleOverride={() => `
            ${fontStyleOverride({ ...bodyFont, alignment })};
            flex: 1 0 auto;
          `}
          >
            {translations.bodyText}
          </Paragraph>
        )}
        {button && (
          <ButtonWrapper {...{ alignment: buttonAlignMapping[alignment] }}>
            <Button
              buttonStyle={
                uiElements.primaryButton && uiElements.primaryButton.buttonStyle
              }
              applyStyle="primary"
              styleOverride={() => buttonStyleOverride(button)}
              text={translations.buttonText}
              onClick={() => dispatch(routerActions.navigate(button.url))}
            />
          </ButtonWrapper>
        )}
      </TextWrapper>
    </Wrapper>
  );
};

import uuid from 'uuid/v1';

export default function() {
  return {
    items: [
      {
        translations: {
          itemTitle: 'Title Placeholder',
        },
        image: {
          label: 'default_icongrid_icon',
          value:
            'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg',
        },
      },
      {
        translations: {
          itemTitle: 'Title Placeholder',
        },
        image: {
          label: 'default_icongrid_icon',
          value:
            'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg',
        },
        itemId: uuid(),
      },
    ],
    translations: {
      headLine: 'Headline',
    },
    headlineFont: {
      fontSize: '22',
      alignment: 'center',
    },
    itemTitleFont: {
      fontSize: '16',
    },
    descriptionFont: {
      fontSize: '14',
    },
  };
}

// @flow
import React from 'react';
import styled from 'styled-components';
import IoTrashB from 'react-icons/lib/io/trash-b';
import IoLocked from 'react-icons/lib/io/locked';
import Button from './Button';

import type { Theme } from '../theme';

type Props = {
  name: string,
  value?: string,
  onChange: string => void,
  editing?: boolean,
  onEdit: boolean => void,
  onDelete: () => void,
  lockDelete: boolean,
  editOnly: boolean,
  small: boolean,
};

type ThemeProps = { theme: Theme };

const EditTextContainer = styled.div.withConfig({
  displayName: 'EditTextContainer',
})`
  display: flex;
  flex: 1 0 0%;
  align-items: stretch;
  margin-bottom: ${(props: ThemeProps & Props) =>
    props.small ? '0' : props.theme.layout.standardPadding};
  width: 90%;
`;

const Input = styled.input.withConfig({
  displayName: 'InputField',
})`
  flex: 1 0 0%;
  background: transparent;
  width: ${(props: Props) => (props.small ? '100%' : 'auto')};
  border: ${(props: Props) => (props.disabled ? 'none' : '1px solid')};
  position: relative;
  margin: 0 4px 0 0;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: ${(props: Props) => (props.disabled ? 'none' : 'text')};
  &::placeholder {
    color: ${(props: ThemeProps) => props.theme.colours.placeholder};
  }
  &:focus {
    border-color: ${({ theme }: ThemeProps) => theme.colours.primary01};
    outline: none;
    appearance: none;
  }
`;

const EditIconsContainer = styled.div.withConfig({
  displayName: 'EditIconsContainer',
})`
  display: flex;
`;

const Icon = styled.img`
  color: ${(props: ThemeProps) => props.theme.colours.primary01};
  padding-left: ${(props: ThemeProps & Props) =>
    props.small ? '0' : props.theme.layout.standardPadding};
  cursor: pointer;
`;

const DeleteIcon = styled(IoTrashB).withConfig({
  displayName: 'DeleteIcon',
})`
  color: ${(props: ThemeProps) => props.theme.colours.primary01};
  padding-left: ${(props: ThemeProps & Props) =>
    props.small ? '0' : props.theme.layout.standardPadding};
  cursor: pointer;
`;

const LockIcon = styled(IoLocked).withConfig({
  displayName: 'LockIcon',
})`
  color: ${(props: ThemeProps) => props.theme.colours.primary01};
  padding-left: ${(props: ThemeProps & Props) =>
    props.small ? '0' : props.theme.layout.standardPadding};
`;

const DoneButton = styled(Button).withConfig({
  displayName: 'DoneButton',
})`
  padding: 6px 10px;
`;

type IconProps = {
  onEditClick: boolean => void,
  onDeleteClick: () => void,
  lockDelete: boolean,
  editOnly: boolean,
  small: boolean,
};

function EditIcons({
  onEditClick: handleEdit,
  onDeleteClick: handleDelete,
  lockDelete,
  editOnly,
}: IconProps) {
  return (
    <EditIconsContainer>
      <Icon
        onClick={handleEdit}
        src="https://res.cloudinary.com/motortrak/image/upload/v1570021938/editor/edit-icon.svg"
      />
      {!editOnly &&
        (lockDelete ? (
          <LockIcon small />
        ) : (
          <DeleteIcon small onClick={handleDelete} />
        ))}
    </EditIconsContainer>
  );
}

export default class EditText extends React.Component<Props, *> {
  static defaultProps = {
    name: 'Edit Text',
    onChange: () => {},
    onEdit: () => {},
    onDelete: () => {},
    lockDelete: false,
    editOnly: false,
    small: false,
  };

  componentDidMount() {
    this.focusOnEdit();
  }

  // Element must be focussed *after* update to ensure that the element we are focussing is rendered
  // (and/or will not be immediately re-rendered).
  componentDidUpdate({ editing }: Props) {
    if (!editing) {
      this.focusOnEdit();
    }
  }

  focusOnEdit() {
    if (this.element && this.props.editing) {
      this.element.focus();
    }
  }

  element: ?HTMLElement = null;

  render() {
    const {
      name,
      value,
      editing,
      onChange,
      onEdit,
      onDelete,
      lockDelete,
      editOnly,
      small,
    } = this.props;
    return (
      <EditTextContainer small={small}>
        <Input
          small={small}
          name={name}
          value={value}
          type="text"
          disabled={!editing}
          onChange={event => onChange(event.target.value)}
          ref={input => {
            this.element = input;
          }}
        />
        {editing ? (
          <DoneButton
            small
            primary
            textTransform="uppercase"
            onClick={() => onEdit(false)}
            disabled={value === ''}
          >
            Done
          </DoneButton>
        ) : (
          <EditIcons
            small={small}
            onEditClick={() => onEdit(true)}
            onDeleteClick={onDelete}
            lockDelete={lockDelete}
            editOnly={editOnly}
          />
        )}
      </EditTextContainer>
    );
  }
}

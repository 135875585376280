import Icon from './Icon';

export { default as component } from './VehicleOverview';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Vehicle Overview';
export const id = 'VehicleOverview';
export const availableToAdministration = true;
export const description = 'Vehicle Overview Module';
export const icon = Icon;

export const searchable = true;
export const getIndexContent = ({
  config: { translations = {} } = {},
  relativePath,
  instanceId,
}) =>
  [
    {
      title: translations.vdpAccessoriesHeader,
      content: translations.vdpAccessoriesSubHeader,
    },
    {
      title: translations.vdpMyDealHeader,
      content: translations.vdpGalleryAndMyDealBottomText,
    },
  ]
    .map(({ title, content }, index) => ({
      key: `${instanceId}-${index}`,
      title,
      content,
      link: relativePath,
    }))
    .filter(({ title, content }) => !!title || !!content);

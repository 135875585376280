// @flow
import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
  height: 40px;
  cursor: pointer;
  width: 100%;
  padding: 0 8px;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  background-color: #ffffff;
`;

type Props = {
  filterKey: string,
  data: any[],
  selectedData: any,
  updateFilters: filterObj => void,
  fuelConsumptionText: string,
  distanceToLabel: string,
};

export default function SingleSelectFilter({
  filterKey,
  selectedData,
  data,
  updateFilters,
  fuelConsumptionText,
  distanceToLabel,
}: Props) {
  const fuelConsumptionOptionText = fuelConsumptionText || '';
  return (
    <Select
      value={selectedData || data[0].value}
      onChange={event => {
        const { value } = event.target;
        updateFilters([
          {
            key: filterKey,
            value: value === data[0].value ? [] : value,
          },
        ]);
      }}
    >
      {data.map(d => (
        <option key={d.label} value={d.value}>
          {(distanceToLabel && d.label.replace('Worldwide', distanceToLabel)) ||
            (fuelConsumptionOptionText &&
              fuelConsumptionOptionText.replace(/{VALUE}/g, d.value)) ||
            d.label}
        </option>
      ))}
    </Select>
  );
}

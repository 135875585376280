// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import media from '../../theme';

const Container = styled.div`
  background-color: #f0f0f0;
  height: auto;
  min-height: 450px;
  padding: 0 130px;
  width: auto;
  color: #444444;
  ${media.max.large`
    padding: 0 5%;
  `};
`;

type Props = {
  children: Node,
};
export default function MyAccountContent({ children }: Props) {
  return <Container>{children}</Container>;
}

// @flow
/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';

import VideoPlayer from '../../modules/VideoPlayer/VideoPlayer';
import FooterActionBanner from '../FooterActionBanner';
import TextImage from './TextImage';
import FiftyFifty from './FiftyFifty';
import Tabs from './Tabs';
import Performance from './Performance';
import VdpAccordion from '../VdpAccordion';
import AccessoryBox from './AccessoryBox';
import media from '../../theme';
import {
  Button,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Paragraph,
  fontStyleOverride,
} from '../Global';
import ContentWrapper from '../ContentWrapper';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

const TopImageTextMobile = styled.div.withConfig({
  displayName: 'TopImageText',
})`
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
  ${media.min.large`
    display: none;
  `};
`;

const AnchorsContainer = styled.div`
  ${media.min.large`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #474747;
  `} display: none;
`;

const VehicleMarketing = styled.div`
  ${media.min.large`
    width: 100%;
    display: block;
  `} display: none;
`;

const VehicleMarketingMobile = styled.div`
  ${media.min.large`
    display: none;
  `};
  display: block;
  width: 90%;
  padding: 0 5%;
`;

const ActionButtonContainer = styled.div`
  display: none;
  ${media.min.large`
    display: flex;
    height: 150px;
    width: 100%;
  `};
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ImageOverlayParent = styled.div`
  display: none;
  ${media.min.large`
    display: block;
    width: 33%;
    position: absolute;
    top: 50px;
    ${({ left }) => left && `left : ${left}%`};
  `};
`;
const ImageOverlayWrapper = styled.div`
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
  }
  position: relative;
  margin: 0 0 1px 0;
  padding: 20px 40px;
`;

const Image = styled.img`
  width: 100%;
`;

type Props = {
  vehicle: Object,
  searchForModel: (model: string) => void,
  navigateToFinance: Function,
  translations: Object,
  globalStyling: Object,
  featureFlags: Object,
  contentWidth: number,
  videoContentLeft: number,
};

const ImageWithOverlay = ({
  heading,
  subheading,
  body,
  footer,
  image,
  bodyFont,
  headingFont,
}) => (
  <ImageContainer>
    <ImageOverlayParent>
      <ImageOverlayWrapper>
        {heading && (
          <HeadingOne
            styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    ${fontStyleOverride(headingFont)}
                  `}
          >
            {heading}
          </HeadingOne>
        )}
        {subheading && (
          <HeadingOne
            styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    ${fontStyleOverride(headingFont)}
                  `}
          >
            {subheading}
          </HeadingOne>
        )}
        {body && (
          <HeadingTwo
            styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    ${fontStyleOverride(bodyFont)}
                  `}
          >
            {body}
          </HeadingTwo>
        )}
        {footer && (
          <HeadingTwo
            styleOverride={() => `
                    position: relative;
                    z-index: 20;
                    margin-top: 20px;
                    ${fontStyleOverride(bodyFont)}
                  `}
          >
            {footer}
          </HeadingTwo>
        )}
      </ImageOverlayWrapper>
    </ImageOverlayParent>
    <Image src={image.value} alt={image.label} />
  </ImageContainer>
);

export default function VehicleModel(props: Props) {
  const {
    vehicle,
    searchForModel,
    navigateToFinance,
    translations,
    globalStyling,
    featureFlags: { modelPageAccessories },
    contentWidth,
    videoContentLeft,
  } = props;
  const FinanceBtn = () => (
    <Button
      applyStyle="secondary"
      buttonStyle={
        globalStyling.uiElements.secondaryButton &&
        globalStyling.uiElements.secondaryButton.buttonStyle
      }
      onClick={navigateToFinance}
      text={translations.financeButtonText}
    />
  );
  const SearchBtn = () => (
    <Button
      applyStyle="primary"
      buttonStyle={
        globalStyling.uiElements.primaryButton &&
        globalStyling.uiElements.primaryButton.buttonStyle
      }
      onClick={() => searchForModel(vehicle.searchModelName)}
      text={translations.searchButtonText}
    />
  );
  const goToAnchor = (ele: string) =>
    document.getElementById(ele).scrollIntoView(true);

  const smallImage = 35;
  const largeImage = 65;

  const headingFont = {
    colour: {
      label: '#ffffff',
      value: '#ffffff',
    },
    kerning: 1,
    fontSize: 22,
    transform: 'uppercase',
    typeface:
      globalStyling.fonts.primaryFont && globalStyling.fonts.primaryFont,
  };

  const bodyFont = {
    colour: {
      label: '#cdcdcd',
      value: '#cdcdcd',
    },
    kerning: 1,
    fontSize: 16,
    transform: 'uppercase',
    typeface:
      globalStyling.fonts.secondaryFont && globalStyling.fonts.secondaryFont,
  };

  return (
    <div>
      {vehicle.headerImage && (
        <ImageWithOverlay
          heading={vehicle.translations.year}
          subheading={vehicle.translations.model}
          body={vehicle.description}
          footer={
            vehicle.priceFrom &&
            `${translations.priceFrom} ${vehicle.priceFrom}`
          }
          headingFont={headingFont}
          bodyFont={bodyFont}
          image={vehicle.headerImage}
        />
      )}
      {vehicle.headerVideo && (
        <VideoPlayer
          config={{
            ...vehicle.headerVideo,
            headingFont,
            bodyFont,
            translations: {
              heading: vehicle.translations.year,
              subheading: vehicle.translations.model,
              body: vehicle.description,
              footer:
                vehicle.priceFrom &&
                `${translations.priceFrom} ${vehicle.priceFrom}`,
            },
            overlayVisible: true,
            backgroundColour: {
              label: '#000000',
              value: '#000000',
            },
            backgroundOpacity: 0.8,
            contentWidth,
            videoContentLeft,
          }}
          preview={false}
        />
      )}
      <TopImageTextMobile>
        <HeadingOne
          styleOverride={() => `
            padding: 16px 30px 0 30px;
            line-height: 26px;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 22px;
          `}
        >
          {vehicle.translations.year} {vehicle.translations.model}
        </HeadingOne>
        <Paragraph
          styleOverride={() => `
            padding: 8px 30px;
            line-height: 22px;
            color: #cdcdcd;
            text-transform: uppercase;
            font-size: 16px;
          `}
        >
          {vehicle.description}
        </Paragraph>
        <HeadingThree
          styleOverride={() => `
            padding: 0 30px 20px 30px;
            line-height: 22px;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 16px;
          `}
        >
          {translations.priceFrom}
          {vehicle.priceFrom}
        </HeadingThree>
      </TopImageTextMobile>

      <AnchorsContainer>
        <ContentWrapper contentWidth={contentWidth} flex>
          <HeadingTwo
            styleOverride={() => `
            background-color: #474747;
            flex: 1 0 0%;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 20px 0;
            cursor: pointer;
            width: calc(100%/5);
            border-right: 1px solid #565656;
            text-transform: uppercase;
            color: #fff;
            font-size: 15px;
            margin: 0;
          `}
            onClick={() => goToAnchor('design')}
          >
            {translations.design}
          </HeadingTwo>
          <HeadingTwo
            styleOverride={() => `
            background-color: #474747;
            flex: 1 0 0%;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 20px 0;
            cursor: pointer;
            width: calc(100%/5);
            border-right: 1px solid #565656;
            text-transform: uppercase;
            color: #fff;
            font-size: 15px;
            margin: 0;
          `}
            onClick={() => goToAnchor('innovation')}
          >
            {translations.innovation}
          </HeadingTwo>
          <HeadingTwo
            styleOverride={() => `
            background-color: #474747;
            flex: 1 0 0%;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 20px 0;
            cursor: pointer;
            width: calc(100%/5);
            border-right: 1px solid #565656;
            text-transform: uppercase;
            color: #fff;
            font-size: 15px;
            margin: 0;
          `}
            onClick={() => goToAnchor('performance')}
          >
            {translations.performance}
          </HeadingTwo>
          {modelPageAccessories && (
            <HeadingTwo
              styleOverride={() => `
            background-color: #474747;
            flex: 1 0 0%;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 20px 0;
            cursor: pointer;
            width: calc(100%/5);
            border-right: 1px solid #565656;
            text-transform: uppercase;
            color: #fff;
            font-size: 15px;
            margin: 0;
          `}
              onClick={() => goToAnchor('accessories')}
            >
              {translations.accessories}
            </HeadingTwo>
          )}
          {vehicle.priceFrom && (
            <HeadingTwo
              styleOverride={() => `
            background-color:
              ${globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value};
            flex: 1 0 0%;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 20px 0;
            cursor: pointer;
            width: calc(100%/5);
            border-right: 1px solid #565656;
            text-transform: uppercase;
            color: #fff;
            font-size: 15px;
            margin: 0;
          `}
              onClick={() => searchForModel(vehicle.searchModelName)}
            >
              {translateFromTemplate(
                'searchModelButtonText',
                {
                  MODEL: vehicle.translations.model,
                },
                translations,
              )}
            </HeadingTwo>
          )}
        </ContentWrapper>
      </AnchorsContainer>
      <VehicleMarketing>
        <TextImage
          imageWidthPercent={50}
          alignment="row"
          title={vehicle.translations.conceptTitle}
          content={vehicle.translations.conceptDescription}
          images={vehicle.concept.imageUrls}
          iconColour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
          contentWidth={contentWidth}
        />
        <div id="design">
          <Tabs
            tabs={[
              {
                title: translations.interior,
                component: (
                  <TextImage
                    imageWidthPercent={largeImage}
                    alignment="row-reverse"
                    title={translations.design}
                    content={vehicle.translations.designDescriptionInterior}
                    images={vehicle.design.images.interior}
                    iconColour={
                      globalStyling.colours.primaryBrandColour &&
                      globalStyling.colours.primaryBrandColour.value
                    }
                    contentWidth={contentWidth}
                  />
                ),
              },
              {
                title: translations.exterior,
                component: (
                  <TextImage
                    imageWidthPercent={largeImage}
                    alignment="row"
                    title={translations.design}
                    content={vehicle.translations.designDescriptionExterior}
                    images={vehicle.design.images.exterior}
                    iconColour={
                      globalStyling.colours.primaryBrandColour &&
                      globalStyling.colours.primaryBrandColour.value
                    }
                    contentWidth={contentWidth}
                  />
                ),
              },
            ]}
          />
        </div>
        <div id="innovation">
          <TextImage
            imageWidthPercent={largeImage}
            alignment="row"
            title={translations.innovation}
            content={vehicle.translations.innovationDescription}
            images={vehicle.innovation.imageUrls}
            iconColour={
              globalStyling.colours.primaryBrandColour &&
              globalStyling.colours.primaryBrandColour.value
            }
            contentWidth={contentWidth}
          />
        </div>
        <Tabs
          tabs={vehicle.tabs.map((tab, index) => ({
            title: vehicle.translations[`technologyTab${index + 1}Title`],
            component: (
              <TextImage
                imageWidthPercent={smallImage}
                alignment="row-reverse"
                title=""
                content={
                  vehicle.translations[`technologyTab${index + 1}Description`]
                }
                images={tab.imageUrls}
                iconColour={
                  globalStyling.colours.primaryBrandColour &&
                  globalStyling.colours.primaryBrandColour.value
                }
                contentWidth={contentWidth}
              />
            ),
          }))}
        />
        <ContentWrapper contentWidth={contentWidth}>
          <div id="performance">
            <HeadingTwo
              styleOverride={() => `
                text-transform: uppercase;
                font-size: 20px;
                margin: 50px 5% 6px;
              `}
            >
              {translations.performance}
            </HeadingTwo>
            <Performance
              translations={vehicle.translations}
              performance={vehicle.performance}
            />
          </div>
        </ContentWrapper>
        {vehicle.footerImage && (
          <ImageWithOverlay
            heading={vehicle.translations.footerVideoHeading}
            subheading={vehicle.translations.footerVideoSubHeading}
            body={vehicle.translations.footerVideoBody}
            headingFont={headingFont}
            bodyFont={bodyFont}
            image={vehicle.footerImage}
          />
        )}
        {vehicle.footerVideo && (
          <VideoPlayer
            config={{
              ...vehicle.footerVideo,
              headingFont: {
                colour: {
                  label: '#ffffff',
                  value: '#ffffff',
                },
                kerning: 1,
                fontSize: 15,
                transform: 'uppercase',
                typeface:
                  globalStyling.fonts.primaryFont &&
                  globalStyling.fonts.primaryFont,
              },
              bodyFont: {
                colour: {
                  label: '#cdcdcd',
                  value: '#cdcdcd',
                },
                kerning: 1,
                fontSize: 16,
                transform: 'uppercase',
                typeface:
                  globalStyling.fonts.secondaryFont &&
                  globalStyling.fonts.secondaryFont,
              },
              translations: {
                heading: vehicle.translations.footerVideoHeading,
                subheading: vehicle.translations.footerVideoSubHeading,
                body: vehicle.translations.footerVideoBody,
              },
              overlayVisible: true,
              backgroundColour: {
                label: '#000000',
                value: '#000000',
              },
              backgroundOpacity: 0.8,
              contentWidth,
              videoContentLeft,
            }}
            preview={false}
            contentWidth={contentWidth}
          />
        )}
        {modelPageAccessories && (
          <ContentWrapper contentWidth={contentWidth}>
            <div id="accessories">
              {vehicle.accessories.length > 0 && (
                <HeadingTwo
                  styleOverride={() => `
                text-transform: uppercase;
                font-size: 20px;
                margin: 50px 5%;
              `}
                >
                  {translations.accessories}
                </HeadingTwo>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'table',
                  borderCollapse: 'separate',
                  borderSpacing: '1px',
                }}
              >
                {vehicle.accessories.map((accessory, index) => (
                  <AccessoryBox
                    key={accessory.title}
                    accessory={accessory}
                    translations={{
                      title:
                        vehicle.translations[`accessories${index + 1}Title`],
                      description:
                        vehicle.translations[
                          `accessories${index + 1}Description`
                        ],
                    }}
                  />
                ))}
              </div>
            </div>
          </ContentWrapper>
        )}
      </VehicleMarketing>
      <VehicleMarketingMobile>
        <VdpAccordion title={vehicle.translations.conceptTitle} openByDefault>
          <FiftyFifty
            title={vehicle.translations.conceptTitle}
            content={vehicle.translations.conceptDescription}
            image={vehicle.concept.imageUrl}
          />
        </VdpAccordion>
        <VdpAccordion title={translations.design}>
          <Tabs
            tabs={[
              {
                title: translations.interior,
                component: (
                  <TextImage
                    imageWidthPercent={smallImage}
                    alignment="row-reverse"
                    title={translations.design}
                    content={vehicle.translations.designDescriptionInterior}
                    images={vehicle.design.images.interior}
                    iconColour={
                      globalStyling.colours.primaryBrandColour &&
                      globalStyling.colours.primaryBrandColour.value
                    }
                    contentWidth={contentWidth}
                  />
                ),
              },
              {
                title: translations.exterior,
                component: (
                  <TextImage
                    imageWidthPercent={smallImage}
                    alignment="row"
                    title={translations.design}
                    content={vehicle.translations.designDescriptionExterior}
                    images={vehicle.design.images.exterior}
                    iconColour={
                      globalStyling.colours.primaryBrandColour &&
                      globalStyling.colours.primaryBrandColour.value
                    }
                    contentWidth={contentWidth}
                  />
                ),
              },
            ]}
          />
        </VdpAccordion>
        <VdpAccordion title={translations.innovation}>
          <TextImage
            alignment="row"
            title={translations.innovation}
            content={vehicle.translations.innovationDescription}
            images={vehicle.innovation.imageUrls}
            iconColour={
              globalStyling.colours.primaryBrandColour &&
              globalStyling.colours.primaryBrandColour.value
            }
            contentWidth={contentWidth}
          />
        </VdpAccordion>
        <VdpAccordion title={translations.technology}>
          <Tabs
            tabs={vehicle.tabs.map((tab, index) => ({
              title: vehicle.translations[`technologyTab${index + 1}Title`],
              component: (
                <TextImage
                  imageWidthPercent={smallImage}
                  alignment={index % 2 === 0 ? 'row-reverse' : 'row'}
                  title=""
                  content={
                    vehicle.translations[`technologyTab${index + 1}Description`]
                  }
                  images={tab.imageUrls}
                  iconColour={
                    globalStyling.colours.primaryBrandColour &&
                    globalStyling.colours.primaryBrandColour.value
                  }
                  contentWidth={contentWidth}
                />
              ),
            }))}
          />
        </VdpAccordion>
        <VdpAccordion title={translations.performance}>
          <Performance
            translations={vehicle.translations}
            performance={vehicle.performance}
          />
        </VdpAccordion>
        <VdpAccordion title={translations.accessories}>
          <div id="accessories" style={{ width: '100%' }}>
            {vehicle.accessories.map((accessory, index) => (
              <AccessoryBox
                key={accessory.title}
                accessory={accessory}
                translations={{
                  title: vehicle.translations[`accessories${index + 1}Title`],
                  description:
                    vehicle.translations[`accessories${index + 1}Description`],
                }}
              />
            ))}
          </div>
        </VdpAccordion>
      </VehicleMarketingMobile>
      {vehicle.priceFrom && (
        <ActionButtonContainer>
          <div style={{ margin: 'auto' }}>
            <Button
              applyStyle="primary"
              buttonStyle={
                globalStyling.uiElements.primaryButton &&
                globalStyling.uiElements.primaryButton.buttonStyle
              }
              onClick={() => searchForModel(vehicle.searchModelName)}
              text={`${translations.searchButtonText} ${
                vehicle.translations.model
              }`}
            />
          </div>
        </ActionButtonContainer>
      )}
      {vehicle.priceFrom && (
        <FooterActionBanner leftButton={FinanceBtn} rightButton={SearchBtn} />
      )}
    </div>
  );
}

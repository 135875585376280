// @flow
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SectionMenuItem } from 'cms-ui';

import { actions as contextActions } from '../state/actions/context';
import { breakoutType } from './BreakoutWrapper';

type Props = {
  onEnterBreakout: Function,
  forms: Object[],
  setForm: Function,
};

const SectionMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 36px;
  border-top: ${({ theme }) => `1px solid ${theme.colours.secondary02}`};
`;

const MenuContent = styled.span`
  display: flex;
  align-items: center;
`;

const SelectFormMenu = ({ onEnterBreakout }: Props) => {
  const dispatch = useDispatch();
  const forms = useSelector(state => {
    const pages = Object.values(state.config.pages).filter(
      p => p.pageType === 'Form' && !p.removed,
    );

    const bandsAndPages = pages.map(p => ({
      pageId: p.id,
      bandId: p.modules[0],
    }));

    const bandIds = bandsAndPages.map(b => b.bandId);
    const podAndBands = Object.values(state.config.bands)
      .filter(b => bandIds.includes(b.instanceId))
      .map(b => ({ bandId: b.instanceId, podId: b.config.pods[0] }));

    const podIds = podAndBands.map(p => p.podId);

    return Object.values(state.config.pods)
      .filter(p => podIds.includes(p.instanceId))
      .map(pod => ({
        pod,
        pageId: bandsAndPages.find(
          bp =>
            podAndBands.find(pb => pb.podId === pod.instanceId).bandId ===
            bp.bandId,
        ).pageId,
      }));
  });

  const setPodContext = (pod, defaultSlice, breakout) => {
    dispatch(contextActions.setPreviousBreakoutContext({ name: breakout }));
    dispatch(
      contextActions.setPodContext({ id: pod.id, instanceId: pod.instanceId }),
    );
    dispatch(contextActions.setSliceContext(defaultSlice));
  };
  return (
    <SectionMenu>
      {forms &&
        forms.map(({ pod: form, pageId }) => (
          <SectionMenuItem
            key={form.instanceId}
            onClick={() => {
              setPodContext(form, 'Form', 'listForms');
              onEnterBreakout({
                name: breakoutType.editForm,
                data: { id: form.instanceId, pageId },
              });
              dispatch(contextActions.setGlobalContext('Edit Form Content'));
            }}
            withBorder
          >
            <MenuContent>{form.config.name}</MenuContent>
          </SectionMenuItem>
        ))}
    </SectionMenu>
  );
};
export default SelectFormMenu;

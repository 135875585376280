// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import type { Theme } from '../theme';

const StyledFormGroup = styled.div`
  display: flex;

  ${props =>
    props.horizontal
      ? css`
          justify-content: flex-start;
          margin-bottom: ${({ theme }: { theme: Theme }) =>
            theme.layout.halfPadding};
        `
      : css`
          flex-direction: column;
          margin-bottom: ${({ theme }: { theme: Theme }) =>
            theme.layout.standardPadding};
        `};
`;

type Props = {
  horizontal?: boolean,
};

export default function FormGroup(props: Props) {
  return <StyledFormGroup {...props} />;
}

FormGroup.defaultProps = {
  horizontal: false,
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Electric({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 32">
        <path d="M22.874 11.603a.467.467 0 0 0-.423-.27h-5.53l5.458-8.617A.466.466 0 0 0 21.985 2h-7.467c-.177 0-.338.1-.418.258l-7 14a.466.466 0 0 0 .418.675h4.799l-5.23 12.42a.468.468 0 0 0 .788.481l14.933-17.733a.467.467 0 0 0 .066-.498z" />
      </svg>
    </IconWrapper>
  );
}

Electric.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

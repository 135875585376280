import {
  Config,
  Slice,
  Section,
  DraggableListWithToggle,
} from '../../helpers/config';
import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'Linked Test',
  { id: 'LinkedTest', linked: true },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Filter options',
      { icon: SectionIcons.TopTier },
      DraggableListWithToggle('filterfilter', 'filterOptions'),
    ),
  ),
);

export default config;

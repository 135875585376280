// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../theme';
import VehicleGridShortlist from './VehicleGridShortlist';
import SortMenuBar from './VehicleSearchResults/SortMenuBar';
import { Paragraph, HeadingThree, fontStyleOverride } from './Global';
import Icon from './StyledIcon';

const Container = styled.div`
  padding: ${({ padding }) =>
    padding ? `0 ${padding.right}px 0 ${padding.left}px` : '16px 16px 0'};
  ${media.max.medium`
    padding: 16px 5% 0;
  `};
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 0 280px 0;
`;

const SortMenuBarStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Footer = styled.p`
  text-align: center;
  color: #7e7e7e;
  font-size: 13px;
  margin: auto 25%;
  margin-top: 27px;
`;

type Props = {
  config: Object,
  vehicles: Object[],
  onCloseClick: Function,
  onHeartClick: Function,
  onCompareClick: Function,
  representativeExample: Object,
  onMoreInfoClick: Function,
  getCompareIconColour: Function,
  getShortListedIconColour: Function,
  compareVehicle: Function,
  shortlistVehicle: Function,
  sortOptions: Object,
  sortValue: string,
  sortValueDictionary: string,
  onSortChange: Function,
  selectedView: 'GRID' | 'LIST',
  onViewChange: Function,
  placeholdingImage: string,
  globalStyling: Object,
  locale: string,
  preview: boolean,
  optionalSelectedColour: string,
  optionalSelectedBgColour: string,
  translations: Object,
};

export default function ShortlistedGridVehicles({
  config,
  vehicles,
  onCloseClick,
  onHeartClick,
  onCompareClick,
  onMoreInfoClick,
  getCompareIconColour,
  getShortListedIconColour,
  compareVehicle,
  shortlistVehicle,
  sortOptions,
  sortValue,
  sortValueDictionary,
  onSortChange,
  selectedView,
  placeholdingImage,
  globalStyling,
  locale,
  optionalSelectedColour,
  optionalSelectedBgColour,
  translations,
}: Props) {
  const vehiclesNoDistance = vehicles.map(v => {
    const { distance, ...rest } = v;
    return rest;
  });

  const singularOrPluralHeading = () =>
    vehicles.length > 1
      ? `${vehicles.length} ${translations.searchResultsAvailableAtLabel}`
      : `${vehicles.length} ${
          translations.searchResultsAvailableAtSingleLabel
        }`;

  const { containerPadding } = config;

  return (
    <Container padding={containerPadding}>
      {config.showVehicleCount && (
        <HeadingThree
          styleOverride={() => `
            ${fontStyleOverride(config.vehicleSpecContainer.subHeaderFont)}
          `}
        >
          {vehicles.length > 0 && singularOrPluralHeading()}
        </HeadingThree>
      )}
      {vehicles.length === 0 ? (
        <EmptyContainer>
          <Icon link={config.emptyIcon} width={100} height={100} />
          <Paragraph
            styleOverride={() => `
              margin-top: 40px;
              font-size: 16px;
              text-align: center;
              ${fontStyleOverride(config.noVehicleTextFont)}
            `}
          >
            {config.translations.noVehicleText}
          </Paragraph>
        </EmptyContainer>
      ) : (
        <Fragment>
          <SortMenuBarStyled>
            <SortMenuBar
              sortOptions={sortOptions}
              sortValue={sortValue}
              onSortChange={onSortChange}
              selectedView={selectedView}
              translations={config.translations}
              showAge={
                config.visibility &&
                config.visibility.sorting &&
                config.visibility.sorting.showSortByAge
              }
              showMileage={
                config.visibility &&
                config.visibility.sorting &&
                config.visibility.sorting.showSortByMileage
              }
              showPrice={
                config.visibility &&
                config.visibility.sorting &&
                config.visibility.sorting.showSortByPrice
              }
              fontFamily={config.sortMenuBarFontFamily}
              gridSelected={config.gridSelected}
              gridDeselected={config.gridDeselected}
              listSelected={config.listSelected}
              listDeselected={config.listDeselected}
              optionalSelectedColour={optionalSelectedColour}
              optionalSelectedBgColour={optionalSelectedBgColour}
            />
          </SortMenuBarStyled>
          <VehicleGridShortlist
            config={config}
            vehicles={vehiclesNoDistance}
            onCloseClick={onCloseClick}
            onHeartClick={onHeartClick}
            onCompareClick={onCompareClick}
            onMoreInfoClick={onMoreInfoClick}
            getCompareIconColour={getCompareIconColour}
            getShortListedIconColour={getShortListedIconColour}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
            selectedView={selectedView}
            sortValue={sortValue}
            sortValueDictionary={sortValueDictionary}
            placeholdingImage={placeholdingImage}
            globalStyling={globalStyling}
            locale={locale}
            vehicleSpecContainer={config.vehicleSpecContainer}
            handleZeroPriceAsText={config.handleZeroPriceAsText}
          />
          <Footer>{config.translations.footer}</Footer>
        </Fragment>
      )}
    </Container>
  );
}

// @flow
/* eslint-env browser */
import React, { Component } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import DirectionSearcher from './DirectionSearcher';

import GoogleMap from './GoogleMap';
import { google } from '../utilities/googleObject';
import media from '../theme';
import { RetailerInfo as Retailer } from '../modules/RetailerInformation/RetailerInformation';
import Pin from './JaguarIcons/Global/Pin';
import { Button, HeadingTwo, Paragraph, fontStyleOverride } from './Global';
import ContentWrapper from './ContentWrapper';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

export const widthOptions = {
  widthOptionOne: { mapWidth: 50, retailerWidth: 50 },
  widthOptionTwo: { mapWidth: 60, retailerWidth: 40 },
  widthOptionThree: { mapWidth: 70, retailerWidth: 30 },
};

const Container = styled.div`
  ${({ bgColour }) => bgColour && `background-color: ${bgColour};`}
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? `${alignItems}` : 'center')};
`;

const HeaderContainer = styled.div`
  display: flex;

  padding: ${({ padding }) => `${padding === 0 ? `${padding}` : '32px 0'}`};

  ${media.max.large`
    padding: ${({ padding }) => `${padding === 0 ? `${padding}` : '32px 5%'}`};
  `};
`;

const FullWidthContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${({ backgroundColour }) =>
    `${backgroundColour && `background-color: ${backgroundColour}`}`};
  ${({ border }) => `${border && `border-top: ${border}`}`};
  ${({ border }) => `${border && `border-bottom: ${border}`}
  `};
  ${({ borderTop }) => `${borderTop && `border-top: ${borderTop}`}
  `};
`;

const RetailerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  ${media.max.large`
    flex-direction: column;
  `};
`;

const RetailerInfo = styled.div`
  ${({ width }) => width && `width: ${width}%`};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: ${({ padding }) => `${padding ? `${padding}` : '32px 48px'}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  ${({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing}`};

  ${media.max.large`
    padding: ${({ mobilePadding }) =>
      `${mobilePadding ? `${mobilePadding}% 5%` : '0 5%'}`};
    width: auto;
  `};

  @media (min-width: 1366px) {
    ${({ desktopPadding }) =>
      desktopPadding && `padding-left: ${desktopPadding}px;`};
  }
`;

const WellMapHeader = styled.div`
  ${({ padding }) =>
    padding &&
    `padding: ${padding.top}px ${padding.right}% ${padding.bottom}px ${
      padding.left
    }%`};
`;

const MapContainer = styled.div`
  ${({ width }) => width && `width: ${width}%`};
  ${media.max.large`
    width: auto;
    padding: 0;
    height: 300px;
  `};
`;

const PhoneLink = styled.a.withConfig({
  displayName: 'PhoneLink',
})`
  text-decoration: none;
`;

const StyledInfoSection = styled.div`
  padding: 8px 0;
  ${({ borderBottom }) =>
    `${borderBottom.lineBreak &&
      `border-bottom: ${borderBottom.borderSectionColour}`}`};
`;

const DirectionSearcherContainer = styled.div`
  ${({ horizontalPadding }) =>
    horizontalPadding && `padding: ${horizontalPadding}px 0`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
`;

type InfoSectionProps = {
  children: Node,
  lineBreak?: boolean,
  qaHook?: string,
  borderSectionColour: string,
};

const InfoSection = ({
  children,
  lineBreak,
  borderSectionColour,
  qaHook,
}: InfoSectionProps) => (
  <StyledInfoSection
    data-qa-hook={qaHook}
    borderBottom={{ lineBreak, borderSectionColour }}
  >
    {children}
  </StyledInfoSection>
);

InfoSection.defaultProps = {
  lineBreak: false,
  qaHook: undefined,
};

const FlexDisplay = styled.div`
  display: flex;
  align-items: center;
`;

const DistanceSpan = styled.div`
  font-size: 12px;
  padding-left: 5px;
`;

const DistanceFrom = ({
  distanceFromRetailer,
  iconColour,
}: {
  distanceFromRetailer: string,
  iconColour: string,
}) => {
  if (distanceFromRetailer) {
    return (
      <FlexDisplay>
        <Pin colour={iconColour} width="1.8em" height="1.8em" />
        <DistanceSpan>{distanceFromRetailer}</DistanceSpan>
      </FlexDisplay>
    );
  }
  return null;
};

type Props = {
  config: Object,
  retailer: Retailer,
  translations: Object,
  viewRetailerStock: Function,
  globalStyling: Object,
  makeAnEnquiry: Function,
};
export default class VdpRetailerMap extends Component<Props, *> {
  constructor(props: Props) {
    super(props);

    this.state = {
      distanceFromRetailer: null,
    };
  }

  getDistanceFromRetailer = (position: Position) => {
    const { latitude: userLat, longitude: userLong } = position.coords;
    const [lat, long] = this.flipRetailerCoordinates().coordinates;

    const distanceService = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix(
      {
        origins: [new google.maps.LatLng(userLat, userLong)],
        destinations: [new google.maps.LatLng(lat, long)],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          const distanceText =
            response.rows[0].elements[0].distance &&
            response.rows[0].elements[0].distance.text;
          if (distanceText) {
            this.setState({
              distanceFromRetailer: translateFromTemplate(
                'vdpRetailerDistanceSuccess',
                {
                  DISTANCE: distanceText,
                },
                this.props.translations,
              ),
            });
          } else {
            this.setState({
              distanceFromRetailer: this.props.translations
                .vdpRetailerDistanceFailed,
            });
          }
        } else {
          this.setState({
            distanceFromRetailer: null,
          });
        }
      },
    );
  };

  flipRetailerCoordinates = () => {
    const { config, retailer } = this.props;
    return config.flipRetailerCoordinates
      ? retailer.location &&
          retailer.location.coordinates &&
          retailer.location.coordinates.length === 2 && {
            coordinates: [
              retailer.location.coordinates[1],
              retailer.location.coordinates[0],
            ],
          }
      : retailer.location;
  };

  render() {
    const {
      config,
      retailer,
      translations,
      viewRetailerStock,
      globalStyling,
      makeAnEnquiry,
    } = this.props;
    const { distanceFromRetailer } = this.state;
    return (
      <Container
        id="retailerMap"
        bgColour={config.backgroundColour}
        alignItems={config.alignItems}
      >
        <WellMapHeader padding={config.wellMapHeaderPadding}>
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <HeaderContainer padding={config.headerContainerPadding}>
              <HeadingTwo
                styleOverride={() => `
                width: 100%;
                ${fontStyleOverride(config.headerFont)}
              `}
              >
                {translations.retailerMapHeader}
              </HeadingTwo>
            </HeaderContainer>
          </ContentWrapper>
        </WellMapHeader>
        <FullWidthContainer
          border={config.borderContainerColour}
          backgroundColour={config.backgroundColour}
          borderTop={config.borderTop}
        >
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <RetailerContainer
              data-qa-hook="retailer-module"
              minHeight={config.contentHeight}
            >
              <RetailerInfo
                width={widthOptions[config.widthOptionStyle].retailerWidth}
                padding={config.padding}
                justifyContent={config.justifyContent}
                boxSizing={config.boxSizing}
                mobilePadding={config.mobileRetailerInfoPadding}
                desktopPadding={config.desktopPadding}
              >
                {config.visibility.showRetailerName && (
                  <InfoSection
                    lineBreak={config.visibility.showLineBreakRetailerName}
                    borderSectionColour={config.borderSectionColour}
                  >
                    <HeadingTwo
                      styleOverride={() => `
                      ${fontStyleOverride(config.retailerFont)}
                    `}
                    >
                      {retailer.name}
                    </HeadingTwo>
                  </InfoSection>
                )}
                {config.visibility.showAddress && (
                  <InfoSection
                    qaHook="retailer-address"
                    lineBreak={config.visibility.showLineAddress}
                    borderSectionColour={config.borderSectionColour}
                  >
                    {retailer.address.map((line, index) => (
                      <Paragraph
                        key={`map-info-${line}-${index.toString()}`}
                        styleOverride={() => `
                          margin: 0;
                          ${fontStyleOverride(config.retailerDetailsFont)}
                        `}
                      >
                        {line}
                      </Paragraph>
                    ))}
                  </InfoSection>
                )}
                {(config.visibility.showPhone ||
                  config.visibility.showEmail) && (
                  <InfoSection
                    qaHook="retailer-contact-info"
                    lineBreak={config.visibility.showLineContact}
                    borderSectionColour={config.borderSectionColour}
                  >
                    {config.visibility.showPhone && (
                      <PhoneLink href={`tel:${retailer.phone}`}>
                        <Paragraph
                          styleOverride={() => `
                            margin: 0;
                            ${fontStyleOverride(config.retailerDetailsFont)}
                          `}
                        >
                          {`${translations.vdpRetailerPhoneLabel}: ${
                            retailer.phone
                          }`}
                        </Paragraph>
                      </PhoneLink>
                    )}
                    {config.visibility.showEmail && (
                      <Paragraph
                        styleOverride={() => `
                          margin: 0;
                          ${fontStyleOverride(config.retailerDetailsFont)}
                        `}
                      >
                        {translations.vdpRetailerEmailLabel}:{' '}
                        {retailer.email.split(',').map(e => (
                          <div>{e}</div>
                        ))}
                      </Paragraph>
                    )}
                  </InfoSection>
                )}
                <DirectionSearcherContainer
                  horizontalPadding={config.paddingBetweenDirectionSearcher}
                  borderBottom={
                    config.visibility &&
                    config.visibility.showLineDirectionSearcher &&
                    config.borderSectionColour
                  }
                >
                  {config.visibility.showDistance && distanceFromRetailer && (
                    <InfoSection qaHook="retailer-location">
                      <DistanceFrom
                        distanceFromRetailer={distanceFromRetailer}
                        iconColour={config.distanceIconColour}
                      />
                    </InfoSection>
                  )}
                  {config.visibility.showDirectionSearcher && (
                    <InfoSection>
                      <DirectionSearcher
                        onGetCurrentPosition={(position: Position) =>
                          this.getDistanceFromRetailer(position)
                        }
                        placeholder={translations.vdpRetailerGetDirections}
                        destination={this.flipRetailerCoordinates()}
                        locationButtonWidth={config.locationButtonWidth}
                        directionButtonText={
                          translations.getDirectionsButtonText
                        }
                        buttonBGColour={config.directionSearcherBGColour}
                        opacityDirectionButton={config.opacityDirectionButton}
                        iconColour={config.locationIconColour}
                        borderRadiusButton={
                          config.directionSearcherButtonBorderRadius
                        }
                        retailerLocationCountry={retailer.country}
                        locationFilterLabel={translations.locationFilterLabel}
                        globalStyling={globalStyling}
                        resetDistanceFromRetailer={
                          this.resetDistanceFromRetailer
                        }
                      />
                    </InfoSection>
                  )}
                </DirectionSearcherContainer>
                {config.visibility.showRetailerStockButton && (
                  <InfoSection>
                    <Button
                      data-qa-hook="view-retailer-stock-button"
                      applyStyle="primary"
                      buttonStyle={
                        globalStyling.uiElements.primaryButton &&
                        globalStyling.uiElements.primaryButton.buttonStyle
                      }
                      styleOverride={() => `
                        width: 100%;
                      `}
                      text={translations.vdpRetailerStockButtonText}
                      onClick={() => viewRetailerStock(retailer)}
                    />
                  </InfoSection>
                )}
                {config.visibility.showEnquiryButton && (
                  <InfoSection>
                    <Button
                      applyStyle="secondary"
                      buttonStyle={
                        globalStyling.uiElements.secondaryButton &&
                        globalStyling.uiElements.secondaryButton.buttonStyle
                      }
                      styleOverride={() => `
                        width: 100%;
                      `}
                      text={translations.vdpRetailerEnquiryButtonText}
                      onClick={() => makeAnEnquiry()}
                    />
                  </InfoSection>
                )}
              </RetailerInfo>
              <MapContainer
                width={widthOptions[config.widthOptionStyle].mapWidth}
              >
                <GoogleMap
                  mapName="vdpMap"
                  retailerLocation={this.flipRetailerCoordinates()}
                  iconPin={config.iconPin}
                />
              </MapContainer>
            </RetailerContainer>
          </ContentWrapper>
        </FullWidthContainer>
      </Container>
    );
  }
}

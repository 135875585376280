// @flow
import React from 'react';

import { Text } from '../components';

interface ITextConfig {
  translations: {
    bodyText: string,
  };
  padding: Padding;
  bodyFont?: Font;
  alignment: string;
  backgroundColour: Option;
}

type Props = {
  config: ITextConfig,
};

export default ({
  config,
  config: {
    translations: { bodyText },
  },
}: Props) => {
  return <Text config={{ ...config, bodyText }} />;
};

// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import media from '../theme';

const Container = styled.div`
  background-color: #f5f5f5;
`;
const Box = styled.div`
  width: auto;
  margin: 120px 332px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  text-align: center;
  padding: 40px 150px;
  ${media.max.extraLarge`
    padding: 50px 5%;
    margin: 63px 24px;
  `};
`;
const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;
const Content = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: #444;
`;
const Email = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #444;
  margin: 20px 0;
`;

type Props = {
  email: string,
  goToPage?: Function,
  translations: Object,
};
export default function EmailVerification({
  email,
  goToPage,
  translations,
  globalStyling,
}: Props) {
  return (
    <Container>
      <Box>
        <Header>{translations.emailVerifyHeader}</Header>
        <Content>{translations.emailVerifyEmailSent}</Content>
        <Email>{email}</Email>
        {goToPage && (
          <Content>
            {translations.emailVerifyNavigationText}
            <Link
              href=""
              onClick={goToPage()}
              theme={{
                linkColour:
                  globalStyling.colours.primaryBrandColour &&
                  globalStyling.colours.primaryBrandColour.value,
              }}
            >
              {translations.emailVerifyNavigationTextLink}
            </Link>
          </Content>
        )}
      </Box>
    </Container>
  );
}

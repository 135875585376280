import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import curry from 'ramda/src/curry';
import uuid from 'uuid/v1';
import { modules } from 'cms-modules';

import { actions as contextActions } from '../../state/actions/context';
import { actions as pageActions } from '../../state/actions/page';

const { Band } = modules;

const clearAddBandContext = () => contextActions.setAddBand('');
const addBandAction = curry(pageActions.addBandToPage);
const addBandThenClearContext = addBandAction(clearAddBandContext);

const getBandConfig = columns => ({
  config: {
    columns,
    pods: Array.from({ length: columns }, () => ({
      id: 'Spacer',
      instanceId: uuid(),
      config: {},
    })),
  },
  name: Band.name,
  id: Band.id,
  instanceId: uuid(),
});

const states = {
  add: 'add',
  selectColumns: 'select-columns',
};

const useActions = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(states.add);

  const page = useSelector(state => state.context.page);

  const add = columns => {
    const newBand = getBandConfig(columns);
    dispatch(addBandThenClearContext(page.id, newBand));
    dispatch(contextActions.setPodContext(null));
    dispatch(
      contextActions.setAddPod({
        id: newBand.id,
        instanceId: newBand.instanceId,
      }),
    );
  };
  const addBand = columns => {
    add(columns);
    setMode(states.add);
  };
  const viewSelectColumns = () => {
    setMode(states.selectColumns);
  };
  return {
    mode,
    addBand,
    viewSelectColumns,
  };
};

export default useActions;

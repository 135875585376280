// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'cms-ui';

import { SiteBox, PublishedStatus } from '.';
import clockImg from '../assets/active.svg';
import configImg from '../assets/config.svg';
import { TO } from '../constants/routes';

const SitesListContainer = styled.div`
  padding: 0 300px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colours.secondary03};
  height: calc(
    100vh - 100px - 37px
  ); /* 100px banner, 37px nav bar, this sucks :-( */
  overflow-y: scroll;
`;
const Span = styled.span`
  &:first-child {
    flex: 2 0 0%;
    text-align: left;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSizeSubHeader};
  }
  &:last-child {
    flex: 1 0 0%;
    text-align: right;
    font-size: ${({ theme }) => theme.fontSizeContent};
  }
`;

const Section = styled.div`
  margin-top: 50px;
`;

const SubSection = styled.div`
  margin-top: 20px;
`;

const Img = styled.img`
  margin-right: 10px;
  vertical-align: middle;
  text-align: center;
`;

const Header = styled.h1`
  font-size: ${({ theme }) => theme.fontSizeHeader};
  display: inline;
  vertical-align: middle;
`;

const siteBuilderLink = (navigate: Function) => ({ id }: { id: string }) => (
  <Link
    href={TO.SITE_BUILDER(id)}
    onClick={e => {
      e.preventDefault();
      navigate(TO.SITE_BUILDER(id));
    }}
  >
    go to builder
  </Link>
);
const siteConfigLink = (navigate: Function) => ({ id }: { id: string }) => (
  <Link
    href={TO.SITE_CONFIG(id)}
    onClick={e => {
      e.preventDefault();
      navigate(TO.SITE_CONFIG(id));
    }}
  >
    configure
  </Link>
);

const RenderSites = ({ sites, LinkNode, noSitesMessage }) =>
  sites.length > 0
    ? sites.map((s: any) => (
        <SiteBox key={s.id}>
          <Span>{s.name}</Span>
          <PublishedStatus
            lastPublished={s.lastPublished}
            lastUpdated={s.lastUpdated}
          />
          <Span>
            <LinkNode id={s.id} />
          </Span>
        </SiteBox>
      ))
    : noSitesMessage;

type Props = {
  sites: Object,
  navigate: Function,
};

type named = { name: string };

export default function SiteList({ sites, navigate }: Props) {
  const sortByName = (a: named, b: named) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;

  const configuredSites = Object.values(sites)
    .filter((site: any) => !!site.configuration)
    .sort(sortByName);
  const sitesNeedingConfiguration = Object.values(sites)
    .filter((site: any) => !site.configuration)
    .sort(sortByName);

  return (
    <SitesListContainer>
      <Section>
        <Img src={configImg} alt="Config cogwheel" />
        <Header>Needs Configuration</Header>
      </Section>
      <SubSection>
        <RenderSites
          sites={sitesNeedingConfiguration}
          navigate={navigate}
          LinkNode={siteConfigLink(navigate)}
          noSitesMessage="You have no sites needing configuration"
        />
      </SubSection>
      <Section>
        <Img src={clockImg} alt="Clock icon" />
        <Header>Active Sites</Header>
      </Section>
      <SubSection>
        <RenderSites
          sites={configuredSites}
          navigate={navigate}
          LinkNode={siteBuilderLink(navigate)}
          noSitesMessage="You have no sites that are currently active"
        />
      </SubSection>
    </SitesListContainer>
  );
}

import {
  Config,
  Slice,
  Section,
  Align,
  Colour,
  Font,
  TextArea,
  Link,
  Pixels,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'TextCTA',
  { id: 'TextCTA' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      'Global',
      { icon: SectionIcons.SideCarousel },
      Align('Alignment', 'alignment'),
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Text with CTA',
      { icon: SectionIcons.TopTier },
      TextArea('Headline', 'headlineText'),
      TextArea('Body Text', 'bodyText'),
      TextArea('Small Body Text', 'smallBodyText'),
      Link('Button', 'buttonText'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Colour('Background Colour', 'backgroundColour'),
      Font('Header Text', 'headlineFont'),
      Font('Body Text', 'bodyFont'),
      Font('Small Body Text', 'smallBodyFont'),
      Font('Button Text', 'buttonFont'),
      Colour('Button Colour', 'buttonBackgroundColour'),
      Colour('Button hover', 'buttonHover'),
    ),
  ),
);

export default config;

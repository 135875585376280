import uuid from 'uuid/v1';
import editor from './editor';

const template = () => ({
  items: {
    id: uuid(),
    media: {
      componentType: 'image',
      src:
        'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
      label: 'Default Icon',
    },
    translations: {
      headlineText: 'Lorem ipsum dolor sit amet.',
      bodyText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      button: 'Lorem Ipsum',
    },
    button: {
      label: 'Lorem Ipsum',
      url: '',
    },
    verticalAlignment: 'top',
    horizontalAlignment: 'left',
  },
});

export default {
  id: 'HeroCarousel',
  name: 'Hero Carousel Pod',
  description: 'Add hero with image and video slides to a page',
  defaultSlice: 'Content',
  defaultConfig: {
    items: [template().items],
    transitionTime: 10,
    isLoop: true,
    opacity: 0.8,
    height: 600,
    headlineFont: {
      colour: {
        label: '#FFFFFF',
        value: '#FFFFFF',
      },
      fontSize: '28',
    },
    bodyFont: {
      colour: {
        label: '#FFFFFF',
        value: '#FFFFFF',
      },
      fontSize: '20',
      kerning: '0',
    },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078973/editor/Side-Icon-TextCTA.svg',
  editor,
  swap: 'PreviewHeroCarousel',
  requirements: {
    columns: [1],
  },
  template,
};

export default {
  translations: {
    emailVerifyHeader: "You're almost done...",
    emailVerifyEmailSent:
      'A email to reset your password has been sent to the address below. Open this email and click the link to reset your password',
    resetPasswordInfoText:
      'Type your email address and an email with a link to reset your password will be sent to you.',
    resetPasswordConfirmationInfoText:
      'A A email to reset your password has been sent to the address below. Open this email and click the link to reset your password',
    forgotPasswordHeader: 'I forgot my password',
    emailLabel: 'Email',
    changePasswordHeader: 'Change Password',
    expiredMessage: 'Token has expired',
    newPassword: 'New Password',
    confirmPassword: 'Repeat new password',
    resetButtonText: 'Reset Password',
    backToLoginLinkText: 'back to login',
    changeButtonText: 'Change Password',
    confirmationHeaderText: 'Your password has been successfully changed',
    confirmationContentText: 'You can now login using your new password',
    confirmationGoBackLabel: 'Login',
  },
};

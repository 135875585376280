// @flow
import React from 'react';
import styled from 'styled-components';

import One from './Templates/One';

import type { HeaderTemplateConfig } from './types';

type Props = {
  config: HeaderTemplateConfig,
};

const templates = {
  One,
};

export default ({ config }: Props) => {
  const Template = templates[config.template];
  return <Template config={config} />;
};

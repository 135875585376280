// @flow
import React from 'react';
import styled from 'styled-components';
import FilterByTile from './FilterByTile';
import type { Car, Font } from './FilterByTile';
import media from '../theme';

const FilterTileGrid = styled.div.withConfig({
  displayName: 'FilterTileGrid',
})`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const EmptyTile = styled.div.withConfig({
  displayName: 'EmptyTile',
})`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  ${media.min.medium`
    max-width: 336px;
  `};
`;

type props = {
  cars: Car[],
  itemClicked: (id: string) => void,
  selectedModels: [],
  translations: Object,
  selectedIconColour: string,
  locale: string,
  titleFont: Font,
  featureFlags: Object,
  globalStyling: Object,
};

export default function FilterByCarContainer({
  cars = [],
  itemClicked,
  selectedModels = [],
  translations,
  selectedIconColour,
  locale,
  titleFont,
  featureFlags,
  globalStyling,
}: props) {
  return (
    <FilterTileGrid>
      {cars.map((car: Car) => (
        <FilterByTile
          key={`${car.id}${car.model}`}
          align="left"
          onIconClick={() => itemClicked(car.modelDisplay)}
          car={car}
          isSelected={selectedModels.includes(car.modelDisplay)}
          translations={translations}
          selectedIconColour={selectedIconColour}
          locale={locale}
          titleFont={titleFont}
          featureFlags={featureFlags}
          globalStyling={globalStyling}
        />
      ))}
      {cars.length % 2 !== 0 && <EmptyTile />}
    </FilterTileGrid>
  );
}

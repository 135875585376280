// @flow
import React from 'react';
import type { Node } from 'react';

type InputChangeProps = {
  children: ({
    value: string,
    inputChange: (value: { value: string }) => void,
  }) => Node,
};

export default class InputChange extends React.Component<InputChangeProps, *> {
  state = {
    inputValue: '',
  };

  handleChange = (value: { value: string }) =>
    this.setState(() => ({ inputValue: value }));

  render() {
    return this.props.children({
      value: this.state.inputValue,
      inputChange: this.handleChange,
    });
  }
}

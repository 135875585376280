// @flow
import fileReader from './fileReader';

export default async function imageParser(image: File) {
  try {
    const response = await fileReader(image, 'DataURL');
    return response;
  } catch (error) {
    throw error;
  }
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import IoChevronRight from 'react-icons/lib/io/chevron-right';
import IoChevronLeft from 'react-icons/lib/io/chevron-left';
import IoChevronDown from 'react-icons/lib/io/chevron-down';
import IoChevronUp from 'react-icons/lib/io/chevron-up';

import type { Theme, Option } from '../types';

import media, { mixins } from '../theme';
import ContentWrapper from './ContentWrapper';

const SubNavBarContainer = styled.div.withConfig({
  displayName: 'SubNavBarContainer',
})`
  display: flex;
  ${({ theme }: { theme: Theme }) => mixins.themeTemplate(theme)};
  background-color: ${({ subNavBGColour }) => subNavBGColour || '#474747'};
  ${({ bottomBorder }) =>
    `${bottomBorder && `border-bottom: ${bottomBorder}`}`};
`;

const SubNavLink = styled.div.withConfig({
  displayName: 'SubNavLink',
})`
  display: flex;
  align-items: center;
  padding: 0 26px;
  cursor: pointer;
  ${({ linkHeight }) => `${linkHeight && `height: ${linkHeight}`}`};
  border-left: ${({ subNavBorderColour, showNavBarItemBorder }) =>
    showNavBarItemBorder
      ? `0.5px solid  ${subNavBorderColour || '#7e7e7e'}`
      : 'none'};
  border-bottom: ${({ subNavBorderColour, showNavBarItemBorder }) =>
    showNavBarItemBorder
      ? `0.5px solid  ${subNavBorderColour || '#7e7e7e'}`
      : 'none'};
  border-right: ${({ subNavBorderColour, showNavBarItemBorder }) =>
    showNavBarItemBorder
      ? `0.5px solid  ${subNavBorderColour || '#7e7e7e'}`
      : 'none'};

  text-transform: uppercase;
  min-width: 100px;
  justify-content: center;
  color: ${({ selected, selectedFontColour, theme }) =>
    selected
      ? selectedFontColour || theme.colour || '#FFF'
      : theme.colour || '#FFF'};
  background-color: ${({ selected, selectedBackgroundColour }) =>
    selected ? selectedBackgroundColour : 'transparent'};

  &:last-child {
    ${props =>
      props.isExploreRange &&
      `background-color: ${props.exploreRangeBackgroundColor}`};
    ${props =>
      props.isExploreRange && `color: ${props.exploreRangeForegroundColour}`};
  }
`;

const DesktopLinksContainer = styled.div.withConfig({
  displayName: 'DesktopLinksContainer',
})`
  flex: 1 0 0%;
  display: flex;
  justify-content: ${({ align }) => (align ? `${align}` : 'center')};
  max-width: 100%;
  flex-wrap: wrap;

  ${media.max.large`
    display: none;
  `};
`;

const PhoneTitleBar = styled.div.withConfig({
  displayName: 'PhoneTitleBar',
})`
  display: flex;
  flex: 1 0 0%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  height: 50px;

  ${media.min.large`
    display: none;
  `};
`;

const PhoneOpenCloseButton = styled.div.withConfig({
  displayName: 'PhoneOpenCloseButton',
})`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  ${media.min.large`
    display: none;
  `};
`;

const PhoneLinks = styled.div.withConfig({
  displayName: 'PhoneLinks',
})`
  display: flex;
  flex-direction: column;
  background-color: #474747;
  width: 100%;

  ${media.min.large`
    display: none;
  `};
`;

const PhoneLink = styled.div.withConfig({
  displayName: 'PhoneLink',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ theme }: { theme: Theme }) => mixins.themeTemplate(theme)};
  background-color: ${({ subNavBGColour }) => subNavBGColour || '#474747'};
  border-top: 1px solid #7e7e7e;
  height: 40px;

  &:first-child {
    border: none;
  }
`;

const BackButton = styled.div.withConfig({
  displayName: 'BackButton',
})`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
`;

type Link = {
  text: string,
  onClick: any => void,
  availableOnMobile: boolean,
  active?: boolean,
  path: string,
};

type Props = {
  links: Link[],
  onBackButtonClick?: () => void,
  title?: string,
  isExploreRange?: boolean,
  subNavBGColour?: Option,
  subNavBorderColour?: Option,
  subNavFontTheme?: Theme,
  subNavChevronColour?: Option,
  showNavBarItemBorder?: boolean,
  exploreRangeBackgroundColor?: string,
  subNavHeight?: string,
  exploreRangeForegroundColour?: Option,
  selected: Link,
  subNavBarItemSelectedBackgroundColour: Option,
  subNavBarItemSelectedFontColour: Option,
  contentWidth?: number,
  alignMenuItems?: string,
  navBarBottomBorder: string,
  globalStyling: Object,
};

export default class SubNavBar extends React.Component<Props, *> {
  static defaultProps = {
    title: '',
    isExploreRange: false,
    subNavBGColour: {
      value: '#474747',
      label: 'grey',
    },
    subNavBorderColour: {
      value: '#7e7e7e',
      label: 'light grey',
    },
    subNavChevronColour: {
      value: '#FFF',
      label: 'white',
    },
    subNavFontTheme: {
      characterSpacing: '0',
      colour: '#fff',
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: '14px',
      textTransform: 'uppercase',
    },
    showNavBarItemBorder: true,
    subNavHeight: '50px',
    exploreRangeBackgroundColor: '#9e1b32',
    exploreRangeForegroundColour: '#ffffff',
    alignMenuItems: 'center',
  };

  state = {
    open: false,
  };

  toggle() {
    this.setState(prev => ({ ...prev, open: !prev.open }));
  }

  render() {
    const {
      onBackButtonClick,
      links,
      title,
      isExploreRange,
      subNavBGColour,
      subNavBorderColour,
      subNavFontTheme,
      subNavChevronColour,
      exploreRangeBackgroundColor,
      showNavBarItemBorder,
      exploreRangeForegroundColour,
      selected,
      subNavBarItemSelectedBackgroundColour,
      subNavBarItemSelectedFontColour,
      contentWidth,
      subNavHeight,
      alignMenuItems,
      navBarBottomBorder,
      globalStyling,
    } = this.props;
    const colourValue = colour => colour && colour.value;

    return (
      <Fragment>
        <SubNavBarContainer
          theme={subNavFontTheme}
          subNavBGColour={colourValue(subNavBGColour)}
          bottomBorder={navBarBottomBorder}
        >
          <ContentWrapper contentWidth={contentWidth} flex>
            {onBackButtonClick && (
              <BackButton onClick={onBackButtonClick}>
                {globalStyling.direction === 'rtl' ? (
                  <IoChevronRight color={colourValue(subNavChevronColour)} />
                ) : (
                  <IoChevronLeft color={colourValue(subNavChevronColour)} />
                )}
              </BackButton>
            )}
            <DesktopLinksContainer align={alignMenuItems}>
              {links.map((link, i) => (
                <SubNavLink
                  linkHeight={subNavHeight}
                  theme={subNavFontTheme}
                  subNavBorderColour={colourValue(subNavBorderColour)}
                  key={i.toString()}
                  onClick={link.onClick}
                  isExploreRange={isExploreRange}
                  showNavBarItemBorder={showNavBarItemBorder}
                  exploreRangeBackgroundColor={exploreRangeBackgroundColor}
                  exploreRangeForegroundColour={colourValue(
                    exploreRangeForegroundColour,
                  )}
                  selectedBackgroundColour={colourValue(
                    subNavBarItemSelectedBackgroundColour,
                  )}
                  selectedFontColour={colourValue(
                    subNavBarItemSelectedFontColour,
                  )}
                  selected={selected === link}
                >
                  &#x200E;{link.text}&#x200E;
                </SubNavLink>
              ))}
            </DesktopLinksContainer>
            <PhoneTitleBar>{title}</PhoneTitleBar>
            <PhoneOpenCloseButton onClick={() => this.toggle()}>
              {this.state.open ? <IoChevronUp /> : <IoChevronDown />}
            </PhoneOpenCloseButton>
          </ContentWrapper>
        </SubNavBarContainer>
        {this.state.open && (
          <PhoneLinks>
            {links
              .filter(link => link.availableOnMobile)
              .map((link, i) => (
                <PhoneLink
                  subNavBGColour={colourValue(subNavBGColour)}
                  theme={subNavFontTheme}
                  key={i.toString()}
                  onClick={link.onClick}
                >
                  &#x200E;{link.text}&#x200E;
                </PhoneLink>
              ))}
          </PhoneLinks>
        )}
      </Fragment>
    );
  }
}

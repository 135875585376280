// @flow
import { constants } from '../actions/blogs';
import type { Action } from '../actions/blogs';
import type { Error } from '../types/error';

export type State = {
  blogs: [],
  editedBlog: object,
  error: ?Error,
};

const initialState = {
  blogs: [],
  saving: false,
};

type Image = {
  label: string,
  src: string,
};

export type BlogConfig = {
  bodyText: string,
  title: string,
  description: string,
  keywords: string,
  image: Image,
};

export type Blog = {
  id: string,
  siteId: string,
  brand: string,
  config: BlogConfig,
  visible: boolean,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.LoadBlogsSuccessful: {
      return {
        ...state,
        blogs: [...action.payload],
        error: null,
      };
    }
    case constants.SaveBlog:
      return {
        ...state,
        saving: true,
      };
    case constants.SaveBlogSuccessful:
      return {
        ...state,
        saving: false,
        dirtyBlogs: false,
      };
    case constants.SaveBlogFailure:
      return {
        ...state,
        saving: false,
      };
    case constants.UpdateBlogInContext: {
      const { payload } = action;
      return {
        ...state,
        blogs: state.blogs.map(b => (b.id === payload.id ? payload : b)),
        dirtyBlogs: true,
      };
    }
    case constants.LoadBlogsFailure:
      return { ...state, error: action.payload };
    case 'ADD_BLOG':
      return { ...state, blogs: [...state.blogs, action.payload] };
    default:
      return state;
  }
}

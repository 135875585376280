// @flow
import { takeEvery, select, call, put, all } from 'redux-saga/effects';
import { convertVehiclesPriceCurrency } from '../currencyConversion/helpers';
import {
  constants,
  actions,
  getVehicles as shortlistVehicles,
} from './reducer';
import {
  get,
  post,
  remove,
  put as update,
  localeConfigSerializer,
} from '../../helpers/http';
import { getToken } from '../CustomerLogin/reducer';
import {
  getAVLConfig,
  vehiclesShortListServiceUrl,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getPlaceholderBrandImageUrl,
  getImageSize,
  getGlobalFinance,
  getInventoryStatus,
} from '../selectors/settings';
import { localiseNumber } from '../localisation/numbers';
import { fetchVehicleStatuses, mapStatus } from '../vehicleStatus';
import { actions as errorActions } from '../errors';
import { mapVehicleAndFinance as getFinance } from '../financeCalculator/effects';

const noop = () => {};

const stripFinanceAndDistance = vehicle => {
  const {
    finance,
    price,
    distancefromRetailer,
    ...vehicleNoFinanceOrDistance
  } = vehicle;
  return {
    ...vehicleNoFinanceOrDistance,
    price: {
      currency: price.currency,
      value: price.value,
    },
  };
};

function* fetchVehicles(token: string) {
  const { locale } = yield select(getAVLConfig);

  const shortlistResponse = yield call(get, {
    url: `${yield select(vehiclesShortListServiceUrl)}shortlist`,
    token,
  });

  const vehicles = shortlistResponse.data.contents
    ? [
        ...shortlistResponse.data.contents.map(v => ({
          ...v,
          odometer: {
            ...v.odometer,
            display: `${localiseNumber(v.odometer.reading, locale)} ${
              v.odometer.units
            }`,
          },
        })),
      ]
    : [];

  return vehicles;
}

function* mapVehicleAndFinance(vehicle: Object) {
  const globalFinance = yield select(getGlobalFinance);
  try {
    const vehicleWithFinance = yield getFinance(
      vehicle,
      globalFinance.defaultProduct,
    );
    yield put(actions.updateFinanceSuccess(vehicleWithFinance));
  } catch (error) {
    yield put(
      actions.updateFinanceSuccess({
        ...vehicle,
        financeLoaded: true,
      }),
    );
  }
}

function* getVehicles() {
  const token = yield select(state => getToken(state.shared));

  let vehicles = [];

  try {
    if (token) {
      vehicles = yield fetchVehicles(token);
    } else {
      vehicles = yield select(state => shortlistVehicles(state.shared));
    }

    const { locale, make, market, region } = yield select(getAVLConfig);
    const vehicleIds = vehicles.map(v => v.id);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const imageSize = yield select(getImageSize);
    const inventoryStatus = yield select(getInventoryStatus);

    if (vehicles.length > 0) {
      const vehicleResponse = yield call(get, {
        url: region
          ? `${yield select(
              inventorySearchServiceUrl,
            )}vehicles/make/${make}/region/${region}`
          : `${yield select(
              inventorySearchServiceUrl,
            )}inventory/make/${make}/market/${market}`,
        config: localeConfigSerializer(withLocaleQueryString && locale, {
          ids: vehicleIds,
          imageSize,
          pageSize: vehicles.length,
          status: inventoryStatus,
        }),
      });

      const vehicleStatuses = yield fetchVehicleStatuses(
        vehicles.map(v => v.id),
      );

      vehicles = vehicleResponse.data.contents.map(v => ({
        ...v,
        odometer: {
          ...v.odometer,
          display: `${localiseNumber(v.odometer.reading, locale)} ${
            v.odometer.units
          }`,
        },
        status: mapStatus(v.id, vehicleStatuses),
        financeLoaded: false,
      }));

      const currency = yield select(
        state => state.shared.sessionPreferences.currency,
      );
      if (currency) {
        const baseRates = yield select(
          state =>
            state.shared.currencyConversion.exchangeRates[currency].rates,
        );

        vehicles = convertVehiclesPriceCurrency(vehicles, baseRates, currency);
      }
    }

    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
    yield put(
      actions.getVehiclesSuccess({
        placeholdingImage,
        vehicles,
      }),
    );
    const loadFinance = yield select(getGlobalFinance);
    if (loadFinance) {
      yield all(vehicles.map(v => call(mapVehicleAndFinance, v)));
    }
  } catch (error) {
    // no-op
  }
}

function* toggleVehicle(action) {
  let { payload: vehicle } = action;
  vehicle = stripFinanceAndDistance(vehicle);
  const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
  const token = yield select(state => getToken(state.shared));
  const vehicles = yield select(state => shortlistVehicles(state.shared));
  const vehicleExists = vehicles.find(v => v.id === vehicle.id);
  try {
    yield (token
      ? function* toggle() {
          const url = `${yield select(vehiclesShortListServiceUrl)}shortlist/${
            vehicle.id
          }`;
          yield call(vehicleExists ? remove : update, {
            url,
            token,
            ...(vehicleExists ? {} : { data: vehicle }),
          });

          yield put(
            actions.toggleVehicleSuccess({ vehicle, placeholdingImage }),
          );
        }
      : function* toggle() {
          yield put(
            actions.toggleVehicleSuccess({ vehicle, placeholdingImage }),
          );
        })();
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* saveShortlistedVehicles() {
  const vehicles = yield select(state => shortlistVehicles(state.shared));
  try {
    yield ((vehicles || []).length
      ? function* saveShorlist() {
          const vehiclesNoFinance = vehicles.map(v =>
            stripFinanceAndDistance(v),
          );
          const token = yield select(state => getToken(state.shared));
          const url = `${yield select(vehiclesShortListServiceUrl)}shortlist`;
          yield call(post, { url, token, data: vehicles });
          yield put(
            actions.saveShorlistedVehiclesSuccess({
              vehicles: vehiclesNoFinance,
            }),
          );
        }
      : noop)();
  } catch (error) {
    yield put(
      actions.saveShorlistedVehiclesFailure({
        ...error,
        message: `Failed to save vehicles: ${error.message}`,
      }),
    );
  }
}

export default function* ShortlistSaga() {
  yield takeEvery(constants.GET_VEHICLES, getVehicles);
  yield takeEvery(constants.TOGGLE_VEHICLE, toggleVehicle);
  yield takeEvery(constants.SAVE_SHORTLISTED_VEHICLES, saveShortlistedVehicles);
}

// @flow

import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';
import type { Theme } from '../theme';

type Props = {
  children: Node,
  theme: Theme,
};

const StyledIconBox = styled.div.withConfig({
  displayName: 'IconBox',
})`
  display: flex;
  margin-left: ${(props: Props) => props.theme.layout.standardPadding};
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
`;

export default function IconBox(props: { children: Node }) {
  return <StyledIconBox {...props} />;
}

const parseYouTubeId = url => {
  let sliceIndex = url.lastIndexOf('=');

  if (sliceIndex === -1) {
    sliceIndex = url.lastIndexOf('/');
  }

  const res = sliceIndex !== -1 ? url.slice(sliceIndex + 1 - url.length) : null;
  return res;
};

export default parseYouTubeId;

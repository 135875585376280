// @flow
import React from 'react';
import styled from 'styled-components';

type Props = {};

const StyledBannerItem = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default function BannerItem(props: Props) {
  return <StyledBannerItem {...props} />;
}

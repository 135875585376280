// @flow
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { HeadingTwo, fontStyleOverride } from '../../../../components/Global';
import LanguageCurrencySelectionModal from './LanguageCurrencySelectionModal';
import MobileMenu from '../../MobileMenu';
import SocialSharePopup from '../../../../components/SocialSharePopup';
import ModalOverlay from '../../../../components/ModalOverlay';
import CurrencyPopup from '../../../../components/CurrencyPopup';
import HeaderSubNav from '../../../../components/HeaderSubNav';
import media from '../../../../theme';

const HeaderWrapper = styled.div`
  ${media.max.large`
    height: 60px;
  `};
  box-sizing: border-box;
  width: 100%;
  z-index: 100;
  height: 100px;
  ${({ stickyHeader, opacity, backgroundColour }) =>
    `
      position: ${stickyHeader ? 'fixed' : 'static'};
      ${opacity &&
        `
        &:after {
          content: '';
          width: 100%;
          height: inherit;
          background-color: ${backgroundColour};
          opacity: ${opacity};
          position: absolute;
          top: 0;
          left: 0;
        }
      `};
    `};
`;

const TopWrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  height: inherit;
  box-sizing: border-box;
  ${media.min.large`
    padding: 0 50px;
  `};
`;

const ImageWrapper = styled.div`
  flex: 1 1 0%;
  z-index: 9;
  position: relative;
  padding-right: 20px;
`;

const Image = styled.img`
  ${({ source }) => source && 'cursor: pointer;'};
  height: 50px;
  ${media.min.large`
    max-width: 60px;
    height: auto;
  `};
`;

const LinksWrapper = styled.div`
  display: none;
  ${media.min.large`
    flex: 1 1 80%;
    display: flex;
    align-items: center;
    z-index: 9;
    position: relative;
  `};
`;

const ActionsWrapper = styled.div`
  display: none;
  ${media.min.large`
    flex: 1 1 10%;
    display: flex;
    justify-content: flex-end;
    z-index: 9;
    position: relative;
  `};
`;
const MenuButtonWrapper = styled.div`
  z-index: 9;
  position: relative;
  ${media.min.large`
    display: none;
  `};
`;

const ShareIcon = styled.img`
  padding-right: 16px;
  display: flex;
  &:hover {
    opacity: 0.6;
  }
`;

const Link = styled.a`
  outline: none;
  text-decoration: none;
  margin: 0 12px;
  &:after {
    display: block;
    content: '';
    height: 1px;
    margin-top: 4px;
    box-sizing: border-box;
    background-color: ${({ underlineColour }) =>
      underlineColour && underlineColour.value};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }

  &.active {
    &:after {
      border-bottom: 1px solid
        ${({ underlineColour }) => underlineColour && underlineColour.value};
      transform: scaleX(1);
    }
  }
`;

const SubNav = styled.div`
  position: absolute;
  top: ${({ open }) => (open ? '0' : '-500px')};
  left: 0;
  width: 100%;
  height: 500px;
  background-color: rgba(32, 32, 32, 0.95); /* comes from lamborghini */
  box-sizing: border-box;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

type Props = {
  config: Object,
  globalStyling: Object,
  links: Object[],
  navigate: () => void,
  currentLanguage: string,
  currentCurrency: string,
  socialShare: Object,
  shared: Object,
  utilityBarActions: Object,
  marketInfo: Object,
};

export default function HeaderTemplateTwo(props: Props) {
  const {
    config,
    links,
    navigate,
    globalStyling: {
      colours: { primaryBrandColour },
      uiElements: { primaryButton },
    },
    globalStyling,
    currentLanguage,
    currentCurrency,
    socialShare,
    shared,
    utilityBarActions,
  } = props;
  const languageOptions =
    config.utilityBarOptions &&
    config.utilityBarOptions.find(op => op.key === 'Language').options;
  const currencyOptions =
    config.utilityBarOptions &&
    config.utilityBarOptions.find(op => op.key === 'Currency').options;
  const territoryOptions = config.utilityBarOptions.find(
    op => op.key === 'Territory',
  ).options;
  const selectedLanguage = languageOptions.find(
    lo => lo.value === currentLanguage,
  );
  const selectedCurrency = currencyOptions.find(
    co => co.value === currentCurrency,
  );
  const [subNavData, setSubNavData] = useState();
  const [activeLink, setActiveLink] = useState(null);

  const Modal = closeModalFn => (
    <LanguageCurrencySelectionModal
      languages={languageOptions}
      selectedLanguage={selectedLanguage}
      currencies={currencyOptions}
      selectedCurrency={selectedCurrency}
      territories={territoryOptions}
      selectedTerritory={props.marketInfo.territory}
      translations={config.translations}
      changeLanguage={utilityBarActions.language}
      changeCurrency={utilityBarActions.currency}
      changeTerritory={utilityBarActions.territory}
      hoverColour={primaryBrandColour}
      selectFont={config.modalSelectionFont}
      button={primaryButton}
      closeModalFn={closeModalFn}
    />
  );

  return (
    <HeaderWrapper
      stickyHeader={config.stickyHeader}
      opacity={config.opacity}
      backgroundColour={
        config.backgroundColour && config.backgroundColour.value
      }
      onMouseLeave={() => setSubNavData()}
    >
      <CurrencyPopup
        translations={config.translations}
        globalStyling={globalStyling}
        disableCurrencyPopup={utilityBarActions.disableCurrencyPopup}
        shared={shared}
      />
      <TopWrapper>
        <ImageWrapper>
          <Image
            source={config.brandLogoLink}
            onClick={() =>
              navigate(config.brandLogoLink && config.brandLogoLink.value)
            }
            src={config.brandLogoUrl}
            alt="lamborghini"
          />
        </ImageWrapper>
        <SubNav open={subNavData} onMouseLeave={() => setActiveLink()}>
          {subNavData && (
            <HeaderSubNav
              globalStyling={globalStyling}
              links={subNavData}
              navigate={navigate}
              config={config}
            />
          )}
        </SubNav>
        <LinksWrapper>
          {links.map(link => (
            <Link
              className={
                activeLink === link.navId && link.subLinks ? 'active' : ''
              }
              key={link.navId}
              href={link.link}
              onClick={event => {
                event.preventDefault();
                navigate(link.link);
              }}
              onMouseEnter={() => {
                setSubNavData(link.subLinks);
                setActiveLink(link.navId);
              }}
              underlineColour={primaryBrandColour}
            >
              <HeadingTwo
                styleOverride={() => fontStyleOverride(config.menuBarLinkFont)}
              >
                {link.label}
              </HeadingTwo>
            </Link>
          ))}
        </LinksWrapper>
        <ActionsWrapper>
          <SocialSharePopup config={config} socialShare={socialShare}>
            <ShareIcon src={config.socialShareIconUrl} alt="share this page" />
          </SocialSharePopup>
          <ModalOverlay
            component={
              <Fragment>
                <HeadingTwo
                  styleOverride={() => `
                    border: 1px solid #ffffff;
                    cursor: pointer;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    &:hover {
                      opacity: 0.5;
                    }
                    ${fontStyleOverride({
                      ...config.menuBarLinkFont,
                      fontSize: 14,
                      padding: {
                        left: 8,
                        right: 8,
                        top: 0,
                        bottom: 0,
                      },
                    })}
                  `}
                >
                  {props.marketInfo.territory}
                </HeadingTwo>
                <HeadingTwo
                  styleOverride={() => `
                    margin-left: 10px;
                    border: 1px solid #ffffff;
                    cursor: pointer;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    &:hover {
                      opacity: 0.5;
                    }
                    ${fontStyleOverride({
                      ...config.menuBarLinkFont,
                      fontSize: 14,
                      padding: {
                        left: 8,
                        right: 8,
                        top: 0,
                        bottom: 0,
                      },
                    })}
                  `}
                >
                  {selectedLanguage.labelShorthand}
                </HeadingTwo>
                <HeadingTwo
                  styleOverride={() => `
                  margin-left: 10px;
                  border: 1px solid #ffffff;
                  cursor: pointer;
                  height: 100%;
                  min-width: 30px;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  &:hover {
                    opacity: 0.5;
                  }
                  ${fontStyleOverride({
                    ...config.menuBarLinkFont,
                    fontSize: 14,
                    padding: {
                      left: 8,
                      right: 8,
                      top: 0,
                      bottom: 0,
                    },
                  })}
                `}
                >
                  {selectedCurrency ? selectedCurrency.labelShorthand : '£ / $'}
                </HeadingTwo>
              </Fragment>
            }
            modalWidth={300}
            closeIconUrl={config.closeIconUrl}
            renderComponentWithProps={Modal}
          >
            {Modal}
          </ModalOverlay>
        </ActionsWrapper>
        <MenuButtonWrapper>
          <MobileMenu
            config={config}
            languages={languageOptions}
            currencies={currencyOptions}
            links={links}
            navigate={navigate}
            selectedLanguage={selectedLanguage}
            changeLanguage={utilityBarActions.language}
            selectedCurrency={selectedCurrency}
            changeCurrency={utilityBarActions.currency}
            socialShare={socialShare}
            territories={territoryOptions}
            selectedTerritory={props.marketInfo.territory}
            changeTerritory={utilityBarActions.territory}
          />
        </MenuButtonWrapper>
      </TopWrapper>
    </HeaderWrapper>
  );
}

// @flow
import React from 'react';
import { IconWrapper } from '../../components';

export default function Icon({ background, colour, width, height }: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg viewBox="0 0 18 18" width="1em" height="1em">
        <g fill="none" fillRule="evenodd">
          <path stroke="#0065DB" d="M.5 1.5h17v15H.5z" />
          <path
            fill="#0065DB"
            d="M2 3h14v2H2zM2 6h2v2H2zM2 9h2v2H2zM6 6h2v2H6zM6 9h2v2H6z"
          />
          <path fill="#D6D6D6" d="M2 12h14v3H2z" />
          <path
            fill="#0065DB"
            d="M10 6h2v2h-2zM10 9h2v2h-2zM14 6h2v2h-2zM14 9h2v2h-2z"
          />
        </g>
      </svg>
    </IconWrapper>
  );
}

Icon.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import { constants, type Action } from '../actions/finance';
import type { State } from '../types/finance';
import {
  constants as settingsConstants,
  type Action as SettingsAction,
} from '../actions/settings';
import { constants as editorConstants } from '../actions/editor';

const initialState: State = {
  productTypes: [],
  availableProductTypes: ['HP', 'PCP', 'Leasing'],
  defaultProduct: 'HP',
  repexDefaultState: 'closed',
  error: '',
  success: null,
};

export default function reducer(
  state: State = initialState,
  action: Action | SettingsAction,
) {
  switch (action.type) {
    case editorConstants.ResetSuccessMessages:
      return {
        ...state,
        success: null,
      };
    case editorConstants.ResetFailureMessages:
      return {
        ...state,
        error: null,
      };
    case constants.UpdateFinanceOptions:
      return {
        ...action.payload,
      };
    case constants.SaveFinanceOptionsSuccess:
      return {
        ...state,
        success: true,
      };
    case constants.SaveFinanceOptionsFailure:
      return {
        ...state,
        error: 'save failed',
      };
    case settingsConstants.LoadSettingsSuccess:
      return {
        ...state,
        ...action.payload.finance,
      };
    default:
      return state;
  }
}

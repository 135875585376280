// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Heart({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 29 25">
        <path d="M14 21.416l-1.4-1.4c-5.2-4.6-8.6-7.7-8.6-11.5 0-3.1 2.4-5.5 5.5-5.5 1.7 0 3.4.8 4.5 2.1 1.1-1.3 2.8-2.1 4.5-2.1 3.1 0 5.5 2.4 5.5 5.5 0 3.8-3.4 6.9-8.6 11.5l-1.4 1.4z" />
      </svg>
    </IconWrapper>
  );
}

Heart.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

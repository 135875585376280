// @flow
import React from 'react';
import { Fields } from 'redux-form';
import styled from 'styled-components';
import path from 'ramda/src/path';
import { CheckboxGroup, Dropdown, Label, InlineError } from 'cms-ui';

type Props<T> = {
  items: T[],
  checkboxGroupName: string,
  dropdownName: string,
  dropdownLabel: string,
  getLabelFromOption: Function,
  getValueFromOption: Function,
  valueKey: string,
  optionRenderer: Function,
  valueRenderer: Function,
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colours.highlight02};
  border: 1px solid ${({ theme }) => theme.colours.lowlight};
  width: 100%;
  margin-top: 20px;
  padding: 40px;
`;

const renderFields = fields => {
  const checkboxGroup = path(fields.checkboxGroupName.split('.'), fields);
  const dropdown = path(fields.dropdownName.split('.'), fields);

  const {
    meta: { error, dirty },
  } = dropdown;

  const dropdownInput = {
    value: dropdown.input.value,
    onChange: dropdown.input.onChange,
  };
  return (
    <div>
      <Box>
        <CheckboxGroup
          {...checkboxGroup}
          getLabelFromOption={fields.getLabelFromOption}
          getValueFromOption={fields.getValueFromOption}
          options={fields.items || []}
          hasSelectAll
        />
      </Box>
      <Box>
        <Label>{fields.dropdownLabel}</Label>
        <Dropdown
          valueKey={fields.valueKey}
          optionRenderer={fields.optionRenderer}
          valueRenderer={fields.valueRenderer}
          options={checkboxGroup.input.value || []}
          input={dropdownInput}
        />
        {dirty && error && (
          <InlineError error={dirty && error}>{error}</InlineError>
        )}
      </Box>
    </div>
  );
};

export default function CheckboxGroupWithDropdown({
  items,
  checkboxGroupName,
  dropdownName,
  dropdownLabel,
  getLabelFromOption,
  getValueFromOption,
  valueKey,
  optionRenderer,
  valueRenderer,
}: Props<*>) {
  return (
    <Fields
      names={[checkboxGroupName, dropdownName]}
      component={renderFields}
      checkboxGroupName={checkboxGroupName}
      dropdownName={dropdownName}
      items={items}
      dropdownLabel={dropdownLabel}
      getLabelFromOption={getLabelFromOption}
      getValueFromOption={getValueFromOption}
      valueKey={valueKey}
      optionRenderer={optionRenderer}
      valueRenderer={valueRenderer}
    />
  );
}

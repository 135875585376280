// @flow
import React from 'react';
import styled from 'styled-components';

import Dropdown from './Dropdown';
import ColourSelection from './ColourSelection';
import InputField from './InputField';
import TextTransform from './TextTransform';
import TextAlignment from './TextAlignment';

import type { Theme } from '../theme';
import type { Option as ColourOption } from './ColourSelection';
import type { Option as TransformOption } from './TextTransform';
import type { Option as TextAlignmentOption } from './TextAlignment';

const Container = styled.div.attrs(() => ({
  type: 'Container',
}))`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeContent};
  display: flex;
  flex-direction: column;
`;

const Label = styled.span.attrs(() => ({
  type: 'Label',
}))`
  color: ${({ theme }: { theme: Theme }) => theme.colours.secondary03};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeFormLabel};
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

const Row = styled.div.attrs(() => ({
  type: 'Row',
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};

  * {
    ${({ expandChildren }) => (expandChildren ? 'flex: 1 0 0%' : '')};
  }
`;

const Bullet = styled.span.attrs(() => ({
  type: 'Bullet',
}))`
  color: ${({ theme }: { theme: Theme }) => theme.colours.disabled01};
  :before {
    content: '•';
    margin-right ${({ theme }: { theme: Theme }) => theme.layout.halfPadding};
    font-size ${({ theme }: { theme: Theme }) => theme.bulletPointSize};
  }
`;

const FixedWidthBox = styled.div`
  width: ${({ width }) => `${width}px`};
`;

const Left = styled.div.withConfig({ displayName: 'Left' })`
  display: flex;
  flex: 3 0 0%;
`;

const Right = styled.div.withConfig({ displayName: 'Right' })`
  display: flex;
  flex: 1 0 0%;
  justify-content: flex-end;
`;

type typeFaceOption = { value: string, label: string };

type Font = {
  colour: ColourOption,
  typeface: typeFaceOption,
  fontSize: Number,
  kerning: Number,
  transform: TransformOption,
  alignment: TextAlignmentOption,
};

type Props = {
  name: string,
  label?: ?string,
  colours: ColourOption[],
  typefaces: typeFaceOption[],
  openCloseTextTransform: boolean => void,
  transformOpen: Boolean,
  withParagraphAlignment?: Boolean,
  value: Font,
  onChange: (value: Font) => void,
};

const FlexGrowOne = styled.span`
  flex-grow: 1;
`;

export default class FontEditor extends React.Component<Props, *> {
  static defaultProps = {
    label: null,
    withParagraphAlignment: false,
  };

  constructor(props: Props) {
    super(props);
    this.openCloseTextTransform = this.unboundOpenCloseTextTransform.bind(this);
  }

  state = { textTransformOpen: false };

  openCloseTextTransform: Function;

  unboundOpenCloseTextTransform(open: boolean) {
    this.setState({
      textTransformOpen: open,
    });
  }

  render() {
    const {
      name,
      label,
      colours,
      typefaces,
      value,
      onChange,
      withParagraphAlignment,
    } = this.props;

    const patch = (change): Font => ({ ...value, ...change });

    return (
      <Container>
        <Row>
          <Label>{label}</Label>
          <FlexGrowOne>
            <ColourSelection
              input={{
                value: value.colour,
                onChange: colour => colour && onChange(patch({ colour })),
              }}
              options={colours}
            />
          </FlexGrowOne>
        </Row>
        <Row expandChildren>
          <Dropdown
            name={`${name}-font-typeface-selector`}
            input={{
              value: value.typeface,
              onChange: typeface =>
                onChange(patch({ typeface: typeface || { value: null } })),
              placeholder: 'Template Font',
            }}
            options={typefaces}
          />
        </Row>
        <Row>
          <Left>
            <Bullet>Font size</Bullet>
          </Left>
          <Right>
            <InputField
              maxInputWidth={40}
              type="number"
              inputLabel="px"
              inputLabelPosition="right"
              input={{
                onChange: e => onChange(patch({ fontSize: e.target.value })),
                name: `${name}-font-size-input`,
                value: value.fontSize,
              }}
              inputFlexMode="1"
              min="0"
            />
          </Right>
        </Row>
        <Row>
          <Left>
            <Bullet>Character spacing</Bullet>
          </Left>
          <Right>
            <InputField
              maxInputWidth={40}
              type="number"
              inputLabel="px"
              inputLabelPosition="right"
              input={{
                onChange: e => onChange(patch({ kerning: e.target.value })),
                name: `${name}-font-char-spacing-input`,
                value: value.kerning,
              }}
              inputFlexMode="1"
            />
          </Right>
        </Row>
        <Row>
          <Bullet>Transform case</Bullet>
          <FixedWidthBox width={70}>
            <TextTransform
              name={`${name}-font-text-transform`}
              value={value.transform}
              open={this.state.textTransformOpen}
              onClose={() => this.openCloseTextTransform(false)}
              onOpen={() => this.openCloseTextTransform(true)}
              onChange={transform => onChange(patch({ transform }))}
              inline
            />
          </FixedWidthBox>
        </Row>
        {!withParagraphAlignment ? null : (
          <Row>
            <Bullet>Paragraph</Bullet>
            <FixedWidthBox width={70}>
              <TextAlignment
                name={`${name}-font-text-alignment`}
                value={value.alignment}
                open={false}
                onChange={alignment => onChange(patch({ alignment }))}
                inline
              />
            </FixedWidthBox>
          </Row>
        )}
      </Container>
    );
  }
}

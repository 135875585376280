// @flow
import React from 'react';
import styled from 'styled-components';
import { Button } from 'cms-ui';

const HiddenFileInput = styled.input.attrs(() => ({ type: 'file' }))`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: -100%;
`;

type Props = {
  input: { name: string, onChange: Function },
  label: string,
  accept?: string,
};

export default function FileUpload({ input, label, accept }: Props) {
  const fileProps = { ...input, value: undefined };
  return (
    <div>
      <Button label primary link htmlFor={input.name}>
        {label}
      </Button>
      <HiddenFileInput
        type="file"
        name="fileUpload"
        accept={accept}
        id={input.name}
        {...fileProps}
      />
    </div>
  );
}

FileUpload.defaultProps = {
  accept: '',
};

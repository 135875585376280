// @flow
import React from 'react';
import styled from 'styled-components';

import { HeadingThree, fontStyleOverride } from './Global';
import Chunky from './JaguarIcons/Arrows/Chunky';
import Criteria from './JaguarIcons/Global/Criteria';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const MobileFiltersOverlay = styled.div`
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: white;
  overflow-y: auto;
`;

const SearchMenuContainer = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour};`};
  ${({ borderColour }) =>
    borderColour && `border-bottom: 1px ${borderColour.value} solid;`};
  align-items: center;
  margin-bottom: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ padding }) =>
    padding
      ? `${padding.top}px ${padding.right}px ${padding.bottom}px ${
          padding.left
        }px`
      : '4px 0 0 12px'};
  ${({ direction }) =>
    direction === 'rtl' ? 'padding: 4px 12px 0 0' : 'padding: 4px 0 0 12px'};
`;

const MenuLabel = styled.div`
  display: flex;
  color: #fff;
  flex: 1 0 0%;
  padding: 0 8px;
`;

const ToggleContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

const BackChevron = styled.div`
  transform: rotate(180deg);
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  width: 16px;
  padding: 0 16px;
  transform: ${({ collapsed }) =>
    collapsed ? 'rotate(0deg)' : 'rotate(90deg)'};
  top: ${({ collapsed }) => (collapsed ? '18px' : '18px')};
  right: ${({ collapsed }) => (collapsed ? '20px' : '16px')};
`;

const FilterHeader = styled.div`
  display: flex;
  margin: 14px 12px;
`;

const FilterHeaderTitle = styled.div`
  margin: 0 auto;
`;

const IconWrapper = styled.img`
  width: 24px;
  height: 24px;
`;

type Props = {
  config: Object,
  filters: Node,
  translations: Object,
  activeFiltersCount: number,
  globalStyling: Object,
  backgroundColour: Option,
  borderColour: Option,
};

const Menu = ({
  config,
  displayMobileFilterOptions,
  toggle,
  activeFiltersCount,
  backgroundColour,
  borderColour,
  chevronColour,
  translations,
  globalStyling,
}) => (
  <SearchMenuContainer
    backgroundColour={backgroundColour}
    borderColour={borderColour}
    onClick={toggle}
  >
    <IconContainer
      padding={config.cogIconPadding}
      direction={globalStyling.direction}
    >
      <Criteria
        colour={
          (config.mobileIconColor && config.mobileIconColor.value) || '#7E7E7E'
        }
      />
    </IconContainer>
    <MenuLabel>
      <HeadingThree
        styleOverride={() => `${fontStyleOverride(config.headlineFont)}`}
      >
        {activeFiltersCount
          ? translateFromTemplate(
              'mobileSearchMenuLabel',
              {
                COUNT: activeFiltersCount,
              },
              translations,
            )
          : translations.mobileSearchMenuNoCountLabel}
      </HeadingThree>
    </MenuLabel>
    <ToggleContainer>
      <ToggleButton collapsed={!displayMobileFilterOptions}>
        {config.arrowRightIcon ? (
          <IconWrapper
            direction={globalStyling.direction}
            src={config.arrowRightIcon}
            alt=""
          />
        ) : (
          <Chunky direction={globalStyling.direction} colour={chevronColour} />
        )}
      </ToggleButton>
    </ToggleContainer>
  </SearchMenuContainer>
);

const FilterOverlay = ({ translations, toggle, filters, globalStyling }) => (
  <MobileFiltersOverlay>
    <FilterHeader>
      <BackChevron onClick={toggle}>
        <Chunky
          colour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
        />
      </BackChevron>
      <FilterHeaderTitle>
        {translations.mobileSearchHeaderLabel}
      </FilterHeaderTitle>
    </FilterHeader>
    {filters}
  </MobileFiltersOverlay>
);

export default function MobileFilters(props: Props) {
  const filterProps = props.filters.props;
  const { globalStyling, config } = props;
  const chevronColour =
    (config.chevronColour && config.chevronColour.value) ||
    (globalStyling.colours.primaryBrandColour &&
      globalStyling.colours.primaryBrandColour.value);
  return (
    <Container>
      <Menu
        {...{
          toggle: filterProps.toggle,
          displayMobileFilterOptions: filterProps.displayMobileFilterOptions,
          ...props,
          chevronColour,
        }}
      />
      {filterProps.displayMobileFilterOptions && (
        <FilterOverlay {...{ toggle: filterProps.toggle, ...props }} />
      )}
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';
import type { Option, Font, Link as LinkType } from '../../types';
import {
  HeadingTwo,
  Button,
  Paragraph,
  fontStyleOverride,
  buttonStyleOverride,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';

import { actions as routerActions } from '../../actions/router';

export interface Config {
  imageAlign: string;
  selectedImage: Option;
  backgroundColour: Option;
  headlineFont: Font;
  bodyFont: Font;
  buttonFont: Font;
  buttonColour: Option;
  buttonHover: Option;
  buttonBackgroundColour: Option;
  buttonText: LinkType;
  translations: {
    headlineText: string,
    bodyText: string,
    buttonText: string,
  };
  contentAlign: string;
}

type Props = {
  config: Config,
  preview: boolean,
  globalStyling: Object,
  dispatch: Function,
};

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  display: flex;
  flex-direction: ${props =>
    props.imageAlign === 'left' ? 'row' : 'row-reverse'};
  ${media.min.medium`
    min-height: 450px;
    max-height: 600px;
    height: 100%;
  `};
  ${media.max.small`
    flex-direction: column;
    max-height:none;
  `};
  ${({ border }) =>
    `
    border-bottom: 1px solid ${border};
  `};
`;

const Column = styled.div.withConfig({
  displayName: 'Column',
})`
  width: 50%;
  ${media.max.medium`
    width: 100%;
  `};
`;

const Spacer = styled.div.withConfig({
  displayName: 'Spacer',
})`
  margin-bottom: 20px;
`;

const Image = styled.div.withConfig({
  displayName: 'Image',
})`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  ${media.max.medium`
    width: 100%;
    height: 300px;
  `};
`;

const contentAlignResolver = (align: string) => {
  if (align === 'center') {
    return 'center';
  }
  if (align === 'bottom') {
    return 'flex-end';
  }
  return 'flex-start';
};

const ColumnWithFlex = styled(Column).withConfig({
  displayName: 'Column With Flex',
})`
  justify-content: ${props => contentAlignResolver(props.contentAlign)};
  display: flex;
  flex-direction: column;
  ${media.max.medium`
    display:block;
  `};
`;

const TextContainer = styled.div`
  line-height: 21px;
  ${({ maxWidth }) =>
    maxWidth &&
    `
  max-width: ${maxWidth}px;
`};
`;

const DivWithPadding = styled.div.withConfig({
  displayName: 'Div With Padding',
})`
  padding: 25px 25px 25px 25px;
`;

export default function FiftyFifty({ config, globalStyling, dispatch }: Props) {
  const link = config.buttonText || {};

  return (
    <ContentWrapper
      colour={config.backgroundColour && config.backgroundColour}
      contentWidth={globalStyling.contentWidth}
    >
      <Container
        colour={config.backgroundColour && config.backgroundColour.value}
        imageAlign={config.imageAlign || 'left'}
        border={config.bottomBorder && config.borderColour.value}
      >
        <Column>
          <Image src={config.selectedImage && config.selectedImage.value} />
        </Column>
        <ColumnWithFlex contentAlign={config.contentAlign}>
          <DivWithPadding>
            <Spacer>
              <HeadingTwo
                styleOverride={() => fontStyleOverride(config.headlineFont)}
              >
                {config.translations.headlineText}
              </HeadingTwo>
            </Spacer>
            <TextContainer maxWidth={config.contentMaxWidth}>
              <Paragraph
                styleOverride={() => `
                white-space: pre-wrap;
                ${fontStyleOverride(config.bodyFont)}`}
              >
                {config.translations.bodyText}
              </Paragraph>
            </TextContainer>
          </DivWithPadding>
          {link.on && (
            <DivWithPadding>
              <Button
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
                applyStyle="primary"
                styleOverride={() =>
                  buttonStyleOverride(
                    config.buttonFont,
                    config.buttonBackgroundColour,
                    config.buttonHover,
                    config.buttonSize,
                    config.buttonHoverColour,
                  )
                }
                text={config.translations.buttonText}
                onClick={() =>
                  dispatch(routerActions.navigate(config.buttonText.url))
                }
              />
            </DivWithPadding>
          )}
        </ColumnWithFlex>
      </Container>
    </ContentWrapper>
  );
}

import editor from './editor';

export default {
  id: 'Brick',
  name: 'Brick',
  description: 'Add brick pod',
  defaultSlice: 'Content',
  defaultConfig: {
    translations: {
      headlineText: 'Lorem Ipsum',
      bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    image: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
      label: 'Default Icon',
    },
    headlineFont: { fontSize: '20', kerning: '2' },
    bodyFont: { fontSize: '14', kerning: '2' },
    border: ['top', 'right', 'bottom', 'left'],
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078989/editor/Side-Icon-MidTier.svg',
  editor,
  requirements: {
    columns: [2, 3, 4],
  },
};

import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_VDP';

export const constants = moduleConstants(modulePrefix, [
  'GET_VEHICLE_INFO',
  'GET_VEHICLE_INFO_SUCCESS',
  'GET_VEHICLE_INFO_FAILURE',
  'GET_SIMILAR_VEHICLES',
  'GET_SIMILAR_VEHICLES_SUCCESS',
  'GET_SIMILAR_VEHICLES_FAILURE',
  'SIMILAR_VEHICLES_NEXT',
  'SIMILAR_VEHICLES_PREV',
  'SIMILAR_VEHICLES_NEXT_MOBILE',
  'SIMILAR_VEHICLES_PREV_MOBILE',
  'ACCESSORIES_NEXT_MOBILE',
  'ACCESSORIES_PREV_MOBILE',
  'UPDATE_MY_DEAL_WITH_PART_EXCHANGE',
  'UPDATE_MY_DEAL_PRODUCT',
  'SAVE_MY_DEAL',
  'SAVE_MY_DEAL_SUCCESS',
  'SAVE_MY_DEAL_FAILURE',
  'PRINT_MY_DEAL',
  'PRINT_MY_DEAL_SUCCESS',
  'PRINT_VEHICLE',
  'PRINT_VEHICLE_SUCCESS',
  'SEND_FINANCE_LEAD_SUCCESS',
  'SEND_FINANCE_LEAD_FAILURE',
  'GET_FINANCE_SUCCESS',
  'GET_SIMILAR_VEHICLES_FINANCE_SUCCESS',
  'CHANGE_FINANCE_PRODUCT',
  'CHANGE_FINANCE_PRODUCT_SUCCESS',
  'UPDATE_VEHICLE_PRICES',
  'UPDATE_VEHICLE_PRICES_SUCCESS',
]);

export const id = 'VDP';

export const actions = {
  sendFinanceLeadSuccess: moduleAction(constants.SEND_FINANCE_LEAD_SUCCESS, id),
  sendFinanceLeadFailure: moduleAction(constants.SEND_FINANCE_LEAD_FAILURE, id),
  getVehicleInfo: moduleActionWithArgs(
    constants.GET_VEHICLE_INFO,
    id,
    payload => payload,
  ),
  getVehicleInfoSuccess: moduleActionWithArgs(
    constants.GET_VEHICLE_INFO_SUCCESS,
    id,
    payload => payload,
  ),
  getVehicleInfoFailure: moduleActionWithArgs(
    constants.GET_VEHICLE_INFO_FAILURE,
    id,
    error => error,
  ),
  getSimilarVehicles: moduleActionWithArgs(
    constants.GET_SIMILAR_VEHICLES,
    id,
    payload => payload,
  ),
  getSimilarVehiclesSuccess: moduleActionWithArgs(
    constants.GET_SIMILAR_VEHICLES_SUCCESS,
    id,
    payload => payload,
  ),
  getSimilarVehiclesFailure: moduleActionWithArgs(
    constants.GET_SIMILAR_VEHICLES_FAILURE,
    id,
    error => error,
  ),
  similarVehiclesNext: moduleAction(constants.SIMILAR_VEHICLES_NEXT, id),
  similarVehiclesPrev: moduleAction(constants.SIMILAR_VEHICLES_PREV, id),
  similarVehiclesNextMobile: moduleAction(
    constants.SIMILAR_VEHICLES_NEXT_MOBILE,
    id,
  ),
  similarVehiclesPrevMobile: moduleAction(
    constants.SIMILAR_VEHICLES_PREV_MOBILE,
    id,
  ),
  accessoriesNextMobile: moduleAction(constants.ACCESSORIES_NEXT_MOBILE, id),
  accessoriesPrevMobile: moduleAction(constants.ACCESSORIES_PREV_MOBILE, id),
  updateMyDealWithPartExchange: moduleActionWithArgs(
    constants.UPDATE_MY_DEAL_WITH_PART_EXCHANGE,
    id,
    finance => finance,
  ),
  updateMyDealProduct: moduleActionWithArgs(
    constants.UPDATE_MY_DEAL_PRODUCT,
    id,
    finance => finance,
  ),
  saveMyDeal: moduleActionWithArgs(
    constants.SAVE_MY_DEAL,
    id,
    payload => payload,
  ),
  saveMyDealSuccess: moduleAction(constants.SAVE_MY_DEAL_SUCCESS, id),
  saveMyDealFailure: moduleActionWithArgs(
    constants.SAVE_MY_DEAL_FAILURE,
    id,
    error => error,
  ),
  printMyDeal: moduleActionWithArgs(
    constants.PRINT_MY_DEAL,
    id,
    finance => finance,
  ),
  printMyDealSuccess: moduleAction(constants.PRINT_MY_DEAL_SUCCESS, id),
  printVehicle: moduleActionWithArgs(
    constants.PRINT_VEHICLE,
    id,
    payload => payload,
  ),
  printVehicleSuccess: moduleAction(constants.PRINT_MY_DEAL_SUCCESS, id),
  getFinanceSuccess: moduleActionWithArgs(
    constants.GET_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  getSimilarVehiclesFinanceSuccess: moduleActionWithArgs(
    constants.GET_SIMILAR_VEHICLES_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  changeFinanceProduct: moduleActionWithArgs(
    constants.CHANGE_FINANCE_PRODUCT,
    id,
    payload => payload,
  ),
  changeFinanceProductSuccess: moduleActionWithArgs(
    constants.CHANGE_FINANCE_PRODUCT_SUCCESS,
    id,
    payload => payload,
  ),
  updateVehiclePrices: moduleActionWithArgs(
    constants.UPDATE_VEHICLE_PRICES,
    id,
    payload => payload,
  ),
  updateVehiclePricesSuccess: moduleActionWithArgs(
    constants.UPDATE_VEHICLE_PRICES_SUCCESS,
    id,
    payload => payload,
  ),
};

const replaceIfExists = (vehicles, vehicle) => [
  ...vehicles.map(v => (v.id === vehicle.id ? vehicle : v)),
];

export const initialState = {
  vdpConfig: null,
  error: null,
  similarVehicles: null,
  vehiclesInView: null,
  similarVehiclePage: 1,
  similarVehiclePageTotal: null,
  selectedVehicleMobile: 0,
  similarVehiclePageMobile: 1,
  similarVehiclePageTotalMobile: null,
  accessories: null,
  accessoriesInView: null,
  accessoriesPage: 1,
  accessoriesPageTotal: null,
  selectedAccessoryMobile: 0,
  accessoriesPageMobile: 1,
  accessoriesPageTotalMobile: null,
  context: '',
  printing: false,
};

// the pagination logic in a nutshell is as follows...
// we have a similarVehicles array which we get from the service which can be a maximum
// of 9 vehicles long. We then slice the array into a vehiclesInView array which is
// a view of up to 3 vehicles on a "page".
// On desktop we then just increase or decrease the "page" number accordingly and re-slice the
// vehiclesInView array to match.

// on mobile it becomes more complicated because we only show 1 vehicle at a time.
// when on mobile there is a concept of a "selected vehicle" which is just the index of a vehicle
// in the vehiclesInView array. We need to increase/decrease the index in view up until
// the boundaries of the vehiclesInView array are hit and then increase or decrease the "page"
// number as before on desktop.
const maxVehiclesPerPage = 3;
const maxAccessoriesPerPage = 3;
// as per comments above
const take = n => collection => collection.slice(0, n);
const takeAccessories = take(maxAccessoriesPerPage);
const takeAccessoriesInView = take(maxAccessoriesPerPage);

function defineVehiclesInView(state, similarVehiclePage) {
  return state.similarVehicles.contents.slice(
    maxVehiclesPerPage * (similarVehiclePage - 1),
    // eslint-disable-next-line
    maxVehiclesPerPage * (similarVehiclePage - 1) + maxVehiclesPerPage,
  );
}

function similarVehiclesNextMobile(state) {
  let { selectedVehicleMobile } = state;
  selectedVehicleMobile =
    selectedVehicleMobile === state.vehiclesInView.length - 1
      ? 0
      : selectedVehicleMobile + 1;

  let { similarVehiclePageMobile } = state;
  similarVehiclePageMobile =
    similarVehiclePageMobile === state.similarVehiclePageTotalMobile
      ? 1
      : (similarVehiclePageMobile += 1);

  let { similarVehiclePage } = state;
  similarVehiclePage =
    state.selectedVehicleMobile === state.vehiclesInView.length - 1
      ? (similarVehiclePage += 1)
      : similarVehiclePage;

  if (state.similarVehiclePageMobile === state.similarVehiclePageTotalMobile) {
    similarVehiclePage = 1;
  }

  const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  return {
    ...state,
    selectedVehicleMobile,
    similarVehiclePageMobile,
    similarVehiclePage,
    vehiclesInView,
  };
}
function similarVehiclesPrevMobile(state) {
  let { similarVehiclePageMobile } = state;
  similarVehiclePageMobile =
    similarVehiclePageMobile === 1
      ? state.similarVehiclePageTotalMobile
      : (similarVehiclePageMobile -= 1);

  let { similarVehiclePage } = state;
  similarVehiclePage =
    state.selectedVehicleMobile === 0
      ? (similarVehiclePage -= 1)
      : similarVehiclePage;

  if (state.similarVehiclePageMobile === 1) {
    similarVehiclePage = state.similarVehiclePageTotal;
  }

  const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  let { selectedVehicleMobile } = state;
  selectedVehicleMobile =
    selectedVehicleMobile === 0
      ? vehiclesInView.length - 1
      : (selectedVehicleMobile -= 1);

  return {
    ...state,
    selectedVehicleMobile,
    similarVehiclePageMobile,
    similarVehiclePage,
    vehiclesInView,
  };
}

function defineAccessoriesInView(state, accessoriesPage) {
  return state.accessories.slice(
    maxAccessoriesPerPage * (accessoriesPage - 1),
    // eslint-disable-next-line
    maxAccessoriesPerPage * (accessoriesPage - 1) + maxAccessoriesPerPage,
  );
}

function accessoriesNextMobile(state) {
  let { selectedAccessoryMobile } = state;
  selectedAccessoryMobile =
    selectedAccessoryMobile === state.accessoriesInView.length - 1
      ? 0
      : selectedAccessoryMobile + 1;

  let { accessoriesPageMobile } = state;
  accessoriesPageMobile =
    accessoriesPageMobile === state.accessoriesPageTotalMobile
      ? 1
      : (accessoriesPageMobile += 1);

  let { accessoriesPage } = state;
  accessoriesPage =
    state.selectedAccessoryMobile === state.accessoriesInView.length - 1
      ? (accessoriesPage += 1)
      : accessoriesPage;

  if (state.accessoriesPageMobile === state.accessoriesPageTotalMobile) {
    accessoriesPage = 1;
  }

  const accessoriesInView = defineAccessoriesInView(state, accessoriesPage);

  return {
    ...state,
    selectedAccessoryMobile,
    accessoriesPageMobile,
    accessoriesPage,
    accessoriesInView,
  };
}
function accessoriesPrevMobile(state) {
  let { accessoriesPageMobile } = state;
  accessoriesPageMobile =
    accessoriesPageMobile === 1
      ? state.accessoriesPageTotalMobile
      : (accessoriesPageMobile -= 1);

  let { accessoriesPage } = state;
  accessoriesPage =
    state.selectedAccessoryMobile === 0
      ? (accessoriesPage -= 1)
      : accessoriesPage;

  if (state.accessoriesPageMobile === 1) {
    accessoriesPage = state.accessoriesPageTotal;
  }

  const accessoriesInView = defineAccessoriesInView(state, accessoriesPage);

  let { selectedAccessoryMobile } = state;
  selectedAccessoryMobile =
    selectedAccessoryMobile === 0
      ? accessoriesInView.length - 1
      : (selectedAccessoryMobile -= 1);

  return {
    ...state,
    selectedAccessoryMobile,
    accessoriesPageMobile,
    accessoriesPage,
    accessoriesInView,
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_VEHICLE_INFO_SUCCESS: {
      const {
        vdpConfig,
        accessories: accessoriesCollection,
        placeholdingImage,
      } = action.payload;
      const accessories = accessoriesCollection
        ? takeAccessories(accessoriesCollection)
        : null;
      const accessoriesPageTotal = accessories
        ? Math.ceil(accessories.length / maxAccessoriesPerPage)
        : 0;
      const accessoriesInView = accessories
        ? takeAccessoriesInView(accessories)
        : undefined;
      return {
        ...state,
        vdpConfig,
        accessories,
        placeholdingImage,
        accessoriesInView,
        accessoriesPageTotal,
        accessoriesPageTotalMobile: accessories ? accessories.length : 0,
      };
    }
    case constants.GET_FINANCE_SUCCESS: {
      return {
        ...state,
        vdpConfig: {
          ...state.vdpConfig,
          ...(state.vdpConfig.id === action.payload.id ? action.payload : {}),
        },
      };
    }
    case constants.GET_SIMILAR_VEHICLES_FINANCE_SUCCESS: {
      return {
        ...state,
        similarVehicles: {
          ...state.similarVehicles,
          contents: replaceIfExists(
            state.similarVehicles.contents || [],
            action.payload,
          ),
        },
        vehiclesInView: replaceIfExists(
          state.vehiclesInView || [],
          action.payload,
        ),
      };
    }
    case constants.GET_SIMILAR_VEHICLES_SUCCESS: {
      const { similarVehicles } = action.payload;
      const similarVehiclePageTotal = Math.ceil(
        similarVehicles.contents.length / maxVehiclesPerPage,
      );
      const vehiclesInView = similarVehicles.contents.slice(0, 3);
      return {
        ...state,
        similarVehicles,
        vehiclesInView,
        similarVehiclePageTotal,
        similarVehiclePageTotalMobile: similarVehicles.contents.length,
      };
    }
    case constants.SIMILAR_VEHICLES_NEXT: {
      let { similarVehiclePage } = state;
      similarVehiclePage =
        similarVehiclePage === state.similarVehiclePageTotal
          ? 1
          : (similarVehiclePage += 1);
      const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);
      return {
        ...state,
        vehiclesInView,
        similarVehiclePage,
      };
    }
    case constants.SIMILAR_VEHICLES_PREV: {
      let { similarVehiclePage } = state;
      similarVehiclePage =
        similarVehiclePage === 1
          ? state.similarVehiclePageTotal
          : (similarVehiclePage -= 1);
      const vehiclesInView = defineVehiclesInView(state, similarVehiclePage);
      return {
        ...state,
        vehiclesInView,
        similarVehiclePage,
      };
    }
    case constants.SIMILAR_VEHICLES_NEXT_MOBILE: {
      return similarVehiclesNextMobile(state);
    }

    case constants.SIMILAR_VEHICLES_PREV_MOBILE: {
      return similarVehiclesPrevMobile(state);
    }
    case constants.UPDATE_MY_DEAL_PRODUCT:
      return {
        ...state,
        vdpConfig: {
          ...state.vdpConfig,
          finance: {
            partExchange:
              state.vdpConfig.finance && state.vdpConfig.finance.partExchange,
            ...action.payload,
          },
        },
      };
    case constants.ACCESSORIES_NEXT_MOBILE: {
      return accessoriesNextMobile(state);
    }
    case constants.ACCESSORIES_PREV_MOBILE: {
      return accessoriesPrevMobile(state);
    }
    case constants.GET_SIMILAR_VEHICLES_FAILURE:
    case constants.GET_VEHICLE_INFO_FAILURE:
    case constants.SAVE_MY_DEAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case constants.CHANGE_FINANCE_PRODUCT_SUCCESS:
      return {
        ...state,
        context: action.payload,
      };
    case constants.PRINT_MY_DEAL:
      return {
        ...state,
        printing: true,
      };
    case constants.PRINT_MY_DEAL_SUCCESS:
      return {
        ...state,
        printing: false,
      };
    case constants.UPDATE_VEHICLE_PRICES_SUCCESS:
      return {
        ...state,
        vdpConfig: action.payload,
      };
    default:
      return state;
  }
}

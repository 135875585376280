import {
  required,
  validEmail,
  validPhone,
  validCheckboxes,
} from './validationFunctions';

const validations = {
  required,
  email: validEmail,
  phone: validPhone,
  checkboxes: validCheckboxes,
};

export default function validate(field, translations) {
  if (!field.validation) {
    return undefined;
  }

  const result = validations[field.validation](field.value, translations);
  let returnValue;

  if (result && result.length > 0) {
    [returnValue] = result;
  }

  return returnValue;
}

export function validateAll(fields, translations) {
  const errors = Object.values(fields).reduce((acc, field) => {
    return { ...acc, [field.name]: validate(field, translations) };
  }, {});

  return errors;
}

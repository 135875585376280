// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_SEARCH';

export const constants = moduleConstants(modulePrefix, [
  'GET_INVENTORY_GROUP',
  'GET_INVENTORY_GROUP_SUCCESS',
  'GET_MODEL_GROUPS_AND_INVENTORY',
  'GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS',
  'GET_FINANCE_SUCCESS',
  'RESET_FILTERS_FAILURE',
  'INIT',
]);

export const id = 'Search';

// @TODO: The models have accents and trailing white spaces in some cases, the modelsGroups do not
// so need to normalize the string to perform lookups
// we need to get the ModelGroups end point to return proper model name data :-(
const normalize = str =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
const modelLookup = (modelGroups, model) =>
  modelGroups.find(
    m => normalize(m.name.toLowerCase()) === normalize(model.toLowerCase()),
  ) || {};

const transform = (lookupModels, placeholdingImage) => ({
  model,
  modelDisplay,
  count,
  minimumPrice,
  finance,
  financeLoaded,
}) => {
  const m = modelLookup(lookupModels, model);
  return {
    model,
    modelDisplay,
    count,
    minimumPrice,
    finance,
    financeLoaded,
    position: m.position || 0,
    imageUrl: m.imageUrl || placeholdingImage,
    modelSubTitle: m.modelSubTitle,
  };
};

const mergeAndSortInventoryGroup = (
  inventoryGroup,
  modelGroups,
  placeholdingImage,
) =>
  inventoryGroup
    .map(transform(modelGroups, placeholdingImage))
    .sort((a, b) => a.position - b.position);

export const actions = {
  getInventoryGroup: moduleActionWithArgs(
    constants.GET_INVENTORY_GROUP,
    id,
    payload => payload,
  ),
  getInventoryGroupSuccess: moduleActionWithArgs(
    constants.GET_INVENTORY_GROUP_SUCCESS,
    id,
    config => config,
  ),
  getModelGroupsAndInventory: moduleActionWithArgs(
    constants.GET_MODEL_GROUPS_AND_INVENTORY,
    id,
    payload => payload,
  ),
  getModelGroupsAndInventorySuccess: moduleActionWithArgs(
    constants.GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS,
    id,
    payload => payload,
  ),
  getFinanceSuccess: moduleActionWithArgs(
    constants.GET_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
  init: moduleAction(constants.INIT, id),
};

const replaceIfExists = (groups, group) => [
  ...groups.map(g => (g.model === group.model ? group : g)),
];

export const initialState = {
  searchConfig: {
    inventoryGroup: [],
    modelGroups: [],
    placeholdingImage: '',
  },
};

type State = {
  searchConfig: {
    inventoryGroup: [],
    modelGroups: [],
    placeholdingImage: '',
  },
};

type Action = {
  type: string,
  payload: any,
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.GET_INVENTORY_GROUP_SUCCESS: {
      const { inventoryGroup, placeholdingImage } = action.payload;

      const mergedInventoryGroup = mergeAndSortInventoryGroup(
        inventoryGroup,
        state.searchConfig.modelGroups,
        placeholdingImage,
      );

      return {
        ...state,
        searchConfig: {
          ...state.searchConfig,
          inventoryGroup: mergedInventoryGroup,
        },
      };
    }
    case constants.GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS: {
      const {
        modelGroups,
        inventoryGroup,
        placeholdingImage,
        modelSubTitle,
      } = action.payload;
      const mergedInventoryGroup = mergeAndSortInventoryGroup(
        inventoryGroup,
        modelGroups,
        placeholdingImage,
        modelSubTitle,
      );

      return {
        ...state,
        searchConfig: {
          ...state.searchConfig,
          modelGroups,
          inventoryGroup: mergedInventoryGroup,
        },
      };
    }
    case constants.GET_INVENTORY_GROUP_FAILURE:
    case constants.GET_MODEL_GROUPS_AND_INVENTORY_FAILURE:
    case constants.GET_FINANCE_SUCCESS: {
      const { group, placeholdingImage } = action.payload;
      const inventoryGroup = replaceIfExists(
        state.searchConfig.inventoryGroup,
        group,
      );
      const mergedInventoryGroup = mergeAndSortInventoryGroup(
        inventoryGroup,
        state.searchConfig.modelGroups,
        placeholdingImage,
      );

      return {
        ...state,
        searchConfig: {
          ...state.searchConfig,
          inventoryGroup: mergedInventoryGroup,
        },
      };
    }
    default:
      return state;
  }
}

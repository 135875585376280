import { put, call, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { post } from '../../helpers/http';
import { authenticationCoreServiceUrl } from '../selectors/settings';

const isValidToken = token =>
  token !== undefined &&
  token != null &&
  Object.keys(token).length === 2 &&
  new Date(token.expiryDateTime) >= Date.now();

function* getLeadsToken() {
  const leadsAuthentication = yield select(
    state => state.shared.leadsAuthentication,
  );
  try {
    if (!isValidToken(leadsAuthentication)) {
      const apac = yield select(state => state.config.settings.apac);
      const baseUrl = yield select(authenticationCoreServiceUrl);
      const url = `${baseUrl}${apac ? 'apac' : 'eu'}/token`;

      const response = yield call(post, { url });

      yield put(actions.getLeadsTokenSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getLeadsTokenFailure());
  }
}

export default function* leadsSaga() {
  yield takeEvery(constants.GET_LEADS_TOKEN, getLeadsToken);
}

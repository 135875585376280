// @flow
import React from 'react';
import styled from 'styled-components';

import FormField, { type FormFieldProps } from './FormField';

const TextArea = styled.textarea`
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#9e1b32' : '#d8d8d8')};
  color: #7e7e7e;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
`;

type Props = FormFieldProps & {
  type: string,
  keyValue: string,
  value: string,
  onChange: (keyValue: string, value: string) => void,
  disabled: boolean,
  placeholder: string,
  error?: string,
  globalStyling: Object,
};

export default function TextAreaField({
  doubleRow,
  label,
  required,
  type,
  keyValue,
  value,
  onChange,
  disabled,
  placeholder,
  error,
  globalStyling,
}: Props) {
  const { direction = '' } = globalStyling || {};
  return (
    <FormField
      doubleRow={doubleRow}
      label={label}
      required={required}
      globalStyling={globalStyling}
    >
      <TextArea
        type={type}
        value={value}
        onChange={e => onChange(keyValue, e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        dir={direction}
      />
      {error && (
        <div
          style={{
            color: '#9e1b32',
            textAlign: `${direction === 'rtl' ? 'right' : 'left'}`,
          }}
        >
          {error}
        </div>
      )}
    </FormField>
  );
}
TextAreaField.defaultProps = {
  error: '',
};

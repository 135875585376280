// @flow
import React, { Component } from 'react';
import querystring from 'querystring';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from '../theme';
import SearchFilters from '../components/SearchFilters';
import { actions as searchFilterActions } from '../shared/searchFilter';
import { actions as sessionPreferencesActions } from '../shared/sessionPreferences';

type Config = {
  make: string,
  market: string,
  locale: string,
  stylingConfig: Object,
  filterVisibility: Object,
  locationIconUrl: string,
  displayNames: [],
};

type Props = {
  config: Config,
  searchFilters: {
    allFilters: any,
    availableFilters: any,
  },
  clearCheckboxesBySection: (id: string) => void,
  toggleCheckboxFilter: (id: string, value: string) => void,
  handleRangeFilters: (id: string, values: number[]) => void,
  handleLocationChange: (location: Location) => void,
  handleMaxDistanceChange: (distance: string) => void,
  getAllSearchFilters: (config: Config) => { [key: string]: string[] },
  getSearchFilters: (config: Config) => { [key: string]: string[] },
  filterSearchUrl: {
    checkboxUrl: string,
    rangeUrl: string,
    locationUrl: string,
    maxDistanceUrl: string,
    resetRangeCount: number,
  },
  retailer: Object,
  selectedCheckboxFilters: string[],
  selectedRangeFilters: { [key: string]: { min: number, max: number } },
  removeRangeFilter: (id: string) => void,
  history: Object,
  featureFlags: Object,
  maxDistance: number,
};

const mapStateToProps = ({ shared }) => ({
  searchFilters: shared.searchFilter.searchFilters,
  selectedCheckboxFilters: shared.searchFilter.selectedCheckboxFilters,
  selectedRangeFilters: shared.searchFilter.selectedRangeFilters,
  filterSearchUrl: shared.searchFilter.filterSearchUrl,
  retailer: shared.searchFilter.retailer,
  maxDistance: shared.searchFilter.maxDistance,
  location: shared.sessionPreferences.location || {},
});

const mapDispatchToProps = dispatch => ({
  goToPath: path => dispatch(searchFilterActions.navigate(path)),
  clearCheckboxesBySection: section =>
    dispatch(searchFilterActions.clearCheckboxSection({ section })),
  toggleCheckboxFilter: (section, filter) =>
    dispatch(searchFilterActions.toggleCheckboxFilter({ section, filter })),
  handleRangeFilters: (id, values) =>
    dispatch(searchFilterActions.changeRangeFilter({ id, values })),
  handleLocationChange: ({ latitude, longitude }: Object) => {
    dispatch(
      sessionPreferencesActions.updateSessionPreferences('location', {
        latitude,
        longitude,
      }),
    );
  },
  handleMaxDistanceChange: (maxDistance: string) =>
    dispatch(searchFilterActions.changeMaxDistance({ maxDistance })),
  getAllSearchFilters: config =>
    dispatch(searchFilterActions.getAllSearchFilters({ ...config })),
  getSearchFilters: config =>
    dispatch(searchFilterActions.getSearchFilters({ ...config })),
  removeRangeFilter: id =>
    dispatch(searchFilterActions.removeRangeFilter({ id })),
});

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
  ${media.min.medium`
    min-width: 350px;
  `};
`;

class SearchFiltersContainer extends Component<Props, *> {
  static defaultProps = {
    searchFilters: {},
  };

  componentDidMount = () => {
    const {
      config: { market, make },
      searchFilters,
      getAllSearchFilters,
    } = this.props;
    if (!searchFilters.loaded && !searchFilters.loadingAllFilters) {
      getAllSearchFilters({ market, make });
    }
  };

  componentDidUpdate(prevProps: {}) {
    if (prevProps.filterSearchUrl !== this.props.filterSearchUrl) {
      const {
        getSearchFilters,
        searchFilters,
        config: { market, make },
        filterSearchUrl: {
          checkboxUrl = '',
          rangeUrl = '',
          locationUrl = '',
          maxDistanceUrl = '',
        },
        history,
      } = this.props;
      const queryParams =
        history && querystring.parse(history.location.search.substring(1));
      const retailerParam =
        queryParams &&
        queryParams.retailerId &&
        `&RetailerId=${queryParams.retailerId}`;
      if (!searchFilters.loadingAvailableFilters) {
        getSearchFilters({
          market,
          make,
          filters: `${checkboxUrl}${rangeUrl}${locationUrl}${maxDistanceUrl}${retailerParam}`,
        });
      }
    }
  }

  render() {
    const {
      searchFilters,
      selectedCheckboxFilters,
      selectedRangeFilters,
      config,
      clearCheckboxesBySection,
      toggleCheckboxFilter,
      handleRangeFilters,
      handleLocationChange,
      handleMaxDistanceChange,
      removeRangeFilter,
      featureFlags,
      maxDistance,
      location,
      locationIconUrl,
      stylingConfig,
    } = this.props;

    return (
      <Container>
        {searchFilters.loaded ? (
          <SearchFilters
            allFilters={searchFilters.allFilters}
            resetRangeCount={searchFilters.resetRangeCount}
            availableFilters={searchFilters.availableFilters}
            selectedCheckboxFilters={selectedCheckboxFilters}
            selectedRangeFilters={selectedRangeFilters}
            config={config}
            clearCheckboxesBySection={clearCheckboxesBySection}
            toggleCheckboxFilter={toggleCheckboxFilter}
            handleRangeFilters={handleRangeFilters}
            handleLocationChange={handleLocationChange}
            handleMaxDistanceChange={handleMaxDistanceChange}
            removeRangeFilter={removeRangeFilter}
            featureFlags={featureFlags}
            maxDistance={maxDistance}
            location={location}
            stylingConfig={stylingConfig}
            locationIconUrl={locationIconUrl}
          />
        ) : null}
      </Container>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchFiltersContainer);

import editor from './editor';

export default {
  id: 'ImageTitle',
  name: 'Image Title Pod',
  description: 'Add an image with title to the page',
  defaultSlice: 'Content',
  defaultConfig: {
    padding: 0,
    headlineFont: {
      fontSize: 28,
    },
    bodyFont: {
      fontSize: 20,
    },
    image: {
      src:
        'https://res.cloudinary.com/motortrak/image/upload/c_lpad,h_600,q_100,w_600/v1570545971/editor/photo-icon.jpg',
      label: 'Default Image',
    },
    translations: {
      headlineText: 'Lorem Ipsum',
      bodyText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    },
  },
  icon:
    'https://res.cloudinary.com/motortrak/image/upload/v1565078977/editor/Side-Icon-Carousel.svg',
  editor,
  requirements: {
    columns: [1, 2],
  },
};

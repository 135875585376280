// @flow
import React from 'react';

import { HeadingTextButton, Image, Wrapper } from '../../components';
import type {
  FeatureInventoryConfig,
  FeatureInventoryPostData,
} from '../types';
import { translateFromTemplate } from '../../../shared/localisation/translateFromTemplate';

type Props = {
  config: FeatureInventoryConfig,
  postData: FeatureInventoryPostData,
};

export default (props: Props) => {
  // get count of stocks available
  props.postData
    ? props.postData.filter(function(modelData) {
        if (
          props.config.model
            .toLowerCase()
            .indexOf(modelData.model.toLowerCase()) > -1
        ) {
          let availableText;
          if (modelData.count !== 0) {
            if (modelData.count >= 2) {
              availableText = 'availableText';
            } else {
              availableText = 'singleAvailableText';
            }
          } else {
            availableText = 'unAvailableText';
          }
          props.config.translations.bodyText = translateFromTemplate(
            availableText,
            {
              COUNT: modelData.count,
            },
            props.config.translations,
          );
        }
      })
    : '';
  return (
    <Wrapper {...props}>
      <HeadingTextButton {...props}>
        {({
          config: {
            image: { src },
            translations: { imageLabel },
            imageBehaviour,
            imagePosition,
            imageHeight,
          },
        }) => (
          <Image
            {...{ src, imageBehaviour, imagePosition, imageHeight }}
            role="img"
            aria-label={imageLabel}
          />
        )}
      </HeadingTextButton>
    </Wrapper>
  );
};

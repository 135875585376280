// @flow
import React from 'react';
import styled from 'styled-components';

const StyledFieldList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export default function FieldList(props: Object) {
  return <StyledFieldList {...props} />;
}

// @flow
import React from 'react';
import styled from 'styled-components';
import clockImg from '../assets/clock.svg';

const StyledPublishedStatus = styled.div`
  display: flex;
  flex: 2 0 0%;
`;
const Span = styled.span`
  color: ${({ theme }) => theme.colours.highlight01};
  vertical-align: middle;
  display: block;
  font-size: ${props => (props.isHeader ? '12px' : '11px')};
  font-weight: ${props => (props.isHeader ? '600' : '')};
`;

const Img = styled.img`
  margin-right: 10px;
`;

type PublishedStatusProps = {
  lastPublished: string,
  lastUpdated: string,
};
const PublishedStatus = ({
  lastPublished,
  lastUpdated,
}: PublishedStatusProps) =>
  lastPublished ? (
    <StyledPublishedStatus>
      <Img src={clockImg} alt="" />
      <div>
        <Span isHeader>
          Last Published:
          {lastPublished}
        </Span>
        <Span>
          last Edited:
          {lastUpdated}
        </Span>
      </div>
    </StyledPublishedStatus>
  ) : (
    ''
  );

export default PublishedStatus;

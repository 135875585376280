import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid/v1';
import settings from '../../settings';
import PAGE_TYPES from '../../constants/pageTypes';

import { actions as pageActions } from '../../state/actions/page';

const createPageConfig = siteId => {
  const id = uuid();
  return {
    siteId,
    id,
    pageTemplateId: settings.formPageInstanceId,
    seo: { title: '', description: '' },
    relativePath: `/${id}`,
    pageName: `form-${id}`,
    pageType: PAGE_TYPES.FORM,
    modules: [],
  };
};

const useCreateForm = () => {
  const dispatch = useDispatch();
  const currentSiteId = useSelector(state => state.site.currentSiteId);
  const createForm = () => {
    const newForm = createPageConfig(currentSiteId);
    dispatch(pageActions.createForm(newForm));
  };
  return {
    createForm,
  };
};

export default useCreateForm;

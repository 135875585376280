// @flow

import React from 'react';
import styled from 'styled-components';

import { InputField } from 'cms-ui';

import { type FieldProps } from '../models/fields';

const namedDiv = displayName => styled.div.withConfig({ displayName });

const Container = namedDiv('Container')`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;

const SocialLabel = namedDiv('SocialLabel')`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 13px;
`;

export default function URL({
  name,
  values: { id: value },
  ids: { id },
  onChange,
  ...props
}: FieldProps) {
  const input = {
    value,
    onChange: event => onChange(id, event.target.value),
  };
  return (
    <Container>
      {props.displayLabel && <SocialLabel>{name}</SocialLabel>}
      <InputField
        input={input}
        type="text"
        placeholder="http://..."
        name={name}
        initialValue={value}
        inputWidth="220"
      />
    </Container>
  );
}

// @flow
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';
import type { Node } from 'react';

import Plus from './JaguarIcons/Global/Plus';
import Minus from './JaguarIcons/Global/Minus';

const AccordionBar = styled.div`
  width: 90.5%;
  border: 1px solid #dedede;
  background-color: #ffffff;
  margin: 25px auto;
  padding: 15px 10px;
`;
const AccordionBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  text-transform: uppercase;
`;

const IconButton = styled.button.withConfig({
  displayName: 'IconButton',
})`
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const AccordionTitle = styled.div`
  padding-left: 16px;
`;

type State = {
  open: boolean,
};
type Props = {
  title: string,
  children: Node,
};
export default class VdpPopupAccordion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: props.openByDefault || true,
    };
  }

  onClickHandler = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  renderIcon = () => {
    const { open } = this.state;
    return open ? (
      <IconButton onClick={this.onClickHandler}>
        <Minus width="2em" height="2em" colour="#858585" />
      </IconButton>
    ) : (
      <IconButton onClick={this.onClickHandler}>
        <Plus width="2em" height="2em" colour="#858585" />
      </IconButton>
    );
  };

  render() {
    const { title, children } = this.props;
    const { open } = this.state;
    return (
      <AccordionBar>
        <AccordionBarContent>
          <AccordionTitle>{title}</AccordionTitle>
          {this.renderIcon()}
        </AccordionBarContent>
        {open ? children : null}
      </AccordionBar>
    );
  }
}

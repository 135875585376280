// @flow
import React, { Fragment } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import SlideOut from './SlideOut';
import InputChange from './InputChange';
import Close from './JaguarIcons/Global/Close';
import Menu from './JaguarIcons/Global/Menu';
import ThinRight from './JaguarIcons/Arrows/ThinRight';
import Search from './JaguarIcons/Global/Search';
import { HeadingTwo, fontStyleOverride } from './Global';
import media from '../theme';
import type { Item, MenuBarTheme, Theme } from '../types';
import SocialShareButton from '../modules/Header/SocialShareButton';

type SearchBarTheme = Theme & { backgroundColour: string, iconColour: string };

const MenuBarLinks = styled.ul`
  list-style-type: none;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  ${media.max.large`
    flex-direction: column;
    display: none;
  `};
`;

const MenuBarLinksMobile = styled(MenuBarLinks)`
  display: none;
  ${media.max.large`
    align-items: flex-end;
    display: flex;
    margin: 0 10px;
    justify-content: center;
  `};
`;

const MenuBarListItem = styled.li`
  width: auto;
  ${({ maxWidth }) => `${maxWidth && `max-width: ${maxWidth}px`}`};
`;

const MenuBarLink = styled.a`
  outline: none;
  text-decoration: none;
  height: 100%;
  align-items: center;
  display: flex;
  background-color: transparent;
  padding: 0 8px;
  ${({ borderColour }: { borderColour: string }) =>
    borderColour && `border-right: 1px solid ${borderColour}`};
  &:hover {
    ${({ backgroundHoverColour }) =>
      backgroundHoverColour &&
      `background-color: ${backgroundHoverColour.value}`};
  }
  ${media.max.large`
  ${({ border }) =>
    `border-bottom: ${border ? `${border}` : '1px solid #444444'}`};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    max-width: none;
`};
`;

const Chevron = styled.div`
  display: none;
  cursor: pointer;
  ${media.max.medium`display: flex;`};
`;

const CloseButtonWrapper = styled.div`
  margin: 14px;
`;

const MenuBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }: { height: string }) => height}px;
  flex-direction: ${({ align }) =>
    align && align === 'left' ? 'row-reverse' : 'unset'};
  ${({ borderColour }: { borderColour: string }) =>
    borderColour && `border-top: 1px solid ${borderColour}`};
`;

const SearchContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  ${media.max.large`
    display: none;
  `};
`;

const SearchContainerMobile = styled(SearchContainer)`
  display: none;
  ${media.max.large`
    display: flex;
    width: auto;
    align-items: center;
    border-bottom: solid 1px #444444;
    padding: 8px 24px;
    margin:0;
    line-height: 30px;
  `};
`;

const StyledInput = styled.input`
  width: 80%;
  padding-left: 5%;
  margin: 0;
  color: ${({ theme }: { theme: SearchBarTheme }) => theme.colour};
  border: none;
  background-color: ${({ theme }: { theme: SearchBarTheme }) =>
    theme.backgroundColour};
  text-transform: ${({ theme }: { theme: SearchBarTheme }) =>
    theme.textTransform};
  font-family: ${({ theme }: { theme: SearchBarTheme }) => theme.fontFamily};
  font-size: ${({ theme }: { theme: SearchBarTheme }) => theme.fontSize};
  letter-spacing: ${({ theme }: { theme: SearchBarTheme }) =>
    `${theme.characterSpacing}`};
  border-left: ${({ theme }: { theme: SearchBarTheme }) =>
    theme.showSearchInputBorder ? 'solid 1px #ffffff' : 'none'};
  &::placeholder {
    color: ${({ theme }: { theme: SearchBarTheme }) => theme.colour};
  }
  &:focus {
    border-left: ${({ theme }: { theme: SearchBarTheme }) =>
      theme.showSearchInputBorder ? 'solid 1px #ffffff' : 'none'};
    outline: none;
  }
  ${media.max.medium`
  border: none;
  padding: 2%;
  &:focus {
    border: none;
    outline: none;
  }
`};
`;

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const IconWithLabelContainer = styled.div`
  display: flex;
  padding: 15px 15px 15px 27px;
  justify-content: center;
  cursor: pointer;
  ${media.max.medium`
    justify-content: flex-start;
  `};
`;

const IconWrapper = styled.img`
  width: 16px;
  height: 16px;
`;

const Select = styled.select`
  ${({ font }) =>
    `${font && font.typeface && `font-family: ${font.typeface.value}`}`};
  ${({ font }) => `${font && font.colour && `color: ${font.colour.value}`}`};
  ${({ font }) =>
    `${font && font.transform && `text-transform: ${font.transform}`}`};
  ${({ font }) =>
    `${font && font.fontSize && `font-size: ${font.fontSize}px`}`};
  background-color: #ffffff;
  padding-left: 8px;
  border: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }
  ${media.max.medium`
    width: 100%
  `};
`;

type SearchPros = {
  withSearch: boolean,
  wrapper: any => Node,
  onSearch: (term: string) => void,
  theme: SearchBarTheme,
  placeholder: string,
  searchIconAlign: string,
};

type Props = {
  translations: Object,
  theme: MenuBarTheme,
  items: Item[],
  preview: boolean,
  backgroundColour: string,
  navigateIfInternal: string => boolean,
  withSearch: boolean,
  onSearch: (value: string) => void,
  searchBarTheme: SearchBarTheme,
  searchBarAlign: string,
  height: number,
  borderColour: string,
  placeholder: string,
  searchIconAlign: string,
  mobileMenuIconsColour: string,
  burgerMenuItemBorder: string,
  socialShareEnabled: boolean,
  utilityBarLanguageEnabled: boolean,
  utilityBarCurrencyEnabled: boolean,
  socialIcon: string,
  languageIcon: string,
  currencyIcon: string,
  socialShareProps: Object,
  utilityBarOptions: Object,
  languageActions: Function,
  currencyActions: Function,
  burgerMenuLanguageFont: Font,
  hideChevronOnMobile: boolean,
  languageChevron: string,
  currentLanguage: string,
  currentCurrency: string,
  maxWidth: number,
  config: Object,
};

const MaybeSearch = ({
  withSearch,
  wrapper: Component,
  onSearch,
  theme,
  placeholder,
  searchIconAlign,
}: SearchPros) =>
  withSearch && (
    <InputChange>
      {({ value, inputChange }) => (
        <Component>
          {searchIconAlign === 'left' && (
            <SearchIconWrapper onClick={() => onSearch(value)}>
              <Search colour={theme.iconColour} width="1.5em" height="1.5em" />
            </SearchIconWrapper>
          )}
          <StyledInput
            theme={theme}
            type="text"
            placeholder={placeholder}
            onChange={e => inputChange(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && onSearch(value)}
          />
          {searchIconAlign === 'right' && (
            <SearchIconWrapper onClick={() => onSearch(value)}>
              <Search colour={theme.iconColour} width="1.5em" height="1.5em" />
            </SearchIconWrapper>
          )}
        </Component>
      )}
    </InputChange>
  );

const renderTitle = colour => () => (
  <Menu width="1.5em" height="1.5em" colour={colour} />
);
const renderClose = colour => () => (
  <CloseButtonWrapper>
    <Close width="1em" height="1em" colour={colour} />
  </CloseButtonWrapper>
);

const isExternal = href => href.match(/^http/);

const RenderLinks = ({
  theme: { font, hoverColour, backgroundHoverColour, underLineColor },
  preview,
  items,
  keyPrefix,
  navigateIfInternal,
  borderColour,
  burgerMenuItemBorder,
  socialShareEnabled,
  utilityBarLanguageEnabled,
  utilityBarCurrencyEnabled,
  socialIcon,
  languageIcon,
  currencyIcon,
  socialShareProps,
  languageValues,
  languageActions,
  currencyValues,
  currencyActions,
  burgerMenuLanguageFont,
  hideChevronOnMobile,
  languageChevron,
  currentLanguage,
  currentCurrency,
  maxWidth,
  config,
  toggle,
}: props) => (
  <Fragment>
    {items.map(({ label, link, navId }) => (
      <MenuBarListItem key={`${keyPrefix} - ${navId}`} maxWidth={maxWidth}>
        <MenuBarLink
          borderColour={borderColour}
          href={link}
          target={isExternal(link) ? '_blank' : '_self'}
          backgroundHoverColour={backgroundHoverColour}
          onClick={event => {
            if (preview || navigateIfInternal(link)) {
              event.preventDefault();
              if (toggle) {
                toggle();
              }
            }
          }}
          border={burgerMenuItemBorder}
        >
          <HeadingTwo
            styleOverride={() => `
          ${fontStyleOverride(font)}
          margin:0;
          width: 100%;
          ${underLineColor &&
            `
            &:after {
              display:block;
              content: '';
              height: 1px;
              margin-top: 4px;
              box-sizing: border-box;
              ${underLineColor && `background-color: ${underLineColor.value}`};
              transform: scaleX(0);
              transition: transform 250ms ease-in-out;
            }
          `};
          ${underLineColor &&
            hoverColour &&
            `
            &:hover {
              ${hoverColour && `color: ${hoverColour.value}`};
              &:after { transform: scaleX(1); }
            }
         `};
         ${underLineColor &&
           !hoverColour &&
           `
            &:hover {
              &:after { transform: scaleX(1); }
            }
         `};
         ${hoverColour &&
           !underLineColor &&
           `
            &:hover {
              ${hoverColour && `color: ${hoverColour.value}`};
            }
          `};
         `}
            mobileStyleOverride={() => [
              {
                queryPath: 'max.medium',
                template: 'text-align: left;',
              },
            ]}
          >
            {label}
          </HeadingTwo>
          {hideChevronOnMobile ? null : (
            <Chevron>
              <ThinRight colour={font && font.colour && font.colour.value} />
            </Chevron>
          )}
        </MenuBarLink>
      </MenuBarListItem>
    ))}
    {utilityBarLanguageEnabled && (
      <IconWithLabelContainer>
        <IconWrapper src={languageIcon} alt="" />
        <Select
          onChange={e => languageActions({ value: e.target.value })}
          font={burgerMenuLanguageFont}
          defaultValue={currentLanguage}
        >
          {languageValues.options.map((item, index) => [
            <option key={index.toString()} value={item.value}>
              {languageValues.prefixLabel + item.label}
            </option>,
          ])}
        </Select>
        <IconWrapper src={languageChevron} alt="" />
      </IconWithLabelContainer>
    )}
    {utilityBarCurrencyEnabled && (
      <IconWithLabelContainer>
        <IconWrapper src={currencyIcon} alt="" />
        <Select
          onChange={e => currencyActions({ value: e.target.value })}
          font={burgerMenuLanguageFont}
          defaultValue={currentCurrency}
        >
          {currencyValues.options.map((item, index) => [
            <option key={index.toString()} value={item.value}>
              {currencyValues.prefixLabel + item.label}
            </option>,
          ])}
        </Select>
        <IconWrapper src={languageChevron} alt="" />
      </IconWithLabelContainer>
    )}
    {socialShareEnabled && (
      <IconWithLabelContainer>
        <SocialShareButton
          socialShare={socialShareProps}
          socialShareIcon={socialIcon}
          config={config}
        />
      </IconWithLabelContainer>
    )}
  </Fragment>
);

export default class MenuBar extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    const {
      items = [],
      preview,
      theme,
      backgroundColour,
      borderColour,
      navigateIfInternal,
      withSearch = false,
      onSearch = () => {},
      searchBarTheme,
      searchBarAlign,
      height,
      placeholder,
      searchIconAlign,
      mobileMenuIconsColour,
      burgerMenuItemBorder,
      socialShareEnabled,
      utilityBarLanguageEnabled,
      utilityBarCurrencyEnabled,
      socialIcon,
      languageIcon,
      currencyIcon,
      socialShareProps,
      utilityBarOptions,
      languageActions,
      currencyActions,
      burgerMenuLanguageFont,
      hideChevronOnMobile,
      languageChevron,
      currentLanguage,
      currentCurrency,
      maxWidth,
      config,
    } = this.props;
    const languageValues =
      utilityBarOptions && utilityBarOptions.find(f => f.key === 'Language');
    const currencyValues =
      utilityBarOptions && utilityBarOptions.find(f => f.key === 'Currency');

    return (
      <MenuBarContainer
        height={height}
        align={searchBarAlign}
        borderColour={borderColour}
      >
        <MenuBarLinks>
          <RenderLinks
            items={items}
            theme={theme}
            preview={preview}
            keyPrefix="Large"
            navigateIfInternal={navigateIfInternal}
            borderColour={borderColour}
            burgerMenuItemBorder={burgerMenuItemBorder}
            maxWidth={maxWidth}
            config={config}
          />
        </MenuBarLinks>
        <MenuBarLinksMobile>
          <SlideOut
            renderTitle={renderTitle(mobileMenuIconsColour)}
            renderClose={renderClose(mobileMenuIconsColour)}
            backgroundColour={backgroundColour}
            collapsed={this.state.collapsed}
            toggle={this.toggle}
          >
            <MaybeSearch
              theme={searchBarTheme}
              withSearch={withSearch}
              wrapper={SearchContainerMobile}
              onSearch={value => onSearch(value)}
              placeholder={placeholder}
              searchIconAlign={searchIconAlign}
            />
            <RenderLinks
              items={items}
              theme={theme}
              preview={preview}
              keyPrefix="Small"
              navigateIfInternal={navigateIfInternal}
              burgerMenuItemBorder={burgerMenuItemBorder}
              socialShareEnabled={socialShareEnabled}
              utilityBarLanguageEnabled={utilityBarLanguageEnabled}
              utilityBarCurrencyEnabled={utilityBarCurrencyEnabled}
              socialIcon={socialIcon}
              languageIcon={languageIcon}
              currencyIcon={currencyIcon}
              socialShareProps={socialShareProps}
              languageValues={languageValues}
              languageActions={languageActions}
              currencyValues={currencyValues}
              currencyActions={currencyActions}
              burgerMenuLanguageFont={burgerMenuLanguageFont}
              hideChevronOnMobile={hideChevronOnMobile}
              languageChevron={languageChevron}
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              config={config}
              toggle={this.toggle}
            />
          </SlideOut>
        </MenuBarLinksMobile>
        <MaybeSearch
          theme={searchBarTheme}
          withSearch={withSearch}
          wrapper={SearchContainer}
          onSearch={value => onSearch(value)}
          placeholder={placeholder}
          searchIconAlign={searchIconAlign}
        />
      </MenuBarContainer>
    );
  }
}

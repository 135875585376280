// @flow
import React, { type Node } from 'react';
import { createElement, mediaProps } from './styledComponentFactory';

const SelectElement = createElement('select');

type Props = {
  styleOverride?: () => string,
  mobileStyleOverride?: () => { queryPath: string, template: string }[],
  onChange: SyntheticEvent => {},
  value: any,
  children: Node,
};

export default function Select({
  styleOverride,
  mobileStyleOverride,
  onChange,
  value,
  children,
  ...props
}: Props) {
  return (
    <SelectElement
      onChange={onChange}
      value={value}
      styleOverride={styleOverride}
      {...{ ...props, ...mediaProps(mobileStyleOverride()) }}
    >
      {children}
    </SelectElement>
  );
}

Select.defaultProps = {
  styleOverride: () => '',
  mobileStyleOverride: () => [],
  onChange: () => {},
  value: null,
  children: null,
};

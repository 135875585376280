// @flow
import React from 'react';
import styled from 'styled-components';

import useLocation from './useLocation';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const BrowserLocation = styled.button`
  flex: 0 0 30px;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  ${({ direction }) =>
    `${
      direction === 'rtl'
        ? 'border-left: 1px solid transparent'
        : 'border-right: 1px solid transparent'
    }`};
  height: ${({ newFilters }) => (newFilters ? '40px;' : '46px;')};
  cursor: pointer;
  padding: 5px 13px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border: 1px solid #999999;
    outline: none;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 1 0 auto;
`;

const InputLocation = styled.input`
  height: ${({ newFilters }) => (newFilters ? '40px;' : '46px;')};
  width: 100%;
  padding: 0 12px;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;

  &:focus {
    border-color: #999999;
    outline: none;
  }
`;

const LocationSuggestions = styled.div`
  position: absolute;
  width: 100%;
  ${props => (props.position === 'below' ? 'top: 40px;' : 'bottom: 40px;')};
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  z-index: 10;
`;

const Suggestion = styled.div`
  font-size: 12px;
  padding: 5px;
  border-bottom: 1px solid #d5d5d5;
  cursor: pointer;
  outline: none;

  ${({ inFocus }) => inFocus && 'background-color: #d5d5d5;'};

  &:hover {
    background-color: #d5d5d5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

type Props = {
  placeholderText: string,
  iconUrl: string,
  direction: string,
  browserLocationCallback: () => void,
  inputLocationCallback: () => void,
  locationIconColour: string,
  newFilters: boolean,
  options?: {
    autocompletePosition?: string,
    countryCode?: string,
    latitude?: string,
    longitude?: string,
  },
};

export default ({
  placeholderText,
  iconUrl,
  direction,
  browserLocationCallback,
  inputLocationCallback,
  options = {},
  newFilters,
}: Props) => {
  const { autocompletePosition = 'below', ...rest } = options;
  const {
    formattedLocation,
    getBrowserLocation,
    getInputLocation,
    onInputChange,
    suggestions,
    visible,
    currentlySelected,
    setCurrentlySelected,
    inputRef,
    onInputKeyDown,
    onInputBlur,
    onInputFocus,
  } = useLocation(browserLocationCallback, inputLocationCallback, rest);

  return (
    <Wrapper>
      <BrowserLocation
        onClick={getBrowserLocation}
        direction={direction}
        newFilters={newFilters}
      >
        <img src={iconUrl} alt="location-icon" />
      </BrowserLocation>
      <InputWrapper>
        <InputLocation
          ref={inputRef}
          placeholder={placeholderText}
          value={formattedLocation}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          newFilters={newFilters}
        />
        {suggestions.length > 0 && formattedLocation.length > 0 && visible && (
          <LocationSuggestions position={autocompletePosition}>
            {suggestions.map((s, index) => (
              <Suggestion
                role="button"
                onMouseDown={() => {
                  setCurrentlySelected(0);
                  getInputLocation(s);
                }}
                onMouseEnter={() => setCurrentlySelected(index)}
                inFocus={currentlySelected === index}
              >
                {s.description}
              </Suggestion>
            ))}
          </LocationSuggestions>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Search({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 30 26">
        <path d="M21.75 20.862l-4.18-5.784c2.581-2.578 3.011-6.79.86-9.934C16.957 3.069 14.745 2 12.409 2h.062c-1.905 0-3.073.44-4.363 1.446-3.319 2.39-4.118 7.105-1.72 10.626 1.474 2.075 3.748 3.144 6.021 3.144 1.045 0 2.151-.252 3.134-.692v.063l4.18 5.91c.245.314.614.503.982.503.246 0 .492-.063.676-.251.676-.503.8-1.258.37-1.887zm-9.342-5.533c-1.843 0-3.564-.88-4.547-2.389-1.844-2.578-1.29-6.224 1.29-7.985a5.431 5.431 0 0 1 3.196-1.069c1.843 0 3.564.88 4.547 2.39 1.844 2.577 1.29 6.224-1.29 7.984-.86.692-2.028 1.07-3.196 1.07z" />
      </svg>
    </IconWrapper>
  );
}

Search.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

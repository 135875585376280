import Icon from '../../modules/VDP/Icon';

export { id as reducerId } from '../../modules/VDP/reducer';

export { default as component } from './VDP';
export { default as effects } from '../../modules/VDP/effects';
export { default as config } from '../../modules/VDP/config';
export { default as defaultConfig } from '../../modules/VDP/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/VDP/reducer';

export const name = 'VDP';
export const id = 'VDP';
export const availableToAdministration = false;
export const description = 'Vehicle Details Page';
export const icon = Icon;

export const searchable = true;
export const getIndexContent = ({
  config: { translations = {} } = {},
  relativePath,
  instanceId,
}) =>
  [
    {
      title: translations.vdpAccessoriesHeader,
      content: translations.vdpAccessoriesSubHeader,
    },
    {
      title: translations.vdpMyDealHeader,
      content: translations.vdpGalleryAndMyDealBottomText,
    },
  ]
    .map(({ title, content }, index) => ({
      key: `${instanceId}-${index}`,
      title,
      content,
      link: relativePath,
    }))
    .filter(({ title, content }) => !!title || !!content);

// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_EXPLORE_RANGE';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'GET_MODEL_GROUPS',
  'GET_MODEL_GROUPS_SUCCESS',
  'GET_FINANCE_SUCCESS',
]);

export const id = 'ExploreRange';

export const actions = {
  init: moduleAction(constants.INIT, id),
  getModelGroups: moduleActionWithArgs(
    constants.GET_MODEL_GROUPS,
    id,
    payload => payload,
  ),
  getModelGroupsSuccess: moduleActionWithArgs(
    constants.GET_MODEL_GROUPS_SUCCESS,
    id,
    payload => payload,
  ),
  getFinanceSuccess: moduleActionWithArgs(
    constants.GET_FINANCE_SUCCESS,
    id,
    payload => payload,
  ),
};

const replaceIfExists = (groups, group) => [
  ...groups.map(g => (g.model === group.model ? group : g)),
];

type State = {
  error: any,
  modelGroups: Object[],
};

export const initialState: State = {
  error: null,
  modelGroups: [],
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.GET_MODEL_GROUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
      };
    case constants.GET_MODEL_GROUPS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case constants.GET_FINANCE_SUCCESS: {
      return {
        ...state,
        modelGroups: replaceIfExists(state.modelGroups, action.payload),
      };
    }
    default:
      return state;
  }
}

/* eslint-disable no-console */
import { resolve } from 'url';
import {
  put,
  call,
  takeEvery,
  select,
  all,
  takeLatest,
} from 'redux-saga/effects';
import reject from 'ramda/src/reject';
import { convertVehiclePriceCurrency } from '../../shared/currencyConversion/helpers';

import { actions, constants } from './reducer';
import { actions as routerActions } from '../../actions/router';
import {
  post,
  get,
  put as update,
  localeConfigSerializer,
} from '../../helpers/http';
import {
  getToken,
  getCustomerDetails,
} from '../../shared/CustomerLogin/reducer';
import { getToken as getLeadsToken } from '../../shared/leadsAuthentication/reducer';
import {
  mapVehicleAndFinance as getDefaultFinance,
  getFinanceProduct,
  printMyDeal as printFinanceDeal,
} from '../../shared/financeCalculator/effects';
import { actions as financeActions } from '../../shared/financeCalculator/reducer';
import { localiseNumber } from '../../shared/localisation/numbers';
import { openPDF, printPos } from '../../shared/pdf';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  myDealsServiceUrl,
  getPlaceholderBrandImageUrl,
  appendLocaleQueryString,
  getLeadsUrl,
  getInventoryStatus,
  getFinanceStatus,
  getModelGroupsBaseUrl,
  getAccessoryStatus,
  getImageSize,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';
import { vanityUrlFormatter } from '../../helpers/vehicle';

let accessoriesCache;

const down = ({
  imageDomain,
  modelGroup,
  title,
  content,
  price_point: pricePoint,
}) => ({
  name: modelGroup.trim(),
  title,
  subTitle: pricePoint,
  description: content,
  imageUrl: imageDomain,
});

function* mapSimilarVehiclesAndFinance(
  vehicle,
  page,
  instance,
  financeProduct,
) {
  try {
    const vehicleWithFinance = yield getDefaultFinance(vehicle, financeProduct);
    yield put(
      actions.getSimilarVehiclesFinanceSuccess(page, instance, {
        ...vehicleWithFinance,
        financeLoaded: true,
      }),
    );
  } catch (error) {
    // noop leave vehicle as is
  }
}

const up = ({ finance, ...vehicle }) => ({
  vin: vehicle.id,
  vehicle,
  finance,
});

function* getVehicleInfo(action) {
  const {
    payload: { vehicleId, finance, financeProductToUse: financeProduct },
    module: { page, instance },
  } = action;
  let vdpConfig;
  try {
    const { locale, make, market, region, country } = yield select(
      getAVLConfig,
    );
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
    const inventoryStatus = yield select(getInventoryStatus);
    const inventoryUrl = region
      ? `${inventoryBaseUrl}inventory/make/${make}/region/${region}/${vehicleId}`
      : `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${vehicleId}`;
    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
    const modelGroupsUrl = yield call(
      resolve,
      yield select(getModelGroupsBaseUrl),
      `${make.replace(' ', '')}/${country}/${locale}/accessory_packs`,
    );

    const displayAccessories = yield select(getAccessoryStatus);
    const imageSize = yield select(getImageSize);
    const params = yield select(state => state.shared.filters.selectedFilters);
    const latitude = params && params.latitude ? params.latitude : null;
    const longitude = params && params.longitude ? params.longitude : null;
    const loadAccessories = displayAccessories
      ? [
          call(get, {
            url: modelGroupsUrl,
          }),
        ]
      : [yield {}];

    const accessories = accessoriesCache
      ? [yield { data: accessoriesCache }]
      : loadAccessories;

    const [{ data: accessoriesData }, { data: vdpData }] = yield all([
      ...accessories,
      ...[
        call(get, {
          url: inventoryUrl,
          config: localeConfigSerializer(withLocaleQueryString && locale, {
            status: inventoryStatus,
            imageSize,
            latitude,
            longitude,
          }),
        }),
      ],
    ]);

    if (!accessoriesCache && accessoriesData && !accessoriesData.status) {
      accessoriesCache = accessoriesData;
    }
    vdpConfig = {
      make,
      locale,
      ...vdpData,
      specification: {
        ...vdpData.specification,
        odometer: {
          ...vdpData.specification.odometer,
          display: `${localiseNumber(
            vdpData.specification.odometer.reading,
            locale,
          )} ${vdpData.specification.odometer.units}`,
        },
      },
      financeLoaded: !!vdpData.finance,
    };

    const currency = yield select(
      state => state.shared.sessionPreferences.currency,
    );
    const exchange = yield select(
      state => state.shared.currencyConversion.exchangeRates[currency],
    );
    if (currency && exchange) {
      const exchangeRates = exchange.rates;
      vdpConfig = convertVehiclePriceCurrency(
        vdpConfig,
        exchangeRates,
        currency,
      );
    }

    const randomAndUniqueArray = (values, length) => {
      const copy = [...values];
      return Array.from(
        { length },
        () =>
          copy.splice(
            Math.floor(Math.random() * Math.floor(copy.length)),
            1,
          )[0],
      ).reduce(
        (arr, val) =>
          !arr.find(v => v.title === val.title) ? [...arr, val] : arr,
        [],
      );
    };

    const applyAccessoriesLogic = accessoriesList => {
      const fullSet =
        accessoriesList && !accessoriesList.status
          ? accessoriesList
              .map(down)
              .filter(
                a =>
                  a.name.toLowerCase().replace(' ', '') ===
                  vdpData.modelGroupName.toLowerCase().replace(' ', ''),
              )
          : null;
      return fullSet && fullSet.length > 0
        ? randomAndUniqueArray(fullSet, 3)
        : [];
    };

    yield put(
      actions.getVehicleInfoSuccess(page, instance, {
        vdpConfig: { ...vdpConfig, finance: finance || vdpData.finance },
        accessories: applyAccessoriesLogic(accessoriesData),
        placeholdingImage,
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }

  const financeStatus = yield select(getFinanceStatus);

  if (vdpConfig && !vdpConfig.finance && financeStatus) {
    try {
      const vehicleWithFinance = yield getDefaultFinance(
        vdpConfig,
        financeProduct,
      );
      let vehicleWithDefaultDeal;

      if (finance) {
        vehicleWithDefaultDeal = {
          ...vdpConfig,
          price: {
            ...vehicleWithFinance.price,
            monthly:
              vehicleWithFinance.finance &&
              vehicleWithFinance.finance.monthlyPrice,
            apr: vehicleWithFinance.finance && vehicleWithFinance.finance.apr,
            interestRate:
              vehicleWithFinance.finance &&
              vehicleWithFinance.finance.interestRate,
          },
          financeLoaded: true,
        };
      } else {
        vehicleWithDefaultDeal = {
          ...vehicleWithFinance,
          price: {
            ...vehicleWithFinance.price,
            monthly:
              vehicleWithFinance.finance &&
              vehicleWithFinance.finance.monthlyPrice,
            apr: vehicleWithFinance.finance && vehicleWithFinance.finance.apr,
            interestRate:
              vehicleWithFinance.finance &&
              vehicleWithFinance.finance.interestRate,
          },
        };
      }

      yield put(
        actions.getFinanceSuccess(page, instance, vehicleWithDefaultDeal),
      );
    } catch (error) {
      // if fail mark as finance loaded so can display N/A
      yield put(
        actions.getFinanceSuccess(page, instance, {
          ...vdpConfig,
          financeLoaded: true,
        }),
      );
    }
  }
}

function* getSimilarVehicles(action) {
  const {
    payload: { vehicleId, financeProductToUse: financeProduct },
    module: { page, instance },
  } = action;

  try {
    const { make, market, region, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const baseUrl = yield select(inventorySearchServiceUrl);
    const inventoryStatus = yield select(getInventoryStatus);
    const collectionUrl = region
      ? `${baseUrl}inventory/make/${make}/region/${region}`
      : `${baseUrl}inventory/make/${make}/market/${market}`;
    const url = `${collectionUrl}/${vehicleId}/similarvehicles?pageSize=9&status=${inventoryStatus}`;

    const response = yield call(get, {
      url,
      config: localeConfigSerializer(withLocaleQueryString && locale),
    });

    const vehicles = [
      ...response.data.contents.map(v => ({
        ...v,
        odometer: {
          ...v.odometer,
          display: `${localiseNumber(v.odometer.reading, locale)} ${
            v.odometer.units
          }`,
        },
      })),
    ];

    yield put(
      actions.getSimilarVehiclesSuccess(page, instance, {
        similarVehicles: {
          ...response.data,
          contents: vehicles,
        },
      }),
    );
    yield all(
      vehicles.map(vehicle =>
        call(
          mapSimilarVehiclesAndFinance,
          vehicle,
          page,
          instance,
          financeProduct,
        ),
      ),
    );
  } catch (error) {
    // no-op. this shouldnt crash the page
  }
}

function getFinanceLeadPayload(
  pageState,
  market,
  {
    title,
    firstName,
    lastName,
    email,
    phoneNumber,
    postCode,
    contactPreferences: {
      email: emailConsent,
      phone: phoneConsent,
      sms: smsConsent,
      distributionList,
      marketing,
      customisedServices,
    },
  },
) {
  const preferenceToBoolean = value =>
    typeof value === 'boolean' ? value : value === 'yes';
  const preferenceToBinary = value => (value ? '1' : '0');
  const {
    id,
    finance: {
      partExchange,
      additionalServices,
      expenses,
      paymentCosts,
      ...finance
    },
  } = pageState.vdpConfig;

  const extractAdditionalServices = services => {
    const returnObj = {};
    services
      .filter(as => as.selected)
      .forEach((as, i) => {
        returnObj[`additionalService${i}.name`] = as.name;
        returnObj[`additionalService${i}.value`] = as.value;
      });
    return returnObj;
  };

  const extractExpenses = exp => {
    const returnObj = {};
    exp.forEach((expense, i) => {
      returnObj[`expenses${i}.name`] = expense.name;
      returnObj[`expenses${i}.value`] = expense.value;
    });
    return returnObj;
  };

  const extractPaymentCosts = payment =>
    payment
      ? {
          'paymentCosts.name': payment.name,
          'paymentCosts.description': payment.description,
          'paymentCosts.value': payment.value,
        }
      : {};

  return {
    leadType: 'finance',
    vehicleId: id,
    vin: id,
    refererURL: `${window.location.origin}/vdp/${id}`.toLowerCase(),
    phoneConsent,
    emailConsent,
    smsConsent,
    consents: {
      'distribution list consent': preferenceToBinary(distributionList),
      'Marketing consent': preferenceToBinary(marketing),
      ...(market.split(' ')[1] === 'italy' && {
        customisedServicesConsent: preferenceToBoolean(customisedServices),
      }),
    },
    additionalInfo: {
      comments: 'Finance Lead',
      ...{
        ...reject(value => !!value === false, finance),
        ...extractAdditionalServices(additionalServices),
        ...extractExpenses(expenses),
        ...extractPaymentCosts(paymentCosts),
      },
      ...(partExchange && {
        valuationId: partExchange.valuationId,
        valuation: partExchange.valuation,
        vehicle: partExchange.vehicle,
      }),
    },
    customer: {
      title,
      firstName,
      lastName,
      email,
      phoneNumber,
      postCode,
    },
  };
}

function* getUserDetails() {
  const userDetails = yield select(state => getCustomerDetails(state.shared));
  return userDetails;
}

function* sendFinanceLead(action) {
  const {
    module: { page, instance },
  } = action;
  const pageState = yield select(state => state.pages[page][instance]);
  const leadsToken = yield select(state => getLeadsToken(state.shared));
  const userDetails = yield getUserDetails();
  const { market } = yield select(getAVLConfig);
  const financeLeadPayload = getFinanceLeadPayload(
    pageState,
    market,
    userDetails,
  );
  const leadsUrl = yield select(getLeadsUrl);
  try {
    yield call(post, {
      url: leadsUrl,
      token: leadsToken,
      data: financeLeadPayload,
    });

    yield put(actions.sendFinanceLeadSuccess(page, instance));
  } catch (error) {
    yield put(errorActions.setError(error));
    // yield put(actions.sendFinanceLeadFailure(error));
  }
}

function* saveMyDeal(action) {
  const {
    payload: { vehicleDetails, vdpConfig },
    module: { page, instance },
  } = action;
  try {
    const url = `${yield select(myDealsServiceUrl)}finance-deals`;
    const { make, locale } = yield select(getAVLConfig);
    const token = yield select(state => getToken(state.shared));
    yield (token
      ? function* save() {
          yield call(update, {
            url,
            token,
            data: up({ ...vehicleDetails, make }),
          });
          yield put(actions.saveMyDealSuccess(page, instance));
        }
      : function* redirect() {
          yield put(
            routerActions.navigateWithPayload('/signin', {
              path: `vdp/${vehicleDetails.id}-${vanityUrlFormatter(
                vdpConfig,
                vehicleDetails,
                locale,
              )}`,
              payload: vehicleDetails.finance,
            }),
          );
        })();
  } catch (error) {
    yield put(
      actions.saveMyDealFailure(page, instance, {
        ...error,
        message: `Failed to save my deal: ${error.message}`,
      }),
    );
  }
}

function* printMyDeal({ payload, module: { page, instance } }) {
  yield call(printFinanceDeal, payload);
  yield put(actions.printMyDealSuccess(page, instance));
}

function* updateMyDealWithPartExchange(action) {
  const {
    payload: { finance, vehicleDetails },
    module: { page, instance },
  } = action;

  const updatedFinance = yield getFinanceProduct(
    vehicleDetails.id,
    finance.duration,
    finance.downpayment,
    finance.additionalServices.filter(s => s.selected).map(s => s.id),
    finance.financingType,
  );

  yield put(
    actions.updateMyDealProduct(page, instance, {
      ...finance,
      ...updatedFinance,
    }),
  );

  yield put(
    financeActions.resetFinanceProduct({
      type: 'HP',
    }),
  );
}

function* changeFinanceProduct(action) {
  try {
    const { make, market, locale } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const inventoryStatus = yield select(getInventoryStatus);
    const {
      payload: { vehicleId, productType },
      module: { page, instance },
    } = action;
    const existingfinanceProductExample = yield select(
      state =>
        state.shared.financeCalculator.financeProductExample[productType],
    );
    const url = `${yield select(
      inventorySearchServiceUrl,
    )}make/${make}/market/${market}/${vehicleId}/finance/example/?status=${inventoryStatus}&FinanceProduct=${productType}`;
    let response;
    if (!existingfinanceProductExample) {
      response = yield call(get, {
        url,
        ...(withLocaleQueryString && {
          config: localeConfigSerializer(locale),
        }),
      });
    }

    yield put(
      financeActions.loadFinanceProductSuccess({
        data: response ? response.data.deal : existingfinanceProductExample,
        type: productType,
      }),
    );
    yield put(actions.changeFinanceProductSuccess(page, instance, productType));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export function* printVehicle({ payload, module: { page, instance } }) {
  const {
    vehicle: { id, vin, modelGroupName },
  } = payload;
  const { make, market } = yield select(getAVLConfig);
  const inventoryBaseUrl = yield select(inventorySearchServiceUrl);
  const vehicleImgUrl = `${inventoryBaseUrl}inventory/make/${make}/market/${market}/${id ||
    vin}/images`;
  const document = yield call(printPos, {
    ...payload,
    vehicleImgUrl,
  });
  yield call(openPDF, document, modelGroupName);
  yield put(actions.printVehicleSuccess(page, instance));
}
export function* updateVehiclePrices({ payload, module: { page, instance } }) {
  let { vehicle } = payload;
  const currency = yield select(
    state => state.shared.sessionPreferences.currency,
  );

  const exchangeRates = yield select(
    state => state.shared.currencyConversion.exchangeRates[currency].rates,
  );

  vehicle = convertVehiclePriceCurrency(vehicle, exchangeRates, currency);
  yield put(actions.updateVehiclePricesSuccess(page, instance, vehicle));
}
export default function* VdpSaga() {
  yield takeEvery(constants.GET_VEHICLE_INFO, getVehicleInfo);
  yield takeEvery(constants.GET_SIMILAR_VEHICLES, getSimilarVehicles);
  yield takeEvery(constants.SAVE_MY_DEAL, saveMyDeal);
  yield takeEvery(constants.SAVE_MY_DEAL_SUCCESS, sendFinanceLead);
  yield takeEvery(constants.PRINT_MY_DEAL, printMyDeal);
  yield takeEvery(
    constants.UPDATE_MY_DEAL_WITH_PART_EXCHANGE,
    updateMyDealWithPartExchange,
  );
  yield takeLatest(constants.CHANGE_FINANCE_PRODUCT, changeFinanceProduct);
  yield takeEvery(constants.PRINT_VEHICLE, printVehicle);
  yield takeLatest(constants.UPDATE_VEHICLE_PRICES, updateVehiclePrices);
}

// @flow
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Frame from 'react-styled-frame';
import { renderer } from 'cms-modules';
import { createMemoryHistory } from 'history';
import settings from '../settings';
import { getGlobalStylingForModules } from '../state/reducers/editor';

import type { Theme } from '../theme';

import {
  pods as previewPods,
  bands as previewBands,
  PreviewBody,
} from '../previewPods';

type ThemeProps = { theme: Theme };

const mockHistory = createMemoryHistory();

const PreviewContainer = styled.div`
  background: ${(props: ThemeProps) => props.theme.colours.secondary03};
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const { Site, ComponentContext } = renderer;

const frameHtmlStyle = 'body { margin: 0; overflow-x:hidden; }';

export default function Preview() {
  const maxWidth = useSelector(
    state => state.siteBuilder.selectedScreenSize.maxWidth,
  );

  const pageContext = useSelector(state => state.context.page) || {};
  const pages = useSelector(state => state.config.pages);
  const currentPage = Object.values(pages).find(p => p.id === pageContext.id);
  const editor = useSelector(state => state.editor);
  const globalStyling = getGlobalStylingForModules(editor);

  return (
    <PreviewContainer>
      <Frame
        head={
          <Fragment>
            <link
              rel="stylesheet"
              type="text/css"
              href={settings.fontsCssUrl}
            />
            <style>{frameHtmlStyle}</style>
          </Fragment>
        }
        style={{
          border: 'none',
          maxWidth: maxWidth ? `${maxWidth}px` : '100%',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          margin: 0,
        }}
      >
        {currentPage && (
          <ComponentContext.Provider
            value={{
              pods: previewPods,
              bands: previewBands,
              Body: PreviewBody,
            }}
          >
            <Site
              path={currentPage.relativePath}
              globalStyling={globalStyling}
              history={mockHistory}
              errors={[]}
            />
          </ComponentContext.Provider>
        )}
      </Frame>
    </PreviewContainer>
  );
}

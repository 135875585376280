// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import PodMenu from './PodMenu';
import RootMenu from './RootMenu';
import BreakoutWrapper from './BreakoutWrapper';
import PodList from './PodList';

export default function Sidebar() {
  const { addPod, breakout, pod } = useSelector(state => state.context);

  if (breakout) {
    return <BreakoutWrapper />;
  }

  if (pod) {
    return <PodMenu />;
  }

  if (addPod) {
    return <PodList />;
  }

  return <RootMenu />;
}

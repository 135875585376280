export default function() {
  return {
    items: [
      {
        image: {
          label: 'placeholder',
          value:
            'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png',
        },
        link: {
          url: 'http://www.example.url',
          label: 'Url',
        },
        translations: {
          headline: 'Title placeholder',
          body: 'Body text placeholder',
          link: 'Text placeholder',
        },
      },
      {
        image: {
          label: 'placeholder',
          value:
            'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png',
        },
        link: {
          url: 'http://www.example.url',
          label: 'Url',
        },
        translations: {
          headline: 'Title placeholder',
          body: 'Body text placeholder',
          link: 'Text placeholder',
        },
      },
      {
        image: {
          label: 'placeholder',
          value:
            'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png',
        },
        link: {
          url: 'http://www.example.url',
          label: 'url',
        },
        translations: {
          headline: 'Title placeholder',
          body: 'Body text placeholder',
          link: 'Text placeholder',
        },
      },
    ],
    gridItemHeaderFont: {
      fontSize: '16',
      kerning: '2',
      transform: 'uppercase',
    },
    gridItemBodyFont: {
      fontSize: '14',
      kerning: '2',
      transform: 'none',
    },
    buttonFont: {
      fontSize: '14',
      kerning: '1',
      transform: 'uppercase',
    },
  };
}

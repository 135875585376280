// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../theme';

const Container = styled.div`
  ${({ colour }) => (colour ? `background: ${colour}` : 'background: #0F0F0F')};
  ${({ border }) => `${border && `border-top: ${border}`}`};
  position: sticky;
  bottom: 0;
  display: none;
  height: 75px;
  width: 100%;
  z-index: 9;

  ${media.max.large`
    display: flex;
    z-index: 8;
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  leftButton: () => any,
  rightButton: () => any,
  stickyFooterBackgroundColour: string,
  stickyFooterBorderTop: string,
};

export default function FooterActionBanner({
  leftButton,
  rightButton,
  stickyFooterBackgroundColour,
  stickyFooterBorderTop,
}: Props) {
  return (
    <Container
      colour={stickyFooterBackgroundColour}
      border={stickyFooterBorderTop}
    >
      <Wrapper>
        {leftButton && (
          <div style={{ width: rightButton ? '49%' : '100%' }}>
            {leftButton()}
          </div>
        )}
        {rightButton && (
          <div style={{ width: leftButton ? '49%' : '100%' }}>
            {rightButton()}
          </div>
        )}
      </Wrapper>
    </Container>
  );
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Images({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 20 16">
        <path d="M19.657.02H.317A.316.316 0 0 0 0 .338V15.46c0 .175.142.316.316.316h19.341a.316.316 0 0 0 .316-.316V.337a.316.316 0 0 0-.316-.316zm-1.582 13.232l-4.353-4.507a.13.13 0 0 0-.178-.007l-3.019 2.657-3.858-4.75a.13.13 0 0 0-.204.004l-4.564 6.123V1.92h16.176v11.332z" />
        <path d="M13.295 6.49a1.553 1.553 0 1 0 0-3.105 1.553 1.553 0 0 0 0 3.106z" />
      </svg>
    </IconWrapper>
  );
}

Images.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import ColourPreview from '../ColourPreview';

const Container = styled.div`
  margin-right: 20px;
  position: relative;
  &:focus {
    outline: none;
  }
`;
const ColourSelectorWrapper = styled.div`
  cursor: pointer;
  height: 40px;
  width: 200px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ open }) => open && 'border-bottom: 1px solid #ffffff'};
`;
const HexLabel = styled.div`
  width: 100px;
  height: 24px;
  border: none;
  margin: 0 0 0 10px;
  font-size: 14px;
  color: #474747;
  line-height: 24px;
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
`;
const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  border: 1px solid #d6d6d6;
  width: 200px;
  background-color: #ffffff;
`;
const NoColoursWrapper = styled.div`
  padding: 10px;
`;
const NoColoursTitle = styled.div`
  color: #474747;
  font-weight: bold;
  font-size: 14px;
`;
const NoColoursText = styled.div`
  color: #474747;
  font-size: 12px;
`;

type Props = {
  qaHook?: string,
  brandColours: Object[],
  selected: { label: string, value: string },
  selectBrandColour: (key: string, value: string) => void,
  forKey: string,
};
type State = {
  optionsVisible: boolean,
};
export default class ColourSelector extends Component<Props, State> {
  static defaultProps = {
    qaHook: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      optionsVisible: false,
    };
  }

  onSelectClick = () => {
    this.setState(prevState => ({ optionsVisible: !prevState.optionsVisible }));
  };

  onOptionClick = (hexValue: string) => {
    this.props.selectBrandColour(this.props.forKey, hexValue);
    this.setState(() => ({ optionsVisible: false }));
  };

  props: Props;

  render = () => {
    const { optionsVisible } = this.state;
    const {
      brandColours,
      selected = { value: '', label: '' },
      qaHook,
    } = this.props;
    return (
      <Container
        tabIndex="0"
        onBlur={() => {
          this.setState({ optionsVisible: false });
        }}
      >
        <ColourSelectorWrapper
          data-qa-hook={qaHook && `${qaHook}-colour-selector-box`}
          onClick={this.onSelectClick}
          open={optionsVisible}
        >
          <HexLabel data-qa-hook={qaHook && `${qaHook}-colour-selector-value`}>
            {selected.value === '' ? 'Please select' : selected.label}
          </HexLabel>
          <ColourPreview hexValue={selected.label} />
        </ColourSelectorWrapper>
        {optionsVisible && (
          <OptionsWrapper>
            {brandColours.length > 0 ? (
              brandColours.map(colour => (
                <Option
                  key={colour.label}
                  data-qa-hook="colour-selector-option"
                  onClick={() => this.onOptionClick(colour.label)}
                >
                  <HexLabel>{colour.label}</HexLabel>
                  <ColourPreview hexValue={colour.label} />
                </Option>
              ))
            ) : (
              <NoColoursWrapper>
                <NoColoursTitle>No brand colours</NoColoursTitle>
                <NoColoursText>
                  please add some first via the input above.
                </NoColoursText>
              </NoColoursWrapper>
            )}
          </OptionsWrapper>
        )}
      </Container>
    );
  };
}

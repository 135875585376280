// @flow
import React from 'react';
import styled from 'styled-components';
import type { Node } from 'react';

import type { Theme } from '../theme';

let i: number = 0;

type ThemeProps = {
  theme: Theme,
  value: boolean,
};

const Container = styled.label.withConfig({
  displayName: 'Container',
})`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  height: 20px;
  display: flex;
  justify-content: flex-end;
  min-width: 54px;
  border: 1px solid
    ${(props: ThemeProps) =>
      props.value
        ? props.theme.colours.connectAutoBlue
        : props.theme.colours.secondary03};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1px;
  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? 'not-allowed' : 'pointer'};
  transition: border 0.35s ease-in-out;
`;

const Slider = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  transform: translatex(${({ value }) => (value ? '0' : '-100%')});
  transition: transform 0.35s ease-in-out, background 0.35s ease-in-out;
  background: ${(props: ThemeProps) =>
    props.value
      ? props.theme.colours.connectAutoBlue
      : props.theme.colours.secondary03};
`;

const ComponentContainer = styled.div`
  display: flex;
  font-size: 10px;
  flex: 1 0 0%;
  justify-content: center;
  color: ${(props: ThemeProps) => props.theme.colours.primary03};
  padding: 1px;
  margin: 0;
`;

const HiddenCheckbox = styled.input
  .withConfig({
    displayName: 'HiddenCheckbox',
  })
  .attrs(() => ({
    type: 'checkbox',
  }))`
  display: none;
`;

type Props = {
  value: boolean,
  onChange?: boolean => void,
  onComponent?: Node,
  offComponent?: Node,
  name: string,
  value: boolean,
  disabled?: ?boolean,
};

export default function CheckboxSwitch({
  value,
  onChange,
  onComponent,
  offComponent,
  name,
  disabled,
}: Props) {
  const id = `checkbox-${(i += 1)}`;
  return (
    <Container value={value} htmlFor={id} disabled={disabled}>
      <HiddenCheckbox
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <Slider value={value}>
        <ComponentContainer>
          {value ? onComponent : offComponent}
        </ComponentContainer>
      </Slider>
    </Container>
  );
}

CheckboxSwitch.defaultProps = {
  onComponent: <span>ON</span>,
  offComponent: <span>OFF</span>,
  onChange: () => {},
  disabled: false,
};

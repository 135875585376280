// @flow
import React, { Fragment, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import { useCarousel } from './useCarousel';
import { useModal, Modal } from '../../shared/hooks/useModal';
import { useScript } from './useScript';
import { Wrapper } from '../components';

import type { IVDPCarouselConfig } from './types';

const CarouselBackground = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background: #eeeeee;
  * {
    box-sizing: border-box;
  }
`;

const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background: #ffffff;
`;

const MediaWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const MediaScriptContainer = styled.div`
  width: 100%;
`;

const SlideWrapper = styled.div`
  padding-bottom: ${({ fullscreen }) => (fullscreen ? '100vh' : '75%')};
  position: relative;
`;

const Slide = styled.img`
  width: 100%;
  display: block;
  position: absolute;

  @keyframes fadein {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }

  ${({ active }) =>
    active &&
    `animation: fadein 0.6s ease-out;
  `}
  ${({ fullscreen }) =>
    fullscreen &&
    `
      height: 100%;
      object-fit: contain;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: auto;
      }
  `}
`;

const SlideButton = styled.div`
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  ${({ left }) => left && 'left: 10px'};
  ${({ right }) => right && 'right: 10px'};
  top: 50%;
  margin-top: -15px;
  cursor: pointer;
`;

const ThumbnailsWrapper = styled.div`
  width: 80%;
  height: 80px;
  max-width: 800px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
`;

const ThumbnailsScroller = styled.div`
  width: 100%;
  position: relative;
  transition: left 0.5s ease;
`;

const ThumbnailsContainer = styled.div`
  height: 100%;
  white-space: nowrap;
  display: inline-block;
  box-sizing: border-box;
`;

const ThumbnailsButton = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  ${({ left }) => left && 'left: 10px'};
  ${({ right }) => right && 'right: 10px'};
  top: 50%;
  margin-top: -10px;
  z-index: 42;
  cursor: pointer;
`;

const Image = styled.img`
  height: 60px;
  margin: 5px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `border: 2px solid orange;
  `}
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  height: 80px;
  ${({ footerBackgroundColour }) =>
    footerBackgroundColour &&
    `background-color: ${footerBackgroundColour.value}`};
`;

const FooterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `text-decoration: underline;
  `}
`;

const playerScriptSrc = '/player.js';

const mediaViewTypes = {
  INTERIOR: 'interior',
  EXTERIOR: 'exterior',
};

type RenderMediaProps = {
  mediaRef: Object,
  setShowMedia: boolean,
  setMediaView: () => void,
  mediaView: string,
};

const RenderMedia = ({
  mediaRef,
  setShowMedia,
  setMediaView,
  mediaView,
}: RenderMediaProps) => (
  <Fragment>
    <MediaWrapper>
      <MediaScriptContainer ref={mediaRef} />
      <Footer>
        <FooterButton onClick={() => setShowMedia(false)}>Photos</FooterButton>
        <FooterButton
          active={mediaView === mediaViewTypes.EXTERIOR}
          onClick={() => setMediaView(mediaViewTypes.EXTERIOR)}
        >
          Exterior
        </FooterButton>
        <FooterButton
          active={mediaView === mediaViewTypes.INTERIOR}
          onClick={() => setMediaView(mediaViewTypes.INTERIOR)}
        >
          Interior
        </FooterButton>
      </Footer>
    </MediaWrapper>
  </Fragment>
);

type Props = {
  config: IVDPCarouselConfig,
  vehicleInfo: {
    images: array,
    vehicleId: string,
    views: number,
  },
};
type CarouselProps = {
  fullscreen: string,
};

export default ({
  config: { footerBackgroundColour },
  vehicleInfo: { images, vehicleId, views },
}: Props) => {
  const mediaRef = useRef(null);
  const scrollerRef = useRef(null);
  const [showMedia, setShowMedia] = useState(false);
  const [mediaView, setMediaView] = useState(mediaViewTypes.EXTERIOR);
  const { isModalVisible, toggleModal } = useModal();
  const error = useScript({
    playerScriptSrc,
    mediaRef,
    showMedia,
    mediaView,
    vehicleId,
  });
  const {
    currentIndex,
    prevIndex,
    clickLeft,
    clickRight,
    onThumbnailClick,
  } = useCarousel(scrollerRef, images.length);

  const handlers = useSwipeable({
    onSwipedLeft: clickRight,
    onSwipedRight: clickLeft,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const Carousel = ({ fullscreen }: CarouselProps) => (
    <SlideWrapper {...handlers} fullscreen={fullscreen}>
      {prevIndex !== null && (
        <Slide
          key={prevIndex}
          alt="car"
          src={images[prevIndex]}
          fullscreen={fullscreen}
        />
      )}
      <Slide
        key={images[currentIndex]}
        alt="car"
        src={images[currentIndex]}
        active
        fullscreen={fullscreen}
      />
      <SlideButton onClick={clickLeft} left>
        &laquo;
      </SlideButton>
      <SlideButton onClick={clickRight} right>
        &raquo;
      </SlideButton>
    </SlideWrapper>
  );

  return (
    <Wrapper>
      <Modal isModalVisible={isModalVisible} hide={toggleModal}>
        <Carousel fullscreen />
      </Modal>
      <CarouselBackground>
        {showMedia ? (
          <RenderMedia
            mediaRef={mediaRef}
            setShowMedia={setShowMedia}
            setMediaView={setMediaView}
            mediaView={mediaView}
            error={error}
          />
        ) : (
          <CarouselWrapper>
            <Carousel />
            <Footer footerBackgroundColour={footerBackgroundColour}>
              <FooterButton onClick={() => setShowMedia(true)}>
                360
              </FooterButton>
              <FooterButton>Views: {views}</FooterButton>
              <ThumbnailsWrapper>
                <ThumbnailsButton left onClick={clickLeft}>
                  &laquo;
                </ThumbnailsButton>
                <ThumbnailsButton right onClick={clickRight}>
                  &raquo;
                </ThumbnailsButton>
                <ThumbnailsScroller ref={scrollerRef}>
                  <ThumbnailsContainer>
                    {images.map((image, index) => (
                      <Image
                        key={image}
                        src={image}
                        onClick={() => onThumbnailClick(index)}
                        selected={currentIndex === index}
                      />
                    ))}
                  </ThumbnailsContainer>
                </ThumbnailsScroller>
              </ThumbnailsWrapper>
              <FooterButton onClick={toggleModal}>Zoom</FooterButton>
            </Footer>
          </CarouselWrapper>
        )}
      </CarouselBackground>
    </Wrapper>
  );
};

// @flow
import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: ${({ padding }) => (padding && padding.top) || 16}px;
  padding-right: ${({ padding }) => (padding && padding.right) || 16}px;
  padding-bottom: ${({ padding }) => (padding && padding.bottom) || 16}px;
  padding-left: ${({ padding }) => (padding && padding.left) || 16}px;
  ${({ alignment }) => `${alignment && `text-align: ${alignment}`}`};
  list-style: inside;
  word-break: break-word;
`;

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Compare({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 27 25">
        <path d="M15.5 7h8.3v8.3L20.499 12 14.8 17.7 13.101 16l5.698-5.7L15.5 7zM5.3 13l5.9-5.9 1.7 1.7L7 14.7l3.3 3.3H2V9.7L5.3 13z" />
      </svg>
    </IconWrapper>
  );
}

Compare.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

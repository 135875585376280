// @flow
import { constants } from '../actions/site';
import type { Action } from '../actions/site';
import type { Language, Currency } from './brand';

export type SiteConfiguration = {
  externalUrl: string,
  languages: Language[],
  currencies: Currency[],
  defaultLanguage: Language,
  defaultCurrency: Currency,
};

export type Site = {
  id: string,
  name: string,
  builderUrl: string,
  configuration: ?SiteConfiguration,
};

type SiteId = $PropertyType<Site, 'id'>;

export type CloneModeValue = 'ContentAndConfig' | 'ConfigOnly';
export type CloneMode = { label: string, value: CloneModeValue };
export type State = {
  sites: { [id: SiteId]: Site },
  cloneableSites: { [id: SiteId]: Site },
  error: ?Error,
  success: boolean,
  selectedForCloning: ?SiteId,
  cloneModeOptions: CloneMode[],
  cloneMode: CloneMode,
  currentSiteId: SiteId,
};

const cloneModeOptions = [
  { label: 'Content and configuration settings', value: 'ContentAndConfig' },
  { label: 'Configuration settings only', value: 'ConfigOnly' },
];

export const initialState: State = {
  sites: {},
  cloneableSites: {},
  selectedForCloning: null,
  error: null,
  success: null,
  cloneModeOptions,
  cloneMode: cloneModeOptions[0],
  currentSiteId: undefined,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.LoadSitesSuccess: {
      const sites = action.payload.reduce(
        (acc, site) => ({ ...acc, [site.id]: site }),
        {},
      );

      return {
        ...state,
        sites,
      };
    }
    case constants.LoadCloneableSitesSuccess: {
      const cloneableSites = action.payload.reduce(
        (acc, site) => ({ ...acc, [site.id]: site }),
        {},
      );

      return {
        ...state,
        cloneableSites,
      };
    }
    case constants.SaveSiteSuccess: {
      const site = action.payload;
      return {
        ...state,
        sites: { ...state.sites, [site.id]: site },
        currentSiteId: site.id,
      };
    }
    case constants.LoadSiteSuccess: {
      const site = action.payload;
      return {
        ...state,
        sites: { ...state.sites, [site.id]: site },
        currentSiteId: site.id,
      };
    }
    case constants.SelectSite:
      return { ...state, selectedForCloning: action.payload };
    case constants.SetCloneMode:
      return {
        ...state,
        cloneMode: cloneModeOptions.find(c => c.value === action.payload),
      };
    default:
      return state;
  }
}

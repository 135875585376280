// @flow
import styled from 'styled-components';

export default styled.div`
  display: flex;
  position: relative;
  word-break: break-all;
  flex-direction: ${props => props.flexDirection || 'column'};
  flex: 1 0 25%;
  ${({ border }) =>
    border && border.map(val => `border-${val}: 1px solid;`).join(' ')};
  ${({ borderColour }) =>
    borderColour && `border-color: ${borderColour.value};`};
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour.value}`};
`;

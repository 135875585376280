import { put, takeEvery } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import {
  actions as customerActions,
  constants as customerConstants,
} from '../../shared/CustomerLogin';

function* register(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  yield put(customerActions.postCreateAccount(page, instance, payload));
}

function* registerSuccess(action) {
  const {
    module: { page, instance },
  } = action;
  if (page.toLowerCase() !== '/register') return;
  yield put(actions.registerSuccess(page, instance));
}

function* registerFailure(action) {
  const {
    module: { page, instance },
  } = action;
  if (page.toLowerCase() !== '/register') return;
  yield put(actions.registerFailure(page, instance));
}

export default function* RegisterSaga() {
  yield takeEvery(constants.REGISTER, register);
  yield takeEvery(customerConstants.CREATE_CUSTOMER_SUCCESS, registerSuccess);
  yield takeEvery(customerConstants.CREATE_CUSTOMER_FAILURE, registerFailure);
}

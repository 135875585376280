// @flow
import type { State as Finance } from '../types/finance';

const UpdateFinanceOptions: 'UPDATE FINANCE OPTIONS' = 'UPDATE FINANCE OPTIONS';
export type UpdateFinanceOptionsAction = {
  type: typeof UpdateFinanceOptions,
  payload: Finance,
};
const updateFinanceOptions = (
  finance: Finance,
): UpdateFinanceOptionsAction => ({
  type: UpdateFinanceOptions,
  payload: finance,
});

const SaveFinanceOptions: 'SAVE FINANCE OPTIONS' = 'SAVE FINANCE OPTIONS';
export type SaveFinanceOptionsAction = {
  type: typeof SaveFinanceOptions,
  payload: string,
};
const saveFinanceOptions = (siteId: string): SaveFinanceOptionsAction => ({
  type: SaveFinanceOptions,
  payload: siteId,
});

const SaveFinanceOptionsSuccess: 'SAVE FINANCE OPTIONS SUCCESS' =
  'SAVE FINANCE OPTIONS SUCCESS';
export type SaveFinanceOptionsSuccessAction = {
  type: typeof SaveFinanceOptionsSuccess,
};
const saveFinanceOptionsSuccess = (): SaveFinanceOptionsSuccessAction => ({
  type: SaveFinanceOptionsSuccess,
});

const SaveFinanceOptionsFailure: 'SAVE FINANCE OPTIONS FAILURE' =
  'SAVE FINANCE OPTIONS FAILURE';
export type SaveFinanceOptionsFailureAction = {
  type: typeof SaveFinanceOptionsFailure,
};
const saveFinanceOptionsFailure = (): SaveFinanceOptionsFailureAction => ({
  type: SaveFinanceOptionsFailure,
});

export const constants = {
  UpdateFinanceOptions,
  SaveFinanceOptions,
  SaveFinanceOptionsSuccess,
  SaveFinanceOptionsFailure,
};

export const actions = {
  updateFinanceOptions,
  saveFinanceOptions,
  saveFinanceOptionsSuccess,
  saveFinanceOptionsFailure,
};

export type Action =
  | UpdateFinanceOptionsAction
  | SaveFinanceOptionsAction
  | SaveFinanceOptionsSuccessAction
  | SaveFinanceOptionsFailureAction;

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import splitEvery from 'ramda/src/splitEvery';
import { Button, Paragraph, fontStyleOverride } from './Global';
import UserLocation from './UserLocation';
import media from '../theme';
import InfoPin from './JaguarIcons/Global/Pin';
import { google } from '../utilities/googleObject';

const ZOOM_THRESHOLD = 10;

const RetailerInfoDiv = styled.div`
  margin: 15px 12px 15px 0;
  ${media.max.small`
  ${({ direction }) =>
    direction === 'rtl' ? 'margin: 0 12px 0 0' : 'margin: 0'};
  `};
`;

const RetailerName = styled.div`
  color: #444444;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const RetailerAddress = styled.div`
  font-size: 13px;
  color: #444444;
`;

const RetailerCountry = styled.div`
  font-size: 13px;
  color: #444444;
  margin-bottom: 10px;
`;

const RetailerPhone = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 13px;
`;

const RetailerDistance = styled.div`
  display: flex;
  align-items: center;
  margin-left: -3px;
  margin-bottom: 10px;
  font-size: 13px;
`;

const TitleSearchWrapper = styled.div`
  max-width: 600px;
  margin: 30px auto;
`;

export type Location = {
  lat: number,
  lng: number,
};

type Retailer = {
  id: string,
  name: string,
  address: string[],
  phone: string,
  distanceToRetailer: number,
  location: Location,
  country: string,
};

type Translations = {
  phone: string,
  distanceAway: string,
  viewStock: string,
};

export type Props = {
  retailers: Retailer[],
  pin: Object,
  retailerInfoButtonColour: string,
  translations: Translations,
  centreLocation: ?Location,
  mapZoomLevel: number,
  defaultCenter: Location,
  viewStockAction: Function,
  zoomChangedAction: Function,
  globalStyling: Object,
  infoWindowButtonType: string,
  onMarkerClustererClick: Function,
  config: Object,
};

const RetailerInfo = (props: {
  retailer: Retailer,
  translations: Translations,
  viewStockAction: Function,
  buttonStyle: string,
  buttonType: string,
  pin: Object,
  direction: string,
}) => (
  <RetailerInfoDiv direction={props.direction}>
    <RetailerName>{props.retailer.name}</RetailerName>
    {splitEvery(2, props.retailer.address).map(addLine => (
      <RetailerAddress>{addLine.join(', ')}</RetailerAddress>
    ))}
    <RetailerCountry>{props.retailer.country}</RetailerCountry>
    {props.retailer.phone && (
      <RetailerPhone>
        {`${props.translations.phone}: `}&#x200E;{`${props.retailer.phone}`}
      </RetailerPhone>
    )}
    {props.retailer.distanceToRetailer ? (
      <RetailerDistance>
        <InfoPin width="2em" height="2em" />
        {props.retailer.distanceToRetailer} {props.translations.distanceAway}
      </RetailerDistance>
    ) : null}
    <Button
      styleOverride={() => 'width: 100%;'}
      buttonStyle={props.buttonStyle}
      applyStyle={props.buttonType}
      onClick={() => props.viewStockAction(props.retailer)}
      text={props.translations.viewStock}
    />
  </RetailerInfoDiv>
);

const RetailerPin = (fillColor: ?string) => ({
  fillColor,
  fillOpacity: 1,
  strokeWeight: 1,
  scale: 1.2,
  anchor: { x: 12, y: 33 },
  path:
    'M11.7984563,32.328125 C19.5397634,22.5334923 23.4888381,15.841435 23.6456803,12.2519531 C23.8937768,6.57403174 19.6927416,0 11.7984563,0 C3.90417106,0 -0.230694235,6.58264819 0.00993810445,12.2519531 C0.162299051,15.8415815 4.09180512,22.5336388 11.7984563,32.328125 Z',
});

class Map extends Component<Props, { active: ?Retailer, zoom: number }> {
  static defaultProps = {
    retailers: [],
  };

  static InfoView = ({
    marker,
    toggle,
    translations,
    pin,
    viewStockAction,
    globalStyling,
    infoWindowButtonType,
  }) => (
    <InfoWindow onCloseClick={() => toggle(marker)}>
      <RetailerInfo
        retailer={marker}
        translations={translations}
        pin={pin}
        viewStockAction={viewStockAction}
        buttonStyle={
          globalStyling.uiElements.secondaryButton &&
          globalStyling.uiElements.secondaryButton.buttonStyle
        }
        buttonType={infoWindowButtonType}
        direction={globalStyling.direction}
      />
    </InfoWindow>
  );

  constructor(props: Props) {
    super(props);
    this.state = {
      active: undefined,
      zoom: props.mapZoomLevel,
    };
  }

  componentDidUpdate(prevProps) {
    const { retailers, loadingNearest } = this.props;
    if (prevProps.loadingNearest && !loadingNearest) {
      const bounds = new google.maps.LatLngBounds();
      retailers.forEach(retailer =>
        bounds.extend(
          new google.maps.LatLng(this.flipCoordinates(retailer.location)),
        ),
      );
      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
    }
  }

  onMapMounted = ref => {
    this.map = ref;
  };

  zoomIn(zoomLevel) {
    this.setState({ zoom: zoomLevel });
  }

  map = null;

  toggle = (marker: ?Retailer) => {
    if (this.state.active && this.state.active.id === marker.id) {
      this.setState(() => ({ active: undefined }));
    } else {
      this.setState(() => ({ active: marker }));
    }
  };

  handleZoomChanged = () => {
    const zoomLevel = this.map.getZoom();
    if (zoomLevel < this.state.zoom) {
      this.props.zoomChangedAction();
    }
  };

  flipCoordinates = coordinates =>
    this.props.flipCoordinates
      ? { lat: coordinates.lng, lng: coordinates.lat }
      : coordinates;

  mapMarkers = () => {
    const { retailers, pin } = this.props;
    const { active } = this.state;
    const { toggle } = this;
    if (retailers.length) {
      if (pin.mapUnSelectedMarkerUrl && pin.mapSelectedMarkerUrl) {
        return retailers.map(marker => {
          const icon =
            active && active.id === marker.id
              ? `${pin.mapSelectedMarkerUrl}`
              : `${pin.mapUnSelectedMarkerUrl}`;
          const sizedIcon = {
            url: icon,
            scaledSize: new google.maps.Size(35, 42),
            size: new google.maps.Size(45, 45, 'px', 'px'),
          };
          return (
            <Marker
              key={marker.id}
              position={this.flipCoordinates(marker.location)}
              onClick={() => this.toggle(marker)}
              icon={sizedIcon}
            >
              {active && active.id === marker.id && (
                <Map.InfoView
                  {...this.props}
                  pin={pin}
                  marker={marker}
                  toggle={toggle}
                />
              )}
            </Marker>
          );
        });
      }
      return retailers.map(marker => (
        <Marker
          icon={RetailerPin(pin.colour.value)}
          key={marker.id}
          position={marker.location}
          onClick={() => this.toggle(marker)}
        >
          {this.state.active && this.state.active.id === marker.id && (
            <Map.InfoView
              {...this.props}
              pin={pin}
              marker={marker}
              toggle={toggle}
            />
          )}
        </Marker>
      ));
    }
    return null;
  };

  render() {
    const {
      globalStyling,
      defaultCenter,
      onMarkerClustererClick,
      pin,
    } = this.props;
    const { zoom } = this.state;
    const { mapMarkers, onMapMounted, handleZoomChanged } = this;
    const defaultClusterProps = {
      onClick: onMarkerClustererClick,
      averageCenter: true,
      enableRetinaIcons: true,
      gridSize: 60,
    };
    const customClusterProps = pin.mapClusterMarkerUrl
      ? Object.assign({}, defaultClusterProps, {
          styles: [
            {
              url: pin.mapClusterMarkerUrl,
              height: 26,
              width: 26,
              textColor: pin.clusterFontColour
                ? pin.clusterFontColour.value
                : globalStyling.colours.primaryBrandColour.value,
            },
          ],
        })
      : defaultClusterProps;
    return (
      <GoogleMap
        zoom={zoom}
        center={defaultCenter}
        ref={onMapMounted}
        onZoomChanged={handleZoomChanged}
      >
        {zoom < ZOOM_THRESHOLD ? (
          <MarkerClusterer {...customClusterProps}>
            {mapMarkers()}
          </MarkerClusterer>
        ) : (
          <div>{mapMarkers()}</div>
        )}
      </GoogleMap>
    );
  }
}

// This loads the Google Maps API dynamically and creates a HOC for the react-google-maps library
const MapWithAPI = withGoogleMap(Map);

// This will dynamically load the Google Map js API with this component
const MapWithControlsContainer = props => (
  <div>
    <TitleSearchWrapper>
      <Paragraph
        styleOverride={() => `
        ${fontStyleOverride(props.headerFont)};
        margin-bottom: 30px;
        text-align: center;
      `}
        mobileStyleOverride={() => [
          { template: 'display: none;', queryPath: 'max.small' },
        ]}
      >
        {props.translations.title}
      </Paragraph>
      <UserLocation
        direction={props.globalStyling.direction}
        placeholderText={props.translations.placeholder}
        iconUrl={props.config.locationIconUrl}
        browserLocationCallback={(latResponse, longResponse) => {
          if (props.retailers.length >= 3) {
            props.returnPlace({ lat: latResponse, lng: longResponse });
          }
        }}
        inputLocationCallback={(latResponse, longResponse) => {
          if (props.retailers.length >= 3) {
            props.returnPlace({ lat: latResponse, lng: longResponse });
          }
        }}
        options={{
          countryCode: props.searchCountryCode,
        }}
      />
    </TitleSearchWrapper>
    <MapWithAPI
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '600px' }} />}
      mapElement={<div style={{ height: '100%' }} />}
      translations={{
        phone: props.translations.phone,
        distanceAway: props.translations.distanceAway,
        viewStock: props.translations.viewStock,
      }}
      defaultCenter={props.defaultCenter}
      centreLocation={props.centreLocation}
      retailers={props.retailers}
      viewStockAction={props.viewStockAction}
      zoomChangedAction={props.zoomChangedAction}
      pin={props.pin}
      mapZoomLevel={props.mapZoomLevel}
      globalStyling={props.globalStyling}
      infoWindowButtonType={props.infoWindowButtonType}
      flipCoordinates={props.flipCoordinates}
      loadingNearest={props.loadingNearest}
    />
  </div>
);

export default MapWithControlsContainer;

// @flow
import React from 'react';
import styled, { css } from 'styled-components';

const StickyWrapperComponent = styled.div`
  display: flex;
  width: 100%;
  position: ${({ position }) => position};
  z-index: 100;
  ${({ layout }) =>
    layout === 'bottom' &&
    css`
      bottom: 0;
      left: 0;
    `} ${({ layout }) =>
    layout === 'top' &&
    css`
      top: 1px;
    `};
`;

type Props = {
  position: 'fixed' | 'relative',
  layout: 'top' | 'bottom',
  children: ?React.Node,
};
const StickyWrapper = ({ layout, children, position = 'fixed' }: Props) => (
  <StickyWrapperComponent position={position} layout={layout}>
    {children}
  </StickyWrapperComponent>
);

export default StickyWrapper;

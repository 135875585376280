import {
  Config,
  Slice,
  ListSlice,
  Section,
  Colour,
  Font,
  Align,
  Image,
  TextArea,
  Link,
  Pixels,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'Grid',
  { id: 'grid' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      'Grid items',
      { icon: SectionIcons.TopTier },
      Align('Align', 'gridItemsAlign'),
    ),
    Section(
      'Global',
      {},
      Pixels('Padding Vertical', 'paddingVertical'),
      Pixels('Padding Horizontal', 'paddingHorizontal'),
    ),
  ),
  ListSlice(
    'Content',
    {
      id: 'items',
      max: 9,
      sectionLabel: 'Grid item',
      buttonLabel: 'Add new grid item',
      icon: SectionIcons.GridItem,
    },
    Image('Image', 'image'),
    TextArea('Headline', 'headline'),
    TextArea('Body Text', 'body'),
    Link('Link Text', 'link'),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Colour('Background', 'gridBackgroundColour'),
      Font('Header text', 'gridItemHeaderFont'),
      Font('Body text', 'gridItemBodyFont'),
      Font('Button Text', 'buttonFont'),
      Colour('Button Colour', 'buttonBackgroundColour'),
      Colour('Button Hover', 'buttonHover'),
    ),
  ),
);

export default config;

// @flow
import React from 'react';
import styled from 'styled-components';

import CheckboxSwitch from './CheckboxSwitch';
import InputField from './InputField';
import FieldLabel from './FieldLabel';
import Label from './Label';

type Link = {
  url: string,
  label: string,
};

type Props = {
  disabled?: boolean,
  name: string,
  labelName?: string,
  value: Link,
  onChange: (value: Link) => void,
};

type ThemeProps = Props & { theme: Theme };

const namedDiv = displayName => styled.div.withConfig({ displayName });

const LinkContainer = namedDiv('LinkContainer')`
  display: flex;
  flex-direction: column;
  font-family: ${(props: ThemeProps) => `${props.theme.fontFamily}`};
`;

const LinkContainerInputs = namedDiv('LinkContainerInputs')`
  padding-top: ${(props: ThemeProps) =>
    `${props.theme.layout.standardPadding}`};
`;

const LabelContainer = styled.span.withConfig({
  displayName: 'LabelContainer',
})`
  display: flex;
  padding-top: ${(props: ThemeProps) =>
    `${props.theme.layout.standardPadding}`};
  ${(props: ThemeProps) =>
    props.labelName
      ? `padding-bottom: ${props.theme.layout.standardPadding}`
      : ''};
`;

export default class LinkEditor extends React.Component<Props, *> {
  static defaultProps = {
    disabled: false,
    name: '',
    labelName: '',
    value: {},
    onChange: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: this.props.disabled,
    };
  }

  render() {
    const { name, onChange, value, labelName } = this.props;

    const toggle = () =>
      this.setState(prevState => ({ disabled: !prevState.disabled }));

    return (
      <LinkContainer>
        <FieldLabel label={name} inputFlexMode="0">
          <CheckboxSwitch
            name={`${name}-toggle`}
            onChange={() => {
              toggle();
              onChange({ url: '', label: '' });
            }}
            value={!this.state.disabled}
          />
        </FieldLabel>
        {!this.state.disabled ? (
          <LinkContainerInputs>
            <InputField
              input={{
                onChange: e =>
                  onChange({ url: e.target.value, label: value.label }),
                name: `${name}-url-input`,
                value: value.url,
              }}
              type="text"
              placeholder="http://..."
            />
            <LabelContainer labelName={labelName}>
              {labelName && <Label>{labelName}</Label>}
            </LabelContainer>
            <InputField
              input={{
                onChange: e =>
                  onChange({ url: value.url, label: e.target.value }),
                name: `${name}-label-input`,
                value: value.label,
              }}
              type="text"
            />
          </LinkContainerInputs>
        ) : null}
      </LinkContainer>
    );
  }
}

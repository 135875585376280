// @flow
import React from 'react';
import styled from 'styled-components';
import splitEvery from 'ramda/src/splitEvery';

import { HeadingThree, fontStyleOverride } from './Global';
import media from '../theme';
import {
  getFinanceDisplayList,
  reorderFinanceDisplayList,
} from '../shared/financeCalculator/data';

const RowBody = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ singleColumn }) => singleColumn && 'flex-direction: column;'};
  ${media.max.medium` flex-direction: column;`};
`;

const ItemBody = styled.div`
  align-items: center;
  min-height: 25px; /* ie11 fix */
  display: flex;
  justify-content: space-between;
  flex: 1 0 0%;
  ${({ marginRight }) => marginRight && 'margin-right: 20px;'};
  ${({ marginLeft }) => marginLeft && 'margin-left: 20px;'};

  ${({ financePricingTable }) =>
    financePricingTable &&
    `padding-right: ${financePricingTable.paddingRight}px;`};
  ${({ financePricingTable }) =>
    financePricingTable &&
    `padding-left: ${financePricingTable.paddingLeft}px;`};

  ${({ financePricingTable }) =>
    financePricingTable && financePricingTable.borderColour
      ? `border-${financePricingTable.borderPosition || 'top'}: 1px solid ${
          financePricingTable.borderColour.value
        }`
      : 'border-top: 1px solid #ACACAC;'};

  ${media.max.medium`
    margin: 0;
  `};
`;

const DisclaimerRow = styled(ItemBody)`
  border: none;
`;

const Disclaimer = ({ text, labelFont, headingMargin }) => (
  <RowBody singleColumn>
    <DisclaimerRow>
      <HeadingThree
        styleOverride={() => `
        flex: 1 0 0%;
        margin: ${headingMargin ? `${headingMargin}px;` : '4px 0;'};
        ${fontStyleOverride(labelFont)};
        `}
      >
        {text}
      </HeadingThree>
    </DisclaimerRow>
  </RowBody>
);

const ItemRow = ({
  pair: [first, second],
  last,
  labelFont,
  valueFont,
  singleColumn,
  financePricingTable,
  headingMargin,
}: {
  pair: [Item, Item],
  last: boolean,
  labelFont: Object,
  valueFont: Object,
  singleColumn: boolean,
  financePricingTable: Object,
  headingMargin: number,
}) => (
  <RowBody singleColumn={singleColumn}>
    <ItemBody
      marginRight={!singleColumn}
      last={last}
      financePricingTable={financePricingTable}
    >
      <HeadingThree
        styleOverride={() => `
          flex: 1 0 0%;
          margin: ${headingMargin ? `${headingMargin}px;` : '4px 0;'};
          ${fontStyleOverride(labelFont)};
          `}
      >
        {first.label}
      </HeadingThree>
      <HeadingThree
        styleOverride={() => `
          margin: ${headingMargin ? `${headingMargin}px;` : '4px 0;'};
          ${fontStyleOverride(valueFont)};
          `}
      >
        {first.value}
      </HeadingThree>
    </ItemBody>
    {second ? (
      <ItemBody
        marginLeft={!singleColumn}
        last={last}
        financePricingTable={financePricingTable}
      >
        <HeadingThree
          styleOverride={() => `
            flex: 1 0 0%;
            margin: ${headingMargin ? `${headingMargin}px;` : '4px 0;'};
            ${fontStyleOverride(labelFont)};
            `}
        >
          {second.label}
        </HeadingThree>
        <HeadingThree
          styleOverride={() => `
            margin: ${headingMargin ? `${headingMargin}px;` : '4px 0;'};
            ${fontStyleOverride(valueFont)};
            `}
        >
          {second.value}
        </HeadingThree>
      </ItemBody>
    ) : (
      <ItemBody
        marginLeft={!singleColumn}
        financePricingTable={financePricingTable}
      />
    )}
  </RowBody>
);

type Props = {
  data: Object[],
  finance: Object,
  labelFontOverride: Object,
  valueFontOverride: Object,
  singleColumn?: boolean,
  financePricingTable: Object,
};

export default function FinanceExample({
  data,
  finance,
  labelFontOverride,
  valueFontOverride,
  singleColumn,
  financePricingTable,
}: Props) {
  const items = singleColumn
    ? getFinanceDisplayList(data, finance)
    : reorderFinanceDisplayList(getFinanceDisplayList(data, finance));
  const pairs = splitEvery(2, items);

  const infoLabelFont =
    (financePricingTable && financePricingTable.infoLabelFont) ||
    labelFontOverride;
  const infoValueFont =
    (financePricingTable && financePricingTable.infoValueFont) ||
    valueFontOverride;

  const rows = pairs.map((pair, i) => (
    <ItemRow
      key={i.toString()}
      pair={pair}
      mark="prop"
      last={i === pairs.length - 1}
      labelFont={infoLabelFont}
      valueFont={infoValueFont}
      singleColumn={singleColumn}
      financePricingTable={financePricingTable}
    />
  ));

  const disclaimer = finance.showExampleDisclaimer && (
    <Disclaimer
      text={finance.translations.exampleDisclaimer}
      labelFont={infoLabelFont}
    />
  );

  return [...rows, disclaimer].filter(Boolean);
}

FinanceExample.defaultProps = {
  valueFontOverride: {
    fontSize: '14',
    colour: {
      label: '#444444',
      value: '#444444',
    },
  },
  labelFontOverride: {
    fontSize: '14',
    colour: {
      label: '#7e7e7e',
      value: '#7e7e7e',
    },
  },
  singleColumn: false,
};

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import type { Filters } from './SearchFilters/search-filters-helper';
import {
  transformRangeProps,
  getInitialRangeValues,
} from './SearchFilters/search-filters-helper';
import RangeSlider from './RangeSlider';
import RangeHeading from './SearchFilters/RangeHeading';
import FilterSectionTitle from './SearchFilters/FilterSectionTitle';

type Range = {
  min: number,
  max: number,
};

type Props = {
  allFilters: Filters,
  availableFilters: Filters,
  handleRangeFilters: (string, number[]) => void,
  closeFilter: Function,
  config: {
    make: string,
    market: string,
    translations: {
      [string]: string,
    },
  },
};

type State = {
  priceRangeLabel: Range,
};

const FilterContainer = styled.div.withConfig({
  displayName: 'FilterContainer',
})`
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  box-sizing: border-box;
`;

const RangeContainer = styled.div.withConfig({
  displayName: 'RangeContainer',
})`
  padding: 20px;
  border-top: 1px solid #d8d8d8;
`;

const CloseButtonWrapper = styled.div.withConfig({
  displayName: 'CloseButtonWrapper',
})`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`;

export default class PriceRangeFilter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { priceRange = { min: 0, max: 0 } } = props.availableFilters;

    this.state = {
      priceRangeLabel: priceRange,
    };
  }

  onChangeRangeLabel = (rangeValues: [number, number], label: string) => {
    this.setState({
      [label]: { min: rangeValues[0], max: rangeValues[1] },
    });
  };

  render() {
    const {
      allFilters,
      availableFilters,
      handleRangeFilters,
      closeFilter,
      config: { translations = {} },
    } = this.props;
    const { priceRange } = transformRangeProps(allFilters);
    return (
      <FilterContainer>
        <CloseButtonWrapper onClick={closeFilter}>
          <FilterSectionTitle title={translations.searchHeaderPriceRange} />
        </CloseButtonWrapper>
        <RangeContainer>
          <RangeHeading
            range={this.state.priceRangeLabel}
            heading={translations.searchSubHeaderPrice}
            unit={translations.unitPrice}
          />
          <RangeSlider
            step={priceRange.step}
            rangeValue={priceRange.fullRangeValues}
            name={priceRange.name}
            defaultValue={getInitialRangeValues(
              100,
              availableFilters.priceRange,
            )}
            onAfterChange={values => handleRangeFilters(priceRange.id, values)}
            onChange={values =>
              this.onChangeRangeLabel(values, 'priceRangeLabel')
            }
          />
        </RangeContainer>
      </FilterContainer>
    );
  }
}

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import useActions from './useActions';

const Container = styled.div`
  border: 1px dashed #0065db;
  display: flex;
  width: calc(100% - 2px);
  height: 200px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
`;

const AddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  width: 80px;
  align-items: center;
  cursor: pointer;
  margin: 0 5px 5px;
`;

const Column = styled.div`
  height: 40px;
  flex: 1;
  margin: 0 2px;
  background: #0065db;
`;

const Text = styled.div`
  font-family: Lato, Helvetica, open sans;
  font-size: 12px;
  color: ${({ colour }) => colour || '#757575'};
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
`;

const AddNewBand = () => {
  const { mode, addBand, viewSelectColumns } = useActions();
  return (
    <Container>
      {mode === 'add' ? (
        <AddWrapper onClick={viewSelectColumns}>
          <Icon
            title="edit"
            src="https://res.cloudinary.com/motortrak/image/upload/v1564992717/editor/settings-icon.svg"
          />
          <Text colour="#0065DB">Add new layout</Text>
        </AddWrapper>
      ) : (
        <Fragment>
          <AddWrapper onClick={() => addBand(1)}>
            <ColumnsWrapper>
              <Column />
            </ColumnsWrapper>
            <Text>Full width</Text>
          </AddWrapper>
          <AddWrapper onClick={() => addBand(2)}>
            <ColumnsWrapper>
              <Column />
              <Column />
            </ColumnsWrapper>
            <Text>2 columns</Text>
          </AddWrapper>
          <AddWrapper onClick={() => addBand(3)}>
            <ColumnsWrapper>
              <Column />
              <Column />
              <Column />
            </ColumnsWrapper>
            <Text>3 columns</Text>
          </AddWrapper>
          <AddWrapper onClick={() => addBand(4)}>
            <ColumnsWrapper>
              <Column />
              <Column />
              <Column />
              <Column />
            </ColumnsWrapper>
            <Text>4 columns</Text>
          </AddWrapper>
        </Fragment>
      )}
    </Container>
  );
};

export default AddNewBand;

// @flow
import React from 'react';
import styled from 'styled-components';

import VdpHeader from './VdpHeader';
import Chunky from './JaguarIcons/Arrows/Chunky';
import media from '../theme';
import ContentWrapper from './ContentWrapper';

const NUM_VISIBLE = 3;

const Container = styled.div.withConfig({
  displayName: 'AccessoriesContainer',
})`
  ${({ config }) =>
    `background-color: ${(config && config.backgroundColour) || '#ffffff'}`};
  ${({ config }) =>
    config &&
    config.borderTop &&
    `border-top: ${config.borderTop.size}px ${config.borderTop.type} ${
      config.borderTop.colour
    }`};
  color: #444444;
  width: 100%;
  height: auto;
  ${({ config }) =>
    `padding-top: ${
      config && config.padding && config.padding.paddingTop !== undefined
        ? config.padding.paddingTop
        : '52'
    }px`};
  ${({ config }) =>
    `padding-bottom: ${
      config && config.padding && config.padding.paddingBottom !== undefined
        ? config.padding.paddingBottom
        : '82'
    }px`};
  ${({ config }) =>
    config &&
    config.box &&
    config.box.border &&
    `border: ${config.box.border.size}px ${config.box.border.type} ${
      config.box.border.colour
    }`};
  ${media.max.large`
    width: 100%;
    box-sizing: border-box;
    padding: 20px 5% 0 5%;
  `};
`;

const Section = styled.div`
  width: 90%;
  margin: 0 5%;
  color: #444444;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.alignment};
  align-items: center;
  padding-bottom: 30px;
`;

const PaginationSection = styled(Section)`
  width: 100%;
  margin: 0;
  ${media.max.medium`
      padding-bottom: 20px;
    `};
`;

const AccessoryBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AccessoryBox = styled.div`
  width: 33.3%;
  background-color: ${({ backgroundColour }) =>
    `${(backgroundColour && backgroundColour) || '#f0f0f0'}`};
  display: flex;
  flex-direction: column;
  ${({ box }) => box && box.margin && `margin: ${box.margin}px`};
  background-color: ${({ box }) =>
    `${(box && box.backgroundColour) || '#f0f0f0'}`};
  ${({ box }) =>
    box &&
    box.border &&
    `border: ${box.border.size}px ${box.border.type} ${box.border.colour}`};
  ${media.max.medium`
    width: 100%;
    display: ${props => (props.mobileSelected ? 'block' : 'none')};
  `};
`;

const Image = styled.img`
  position: absolute;
  width: 1px;
  height: 1px;
`;

const ImageDiv = styled.div`
  width: 100%;
  border-top: 1px solid #f0f0f0;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Content = styled.div`
  min-height: 100px;
  flex: 1 1 0%;
  padding: 5%;
  box-sizing: border-box;
  ${({ border }) =>
    border && `border: ${border.size}px ${border.type} ${border.colour}`};
`;

const Title = styled.div`
  ${({ font }) => `font-size: ${(font && font.fontSize) || '20'}px`};
  line-height: 24px;
  font-weight: 600;
  ${({ font }) =>
    `color: ${(font && font.colour && font.colour.value) || '#000'}`};
  padding-bottom: 10px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  color: #444444;
  line-height: 21px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #444444;
  line-height: 21px;
`;

const Button = styled.button`
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  display: ${props => (props.hideOnDesktop ? 'none' : 'flex')};
  &:focus {
    outline: none;
  }
  ${media.max.large`
    display:  ${props => (props.hideOnDesktop ? 'flex' : 'none')};
  `};
`;

const MobileSection = styled.div`
  display: ${props => (props.hideOnDesktop ? 'none' : 'flex')};
  ${media.max.medium`
    display: ${props => (props.hideOnDesktop ? 'flex' : 'none')};
  `};
`;

const SubHeader = styled.div`
  color: #444444;
  font-size: 15px;
  line-height: 21px;
  width: 40%;
  display: block;
  ${media.max.large`
    width: 100%;
    `};
`;

const HeadingContainer = styled.div`
  ${({ config }) =>
    `margin-left: ${
      config && config.marginLeft !== undefined ? config.marginLeft : '5'
    }%`};
  ${({ config }) =>
    `margin-bottom: ${
      config && config.marginBottom !== undefined ? config.marginBottom : '50'
    }px`};
  ${media.max.large`
    width: 80%;
    margin-left: 10%;
    text-align: center;
  `};
`;
type Accessory = {
  imageUrl: string,
  title: string,
  subTitle: string,
  description: string,
};
type Props = {
  accessories: Accessory[],
  accessoriesInView: Accessory[],
  next: () => void,
  previous: () => void,
  nextMobile: () => void,
  previousMobile: () => void,
  totalPages: number,
  currentPage: number,
  mobileSelected: number,
  mobileCurrentPage: number,
  mobileTotalPages: number,
  config: Object,
  globalStyling: Object,
};

export default function VdpAccessories({
  accessories,
  accessoriesInView,
  nextMobile,
  previousMobile,
  totalPages,
  currentPage,
  mobileSelected,
  mobileCurrentPage,
  mobileTotalPages,
  config,
  globalStyling,
}: Props) {
  const { accessoriesList, translations, paginationArrowUrl } = config;
  return accessories && accessoriesInView && accessories.length ? (
    <Container
      data-qa-hook="vehicle-accessories-module"
      config={accessoriesList}
    >
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <HeadingContainer config={accessoriesList}>
          <VdpHeader
            qaHook="vehicle-accessories-header"
            styles={accessoriesList}
          >
            {translations.vdpAccessoriesHeader}
          </VdpHeader>
          <SubHeader data-qa-hook="vehicle-accessories-subheader">
            {translations.vdpAccessoriesSubHeader}
          </SubHeader>
        </HeadingContainer>
        <PaginationSection>
          <AccessoryBoxWrapper
            data-qa-hook="vehicle-accessories-list"
            centralise={accessories.length <= NUM_VISIBLE}
            config={accessoriesList}
          >
            {accessoriesInView.map((a, index) => (
              <AccessoryBox
                key={a.title}
                mobileSelected={mobileSelected === index}
                backgroundColour={
                  accessoriesList && accessoriesList.backgroundColour
                }
                box={accessoriesList && accessoriesList.box}
              >
                <ImageDiv style={{ backgroundImage: `url(${a.imageUrl})` }}>
                  <Image src={a.imageUrl} config={accessoriesList} />
                </ImageDiv>

                <Content
                  border={accessoriesList && accessoriesList.itemTextBorder}
                >
                  <Title font={accessoriesList && accessoriesList.titleFont}>
                    {a.title}
                  </Title>
                  <Subtitle>{a.subTitle}</Subtitle>
                  <Description>{a.description}</Description>
                </Content>
              </AccessoryBox>
            ))}
          </AccessoryBoxWrapper>
        </PaginationSection>
        {accessories.length > NUM_VISIBLE && (
          <MobileSection>
            <Section alignment="center">{`${currentPage}/${totalPages}`}</Section>
          </MobileSection>
        )}
        <MobileSection hideOnDesktop>
          <Section alignment="center">
            <Button
              style={{ transform: 'rotateY(180deg)' }}
              onClick={previousMobile}
              hideOnDesktop
            >
              {paginationArrowUrl ? (
                <img
                  style={{ width: '20px', paddingLeft: '20px' }}
                  src={paginationArrowUrl}
                  alt="view-previous-accessory"
                />
              ) : (
                <Chunky width="2em" height="2em" />
              )}
            </Button>
            {`${mobileCurrentPage}/${mobileTotalPages}`}
            <Button onClick={nextMobile} hideOnDesktop>
              {paginationArrowUrl ? (
                <img
                  style={{ width: '20px', paddingLeft: '20px' }}
                  src={paginationArrowUrl}
                  alt="view-next-accessory"
                />
              ) : (
                <Chunky width="2em" height="2em" />
              )}
            </Button>
          </Section>
        </MobileSection>
      </ContentWrapper>
    </Container>
  ) : null;
}

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Calendar({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 28 26">
        <path d="M22.686 21.249a.44.44 0 0 1-.437.436H4.751a.44.44 0 0 1-.437-.436V5.717a.44.44 0 0 1 .437-.437h2.843v1.31a.656.656 0 0 0 1.31 0V5.28h3.939v1.31a.656.656 0 0 0 1.31 0V5.28h3.939v1.31a.656.656 0 0 0 1.31 0V5.28h2.843a.44.44 0 0 1 .436.437v15.532h.005zm-.441-17.28h-2.843V2.656a.656.656 0 0 0-1.31 0v1.31h-3.939v-1.31a.656.656 0 0 0-1.31 0v1.31H8.904v-1.31a.656.656 0 0 0-1.31 0v1.31H4.75A1.756 1.756 0 0 0 3 5.718v15.532C3 22.215 3.785 23 4.751 23h17.498c.966 0 1.751-.785 1.751-1.751V5.717a1.753 1.753 0 0 0-1.755-1.747z" />
        <path d="M7 9h3v2H7V9zm0 4h3v2H7v-2zm0 4h3v2H7v-2zm5-8h3v2h-3V9zm0 4h3v2h-3v-2zm0 4h3v2h-3v-2zm5-8h3v2h-3V9zm0 4h3v2h-3v-2zm0 4h3v2h-3v-2z" />
      </svg>
    </IconWrapper>
  );
}

Calendar.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

// @flow
import React from 'react';
import IconRadioGroup from '../IconRadioGroup';

import lowercaseDisabled from './LowercaseDisabled';
import lowercaseEnabled from './LowercaseEnabled';
import regularcaseDisabled from './RegularcaseDisabled';
import regularcaseEnabled from './RegularcaseEnabled';
import uppercaseDisabled from './UppercaseDisabled';
import uppercaseEnabled from './UppercaseEnabled';

type Icon = { base: string, active: string, disabled: string };
export type Option = { icons: Icon, value: string };

type Props = {
  name: string,
  value: string,
  open: boolean,
  onChange: string => void,
  onOpen: () => void,
  onClose: () => void,
  inline?: boolean,
};

const options: Option[] = [
  {
    icons: {
      base: uppercaseDisabled,
      active: uppercaseEnabled,
      disabled: 'blue',
    },
    value: 'uppercase',
  },
  {
    icons: {
      base: regularcaseDisabled,
      active: regularcaseEnabled,
      disabled: 'blue',
    },
    value: 'none',
  },
  {
    icons: {
      base: lowercaseDisabled,
      active: lowercaseEnabled,
      disabled: 'blue',
    },
    value: 'lowercase',
  },
];

export default function TextTransform({
  value,
  name,
  open,
  onChange,
  onClose,
  onOpen,
  inline,
}: Props) {
  const objValue: Option = options.find(o => o.value === value) || options[0];
  return (
    <IconRadioGroup
      name={name}
      options={options}
      selectedValue={objValue}
      open={inline || open}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      inline={inline}
    />
  );
}

TextTransform.defaultProps = {
  inline: false,
};

/* eslint-disable jsx-a11y/label-has-for */
// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Icon = { base: string, active: string, disabled: string };
type Option = { value: string, icons: Icon };

type Props = {
  name: string,
  options: Option[],
  selectedValue: Option,
  open: boolean,
  onChange: string => void,
  onOpen: () => void,
  onClose: () => void,
  inline?: boolean,
};

function justifyIcons(inline?: boolean, options: Option[]) {
  if (options.length === 2) {
    return 'flex-end';
  }
  if (inline) {
    return 'space-between';
  }
  return 'start';
}

const StyledIconRadioGroup = styled.div`
  display: flex;
  justify-content: ${({ inline, options }: Props) =>
    justifyIcons(inline, options)};
`;

const StyledRadioContainer = styled.div`
  visibility: ${(props: Props) => (props.open ? 'visible' : 'hidden')};
  margin-top: ${(props: Props) => (props.inline ? '0' : '-20px')};
`;

const HiddenRadioButton = styled.input.withConfig({
  displayName: 'HiddenRadioButton',
})`
  display: none;
`;

const iconSize = '20px';

const BaseWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colours.primary03}};
  width: ${iconSize};
  height: ${iconSize};

  svg {
    width: ${iconSize};
    height: ${iconSize};
  }

  &:hover {
    cursor: pointer;
  }
`;
const ActiveWrapper = styled(BaseWrapper)`
  ${({ theme }: { theme: Theme }) =>
    theme.mixins.boxShadow(theme.colours.primary01, 0.6, 0, 0, 8, 0, 0)};
`;

type SelectedImageProps = {
  selectedValue: Option,
  open: boolean,
  onOpen: () => void,
  onClose: () => void,
  inline?: boolean,
};
const SelectedImage = ({
  selectedValue,
  open,
  onClose,
  onOpen,
  inline,
}: SelectedImageProps) => {
  const Active = selectedValue.icons.active;

  if (inline) return null;
  return (
    <ActiveWrapper>
      <Active onClick={open ? onClose : onOpen} />
    </ActiveWrapper>
  );
};

SelectedImage.defaultProps = {
  inline: false,
};

export default function IconRadioGroup({
  options,
  selectedValue,
  open,
  onChange,
  onOpen,
  onClose,
  name,
  inline,
}: Props) {
  const inputs = options.map(({ value, icons }, index) => {
    const { active: Active, base: Base } = icons;
    return (
      <StyledRadioContainer key={value} open={open} inline={inline}>
        <HiddenRadioButton
          onChange={event => onChange(event.target.value)}
          key={value}
          type="radio"
          name={name}
          value={value}
          id={`${name}${index}`}
          icons={icons}
        />
        <label htmlFor={`${name}${index}`} title={value}>
          {selectedValue.value === value ? (
            <ActiveWrapper>
              <Active />
            </ActiveWrapper>
          ) : (
            <BaseWrapper>
              <Base />
            </BaseWrapper>
          )}
        </label>
      </StyledRadioContainer>
    );
  });

  return (
    <div>
      <StyledIconRadioGroup inline={inline} options={options}>
        {inputs}
      </StyledIconRadioGroup>
      <SelectedImage
        selectedValue={selectedValue}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        inline={inline}
      />
    </div>
  );
}

IconRadioGroup.defaultProps = {
  name: '',
  options: [],
  value: {},
  open: false,
  onChange: () => {},
  onOpen: () => {},
  onClose: () => {},
  inline: false,
};

// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Wrapper } from '../components';

const EquipmentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Equipment = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
`;

const EquipmentHeader = styled.div`
  display: flex;
  flex: 1 0 100%;
  font-weight: bold;
`;

const EquipmentValue = styled.div`
  display: flex;
  flex: 1 0 100%;
`;

const equipmentSelector = state => {
  if (!state.dataProvider.vehicleInfo) {
    return [];
  }

  return state.dataProvider.vehicleInfo.otherEquipment;
};

interface IVDPEquipmentConfig {
  showCategoryHeaders: boolean;
}

type Props = {
  config: IVDPEquipmentConfig,
};

export default ({ config: { showCategoryHeaders } }: Props) => {
  const equipment = useSelector(equipmentSelector);

  return (
    equipment.length > 0 && (
      <Wrapper>
        <EquipmentContainer>
          {equipment.map(o => (
            <Equipment key={o.category}>
              {showCategoryHeaders && (
                <EquipmentHeader>{o.category}</EquipmentHeader>
              )}
              {o.equipment.map(item => (
                <EquipmentValue key={item}>{item}</EquipmentValue>
              ))}
            </Equipment>
          ))}
        </EquipmentContainer>
      </Wrapper>
    )
  );
};

// @flow
import findIndex from 'ramda/src/findIndex';

import { constants } from '../actions/externalLink';
import type { Action } from '../actions/externalLink';
import type { ExternalLinkState } from '../types/siteBuilder';

export const initialState: ExternalLinkState = {
  links: [],
  error: null,
  linkInContext: null,
  linkAction: null,
};

export default function reducer(
  state: ExternalLinkState = initialState,
  action: Action,
) {
  switch (action.type) {
    case constants.LoadLinksSuccess:
      return {
        ...state,
        links: action.payload,
        error: null,
      };
    case constants.SetLinkInContext: {
      const { link, linkAction } = action.payload;
      return {
        ...state,
        linkInContext: link,
        linkAction,
      };
    }
    case constants.FieldChangeLink: {
      const { id } = action.payload;
      const index = findIndex(link => id === link.id, state.links);
      return {
        ...state,
        linkInContext: action.payload,
        links:
          index === -1
            ? state.links
            : [
                ...state.links.slice(0, index),
                action.payload,
                ...state.links.slice(index + 1),
              ],
      };
    }
    case constants.SaveLinkSuccess:
      return {
        ...state,
        links: [...state.links, action.payload],
        error: null,
      };
    case constants.DeleteLinkSuccess: {
      const index = findIndex(link => action.payload === link.id, state.links);
      return {
        ...state,
        links: [
          ...state.links.slice(0, index),
          ...state.links.slice(index + 1),
        ],
        error: null,
      };
    }
    case constants.LoadLinksFailure:
    case constants.DeleteLinkFailure:
    case constants.SaveLinkFailure:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

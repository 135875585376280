import React from 'react';

const DragHandle = props => (
  <svg width="1em" height="1em" viewBox="0 0 14 12" {...props}>
    <path
      d="M14 11a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1zm0-5a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1zm0-5a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1z"
      fill="#757575"
      fillRule="evenodd"
    />
  </svg>
);

export default DragHandle;

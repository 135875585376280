// @flow
import React from 'react';
import styled from 'styled-components';
import SelectSubmit from './SelectSubmit';

import type { Theme } from '../theme';

const editIcon =
  'https://res.cloudinary.com/motortrak/image/upload/v1570021938/editor/edit-icon.svg';
const deleteIcon =
  'https://res.cloudinary.com/motortrak/image/upload/v1570186934/editor/delete-icon.svg';
const lockIcon =
  'https://res.cloudinary.com/motortrak/image/upload/v1565171516/editor/car_locked.svg';

type Props = {
  name: string,
  value?: { id: string, label: string },
  onChange: (id: string, label: string) => void,
  editing?: boolean,
  onEdit: boolean => void,
  onDelete: () => void,
  lockDelete: boolean,
  editOnly: boolean,
  small: boolean,
  options: Object[],
  siteId: String,
  actions: Object,
};

type ThemeProps = { theme: Theme };

const EditLinkContainer = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${(props: ThemeProps & Props) =>
    props.small ? '0' : props.theme.layout.standardPadding};
  width: 90%;
`;

const Label = styled.span`
  flex: 1 0 0%;
  background: transparent;
  width: ${(props: Props) => (props.small ? '100%' : 'auto')};
  position: relative;
  margin: 0 4px 0 0;
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EditIconsContainer = styled.div`
  display: flex;
`;

const Icon = styled.img`
  color: ${(props: ThemeProps) => props.theme.colours.primary01};
  padding-left: ${(props: ThemeProps & Props) =>
    props.small ? '0' : props.theme.layout.standardPadding};
  cursor: pointer;
`;

type IconProps = {
  onEditClick: boolean => void,
  onDeleteClick: () => void,
  lockDelete: boolean,
  editOnly: boolean,
};

function EditIcons({
  onEditClick: handleEdit,
  onDeleteClick: handleDelete,
  lockDelete,
  editOnly,
}: IconProps) {
  return (
    <EditIconsContainer>
      <Icon src={editIcon} onClick={handleEdit} small />
      {!editOnly &&
        (lockDelete ? (
          <Icon src={lockIcon} small />
        ) : (
          <Icon src={deleteIcon} onClick={handleDelete} small />
        ))}
    </EditIconsContainer>
  );
}

const EditLink = ({
  name,
  value,
  editing,
  onChange,
  onEdit,
  onDelete,
  lockDelete,
  editOnly,
  small,
  options,
  siteId,
  actions,
  buttonText,
}: Props) => (
  <EditLinkContainer>
    {editing ? (
      <SelectSubmit
        dropdownPlaceholder="Select a page/link"
        inputPlaceholder="Enter link text"
        value={value}
        name={name}
        options={options.map(({ label, id }) => ({ value: id, label }))}
        onSubmit={(id, label) => {
          onChange(id, label);
          onEdit(false);
        }}
        actions={actions}
        siteId={siteId}
        handleLinks
        buttonText={buttonText}
      />
    ) : (
      <>
        <Label small={small} name={name}>
          {value.label}
        </Label>
        <EditIcons
          onEditClick={() => onEdit(true)}
          onDeleteClick={onDelete}
          lockDelete={lockDelete}
          editOnly={editOnly}
        />
      </>
    )}
  </EditLinkContainer>
);
export default EditLink;

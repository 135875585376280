/* eslint-disable no-new, no-unused-vars */
// @flow
/* eslint-env browser */
import React from 'react';

import { google } from '../utilities/googleObject';
import { Location } from '../modules/RetailerInformation/RetailerInformation';

type Props = {
  retailerLocation: Location,
  mapName: string,
  iconPin: string,
};
export default class GoogleMap extends React.Component<Props, *> {
  componentDidMount() {
    const [lat, long] = this.props.retailerLocation.coordinates;

    const map = new google.maps.Map(this.googleMap, {
      zoom: 17,
      center: {
        lat,
        lng: long,
      },
    });

    const marker = new google.maps.Marker({
      position: {
        lat,
        lng: long,
      },
      map,
      icon: this.props.iconPin,
    });
  }

  googleMap = null;

  render() {
    return (
      <div
        data-qa-hook="google-map"
        id={this.props.mapName}
        style={{ height: '100%' }}
        ref={map => {
          this.googleMap = map;
        }}
      />
    );
  }
}

import React from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #0065db;
`;
export const FormButtonEditor = ({
  field,
  fields,
  updatePodField,
  translations,
}) => (
  <>
    <InputWrapper>
      <div>Button Label</div>
      <input
        style={{ width: '300px' }}
        value={translations.submitbuttonLabel}
        onChange={e =>
          updatePodField(
            'fields',
            fields.map(m =>
              m === field
                ? {
                    ...m,
                    config: {
                      ...m.config,
                      translations: {
                        ...m.config.translations,
                        submitbuttonLabel: e.target.value,
                      },
                    },
                  }
                : m,
            ),
          )
        }
      />
    </InputWrapper>
  </>
);

import { put, call, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import {
  actions as loginActions,
  constants as loginConstants,
} from '../../shared/CustomerLogin';
import {
  isTokenValid,
  getCustomerDetails,
} from '../../shared/CustomerLogin/reducer';
import { getToken as getLeadsToken } from '../../shared/leadsAuthentication/reducer';
import { get, post, localeConfigSerializer } from '../../helpers/http';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  testDrivesServiceUrl,
  getPlaceholderBrandImageUrl,
  appendLocaleQueryString,
  getLeadsUrl,
} from '../../shared/selectors/settings';
import { localiseNumber } from '../../shared/localisation/numbers';

function* getVehicleInfo(action) {
  const {
    payload,
    module: { page, instance },
  } = action;

  const { locale, make, market } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const baseUrl = yield select(inventorySearchServiceUrl);
  const url = `${baseUrl}inventory/make/${make}/market/${market}/${
    payload.vehicleId
  }`;

  const placeholdingImage = yield select(getPlaceholderBrandImageUrl);

  try {
    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });

    const testDriveConfig = {
      ...response.data,
      specification: {
        ...response.data.specification,
        odometer: {
          ...response.data.specification.odometer,
          display: `${localiseNumber(
            response.data.specification.odometer.reading,
            locale,
          )} ${response.data.specification.odometer.units}`,
        },
      },
      placeholdingImage,
    };

    yield put(
      actions.getVehicleInfoSuccess(page, instance, { testDriveConfig }),
    );
  } catch (error) {
    yield put(
      actions.getVehicleInfoFailure(page, instance, {
        ...error,
        message: `Failed to retreive Vehicle Information: ${error.message}`,
      }),
    );
  }
}

function CreateCustomerDetails(payload) {
  return {
    title: payload.bookingForm.title,
    firstName: payload.bookingForm.firstname,
    lastName: payload.bookingForm.lastname,
    email: payload.bookingForm.email,
    phoneNumber: payload.bookingForm.phone,
    postCode: payload.bookingForm.postcode,
    vehiclePreferences: {
      interestedIn: payload.testDriveConfig.name,
      currentVehicle: payload.bookingForm.currentVehicle,
    },
    contactPreferences: {
      sms: payload.bookingForm.smsConsent,
      email: payload.bookingForm.emailConsent,
      phone: payload.bookingForm.phoneConsent,
    },
  };
}

function hasBookingFormErrors(bookingFormError) {
  return (
    (bookingFormError.title && bookingFormError.title[0]) ||
    (bookingFormError.firstname && bookingFormError.firstname[0]) ||
    (bookingFormError.lastname && bookingFormError.lastname[0]) ||
    (bookingFormError.firstname && bookingFormError.firstname[0]) ||
    (bookingFormError.lastname && bookingFormError.lastname[0]) ||
    (bookingFormError.email && bookingFormError.email[0]) ||
    (bookingFormError.emailConfirm && bookingFormError.emailConfirm[0]) ||
    (bookingFormError.phone && bookingFormError.phone[0]) ||
    (bookingFormError.postcode && bookingFormError.postcode[0]) ||
    (bookingFormError.phone && bookingFormError.phone[0]) ||
    (bookingFormError.postcode && bookingFormError.postcode[0]) ||
    (bookingFormError.termsAndConditionsNotChecked &&
      bookingFormError.termsAndConditionsNotChecked[0]) ||
    (bookingFormError.accountPassword && bookingFormError.accountPassword[0]) ||
    (bookingFormError.accountPasswordConfirm &&
      bookingFormError.accountPasswordConfirm[0])
  );
}

function GetCustomerCreateAccountDetails(customerDetails, pass) {
  return {
    customer: customerDetails,
    credentials: {
      password: pass,
    },
  };
}

function* postPersistGuestDetails(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  const pageState = yield select(state => state.pages[page][instance]);

  try {
    const hasErrors = yield call(
      hasBookingFormErrors,
      pageState.bookingFormError,
    );

    if (!hasErrors) {
      const guestDetails = yield call(CreateCustomerDetails, payload);

      const password = pageState.bookingForm.accountPassword;
      const newAccount =
        password !== {} && password != null && password !== undefined;

      if (newAccount) {
        const customerCreateAccountDetails = yield call(
          GetCustomerCreateAccountDetails,
          guestDetails,
          password,
        );

        yield put(
          loginActions.postCreateAccount(
            page,
            instance,
            customerCreateAccountDetails,
          ),
        );
      }

      yield put(
        actions.guestDetailsSubmitted(page, instance, guestDetails, newAccount),
      );
    }
  } catch (error) {
    yield put(actions.somethingWentWrong(page, instance));
  }
}

function* loginAndConfirm(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    yield put(loginActions.postLogin(page, instance, payload));
  } catch (error) {
    yield put(actions.somethingWentWrong(page, instance));
  }
}

function* loginSuccess(action) {
  const {
    payload,
    module: { page, instance },
  } = action;

  if (page.toLowerCase() !== '/testdrive') return;

  try {
    yield put(actions.loginSuccess(page, instance, payload));
  } catch (error) {
    // console.log(error, 'TestDrive login success effect error');
  }
}

function* loginFailed(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  try {
    yield put(actions.loginFailed(page, instance, payload));
  } catch (error) {
    // console.log(error, 'TestDrive login success effect error');
  }
}

function getTestDriveLeadPayload(
  pageState,
  {
    firstName,
    lastName,
    email,
    phoneNumber,
    postCode,
    contactPreferences: {
      email: emailConsent,
      phone: phoneConsent,
      sms: smsConsent,
    },
  },
) {
  return {
    leadType: 'test_drive',
    vin: pageState.testDriveConfig.id,
    dealerId: pageState.testDriveConfig.retailerInformation.id,
    refererURL: `${window.location.origin}/VDP/${
      pageState.testDriveConfig.id
    }`.toLowerCase(),
    phoneConsent,
    emailConsent,
    smsConsent,
    additionalInfo: {
      date:
        pageState.selectedDateMode === 'AnyDate'
          ? pageState.selectedDateMode
          : new Date(pageState.bookingForm.date).toDateString(),
      time: pageState.selectedTimeMode,
      interestedInRemote: `${pageState.bookingForm.interestedChecked}`,
    },
    customer: {
      firstName,
      lastName,
      email,
      phoneNumber,
      postCode,
    },
  };
}

function getTestDriveRecordPayload({
  testDriveConfig,
  selectedDateMode,
  selectedTimeMode,
  email,
  make,
  market,
}) {
  return {
    vehicle: {
      vin: testDriveConfig.id,
      year: null, // TODO not currently returned from service
      make: null, // TODO not currently returned from service
      model: testDriveConfig.name,
      odometer: testDriveConfig.specification.odometer,
      engine: testDriveConfig.specification.engine,
      transmission: testDriveConfig.specification.transmission,
      fuel: testDriveConfig.specification.fuelType,
      imageUrl: testDriveConfig.images[0],
      registration: testDriveConfig.registration,
      description: testDriveConfig.description,
    },
    dealer: {
      id: testDriveConfig.retailerInformation.id,
      name: testDriveConfig.retailerInformation.name,
      address: testDriveConfig.retailerInformation.address.join(),
      phone: testDriveConfig.retailerInformation.phone,
    },
    request: {
      date: selectedDateMode,
      time: selectedTimeMode,
    },
    email,
    make,
    market,
  };
}

function* getUserDetails({ guestDetails, newAccount } = { guestDetails: {} }) {
  const isUserLoggedIn = yield select(state => isTokenValid(state.shared));
  if (isUserLoggedIn) {
    const userDetails = yield select(state => getCustomerDetails(state.shared));
    return { ...userDetails, newAccount: false };
  }
  return { ...guestDetails, newAccount };
}

function* sendTestDriveLead(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  const pageState = yield select(state => state.pages[page][instance]);
  const leadsToken = yield select(state => getLeadsToken(state.shared));
  const userDetails = yield getUserDetails(payload);
  const testDriveLeadPayload = getTestDriveLeadPayload(pageState, userDetails);
  const leadsUrl = yield select(getLeadsUrl);

  try {
    yield call(post, {
      url: leadsUrl,
      token: leadsToken,
      data: testDriveLeadPayload,
    });

    yield put(
      actions.sendTestDriveLeadSuccess(
        page,
        instance,
        userDetails.newAccount,
        userDetails.email,
      ),
    );
  } catch (error) {
    yield put(actions.somethingWentWrong(page, instance));
  }
}

function* saveTestDriveForCustomer(action) {
  const {
    module: { page, instance },
    payload,
  } = action;
  if (!page.match(/\/Testdrive\/[0-9].*/i)) return;
  const pageState = yield select(state => state.pages[page][instance]);
  const userDetails = yield getUserDetails(payload);
  const { make, market } = yield select(getAVLConfig);
  const testDriveRecordPayload = getTestDriveRecordPayload({
    ...pageState,
    email: userDetails.email,
    make,
    market,
  });
  try {
    const testDriveUrl = yield select(testDrivesServiceUrl);
    yield call(post, {
      url: `${testDriveUrl}make/${make}/market/${market}/requests`,
      data: testDriveRecordPayload,
    });

    yield put(actions.saveTestDriveSuccess(page, instance, userDetails));
  } catch (error) {
    yield put(actions.somethingWentWrong(page, instance));
  }
}

function* sendSomethingWentWrong(action) {
  yield put(actions.somethingWentWrong(action.page, action.instance));
}

export default function* TestDriveSaga() {
  yield takeEvery(constants.GET_VEHICLE_INFO, getVehicleInfo);
  yield takeEvery(constants.LOGIN_AND_CONFIRM, loginAndConfirm);
  yield takeEvery(
    [
      constants.USER_LOGGED_IN,
      loginConstants.GET_CUSTOMER_SUCCESS,
      constants.GUEST_DETAILS_SUBMITTED,
    ],
    saveTestDriveForCustomer,
  );
  yield takeEvery(constants.CONFIRM_REQUEST, postPersistGuestDetails);
  yield takeEvery(
    [
      loginConstants.CREATE_CUSTOMER_FAILURE,
      loginConstants.GET_CUSTOMER_FAILURE,
    ],
    sendSomethingWentWrong,
  );
  yield takeEvery(constants.SAVE_TEST_DRIVE_SUCCESS, sendTestDriveLead);
  yield takeEvery(loginConstants.LOGIN_SUCCESS, loginSuccess);
  yield takeEvery(loginConstants.LOGIN_FAILURE, loginFailed);
}

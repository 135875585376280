import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import CompiledModule from './CompiledModule';
import useBody from './useBody';

const Body = ({ path, globalStyling }) => {
  const { page, bands, bandComponents } = useBody(path);
  return (
    <Fragment>
      <Helmet>
        {page.seo && page.seo.description && (
          <meta name="description" content={page.seo.description} />
        )}
        {page.seo && page.seo.title && <title>{page.seo.title}</title>}
      </Helmet>
      {bands.map((band, index) => {
        const component = bandComponents[band.id];
        const BandComponent = component ? component.component : CompiledModule;
        return (
          <BandComponent
            key={band.instanceId}
            band={band}
            path={path}
            globalStyling={globalStyling}
            index={index}
          />
        );
      })}
    </Fragment>
  );
};

export default Body;

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CreatePage } from 'cms-ui';

import { actions as pageActions } from '../state/actions/page';
import { actions as siteBuilderActions } from '../state/actions/siteBuilder';
import { actions as pageTemplatesActions } from '../state/actions/pageTemplates';

import { stripNonUrlSafeCharsFromPageUrl } from '../state/reducers/page';

type PageTemplateOption = { value: string, label: string };

type Props = {
  renderButton: (() => void) => Node,
  loadPageTemplates: Function,
  showAccordion: Function,
  pageTemplates: PageTemplateOption[],
  currentSiteId: string,
};

type State = {
  configuredPage: {
    siteId: string,
    seo: Seo,
  },
};

const mapStateToProps = ({ pageTemplates, site }) => ({
  pageTemplates: pageTemplates.pageTemplates,
  currentSiteId: site.currentSiteId,
});

const mapDispatchToProps = (dispatch, { renderButton }) => ({
  loadPageTemplates: () => dispatch(pageTemplatesActions.loadPageTemplates()),
  showAccordion: (id, show) =>
    dispatch(siteBuilderActions.showAccordion(id, show)),
  renderButton: page =>
    renderButton(() => dispatch(pageActions.createPage(page))),
});

class CreatePageWithLifecycle extends Component<Props, State> {
  state = {
    configuredPage: {
      siteId: this.props.currentSiteId,
      seo: {
        title: '',
        description: '',
      },
    },
  };

  componentDidMount() {
    this.props.loadPageTemplates();
  }

  onPageChange = page =>
    this.setState({ configuredPage: stripNonUrlSafeCharsFromPageUrl(page) });

  render() {
    const { renderButton, pageTemplates, ui } = this.props;
    return (
      <CreatePage
        pageTemplates={pageTemplates}
        value={this.state.configuredPage}
        onChange={this.onPageChange}
        ui={ui}
        renderButton={renderButton}
        canChangePageType
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageWithLifecycle);

import { constants as filterConstants } from '../../shared/filters';

export const constants = {
  getModelGroups: 'GET_MODEL_GROUPS',
  getModelGroupsSuccess: 'GET_MODEL_GROUPS_SUCCESS',
  getModelGroupsFailure: 'GET_MODEL_GROUPS_FAILURE',
  clearState: 'CLEAR_STATE',
};

export const actions = {
  getModelGroups: () => ({ type: constants.getModelGroups }),
  getModelGroupsSuccess: payload => ({
    type: constants.getModelGroupsSuccess,
    payload,
  }),
  getModelGroupsFailure: () => ({
    type: constants.getModelGroupsFailure,
  }),
  clearState: () => ({
    type: constants.clearState,
  }),
};

const initialState = {
  modelGroups: [],
  loaded: false,
};
export function reducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case constants.getModelGroupsSuccess:
      return {
        ...state,
        modelGroups: payload,
      };
    case filterConstants.LOAD_FILTERS_SUCCESS:
      return {
        ...state,
        loaded: true,
      };
    case constants.clearState:
      return initialState;
    default:
      return state;
  }
}

// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_SIGN_IN';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'SIGN_IN',
  'SIGN_IN_FAILURE',
]);

export const id = 'SignIn';

export const actions = {
  init: moduleAction(constants.INIT, id),
  signIn: moduleActionWithArgs(constants.SIGN_IN, id, payload => ({
    ...payload,
  })),
  signInFailure: moduleActionWithArgs(
    constants.SIGN_IN_FAILURE,
    id,
    payload => ({ ...payload }),
  ),
};

type State = {
  error: any,
  loginFailed?: 'VALIDATION REQUIRED' | 'UNAUTHORISED',
};
export const initialState: State = {
  error: null,
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.INIT:
      return {
        ...state,
        loginFailed: undefined,
      };
    case constants.SIGN_IN_FAILURE:
      return {
        ...state,
        loginFailed: action.payload.reason,
      };
    default:
      return state;
  }
}

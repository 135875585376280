// @flow
import React, { type Node } from 'react';
import { createElement, mediaProps } from './styledComponentFactory';

const Para = createElement('p');

type Props = {
  styleOverride?: () => string,
  mobileStyleOverride?: () => { queryPath: string, template: string }[],
  children?: Node,
};

export default function Paragraph({
  styleOverride,
  mobileStyleOverride,
  ...props
}: Props) {
  return (
    <Para
      styleOverride={styleOverride}
      {...{ ...props, ...mediaProps(mobileStyleOverride()) }}
    />
  );
}

Paragraph.defaultProps = {
  styleOverride: () => '',
  mobileStyleOverride: () => [],
  children: null,
};

import {
  Config,
  Slice,
  ListSlice,
  Section,
  TextArea,
  Toggle,
  Image,
  Colour,
  Font,
  Align,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const hintText = () => `
  Please note that all images for this Module will be transformed to a resolution of 1366 x 600 pixels.
  To optimise the display of images they should be as close to this resolution as possible.
  `;

const config = Config(
  'Icon grid',
  { id: 'icongrid' },
  Slice(
    'Heading',
    { id: 'heading' },
    Section(
      'Global',
      {},
      TextArea('Header', 'headLineText'),
      TextArea('Description', 'subHeadlineText'),
      Toggle('Background Image', 'backgroundImageActive'),
      Image('Image', 'backgroundImage', { hintText }),
    ),
  ),
  ListSlice(
    'Grid Items',
    {
      id: 'items',
      max: 9,
      sectionLabel: 'Grid Item',
      buttonLabel: 'Add new grid item',
      icon: SectionIcons.GridItem,
    },
    Image('Image', 'image'),
    TextArea('Title', 'titleItemText'),
    TextArea('Description', 'descriptionItemText'),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Align('Align', 'headLineAlignment'),
      Colour('Background Colour', 'backgroundColour'),
      Font('Header Text', 'headlineFont'),
      Font('Grid Item Header', 'itemHeaderFont'),
      Font('Description', 'descriptionFont'),
    ),
  ),
);

export default config;

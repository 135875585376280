import Icon from './Icon';

export { default as defaultConfig } from './defaultConfig';
export { default as config } from './config';

export const id = 'Filters';
export const linked = true;
export const availableToAdministration = false;
export const name = 'Filters';

export const description = 'Filters Linked Module';
export const icon = Icon;

import Icon from './Icon';

export { id as reducerId } from './reducer';

export { default as component } from './VehicleSearchResults';
export { default as effects } from './effects';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';
export { actions, constants, reducer, initialState } from './reducer';

export const name = 'Vehicle Search Results';
export const id = 'VehicleSearchResults';
export const availableToAdministration = true;
export const description = 'Search Results Page';
export const icon = Icon;

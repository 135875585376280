// @flow
import React from 'react';
import styled from 'styled-components';

type Props = {
  image: string,
  active: boolean,
  fullscreen: boolean,
};

const SlideContainer = styled.img`
  width: 100%;
  position: absolute;

  @keyframes fadein {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }

  ${({ active }) =>
    active &&
    `animation: fadein 0.6s ease-out;
  `}
  ${({ fullscreen }) =>
    fullscreen &&
    `
    height: 100%;
    object-fit: contain;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
    }
  `}
`;

export default function Slide(props: Props) {
  return (
    <SlideContainer
      src={props.image}
      active={props.active}
      fullscreen={props.fullscreen}
    />
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import { Link, fontStyleOverride } from '../../components/Global';

import type { HeaderTemplateConfig } from '../types';

const propHelper = a =>
  (a &&
    {
      left: 'flex-start',
      right: 'flex-end',
    }[a]) ||
  'center';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  width: 100%;
  margin: 0;
  position: relative;
  ${({ backgroundColour }) =>
    backgroundColour ? `background-color: ${backgroundColour.value}` : ''};
  * {
    box-sizing: border-box;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100px;
  height: 100%;
  align-items: center;
  justify-content: ${({ horizontalAlignment }) =>
    propHelper(horizontalAlignment)};
`;

const HeaderNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: space-between;
  word-break: break-word;
`;

const NavBarContainer = styled.nav`
  display: flex;
  width: 100%;
  justify-content: ${({ horizontalAlignment }) =>
    propHelper(horizontalAlignment)};
`;

const LinksList = styled.ul`
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: inline-block;
  }
`;

const MenuListItem = styled.li`
  margin: 0;
  padding: 0 20px;
`;

type Props = {
  config: HeaderTemplateConfig,
};

export default function one(props: Props) {
  const {
    config: {
      menu = { links: [] },
      logo = {},
      headerFont = {},
      translations = {},
    },
  } = props;

  const links = (menu || { items: [] }).links.map(value => ({
    ...value,
    label: translations[value.itemId],
  }));
  return (
    <HeaderWrapper>
      <LogoWrapper horizontalAlignment={logo.horizontalAlignment}>
        <Link href={logo.url}>
          <img alt={translations.imageLabel} src={logo.src} />
        </Link>
      </LogoWrapper>
      <HeaderNavigationWrapper>
        <NavBarContainer horizontalAlignment={menu.horizontalAlignment}>
          <LinksList>
            {links.map(link => (
              <MenuListItem key={link.label}>
                <Link
                  styleOverride={() => fontStyleOverride(headerFont)}
                  href={link.url}
                >
                  {link.label}
                </Link>
              </MenuListItem>
            ))}
          </LinksList>
        </NavBarContainer>
      </HeaderNavigationWrapper>
    </HeaderWrapper>
  );
}

// @flow
import React, { useState } from 'react';
import styled from 'styled-components';

import { InputField, FormGroup, Column, Button, Dropdown } from 'cms-ui';
import LinkDropdown from './LinkDropdown';

import type { Theme } from '../theme';

const Container = styled.div`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeContent};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSelect = styled(Dropdown)``;

const AddButton = styled(Button)`
  padding: 6px 0;
  margin-top: 3px;
`;

type Option = { value: string, label: string };

type Props = {
  name: string,
  options: Option[],
  onSubmit: (label: string, id: string) => any,
  selectedValue?: ?string,
  dropdownPlaceholder?: string,
  inputPlaceholder?: string,
  actions?: Object,
  siteId?: string,
  handleLinks?: Boolean,
  buttonText: string,
};

const SelectSubmit = ({
  options,
  name,
  onSubmit,
  dropdownPlaceholder,
  inputPlaceholder,
  actions,
  siteId,
  handleLinks,
  value,
  buttonText,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState(
    options.find(option =>
      value
        ? option.value === value.id
        : {
            value: '',
            label: '',
          },
    ),
  );
  const [label, setLabel] = useState(value ? value.label : '');

  const dropdownInput = {
    value: selectedOption,
    onChange: event => setSelectedOption(event.value),
    placeholder: dropdownPlaceholder,
  };

  const labelInput = {
    onChange: e => setLabel(e.target.value),
    name: `${name}-text-input`,
    value: label,
  };

  return (
    <Container>
      <FormGroup horizontal>
        <Column width="100%">
          <InputField
            input={labelInput}
            type="text"
            placeholder={inputPlaceholder}
          />
        </Column>
      </FormGroup>
      <FormGroup horizontal>
        <Column width="100%">
          {handleLinks ? (
            <LinkDropdown
              onChange={event => {
                setSelectedOption(event);
              }}
              name={`select-link-${name}`}
              value={selectedOption && selectedOption.value}
              options={options}
              actions={actions}
              siteId={siteId}
            />
          ) : (
            <StyledSelect
              input={dropdownInput}
              name={`select-${name}`}
              options={options}
            />
          )}
        </Column>
      </FormGroup>
      <FormGroup horizontal>
        <Column width="100%">
          <AddButton
            onClick={() =>
              onSubmit(selectedOption.value, label || selectedOption.label)
            }
            small
            primary
            textTransform="uppercase"
            disabled={selectedOption ? !selectedOption.value : true}
          >
            {buttonText}
          </AddButton>
        </Column>
      </FormGroup>
    </Container>
  );
};

export default SelectSubmit;

import Icon from './Icon';

export { default as component } from './Grid';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Grid';
export const id = 'Grid';
export const availableToAdministration = true;
export const description = 'A grid view of items with image, text and button';
export const icon = Icon;

export function template() {
  return {
    items: {
      image: {
        label: 'search_approved.jpg',
        value:
          'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png',
      },
      link: {
        url: 'http://approved.me.jaguar.com',
        label: 'Text placeholder',
      },
      translations: {
        headline: 'Title placeholder',
        body: 'Body text placeholder',
        link: 'Find Out More',
      },
    },
  };
}

export const searchable = true;
export const getIndexContent = ({
  config: { items = [] } = {},
  relativePath,
  instanceId,
}) =>
  [
    ...items.map(({ translations: { headline, body } }) => ({
      title: headline,
      content: body,
    })),
    ...items.map(({ translations: { headline, link } }) => ({
      title: headline,
      content: link,
    })),
  ]
    .map(({ title, content }, index) => ({
      key: `${instanceId}-${index}`,
      title,
      content,
      link: relativePath,
    }))
    .filter(({ title, content }) => !!title || !!content);

// @flow
import type { Option, Font } from '../../types';
import fallbackFonts from './fallbackFonts';

export const fontStyleOverride = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
  lineHeight,
  alignment,
  padding,
}: Font = {}) =>
  [
    typeface && `font-family: ${typeface.value},${fallbackFonts};`,
    fontSize && `font-size: ${fontSize}px;`,
    kerning !== undefined && `letter-spacing: ${kerning}px;`,
    transform && `text-transform: ${transform};`,
    colour && `color: ${colour.value};`,
    lineHeight && `line-height: ${lineHeight}px;`,
    alignment && `text-align: ${alignment};`,
    padding && padding.top && `padding-top: ${padding.top}px;`,
    padding && padding.bottom && `padding-bottom: ${padding.bottom}px;`,
    padding && padding.left && `padding-left: ${padding.left}px;`,
    padding && padding.right && `padding-right: ${padding.right}px;`,
  ]
    .filter(Boolean)
    .join('');

export const buttonStyleOverride = (
  font: Font,
  backgroundColour: Option,
  hoverBackgroundColour: Option,
  buttonSize: Object,
  hoverColour: Option,
) => `
    ${fontStyleOverride(font)}
    ${[
      buttonSize && buttonSize.width && `width: ${buttonSize.width};`,
      buttonSize && buttonSize.height && `height: ${buttonSize.height};`,
      backgroundColour && `background-color: ${backgroundColour.value};`,
    ]
      .filter(Boolean)
      .join('')}

    svg > rect:not([fill='none']) {
      ${font && font.colour && `fill: ${font.colour.value}`};
    }
    svg > path:not([fill]) {
      ${font && font.colour && `fill: ${font.colour.value}`};
    }
    &:hover {
      ${[
        hoverBackgroundColour &&
          `background-color: ${hoverBackgroundColour.value};`,
        hoverColour && `color: ${hoverColour.value};`,
      ]
        .filter(Boolean)
        .join('')}
    }
  `;

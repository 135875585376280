// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function ListEnabled({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 45 45">
        <g fill="none" fillRule="evenodd">
          <path fill={background} d="M0 0h45v45H0z" />
          <g fill={colour}>
            <path d="M12 12h5v5h-5zM12 19.381h5v5h-5zM12 26.762h5v5h-5zM19.333 12h14v5h-14zM19.333 19.381h14v5h-14zM19.333 26.762h14v5h-14z" />
          </g>
        </g>
      </svg>
    </IconWrapper>
  );
}

ListEnabled.defaultProps = {
  colour: '#D8D8D8',
  width: null,
  height: null,
  background: '#252525',
};

// @flow
/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';

import Modal from './Modal';

import type { Theme } from '../theme';

type Props = {
  content: string,
};

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  font-family: ${({ theme }: { theme: Theme }) => theme.fontFamily};
  background-color: ${({ theme }: { theme: Theme }) => theme.colours.help};
  font-size: ${({ theme }: { theme: Theme }) => theme.fontSizeSmall};
  height: 14px;
  width: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  border: 1px solid
    ${({ theme }: { theme: Theme }) => theme.colours.secondary02};
  border-radius: 4px;
  padding: 1px;
  cursor: help;
  align-content: center;
`;

export default class HelpIndicator extends React.Component<Props, *> {
  state = {
    position: undefined,
  };

  position = (x: number, y: number) => ({
    x,
    y,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  handleMouseEnter = ({
    clientX,
    clientY,
  }: {
    clientX: number,
    clientY: number,
  }) =>
    this.setState({
      position: this.position(clientX, clientY),
      show: true,
    });

  handleMouseLeave = () => this.setState({ show: false });

  render() {
    const { content } = this.props;

    return (
      <Container
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        aria-label={content}
      >
        ?
        {this.state.show ? (
          <Modal position={this.state.position} overlay={false}>
            <p>{content}</p>
          </Modal>
        ) : null}
      </Container>
    );
  }
}

import { constants as pageConstants } from '../actions/page';
import { constants as editorConstants } from '../actions/editor';
import { constants as siteConstants } from '../actions/site';

const initialState = {
  success: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case pageConstants.CreatePageSuccess:
    case pageConstants.SavePageSuccess:
    case siteConstants.SaveSiteSuccess:
    case pageConstants.SaveChangesSuccess: {
      return {
        ...state,
        success: true,
        error: null,
      };
    }
    case pageConstants.LoadPagesFailure:
    case pageConstants.CreatePageFailure:
    case pageConstants.SavePageFailure:
    case siteConstants.LoadSiteFailure:
    case siteConstants.LoadSitesFailure:
    case siteConstants.LoadCloneableSitesFailure:
    case siteConstants.SaveSiteFailure:
    case siteConstants.SkipCloningFailure:
    case siteConstants.CloneSiteFailure:
    case pageConstants.SaveChangesFailure: {
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    }
    case editorConstants.ResetSuccessMessages:
    case editorConstants.ResetFailureMessages: {
      return {
        success: null,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};

// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { SectionMenuItem } from 'cms-ui';
import { breakoutType } from './BreakoutWrapper';
import { actions } from '../state/actions/blogs';
import { actions as contextActions } from '../state/actions/context';

const SectionMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 36px;
  border-top: ${({ theme }) => `1px solid ${theme.colours.secondary02}`};
`;

const MenuContent = styled.span`
  display: flex;
  align-items: center;
`;

export default ({ onEnterBreakout }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadBlogs());
  }, [dispatch]);
  const { blogs } = useSelector(state => state.blogs);
  return (
    <SectionMenu>
      {blogs.map(blog => (
        <SectionMenuItem
          key={blog.id}
          onClick={() => {
            dispatch(contextActions.setGlobalContext('Edit Blog Content'));
            onEnterBreakout({ name: breakoutType.editPost, data: blog });
          }}
          withBorder
        >
          <MenuContent>{blog.config.title}</MenuContent>
        </SectionMenuItem>
      ))}
    </SectionMenu>
  );
};

// @flow
import styled from 'styled-components';

const SiteBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #d6d6d6;
  margin-bottom: 10px;
  background-color: #fff;
`;

export default SiteBox;

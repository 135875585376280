// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
};

const BannerContainer = styled.div`
  width: 100%;
  background-color: ${(props: Props) => props.theme.colours.secondary01};
  border-bottom: 3px solid ${(props: Props) => props.theme.colours.primary01};
`;
const StyledBanner = styled.div`
  font-family: ${(props: Props) => props.theme.fontFamily};
  font-size: ${(props: Props) => props.theme.fontSizeHeader};
  height: 100px;
  display: flex;
  align-items: center;
  max-width: 1366px;
  margin: auto;
`;

export default function Banner(props: Props) {
  return (
    <BannerContainer>
      <StyledBanner {...props} />
    </BannerContainer>
  );
}

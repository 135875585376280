// @flow
import React from 'react';
import styled from 'styled-components';

import MyAccountHeading from './MyAccountHeading';
import MyAccountContent from './MyAccountContent';

import TestDrive from '../JaguarIcons/Global/TestDrive';
import Budget from '../JaguarIcons/Global/Budget';
import Criteria from '../JaguarIcons/Global/Criteria';
import User from '../JaguarIcons/Global/User';
import Heart from '../JaguarIcons/Global/Heart';
import media from '../../theme';
import { HeadingTwo, Paragraph } from '../Global';

const Content = styled.div`
  display: flex;
  min-height: 450px;
  ${media.max.large`
    display: block;
  `};
`;
const GreetingContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: auto;
  padding-right: 135px;
  ${media.max.large`
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 40px 0 0 0;
  `};
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  margin: auto;
  padding-left: 135px;
  ${media.max.large`
    margin: 0;
    width: 100%;
    padding: 40px 0;
  `};
`;
const MenuItem = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid #d8d8d8;
  &:last-child {
    border-bottom: 1px solid #d8d8d8;
  }
`;
const MenuItemText = styled.div`
  margin-left: 20px;
`;

const Menu = ({
  translations,
  changeAccountView,
  signOut,
  onShortListClick,
  featureFlags,
}) => (
  <MenuContainer>
    <MenuItem onClick={changeAccountView('Details')}>
      <Criteria width="1.5em" height="1.5em" colour="#7e7e7e" />
      <MenuItemText>{translations.myAccountDetailsLabel}</MenuItemText>
    </MenuItem>
    <MenuItem onClick={changeAccountView('Deals')}>
      <Budget width="1.5em" height="1.5em" colour="#7e7e7e" />
      <MenuItemText>{translations.myAccountDealsLabel}</MenuItemText>
    </MenuItem>
    {featureFlags.testDrive && (
      <MenuItem onClick={changeAccountView('TestDrives')}>
        <TestDrive width="1.5em" height="1.5em" colour="#7e7e7e" />
        <MenuItemText>{translations.myAccountTestDrivesLabel}</MenuItemText>
      </MenuItem>
    )}
    <MenuItem onClick={onShortListClick}>
      <Heart width="1.5em" height="1.5em" colour="#7e7e7e" />
      <MenuItemText>{translations.myAccountShortlistLabel}</MenuItemText>
    </MenuItem>
    <MenuItem onClick={signOut}>
      <User width="1.5em" height="1.5em" colour="#7e7e7e" />
      <MenuItemText>{translations.myAccountSignOutLabel}</MenuItemText>
    </MenuItem>
  </MenuContainer>
);

const Greeting = ({ translations, name }) => (
  <GreetingContainer>
    <HeadingTwo
      styleOvveride={() => `
        font-size: 22px;
      `}
    >
      {`${translations.myAccountGreeting} ${name}`}
    </HeadingTwo>
    <Paragraph
      styleOverride={() => `
        font-size: 18px;
        line-height: 22px;
        margin-top: 30px;
      `}
    >
      {translations.myAccountGreetingContent}
    </Paragraph>
  </GreetingContainer>
);

type Props = {
  translations: Object,
  changeAccountView: (name: string) => void,
  userDetails: Object,
  signOut: () => void,
  onShortListClick: Function,
  featureFlags: Object,
};
export default function MyAccountLanding({
  translations,
  changeAccountView,
  userDetails,
  signOut,
  onShortListClick,
  featureFlags,
}: Props) {
  return (
    <div>
      <MyAccountHeading heading={translations.myAccountMyAccountLabel} />
      <MyAccountContent>
        <Content>
          <Greeting translations={translations} name={userDetails.firstName} />
          <Menu
            featureFlags={featureFlags}
            translations={translations}
            changeAccountView={changeAccountView}
            signOut={signOut}
            onShortListClick={onShortListClick}
          />
        </Content>
      </MyAccountContent>
    </div>
  );
}

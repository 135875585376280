// @flow
import { useDispatch, useSelector } from 'react-redux';
import { actions as filterActions } from '../../shared/filters';
import { actions as sessionPreferencesActions } from '../../shared/sessionPreferences';

type LocationHook = {
  onButtonClick: () => void,
  updateLocationFilter: (latitude: string, longitude: string) => void,
  latitude: string,
  longitude: string,
};

export function useLocationHook(): LocationHook {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(
    state => state.shared.sessionPreferences.location || {},
  );

  const updateLocationFilter = (latitude, longitude) => {
    dispatch(
      sessionPreferencesActions.updateSessionPreferences('location', {
        latitude,
        longitude,
      }),
    );
  };

  const onButtonClick = () => {
    dispatch(filterActions.searchWithFilters());
  };

  return {
    onButtonClick,
    updateLocationFilter,
    latitude: selectedFilters.latitude,
    longitude: selectedFilters.longitude,
  };
}

// @flow
import { useEffect } from 'react';

import { actions } from '.';

const DataProvider = props => {
  useEffect(() => {
    const {
      config: { actionName, dataKey },
      dispatch,
    } = props;

    dispatch(actions[actionName](dataKey));

    return () => dispatch(actions.clearDataProviderState(dataKey));
  }, []);

  return null;
};

export default DataProvider;

import Icon from '../../modules/TestDrive/Icon';

export { id as reducerId } from '../../modules/TestDrive/reducer';

export { default as component } from './TestDrive';
export { default as effects } from '../../modules/TestDrive/effects';
export { default as config } from '../../modules/TestDrive/config';
export {
  default as defaultConfig,
} from '../../modules/TestDrive/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/TestDrive/reducer';

export const name = 'Test Drive';
export const id = 'TestDrive';
export const availableToAdministration = false;
export const description =
  'A module that takes the user through the booking of a test drive process for a vehicle';
export const icon = Icon;

/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../../theme';
import ContentWrapper from '../../components/ContentWrapper';
import {
  HeadingTwo,
  Paragraph,
  fontStyleOverride,
} from '../../components/Global';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.max.large`
  flex-direction: column;
  `};
`;

const DescriptionContainer = styled.div`
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
  width: ${({ width }) => width};
  ${({ borderLeft }) => `${borderLeft && `border-left: ${borderLeft}`}`};
  ${media.max.large`
    text-align: center;
    width: 100%;
  `};
`;

const ImageContainer = styled.div`
  display: block;
  min-height: 1px;
  padding: 16px 16px 0 16px;
  ${media.max.large`
  padding: 0;
  `};
`;

const ParagraphContainer = styled.div`
  display: flex;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
`;

const GridItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 1px;
  width: ${({ width }) => width};
  ${({ borderLeft }) => `${borderLeft && `border-left: ${borderLeft}`}`};
  &:last-child {
    ${({ borderLeft }) => `${borderLeft && 'border-left: 0'}`};
  }
  ${media.max.large`
    width: 100%;
  `};
`;

const ImageItemContainer = styled.img`
  display: flex;
  width: 100%;
`;

type Item = {
  image: { label: string, value: string },
  translations: {
    body: string,
  },
};

export interface Config {
  items: Item[];
}

type Props = {
  config: Config,
  globalStyling: Object,
};

export default function TextWithImageGrid({ config, globalStyling }: Props) {
  return (
    <ContentWrapper
      colour={config.gridBackgroundColour}
      contentWidth={globalStyling.contentWidth}
    >
      <Container>
        <DescriptionContainer
          width={`${100 / (config.items.length + 1)}%`}
          borderLeft={config.column && config.column.borderLeft}
        >
          {config.translations.headlineText && (
            <HeadingTwo
              styleOverride={() => `
              ${fontStyleOverride(config.headlineFont)}`}
            >
              {config.translations.headlineText}
            </HeadingTwo>
          )}
          {config.translations.subHeadlineText && (
            <Paragraph
              styleOverride={() => `
              ${fontStyleOverride(config.descriptionFont)}`}
            >
              {config.translations.subHeadlineText}
            </Paragraph>
          )}
        </DescriptionContainer>
        {config.items.map((item, index) => [
          <GridItemWrapper
            key={`textWithImageGrid-${index.toString()}`}
            width={`${100 / (config.items.length + 1)}%`}
            borderLeft={config.column && config.column.borderLeft}
          >
            <ImageContainer>
              <ImageItemContainer src={item.image.value} />
            </ImageContainer>
            {item.translations.body && (
              <ParagraphContainer>
                <Paragraph
                  styleOverride={() => `
                    ${fontStyleOverride(config.descriptionFont)}
                    width: 100%;
                  `}
                >
                  {item.translations.body}
                </Paragraph>
              </ParagraphContainer>
            )}
          </GridItemWrapper>,
        ])}
      </Container>
    </ContentWrapper>
  );
}

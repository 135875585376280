// @flow
import React from 'react';
import styled from 'styled-components';
import { Button, Dropdown } from 'cms-ui';
import type { Theme } from '../theme';
import type { User, Role } from '../state/types/users';

type Props = {
  roles: Role[],
  selectedUserRole: Role,
  onSelectedUserRoleChanged: Role => void,
  addUserIfValid: User => void,
  emailAddress: string,
  onEmailAddressChanged: string => void,
  error: string,
};

type ThemeProps = { theme: Theme, error: string };

const Container = styled.div.withConfig({ displayName: 'AddUser' })`
  min-width: 800px;
  font-family: ${({ theme }: ThemeProps) => `${theme.fontFamily}`};
  display: flex;
  border: 1px solid ${({ theme }: ThemeProps) => `${theme.colours.secondary02}`};
  background-color: ${({ theme }: ThemeProps) => `${theme.colours.primary03}`};
  padding: ${({ theme }: ThemeProps) => `${theme.layout.standardPadding}`} 0px;
  height: 113px;
`;

const ColumnContainer = styled.div.withConfig({
  displayName: 'ColumnContainer',
})`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: ${({ theme }: ThemeProps) => `${theme.layout.standardPadding}`};
`;

const EmailContainer = styled(ColumnContainer).withConfig({
  displayName: 'EmailContainer',
})`
  width: 300px;
`;

const ReverseColumnContainer = styled(ColumnContainer).withConfig({
  displayName: 'ReverseColumnContainer',
})`
  flex-direction: column-reverse;
  width: 140px;
  margin-bottom: 16px;
`;

const Label = styled.span`
  color: ${({ theme }: ThemeProps) => `${theme.colours.highlight01}`};
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeFormControls}`};
  font-weight: 600;
  padding-bottom: ${({ theme }: ThemeProps) => `${theme.layout.halfPadding}`};
`;

const FailureMessage = styled.span`
  font-size: ${({ theme }: ThemeProps) => `${theme.fontSizeFormControls}`};
  color: ${({ theme }: ThemeProps) => `${theme.colours.danger01}`};
  margin-top: 4px;
`;

const StyledInput = styled.input.withConfig({
  displayName: 'StyledInput',
})`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  box-sizing: border-box;
  max-height: 40px;
  max-width: 280px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, error }: ThemeProps) =>
    error ? theme.colours.danger01 : theme.colours.secondary02};
  padding: ${({ theme }: ThemeProps) => `${theme.layout.halfPadding}`};
  &:focus {
    border-color: ${({ theme }: ThemeProps) => theme.colours.primary01};
    outline: none;
    appearance: none;
  }
`;

export default function AddUser({
  roles,
  addUserIfValid,
  emailAddress,
  onEmailAddressChanged,
  selectedUserRole,
  onSelectedUserRoleChanged,
  error,
}: Props) {
  return (
    <Container>
      <EmailContainer>
        <Label>Email address</Label>
        <StyledInput
          placeholder="email"
          value={emailAddress}
          onChange={event => onEmailAddressChanged(event.target.value)}
          inputType="text"
          error={error}
        />
        {error && <FailureMessage>{error}</FailureMessage>}
      </EmailContainer>
      <ColumnContainer>
        <Label>Role</Label>
        <Dropdown
          input={{
            onChange: onSelectedUserRoleChanged,
            value: selectedUserRole,
          }}
          size={36}
          options={roles}
          valueKey="id"
          valueRenderer={r => r.name}
          optionRenderer={r => r.name}
          themeStyle="light"
        />
      </ColumnContainer>
      <ReverseColumnContainer>
        <Button
          type="button"
          primary
          onClick={() =>
            addUserIfValid({
              email: emailAddress,
              role: selectedUserRole,
            })
          }
          disabled={!(selectedUserRole && emailAddress)}
        >
          ADD USER
        </Button>
      </ReverseColumnContainer>
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import { pods } from 'cms-modules';

const { HeroCarousel } = pods.default;

const PreviewPodWrapper = styled.div`
  position: relative;
  width: 100%;

  && > * > * > :nth-child(-n + ${({ noOfSlides }) => noOfSlides}) {
    pointer-events: none;
  }
`;

export default (props: Props) => {
  return (
    <PreviewPodWrapper noOfSlides={props.config.items.length}>
      <HeroCarousel {...props} />
    </PreviewPodWrapper>
  );
};

// @flow
import React from 'react';
import IconWrapper from '../../IconWrapper';

export default function Print({
  background,
  colour,
  width,
  height,
}: IconProps) {
  return (
    <IconWrapper
      background={background}
      colour={colour}
      width={width}
      height={height}
    >
      <svg width="1em" height="1em" viewBox="0 0 24 20">
        <path d="M17.333 5.217H22v10.435h-4.667V20H5.667v-4.348H1V5.217h4.667V0h11.666v5.217zm-.777 0V.87H6.444v4.347h10.112zM6.444 12.174v6.956h10.112v-6.956H6.444z" />
        <path d="M7 13h8.556v.875H7V13zm0 1.75h8.556v.875H7v-.875zm0 1.75h8.556v.875H7V16.5z" />
      </svg>
    </IconWrapper>
  );
}

Print.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
};

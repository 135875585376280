import { config } from 'cms-modules';

const {
  Config,
  Slice,
  Section,
  Number,
  TextArea,
  Text,
  Colour,
  Image,
} = config;

const editor = Config(
  'Vehicle Tile',
  { id: 'VehicleTile' },
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      Number('Center Point - Latitude', 'defaultCenterPoint.lat', {
        min: -90,
        max: 90,
        step: 0.001,
      }),
      Number('Center Point - Longitude', 'defaultCenterPoint.lng', {
        min: -90,
        max: 90,
        step: 0.001,
      }),
      Number('Default Zoom Level', 'defaultZoomLevel'),
      TextArea('Description', 'description'),
      Text('Input Placeholder', 'inputPlaceholder'),
      Text('Phone', 'phoneLabel'),
      Text('No Dealers', 'noDealers'),
      Text('Button Text', 'buttonText'),
      Image('Pin Icon (unselected)', {
        image: 'pinIcon',
      }),
      Image('Pin Icon (selected)', {
        image: 'selectedPinIcon',
      }),
      Image('Cluster Icon', {
        image: 'clusterIcon',
      }),
      Image('Location Icon', {
        image: 'locationIcon',
      }),
      Colour('Cluster Font Colour', 'clusterFontColour', {
        hideOpacity: false,
      }),
    ),
  ),
);

export default editor;

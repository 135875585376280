// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../../theme';
import { HeadingOne, Paragraph } from '../Global';

const Container = styled.div.withConfig({
  displayName: 'PerfContainer',
})`
  width: 100%;
  display: block;
  ${media.min.large`
    display: flex;
    padding: 0 5%;
    width: 90%;
    margin-bottom: 100px;
  `};
`;
const InfoBox = styled.div`
  width: 100%;
  display: block;
  color: #444;
  font-size: 18px;
  ${media.min.large`
    width: 30%;
  `};
`;

const Engines = styled.div`
  width: 100%;
  display: block;
  ${media.min.large`
  width: 70%;
  display: flex;
  flex-flow: row wrap;
`};
`;
const Engine = styled.div`
  width: 100%;
  margin-bottom: 20px;
  ${media.min.large`
    max-width: 33.3%;
    flex: 0 1 33.3%;
    border: 5px solid transparent;
    box-sizing: border-box;
    margin: 0;
  `};
`;
const EngineImage = styled.div`
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${props => `${props.url && `url(${props.url})`}`};
  width: 100%;
  ${media.min.small`
    min-height: 200px;
  `};
  ${media.min.medium`
    min-height: 300px;
  `};
  ${media.min.large`
    min-height: 250px;
  `};
  margin-bottom: 24px;
`;

type Props = {
  performance: Object,
  translations: Object,
};
export default function Performance({ performance, translations }: Props) {
  return (
    <Container>
      <InfoBox>
        <Paragraph
          styleOverride={() => `
            margin-right: 25px;
          `}
        >
          {translations.performanceContent}
        </Paragraph>
      </InfoBox>
      <Engines>
        {performance.engines.map((e, i) => (
          <Engine key={e.name}>
            <EngineImage url={e.imageUrl} />
            <HeadingOne
              styleOverride={() => `
                margin-bottom: 15px;
                text-transform: uppercase;
                font-size: 15px;
              `}
            >
              {translations[`performanceEngine${i + 1}Name`]}
            </HeadingOne>
            <Paragraph
              styleOverride={() => `
                font-size: 13px;
              `}
            >
              {translations[`performanceEngine${i + 1}Description`]}
            </Paragraph>
          </Engine>
        ))}
      </Engines>
    </Container>
  );
}

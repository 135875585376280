// @flow
import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  box-sizing: border-box;
`;

const Image = styled.img`
  ${({ height }) => height && `height: ${height}px`};
  margin: 5px;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? 1.0 : 0.4)};
`;

type Props = {
  height: number,
  width: number,
  imageUrls: string[],
  onImageClick: (idx: number) => void,
  selectedImage: number,
  arrowRight: string,
  arrowLeft: string,
};

export default ({ height, imageUrls, onImageClick, selectedImage }: Props) => (
  <ImageContainer>
    {imageUrls.map((image, idx) => (
      <Image
        key={image}
        src={image}
        height={height}
        onClick={() => onImageClick(idx)}
        selected={selectedImage === idx}
      />
    ))}
  </ImageContainer>
);

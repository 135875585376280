// @flow

import React from 'react';
import styled from 'styled-components';

import media from '../theme';
import { SeparatedListContainer, SeparatedList, SeparatedListItem } from '.';

type Props = {
  items: {
    key: string,
    value: string,
  }[],
};

const ListColumn = styled.div.withConfig({
  displayName: 'ListColumn',
})`
  width: 50%;
`;

const StyledListItem = styled(SeparatedListItem)`
  border-top: 1px solid #dedede;
  padding: 10px 0;
  line-height: 24px;
  color: #444444;
  &:last-child {
    border-bottom: 1px solid #dedede;
  }
  ${media.max.large`
    &:last-child {
        border-bottom: 0;
    }
  `};
`;
const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

function KeyValueList({ items }: Props) {
  return (
    <SeparatedListContainer>
      <SeparatedList>
        {items.map(({ key, value }) => (
          <StyledListItem key={key}>
            <ListColumn>
              <Label>{key}</Label>
            </ListColumn>
            <ListColumn>{value}</ListColumn>
          </StyledListItem>
        ))}
      </SeparatedList>
    </SeparatedListContainer>
  );
}

export default KeyValueList;

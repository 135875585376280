import { useDispatch, useSelector } from 'react-redux';
import { actions as pageActions } from '../../state/actions/page';

const useFormEditor = () => {
  const dispatch = useDispatch();
  const uiElements = useSelector(state => state.editor.branding.uiElements);
  const formId = useSelector(state => state.context.breakout.data.id);

  const formPod = useSelector(state => state.config.pods[formId]) || {};
  const updatePodField = (key, value) =>
    dispatch(pageActions.setPodField(formPod.instanceId, key, value));
  const { fields, translations } = formPod.config;

  const formFieldTypes = [
    { label: 'Radio', value: 'Radio' },
    { label: 'Text field', value: 'TextInput' },
    // { label: 'Date', value: 'Date' },
    // { label: 'Name', value: 'Name' },
    // { label: 'Time', value: 'Time' },
    // { label: 'Checkbox', value: 'Checkbox' },
    // { label: 'Linebreak', value: 'Linebreak' },
    // { label: 'TextField', value: 'TextField' },
    // { label: 'Dropdown', value: 'Dropdown' },
    // { label: 'Phone', value: 'Phone' },
    // { label: 'Multiple Choice', value: 'MultipleChoice' },
    // { label: 'Address', value: 'Address' },
    // { label: 'Vehicle', value: 'Vehicle' },
    // { label: 'Consent', value: 'Consent' },
    { label: 'Form Button', value: 'FormButton' },
    { label: 'Email', value: 'Email' },
  ];

  const inputTypes = {
    Email: 'TextInput',
    TextInput: 'TextInput',
    FormButton: 'FormButton',
  };

  return {
    dispatch,
    uiElements,
    updatePodField,
    fields,
    translations,
    formFieldTypes,
    inputTypes,
  };
};

export default useFormEditor;

// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import IoChevronDown from 'react-icons/lib/io/chevron-down';

import media from '../../theme';

import {
  HeadingTwo,
  Paragraph,
  Button,
  fontStyleOverride,
} from '../../components/Global';
import { ModalContentWrapper, Overlay } from '../../components/ModalOverlay';

import { useMarketSelector } from './useMarketSelector';

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: #f3f3f3;
  ${media.min.medium`
    width: 600px;
    padding: 12px;
  `}
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  ${media.min.medium`
    height: 265px;
    width: 275px;
  `}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 12px;
`;

const LeftSection = styled(Section)`
  justify-content: flex-start;
  flex: 1 0 auto;
`;

const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${media.min.medium`
    width: 210px;
  `}
`;

const SelectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 4px 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  background: #ffffff;
`;

const SelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  align-items: left;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  position: absolute;
  top: 27px;
  z-index: 100;
  width: 100%;
`;

const Icon = styled(IoChevronDown)`
  width: 20x;
  height: 20px;
  margin-right: 6px;
  cursor: pointer;
`;

const Centred = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const subHeaderFont = {
  transform: 'uppercase',
  fontSize: 14,
};

const selectorStyle = () => `margin: 0;
font-size: 12px;
padding: 2px;
text-transform: uppercase;
`;

const optionStyle = (hover, font) => () => `${selectorStyle()}
  border-bottom: 1px solid #d5d5d5;
  min-height: 13px;
  ${font ? `font-family: ${font}` : ''}
  ${
    hover
      ? `
      cursor: pointer;
      padding: 4px 6px;
    &:hover {
    background: #F3F3F3;;
  }`
      : `
      background: #EEEEEE;
      padding: 2px 4px;
    `
  }`;

const MarketSelector = props => {
  const {
    config: { translations, groupsTitleFont },
  } = props;

  const {
    marketSelected,
    currency,
    language,
    dropDowns,
    closeAll,
    submit,
  } = useMarketSelector(translations);

  let currentGroup;
  return (
    !marketSelected && (
      <Overlay open>
        <ModalContentWrapper>
          <ContentWrapper onClick={closeAll}>
            <ColumnWrapper>
              {dropDowns.map(dd => (
                <LeftSection key={dd.title}>
                  <HeadingTwo
                    styleOverride={() => `
                      ${fontStyleOverride(subHeaderFont)}
                      margin-bottom: 18px;
                    `}
                  >
                    {dd.heading}
                  </HeadingTwo>
                  <SelectContainer>
                    <SelectHeader onClick={dd.toggle}>
                      <Paragraph styleOverride={selectorStyle}>
                        {dd.title}
                      </Paragraph>
                      <Icon />
                    </SelectHeader>
                    {dd.open && (
                      <SelectOptions>
                        {dd.showResetOption && (
                          <Paragraph
                            onClick={dd.setter(null)}
                            styleOverride={optionStyle(true)}
                          >
                            {dd.resetLabel}
                          </Paragraph>
                        )}
                        {dd.options.map(opt => {
                          const option = (
                            <Fragment key={opt.value}>
                              {opt.group && opt.group !== currentGroup && (
                                <Paragraph
                                  styleOverride={optionStyle(
                                    false,
                                    groupsTitleFont,
                                  )}
                                  onClick={e => e.preventDefault()}
                                >
                                  {opt.group}
                                </Paragraph>
                              )}
                              <Paragraph
                                onClick={dd.setter(opt)}
                                styleOverride={optionStyle(true)}
                              >
                                {opt.label}
                              </Paragraph>
                            </Fragment>
                          );
                          currentGroup = opt.group;
                          return option;
                        })}
                      </SelectOptions>
                    )}
                  </SelectContainer>
                </LeftSection>
              ))}
            </ColumnWrapper>
            <ColumnWrapper>
              <Section>
                <Centred>
                  <HeadingTwo
                    styleOverride={() => fontStyleOverride(subHeaderFont)}
                  >
                    {translations.currencyAdvisoryHeader}
                  </HeadingTwo>
                </Centred>
              </Section>
              <Section>
                <Paragraph
                  styleOverride={() =>
                    `width: 100%;
                    font-size: 14px;
                    line-height: 18px;`
                  }
                >
                  {translations.currencyAdvisoryText}
                </Paragraph>
              </Section>
              <Section>
                <Centred>
                  <Button
                    text={translations.confirm}
                    disabled={!currency || !language}
                    onClick={submit}
                    styleOverride={() => 'width: 150px;'}
                  />
                </Centred>
              </Section>
            </ColumnWrapper>
          </ContentWrapper>
        </ModalContentWrapper>
      </Overlay>
    )
  );
};

export default MarketSelector;

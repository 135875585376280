import { takeEvery, takeLatest, put, select, call } from 'redux-saga/effects';
import { get, localeConfigSerializer } from '../../helpers/http';
import { constants, actions, getVehicles as compareVehicles } from './reducer';
import { fetchVehicleStatuses, mapStatus } from '../vehicleStatus';
import { localiseNumber } from '../localisation/numbers';
import { convertVehiclesPriceCurrency } from '../currencyConversion/helpers';
import {
  getAVLConfig,
  inventorySearchServiceUrl,
  appendLocaleQueryString,
  getImageSize,
  getInventoryStatus,
} from '../selectors/settings';
import { actions as errorActions } from '../errors';

function* toggle(action) {
  const { locale } = yield select(getAVLConfig);
  yield put(
    actions.toggleVehicleSuccess({ vehicle: { ...action.payload, locale } }),
  );
}

function* checkVehicleStatus() {
  try {
    const { locale, make, market, region } = yield select(getAVLConfig);

    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const imageSize = yield select(getImageSize);
    const { paramsSerializer, params } = localeConfigSerializer(locale);

    let vehicles = yield select(state => compareVehicles(state.shared));
    const vehicleIds = vehicles.map(v => v.id);
    const inventoryStatus = yield select(getInventoryStatus);

    if (vehicles.length > 0) {
      const vehicleResponse = yield call(get, {
        url: region
          ? `${yield select(
              inventorySearchServiceUrl,
            )}vehicles/make/${make}/region/${region}`
          : `${yield select(
              inventorySearchServiceUrl,
            )}inventory/make/${make}/market/${market}`,
        config: {
          paramsSerializer,
          params: {
            ids: vehicleIds,
            ...(withLocaleQueryString && params),
            imageSize,
            status: inventoryStatus,
          },
        },
      });

      const vehicleStatuses = yield fetchVehicleStatuses(vehicleIds);
      vehicles = vehicleResponse.data.contents.map(v => ({
        ...v,
        odometer: {
          ...v.odometer,
          display: `${localiseNumber(v.odometer.reading, locale)} ${
            v.odometer.units
          }`,
        },
        status: mapStatus(v.id, vehicleStatuses),
      }));

      const currency = yield select(
        state => state.shared.sessionPreferences.currency,
      );
      const exchange = yield select(
        state => state.shared.currencyConversion.exchangeRates[currency],
      );

      if (currency && exchange) {
        const baseRates = exchange.rates;
        vehicles = convertVehiclesPriceCurrency(vehicles, baseRates, currency);
      }
    }

    yield put(
      actions.checkVehicleStatusSuccess({
        vehicles,
      }),
    );
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* getEquipmentList() {
  try {
    const { locale, make, market, region } = yield select(getAVLConfig);
    const withLocaleQueryString = yield select(appendLocaleQueryString);
    const { paramsSerializer, params } = localeConfigSerializer(locale);

    const equipmentList = yield call(get, {
      url: region
        ? `${yield select(
            inventorySearchServiceUrl,
          )}vehicles/make/${make}/region/${region}/equipment`
        : `${yield select(
            inventorySearchServiceUrl,
          )}vehicles/make/${make}/market/${market}/equipment`,
      config: {
        paramsSerializer,
        params: {
          ...(withLocaleQueryString && params),
        },
      },
    });

    yield put(actions.getEquipmentListSuccess(equipmentList.data.contents));
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

export default function* compareSaga() {
  yield takeEvery(constants.TOGGLE_VEHICLE, toggle);
  yield takeEvery(constants.CHECK_VEHICLE_STATUS, checkVehicleStatus);
  yield takeLatest(constants.GET_EQUIPMENT_LIST, getEquipmentList);
}

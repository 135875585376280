// @flow
/* eslint-env browser */
import React, { Fragment } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import Button from './Button';

type ButtonProps = {
  children?: Node,
  fullWidth?: boolean,
  disabled?: boolean,
  label?: boolean,
  link?: boolean,
  primary?: boolean,
  danger?: boolean,
  small?: boolean,
  textTransform: string,
};

type Props = {
  buttonText: string,
  renderModalContents: Function => Node, // pass close function out
  buttonProps: ButtonProps,
  icon?: string,
};

const IconButton = styled.input`
  margin: 4px;
  width: 14px;
  height: 14px;
`;

const position = (x: number, y: number) => ({
  x,
  y,
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
});

export default class ButtonWithConfirm extends React.Component<Props, any> {
  state = {
    showConfirm: false,
    position: position(0, 0),
  };

  showConfirmDialogueAtPosition = ({
    clientX,
    clientY,
  }: {
    clientX: number,
    clientY: number,
  }) => {
    this.setState({
      position: position(clientX, clientY),
      showConfirm: true,
    });
  };

  hideConfirmDialogue = e => {
    e.stopPropagation();
    this.setState({
      showConfirm: false,
    });
  };

  render() {
    const { buttonText, renderModalContents, buttonProps, icon } = this.props;

    return (
      <Fragment>
        {icon ? (
          <IconButton
            {...buttonProps}
            src={icon}
            onClick={e => {
              e.stopPropagation();
              this.showConfirmDialogueAtPosition(e);
            }}
            type="image"
          />
        ) : (
          <Button {...buttonProps} onClick={this.showConfirmDialogueAtPosition}>
            {buttonText}
          </Button>
        )}
        {this.state.showConfirm ? (
          <Modal
            position={this.state.position}
            overlay
            onClickOutside={this.hideConfirmDialogue}
          >
            {renderModalContents(this.hideConfirmDialogue)}
          </Modal>
        ) : null}
      </Fragment>
    );
  }
}

ButtonWithConfirm.defaultProps = {
  buttonProps: {
    small: true,
    fullWidth: true,
    danger: true,
    textTransform: 'uppercase',
  },
};

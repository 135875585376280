export const constants = {
  GET_LEADS_TOKEN: 'GET_LEADS_TOKEN',
  GET_LEADS_TOKEN_SUCCESS: 'GET_LEADS_TOKEN_SUCCESS',
  GET_LEADS_TOKEN_FAILURE: 'GET_LEADS_TOKEN_FAILURE',
};

export const actions = {
  getLeadsToken: () => ({ type: constants.GET_LEADS_TOKEN }),
  getLeadsTokenSuccess: token => ({
    type: constants.GET_LEADS_TOKEN_SUCCESS,
    payload: token,
  }),
  getLeadsTokenFailure: () => ({ type: constants.GET_LEADS_TOKEN_FAILURE }),
};

const initialState = {
  idToken: null,
  expiryDateTime: null,
};

export function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case constants.GET_LEADS_TOKEN_SUCCESS:
      return {
        ...state,
        idToken: payload.idToken,
        expiryDateTime: payload.expiryDateTime,
      };
    case constants.GET_LEADS_TOKEN_FAILURE:
      return {
        ...state,
        idToken: null,
        expiryDateTime: null,
      };
    default:
      return state;
  }
}

export const getToken = state => (state.leadsAuthentication || {}).idToken;

export const selectors = {
  getToken,
};

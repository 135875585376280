// @flow
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import fallbackFonts from './Global/fallbackFonts';

const GlobalStyle = createGlobalStyle`
  ${({ styles: { direction } }) =>
    direction && direction === 'rtl'
      ? `body {
    direction: rtl;
  }
  `
      : `body {
    direction: ltr;
  }
  `}
  h1.global {
    ${({ styles }) =>
      `font-family: ${
        styles.fonts.primaryFont ? `${styles.fonts.primaryFont.value},` : ''
      }${fallbackFonts}`};
    ${({ styles }) =>
      styles.colours.primaryFontColour &&
      `color:  ${styles.colours.primaryFontColour.value}`};
    font-weight: normal;
  }
  h2.global {
    ${({ styles }) =>
      `font-family: ${(styles.fonts.secondaryFont &&
        `${styles.fonts.secondaryFont.value},`) ||
        (styles.fonts.primaryFont
          ? `${styles.fonts.primaryFont.value},`
          : '')}${fallbackFonts}`};
    ${({ styles }) =>
      (styles.colours.secondaryFontColour &&
        `color:  ${styles.colours.secondaryFontColour.value}`) ||
      (styles.colours.primaryFontColour &&
        `color:  ${styles.colours.primaryFontColour.value}`)};
    font-weight: normal;
    margin: 0;
  }
  h3.global {
    ${({ styles }) =>
      `font-family: ${(styles.fonts.highlightedLabelsFont &&
        `${styles.fonts.highlightedLabelsFont.value},`) ||
        (styles.fonts.paragraphFont &&
          `${styles.fonts.paragraphFont.value},`) ||
        (styles.fonts.secondaryFont &&
          `${styles.fonts.secondaryFont.value},`) ||
        (styles.fonts.primaryFont
          ? `${styles.fonts.primaryFont.value},`
          : '')}${fallbackFonts}`};
    ${({ styles }) =>
      (styles.colours.highlightedLabelsColour &&
        `color:  ${styles.colours.highlightedLabelsColour.value}`) ||
      (styles.colours.paragraphFontColour &&
        `color:  ${styles.colours.paragraphFontColour.value}`) ||
      (styles.colours.secondaryFontColour &&
        `color:  ${styles.colours.secondaryFontColour.value}`) ||
      (styles.colours.primaryFontColour &&
        `color:  ${styles.colours.primaryFontColour.value}`)};
    font-weight: normal;
    margin: 0;
  }
  a.global {
    ${({ styles }) =>
      `font-family: ${(styles.fonts.paragraphFont &&
        styles.fonts.paragraphFont.value) ||
        (styles.fonts.secondaryFont && styles.fonts.secondaryFont.value) ||
        (styles.fonts.primaryFont && styles.fonts.primaryFont.value)}`};
    ${({ styles }) =>
      (styles.colours.paragraphFontColour &&
        `color:  ${styles.colours.paragraphFontColour.value}`) ||
      (styles.colours.secondaryFontColour &&
        `color:  ${styles.colours.secondaryFontColour.value}`) ||
      (styles.colours.primaryFontColour &&
        `color:  ${styles.colours.primaryFontColour.value}`)};
    cursor: pointer;
  }
  p.global {
    ${({ styles }) =>
      `font-family: ${(styles.fonts.paragraphFont &&
        `${styles.fonts.paragraphFont.value},`) ||
        (styles.fonts.secondaryFont &&
          `${styles.fonts.secondaryFont.value},`) ||
        (styles.fonts.primaryFont
          ? `${styles.fonts.primaryFont.value},`
          : '')}${fallbackFonts}`};
    ${({ styles }) =>
      (styles.colours.paragraphFontColour &&
        `color:  ${styles.colours.paragraphFontColour.value}`) ||
      (styles.colours.secondaryFontColour &&
        `color:  ${styles.colours.secondaryFontColour.value}`) ||
      (styles.colours.primaryFontColour &&
        `color:  ${styles.colours.primaryFontColour.value}`)};
    ${({ styles }) =>
      styles.fonts.paragraphFont &&
      styles.fonts.paragraphFont.lineHeight &&
      `line-height:  ${styles.fonts.paragraphFont.lineHeight}px`};
  }
  button.global {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
    min-height: 30px;
    font-size: 13px;
    border: none;
    margin: 0;
    &:focus {
      outline: none;
    }
  }
  button.global.primary {
    ${({ styles }) =>
      styles.fonts.primaryButtonFont &&
      `font-family: ${styles.fonts.primaryButtonFont.value},${fallbackFonts};`}
    ${({ styles }) =>
      styles.uiElements.primaryButton.primaryButtonCasing &&
      `text-transform: ${styles.uiElements.primaryButton.primaryButtonCasing}`};
    ${({ styles }) =>
      styles.uiElements.primaryButton.primaryButtonHeight &&
      `height: ${styles.uiElements.primaryButton.primaryButtonHeight}px`};
    ${({ styles }) =>
      styles.colours.primaryBorderColour &&
      `border: 1px solid ${styles.colours.primaryBorderColour.value}`};
    ${({ styles }) =>
      styles.colours.primaryBackgroundColour &&
      `background-color: ${styles.colours.primaryBackgroundColour.value}`};
    ${({ styles }) =>
      styles.colours.primaryForegroundColour &&
      `color: ${styles.colours.primaryForegroundColour.value}`};
    svg > rect:not([fill='none']) {
      ${({ styles }) =>
        styles.colours.primaryForegroundColour &&
        `fill: ${styles.colours.primaryForegroundColour.value}`};
    }
    svg > path:not([fill]) {
      ${({ styles }) =>
        styles.colours.primaryForegroundColour &&
        `fill: ${styles.colours.primaryForegroundColour.value}`};
    }
    svg {
      ${({ styles }) =>
        styles.colours.primaryBackgroundColour &&
        `stroke: ${styles.colours.primaryBackgroundColour.value}`};
      ${({ styles }) =>
        styles.direction === 'rtl' && 'transform:  scaleX(-1);'};
    }

    &:hover {
      ${({ styles }) =>
        styles.colours.primaryBorderHoverColour &&
        `border: 1px solid ${styles.colours.primaryBorderHoverColour.value}`};
      ${({ styles }) =>
        styles.colours.primaryBackgroundHoverColour &&
        `background-color: ${
          styles.colours.primaryBackgroundHoverColour.value
        }`};
      ${({ styles }) =>
        styles.colours.primaryForegroundHoverColour &&
        `color: ${styles.colours.primaryForegroundHoverColour.value}`};
      svg > rect:not([fill='none']) {
        ${({ styles }) =>
          styles.colours.primaryForegroundHoverColour &&
          `fill: ${styles.colours.primaryForegroundHoverColour.value}`};
      }
      svg > path:not([fill]) {
        ${({ styles }) =>
          styles.colours.primaryForegroundHoverColour &&
          `fill: ${styles.colours.primaryForegroundHoverColour.value}`};
      }
    }
  }
  button.global.secondary {
    ${({ styles }) =>
      styles.fonts.secondaryButtonFont &&
      `font-family: ${
        styles.fonts.secondaryButtonFont.value
      },${fallbackFonts};`}
    ${({ styles }) =>
      styles.uiElements.secondaryButton.secondaryButtonCasing &&
      `text-transform: ${
        styles.uiElements.secondaryButton.secondaryButtonCasing
      }`};
    ${({ styles }) =>
      styles.uiElements.secondaryButton.secondaryButtonHeight &&
      `height: ${styles.uiElements.secondaryButton.secondaryButtonHeight}px`};
    ${({ styles }) =>
      styles.colours.secondaryBorderColour &&
      `border: ${styles.uiElements.secondaryButton.borderSize || 1}px solid ${
        styles.colours.secondaryBorderColour.value
      }`};
    ${({ styles }) =>
      styles.colours.secondaryBackgroundColour &&
      `background-color: ${styles.colours.secondaryBackgroundColour.value}`};
    ${({ styles }) =>
      styles.colours.secondaryForegroundColour &&
      `color: ${styles.colours.secondaryForegroundColour.value}`};
    &:hover {
      ${({ styles }) =>
        styles.colours.secondaryBorderHoverColour &&
        `border: ${styles.uiElements.secondaryButton.borderSize || 1}px solid ${
          styles.colours.secondaryBorderHoverColour.value
        }`};
      ${({ styles }) =>
        styles.colours.secondaryBackgroundHoverColour &&
        `background-color: ${
          styles.colours.secondaryBackgroundHoverColour.value
        }`};
      ${({ styles }) =>
        styles.colours.secondaryForegroundHoverColour &&
        `color: ${styles.colours.secondaryForegroundHoverColour.value}`};
      svg > rect:not([fill='none']) {
        ${({ styles }) =>
          styles.colours.secondaryForegroundHoverColour &&
          `fill: ${styles.colours.secondaryForegroundHoverColour.value}`};
      }
      svg > path:not([fill]) {
        ${({ styles }) =>
          styles.colours.secondaryForegroundHoverColour &&
          `fill: ${styles.colours.secondaryForegroundHoverColour.value}`};
      }
    }
    svg > rect:not([fill='none']) {
      ${({ styles }) =>
        styles.colours.secondaryForegroundColour &&
        `fill: ${styles.colours.secondaryForegroundColour.value}`};
    }
    svg > path:not([fill]) {
      ${({ styles }) =>
        styles.colours.secondaryForegroundColour &&
        `fill: ${styles.colours.secondaryForegroundColour.value}`};
    }
    svg {
      ${({ styles }) =>
        styles.colours.secondaryBackgroundColour &&
        `stroke: ${styles.colours.secondaryBackgroundColour.value}`};
      ${({ styles }) =>
        styles.direction === 'rtl' && 'transform:  scaleX(-1);'};
    }
  }
  button.global.tertiary {
    ${({ styles }) =>
      styles.fonts.tertiaryButtonFont &&
      `font-family: ${styles.fonts.tertiaryButtonFont.value},${fallbackFonts};`}
    ${({ styles }) =>
      styles.uiElements.tertiaryButton.tertiaryButtonCasing &&
      `text-transform: ${
        styles.uiElements.tertiaryButton.tertiaryButtonCasing
      }`};
    ${({ styles }) =>
      styles.uiElements.tertiaryButton.tertiaryButtonHeight &&
      `height: ${styles.uiElements.tertiaryButton.tertiaryButtonHeight}px`};
    ${({ styles }) =>
      styles.colours.tertiaryBorderColour &&
      `border: 1px solid ${styles.colours.tertiaryBorderColour.value}`};
    ${({ styles }) =>
      styles.colours.tertiaryBackgroundColour &&
      `background-color: ${styles.colours.tertiaryBackgroundColour.value}`};
    ${({ styles }) =>
      styles.colours.tertiaryForegroundColour &&
      `color: ${styles.colours.tertiaryForegroundColour.value}`};
    &:hover {
      ${({ styles }) =>
        styles.colours.tertiaryBorderHoverColour &&
        `border: 1px solid ${styles.colours.tertiaryBorderHoverColour.value}`};
      ${({ styles }) =>
        styles.colours.tertiaryBackgroundHoverColour &&
        `background-color: ${
          styles.colours.tertiaryBackgroundHoverColour.value
        }`};
      ${({ styles }) =>
        styles.colours.tertiaryForegroundHoverColour &&
        `color: ${styles.colours.tertiaryForegroundHoverColour.value}`};
    svg > rect:not([fill='none']) {
      ${({ styles }) =>
        styles.colours.tertiaryForegroundHoverColour &&
        `fill:  ${styles.colours.tertiaryForegroundHoverColour.value}`};
    }
    svg > path:not([fill]) {
      ${({ styles }) =>
        styles.colours.tertiaryForegroundHoverColour &&
        `fill:  ${styles.colours.tertiaryForegroundHoverColour.value}`};
    }
    }
    svg > rect:not([fill='none']) {
      ${({ styles }) =>
        styles.colours.tertiaryForegroundColour &&
        `fill:  ${styles.colours.tertiaryForegroundColour.value}`};
    }
    svg > path:not([fill]) {
      ${({ styles }) =>
        styles.colours.tertiaryForegroundColour &&
        `fill:  ${styles.colours.tertiaryForegroundColour.value}`};
    }
  }
  button.global.disabled {
    border: none;
    cursor: not-allowed;
    ${({ styles }) =>
      styles.colours.disabledBackgroundColour &&
      `background-color: ${styles.colours.disabledBackgroundColour.value}`};
    ${({ styles }) =>
      styles.colours.disabledForegroundColour &&
      `color: ${styles.colours.disabledForegroundColour.value}`};
    svg > rect:not([fill='none']) {
      ${({ styles }) =>
        styles.colours.disabledForegroundColour &&
        `fill: ${styles.colours.disabledForegroundColour.value}`};
    }
    svg > path:not([fill]) {
      ${({ styles }) =>
        styles.colours.disabledForegroundColour &&
        `fill: ${styles.colours.disabledForegroundColour.value}`};
    }
    svg {
      ${({ styles }) =>
        styles.colours.disabledForegroundColour &&
        `stroke: ${styles.colours.disabledForegroundColour.value}`};
      ${({ styles }) =>
        styles.direction === 'rtl' && 'transform:  scaleX(-1);'};
    }
    &:hover {
      border: none;
      ${({ styles }) =>
        styles.colours.disabledBackgroundColour &&
        `background-color: ${styles.colours.disabledBackgroundColour.value}`};
      ${({ styles }) =>
        styles.colours.disabledForegroundColour &&
        `color: ${styles.colours.disabledForegroundColour.value}`};
      ${({ styles }) =>
        styles.colours.disabledForegroundColour &&
        `color: ${styles.colours.disabledForegroundColour.value}`};
      svg > rect:not([fill='none']) {
        ${({ styles }) =>
          styles.colours.disabledForegroundColour &&
          `fill: ${styles.colours.disabledForegroundColour.value}`};
      }
      svg > path:not([fill]) {
        ${({ styles }) =>
          styles.colours.disabledForegroundColour &&
          `fill: ${styles.colours.disabledForegroundColour.value}`};
      }
      svg {
        ${({ styles }) =>
          styles.colours.disabledForegroundColour &&
          `stroke: ${styles.colours.disabledForegroundColour.value}`};
      }
    }
  }

  div.content {
    ${({ styles }) =>
      styles.colours.pageBackgroundColour &&
      `background-color: ${styles.colours.pageBackgroundColour.value}`};
        ${({ styles }) =>
          `font-family: ${(styles.fonts.paragraphFont &&
            `${styles.fonts.paragraphFont.value},`) ||
            (styles.fonts.secondaryFont &&
              `${styles.fonts.secondaryFont.value},`) ||
            (styles.fonts.primaryFont &&
              `${styles.fonts.primaryFont.value},`)}${fallbackFonts}`};
  }

  select.global {
    margin: 0;
    box-sizing: border-box;
    outline: none;
    ${({ styles }) =>
      `font-family: ${(styles.fonts.secondaryFont &&
        styles.fonts.secondaryFont.value) ||
        (styles.fonts.primaryFont && styles.fonts.primaryFont.value)}`};

    ${({
      styles: {
        uiElements: {
          select: {
            appearance = 'none',
            font: {
              fontSize = '14px',
              colour = { value: '#444444' },
              padding = { left: '15px', right: '15px' },
              lineHeight = '40px',
            } = {},
            width = '100%',
            height = '40px',
            backgroundColour = { value: '#ffffff' },
            backgroundImageURL,
            backgroundSize = '1em auto, 100%',
          } = {},
        },
      },
    }) => {
      return [
        `appearance: ${appearance};`,
        `line-height: ${lineHeight};`,
        `font-size: ${fontSize};`,
        `color: ${colour.value};`,
        `width: ${width};`,
        `height: ${height};`,
        `background-color:  ${backgroundColour.value};`,
        `background-image: url('${backgroundImageURL ||
          'https://res.cloudinary.com/motortrak/image/upload/v1556641757/cms/lamborghini/content/chevron-active.svg'}');`,
        `background-position: right .7em top 50%, 0 0;`,
        `background-size: ${backgroundSize};`,
        `background-repeat: no-repeat;`,
        padding &&
          Object.keys(padding)
            .map(k => `padding-${k}: ${padding[k]};`)
            .filter(Boolean),
      ];
    }}
`;

type Props = {
  globalStyling: Object,
};
export default function GlobalStyling(props: Props) {
  return <GlobalStyle styles={props.globalStyling} />;
}

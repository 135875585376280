// @flow
import React, { Component } from 'react';
import querystring from 'querystring';

import SearchResultsComponent from '../../components/VehicleSearchResults/SearchResults';
import { actions as routerActions } from '../../actions/router';
import { screenSizeOptions } from '../../theme';
import { actions as compareActions } from '../../shared/compare';
import { getVehicles as compareVehicles } from '../../shared/compare/reducer';
import { actions as shortlistActions } from '../../shared/shortlist';
import { getVehicles as shortlistVehicles } from '../../shared/shortlist/reducer';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import { sortOptions } from '../../shared/sortOptions/settings';
import type { Font, Option } from '../../types';
import { actions as filterActions } from '../../shared/filters';
import ContentWrapper from '../../components/ContentWrapper';
import applyModelVariantLogic from '../../helpers/filters';
import { vanityUrlFormatter } from '../../helpers/vehicle';

const FIRST_PAGE = 1;

export interface Config {
  translations: Object;
  subNavBGColour?: string;
  subNavFont?: Font;
  subNavBorderColour?: string;
  subNavChevronColour?: string;
  subHeadingFont?: Font;
  headingAlignment?: string;
  buttonFont?: Font;
  buttonBGColour?: Option;
  stylingConfig: Object;
  visibility: Object;
  compareIconListedColour: Object;
  shortlistIconListedColour: Object;
  handleZeroPriceAsText: boolean;
  viewBrakepoint: Number;
  vdpUrlMetaInfo: Object;
}

type Props = {
  config: Config,
  actions: {
    init: Function,
    getInventory: Function,
    clearLoaded: () => void,
    updateVehiclePrices: Function,
  },
  pathByModule: string => any,
  state: Object,
  shared: Object,
  dispatch: Function,
  history: Object,
  marketInfo: Object,
  globalStyling: Object,
  featureFlags: Object,
  linkedModules: Object,
  router: Object,
};

type State = {
  filtersExpanded: boolean,
  leasingExampleExpanded: boolean,
  selectedView: 'GRID' | 'LIST',
  viewBrakepoint: number,
};

export default class VehicleSearchResults extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedView =
      window.innerWidth < screenSizeOptions.xxl ? 'GRID' : 'LIST';

    this.state = {
      filtersExpanded: false,
      leasingExampleExpanded: false,
      selectedView,
      viewBrakepoint: props.config.viewBrakepoint,
    };
  }

  componentDidMount() {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const { actions, dispatch, history, shared, router, state } = this.props;
    const query = history.location.search.substring(1);
    const parsedQuery = querystring.parse(query);

    const newQuery = querystring.stringify(applyModelVariantLogic(parsedQuery));
    if (query !== newQuery) {
      dispatch(routerActions.navigateWithFilters(`?${newQuery}`, true));
    }

    this.setState({ query });
    actions.init();
    this.updateInventory(
      state.currentPage,
      shared.sessionPreferences.searchSort,
    );
    dispatch(filterActions.initialiseFilters(query));
    dispatch(shortlistActions.getVehicles());

    if (parsedQuery.retailerId) {
      actions.getDealer(parsedQuery.retailerId);
    }

    if (shared.sessionPreferences.currency) {
      dispatch(
        actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }

    window.addEventListener('resize', this.onViewportChange);

    const vdpMatcher = /.*\/vdp\/.*/i;
    const previousRoute = router.navigationHistory[1];
    const comeFromVdp = vdpMatcher.test(previousRoute);

    const forceUpdate = !comeFromVdp || (comeFromVdp && parsedQuery.retailerId);

    if (shared.sessionPreferences.infiniteScroll) {
      this.onShowAllResultsClick();
    } else {
      actions.cancelLoading(forceUpdate);
      window.scroll(0, 0);
    }

    if (forceUpdate) {
      actions.clearLoaded();
      this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      shared,
      state,
      actions,
      history,
      dispatch,
      marketInfo,
    } = this.props;
    const query = history.location.search.substring(1);

    if (
      ((state.searchResults[FIRST_PAGE] || state.filters) &&
        (this.state.query !== query ||
          state.filters !== query ||
          prevProps.shared.sessionPreferences.market !==
            shared.sessionPreferences.market ||
          (!state.loading && !state.searchResults[FIRST_PAGE]))) ||
      prevProps.shared.sessionPreferences.location !==
        shared.sessionPreferences.location
    ) {
      this.setState({ query });
      actions.clearLoaded();

      this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
      const retailerId =
        shared.filters.selectedFilters &&
        shared.filters.selectedFilters.retailerId;

      if (retailerId && !(state.dealer && state.dealer.id === retailerId)) {
        actions.getDealer(retailerId);
      }
    }

    if (
      prevProps.shared.currencyConversion.exchangeRates !==
      shared.currencyConversion.exchangeRates
    ) {
      dispatch(
        actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
        }),
      );
    }

    if (prevProps.marketInfo.market !== marketInfo.market) {
      this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      this.updateInventory(
        state.currentPage,
        shared.sessionPreferences.searchSort,
      );
    }
  }

  componentWillUnmount() {
    const { actions, dispatch, history } = this.props;
    const vdpMatcher = /.*\/vdp\/.*/i;
    const goingToVdp = vdpMatcher.test(history.location.pathname);

    if (!goingToVdp) {
      dispatch(
        sessionActions.updateSessionPreferences('infiniteScroll', false),
      );
    }

    actions.cancelLoading(!goingToVdp);
  }

  onViewportChange = () => {
    const viewBrakepoint = this.state.viewBrakepoint
      ? screenSizeOptions.extraLarge
      : screenSizeOptions.xxl;
    if (window.innerWidth < viewBrakepoint) {
      this.setState(() => ({ selectedView: 'GRID' }));
    }
    if (window.innerWidth > viewBrakepoint) {
      this.setState(() => ({ selectedView: 'LIST' }));
    }
  };

  onFilterMenuClick = () =>
    this.setState(prevState => ({
      filtersExpanded: !prevState.filtersExpanded,
    }));

  onShowAllResultsClick = () => {
    const { actions, marketInfo, history, state, dispatch } = this.props;
    const filters = history.location.search.substring(1);

    dispatch(sessionActions.updateSessionPreferences('infiniteScroll', true));

    actions.showAll({
      ...marketInfo,
      filters,
      pageNumber: state.currentPage,
      sortField: this.props.shared.sessionPreferences.searchSort,
    });
  };

  onPageNumberChange = (value: Number, infiniteScroll: boolean) => {
    this.updateInventory(
      value,
      this.props.shared.sessionPreferences.searchSort,
    );

    if (!infiniteScroll) {
      window.scroll(0, 0);
    }
  };

  onSortChange = (payload, sortField) => {
    this.props.dispatch(filterActions.updateFilters(payload));
    this.props.dispatch(
      sessionActions.updateSessionPreferences(
        'searchSort',
        sortOptions[sortField],
      ),
    );
  };

  updateInventory = (pageNumber: Number, sortField: Object) => {
    const { actions, marketInfo, history } = this.props;
    const filters = history.location.search.substring(1);

    actions.getInventory({
      ...marketInfo,
      filters,
      pageNumber,
      sortField,
    });
  };

  render() {
    const {
      state,
      dispatch,
      config,
      shared,
      history,
      marketInfo,
      globalStyling,
      featureFlags,
      linkedModules,
    } = this.props;

    const {
      filtersExpanded,
      selectedView,
      leasingExampleExpanded,
    } = this.state;
    const filtersConfig = Object.values(linkedModules).find(
      lm => lm.name === 'Filters',
    );

    const onMoreInfoClick = (name, vehicle) => {
      const vehicleMetaInfo = vanityUrlFormatter(
        config,
        vehicle,
        shared.sessionPreferences.language,
      );
      dispatch(routerActions.navigate(`/vdp/${vehicle.id}-${vehicleMetaInfo}`));
    };

    const sharedShortListedVehicles = shortlistVehicles(shared);
    const retailer = this.retailerId && {
      id: this.retailerId,
      ...state.dealer,
    };

    const sharedCompareVehicles = compareVehicles(shared);

    const isListed = (id: string, vehicles: Vehicle[]) =>
      vehicles.find(vehicle => vehicle.id === (id || {}));

    const optionalIconListedColour =
      (config.optionalIconListedColour &&
        config.optionalIconListedColour.value) ||
      '#ACACAC';

    const getShortListedIconColour = (id: string) =>
      isListed(id, sharedShortListedVehicles)
        ? config.shortlistIconListedColour &&
          config.shortlistIconListedColour.value
        : optionalIconListedColour;

    const getCompareIconColour = (id: string) =>
      isListed(id, sharedCompareVehicles)
        ? config.compareIconListedColour && config.compareIconListedColour.value
        : optionalIconListedColour;
    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth} flex>
        <SearchResultsComponent
          config={config}
          shared={shared}
          filtersStyling={config.stylingConfig}
          marketInfo={marketInfo}
          translations={config.translations}
          filtersExpanded={filtersExpanded}
          onFilterMenuClick={this.onFilterMenuClick}
          results={state.searchResults}
          onMoreInfoClick={onMoreInfoClick}
          onShowAllResultsClick={this.onShowAllResultsClick}
          onPageNumberChange={page =>
            this.onPageNumberChange(page, state.infiniteScroll)
          }
          sortOptions={sortOptions}
          sortValue={
            shared.sessionPreferences.searchSort &&
            shared.sessionPreferences.searchSort.value
          }
          onSortChange={this.onSortChange}
          showSortByDistance={
            shared.sessionPreferences.location &&
            shared.sessionPreferences.location.latitude &&
            shared.sessionPreferences.location.longitude
          }
          showSortByAge={
            config.visibility.sorting && config.visibility.sorting.showSortByAge
          }
          showSortByPrice={
            config.visibility.sorting &&
            config.visibility.sorting.showSortByPrice
          }
          showSortByMileage={
            config.visibility.sorting &&
            config.visibility.sorting.showSortByMileage
          }
          selectedView={selectedView}
          toggleLeasingExample={this.toggleLeasingExample}
          leasingExampleExpanded={leasingExampleExpanded}
          compareVehicle={vehicle =>
            dispatch(compareActions.toggleVehicle(vehicle))
          }
          shortlistVehicle={vehicle =>
            dispatch(shortlistActions.toggleVehicle(vehicle))
          }
          getShortListedIconColour={getShortListedIconColour}
          getCompareIconColour={getCompareIconColour}
          resetFilters={this.resetFilters}
          retailer={retailer}
          globalStyling={globalStyling}
          tilesVisibility={config.visibility.tiles}
          filterVisibility={config.visibility.filters}
          subHeadingFont={config.subHeadingFont}
          capitalizeSubHeaderCountryName={config.capitalizeSubHeaderCountryName}
          headingAlignment={config.headingAlignment}
          history={history}
          featureFlags={featureFlags}
          vehicleModelTitleFontWeight={config.vehicleModelTitleFontWeight}
          headingBottomLineDisable={config.headingBottomLineDisable}
          subHeadingAlignment={config.subHeadingAlignment}
          showGridTiles={config.showGridTiles}
          filtersConfig={filtersConfig}
          badgeStyling={config.badgeStyling}
          headerItemFont={config.headerItemFont}
          fontFamily={config.sortMenuBarFontFamily}
          gridSelected={config.gridSelected}
          gridDeselected={config.gridDeselected}
          listSelected={config.listSelected}
          listDeselected={config.listDeselected}
          optionalSelectedColour={
            config.optionalSelectedColour && config.optionalSelectedColour.value
          }
          optionalSelectedBgColour={
            config.optionalSelectedBgColour &&
            config.optionalSelectedBgColour.value
          }
          pricingSectionImage={config.pricingSectionImage}
          listViewImageWidth={config.listViewImageWidth}
          gridViewColumns={config.gridViewColumns || 3}
          resultItemBackgroundColour={config.resultItemBackgroundColour}
          moreInfoButtonRightAlign={config.moreInfoButtonRightAlign}
          tileImageMaxWidth={config.tileImageMaxWidth}
          financeCellHorizontalPadding={config.financeCellHorizontalPadding}
          showFinancePopup={config.showFinancePopup}
          activeFiltersCount={shared.filters.activeFiltersCount}
          handleZeroPriceAsText={config.handleZeroPriceAsText}
          showNumberOfImagesInsteadOfViews={
            config.showNumberOfImagesInsteadOfViews
          }
          badgeWithStripUrl={config.badgeWithStripUrl}
          badgeWithoutStripUrl={config.badgeWithoutStripUrl}
          warrantyBadgeMonths={config.warrantyBadgeMonths}
          warrantyBadgeMaxMileage={config.warrantyBadgeMaxMileage}
          optionalVehicleTitle={config.optionalVehicleTitle}
          vehicleTitleFont={config.vehicleTitleFont}
          vehicleSubTitleFont={config.vehicleSubTitleFont}
          backgroundColour={config.backgroundColour}
          financeWidthOverride={config.financeWidthOverride}
          financePaddingOverride={config.financePaddingOverride}
          financeHeadingFont={config.financeHeadingFont}
          financeSubHeadingFont={config.financeSubHeadingFont}
          actionRowStyles={config.actionRowStyles}
          vehicleSpecContainer={config.vehicleSpecContainer}
          infiniteScroll={state.infiniteScroll}
          loading={state.loading}
          currentPage={state.currentPage}
          pageSize={state.pageSize}
          totalResults={state.totalResults}
          filtersLoaded={
            shared.filters &&
            (shared.filters.selectedFilters ||
              shared.filters.availableFilterValues)
          }
          countDivMargin={config.countDivMargin}
          compareShortlistIconWidth={config.compareShortlistIconWidth}
          icon360={config.icon360}
        />
      </ContentWrapper>
    );
  }
}

import React from 'react';

const LowercaseDisabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#757575" d="M1.5 1.5h15v15h-15z" />
      <path
        d="M8.679 11.496H8.2c-.1 0-.18-.015-.237-.045-.057-.03-.1-.091-.129-.183l-.094-.314c-.112.1-.222.188-.33.264-.107.076-.218.14-.333.192a1.7 1.7 0 0 1-.366.116 2.2 2.2 0 0 1-.43.039c-.186 0-.358-.025-.516-.075a1.113 1.113 0 0 1-.407-.226 1.026 1.026 0 0 1-.264-.375A1.328 1.328 0 0 1 5 10.37c0-.167.044-.33.131-.493.088-.162.233-.308.437-.439.204-.13.475-.238.813-.325a5.747 5.747 0 0 1 1.26-.146v-.258c0-.295-.062-.514-.187-.656-.124-.142-.306-.213-.544-.213a1.317 1.317 0 0 0-.729.196l-.234.135a.476.476 0 0 1-.237.06.307.307 0 0 1-.185-.055.44.44 0 0 1-.125-.138l-.193-.34C5.714 7.232 6.327 7 7.044 7c.258 0 .488.042.69.127.202.085.374.202.514.353.14.15.248.33.32.54.074.209.11.439.11.688v2.788zm-2.066-.663c.11 0 .21-.01.302-.03.091-.02.178-.05.26-.09.082-.04.16-.09.236-.148.076-.06.153-.129.23-.21v-.743c-.31.014-.568.04-.776.08a2.064 2.064 0 0 0-.501.148.644.644 0 0 0-.269.21.467.467 0 0 0-.08.263c0 .186.056.32.166.4.11.08.255.12.432.12zm6.57.663h-.477c-.1 0-.18-.015-.237-.045-.057-.03-.1-.091-.13-.183l-.094-.314c-.112.1-.221.188-.329.264-.108.076-.219.14-.333.192a1.7 1.7 0 0 1-.366.116 2.2 2.2 0 0 1-.43.039c-.187 0-.359-.025-.517-.075a1.113 1.113 0 0 1-.406-.226 1.026 1.026 0 0 1-.265-.375 1.328 1.328 0 0 1-.094-.52c0-.167.043-.33.13-.493.088-.162.234-.308.437-.439.204-.13.475-.238.814-.325a5.747 5.747 0 0 1 1.26-.146v-.258c0-.295-.062-.514-.187-.656-.125-.142-.306-.213-.544-.213a1.317 1.317 0 0 0-.73.196c-.084.05-.162.095-.234.135a.476.476 0 0 1-.237.06.307.307 0 0 1-.185-.055.44.44 0 0 1-.124-.138l-.194-.34C10.22 7.232 10.831 7 11.548 7c.258 0 .489.042.69.127.203.085.374.202.515.353.14.15.247.33.32.54.074.209.11.439.11.688v2.788zm-2.065-.663c.109 0 .21-.01.301-.03.092-.02.179-.05.26-.09.082-.04.161-.09.237-.148.076-.06.153-.129.23-.21v-.743c-.31.014-.568.04-.776.08a2.064 2.064 0 0 0-.502.148.644.644 0 0 0-.268.21.467.467 0 0 0-.08.263c0 .186.055.32.166.4.11.08.254.12.432.12z"
        fill="#757575"
      />
    </g>
  </svg>
);

export default LowercaseDisabled;

import { config } from 'cms-modules';

const { Align, Colour, Config, Font, Slice, Section, Image, TextArea } = config;

const hintText = image =>
  !image ||
  (image && image.meta && (image.meta.width < 1200 || image.meta.height < 600))
    ? 'Recommended minimum size is 1200x600px'
    : '';

const editor = Config(
  'ImageTitle',
  { id: 'imageTitle' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section('Layout', {}, Align('Alignment', 'horizontalAlignment')),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      Image(
        'Image',
        { image: 'image', accessibilityText: 'imageLabel' },
        { hintText },
      ),
      TextArea('Heading', 'headlineText', { rows: 3 }),
      TextArea('Paragraph', 'bodyText'),
    ),
  ),
  Slice(
    'Styles',
    { id: 'styles' },
    Section(
      'Fonts',
      {},
      Font('Headline font', 'headlineFont'),
      Font('Body font', 'bodyFont'),
      Colour('Background Colour', 'backgroundColour'),
    ),
  ),
);

export default editor;

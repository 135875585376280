import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { get } from '../../helpers/http';
import { postsServiceUrl } from '../../shared/selectors/settings';

export function useBlogPost(postId) {
  const [postData, setPostData] = useState(null);
  const postsUrl = useSelector(postsServiceUrl);

  useEffect(() => {
    try {
      const getPostData = async () => {
        const url = `${postsUrl}posts/${postId}`;
        const result = await get({ url });
        setPostData(result.data.data);
      };
      getPostData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return postData;
}

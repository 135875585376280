// @flow
import React from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Handle from './Handle';

type Props = {
  onAfterChange?: (values: [number, number]) => void,
  onChange?: (values: [number, number]) => void,
  rangeValue?: {
    min: number,
    max: number,
  },
  step?: number,
  defaultValue?: number[],
  stylingConfig: Object,
};

export default function StyledRange({
  step,
  defaultValue,
  onAfterChange,
  onChange,
  rangeValue,
  stylingConfig,
}: Props) {
  return (
    <Range
      handle={Handle}
      trackStyle={[
        {
          backgroundColor:
            stylingConfig.rangeSliderColour &&
            stylingConfig.rangeSliderColour.value,
        },
      ]}
      railStyle={{ backgroundColor: '#F0F0F0' }}
      min={rangeValue.min}
      max={rangeValue.max}
      step={step}
      defaultValue={defaultValue}
      allowCross={false}
      onAfterChange={onAfterChange}
      onChange={onChange}
    />
  );
}

StyledRange.defaultProps = {
  step: 1,
  defaultValue: [],
  rangeValue: { min: 0, max: 100 },
  onAfterChange: () => {},
  onChange: () => {},
};

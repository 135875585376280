import React from 'react';

const MiddleAlignDisabled = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#757575" d="M1.5 1.5h15v15h-15z" />
      <path fill="#757575" d="M3 6h12v2H3zM6 10h6v2H6z" />
    </g>
  </svg>
);

export default MiddleAlignDisabled;

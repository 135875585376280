export default {
  translations: {
    testDriveHeader: 'test drive',
    testDriveSubHeaderDateAndTime: 'step 1 - date and time',
    testDriveSubHeaderLogin: 'step 2 - your details',
    testDriveSubHeaderUserDetails: 'step 2 - your details',
    testDriveSubHeaderSummary: 'summary',
    testDriveDateAndTimeHeader: 'Do you have a preferred date and time?',
    testDriveDateAndTimeNextButton: 'next',
    testDriveSelectDateLabel: 'Select Date',
    testDriveSelectTimeLabel: 'Select Time',
    testDriveOfferText:
      'We are pleased to offer test drives at your home or workplace if within 20km of our showroom ',
    testDriveInterestedCheckboxLabel: 'I am interested',
    testDriveLoginHeader: 'Already have an account?',
    testDriveLoginUsernameLabel: 'Username',
    testDriveLoginPasswordLabel: 'Password',
    testDriveLoginForgotPassword: 'Forgot Password?',
    testDriveLoginInvalidUsernamePassword:
      'That email / password combination is not valid.',
    testDriveRequestSomethingWentWrong:
      'Something went wrong and we couldnt send your test drive request. please try again.',
    testDriveLoginAndConfirmButtonLabel: 'login & confirm request',
    testDriveLoginNewUserHeader: 'Dont have an existing account?',
    testDriveLoginNewUserInfo:
      'Continue as guest and you will have an opportunity to create an account',
    testDriveLoginContinueAsGuestLabel: 'continue as guest',
    testDriveUserDetailsHeader:
      'Complete your details to finalise your test drive request',
    testDriveUserDetailsTitle: 'Title',
    testDriveUserDetailsFirstName: 'First Name',
    testDriveUserDetailsLastName: 'Last Name',
    testDriveUserDetailsEmail: 'Email',
    testDriveUserDetailsEmailConfirm: 'Confirm Email',
    testDriveUserDetailsPhone: 'Phone',
    testDriveUserDetailsPostcode: 'Post Code',
    testDriveUserDetailsVehicleInterest: 'I am interested in',
    testDriveUserDetailsCurrentVehicle: 'Current Vehicle',
    testDriveUserDetailsCurrentVehiclePlaceholder: 'Make/Model',
    testDriveUserDetailsCreateAccount: 'Would you like to create an account?',
    testDriveUserDetailsCreatePassword:
      'Choose a password to create your personal Jaguar account',
    testDriveUserDetailsTermsAndConditionsAccept:
      'I accept the Terms & Conditions',
    testDriveUserDetailsTermsAndConditionsNotChecked:
      'You must accept the Terms & Conditions.',
    testDriveUserDetailsConfirmPwdPlaceholder: 'Repeat password',
    testdriveUserDetailsConfirmRequest: 'confirm request',
    testDriveSummaryHeader: 'Thank you',
    testDriveSummaryVerifyAccountHeader:
      'Verify your account to view your Test Drives.',
    testDriveSummaryVerifyAccountStart: 'A verification email was sent to ',
    testDriveSummaryVerifyAccountEnd:
      'Open this email and click the link to verify your account.',
    testDriveSummaryContentHeader: 'Thanks for your enquiry.',
    testDriveSummaryContent:
      'Our representative will be in contact with you to confirm your test drive. In the meantime, if you require any further information please contact us.',
    testDriveSummaryEmailButton: 'email retailer',
    testDriveSummaryAccountButton: 'view my account',
    testDriveSummaryReserveHeader:
      'This car remains on sale and could be sold between now and your test drive.',
    testDriveSummaryReserveContent:
      'Alternatively, you can place a refundable deposit on the car to remove it from sale while you discuss the deal with the selling retailer.',
    testDriveSummaryReserveButton: 'reserve this car now',
    testDriveSummaryNextSteps: 'next steps',
    testDriveSummaryVisitUs: 'Visit Us',
    testDriveSummaryReturnToSearchResults: 'return to search results',
    testDriveGoBack: 'back',
  },
};

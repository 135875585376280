export { default as component } from './Header';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Header';
export const id = 'Header';
export const availableToAdministration = false;
export const global = true;
export const required = true;
export const position = 2;

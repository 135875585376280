import Icon from '../../modules/ResetPassword/Icon';

export { id as reducerId } from '../../modules/ResetPassword/reducer';

export { default as component } from './ResetPassword';

export { default as effects } from '../../modules/ResetPassword/effects';
export { default as config } from '../../modules/ResetPassword/config';
export {
  default as defaultConfig,
} from '../../modules/ResetPassword/defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../../modules/ResetPassword/reducer';

export const name = 'Reset Password';
export const id = 'ResetPassword';
export const availableToAdministration = false;
export const description =
  'A module that allows the user to reset their password';
export const icon = Icon;

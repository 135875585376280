// @flow
import React from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  size: number,
  colour: Object,
};

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

const AnimatedSpinner = styled.div`
  border: 4px solid #d8d8d8;
  border-radius: 50%;
  border-top: ${({ colour }) => `4px solid ${colour.value}`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  animation: ${spin} 1s linear infinite;
`;

export default function Spinner({ size, colour }: Props) {
  return <AnimatedSpinner size={size} colour={colour} />;
}

Spinner.defaultProps = {
  size: 20,
};

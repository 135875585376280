import { put, takeEvery, select, call } from 'redux-saga/effects';
import { actions, constants } from './reducer';
import { actions as financeActions } from '../financeCalculator';
import { get } from '../../helpers/http';
import {
  getPlaceholderBrandImageUrl,
  vehicleValuationServiceUrl,
  getLocale,
} from '../selectors/settings';

function* loadVehicleMakes() {
  try {
    const baseUrl = yield select(vehicleValuationServiceUrl);
    const url = `${baseUrl}makes`;
    const response = yield call(get, { url });
    yield put(actions.loadVehicleMakesSuccess(response.data.contents));
  } catch (error) {
    yield put(actions.loadVehicleMakesFailure());
  }
}

function* loadVehicleModels(action) {
  const { payload: makeId } = action;
  try {
    const baseUrl = yield select(vehicleValuationServiceUrl);
    const url = `${baseUrl}makes/${makeId}/models`;
    const response = yield call(get, { url });
    yield put(actions.loadVehicleModelsSuccess(response.data.contents));
  } catch (error) {
    yield put(actions.loadVehicleMakesFailure());
  }
}

function* loadVehicleVersions(action) {
  const {
    payload: { makeId, modelId },
  } = action;
  try {
    const baseUrl = yield select(vehicleValuationServiceUrl);
    const url = `${baseUrl}makes/${makeId}/models/${modelId}/variants`;
    const response = yield call(get, { url });
    yield put(actions.loadVehicleVersionsSuccess(response.data.contents));
  } catch (error) {
    yield put(actions.loadVehicleVersionsFailure());
  }
}

function* loadVehicleRegistrationMonths(action) {
  const {
    payload: { makeId, modelId, year },
  } = action;
  const transform = (dates, locale) =>
    dates.map(date => {
      const newDate = new Date();
      newDate.setMonth(date - 1, 1);
      const month = newDate.toLocaleString(locale.replace('_', '-'), {
        month: 'long',
      });
      return {
        id: date.toString(),
        label: month,
        value: date.toString(),
      };
    });
  try {
    const baseUrl = yield select(vehicleValuationServiceUrl);
    const locale = yield select(getLocale);
    const url = `${baseUrl}makes/${makeId}/models/${modelId}/years/${year}/months`;
    const response = yield call(get, { url });
    yield put(
      actions.loadVehicleRegistrationMonthsSuccess(
        transform(response.data.contents, locale),
      ),
    );
  } catch (error) {
    yield put(actions.loadVehicleRegistrationMonthsFailure());
  }
}

function* loadVehicleRegistrationYears(action) {
  const {
    payload: { makeId, modelId, versionId },
  } = action;
  const transform = years =>
    years.map(year => ({
      id: year.toString(),
      label: year.toString(),
      value: year.toString(),
    }));
  try {
    const baseUrl = yield select(vehicleValuationServiceUrl);
    const url = `${baseUrl}makes/${makeId}/models/${modelId}/variants/${versionId}/years`;
    const response = yield call(get, { url });
    yield put(
      actions.loadVehicleRegistrationYearsSuccess(
        transform(response.data.contents),
      ),
    );
  } catch (error) {
    yield put(actions.loadVehicleRegistrationYearsFailure());
  }
}

function* getVehicleValuation(action) {
  const {
    payload: { make, model, versionId, year, month, odometer, vehicleDetails },
  } = action;
  try {
    const baseUrl = yield select(vehicleValuationServiceUrl);
    const url = `${baseUrl}variants/${versionId}/year/${year}/month/${month}/odometer-reading/${odometer}/quotation`;
    const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
    const { financingType } = vehicleDetails.finance;
    const maxDeposit = yield select(
      state =>
        state.shared.financeCalculator.financeOptions[financingType]
          .downpaymentRange.max,
    );
    const additionalServices =
      vehicleDetails.finance.additionalServices &&
      vehicleDetails.finance.additionalServices
        .filter(as => as.selected)
        .map(as => as.id);

    const response = yield call(get, { url });
    yield put(
      financeActions.loadFinanceProduct({
        duration: vehicleDetails.finance.duration,
        downpayment: Math.min(response.data.valuation, maxDeposit),
        additionalServices,
        id: vehicleDetails.id,
        type: financingType,
      }),
    );
    yield put(
      actions.getVehicleValuationSuccess({
        id: vehicleDetails.id,
        valuationId: response.data.id,
        valuation: response.data.valuation,
        image: placeholdingImage,
        vehicle: `${make.label} ${model.label}`,
      }),
    );
  } catch (error) {
    if (error.status === 429) {
      yield put(actions.valuationRequestLimitReached());
      return;
    }
    yield put(actions.getVehicleValuationFailure());
  }
}

export default function* effects() {
  yield takeEvery(constants.LOAD_VEHICLE_MAKES, loadVehicleMakes);
  yield takeEvery(constants.LOAD_VEHICLE_MODELS, loadVehicleModels);
  yield takeEvery(constants.LOAD_VEHICLE_VERSIONS, loadVehicleVersions);
  yield takeEvery(
    constants.LOAD_VEHICLE_REGISTRATION_MONTHS,
    loadVehicleRegistrationMonths,
  );
  yield takeEvery(
    constants.LOAD_VEHICLE_REGISTRATION_YEARS,
    loadVehicleRegistrationYears,
  );
  yield takeEvery(constants.GET_VEHICLE_VALUATION, getVehicleValuation);
}

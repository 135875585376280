// @flow
import React from 'react';
import styled from 'styled-components';
import media from '../theme';

const Container = styled.div`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 1px;
  }
  width: 100%;
  max-height: 100%;
  display: flex;
  box-sizing: border-box;
  ${({ colour }) => colour && `background-color: ${colour.value}`};
`;

const Wrapper = styled.div`
  min-width: 1px;
  margin: 0 auto;
  ${({ relativePosition }) => relativePosition && 'position: relative'};
  ${({ contentWidth }) =>
    `width: ${contentWidth ? `${contentWidth}px` : '100%'}`};
  ${({ contentHeight }) => contentHeight && `height: ${contentHeight}px`};
  ${({ flex }) => flex && 'display: flex'};
  ${media.max.extraLarge`
    width: 100%;
  `};
`;

type Props = {
  flex: boolean,
  children: Node,
  colour: Object,
  contentWidth: number,
  contentHeight: number,
  relativePosition: boolean,
};

export default function ContentWrapper({
  children,
  colour,
  contentWidth,
  contentHeight,
  flex,
  relativePosition,
}: Props) {
  return (
    <Container colour={colour}>
      <Wrapper
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        flex={flex}
        relativePosition={relativePosition}
      >
        {children}
      </Wrapper>
    </Container>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';
import type { Theme } from '../theme';

export type Option = {
  element: Node,
  value: any,
  rotate?: number,
};

type Props = {
  name: string,
  options: Option[],
  selected?: ?string,
  onChange: string => void,
};

type ThemeProps = Props & { theme: Theme };

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  border: 2px solid
    ${(props: ThemeProps) => props.theme.colours.connectAutoBlue};
  color: ${(props: ThemeProps) => props.theme.colours.highlight01};
  display: flex;
  justify-content: space-between;
  padding: 0;
`;

const RadioLabel = styled.label.withConfig({
  displayName: 'RadioLabel',
})`
  background: ${({ theme, selected }: { theme: Theme, selected: Boolean }) =>
    selected ? theme.colours.connectAutoBlue : theme.colours.highlight02};
  color: ${({ theme, selected }: { theme: Theme, selected: Boolean }) =>
    selected ? theme.colours.highlight02 : theme.colours.highlight01};
  font-size: 32px;
  font-weight: 600;
  display: flex;
  flex: 1 0 0%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ElementWrapper = styled.div.withConfig({
  displayName: 'ElementWrapper',
})`
  display: flex;
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'none')};
`;

const HiddenRadioButton = styled.input.withConfig({
  displayName: 'HiddenRadioButton',
})`
  display: none;
`;

export default function LargeIconRadioGroup({
  options,
  selected,
  onChange,
  name,
}: Props) {
  return (
    <Container>
      {options.map(({ value, element, rotate }) => (
        <RadioLabel key={value} selected={value === selected}>
          <ElementWrapper rotate={rotate}>{element}</ElementWrapper>

          <HiddenRadioButton
            onChange={e => onChange(e.target.value)}
            type="radio"
            name={name}
            value={value}
          />
        </RadioLabel>
      ))}
    </Container>
  );
}

LargeIconRadioGroup.defaultProps = {
  options: [],
  selected: null,
};

// @flow

import React from 'react';
import { Provider, connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/es/integration/react';
import store, { persistor } from './state/store';
import theme from './theme';

import {
  App,
  LogoutWrapperContainer as RequireLogin,
  SiteContainer,
  CloneContainer,
  SiteBuilderContainer,
  HomePageContainer,
} from './containers';

const BareRoute = ({ match, component: Component, path, site }) =>
  path === match && <Component siteId={site} />;

const Route = connect(state => ({
  path: state.router.route,
  site: state.router.site,
}))(BareRoute);

export default () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <App>
          <RequireLogin>
            <Route match="Index" component={HomePageContainer} />
            <Route match="Configure" component={SiteContainer} />
            <Route match="Clone" component={CloneContainer} />
            <Route match="Edit" component={SiteBuilderContainer} />
          </RequireLogin>
        </App>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// @flow

import React, { Fragment } from 'react';
import styled from 'styled-components';

import media from '../theme';
import { localiseNumber } from '../shared/localisation/numbers';

const SpecContainer = styled.div.withConfig({
  displayName: 'SpecContainer',
})`
  display: flex;
  margin-bottom: 10px;
  ${media.max.medium`
    flex-direction: column;
    margin-bottom: 25px;
  `};
`;

const SpecGroup = styled.div.withConfig({
  displayName: 'SpecGroup',
})`
  width: 100%;
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '10px'};
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  ${media.max.medium`
    margin: 0;
  `};
`;

const SpecItem = styled.div.withConfig({
  displayName: 'SpecItem',
})`
  display: flex;
  line-height: 40px;
  border-bottom: 1px solid
    ${({ itemBorder }) => (itemBorder ? `${itemBorder.colour}` : '#d8d8d8')};
  &:first-child {
    border-top: ${({ borderTop }) =>
      borderTop ? `${borderTop.size}px solid #e5e5e5` : '1px solid #d8d8d8'};
  }
  ${({ padding }) => padding && `padding: 0 ${padding}px`};
  ${media.max.medium`
    border: 0;
    &:first-child {
      border: 0;
    }
  `};
`;

const SpecItemLabel = styled.div.withConfig({
  displayName: 'SpecItemLabel',
})`
  width: ${({ labelItemFont }) => (labelItemFont ? '100%' : '50%')};
  font-size: ${({ labelItemFont }) =>
    labelItemFont.fontSize ? `${labelItemFont.fontSize}px` : '16px'};
  ${({ labelItemFont }) =>
    labelItemFont.colour && `color: ${labelItemFont.colour.value}`};
  font-weight: 600;
`;

const SpecItemValue = styled.div.withConfig({
  displayName: 'SpecItemValue',
})`
  font-size: ${({ itemFont }) =>
    itemFont.fontSize ? `${itemFont.fontSize}px` : '16px'};
  ${({ itemFont }) =>
    itemFont.alignment && `text-align: ${itemFont.alignment}`};
  ${({ itemFont }) => itemFont.alignment && 'width: 100%'};
  ${({ itemFont }) => itemFont.colour && `color: ${itemFont.colour.value}`};
  ${({ itemFont }) => itemFont && 'flex: 1; min-width: 100px;'}
`;

type Props = {
  specification: any,
  translations: Object,
  showOdometerInformation: boolean,
  registration: string,
  showRegistration: boolean,
  styles: Object,
  showEngineCapacity: boolean,
  locale: String,
  featuredSpec: Object,
  vehicleInfo: Object,
};

function VdpSpecification({
  specification,
  translations,
  showOdometerInformation,
  registration,
  showRegistration,
  styles,
  showEngineCapacity,
  locale,
  featuredSpec,
}: Props) {
  const {
    labelItemFont = '',
    itemFont = '',
    specGroupMarginRight = '',
    specItemPadding = '',
    borderTop = '',
    itemBorder = '',
  } = styles || {};
  return (
    <SpecContainer>
      {featuredSpec ? (
        <Fragment>
          <SpecGroup marginRight={specGroupMarginRight}>
            {specification.engineDetails.powerKw && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.power}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engineDetails.powerKw} {translations.powerUnit}
                </SpecItemValue>
              </SpecItem>
            )}
            {specification.engineDetails.consumptionExtraurban && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.consumptionExtraUrban}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engineDetails.consumptionExtraurban}{' '}
                  {translations.consumptionExtraUrbanUnit}
                </SpecItemValue>
              </SpecItem>
            )}
          </SpecGroup>
          <SpecGroup marginRight={specGroupMarginRight}>
            {specification.engineDetails.combinedConsumption && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.consumptionCombined}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engineDetails.combinedConsumption}{' '}
                  {translations.consumptionCombinedUnit}
                </SpecItemValue>
              </SpecItem>
            )}

            {specification.engineDetails.co2EmissionsCombined && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.co2}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engineDetails.co2EmissionsCombined}{' '}
                  {translations.co2Unit}
                </SpecItemValue>
              </SpecItem>
            )}
          </SpecGroup>
          <SpecGroup>
            {specification.engineDetails.consumptionUrban && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.consumptionUrban}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engineDetails.consumptionUrban}{' '}
                  {translations.consumptionUrbanUnit}
                </SpecItemValue>
              </SpecItem>
            )}

            {specification.engineDetails.emissionsClass && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.emissionsClass}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engineDetails.emissionsClass}
                </SpecItemValue>
              </SpecItem>
            )}
          </SpecGroup>
        </Fragment>
      ) : (
        <Fragment>
          <SpecGroup marginRight={specGroupMarginRight}>
            <SpecItem
              padding={specItemPadding}
              borderTop={borderTop}
              itemBorder={itemBorder}
            >
              <SpecItemLabel labelItemFont={labelItemFont}>
                {translations.exterior}
              </SpecItemLabel>
              <SpecItemValue itemFont={itemFont}>
                {specification.exterior}
              </SpecItemValue>
            </SpecItem>
            <SpecItem padding={specItemPadding} itemBorder={itemBorder}>
              <SpecItemLabel labelItemFont={labelItemFont}>
                {translations.interior}
              </SpecItemLabel>
              <SpecItemValue itemFont={itemFont}>
                {specification.interior}
              </SpecItemValue>
            </SpecItem>
            <SpecItem padding={specItemPadding} itemBorder={itemBorder}>
              <SpecItemLabel labelItemFont={labelItemFont}>
                {translations.bodystyle}
              </SpecItemLabel>
              <SpecItemValue itemFont={itemFont}>
                {specification.bodystyle}
              </SpecItemValue>
            </SpecItem>
          </SpecGroup>
          <SpecGroup marginRight={specGroupMarginRight}>
            {showOdometerInformation && (
              <SpecItem
                padding={specItemPadding}
                borderTop={borderTop}
                itemBorder={itemBorder}
              >
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.display}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {`${localiseNumber(specification.odometer.reading, locale)} ${
                    specification.odometer.units
                  }`}
                </SpecItemValue>
              </SpecItem>
            )}
            <SpecItem
              padding={specItemPadding}
              borderTop={borderTop}
              itemBorder={itemBorder}
            >
              <SpecItemLabel labelItemFont={labelItemFont}>
                {translations.transmission}
              </SpecItemLabel>
              <SpecItemValue itemFont={itemFont}>
                {specification.transmission}
              </SpecItemValue>
            </SpecItem>
            {!showEngineCapacity && specification.engine && (
              <SpecItem itemBorder={itemBorder}>
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.engine}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.engine}
                </SpecItemValue>
              </SpecItem>
            )}
            {showEngineCapacity && specification.capacity && (
              <SpecItem itemBorder={itemBorder}>
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.engine}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {specification.capacity}
                </SpecItemValue>
              </SpecItem>
            )}
            <SpecItem
              padding={specItemPadding}
              borderTop={borderTop}
              itemBorder={itemBorder}
            >
              <SpecItemLabel labelItemFont={labelItemFont}>
                {translations.fuelType}
              </SpecItemLabel>
              <SpecItemValue itemFont={itemFont}>
                {specification.fuelType}
              </SpecItemValue>
            </SpecItem>
          </SpecGroup>
          <SpecGroup>
            <SpecItem
              padding={specItemPadding}
              borderTop={borderTop}
              itemBorder={itemBorder}
            >
              <SpecItemLabel labelItemFont={labelItemFont}>
                {translations.location}
              </SpecItemLabel>
              <SpecItemValue itemFont={itemFont}>
                {specification.location}
              </SpecItemValue>
            </SpecItem>
            {showRegistration && (
              <SpecItem padding={specItemPadding} itemBorder={itemBorder}>
                <SpecItemLabel labelItemFont={labelItemFont}>
                  {translations.registration}
                </SpecItemLabel>
                <SpecItemValue itemFont={itemFont}>
                  {registration}
                </SpecItemValue>
              </SpecItem>
            )}
          </SpecGroup>
        </Fragment>
      )}
    </SpecContainer>
  );
}

export default VdpSpecification;

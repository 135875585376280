// @flow
import React from 'react';
import styled from 'styled-components';

const StyledColumn = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%'};
  padding-right: ${props =>
    props.paddingRight ? props.theme.layout[props.paddingRight] : 0};
  padding-left: ${props =>
    props.paddingLeft ? props.theme.layout[props.paddingLeft] : 0};
  padding-bottom: ${props =>
    props.paddingBottom ? props.theme.layout[props.paddingBottom] : 0};
  padding-top: ${props =>
    props.paddingTop ? props.theme.layout[props.paddingTop] : 0};
  align-items: ${props => (props.alignItems ? props.alignItems : 'top')};
  flex-direction: column;
`;

type Props = {
  width?: ?string,
  alignItems?: ?string,
  paddingBottom?: ?string,
  paddingLeft?: ?string,
  paddingRight?: ?string,
  paddingTop?: ?string,
};

export default function Column(props: Props) {
  return <StyledColumn {...props} />;
}

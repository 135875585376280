// @flow
import React from 'react';
import styled from 'styled-components';

import type { Theme } from '../theme';

type Props = {
  theme: Theme,
};

const Error = styled.div`
  font-family: ${(props: Props) => props.theme.fontFamily};
  font-size: ${(props: Props) => props.theme.fontSizeHeader};
  color: ${(props: Props) => props.theme.colours.danger03};
  background: ${(props: Props) => props.theme.colours.danger01};
  border-bottom: 1px solid ${(props: Props) => props.theme.colours.danger02};
  display: flex;
  padding: ${(props: Props) =>
    `${props.theme.layout.standardPadding} ${
      props.theme.layout.doublePadding
    }`};
  align-items: center;
`;

export default function ErrorBanner(props: Props) {
  return <Error {...props} />;
}

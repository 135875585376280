// @flow
import React from 'react';
import styled from 'styled-components';

import { SectionMenuBack } from 'cms-ui';
import Help from 'react-icons/lib/io/ios-help';
import Brush from 'react-icons/lib/md/brush';
import Dashboard from 'react-icons/lib/md/dashboard';
import Subject from 'react-icons/lib/md/subject';

import ConfigSection from './ConfigSection';
import usePodMenu from './hooks/usePodMenu';

const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MenuItem = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colours.secondary01};
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  box-sizing: border-box;
  height: 61px;
  font-weight: 600;
  padding: ${({ theme }) => theme.layout.standardPadding};
  background: ${({ theme, selected }) =>
    selected ? theme.colours.primary02 : theme.colours.primary01};
  ${({ selected }) => (!selected ? 'cursor: pointer;' : '')}
`;

const sliceIcon = slice => {
  switch (slice) {
    case 'Content':
      return <Subject size="20px" />;
    case 'Layout':
      return <Dashboard size="20px" />;
    case 'Styles':
      return <Brush size="20px" />;
    default:
      return <Help size="20px" />;
  }
};

export default function PodMenu({ onLeaveModuleOverride }) {
  const {
    name,
    slices = [],
    onLeaveModule,
    onEnterSlice,
    sliceProps,
  } = usePodMenu(onLeaveModuleOverride);

  return (
    <>
      <SectionMenuBack onClick={onLeaveModule}>{name}</SectionMenuBack>
      <MenuWrapper>
        {slices.map(({ name: slice }) => (
          <MenuItem
            key={slice}
            onClick={() => onEnterSlice(slice)}
            selected={sliceProps && sliceProps.current === slice}
          >
            {sliceIcon(slice)}
            {slice}
          </MenuItem>
        ))}
      </MenuWrapper>
      {sliceProps && <ConfigSection {...sliceProps} />}
    </>
  );
}

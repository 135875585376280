import { config } from 'cms-modules';

const {
  Config,
  TextArea,
  Slice,
  Text,
  Section,
  EditorText,
  EditorLabel,
  EditorTextArea,
  Email,
  Styles,
} = config;

const enquiryTypeOptions = [
  'test_drive_request',
  'general_enquiry',
  'reservation_request',
];

const editor = Config(
  'Form',
  { id: 'Form' },
  Slice(
    'Form',
    { id: 'form' },
    Section(
      'Form',
      {},
      EditorText('Form Name', 'name'),
      Text('Success Text Headline', 'successHeadline'),
      TextArea('Success Text Body', 'successBodyText'),
      TextArea('Error Text', 'errorText'),
    ),
  ),
  Slice('E-mail', { id: 'email' }, Section('E-mail', {}, Email())),
  Slice(
    'Tracking',
    { id: 'tracking' },
    Section(
      'Tracking',
      {},
      EditorLabel('Campaign name', 'metadata.campaignName'),
      EditorTextArea('PPC Code (Landing page)', 'metadata.landingCodePPC'),
      EditorTextArea('PPC Code (submitted page)', 'metadata.submittedPagePPC'),
      EditorTextArea('Google UTM Code', 'metadata.goodgleUTMCode'),
      EditorTextArea('Google Adwords Code', 'metadata.googleAdwordsCode'),
      Styles('Enquiry Type', 'metaData.enquiryType', {
        options: enquiryTypeOptions,
      }),
    ),
  ),
);

export default editor;

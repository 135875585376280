import { config } from 'cms-modules';

const {
  Align,
  Colour,
  Config,
  Font,
  Image,
  Link,
  Slice,
  Section,
  TextArea,
} = config;

const editor = Config(
  'TextImage',
  { id: 'TextImage' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section('Layout', {}, Align('Alignment', 'alignment')),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      Image('', { image: 'image', accessibilityText: 'imageLabel' }),
      TextArea('Heading', 'headlineText', { rows: 3 }),
      TextArea('Paragraph', 'bodyText'),
      Link('Button', { button: 'button', text: 'buttonText' }),
    ),
  ),
  Slice(
    'Styles',
    { id: 'styles' },
    Section(
      'Fonts',
      {},
      Colour('Background Colour', 'backgroundColour'),
      Font('Headline font', 'headlineFont'),
      Font('Body font', 'bodyFont'),
    ),
  ),
);

export default editor;

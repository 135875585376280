/* eslint-disable import/prefer-default-export */
import { localiseCurrency } from '../shared/localisation/numbers';

const financePlaceholdersAndPropNames = {
  FINANCEPRICE: {
    prop: 'monthlyPrice',
    formatter: (price, currency, locale) =>
      localiseCurrency(
        parseFloat(price.replace('.', '').replace(',', '.')),
        locale,
        currency,
      ),
  },
  APR: {
    prop: 'apr',
    formatter: apr => apr,
  },
  INTERESTRATE: {
    prop: 'interestRate',
    formatter: interestRate => interestRate,
  },
  DURATION: {
    prop: 'duration',
    formatter: duration => duration,
  },
};

export const translateFinanceFromTemplate = (template, finance, locale) => {
  const replaceValue = (t, entry) =>
    t.replace(
      `{${entry[0]}}`,
      entry[1].formatter(finance[entry[1].prop], finance.currency, locale),
    );
  return template
    ? Object.entries(financePlaceholdersAndPropNames).reduce(
        replaceValue,
        template,
      )
    : '';
};

import {
  Config,
  Slice,
  ListSlice,
  Section,
  TextArea,
  Colour,
  Font,
  Image,
  Toggle,
} from '../../helpers/config';
import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'Brick',
  { id: 'Brick' },
  ListSlice(
    'Content',
    {
      id: 'items',
      max: 4,
      sectionLabel: 'Brick item',
      buttonLabel: 'Add new brick item',
      icon: SectionIcons.GridItem,
    },
    Image('Image', 'image'),
    TextArea('Headline', 'headlineText'),
    TextArea('Body Text', 'descriptionText'),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Colour('Background', 'brickBackgroundColour'),
      Font('Header text', 'brickItemHeaderFont'),
      Font('Description text', 'brickItemDescriptionFont'),
      Toggle('Border', 'brickPartitionBorderActive'),
      Colour('Border Colour', 'brickPartitionBorderColour'),
    ),
  ),
);

export default config;

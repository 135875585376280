export default function() {
  return {
    items: [
      {
        translations: {
          headlineText: 'Title Placeholder',
          descriptionText: 'Description Placeholder',
        },
        image: {
          label: 'default_brick_icon',
          value:
            'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg',
        },
      },
    ],
    brickItemHeaderFont: {
      fontSize: 16,
      kerning: 0.4,
      transform: 'uppercase',
      height: '10px',
    },
    brickItemDescriptionFont: {
      fontSize: 10,
      kerning: 0.4,
      transform: 'capitalize',
    },
    brickPartitionBorderActive: true,
  };
}

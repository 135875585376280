// @flow
import React from 'react';
import without from 'ramda/src/without';

import { type Props } from './FinanceOptions';
import CheckboxGroup from '../CheckboxGroup';

const newProductTypes = (productTypes: string[], product: string) => {
  if (productTypes.some(p => p === product)) {
    return without(product, productTypes);
  }
  return [...productTypes, product];
};

const convertToOption = (finance, ap) => ({
  selected: finance.productTypes.some(p => ap === p),
  label: ap,
});

export default function ProductList({ finance, updateFinanceOptions }: Props) {
  return (
    <CheckboxGroup
      options={finance.availableProductTypes.map(ap =>
        convertToOption(finance, ap),
      )}
      onOptionClick={idx =>
        updateFinanceOptions({
          ...finance,
          productTypes: newProductTypes(
            finance.productTypes,
            finance.availableProductTypes[idx],
          ),
        })
      }
    />
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';
import { HeadingTwo, fontStyleOverride } from '../Global';

import media from '../../theme';

const Checkbox = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #d5d5d5;
  padding: ${({ region }) => (region ? '8px 8px 8px 16px' : '8px')};
  align-items: center;
  justify-content: space-between;
  outline: none;
  background-color: #ffffff;
  color: ${({ enabled }) => (enabled ? '#333333' : '#d5d5d5')};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    cursor: ${({ enabled }) => (enabled ? 'pointer' : 'default')};
    background-color: ${({ enabled }) => (enabled ? ' #f8f8f8' : 'none')};
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 228px;
    ${media.max.medium`
    width: 100%;
    `};
  }
`;

export default function RegionCheckbox({
  onClick,
  label,
  filtersStyling: { filterHeadingFont },
  renderIcon,
  region,
  enabled,
}: Props) {
  const filterStyling = enabled
    ? filterHeadingFont
    : { ...filterHeadingFont, colour: { value: '#D5D5D5' } };
  return (
    <Checkbox enabled={enabled} region={region} onClick={onClick}>
      <HeadingTwo styleOverride={() => fontStyleOverride(filterStyling)}>
        {label}
      </HeadingTwo>
      {renderIcon}
    </Checkbox>
  );
}

// @flow
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import type { MediaResource } from '../state/types/media';
import settings from '../settings';

const cloudinaryMediaContentPath = `avl/${settings.brand}/`;

export const mediaResourcesDown = (resources: MediaResource[]): any => {
  return resources.map(
    ({ public_id, secure_url, format, version, width, height, bytes }) => ({
      label: public_id
        .replace(cloudinaryMediaContentPath, '')
        .split('/')
        .pop(),
      value: secure_url,
      publicId: public_id,
      meta: {
        width,
        height,
        format,
        version,
        size: `${Math.round(bytes * 0.001)} KB`,
      },
      withDelete: true,
    }),
  );
};

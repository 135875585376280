// @flow
/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import styled from 'styled-components';
import isEmpty from 'ramda/src/isEmpty';
import intersection from 'ramda/src/intersection';
import difference from 'ramda/src/difference';

import FilterPill from '../../components/FilterPill';
import { actions as filterActions, helpers } from '../../shared/filters';
import { actions as sessionPreferencesActions } from '../../shared/sessionPreferences';
import ContentWrapper from '../../components/ContentWrapper';
import { google } from '../../utilities/googleObject';
import { Paragraph, fontStyleOverride } from '../../components/Global';
import media from '../../theme';
import { localiseCurrency } from '../../shared/localisation/numbers';
import { convertPriceCurrency } from '../../shared/currencyConversion/helpers';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';

type Props = {
  config: Object,
  shared: Object,
  globalStyling: Object,
  dispatch: Function,
  updateFilters: ({ key: string, value: any }) => void,
  marketInfo: Object,
};

type State = {
  location: string,
};

const Wrapper = styled.div`
  padding: 0 40px 0px 32px;
  min-height: 48px;
  ${media.max.large`
    display: none;
  `};
`;

const ResetFilters = styled.a`
  color: #9e1b32;
  font-size: 16px;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`;

const PillsControls = styled.div`
  display: flex;
  padding-bottom: 19px;
`;

type ValuePillProps = {
  descriptionDisplay: value => string,
  filterKey: string,
};

export default class FilterPills extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      attempts: 0,
    };
  }

  componentDidMount = () => {
    this.geocoder = new google.maps.Geocoder();
    const { shared } = this.props;

    if (shared.sessionPreferences.location) {
      const { latitude, longitude } = shared.sessionPreferences.location;

      if (latitude && longitude) {
        this.parseCoordinates(latitude, longitude);
      }
    }

    this.setState(() => ({ attempts: 0 }));
  };

  componentDidUpdate(prevProps) {
    const { shared, dispatch } = this.props;
    const { shared: prevShared } = prevProps;

    const updateFilters = payload =>
      dispatch(filterActions.updateFilters(payload));

    const { latitude, longitude } = shared.sessionPreferences.location || {};

    const { latitude: prevLatitude, longitude: prevLongitude } =
      prevShared.sessionPreferences.location || {};

    if (latitude !== prevLatitude && longitude !== prevLongitude) {
      this.parseCoordinates(latitude, longitude);
    }

    if (
      !shared.sessionPreferences.currency &&
      ((shared.filters.selectedFilters &&
        shared.filters.selectedFilters.maxDefaultPrice) ||
        (shared.filters.selectedFilters &&
          shared.filters.selectedFilters.minDefaultPrice))
    ) {
      updateFilters([
        { key: 'minDefaultPrice', value: [] },
        { key: 'maxDefaultPrice', value: [] },
      ]);
    }
  }

  parseCoordinates = (latitude, longitude) => {
    this.setState(() => ({ location: null }));
    if (latitude && longitude) {
      this.geocoder.geocode(
        {
          location: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        },
        (results, status) => {
          let timeout;
          if (status === 'OVER_QUERY_LIMIT') {
            timeout = setTimeout(
              this.setState(({ attempts }) => ({ attempts: attempts + 1 })),
              2000,
            );
            if (this.state.attempts > 4) {
              return;
            }
            this.parseCoordinates(latitude, longitude);
          }
          if (
            status === google.maps.GeocoderStatus.OK &&
            results &&
            results.length > 0
          ) {
            const address =
              results.find(res => res.types.find(t => t === 'locality')) ||
              results.find(res => res.types.find(t => t === 'postal_code')) ||
              results.find(res => res.types.find(t => t === 'route'));
            if (address) {
              clearTimeout(timeout);
              this.setState(() => ({ attempts: 0 }));
              this.setState(() => ({ location: address.formatted_address }));
            }
          }
        },
      );
    }
  };

  render = () => {
    const {
      shared,
      dispatch,
      globalStyling,
      marketInfo,
      config: {
        translations,
        subHeadingFont,
        showResetFiltersButton,
        pillsFont,
      },
    } = this.props;
    const updateFilters = payload =>
      dispatch(filterActions.updateFilters(payload));
    const eraseLocation = () =>
      dispatch(
        sessionPreferencesActions.updateSessionPreferences('location', {}),
      );
    const resetFilters = () => dispatch(filterActions.resetFilters());

    const distanceDisplay = value =>
      translateFromTemplate(
        'distanceDisplayText',
        {
          VALUE: value,
        },
        translations,
      );
    const co2UnitDisplay = value =>
      translateFromTemplate(
        'co2UnitDisplayText',
        {
          VALUE: value,
        },
        translations,
      );
    const odometerDisplay = value =>
      translateFromTemplate(
        'odometerDisplayText',
        {
          VALUE: Number(value.toString()).toLocaleString(),
        },
        translations,
      );
    const fuelConsumptionDisplay = value =>
      translateFromTemplate(
        'fuelConsumptionText',
        {
          VALUE: Number(value.toString()).toLocaleString(),
        },
        translations,
      );

    const currentCurrency = shared.sessionPreferences.currency;
    const currencySymbol = currentCurrency || marketInfo.currencyCode || 'EUR';

    const priceDisplay = (min, max) => {
      return `${localiseCurrency(
        Number(min),
        marketInfo.locale,
        currentCurrency || currencySymbol,
        0,
      )} -
        ${localiseCurrency(
          Number(max),
          marketInfo.locale,
          currentCurrency || currencySymbol,
          0,
        )}`;
    };

    const selectedFilters =
      shared && shared.filters && shared.filters.selectedFilters;

    const RangePill = ({ minFilterKey, maxFilterKey, rangeDisplay }) => {
      const min = selectedFilters && selectedFilters[minFilterKey];
      const max = selectedFilters && selectedFilters[maxFilterKey];

      return min && max ? (
        <FilterPill
          pillText={rangeDisplay ? rangeDisplay(min, max) : `${min} - ${max}`}
          onClose={() => {
            updateFilters([
              { key: minFilterKey, value: [] },
              { key: maxFilterKey, value: [] },
            ]);
          }}
          font={pillsFont}
          direction={globalStyling.direction}
        />
      ) : null;
    };

    const getFilterDisplayText = (filterKey, item) => {
      const availableFilterValues =
        shared &&
        shared.filters &&
        shared.filters.availableFilterValues &&
        shared.filters.availableFilterValues[filterKey];

      const value =
        availableFilterValues &&
        availableFilterValues.find(f => f.value.toString() === item);
      return (value && value.display) || item;
    };

    const MultiItemsPills = ({ filterKey }) => {
      const items = selectedFilters && selectedFilters[filterKey];
      return items && !isEmpty(items)
        ? items.map(item => (
            <FilterPill
              key={`${filterKey}${item}`}
              pillText={getFilterDisplayText(filterKey, item)}
              onClose={() =>
                updateFilters([
                  {
                    key: filterKey,
                    value: helpers.newArray(items, item),
                  },
                ])
              }
              font={pillsFont}
              direction={globalStyling.direction}
            />
          ))
        : null;
    };

    const ValuePill = ({ descriptionDisplay, filterKey }: ValuePillProps) => {
      const value = selectedFilters && selectedFilters[filterKey];
      return value ? (
        <FilterPill
          pillText={descriptionDisplay(value)}
          onClose={() => updateFilters([{ key: filterKey, value: [] }])}
          font={pillsFont}
          direction={globalStyling.direction}
        />
      ) : null;
    };
    ValuePill.defaultProps = {
      descriptionDisplay: value => value,
    };

    const LocationPill = () => {
      const value = this.state.location;
      return value ? (
        <FilterPill
          pillText={value}
          onClose={() => {
            eraseLocation();
          }}
          font={pillsFont}
          direction={globalStyling.direction}
        />
      ) : null;
    };

    const updateFiltersForRegions = (regionValue, regions, selectedData) => {
      const subregions = regions[regionValue]
        .filter(sr => sr.enabled)
        .map(sr => sr.name);
      const newFilterValue = difference(selectedData, subregions);
      updateFilters([{ key: 'subregions', value: newFilterValue }]);
    };

    const updateFiltersForSubregions = (subRegion, selectedData) =>
      updateFilters([
        {
          key: 'subregions',
          value: helpers.newArray(
            selectedData && selectedData.length > 0
              ? selectedData.filter(Boolean)
              : [],
            subRegion,
          ),
        },
      ]);

    const RegionPills = () => {
      const regions =
        shared.filters.availableFilterValues &&
        shared.filters.availableFilterValues.regions;
      const selectedSubregions = selectedFilters && selectedFilters.subregions;
      const formatTitles =
        regions &&
        Object.keys(regions)
          .map(key => {
            const enabledSubregionsForRegion =
              regions[key].some(r => r.enabled) &&
              regions[key].filter(r => r.enabled).map(sr => sr.name);
            const selected =
              selectedSubregions && selectedSubregions.length > 0
                ? selectedSubregions
                : [];
            const enabledRegionKey = regions[key].some(r => r.enabled) && [key];
            return difference(enabledSubregionsForRegion, selected || [])
              .length === 0
              ? enabledRegionKey
              : intersection(enabledSubregionsForRegion, selected);
          })
          .filter(el => el.length > 0)
          .flat();

      const onClose = pillValue =>
        Object.keys(regions).some(
          r => r.toLowerCase() === pillValue.toLowerCase(),
        )
          ? updateFiltersForRegions(pillValue, regions, selectedSubregions)
          : updateFiltersForSubregions(pillValue, selectedSubregions);

      return formatTitles && formatTitles.length > 0
        ? formatTitles
            .sort()
            .map(pill => (
              <FilterPill
                pillText={pill}
                onClose={() => onClose(pill)}
                font={pillsFont}
                direction={globalStyling.direction}
              />
            ))
        : null;
    };

    const filteringByRetailer = window.location.search
      .substring(1)
      .includes('retailerId');
    const resultsCountLabel = `${shared.filters.total} ${
      shared.filters.total !== 1
        ? translations.searchResultsAvailableAtLabel
        : translations.searchResultsAvailableAtSingleLabel
    }`;

    const retailerLabel = translateFromTemplate(
      shared.filters.total !== 1
        ? 'searchResultsAvailableRetailerLabel'
        : 'searchResultsAvailableRetailerSingleLabel',
      {
        COUNT: shared.filters.total,
        RETAILER: shared.filters.dealer ? shared.filters.dealer.name : '',
      },
      translations,
    );

    const singularOrPluralHeading = () =>
      filteringByRetailer ? retailerLabel : resultsCountLabel;

    const availableVehiclesText = () =>
      shared.filters.total !== undefined && singularOrPluralHeading();

    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <Wrapper>
          <PillsControls>
            <Paragraph
              styleOverride={() => `
                    ${fontStyleOverride(subHeadingFont)}
                    margin: 0;
                `}
            >
              {availableVehiclesText()}
            </Paragraph>
            {showResetFiltersButton && (
              <ResetFilters onClick={resetFilters}>reset filters</ResetFilters>
            )}
          </PillsControls>
          <RegionPills />
          <LocationPill />
          <ValuePill
            descriptionDisplay={distanceDisplay}
            filterKey="maxDistance"
          />
          <RangePill
            minFilterKey="minPriceRange"
            maxFilterKey="maxPriceRange"
            rangeDisplay={priceDisplay}
          />
          {!currentCurrency ? null : (
            <RangePill
              minFilterKey="minDefaultPrice"
              maxFilterKey="maxDefaultPrice"
              rangeDisplay={priceDisplay}
            />
          )}
          <ValuePill
            descriptionDisplay={co2UnitDisplay}
            filterKey="co2Emissions"
          />
          <MultiItemsPills filterKey="model" />
          <MultiItemsPills filterKey="variant" />
          <MultiItemsPills filterKey="modelVariants" />
          <MultiItemsPills filterKey="engine" />
          <MultiItemsPills filterKey="fuelType" />
          <MultiItemsPills filterKey="transmission" />
          <MultiItemsPills filterKey="bodystyle" />
          <MultiItemsPills filterKey="exteriorColours" />
          <MultiItemsPills filterKey="interiorShades" />
          <ValuePill
            descriptionDisplay={odometerDisplay}
            filterKey="maxOdometer"
          />
          <RangePill minFilterKey="yearRangeMin" maxFilterKey="yearRangeMax" />
          <ValuePill
            descriptionDisplay={fuelConsumptionDisplay}
            filterKey="maxFuelConsumption"
          />
          <MultiItemsPills filterKey="features" />
        </Wrapper>
      </ContentWrapper>
    );
  };
}

// @flow

export type ScreenSizeOption = {
  name: string,
  maxWidth: number,
  minWidth: number,
  height?: number,
};

const screenSizeOptions: ScreenSizeOption[] = [
  { name: 'desktop' },
  { name: 'tablet', maxWidth: 1024 },
  { name: 'mobile', maxWidth: 375 },
];

export default screenSizeOptions;

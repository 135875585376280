// @flow
import React from 'react';

import { TextArea, CheckboxSwitch, FieldLabel } from 'cms-ui';
import styled from 'styled-components';
import { Media } from '../fields';
import useBlogUpdater from './hooks/useBlogUpdater';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 0px 10px;
`;

const TextLabel = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  font-size: 13px;
`;

export default function EditBlogMenu() {
  const {
    setVisibility,
    setDescription,
    setKeywords,
    setTitle,
    setImage,
    title,
    keywords,
    description,
    visible,
    image,
    media,
    mediaActions,
  } = useBlogUpdater();

  return (
    <>
      <div style={{ padding: '10px 10px 0px 10px' }}>
        <FieldLabel label="Visibility" inputFlexMode="0">
          <CheckboxSwitch
            value={visible}
            onChange={() => setVisibility(!visible)}
          />
        </FieldLabel>
      </div>
      <FieldContainer>
        <TextLabel>Post Title</TextLabel>
        <TextArea type="text" value={title} onChange={setTitle} />
      </FieldContainer>
      <FieldContainer>
        <Media
          {...{
            name: 'Image',
            ids: { media: 'image' },
            values: { media: image },
            onChange: (_, value) => setImage(value),
            type: 'image',
            actions: mediaActions,
            data: { media },
          }}
        />
      </FieldContainer>
      <FieldContainer>
        <TextLabel>Meta Description</TextLabel>
        <TextArea type="text" value={description} onChange={setDescription} />
      </FieldContainer>
      <FieldContainer>
        <TextLabel>Meta Keywords</TextLabel>
        <TextArea type="text" value={keywords} onChange={setKeywords} />
      </FieldContainer>
    </>
  );
}

// @flow
import React from 'react';
import styled from 'styled-components';

import { Button, buttonStyleOverride } from '../Global';

const buttonAlignMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.alignment || 'center'};
`;

export default (props: Props) => {
  const {
    config: { translations, alignment, button },
    globalStyling: { uiElements = {} } = {},
    handleSubmit,
  } = props;

  return (
    <ButtonWrapper {...{ alignment: buttonAlignMapping[alignment] }}>
      <Button
        buttonStyle={
          uiElements.primaryButton && uiElements.primaryButton.buttonStyle
        }
        applyStyle="primary"
        styleOverride={() => buttonStyleOverride(button)}
        text={translations.submitbuttonLabel}
        onClick={handleSubmit}
      />
    </ButtonWrapper>
  );
};

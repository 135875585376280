import {
  Config,
  Slice,
  Section,
  Colour,
  Font,
  TextArea,
  Align,
} from '../../helpers/config';

import SectionIcons from '../../helpers/SectionIcons';

const config = Config(
  'CookiePolicy',
  { id: 'CookiePolicy' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section(
      'Global',
      { icon: SectionIcons.TopTier },
      Align('Alignment', 'alignment'),
    ),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Cookie Policy',
      { icon: SectionIcons.MidTier },
      TextArea('Cookie policy text', 'bodyText'),
    ),
  ),
  Slice(
    'Styling',
    { id: 'styling' },
    Section(
      'Styling',
      { icon: SectionIcons.TopTier },
      Colour('Background Colour', 'backgroundColour'),
      Font('Body text', 'cookiesBodyFont'),
    ),
  ),
);
export default config;

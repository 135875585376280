// @flow
import React from 'react';
import styled from 'styled-components';
import IoAndroidRadioButtonOn from 'react-icons/lib/io/android-radio-button-on';
import IoAndroidRadioButtonOff from 'react-icons/lib/io/android-radio-button-off';

import type { Theme } from '../theme';

export type Props<Option> = {
  options: Option[],
  getLabelFromOption?: (?Option) => any,
  getValueFromOption?: (?Option) => any,
  onChange: Option => void,
  value?: ?Option,
};

type ThemeProps = Props<*> & { theme: Theme };

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  color: ${(props: ThemeProps) => props.theme.colours.secondary03};
  display: flex;
  flex-direction: column;
`;

const Option = styled.div.withConfig({
  displayName: 'Option',
})`
  display: flex;
  cursor: pointer;
  margin-bottom: 9px;
`;

const OptionLabel = styled.span`
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
`;

const ThemedIcon = (icon, name) => styled(icon).withConfig({
  displayName: name,
})`
  color: ${(props: ThemeProps) => props.theme.colours.primary01};
  margin-right: 7px;
`;

const RadioOn = ThemedIcon(IoAndroidRadioButtonOn, 'RadioOn');
const RadioOff = ThemedIcon(IoAndroidRadioButtonOff, 'RadioOff');

const Radio = ({ on }: { on: boolean }) => (on ? <RadioOn /> : <RadioOff />);

export default function RadioGroup({
  options,
  getLabelFromOption,
  getValueFromOption,
  onChange,
  value,
}: Props<*>) {
  const defaultGetLabel = o => o.label;
  const defaultGetValue = o => o.value;
  const getLabel = getLabelFromOption || defaultGetLabel;
  const getValue = getValueFromOption || defaultGetValue;

  return (
    <Container>
      {options.map(o => (
        <Option key={getValue(o)} onClick={() => onChange(o)}>
          <Radio on={!!value && getValue(o) === getValue(value)} />
          <OptionLabel>{getLabel(o)}</OptionLabel>
        </Option>
      ))}
    </Container>
  );
}

RadioGroup.defaultProps = {
  getLabelFromOption: opt => opt.label,
  getValueFromOption: opt => opt.value,
  value: null,
};

// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_SHORTLIST';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'TOGGLE_VEHICLE',
]);

export const id = 'ShortlistGrid';

export const actions = {
  init: moduleAction(constants.INIT, id),
  toggleVehicle: moduleActionWithArgs(
    constants.TOGGLE_VEHICLE,
    id,
    payload => ({ ...payload }),
  ),
};

export const initialState = {};

export function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

// @flow
import React from 'react';
import styled from 'styled-components';

import { HeadingThree, Paragraph, Button } from './Global';
import ModalCurrency from './ModalCurrency';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  width: 100%;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
`;

type Props = {
  translations: Object,
  globalStyling: Object,
  disableCurrencyPopup: () => void,
  shared: Object,
};

export default function CurrencyPopup({
  translations,
  globalStyling,
  disableCurrencyPopup,
  shared,
}: Props) {
  return (
    <ModalCurrency
      modalWidth={600}
      globalStyling={globalStyling}
      translations={translations}
      shared={shared}
      closeButton
    >
      <Container>
        <HeadingThree
          styleOverride={() => `
            display: flex;
            padding: 24px;
            justify-content: center;
            text-transform: uppercase;
          `}
        >
          {translations.currencyPopupHeader}
        </HeadingThree>
        <Paragraph
          styleOverride={() => `
            display: flex;
            width: 80%;
            margin: 0;
            justify-content: center;
          `}
        >
          {translations.currencyPopupText}
        </Paragraph>
        <ButtonContainer>
          <Button
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
            text={translations.currencyButton}
            applyStyle="primary"
            styleOverride={() => 'margin: 24px;'}
            mobileStyleOverride={() => 'margin: 20px;'}
            onClick={disableCurrencyPopup}
          />
        </ButtonContainer>
      </Container>
    </ModalCurrency>
  );
}

// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import intersection from 'ramda/src/intersection';
import difference from 'ramda/src/difference';
import {
  HeadingTwo,
  HeadingThree,
  fontStyleOverride,
} from '../../components/Global';
import StyledIcon from '../../components/StyledIcon';
import { RegionCheckboxGroup } from '../../components/RegionCheckboxGroup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  position: relative;
  box-sizing: border-box;
`;

const HeadingWrapper = styled.div`
  padding-bottom: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  height: 40px;
  cursor: pointer;
  width: 100%;
  padding: 0 8px;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;

const ExpandContainer = styled.div`
  width: 89%;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  position: absolute;
  top: 81px;
  max-height: 210px;
  overflow-y: auto;
  background-color: #ffffff;
`;

type Props = {
  translations: Object,
  globalStyling: Object,
  filtersStyling: Object,
  filterKey: string,
  updateFilters: filterObj => void,
  availableFilterValues: Object,
  selectedData: any[],
};

export default function RegionalFilter({
  translations,
  globalStyling,
  filtersStyling,
  availableFilterValues,
  updateFilters,
  filterKey,
  selectedData,
}: Props) {
  const [open, toggleOpen] = useState(false);
  const { regions } = availableFilterValues;
  const renderIcon = (toggle, openIcon, closeIcon) =>
    toggle ? (
      <StyledIcon link={openIcon} width={20} height={20} />
    ) : (
      <StyledIcon link={closeIcon} width={20} height={20} />
    );

  /* formatTitles: If all subregions of a region are selected. We return the region.
  Else we return a list of the selected subregions.

  flattenedTitles flattens formatTitles into a single and sorts it alphabetically.
  */
  const formatTitles = Object.keys(regions)
    .map(key => {
      const enabledSubregionsForRegion =
        regions[key].some(r => r.enabled) &&
        regions[key].filter(r => r.enabled).map(sr => sr.name);
      const selected =
        selectedData && selectedData.length > 0 ? selectedData : [];
      const enabledRegionKey = regions[key].some(r => r.enabled) && [key];
      return difference(enabledSubregionsForRegion, selected || []).length === 0
        ? enabledRegionKey
        : intersection(enabledSubregionsForRegion, selected);
    })
    .filter(el => el.length > 0);

  const flattenedTitles =
    formatTitles.length > 0
      ? formatTitles
          .flat()
          .sort()
          .join(', ')
      : formatTitles;

  const selectedValues =
    flattenedTitles.length > 0
      ? flattenedTitles
      : translations.regionFilterPlaceholderText;

  const primaryColour =
    globalStyling &&
    globalStyling.colours &&
    globalStyling.colours.primaryBrandColour &&
    globalStyling.colours.primaryBrandColour.value;

  const regionalCheckboxes = Object.keys(regions).map(key => (
    <RegionCheckboxGroup
      key={key}
      region={key}
      subregions={regions[key]}
      filtersStyling={filtersStyling}
      updateFilters={updateFilters}
      selectedData={selectedData}
      filterKey={filterKey}
      renderIcon={renderIcon}
    />
  ));
  return (
    <Container>
      <HeadingWrapper>
        <HeadingThree
          styleOverride={() =>
            fontStyleOverride(filtersStyling.filterHeadingFont)
          }
        >
          {translations.searchIn}
        </HeadingThree>
      </HeadingWrapper>
      <SelectContainer onClick={() => toggleOpen(!open)}>
        <HeadingTwo
          styleOverride={() => `
              ${fontStyleOverride(filtersStyling.filterHeadingFont)}
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            `}
        >
          {selectedValues}
        </HeadingTwo>
        {renderIcon(
          open,
          filtersStyling.arrowUpIcon,
          filtersStyling.arrowDownIcon,
        )}
      </SelectContainer>
      {open ? (
        <ExpandContainer primaryColour={primaryColour}>
          {regionalCheckboxes}
        </ExpandContainer>
      ) : null}
    </Container>
  );
}

export default {
  translations: {
    budgetSearchFinanceLink: 'Finance Explained',
    budgetSearchBudgetLink: 'Budget Search',
    budgetSearchTitle: 'My Monthly Budget',
    budgetSearchSliderTitle: 'My budget search',
    priceFrom: 'From {PRICE} €',
    financeFrom: 'Finance from {FINANCEPRICE} € a month / {APR}% APR',
    numberAvailable: '{COUNT} available',
    bodyText:
      'Based on your specified budget of {MAX} € per month, we have found {COUNT} models that match your budget.',
    budgetMatch: '{COUNT} models match your budget',
    similarPricesText:
      'If you increase your monthly budget by {INCREASE} € you could drive away in one of these',
    learnMoreButtonText: 'Learn more about this model',
    viewButtonText: 'View Now',
    searchButtonText: 'View All Results',
    noResultsText: 'No models found with that criteria',
  },
};

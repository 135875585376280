import Icon from './Icon';

export { default as component } from './Brick';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Brick';
export const id = 'Brick';
export const availableToAdministration = true;

export const description = 'Brick Module';
export const icon = Icon;

export function template() {
  return {
    items: {
      translations: {
        headlineText: 'Title Placeholder',
        descriptionText: 'Description Placeholder',
      },
      image: {
        label: 'default_brick_icon',
        value:
          'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg',
      },
    },
  };
}

// @flow
import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

const animateFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const animateFadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const FadeIn = styled.div`
  opacity: 1;
  animation: ${animateFadeIn} 0.5s linear;
`;

const FadeOut = styled.div`
  opacity: 0;
  animation: ${animateFadeOut} 0.5s linear;
  display: ${({ removeFadeOut }) => (removeFadeOut ? 'none' : 'block')};
`;

type Props = {
  children: React.Node,
  display: boolean,
};

export default class Fade extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      removeFadeOut: false,
    };
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.display !== this.props.display &&
      !this.state.fadeOut &&
      this.props.display
    ) {
      this.setState({
        removeFadeOut: false,
      });
    }
  };

  setFadeOutNone = () => {
    this.setState({
      removeFadeOut: true,
    });
  };

  render = () => {
    const { children, display } = this.props;

    return display ? (
      <FadeIn>{children}</FadeIn>
    ) : (
      <FadeOut
        removeFadeOut={this.state.removeFadeOut}
        onAnimationEnd={() => this.setFadeOutNone()}
      >
        {children}
      </FadeOut>
    );
  };
}

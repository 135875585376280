// @flow
import React from 'react';
import DisplaySubNavBar from './DisplaySubNavBar';

type Props = {
  onBackButtonClick: () => void,
  links: Link[],
  config: Object,
  globalStyling: Object,
};

export default function PathnameSubNav(props: Props) {
  const selected =
    props.links &&
    props.links.find(link => link.path === window.location.pathname.substr(1));
  return <DisplaySubNavBar {...props} selected={selected} />;
}

import { useDispatch, useSelector } from 'react-redux';
import { actions as contextActions } from '../../state/actions/context';
import cookiePolicyConfig from '../../pods/cookiePolicy';
import { getGlobalStylingForModules } from '../../state/reducers/editor';

const useEditCookiePolicy = () => {
  const pod = useSelector(state =>
    Object.values(state.config.pods).find(p => p.id === 'CookiePolicy'),
  );
  const editor = useSelector(state => state.editor);
  const dispatch = useDispatch();

  const band = useSelector(
    state =>
      pod &&
      Object.values(state.config.bands).find(b =>
        b.config.pods.includes(pod.instanceId),
      ),
  );

  const globalStyling = getGlobalStylingForModules(editor);

  const editCookiePolicy = () => {
    dispatch(
      contextActions.setBandContext({
        id: band.id,
        instanceId: band.instanceId,
        global: true,
      }),
    );
    dispatch(
      contextActions.setPodContext({
        id: pod.id,
        instanceId: pod.instanceId,
      }),
    );
    dispatch(contextActions.setSliceContext(cookiePolicyConfig.defaultSlice));
    dispatch(contextActions.setGlobalContext('Edit Cookie Policy Content'));
  };

  return {
    editCookiePolicy,
    pod,
    globalStyling,
  };
};

export default useEditCookiePolicy;

import { config } from 'cms-modules';

const {
  Config,
  Slice,
  Section,
  Image,
  TextArea,
  Text,
  Styles,
  Align,
  Font,
} = config;

const editor = Config(
  'FeatureInventoryRange',
  { id: 'FeatureInventoryRange' },
  Slice(
    'Layout',
    { id: 'layout' },
    Section('Layout', {}, Align('Alignment', 'alignment')),
  ),
  Slice(
    'Content',
    { id: 'content' },
    Section(
      'Content',
      {},
      Styles('Model', 'model', {
        options: ['Huracan', 'Heritage', 'Aventador', 'Urus', 'Gallardo'],
      }),
      Image('Image', { image: 'image', accessibilityText: 'imageLabel' }),
      TextArea('Headline Text', 'headlineText'),
      Text('Vehicles Available Text', 'availableText'),
      Text('Single Available Vehicle Text', 'singleAvailableText'),
      Text('No Available Vehicles Text', 'unAvailableText'),
      Text('Button Text', 'buttonText'),
    ),
  ),
  Slice(
    'Styles',
    { id: 'styles' },
    Section('Fonts', {}, Font('Headline font', 'headlineFont')),
    Section('Fonts', {}, Font('Body font', 'bodyFont')),
  ),
);

export default editor;

// @flow
import React from 'react';
import styled from 'styled-components';

import media from '../theme';
import CircleTick from './JaguarIcons/Global/CircleTick';
import Button from './Global/Button';

const Container = styled.div`
  height: auto;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 32px;
  ${media.max.large`
    width: 100%;
  `};
`;

const CarTile = styled.div`
  height: 225px;
  display: flex;
  margin-bottom: 32px;
  ${media.max.large`
    height: 142px;
    align-items: center;
  `};
`;

const CarTextAndIconAndCheckList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;
`;

const CarTextAndIcon = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const CarText = styled.div`
  text-align: left;
  width: 100%;
  height: 100%;
  margin: 0 12px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  ${media.max.large`
    display: none;
  `};
`;

const ModelName = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0;
  ${media.max.large`
    height: 50%;
    text-align: left;
    font-size: 18px;
    margin: 0;
  `};
`;

const SubHeading = styled.div`
  height: 50%;
  text-align: left;
  font-size: 16px;
  color: #444444;
  margin: 7px 0 0 0;
`;

const CarImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 300px;
  ${media.max.large`
    min-height: 60px;
    width: 30%;
  `};
  ${media.max.medium`
    width: 40%;
  `};
`;

const IconColumn = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

type Props = {
  bookingForm: Object,
  testDriveConfig: Object,
  placeholdingImage: string,
  globalStyling: Object,
  translations: Object,
  navigateToVdp: () => void,
};

export default function Summary({
  testDriveConfig,
  globalStyling: { colours, uiElements },
  translations,
  navigateToVdp,
}: Props) {
  const primaryBrandColour =
    colours.primaryBrandColour && colours.primaryBrandColour.value;
  const testDriveSpec = testDriveConfig.specification;
  return (
    <Container>
      <CarTile>
        {testDriveConfig.images.length > 0 ? (
          <CarImage src={testDriveConfig.images[0]} />
        ) : (
          <CarImage src={testDriveConfig.placeholdingImage} />
        )}
        <CarTextAndIconAndCheckList>
          <CarTextAndIcon>
            <CarText>
              <div>
                <ModelName>{testDriveConfig.name}</ModelName>
                <SubHeading>
                  {[
                    testDriveSpec.odometer.display,
                    testDriveSpec.engine,
                    testDriveSpec.transmission,
                    testDriveSpec.fuelType,
                  ]
                    .filter(Boolean)
                    .join(' | ')}
                </SubHeading>
              </div>
              <Button
                applyStyle="primary"
                buttonStyle={
                  uiElements.primaryButton &&
                  uiElements.primaryButton.buttonStyle
                }
                text={translations.viewCarDetails}
                onClick={navigateToVdp}
              />
            </CarText>
            <IconColumn>
              <IconWrapper>
                <CircleTick
                  colour={primaryBrandColour}
                  width="4em"
                  height="4em"
                />
              </IconWrapper>
            </IconColumn>
          </CarTextAndIcon>
        </CarTextAndIconAndCheckList>
      </CarTile>
    </Container>
  );
}

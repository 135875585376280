// @flow

import compose from 'ramda/src/compose';
import { constants } from '../actions/page';
import type { Action } from '../actions/page';
import type { Action as EditorAction } from '../actions/editor';
import type { Page, Module } from '../types/page';

type State = {
  pages: Page[],
  modules: Module[],
  error: ?Error,
  numPagesSaving: number,
};

const initialState: State = {
  pages: [],
  modules: [],
  linkedModules: {},
  error: null,
  numPagesSaving: 0,
};

const stripNonUrlSafeChars = (str: string): string =>
  str.replace(/[^a-zA-Z0-9-_.-/]+/, '');
const stripDoubleForwardSlashes = str => str.replace(/\/\//, '/');

const formatPath = str =>
  str === '/*' ? str : stripNonUrlSafeChars(stripDoubleForwardSlashes(str));

export const stripNonUrlSafeCharsFromPageUrl = (page: any): any => ({
  ...page,
  relativePath: page.relativePath && formatPath(page.relativePath),
});

export const markPageAsDirty = (page: any) => ({ ...page, dirty: true });

export default function reducer(
  state: State = initialState,
  action: Action | EditorAction,
) {
  const replacePage = page =>
    state.pages.map(p => (page.id === p.id ? page : p));

  const modifyPage = compose(
    replacePage,
    markPageAsDirty,
    stripNonUrlSafeCharsFromPageUrl,
  );

  switch (action.type) {
    case constants.CreatePageSuccess:
    case constants.SavePageSuccess:
      return {
        ...state,
        numPagesSaving: state.numPagesSaving - 1,
      };
    case constants.SavePage:
      return {
        ...state,
        numPagesSaving: state.numPagesSaving + 1,
      };
    case constants.SavePageFailure:
      return {
        ...state,
        numPagesSaving: state.numPagesSaving - 1,
        error: action.payload,
      };
    case constants.LoadPagesFailure:
    case constants.CreatePageFailure:
      return { ...state, error: action.payload };
    case constants.UpdatePage:
      return { ...state, pages: modifyPage(action.payload) };
    case constants.DeletePageSuccess:
      return {
        ...state,
        pages: state.pages.filter(p => p.id !== action.payload),
      };
    case constants.LoadLinkedModulesSuccess: {
      return {
        ...state,
        linkedModules: action.payload,
        error: null,
      };
    }

    default:
      return state;
  }
}

// @flow
import React, { Component } from 'react';
import CheckboxGroup from './CheckboxGroup';
import SectionTitle from './SectionTitle';
import Section from './Section';
import SubSection from './SubSection';
import type { CheckboxFilter } from './search-filters-helper';

type CheckboxGroupType = {
  titleKey?: string,
  checkboxes: CheckboxFilter[],
  name: string,
  id: string,
};

type Props = {
  checkboxGroups: CheckboxGroupType[],
  handleCheckboxClick: (string, string) => void,
  clearCheckboxSection: string => void,
  translations: {
    [string]: string,
  },
  stylingConfig: Object,
};

type Event = {
  target: {
    isDisabled: boolean,
    checked: boolean,
    value: string,
    id: string,
  },
};

export default class CheckboxGroupList extends Component<Props, *> {
  handleCheckboxClick = ({ target }: Event) => {
    this.props.handleCheckboxClick(target.id, target.value);
  };

  handleUncheckList = (sectionId: string) => {
    this.props.clearCheckboxSection(sectionId);
  };

  render() {
    const { checkboxGroups, translations, stylingConfig } = this.props;
    return (
      <Section>
        {checkboxGroups.map(section => (
          <div key={section.id}>
            <SubSection>
              {section.titleKey && (
                <SectionTitle hasBorder>
                  {translations[section.titleKey]}
                </SectionTitle>
              )}
              <CheckboxGroup
                id="any"
                handleClick={() => this.handleUncheckList(section.id)}
                value={translations.checkboxAnySelection}
                isChecked={!section.checkboxes.some(el => el.isChecked)}
                isDisabled={false}
                isCheckedColour={
                  stylingConfig.checkboxSelectedColour &&
                  stylingConfig.checkboxSelectedColour.value
                }
              />
              {section.checkboxes.map(checkbox => (
                <CheckboxGroup
                  id={checkbox.id}
                  key={checkbox.value}
                  handleClick={this.handleCheckboxClick}
                  value={checkbox.value}
                  isChecked={checkbox.isChecked}
                  isDisabled={checkbox.isDisabled}
                  isCheckedColour={
                    stylingConfig.checkboxSelectedColour &&
                    stylingConfig.checkboxSelectedColour.value
                  }
                />
              ))}
            </SubSection>
          </div>
        ))}
      </Section>
    );
  }
}

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import FinanceContainer from '../../containers/FinanceContainer';
import RepresentativeExample from '../../components/RepresentativeExample';
import { actions as financeActions } from '../../shared/financeCalculator';
import ContentWrapper from '../../components/ContentWrapper';

const Wrapper = styled.div`
  box-sizing: border-box;
  ${({ config }) =>
    config.horizontalBorder &&
    `
  border-top: 1px solid ${config.borderColour && config.borderColour.value};
  border-bottom: 1px solid ${config.borderColour && config.borderColour.value};
  `};
  ${({ config }) =>
    config.verticalBorder &&
    `
  border-left: 1px solid ${config.borderColour && config.borderColour.value};
  border-right: 1px solid ${config.borderColour && config.borderColour.value};
  `};
  ${({ config }) =>
    config.backgroundColour &&
    `background-color: ${config.backgroundColour.value}`};
`;

type Props = {
  config: Object,
  globalStyling: Object,
  finance: Object,
  dispatch: Function => void,
  featureFlags: Object,
  shared: Object,
};

export default class Repex extends Component<Props> {
  componentDidMount = () => {
    if (
      this.props.featureFlags.finance &&
      !this.props.shared.financeCalculator.representativeExample
    ) {
      this.props.dispatch(financeActions.loadRepresentativeExample());
    }
  };

  render = () => {
    const { config, shared, globalStyling } = this.props;
    const representativeExample =
      shared && shared.financeCalculator.representativeExample;
    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <Wrapper config={config}>
          <FinanceContainer
            component={financeProps => (
              <RepresentativeExample
                {...financeProps}
                representativeExample={representativeExample}
                config={config}
              />
            )}
          />
        </Wrapper>
      </ContentWrapper>
    );
  };
}

import Icon from './Icon';

export { default as component } from './PageHeading';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Page Heading';
export const id = 'PageHeading';
export const availableToAdministration = true;

export const description = 'PageHeading Module';
export const icon = Icon;

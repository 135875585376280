// @flow
import React from 'react';
import styled from 'styled-components';
import MyAccountHeading from './MyAccountHeading';
import Button from '../Global/Button';
import media from '../../theme';
import MailIcon from '../JaguarIcons/Global/Mail';

const namedDiv = displayName => styled.div.withConfig({ displayName });

const DesktopView = namedDiv('DesktopView')`
  display: block;
  width: 83%;
  ${media.max.medium`
    display: none;
  `};
`;

const MobileView = namedDiv('MobileView')`
  display: none;
  ${media.max.medium`
    display: flex;
    flex-direction: column;
  `};
`;

const MyAccountContent = namedDiv('MyAccountContent')`
  background-color: #f0f0f0;
  color: #000000;
  height: auto;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
`;

const TileItemContainer = namedDiv('TileItemContainer')`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  ${media.min.large`
    flex-direction: row;
    height: 220px;
  `};
`;

const DetailsContainer = namedDiv('DetailsContainer')`
  display: flex;
  flex-direction: column;
  ${media.min.large`
    width: 50%;
    padding: 10px;
  `};
`;

const HeadingContainer = namedDiv('HeadingContainer')`
  margin-left: 15px;
  margin-right: 10px;
  padding: 10px;
  ${media.min.large`
    padding: 0;
  `};
`;

const ModelName = namedDiv('ModelName')`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  &:hover {
    cursor: pointer;
  }
`;

const SubHeading = namedDiv('SubHeading')`
  font-size: 15px;
  color: #7E7E7E;
  line-height: 30px;
  text-transform: uppercase;
  font-size: 14px;
`;

const BottomContainer = namedDiv('BottomContainer')`
  ${media.min.large`
    width: 20%;
    margin: auto;
  `};
`;

const InfoContainer = namedDiv('BottomContainer')`
  padding: 10px 0;
  display: flex;
`;

const TableWrap = namedDiv('TableWrap')`
  display: flex;
  flex-flow: column nowrap;
  font-size: .8rem;
  margin: 0.5rem;
  line-height: 1.5;
  flex: 1 1 auto;
`;

const TableRow = namedDiv('TableRow')`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`;

const ImageContainer = namedDiv('ImageContainer')`
  width: auto;
  height: 100%;
  position: relative;
  ${media.min.large`
    width: 25%;
    &:hover{
      cursor: pointer;
    }
  `};
`;

const ImageOverlay = styled.div.withConfig({
  displayName: 'ImageOverlay',
})`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ButtonContainer = namedDiv('ButtonContainer')`
  display: flex;
  flex-direction: row;
  padding: 10px;
  ${media.min.large`
    flex-direction: column;
    padding: 0;
  `};
`;

const HorizontalLine = namedDiv('HorizontalLine')`
  display: block;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-top: 1px solid #dedede;
  justify-content: center;
`;

const VerticalLine = namedDiv('VerticalLine')`
  display: block;
  height: 220px;
  border: 0;
  border-left: 1px solid #dedede;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 1%;
`;

const RetailerName = namedDiv('RetailerName')`
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  ${media.max.small`
    font-size: 20px;
  `};
`;

const RetailerAddress = namedDiv('RetailerAddress')`
  font-size: 15px;
  line-height: 30px;
  color: #474747;
  ${media.max.small`
    padding-left: 5px;
    font-size: 15px;
  `};
`;

const RetailerPhone = namedDiv('RetailerPhone')`
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
  ${media.max.small`
    font-size: 14px;
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Dealer = {
  name: string,
  address: string,
  phone: string,
};

export type Vehicle = {
  vin: string,
  model: string,
  odometer: Object,
  engine: string,
  transmission: string,
  fuel: string,
  imageUrl: string,
};

export type TestDrive = {
  vehicle: Vehicle,
  placeholdingImage: string,
  dealer: Dealer,
  onContactClick: Function,
  translations: Object,
  goToVdp: (vehicle: Object) => void,
  onDeleteTestDriveClick: (vehicle: Object) => void,
  globalStyling: Object,
};

type Props = {
  testDrives: TestDrive[],
  onContactClick: Function,
  onDeleteTestDriveClick: (vehicle: Object) => void,
  translations: Object,
  goToVdp: (vehicle: Object) => void,
  globalStyling: Object,
};

function labelWithIcon(label, iconColour, Icon) {
  return (
    <IconWrapper>
      <Icon width="2em" height="2em" colour={iconColour} />
      <div style={{ marginLeft: '8px' }}>{label}</div>
    </IconWrapper>
  );
}

const TestDriveInfo = ({
  vehicle,
  placeholdingImage,
  dealer: { name, address, phone },
  onContactClick,
  onDeleteTestDriveClick,
  translations,
  goToVdp,
  globalStyling: { colours },
}: TestDrive) => (
  <TileItemContainer>
    <ImageContainer onClick={() => goToVdp({ ...vehicle })}>
      {vehicle.imageUrl ? (
        <Image src={vehicle.imageUrl} alt={vehicle.imageUrl} />
      ) : (
        <Image src={placeholdingImage} alt={placeholdingImage} />
      )}
      {vehicle.status === 'sold' && (
        <ImageOverlay>
          <SoldHeading>{translations.sold}</SoldHeading>
          <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
        </ImageOverlay>
      )}
    </ImageContainer>
    <DetailsContainer>
      <HeadingContainer>
        <ModelName onClick={() => goToVdp({ ...vehicle })}>
          {vehicle.year} {vehicle.model}
        </ModelName>
        <SubHeading>
          {`${vehicle.odometer.display} | ${vehicle.engine} | ${
            vehicle.transmission
          } | ${vehicle.fuel}`}
        </SubHeading>
      </HeadingContainer>
      <InfoContainer>
        <TableWrap>
          <TableRow>
            <RetailerName>{name}</RetailerName>
          </TableRow>
          <TableRow>
            <RetailerAddress>{address}</RetailerAddress>
          </TableRow>
          <RetailerPhone>
            {translations.myAccountRetailerPhone}
            &nbsp;
            {phone}
          </RetailerPhone>
          <TableRow />
        </TableWrap>
      </InfoContainer>
    </DetailsContainer>
    <VerticalLine />
    <BottomContainer>
      <ButtonContainer>
        {(vehicle.status !== 'sold' && (
          <Button
            data-qa-hook="contact-us-button"
            applyStyle="secondary"
            styleOverride={() => `
                    width: 100%;
                  `}
            text={labelWithIcon(
              translations.myAccountRetailerContact,
              colours && colours.primaryBrandColour.value,
              MailIcon,
            )}
            onClick={() => onContactClick(vehicle)}
            buttonStyle="ButtonStyleBTT"
          />
        )) || (
          <Button
            data-qa-hook="delete-button"
            applyStyle="secondary"
            styleOverride={() => `
                    width: 100%;
                  `}
            text={translations.myAccountDealDelete}
            onClick={() => onDeleteTestDriveClick(vehicle)}
            buttonStyle="ButtonStyle2"
          />
        )}
      </ButtonContainer>
    </BottomContainer>
  </TileItemContainer>
);

const TestDriveInfoMobile = ({
  vehicle,
  placeholdingImage,
  dealer: { name, address, phone },
  onContactClick,
  translations,
  onDeleteTestDriveClick,
  goToVdp,
  globalStyling: { colours },
}: TestDrive) => (
  <TileItemContainer>
    <HeadingContainer>
      <ModelName onClick={() => goToVdp({ ...vehicle })}>
        {vehicle.year} {vehicle.model}
      </ModelName>
      <SubHeading>
        {`${vehicle.odometer.display} | ${vehicle.engine} | ${
          vehicle.transmission
        } | ${vehicle.fuel}`}
      </SubHeading>
    </HeadingContainer>
    <ImageContainer onClick={() => goToVdp({ ...vehicle })}>
      {vehicle.imageUrl ? (
        <Image src={vehicle.imageUrl} alt={vehicle.imageUrl} />
      ) : (
        <Image src={placeholdingImage} alt={placeholdingImage} />
      )}
      {vehicle.status === 'sold' && (
        <ImageOverlay>
          <SoldHeading>{translations.sold}</SoldHeading>
          <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
        </ImageOverlay>
      )}
    </ImageContainer>
    <BottomContainer>
      <InfoContainer>
        <TableWrap>
          <TableRow>
            <RetailerName>{name}</RetailerName>
          </TableRow>
          <TableRow>
            <RetailerAddress>{address}</RetailerAddress>
          </TableRow>
          <RetailerPhone>
            {translations.myAccountRetailerPhone}
            :&nbsp;
            {phone}
          </RetailerPhone>
          <TableRow />
        </TableWrap>
      </InfoContainer>
      <HorizontalLine />
      <ButtonContainer>
        {(vehicle.status !== 'sold' && (
          <Button
            data-qa-hook="contact-us-button"
            applyStyle="secondary"
            styleOverride={() => `
                  width: 100%;
                `}
            text={labelWithIcon(
              translations.myAccountRetailerContact,
              colours && colours.primaryBrandColour.value,
              MailIcon,
            )}
            onClick={() => onContactClick(vehicle)}
            buttonStyle="ButtonStyleBTT"
          />
        )) || (
          <Button
            data-qa-hook="delete-button"
            applyStyle="secondary"
            styleOverride={() => `
                width: 100%;
              `}
            text={translations.myAccountDealDelete}
            onClick={() => onDeleteTestDriveClick(vehicle)}
            buttonStyle="ButtonStyle2"
          />
        )}
      </ButtonContainer>
    </BottomContainer>
  </TileItemContainer>
);

export default function MyAccountTestDrive(props: Props) {
  const goToVdpIfNotSold = vehicle =>
    vehicle.status !== 'sold' && props.goToVdp(vehicle);
  return (
    <div>
      <MyAccountHeading
        heading={props.translations.myAccountTestDrivesHeading}
        subHeading={props.translations.myAccountTestDrivesSubheading}
      />
      <MyAccountContent>
        <DesktopView>
          {props.testDrives.map(testDrive => (
            <TestDriveInfo
              key={testDrive.vehicle.vin}
              {...testDrive}
              onContactClick={props.onContactClick}
              onDeleteTestDriveClick={props.onDeleteTestDriveClick}
              translations={props.translations}
              goToVdp={goToVdpIfNotSold}
              globalStyling={props.globalStyling}
            />
          ))}
        </DesktopView>
        <MobileView>
          {props.testDrives.map(testDrive => (
            <TestDriveInfoMobile
              key={testDrive.vehicle.vin}
              {...testDrive}
              onContactClick={props.onContactClick}
              onDeleteTestDriveClick={props.onDeleteTestDriveClick}
              translations={props.translations}
              goToVdp={goToVdpIfNotSold}
              globalStyling={props.globalStyling}
            />
          ))}
        </MobileView>
      </MyAccountContent>
    </div>
  );
}

import Icon from './Icon';

export { default as component } from './ImageTitle';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';

export const name = 'Image title';
export const id = 'ImageTitle';
export const availableToAdministration = true;
export const description = 'Full screen image with header overlay';
export const icon = Icon;
export const searchable = true;
export const getIndexContent = ({ config: { translations = {} } = {} }) => [
  {
    title: translations.headlineText,
    content: translations.bodyText,
  },
];

// @flow
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg > rect:not([fill='none']) {
    fill: ${props => props.colour};
  }
  svg > path:not([fill]) {
    fill: ${props => props.colour};
  }
  svg {
    width: ${props => props.width};
    height: ${props => props.height};
  }
`;
export default function SlideTop({ colour, width, height }: IconProps) {
  return (
    <IconWrapper colour={colour} width={width} height={height}>
      <svg viewBox="0 0 18 18" width="1em" height="1em">
        <g fill="none" fillRule="evenodd">
          <path fill="#0065DB" d="M1456 137h7v7h-7z" />
          <path fill="#D6D6D6" d="M1464 137h6v12h-14v-4.273h8z" />
          <path stroke="#0065DB" d="M1454.5 135.5h17v15h-17z" />
        </g>
      </svg>
    </IconWrapper>
  );
}
SlideTop.defaultProps = {
  colour: null,
  width: null,
  height: null,
};

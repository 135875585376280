// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_RESET_PASSWORD';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'REQUEST_PASSWORD_RESET',
  'REQUEST_PASSWORD_RESET_SUCCESS',
  'REQUEST_PASSWORD_RESET_FAILURE',
  'RESET_PASSWORD',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_FAILURE',
]);

export const id = 'ResetPassword';

export const actions = {
  init: moduleAction(constants.INIT, id),
  requestPasswordReset: moduleActionWithArgs(
    constants.REQUEST_PASSWORD_RESET,
    id,
    payload => payload,
  ),
  requestPasswordResetSuccess: moduleAction(
    constants.REQUEST_PASSWORD_RESET_SUCCESS,
    id,
  ),
  requestPasswordResetFailure: moduleAction(
    constants.REQUEST_PASSWORD_RESET_FAILURE,
    id,
  ),
  resetPassword: moduleActionWithArgs(
    constants.RESET_PASSWORD,
    id,
    payload => payload,
  ),
  resetPasswordSuccess: moduleAction(constants.RESET_PASSWORD_SUCCESS, id),
  resetPasswordFailure: moduleAction(constants.RESET_PASSWORD_FAILURE, id),
};

type State = {
  resetRequestStatus: 'NONE' | 'SUCCESS' | 'FAILURE',
  resetPasswordStatus: 'NONE' | 'SUCCESS' | 'EXPIRED',
};

export const initialState: State = {
  resetRequestStatus: 'NONE',
  resetPasswordStatus: 'NONE',
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.INIT:
      return {
        ...initialState,
      };
    case constants.REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resetRequestStatus: 'SUCCESS',
      };
    case constants.REQUEST_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        resetRequestStatus: 'FAILURE',
      };
    case constants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordStatus: 'SUCCESS',
      };
    case constants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordStatus: 'EXPIRED',
      };
    default:
      return state;
  }
}

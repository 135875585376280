// @flow
import {
  moduleAction,
  moduleActionWithArgs,
  moduleConstants,
} from '../../utilities/reducerMacros';

const modulePrefix = 'MOD_MY_ACCOUNT';

export const constants = moduleConstants(modulePrefix, [
  'INIT',
  'CHANGE_ACCOUNT_VIEW',
  'SAVE_USER_DETAILS',
  'LOAD_TEST_DRIVES',
  'LOAD_TEST_DRIVES_SUCCESS',
  'LOAD_TEST_DRIVES_FAILURE',
  'DELETE_TEST_DRIVE',
  'DELETE_TEST_DRIVE_SUCCESS',
  'DELETE_TEST_DRIVE_FAILURE',
  'LOAD_MY_DEALS',
  'LOAD_MY_DEALS_SUCCESS',
  'LOAD_MY_DEALS_FAILURE',
  'DELETE_MY_DEAL',
  'DELETE_MY_DEAL_SUCCESS',
  'DELETE_MY_DEAL_FAILURE',
  'PRINT_MY_DEAL',
  'PRINT_MY_DEAL_SUCCESS',
  'CHANGE_PASSWORD',
  'DELETE_ACCOUNT',
  'SET_FEEDBACK',
  'SIGN_OUT',
  'RESET',
]);

export const id = 'MyAccount';

export const actions = {
  init: moduleAction(constants.INIT, id),
  changeAccountView: moduleActionWithArgs(
    constants.CHANGE_ACCOUNT_VIEW,
    id,
    context => ({
      context,
    }),
  ),
  saveUserDetails: moduleActionWithArgs(
    constants.SAVE_USER_DETAILS,
    id,
    details => ({
      ...details,
    }),
  ),
  loadTestDrives: moduleAction(constants.LOAD_TEST_DRIVES, id),
  loadTestDrivesSuccess: moduleActionWithArgs(
    constants.LOAD_TEST_DRIVES_SUCCESS,
    id,
    testDrives => ({
      ...testDrives,
    }),
  ),
  loadTestDrivesFailure: moduleActionWithArgs(
    constants.LOAD_TEST_DRIVES_FAILURE,
    id,
    error => error,
  ),
  deleteTestDrive: moduleActionWithArgs(
    constants.DELETE_TEST_DRIVE,
    id,
    vin => ({ vin }),
  ),
  deleteTestDriveSuccess: moduleActionWithArgs(
    constants.DELETE_TEST_DRIVE_SUCCESS,
    id,
    vin => ({
      vin,
    }),
  ),
  deleteTestDriveFailure: moduleActionWithArgs(
    constants.DELETE_TEST_DRIVE_FAILURE,
    id,
    error => error,
  ),
  loadMyDeals: moduleAction(constants.LOAD_MY_DEALS, id),
  loadMyDealsSuccess: moduleActionWithArgs(
    constants.LOAD_MY_DEALS_SUCCESS,
    id,
    myDeals => ({
      ...myDeals,
    }),
  ),
  loadMyDealsFailure: moduleActionWithArgs(
    constants.LOAD_MY_DEALS_FAILURE,
    id,
    error => error,
  ),
  deleteMyDeal: moduleActionWithArgs(constants.DELETE_MY_DEAL, id, vin => ({
    id: vin,
  })),
  deleteMyDealSuccess: moduleActionWithArgs(
    constants.DELETE_MY_DEAL_SUCCESS,
    id,
    vin => ({
      id: vin,
    }),
  ),
  deleteMyDealFailure: moduleActionWithArgs(
    constants.DELETE_MY_DEAL_FAILURE,
    error => error,
  ),
  printMyDeal: moduleActionWithArgs(
    constants.PRINT_MY_DEAL,
    id,
    payload => payload,
  ),
  printMyDealSuccess: moduleActionWithArgs(
    constants.PRINT_MY_DEAL_SUCCESS,
    id,
    vehicle => vehicle,
  ),
  changePassword: moduleActionWithArgs(
    constants.CHANGE_PASSWORD,
    id,
    passwords => ({
      ...passwords,
    }),
  ),
  deleteAccount: moduleActionWithArgs(
    constants.DELETE_ACCOUNT,
    id,
    password => ({ ...password }),
  ),
  setFeedback: moduleActionWithArgs(constants.SET_FEEDBACK, id, payload => ({
    ...payload,
  })),
  signOut: moduleAction(constants.SIGN_OUT, id),
  reset: moduleAction(constants.RESET, id),
};

type State = {
  error: any,
  context: string,
  testDrives: any,
  myDeals: any,
  isLoading: boolean,
  detailsFeedback: {
    saveDetails: ?string,
    changePassword: ?string,
    deleteAccount: ?string,
  },
  printing: boolean,
};
export const initialState: State = {
  error: null,
  context: 'Account',
  testDrives: [],
  myDeals: [],
  isLoading: false,
  detailsFeedback: {
    saveDetails: null,
    changePassword: null,
    deleteAccount: null,
  },
  printing: [],
};

export function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case constants.RESET:
      return {
        ...initialState,
      };
    case constants.CHANGE_ACCOUNT_VIEW:
      return {
        ...state,
        context: action.payload.context,
      };
    case constants.LOAD_TEST_DRIVES:
      return {
        ...state,
        isLoading: true,
      };
    case constants.LOAD_TEST_DRIVES_SUCCESS:
      return {
        ...state,
        testDrives: action.payload.testDrives,
        isLoading: false,
        error: null,
      };
    case constants.LOAD_MY_DEALS:
      return {
        ...state,
        isLoading: true,
      };
    case constants.LOAD_MY_DEALS_SUCCESS:
      return {
        ...state,
        myDeals: action.payload.myDeals,
        isLoading: false,
        error: null,
      };
    case constants.DELETE_MY_DEAL_SUCCESS: {
      const { id: vin } = action.payload;
      const index = state.myDeals.findIndex(
        myDeal => myDeal.vehicle.id === vin,
      );
      return {
        ...state,
        isLoading: false,
        myDeals: [
          ...state.myDeals.slice(0, index),
          ...state.myDeals.slice(index + 1),
        ],
      };
    }
    case constants.DELETE_TEST_DRIVE_SUCCESS: {
      const { vin } = action.payload;
      const index = state.testDrives.findIndex(
        testDrive => testDrive.vehicle.vin === vin,
      );
      return {
        ...state,
        isLoading: false,
        testDrives: [
          ...state.testDrives.slice(0, index),
          ...state.testDrives.slice(index + 1),
        ],
      };
    }
    case constants.SET_FEEDBACK:
      return {
        ...state,
        detailsFeedback: {
          ...state.detailsFeedback,
          ...action.payload,
        },
      };
    case constants.LOAD_TEST_DRIVES_FAILURE:
    case constants.LOAD_MY_DEALS_FAILURE:
    case constants.DELETE_MY_DEAL_FAILURE:
    case constants.DELETE_TEST_DRIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case constants.PRINT_MY_DEAL: {
      const { vehicle } = action.payload;
      return {
        ...state,
        printing: [...state.printing, vehicle.id],
      };
    }
    case constants.PRINT_MY_DEAL_SUCCESS: {
      return {
        ...state,
        printing: [...state.printing.filter(vin => vin !== action.payload.id)],
      };
    }
    default:
      return state;
  }
}

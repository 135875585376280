import Icon from './Icon';

export { id as reducerId } from '../VehicleSearchResults/reducer';

export { default as component } from './LinkedVehicleSearchResults';
export { default as effects } from '../VehicleSearchResults/effects';
export { default as config } from './config';
export { default as defaultConfig } from './defaultConfig';
export {
  actions,
  constants,
  reducer,
  initialState,
} from '../VehicleSearchResults/reducer';

export const name = 'LinkedVehicleSearchResults';
export const id = 'LinkedVehicleSearchResults';
export const availableToAdministration = true;
export const description = 'Search Results Page';
export const icon = Icon;
